// Common
import { Injectable, ElementRef } from '@angular/core';

// RX
import { fromEvent, Subject, merge } from 'rxjs';

// Components
import { PopoverComponent } from '../components/popover/popover.component';
import { PopoverConfig } from '../types/config';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil, filter, take } from 'rxjs/operators';

@Injectable()
export class PopoverService {

  /**
   * Constructor
   */

  constructor(
    private overlay: Overlay
  ) { }

  /**
   * Methods
   */

  create(originRef: ElementRef, config: PopoverConfig, originEvent?: MouseEvent): void {
    const overlayRef = this.overlay.create();
    const popoverRef = overlayRef.attach(
      new ComponentPortal(PopoverComponent)
    );

    const close = new Subject();

    // Popover Close options
    popoverRef.instance.error
      .pipe(takeUntil(close))
      .subscribe(() => close.next());

    if (config.showUntil) {
      config.showUntil
        .pipe(takeUntil(close))
        .subscribe(() => close.next());
    }
    if (config.trigger === 'click') {
      merge(
        popoverRef.instance.outsideClick,
        fromEvent(originRef.nativeElement, 'click')
      )
        .pipe(takeUntil(close))
        .subscribe(() => close.next());
    }
    if (config.trigger === 'hover') {
      merge(
        merge(
          popoverRef.instance.mouseLeave,
          fromEvent(originRef.nativeElement, 'mouseleave')
        )
          .pipe(
            filter((event: MouseEvent) =>
              !originRef.nativeElement.contains(event['toElement']) &&
              (
                !popoverRef.instance.popoverContainer ||
                !popoverRef.instance.popoverContainer.nativeElement.contains(event['toElement'])
              )
            )
          ),
        fromEvent(originRef.nativeElement, 'click')
      )
        .pipe(
          takeUntil(close)
        )
        .subscribe(() => close.next());
    }

    // This is have to be after all subscribes, so all takeUntils called properly
    close
      .pipe(take(1))
      .subscribe(() => {
        popoverRef.instance.hide();
        popoverRef.destroy();
        overlayRef.dispose();
        close.complete();
      });

    // Setting up Inputs
    popoverRef.instance.content = config.content;
    popoverRef.instance.placement = config.placement;
    popoverRef.instance.popoverArrow = config.arrow;
    popoverRef.instance.innerShadow = config.innerShadow;
    popoverRef.instance.trackMouse = config.trackMouse;
    popoverRef.instance.popoverOffsetX = config.popoverOffsetX || 0;
    popoverRef.instance.allowedOutsideSelectorsClick = config.allowedOutsideSelectors;
    popoverRef.instance.originRef = originRef;

    // Reaction to outputs
    if (config.visibleChange) {
      popoverRef.instance.visibleChange.subscribe((visible: boolean) => config.visibleChange.next(visible));
    }

    // Set correct popover position
    popoverRef.instance.setOverlayOrigin({ elementRef: originRef });
    popoverRef.instance.show(originEvent);
  }
}
