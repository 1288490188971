// Common
import {
  Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, SimpleChanges,
  ChangeDetectorRef, ViewChild, ElementRef, NgZone
} from '@angular/core';
import { Subject, of, Observable, fromEvent } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

// Services
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../../account/services/account.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { MailService } from '../../services/mail.service';
import { ModalService } from '../../../modal/services/modal.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

// Components
import { TextEditorComponent } from '@modules/common/components/text-editor/text-editor.component';

// Types
import { MailMessage } from '../../types/mail-message';
import { Account } from '../../../account/types/account';
import { AccountQuickReplyTemplate } from '../../../account/types/account-quick-reply-template';
import { DropdownOption } from '../../../dropdown/types/dropdown-option';
import { CalendarEvent } from '@modules/events/types/calendar-event';

@Component({
  selector: 'app-message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.less']
})
export class MessageEditorComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() message: MailMessage;
  @Input() sourceMessage: MailMessage;
  @Input() action: 'reply' | 'replyAll' | 'forward';
  @Input() header = false;

  // Outputs
  @Output() messageChange = new EventEmitter<MailMessage>();
  @Output() sent = new EventEmitter<MailMessage>();
  @Output() draftSaved = new EventEmitter<MailMessage>();
  @Output() cancel = new EventEmitter();

  // ViewChild
  @ViewChild(TextEditorComponent, { static: true }) textEditor: TextEditorComponent;
  @ViewChild('droppableArea', { static: true }) droppableArea: ElementRef;

  // Public
  public account: Account;
  public signature: string;
  public templates: Observable<AccountQuickReplyTemplate[]>;
  public showFields = {
    cc: false,
    bcc: false
  };

  public followUpOptions: DropdownOption[] = [
    { name: 'Tomorrow', key: 'tomorrow' },
    { name: 'Day after tomorrow', key: 'afterTomorrow' },
    { name: 'End of week', key: 'endOfWeek' },
    { name: 'Next monday', key: 'nextWeek' }
  ];
  public followUpSelectedOption: DropdownOption;

  public delayOptions: DropdownOption[] = [
    { name: '15 min', key: '15m' },
    { name: '1 hour', key: '1h' },
    { name: '3 hours', key: '3h' },
    { name: 'Tomorrow', key: 'tomorrow' },
    { name: 'Day after tomorrow', key: 'afterTomorrow' },
    { name: 'End of week', key: 'endOfWeek' },
    { name: 'Next Monday', key: 'monday' }
  ];
  public delaySelectedOption: DropdownOption;

  public templatesOptions: DropdownOption[];
  public templatesSelectedOption: DropdownOption;

  public createAppointment = false;

  public processing = false;
  public dragOverEventsCounter = 0;
  public uploadingFiles = false;
  public uploadFiles: Subject<FileList> = new Subject();


  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private accountService: AccountService,
    private ga: GoogleAnalyticsService,
    private mailService: MailService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private ngZone: NgZone,
    private elementRef: ElementRef
  ) {
    this.accountService.getAccount()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((account: Account) => this.account = account);

    this.templates = this.accountService.getQuickReplyTemplates()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        catchError(() => of([]))
      );
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    if (this.message && this.sourceMessage && this.action) {
      this.formatMessageFromSource();
    }
    if (!this.message) {
      this.message = new MailMessage();
      this.messageChanged();
    }
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.droppableArea.nativeElement, 'dragover')
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((event: DragEvent) => event.preventDefault());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.message || (this.message.id && !this.message.isDraft)) {
      this.message = new MailMessage();

      if (this.sourceMessage && this.action) {
        this.formatMessageFromSource();
      }

      this.textEditor.updateContent();

      this.messageChanged();
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  addNewTemplate() {
    this.modalService.settings('quick-reply-templates');
  }

  addFollowUp(date: Date) {
    this.message.followUpAt = date;
    this.followUpSelectedOption = null;
    this.messageChanged();
  }

  selectFollowUpOption(option: DropdownOption) {
    this.message.followUpAt = this.getDateWithPeriod(option.key);
    this.followUpSelectedOption = option;
    this.messageChanged();
  }

  removeFollowUp() {
    this.message.followUpAt = null;
    this.followUpSelectedOption = null;
    this.messageChanged();
  }

  addDelay(date: Date) {
    this.message.delay = date;
    this.delaySelectedOption = null;
    this.messageChanged();
  }

  selectDelayOption(option: DropdownOption) {
    this.message.delay = this.getDateWithPeriod(option.key);
    this.delaySelectedOption = option;
    this.messageChanged();
  }

  removeDelay() {
    this.message.delay = null;
    this.delaySelectedOption = null;
    this.messageChanged();
  }

  messageChanged() {
    this.messageChange.emit(this.message);
  }

  send() {
    if ((!this.message.body || !this.message.subject)
      && !confirm('Send this message without a subject or text in the body?')) {
      return;
    }
    this.ga.trackEvent('mail-action', 'send-email');

    this.processing = true;

    if (!this.message.from) {
      this.message.from = {
        id: null,
        email: this.account.email,
        name: this.account.name
      };
    }

    this.mailService.sendMessage(this.message)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(
        (message: MailMessage) => {
          this.processing = false;
          this.message = new MailMessage();
          this.messageChanged();
          this.sent.emit(message);

          const actions = [{
            text: 'View',
            handler: () => this.modalService.mailContent(message)
          }];
          if (message.sending) {
            actions.push({
              text: 'Undo',
              handler: () => this.mailService.cancelSendMessage(message)
            });
          }

          this.asyncTasksToastsService.show({
            text: 'Your message has been sent.',
            icon: 'folder-sent',
            actions
          });

          if (this.createAppointment) {
            this.modalService.calendarEvent(CalendarEvent.fromMailMessage(message));
          }
        },
        (error: Error) => {
          this.processing = false;
          this.asyncTasksToastsService.show({
            text: `Error while sending message: ${error.message}.`,
            icon: 'folder-sent'
          });
        }
      );
  }

  save(popoutMessage: boolean = false) {
    this.ga.trackEvent('mail-action', 'save-draft');

    this.processing = true;

    if (!this.message.from) {
      this.message.from = {
        id: null,
        email: this.account.email,
        name: this.account.name
      };
    }

    this.mailService.saveMessageToDrafts(this.message)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(
        (message: MailMessage) => {
          this.processing = false;
          this.message = new MailMessage();
          this.messageChanged();
          this.draftSaved.emit(message);
          if (popoutMessage) {
            this.modalService.compose(message);
          } else {
            this.toastrService.success('Draft has been saved.');
          }
        },
        (error: Error) => {
          this.processing = false;
          this.toastrService.error('Error while saving draft: ' + error.message);
        }
      );
  }

  close() {
    this.cancel.emit();
  }

  /**
   * Files
   */

  uploadDropedFiles(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.files) {
      this.uploadFiles.next(event.dataTransfer.files);
    }
    event.stopPropagation();
    event.preventDefault();
  }

  uploadSelectedFiles(files: FileList) {
    this.uploadFiles.next(files);
  }

  /**
   * Helpers
   */

  private getDateWithPeriod(period: string): Date {
    const date = new Date();

    switch (period) {
      case '15m':
        date.setMinutes(date.getMinutes() + 15);
        break;
      case '1h':
        date.setHours(date.getHours() + 1);
        break;
      case '3h':
        date.setHours(date.getHours() + 3);
        break;
      case 'tomorrow':
        date.setDate(date.getDate() + 1);
        break;
      case 'afterTomorrow':
        date.setDate(date.getDate() + 2);
        break;
      case 'endOfWeek':
        date.setDate(date.getDate() - (date.getDay() - 5));
        break;
      case 'nextWeek':
        date.setDate(date.getDate() + (-date.getDay() + 7) % 7 + 1);
        break;
    }

    return date;
  }

  private formatMessageFromSource() {
    let subjectPrefix = '';

    if (this.action === 'reply' || this.action === 'replyAll') {
      subjectPrefix = 'Re:';
      this.message.to = [this.sourceMessage.from];
      this.message.replyToMessage = this.sourceMessage.id;
    }

    if (this.action === 'replyAll') {
      this.message.to = this.message.to.concat(
        (this.sourceMessage.to || []).filter(recipient => !this.account || recipient.email !== this.account.email)
      );
      this.message.cc = this.sourceMessage.cc && this.sourceMessage.cc.length ? this.sourceMessage.cc : null;
    }

    if (this.action === 'forward') {
      subjectPrefix = 'Fwd:';
      this.message.forwardOfMessage = this.sourceMessage.id;
    }

    // Avoid duplication like Re: Re: Re:...
    this.message.subject = !subjectPrefix || this.sourceMessage.subject
      .substr(0, subjectPrefix.length).toLowerCase() === subjectPrefix.toLowerCase() ?
        this.sourceMessage.subject :
        `${subjectPrefix} ${this.sourceMessage.subject}`;

    this.message.body = `
      <!-- Signature start --><!-- Signature end -->
      <hr />
      <p>On ${this.sourceMessage.sentTime} ${this.sourceMessage.from.name} <${this.sourceMessage.from.email}> wrote:</p>
      <blockquote>${this.sourceMessage.body.replace('<!-- Signature start -->', '').replace('<!-- Signature end -->', '')}</blockquote>
    `;
  }
}
