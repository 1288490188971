<div
  class="event"
  [class.past-event]="eventFinished"
  [class.highlighted]="highlighted"
  [class.with-shadow]="!event.id"
  [class.cut]="event | isEventCut | async"
  [style.background-color]="(event.color || (event.calendarId | calendarColor | async)) + (highlighted ? '' : '20')"
  (appClick)="handleClick()"
  (appDblClick)="handleDoubleClick()"
  [stopPropagation]="true"
  [appEventContextMenu]="event"
  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [event], type: 'event'}"
  [appDraggablePlaceholder]="draggablePlaceholder"
  (appDraggableDraggingChanged)="handleDraggingChanged($event)"
  popover
  [popoverPlacement]="hoverPlacement"
  popoverTrigger="hover"
  [popoverOffsetX]="popoverOffsetX"
  [popoverContent]="detailsView"
  [popoverTrackMouse]="popoverTrackMouse"
  [popoverDisabled]="popoverDisabled || !dragEnabled || isDragging || !event.id || event.virtual"
  [popoverDelay]="800"
  appDroppable
  appDroppableHoverClass="event-drag-over"
  (appDroppableDrop)="handleDrop($event)"
  [appDroppableZIndex]="1"
  [appDroppablePredicate]="dndPredicate"
>
  <div class="left-border" [class.left-border-small]="rowsCount < 2" [style.border-left-color]="event.color || (event.calendarId | calendarColor | async)"></div>
  <div [ngClass]="['event-container', view, 'split-row-' + rowsCount]" [class.split-row]="rowsCount > 1">
    <mat-icon *ngIf="event.reminder" svgIcon="reminder-bell" class="event-container-icon-reminder" [style.color]="event.color || (event.calendarId | calendarColor | async)"></mat-icon>
    <div class="event-title" [class.with-linked-info]="linkedInfoSuspect || event.linkedInfo?.length > 0">{{ event.title || '(No Title)' }}{{showTime ? ',' : ''}}</div>
    <div class="event-start-time" *ngIf="showTime"> {{ event.when.start | date: "shortTime" | lowercase }}</div>
    <div class="event-start-time" *ngIf="rowsCount > 1 && (view === 'week' || view === 'workWeek' || view === 'day') ">
      {{ event.when.start | date: "shortTime" | lowercase }} - {{ event.when.end | date: "shortTime" | lowercase }}
    </div>

    <mat-icon class="event-link-icon" svgIcon="link-bold" *ngIf="linkedInfoSuspect || event.linkedInfo?.length > 0"></mat-icon>
  </div>
</div>

<ng-template #detailsView>
  <event-popover-preview [event]="event"></event-popover-preview>
</ng-template>

<ng-template #draggablePlaceholder>
  <app-event-badge [view]="view" [event]="event" [showTime]="showTime" [dragEnabled]="false"></app-event-badge>
</ng-template>

<ng-template #popoverFormTemplate>
  <app-event-form-popover
    [(event)]="event"
    (close)="handlePopoverFormClose()"
  ></app-event-form-popover>
</ng-template>
