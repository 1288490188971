import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuard } from '../auth/services/auth-guard.service';
import { PopupGuardService } from './services/popup-guard.service';

// Components
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';
import { MailContentPopupComponent } from './components/mail-content-popup/mail-content-popup.component';
import { MailEditorPopupComponent } from './components/mail-editor-popup/mail-editor-popup.component';
import { AnnotationsPopupComponent } from './components/annotations-popup/annotations-popup.component';
import { CalendarEventFormPopupComponent } from './components/calendar-event-form-popup/calendar-event-form-popup.component';
import { TaskFormPopupComponent } from './components/task-form-popup/task-form-popup.component';
import { NoteFormPopupComponent } from './components/note-form-popup/note-form-popup.component';

const popupRoutes: Routes = [
  {
    path: 'compact', canActivate: [AuthGuard], canDeactivate: [PopupGuardService], component: PopupWrapperComponent, children: [
      { path: 'mail/message/:id', canActivate: [AuthGuard], component: MailContentPopupComponent },
      { path: 'mail/message/:id/annotations', canActivate: [AuthGuard], component: AnnotationsPopupComponent },
      { path: 'mail/compose', canActivate: [AuthGuard], component: MailEditorPopupComponent },
      { path: 'event/:id/edit', canActivate: [AuthGuard], component: CalendarEventFormPopupComponent },
      { path: 'task/:id/edit', canActivate: [AuthGuard], component: TaskFormPopupComponent },
      { path: 'note/:id/edit', canActivate: [AuthGuard], component: NoteFormPopupComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(popupRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PopupRoutingModule { }
