import * as tslib_1 from "tslib";
// Rx
import { combineLatest, merge } from 'rxjs';
import { map, switchMap, startWith, tap } from 'rxjs/operators';
// Types
import { SectionsTree } from '../types/sections-tree';
var SectionsTreeService = /** @class */ (function () {
    /**
     * Constructor
     */
    function SectionsTreeService(sectionsService, tasksStateService, tasksService) {
        this.sectionsService = sectionsService;
        this.tasksStateService = tasksStateService;
        this.tasksService = tasksService;
    }
    /**
     * Methods
     */
    SectionsTreeService.prototype.getSections = function (projectId) {
        var _this = this;
        return merge(this.sectionsService.sectionCreated, this.sectionsService.sectionUpdated, this.sectionsService.sectionDeleted, this.tasksService.taskCreated, this.tasksService.taskUpdated, this.tasksService.taskDeleted, this.tasksStateService.getRefreshAll())
            .pipe(startWith(null), switchMap(function () { return (combineLatest([
            _this.sectionsService.getSections({ projectsIds: [projectId] }),
            _this.tasksService.getTasks({ projectsIds: [projectId] })
        ])); }), map(function (_a) {
            var _b = tslib_1.__read(_a, 2), sections = _b[0].sections, tasks = _b[1].tasks;
            return (new SectionsTree({ projectId: projectId, sections: sections, tasks: tasks }));
        }));
    };
    SectionsTreeService.prototype.saveSectionsOrder = function (_a, newSectionId) {
        var tree = _a.tree;
        var filteredSections = tree.map(function (section) { return section.id === 'temp' ? newSectionId : section.id; });
        if (filteredSections.length === 0) {
            return;
        }
        return this.sectionsService.reorder(filteredSections);
    };
    SectionsTreeService.prototype.saveSubtasksOrder = function (sectionId, parentId, subtasks, projectId, columnId) {
        this.tasksService.reorder(sectionId, SectionsTree.sortByPosition(subtasks, columnId).map(function (task) { return task.id; }), projectId, columnId, parentId);
    };
    SectionsTreeService.prototype.saveTasksOrder = function (sectionId, tasks, projectId, columnId) {
        var filteredTasks = tasks.filter(function (task) { return !task.temp; });
        if (filteredTasks.length === 0) {
            return;
        }
        this.tasksService.reorder(sectionId, filteredTasks.map(function (task) { return task.id; }), projectId, columnId);
    };
    SectionsTreeService.prototype.submitTask = function (_a, task) {
        var _this = this;
        var tree = _a.tree;
        var section = tree.find(function (item) { return item.id === task.sectionId; });
        this.tasksService.create(task)
            .subscribe(function () {
            if (section) {
                _this.saveTasksOrder(section.id, section.tasks);
            }
        });
    };
    SectionsTreeService.prototype.submitSection = function (tree, sectionInstance) {
        var _this = this;
        return this.sectionsService.create(sectionInstance)
            .pipe(tap(function (section) {
            _this.saveSectionsOrder(tree, section.id);
            _this.saveTasksOrder(section.id, sectionInstance.tasks);
        }));
    };
    SectionsTreeService.prototype.moveTask = function (tree, task, sectionInstance, index) {
        tree.moveTask(task, sectionInstance, index);
        var section = tree.tree.find(function (item) { return item.id === sectionInstance.id; });
        this.saveTasksOrder(section.id, section.tasks, tree.projectId, tree.columnId);
    };
    SectionsTreeService.prototype.moveSubtask = function (tree, subtask, taskInstance, index) {
        tree.moveSubtask(subtask, taskInstance, index);
        var section = tree.tree.find(function (item) { return item.id === taskInstance.sectionId; });
        var task = section.tasks.find(function (item) { return item.id === taskInstance.id; });
        this.saveSubtasksOrder(section.id, task.id, task.subtasks, tree.projectId, tree.columnId);
    };
    SectionsTreeService.prototype.moveSection = function (tree, section, direction) {
        tree.moveSection(section, direction);
        this.saveSectionsOrder(tree);
    };
    return SectionsTreeService;
}());
export { SectionsTreeService };
