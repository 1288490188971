<app-link-info-suggestions
  [inputFormControl]="inputFormControl"
  [appearance]="appearance"
  title="Link To Message"
  [createTitle]="'Create New Message'"
  [items]="suggestions"
  [itemTemplateRef]="suggestionsItem"
  (close)="closed()"
  (create)="creating()"
>
</app-link-info-suggestions>

<ng-template #suggestionsItem let-message="item">
  <app-message
    [message]="message"
    (selectMessage)="selectedLinkedInfo({type: 'message', id: message.id})"
    [actionsButtonEnabled]="false"
    [threadEnabled]="false">
  </app-message>
</ng-template>
