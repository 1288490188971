/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-wrapper.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./modal-wrapper.component";
import * as i6 from "@angular/cdk/drag-drop";
var styles_ModalWrapperComponent = [i0.styles];
var RenderType_ModalWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalWrapperComponent, data: {} });
export { RenderType_ModalWrapperComponent as RenderType_ModalWrapperComponent };
function View_ModalWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "resize-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "resize"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.resize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "resize"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ModalWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalAnchor: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "modal--minimized": 0 }), (_l()(), i1.ɵand(16777216, [[1, 3], ["modalAnchor", 2]], null, 0, null, View_ModalWrapperComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalWrapperComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal-content"; var currVal_1 = _ck(_v, 4, 0, _co.minimized); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (!_co.disabledResize && !_co.minimized); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ModalWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-wrapper", [], null, null, null, View_ModalWrapperComponent_0, RenderType_ModalWrapperComponent)), i1.ɵdid(1, 245760, null, 0, i5.ModalWrapperComponent, [i1.ComponentFactoryResolver, i1.ElementRef, i1.Renderer2, i1.NgZone, i6.DragDrop, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalWrapperComponentNgFactory = i1.ɵccf("app-modal-wrapper", i5.ModalWrapperComponent, View_ModalWrapperComponent_Host_0, {}, { closed: "closed", collapsed: "collapsed" }, []);
export { ModalWrapperComponentNgFactory as ModalWrapperComponentNgFactory };
