// Common
import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';

@Component({
  selector: 'app-contact-quick-form',
  templateUrl: './contact-quick-form.component.html',
  styleUrls: ['./contact-quick-form.component.less'],
})
export class ContactQuickFormComponent implements OnDestroy, OnChanges {

  // Inputs
  @Input() bundledGroup: string;
  @Input() contact: Contact = new Contact();

  // Outputs
  @Output() more: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Contact> = new EventEmitter();

  // Public
  public contactForm: FormGroup;
  public saveInProgress = false;

  // Private
  private alive = new Subject<void>();
  private selectedGroup: Group;

  /**
   * Constructor
   */

  constructor (
    private contactsService: ContactsService,
    private contactsStateService: ContactsStateService,
  ) {
    this.contactsStateService.getGroup()
      .pipe(takeUntil(this.alive))
      .subscribe((group: Group) => this.selectedGroup = group);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('contact' in changes) {
      this.reset();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    this.populateGroupId();
    if (!this.contactForm.valid) {
      return;
    }

    if (this.save.observers.length > 0) {
      const contact = Contact.fromFormGroup(this.contactForm);
      this.save.emit(contact);
      return;
    }

    this.saveInProgress = true;

    this.contactsService.upsert(this.contactForm)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleError() {
    this.saveInProgress = false;
  }

  reset() {
    if (!this.contact) { return; }

    this.contactForm = this.contact.asFormGroup();
    this.saveInProgress = false;
  }

  handleMoreOptions() {
    this.populateGroupId();
    this.more.emit(Contact.fromFormGroup(this.contactForm));
    this.close.emit();
  }

  handleClickCancel() {
    this.close.emit();
  }

  populateGroupId() {
    if (!this.contactForm.get('groupId').value) {
      this.contactForm.patchValue({ groupId: this.selectedGroup && this.selectedGroup.id });
    }
  }
}
