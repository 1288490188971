// Common
import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of as createObservableOf, Subject, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { SearchService } from '../../../../search/services/search.service';

// Types
import { Recipient } from '../../../types/recipient';
import { SearchSuggestions } from '../../../../search/types/search-suggestions';

@Component({
  selector: 'app-recipients-input',
  templateUrl: './recipients-input.component.html',
  styleUrls: ['./recipients-input.component.less']
})
export class RecipientsInputComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() recipients: Recipient[];

  // Outputs
  @Output() recipientsChange = new EventEmitter<Recipient[]>();

  // Public
  public contacts: Observable<Recipient[]>;
  public searchQuery = new FormControl();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(private searchService: SearchService) {
    this.contacts = this.searchQuery.valueChanges.pipe(
      takeUntil(this.componentNotDestroyed),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((query: string) => {
        if (query && query.length) {
          return this.searchService.searchSuggestions(query, ['contacts']);
        }
        return createObservableOf({contacts: []} as SearchSuggestions);
      }),
      map((result: SearchSuggestions) => result.contacts as Recipient[])
    );
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  inputBlur() {
  }

  autocompleteContactSelected(contact: Recipient) {
    if (!this.recipients) {
      this.recipients = [];
    }
    this.recipients.push(contact);
    this.recipientsChange.emit(this.recipients);
    this.searchQuery.setValue(null);
  }

  removeRecipient(recipient: Recipient) {
    const index = this.recipients.indexOf(recipient);
    if (index !== -1) {
      this.recipients.splice(index, 1);
    }
    this.recipientsChange.emit(this.recipients);
  }

}
