import {
  Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter,
  SimpleChanges, OnChanges, TemplateRef, ElementRef
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { isSameDay } from 'date-fns';

// RX
import { Subject } from 'rxjs';

// Types
import { CalendarEvent as AngularCalendarEvent, CalendarDateFormatter, DateFormatterParams, CalendarMonthViewDay } from 'angular-calendar';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';

// Animations
import { slideAnimation } from '@modules/events/animations/slide-animation';

// Override month header week names formatter
export class CalendarMonthDateFormatter extends CalendarDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'E', locale);
  }
}

@Component({
  selector: 'full-calendar-month',
  templateUrl: './full-calendar-month.component.html',
  styleUrls: [ './full-calendar-month.component.less' ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarMonthDateFormatter
    },
  ],
  animations: [slideAnimation]
})

export class FullCalendarMonthComponent implements OnChanges {

  // Inputs
  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() events: AngularCalendarEvent[];
  @Input() badgeTemplate: TemplateRef<any>;
  @Input() highlightedDay: Date;

  // Outputs
  @Output() dateClicked: EventEmitter<CalendarCellClickEvent> = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dayNumberDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() eventDropped: EventEmitter<CalendarDropEvent> = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  // Public
  public displayDate: Date;
  public refreshCalendar: Subject<any> = new Subject();

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate) {
      if (changes.viewDate.firstChange || this.monthDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
        this.displayDate = this.viewDate;
      }
    }
    if (changes.highlightedDay || changes.selectedDate) {
      this.refreshCalendar.next();
    }
  }

  /**
   *  Angular Calendar
   */

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (this.highlightedDay && isSameDay(day.date, this.highlightedDay)) {
        day.cssClass += ' cal-day-cell-highlighted';
      }
      if (this.selectedDate && isSameDay(this.selectedDate, day.date)) {
        day.cssClass += ' cal-day-cell-selected';
      }
    });
  }

  selectCalendarDate(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.dateClicked.emit({date, originRef: new ElementRef(origin), event});
  }

  dblClickCalendarDate(date: Date) {
    this.dateDblClicked.emit(date);
  }

  dblClickDay(date: Date) {
    this.dayNumberDblClicked.emit(date);
  }

  handleDrop(dragData: DragData, day: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart: this.beginningOfDay(day),
      newEnd: this.endOfDay(day),
      originRef: new ElementRef(origin)
    });
  }

  /**
   * Helpers
   */

  private beginningOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }

  private endOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  private monthDiffers(date1: Date, date2: Date) {
    return date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
  }

  /**
   * Actions
   */

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }
}
