// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

// Modules
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ContentMenuModule } from '../content-menu/content-menu.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { ContextMenuModule } from '@modules/context-menu/context-menu.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { TopicModule } from '@modules/topic/topic.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PopoverModule } from '@modules/popover/popover.module';
import { ElementsModule } from '../elements/elements.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { TagModule } from '@modules/tag/tag.module';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';

// Components
import { EventCalendarComponent } from './components/event-calendar/event-calendar.component';
import { EventsSideBarComponent } from './components/side-bar/side-bar.component';
import { FullCalendarModule } from '../full-calendar/full-calendar.module';
import { EventsListComponent } from './components/events-list/events-list.component';
import { EventsAgendaContextMenuComponent } from './components/events-agenda-context-menu/events-agenda-context-menu.component';
import { EventFormComponent } from './components/event-form/event-form.component';
import { EventsListHeaderComponent } from './components/events-list-header/events-list-header.component';
import { EventsListContainerComponent } from './components/events-list-container/events-list-container.component';
import {
  RepeaterDialogComponent
} from '../form-controls/components/schedule-repater-dropdown/custom-repeater-dialog/custom-repeater-dialog.component';
import { CalendarFormComponent } from './components/calendar-form/calendar-form.component';
import { ConfirmationFormComponent } from './components/confirmation-form/confirmation-form.component';
import { EventsAgendaComponent } from './components/events-agenda/events-agenda.component';
import { EventMainViewComponent } from './components/events-main-view/events-main-view.component';
import { CalendarContextMenuComponent } from './components/calendar-context-menu/calendar-context-menu.component';
import { EventBadgeComponent } from './components/event-badge/event-badge.component';
import { EventQuickPreviewComponent } from './components/events-quick-preview/events-quick-preview.component';
import { EventsAgendaEventComponent } from './components/events-agenda-event/events-agenda-event.component';
import { EventsDatePickerComponent } from './components/form-controls/events-date-picker/events-date-picker.component';
import { EventFormPopoverComponent } from './components/event-form-popover/event-form-popover.component';
import { EventTopicsComponent } from './components/form-controls/event-topics/event-topics.component';

// Directives
import { EventsAgendaContextMenuDirective } from './directives/events-agenda-context-menu-directive/events-agenda-context-menu.directive';
import { CalendarContextMenuDirective } from './directives/calendar-context-menu-directive/calendar-context-menu.directive';
import { CalendarEventTitleFormatter } from 'angular-calendar';
import { HideTooltipEventTitleFormatter } from '@modules/full-calendar/providers/hide-tooltip-event-title-formatter.provider';

// Services
import { EventsStateService } from './services/events-state.service';
import { CalendarService } from './services/calendar.service';

// Pipes
import { IsEventCutPipe } from './pipes/is-event-cut.pipe';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppCommonModule,
    ContentMenuModule,
    UserAvatarModule,
    FullCalendarModule,
    MatIconModule,
    MatTooltipModule,
    NgxContextMenuModule.forRoot(),
    ContextMenuModule,
    ReactiveFormsModule,
    FormControlsModule,
    TopicModule,
    DropdownModule,
    ScrollingModule,
    ElementsModule,
    LinkedInfoModule,
    PopoverModule,
    DragNDropModule,
    TagModule,
    QuickFormsModule,
    ScrollModule,
    PipesModule,
    AttachmentsModule,
  ],
  exports: [
    EventFormComponent,
    EventsSideBarComponent,
    EventsListComponent,
    EventsListHeaderComponent,
    EventsListContainerComponent,
    EventMainViewComponent,
    EventBadgeComponent,
    EventQuickPreviewComponent,
    EventBadgeComponent,
    EventsDatePickerComponent,
    EventFormPopoverComponent,
  ],
  declarations: [
    EventsSideBarComponent,
    EventCalendarComponent,
    EventsListComponent,
    EventFormComponent,
    EventsAgendaContextMenuComponent,
    EventsListHeaderComponent,
    EventsListContainerComponent,
    EventsAgendaComponent,
    EventMainViewComponent,
    CalendarContextMenuComponent,
    CalendarFormComponent,
    ConfirmationFormComponent,
    EventBadgeComponent,
    EventQuickPreviewComponent,
    EventsAgendaEventComponent,
    EventsDatePickerComponent,
    EventFormPopoverComponent,
    EventTopicsComponent,

    EventsAgendaContextMenuDirective,
    CalendarContextMenuDirective,

    IsEventCutPipe,
  ],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: HideTooltipEventTitleFormatter
    },
    EventsStateService,
    CalendarService,
    IsEventCutPipe,
  ],
  entryComponents: [
    RepeaterDialogComponent,
    EventsAgendaContextMenuComponent,
    CalendarContextMenuComponent,
  ],
})
export class EventsModule { }
