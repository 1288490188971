import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { MailService } from '../../services/mail.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { StateService } from '@modules/settings/services/state.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

// Types
import { MailFolder } from '../../types/mail-folder';
import { MailMessage } from '../../types/mail-message';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

@Component({
  selector: 'app-mail-side-bar',
  templateUrl: './mail-side-bar.component.html',
  styleUrls: ['./mail-side-bar.component.less'],
  providers: [ContentMenuService]
})

export class MailSideBarComponent implements OnInit, OnDestroy {

  // ViewChild
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;

  // ViewChildren
  @ViewChild('container', { static: false }) container: ElementRef;

  // Inputs
  @Input() baseRouter = '/mail';

  // Public
  public loading = false;
  public folders: MailFolder[] = [];
  public defaultFolders: MailFolder[];
  public userFolders: MailFolder[];
  public userFoldersState: string[];
  public selectedFolderId: string;
  public selectedFolder: MailFolder;
  public isUserFolderExpanded: boolean;
  public dragHoverFolderId: string;

  // Private
  private defaultFolderIds: string[] = MailService.defaultFolderIds;
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Static methods
   */

  static recursiveFindFoldersTree(folders: MailFolder[], folderId: string): MailFolder[] | undefined {
    for (const folder of folders) {
      if (folder.id === folderId) {
        return [folder];
      }
      if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
        const foundFolders = this.recursiveFindFoldersTree(folder.subFolders, folderId);
        if (foundFolders !== undefined) {
          foundFolders.push(folder);
          return foundFolders;
        }
      }
    }
  }

  /**
   * Constructor
   */

  constructor (
    private mailService: MailService,
    private router: Router,
    private ga: GoogleAnalyticsService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private splitViewService: SplitViewService,
    contentMenuService: ContentMenuService
  ) {
    this.splitViewService.getMinimized('mailsSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.isUserFolderExpanded = this.stateService.isUserFolderExpanded;
    this.userFoldersState = this.stateService.userFoldersState;
    this.route.params
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((params: Params) => {
        this.selectedFolderId = params['folder'];
        if ((this.selectedFolderId !== undefined) && (this.folders !== undefined)) {
          this.openFolderWithSubfolders(this.selectedFolderId);
          this.selectedFolder = this.findFolder(this.folders, this.selectedFolderId);
        }
      });

    this.getFolders();
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }


  /**
   * Methods
   */

  private filterDefaultFolders(folders: MailFolder[]): MailFolder[] {
    return folders
      .filter(item => this.defaultFolderIds.some(id => item.id === id))
      .sort((a, b) => this.defaultFolderIds.indexOf(a.id) - this.defaultFolderIds.indexOf(b.id));
  }

  private filterUserFolders(folders: MailFolder[]): MailFolder[] {
    return folders.filter(item => !this.defaultFolderIds.some(id => item.id === id));
  }

  private findFolder(folders: MailFolder[], id: string): MailFolder | undefined {
    const recursiveFindFolder = ((folderList: MailFolder[]): MailFolder | undefined => {
      for (const folder of folderList) {
        if (folder.id === id) {
          return folder;
        }
        if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
          const result = recursiveFindFolder(folder.subFolders);
          if (result !== undefined) {
            return result;
          }
        }
      }
    });
    return recursiveFindFolder(folders);
  }

  getFolders() {
    this.loading = true;
    this.mailService.getFolders()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(folders => {
        this.loading = false;
        this.folders = folders;
        this.defaultFolders = this.filterDefaultFolders(this.folders);
        this.userFolders = this.filterUserFolders(this.folders);
        if (this.selectedFolderId) {
          this.openFolderWithSubfolders(this.selectedFolderId);
          this.selectedFolder = this.findFolder(this.folders, this.selectedFolderId);
        }
     });
  }

  /**
   * Actions
   */

  createFolder($event?: MouseEvent) {
    if ($event !== undefined) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    this.ga.trackEvent('side-menu', 'create-folder');

    const folderName = prompt('Enter new folder name:');
    if (!folderName) {
      return;
    }

    this.mailService.createFolder(folderName)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe();
  }

  openFolder(folder: MailFolder, event: MouseEvent): void {
    event.stopPropagation();
    if (folder.hasOwnProperty('subFolders')) {
      this.stateService.toggleUserFolderState(folder.folderId);
      this.userFoldersState = this.stateService.userFoldersState;
    }
  }

  openFolderWithSubfolders(folderId: string) {
    if (this.userFolders === undefined) {
      return;
    }

    const foundFolders = MailSideBarComponent.recursiveFindFoldersTree(this.userFolders, folderId);

    if (foundFolders !== undefined) {
      foundFolders.forEach(folder => {
        folder['open'] = true;
        folder.icon = 'folder-open';
      });
    }
  }

  renameFolder(folder: MailFolder): void {
    const folderName = prompt('Enter new folder name:', folder.name);
    if (!folderName || folder.name === folderName) {
      return;
    }

    this.mailService.renameFolder(folder.folderId, folderName)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe();
  }

  deleteFolder(folder: MailFolder) {
    if (!confirm('Are you sure you want to delete the folder?')) {
      return;
    }

    this.mailService.deleteFolder(folder.folderId)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe();
  }

  readAllMessages(folder: MailFolder) {
    this.mailService.updateMessagesUnreadStatus({folder: folder.folderId});
  }

  pinFolder(folder: MailFolder) {
    this.mailService.pinFolder([folder], !folder.pinned)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe();
  }

  moveMessages(messages: MailMessage[], folderId: string) {
    switch (folderId) {
      case 'important':
        this.ga.trackEvent('side-menu', 'star');
        break;
      case 'spam':
        this.ga.trackEvent('side-menu', 'move-to-spam');
        break;
      case 'trash':
        this.ga.trackEvent('side-menu', 'delete');
        break;
      default:
        break;
    }
    if (messages.some(msg => !!msg.threadMessages)) {
      this.modalService.confirmationModal(
        'Selected message(s) are part of the thread. Would you like to move all messages in the thread?',
        'Move Message(s)',
        'Yes',
        'No',
        (withThread: boolean) => {
          this.mailService.moveMessages({
            messages,
            filters: {
              threadIds: withThread ? messages.map(message => message.thread) : null
            }
          }, [folderId]);
        });
    } else {
      this.mailService.moveMessages({ messages }, [folderId]);
    }
  }

  toggleUserFolder() {
    this.stateService.isUserFolderExpanded = !this.stateService.isUserFolderExpanded;
    this.isUserFolderExpanded = this.stateService.isUserFolderExpanded;
  }

  openAccountSettings() {
    this.modalService.settings();
  }

  deleteAll(folder: MailFolder) {
    this.mailService
      .deleteMessages({ folder: folder.folderId })
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        if (this.selectedFolder.folderId === folder.folderId) {
          this.mailService.doRefreshMailList();
        }
      });
  }

  openFolderInExploreScreen(folder: MailFolder) {
    this.router.navigate(['/insights', folder.id]);
  }

  /**
   * Drag and drop
   */

  allowDropFolder({ id }: MailFolder): Function {
    return (): boolean => !MailService.disallowMoveFolderIds.includes(id);
  }

  dndLeave({ id }: MailFolder) {
    if (this.dragHoverFolderId === id) {
      this.dragHoverFolderId = null;
    }
  }

  dndEnter(dragData: DragData, { id }: MailFolder) {
    if (dragData.type === 'message' || dragData.type === 'topic') {
      this.dragHoverFolderId = id;
    }
  }

  dndDrop(dragData: DragData, folder: MailFolder) {
    const allowFolder = this.allowDropFolder(folder);
    if (folder.id && allowFolder()) {
      // Message
      if (dragData.type === 'message') {
        const messages = dragData.data as MailMessage[];
        this.moveMessages(messages, folder.id);
        this.dragHoverFolderId = null;
      }
      // Topic
      if (dragData.type === 'topic' && dragData.data) {
        const topic = dragData.data[0] as string;
        this.modalService.confirmationModal(
          `Are you sure want to move messages relater to the topic '${topic}'?`,
          'Move Message(s)',
          'Yes',
          'No',
          (confirm: boolean) => {
            if (confirm) {
              this.mailService.moveMessages({ filters: { topics: [topic] } }, [folder.id]);
            }
          }
        );
      }
    }
  }

  foldersTrackByFunction(index, folder) {
    return folder.id;
  }
}
