import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Rx
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
// Types
import { File } from '@modules/files/types/file';
var FileFormComponent = /** @class */ (function () {
    // Constructor
    function FileFormComponent(dynamicPanelService, tasksService, eventsService, mailsService, notesService) {
        var _this = this;
        this.dynamicPanelService = dynamicPanelService;
        this.tasksService = tasksService;
        this.eventsService = eventsService;
        this.mailsService = mailsService;
        this.notesService = notesService;
        // Inputs
        this.file = new File();
        // Outputs
        this.close = new EventEmitter();
        // Public
        this.fileForm = this.file.asFormGroup();
        this.componentNotDestroyed = new Subject();
        this.dynamicPanelService.getContent()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var type = _a.type;
            return _this.dynamicPanel = type;
        });
    }
    // LifeCycle
    FileFormComponent.prototype.ngOnInit = function () {
        this.fileForm = this.file.asFormGroup();
    };
    FileFormComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if ('file' in changes && this.file) {
            this.fileForm = this.file.asFormGroup();
            this.dynamicPanelService.setContent();
            this.linkedInfo = [];
            combineLatest([
                this.tasksService.getTasks({ limit: 2, offset: this.getRandomNumber() }),
                this.eventsService.getEvents({ limit: 1, offset: this.getRandomNumber() }),
                this.mailsService.getMessages({ folder: 'inbox' }, 'ASC', 3, this.getRandomNumber()),
                this.notesService.getNotes({ limit: 2, offset: this.getRandomNumber() })
            ])
                .pipe(map(function (_a) {
                var _b = tslib_1.__read(_a, 4), tasks = _b[0].tasks, events = _b[1].events, messages = _b[2].messages, notes = _b[3].notes;
                return tslib_1.__spread(_this.transformData(tasks, 'task'), _this.transformData(events, 'event'), _this.transformData(messages, 'message'), _this.transformData(notes, 'note'));
            }), takeUntil(this.componentNotDestroyed))
                .subscribe(function (linkedInfo) { return _this.linkedInfo = linkedInfo; });
        }
    };
    // Actions
    FileFormComponent.prototype.handleCancel = function () {
        this.close.emit();
    };
    FileFormComponent.prototype.openAttachmentsDynamicPanel = function () {
        if (this.dynamicPanel === 'attachments') {
            this.dynamicPanelService.setContent();
        }
        else {
            this.dynamicPanelService.setContent('attachments', { attachments: [], parentTitle: this.file.title });
        }
    };
    FileFormComponent.prototype.openLinkedInfoDynamicPanel = function () {
        if (this.dynamicPanel === 'linkedInfo') {
            this.dynamicPanelService.setContent();
        }
        else {
            this.dynamicPanelService.setContent('linkedInfo', {
                linkedInfo: this.linkedInfo,
                parentLinkedInfo: { type: 'task', id: this.file.id },
                parentTitle: this.file.title
            });
        }
    };
    // Helpers
    FileFormComponent.prototype.transformData = function (data, type) {
        return data.map(function (item) { return ({ id: item.id, type: type, createdAt: item.createdAt }); });
    };
    FileFormComponent.prototype.getRandomNumber = function () {
        return Math.floor(Math.random() * 10);
    };
    return FileFormComponent;
}());
export { FileFormComponent };
