// Common
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Task } from '@modules/tasks/types/task';
import { SectionsTree } from '@modules/tasks/types/sections-tree';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { SectionsTreeService } from '@modules/tasks/services/sections-tree.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';

@Component({
  selector: 'app-project-section-task',
  templateUrl: './section-task.component.html',
  styleUrls: ['./section-task.component.less'],
})
export class SectionTaskComponent implements OnInit {

  // Public
  public repositionStream: Subject<DragData> = new Subject<null>();

  // Private
  private alive = new Subject<void>();

  // Inputs
  @Input() task: Task;
  @Input() subtask = false;
  @Input() sectionsTree: SectionsTree;
  @Input() appearance: 'board' | 'default' = 'default';
  @Input() dragEnabled = true;
  @Input() allowedNewTasksSections = true;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => ['message', 'event', 'project', 'note', 'topic', 'tag'].includes(dragData.type);
  public reorderPredicate = (dragData: DragData): boolean => dragData.type === 'task';

  /**
   * Constructor
   */

  constructor (
    private tasksService: TasksService,
    private sectionsTreeService: SectionsTreeService,
    private linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.repositionStream.subscribe((data: DragData) => {
      this.sectionsTreeService.moveSubtask(this.sectionsTree, data.data[0] as Task, this.task, data.index);
    });
  }

  /**
   * Actions
   */

  pin() {
    this.tasksService.pin([this.task.id], !this.task.pinned)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.pinned = tasks[0].pinned;
        }
      });
  }

  archive() {
    this.tasksService.archive([this.task.id], !this.task.archived)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.archived = tasks[0].archived;
        }
      });
  }

  delete() {
    (
      this.task.deleted ?
        this.tasksService.deletePermanently([this.task.id]) :
        this.tasksService.delete([this.task.id], true)
    )
      .subscribe(() => {
        this.task.deleted = true;
      });
  }

  handleSubmitTask(task: Task) {
    this.sectionsTreeService.submitTask(this.sectionsTree, task);
  }

  handleNewSection() {
    this.sectionsTree.insertSection(this.task);
  }

  handleNewTask() {
    this.sectionsTree.insertTask(this.task);
  }

  dndDrop(dragData: DragData) {
    if (!dragData.data) { return; }

    if (['message', 'event', 'project', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('task', this.task.id), linkedItems);
    }

    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {tasksIds: [this.task.id]});
    }

    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {tasksIds: [this.task.id]});
    }
  }

  /**
   * Helpers
   */

  tasksTrackByFn(task: Task): string {
    return task && task.id;
  }
}
