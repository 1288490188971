import { publish } from 'rxjs/operators';
export function warmUpObservable(target, key, descriptor) {
    var origin = descriptor.value;
    descriptor.value = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var hotObservable = origin.apply(this, args).pipe(publish());
        hotObservable.connect();
        return hotObservable;
    };
    return descriptor;
}
