// RxJS
import { of, merge, timer } from 'rxjs';
import { tap, catchError, map, take, delayWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./async-tasks.service";
import * as i2 from "ngx-toastr";
var AsyncTasksToastsService = /** @class */ (function () {
    function AsyncTasksToastsService(asyncTasksService, toastrService) {
        this.asyncTasksService = asyncTasksService;
        this.toastrService = toastrService;
    }
    AsyncTasksToastsService.prototype.getToastSettings = function (status, settings) {
        return {
            text: settings.status[status].text,
            icon: settings.status[status].icon,
            actions: (settings.status[status].actions || []).reduce(function (actions, actionName) {
                if (settings.actions && settings.actions[actionName]) {
                    actions.push(settings.actions[actionName]);
                }
                return actions;
            }, [])
        };
    };
    AsyncTasksToastsService.prototype.show = function (settings, disableTimeOut) {
        if (disableTimeOut === void 0) { disableTimeOut = false; }
        var toast = this.toastrService.show(settings.text, null, 
        // Temp solution due to a bug in latest version
        // TODO: remove once PR is merged: https://github.com/scttcper/ngx-toastr/pull/723
        { disableTimeOut: disableTimeOut ? 'timeOut' : false });
        toast.toastRef.componentInstance.setActions(settings.actions);
        toast.toastRef.componentInstance.setIcon(settings.icon);
        return toast;
    };
    AsyncTasksToastsService.prototype.showAwaitProgress = function (asyncTask, settings) {
        var _this = this;
        if (!asyncTask || asyncTask.status === 'completed') {
            this.show(this.getToastSettings('completed', settings));
            return of(true);
        }
        if (asyncTask.status === 'error') {
            this.show(this.getToastSettings('error', settings));
            return of(false);
        }
        var toast = this.show(this.getToastSettings('processing', settings), true);
        var delayUntil = new Date().getTime() + 1000;
        return merge(toast.onAction.pipe(map(function () { return false; })), this.asyncTasksService
            .await(asyncTask)
            .pipe(delayWhen(function () { return timer(delayUntil - new Date().getTime()); }), catchError(function () { return of(false); }), tap(function (success) {
            _this.toastrService.remove(toast.toastId);
            _this.show(_this.getToastSettings(success ? 'completed' : 'error', settings));
        })))
            .pipe(take(1));
    };
    AsyncTasksToastsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AsyncTasksToastsService_Factory() { return new AsyncTasksToastsService(i0.ɵɵinject(i1.AsyncTasksService), i0.ɵɵinject(i2.ToastrService)); }, token: AsyncTasksToastsService, providedIn: "root" });
    return AsyncTasksToastsService;
}());
export { AsyncTasksToastsService };
