<div class="task-form">
  <form [formGroup]="taskForm" (ngSubmit)="handleSubmit()">

    <div class="task-form-horizontal-group-full-width-item">
      <app-input
        placeholder="Title"
        appearance="standard"
        icon="tab-tasks"
        [inputFormControl]="taskForm.controls['title']"
        [bundledInputConsumerGroup]="bundledGroup"
        [bundledInputConsumerKeys]="['title']"
      ></app-input>
    </div>

    <div
      class="task-form-horizontal-group"
      *ngIf="taskForm.controls['fromDate'].value || taskForm.controls['fromTime'].value ||
             taskForm.controls['toDate'].value || taskForm.controls['toTime'].value"
    >
      <app-date-time
        [startDate]="taskForm.controls['fromDate'].value"
        [startTime]="taskForm.controls['fromTime'].value"
        [endDate]="taskForm.controls['toDate'].value"
        [endTime]="taskForm.controls['toTime'].value"
      ></app-date-time>
    </div>

    <div class="task-form-horizontal-group">
      <app-quick-actions
        [startDateFormControl]="taskForm.controls['fromDate']"
        [startTimeFormControl]="taskForm.controls['fromTime']"
        [endDateFormControl]="taskForm.controls['toDate']"
        [endTimeFormControl]="taskForm.controls['toTime']"
        [priorityFormControl]="taskForm.controls['priority']"
      ></app-quick-actions>
    </div>

    <div class="task-form-buttons">
      <app-button
        class="task-form-buttons-item"
        appearance="link-blue"
        (click)="handleMoreOptions()"
      >
        More Options
      </app-button>

      <div class="task-form-buttons-container">
        <app-button
          class="task-form-buttons-item"
          appearance="link"
          [disabled]="saveInProgress"
          (click)="handleClickCancel()"
        >
          Cancel
        </app-button>

        <app-button
          class="task-form-buttons-item"
          type="submit"
          appearance="link-blue"
          [disabled]="saveInProgress"
        >
          Save
        </app-button>
      </div>
    </div>

  </form>
</div>
