<div class="tooltipContainer">
  <div class="date">
    <div class="day-number">{{ day | date: "dd" }}</div>
    <div class="day-title">{{ day | date: "EEEE" }}</div>
  </div>

  <div class="events" *ngFor="let event of events">
    <app-event-badge [event]="event.meta" [showTime]="true" view="year"></app-event-badge>
  </div>
</div>
