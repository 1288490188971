import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ChangeDetectorRef, TemplateRef, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, interval, fromEvent, merge, timer } from 'rxjs';
import { CalendarDateFormatter } from 'angular-calendar';
import { isSameDay, startOfDay } from 'date-fns';
import { debounceTime, takeUntil } from 'rxjs/operators';
// Override week time formatter
var CalendarWeekDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarWeekDateFormatter, _super);
    function CalendarWeekDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CalendarWeekDateFormatter.prototype.weekViewHour = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date).toLowerCase();
    };
    CalendarWeekDateFormatter.prototype.weekViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return "" + date.getDate();
    };
    CalendarWeekDateFormatter.prototype.weekViewColumnSubHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new DatePipe(locale).transform(date, 'E', locale);
    };
    return CalendarWeekDateFormatter;
}(CalendarDateFormatter));
export { CalendarWeekDateFormatter };
var FullCalendarWeekComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FullCalendarWeekComponent(element, ngZone, changeDetector, stateService) {
        var _this = this;
        this.element = element;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.stateService = stateService;
        // Outputs
        this.dateClicked = new EventEmitter();
        this.dateDblClicked = new EventEmitter();
        this.dayNumberDblClicked = new EventEmitter();
        this.eventDropped = new EventEmitter();
        this.loadDayEvents = new EventEmitter();
        this.excludeDays = [];
        this.refreshCalendar = new Subject();
        this.timeZoneName = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        this.currentTimeLineOffset = 0;
        this.currentTime = new Date();
        this.hourSegmentHeight = 48;
        this.weekStartsOn = 0; // Calendar week starts on 0 - Sunday, 1 - Monday
        // Private
        this.componentNotDestroyed = new Subject();
        this.stateService.getSelectedCalendarType()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendarType) {
            _this.excludeDays = calendarType === 'workWeek' ? [0, 6] : [];
            _this.changeDetector.markForCheck();
        });
    }
    /**
     * Component lifecycle
     */
    FullCalendarWeekComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            fromEvent(window, 'resize')
                .pipe(debounceTime(200), takeUntil(_this.componentNotDestroyed))
                .subscribe(function () { return _this.calculateSegmentHeight(); });
        });
        // Update current time line
        merge(interval(15000), // update every 15 sec
        this.refreshCalendar.asObservable())
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.updateCurrentTimeLine();
        });
        this.updateCurrentTimeLine();
    };
    FullCalendarWeekComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewDate) {
            if (changes.viewDate.firstChange || this.weekDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
                this.displayDate = this.viewDate;
                this.refreshView();
            }
        }
        if (changes.highlightedDay || changes.selectedDate) {
            this.refreshView();
        }
    };
    FullCalendarWeekComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        timer(0).subscribe(function () { return _this.calculateSegmentHeight(); });
    };
    FullCalendarWeekComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Helpers
     */
    FullCalendarWeekComponent.prototype.updateCurrentTimeLine = function () {
        // Calc offset for current time line
        this.currentTime = new Date();
        var hoursOffset = this.currentTime.getHours() * this.hourSegmentHeight;
        var minutesOffset = (this.currentTime.getMinutes() / 60) * this.hourSegmentHeight;
        this.currentTimeLineOffset = hoursOffset + minutesOffset;
        this.changeDetector.markForCheck();
    };
    FullCalendarWeekComponent.prototype.isToday = function (time) {
        return (startOfDay(time).getTime() === startOfDay(this.currentTime).getTime());
    };
    /**
     * Action
     */
    FullCalendarWeekComponent.prototype.beforeRender = function (data) {
        var _this = this;
        if (this.highlightedDay) {
            data.hourColumns.filter(function (column) { return (isSameDay(column.date, _this.highlightedDay)); })
                .forEach(function (column) {
                return column.hours.forEach(function (hour) {
                    return hour.segments.forEach(function (segment) { return segment.cssClass = 'highlighted'; });
                });
            });
        }
    };
    FullCalendarWeekComponent.prototype.refreshView = function () {
        this.refreshCalendar.next();
    };
    FullCalendarWeekComponent.prototype.clickHourSegment = function (event, date, origin) {
        this.dateClicked.emit({ date: date, originRef: new ElementRef(origin), event: event });
    };
    FullCalendarWeekComponent.prototype.dblClickHourSegment = function (date) {
        this.dateDblClicked.emit(date);
    };
    FullCalendarWeekComponent.prototype.handleDrop = function (dragData, newStart, origin) {
        this.eventDropped.emit({
            dragData: dragData,
            newStart: newStart,
            newEnd: new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate(), newStart.getHours() + 1, newStart.getMinutes()),
            originRef: new ElementRef(origin)
        });
    };
    FullCalendarWeekComponent.prototype.dblClickDay = function (date) {
        this.dayNumberDblClicked.emit(date);
    };
    FullCalendarWeekComponent.prototype.weekDiffers = function (date1, date2) {
        var weekStartTimestamp = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() - (7 + date1.getDay() - this.weekStartsOn) % 7, 0, 0, 0, 0).getTime();
        return date2.getTime() < weekStartTimestamp || date2.getTime() >= weekStartTimestamp + 7 * 24 * 60 * 60 * 1000;
    };
    FullCalendarWeekComponent.prototype.calculateSegmentHeight = function () {
        // Set the height of hour segment between 40px and 60px according to available space on the screen
        this.hourSegmentHeight = Math.min(Math.max(40, Math.round((this.element.nativeElement.getBoundingClientRect().height) / 11)), 60);
        this.changeDetector.markForCheck();
        this.refreshCalendar.next();
    };
    FullCalendarWeekComponent.prototype.handleLoadDayEvents = function (date) {
        this.loadDayEvents.emit(date);
    };
    return FullCalendarWeekComponent;
}());
export { FullCalendarWeekComponent };
