// Common
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

// Services
import { BundledInputsService } from '../services/bundled-inputs.service';

// Types
import { BundledEvent } from '../types/bundled-event';

@Directive({
  selector: '[bundledProvider]',
})
export class BundledProviderDirective implements AfterViewInit, OnDestroy {

  // Private
  private alive: Subject<void> = new Subject();

  // Inputs
  @Input() bundledProvider: string[];
  @Input() bundledProviderGroup = '';

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private bundledInputsService: BundledInputsService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit(): void {
    if (this.bundledProvider && this.bundledProvider.length) {
      this.subscribeToEvents();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private subscribeToEvents() {
    fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.alive),
        debounceTime(400)
      )
      .subscribe((event: KeyboardEvent) => {
        this.bundledProvider
          .map(key => `${this.bundledProviderGroup}_${key}`)
          .forEach((key: string) => {
            const boundingRect = event.target['getBoundingClientRect']();
            const computedStyle = window.getComputedStyle(this.elementRef.nativeElement);
            const value = event.target['value'];
            const bundledEvent: BundledEvent = {
              key,
              fromValue: value,
              toValue: value,
              provider: {
                x: boundingRect.left,
                y: boundingRect.top,
                padding: computedStyle.padding,
                fontSize: computedStyle.fontSize,
                fontFamily: computedStyle.fontFamily,
                fontWeight: computedStyle.fontWeight,
                lineHeight: computedStyle.lineHeight,
                letterSpacing: computedStyle.letterSpacing,
                color: computedStyle.color,
              },
            };
            this.bundledInputsService.emitBundledEvent(bundledEvent);
          });
      });
  }
}
