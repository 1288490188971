/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-snippet.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../topic/topic.component.ngfactory";
import * as i3 from "../topic/topic.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../analytics/services/google-analytics.service";
import * as i6 from "../../../topic/services/topic.service";
import * as i7 from "../tag/tag.component.ngfactory";
import * as i8 from "../tag/tag.component";
import * as i9 from "../../../tag/services/tag.service";
import * as i10 from "@angular/common";
import * as i11 from "./message-snippet.component";
var styles_MessageSnippetComponent = [i0.styles];
var RenderType_MessageSnippetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageSnippetComponent, data: {} });
export { RenderType_MessageSnippetComponent as RenderType_MessageSnippetComponent };
function View_MessageSnippetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topic", [["topicStyle", "snippet"]], null, null, null, i2.View_TopicComponent_0, i2.RenderType_TopicComponent)), i1.ɵdid(1, 114688, null, 0, i3.TopicComponent, [i4.Router, i5.GoogleAnalyticsService, i6.TopicService], { topic: [0, "topic"], selected: [1, "selected"], topicStyle: [2, "topicStyle"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _v.parent.context.$implicit.highlighted; var currVal_2 = "snippet"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MessageSnippetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tag", [["class", "snippet-tag"]], null, null, null, i7.View_TagComponent_0, i7.RenderType_TagComponent)), i1.ɵdid(1, 245760, null, 0, i8.TagComponent, [i9.TagService], { tag: [0, "tag"], dragEnabled: [1, "dragEnabled"] }, null), i1.ɵpod(2, { name: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit.value); var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MessageSnippetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[2, "highlighted", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.highlighted; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_1); }); }
function View_MessageSnippetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageSnippetComponent_2)), i1.ɵdid(3, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageSnippetComponent_3)), i1.ɵdid(5, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageSnippetComponent_4)), i1.ɵdid(7, 16384, null, 0, i10.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "topic"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "tag"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MessageSnippetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageSnippetComponent_1)), i1.ɵdid(1, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.snippet; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessageSnippetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-snippet", [], null, null, null, View_MessageSnippetComponent_0, RenderType_MessageSnippetComponent)), i1.ɵdid(1, 114688, null, 0, i11.MessageSnippetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageSnippetComponentNgFactory = i1.ɵccf("app-message-snippet", i11.MessageSnippetComponent, View_MessageSnippetComponent_Host_0, { snippet: "snippet" }, {}, []);
export { MessageSnippetComponentNgFactory as MessageSnippetComponentNgFactory };
