// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Components
import { NoteComponent } from '@modules/elements/components/note/note.component';

// Types
import { LinkedInfo } from '../../types/linked-info';

@Component({
  selector: 'app-link-info-note',
  templateUrl: './link-info-note.component.html',
  styleUrls: ['./link-info-note.component.less']
})
export class LinkInfoNoteComponent extends NoteComponent {

    // Inputs
    @Input() linkedInfo: LinkedInfo;
    @Input() shortView = false;

    // Outputs
    @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

    /**
     * Actions
     */

    unlinkItem(event: MouseEvent) {
      event.preventDefault();
      event.stopPropagation();

      this.unlink.emit(this.linkedInfo);
    }

    openNote() {
      if (this.note) {
        this.modalService.noteForm(this.note);
      }
    }

}
