// Types
import { SplitViewState } from './split-view-state';
import { SelectedTabsState } from './selected-tabs-state';
import { EventsMainView } from '@modules/events/types/events-main-view';
import { TasksMainView } from '@modules/tasks/types/tasks-main-view';
import { ContactsMainView } from '@modules/contacts/types/contacts-main-view';
import { CalendarType } from '@modules/events/types/calendar-type';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { SortMessagesListState } from './sort-messages-list-state';
import { SidebarFilters } from './sidebar-filters-state';
import { MessagesListState } from './messages-list-state';
import { CollapsedState } from './collapsed-state';

export class GlobalState {

  // Sort
  sortEventsList?: DropdownOption;
  sortTasksProjectsList?: DropdownOption;
  sortKnowledgePaneRelatedTopics?: DropdownOption;
  sortKnowledgePaneRelatedConnections?: DropdownOption;
  sortKnowledgePaneRelatedFiles?: DropdownOption;
  sortKnowledgePaneRelatedHyperlinks?: DropdownOption;
  sortKnowledgePaneUpcomingEvents?: DropdownOption;
  sortKnowledgePaneArchivedEvents?: DropdownOption;
  sortInsightsRelatedConnections?: DropdownOption;
  sortTopicMap?: DropdownOption;
  sortPinnedTopics?: DropdownOption;
  sortPinnedTags?: DropdownOption;
  sortNotesList?: DropdownOption;
  sortMessagesList?: SortMessagesListState;
  sortContactsList?: DropdownOption;
  messagesLists?: MessagesListState;

  // Filter
  filterTopicMapCount?: {name: string, key: number};
  filterTopicMapDate?: {name: string, key: number | string, toDate: Date, fromDate: Date};
  filterTopicMapFirstSymbol?: string;
  filterTasksList?: DropdownOption[];

  // Selected
  tabsState?: SelectedTabsState;
  sidebarFilters?: SidebarFilters;
  previousKnowledgePanelState?: number;
  selectedKnowledgePaneContactContext?: string;
  selectedTopicMapView?: 'cloud' | 'list';
  selectedOrderOptions?: DropdownOption[];
  selectedExtendedFilteringMatchTypes?: DropdownOption[];
  selectedCalendarType?: CalendarType;
  selectedCalendarDates?: {[K in CalendarType]: Date};
  selectedCalendars?: string[];
  selectedKPCalendars?: string[];
  eventsMainView?: EventsMainView;
  tasksMainView?: TasksMainView;
  filesMainView?: 'empty' | 'file-form';
  contactsMainView?: ContactsMainView;
  collapsedColumns?: string[];

  // Split
  splitInsightsTopics?: number;
  splitInsightsRelatedContacts?: number;
  splitKnowledgePanelTopics?: number;
  splitTaskDetailsLinked?: number;
  splitKnowledgePanelContacts?: number;
  splitState?: SplitViewState;

  // Folders
  lastFoldersMovedTo?: string[];
  knowledgePanelFolder?: string;

  // Settings
  settingMessageSnoozed?: string;
  isUserFolderExpanded?: boolean;
  isAppMenuExpanded?: boolean;
  userFoldersState?: string[];
  isTextEditorToolbarExpanded?: boolean;
  collapsed?: CollapsedState;

  linkedToolbarFormMaximized?: boolean;
  linkedToolbarFormHeight?: number;

  constructor(jsonObject: object = {}) {

    // Sort
    this.sortEventsList = jsonObject['sortEventsList'] || {name: 'Date', key: 'date'};
    this.sortTasksProjectsList = jsonObject['sortTasksProjectsList'] || {name: 'Date', key: 'date'};
    this.sortKnowledgePaneRelatedTopics = jsonObject['sortKnowledgePaneRelatedTopics'] || {name: 'Relevance', key: 'relevance'};
    this.sortKnowledgePaneRelatedConnections = jsonObject['sortKnowledgePaneRelatedConnections'] || {name: 'Relevance', key: 'relevance'};
    this.sortKnowledgePaneRelatedFiles = jsonObject['sortKnowledgePaneRelatedFiles'] || {name: 'Relevance', key: 'relevance'};
    this.sortKnowledgePaneRelatedHyperlinks = jsonObject['sortKnowledgePaneRelatedHyperlinks'] || {name: 'Relevance', key: 'relevance'};
    this.sortKnowledgePaneUpcomingEvents = jsonObject['sortKnowledgePaneUpcomingEvents'] || {name: 'Date', key: 'date'};
    this.sortKnowledgePaneArchivedEvents = jsonObject['sortKnowledgePaneArchivedEvents'] || {name: 'Date', key: 'date'};
    this.sortInsightsRelatedConnections = jsonObject['sortInsightsRelatedConnections'] || {name: 'Relevance', key: 'relevance'};
    this.sortTopicMap = jsonObject['sortTopicMap'] || {name: 'Relevance', key: 'relevance'};
    this.sortPinnedTopics = jsonObject['sortPinnedTopics'] || {name: 'Relevance', key: 'relevance'};
    this.sortPinnedTags = jsonObject['sortPinnedTags'] || {name: 'Relevance', key: 'relevance'};
    this.sortNotesList = jsonObject['sortNotesList'] || {name: 'Create date', key: 'created-asc'};
    this.sortMessagesList = jsonObject['sortMessagesList'] || {
      sortMessagesList: {name: 'Date', key: 'date'},
      sortSearchMessagesList: {name: 'Relevance', key: 'relevance'},
      sortKnowledgePanelMessagesList: {name: 'Date', key: 'date'},
      sortKnowledgePaneRelatedMessages: {name: 'Relevance', key: 'relevance'}
    };
    this.sortContactsList = jsonObject['sortContactsList'] || {name: 'Name', key: 'name'};

    const defaultMessagesList = {
      order: 'date',
      orderWithRelevance: true,
      matchingTypes: ['phrase', 'match', 'synonyms', 'ngram']
    };
    this.messagesLists = {
      'MainMessagesList':
        (jsonObject['messagesLists'] && jsonObject['messagesLists']['MainMessagesList']) || defaultMessagesList,
      'KPMessagesPannel':
        (jsonObject['messagesLists'] && jsonObject['messagesLists']['KPMessagesPannel']) || defaultMessagesList,
      'KPRelatedMessagesPannel':
        (jsonObject['messagesLists'] && jsonObject['messagesLists']['KPRelatedMessagesPannel']) || defaultMessagesList,
      'InsightsMessagesList':
        (jsonObject['messagesLists'] && jsonObject['messagesLists']['InsightsMessagesList']) || defaultMessagesList,
    };

    // Filters
    this.filterTopicMapCount = jsonObject['filterTopicMapCount'] || {name: '20', key: 20};
    this.filterTopicMapDate = jsonObject['filterTopicMapDate'] || {name: 'Today', key: 'day'};
    this.filterTopicMapFirstSymbol = jsonObject['filterTopicMapFirstSymbol'] || 'a';
    this.filterTasksList = jsonObject['filterTasksList'] || [
      {name: 'Only tasks', key: 'regular'},
      {name: 'Snoozed emails', key: 'snooze'},
      {name: 'Follow-up emails', key: 'follow_up'},
    ];

    // Selected
    this.tabsState = jsonObject['tabsState'] || {kp: 0, kpMails: 0, kpEvents: 0, kpTasks: 0};
    this.sidebarFilters = jsonObject['sidebarFilters'] || { events: 'calendars', tasks: 'today'};
    this.selectedKnowledgePaneContactContext = jsonObject['selectedKnowledgePaneContactContext'] || 'context',
    this.selectedTopicMapView = jsonObject['selectedTopicMapView'] || 'cloud';
    this.selectedOrderOptions = jsonObject['selectedOrderOptions'] || [{name: 'Relevance', key: 'relevance'}];
    this.selectedExtendedFilteringMatchTypes = jsonObject['selectedExtendedFilteringMatchTypes'] || [
      {name: 'Exact Match', key: 'phrase'},
      {name: 'Partial Match', key: 'match'},
      {name: 'Synonyms', key: 'synonyms'},
      {name: 'Word Overlap', key: 'ngram'},
    ];
    this.selectedCalendarType = jsonObject['selectedCalendarType'] || 'year';
    this.eventsMainView = jsonObject['eventsMainView'] || 'calendar';
    this.tasksMainView = jsonObject['tasksMainView'] || 'empty';
    this.contactsMainView = jsonObject['contactsMainView'] || 'empty';
    this.selectedCalendarDates = {
      year: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].year ?
        new Date(jsonObject['selectedCalendarDates'].year) : new Date(),
      month: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].month ?
        new Date(jsonObject['selectedCalendarDates'].month) : new Date(),
      week: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].week ?
        new Date(jsonObject['selectedCalendarDates'].week) : new Date(),
      workWeek: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].workWeek ?
        new Date(jsonObject['selectedCalendarDates'].workWeek) : new Date(),
      day: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].day ?
        new Date(jsonObject['selectedCalendarDates'].day) : new Date(),
      agenda: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].agenda ?
        new Date(jsonObject['selectedCalendarDates'].agenda) : new Date(),
      kpEventsToday: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].kpEventsToday ?
        new Date(jsonObject['selectedCalendarDates'].kpEventsToday) : new Date(),
      kpCompass: jsonObject['selectedCalendarDates'] && jsonObject['selectedCalendarDates'].kpCompass ?
        new Date(jsonObject['selectedCalendarDates'].kpCompass) : new Date(),
    };
    this.selectedCalendars = jsonObject['selectedCalendars'] || [];
    this.selectedKPCalendars = jsonObject['selectedKPCalendars'] || [];
    this.collapsedColumns = jsonObject['collapsedColumns'] || [];

    // Split
    this.splitInsightsTopics = jsonObject['splitInsightsTopics'] || 50;
    this.splitInsightsRelatedContacts = jsonObject['splitInsightsRelatedContacts'] || 20;
    this.splitKnowledgePanelTopics = jsonObject['splitKnowledgePanelTopics'] || 200;
    this.splitTaskDetailsLinked = jsonObject['splitTaskDetailsLinked'] || 35;
    this.splitKnowledgePanelContacts = jsonObject['splitKnowledgePanelContacts'] || 200;
    this.splitState = jsonObject['splitState'] || {
      knowledgePanel: [300, 300],
      knowledgePanelModal: [250, 250],
      eventsList: [340, 340],
      mailsList: [340, 340],
      tasksList: [340, 340],
      notesList: [340, 340],
      contactsList: [340, 340],
      filesList: [340, 340],
      eventsSidebar: [150, 150],
      mailsSidebar: [150, 150],
      tasksSidebar: [150, 150],
      notesSidebar: [150, 150],
      contactsSidebar: [150, 150],
      filesSidebar: [150, 150],
    };

    // Folders
    this.lastFoldersMovedTo = this.lastFoldersMovedTo || [];
    this.knowledgePanelFolder = jsonObject['knowledgePanelFolder'] || 'inbox';

    // Setting
    this.settingMessageSnoozed = jsonObject['settingMessageSnoozed'] || 'hour',
    this.isUserFolderExpanded = jsonObject['isUserFolderExpanded'],
    this.isAppMenuExpanded = jsonObject['isAppMenuExpanded'],
    this.userFoldersState = jsonObject['userFoldersState'] || [];
    this.isTextEditorToolbarExpanded = jsonObject['isTextEditorToolbarExpanded'],
    this.collapsed = jsonObject['collapsed'] || {};

    // LinkedToolbar
    this.linkedToolbarFormMaximized = jsonObject['linkedToolbarFormMaximized'] || false;
    this.linkedToolbarFormHeight = jsonObject['linkedToolbarFormHeight'] || 300;
  }
}
