// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// Services
import { PopupService } from '../../../popup/services/popup.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Group } from '@modules/contacts/types/group';

@Component({
  selector: 'app-group-form-modal',
  templateUrl: './group-form-modal.component.html',
  styleUrls: ['./group-form-modal.component.less']
})
export class GroupFormModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() group: Group;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 259px)',
    y: '5vh',
    width: '518px',
    height: '90vh'
  };

  /**
   * Constructor
   */

  constructor(
    protected element: ElementRef,
    protected popupService: PopupService,
    protected ga: GoogleAnalyticsService,
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  expand() {
    this.ga.trackEvent('group-form-modal', 'expand');
    this.popupService.groupContent(this.group.id, this.maximizedSize.popupSize());
    this.close();
  }
}
