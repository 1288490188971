<div class="tasks">
  <app-knowledge-header
    title="Tasks"
    [search]="true"
    [orders]="orderOptions[tabs[selectedTab]]"
    [selected]="orders[tabs[selectedTab]]"
    [actions]="scrollOptions[tabs[selectedTab]]"
    (selectedChange)="selectOrder(tabs[selectedTab], $event)"
    (actionClick)="selectScrollOption(tabs[selectedTab], $event)"
    (searchQueryChange)="searchQuery = $event"
  ></app-knowledge-header>

  <mat-tab-group
    class="full-height"
    [disableRipple]="true"
    [selectedIndex]="selectedTab"
    (selectedTabChange)="handleSelectTab($event)"
  >
    <mat-tab label="All">
      <div class="tasks-all">
        <ng-container *ngTemplateOutlet="taskQuickForm"></ng-container>

        <app-tasks-list
          class="tasks-all-list"
          placeholderText="You have no tasks"
          [selectedOrder]="orders.all"
          [defaultFilters]="{title: searchQuery}"
        ></app-tasks-list>
      </div>
    </mat-tab>

    <mat-tab label="Today">
      <div class="tasks-all">
        <ng-container *ngTemplateOutlet="taskQuickForm"></ng-container>

        <app-tasks-list
          class="tasks-all-list"
          placeholderText="You have no today tasks"
          [selectedOrder]="orders.today"
          [defaultFilters]="{title: searchQuery}"
        ></app-tasks-list>
      </div>
    </mat-tab>

    <mat-tab label="Next 7 Days">
      <div class="tasks-all">
        <ng-container *ngTemplateOutlet="taskQuickForm"></ng-container>

        <app-tasks-list
          class="tasks-all-list"
          placeholderText="You have no next 7 days tasks"
          [selectedOrder]="orders.next7"
          [defaultFilters]="{title: searchQuery}"
        ></app-tasks-list>
      </div>
    </mat-tab>

    <mat-tab label="Projects">
      <div class="tasks-all">
        <app-quick-form
          class="tasks-all-quick-form"
          placeholder="Add New Project"
          bundledGroup="kpProjectsListQuickForm"
          [toggle]="quickFormProjectToggle"
          (clickMore)="handleAddProject()"
          (itemDropped)="handleProjectQuickFormDrop($event)"
        >
          <app-project-quick-form
            bundledGroup="kpProjectsListQuickForm"
            [project]="quickFormProject"
            (more)="handleAddProject($event)"
          ></app-project-quick-form>
        </app-quick-form>

        <app-projects-list
          class="tasks-all-list"
          placeholderText="You have no projects"
          [selectedOrder]="orders.projects"
          [defaultFilters]="{title: searchQuery}"
        ></app-projects-list>
      </div>
    </mat-tab>

    <mat-tab label="Archive">
      <div class="tasks-all">
        <ng-container *ngTemplateOutlet="taskQuickForm"></ng-container>

        <app-tasks-list
          class="tasks-all-list"
          placeholderText="You have no archived tasks"
          [selectedOrder]="orders.archive"
          [defaultFilters]="{title: searchQuery}"
        ></app-tasks-list>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<ng-template #taskQuickForm>
  <app-quick-form
    class="tasks-all-quick-form"
    placeholder="Add New Task"
    bundledGroup="kpTasksListQuickForm"
    [toggle]="quickFormTaskToggle"
    (clickMore)="handleAdd()"
    (itemDropped)="handleTaskQuickFormDrop($event)"
  >
    <app-task-quick-form
      (more)="handleAdd($event)"
      [task]="quickFormTask"
      bundledGroup="kpTasksListQuickForm"
    ></app-task-quick-form>
  </app-quick-form>
</ng-template>
