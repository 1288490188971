// Common
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less', '../../styles/input.less'],
})
export class TextareaComponent {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() rows = 3;
  @Input() appearance: 'standard' | 'outline' = 'outline';

  /**
   * Constructor
   */

  constructor() {

  }
}
