import * as tslib_1 from "tslib";
// Rx
import { combineLatest, merge } from 'rxjs';
import { map, switchMap, startWith, tap } from 'rxjs/operators';
import { ColumnsTree } from '../types/columns-tree';
var ColumnsTreeService = /** @class */ (function () {
    /**
     * Constructor
     */
    function ColumnsTreeService(sectionsService, tasksStateService, tasksService, columnsService) {
        this.sectionsService = sectionsService;
        this.tasksStateService = tasksStateService;
        this.tasksService = tasksService;
        this.columnsService = columnsService;
    }
    /**
     * Methods
     */
    ColumnsTreeService.prototype.getColumns = function (projectId) {
        var _this = this;
        return merge(this.sectionsService.sectionCreated, this.sectionsService.sectionUpdated, this.sectionsService.sectionDeleted, this.columnsService.columnCreated, this.columnsService.columnUpdated, this.columnsService.columnDeleted, this.tasksService.taskCreated, this.tasksService.taskUpdated, this.tasksService.taskDeleted, this.tasksStateService.getRefreshAll())
            .pipe(startWith(null), switchMap(function () { return (combineLatest([
            _this.columnsService.getColumns({ projectsIds: [projectId] }),
            _this.sectionsService.getSections({ projectsIds: [projectId] }),
            _this.tasksService.getTasks({ projectsIds: [projectId] })
        ])); }), map(function (_a) {
            var _b = tslib_1.__read(_a, 3), columns = _b[0].columns, sections = _b[1].sections, tasks = _b[2].tasks;
            return (new ColumnsTree({
                projectId: projectId,
                columns: columns,
                sections: sections,
                tasks: tasks
            }));
        }));
    };
    ColumnsTreeService.prototype.saveColumnsOrder = function (tree, newColumnId) {
        var filteredColumns = tree.columns.getValue()
            .map(function (column) { return column.temp ? newColumnId : column.id; })
            .filter(function (id) { return !!id; });
        if (filteredColumns.length === 0) {
            return;
        }
        return this.columnsService.reorder(filteredColumns);
    };
    ColumnsTreeService.prototype.submitColumn = function (tree, columnInstance) {
        var _this = this;
        return this.columnsService.create(columnInstance)
            .pipe(tap(function (column) {
            _this.saveColumnsOrder(tree, column.id);
        }));
    };
    ColumnsTreeService.prototype.moveColumn = function (tree, column, index) {
        tree.moveColumn(column, index);
        this.saveColumnsOrder(tree);
    };
    return ColumnsTreeService;
}());
export { ColumnsTreeService };
