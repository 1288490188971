// Common
import { Component } from '@angular/core';
import { ContextMenuService } from 'ngx-contextmenu';

// Other
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { MailMessage } from '@modules/mail/types/mail-message';

@Component({
  selector: 'app-events-agenda-context-menu',
  templateUrl: './events-agenda-context-menu.component.html',
  styleUrls: ['./events-agenda-context-menu.component.less']
})
export class EventsAgendaContextMenuComponent extends ContextMenuComponent {

  /**
   * Constructor
   */

  constructor(
    protected contextMenuService: ContextMenuService,
    private modalService: ModalService,
  ) {
    super(contextMenuService);
  }

  /**
   * Actions
   */

  copyLinkToAgenda() {

  }

  openInWindow() {

  }

  copy() {

  }

  paste() {

  }

  print() {

  }

  deleteAgenda() {

  }

  moveToArchive() {

  }

  selectAll() {

  }

  snooze(snooze: string) {

  }

  openCustomDateModal(reminderType: 'followup'|'snooze') {
    this.modalService.reminderDateTimePicker(new MailMessage(), reminderType);
  }
}
