import * as tslib_1 from "tslib";
// RxJS
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
// Decorators
import { warmUpObservable } from '@decorators';
// Env
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../async-tasks/services/async-tasks-toasts.service";
var UpdateType;
(function (UpdateType) {
    UpdateType[UpdateType["Add"] = 1] = "Add";
    UpdateType[UpdateType["Delete"] = 2] = "Delete";
})(UpdateType || (UpdateType = {}));
var LinkedInfoService = /** @class */ (function () {
    /**
     * Constructor
     */
    function LinkedInfoService(http, asyncTasksToastsService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        // Private
        this.linkedInfoUpdated = new Subject();
    }
    /**
     * Static methods
     */
    LinkedInfoService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Methods
     */
    LinkedInfoService.prototype.getLinkedInfoUpdate = function () {
        return this.linkedInfoUpdated.asObservable();
    };
    LinkedInfoService.prototype.getLinkedInfo = function (type, id) {
        return this.http.get(environment.baseUrl + "/api/linked-info/" + type + "/" + id)
            .pipe(map(function (_a) {
            var linkedItems = _a.linkedItems;
            return linkedItems;
        }), catchError(LinkedInfoService.handleObserverError));
    };
    LinkedInfoService.prototype.linkItems = function (items) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/linked-info', { linkedItems: items })
            .pipe(tap(function (_a) {
            var success = _a.success;
            _this.linkedInfoUpdated.next(UpdateType.Add);
            if (success) {
                _this.asyncTasksToastsService.show({ text: "Item(s) linked." });
            }
        }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(LinkedInfoService.handleObserverError));
    };
    LinkedInfoService.prototype.linkToItem = function (item, items) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/linked-info/' + item.type + 's/' + item.id, { linkedItems: items })
            .pipe(tap(function () { return _this.linkedInfoUpdated.next(UpdateType.Add); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(LinkedInfoService.handleObserverError));
    };
    LinkedInfoService.prototype.unlinkItems = function (items) {
        var _this = this;
        var params = { body: {
                linkedItems: items
            } };
        return this.http.request('DELETE', environment.baseUrl + '/api/linked-info', params)
            .pipe(tap(function () { return _this.linkedInfoUpdated.next(UpdateType.Delete); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(LinkedInfoService.handleObserverError));
    };
    LinkedInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkedInfoService_Factory() { return new LinkedInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AsyncTasksToastsService)); }, token: LinkedInfoService, providedIn: "root" });
    tslib_1.__decorate([
        warmUpObservable
    ], LinkedInfoService.prototype, "linkItems", null);
    tslib_1.__decorate([
        warmUpObservable
    ], LinkedInfoService.prototype, "linkToItem", null);
    tslib_1.__decorate([
        warmUpObservable
    ], LinkedInfoService.prototype, "unlinkItems", null);
    return LinkedInfoService;
}());
export { LinkedInfoService };
