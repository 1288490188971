// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// RxJS
import { zip } from 'rxjs';

// Services
import { MailService } from '../../../mail/services/mail.service';
import { PopupService } from '../../../popup/services/popup.service';
import { TopicService } from '../../../topic/services/topic.service';

// Types
import { MailMessage } from '../../../mail/types/mail-message';
import { Topic, TopicAnnotation } from '@modules/topic/types/topic';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-topics-annotations-modal',
  templateUrl: './topics-annotations-modal.component.html',
  styleUrls: ['./topics-annotations-modal.component.less']
})
export class TopicsAnnotationsModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() availableExpand = true;
  @Input()
  set message(message: MailMessage) {
    this.messageValue = message;
    if (this.initialized) {
      this.getAnnotations();
    }
  }
  get message(): MailMessage {
    return this.messageValue;
  }

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 300px)',
    y: '40px',
    width: '600px',
    height: 'calc(100% - 100px)'
  };

  // Public
  public annotations: TopicAnnotation[] = [];
  public shareText = true;
  public autoAdd = false;
  public newTopic: string;
  public newTopics: string[] = [];
  public status: string;
  public statusError: string;
  public annotationsStatus: {[key: string]: 'confirm'|'loading'|'deleted'|'error'} = {};

  // Private
  private messageValue: MailMessage;
  private initialized = false;

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService,
    private popupService: PopupService,
    private topicService: TopicService,
    private element: ElementRef
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    if (this.message) {
      this.getAnnotations();
    }
    this.initialized = true;
  }

  /**
   * Actions
   */

  getAnnotations() {
    this.status = 'loadingAnnotations';
    this.annotations = [];
    this.mailService.getTopicsAnnotations(this.message.id)
      .subscribe(result => {
        this.status = null;
        this.shareText = result.shareText;
        this.annotations = result.annotations;
      });
  }

  addTopic() {
    if (this.newTopic) {
      this.newTopics.push(this.newTopic);
      this.newTopic = '';
    }
  }

  removeTopic(topic: string) {
    this.newTopics = this.newTopics.filter(listTopic => listTopic !== topic);
  }

  deleteTopic(topic: TopicAnnotation): boolean {
    this.annotationsStatus[topic.name] = 'loading';
    this.topicService
      .deleteTopics(
        [topic as Topic],
        { messagesIds: [this.message.id] }
      )
      .subscribe((success: boolean) => {
        this.annotationsStatus[topic.name] = success ? 'deleted' : 'error';
      }, () => {
        this.annotationsStatus[topic.name] = 'error';
      });
    return false;
  }

  save() {
    this.status = 'saving';
    this.statusError = '';

    this.addTopic();

    zip(
      this.mailService.saveTopicsAnnotations(
        this.message.id,
        this.annotations
          .filter(annotation => !!annotation.rating)
          .concat(this.newTopics.map(topic => {
            return {name: topic, rating: 'good'} as TopicAnnotation;
          })),
        this.shareText
      ),
      this.topicService.createTopics(
        this.newTopics.map(topic => {
          return {name: topic} as Topic;
        }),
        {messagesIds: [this.message.id]}
      )
    ).subscribe(
      ([isSavedTopicsAnnotations, isCreatedTopics]: boolean[]) => {
        if (isSavedTopicsAnnotations && isCreatedTopics) {
          this.close();
        } else {
          this.status = (isSavedTopicsAnnotations && isCreatedTopics) ? 'saved' : 'error';
        }
      },
      () => {
        this.status = 'error';
        this.statusError = 'Unexpected error while saving data. Please try again.';
      }
    );
  }

  reprocess() {
    this.status = 'loadingAnnotations';
    this.mailService.getTopics(this.message, true)
      .subscribe(
        () => this.getAnnotations(),
        () => {
          this.status = 'error';
          this.statusError = 'Unexpected error while re-processing message. Please try again.';
        }
      );
  }

  getModalContent(): HTMLElement {
    const modalContent = this.element.nativeElement.parentElement;
    return modalContent.parentElement;
  }

  expandPopup() {
    const modalContent = this.getModalContent();
    this.popupService.annotations(this.messageValue.id, {
      width: modalContent.offsetWidth,
      height: modalContent.offsetHeight,
    });
    this.close();
  }

}
