// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Module
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { PopoverModule } from '@modules/popover/popover.module';

// Components
import { TagListComponent } from './components/tag-list/tag-list.component';
import { CreateTagComponent } from './components/create-tag/create-tag.component';



@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    ContextMenuModule,
    MatIconModule,
    ElementsModule,
    FormControlsModule,
    PopoverModule,
  ],
  declarations: [ TagListComponent, CreateTagComponent ],
  exports: [ TagListComponent, CreateTagComponent ]
})
export class TagModule { }
