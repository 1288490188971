<div class="event-wrapper" *ngIf="event" (click)="openEvent()">
  <div
    class="event"
    [class.short-view]="shortView"
    appDraggable
    [appDraggableEnabled]="dragEnabled"
    [appDraggableData]="{data: dragData, type: 'event'}"
    [appDraggablePlaceholder]="dragPlaceholder"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"

    [appEventContextMenu]="event"
    (contextMenuOpened)="contextMenuOpened = $event"
  >
    <div class="event-icons">
      <mat-icon *ngIf="event.pinned" class="event-icons-small-icon pin" svgIcon="mail-pinned"></mat-icon>
      <mat-icon *ngIf="event.starred" class="event-icons-small-icon star" svgIcon="starred"></mat-icon>
    </div>
    <div class="event-info">
      <div class="event-info-title">{{ event.title }}</div>

      <div class="event-info-date-time">
        <mat-icon
          class="event-info-date-time-repeat-icon"
          *ngIf="!event.repeat"
          svgIcon="repeat"
        ></mat-icon>
        <app-date-time
          [startDate]="event.when?.start"
          [startTime]="event.when?.start"
          [endDate]="event.when?.end"
          [endTime]="event.when?.end"
        ></app-date-time>
      </div>

      <div class="event-info-description" [class.empty]="!event.description?.trim()">{{ event.description }}</div>

      <app-link-info-bar *ngIf="!shortView" [linkedInfo]="event.linkedInfo"></app-link-info-bar>
    </div>
    <div class="event-icons right">
      <div class="event-icons-hideable">
        <mat-icon class="event-icons-hideable-type-icon" svgIcon="events"></mat-icon>
        <mat-icon *ngIf="event.files?.length" class="event-icons-small-icon attachment" svgIcon="mail-attachment"></mat-icon>
        <mat-icon
          class="event-icons-small-icon unlink"
          *ngIf="shortView"
          svgIcon="unlink"
          matTooltip="Unlink"
          (click)="unlinkItem($event)"
        ></mat-icon>
      </div>

      <div class="event-icons-unlink-participants-container">
        <mat-icon
          class="event-icons-small-icon unlink"
          svgIcon="unlink"
          matTooltip="Unlink"
          (click)="unlinkItem($event)"
        ></mat-icon>
        <div class="participants-container" *ngIf="event.participants?.length">
          <app-contacts-counter-dropdown
            [contactList]="event.participants"
            [size]="24"
            [minContactLen]="1"
            [showNoReplyMark]="false"
          ></app-contacts-counter-dropdown>
        </div>
      </div>
    </div>

    <!--  Actions on hover  -->
    <div class="actions" *ngIf="actionsButtonEnabled" (click)="$event.stopPropagation(); $event.preventDefault()">
      <button
        class="button-icon-small btn-action"
        [class.selected]="event.starred"
      >
        <mat-icon svgIcon="saved" ></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
        [class.selected]="event.snooze"
      >
        <mat-icon svgIcon="snooze"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
      >
        <mat-icon svgIcon="event"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
      >
        <mat-icon svgIcon="calendar-new"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
      >
        <mat-icon svgIcon="archive"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
      >
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Event placeholder -->
<div class="event-wrapper placeholder" *ngIf="!event">
  <div class="event">
    <div class="event-icons">
      <div class="event-icons-placeholder"></div>
      <div class="event-icons-placeholder"></div>
    </div>
    <div class="event-info">
      <div class="event-info-title">
        <div class="event-icons-placeholder medium"></div>
        <div class="event-info-title-text"></div>
      </div>

      <div class="event-info-date-time">
        <div class="event-info-date-time-item"></div>
        <div class="event-info-date-time-item"></div>
      </div>

      <div class="event-info-description"></div>

      <div class="event-info-link-info-bar">
        <div class="event-icons-placeholder square"></div>
        <div class="event-icons-placeholder square"></div>
        <div class="event-icons-placeholder square"></div>
        <div class="event-icons-placeholder square"></div>
      </div>
    </div>
    <div class="event-icons right">
      <div class="event-icons-placeholder square"></div>
      <div class="event-icons-placeholder"></div>
      <div class="event-icons-placeholder big"></div>
    </div>
  </div>
</div>

<!-- Drag event placeholder -->
<ng-template #dragPlaceholder>
  <app-link-info-event
    [event]="event"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [shortView]="shortView"
  ></app-link-info-event>
</ng-template>
