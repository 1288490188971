// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule, MatNativeDateModule, MatInputModule } from '@angular/material';
import { PopoverModule } from '../popover/popover.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { ContextMenuModule } from '@modules/context-menu/context-menu.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { PipesModule } from '@modules/pipes/pipes.module';

// Pipes
import { SameDayEventsCountPipe } from './components/calendar-week/pipes/same-day-events-count.pipe';

// Components
import { FullCalendarMonthComponent } from './components/calendar-month/full-calendar-month.component';
import { FullCalendarYearComponent } from './components/calendar-year/full-calendar-year.component';
import { FullCalendarWeekComponent } from './components/calendar-week/full-calendar-week.component';
import { FullCalendarDayComponent } from './components/calendar-day/full-calendar-day.component';
import {
  CustomizedCalendarWeekComponent
} from './components/calendar-week/components/customized-calendar-week/customized-calendar-week.component';
import { CalendarCellContextMenuComponent } from './components/calendar-cell-context-menu/calendar-cell-context-menu.component';

// Directives
import { CalendarCellContextMenuDirective } from './directives/calendar-cell-context-menu.directive';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ContextMenuModule,
    NgxContextMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    MatInputModule,
    UserAvatarModule,
    AppCommonModule,
    FormControlsModule,
    DragNDropModule,
    ElementsModule,
    PipesModule,
  ],
  declarations: [
    FullCalendarMonthComponent,
    FullCalendarYearComponent,
    FullCalendarWeekComponent,
    FullCalendarDayComponent,
    CalendarCellContextMenuComponent,
    CustomizedCalendarWeekComponent,
    CalendarCellContextMenuDirective,
    SameDayEventsCountPipe,
  ],
  exports: [
    FullCalendarMonthComponent, FullCalendarYearComponent, FullCalendarWeekComponent, FullCalendarDayComponent,
    CustomizedCalendarWeekComponent, SameDayEventsCountPipe
  ],
  entryComponents: [
    CalendarCellContextMenuComponent,
  ]
})
export class FullCalendarModule { }
