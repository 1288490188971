import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoProjectListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoProjectListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoProjectListComponent(modalService, projectsService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.projectsService = projectsService;
        _this.ngZone = ngZone;
        _this.type = 'project';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoProjectListComponent.prototype.getItems = function (ids) {
        return this.projectsService.getProjects({ projectsIds: ids, limit: ids.length })
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.projects; }));
    };
    /**
     * Actions
     */
    LinkedInfoProjectListComponent.prototype.select = function (project) {
        this.modalService.projectForm(project);
    };
    return LinkedInfoProjectListComponent;
}(LinkInfoPopover));
export { LinkedInfoProjectListComponent };
