import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ChangeDetectorRef, TemplateRef, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, interval, fromEvent, merge, timer } from 'rxjs';
import { CalendarDateFormatter, CalendarUtils, } from 'angular-calendar';
import { endOfDay, startOfDay } from 'date-fns';
import { debounceTime, takeUntil } from 'rxjs/operators';
// Override week time formatter
var CalendarWeekDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarWeekDateFormatter, _super);
    function CalendarWeekDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CalendarWeekDateFormatter.prototype.weekViewHour = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date).toLowerCase();
    };
    CalendarWeekDateFormatter.prototype.weekViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return "" + date.getDate();
    };
    CalendarWeekDateFormatter.prototype.weekViewColumnSubHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new DatePipe(locale).transform(date, 'EEEE', locale);
    };
    return CalendarWeekDateFormatter;
}(CalendarDateFormatter));
export { CalendarWeekDateFormatter };
var DayCalendarUtils = /** @class */ (function (_super) {
    tslib_1.__extends(DayCalendarUtils, _super);
    function DayCalendarUtils() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DayCalendarUtils.prototype.getWeekView = function (args) {
        args.viewStart = args.viewDate;
        args.viewEnd = args.viewDate;
        return _super.prototype.getWeekView.call(this, args);
    };
    DayCalendarUtils.prototype.getWeekViewHeader = function (args) {
        args.viewStart = startOfDay(args.viewDate);
        args.viewEnd = endOfDay(args.viewDate);
        return _super.prototype.getWeekViewHeader.call(this, args);
    };
    return DayCalendarUtils;
}(CalendarUtils));
export { DayCalendarUtils };
var FullCalendarDayComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FullCalendarDayComponent(element, ngZone, changeDetector) {
        this.element = element;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.hideDateHeader = false;
        // Outputs
        this.dateClicked = new EventEmitter();
        this.dateDblClicked = new EventEmitter();
        this.eventDropped = new EventEmitter();
        this.loadDayEvents = new EventEmitter();
        this.refreshCalendar = new Subject();
        this.timeZoneName = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        this.currentTimeLineOffset = 0;
        this.currentTime = new Date();
        this.hourSegmentHeight = 48;
        // Private
        this.componentNotDestroyed = new Subject();
    }
    /**
     * Component lifecycle
     */
    FullCalendarDayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.displayDate = this.viewDate;
        this.ngZone.runOutsideAngular(function () {
            fromEvent(window, 'resize')
                .pipe(debounceTime(200), takeUntil(_this.componentNotDestroyed))
                .subscribe(function () { return _this.calculateSegmentHeight(); });
        });
        // Update current time line
        merge(interval(15000), // update every 15 sec
        this.refreshCalendar.asObservable())
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.updateCurrentTimeLine();
        });
        this.updateCurrentTimeLine();
    };
    FullCalendarDayComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewDate) {
            if (changes.viewDate.firstChange || this.dayDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
                this.displayDate = this.viewDate;
                this.refreshView();
            }
            if (changes.selectedDate) {
                this.refreshView();
            }
        }
    };
    FullCalendarDayComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        timer(0).subscribe(function () { return _this.calculateSegmentHeight(); });
    };
    FullCalendarDayComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Helpers
     */
    FullCalendarDayComponent.prototype.updateCurrentTimeLine = function () {
        // Calc offset for current time line
        this.currentTime = new Date();
        var hoursOffset = this.currentTime.getHours() * this.hourSegmentHeight;
        var minutesOffset = (this.currentTime.getMinutes() / 60) * this.hourSegmentHeight;
        this.currentTimeLineOffset = Math.round(hoursOffset + minutesOffset - 1);
        this.changeDetector.markForCheck();
    };
    /**
     * Action
     */
    FullCalendarDayComponent.prototype.refreshView = function () {
        this.refreshCalendar.next();
    };
    FullCalendarDayComponent.prototype.clickHourSegment = function (event, date, origin) {
        this.dateClicked.emit({ date: date, originRef: new ElementRef(origin), event: event });
    };
    FullCalendarDayComponent.prototype.dblClickHourSegment = function (date) {
        this.dateDblClicked.emit(date);
    };
    FullCalendarDayComponent.prototype.handleDrop = function (dragData, newStart, origin) {
        this.eventDropped.emit({
            dragData: dragData,
            newStart: newStart,
            newEnd: new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate(), newStart.getHours() + 1, newStart.getMinutes()),
            originRef: new ElementRef(origin)
        });
    };
    FullCalendarDayComponent.prototype.dayDiffers = function (date1, date2) {
        return date1.getDate() !== date2.getDate() || date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
    };
    FullCalendarDayComponent.prototype.calculateSegmentHeight = function () {
        // Set the height of hour segment between 40px and 60px according to available space on the screen
        this.hourSegmentHeight = Math.min(Math.max(40, Math.round((this.element.nativeElement.getBoundingClientRect().height) / 11)), 60);
        this.changeDetector.markForCheck();
        this.refreshCalendar.next();
    };
    FullCalendarDayComponent.prototype.handleLoadDayEvents = function (date) {
        this.loadDayEvents.emit(date);
    };
    return FullCalendarDayComponent;
}());
export { FullCalendarDayComponent };
