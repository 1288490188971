<!-- Toolbar -->
<div class="toolbar">
  <mat-icon svgIcon="button-nav-modal" class="toolbar-button"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- Undo/Redo -->
  <mat-icon class="toolbar-icon" svgIcon="undo" (click)="execCommand('Undo')" matTooltip="Undo"></mat-icon>
  <mat-icon class="toolbar-icon" svgIcon="redo" (click)="execCommand('Redo')" matTooltip="Redo"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- H1 -->
  <mat-icon svgIcon="h1" class="toolbar-icon" [class.selected]="formats.h1" (click)="execCommand('mceToggleFormat', 'h1')" matTooltip="H1"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="bullet-list" class="toolbar-icon" (click)="execCommand('InsertUnorderedList')" matTooltip="Bulet list"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- Align text -->
  <app-dropdown [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon [svgIcon]="formats.align || 'alignleft'" class="toolbar-icon" matTooltip="Align text"></mat-icon>
        <mat-icon svgIcon="dropdown-small" class="dropdown-icon--small"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-template-items
      [options]="alignOptions"
      [optionTemplateRef]="alignItem"
      (selectedChange)="execCommand($event.action)"
    ></app-dropdown-template-items>
  </app-dropdown>
  <ng-template #alignItem let-option="option" let-select="select">
    <div class="dropdown-item" (click)="select(option)">
      <div class="text-with-icon">
        <mat-icon [svgIcon]="option.key" class="dropdown-item-icon"></mat-icon>
        <span>{{ option.name }}</span>
      </div>
      <mat-icon svgIcon="check" class="check-icon" *ngIf="option.key === formats.fontname"></mat-icon>
    </div>
  </ng-template>
  <div class="toolbar-devider"></div>
  <!-- Bold/Italic/Underline -->
  <mat-icon svgIcon="bold" class="toolbar-icon" [class.selected]="formats.bold" (click)="execCommand('mceToggleFormat', 'bold')" matTooltip="Bold"></mat-icon>
  <mat-icon svgIcon="italic" class="toolbar-icon" [class.selected]="formats.italic" (click)="execCommand('mceToggleFormat', 'italic')" matTooltip="Italic"></mat-icon>
  <mat-icon svgIcon="underline" class="toolbar-icon" [class.selected]="formats.underline" (click)="execCommand('mceToggleFormat', 'underline')" matTooltip="Underline"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- Forecolor -->
  <app-dropdown align="right" [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon svgIcon="color" class="toolbar-icon" [style.color]="formats.forecolor || '#000000'" matTooltip="Forecolor"></mat-icon>
        <mat-icon svgIcon="dropdown-small" class="dropdown-icon--small"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-color-select
      [selected]="formats.forecolor"
      (selectedChange)="execCommand('ForeColor', $event)"
    ></app-dropdown-color-select>
  </app-dropdown>
  <div class="toolbar-devider"></div>
  <!-- Link -->
  <mat-icon svgIcon="link" class="toolbar-icon" (click)="execCommand('mceLink')" matTooltip="Link"></mat-icon>
  <mat-icon svgIcon="more" class="toolbar-icon" matTooltip="More"></mat-icon>
</div>

<!-- Editor -->
<div class="editor">
  <div class="text-block">
    <mat-icon *ngIf="!content?.length" svgIcon="add-small" class="add-element-icon"></mat-icon>
    <div *ngIf="!content?.length" class="text-placeholder">Type something</div>
    <editor
      class="text-editor"
      [ngModel]="content"
      (ngModelChange)="contentChanged($event)"
      [init]="editorSettings"
      (onInit)="editorInitialized($event)"
      (onDragOver)="editorDragOver($event)"
    ></editor>
  </div>
</div>
