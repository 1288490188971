/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes-main-view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../note-form/note-form.component.ngfactory";
import * as i3 from "../note-form/note-form.component";
import * as i4 from "../../services/notes.service";
import * as i5 from "../../../linked-info/services/linked-info.service";
import * as i6 from "../../../modal/services/modal.service";
import * as i7 from "../../../pages/services/dynamic-panel.service";
import * as i8 from "../../../topic/services/topic.service";
import * as i9 from "../../../tag/services/tag.service";
import * as i10 from "@angular/common";
import * as i11 from "./notes-main-view.component";
var styles_NotesMainViewComponent = [i0.styles];
var RenderType_NotesMainViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesMainViewComponent, data: {} });
export { RenderType_NotesMainViewComponent as RenderType_NotesMainViewComponent };
function View_NotesMainViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "empty-state-image"], ["src", "assets/placeholders/placeholder-notes.svg"]], null, null, null, null, null))], null, null); }
function View_NotesMainViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-note-form", [["appearance", "headerFixedSmall"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NoteFormComponent_0, i2.RenderType_NoteFormComponent)), i1.ɵdid(1, 638976, null, 0, i3.NoteFormComponent, [i4.NotesService, i5.LinkedInfoService, i6.ModalService, i7.DynamicPanelService, i8.TopicService, i9.TagService], { note: [0, "note"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.note; var currVal_1 = "headerFixedSmall"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NotesMainViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesMainViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesMainViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.note; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.note; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NotesMainViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notes-main-view", [], null, null, null, View_NotesMainViewComponent_0, RenderType_NotesMainViewComponent)), i1.ɵdid(1, 638976, null, 0, i11.NotesMainViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesMainViewComponentNgFactory = i1.ɵccf("app-notes-main-view", i11.NotesMainViewComponent, View_NotesMainViewComponent_Host_0, { selectedNotes: "selectedNotes" }, {}, []);
export { NotesMainViewComponentNgFactory as NotesMainViewComponentNgFactory };
