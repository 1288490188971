// Common
import {
  Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnDestroy, NgZone
} from '@angular/core';

// RxJS
import { Subject, BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { MailService } from '../../services/mail.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessagesListStateService } from '@modules/mail/services/messages-list-state.service';

// Base Component
import { VirtualScrollListComponent } from '@modules/virtual-scroll/classes/virtual-scroll-list';

// Types
import { MailFolder } from '../../types/mail-folder';
import { MailMessage } from '../../types/mail-message';
import { MessagesFilters } from '../../services/mail.service';
import { ResponseMessagesOffset } from '../../types/mail-response.model';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { DynamicSizeMailMessage } from '@modules/mail/types/dynamic-size-mail-message';

// Env
import { environment } from '@environment';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class MessagesListComponent extends VirtualScrollListComponent<MailMessage> implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() selectedMessages: MailMessage[] = [];
  @Input() folderDropdown = false;
  @Input() header = true;

  // Outputs
  @Output() selectedMessagesChange: EventEmitter<MailMessage[]> = new EventEmitter();

  // Public
  public extendedFiltering = false;
  public folderDetails: MailFolder;
  public orders: DropdownOption[] = [
    {name: 'Date', key: 'date'},
    {name: 'Thread', key: 'thread'},
    {name: 'Sender', key: 'sender'},
    {name: 'Attachments', key: 'attachments'},
    {name: 'Unread', key: 'unread'}
  ];
  public selectedOrder: DropdownOption = {name: 'Date', key: 'date'};
  public extendedFilteringMatchTypes: DropdownOption[] = [
    {name: 'Exact Match', key: 'phrase'},
    {name: 'Partial Match', key: 'match'},
    {name: 'Word Overlap', key: 'ngram'},
  ];
  public selectedExtendedFilteringMatchTypes: DropdownOption[];
  public orderOprions: DropdownOption[] = [
    {name: 'Relevance', key: 'relevance'}
  ];
  public selectedOrderOptions: DropdownOption[];
  public expandedMessagesId: string[] = [];
  public showCountView = new BehaviorSubject(true);
  public dropdownClose: Subject<void> = new Subject();
  public maxDate = new Date();
  public popoverClose: Subject<void> = new Subject();
  public filters: MessagesFilters;

  // Private
  private folders: MailFolder[];

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService,
    private ga: GoogleAnalyticsService,
    private modalService: ModalService,
    private linkedInfoService: LinkedInfoService,
    private messagesListStateService: MessagesListStateService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    // Receiving folders for further title displaying
    this.mailService.getFolders()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((folders: MailFolder[]) => {
        this.folders = folders;

        if (this.filters && this.filters.folder) {
          this.folderDetails = MailService.findFolder(this.folders, this.filters.folder);
          this.showCountView.next(true);
        }
      });

    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Subscribe for global refresh all messages button
      this.mailService.refreshAllMails$,
      // Subscribe for global notification of message moved
      this.mailService.onMoveToFolder,
      this.mailService.onSentMessage,
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Make automatic updates for new messages
      interval(environment.messageFetchInterval)
        .pipe(
          filter(() =>
            !this.loading
            && this.selectedOrder && this.selectedOrder.key === 'date'
            && !this.extendedFiltering
          )
        )
    )
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.refreshCurrentItems());

    this.messagesListStateService.getFilters()
      .pipe(takeUntil(this.alive))
      .subscribe(filters => {

        // Prepare filters list for messages
        this.filters = {
          folder: filters.folder,
          orderWithPinned: filters.orderWithPinned,
          topics: filters.topics,
          tags: filters.tags,
          contacts: filters.contacts,
          keywords: filters.keywords,
          relatedTopics: filters.relatedTopics,
          relatedContacts: filters.relatedContacts,
          relatedMessageId: filters.relatedMessageId,
          relatedMessageTopics: filters.relatedMessageTopics,
          fromTime: filters.fromTime,
          toTime: filters.toTime,
          groupBy: filters.groupByThread ? 'thread' : null,
          relevance: filters.orderWithRelevance,
          matchingTypes: filters.matchingTypes
        };

        // Set current folder details
        if (this.folders) {
          this.folderDetails = MailService.findFolder(this.folders, this.filters.folder);
        }

        // Set selected dropdown properties
        this.selectedOrder = this.orders.find(option => option.key === filters.order) || { name: '', key: filters.order };
        this.selectedOrderOptions = filters.orderWithRelevance ? this.orderOprions : [];
        this.selectedExtendedFilteringMatchTypes =
          this.extendedFilteringMatchTypes.filter(option => filters.matchingTypes && filters.matchingTypes.includes(option.key));

        // Set extanded filtering flag to show proper sorting
        this.extendedFiltering = !!(
          (filters.topics && filters.topics.length) ||
          (filters.tags && filters.tags.length) ||
          (filters.contacts && filters.contacts.length) ||
          (filters.keywords && filters.keywords.length) ||
          (filters.relatedTopics && filters.relatedTopics.length) ||
          (filters.relatedContacts && filters.relatedContacts.length) ||
          (filters.relatedMessageId) ||
          (filters.relatedMessageTopics && filters.relatedMessageTopics.length)
        );

        this.resetItems();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedMessages' in changes) {
      this.selectedItems = (this.selectedMessages || []).map(this.itemFactory);
    }
  }

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.dropdownClose.next();
    this.dropdownClose.complete();
    super.ngOnDestroy();
  }

  /**
   * Base Methods override
   */

  getItems(offset: number, limit: number): Observable<{ items: DynamicSizeMailMessage[], count: number }> {
    return this.mailService
      .getMessages(this.filters, this.selectedOrder.key, limit, offset)
      .pipe(
        map(({ messages, count }) => ({
          items: messages.map(this.itemFactory),
          count
        })),
      );
  }

  itemFactory(message: MailMessage): DynamicSizeMailMessage {
    return new DynamicSizeMailMessage(message);
  }

  resetItems() {
    this.expandedMessagesId = [];
    this.selectedItems = [];
    this.selectedMessagesChange.emit([]);

    super.resetItems();
  }

  selectItem(item: DynamicSizeMailMessage, event: MouseEvent|KeyboardEvent, selectAll = false) {
    if (!item || !item.data) {
      return;
    }

    if (item.data.isDraft && !item.data.sending) {
      // Drafts are handled in custom way
      return this.modalService.compose(item.data);
    }

    if (item.data.unread && (!this.selectedItems.length || !(event.ctrlKey || event.shiftKey || event.metaKey))) {
      this.mailService.updateMessagesUnreadStatus({messagesIds: [item.data.id]}, !item.data.unread);
    }

    super.selectItem(item, event, selectAll);

    this.selectMessages(this.selectedItems.map(selectedItem => selectedItem.data));
  }

  /**
   * Actions
   */

  openMessageModal(message: MailMessage) {
    if (message.isDraft &&
      !(message.sending && (message.sending.status === 'sending' || message.sending.status === 'pending'))
    ) {
      return;
    }
    this.ga.trackEvent('mail-messages-list', 'open-email');
    this.modalService.mailContent(message);
  }

  // Used for output from child component, which can manipulate the selection as well
  selectMessages(messages: MailMessage[]) {
    this.selectedItems = [];
    this.selectedMessages = [];

    for (const message of (messages || [])) {
      const selectedItem = this.items.find(item => this.compareItems(item, this.itemFactory(message)));
      if (this.selectedItems) {
        this.selectedItems.push(selectedItem);
        this.selectedMessages.push(selectedItem.data);
      }
    }

    this.selectedMessagesChange.emit(this.selectedMessages);
  }

  selectFolder(folder: MailFolder) {
    this.messagesListStateService.setFilters({ folder: folder.id });
  }

  selectOrder(order: DropdownOption) {
    this.messagesListStateService.setFilters({ order: order.key });
  }

  selectSearchMatchType(matchTypes: DropdownOption[]) {
    this.messagesListStateService.setFilters({ matchingTypes: matchTypes.map(matchingType => matchingType.key) });
  }

  selectOrderOption(options: DropdownOption[]) {
    this.messagesListStateService.setFilters({ orderWithRelevance: !!options.length });
  }

  scrollToDate(pagesDate: Date) {
    this.popoverClose.next();
    this.dropdownClose.next();

    const date = new Date(pagesDate);
    date.setHours(0, 0, 0, 0);
    date.setHours(+24);

    this.mailService.getMessagesOffset({ ...this.filters, fromTime: date }, this.selectedOrder.key)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((res: ResponseMessagesOffset) => {
        this.scrollToIndex(res.offset);
      });
  }

  scrollToLetter(letter: string) {
    this.mailService.getMessagesOffset({ ...this.filters, firstSymbol: letter }, this.selectedOrder.key)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((res: ResponseMessagesOffset) => {
        this.scrollToIndex(res.offset);
      });
  }

  toggleMail(messageId: string) {
    const index = this.expandedMessagesId.findIndex( msgId => msgId === messageId);
    if (index === -1) {
      this.expandedMessagesId.push(messageId);

    } else  {
      this.expandedMessagesId.splice(index, 1);
    }
  }

  isExpandedThread(messageId: string) {
    return this.expandedMessagesId.findIndex(msgId => msgId === messageId) !== -1 ? true : false;
  }

}
