// Common
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Types
import { Group } from '@modules/contacts/types/group';

@Component({
  selector: 'app-group-context-menu',
  templateUrl: './group-context-menu.component.html',
  styleUrls: ['./group-context-menu.component.less']
})
export class GroupContextMenuComponent extends ContextMenuComponent implements OnInit {

  // Inputs
  @Input() group: Group;

  // Public
  public groupForm: FormGroup;
  public groupTitleFormControl = new FormControl();

  /**
   * Constructor
   */

  constructor (
    protected contextMenuService: ContextMenuService,
    private contactsStateService: ContactsStateService,
    private ga: GoogleAnalyticsService,
    private groupsService: GroupsService,
  ) {
    super(contextMenuService);
  }

  /**
   * Component lifecycle
   */

  public ngOnInit() {
    this.groupForm = this.group.asFormGroup();
  }

  /**
   * Actions
   */

  handleEdit() {
    this.contactsStateService.setMainViewGroup(this.group);
    this.contactsStateService.setMainView('group-form');
  }

  pin(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'pin');
    this.groupsService.pin(groups.filter(group => group.pinned !== true).map(group => group.id), true);
  }

  unpin(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'unpin');
    this.groupsService.pin(groups.filter(group => group.pinned === true).map(group => group.id), false);
  }

  delete(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'delete');
    this.groupsService.delete(groups.filter(group => group.deleted !== true).map(group => group.id), true);
  }

  deletePermanently(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'delete-permanently');
    this.groupsService.deletePermanently(
      groups.filter(group => group.deleted === true).map(group => group.id));
  }

  restoreFromTrash(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'restore-from-trash');
    this.groupsService.delete(groups.filter(group => group.deleted === true).map(group => group.id), false);
  }

  moveToArchive(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'move-to-archive');
    this.groupsService.archive(
      groups
        .filter(group => group.archived === false)
        .map(group => group.id),
      true
    );
  }

  restoreFromArchive(groups: Group[]) {
    this.ga.trackEvent('group-context-menu', 'restore-from-archive');
    this.groupsService.archive(
      groups
        .filter(group => group.archived === true)
        .map(group => group.id),
      false
    );
  }

  public handleRenameSubmit() {
    this.groupForm.patchValue({ title: this.groupTitleFormControl.value });
    this.groupsService.update(Group.fromFormGroup(this.groupForm));
    this.closeContextMenu();
  }

  public handleRenameCancel() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  public closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }
}
