import { Component, Input, OnInit, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RepeaterDialogComponent } from './custom-repeater-dialog/custom-repeater-dialog.component';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-schedule-repeater-dropdown',
  templateUrl: './schedule-repeater-dropdown.component.html',
  styleUrls: ['./schedule-repeater-dropdown.component.less']
})
export class ScheduleRepeaterDropdownComponent implements OnInit, OnChanges {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() customRepeatFormControl: FormControl;
  @Input() selectedDate: string;

  // Outputs
  @Output() selected = new EventEmitter<void>();

  // Public
  public options: DropdownSelectItem[];
  public repeatForm: FormGroup;
  public timePeriodOptions = [
    {title: 'Days', value: 'days'},
    {title: 'Weeks', value: 'weeks'},
    {title: 'Months', value: 'Months'},
    {title: 'Years', value: 'Years'},
  ];

  /**
   * Constructor
   */
  constructor( private formBuilder: FormBuilder,
               private datePipe: DatePipe,
               public dialog: MatDialog) {
    this.repeatForm = this.formBuilder.group({
      repeatTimes: [1],
      repeatPeriod: [this.timePeriodOptions[1], Validators.required],
      repeatDays: [[]],
      endType: ['never'],
      endDate: [new Date()],
      endAfterOccurrenceTimes: [10],
    });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    if (this.customRepeatFormControl.value) {
        this.repeatForm.patchValue(this.customRepeatFormControl.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedDate' in changes && this.selectedDate) {
      this.options = [
        { title: 'Does not repeat', value: 'noRepeat' },
        { title: 'Daily', value: 'daily' },
        { title: `Weekly on ${this.datePipe.transform(this.selectedDate, 'EEEE')}`, value: 'weekly' },
        { title: `Annually on ${this.datePipe.transform(this.selectedDate, 'MMM dd')}`, value: 'annually' },
        { title: 'Every weekday (Monday to Friday)', value: 'weekday' },
        { title: 'Custom...', value: 'custom', itemClick: this.processSelection.bind(this) },
      ];
    }
  }

  processSelection() {
    const dialogRef = this.dialog.open(RepeaterDialogComponent, {
      width: '347',
      data: { customRepeatForm: this.repeatForm, timePeriodOptions: this.timePeriodOptions }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.customRepeatFormControl.setValue(this.repeatForm.getRawValue());
      } else if (this.customRepeatFormControl.value === null) {
          this.inputFormControl.setValue(null);
      }
    });
  }
}
