<nav class="navbar" *ngIf="isAuthenticated()">
  <div class="action-buttons">
    <button class="button-icon btn-nav" (click)="refreshMailList()">
      <mat-icon svgIcon="button-nav-refresh"></mat-icon>
    </button>
    <button class="button-icon btn-nav" (click)="composeNewMessage($event)">
      <mat-icon svgIcon="button-nav-compose"></mat-icon>
    </button>
    <button class="button-icon btn-nav" [matMenuTriggerFor]="createItemMenu">
      <mat-icon svgIcon="button-nav-modal"></mat-icon>
    </button>
    <mat-menu #createItemMenu="matMenu" yPosition="below">
      <button mat-menu-item (click)="createEvent()">New Event</button>
      <button mat-menu-item (click)="createTask()">New Task</button>
      <button mat-menu-item (click)="createNote()">New Note</button>
      <button mat-menu-item disabled (click)="createProject()">New Project</button>
    </mat-menu>
  </div>
  <app-search-input class="navbar-form"></app-search-input>
  <button class="button-icon btn-notification" matTooltip="Coming soon" matTooltipPosition="below">
    <mat-icon svgIcon="button-nav-notification"></mat-icon>
  </button>
</nav>
