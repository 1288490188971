/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../account/components/account/account.component.ngfactory";
import * as i5 from "../../../account/components/account/account.component";
import * as i6 from "./account-modal.component";
import * as i7 from "@angular/router";
var styles_AccountModalComponent = [i0.styles];
var RenderType_AccountModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountModalComponent, data: {} });
export { RenderType_AccountModalComponent as RenderType_AccountModalComponent };
export function View_AccountModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Account settings"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-account", [], null, null, null, i4.View_AccountComponent_0, i4.RenderType_AccountComponent)), i1.ɵdid(8, 114688, null, 0, i5.AccountComponent, [], { routePath: [0, "routePath"], isModal: [1, "isModal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "close"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.routePath; var currVal_4 = true; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).inline; var currVal_1 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_AccountModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-modal", [], null, null, null, View_AccountModalComponent_0, RenderType_AccountModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.AccountModalComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountModalComponentNgFactory = i1.ɵccf("app-account-modal", i6.AccountModalComponent, View_AccountModalComponent_Host_0, { routePath: "routePath" }, { closed: "closed", collapsed: "collapsed" }, []);
export { AccountModalComponentNgFactory as AccountModalComponentNgFactory };
