// Common
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

@Component({
  selector: 'app-link-info-bar',
  templateUrl: './link-info-bar.component.html',
  styleUrls: ['./link-info-bar.component.less']
})
export class LinkInfoBarComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() linkedInfo: LinkedInfo[];
  @Input() linkIcon = false;

  // Public
  public popoverClose = new Subject<void>();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  /**
   * Actions
   */

  closePopover() {
    this.popoverClose.next();
  }

}
