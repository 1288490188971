// Common
import { Component, Input, ChangeDetectorRef, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef, Optional } from '@angular/core';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';

// Services
import { ContentMenuService } from '../../services/content-menu.service';

@Component({
  selector: 'app-content-menu-checkbox',
  templateUrl: './content-menu-checkbox.component.html',
  styleUrls: ['./content-menu-checkbox.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuCheckboxComponent }]
})
export class ContentMenuCheckboxComponent extends ContentMenuBaseItemComponent implements OnChanges {

  // Public
  public headerHeight = 24;

  // Inputs
  @Input() icon: string;
  @Input() title: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() color: string;

  // Outputs
  @Output() checkedChange = new EventEmitter<Boolean>();

  /**
   * Constructor
   */

  constructor (
    changeDetector: ChangeDetectorRef,
    private element: ElementRef,
    @Optional() contentMenuService: ContentMenuService
  ) {
    super(changeDetector, contentMenuService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('color' in changes) {
      this.element.nativeElement.style.setProperty('--checkboxColor', this.color);
    }
  }

  /**
   * Actions
   */

  toggle() {
    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
