<div
  #scrollBody
  class="scroll-body"
  cdkScrollable
>
  <ng-content></ng-content>
</div>

<div class="scroll-bottom-gradient"></div>

<div #scrollBar class="scroll-bar">
  <div class="scroll-bar-inner">
    <mat-icon
      class="scroll-bar-inner-arrow"
      svgIcon="arrow-up"
      (click)="handleUp()"
    ></mat-icon>
    <div class="scroll-bar-inner-divider"></div>
    <mat-icon
      class="scroll-bar-inner-arrow"
      svgIcon="arrow-down"
      (click)="handleDown()"
    ></mat-icon>
  </div>
</div>
