// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Types
import { DropdownOption } from '../../types/dropdown-option';

@Component({
  selector: 'app-dropdown-single-select',
  templateUrl: './dropdown-single-select.component.html',
  styleUrls: ['./dropdown-single-select.component.less']
})
export class DropdownSingleSelectComponent implements OnInit {

  // Inputs
  @Input() title: string;
  @Input() selected: DropdownOption;
  @Input() options: DropdownOption[];
  @Input() disabled: DropdownOption[] = [];
  @Input() divider = false;

  // Outputs
  @Output() selectedChange: EventEmitter<DropdownOption> = new EventEmitter();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  select(option: DropdownOption) {
    if (this.disabled.includes(option)) {
      return;
    }
    this.selectedChange.emit(option);
  }

}
