<div class="dropdown-header" *ngIf="title">{{ title }}</div>

<app-dropdown
  class="dropdown-nested"
  trigger="hover"
  placement="right"
>
  <ng-template #toggleButtonTemplate>
    <app-dropdown-item
      [title]="name"
      [arrowIcon]="true"
    ></app-dropdown-item>
  </ng-template>
  <ng-content></ng-content>
</app-dropdown>

<div class="divider" *ngIf="divider"></div>
