import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// Types
import { BaseModalComponent } from '../base-modal/base-modal.component';
var CalendarEventModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarEventModalComponent, _super);
    /**
     * Constructor
     */
    function CalendarEventModalComponent(calendarService) {
        var _this = _super.call(this) || this;
        _this.calendarService = calendarService;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 350px)',
            y: 'calc(50% - 250px)',
            width: '700px',
            height: '500px'
        };
        _this.selectedDate = new Date();
        return _this;
    }
    /**
     * Component lifecycle
     */
    CalendarEventModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.calendarEvent = null;
    };
    /**
     * Actions
     */
    CalendarEventModalComponent.prototype.updatedEvent = function () {
        this.calendarService.createNewEvent.next(this.calendarEvent);
        this.close();
    };
    return CalendarEventModalComponent;
}(BaseModalComponent));
export { CalendarEventModalComponent };
