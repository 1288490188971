// Common
import {
  Directive, ElementRef, Input, OnDestroy, HostListener, HostBinding, TemplateRef, OnInit, Output,
  EventEmitter,
  NgZone
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

// RX
import { Subject, Subscription, fromEvent } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// Components
import { DraggableElementComponent } from '../components/draggable-element/draggable-element.component';

// Services
import { DragNDropService } from '../services/drag-n-drop.service';

// Types
import { DragData } from '../types/drag-data';

@Directive({
  selector: '[appDraggable]',
})
export class DraggableDirective implements OnInit, OnDestroy {

  // Private
  private overlayRef: OverlayRef;
  private isDragging = false;
  private alive: Subject<void> = new Subject();
  private returnBackAnimationSubscription: Subscription;

  // Inputs
  @Input() appDraggableData: DragData;
  @Input() appDraggablePlaceholder: TemplateRef<any>;
  @Input() @HostBinding('draggable') appDraggableEnabled = true;
  @Input() appDraggableContainerStyles: Object;
  @Input() appDraggableContainerMultiple = false;
  @Input() appDraggableContainerAdjustFit = false;
  @Input() appDraggableNoShadow = false;

  // Outputs
  @Output() appDraggableDraggingChanged = new EventEmitter<boolean>();

  /**
   * Constructor
   */

  constructor (
    private elementRef: ElementRef,
    private overlay: Overlay,
    private dragNDropService: DragNDropService,
    private ngZone: NgZone
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.dragNDropService.getDraggingDataChanges()
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe((data: DragData) => {
        this.isDragging = !!data;
        if (!this.isDragging) {
          this.dragNDropService.setControlledDrop(null);
          this.stopDragging();
        }
        this.appDraggableDraggingChanged.emit(this.isDragging);
      });

    this.ngZone.runOutsideAngular(() => {
      // @TODO: Clarify why that subs is needed, as do not see a big reason for it
      fromEvent(this.elementRef.nativeElement, 'click')
        .pipe(
          filter(() => this.isDragging),
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        });
    });

    fromEvent(this.elementRef.nativeElement, 'dragstart')
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((event: MouseEvent) => {
        event.stopImmediatePropagation();
        this.dragNDropService.setDragging(this.appDraggableData);

        // @TODO: Check that there is no bug when placeholder is not set, what will be shown dragging then?
        if (this.appDraggablePlaceholder) {
          this.startDragging(event);
          event.preventDefault();
        }
      });
  }

  ngOnDestroy(): void {
    this.stopDragging();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private stopDragging() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private startDragging(event: MouseEvent): void {
    this.overlayRef = this.overlay.create();
    const componentPortal = new ComponentPortal(DraggableElementComponent);
    const componentRef = this.overlayRef.attach(componentPortal);

    componentRef.instance.dragPlaceholder = this.appDraggablePlaceholder;
    componentRef.instance.multipleStyle = this.appDraggableContainerMultiple;
    componentRef.instance.noShadow = this.appDraggableNoShadow;

    const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();

    // @TODO: Revisit that initial position setting, as of now there is a glitch where dragable element blinks in 0,0 position
    // This is change to avoid the glich described, but Pretty sure there is better decision but require complete refactoring

    componentRef.instance.homeTop = boundingRect.top;
    componentRef.instance.homeLeft = boundingRect.left;

    if (this.appDraggableContainerAdjustFit) {
      componentRef.instance.mouseYAdjustment = 0;
      componentRef.instance.mouseXAdjustment = 0;
    } else {
      componentRef.instance.mouseXAdjustment = event.pageX - boundingRect.left;
      componentRef.instance.mouseYAdjustment = event.pageY - boundingRect.top;
      componentRef.instance.width = boundingRect.width;
      componentRef.instance.height = boundingRect.height;
    }

    if (this.appDraggableContainerStyles) {
      componentRef.instance.customStyles = this.appDraggableContainerStyles;
    }

    if (this.returnBackAnimationSubscription) {
      this.returnBackAnimationSubscription.unsubscribe();
    }

    this.returnBackAnimationSubscription = componentRef.instance.animationFinish
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.dragNDropService.setDragging(null);
      });
  }
}
