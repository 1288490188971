// Common
import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class File {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  title?: string;
  description?: string;
  type?: any;
  pinned?: boolean;
  starred?: boolean;
  linkedInfo?: LinkedInfo[];
  createdAt: Date;
  updatedAt: Date;

  // Tech properties

  constructor(fileObject: any = {}) {
    this.id = fileObject.id;
    this.title = fileObject.title;
    this.description = fileObject.description;
    this.type = fileObject.type;
    this.pinned = fileObject.pinned;
    this.starred = fileObject.starred;
    this.linkedInfo = fileObject.linkedInfo;
    this.createdAt = new Date(Date.parse(fileObject.createdAt));
    this.updatedAt = new Date(Date.parse(fileObject.createdAt));
  }

  static fromFormGroup(form: FormGroup): File {
    const formValues = form.value;

    return new File({
      id: formValues.id,
      title: formValues.title,
      description: formValues.description,
    });
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      description: [this.description || ''],
    });
  }
}
