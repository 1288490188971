<div class="contact-form">
  <form [formGroup]="contactForm" (ngSubmit)="handleSubmit()">

    <div class="contact-form-horizontal-group-full-width-item">
      <app-input
        placeholder="Name"
        appearance="standard"
        icon="contacts"
        [inputFormControl]="contactForm.controls['name']"
        [bundledInputConsumerGroup]="bundledGroup"
        [bundledInputConsumerKeys]="['title']"
      ></app-input>
    </div>

    <div class="contact-form-horizontal-group-full-width-item">
      <app-input
        placeholder="Work Email"
        [inputFormControl]="contactForm.controls['email']"
        width="100%"
      ></app-input>
    </div>

    <div class="contact-form-horizontal-group-full-width-item">
      <app-input
        placeholder="Work Phone Number"
        [inputFormControl]="contactForm.controls['phoneNumber']"
        width="100%"
      ></app-input>
    </div>

    <div class="contact-form-buttons">
      <app-button
        class="contact-form-buttons-item"
        appearance="link-blue"
        (click)="handleMoreOptions()"
      >
        More Options
      </app-button>

      <div class="contact-form-buttons-container">
        <app-button
          class="contact-form-buttons-item"
          appearance="link"
          [disabled]="saveInProgress"
          (click)="handleClickCancel()"
        >
          Cancel
        </app-button>

        <app-button
          class="contact-form-buttons-item"
          type="submit"
          appearance="link-blue"
          [disabled]="saveInProgress"
        >
          Save
        </app-button>
      </div>
    </div>

  </form>
</div>
