// Common
import { Directive, Input, ComponentRef, EventEmitter, Output } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { CalendarCellContextMenuComponent } from '../components/calendar-cell-context-menu/calendar-cell-context-menu.component';
import { CalendarType } from '@modules/events/types/calendar-type';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appCalendarCellContextMenu]'
})
export class CalendarCellContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appCalendarCellContextMenu: Date;
  @Input() appCalendarCellContextMenuCalendarType: CalendarType;
  @Input() contextMenuComponent = CalendarCellContextMenuComponent;

  // Outputs
  @Output() appCalendarCellContextMenuLoadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  setContextMenuInstanceData(componentRef: ComponentRef<CalendarCellContextMenuComponent>) {
    componentRef.instance.date = this.appCalendarCellContextMenu;
    componentRef.instance.calendarType = this.appCalendarCellContextMenuCalendarType;
    componentRef.instance.loadDayEvents
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appCalendarCellContextMenuLoadDayEvents.emit();
      });
  }
}
