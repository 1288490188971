// Common
import { Injectable } from '@angular/core';

// RX
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// Types
import { BundledEvent } from '../types/bundled-event';

@Injectable()
export class BundledInputsService {

  // Private
  private bundledEventsThread = new Subject<BundledEvent>();

  emitBundledEvent(event: BundledEvent) {
    this.bundledEventsThread.next(event);
  }

  bundledEvents(keys: string[]): Observable<BundledEvent> {
    return this.bundledEventsThread
      .asObservable()
      .pipe(
        filter((event: BundledEvent) => keys.includes(event.key)),
      );
  }
}
