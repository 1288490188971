<div class="content">
  <div class="page-calendar-header">
    <div class="today-btn" (click)="setToday()"> Today </div>
    <div class="controls">
      <mat-icon (click)="prevDate()" class="calendar-nav__icon" svgIcon="button-pagination-left"></mat-icon>
      <mat-icon (click)="nextDate()" class="calendar-nav__icon" svgIcon="button-pagination-right"></mat-icon>
    </div>

    <div class="headerInput">
      <input 
        #yearInput
        [type]="calendarType.key === 'year' ? 'number' : 'text'"
        [value]="displayHeaderTitle"
        (change)="setYear(yearInput.value)"
        [disabled]="calendarType.key !== 'year'"
        [ngClass]="{year: calendarType.key === 'year'}"
      />
      <div *ngIf="(['week', 'workWeek'] | includesBy: calendarType.key)" class="weekNumber">
        Week {{ selectedDates[calendarType.key] | date : 'w' }}
      </div>
    </div>

    <app-dropdown align="right" [closeOnSelect]="true" [title]="calendarType.name">
      <ng-template #toggleButtonTemplate>
        <div class="btn-group">
          <button class="btn btn-default">{{ calendarType.name }}<span class="caret"></span></button>
        </div>
      </ng-template>
      <app-dropdown-single-select
        [options]="calendarTypes"
        [selected]="calendarType"
        (selectedChange)="selectCalendarType($event.key)"  
      ></app-dropdown-single-select>
    </app-dropdown>
  </div>

  <div [ngSwitch]="calendarType.key" class="full-height">
    <full-calendar-year
      *ngSwitchCase="'year'"
      [viewDate]="stateService.getSelectedCalendarDates('year') | async"
      [selectedDate]="selectedDate"
      [events]="events"
      [highlightedDay]="highlightedDate"
      [quickPreviewTemplate]="quickPreviewTemplate"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    ></full-calendar-year>

    <full-calendar-month
      *ngSwitchCase="'month'"
      [viewDate]="stateService.getSelectedCalendarDates('month') | async"
      [selectedDate]="selectedDate"
      [events]="events"
      [highlightedDay]="highlightedDate"
      [badgeTemplate]="badgeTemplate"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (dayNumberDblClicked)="handleDayNumberDblClick($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    ></full-calendar-month>

    <full-calendar-week
      *ngSwitchCase="(['week', 'workWeek'] | includesBy: calendarType.key) && calendarType.key"
      [viewDate]="stateService.getSelectedCalendarDates(calendarType.key) | async"
      [selectedDate]="selectedDate"
      [events]="events"
      [eventTemplate]="badgeTemplate"
      [highlightedDay]="highlightedDate"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (dayNumberDblClicked)="handleDayNumberDblClick($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    ></full-calendar-week>

    <full-calendar-day
      *ngSwitchCase="'day'"
      [viewDate]="stateService.getSelectedCalendarDates('day') | async"
      [selectedDate]="selectedDate"
      [highlightedDay]="highlightedDate"
      [events]="events"
      [eventTemplate]="badgeTemplate"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    ></full-calendar-day>

    <app-events-agenda
      *ngSwitchCase="'agenda'"
      [viewDate]="stateService.getSelectedCalendarDates('agenda') | async"
      (headerDateClick)="handleOpenInListView($event)"
    ></app-events-agenda>
  </div>
</div>

<ng-template #badgeTemplate let-event="event" let-weekEvent="weekEvent" let-showTime="showTime">
  <app-event-badge
    [event]="event ? event?.meta : weekEvent?.event?.meta"
    [showTime]="showTime"
    [view]="calendarType.key"
    [hoverPlacement]="calendarType.key === 'day' ? 'top' : 'right'"
    (popoverFormClose)="handlePopoverFormClose()"
  ></app-event-badge>
</ng-template>

<ng-template #quickPreviewTemplate let-day="day" let-calendarEvents="events">
  <events-quick-preview [day]="day" [events]="calendarEvents"></events-quick-preview>
</ng-template>

<!-- form popover for year calendar - since there is no badge -->
<ng-template #popoverFormTemplate>
  <app-event-form-popover
    [event]="virtualEvent | async"
    (close)="handlePopoverFormClose()"
  ></app-event-form-popover>
</ng-template>
