import { MailMessage } from './mail-message';

export class ResponseModel {
  success: boolean;
}

export class ResponseMessagesOffset extends ResponseModel {
  offset: number;
}

export class ResponseMessages {
  messages: MailMessage[];
  count: number;
}
