// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ContextMenuService } from 'ngx-contextmenu';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { EventsStateService } from '@modules/events/services/events-state.service';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarType } from '@modules/events/types/calendar-type';
import { Clipboard } from '@modules/events/types/clipboard';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

@Component({
  selector: 'app-calendar-cell-context-menu',
  templateUrl: './calendar-cell-context-menu.component.html',
  styleUrls: ['./calendar-cell-context-menu.component.less']
})
export class CalendarCellContextMenuComponent extends ContextMenuComponent implements OnInit, OnDestroy {

  // Public
  public clipboard = false;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject<void>();

  // Inputs
  @Input() date: Date;
  @Input() calendarType: CalendarType;

  // Outputs
  @Output() loadDayEvents: EventEmitter<null> = new EventEmitter<null>();

  /**
   * Constructor
   */

  constructor(
    protected contextMenuService: ContextMenuService,
    private eventsStateService: EventsStateService,
  ) {
    super(contextMenuService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.eventsStateService.getClipboard()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((clipboard: Clipboard) => this.clipboard = !!clipboard);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  createEvent() {
    this.eventsStateService.openEventForm(new CalendarEvent({
      when: {
        durationType: 'day',
        start: this.date,
        end: this.date,
      }
    }));
  }

  handleLoadDayEvents() {
    this.loadDayEvents.emit();
  }

  pasteEvents() {
    this.eventsStateService.performPaste(this.date);
  }

  openCalendar(type: CalendarType) {
    this.eventsStateService.setCalendarView(type, this.date);
  }
}
