// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject, Observable, EMPTY } from 'rxjs';
import { takeUntil, map, switchMap, tap, startWith } from 'rxjs/operators';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';
import { ColumnsListResponse } from '@modules/tasks/types/columns-list-response';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Tag } from '@modules/tag/types/tag';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';

// Animations
import { collapseMotion } from '@modules/form-controls/animations/collapse-animation';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.less'],
  animations: [collapseMotion]
})
export class TaskFormComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() task: Task = new Task();
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';
  @Input() submit: Observable<void>;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public taskForm: FormGroup = this.task.asFormGroup();
  public displayFixedBigHeaderShadow = false;
  public organizerOptions = [{ title: 'mymail@mail.com', value: 'mymail@mail.com' }];
  public columnOptions: DropdownSelectItem[] = [];
  public projectOptions: DropdownSelectItem[] = [];
  public saveInProgress = false;
  public dynamicPanel: DynamicPanelType;
  public displayDescription = false;
  public closeDroppedElement = new Subject<void>();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private projectChanged: Subject<void> = new Subject<void>();
  private dragData: DragData;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'task' && dragData.data.length === 1 && dragData.data[0]['id'] === this.task.id) &&
    ['message', 'event', 'project', 'task', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService,
    private projectsService: ProjectsService,
    private dynamicPanelService: DynamicPanelService,
    private columnsService: ColumnsService,
    private linkedInfoService: LinkedInfoService,
    private topicService: TopicService,
    private tagService: TagService,
    private tasksStateService: TasksStateService,
  ) {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  ngOnInit() {
    this.projectsService.getProjects()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map(({projects}) => projects)
      )
      .subscribe((projects: Project[]) => {
        this.projectOptions = projects.map(project => ({title: project.title, value: project.id }));
      });

    this.projectChanged
      .pipe(
        switchMap(() => this.taskForm.controls['projectId'].valueChanges
          .pipe(
            startWith(this.taskForm.get('projectId').value as string)
          )
        ),
        switchMap(projectId => this.columnsService.getColumns({ projectsIds: projectId })),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({ columns }: ColumnsListResponse) => {
        this.columnOptions = columns.map(column => ({ title: column.title, value: column.id }));
      });
    this.projectChanged.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('task' in changes && this.task) {
      this.taskForm = this.task.asFormGroup();
      this.projectChanged.next();
      this.dynamicPanelService.setContent();
    }
    // TODO need to fix this memory leak
    if ('submit' in changes && this.submit) {
      this.submit
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe(() => this.handleSubmit());
    }
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (!this.taskForm.valid) {
      return;
    }

    this.saveInProgress = true;

    this.tasksService.upsert(this.taskForm)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleCancel() {
    this.close.emit();
  }

  openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('attachments', { attachments: this.task.files, parentTitle: this.task.title });
    }
  }

  openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.task.linkedInfo,
        parentLinkedInfo: { type: 'event', id: this.task.id },
        parentTitle: this.task.title
      });
    }
  }

  public handleCreateSubTaskOnDrop(): void {
    this.closeDroppedElement.next();
  }

  public handleLinkOnDrop(): void {
    if (!this.task || !this.dragData || !this.dragData.data) { return; }

    if (['message', 'event', 'project', 'task', 'note'].includes(this.dragData.type) && this.dragData.data) {
      const items = this.dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(this.dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('task', this.task.id), linkedItems);
    }

    this.closeDroppedElement.next();
  }

  dndDrop(dragData: DragData) {
    if (!this.task) { return; }

    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {tasksIds: [this.task.id]});
      this.closeDroppedElement.next();
    } else if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {tasksIds: [this.task.id]});
      this.closeDroppedElement.next();
    } else {
      this.dragData = dragData;
    }
  }

  pin() {
    this.tasksService.pin([this.task.id], !this.task.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.pinned = tasks[0].pinned;
        }
      });
  }

  archive() {
    this.tasksService.archive([this.task.id], !this.task.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.archived = tasks[0].archived;
        }
      });
  }

  delete() {
    this.task.deleted ?
      this.tasksService.deletePermanently([this.task.id]) :
      this.tasksService.delete([this.task.id], true);

    this.tasksStateService.setMainView('empty');
  }
}
