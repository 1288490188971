import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnChanges, EventEmitter, SimpleChanges, NgZone, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
// RX
import { Subject, of, forkJoin, fromEvent } from 'rxjs';
import { catchError, tap, takeUntil, switchMap, startWith } from 'rxjs/operators';
// Types
import { FileUpload } from '@modules/form-controls/types/file-upload';
// Animations
var expandAnimation = trigger('expandAnimation', [
    transition('expanded => collapsed', [
        query('.attachment.to-hide', [
            style({ opacity: 1, width: '*', border: '0' }),
            stagger(-50, [
                animate('150ms ease-in-out', style({ opacity: 0, width: 0, margin: 0, padding: 0, border: 'none' }))
            ])
        ])
    ]),
    transition('collapsed => expanded', [
        query('.attachment.to-hide', [
            style({ opacity: 0, width: 0 }),
            stagger(50, [
                animate('150ms ease-in-out', style({ opacity: 1, width: '*' }))
            ])
        ])
    ])
]);
var FileUploaderComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FileUploaderComponent(mailService, modalService, ngZone) {
        this.mailService = mailService;
        this.modalService = modalService;
        this.ngZone = ngZone;
        // Constants
        this.amountOfCollapsedAttachments = 5;
        // Outputs
        this.attachmentsChange = new EventEmitter();
        this.uploading = new EventEmitter();
        // Public
        this.uploads = [];
        this.dragOverEventsCounter = 0;
        this.expandedAttachments = false;
        // Private
        this.componentNotDestroyed = new Subject();
        this.attachmentsFormControlChanged = new Subject();
    }
    /**
     * Component lifecycle
     */
    FileUploaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.attachmentsFormControl) {
            return;
        }
        this.attachmentsFormControlChanged
            .pipe(switchMap(function () {
            return _this.attachmentsFormControl.valueChanges
                .pipe(startWith(_this.attachmentsFormControl.value));
        }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (values) {
            var _a;
            _this.uploads = _this.uploads.filter(function (upload) {
                return !upload.attachment || values.some(function (attachment) { return upload.attachment.id === attachment.id; });
            });
            (_a = _this.uploads).push.apply(_a, tslib_1.__spread(values
                .filter(function (attachment) { return !_this.uploads.some(function (upload) { return upload.attachment && upload.attachment.id === attachment.id; }); })
                .map(function (attachment) { return ({
                file: null,
                attachment: attachment,
                status: 'uploaded'
            }); })));
        });
        this.attachmentsFormControlChanged.next();
    };
    FileUploaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.attachmentsFormControl) {
            return;
        }
        this.ngZone.runOutsideAngular(function () {
            fromEvent(_this.droppableArea.nativeElement, 'dragover')
                .pipe(takeUntil(_this.componentNotDestroyed))
                .subscribe(function (event) { return event.preventDefault(); });
        });
    };
    FileUploaderComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        var _this = this;
        this.attachments = this.attachments || [];
        if ('attachments' in changes) {
            this.uploads = this.uploads.filter(function (upload) {
                return !upload.attachment || _this.attachments.some(function (attachment) { return upload.attachment.id === attachment.id; });
            });
            (_a = this.uploads).push.apply(_a, tslib_1.__spread(this.attachments
                .filter(function (attachment) { return !_this.uploads.some(function (upload) { return upload.attachment && upload.attachment.id === attachment.id; }); })
                .map(function (attachment) { return ({
                file: null,
                attachment: attachment,
                status: 'uploaded'
            }); })));
        }
        if ('upload' in changes && this.upload) {
            this.upload
                .pipe(takeUntil(this.componentNotDestroyed))
                .subscribe(function (files) { return _this.uploadFiles(files); });
        }
        if ('attachmentsFormControl' in changes) {
            this.attachmentsFormControlChanged.next();
        }
    };
    FileUploaderComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    FileUploaderComponent.prototype.uploadDroppedFiles = function (event) {
        if (event.dataTransfer && event.dataTransfer.files) {
            this.uploadFiles(event.dataTransfer.files);
        }
        event.stopPropagation();
        event.preventDefault();
    };
    FileUploaderComponent.prototype.uploadSelectedFiles = function (event) {
        var filesInput = event.target;
        if (filesInput && filesInput.files) {
            this.uploadFiles(filesInput.files);
        }
        filesInput.value = null;
    };
    FileUploaderComponent.prototype.removeFile = function (upload) {
        var index = this.uploads.indexOf(upload, 0);
        if (index < 0) {
            return;
        }
        var attachment = this.uploads[index].attachment;
        if (!attachment) {
            return;
        }
        // This is a temporary solution needed until the mail modules are refactored.
        if (this.attachmentsFormControl) {
            var newAttachments = this.attachmentsFormControl.value.filter(function (item) { return item.id !== attachment.id; });
            this.attachmentsFormControl.setValue(newAttachments);
        }
        else if (this.attachments) {
            var newAttachments = this.attachments.filter(function (item) { return item.id !== attachment.id; });
            this.attachmentsChange.emit(newAttachments);
        }
        else {
            this.uploads.splice(index, 1);
        }
    };
    FileUploaderComponent.prototype.cancelRequest = function (upload) {
        var index = this.uploads.indexOf(upload, 0);
        if (index > -1 && upload.request) {
            upload.cancel.next();
            upload.cancel.complete();
            this.uploads.splice(index, 1);
        }
    };
    FileUploaderComponent.prototype.showAttachment = function (file) {
        if (file && file.status !== 'uploaded') {
            return;
        }
        this.modalService.showAttachmentsModal([file.attachment], 0);
    };
    FileUploaderComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (files && files.length) {
            this.uploading.emit(true);
            var uploadsObservers = [];
            for (var i = 0; i < files.length; i++) {
                uploadsObservers.push(this.uploadFile(files[i]));
            }
            forkJoin(uploadsObservers)
                .pipe(takeUntil(this.componentNotDestroyed))
                .subscribe(function () { return _this.uploading.emit(false); });
        }
    };
    FileUploaderComponent.prototype.uploadFile = function (file) {
        var _this = this;
        var upload = new FileUpload();
        upload.file = file;
        upload.status = 'uploading';
        upload.cancel = new Subject();
        this.uploads.push(upload);
        if (file.size > 26214400) {
            upload.status = 'error';
            upload.error = 'File is too large. Maximum size 25MB.';
            return of(null);
        }
        var request = this.mailService
            .uploadFile(upload.file)
            .pipe(takeUntil(this.componentNotDestroyed), takeUntil(upload.cancel), tap(function (attachment) {
            upload.attachment = attachment;
            upload.status = 'uploaded';
            // This is a temporary solution needed until the mail modules are refactored.
            if (_this.attachmentsFormControl) {
                var newAttachments = _this.attachmentsFormControl.value.concat(attachment);
                _this.attachmentsFormControl.setValue(newAttachments);
            }
            else {
                var newAttachments = _this.attachments.concat(attachment);
                _this.attachmentsChange.emit(newAttachments);
            }
        }), catchError(function (error) {
            upload.status = 'error';
            upload.error = error.message || 'Unexpected error';
            return of(null);
        }));
        upload.request = request;
        return request;
    };
    FileUploaderComponent.prototype.handleExpand = function () {
        this.expandedAttachments = !this.expandedAttachments;
    };
    return FileUploaderComponent;
}());
export { FileUploaderComponent };
