// Core
import { Injectable } from '@angular/core';

// RxJS
import { Observable, of, merge, timer } from 'rxjs';
import { tap, catchError, map, take, delayWhen } from 'rxjs/operators';

// Services
import { ToastrService } from 'ngx-toastr';
import { AsyncTasksService } from './async-tasks.service';

// Types
import { ActiveToast } from 'ngx-toastr';
import { AsyncTask } from '../types/async-task';
import { AsyncTaskToastSettings } from '../types/async-task-toast-settings';
import { AsyncTaskProgressToastSettings } from '../types/async-task-progress-toast-settings';
import { AsyncTaskToastAction } from '../types/async-task-toast-action';

@Injectable({
  providedIn: 'root'
})
export class AsyncTasksToastsService {

  constructor(
    private asyncTasksService: AsyncTasksService,
    private toastrService: ToastrService
  ) { }

  private getToastSettings(status: string, settings: AsyncTaskProgressToastSettings): AsyncTaskToastSettings {
    return {
      text: settings.status[status].text,
      icon: settings.status[status].icon,
      actions: (settings.status[status].actions || []).reduce(
        (actions: AsyncTaskToastAction[], actionName: string) => {
          if (settings.actions && settings.actions[actionName]) {
            actions.push(settings.actions[actionName]);
          }
          return actions;
        },
        [] as AsyncTaskToastAction[]
      )
    };
  }

  show(settings: AsyncTaskToastSettings, disableTimeOut = false): ActiveToast<boolean> {
    const toast = this.toastrService.show(
      settings.text,
      null,
      // Temp solution due to a bug in latest version
      // TODO: remove once PR is merged: https://github.com/scttcper/ngx-toastr/pull/723
      { disableTimeOut: disableTimeOut ? 'timeOut' : false }
    );

    toast.toastRef.componentInstance.setActions(settings.actions);
    toast.toastRef.componentInstance.setIcon(settings.icon);

    return toast;
  }

  showAwaitProgress(asyncTask: AsyncTask, settings: AsyncTaskProgressToastSettings): Observable<boolean> {
    if (!asyncTask || asyncTask.status === 'completed') {
      this.show(this.getToastSettings('completed', settings));
      return of(true);
    }

    if (asyncTask.status === 'error') {
      this.show(this.getToastSettings('error', settings));
      return of(false);
    }

    const toast = this.show(this.getToastSettings('processing', settings), true);
    const delayUntil = new Date().getTime() + 1000;

    return merge(
      toast.onAction.pipe(map(() => false)),
      this.asyncTasksService
        .await(asyncTask)
        .pipe(
          delayWhen(() => timer(delayUntil - new Date().getTime())),
          catchError(() => of(false)),
          tap(success => {
            this.toastrService.remove(toast.toastId);
            this.show(this.getToastSettings(success ? 'completed' : 'error', settings));
          })
        )
    )
      .pipe(take(1));
  }
}
