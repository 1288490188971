<context-menu #contextMenu>

  <ng-template *ngIf="clipboard" contextMenuItem (execute)="pasteEvents()">
    Paste
  </ng-template>

  <ng-template *ngIf="clipboard" contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="createEvent()">
    Create Event
  </ng-template>

  <ng-template contextMenuItem (execute)="handleLoadDayEvents()">
    Load Day's Events in List View
  </ng-template>

  <ng-template
    contextMenuItem
    (execute)="openCalendar('day')"
    *ngIf="calendarType !== 'day'"
  >
    Open Day
  </ng-template>
  
  <ng-template
    contextMenuItem
    (execute)="openCalendar('week')"
    *ngIf="calendarType !== 'week'"
  >
    Open Week
  </ng-template>
  
  <ng-template 
    contextMenuItem
    (execute)="openCalendar('month')"
    *ngIf="calendarType !== 'month'"
  >
    Open Month
  </ng-template>
  
  <ng-template 
    contextMenuItem
    (execute)="openCalendar('year')"
    *ngIf="calendarType !== 'year'"
  >
    Open Year
  </ng-template>

  <ng-template 
    contextMenuItem
    (execute)="openCalendar('agenda')"
  >
    Open Agenda
  </ng-template>

</context-menu>
