// Common
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// RX
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';
import { DynamicPanelContent, DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.less']
})
export class AttachmentsListComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() attachments: Attachment[];
  @Input() attachmentsFormControl: AbstractControl;
  @Input() upload: Observable<FileList>;
  @Input() dynamicPanel = false;
  @Input() extendIconEnabled = true;

  // Outputs
  @Output() attachmentsChange = new EventEmitter<Attachment[]>();
  @Output() uploading = new EventEmitter<boolean>();
  @Output() extendAction = new EventEmitter();

  // Public
  public dynamicPanelType: DynamicPanelType;

  // Private
  private componentNotDestroyed = new Subject<void>();

  // Constructor
  constructor(
    private modalService: ModalService,
    private dynamicPanelService: DynamicPanelService
  ) { }

  // Component lifecycle
  public ngOnInit(): void {
    this.dynamicPanelService.getContent()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({ type }: DynamicPanelContent) => this.dynamicPanelType = type);
  }

  public ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  // Actions

  public downloadAll() {
    const resources: Attachment[] = this.attachmentsFormControl ? this.attachmentsFormControl.value : this.attachments;
    resources.forEach((attachment: Attachment) => window.open(attachment.url, '_blank'));
  }

  public showAttachments() {
    const resources: Attachment[] = this.attachmentsFormControl ? this.attachmentsFormControl.value : this.attachments;
    this.modalService.showAttachmentsModal(resources, 0);
  }

}
