// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { of, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';

@Component({
  selector: 'app-link-info-note-suggestions',
  templateUrl: './link-info-note-suggestions.component.html',
  styleUrls: ['./link-info-note-suggestions.component.less']
})
export class LinkInfoNoteSuggestionsComponent extends LinkInfoItemSuggestions implements OnInit, OnDestroy {

  /**
   * Constructor
   */

  constructor(
    protected modalService: ModalService,
    protected notesService: NotesService,
    protected linkedInfoService: LinkedInfoService
  ) {
    super(linkedInfoService);
  }

  /**
   * Methods
   */

  //  Override method LinkInfoItemSuggestions
  getSuggestions(inputValue: string): Observable<any[]> {
    if (inputValue && inputValue.length) {
      return this.notesService.getNotes({
        keywords: [inputValue],
        order: 'created-asc',
        limit: 10,
        offset: 0
      })
        .pipe(
          takeUntil(this.componentNotDestroyed),
          map(({notes}) => notes || [])
        );
    }
    return of([]);
  }

  //  Override method LinkInfoItemSuggestions
  openCreateModal() {
  }

}
