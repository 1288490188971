// Common
import {
  OnInit, OnChanges, OnDestroy, AfterViewInit, Input, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, NgZone
} from '@angular/core';

// RxJS
import { Subject, Observable, of, fromEvent } from 'rxjs';
import { takeUntil, tap, map, switchMap } from 'rxjs/operators';

// Types
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';

export class LinkInfoPopover implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  // Inputs
  @Input() linkedInfo: LinkedInfo[];

  // Outputs
  @Output() close = new EventEmitter();

  // ViewChild
  @ViewChild('scrollList', { static: true }) scrollList: ElementRef;

  // Public
  public items: MailMessage[] | CalendarEvent[] | Project[] | Task[] | Note[] | Group[] | Contact[];
  public loading = false;
  public showHeaderShadow = false;
  public loadLinkedInfoData = new Subject<void>();

  // Protected
  protected type: LinkedInfoType;
  protected componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    protected ngZone: NgZone
  ) {
    this.loadLinkedInfoData
      .pipe(
        map(() => (this.linkedInfo || []).filter(info => info.type === this.type).map(info => info.id)),
        tap(() => this.loading = true),
        switchMap(ids => ids.length ? this.getItems(ids) : of([])),
        tap(() => this.loading = false),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((items: MailMessage[] | CalendarEvent[] | Project[] | Task[] | Note[] | Group[] | Contact[]) => this.items = items);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollList.nativeElement, 'scroll')
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((event: MouseEvent) => {
          const value = event.target['scrollTop'] !== 0;
          if (this.showHeaderShadow !== value) {
            this.ngZone.run(() => this.showHeaderShadow = value);
          }
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('linkedInfo' in changes) {
      this.loadLinkedInfoData.next();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }


  /**
   * Methods
   */

   getItems(ids: string[]): Observable<MailMessage[] | CalendarEvent[] | Project[] | Task[] | Note[] | Group[] | Contact[]> {
     return of([]);
   }

  /**
   * Actions
   */

  closed() {
    this.close.emit();
  }

  select(item: any) {

  }

}
