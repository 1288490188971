<div class="content-menu-tags__wrapper">
  <!-- Title -->
  <div
    class="header"
    popover
    [popoverContent]="contentTemplate"
    [popoverInnerShadow]="true"
    popoverPlacement="right"
    [popoverDisabled]="!minimized || !tags?.length"
    popoverTrigger="click"
  >
    <mat-icon svgIcon="dropdown-down" class="dropdown-icon" [ngClass]="{'collapsed': collapsed || minimized}" (click)="trigger()"></mat-icon>

    <div class="icon-container">
      <mat-icon svgIcon="tags-small" class="icon"></mat-icon>
      <mat-icon svgIcon="pin-small-color" class="pin-icon"></mat-icon>
    </div>

    <div class="flex-container">
      <div class="title" *ngIf="!minimized" (click)="trigger()">Tags</div>

      <app-dropdown *ngIf="!minimized" [closeOnSelect]="true" class="filter-dropdown">
        <ng-template #toggleButtonTemplate>
          <div class="filter-dropdown-icon-container">
            <mat-icon svgIcon="filter-sorting" class="filter-dropdown-icon-container-item"></mat-icon>
          </div>
        </ng-template>

        <app-dropdown-single-select
          [options]="orders"
          [selected]="selectedOrder"
          (selectedChange)="selectOrder($event)"
          [divider]="true">
        </app-dropdown-single-select>

        <app-dropdown-action-items
          [options]="[{name: 'Manage My Tags...', key: 0}]"
          (actionSelected)="openTagsModal()">
        </app-dropdown-action-items>
      </app-dropdown>
    </div>
  </div>

  <div class="collapseable-body" *ngIf="!minimized" [style.height.px]="collapsed ? 0 : bodyHeight">
    <div class="container">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>

</div>

<ng-template #contentTemplate>
  <!-- Tags -->
  <app-content-menu-tag
    *ngFor="let tag of tags"
    [tag]="tag"
    [active]="selectedTags | includesBy: tag.name"
    (selected)="selectTag(tag, $event)">
  </app-content-menu-tag>

  <!-- Collapse -->
  <div
    *ngIf="pagination.count > tags?.length"
    class="show-more"
    (click)="showMore()">
    + {{ (pagination.count - tags.length) > pagination.step ? pagination.step : (pagination.count - tags.length) }} more
  </div>

  <!-- Placeholder -->
  <div *ngIf="!tags?.length" class="placeholder">No Pinned Tags</div>

</ng-template>
