// Common
import { Component, Input } from '@angular/core';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Types
import { Priority } from '@modules/tasks/types/priority';
import { Task } from '@modules/tasks/types/task';
// Services
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { ContextMenuService } from 'ngx-contextmenu';


@Component({
  selector: 'app-sidebar-task-context-menu',
  templateUrl: './sidebar-task-context-menu.component.html',
  styleUrls: ['./sidebar-task-context-menu.component.less']
})
export class SidebarTaskContextMenuComponent extends ContextMenuComponent {

  // Inputs
  @Input() startDate: Date;
  @Input() priority: Priority = 'normal';

  // Constructor
  constructor(
    protected contextMenuService: ContextMenuService,
    private tasksStateService: TasksStateService
  ) {
    super(contextMenuService);
  }

  // Actions
  public createTask() {
   const task = new Task({ priority: this.priority, fromTime: this.startDate });
    this.tasksStateService.openTaskForm(task);
  }

}
