// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { Observable, Subscription, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-time-zone-select',
  templateUrl: './time-zone-select.component.html',
  styleUrls: ['./time-zone-select.component.less', '../../styles/input.less'],
})
export class TimeZoneSelectComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() appearance: 'standard' | 'outline' = 'outline';

  // Public
  public textControl = new FormControl();
  public options: DropdownSelectItem[] = [
    {title: '(GMT-11:00) Niue Time', value: 'Pacific/Niue'},
    {title: '(GMT-11:00) Samoa Standard Time', value: 'Pacific/Pago_Pago'},
    {title: '(GMT-10:00) Cook Islands Standard Time', value: 'Pacific/Rarotonga'},
    {title: '(GMT-10:00) Hawaii-Aleutian Standard Time', value: 'Pacific/Honolulu'},
    {title: '(GMT-10:00) Tahiti Time', value: 'Pacific/Tahiti'},
    {title: '(GMT-09:30) Marquesas Time', value: 'Pacific/Marquesas'},
    {title: '(GMT-09:00) Gambier Time', value: 'Pacific/Gambier'},
    {title: '(GMT-09:00) Hawaii-Aleutian Time (Adak)', value: 'America/Adak'},
    {title: '(GMT-08:00) Alaska Time - Anchorage', value: 'America/Anchorage'},
    {title: '(GMT-08:00) Alaska Time - Juneau', value: 'America/Juneau'},
    {title: '(GMT-08:00) Alaska Time - Metlakatla', value: 'America/Metlakatla'},
    {title: '(GMT-08:00) Alaska Time - Nome', value: 'America/Nome'},
    {title: '(GMT-08:00) Alaska Time - Sitka', value: 'America/Sitka'},
    {title: '(GMT-08:00) Alaska Time - Yakutat', value: 'America/Yakutat'},
    {title: '(GMT-08:00) Pitcairn Time', value: 'Pacific/Pitcairn'},
    {title: '(GMT-07:00) Mexican Pacific Standard Time', value: 'America/Hermosillo'},
    {title: '(GMT-07:00) Mountain Standard Time - Creston', value: 'America/Creston'},
    {title: '(GMT-07:00) Mountain Standard Time - Dawson Creek', value: 'America/Dawson_Creek'},
    {title: '(GMT-07:00) Mountain Standard Time - Fort Nelson', value: 'America/Fort_Nelson'},
    {title: '(GMT-07:00) Mountain Standard Time - Phoenix', value: 'America/Phoenix'},
    {title: '(GMT-07:00) Pacific Time - Dawson', value: 'America/Dawson'},
    {title: '(GMT-07:00) Pacific Time - Los Angeles', value: 'America/Los_Angeles'},
    {title: '(GMT-07:00) Pacific Time - Tijuana', value: 'America/Tijuana'},
    {title: '(GMT-07:00) Pacific Time - Vancouver', value: 'America/Vancouver'},
    {title: '(GMT-07:00) Pacific Time - Whitehorse', value: 'America/Whitehorse'},
    {title: '(GMT-06:00) Central Standard Time - Belize', value: 'America/Belize'},
    {title: '(GMT-06:00) Central Standard Time - Costa Rica', value: 'America/Costa_Rica'},
    {title: '(GMT-06:00) Central Standard Time - El Salvador', value: 'America/El_Salvador'},
    {title: '(GMT-06:00) Central Standard Time - Guatemala', value: 'America/Guatemala'},
    {title: '(GMT-06:00) Central Standard Time - Managua', value: 'America/Managua'},
    {title: '(GMT-06:00) Central Standard Time - Regina', value: 'America/Regina'},
    {title: '(GMT-06:00) Central Standard Time - Swift Current', value: 'America/Swift_Current'},
    {title: '(GMT-06:00) Central Standard Time - Tegucigalpa', value: 'America/Tegucigalpa'},
    {title: '(GMT-06:00) Easter Island Time', value: 'Pacific/Easter'},
    {title: '(GMT-06:00) Galapagos Time', value: 'Pacific/Galapagos'},
    {title: '(GMT-06:00) Mexican Pacific Time - Chihuahua', value: 'America/Chihuahua'},
    {title: '(GMT-06:00) Mexican Pacific Time - Mazatlan', value: 'America/Mazatlan'},
    {title: '(GMT-06:00) Mountain Time - Boise', value: 'America/Boise'},
    {title: '(GMT-06:00) Mountain Time - Cambridge Bay', value: 'America/Cambridge_Bay'},
    {title: '(GMT-06:00) Mountain Time - Denver', value: 'America/Denver'},
    {title: '(GMT-06:00) Mountain Time - Edmonton', value: 'America/Edmonton'},
    {title: '(GMT-06:00) Mountain Time - Inuvik', value: 'America/Inuvik'},
    {title: '(GMT-06:00) Mountain Time - Ojinaga', value: 'America/Ojinaga'},
    {title: '(GMT-06:00) Mountain Time - Yellowknife', value: 'America/Yellowknife'},
    {title: '(GMT-05:00) Acre Standard Time - Eirunepe', value: 'America/Eirunepe'},
    {title: '(GMT-05:00) Acre Standard Time - Rio Branco', value: 'America/Rio_Branco'},
    {title: '(GMT-05:00) Central Time - Bahia Banderas', value: 'America/Bahia_Banderas'},
    {title: '(GMT-05:00) Central Time - Beulah, North Dakota', value: 'America/North_Dakota/Beulah'},
    {title: '(GMT-05:00) Central Time - Center, North Dakota', value: 'America/North_Dakota/Center'},
    {title: '(GMT-05:00) Central Time - Chicago', value: 'America/Chicago'},
    {title: '(GMT-05:00) Central Time - Knox, Indiana', value: 'America/Indiana/Knox'},
    {title: '(GMT-05:00) Central Time - Matamoros', value: 'America/Matamoros'},
    {title: '(GMT-05:00) Central Time - Menominee', value: 'America/Menominee'},
    {title: '(GMT-05:00) Central Time - Merida', value: 'America/Merida'},
    {title: '(GMT-05:00) Central Time - Mexico City', value: 'America/Mexico_City'},
    {title: '(GMT-05:00) Central Time - Monterrey', value: 'America/Monterrey'},
    {title: '(GMT-05:00) Central Time - New Salem, North Dakota', value: 'America/North_Dakota/New_Salem'},
    {title: '(GMT-05:00) Central Time - Rainy River', value: 'America/Rainy_River'},
    {title: '(GMT-05:00) Central Time - Rankin Inlet', value: 'America/Rankin_Inlet'},
    {title: '(GMT-05:00) Central Time - Resolute', value: 'America/Resolute'},
    {title: '(GMT-05:00) Central Time - Tell City, Indiana', value: 'America/Indiana/Tell_City'},
    {title: '(GMT-05:00) Central Time - Winnipeg', value: 'America/Winnipeg'},
    {title: '(GMT-05:00) Colombia Standard Time', value: 'America/Bogota'},
    {title: '(GMT-05:00) Eastern Standard Time - Atikokan', value: 'America/Atikokan'},
    {title: '(GMT-05:00) Eastern Standard Time - Cancun', value: 'America/Cancun'},
    {title: '(GMT-05:00) Eastern Standard Time - Jamaica', value: 'America/Jamaica'},
    {title: '(GMT-05:00) Eastern Standard Time - Panama', value: 'America/Panama'},
    {title: '(GMT-05:00) Ecuador Time', value: 'America/Guayaquil'},
    {title: '(GMT-05:00) Peru Standard Time', value: 'America/Lima'},
    {title: '(GMT-04:00) Amazon Standard Time - Boa Vista', value: 'America/Boa_Vista'},
    {title: '(GMT-04:00) Amazon Standard Time - Campo Grande', value: 'America/Campo_Grande'},
    {title: '(GMT-04:00) Amazon Standard Time - Cuiaba', value: 'America/Cuiaba'},
    {title: '(GMT-04:00) Amazon Standard Time - Manaus', value: 'America/Manaus'},
    {title: '(GMT-04:00) Amazon Standard Time - Porto Velho', value: 'America/Porto_Velho'},
    {title: '(GMT-04:00) Atlantic Standard Time - Barbados', value: 'America/Barbados'},
    {title: '(GMT-04:00) Atlantic Standard Time - Blanc-Sablon', value: 'America/Blanc-Sablon'},
    {title: '(GMT-04:00) Atlantic Standard Time - Curaçao', value: 'America/Curacao'},
    {title: '(GMT-04:00) Atlantic Standard Time - Martinique', value: 'America/Martinique'},
    {title: '(GMT-04:00) Atlantic Standard Time - Port of Spain', value: 'America/Port_of_Spain'},
    {title: '(GMT-04:00) Atlantic Standard Time - Puerto Rico', value: 'America/Puerto_Rico'},
    {title: '(GMT-04:00) Atlantic Standard Time - Santo Domingo', value: 'America/Santo_Domingo'},
    {title: '(GMT-04:00) Bolivia Time', value: 'America/La_Paz'},
    {title: '(GMT-04:00) Chile Time', value: 'America/Santiago'},
    {title: '(GMT-04:00) Cuba Time', value: 'America/Havana'},
    {title: '(GMT-04:00) Eastern Time - Detroit', value: 'America/Detroit'},
    {title: '(GMT-04:00) Eastern Time - Grand Turk', value: 'America/Grand_Turk'},
    {title: '(GMT-04:00) Eastern Time - Indianapolis', value: 'America/Indiana/Indianapolis'},
    {title: '(GMT-04:00) Eastern Time - Iqaluit', value: 'America/Iqaluit'},
    {title: '(GMT-04:00) Eastern Time - Louisville', value: 'America/Kentucky/Louisville'},
    {title: '(GMT-04:00) Eastern Time - Marengo, Indiana', value: 'America/Indiana/Marengo'},
    {title: '(GMT-04:00) Eastern Time - Monticello, Kentucky', value: 'America/Kentucky/Monticello'},
    {title: '(GMT-04:00) Eastern Time - Nassau', value: 'America/Nassau'},
    {title: '(GMT-04:00) Eastern Time - New York', value: 'America/New_York'},
    {title: '(GMT-04:00) Eastern Time - Nipigon', value: 'America/Nipigon'},
    {title: '(GMT-04:00) Eastern Time - Pangnirtung', value: 'America/Pangnirtung'},
    {title: '(GMT-04:00) Eastern Time - Petersburg, Indiana', value: 'America/Indiana/Petersburg'},
    {title: '(GMT-04:00) Eastern Time - Port-au-Prince', value: 'America/Port-au-Prince'},
    {title: '(GMT-04:00) Eastern Time - Thunder Bay', value: 'America/Thunder_Bay'},
    {title: '(GMT-04:00) Eastern Time - Toronto', value: 'America/Toronto'},
    {title: '(GMT-04:00) Eastern Time - Vevay, Indiana', value: 'America/Indiana/Vevay'},
    {title: '(GMT-04:00) Eastern Time - Vincennes, Indiana', value: 'America/Indiana/Vincennes'},
    {title: '(GMT-04:00) Eastern Time - Winamac, Indiana', value: 'America/Indiana/Winamac'},
    {title: '(GMT-04:00) Guyana Time', value: 'America/Guyana'},
    {title: '(GMT-04:00) Paraguay Time', value: 'America/Asuncion'},
    {title: '(GMT-04:00) Venezuela Time', value: 'America/Caracas'},
    {title: '(GMT-03:00) Argentina Standard Time - Buenos Aires', value: 'America/Argentina/Buenos_Aires'},
    {title: '(GMT-03:00) Argentina Standard Time - Catamarca', value: 'America/Argentina/Catamarca'},
    {title: '(GMT-03:00) Argentina Standard Time - Cordoba', value: 'America/Argentina/Cordoba'},
    {title: '(GMT-03:00) Argentina Standard Time - Jujuy', value: 'America/Argentina/Jujuy'},
    {title: '(GMT-03:00) Argentina Standard Time - La Rioja', value: 'America/Argentina/La_Rioja'},
    {title: '(GMT-03:00) Argentina Standard Time - Mendoza', value: 'America/Argentina/Mendoza'},
    {title: '(GMT-03:00) Argentina Standard Time - Rio Gallegos', value: 'America/Argentina/Rio_Gallegos'},
    {title: '(GMT-03:00) Argentina Standard Time - Salta', value: 'America/Argentina/Salta'},
    {title: '(GMT-03:00) Argentina Standard Time - San Juan', value: 'America/Argentina/San_Juan'},
    {title: '(GMT-03:00) Argentina Standard Time - Tucuman', value: 'America/Argentina/Tucuman'},
    {title: '(GMT-03:00) Argentina Standard Time - Ushuaia', value: 'America/Argentina/Ushuaia'},
    {title: '(GMT-03:00) Atlantic Time - Bermuda', value: 'Atlantic/Bermuda'},
    {title: '(GMT-03:00) Atlantic Time - Glace Bay', value: 'America/Glace_Bay'},
    {title: '(GMT-03:00) Atlantic Time - Goose Bay', value: 'America/Goose_Bay'},
    {title: '(GMT-03:00) Atlantic Time - Halifax', value: 'America/Halifax'},
    {title: '(GMT-03:00) Atlantic Time - Moncton', value: 'America/Moncton'},
    {title: '(GMT-03:00) Atlantic Time - Thule', value: 'America/Thule'},
    {title: '(GMT-03:00) Brasilia Standard Time - Araguaina', value: 'America/Araguaina'},
    {title: '(GMT-03:00) Brasilia Standard Time - Bahia', value: 'America/Bahia'},
    {title: '(GMT-03:00) Brasilia Standard Time - Belem', value: 'America/Belem'},
    {title: '(GMT-03:00) Brasilia Standard Time - Fortaleza', value: 'America/Fortaleza'},
    {title: '(GMT-03:00) Brasilia Standard Time - Maceio', value: 'America/Maceio'},
    {title: '(GMT-03:00) Brasilia Standard Time - Recife', value: 'America/Recife'},
    {title: '(GMT-03:00) Brasilia Standard Time - Santarem', value: 'America/Santarem'},
    {title: '(GMT-03:00) Brasilia Standard Time - Sao Paulo', value: 'America/Sao_Paulo'},
    {title: '(GMT-03:00) Falkland Islands Standard Time', value: 'Atlantic/Stanley'},
    {title: '(GMT-03:00) French Guiana Time', value: 'America/Cayenne'},
    {title: '(GMT-03:00) Palmer Time', value: 'Antarctica/Palmer'},
    {title: '(GMT-03:00) Punta Arenas Time', value: 'America/Punta_Arenas'},
    {title: '(GMT-03:00) Rothera Time', value: 'Antarctica/Rothera'},
    {title: '(GMT-03:00) Suriname Time', value: 'America/Paramaribo'},
    {title: '(GMT-03:00) Uruguay Standard Time', value: 'America/Montevideo'},
    {title: '(GMT-03:00) Western Argentina Standard Time', value: 'America/Argentina/San_Luis'},
    {title: '(GMT-02:30) Newfoundland Time', value: 'America/St_Johns'},
    {title: '(GMT-02:00) Fernando de Noronha Standard Time', value: 'America/Noronha'},
    {title: '(GMT-02:00) South Georgia Time', value: 'Atlantic/South_Georgia'},
    {title: '(GMT-02:00) St Pierre &amp; Miquelon Time', value: 'America/Miquelon'},
    {title: '(GMT-02:00) West Greenland Time', value: 'America/Godthab'},
    {title: '(GMT-01:00) Cape Verde Standard Time', value: 'Atlantic/Cape_Verde'},
    {title: '(GMT+00:00) Azores Time', value: 'Atlantic/Azores'},
    {title: '(GMT+00:00) Coordinated Universal Time', value: 'UTC'},
    {title: '(GMT+00:00) East Greenland Time', value: 'America/Scoresbysund'},
    {title: '(GMT+00:00) Greenwich Mean Time', value: 'Etc/GMT'},
    {title: '(GMT+00:00) Greenwich Mean Time - Abidjan', value: 'Africa/Abidjan'},
    {title: '(GMT+00:00) Greenwich Mean Time - Accra', value: 'Africa/Accra'},
    {title: '(GMT+00:00) Greenwich Mean Time - Bissau', value: 'Africa/Bissau'},
    {title: '(GMT+00:00) Greenwich Mean Time - Danmarkshavn', value: 'America/Danmarkshavn'},
    {title: '(GMT+00:00) Greenwich Mean Time - Monrovia', value: 'Africa/Monrovia'},
    {title: '(GMT+00:00) Greenwich Mean Time - Reykjavik', value: 'Atlantic/Reykjavik'},
    {title: '(GMT+00:00) Greenwich Mean Time - São Tomé', value: 'Africa/Sao_Tome'},
    {title: '(GMT+01:00) Central European Standard Time - Algiers', value: 'Africa/Algiers'},
    {title: '(GMT+01:00) Central European Standard Time - Tunis', value: 'Africa/Tunis'},
    {title: '(GMT+01:00) Ireland Time', value: 'Europe/Dublin'},
    {title: '(GMT+01:00) Morocco Time', value: 'Africa/Casablanca'},
    {title: '(GMT+01:00) United Kingdom Time', value: 'Europe/London'},
    {title: '(GMT+01:00) West Africa Standard Time - Lagos', value: 'Africa/Lagos'},
    {title: '(GMT+01:00) West Africa Standard Time - Ndjamena', value: 'Africa/Ndjamena'},
    {title: '(GMT+01:00) Western European Time - Canary', value: 'Atlantic/Canary'},
    {title: '(GMT+01:00) Western European Time - Faroe', value: 'Atlantic/Faroe'},
    {title: '(GMT+01:00) Western European Time - Lisbon', value: 'Europe/Lisbon'},
    {title: '(GMT+01:00) Western European Time - Madeira', value: 'Atlantic/Madeira'},
    {title: '(GMT+01:00) Western Sahara Time', value: 'Africa/El_Aaiun'},
    {title: '(GMT+02:00) Central Africa Time - Khartoum', value: 'Africa/Khartoum'},
    {title: '(GMT+02:00) Central Africa Time - Maputo', value: 'Africa/Maputo'},
    {title: '(GMT+02:00) Central Africa Time - Windhoek', value: 'Africa/Windhoek'},
    {title: '(GMT+02:00) Central European Time - Amsterdam', value: 'Europe/Amsterdam'},
    {title: '(GMT+02:00) Central European Time - Andorra', value: 'Europe/Andorra'},
    {title: '(GMT+02:00) Central European Time - Belgrade', value: 'Europe/Belgrade'},
    {title: '(GMT+02:00) Central European Time - Berlin', value: 'Europe/Berlin'},
    {title: '(GMT+02:00) Central European Time - Brussels', value: 'Europe/Brussels'},
    {title: '(GMT+02:00) Central European Time - Budapest', value: 'Europe/Budapest'},
    {title: '(GMT+02:00) Central European Time - Ceuta', value: 'Africa/Ceuta'},
    {title: '(GMT+02:00) Central European Time - Copenhagen', value: 'Europe/Copenhagen'},
    {title: '(GMT+02:00) Central European Time - Gibraltar', value: 'Europe/Gibraltar'},
    {title: '(GMT+02:00) Central European Time - Luxembourg', value: 'Europe/Luxembourg'},
    {title: '(GMT+02:00) Central European Time - Madrid', value: 'Europe/Madrid'},
    {title: '(GMT+02:00) Central European Time - Malta', value: 'Europe/Malta'},
    {title: '(GMT+02:00) Central European Time - Monaco', value: 'Europe/Monaco'},
    {title: '(GMT+02:00) Central European Time - Oslo', value: 'Europe/Oslo'},
    {title: '(GMT+02:00) Central European Time - Paris', value: 'Europe/Paris'},
    {title: '(GMT+02:00) Central European Time - Prague', value: 'Europe/Prague'},
    {title: '(GMT+02:00) Central European Time - Rome', value: 'Europe/Rome'},
    {title: '(GMT+02:00) Central European Time - Stockholm', value: 'Europe/Stockholm'},
    {title: '(GMT+02:00) Central European Time - Tirane', value: 'Europe/Tirane'},
    {title: '(GMT+02:00) Central European Time - Vienna', value: 'Europe/Vienna'},
    {title: '(GMT+02:00) Central European Time - Warsaw', value: 'Europe/Warsaw'},
    {title: '(GMT+02:00) Central European Time - Zurich', value: 'Europe/Zurich'},
    {title: '(GMT+02:00) Eastern European Standard Time - Cairo', value: 'Africa/Cairo'},
    {title: '(GMT+02:00) Eastern European Standard Time - Kaliningrad', value: 'Europe/Kaliningrad'},
    {title: '(GMT+02:00) Eastern European Standard Time - Tripoli', value: 'Africa/Tripoli'},
    {title: '(GMT+02:00) South Africa Standard Time', value: 'Africa/Johannesburg'},
    {title: '(GMT+02:00) Troll Time', value: 'Antarctica/Troll'},
    {title: '(GMT+03:00) Arabian Standard Time - Baghdad', value: 'Asia/Baghdad'},
    {title: '(GMT+03:00) Arabian Standard Time - Qatar', value: 'Asia/Qatar'},
    {title: '(GMT+03:00) Arabian Standard Time - Riyadh', value: 'Asia/Riyadh'},
    {title: '(GMT+03:00) East Africa Time - Juba', value: 'Africa/Juba'},
    {title: '(GMT+03:00) East Africa Time - Nairobi', value: 'Africa/Nairobi'},
    {title: '(GMT+03:00) Eastern European Time - Amman', value: 'Asia/Amman'},
    {title: '(GMT+03:00) Eastern European Time - Athens', value: 'Europe/Athens'},
    {title: '(GMT+03:00) Eastern European Time - Beirut', value: 'Asia/Beirut'},
    {title: '(GMT+03:00) Eastern European Time - Bucharest', value: 'Europe/Bucharest'},
    {title: '(GMT+03:00) Eastern European Time - Chisinau', value: 'Europe/Chisinau'},
    {title: '(GMT+03:00) Eastern European Time - Damascus', value: 'Asia/Damascus'},
    {title: '(GMT+03:00) Eastern European Time - Gaza', value: 'Asia/Gaza'},
    {title: '(GMT+03:00) Eastern European Time - Hebron', value: 'Asia/Hebron'},
    {title: '(GMT+03:00) Eastern European Time - Helsinki', value: 'Europe/Helsinki'},
    {title: '(GMT+03:00) Eastern European Time - Kiev', value: 'Europe/Kiev'},
    {title: '(GMT+03:00) Eastern European Time - Nicosia', value: 'Asia/Nicosia'},
    {title: '(GMT+03:00) Eastern European Time - Riga', value: 'Europe/Riga'},
    {title: '(GMT+03:00) Eastern European Time - Sofia', value: 'Europe/Sofia'},
    {title: '(GMT+03:00) Eastern European Time - Tallinn', value: 'Europe/Tallinn'},
    {title: '(GMT+03:00) Eastern European Time - Uzhhorod', value: 'Europe/Uzhgorod'},
    {title: '(GMT+03:00) Eastern European Time - Vilnius', value: 'Europe/Vilnius'},
    {title: '(GMT+03:00) Eastern European Time - Zaporozhye', value: 'Europe/Zaporozhye'},
    {title: '(GMT+03:00) Famagusta Time', value: 'Asia/Famagusta'},
    {title: '(GMT+03:00) Israel Time', value: 'Asia/Jerusalem'},
    {title: '(GMT+03:00) Kirov Time', value: 'Europe/Kirov'},
    {title: '(GMT+03:00) Moscow Standard Time - Minsk', value: 'Europe/Minsk'},
    {title: '(GMT+03:00) Moscow Standard Time - Moscow', value: 'Europe/Moscow'},
    {title: '(GMT+03:00) Moscow Standard Time - Simferopol', value: 'Europe/Simferopol'},
    {title: '(GMT+03:00) Syowa Time', value: 'Antarctica/Syowa'},
    {title: '(GMT+03:00) Turkey Time', value: 'Europe/Istanbul'},
    {title: '(GMT+04:00) Armenia Standard Time', value: 'Asia/Yerevan'},
    {title: '(GMT+04:00) Astrakhan Time', value: 'Europe/Astrakhan'},
    {title: '(GMT+04:00) Azerbaijan Standard Time', value: 'Asia/Baku'},
    {title: '(GMT+04:00) Georgia Standard Time', value: 'Asia/Tbilisi'},
    {title: '(GMT+04:00) Gulf Standard Time', value: 'Asia/Dubai'},
    {title: '(GMT+04:00) Mauritius Standard Time', value: 'Indian/Mauritius'},
    {title: '(GMT+04:00) Réunion Time', value: 'Indian/Reunion'},
    {title: '(GMT+04:00) Samara Standard Time', value: 'Europe/Samara'},
    {title: '(GMT+04:00) Saratov Time', value: 'Europe/Saratov'},
    {title: '(GMT+04:00) Seychelles Time', value: 'Indian/Mahe'},
    {title: '(GMT+04:00) Ulyanovsk Time', value: 'Europe/Ulyanovsk'},
    {title: '(GMT+04:00) Volgograd Standard Time', value: 'Europe/Volgograd'},
    {title: '(GMT+04:30) Afghanistan Time', value: 'Asia/Kabul'},
    {title: '(GMT+04:30) Iran Time', value: 'Asia/Tehran'},
    {title: '(GMT+05:00) French Southern &amp; Antarctic Time', value: 'Indian/Kerguelen'},
    {title: '(GMT+05:00) Maldives Time', value: 'Indian/Maldives'},
    {title: '(GMT+05:00) Mawson Time', value: 'Antarctica/Mawson'},
    {title: '(GMT+05:00) Pakistan Standard Time', value: 'Asia/Karachi'},
    {title: '(GMT+05:00) Tajikistan Time', value: 'Asia/Dushanbe'},
    {title: '(GMT+05:00) Turkmenistan Standard Time', value: 'Asia/Ashgabat'},
    {title: '(GMT+05:00) Uzbekistan Standard Time - Samarkand', value: 'Asia/Samarkand'},
    {title: '(GMT+05:00) Uzbekistan Standard Time - Tashkent', value: 'Asia/Tashkent'},
    {title: '(GMT+05:00) West Kazakhstan Time - Aqtau', value: 'Asia/Aqtau'},
    {title: '(GMT+05:00) West Kazakhstan Time - Aqtobe', value: 'Asia/Aqtobe'},
    {title: '(GMT+05:00) West Kazakhstan Time - Atyrau', value: 'Asia/Atyrau'},
    {title: '(GMT+05:00) West Kazakhstan Time - Oral', value: 'Asia/Oral'},
    {title: '(GMT+05:00) West Kazakhstan Time - Qyzylorda', value: 'Asia/Qyzylorda'},
    {title: '(GMT+05:00) Yekaterinburg Standard Time', value: 'Asia/Yekaterinburg'},
    {title: '(GMT+05:30) India Standard Time - Colombo', value: 'Asia/Colombo'},
    {title: '(GMT+05:30) India Standard Time - Kolkata', value: 'Asia/Kolkata'},
    {title: '(GMT+05:45) Nepal Time', value: 'Asia/Kathmandu'},
    {title: '(GMT+06:00) Bangladesh Standard Time', value: 'Asia/Dhaka'},
    {title: '(GMT+06:00) Bhutan Time', value: 'Asia/Thimphu'},
    {title: '(GMT+06:00) East Kazakhstan Time - Almaty', value: 'Asia/Almaty'},
    {title: '(GMT+06:00) East Kazakhstan Time - Kostanay', value: 'Asia/Qostanay'},
    {title: '(GMT+06:00) Indian Ocean Time', value: 'Indian/Chagos'},
    {title: '(GMT+06:00) Kyrgyzstan Time', value: 'Asia/Bishkek'},
    {title: '(GMT+06:00) Omsk Standard Time', value: 'Asia/Omsk'},
    {title: '(GMT+06:00) Urumqi Time', value: 'Asia/Urumqi'},
    {title: '(GMT+06:00) Vostok Time', value: 'Antarctica/Vostok'},
    {title: '(GMT+06:30) Cocos Islands Time', value: 'Indian/Cocos'},
    {title: '(GMT+06:30) Myanmar Time', value: 'Asia/Yangon'},
    {title: '(GMT+07:00) Barnaul Time', value: 'Asia/Barnaul'},
    {title: '(GMT+07:00) Christmas Island Time', value: 'Indian/Christmas'},
    {title: '(GMT+07:00) Davis Time', value: 'Antarctica/Davis'},
    {title: '(GMT+07:00) Hovd Standard Time', value: 'Asia/Hovd'},
    {title: '(GMT+07:00) Indochina Time - Bangkok', value: 'Asia/Bangkok'},
    {title: '(GMT+07:00) Indochina Time - Ho Chi Minh City', value: 'Asia/Ho_Chi_Minh'},
    {title: '(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk', value: 'Asia/Krasnoyarsk'},
    {title: '(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk', value: 'Asia/Novokuznetsk'},
    {title: '(GMT+07:00) Novosibirsk Standard Time', value: 'Asia/Novosibirsk'},
    {title: '(GMT+07:00) Tomsk Time', value: 'Asia/Tomsk'},
    {title: '(GMT+07:00) Western Indonesia Time - Jakarta', value: 'Asia/Jakarta'},
    {title: '(GMT+07:00) Western Indonesia Time - Pontianak', value: 'Asia/Pontianak'},
    {title: '(GMT+08:00) Australian Western Standard Time - Casey', value: 'Antarctica/Casey'},
    {title: '(GMT+08:00) Australian Western Standard Time - Perth', value: 'Australia/Perth'},
    {title: '(GMT+08:00) Brunei Darussalam Time', value: 'Asia/Brunei'},
    {title: '(GMT+08:00) Central Indonesia Time', value: 'Asia/Makassar'},
    {title: '(GMT+08:00) China Standard Time - Macao', value: 'Asia/Macau'},
    {title: '(GMT+08:00) China Standard Time - Shanghai', value: 'Asia/Shanghai'},
    {title: '(GMT+08:00) Choibalsan Standard Time', value: 'Asia/Choibalsan'},
    {title: '(GMT+08:00) Hong Kong Standard Time', value: 'Asia/Hong_Kong'},
    {title: '(GMT+08:00) Irkutsk Standard Time', value: 'Asia/Irkutsk'},
    {title: '(GMT+08:00) Malaysia Time - Kuala Lumpur', value: 'Asia/Kuala_Lumpur'},
    {title: '(GMT+08:00) Malaysia Time - Kuching', value: 'Asia/Kuching'},
    {title: '(GMT+08:00) Philippine Standard Time', value: 'Asia/Manila'},
    {title: '(GMT+08:00) Singapore Standard Time', value: 'Asia/Singapore'},
    {title: '(GMT+08:00) Taipei Standard Time', value: 'Asia/Taipei'},
    {title: '(GMT+08:00) Ulaanbaatar Standard Time', value: 'Asia/Ulaanbaatar'},
    {title: '(GMT+08:45) Australian Central Western Standard Time', value: 'Australia/Eucla'},
    {title: '(GMT+09:00) East Timor Time', value: 'Asia/Dili'},
    {title: '(GMT+09:00) Eastern Indonesia Time', value: 'Asia/Jayapura'},
    {title: '(GMT+09:00) Japan Standard Time', value: 'Asia/Tokyo'},
    {title: '(GMT+09:00) Korean Standard Time - Pyongyang', value: 'Asia/Pyongyang'},
    {title: '(GMT+09:00) Korean Standard Time - Seoul', value: 'Asia/Seoul'},
    {title: '(GMT+09:00) Palau Time', value: 'Pacific/Palau'},
    {title: '(GMT+09:00) Yakutsk Standard Time - Chita', value: 'Asia/Chita'},
    {title: '(GMT+09:00) Yakutsk Standard Time - Khandyga', value: 'Asia/Khandyga'},
    {title: '(GMT+09:00) Yakutsk Standard Time - Yakutsk', value: 'Asia/Yakutsk'},
    {title: '(GMT+09:30) Australian Central Standard Time', value: 'Australia/Darwin'},
    {title: '(GMT+09:30) Central Australia Time - Adelaide', value: 'Australia/Adelaide'},
    {title: '(GMT+09:30) Central Australia Time - Broken Hill', value: 'Australia/Broken_Hill'},
    {title: '(GMT+10:00) Australian Eastern Standard Time - Brisbane', value: 'Australia/Brisbane'},
    {title: '(GMT+10:00) Australian Eastern Standard Time - Lindeman', value: 'Australia/Lindeman'},
    {title: '(GMT+10:00) Chamorro Standard Time', value: 'Pacific/Guam'},
    {title: '(GMT+10:00) Chuuk Time', value: 'Pacific/Chuuk'},
    {title: '(GMT+10:00) Dumont-d’Urville Time', value: 'Antarctica/DumontDUrville'},
    {title: '(GMT+10:00) Eastern Australia Time - Currie', value: 'Australia/Currie'},
    {title: '(GMT+10:00) Eastern Australia Time - Hobart', value: 'Australia/Hobart'},
    {title: '(GMT+10:00) Eastern Australia Time - Melbourne', value: 'Australia/Melbourne'},
    {title: '(GMT+10:00) Eastern Australia Time - Sydney', value: 'Australia/Sydney'},
    {title: '(GMT+10:00) Papua New Guinea Time', value: 'Pacific/Port_Moresby'},
    {title: '(GMT+10:00) Vladivostok Standard Time - Ust-Nera', value: 'Asia/Ust-Nera'},
    {title: '(GMT+10:00) Vladivostok Standard Time - Vladivostok', value: 'Asia/Vladivostok'},
    {title: '(GMT+10:30) Lord Howe Time', value: 'Australia/Lord_Howe'},
    {title: '(GMT+11:00) Bougainville Time', value: 'Pacific/Bougainville'},
    {title: '(GMT+11:00) Kosrae Time', value: 'Pacific/Kosrae'},
    {title: '(GMT+11:00) Macquarie Island Time', value: 'Antarctica/Macquarie'},
    {title: '(GMT+11:00) Magadan Standard Time', value: 'Asia/Magadan'},
    {title: '(GMT+11:00) New Caledonia Standard Time', value: 'Pacific/Noumea'},
    {title: '(GMT+11:00) Norfolk Island Time', value: 'Pacific/Norfolk'},
    {title: '(GMT+11:00) Ponape Time', value: 'Pacific/Pohnpei'},
    {title: '(GMT+11:00) Sakhalin Standard Time', value: 'Asia/Sakhalin'},
    {title: '(GMT+11:00) Solomon Islands Time', value: 'Pacific/Guadalcanal'},
    {title: '(GMT+11:00) Srednekolymsk Time', value: 'Asia/Srednekolymsk'},
    {title: '(GMT+11:00) Vanuatu Standard Time', value: 'Pacific/Efate'},
    {title: '(GMT+12:00) Anadyr Standard Time', value: 'Asia/Anadyr'},
    {title: '(GMT+12:00) Fiji Time', value: 'Pacific/Fiji'},
    {title: '(GMT+12:00) Gilbert Islands Time', value: 'Pacific/Tarawa'},
    {title: '(GMT+12:00) Marshall Islands Time - Kwajalein', value: 'Pacific/Kwajalein'},
    {title: '(GMT+12:00) Marshall Islands Time - Majuro', value: 'Pacific/Majuro'},
    {title: '(GMT+12:00) Nauru Time', value: 'Pacific/Nauru'},
    {title: '(GMT+12:00) New Zealand Time', value: 'Pacific/Auckland'},
    {title: '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time', value: 'Asia/Kamchatka'},
    {title: '(GMT+12:00) Tuvalu Time', value: 'Pacific/Funafuti'},
    {title: '(GMT+12:00) Wake Island Time', value: 'Pacific/Wake'},
    {title: '(GMT+12:00) Wallis &amp; Futuna Time', value: 'Pacific/Wallis'},
    {title: '(GMT+12:45) Chatham Time', value: 'Pacific/Chatham'},
    {title: '(GMT+13:00) Apia Time', value: 'Pacific/Apia'},
    {title: '(GMT+13:00) Phoenix Islands Time', value: 'Pacific/Enderbury'},
    {title: '(GMT+13:00) Tokelau Time', value: 'Pacific/Fakaofo'},
    {title: '(GMT+13:00) Tonga Standard Time', value: 'Pacific/Tongatapu'},
    {title: '(GMT+14:00) Line Islands Time', value: 'Pacific/Kiritimati'},
  ];
  public suggestions: Observable<DropdownSelectItem[]>;

  // Private
  private formControlSubscription: Subscription;
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = this.textControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterOptions(value)),
        takeUntil(this.componentNotDestroyed),
      );

    this.setDefault();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      if (this.formControlSubscription) {
        this.formControlSubscription.unsubscribe();
      }

      this.formControlSubscription = this.inputFormControl.valueChanges
        .pipe(
          startWith(this.inputFormControl.value),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe((value: string) => {
          const option = this.options.find((item: DropdownSelectItem) => item.value === value);
          if (option) {
            this.textControl.setValue(option.title);
          } else {
            this.setDefault();
          }
        });
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSelectOption(calendarEvent: DropdownSelectItem, event: MouseEvent) {
    this.inputFormControl.setValue(calendarEvent.value);
    event.preventDefault();
    event.stopPropagation();
  }

  setDefault() {
    const option = this.options.find((item: DropdownSelectItem) => item.value === Intl.DateTimeFormat().resolvedOptions().timeZone);

    if (option) {
      this.textControl.setValue(option.title);
    }
  }

  /**
   * Helpers
   */

  private filterOptions(value: string): DropdownSelectItem[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.title.toLowerCase().includes(filterValue));
  }
}
