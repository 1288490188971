<div class="confirmation-modal-dialog">
  <div class="confirmation-modal-header">
    <div class="confirmation-modal-title">{{modalTitle}}</div>
    <button type="button" class="confirmation-button-modal" aria-label="Close" (click)="close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="confirmation-modal-body"> {{modalText}} </div>
  <div class="confirmation-modal-footer">
    <button (click)="closeModal()" class="btn btn-default">{{cancelButtonLabel}}</button>
    <button (click)="submitModal()" class="btn btn-primary">{{submitButtonLabel}}</button>
  </div>
</div>
