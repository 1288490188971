/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-quick-preview.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../event-badge/event-badge.component.ngfactory";
import * as i3 from "../event-badge/event-badge.component";
import * as i4 from "../../../knowledge-panel/services/knowledge-panel.service";
import * as i5 from "../../services/events-state.service";
import * as i6 from "../../../linked-info/services/linked-info.service";
import * as i7 from "../../../modal/services/modal.service";
import * as i8 from "../../../popover/services/popover.service";
import * as i9 from "@angular/common";
import * as i10 from "./events-quick-preview.component";
var styles_EventQuickPreviewComponent = [i0.styles];
var RenderType_EventQuickPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventQuickPreviewComponent, data: {} });
export { RenderType_EventQuickPreviewComponent as RenderType_EventQuickPreviewComponent };
function View_EventQuickPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "events"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-event-badge", [["view", "year"]], null, null, null, i2.View_EventBadgeComponent_0, i2.RenderType_EventBadgeComponent)), i1.ɵdid(2, 770048, null, 0, i3.EventBadgeComponent, [i4.KnowledgePanelService, i5.EventsStateService, i6.LinkedInfoService, i7.ModalService, i8.PopoverService, i1.ElementRef], { view: [0, "view"], event: [1, "event"], showTime: [2, "showTime"] }, null)], function (_ck, _v) { var currVal_0 = "year"; var currVal_1 = _v.context.$implicit.meta; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EventQuickPreviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "tooltipContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "day-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "day-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventQuickPreviewComponent_1)), i1.ɵdid(10, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.events; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.day, "dd")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.day, "EEEE")); _ck(_v, 7, 0, currVal_1); }); }
export function View_EventQuickPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "events-quick-preview", [], null, null, null, View_EventQuickPreviewComponent_0, RenderType_EventQuickPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i10.EventQuickPreviewComponent, [], null, null)], null, null); }
var EventQuickPreviewComponentNgFactory = i1.ɵccf("events-quick-preview", i10.EventQuickPreviewComponent, View_EventQuickPreviewComponent_Host_0, { day: "day", events: "events" }, {}, []);
export { EventQuickPreviewComponentNgFactory as EventQuickPreviewComponentNgFactory };
