export const environment = {
  production: true,
  baseUrl: 'https://api.pellet.io',
  authDomains: ['api.pellet.io', 'staging-api.pellet.io'],
  googleMapsKey: 'AIzaSyADfI6jtH-sX4T6n9rDMUiLgfaeeyeAhDI',
  socialAuth: {
    google: {
      clientId: '623871180391-efgj2v76dr3tm971bipcjl0phd0d7rv3.apps.googleusercontent.com',
      redirectUri: 'https://app.pellet.io',
      scope: [
        'email',
        'profile',
        'https://mail.google.com/',
        'https://www.googleapis.com/auth/contacts',
        'https://www.googleapis.com/auth/calendar'
      ],
      prompt: 'consent'
    }
  },
  messageFetchInterval: 30000,
  oneSignal: {
    enabled: true,
    appId: '66895a6d-8650-439b-afe0-e690dcdec958'
  },
  sentry: {
    enabled: true,
    dsn: 'https://79b34c1d2b6c4665a51d40bb033f7b89@sentry.io/239526'
  }
};
