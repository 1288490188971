import * as tslib_1 from "tslib";
// Common
import { EventEmitter } from '@angular/core';
// RxJS
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { SearchParam } from '@modules/search/types/search-param';
// Other
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
var ContactContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactContextMenuComponent, _super);
    function ContactContextMenuComponent(ga, modalService, router, mailService, contextMenuService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.ga = ga;
        _this.modalService = modalService;
        _this.router = router;
        _this.mailService = mailService;
        _this.contextMenuService = contextMenuService;
        // Outputs
        _this.loadInKnowledgePanel = new EventEmitter();
        // Private
        _this.gaMenuEventName = 'contact-context-menu';
        return _this;
    }
    ContactContextMenuComponent.prototype.copyEmail = function () {
        this.ga.trackEvent(this.gaMenuEventName, 'copy-email');
        var email = this.relatedContact.email;
        fromEvent(document, 'copy')
            .pipe(take(1))
            .subscribe(function (clipboard) {
            clipboard.clipboardData.setData('text/plain', email);
            clipboard.preventDefault();
        });
        document.execCommand('copy');
    };
    ContactContextMenuComponent.prototype.sendNewEmail = function () {
        this.ga.trackEvent(this.gaMenuEventName, 'compose-mail');
        this.modalService.compose({ to: [{
                    id: this.relatedContact.id,
                    email: this.relatedContact.email,
                    name: this.relatedContact.name,
                }] });
    };
    ContactContextMenuComponent.prototype.openRelatedEmails = function () {
        this.ga.trackEvent(this.gaMenuEventName, 'related-emails');
        var searchParams = [new SearchParam(this.relatedContact.email, 'contact')];
        var params = JSON.stringify(searchParams);
        this.router.navigate(['/mail/search'], { queryParams: { query: params } });
    };
    ContactContextMenuComponent.prototype.openRelatedTopics = function () {
        this.ga.trackEvent(this.gaMenuEventName, 'related-topics');
        var searchParams = [new SearchParam(this.relatedContact.email, 'contact')];
        var params = JSON.stringify(searchParams);
        this.router.navigate(['/insights/search'], { queryParams: { query: params } });
    };
    ContactContextMenuComponent.prototype.loadContactInKnowledgePanel = function () {
        this.ga.trackEvent(this.gaMenuEventName, 'open-knowledge-pane');
        this.loadInKnowledgePanel.emit();
    };
    ContactContextMenuComponent.prototype.changeVipStatus = function (status) {
        this.mailService.updateRecipientVipStatus(this.relatedContact, status).subscribe();
    };
    return ContactContextMenuComponent;
}(ContextMenuComponent));
export { ContactContextMenuComponent };
