<div class="box">
  <!-- Header -->
  <div class="box-header with-border">
    <h3 class="box-title">General</h3>
  </div>
  <!-- Body -->
  <div class="box-body with-padding">
    <!-- Snoozed -->
    <div>
      <label class="snoozed__title" for="snoozedSelect">Snoozed by default:</label>
      <select [(ngModel)]="messageSnoozed" id="snoozedSelect">
        <option value="hour">{{'hour' | snoozedDate}}</option>
        <option value="tomorrow">{{'tomorrow' | snoozedDate}}</option>
        <option value="afterTomorrow">{{'afterTomorrow' | snoozedDate}}</option>
        <option value="endOfWeek">{{'endOfWeek' | snoozedDate}}</option>
        <option value="nextWeek">{{'nextWeek' | snoozedDate}}</option>
      </select>
    </div>

    <!-- Buttons -->
    <div>
      <button class="btn btn-primary m-sm" (click)="save()">Save</button>
      <button type="button" class="btn btn-secondary m-sm" (click)="cancel()">Cancel</button>
    </div>

    <!-- Alerts -->
    <div class="alert alert-success" *ngIf="saved">Settings is saved</div>
  </div>
</div>
