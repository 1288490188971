// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Types
import { Group } from '@modules/contacts/types/group';
import { GroupsListResponse } from '@modules/contacts/types/groups-list-response';

@Component({
  selector: 'app-linked-info-group-list',
  templateUrl: './linked-info-group-list.component.html',
  styleUrls: ['./linked-info-group-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoGroupListComponent extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private groupsService: GroupsService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'group';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<Group[]> {
    return this.groupsService.getGroups({groupsIds: ids, limit: ids.length})
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response: GroupsListResponse) => response.groups)
      );
  }

  /**
   * Actions
   */

  select(group: Group) {
    this.modalService.groupForm(group);
  }
}
