import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { CalendarEvent } from '@modules/events/types/calendar-event';
// RX
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var EventQuickFormComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventQuickFormComponent(calendarService) {
        this.calendarService = calendarService;
        this.event = new CalendarEvent();
        // Outputs
        this.more = new EventEmitter();
        this.close = new EventEmitter();
        this.calendarOptions = [];
        this.saveInProgress = false;
        // Private
        this.componentNotDestroyed = new Subject();
        this.reset();
    }
    /**
     * Lifecycle
     */
    EventQuickFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.calendarService.getCalendarsList()
            .pipe(takeUntil(this.componentNotDestroyed), map(function (calendars) { return calendars.filter(function (calendar) { return !calendar.readOnly; }); }))
            .subscribe(function (calendars) {
            _this.calendarOptions = calendars.map(function (calendar) { return ({ title: calendar.name, value: calendar.id }); });
        });
    };
    EventQuickFormComponent.prototype.ngOnChanges = function (changes) {
        if ('event' in changes) {
            this.reset();
        }
    };
    EventQuickFormComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    EventQuickFormComponent.prototype.handleSubmit = function () {
        var _this = this;
        if (!this.eventForm.valid) {
            return;
        }
        this.saveInProgress = true;
        var event = CalendarEvent.fromFormGroup(this.eventForm);
        this.calendarService.createEvent(event)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.saveInProgress = false;
            _this.close.emit();
        }, function (error) { return _this.handleError(error); });
    };
    EventQuickFormComponent.prototype.handleError = function (error) {
        this.saveInProgress = false;
    };
    EventQuickFormComponent.prototype.reset = function () {
        var _this = this;
        if (!this.event) {
            return;
        }
        this.eventForm = this.event.asFormGroup();
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        this.changesSubscription = this.eventForm.valueChanges
            .pipe(startWith(this.eventForm.value), pairwise(), takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), previous = _b[0], current = _b[1];
            if (!_this.datesEqual(previous.startDate, current.startDate) && current.startDate && _this.eventForm.controls.endDate.pristine) {
                _this.eventForm.patchValue({ endDate: current.startDate });
            }
            if (!current.startTime && previous.startTime) {
                current.startTime = previous.startTime;
            }
            if (current.startTime && previous.startTime && !_this.datesEqual(previous.startTime, current.startTime)) {
                var timeDifference = current.startTime.getTime() - previous.startTime.getTime();
                _this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
            }
            if (current.reminder && current.reminder !== previous.reminder) {
                _this.eventForm.patchValue({
                    endDate: _this.eventForm.controls.startDate.value,
                    endTime: current.startTime && new Date(current.startTime.getTime() + 900000)
                }, { emitEvent: false });
            }
        });
    };
    EventQuickFormComponent.prototype.handleMoreOptions = function () {
        this.more.emit(CalendarEvent.fromFormGroup(this.eventForm));
        this.close.emit();
    };
    EventQuickFormComponent.prototype.datesEqual = function (date1, date2) {
        return date1 && date2 && date1.getTime() === date2.getTime();
    };
    return EventQuickFormComponent;
}());
export { EventQuickFormComponent };
