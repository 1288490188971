// Common
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, OnInit } from '@angular/core';

// Types
import { Topic } from '@modules/topic/types/topic';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.less'],
})
export class TopicsComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() topics: Topic[] = [];
  @Input() temporalExpressions: TemporalExpression[] = [];
  @Input() selectedTopics: Topic[] = [];
  @Input() selectedTemporalExpressions: TemporalExpression[] = [];
  @Input() highlightedTopics: Topic[] = [];
  @Input() newTopic: Subject<string>;

  // Outputs
  @Output() visibleTopicsChange = new EventEmitter<Topic[]>();
  @Output() selectedTopicsChange = new EventEmitter<Topic[]>();
  @Output() selectedTemporalExpressionsChange = new EventEmitter<TemporalExpression[]>();

  // Public
  public visibleLength = 10;
  public displayAnnotations = false;
  public visibleTopics: Topic[] = [];
  public newTopics: Topic[] = [];
  public popoverCustomTrigger = new Subject<void>();
  public popoverClose = new Subject<void>();
  public formLoading = false;
  public formError = null;

  // Private
  private aliveNewTopic = new Subject<void>();

  // Protected
  protected alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor (
    protected modalService: ModalService
  ) {

  }

  /**
   * Component lifecycle
  */

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('topics' in changes) {
      this.setVisibleLength(this.visibleLength);
    }
    if ('newTopic' in changes) {
      this.aliveNewTopic.next();
      this.newTopic
        .pipe(takeUntil(this.aliveNewTopic))
        .subscribe((newTopic: string) => {
          this.newTopics.push({name: newTopic, source: 'user'});
          this.popoverCustomTrigger.next();
        });
    }
  }

  ngOnDestroy() {
    this.changeVisibleTopics([]);
    this.selectedTopicsChange.emit([]);
    this.selectedTemporalExpressionsChange.emit([]);

    this.aliveNewTopic.next();
    this.aliveNewTopic.complete();
    this.popoverClose.next();
    this.popoverClose.complete();
    this.popoverCustomTrigger.complete();

    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  changeVisibleTopics(newVisibleTopics: Topic[]) {
    this.visibleTopicsChange.emit(newVisibleTopics);
  }

  setVisibleLength(value: number = this.visibleLength, force?: boolean) {
    const newVisibleTopics = this.topics.slice(0, value);

    if (
      newVisibleTopics.length !== this.visibleTopics.length ||
      newVisibleTopics.some((topic, index) => !this.visibleTopics[index] || topic.name !== this.visibleTopics[index].name) ||
      force
    ) {
      this.visibleTopics = newVisibleTopics;
      this.visibleLength = value;
      this.changeVisibleTopics(newVisibleTopics);
    }
  }

  selectTopic(topic: Topic, event: MouseEvent) {
    if (!event.shiftKey) {
      if (this.selectedTopics.length === 1 && this.selectedTemporalExpressions.length === 0 && this.selectedTopics[0] === topic) {
        this.selectedTopics = [];
      } else {
        this.selectedTopics = [topic];
      }
      this.selectedTemporalExpressions = [];
      this.selectedTemporalExpressionsChange.emit(this.selectedTemporalExpressions);
    } else {
      if (this.selectedTopics.some(selectedTopic => selectedTopic.name === topic.name)) {
        this.selectedTopics = this.selectedTopics.filter(t => t !== topic);
      } else {
        this.selectedTopics = [...this.selectedTopics, topic];
      }
    }

    this.selectedTopicsChange.emit(this.selectedTopics);
  }

  selectTemporalExpression(expression: TemporalExpression, event?: MouseEvent) {
    if (!event || !event.shiftKey) {
      if (this.selectedTemporalExpressions && this.selectedTemporalExpressions.length === 1
          && this.selectedTopics.length === 0
          && this.selectedTemporalExpressions[0] === expression) {
        this.selectedTemporalExpressions = [];
      } else {
        this.selectedTemporalExpressions = [expression];
      }
      this.selectedTopics = [];
      this.selectedTopicsChange.emit(this.selectedTopics);
    } else {
      if (this.selectedTemporalExpressions.includes(expression)) {
        this.selectedTemporalExpressions = this.selectedTemporalExpressions.filter(t => t !== expression);
      } else {
        this.selectedTemporalExpressions.push(expression);
      }
    }

    this.selectedTemporalExpressionsChange.emit(this.selectedTemporalExpressions);
  }

  handleDeleteTopic(index: number): void {

  }

  handleAddTopics(topics: Topic[]): void {

  }

  handlePopoverFormClose() {
    this.popoverClose.next();
    this.newTopics = [];
  }

  showAnnotateTopicsModal(): void {

  }

  showTopicsModal() {
    this.modalService.topicList();
  }

  /**
   * Helpers
   */

  increaseVisibleLenght(value: number) {
    if (this.topics.length + value > this.visibleLength) {
      this.setVisibleLength(
        (this.topics.length > this.visibleLength ? this.visibleLength : this.topics.length) + value,
        true
      );
    } else {
      this.setVisibleLength(this.visibleLength, true);
    }
  }
}
