<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="projects-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
>
  <!-- Projects -->
  <ng-container *cdkVirtualFor="let project of itemsStreamObservable; let i = index">

    <!-- Date header -->
    <div
      class="days-separator"
      *ngIf="!project?.pinned && selectedOrder?.key === 'date' && isHover && project?.when?.start | differentDay: items[i - 1]?.when?.start"
    >
      <div class="days-separator-date">
        {{ project.when.start | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <!-- Project -->
    <app-project [project]="project" (click)="selectItem(project, $event)"></app-project>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="project-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true || (selectedItems && selectedItems?.length > 1))">
  <div class="total">Items: {{items.length}}</div>
  <div *ngIf="selectedItems?.length > 1" class="selected">Selected: {{selectedItems?.length}}</div>
</div>

<div class="projects-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="projects-stub-image" src="assets/placeholders/placeholder-tasks.svg"/>
  <p class="projects-stub-text">{{ placeholderText }}</p>
</div>
