// Common
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { AccountService } from '../../services/account.service';

// Types
import { AutoReply } from '../../types/auto-reply';

@Component({
  selector: 'app-account-autoreplies',
  templateUrl: './account-autoreplies.component.html',
  styleUrls: ['./account-autoreplies.component.less']
})
export class AccountAutorepliesComponent implements OnInit {

  // Public
  public startDate = new FormControl();
  public endDate = new FormControl();
  public enabled: boolean;
  public errorMessage: string;
  public autoReply: AutoReply;
  public success = false;
  public loading = false;

  /**
   * Constructor
   */

  constructor(private accountService: AccountService) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.loading = false;
    this.errorMessage = '';
    this.autoReply = { content: '' };
    this.accountService.getAutoReply()
      .subscribe(
        data => {
          if (data) {
            if (data.dateFrom) {
              this.startDate.setValue(new Date(data.dateFrom));
            }
            if (data.dateTo) {
              this.endDate.setValue(new Date(data.dateTo));
            }
            if (data.content) {
              this.autoReply.content = data.content;
            }
            if (data.enabled) {
              this.enabled = data.enabled;
            }
          }
        },
        error => this.errorMessage = error
      );
  }

  /**
   * Actions
   */

  saveAutoReply() {
    this.loading = true;
    this.errorMessage = this.validate();

    if (this.errorMessage.length > 0) {
      this.loading = false;
      return;
    }

    const autoReply = {
      dateFrom: this.startDate.value.getTime(),
      dateTo: this.endDate.value.getTime(),
      content: this.autoReply.content,
      enabled: this.enabled,
    };

    this.accountService.saveAutoReply(autoReply)
      .subscribe(
        () => {
            this.loading = false;
            this.success = true;
        },
        error => {
          this.loading = false;
          console.error(error);
        }
      );
  }

  /**
   * Validation
   */

  validate(): string {
    const today = new Date();

    if (!this.startDate.value) {
      return 'Choose from which date autoreply period should start';
    }

    if (!this.endDate.value) {
      return 'Choose when autoreply period should end';
    }

    if (!this.autoReply.content.length) {
      return 'Autoreply message can not be empty';
    }

    if (this.endDate.value.getTime() < today.getTime()) {
      return 'End of the period can not be earlier than now';
    }

    if (this.endDate.value.getTime() < this.startDate.value.getTime()) {
      return 'Can not set end of the period earlier than then its own start';
    }

    return '';
  }
}
