// Common
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-link-info-suggestions',
  templateUrl: './link-info-suggestions.component.html',
  styleUrls: ['./link-info-suggestions.component.less']
})
export class LinkInfoSuggestionsComponent implements OnInit {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() items: any[];
  @Input() itemTemplateRef: TemplateRef<any>;
  @Input() title: string;
  @Input() createTitle = 'Create';
  @Input() inputPlaceholder = 'Search...';
  @Input() emptyPlaceholder = 'Start typing to get the suggestions';
  @Input() appearance: 'default' | 'context-menu' = 'default';

  // Outputs
  @Output() create = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  creating() {
    this.create.emit();
  }

  closed() {
    this.close.emit();
  }

}
