/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-editor-popup.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modal/components/mail-editor-modal/mail-editor-modal.component.ngfactory";
import * as i3 from "../../../modal/components/mail-editor-modal/mail-editor-modal.component";
import * as i4 from "../../../analytics/services/google-analytics.service";
import * as i5 from "../../services/popup.service";
import * as i6 from "./mail-editor-popup.component";
var styles_MailEditorPopupComponent = [i0.styles];
var RenderType_MailEditorPopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MailEditorPopupComponent, data: {} });
export { RenderType_MailEditorPopupComponent as RenderType_MailEditorPopupComponent };
export function View_MailEditorPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mail-editor-modal", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MailEditorModalComponent_0, i2.RenderType_MailEditorModalComponent)), i1.ɵdid(2, 245760, null, 0, i3.MailEditorModalComponent, [i4.GoogleAnalyticsService, i1.ElementRef, i5.PopupService, i1.ChangeDetectorRef], { availableExpand: [0, "availableExpand"], message: [1, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.message; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_MailEditorPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-editor-popup", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).receiveMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MailEditorPopupComponent_0, RenderType_MailEditorPopupComponent)), i1.ɵdid(1, 114688, null, 0, i6.MailEditorPopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailEditorPopupComponentNgFactory = i1.ɵccf("app-mail-editor-popup", i6.MailEditorPopupComponent, View_MailEditorPopupComponent_Host_0, {}, {}, []);
export { MailEditorPopupComponentNgFactory as MailEditorPopupComponentNgFactory };
