<!-- Search field -->
<div class="search-wrapper expanded">
  <div
    class="search-input-group"
    matAutocompleteOrigin
    #originAutocompleteSearch="matAutocompleteOrigin">
    <div class="search-icon">
      <mat-icon svgIcon="search"></mat-icon>
    </div>
    <mat-chip-list #chipList class="chips-list">
      <mat-chip
        *ngFor="let param of searchParams"
        class="chips-item"
        [disableRipple]="true"
        [selectable]="true"
        [removable]="true"
        (removed)="onRemoveSearchParam(param)">
        <div class="chips-item__icon">
          <mat-icon *ngIf="param.type === 'keyword'" svgIcon="search"></mat-icon>
          <mat-icon *ngIf="param.type === 'contact'" svgIcon="search-connection"></mat-icon>
          <mat-icon *ngIf="param.type === 'topic'" svgIcon="search-pellet"></mat-icon>
          <mat-icon *ngIf="param.type === 'tag'" svgIcon="tags-small" class="tag-icon"></mat-icon>
        </div>
        <div class="chips-item__text">{{param.value}}</div>
        <div matChipRemove class="chips-item__delete">
          <mat-icon svgIcon="close"></mat-icon>
        </div>
      </mat-chip>
      <input
        #searchInput
        class="search-input"
        placeholder="Search..."
        [formControl]="searchCtrl"
        [matAutocomplete]="autocompleteSearch"
        [matAutocompleteConnectedTo]="originAutocompleteSearch"
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="false">
    </mat-chip-list>
    <button class="button-icon btn-close" *ngIf="searchParams.length || (searchCtrl?.value && searchCtrl?.value?.length)" (click)="collapseSearch()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</div>

<!-- Autocomplete -->
<mat-autocomplete
  autoActiveFirstOption
  #autocompleteSearch="matAutocomplete"
  (optionSelected)="onAutocompleteSearchParamSelected($event)">
  <!-- History -->
  <mat-optgroup [label]="'History'" class="autocomplete__group__title" *ngIf="!(searchCtrl?.value && searchCtrl?.value?.length) && !searchParams?.length">
    <mat-option *ngFor="let params of searchHistory" [value]="params" class="autocomplete-option autocomplete-option__history">
      <div class="search-history__wrapper">
        <mat-chip-list>
          <mat-chip
            *ngFor="let param of params"
            class="chips-item avoid-clicks"
            [selectable]="false"
            [disableRipple]="true">
            <div class="chips-item__icon">
              <mat-icon *ngIf="param.type === 'keyword'" class="chips-item__icon__history" svgIcon="search"></mat-icon>
              <mat-icon *ngIf="param.type === 'contact'" class="chips-item__icon__history" svgIcon="search-connection"></mat-icon>
              <mat-icon *ngIf="param.type === 'topic'" class="chips-item__icon__history" svgIcon="search-pellet"></mat-icon>
              <mat-icon *ngIf="param.type === 'tag'" class="chips-item__icon__history tag-icon" svgIcon="tags-small"></mat-icon>
            </div>
            <div class="chips-item__text">{{param?.value}}</div>
          </mat-chip>
        </mat-chip-list>
        <div class="search-history__delete hover-icon" (click)="onRemoveSearchHistory(params, $event)">
          <mat-icon class="search-history__delete__icon" svgIcon="close"></mat-icon>
        </div>
      </div>
    </mat-option>
  </mat-optgroup>
  <!-- Keywords -->
  <mat-optgroup [label]="'Keywords'" class="autocomplete__group__title" *ngIf="searchCtrl?.value && searchCtrl?.value?.length">
    <mat-option class="autocomplete-option autocomplete-option__keyword" [value]="[{value: searchCtrl?.value, type: 'keyword'}]">
      {{searchCtrl?.value}}
    </mat-option>
  </mat-optgroup>
  <!-- Tags -->
  <mat-optgroup [label]="'Tags'" *ngIf="searchSuggestions?.tags?.length">
    <mat-option class="autocomplete-option__tag" *ngFor="let tag of searchSuggestions?.tags" [value]="[{value: tag.name, type: 'tag'}]">
        #{{tag.name}}
    </mat-option>
  </mat-optgroup>
  <!-- Topics -->
  <mat-optgroup [label]="'Pellets'" *ngIf="searchSuggestions?.topics?.length">
    <mat-option class="autocomplete-option__topic" *ngFor="let topic of searchSuggestions?.topics" [value]="[{value: topic.name, type: 'topic'}]">
        {{topic.name}}
    </mat-option>
  </mat-optgroup>
  <!-- Contacts -->
  <mat-optgroup [label]="'Contacts'" *ngIf="searchSuggestions?.contacts?.length">
    <mat-option *ngFor="let contact of searchSuggestions?.contacts" class="autocomplete-option" [value]="[{value: contact.email, type: 'contact'}]">
      <div class="autocomplete__user">
        <app-user-avatar size="30" [contact]="contact" [usePopover]="false"></app-user-avatar>
        <div class="autocomplete__user__info">
          <div class="autocomplete__user__name">{{contact.name}}</div>
          <div [ngClass]="{'autocomplete__user__email': contact.name, 'autocomplete__user__name': !contact.name}">{{contact.email}}</div>
        </div>
      </div>
    </mat-option>
  </mat-optgroup>
  <!-- Messages -->
  <mat-optgroup [label]="'Messages'" *ngIf="searchEmails?.length">
    <mat-option *ngFor="let message of searchEmails" class="autocomplete-option autocomplete-option__message" [value]="{message: message, input: searchCtrl?.value}">
      <div
        class="box box-solid box-header box-message mail-list-item" [ngClass]="{'vip': message.from?.vip}">
        <div class="pull-right info-icons">
          <i class="fa info-icon" [ngClass]="{'fa-envelope-open': !message.unread, 'fa-envelope': message.unread}"></i>
          <i *ngIf="message.replied" class="fa fa-reply" aria-hidden="true"></i>
          <i *ngIf="message.forwarded" class="fa fa-share" aria-hidden="true"></i>
          <i class="fa fa-paperclip info-icon" *ngIf="message.files.length > 0"></i>
        </div>
        <div class="username"><i *ngIf="message.from?.vip" class="fa fa-user fa-fw vip-icon"></i> {{message.from?.name || message.from?.email || 'Unknown'}} </div>
        <div class="date pull-right">{{message.sentTime | relativeDate }}</div>
        <div class="subject">{{message.subject || '(no subject)'}}</div>
        <div class="message-line">{{ message.snippet }}</div>
      </div>
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>
