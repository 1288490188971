// Common
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// RxJS
import { of, Subject, Observable } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map, takeUntil } from 'rxjs/operators';

// Services
import { SearchService } from '@modules/search/services/search.service';

// Types
import { Topic } from '@modules/topic/types/topic';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { SearchSuggestions } from '@modules/search/types/search-suggestions';

@Component({
  selector: 'app-topics-form-popover',
  templateUrl: './topics-form-popover.component.html',
  styleUrls: ['./topics-form-popover.component.less']
})
export class TopicsFormPopoverComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() topics: Topic[] = [];
  @Input() error: string;
  @Input() loading: boolean;

  // Outputs
  @Output() save = new EventEmitter<Topic[]>();
  @Output() close = new EventEmitter<void>();

  // Public
  public suggestedOptions: Observable<DropdownSelectItem[]>;
  public inputFormControl = new FormControl();

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private searchService: SearchService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.suggestedOptions = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(600),
        distinctUntilChanged(),
        switchMap((title: string) => {
          if (title && title.length) {
            return this.searchService.searchSuggestions(title, ['topics'], 3)
              .pipe(
                map((suggestions: SearchSuggestions) => suggestions.topics.map(topic => ({title: topic.name, value: topic}))),
              );
          }
          return of([]);
        }),
        takeUntil(this.componentNotDestroyed),
      );
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  addTopicToList() {
    if (this.inputFormControl.value && this.inputFormControl.value.length) {
      this.topics.push({name: this.inputFormControl.value, source: 'user'});
      this.inputFormControl.setValue(null);
    }
  }

  deleteTopicFromList(topic: Topic) {
    const index = this.topics.indexOf(topic);
    if (index > -1) {
      this.topics.splice(index, 1);
    }
  }

  handleSave() {
    this.addTopicToList();
    this.save.emit(this.topics);
  }

  closed() {
    this.topics = [];
    this.inputFormControl.setValue(null);
    this.close.emit();
  }
}
