/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-repeater-dropdown.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dropdown-select/dropdown-select.component.ngfactory";
import * as i3 from "../dropdown-select/dropdown-select.component";
import * as i4 from "ngx-contextmenu";
import * as i5 from "./schedule-repeater-dropdown.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/dialog";
var styles_ScheduleRepeaterDropdownComponent = [i0.styles];
var RenderType_ScheduleRepeaterDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleRepeaterDropdownComponent, data: {} });
export { RenderType_ScheduleRepeaterDropdownComponent as RenderType_ScheduleRepeaterDropdownComponent };
export function View_ScheduleRepeaterDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "event-form-horizontal-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dropdown-select", [["maxWidth", "200px"], ["placeholder", "Repeat"]], null, null, null, i2.View_DropdownSelectComponent_0, i2.RenderType_DropdownSelectComponent)), i1.ɵdid(2, 245760, null, 0, i3.DropdownSelectComponent, [i4.ContextMenuService], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], options: [2, "options"], maxWidth: [3, "maxWidth"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; var currVal_1 = "Repeat"; var currVal_2 = _co.options; var currVal_3 = "200px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ScheduleRepeaterDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule-repeater-dropdown", [], null, null, null, View_ScheduleRepeaterDropdownComponent_0, RenderType_ScheduleRepeaterDropdownComponent)), i1.ɵdid(1, 638976, null, 0, i5.ScheduleRepeaterDropdownComponent, [i6.FormBuilder, i7.DatePipe, i8.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleRepeaterDropdownComponentNgFactory = i1.ɵccf("app-schedule-repeater-dropdown", i5.ScheduleRepeaterDropdownComponent, View_ScheduleRepeaterDropdownComponent_Host_0, { inputFormControl: "inputFormControl", customRepeatFormControl: "customRepeatFormControl", selectedDate: "selectedDate" }, { selected: "selected" }, []);
export { ScheduleRepeaterDropdownComponentNgFactory as ScheduleRepeaterDropdownComponentNgFactory };
