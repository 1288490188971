// Common
import { Component, Input, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';

// Types
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MailMessage } from '@modules/mail/types/mail-message';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';
import { PopoverPlacement } from '@modules/popover/types/placement';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults }
  ],
})
export class EventComponent implements OnChanges, OnDestroy {

  // Inputs
  @Input() event: CalendarEvent;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() hoverPlacement: PopoverPlacement = 'right';
  @Input() selectedEvents: CalendarEvent[] = [];

  // Public
  public selected = false;
  public isDragging = false;
  public contextMenuOpened = false;
  public dragData: CalendarEvent[] = [];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'event' && dragData.data.length === 1 && dragData.data[0]['id'] === this.event.id) &&
    ['message', 'event', 'task', 'project', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected modalService: ModalService,
    protected eventsStateService: EventsStateService,
    protected changeDetector: ChangeDetectorRef,
    protected calendarService: CalendarService,
    protected linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('event' in changes) {
      this.dragData = [this.event];
    }
    if ('selectedEvents' in changes) {
      if (
        this.event &&
        this.selectedEvents &&
        this.selectedEvents.some(selected => selected.id === this.event.id)
      ) {
        this.dragData = this.selectedEvents;
        this.selected = true;
      } else {
        this.selected = false;
        this.dragData = [this.event];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleDoubleClick(calendarEvent: CalendarEvent) {
    this.ga.trackEvent('events-list', 'open-event-modal');
    this.modalService.calendarEventForm(calendarEvent);
  }

  pinEvent(clickEvent: MouseEvent) {
    this.calendarService.pinEvent([this.event.id], !this.event.pinned);
    clickEvent.stopPropagation();
  }

  archiveEvent(clickEvent: MouseEvent) {
    this.calendarService.archiveEvents([this.event.id], !this.event.archived);
    clickEvent.stopPropagation();
  }

  deleteEvent(clickEvent: MouseEvent) {
    if (!this.event.readOnly) {
      this.event.deleted
        ? this.calendarService.deletePermanentlyEvent([this.event.id])
        : this.calendarService.deleteEvent([this.event.id], true);
    }
    clickEvent.stopPropagation();
  }

  createMail(clickEvent: MouseEvent) {
    this.modalService.compose(MailMessage.fromCalendarEvent(this.event));
    clickEvent.stopPropagation();
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    if (!dragData.data) {
      return;
    }
    // Message | Event | Project | Task | Note
    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('event', this.event.id), linkedItems);
    }
    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {eventsIds: [this.event.id]});
    }
    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {eventsIds: [this.event.id]});
    }
  }
}
