// Common
import { Component, OnInit, Input } from '@angular/core';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';

// Types
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-calendar-event-modal',
  templateUrl: './calendar-event-modal.component.html',
  styleUrls: ['./calendar-event-modal.component.less']
})
export class CalendarEventModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() event: CalendarEvent;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 350px)',
    y: 'calc(50% - 250px)',
    width: '700px',
    height: '500px'
  };

  // Public
  public windowHeight: string;
  public calendarEvent: CalendarEvent;
  public selectedDate: Date = new Date();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
    this.calendarEvent = null;
  }

  /**
   * Actions
   */

  updatedEvent() {
    this.calendarService.createNewEvent.next(this.calendarEvent);
    this.close();
  }

}
