import {Injectable} from '@angular/core';

Injectable();

export class PrintService {

  printInnerHtml(innerHtml: string) {

    let printMailTemplate: string = '<html><head>' +
      '<link rel="stylesheet" href="../../assets/print.css">' +
      '</head>' +
      '<body>{{content}}</body></html>';
    printMailTemplate = printMailTemplate.replace('{{content}}', innerHtml);

    const printWin = window.open();
    printWin.document.write(printMailTemplate);
    printWin.focus();
    setTimeout(() => printWin.print(), 0);
  }
}
