// Common
import { Component, Input, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
import { FormGroup } from '@angular/forms';

// RX
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

// Types
import { Section } from '@modules/tasks/types/section';
import { SectionsTree } from '@modules/tasks/types/sections-tree';
import { Task } from '@modules/tasks/types/task';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Services
import { SectionsService } from '../../../tasks/services/sections.service';
import { SectionsTreeService } from '@modules/tasks/services/sections-tree.service';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: 0, opacity: 0 }),
    animate(
      '.3s ease-in-out',
      style({ height: '{{height}}', opacity: 1 })
    )
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{height}}', opacity: 1 }),
    animate(
      '.3s ease-in-out',
      style({ height: 0, opacity: 0 })
    )
  ])
]);

@Component({
  selector: 'app-project-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.less'],
  animations: [collapseMotion]
})
export class SectionComponent implements OnInit, OnDestroy {

  // Public
  public collapsed = false;
  public contentHeight = '0px';
  public sectionForm: FormGroup;
  public repositionStream: Subject<DragData> = new Subject<null>();

  // Private
  private alive = new Subject<void>();

  // Inputs
  @Input() section: Section;
  @Input() sectionsTree: SectionsTree;
  @Input() appearance: 'board' | 'default' = 'default';
  @Input() last: boolean;
  @Input() allowedNewTasksSections = true;

  // View Children
  @ViewChild('collapseableContent', { static: false }) collapseableContent: ElementRef;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => dragData.type === 'task';

  /**
   * Constructor
   */

  constructor (
    private sectionsService: SectionsService,
    private sectionsTreeService: SectionsTreeService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.sectionForm = this.section.asFormGroup();

    this.repositionStream.subscribe((data: DragData) => {
      this.sectionsTreeService.moveTask(this.sectionsTree, data.data[0] as Task, this.section, data.index);
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleError() {

  }

  handleBlur() {
    if (
      this.sectionForm.get('title').dirty &&
      this.sectionForm.get('title').value.trim() !== ''
    ) {
      const section = Section.fromFormGroup(this.sectionForm);

      const saveRequest: Observable<Section> = (
        section.id && section.id !== 'temp'
          ? this.sectionsService.update(section)
          : this.sectionsTreeService.submitSection(this.sectionsTree, section)
      );

      saveRequest
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe(
          () => {},
          () => this.handleError()
        );
    }
  }

  toggleCollapse() {
    this.contentHeight = this.collapseableContent.nativeElement.offsetHeight + 'px';
    this.collapsed = !this.collapsed;
  }

  pin() {
    this.sectionsService.pin([this.section.id], !this.section.pinned)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((sections: Section[]) => {
        if (sections[0]) {
          this.section.pinned = sections[0].pinned;
        }
      });
  }

  archive() {
    this.sectionsService.archive([this.section.id], !this.section.archived)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((sections: Section[]) => {
        if (sections[0]) {
          this.section.archived = sections[0].archived;
        }
      });
  }

  delete() {
    this.sectionsService.deletePermanently([this.section.id]);
  }

  public handleMove(direction: 1 | -1) {
    if (
      (this.last && direction > 0) ||
      (this.section.position <= 1 && direction < 0)
    ) { return; }

    this.sectionsTreeService.moveSection(this.sectionsTree, this.section, direction);
  }

  /**
   * Helpers
   */

  tasksTrackByFn(task: Task): string {
    return task && task.id;
  }
}
