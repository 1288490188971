// Common
import { Directive, Input, Output, EventEmitter, ComponentRef } from '@angular/core';

// Types
import { Contact } from '@modules/mail/types/contact';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
import { ContactContextMenuComponent } from '../contact-context-menu/contact-context-menu.component';


@Directive({
  selector: '[appContactContextMenu]'
})
export class ContactContextMenuDirective extends ContextMenuDirective {

  @Input() appContactContextMenu: Contact;
  @Input() contextMenuComponent = ContactContextMenuComponent;
  @Output() loadInKnowledgePanel = new EventEmitter();

  setContextMenuInstanceData(componentRef: ComponentRef<ContactContextMenuComponent>) {
    componentRef.instance.contextMenuItem = this.appContactContextMenu;
    componentRef.instance.relatedContact = this.appContactContextMenu;
    componentRef.instance.loadInKnowledgePanel = this.loadInKnowledgePanel;
  }
}
