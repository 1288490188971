// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { MailService } from '@modules/mail/services/mail.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { ResponseMessages } from '@modules/mail/types/mail-response.model';
import { MailMessage } from '@modules/mail/types/mail-message';

// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';

@Component({
  selector: 'app-link-info-message-suggestions',
  templateUrl: './link-info-message-suggestions.component.html',
  styleUrls: ['./link-info-message-suggestions.component.less']
})
export class LinkInfoMessageSuggestionsComponent extends LinkInfoItemSuggestions implements OnInit, OnDestroy {

  /**
   * Constructor
   */

  constructor(
    protected modalService: ModalService,
    protected mailService: MailService,
    protected linkedInfoService: LinkedInfoService
  ) {
    super(linkedInfoService);
  }

  /**
   * Methods
   */

  //  Override method LinkInfoItemSuggestions
  getSuggestions(inputValue: string): Observable<any[]> {
    if (inputValue && inputValue.length) {
      return this.mailService.getMessages({relevance: true, keywords: [inputValue]}, 'date', 10, 0)
        .pipe(
          map((response: ResponseMessages) => response.messages || [])
        );
    }
    return of([]);
  }

  //  Override method LinkInfoItemSuggestions
  openCreateModal() {
    const message = new MailMessage;
    message.linkedInfo = this.linkedInfo;
    this.modalService.compose(message);
  }

}
