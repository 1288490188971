// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone } from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { Task } from '@modules/tasks/types/task';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.less'],
})
export class TasksListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no tasks';
  @Input() defaultFilters: TasksFilters = {};

  // Public
  public itemHeight = 60;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService,
    private tasksStateService: TasksStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone
  ) {
    super(ngZone);

    this.tasksStateService.getSelectedTasks()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((tasks: Task[]) => {
        this.selectedItems = tasks;
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.tasksStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Make automatic updates for new task
      interval(environment.messageFetchInterval)
        .pipe(
          filter(() => !this.loading && this.selectedOrder && this.selectedOrder.key === 'date')
        ),
      this.tasksService.taskCreated,
      this.tasksService.taskUpdated,
      this.tasksService.taskDeleted,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('defaultFilters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.tasksService
      .getTasks({
        ...this.defaultFilters,
        limit,
        offset
      })
      .pipe(
        map(({ tasks, count }) => ({ items: tasks, count }))
      );
  }

  resetItems() {
    this.tasksStateService.setSelectedTasks([]);
    super.resetItems();
  }

  selectItem(task: Task, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(task, event, selectAll);

    this.tasksStateService.setSelectedTasks(this.selectedItems as Task[]);

    if (this.selectedItems.length === 1) {
      this.tasksStateService.setMainViewTask(this.selectedItems[0] as Task);
      this.tasksStateService.setMainView('task-form');
    }
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
