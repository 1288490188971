// Common
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

// RxJS
import { Observable, Subject, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map, takeUntil } from 'rxjs/operators';

// Services
import { TagService } from '@modules/tag/services/tag.service';
import { SearchService } from '@modules/search/services/search.service';

// Types
import { Tag } from '@modules/tag/types/tag';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { SearchSuggestions } from '@modules/search/types/search-suggestions';

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.less']
})
export class CreateTagComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() tags: Tag[] = [];
  @Input() messages: string[];
  @Input() events: string[];
  @Input() projects: string[];

  // Outputs
  @Output() saved = new EventEmitter<Tag[]>();
  @Output() close = new EventEmitter<void>();

  // Public
  public suggestedOptions: Observable<DropdownSelectItem[]>;

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private tagService: TagService,
    private searchService: SearchService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.suggestedOptions = this.inputFormControl.valueChanges
        .pipe(
          startWith(''),
          debounceTime(600),
          distinctUntilChanged(),
          switchMap((title: string) => {
            if (title && title.length) {
              return this.searchService.searchSuggestions(title, ['tags'], 3)
                .pipe(
                  map((suggestions: SearchSuggestions) => suggestions.tags.map(tag => ({title: tag.name, value: tag}))),
                );
            }
            return of([]);
          }),
          takeUntil(this.componentNotDestroyed),
        );
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  addTagToList() {
    if (this.inputFormControl.value && this.inputFormControl.value.length) {
      this.tags.push({name: this.inputFormControl.value});
      this.inputFormControl.setValue(null);
    }
  }

  deleteTagFromList(tag: Tag) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
  }

  save() {
    this.addTagToList();
    this.tagService.createTags(
      this.tags,
      {
        messagesIds: this.messages,
        eventsIds: this.events,
        projectsIds: this.projects
      }
    );
    this.saved.emit(this.tags);
  }

  closed() {
    this.close.emit();
  }

}
