/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-nested.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dropdown-item/dropdown-item.component.ngfactory";
import * as i3 from "../dropdown-item/dropdown-item.component";
import * as i4 from "@angular/common";
import * as i5 from "../dropdown/dropdown.component.ngfactory";
import * as i6 from "../dropdown/dropdown.component";
import * as i7 from "./dropdown-nested.component";
var styles_DropdownNestedComponent = [i0.styles];
var RenderType_DropdownNestedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownNestedComponent, data: {} });
export { RenderType_DropdownNestedComponent as RenderType_DropdownNestedComponent };
function View_DropdownNestedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownNestedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-item", [], null, null, null, i2.View_DropdownItemComponent_0, i2.RenderType_DropdownItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.DropdownItemComponent, [i1.ElementRef], { title: [0, "title"], arrowIcon: [1, "arrowIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DropdownNestedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null))], null, null); }
export function View_DropdownNestedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownNestedComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 9, "app-dropdown", [["class", "dropdown-nested"], ["placement", "right"], ["trigger", "hover"]], null, null, null, i5.View_DropdownComponent_0, i5.RenderType_DropdownComponent)), i1.ɵdid(3, 1753088, null, 6, i6.DropdownComponent, [], { placement: [0, "placement"], trigger: [1, "trigger"] }, null), i1.ɵqud(335544320, 1, { toggleButtonTemplate: 0 }), i1.ɵqud(603979776, 2, { singleSelects: 1 }), i1.ɵqud(603979776, 3, { multiSelects: 1 }), i1.ɵqud(603979776, 4, { actionItems: 1 }), i1.ɵqud(603979776, 5, { templateItems: 1 }), i1.ɵqud(603979776, 6, { colorSelect: 1 }), (_l()(), i1.ɵand(0, [[1, 2], ["toggleButtonTemplate", 2]], 0, 0, null, View_DropdownNestedComponent_2)), i1.ɵncd(0, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownNestedComponent_3)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = "right"; var currVal_2 = "hover"; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.divider; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_DropdownNestedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-nested", [], null, null, null, View_DropdownNestedComponent_0, RenderType_DropdownNestedComponent)), i1.ɵdid(1, 114688, null, 0, i7.DropdownNestedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownNestedComponentNgFactory = i1.ɵccf("app-dropdown-nested", i7.DropdownNestedComponent, View_DropdownNestedComponent_Host_0, { title: "title", name: "name", divider: "divider" }, {}, ["*"]);
export { DropdownNestedComponentNgFactory as DropdownNestedComponentNgFactory };
