import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  customRepeatForm: FormGroup;
  timePeriodOptions: { title: string, value: any }[];
}

@Component({
  selector: 'custom-repeater-dialog',
  templateUrl: 'custom-repeater-dialog.component.html',
  styleUrls: ['../../../../events/components/event-form/event-form.component.less', './custom-repeater-dialog.component.less']
})

export class RepeaterDialogComponent {

  public days = [
    { title: 'M', value: 'monday'},
    { title: 'T', value: 'tuesday'},
    { title: 'W', value: 'wednesday'},
    { title: 'T', value: 'thursday'},
    { title: 'F', value: 'friday'},
    { title: 'S', value: 'saturday'},
    { title: 'S', value: 'sunday'},
  ];

  constructor(
    public dialogRef: MatDialogRef<RepeaterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  cancel(): void {
    this.dialogRef.close();
  }
  submit(): void {
    if (this.data.customRepeatForm.valid) {
      this.dialogRef.close(true);
    }
  }

  daySelected(val) {
    return (this.data.customRepeatForm.controls['repeatDays'].value).some(item => item === val);
  }

  dayClick(day) {
    const index = this.data.customRepeatForm.controls['repeatDays'].value.indexOf(day);
    if (index >= 0) {
      this.data.customRepeatForm.controls['repeatDays'].value.splice(index, 1);
    } else {
      this.data.customRepeatForm.controls['repeatDays'].value.push(day);
    }
  }
}
