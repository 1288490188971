import * as tslib_1 from "tslib";
// Other
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
import { MailMessage } from '@modules/mail/types/mail-message';
var EventsAgendaContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EventsAgendaContextMenuComponent, _super);
    /**
     * Constructor
     */
    function EventsAgendaContextMenuComponent(contextMenuService, modalService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.modalService = modalService;
        return _this;
    }
    /**
     * Actions
     */
    EventsAgendaContextMenuComponent.prototype.copyLinkToAgenda = function () {
    };
    EventsAgendaContextMenuComponent.prototype.openInWindow = function () {
    };
    EventsAgendaContextMenuComponent.prototype.copy = function () {
    };
    EventsAgendaContextMenuComponent.prototype.paste = function () {
    };
    EventsAgendaContextMenuComponent.prototype.print = function () {
    };
    EventsAgendaContextMenuComponent.prototype.deleteAgenda = function () {
    };
    EventsAgendaContextMenuComponent.prototype.moveToArchive = function () {
    };
    EventsAgendaContextMenuComponent.prototype.selectAll = function () {
    };
    EventsAgendaContextMenuComponent.prototype.snooze = function (snooze) {
    };
    EventsAgendaContextMenuComponent.prototype.openCustomDateModal = function (reminderType) {
        this.modalService.reminderDateTimePicker(new MailMessage(), reminderType);
    };
    return EventsAgendaContextMenuComponent;
}(ContextMenuComponent));
export { EventsAgendaContextMenuComponent };
