// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { ListState } from '@modules/tasks/types/list-state';
import { TasksSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { SearchService } from '@modules/search/services/search.service';
import { StateService } from '@modules/settings/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tasks-list-container',
  templateUrl: './tasks-list-container.component.html',
  styleUrls: ['./tasks-list-container.component.less'],
})
export class TasksListContainerComponent implements OnInit, OnDestroy {

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public currentSidebarFilter: TasksSidebarFilterKey;
  public selectedSidebarProject: Project;
  public quickFormExpanded = false;
  public listFilters: TasksFilters;
  public loading: Boolean;
  public quickFormProject: Project = new Project();
  public quickFormTask: Task = new Task();
  public quickFormToggle: Subject<void> = new Subject();
  public orders: DropdownOption[] = [
    {name: 'Recency', key: 'recency'},
    {name: 'Date', key: 'date'},
    {name: 'Title', key: 'title'},
  ];
  public scrollOptions = [
    {name: 'Scroll to top', key: 'scroll-top'},
    {name: 'Scroll to bottom', key: 'scroll-bottom'}
  ];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private searchTitle = new BehaviorSubject('');

  /**
   * Constructor
   */

  constructor(
    private splitViewService: SplitViewService,
    private tasksStateService: TasksStateService,
    private searchService: SearchService,
    private stateService: StateService,
  ) {
    combineLatest([
      this.searchService.getSearchParams(),
      this.searchTitle,
      this.tasksStateService.getSidebarFilters()
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([searchParams, title, sidebarFilters]) => {
        const keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];

        this.listFilters = {
          ...searchParams,
          ...sidebarFilters,
          keywords: !title || title.trim() === '' ? keywords : [...keywords, title]
        };
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('tasksList')
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.tasksStateService.getListState()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((state: ListState) => this.listState = state);
    this.tasksStateService.getTasksFilters()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((filters: TasksFilters) => this.listFilters = filters);

    this.stateService.getSidebarFilters('tasks')
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((currentSidebarFilter: TasksSidebarFilterKey) => this.currentSidebarFilter = currentSidebarFilter);

    this.tasksStateService.getProject()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((project: Project) => this.selectedSidebarProject = project);

    this.selectedOrder = this.stateService.sortTasksProjectsList;
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleScrollOptionChange(scrollOption: 'scroll-top' | 'scroll-bottom'): void {
    this.listScrollPosition = null;
    setTimeout(() => this.listScrollPosition = scrollOption === 'scroll-top' ? 0 : -1);
  }

  handleSelectOrder(order: DropdownOption): void {
    this.selectedOrder = order;
    this.stateService.sortTasksProjectsList = order;
  }

  handleNewTask(task = new Task()) {
    this.tasksStateService.openTaskForm(task);
  }

  handleNewProject(project = new Project()) {
    this.tasksStateService.openProjectForm(project);
  }

  handleSearchQueryChange(title: string) {
    this.searchTitle.next(title);
  }

  handleProjectQuickFormDrop(dragData: DragData) {
    const project = Project.fromDragData(dragData);

    if (!project) { return; }

    this.quickFormProject = project;
    this.quickFormToggle.next();
  }

  handleTaskQuickFormDrop(dragData: DragData) {
    const task = Task.fromDragData(dragData);

    if (!task) { return; }

    this.quickFormTask = task;
    this.quickFormToggle.next();
  }
}
