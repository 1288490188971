<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="files-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
>
  <ng-container *cdkVirtualFor="let file of itemsStreamObservable; let i = index">
    <app-file
      [file]="file"
      (click)="selectItem(file, $event)"
      [selectedFiles]="selectedItems"
    ></app-file>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="files-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true || (selectedItems && selectedItems?.length > 1))">
  <div class="total">Items: {{items.length}}</div>
  <div *ngIf="selectedItems?.length > 1" class="selected">Selected: {{selectedItems?.length}}</div>
</div>

<div class="files-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="files-stub-image" src="assets/placeholders/placeholder-files.svg"/>
  <p class="files-stub-text">{{ placeholderText }}</p>
</div>
