/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./caja.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./caja.component";
var styles_CajaComponent = [i0.styles];
var RenderType_CajaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CajaComponent, data: {} });
export { RenderType_CajaComponent as RenderType_CajaComponent };
export function View_CajaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "caja-body"]], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uniqueBodyID; _ck(_v, 0, 0, currVal_0); }); }
export function View_CajaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-caja", [], null, null, null, View_CajaComponent_0, RenderType_CajaComponent)), i1.ɵdid(1, 4833280, null, 0, i2.CajaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CajaComponentNgFactory = i1.ɵccf("app-caja", i2.CajaComponent, View_CajaComponent_Host_0, { body: "body" }, {}, []);
export { CajaComponentNgFactory as CajaComponentNgFactory };
