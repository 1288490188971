/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-cancel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./account-cancel.component";
import * as i4 from "../../services/account.service";
import * as i5 from "../../../auth/services/auth.service";
import * as i6 from "@angular/router";
var styles_AccountCancelComponent = [i0.styles];
var RenderType_AccountCancelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountCancelComponent, data: {} });
export { RenderType_AccountCancelComponent as RenderType_AccountCancelComponent };
function View_AccountCancelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountCancelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-3x fa-fw"]], null, null, null, null, null))], null, null); }
export function View_AccountCancelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "box-header with-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "box-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage your account"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "box-body with-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "alert alert-warning"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Warning!"])), (_l()(), i1.ɵted(-1, null, [" Account cancellation cannot be undone. "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel account"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountCancelComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountCancelComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 15, 0, currVal_1); }, null); }
export function View_AccountCancelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-cancel", [], null, null, null, View_AccountCancelComponent_0, RenderType_AccountCancelComponent)), i1.ɵdid(1, 245760, null, 0, i3.AccountCancelComponent, [i4.AccountService, i5.AuthService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountCancelComponentNgFactory = i1.ɵccf("app-account-cancel", i3.AccountCancelComponent, View_AccountCancelComponent_Host_0, {}, {}, []);
export { AccountCancelComponentNgFactory as AccountCancelComponentNgFactory };
