/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-calendar-day.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "angular-calendar";
import * as i4 from "../../../common/directives/click/click.directive";
import * as i5 from "../../../drag-n-drop/directives/droppable.directive";
import * as i6 from "../../../drag-n-drop/services/drag-n-drop.service";
import * as i7 from "../../directives/calendar-cell-context-menu.directive";
import * as i8 from "../../../pipes/pipes/same-day.pipe";
import * as i9 from "../../../pipes/pipes/check-date.pipe";
import * as i10 from "../calendar-week/components/customized-calendar-week/customized-calendar-week.component.ngfactory";
import * as i11 from "../calendar-week/components/customized-calendar-week/customized-calendar-week.component";
import * as i12 from "./full-calendar-day.component";
var styles_FullCalendarDayComponent = [i0.styles];
var RenderType_FullCalendarDayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullCalendarDayComponent, data: { "animation": [{ type: 7, name: "dateAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: { type: 8, animation: [{ type: 6, styles: { opacity: 0, transform: "translateX({{offsetStart}}%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateX({{offsetFinish}}%)" }, offset: null }, timings: "{{ duration }} ease-out" }], options: { params: { duration: "300ms", offsetStart: -5, offsetFinish: 0 } } }, options: null }], options: {} }] } });
export { RenderType_FullCalendarDayComponent as RenderType_FullCalendarDayComponent };
function View_FullCalendarDayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "cal-header"]], [[2, "cal-past", null], [2, "cal-today", null], [2, "cal-future", null], [2, "cal-weekend", null], [2, "cal-selected", null]], [[null, "mwlClick"]], function (_v, en, $event) { var ad = true; if (("mwlClick" === en)) {
        var pd_0 = (_v.parent.parent.context.dayHeaderClicked.emit({ day: _v.context.$implicit }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 212992, null, 0, i3.ɵk, [i1.Renderer2, i1.ElementRef, i2.DOCUMENT, i1.NgZone], null, { click: "mwlClick" }), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "side-borders-overflow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "flexRow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "headerDate"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLoadDayEvents(_v.context.$implicit.date) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "b", [["class", "day-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 3), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "day-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 3)], function (_ck, _v) { var currVal_5 = "cal-header"; var currVal_6 = _v.context.$implicit.cssClass; _ck(_v, 2, 0, currVal_5, currVal_6); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isPast; var currVal_1 = _v.context.$implicit.isToday; var currVal_2 = _v.context.$implicit.isFuture; var currVal_3 = _v.context.$implicit.isWeekend; var currVal_4 = i1.ɵunv(_v, 0, 4, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.date, _co.selectedDate)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit.date, "weekViewColumnHeader", _v.parent.parent.context.locale)); _ck(_v, 9, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit.date, "weekViewColumnSubHeader", _v.parent.parent.context.locale)); _ck(_v, 12, 0, currVal_8); }); }
function View_FullCalendarDayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "cal-day-headers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "gmt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "timezone"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullCalendarDayComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.days; var currVal_2 = _v.parent.context.trackByWeekDayHeaderDate; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeZoneName; _ck(_v, 3, 0, currVal_0); }); }
function View_FullCalendarDayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullCalendarDayComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideDateHeader; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FullCalendarDayComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "timelineContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "calendar-day__current-time"]], [[4, "top", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "display-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "calendar-day__current-time-line"]], [[4, "top", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentTimeLineOffset - 9); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 3), _co.currentTime, "shortTime")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.currentTimeLineOffset; _ck(_v, 6, 0, currVal_2); }); }
function View_FullCalendarDayComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cal-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.segment.date, "weekViewHour", _v.parent.context.locale)); _ck(_v, 1, 0, currVal_0); }); }
function View_FullCalendarDayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FullCalendarDayComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 16777216, [["cell", 1]], null, 7, "div", [["appCalendarCellContextMenuCalendarType", "day"], ["appDroppable", ""], ["appDroppableHoverClass", "drag-over"], ["class", "cal-hour-segment"]], [[4, "height", "px"], [2, "cal-hour-start", null], [2, "cal-after-hour-start", null]], [[null, "appDroppableDrop"], [null, "appClick"], [null, "appDblClick"], [null, "appCalendarCellContextMenLoadDayEvents"], [null, "click"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).handleDblClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("appDroppableDrop" === en)) {
        var pd_2 = (_co.handleDrop($event, _v.context.segment.date, i1.ɵnov(_v, 3)) !== false);
        ad = (pd_2 && ad);
    } if (("appClick" === en)) {
        var pd_3 = (_co.clickHourSegment($event, _v.context.segment.date, i1.ɵnov(_v, 3)) !== false);
        ad = (pd_3 && ad);
    } if (("appDblClick" === en)) {
        var pd_4 = (_co.dblClickHourSegment(_v.context.segment.date) !== false);
        ad = (pd_4 && ad);
    } if (("appCalendarCellContextMenLoadDayEvents" === en)) {
        var pd_5 = (_co.handleLoadDayEvents(_v.context.segment.date) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(6, 16384, null, 0, i4.ClickDirective, [], null, { appClick: "appClick", appDblClick: "appDblClick" }), i1.ɵdid(7, 737280, null, 0, i5.DroppableDirective, [i6.DragNDropService, i1.Renderer2, i1.ElementRef, i1.NgZone], { appDroppableHoverClass: [0, "appDroppableHoverClass"] }, { appDroppableDrop: "appDroppableDrop" }), i1.ɵdid(8, 212992, null, 0, i7.CalendarCellContextMenuDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ElementRef, i1.ChangeDetectorRef], { appCalendarCellContextMenu: [0, "appCalendarCellContextMenu"], appCalendarCellContextMenuCalendarType: [1, "appCalendarCellContextMenuCalendarType"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullCalendarDayComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 2), (!_v.context.isTimeLabel && _v.context.segment.date), "beginningOfToday")); _ck(_v, 1, 0, currVal_0); var currVal_4 = "cal-hour-segment"; var currVal_5 = _v.context.segment.cssClass; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = "drag-over"; _ck(_v, 7, 0, currVal_6); var currVal_7 = _v.context.segment.date; var currVal_8 = "day"; _ck(_v, 8, 0, currVal_7, currVal_8); var currVal_9 = _v.context.isTimeLabel; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = _v.context.segmentHeight; var currVal_2 = _v.context.segment.isStart; var currVal_3 = !_v.context.segment.isStart; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_FullCalendarDayComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i8.SameDayPipe, []), i1.ɵpid(0, i3.ɵi, [i3.CalendarDateFormatter, i1.LOCALE_ID]), i1.ɵpid(0, i9.CheckDatePipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "calendar-day--wrapper"]], [[24, "@dateAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵand(0, [["headerTemplate", 2]], null, 0, null, View_FullCalendarDayComponent_1)), (_l()(), i1.ɵand(0, [["hourSegmentTemplate", 2]], null, 0, null, View_FullCalendarDayComponent_4)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-customized-calendar-week", [["class", "calendar"]], null, null, null, i10.View_CustomizedCalendarWeekComponent_0, i10.RenderType_CustomizedCalendarWeekComponent)), i1.ɵdid(8, 4964352, null, 0, i11.CustomizedCalendarWeekComponent, [i1.ChangeDetectorRef, i3.CalendarUtils, i1.LOCALE_ID, i3.DateAdapter], { viewDate: [0, "viewDate"], events: [1, "events"], refresh: [2, "refresh"], weekStartsOn: [3, "weekStartsOn"], headerTemplate: [4, "headerTemplate"], eventTemplate: [5, "eventTemplate"], hourSegments: [6, "hourSegments"], hourSegmentHeight: [7, "hourSegmentHeight"], hourSegmentTemplate: [8, "hourSegmentTemplate"], highlightedDay: [9, "highlightedDay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.displayDate; var currVal_2 = _co.events; var currVal_3 = _co.refreshCalendar; var currVal_4 = 0; var currVal_5 = i1.ɵnov(_v, 5); var currVal_6 = _co.eventTemplate; var currVal_7 = 1; var currVal_8 = _co.hourSegmentHeight; var currVal_9 = i1.ɵnov(_v, 6); var currVal_10 = _co.highlightedDay; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDate; _ck(_v, 4, 0, currVal_0); }); }
export function View_FullCalendarDayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "full-calendar-day", [], null, null, null, View_FullCalendarDayComponent_0, RenderType_FullCalendarDayComponent)), i1.ɵprd(4608, null, i3.CalendarDateFormatter, i12.CalendarWeekDateFormatter, [i3.DateAdapter]), i1.ɵprd(4608, null, i3.CalendarUtils, i12.DayCalendarUtils, [i3.DateAdapter]), i1.ɵdid(3, 4964352, null, 0, i12.FullCalendarDayComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var FullCalendarDayComponentNgFactory = i1.ɵccf("full-calendar-day", i12.FullCalendarDayComponent, View_FullCalendarDayComponent_Host_0, { viewDate: "viewDate", selectedDate: "selectedDate", highlightedDay: "highlightedDay", events: "events", hideDateHeader: "hideDateHeader", eventTemplate: "eventTemplate" }, { dateClicked: "dateClicked", dateDblClicked: "dateDblClicked", eventDropped: "eventDropped", loadDayEvents: "loadDayEvents" }, []);
export { FullCalendarDayComponentNgFactory as FullCalendarDayComponentNgFactory };
