// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { EventsListResponse } from '@modules/events/types/events-list-response';

// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';
import { CalendarEvent } from '@modules/events/types/calendar-event';

@Component({
  selector: 'app-link-info-event-suggestions',
  templateUrl: './link-info-event-suggestions.component.html',
  styleUrls: ['./link-info-event-suggestions.component.less']
})
export class LinkInfoEventSuggestionsComponent extends LinkInfoItemSuggestions implements OnInit, OnDestroy {

  /**
   * Constructor
   */

  constructor(
    protected modalService: ModalService,
    protected calendarService: CalendarService,
    protected linkedInfoService: LinkedInfoService
  ) {
    super(linkedInfoService);
  }

  /**
   * Methods
   */

  //  Override method LinkInfoItemSuggestions
  getSuggestions(inputValue: string): Observable<any[]> {
    if (inputValue && inputValue.length) {
      return this.calendarService.getEvents({
        title: inputValue,
        order: 'date',
        limit: 10,
        offset: 0
      })
        .pipe(
          map((response: EventsListResponse) => response.events || [])
        );
    }
    return of([]);
  }

  //  Override method LinkInfoItemSuggestions
  openCreateModal() {
    const event = new CalendarEvent();
    event.linkedInfo = this.linkedInfo;
    this.modalService.calendarEventForm(event);
  }

}
