// Common
import { Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { endOfDay, startOfDay } from 'date-fns';

// RX
import { Subject, merge, interval, timer, BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, map, distinctUntilChanged } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { MatTabChangeEvent } from '@angular/material';
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { EventFilters } from '@modules/events/types/event-filters';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { Calendar } from '@modules/events/types/calendar';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Environment
import { environment } from '@environment';

@Component({
  selector: 'app-events-panel',
  templateUrl: './events-panel.component.html',
  styleUrls: ['./events-panel.component.less']
})
export class EventsPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  // Public
  public todayFormControl: FormControl = new FormControl(new Date());
  public upcomingDateControl: FormControl = new FormControl(new Date());
  public archiveDateControl: FormControl = new FormControl(new Date());
  public orderOptions: {
    today: DropdownOption[],
    upcoming: DropdownOption[],
    archived: DropdownOption[],
  } = {
    today: [ // TODO don't know what should be here - currently it's just filler
      {name: 'Relevance', key: 'relevance'},
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
      {name: 'Thread', key: 'thread'},
      {name: 'Sender', key: 'sender'},
      {name: 'Attachments', key: 'attachments'}
    ],
    upcoming: [
      {name: 'Date', key: 'date'},
      {name: 'Title', key: 'title'},
    ],
    archived: [
      {name: 'Date', key: 'date'},
      {name: 'Title', key: 'title'},
    ],
  };
  public scrollOptions: {
    today: DropdownOption[],
    upcoming: DropdownOption[],
    archived: DropdownOption[],
  } = {
    today: null,
    upcoming: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
    archived: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ]
  };
  public filterOptions: DropdownOption[] = [];
  public selectedFilters: DropdownOption[] = [];
  public tabs = ['today', 'upcoming', 'archived'];
  public selectedTab: number;
  public orders: {
    today: DropdownOption,
    upcoming: DropdownOption,
    archived: DropdownOption,
  };
  public searchQuery: string;
  public events: AngularCalendarEvent[] = [];
  public filters: EventFilters;
  public upcomingScrollPosition: number;
  public archivedScrollPosition: number;
  public calendarIds: string[] = [];
  public hostWidth = 0;
  public renderFinished = false;
  public quickFormEvent: CalendarEvent = new CalendarEvent();
  public quickFormToggle: Subject<void> = new Subject();

  // Private
  private loadTodayTabEvents: Subject<void> = new Subject();
  private componentNotDestroyed: Subject<void> = new Subject();
  private popoverClose: Subject<void> = new Subject();
  private virtualEvent = new BehaviorSubject<CalendarEvent>(null);

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private stateService: StateService,
    private eventsStateService: EventsStateService,
    private knowledgePanelService: KnowledgePanelService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.stateService.getSelectedKPCalendars()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarIds: string[]) => {
        this.calendarIds = calendarIds;
        this.loadTodayTabEvents.next();
        this.populateSelectedFilters();
      });

    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((calendars: Calendar[]) => calendars.map(calendar => ({name: calendar.name, key: calendar.id})))
      )
      .subscribe((calendars: DropdownOption[]) => {
        this.filterOptions = calendars;
        this.populateSelectedFilters();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.getPersistedState();

    this.loadTodayTabEvents
      .pipe(
        switchMap(() => this.calendarService.getEvents({
          fromDate: startOfDay(this.todayFormControl.value),
          toDate: endOfDay(this.todayFormControl.value),
          calendarIds: this.calendarIds
        })),
        map((response: EventsListResponse) => response.events),
        switchMap((events: CalendarEvent[]) => this.virtualEvent.pipe(
          map((virtualEvent: CalendarEvent) => virtualEvent ? [...events, virtualEvent] : events)
        )),
        map((events: CalendarEvent[]) => events.map(event => event.asAngularCalendarEvent())),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((events: AngularCalendarEvent[]) => this.events = events);


    merge(
      this.eventsStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.calendarService.createNewEvent,
      this.calendarService.updatedEvent,
      this.calendarService.deletedEvent,
      this.todayFormControl.valueChanges
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.loadTodayTabEvents.next();
      });

    this.loadTodayTabEvents.next();
  }

  ngAfterViewInit(): void {
    // Moved here due to problem with scrolling calendar day view to current hour
    this.stateService.getTabsState('kpEvents')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((value: number) => {
        this.selectedTab = value;
      });

    this.stateService.getSplitState('knowledgePanel')
      .pipe(
        takeUntil(this.componentNotDestroyed),
        distinctUntilChanged(),
      )
      .subscribe((value: {current: number, last: number}) => {
        this.hostWidth = value.current;
      });

    // need for disabling animation of tabs when changing from initial state
    timer(0).subscribe(() => this.renderFinished = true);

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSelectTab(tab: MatTabChangeEvent) {
    this.stateService.setTabsState('kpEvents', tab.index);
  }

  handleAdd(event = new CalendarEvent()) {
    this.knowledgePanelService.setFormItem(event);
  }

  selectOrder(type: string, order: DropdownOption) {
    this.orders[type] = order;

    switch (type) {
      case 'today':
        break;
      case 'upcoming':
        this.stateService.sortKnowledgePaneUpcomingEvents = order;
        break;
      case 'archived':
        this.stateService.sortKnowledgePaneArchivedEvents = order;
        break;
    }
  }

  getPersistedState() {
    this.orders = {
      today: null,
      upcoming: this.stateService.sortKnowledgePaneUpcomingEvents,
      archived: this.stateService.sortKnowledgePaneArchivedEvents,
    };
  }

  selectScrollOption(type: string, scrollOption: 'scroll-top' | 'scroll-bottom') {
    const value = scrollOption === 'scroll-top' ? 0 : -1;

    switch (type) {
      case 'today':
        break;
      case 'upcoming':
        this.upcomingScrollPosition = null;
        setTimeout(() => this.upcomingScrollPosition = value);
        break;
      case 'archived':
        this.archivedScrollPosition = null;
        setTimeout(() => this.archivedScrollPosition = value);
        break;
    }
  }

  handleDateClicked(cellEvent: CalendarCellClickEvent) {
    this.virtualEvent.next(
      CalendarEvent.fromCalendarCell(null, cellEvent.date, 'day', true)
    );
  }

  handlePopoverFormClose() {
    this.virtualEvent.next(null);
  }

  handleQuickFormDrop(dragData: DragData) {
    const event = CalendarEvent.fromDragData(dragData);

    if (!event) { return; }

    this.quickFormEvent = event;
    this.quickFormToggle.next();
  }

  handleCellDrop(event: CalendarDropEvent) {
    const calendarAppointment = CalendarEvent.fromDragData(event.dragData);

    if (!calendarAppointment) { return; }

    this.virtualEvent.next(
      CalendarEvent.fromCalendarCell(
        calendarAppointment,
        event.newStart,
        'day',
        true
      )
    );
  }

  handleFiltersChange(filters: DropdownOption[]) {
    this.stateService.selectedKPCalendars = filters.map(option => option.key);
  }

  handleEventClick(event: CalendarEvent) {
    this.knowledgePanelService.setFormItem(event);
  }

  /**
   * Helpers
   */

  populateSelectedFilters() {
    this.selectedFilters = this.filterOptions.filter(option => this.calendarIds.includes(option.key));
  }
}
