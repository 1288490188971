// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.less']
})
export class DateTimeComponent {

  // Inputs
  @Input() public startDate: Date;
  @Input() public startTime: Date;
  @Input() public endDate: Date;
  @Input() public endTime: Date;
  @Input() public dateFormat = 'MMM dd';
  @Input() public timeFormat = 'h:mm aa';
  @Input() public endDateFormat: string;
  @Input() public endTimeFormat: string;
  @Input() public withTime = true;

  // Constructor
  constructor() {
  }

}
