// Common
import {
  Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, ContentChild, AfterViewInit, SimpleChanges, OnChanges
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
import { FormControl } from '@angular/forms';

// Components
import { EventQuickFormComponent } from '../event-quick-form/event-quick-form.component';
import { TaskQuickFormComponent } from '../task-quick-form/task-quick-form.component';
import { ProjectQuickFormComponent } from '../project-quick-form/project-quick-form.component';
import { NoteQuickFormComponent } from '../note-quick-form/note-quick-form.component';
import { ContactQuickFormComponent } from '../contact-quick-form/contact-quick-form.component';
import { GroupQuickFormComponent } from '../group-quick-form/group-quick-form.component';

// RX
import { Subject, EMPTY, timer } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// entered text should start with followed keywords to open quick form
const keywords = {
  'calendar': 'event',
  'cal': 'event',
  'event': 'event',
  'appointment': 'event',
  'appt': 'event',
  'meeting': 'event',
  'mtg': 'event',
  'reminder': 'event',
  'rem': 'event',
  'call': 'event',
  'telephone': 'event',
  'tel': 'event',
  'conference': 'event',
  'conf': 'event',
  'convention': 'event',
  'interview': 'event',
  'todo': 'task',
  'to-do': 'task',
  'task': 'task',
  'errand': 'task',
  'note': 'note',
  'txt': 'note',
  'record': 'note',
  'scribe': 'note',
  'entry': 'note',
  'log': 'note',
  'project': 'project',
  'contact': 'contact',
  'group': 'group',
};

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: '56px' }),
    animate(`.3s ease-in-out`,
      style({
        height: '{{height}}',
      })
    )
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{height}}', }),
    animate(`.3s ease-in-out`,
      style({
        height: '56px',
      })
    )
  ])
]);

@Component({
  selector: 'app-quick-form',
  templateUrl: './quick-form.component.html',
  styleUrls: ['./quick-form.component.less'],
  animations: [collapseMotion],
})
export class QuickFormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  // Inputs
  @Input() placeholder: string;
  @Input() bundledGroup: string;
  @Input() smart: boolean;
  @Input() toggle: Subject<void>;

  // Outputs
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickMore: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemDropped: EventEmitter<DragData> = new EventEmitter();

  // Public
  public titleControl: FormControl = new FormControl();
  public expanded = false;
  public formHeight = '10px';
  public currentFormSelector: 'event' | 'task' | 'project' | 'note' | 'contact' | 'group';
  public forms: {
    event: EventQuickFormComponent,
    project: ProjectQuickFormComponent,
    task: TaskQuickFormComponent,
    note: NoteQuickFormComponent,
    contact: ContactQuickFormComponent,
    group: GroupQuickFormComponent,
  };

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private currentForm: (
    EventQuickFormComponent |
    ProjectQuickFormComponent |
    TaskQuickFormComponent |
    NoteQuickFormComponent |
    ContactQuickFormComponent |
    GroupQuickFormComponent
  );
  private updatedForm = new Subject<void>();
  private toggleChanged = new Subject<void>();

  // View Children
  @ViewChild('formContainer', {static: true}) formContainer: ElementRef;

  // Content Children
  @ContentChild(EventQuickFormComponent, { static: true }) private eventForm: EventQuickFormComponent;
  @ContentChild(ProjectQuickFormComponent, { static: true }) private projectForm: ProjectQuickFormComponent;
  @ContentChild(TaskQuickFormComponent, { static: true }) private taskForm: TaskQuickFormComponent;
  @ContentChild(NoteQuickFormComponent, { static: true }) private noteForm: NoteQuickFormComponent;
  @ContentChild(ContactQuickFormComponent, { static: true }) private contactForm: ContactQuickFormComponent;
  @ContentChild(GroupQuickFormComponent, { static: true }) private groupForm: GroupQuickFormComponent;

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.forms = {
      event: this.eventForm,
      project: this.projectForm,
      task: this.taskForm,
      note: this.noteForm,
      contact: this.contactForm,
      group: this.groupForm,
    };

    this.titleControl.valueChanges
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((value: string) => {
        let expanded = false;

        if (this.smart) {
          const currentFormSelector = keywords[value.trim().split(' ')[0].toLowerCase()];

          if (currentFormSelector) {
            expanded = true;
            this.currentFormSelector = currentFormSelector;
            this.currentForm = this.forms[currentFormSelector];
            this.updatedForm.next();
          }
        } else {
          expanded = value.length > 0;

          if (expanded) {
            this.initForm();
          }
        }

        if (expanded !== this.expanded) {
          this.collapsedChange.emit(this.expanded);
        }

        if (expanded) {
          this.calculateFormHeight();
        } else {
          this.currentFormSelector = null;
        }

        this.expanded = expanded;

        this.valueChange.emit(value);
      });

      this.updatedForm
        .pipe(
          takeUntil(this.componentNotDestroyed),
        )
        .subscribe(() => {
          if (this.currentForm) {
            this.currentForm.close
              .pipe(
                takeUntil(this.updatedForm)
              )
              .subscribe(() => this.handleClose());
          }
        });

    this.toggleChanged
      .pipe(
        switchMap(() => this.toggle || EMPTY),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(async () => {
        if (!this.expanded) {
          this.initForm();

          timer(0).subscribe(() => {
            this.calculateFormHeight();
            this.expanded = true;
          });
        } else {
          this.expanded = false;
        }
      });

    this.toggleChanged.next();
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('toggle' in changes) {
      this.toggleChanged.next();
    }
  }

  /**
   * Actions
   */

  handleClose() {
    this.titleControl.setValue('');
    if (this.currentForm) {
      this.currentForm.reset();
    }
  }

  handleMore() {
    this.clickMore.emit();
  }

  handleDrop(dragData: DragData) {
    this.itemDropped.emit(dragData);
  }

  initForm() {
    const [[selector, form]] = Object.entries(this.forms).filter(([formType, formComponent]) => !!formComponent);
    this.currentFormSelector = <'event' | 'task' | 'project' | 'note' | 'contact' | 'group'>selector;
    this.currentForm = form;
    this.updatedForm.next();
  }

  calculateFormHeight() {
    this.formHeight = this.formContainer.nativeElement.offsetHeight + 56 + 'px';
  }
}
