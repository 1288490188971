<app-form-collapseable-group
  title="Attachments"
  [extendIconEnabled]="extendIconEnabled"
  [headerRightIconsTemplate]="rightIcons"
  [extendIconSelected]="dynamicPanelType === 'attachments' && !dynamicPanel"
  [extendIcon]="dynamicPanel && 'open-modal'"
  (extendIconAction)="extendAction.emit()"
>
  <app-file-uploader
    [attachments]="attachments"
    [attachmentsFormControl]="attachmentsFormControl"
    (attachmentsChange)="attachmentsChange.emit($event)"
    (uploading)="uploading.emit($event)"
    [upload]="upload"
  ></app-file-uploader>
</app-form-collapseable-group>

<ng-template #rightIcons>
  <app-icon-button
    class="right-icon"
    icon="download"
    (clicked)="downloadAll()"
    [disabled]="!attachments?.length && !attachmentsFormControl?.value?.length"
  ></app-icon-button>
  <app-icon-button
    class="right-icon"
    icon="preview"
    (clicked)="showAttachments()"
    [disabled]="!attachments?.length && !attachmentsFormControl?.value?.length"
  ></app-icon-button>
</ng-template>
