import * as tslib_1 from "tslib";
import { warmUpObservable } from '@decorators';
// Rx
import { throwError, Subject, of, forkJoin } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Types
import { Project } from '../types/project';
import { Task } from '../types/task';
import { Section } from '../types/section';
// Env
import { environment } from '@environment';
var ProjectsService = /** @class */ (function () {
    /**
     * Constructor
     */
    function ProjectsService(http, asyncTasksToastsService, linkedInfoService, topicService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.linkedInfoService = linkedInfoService;
        this.topicService = topicService;
        this.projectCreated = new Subject();
        this.projectUpdated = new Subject();
        this.projectDeleted = new Subject();
    }
    ProjectsService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /*
    * Helpers
    * */
    ProjectsService.prototype.formatFilters = function (filters) {
        var formattedFilters = {};
        if (filters.projectsIds) {
            formattedFilters['projects_ids[]'] = filters.projectsIds;
        }
        if (filters.archived !== null && filters.archived !== undefined) {
            formattedFilters['archived'] = filters.archived + '';
        }
        if (filters.deleted !== null && filters.deleted !== undefined) {
            formattedFilters['deleted'] = filters.deleted + '';
        }
        if (filters.pinned !== null && filters.pinned !== undefined) {
            formattedFilters['pinned'] = filters.pinned + '';
        }
        if (filters.fromTime) {
            formattedFilters['from_time'] = filters.fromTime.getTime() + '';
        }
        if (filters.toTime) {
            formattedFilters['to_time'] = filters.toTime.getTime() + '';
        }
        if (filters.scheduled !== null && filters.scheduled !== undefined) {
            formattedFilters['scheduled'] = filters.scheduled + '';
        }
        if (filters.priority) {
            formattedFilters['priority'] = filters.priority;
        }
        if (filters.keywords) {
            formattedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.topics) {
            formattedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formattedFilters['tags[]'] = filters.tags;
        }
        if (filters.contacts) {
            formattedFilters['contacts[]'] = filters.contacts;
        }
        if (filters.order) {
            formattedFilters['order'] = filters.order;
        }
        if (filters.orderWithPinned) {
            formattedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.limit) {
            formattedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formattedFilters['offset'] = filters.offset + '';
        }
        return formattedFilters;
    };
    /**
     * Methods
     */
    ProjectsService.prototype.getProjects = function (filters) {
        if (filters === void 0) { filters = {}; }
        var requestParams = { params: this.formatFilters(filters) };
        return this.http.get(environment.baseUrl + '/api/projects', requestParams)
            .pipe(map(function (_a) {
            var count = _a.count, projects = _a.projects;
            return ({ count: count, projects: projects.map(function (project) { return new Project(project); }) });
        }), catchError(ProjectsService.handleObserverError));
    };
    ProjectsService.prototype.getProjectSections = function (projectId) {
        return of(sectionsMock
            .map(function (section) { return (tslib_1.__assign({}, section, { tasks: tasksMock.filter(function (task) { return task.sectionId === section.id; }) })); })
            .map(function (section) { return new Section(section); }));
    };
    ProjectsService.prototype.getProject = function (id) {
        return this.http.get(environment.baseUrl + '/api/projects/' + id)
            .pipe(map(function (_a) {
            var project = _a.project;
            return new Project(project);
        }));
    };
    ProjectsService.prototype.create = function (newProject) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/projects', newProject.asPayloadJSON())
            .pipe(map(function (_a) {
            var project = _a.project;
            return project;
        }), tap(function (project) {
            if (newProject.linkedInfo && newProject.linkedInfo.length) {
                _this.linkedInfoService.linkToItem({ type: 'project', id: project.id }, newProject.linkedInfo);
            }
        }), tap(function (event) { return _this.projectCreated.next(event); }), switchMap(function (project) {
            if (newProject.topics.length === 0) {
                return of(project);
            }
            return _this.topicService.createTopics(newProject.topics, { projectsIds: [project.id] })
                .pipe(map(function () { return project; }));
        }));
    };
    ProjectsService.prototype.edit = function (projectInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/projects/' + projectInstance.id, projectInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var project = _a.project;
            return _this.projectUpdated.next(project);
        }), map(function (_a) {
            var project = _a.project;
            return project;
        }));
    };
    ProjectsService.prototype.pin = function (projectIds, status) {
        var _this = this;
        return forkJoin(projectIds.map(function (projectId) { return _this.http.put(environment.baseUrl + '/api/projects/' + projectId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.project; }); }), tap(function (success) {
            if (success) {
                _this.projectUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Project" + (projectIds.length > 1 ? 's' : '') + " " + (projectIds.length > 1 ? 'are' : 'is') + " " + (status ? '' : 'un') + "pinned" });
            }
        }));
    };
    ProjectsService.prototype.archive = function (projectIds, archived) {
        var _this = this;
        return forkJoin(projectIds.map(function (projectId) { return _this.http.put(environment.baseUrl + '/api/projects/' + projectId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.project; }); }), tap(function (success) {
            if (success) {
                _this.projectUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Project(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    ProjectsService.prototype.deletePermanently = function (projectIds) {
        var _this = this;
        return forkJoin(projectIds.map(function (projectId) { return _this.http.delete(environment.baseUrl + '/api/projects/' + projectId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.projectDeleted.next();
                _this.asyncTasksToastsService.show({ text: "Project(s) successfully deleted." });
            }
        }));
    };
    ProjectsService.prototype.delete = function (projectIds, deleted) {
        var _this = this;
        return forkJoin(projectIds.map(function (projectId) { return _this.http.put(environment.baseUrl + '/api/projects/' + projectId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.projectUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Project(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    ProjectsService.prototype.upsert = function (projectForm) {
        var project = Project.fromFormGroup(projectForm);
        return projectForm.get('id').value ?
            this.edit(project) : this.create(project);
    };
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "create", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "edit", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "pin", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "archive", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "deletePermanently", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ProjectsService.prototype, "delete", null);
    return ProjectsService;
}());
export { ProjectsService };
var tasksMock = [
    new Task({
        id: '1',
        sectionId: '1',
        columnId: '1',
        title: 'Identify Pain Point',
        fromTime: new Date(2020, 2, 10, 8, 30),
        toTime: new Date(2020, 2, 10, 16, 45),
        description: 'Upload new design screens in Zeplin today and...',
        subtasks: [
            new Task({
                title: 'Discussing With Team'
            }),
            new Task({
                title: 'Discussing With Team'
            })
        ]
    }),
    new Task({
        id: '2',
        sectionId: '2',
        columnId: '1',
        title: 'Test Current Solution Features',
        circleColor: '#ffc820',
    }),
    new Task({
        id: '3',
        sectionId: '1',
        columnId: '2',
        title: 'Interview Users',
        fromTime: new Date(2020, 2, 10),
        toTime: new Date(2020, 2, 10),
        pinned: true
    }),
    new Task({
        id: '4',
        sectionId: '2',
        columnId: '2',
        title: 'Identify Current Solution',
        fromTime: new Date(2020, 2, 10, 8, 30),
        toTime: new Date(2020, 2, 10, 16, 45),
        circleColor: '#ff4666'
    }),
    new Task({
        id: '5',
        sectionId: '3',
        columnId: '2',
        title: 'Develop Prototype Features',
        fromTIme: new Date(2020, 2, 10, 8, 30),
        toTime: new Date(2020, 2, 10, 16, 45),
    }),
    new Task({
        id: '6',
        sectionId: '2',
        columnId: '3',
        title: 'Identify Problems and Limitations With Current Solutions. Identify Problems and Limitations.',
        checked: true
    }),
    new Task({
        id: '7',
        sectionId: '3',
        columnId: '3',
        title: 'Develop Prototype Core Functions',
        fromTime: new Date(2020, 2, 10, 8, 30),
        toTime: new Date(2020, 2, 10, 16, 45),
    })
];
var sectionsMock = [
    {
        id: '1',
        title: 'User Requirements',
    },
    {
        id: '2',
        title: 'Product Concept',
    },
    {
        id: '3',
        title: 'Prototype Design',
    }
];
