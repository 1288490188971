/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-wrapper.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./popup-wrapper.component";
var styles_PopupWrapperComponent = [i0.styles];
var RenderType_PopupWrapperComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PopupWrapperComponent, data: {} });
export { RenderType_PopupWrapperComponent as RenderType_PopupWrapperComponent };
export function View_PopupWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "popup-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PopupWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-wrapper", [], null, null, null, View_PopupWrapperComponent_0, RenderType_PopupWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i3.PopupWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupWrapperComponentNgFactory = i1.ɵccf("app-popup-wrapper", i3.PopupWrapperComponent, View_PopupWrapperComponent_Host_0, {}, {}, []);
export { PopupWrapperComponentNgFactory as PopupWrapperComponentNgFactory };
