// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { SearchModule } from '../search/search.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

// Components
import { NavBarComponent } from './components/nav-bar/nav-bar.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([]),
    SearchModule,
    UserAvatarModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  declarations: [NavBarComponent],
  exports: [NavBarComponent],
  providers: []
})
export class NavBarModule { }
