// Common
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Types
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { Group } from '@modules/contacts/types/group';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';

// RX
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class GroupComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() group: Group;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() selectedGroups: Group[] = [];

  // Public
  public selected = false;
  public isDragging = false;
  public contextMenuOpened = false;
  public dragData: Group[] = [];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'group' && dragData.data.length === 1 && dragData.data[0]['id'] === this.group.id) &&
    ['message', 'event', 'project', 'task', 'note', 'group', 'contact', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected modalService: ModalService,
    protected contactsStateService: ContactsStateService,
    protected changeDetector: ChangeDetectorRef,
    protected linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService,
    private groupsService: GroupsService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.contactsStateService.getSelectedGroups()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((groups: Group[]) => this.group && groups.some(group => group.id === this.group.id))
      )
      .subscribe((selected: boolean) => {
        this.selected = selected;
        this.changeDetector.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('group' in changes) {
      this.dragData = [this.group];
    }
    if ('selectedGroups' in changes) {
      if (
        this.group &&
        this.selectedGroups &&
        this.selectedGroups.some(selected => selected.id === this.group.id)
      ) {
        this.dragData = this.selectedGroups;
        this.selected = true;
      } else {
        this.selected = false;
        this.dragData = [this.group];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleDoubleClick(group: Group) {

  }

  dndDrop(dragData: DragData) {
    if (!dragData.data) { return; }

    if (['message', 'event', 'project', 'task', 'note', 'contact', 'group'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('group', this.group.id), linkedItems);
    }

    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {groupsIds: [this.group.id]});
    }

    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {groupsIds: [this.group.id]});
    }
  }

  pin() {
    this.groupsService.pin([this.group.id], !this.group.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((groups: Group[]) => {
        if (groups[0]) {
          this.group.pinned = groups[0].pinned;
        }
      });
  }

  archive() {
    this.groupsService.archive([this.group.id], !this.group.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((groups: Group[]) => {
        if (groups[0]) {
          this.group.archived = groups[0].archived;
        }
      });
  }

  delete() {
    this.group.deleted ?
      this.groupsService.deletePermanently([this.group.id]) :
      this.groupsService.delete([this.group.id], true);
  }
}
