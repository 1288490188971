import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
import { CalendarEvent } from '@modules/events/types/calendar-event';
// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
var EventsListContainerComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventsListContainerComponent(splitViewService, eventsStateService, stateService, searchService) {
        var _this = this;
        this.splitViewService = splitViewService;
        this.eventsStateService = eventsStateService;
        this.stateService = stateService;
        this.searchService = searchService;
        this.quickFormExpanded = false;
        this.listFilters = {};
        this.quickFormEvent = new CalendarEvent();
        this.quickFormToggle = new Subject();
        this.componentNotDestroyed = new Subject();
        this.searchTitle = new BehaviorSubject('');
        this.stateService.getSelectedCalendarType()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendarType) { return _this.calendarType = calendarType; });
        combineLatest([
            this.eventsStateService.getSidebarFilters(),
            this.stateService.getSelectedCalendars(),
            this.searchService.getSearchParams(),
            this.searchTitle
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 4), sidebarFilters = _b[0], calendarIds = _b[1], searchParams = _b[2], title = _b[3];
            var keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];
            _this.listFilters = tslib_1.__assign({ calendarIds: calendarIds }, sidebarFilters, searchParams, { keywords: !title || title.trim() === '' ? keywords : tslib_1.__spread(keywords, [title]) });
        });
        this.scrollToDay = this.eventsStateService.getScrollToDay();
    }
    /**
     * Component lifecycle
     */
    EventsListContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splitViewService.getMinimized('eventsList')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (minimized) {
            _this.minimized = minimized;
        });
    };
    EventsListContainerComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    EventsListContainerComponent.prototype.handleScrollOptionChange = function (value) {
        var _this = this;
        this.listScrollPosition = null;
        setTimeout(function () { return _this.listScrollPosition = value; });
    };
    EventsListContainerComponent.prototype.handleNewEvent = function (event) {
        if (event === void 0) { event = new CalendarEvent(); }
        this.eventsStateService.openEventForm(event);
    };
    EventsListContainerComponent.prototype.handleSearch = function (title) {
        this.searchTitle.next(title);
    };
    EventsListContainerComponent.prototype.handleEventClick = function (event) {
        this.stateService.setSelectedCalendarDates(this.calendarType, event.when.start);
        this.eventsStateService.setSelectedCalendarDate(event.when.start);
    };
    EventsListContainerComponent.prototype.handleSelectedEvents = function (events) {
        this.eventsStateService.setSelectedEvents(events);
    };
    EventsListContainerComponent.prototype.setSelectedCalendarDate = function (date) {
        this.eventsStateService.setSelectedCalendarDate(date);
        if (['year', 'month'].includes(this.calendarType)) {
            this.stateService.setSelectedCalendarDates(this.calendarType, date);
        }
    };
    EventsListContainerComponent.prototype.handleQuickFormDrop = function (dragData) {
        var event = CalendarEvent.fromDragData(dragData);
        if (!event) {
            return;
        }
        this.quickFormEvent = new CalendarEvent(event);
        this.quickFormToggle.next();
    };
    return EventsListContainerComponent;
}());
export { EventsListContainerComponent };
