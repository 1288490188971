// Common
import { AfterViewInit, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// Rx
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { File } from '@modules/files/types/file';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

// Services
import { FilesService } from '@modules/files/services/files.service';
import { FilesStateService } from '@modules/files/services/files-state.service';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.less']
})
export class FilesListComponent extends InfinityScrollListComponent implements OnInit, OnChanges, AfterViewInit {
// Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no files';
  @Input() defaultFilters: TasksFilters = {};

  // Public
  public itemHeight = 76;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);

  // Private
  private initialized = false;

  /**
   * Constructor
   */

  constructor(
    protected ngZone: NgZone,
    private filesService: FilesService,
    private filesStateService: FilesStateService
  ) {
    super(ngZone);
    this.filesStateService.getSelectedFiles()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((files: File[]) => {
        this.selectedItems = files;
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.refreshCurrentItems();
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('defaultFilters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.filesService
      .getFiles({
        ...this.defaultFilters,
        limit,
        offset
      })
      .pipe(
        map(({ files, count }) => ({ items: files, count })),
        tap(() => {
          if (!this.initialized) {
            this.initialized = true;
            setTimeout(() => {
              this.scrollToIndex(100);
              super.ngOnInit();
            }, 0);
          }
        })
      );
  }

  selectItem(file: File, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(file, event, selectAll);

    this.filesStateService.setSelectedFiles(this.selectedItems as File[]);

    if (this.selectedItems.length === 1) {
      this.filesStateService.setMainViewFile(this.selectedItems[0] as File);
      this.filesStateService.setMainView('file-form');
    }
  }

  resetItems() {
    super.resetItems();
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
