import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundToK'
})

// converting number from 1000 to 1K
export class RoundToK implements PipeTransform {

  transform(digit: string | number): string | number {
    const numberVal = +digit;
    return numberVal > 999 ? (numberVal / 1000).toFixed(1) + 'k' : digit;
  }

}
