<div class="knowledge-panel" *ngIf="!minimized">
  <div class="navigation-bar">
    <button class="button-icon btn-minimized" [split-view-toggle]="splitViewKey">
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
    <div class="tabs">
      <button 
        *ngFor="let item of tabs; let index = index" 
        class="tab-item" 
        (click)="selectTab(item, index)" 
        [ngClass]="{'selected': selectedTab === item.tab, 'disabled': item.disabled}" 
        [matTooltipDisabled]="item.disabled || (selectedTab === item.tab)" 
        [matTooltip]="item.tooltip" 
        matTooltipPosition="below"
      >
        <mat-icon svgIcon="tab-{{item.icon}}"></mat-icon>
        <div *ngIf="selectedTab === item.tab" class="tab-item__selected-line"></div>
      </button>
    </div>
  </div>
  <div class="knowledge-panel__body" [ngSwitch]="selectedTab" *ngIf="!formOpened">
    <!-- Mail Tab -->
    <app-mail-panel
      *ngSwitchCase="'message'"
      [message]="selectedMessages[0]"
      [contact]="selectedContact"
      (contactChange)="selectContact($event)"
      [topics]="selectedTopics"
      [selectedRelatedContacts]="selectedRelatedContacts"
      (selectedRelatedContactsChange)="selectRelatedContacts($event)"
      [selectedRelatedTopics]="selectedRelatedTopics"
      (selectedRelatedTopicsChange)="selectRelatedTopics($event)"
      [temporalExpression]="selectedTemporalExpressions[0]"
      [baseTopics]="selectedMessagesVisibleTopics"
    >
    </app-mail-panel>
    <!-- Events Tab -->
    <app-events-panel
      *ngSwitchCase="'calendar'"
    ></app-events-panel>
    <!-- Tasks Tab -->
    <app-tasks-panel
      *ngSwitchCase="'tasks'"
    ></app-tasks-panel>
    <!-- Compass Tab -->
    <app-compass-panel 
      *ngSwitchCase="'compass'"
    ></app-compass-panel>
    <!-- Messages Tab -->
    <app-messages-list-panel
      *ngSwitchCase="'mail'"
    ></app-messages-list-panel>
    <!-- Notes Tab -->
    <app-notes-panel
      *ngSwitchCase="'notes'"
    ></app-notes-panel>
  </div>

  <!-- Forms -->
  <app-knowledge-panel-form *ngIf="formOpened"></app-knowledge-panel-form>
</div>

<!-- Minimized -->
<div class="knowledge-panel minimized" *ngIf="minimized">
  <div class="navigation-bar minimized">
    <button class="button-icon btn-minimized" [split-view-toggle]="splitViewKey">
      <mat-icon svgIcon="arrow-left"></mat-icon>
    </button>
  </div>
  <div class="knowledge-panel__body">
    <div class="tabs minimized">
      <button 
        *ngFor="let item of tabs" 
        class="tab-item minimized" 
        (click)="selectTab(item)" 
        [ngClass]="{'selected': selectedTab === item.tab, 'disabled': item.disabled}" 
        [matTooltipDisabled]="item.disabled || (selectedTab === item.tab)" 
        [matTooltip]="item.tooltip" 
        matTooltipPosition="left"
      >
        <mat-icon svgIcon="tab-{{item.icon}}"></mat-icon>
        <div *ngIf="selectedTab === item.tab" class="tab-item__selected-line--vertical"></div>
      </button>
    </div>
  </div>
</div>
