<div class="linked-info-popover">
  <div class="header" [class.shadow]="showHeaderShadow">
    <div class="title">Tasks</div>
    <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  </div>
  <div class="list" #scrollList>
    <app-task
      *ngFor="let task of items"
      [task]="task"
      (click)="select(task)"
    ></app-task>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>
