<div
  *ngIf="note"
  class="note"
  [class.selected]="selected && !isDragging"
  [class.pinned]="note.pinned"
  [class.dragging]="!dragEnabled"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'note'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  [appNoteContextMenu]="note"
  (contextMenuOpened)="contextMenuOpened = $event"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"
>
  <div class="note-info">
    <!-- Selected Line -->
    <div *ngIf="selected" class="selected-line"></div>

    <!-- Icons -->
    <div class="icons">
      <mat-icon class="type" [svgIcon]="note.archived ? 'folder-archive' : 'note-small'"></mat-icon>
      <mat-icon class="pin" svgIcon="pin-direct" *ngIf="note.pinned"></mat-icon>
      <mat-icon class="favorite" svgIcon="saved-small" *ngIf="note.favorite"></mat-icon>
      <mat-icon class="drag" svgIcon="drag-icon"></mat-icon>
      <mat-icon
        class="attachment"
        svgIcon="mail-attachment"
        *ngIf="note.files?.length"
        popover
        [popoverDisabled]="!(note.files.length > 0)"
        [popoverContent]="attachmentPopover"
        [popoverArrow]="true"
        popoverPlacement="right"
        popoverTrigger="hover"
      ></mat-icon>
    </div>

    <!-- Actions -->
    <div class="actions" *ngIf="actionsButtonEnabled">
      <div class="actions-gradient"></div>
      <div class="actions-background">
        <mat-icon class="btn-action" svgIcon="saved" (click)="favoriteNote($event)" [matTooltip]="note?.favorite ? 'Remove from favorite' : 'Favorite'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="pin" (click)="pinNote($event)" [matTooltip]="note?.pinned ? 'Unpin' : 'Pin'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="snooze" (click)="snoozeNote($event)" matTooltip="Snooze (Coming soon)"></mat-icon>
        <mat-icon class="btn-action" svgIcon="mail" (click)="createMail($event)" matTooltip="New mail via note"></mat-icon>
        <mat-icon class="btn-action" svgIcon="followup" (click)="followUpNote($event)" matTooltip="Follow up (Coming soon)"></mat-icon>
        <mat-icon class="btn-action" svgIcon="archive" (click)="archiveNote($event)" [matTooltip]="note?.archived ? 'Restore from archive' : 'Archive'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="trash" (click)="deleteNote($event)" [matTooltip]="note?.deleted ? 'Delete' : 'Move to trash'"></mat-icon>
      </div>
    </div>

    <!-- Title -->
    <div class="title" [class.empty]="!note.title">{{ note.title || 'No Subject'}}</div>

    <!-- Date -->
    <div class="date-time">
      <div class="date">
        {{ note.dueDate ? (note.dueDate | date: 'MMM d,yyyy') : 'No selected date'}}
      </div>
      <div class="time">
        <div class="time-divider"></div>
        <div class="text" *ngIf="!note.createdAt || !note.dueDate">No selected time</div>
        <div class="text" *ngIf="note.createdAt && note.dueDate">
          {{ note.createdAt | date: 'h:mm aaa' }} - {{ note.dueDate | date: 'h:mm aaa' | lowercase }}
        </div>
      </div>
    </div>

    <!-- Snippet -->
    <div class="description">{{ note.snippet || 'This note does not contain a body' }}</div>

    <!-- Linked Info -->
    <app-link-info-bar class="linked-info" *ngIf="note.linkedInfo?.length" [linkedInfo]="note.linkedInfo"></app-link-info-bar>
    <div class="linked-info__placeholder" *ngIf="!note.linkedInfo?.length"></div>

    <!-- Drop zone -->
    <div class="drop-zone"></div>
  </div>
</div>

<!-- Drag placeholder -->
<ng-template #dragPlaceholder>
  <app-note [note]="note" [dragEnabled]="false"></app-note>
</ng-template>

<!-- Attachment popover -->
<ng-template #attachmentPopover>
  <app-related-files class="popover-attachments" [files]="note.files"></app-related-files>
</ng-template>

<!-- Note Placeholder -->
<div class="note-wrapper" *ngIf="!note">
  <div class="note-placeholder">
    <div class="note-info">
      <div class="icons">
        <div class="pin"></div>
        <div class="favorite"></div>
        <div class="attachment"></div>
        <div class="type"></div>
        <div class="user"></div>
      </div>
      <div class="title"></div>
      <div class="date-info">
        <div class="date"></div>
        <div class="time"></div>
      </div>
      <div class="description"></div>
      <div class="linked-info">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
  </div>
</div>
