// Common
import {
  Component, OnDestroy, OnChanges, Input, Output, EventEmitter,
  SimpleChanges, NgZone, ViewChild, ElementRef, AfterViewInit, OnInit
} from '@angular/core';
import { animate, AnimationTriggerMetadata, query, stagger, style, transition, trigger } from '@angular/animations';

// RX
import { Observable, Subject, of, forkJoin, fromEvent } from 'rxjs';
import { catchError, tap, takeUntil, switchMap, startWith } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { FileUpload } from '@modules/form-controls/types/file-upload';
import { Attachment } from '@modules/form-controls/types/attachment';
import { AbstractControl } from '@angular/forms';

// Animations
const expandAnimation: AnimationTriggerMetadata = trigger('expandAnimation', [
  transition('expanded => collapsed', [
    query('.attachment.to-hide', [
      style({ opacity: 1, width: '*', border: '0' }),
      stagger(-50, [
        animate('150ms ease-in-out', style({ opacity: 0, width: 0, margin: 0, padding: 0, border: 'none' }))
      ])
    ])
  ]),
  transition('collapsed => expanded', [
    query('.attachment.to-hide', [
      style({ opacity: 0, width: 0 }),
      stagger(50, [
        animate('150ms ease-in-out', style({ opacity: 1, width: '*' }))
      ])
    ])
  ])
]);

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.less'],
  animations: [expandAnimation]
})
export class FileUploaderComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // Constants
  public readonly amountOfCollapsedAttachments = 5;

  // Inputs
  @Input() attachments: Attachment[];
  @Input() attachmentsFormControl: AbstractControl;
  @Input() upload: Observable<FileList>;

  // Outputs
  @Output() attachmentsChange = new EventEmitter<Attachment[]>();
  @Output() uploading = new EventEmitter<boolean>();

  // Public
  public uploads: FileUpload[] = [];
  public dragOverEventsCounter = 0;
  public expandedAttachments = false;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private attachmentsFormControlChanged = new Subject<void>();

  // View Children
  @ViewChild('droppableArea', { static: false }) droppableArea: ElementRef;

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService,
    private modalService: ModalService,
    private ngZone: NgZone
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit(): void {
    if (!this.attachmentsFormControl) { return; }

    this.attachmentsFormControlChanged
      .pipe(
        switchMap(() =>
          this.attachmentsFormControl.valueChanges
            .pipe(
              startWith(this.attachmentsFormControl.value as Attachment[])
            )
        ),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((values: Attachment[]) => {
        this.uploads = this.uploads.filter(upload =>
          !upload.attachment || values.some(
          attachment => upload.attachment.id === attachment.id
          )
        );
        this.uploads.push(...values
          .filter(attachment => !this.uploads.some(
            upload => upload.attachment && upload.attachment.id === attachment.id)
          )
          .map((attachment: Attachment) => ({
            file: null,
            attachment,
            status: 'uploaded'
          } as FileUpload))
        );
      });
    this.attachmentsFormControlChanged.next();
  }

  ngAfterViewInit() {
    if (!this.attachmentsFormControl) { return; }

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.droppableArea.nativeElement, 'dragover')
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((event: DragEvent) => event.preventDefault());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.attachments = this.attachments || [];
    if ('attachments' in changes) {
      this.uploads = this.uploads.filter(upload =>
        !upload.attachment || this.attachments.some(
          attachment => upload.attachment.id === attachment.id
        )
      );
      this.uploads.push(...this.attachments
        .filter(attachment => !this.uploads.some(
          upload => upload.attachment && upload.attachment.id === attachment.id)
        )
        .map((attachment: Attachment) => ({
          file: null,
          attachment,
          status: 'uploaded'
        } as FileUpload))
      );
    }
    if ('upload' in changes && this.upload) {
      this.upload
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((files: FileList) => this.uploadFiles(files));
    }
    if ('attachmentsFormControl' in changes) { this.attachmentsFormControlChanged.next(); }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  uploadDroppedFiles(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.files) {
      this.uploadFiles(event.dataTransfer.files);
    }
    event.stopPropagation();
    event.preventDefault();
  }

  uploadSelectedFiles(event: Event) {
    const filesInput = (event.target as HTMLInputElement);
    if (filesInput && filesInput.files) {
      this.uploadFiles(filesInput.files);
    }
    filesInput.value = null;
  }

  removeFile(upload: FileUpload) {
    const index = this.uploads.indexOf(upload, 0);
    if (index < 0) { return; }
    const attachment = this.uploads[index].attachment;
    if (!attachment) { return; }

    // This is a temporary solution needed until the mail modules are refactored.
    if (this.attachmentsFormControl) {
      const newAttachments = this.attachmentsFormControl.value.filter(item => item.id !== attachment.id);
      this.attachmentsFormControl.setValue(newAttachments);
    } else if (this.attachments) {
      const newAttachments = this.attachments.filter(item => item.id !== attachment.id);
      this.attachmentsChange.emit(newAttachments);
    } else {
      this.uploads.splice(index, 1);
    }
  }

  cancelRequest(upload: FileUpload) {
    const index = this.uploads.indexOf(upload, 0);
    if (index > -1 && upload.request) {
      upload.cancel.next();
      upload.cancel.complete();
      this.uploads.splice(index, 1);
    }
  }

  showAttachment(file: FileUpload): void {
    if (file && file.status !== 'uploaded' ) {
      return;
    }
    this.modalService.showAttachmentsModal([file.attachment], 0);
  }

  private uploadFiles(files: FileList) {
    if (files && files.length) {
      this.uploading.emit(true);

      const uploadsObservers = [];
      for (let i = 0; i < files.length; i++) {
        uploadsObservers.push(this.uploadFile(files[i]));
      }
      forkJoin(uploadsObservers)
        .pipe(
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(() => this.uploading.emit(false));
    }
  }

  private uploadFile(file: File): Observable<Attachment> {
    const upload = new FileUpload();
    upload.file = file;
    upload.status = 'uploading';
    upload.cancel = new Subject();

    this.uploads.push(upload);

    if (file.size > 26214400) {
      upload.status = 'error';
      upload.error = 'File is too large. Maximum size 25MB.';
      return of(null);
    }

    const request = this.mailService
      .uploadFile(upload.file)
      .pipe(
        takeUntil(this.componentNotDestroyed),
        takeUntil(upload.cancel),
        tap((attachment: Attachment) => {
          upload.attachment = attachment;
          upload.status = 'uploaded';

          // This is a temporary solution needed until the mail modules are refactored.
          if (this.attachmentsFormControl) {
            const newAttachments = this.attachmentsFormControl.value.concat(attachment);
            this.attachmentsFormControl.setValue(newAttachments);
          } else {
            const newAttachments = this.attachments.concat(attachment);
            this.attachmentsChange.emit(newAttachments);
          }
        }),
        catchError(error => {
          upload.status = 'error';
          upload.error = error.message || 'Unexpected error';
          return of(null);
        })
      );
    upload.request = request;
    return request;
  }

  public handleExpand() {
    this.expandedAttachments = !this.expandedAttachments;
  }
}
