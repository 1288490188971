import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
var CalendarCellContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarCellContextMenuComponent, _super);
    /**
     * Constructor
     */
    function CalendarCellContextMenuComponent(contextMenuService, eventsStateService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.eventsStateService = eventsStateService;
        // Public
        _this.clipboard = false;
        // Private
        _this.componentNotDestroyed = new Subject();
        // Outputs
        _this.loadDayEvents = new EventEmitter();
        return _this;
    }
    /**
     * Lifecycle
     */
    CalendarCellContextMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventsStateService.getClipboard()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (clipboard) { return _this.clipboard = !!clipboard; });
    };
    CalendarCellContextMenuComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    CalendarCellContextMenuComponent.prototype.createEvent = function () {
        this.eventsStateService.openEventForm(new CalendarEvent({
            when: {
                durationType: 'day',
                start: this.date,
                end: this.date,
            }
        }));
    };
    CalendarCellContextMenuComponent.prototype.handleLoadDayEvents = function () {
        this.loadDayEvents.emit();
    };
    CalendarCellContextMenuComponent.prototype.pasteEvents = function () {
        this.eventsStateService.performPaste(this.date);
    };
    CalendarCellContextMenuComponent.prototype.openCalendar = function (type) {
        this.eventsStateService.setCalendarView(type, this.date);
    };
    return CalendarCellContextMenuComponent;
}(ContextMenuComponent));
export { CalendarCellContextMenuComponent };
