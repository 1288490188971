// Common
import {
  Component, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, NgZone, Renderer2,
  ContentChildren, QueryList
} from '@angular/core';

// RX
import { Subject, fromEvent, Subscription } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

// Directives
import { ScrollAnchorDirective } from '@modules/scroll/directives/scroll-anchor/scroll-anchor.directive';

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.less']
})
export class ScrollComponent implements AfterViewInit, OnDestroy {

  // Private
  private alive: Subject<void> = new Subject();
  private isScrollTop = true;
  private mouseMoveSubscription: Subscription;
  private relativeMousePosition: number;
  private tolerance = 5;

  // Outputs
  @Output() top: EventEmitter<boolean> = new EventEmitter();

  // View Children
  @ViewChild('scrollBody', { static: true }) scrollBody: ElementRef;
  @ViewChild('scrollBar', { static: true }) scrollBar: ElementRef;
  @ContentChildren(ScrollAnchorDirective) anchors: QueryList<ScrollAnchorDirective>;

  /**
   * Constructor
   */

  constructor (
    private ngZone: NgZone,
    private renderer: Renderer2,
    private element: ElementRef
  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollBody.nativeElement, 'scroll')
        .pipe(
          takeUntil(this.alive),
        )
        .subscribe(this.handleScroll.bind(this));
    });

    this.setupDragEvents();
  }


  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleUp() {
    const currentIndex = this.getCurrentAnchorIndex();
    if (currentIndex === 0) {
      return;
    }

    this.scrollToAnchor(this.anchors.toArray()[currentIndex - 1].element);
  }

  handleDown() {
    const currentIndex = this.getCurrentAnchorIndex();
    if (currentIndex === this.anchors.length - 1) {
      return;
    }

    this.scrollToAnchor(this.anchors.toArray()[currentIndex + 1].element);
  }

  handleScroll(event: Event) {
    const scrollPosition = event.target['scrollTop'];
    const viewportHeight = event.target['offsetHeight'];
    const scrollHeight = event.target['scrollHeight'];

    if (!this.isScrollTop && scrollPosition === 0) {
      this.ngZone.run(() => {
        this.top.emit(true);
        this.isScrollTop = true;
      });
    }

    if (this.isScrollTop && scrollPosition > 0) {
      this.ngZone.run(() => {
        this.top.emit(false);
        this.isScrollTop = false;
      });
    }

    const scrollPositionPercentage = scrollPosition * 100.0 / (scrollHeight - viewportHeight);

    this.renderer.setStyle(
      this.scrollBar.nativeElement,
      'top',
      `${
        (
          (viewportHeight - this.scrollBar.nativeElement.offsetHeight - this.tolerance * 2) /
          100.0 * scrollPositionPercentage
        ) +
        this.tolerance
      }px`
    );
  }

  /**
   * Helpers
   */

  getElementTop(element: ElementRef): number {
    return element.nativeElement.getBoundingClientRect().y;
  }

  getCurrentAnchorIndex(): number {
    const containerTop = this.getElementTop(this.element);
    const anchorsArray = this.anchors.toArray();

    const closestItem = anchorsArray.reduce((closest: ScrollAnchorDirective, item: ScrollAnchorDirective) => {
      if (!closest) {
        return item;
      }

      const itemTop = this.getElementTop(item.element);
      const closestItemTop = this.getElementTop(closest.element);

      return (
        itemTop - containerTop - this.scrollBody.nativeElement.offsetTop >= 0 &&
        (
          closestItemTop - containerTop - this.scrollBody.nativeElement.offsetTop < 0 ||
          itemTop < closestItemTop
        ) ?
        item : closest
      );
    }, null);

    return anchorsArray.indexOf(closestItem);
  }

  scrollToAnchor(element: ElementRef) {
    const anchorTop = this.getElementTop(element);
    const parentTop = this.getElementTop(this.element);

    const anchorRelativeTop = anchorTop - parentTop + this.scrollBody.nativeElement.scrollTop;

    this.scrollBody.nativeElement.scroll({top: anchorRelativeTop, behavior: 'smooth'});
  }

  private setupDragEvents() {
    this.ngZone.runOutsideAngular(() => {
      const mousedown = fromEvent(this.scrollBar.nativeElement, 'mousedown');

      mousedown
        .pipe(takeUntil(this.alive))
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          this.relativeMousePosition = event.pageY - this.getElementTop(this.scrollBar);
          this.subscribeToMouseMove();
        });

    });
  }

  private subscribeToMouseMove() {
    const mousemove = fromEvent(document, 'mousemove');
    const mouseup = fromEvent(document, 'mouseup');

    this.mouseMoveSubscription = mousemove
      .pipe(takeUntil(this.alive))
      .subscribe((event: MouseEvent) => {
        event.preventDefault();

        const viewportTop = this.getElementTop(this.scrollBody);
        const viewportHeight = this.scrollBody.nativeElement.offsetHeight;

        if (
          event.pageY >= viewportTop + this.relativeMousePosition + this.tolerance &&
          event.pageY <= viewportTop + viewportHeight - this.relativeMousePosition - this.tolerance
        ) {
          const scrollBarPosition = event.pageY - viewportTop - this.tolerance - this.relativeMousePosition;

          const scrollHeight = this.scrollBody.nativeElement.scrollHeight;
          const scrollBarHeight = this.scrollBar.nativeElement.offsetHeight;

          const scrollPercentage = scrollBarPosition * 100.0 / (viewportHeight - scrollBarHeight - this.tolerance * 2);

          this.scrollBody.nativeElement.scrollTop = scrollPercentage * (scrollHeight - viewportHeight) / 100.0;
        }
      });

     mouseup
      .pipe(
        first(),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        if (this.mouseMoveSubscription) {
          this.mouseMoveSubscription.unsubscribe();
        }
      });
  }
}
