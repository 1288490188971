// Common
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
// Type
import { CalendarEvent } from '@modules/events/types/calendar-event';
// Service
import { CalendarService } from '@modules/events/services/calendar.service';

@Component({
  selector: 'app-calendar-event-form-popup',
  templateUrl: './calendar-event-form-popup.component.html',
  styleUrls: ['./calendar-event-form-popup.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarEventFormPopupComponent implements OnDestroy {

  // Public
  public event = new CalendarEvent();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private eventsService: CalendarService
  ) {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (params['id']) {
            return this.eventsService.getEvent(params['id']);
          }
          return of(new CalendarEvent());
        }),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((event: CalendarEvent) => {
        this.event = event;
      });
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  close() {
    window.close();
  }
}
