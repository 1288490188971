import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { PopupRoutingModule } from './popup-routing.module';
import { MailModule } from '@modules/mail/mail.module';
import { EventsModule } from '@modules/events/events.module';
import { ModalModule } from '@modules/modal/modal.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { NotesModule } from '@modules/notes/notes.module';

// Services
import { PopupService } from './services/popup.service';
import { PopupGuardService } from './services/popup-guard.service';

// Components
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';
import { MailContentPopupComponent } from './components/mail-content-popup/mail-content-popup.component';
import { MailEditorPopupComponent } from './components/mail-editor-popup/mail-editor-popup.component';
import { AnnotationsPopupComponent } from './components/annotations-popup/annotations-popup.component';
import { CalendarEventFormPopupComponent } from './components/calendar-event-form-popup/calendar-event-form-popup.component';
import { TaskFormPopupComponent } from './components/task-form-popup/task-form-popup.component';
import { NoteFormPopupComponent } from './components/note-form-popup/note-form-popup.component';

@NgModule({
  imports: [
    CommonModule,
    PopupRoutingModule,
    MailModule,
    ModalModule,
    EventsModule,
    TasksModule,
    NotesModule,
  ],
  declarations: [
    PopupWrapperComponent, MailContentPopupComponent, MailEditorPopupComponent, AnnotationsPopupComponent,
    CalendarEventFormPopupComponent,
    TaskFormPopupComponent,
    NoteFormPopupComponent,
  ],
  providers: [
    PopupService,
    PopupGuardService
  ]
})
export class PopupModule { }
