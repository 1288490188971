// Common
import { OnInit, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTooltipDefaultOptions } from '@angular/material';
// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabInfo } from './../../types/tab-info';
/** Custom options the configure the tooltip's default show/hide delays. */
var tooltipDefaults = {
    showDelay: 600,
    hideDelay: 200,
    touchendHideDelay: 1000,
};
var ɵ0 = tooltipDefaults;
var KnowledgePanelComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function KnowledgePanelComponent(stateService, splitViewService, changeDetector, knowledgePanelService) {
        var _this = this;
        this.stateService = stateService;
        this.splitViewService = splitViewService;
        this.changeDetector = changeDetector;
        this.knowledgePanelService = knowledgePanelService;
        // Inputs
        this.selectedMessages = [];
        this.selectedRelatedContacts = [];
        this.selectedRelatedTopics = [];
        this.selectedTemporalExpressions = [];
        this.splitViewKey = 'knowledgePanel';
        // Outputs
        this.selectedContactChange = new EventEmitter();
        this.selectedRelatedContactsChange = new EventEmitter();
        this.selectedRelatedTopicsChange = new EventEmitter();
        // Public
        this.selectedTab = 'message';
        this.tabs = [
            new TabInfo('compass', 'Compass', 'compass'),
            new TabInfo('message', 'KP', 'kp'),
            new TabInfo('mail', 'Mail', 'mail'),
            new TabInfo('calendar', 'Calendar', 'calendar'),
            new TabInfo('tasks', 'Tasks', 'tasks'),
            new TabInfo('notes', 'Notes', 'notes'),
            new TabInfo('contacts', 'Contacts', 'contact', true),
            new TabInfo('explore', 'Explore', 'explore', true),
            new TabInfo('attachments', 'Attachments', 'attachment', true),
        ];
        // Private
        this.componentNotDestroyed = new Subject();
        this.knowledgePanelService.getFormItem()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (item) { return _this.formOpened = !!item; });
    }
    /**
     * Component lifecycle
     */
    KnowledgePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateService.getTabsState('kp')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (index) { return _this.selectedTab = _this.tabs[index].tab; });
        this.splitViewService
            .getMinimized(this.splitViewKey)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (minimized) {
            _this.minimized = minimized;
            _this.changeDetector.detectChanges();
        });
    };
    KnowledgePanelComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    KnowledgePanelComponent.prototype.selectTab = function (tab, index) {
        if (tab.disabled) {
            return;
        }
        this.stateService.setTabsState('kp', index);
    };
    /**
     * Outputs methods
     */
    KnowledgePanelComponent.prototype.selectContact = function (contact) {
        this.selectedContact = contact;
        this.selectedContactChange.emit(this.selectedContact);
    };
    KnowledgePanelComponent.prototype.selectRelatedContacts = function (contacts) {
        this.selectedRelatedContacts = contacts;
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
    };
    KnowledgePanelComponent.prototype.selectRelatedTopics = function (topics) {
        this.selectedRelatedTopics = topics;
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
    };
    return KnowledgePanelComponent;
}());
export { KnowledgePanelComponent };
export { ɵ0 };
