// Common
import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

// Types
import { Topic } from '@modules/topic/types/topic';
import { Tag } from '@modules/tag/types/tag';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Group } from './group';
import { Note } from '@modules/notes/types/note';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MailMessage } from '@modules/mail/types/mail-message';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Contact {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  groupId?: string;
  name?: string;
  role?: string;
  avatar?: string;
  email?: string;
  phoneNumber?: string;
  topics?: Topic[];
  updatedAt?: Date;
  createdAt?: Date;
  tags?: Tag[];
  pinned?: boolean;
  starred?: boolean;
  archived?: boolean;
  deleted?: boolean;
  files?: Attachment[];
  linkedInfo?: LinkedInfo[];

  constructor(contactObject: any = {}) {
    this.id = contactObject.id;
    this.groupId = contactObject.groupId;
    this.name = contactObject.name;
    this.role = contactObject.role;
    this.avatar = contactObject.avatar;
    this.email = contactObject.email;
    this.phoneNumber = contactObject.phoneNumber;
    this.topics = contactObject.topics;
    this.updatedAt = contactObject.updatedAt;
    this.createdAt = contactObject.createdAt;
    this.tags = contactObject.tags;
    this.pinned = contactObject.pinned;
    this.starred = contactObject.starred;
    this.archived = contactObject.archived;
    this.deleted = contactObject.deleted;
    this.linkedInfo = contactObject.linkedInfo;
  }

  static fromFormGroup(form: FormGroup): Contact {
    const formValues = form.value;

    return new Contact({
      id: formValues.id,
      groupId: formValues.groupId,
      name: formValues.name,
      avatar: formValues.avatar,
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
      topics: formValues.topics,
      updatedAt: formValues.updatedAt,
      createdAt: formValues.createdAt,
      tags: formValues.tags,
      pinned: formValues.pinned,
      starred: formValues.starred,
      archived: formValues.archived,
      deleted: formValues.deleted,
      linkedInfo: formValues.linkedInfo
    });
  }

  static fromMailMessage(message?: MailMessage): Contact {
    const contact = new Contact();

    if (message) {
      contact.name = message.subject || '';
      contact.topics = message.topics || [];
      contact.tags = message.tags || [];
      contact.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return contact;
  }

  static fromEvent(event?: CalendarEvent): Contact {
    const contact = new Contact();

    if (event) {
      contact.name = event.title || '';
      contact.topics = event.topics || [];
      contact.tags = event.tags || [];
      contact.linkedInfo = event.id ? [new LinkedInfo('event', event.id)] : [];
    }

    return contact;
  }

  static fromProject(project?: Project): Contact {
    const contact = new Contact();

    if (project) {
      contact.name = project.title || '';
      contact.topics = project.topics || [];
      contact.tags = project.tags || [];
      contact.linkedInfo = project.id ? [new LinkedInfo('project', project.id)] : [];
    }

    return contact;
  }

  static fromTask(task?: Task): Contact {
    const contact = new Contact();

    if (task) {
      contact.name = task.title || '';
      contact.topics = task.topics || [];
      contact.tags = task.tags || [];
      contact.linkedInfo = task.id ? [new LinkedInfo('task', task.id)] : [];
    }

    return contact;
  }

  static fromNote(note?: Note): Contact {
    const contact = new Contact();

    if (note) {
      contact.name = note.title || '';
      contact.topics = note.topics || [];
      contact.tags = note.tags || [];
      contact.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
    }

    return contact;
  }

  static fromGroup(group?: Group): Contact {
    const contact = new Contact();

    if (group) {
      contact.name = group.name || '';
      contact.topics = group.topics || [];
      contact.tags = group.tags || [];
      contact.linkedInfo = group.id ? [new LinkedInfo('group', group.id)] : [];
    }

    return contact;
  }

  static fromContact(originalContact?: Contact): Contact {
    if (originalContact) {
      const contact = new Contact({...originalContact});
      contact.id = null;
      contact.linkedInfo = originalContact.id ? [new LinkedInfo('contact', originalContact.id)] : [];
      return contact;
    } else {
      return new Contact();
    }
  }

  static fromDragData(dragData: DragData): Contact {
    let contact: Contact = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        contact = Contact.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        contact = Contact.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        contact = Contact.fromProject(<Project>dragItem);
        break;
      case 'task':
        contact = Contact.fromTask(<Task>dragItem);
        break;
      case 'note':
        contact = Contact.fromNote(<Note>dragItem);
        break;
      case 'group':
        contact = Contact.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        contact = Contact.fromContact(<Contact>dragItem);
        break;
    }

    return contact;
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      groupId: [this.groupId],
      name: [this.name],
      avatar: [this.avatar],
      email: [this.email],
      phoneNumber: [this.phoneNumber],
      topics: [this.topics],
      updatedAt: [this.updatedAt],
      createdAt: [this.createdAt],
      tags: [this.tags],
      pinned: [this.pinned],
      starred: [this.starred],
      archived: [this.archived],
      deleted: [this.deleted],
      linkedInfo: [this.linkedInfo],
      social: ['']
    });
  }

  asPayloadJSON() {
    return {
      groupId: this.groupId,
      name: this.name,
      avatar: this.avatar,
      email: this.email,
      phoneNumber: this.phoneNumber,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      pinned: this.pinned,
      starred: this.starred,
      archived: this.archived,
      deleted: this.deleted,
    };
  }
}
