// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-task-form-popup',
  templateUrl: './task-form-popup.component.html',
  styleUrls: ['./task-form-popup.component.less'],
})
export class TaskFormPopupComponent {

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Actions
   */

  close() {
    window.close();
  }
}
