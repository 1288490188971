import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Directives
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { SidebarTaskContextMenuComponent } from '@modules/tasks/components/sidebar-task-context-menu/sidebar-task-context-menu.component';
var SidebarTaskContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(SidebarTaskContextMenuDirective, _super);
    function SidebarTaskContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = SidebarTaskContextMenuComponent;
        return _this;
    }
    SidebarTaskContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.startDate = this.appTaskContextMenuStartDate;
        componentRef.instance.priority = this.appTaskContextMenuPriority;
    };
    return SidebarTaskContextMenuDirective;
}(ContextMenuDirective));
export { SidebarTaskContextMenuDirective };
