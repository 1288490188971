// Common
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.less']
})
export class NotificationDropdownComponent {

  // Inputs
  @Input() inputFormArray: FormArray;

  // Public
  public notificationByTimeOptions = [
    {title: 'Minutes', value: 'minutes'},
    {title: 'Hours', value: 'hours'},
    {title: 'Days', value: 'days'},
    {title: 'Weeks', value: 'weeks'}
  ];
  public notificationByOptions = [{title: 'Email', value: 'email'}, {title: 'Notification', value: 'notification'}];

  /**
   * Constructor
   */

  constructor(private formBuilder: FormBuilder) { }

  addNotification() {
    this.inputFormArray.push(this.formBuilder.group({ type: [], duration: [], units: [] }));
  }

  removeNotification(index: number) {
    this.inputFormArray.removeAt(index);
  }
}
