import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, of as createObservableOf } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
// Services
import { SearchService } from '../../services/search.service';
import { SearchSuggestions } from './../../types/search-suggestions';
import { ResponseMessages } from '../../../mail/types/mail-response.model';
var SearchInputComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function SearchInputComponent(router, route, searchService, mailService, modalService, ga) {
        this.router = router;
        this.route = route;
        this.searchService = searchService;
        this.mailService = mailService;
        this.modalService = modalService;
        this.ga = ga;
        // Publics
        this.searchCtrl = new FormControl();
        this.searchParams = [];
        this.searchEmails = [];
    }
    /**
     * Component lifecycle
     */
    SearchInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get search params from Route
        combineLatest([this.route.params, this.route.queryParams])
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), params = _b[0], queryParams = _b[1];
            var folder = params.folder;
            var page = _this.getMainRouteSegment();
            if (((page === 'events' || page === 'tasks' || page === 'notes') ||
                (folder && folder === 'search')) &&
                queryParams.query) {
                _this.searchParams = JSON.parse(queryParams.query);
                _this.searchService.setSearchParams(SearchService.parseSearchParams(queryParams.query));
            }
            else {
                _this.searchParams = [];
                _this.searchService.setSearchParams(null);
            }
        });
        // Get search suggestions from input
        this.searchCtrl.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), switchMap(function (value) {
            if (value && value.length) {
                if (value.charAt(0) === '#' && value.length > 1) {
                    value = value.substr(1);
                }
                return _this.searchService.searchSuggestions(value, undefined, 5);
            }
            return createObservableOf(new SearchSuggestions());
        })).subscribe(function (result) {
            _this.searchSuggestions = result;
        });
        // Get search suggestions emails from input
        this.searchCtrl.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), switchMap(function (value) {
            if (value && value.length) {
                var topics = _this.searchParams.filter(function (param) { return param.type === 'topic'; }).map(function (param) { return param.value; });
                var contacts = _this.searchParams.filter(function (param) { return param.type === 'contact'; }).map(function (param) { return param.value; });
                var keywords = _this.searchParams.filter(function (param) { return param.type === 'keyword'; }).map(function (param) { return param.value; });
                var tags = _this.searchParams.filter(function (param) { return param.type === 'tag'; }).map(function (param) { return param.value; });
                if (value.charAt(0) === '#' && value.length > 1) {
                    tags.push(value.substr(1));
                }
                else {
                    keywords.push(value);
                }
                var filters = {
                    relevance: true, topics: topics, contacts: contacts, keywords: keywords, tags: tags
                };
                return _this.mailService.getMessages(filters, 'date', 3, 0);
            }
            return createObservableOf(new ResponseMessages());
        })).subscribe(function (response) {
            _this.searchEmails = response.messages || [];
        });
        // Get Input history
        this.searchService.searchHistory
            .pipe(map(function (history) { return history.slice(0, 5); }))
            .subscribe(function (history) {
            _this.searchHistory = history;
        });
    };
    /**
     * Actions
     */
    SearchInputComponent.prototype.onAutocompleteSearchParamSelected = function (event) {
        var _a;
        var value = event.option.value;
        if (value.message) {
            this.modalService.mailContent(value.message);
            this.searchCtrl.setValue(value.input);
            return;
        }
        if (value.length === 1 && value[0].type === 'keyword' && (value[0].value.charAt(0) === '#' && value[0].value.length > 1)) {
            value = [{ value: value[0].value.substr(1), type: 'tag' }];
        }
        this.ga.trackEvent('search-email', 'search');
        var params = value;
        (_a = this.searchParams).push.apply(_a, tslib_1.__spread(params));
        this.searchCtrl.setValue(null);
        this.searchService.updateSearchHistory(this.searchParams);
        this.performSearch();
    };
    SearchInputComponent.prototype.onRemoveSearchParam = function (param) {
        var index = this.searchParams.indexOf(param);
        if (index !== -1) {
            this.searchParams.splice(index, 1);
        }
        this.searchInput.nativeElement.focus();
        this.performSearch();
    };
    SearchInputComponent.prototype.onRemoveSearchHistory = function (params, event) {
        event.preventDefault();
        event.stopPropagation();
        this.searchService.removeSearchHistory(params);
    };
    SearchInputComponent.prototype.collapseSearch = function () {
        this.searchCtrl.setValue(null);
        this.searchInput.nativeElement.blur();
        this.searchParams = [];
        this.performSearch();
    };
    /**
     * Methods
     */
    SearchInputComponent.prototype.performSearch = function () {
        var page = this.getMainRouteSegment();
        if (this.searchParams && this.searchParams.length > 0) {
            var params = JSON.stringify(this.searchParams);
            this.router.navigate([page === 'mail' || page === 'insights' ? '../search' : "/" + page], { relativeTo: this.route, queryParams: { query: params } });
        }
        else {
            this.router.navigate([page === 'mail' || page === 'insights' ? '../' : "/" + page], { relativeTo: this.route });
        }
    };
    /**
     * Helpers
     */
    SearchInputComponent.prototype.getMainRouteSegment = function () {
        return this.router.url.split(/\/|\?/)[1];
    };
    return SearchInputComponent;
}());
export { SearchInputComponent };
