// Common
import { Component, Input, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ContextMenuComponent as NgxContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

// RX
import { takeUntil, tap } from 'rxjs/operators';
import { merge } from 'rxjs';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

// Other
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

@Component({
  selector: 'app-event-context-menu',
  templateUrl: './event-context-menu.component.html',
  styleUrls: ['./event-context-menu.component.less']
})
export class EventContextMenuComponent extends ContextMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  // Inputs
  @Input() event: CalendarEvent;

  @ViewChild('singleItemMenu', { static: false }) singleItemMenu: NgxContextMenuComponent;
  @ViewChild('multipleItemsMenu', { static: false }) multipleItemsMenu: NgxContextMenuComponent;

  // Public
  public selectedEvents: CalendarEvent[] = [];
  public linkedInfo: LinkedInfo[] = [];
  public allEventsDeleted = false;
  public allEventsArchived = false;
  public allEventsPinned = false;

  // Private
  private includesInSelected = false;

  /**
   * Constructor
   */

  constructor(
    private ga: GoogleAnalyticsService,
    protected contextMenuService: ContextMenuService,
    private calendarService: CalendarService,
    private eventsStateService: EventsStateService,
    private linkedInfoService: LinkedInfoService
  ) {
    super(contextMenuService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.eventsStateService.getSelectedEvents()
      .pipe(
        tap((events: CalendarEvent[]) => {
          // To be moved in ngOnChanges after refactoring
          if (!events || events.length === 0) {
            return;
          }
          this.allEventsDeleted = events.filter(event => event.deleted).length === events.length;
          this.allEventsArchived = events.filter(event => event.archived).length === events.length;
          this.allEventsPinned = events.filter(event => event.pinned).length === events.length;
        }),
        tap((events: CalendarEvent[]) => {
          this.includesInSelected = events.length > 1 && events.map(event => event.id).includes(this.event.id);
        }),
        takeUntil(this.alive)
      )
      .subscribe((events: CalendarEvent[]) => {
        this.selectedEvents = events;
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    merge(
      this.singleItemMenu.close,
      this.multipleItemsMenu.close
    )
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.close.emit();
      });
  }

  /**
   * Actions
   */

  getContextMenu(): NgxContextMenuComponent {
    if (this.includesInSelected) {
      this.linkedInfo = this.selectedEvents.map(selectedEvent => ({type: 'event', id: selectedEvent.id}));
      return this.multipleItemsMenu;
    }
    return this.singleItemMenu;
  }

  editEvent(event: CalendarEvent) {
    this.ga.trackEvent('event-context-menu', 'edit');
    this.eventsStateService.setMainViewEvent(CalendarEvent.fromFormGroup(event.asFormGroup()));
    this.eventsStateService.setMainView('event-form');
  }

  cutEvents() {
    this.ga.trackEvent('event-context-menu', 'cut');
    this.eventsStateService.addToClipboard(this.includesInSelected ? this.selectedEvents : [this.event], 'cut');
  }

  copyEvents() {
    this.ga.trackEvent('event-context-menu', 'copy');
    this.eventsStateService.addToClipboard(this.includesInSelected ? this.selectedEvents : [this.event], 'copy');
  }

  pasteEvents() {
    this.ga.trackEvent('event-context-menu', 'paste');
  }

  pinEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'pin');
    this.calendarService.pinEvent(events.filter(event => event.pinned !== true).map(event => event.id), true);
  }

  unpinEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'unpin');
    this.calendarService.pinEvent(events.filter(event => event.pinned === true).map(event => event.id), false);
  }

  printEvents(events: CalendarEvent[]) {
    events.forEach(event => {
      this.ga.trackEvent('event-context-menu', 'print');
    });
  }

  deleteEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'delete');
    this.calendarService.deleteEvent(events.filter(event => event.deleted !== true && !event.readOnly).map(event => event.id), true);
  }

  deletePermanentlyEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'delete-permanently');
    this.calendarService.deletePermanentlyEvent(
      events.filter(event => event.deleted === true && !event.readOnly).map(event => event.id));
  }

  restoreFromTrashEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'restore-from-trash');
    this.calendarService.deleteEvent(events.filter(event => event.deleted === true).map(event => event.id), false);
  }

  moveToArchiveEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'move-to-archive');
    this.calendarService.archiveEvents(events
        .filter(event => event.archived === false)
        .map(event => event.id),
      true
    );
  }

  restoreFromArchiveEvents(events: CalendarEvent[]) {
    this.ga.trackEvent('event-context-menu', 'restore-from-archive');
    this.calendarService.archiveEvents(events
      .filter(event => event.archived === true)
      .map(event => event.id),
      false
    );
  }

  linkEvents() {
    this.linkedInfoService.linkItems([...this.linkedInfo]);
  }

  /**
   * Methods
   */

  closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }
}
