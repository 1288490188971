<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="notes-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
>
  <!-- Notes -->
  <ng-container *cdkVirtualFor="let note of itemsStreamObservable; let i = index">

    <!-- Note -->
    <app-note
      [note]="note"
      (appClick)="selectItem(note, $event)"
      (appDblClick)="openNoteInModal(note)"
      [selectedNotes]="selectedItems"
    ></app-note>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="notes-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true || (selectedItems && selectedItems?.length > 1))">
  <div class="total">Items: {{items.length}}</div>
  <div *ngIf="selectedItems?.length > 1" class="selected">Selected: {{selectedItems?.length}}</div>
</div>

<div class="notes-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="notes-stub-image" src="assets/placeholders/placeholder-notes.svg"/>
  <p class="notes-stub-text">{{ placeholderText }}</p>
</div>
