// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// Rx
import { Observable, Subject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { TopicService } from '@modules/topic/services/topic.service';

// Types
import { Contact } from '../types/contact';
import { ContactsFilters } from '../types/contacts-filters';
import { ContactsListResponse } from '../types/contacts-list-response';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class ContactsService {

  // Public
  public createNewContact = new Subject<Contact>();
  public updatedContact = new Subject<Contact>();
  public deletedContact = new Subject<string>();

  /**
   * Settings
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private linkedInfoService: LinkedInfoService,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private topicService: TopicService
  ) {

  }

  /**
   * Helpers
   */

  private formatFilters(filters: ContactsFilters): { [param: string]: string | string[]; } {
    const formatedFilters = {};
    if (filters.contactsIds) { formatedFilters['contacts_ids[]'] = filters.contactsIds; }
    if (filters.groupsIds) { formatedFilters['groups_ids[]'] = filters.groupsIds; }

    if (filters.archived) { formatedFilters['archived'] = filters.archived + ''; }
    if (filters.deleted) { formatedFilters['deleted'] = filters.deleted + ''; }
    if (filters.pinned) { formatedFilters['pinned'] = filters.pinned + ''; }

    if (filters.keywords) { formatedFilters['keywords[]'] = filters.keywords; }
    if (filters.topics) { formatedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formatedFilters['tags[]'] = filters.tags; }

    if (filters.order) { formatedFilters['order'] = filters.order; }
    if (filters.orderWithPinned) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }

    if (filters.limit) { formatedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formatedFilters['offset'] = filters.offset + ''; }

    return formatedFilters;
  }

  /**
   * Methods
   */

  getContacts(filters: ContactsFilters = {}): Observable<ContactsListResponse> {
    const requestParams = {params: this.formatFilters(filters)};

    // return this.http.get<ContactsListResponse>(environment.baseUrl + '/api/contacts', requestParams)
    return of({ contacts: mockContacts, count: 4 })
      .pipe(
        map(({ count, contacts }) => ({count, contacts: contacts.map(contact => new Contact(contact))})),
        catchError(ContactsService.handleObserverError)
      );
  }

  getContact(id: string): Observable<Contact> {
    return this.http.get<{ contact: Contact }>(environment.baseUrl + '/api/contacts/' + id)
      .pipe(
        map(({ contact }) => new Contact(contact)),
      );
  }

  create(contactInstance: Contact): Observable<Contact> {
    return this.http.post<{ contact: Contact, success: boolean }>(`${environment.baseUrl}/api/contacts`, contactInstance.asPayloadJSON())
      .pipe(
        map(({ contact, success }) => ({ contact: new Contact(contact), success })),
        tap(({ contact, success }) => {
          if (success) {
            this.createNewContact.next(contact);
            this.asyncTasksToastsService.show({ text: `Contact(s) created.` });
          }
        }),
        map(({ contact }) => contact),
        tap(contact => {
          if (contactInstance.linkedInfo) {
            this.linkedInfoService.linkToItem({ type: 'contact', id: contact.id }, contactInstance.linkedInfo);
          }
        }),
        switchMap(contact => {
          if (contactInstance.topics.length === 0) { return of(contact); }

          return this.topicService.createTopics(
            contactInstance.topics, {contactsIds: [contact.id]}
          )
            .pipe(
              map(() => contact)
            );
        }),
        catchError((error: HttpErrorResponse) => {
          this.asyncTasksToastsService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(contactInstance: Contact): Observable<Contact> {
    return this.http.put<{ contact: Contact, success: boolean }>(
      environment.baseUrl + '/api/contacts/' + contactInstance.id, contactInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ contact, success }) => {
          if (success) {
            this.updatedContact.next();
            this.asyncTasksToastsService.show({ text: 'Contact updated' });
          }
        }),
        map(({ contact }) => contact)
      );
  }

  @warmUpObservable
  pin(contactsIds: string[], status: boolean): Observable<Contact[]> {
    return forkJoin(
      contactsIds.map(contactId => this.http.put<{ contact: Contact, success: boolean }>
        (environment.baseUrl + '/api/contacts/' + contactId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { contact: Contact, success: boolean }[]) => results.map(result => result.contact)),
        tap(success => {
          if (success) {
            this.updatedContact.next();
            this.asyncTasksToastsService.show(
              {text: `Contact${contactsIds.length > 1 ? 's' : ''} ${contactsIds.length > 1 ? 'are' : 'is'} ${ status ? '' : 'un'}pinned`}
            );
          }
        })
      );
  }

  @warmUpObservable
  archive(contactsIds: string[], archived: boolean): Observable<Contact[]> {
    return forkJoin(
      contactsIds.map(contactId => this.http.put<{ contact: Contact, success: boolean }>
        (environment.baseUrl + '/api/contacts/' + contactId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { contact: Contact, success: boolean }[]) => results.map(result => result.contact)),
        tap(success => {
          if (success) {
            this.updatedContact.next();
            this.asyncTasksToastsService.show({ text: `Contact(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  deletePermanently(contactsIds: string[]): Observable<boolean> {
    return forkJoin(
      contactsIds.map(contactId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/contacts/' + contactId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.deletedContact.next();
            this.asyncTasksToastsService.show({ text: `Contact(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  delete(contactsIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      contactsIds.map(contactId => this.http.put<{ contact: Contact, success: boolean }>
        (environment.baseUrl + '/api/contacts/' + contactId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { contact: Contact, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.updatedContact.next();
            this.asyncTasksToastsService.show({ text: `Contact(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }

  upsert(contactForm: FormGroup): Observable<Contact> {
    const contact = Contact.fromFormGroup(contactForm);

    return contactForm.get('id').value ?
      this.update(contact) : this.create(contact);
  }
}


// tslint:disable: max-line-length
const mockContacts = [
  {
    id: '1',
    name: 'Angelina Jolie',
    role: 'UI/UX Designer',
    email: 'jolie1@hotmail.com',
    phoneNumber: '+380503424253',
    createdAt: new Date(),
    pinned: true,
    starred: true,
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
  {
    id: '2',
    name: 'Benedict Cumberbatch',
    role: 'Actor',
    email: 'benedict@gmail.com',
    phoneNumber: '+440503424253',
    createdAt: new Date(),
    pinned: false,
    starred: false,
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
  {
    id: '3',
    name: 'Frank Sinatra',
    role: 'Singer',
    email: 'fsinatra@gmail.com',
    phoneNumber: '+440503424253',
    createdAt: new Date(),
    pinned: false,
    starred: true,
  },
  {
    id: '4',
    name: 'Harry Potter',
    role: 'Wizard',
    email: 'potter@gmail.com',
    phoneNumber: '+440503424253',
    createdAt: new Date(),
    pinned: false,
    starred: false,
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'task',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'message',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'group',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
  {
    id: '5',
    name: 'Joanne Rowling',
    role: '',
    email: 'joanne@gmail.com',
    phoneNumber: '+440503424253',
    createdAt: new Date(),
    pinned: false,
    starred: false,
  }
];
