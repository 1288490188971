import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { KnowledgeGraphService } from '../../../mail/services/knowledge-graph.service';
import { AccountService } from '../../../account/services/account.service';
import { StateService } from './../../../settings/services/state.service';

// Types
import { Account } from '../../../account/types/account';
import { Recipient } from '../../../mail/types/recipient';
import { DropdownOption } from '../../../dropdown/types/dropdown-option';

@Component({
  selector: 'app-insights-related-connections',
  templateUrl: './insights-related-connections.component.html',
  styleUrls: ['./insights-related-connections.component.less']
})
export class InsightsRelatedConnectionsComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() folder: string;
  @Input() fromTime: Date;
  @Input() toTime: Date;
  @Input() topics: string[];
  @Input() contacts: string[];
  @Input() keywords: string[];
  @Input() relatedTopics: string[];

  // Outputs
  @Output() connectionSelected: EventEmitter<Recipient> = new EventEmitter();

  // Public
  public relatedConnections: Recipient[] = [];
  public loading = false;
  public count = 0;
  public selectedConnection: Recipient = null;
  public orderOptions: { name: string, key: string }[] = [
    {name: 'Relevance', key: 'relevance'},
    {name: 'A-Z', key: 'name-asc'},
    {name: 'Z-A', key: 'name-desc'},
  ];
  public selectedOrder: DropdownOption;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private connectionsLimit = 10;
  private account: Account;
  private initialized = false;

  /**
   * Constructor
   */

  constructor(
    private knowledgeGraphService: KnowledgeGraphService,
    private accountService: AccountService,
    private stateService: StateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.selectedOrder = this.stateService.sortInsightsRelatedConnections;
    this.accountService.getAccount()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((account: Account) => {
        this.account = account;
        this.loadConnections(true);
      });
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      this.selectConnection(null);
      this.loadConnections(true);
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  loadConnections(forceUpdate: boolean = false) {
    // for the case when are we invoking order
    if (forceUpdate) {
      this.relatedConnections = [];
      this.count = 0;
    } else if (this.loading || this.relatedConnections.length >= this.count) {
      return;
    }

    this.loading = true;

    this.knowledgeGraphService
      .getRelatedConnections(
        {
          fromTime: this.fromTime,
          toTime: this.toTime,
          folder: this.folder,
          topics: this.topics,
          contacts: this.contacts,
          keywords: this.keywords,
          relatedTopics: this.relatedTopics,
          ignoreContact: this.account ? {email: this.account.email, name: null, id: null} : null
        },
        this.connectionsLimit,
        this.relatedConnections.length,
        this.selectedOrder.key,
      )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((resp: { data: Recipient[], count: number }) => {
        this.relatedConnections.push(...resp.data);
        this.count = resp.count;
        this.loading = false;
      });
  }

  /**
   * Actions
   */

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.stateService.sortInsightsRelatedConnections = order;
    this.loadConnections(true);
  }

  selectConnection(connection: Recipient) {
    this.selectedConnection = (this.selectedConnection === connection) ? null : connection;
    this.connectionSelected.emit(this.selectedConnection);
  }
}
