// Common
import { Injectable } from '@angular/core';

// Rx
import { BehaviorSubject, Observable } from 'rxjs';

// Types
import { File } from '@modules/files/types/file';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class FilesStateService {

  // Private
  private file = new BehaviorSubject<File>(null);
  private selectedFiles = new BehaviorSubject<File[]>([]);
  private mainView = new BehaviorSubject<'empty' | 'file-form'>('empty');
  private mainViewFile = new BehaviorSubject<File>(null);

  // Constructor
  constructor(
    private stateService: StateService
  ) { }

  setFile(currentFile: File) {
    this.file.next(currentFile);
  }

  getFile(): Observable<File> {
    return this.file.asObservable();
  }

  setSelectedFiles(files: File[]) {
    this.selectedFiles.next(files);
  }

  getSelectedFiles(): Observable<File[]> {
    return this.selectedFiles.asObservable();
  }

  getMainView(): Observable<'empty' | 'file-form'> {
    return this.mainView.asObservable();
  }

  getMainViewFile(): Observable<File> {
    return this.mainViewFile.asObservable();
  }

  setMainView(view: 'empty' | 'file-form') {
    this.mainView.next(view);
    this.stateService.filesMainView = view;
  }

  setMainViewFile(file: File) {
    this.mainViewFile.next(
      new File(file)
    );
  }

  openFileForm(file: File) {
    this.setMainViewFile(file);
    this.setMainView('file-form');
  }
}
