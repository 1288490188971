<app-date-picker
  [inputFormControl]="inputFormControl"
  [placeholder]="placeholder"
  [width]="width"
  [disabled]="disabled"
  [appearance]="appearance"
  [inline]="inline"
  [bundledInputConsumerKeys]="bundledInputConsumerKeys"
  [bundledInputAppearance]="bundledInputAppearance"
  [bundledInputConsumerGroup]="bundledInputConsumerGroup"
  [collapsedStateKey]="collapsedStateKey"
  [maxDate]="maxDate"
  [collapseable]="collapseable"
  [dayTemplate]="dayTemplate"
  [events]="events"
  (activeDateChange)="handleActiveDateChange($event)"
></app-date-picker>

<ng-template
  #dayTemplate
  let-day="day"
  let-locale="locale"
  let-trackByEventId="trackByEventId"
>
  <div
    #cell
    class="day-cell"
    appDroppable
    appDroppableHoverClass="drag-over"
    (appDroppableDrop)="handleDrop($event, day.date, cell)"
  >
    <div
      *ngIf="day.inMonth"
      class="day-cell-circle"
      [class.selected]="inputFormControl.value && !(day.date | differentDay : inputFormControl.value)"
      [class.disabled]="maxDate < day.date"
    >
      {{ day.date | date : 'd' }}
    </div>
    <div class="day-cell-dots">
      <div
        class="day-cell-dots-item"
        *ngFor="let event of day.events.slice(0,3)"
        [style.background-color]="event.meta.color || (event.meta.calendarId | calendarColor | async)"
      ></div>
    </div>
  </div>

</ng-template>
