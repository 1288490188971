<app-link-info-suggestions
  [inputFormControl]="inputFormControl"
  [appearance]="appearance"
  title="Link To Event"
  [createTitle]="'Create New Event'"
  [items]="suggestions"
  [itemTemplateRef]="suggestionsItem"
  (close)="closed()"
  (create)="creating()"
>
</app-link-info-suggestions>

<ng-template #suggestionsItem let-event="item">
  <app-event
    [event]="event"
    [actionsButtonEnabled]="false"
    (click)="selectedLinkedInfo({type: 'event', id: event.id})"
  ></app-event>
</ng-template>
