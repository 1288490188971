// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { Topic } from '../types/topic';
import { AsyncTask } from '@modules/async-tasks/types/async-task';
import { TopicsFilters } from '../types/topics-filters';
import { ResponseTopics } from '../types/response-topics';
import { UpdateType } from '@modules/common/types/update-type';

// Env
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  // Private
  private topicsUpdated = new Subject<UpdateType>();

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) { }

  /**
   * Static methods
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Methods
   */

  private formatFilters(filters: TopicsFilters): { [param: string]: string | string[]; } {
    const formatedFilters = {};

    if ('autodiscovered' in filters) { formatedFilters['autodiscovered'] = filters.autodiscovered + ''; }
    if ('pinned' in filters) { formatedFilters['pinned'] = filters.pinned + ''; }
    if ('orderWithPinned' in filters) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }
    if (filters.order) { formatedFilters['order'] = filters.order; }
    if (filters.limit) { formatedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formatedFilters['offset'] = filters.offset + ''; }

    return formatedFilters;
  }

  getTopicsUpdate(): Observable<UpdateType> {
    return this.topicsUpdated.asObservable();
  }

  getTopics(filters: TopicsFilters): Observable<ResponseTopics> {
    return this.http.get<ResponseTopics>(`${environment.baseUrl}/api/topics`, { params: this.formatFilters(filters) })
      .pipe(
        catchError(TopicService.handleObserverError)
      );
  }

  @warmUpObservable
  createTopics(
    topics: Topic[],
    items: {
      messagesIds?: string[],
      eventsIds?: string[],
      projectsIds?: string[],
      tasksIds?: string[],
      notesIds?: string[],
      groupsIds?: string[],
      contactsIds?: string[],
      foldersIds?: string[],
      filesIds?: string[],
    } = {}
  ): Observable<boolean> {
    return this.http.post<{ asyncTask: AsyncTask }>(
      `${environment.baseUrl}/api/topics`,
      {
        topics,
        messagesIds: items.messagesIds,
        eventsIds: items.eventsIds,
        projectsIds: items.projectsIds,
        tasksIds: items.tasksIds,
        notesIds: items.notesIds,
        groupsIds: items.groupsIds,
        contactsIds: items.contactsIds,
        foldersIds: items.foldersIds,
        filesIds: items.filesIds
      }
    )
      .pipe(
        switchMap(({ asyncTask }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: 'Creating new Pellet(s).'
              },
              completed: {
                text: 'New Pellet(s) added.'
              },
              error: {
                text: `Error while creating new Pellet(s). Please try again.`
              }
            }
          })
        ),
        tap(() => this.topicsUpdated.next(UpdateType.Create)),
        catchError(TopicService.handleObserverError)
      );
  }

  @warmUpObservable
  updateTopics(oldTopic: string, newTopic: string): Observable<boolean> {
    return this.http.put<{ asyncTask: AsyncTask }>(
      `${environment.baseUrl}/api/topics`,
      { oldTopic, newTopic }
    )
      .pipe(
        switchMap(({ asyncTask }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `Updating Pellet '${oldTopic}' to '${newTopic}'.`
              },
              completed: {
                text: 'Pellet updated.'
              },
              error: {
                text: `Error while updating Pellet. Please try again.`
              }
            }
          })
        ),
        tap(() => this.topicsUpdated.next(UpdateType.Update)),
        catchError(TopicService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteTopics(
    topics: Topic[],
    items: {
      messagesIds?: string[],
      eventsIds?: string[],
      projectsIds?: string[],
      tasksIds?: string[],
      notesIds?: string[],
      groupsIds?: string[],
      contactsIds?: string[],
      foldersIds?: string[],
      filesIds?: string[]
    } = {}
  ): Observable<boolean> {
    return this.http.request<{ asyncTask: AsyncTask }>(
      'DELETE',
      `${environment.baseUrl}/api/topics`,
      {
        body: {
          topics,
          messagesIds: items.messagesIds,
          eventsIds: items.eventsIds,
          projectsIds: items.projectsIds,
          tasksIds: items.tasksIds,
          notesIds: items.notesIds,
          groupsIds: items.groupsIds,
          contactsIds: items.contactsIds,
          foldersIds: items.foldersIds,
          filesIds: items.filesIds
        }
      }
    )
      .pipe(
        switchMap(({ asyncTask }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `Deleting Pellet(s).`
              },
              completed: {
                text: 'Pellet(s) deleted.'
              },
              error: {
                text: `Error while deleting Pellet(s). Please try again.`
              }
            }
          })
        ),
        tap(() => this.topicsUpdated.next(UpdateType.Delete)),
        catchError(TopicService.handleObserverError)
      );
  }

  @warmUpObservable
  pinTopics(topics: Topic[], pinned: boolean): Observable<boolean> {
    return this.http.put<{success: boolean}>(
      `${environment.baseUrl}/api/topics/pinned`,
      { topics, pinned }
    )
      .pipe(
        tap(({ success }) => {
          this.asyncTasksToastsService.show({
            text: success
              ? `Pellet(s) ${pinned ? 'pinned' : 'unpinned'}.`
              : `Error while pinning Pellet(s). Please try again.`
          });
          this.topicsUpdated.next(UpdateType.Pin);
        }),
        map(({ success }) => success),
        catchError(TopicService.handleObserverError)
      );
  }

  @warmUpObservable
  unpinAllTopics(): Observable<boolean> {
    const filters: TopicsFilters = {
      pinned: true,
      limit: 0,
      offset: 0
    };
    return this.getTopics(filters)
      .pipe(
        tap(response => filters.limit = response.count),
        switchMap(() => this.getTopics(filters)),
        map(({topics}) => topics.map(topic => ({name: topic.name}))),
        switchMap(topics => this.pinTopics(topics, false)),
        catchError(TopicService.handleObserverError)
      );
  }

}
