// Types
import { Section } from './section';
import { Task } from './task';

export class SectionsTree {
  public projectId: string;
  public columnId?: string;
  public sections: Section[] = [];
  public tasks: Task[] = [];
  public tempTask: Task;

  public tree: Section[] = [];

  public static sortByPosition(items: Task[] | Section[], columnId: string): Task[] | Section[] {
    return items.sort((a, b) => (columnId ? (a.boardPosition > b.boardPosition) : (a.position > b.position)) ? 1 : -1);
  }

  constructor ({
    projectId,
    columnId,
    sections,
    tasks
  }: {
    projectId: string,
    columnId?: string,
    sections: Section[],
    tasks: Task[]
  }) {
    this.projectId = projectId;
    this.columnId = columnId;
    this.sections = [
      new Section({
        default: true, // invisible section for tasks without section
        position: -1,
        id: null
      }),
      ...sections
    ];
    this.tasks = tasks;

    this.buildTree();
  }

  private buildTree() {
    // if section was removed and there are tasks left with removed section Id
    // or task was moved from another project
    const sectionsIds = this.sections.map(section => section.id);

    this.tasks.forEach(task => {
      if (!sectionsIds.includes(task.sectionId)) {
        task.sectionId = null;
      }
    });

    const topLevelTasks = this.tasks.filter(task => task.parentId === null || task.parentId === undefined);

    this.tree = (SectionsTree.sortByPosition(this.sections, this.columnId) as Section[])
      .map((section, sectionIndex) => {
        section.position = sectionIndex;

        const sectionTasks = SectionsTree.sortByPosition(
          topLevelTasks.filter(task => task.sectionId === section.id),
          this.columnId
        ) as Task[];

        sectionTasks.forEach((task, index) => task.position = index);

        return new Section({
          ...section,
          tasks: sectionTasks
            .map(task => new Task({
              ...task,
              subtasks: SectionsTree.sortByPosition(
                this.tasks.filter(subtask => subtask.parentId === task.id),
                this.columnId
              ) as Task []
            }))
        });
      });
  }

  public insertSection(afterTask?: Task) {
    // if there was temp section - remove it and put all tasks to previous one
    const tempSection = this.sections.find(section => section.id === 'temp');

    if (tempSection) {
      const previousSection = this.sections.find(section => section.position === tempSection.position - 1);

      const previousSectionsTasksCount = previousSection
        ? this.tasks.filter(task => task.sectionId === previousSection.id).length
        : 0;

      this.tasks
        .filter(task => task.sectionId === 'temp')
        .forEach((task, index) => {
          if (this.columnId) {
            task.boardPosition = previousSectionsTasksCount + index;
          } else {
            task.position = previousSectionsTasksCount + index;
          }
          task.sectionId = previousSection ? previousSection.id : null;
        });
    }

    const newSection = new Section({
      id: 'temp',
      projectId: this.projectId
    });

    if (afterTask) {
      const afterSection = this.sections.find(section => section.id === afterTask.sectionId);

      newSection.position = afterSection.position + 0.5;

      this.tasks
        .filter(task => task.sectionId === afterSection.id && task.position > afterTask.position)
        .forEach((task, index) => {
          if (this.columnId) {
            task.boardPosition = index;
          } else {
            task.position = index;
          }
          task.sectionId = 'temp';
        });
    } else {
      newSection.position = this.sections.length;
    }

    this.sections = this.sections.filter(section => section.id !== 'temp');

    this.sections.push(newSection);

    this.buildTree();
  }

  public insertTask(afterTask?: Task): void {
    this.tempTask = this.tempTask || new Task({
      temp: true,
      projectId: this.projectId,
      columnId: this.columnId,
      sectionId: afterTask ? afterTask.sectionId : null
    });

    this.tempTask[this.columnId ? 'boardPosition' : 'position'] = afterTask ? afterTask.position + 0.5 :
      this.tasks.filter(task => task.sectionId === null).length;

    const tasks = this.tasks.filter(task => !task.temp);

    this.tasks = [...tasks, this.tempTask];

    this.buildTree();
  }

  public moveTask(task: Task, targetSection: Section, index: number) {
    const newTask = new Task({
      ...task,
      sectionId: targetSection.id,
      projectId: this.projectId,
      position: index - 0.5,
      columnId: this.columnId || task.columnId,
      parentId: null
    });

    this.tasks = [...this.tasks.filter(item => item.id !== task.id), newTask];

    this.buildTree();
  }

  public moveSubtask(subtask: Task, targetTask: Task, index: number) {
    const newSubtask = new Task({
      ...subtask,
      sectionId: null,
      projectId: this.projectId,
      position: index - 0.5,
      columnId: null,
      parentId: targetTask.id
    });

    this.tasks = [...this.tasks.filter(item => item.id !== subtask.id), newSubtask];

    this.buildTree();
  }

  public moveSection(sectionToMove: Section, direction: 1 | -1) {
    this.sections = this.sections.map(section =>
      new Section({
        ...section,
        position: section.id === sectionToMove.id ?
          section.position + direction * 1.5
          :
          section.position
      })
    );

    this.buildTree();
  }
}
