// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Types
import { Project } from '@modules/tasks/types/project';
import { ProjectsListResponse } from '@modules/tasks/types/projects-list-response';

@Component({
  selector: 'app-linked-info-project-list',
  templateUrl: './linked-info-project-list.component.html',
  styleUrls: ['./linked-info-project-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoProjectListComponent extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private projectsService: ProjectsService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'project';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<Project[]> {
    return this.projectsService.getProjects({projectsIds: ids, limit: ids.length})
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response: ProjectsListResponse) => response.projects)
      );
  }

  /**
   * Actions
   */

  select(project: Project) {
    this.modalService.projectForm(project);
  }

}
