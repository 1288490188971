// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, Subject, throwError, from, combineLatest, of, interval, merge, BehaviorSubject } from 'rxjs';
import { map, concatMap, toArray, catchError, tap, switchMap, filter, retryWhen, distinctUntilChanged } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { AsyncTasksService } from '@modules/async-tasks/services/async-tasks.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { MailFolder } from '../types/mail-folder';
import { MailMessage } from '../types/mail-message';
import { Topic, TopicAnnotation } from '@modules/topic/types/topic';
import { Recipient } from '../types/recipient';
import { ResponseMessagesOffset, ResponseMessages } from '../types/mail-response.model';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { Attachment } from '@modules/form-controls/types/attachment';
import { AsyncTask } from '@modules/async-tasks/types/async-task';

// Env
import { environment } from '@environment';

export class MessagesFilters {
  ids?: string[];
  folder?: string;
  messagesIds?: string[];
  threadIds?: string[];
  fromTime?: Date;
  toTime?: Date;
  starred?: boolean;
  pinned?: boolean;
  snoozed?: boolean;
  followed?: boolean;
  relevance?: boolean;
  topics?: string[];
  tags?: string[];
  contacts?: string[];
  keywords?: string[];
  relatedTopics?: string[];
  relatedContacts?: Recipient[];
  relatedMessageId?: string;
  relatedMessageTopics?: string[];
  matchingTypes?: string[];
  groupBy?: string;
  firstSymbol?: string;
  orderWithPinned?: boolean;
}

@Injectable()
export class MailService {

  // tslint:disable-next-line:max-line-length
  static emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static defaultFolderIds: string[] = [
    'inbox', 'all', 'vips', 'prioritized', 'starred', 'important', 'outbox',
    'sent', 'drafts', 'snoozed', 'followed', 'archive', 'spam', 'trash'
  ];
  public static disallowMoveFolderIds: string[] = [
    'vips', 'prioritized', 'starred', 'outbox', 'sent', 'drafts',
    'snoozed', 'followed', 'archive'
  ];

  public onMoveToFolder = new Subject<MailMessage>();
  public onSentMessage = new Subject<MailMessage>();
  public refreshAllMails = new Subject<void>();
  public fetchFolders = new Subject<void>();
  // To prevent calling .next() from Component
  public refreshAllMails$ = this.refreshAllMails.asObservable();

  // Private
  private folders = new BehaviorSubject<MailFolder[]>([]);
  private recentFolders = new BehaviorSubject<string[]>([]);

  /**
   * Static methods
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  static handlePromiseError(error: Error): Promise<boolean> {
    console.error(error);
    return Promise.resolve(false);
  }

  static findFolder(folders: MailFolder[], folderId: string): MailFolder | undefined {
    if (!folders) {
      return;
    }
    const recursiveFindFolder = ((folderList: MailFolder[]): MailFolder | undefined => {
      for (const folder of folderList) {
        if (folder.id === folderId) {
          return folder;
        }
        if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
          const result = recursiveFindFolder(folder.subFolders);
          if (result !== undefined) {
            return result;
          }
        }
      }
    });
    return recursiveFindFolder(folders);
  }

  static attachmentIcon(type: string): string {
    const iconsClasses = {
      'audio': 'file-audio',
      'default': 'file-archive',
      'image': 'file-image',
      'text': 'file-text',
      'video': 'file-video',
      'application/ics': 'calendar',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
      'application/javascript': 'file-code',
      'application/msword': 'file-word',
      'application/pdf': 'file-pdf',
      'text/html': 'file-code',
      'text/xml': 'file-excel',
      'text/calendar': 'calendar',
    };

    return `fa fa-${iconsClasses[type] || iconsClasses[type.split('/')[0]] || iconsClasses.default}-o`;
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private asyncTasksService: AsyncTasksService,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private linkedInfoService: LinkedInfoService,
  ) {
    this.recentFolders.next(this.stateService.lastFoldersMovedTo);

    // Fetch Folders
    this.fetchFolders
      .pipe(
        switchMap(() => this.http.get<MailFolder[]>(`${environment.baseUrl}/api/mail/folders`)),
        retryWhen(error => error)
      )
      .subscribe(folders => this.folders.next(folders));
    interval(30000)
      .pipe(
        filter(() => this.folders.observers.length > 0)
      )
      .subscribe(() => this.fetchFolders.next());
  }

  /**
   * Methods
   */

  private formatFilters(filters: MessagesFilters,
                        sortBy?: string,
                        limit?: number,
                        offset?: number): { [param: string]: string | string[]; } {
    const formatedFilters = {};

    if (filters.ids) { formatedFilters['messages_ids[]'] = filters.ids; }
    if (filters.folder) { formatedFilters['folder'] = filters.folder; }
    if (filters.messagesIds) { formatedFilters['messages_ids[]'] = filters.messagesIds; }
    if (filters.threadIds) { formatedFilters['thread_ids[]'] = filters.threadIds; }
    if (filters.groupBy) { formatedFilters['groupby'] = filters.groupBy; }
    if (filters.fromTime) { formatedFilters['from_time'] = filters.fromTime.getTime() + ''; }
    if (filters.toTime) { formatedFilters['to_time'] = filters.toTime.getTime() + ''; }
    if ('starred' in filters) { formatedFilters['starred'] = filters.starred + ''; }
    if ('pinned' in filters) { formatedFilters['pinned'] = filters.pinned + ''; }
    if ('snoozed' in filters) { formatedFilters['snoozed'] = filters.snoozed + ''; }
    if ('followed' in filters) { formatedFilters['followed'] = filters.followed + ''; }
    if ('relevance' in filters) { formatedFilters['use_relevance'] = filters.relevance + ''; }
    if ('orderWithPinned' in filters) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }
    if (filters.topics) { formatedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formatedFilters['tags[]'] = filters.tags; }
    if (filters.contacts) { formatedFilters['contacts[]'] = filters.contacts; }
    if (filters.keywords) { formatedFilters['keywords[]'] = filters.keywords; }
    if (filters.relatedTopics) { formatedFilters['related_topics[]'] = filters.relatedTopics; }
    if (filters.relatedContacts) { formatedFilters['related_contacts[]'] = filters.relatedContacts.map(contact => contact.id); }
    if (filters.relatedMessageId) { formatedFilters['related_message_id'] = filters.relatedMessageId; }
    if (filters.relatedMessageTopics) { formatedFilters['related_message_topics[]'] = filters.relatedMessageTopics; }
    if (filters.matchingTypes) { formatedFilters['matching_types[]'] = filters.matchingTypes; }
    if (filters.firstSymbol) { formatedFilters['first_symbol'] = filters.firstSymbol; }
    if (sortBy) { formatedFilters['sort_by'] = sortBy; }
    if (limit) { formatedFilters['limit'] = limit + ''; }
    if (offset) { formatedFilters['offset'] = offset + ''; }

    formatedFilters['timing'] = localStorage.getItem('timing');

    return formatedFilters;
  }

  getFolders(): Observable<MailFolder[]> {
    this.fetchFolders.next();
    return this.folders.asObservable();
  }

  getFoldersCounts(filters: MessagesFilters): Observable<MailFolder[]> {
    return this.http.get<{ data: MailFolder[] }>(environment.baseUrl + '/api/mail/folders/counters', {params: this.formatFilters(filters)})
      .pipe(
        map(({ data }) => data)
      );
  }

  getFolderDetails(folder: string): Observable<MailFolder> {
    return this.getFolders()
      .pipe(
        map(folders => MailService.findFolder(folders, folder)),
        distinctUntilChanged((oldDetails, newDetails) =>
          oldDetails && newDetails &&
          oldDetails.id === newDetails.id &&
          oldDetails.folderId === newDetails.folderId &&
          oldDetails.name === newDetails.name &&
          oldDetails.totalMails === newDetails.totalMails &&
          oldDetails.unreadMails === newDetails.unreadMails &&
          oldDetails.icon === newDetails.icon &&
          oldDetails.pinned === newDetails.pinned
        )
      );
  }

  createFolder(folderName): Observable<MailFolder> {
    const body = {
      display_name: folderName
    };

    const createFolder$ = this.http.post(environment.baseUrl + '/api/mail/folders/', body)
      .pipe(
        map((response) => response['data'])
      );
    const fetchFolders$ = this.http.get(environment.baseUrl + '/api/mail/folders');

    return from([createFolder$, fetchFolders$])
      .pipe(
        concatMap(observer => observer),
        toArray(),
        map((result) => {
          const folder = result[0];
          const folders = result[1] as MailFolder[];
          this.folders.next(folders);
          return MailService.findFolder(this.folders.value, folder.id);
        })
      );
  }

  renameFolder(folderId: string, folderName: string): Observable<boolean> {
    const body = {
      display_name: folderName
    };

    return this.http.put(environment.baseUrl + '/api/mail/folders/' + folderId, body)
      .pipe(
        tap(() => this.fetchFolders.next()),
        map(response => response['success']),
        catchError(MailService.handleObserverError)
      );
  }

  deleteFolder(folderId: string): Observable<boolean> {
    return this.http.delete(environment.baseUrl + '/api/mail/folders/' + folderId)
      .pipe(
        tap(() => this.fetchFolders.next()),
        map(response => response['success']),
        catchError(MailService.handleObserverError)
      );
  }

  pinFolder(folders: MailFolder[], pinned: boolean): Observable<boolean> {
    return this.http.put(environment.baseUrl + '/api/mail/folders/pinned', { folders, pinned })
      .pipe(
        tap(() => this.fetchFolders.next()),
        map(response => response['success']),
        catchError(MailService.handleObserverError)
      );
  }

  getMessages(filters: MessagesFilters, sortBy: string, limit: number, offset: number): Observable<ResponseMessages> {
    const requestParams = {
      params: this.formatFilters(filters, sortBy, limit, offset)
    };

    return this.http.get<{ data: ResponseMessages }>(environment.baseUrl + '/api/mail/messages', requestParams)
      .pipe(
        map(({ data }) => data),
        catchError(MailService.handleObserverError)
      );
  }

  getMessage(id: string): Observable<MailMessage> {
    return this.http.get<{ data: MailMessage }>(`${environment.baseUrl}/api/mail/messages/${id}`)
      .pipe(
        map(({ data }) => data)
      );
  }

  getMessagesOffset(filters: MessagesFilters, sortBy: string): Observable<ResponseMessagesOffset> {
    const requestParams = {
      params: this.formatFilters(filters, sortBy)
    };
    return this.http.get<ResponseMessagesOffset>(environment.baseUrl + '/api/mail/message-offset', requestParams)
      .pipe(
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  updateMessagesUnreadStatus(filters: MessagesFilters, unread: boolean = false): Observable<boolean> {
    return this.http.put<{success: boolean, asyncTask: AsyncTask, messages: string[]}>(
      environment.baseUrl + '/api/mail/messages/unread', { unread }, { params: this.formatFilters(filters) })
      .pipe(
        switchMap(({success, asyncTask }) => {
          if (filters.messagesIds && filters.messagesIds.length === 1) {
            return of(success);
          }
          return this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `Marking message(s) as ${unread ? 'unread' : 'read'}.`,
              },
              completed: {
                text: `Message(s) marked as ${unread ? 'unread' : 'read'}.`,
              },
              error: {
                text: `Error while marking message(s) as ${unread ? 'unread' : 'read'}. Please try again.`,
              }
            }
          });
        }),
        tap(() => this.doRefreshMailList()),
        catchError(MailService.handleObserverError)
      );
  }

  starMessage(message: MailMessage): Observable<boolean> {
    return this.http.put(environment.baseUrl + '/api/mail/messages/star', {
      message_id: message.id,
      starred: !message.starred
    })
      .pipe(
        tap(() => this.fetchFolders.next()),
        map(() => {
          message.starred = !message.starred;
          return true;
        }),
        catchError(MailService.handleObserverError)
      );
  }

  private dateConvector(currentDate: Date, alertTime: string) {
    switch (alertTime) {
      case 'hour':
        currentDate.setHours(currentDate.getHours() + 1);
        break;
      case 'tomorrow':
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case 'afterTomorrow':
        currentDate.setDate(currentDate.getDate() + 2);
        break;
      case 'endOfWeek':
        currentDate.setDate(currentDate.getDate() - (currentDate.getDay() - 5));
        break;
      case 'nextWeek':
        currentDate.setDate(currentDate.getDate() + (-currentDate.getDay() + 7) % 7 + 1);
        break;
      default:
        break;
    }
    return currentDate;
  }

  @warmUpObservable
  snoozeMessage(snooze: string, messageId: string, customDate?: Date): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${environment.baseUrl}/api/mail/messages/snooze`, {
      id: messageId,
      snooze_schedule: this.dateConvector(customDate || new Date(), snooze)
    })
      .pipe(
        tap(() => this.doRefreshMailList()),
        map(({ success }) => success),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  followMessage(snooze: string, messageId: string, customDate?: Date): Observable<boolean> {
    return this.http.post<{ success: boolean }>(`${environment.baseUrl}/api/mail/messages/follow`, {
      id: messageId,
      follow_schedule: this.dateConvector(customDate || new Date(), snooze)
    })
      .pipe(
        tap(() => this.doRefreshMailList()),
        map(({ success }) => success),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  removeSnoozeMessage(messageId: string): Observable<boolean> {
    return this.http.request<{ success: boolean }>(
      'DELETE',
      `${environment.baseUrl}/api/mail/messages/snooze`,
      { body: { id: messageId } }
    )
      .pipe(
        tap(() => this.doRefreshMailList()),
        map(({ success }) => success),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  removeFollowMessage(messageId: string): Observable<boolean> {
    return this.http.request<{ success: boolean }>(
      'DELETE',
      `${environment.baseUrl}/api/mail/messages/follow`,
      { body: { id: messageId } }
    )
      .pipe(
        tap(() => this.doRefreshMailList()),
        map(({ success }) => success),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  sendMessage(message: MailMessage): Observable<MailMessage> {
    const linkedInfo = message.linkedInfo;
    return this.http.post<{ success: boolean, message: MailMessage }>(
      environment.baseUrl + '/api/mail/messages/send', { message }
    )
      .pipe(
        tap(({ message: sentMessage }) => {
          this.fetchFolders.next();
          this.onSentMessage.next(sentMessage);
        }),
        tap(({ message: sentMessage }) => {
          if (linkedInfo && linkedInfo.length) {
            this.linkedInfoService.linkToItem({type: 'message', id: sentMessage.id}, linkedInfo);
          }
        }),
        map(({ message: sentMessage }) => sentMessage),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  saveMessageToDrafts(message: MailMessage): Observable<MailMessage> {
    return this.http.post<{success: boolean, draft: MailMessage}>(
      environment.baseUrl + '/api/mail/messages/drafts',
      { message }
    )
      .pipe(
        tap(({ draft }) => {
          if (message.linkedInfo && message.linkedInfo.length) {
            this.linkedInfoService.linkToItem({ type: 'message', id: draft.id }, message.linkedInfo);
          }
        }),
        map(({ draft }) => draft),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteDraft(message: MailMessage): Observable<boolean> {
    return this.http.request<{success: boolean}>(
      'DELETE',
      environment.baseUrl + '/api/mail/messages/drafts/' + message.id,
      { body: { message } }
    )
      .pipe(
        map(({ success }) => success),
        catchError(MailService.handleObserverError)
      );
  }

  uploadFile(file: File): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<{success: boolean, file: Attachment}>(environment.baseUrl + '/api/mail/files', formData)
      .pipe(
        map(({ file: attachment }) => attachment),
        catchError(MailService.handleObserverError)
      );
  }
  downloadFile(url): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  getTopics(message: MailMessage, forceProcess: boolean = false): Observable<Topic[]> {
    const params = {};
    if (forceProcess) {
      params['force_process'] = 'true';
    }
    return this.http.get(environment.baseUrl + '/api/mail/messages/' + message.id + '/topics', {params})
      .pipe(
        map(res => res['data'] as Topic[]),
        catchError(MailService.handleObserverError)
      );
  }

  getTopicsAnnotations(messageId: string): Observable<{annotations: TopicAnnotation[], shareText: boolean}> {
    return this.http.get<{annotations: TopicAnnotation[], shareText: boolean}>(
      `${environment.baseUrl}/api/mail/messages/${messageId}/topics/annotations`
    );
  }

  saveTopicsAnnotations(messageId: string, annotations: TopicAnnotation[], shareText: boolean): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/mail/messages/${messageId}/topics/annotations`,
      { annotations, shareText }
    )
      .pipe(
        map(({ success }) => success)
      );
  }

  getTemporalExpressions(message: MailMessage): Observable<TemporalExpression[]> {
    return this.http.get(environment.baseUrl + '/api/messages/' + message.id + '/temporal-expressions',  { params: {
      timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone_offset: new Date().getTimezoneOffset() + ''
    }})
      .pipe(
        map(response => response['expressions'].map(expression => {
          expression.fromTime = expression.fromTime ? new Date(expression.fromTime) : null;
          expression.toTime = expression.toTime ? new Date(expression.toTime) : null;
          return expression;
        }) as TemporalExpression[]),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  moveMessages(
    { messages, filters }: { messages?: MailMessage[], filters?: MessagesFilters },
    folders: string[],
    copy = false
  ): Observable<boolean> {
    return this.http.put<{
      success: boolean, data: { asyncTask: AsyncTask, messages: string[] }
    }>(environment.baseUrl + '/api/mail/messages/folders', {
      messages: messages ? messages.map(message => message.id) : null,
      filters: filters ? this.formatFilters(filters) : null,
      // Convert folders ids to folderId values, which requried for api
      folders: folders.map(folder => MailService.findFolder(this.folders.value, folder).folderId),
      copy
    })
      .pipe(
        switchMap(({ data: { asyncTask, messages: movingMessages } }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `${!copy ? 'Moving' : 'Copy'} ${movingMessages ? movingMessages.length : ''} message(s).`,
                actions: ['undo']
              },
              completed: {
                text: `Message(s) ${ !copy ? 'moved' : 'copied'}.`,
                actions: ['undo']
              },
              error: {
                text: `Error while ${ !copy ? 'moving' : 'copy'} message(s). Please try again.`,
                actions: ['undo']
              }
            },
            actions: {
              undo: {
                text: 'Undo',
                handler: () => this.asyncTasksService
                  .undo(asyncTask)
                  .pipe(
                    switchMap(undoAsyncTaks => this.asyncTasksToastsService.showAwaitProgress(undoAsyncTaks.asyncTaks, {
                      status: {
                        processing: {
                          text:
                            `Undoing
                            ${ !copy ? 'moving' : 'copy'} of
                            ${undoAsyncTaks.data.messages ? undoAsyncTaks.data.messages.length : ''}
                            message(s).`,
                        },
                        completed: { text: `${ !copy ? 'Moving' : 'Copy'} undone.` },
                        error: { text: `Error while undo ${ !copy ? 'moving' : 'copy'} message(s).` }
                      }
                    }))
                  )
                  .subscribe(() => this.doRefreshMailList())
              }
            }
          })
        ),
        tap(() => this.doRefreshMailList())
      );
  }

  @warmUpObservable
  archiveMessages(messages: MailMessage[], archived: boolean): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/mail/archivation/`,
      {
        messages: messages.map(msg => msg.id),
        archived: archived
      }
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          this.doRefreshMailList();
          this.asyncTasksToastsService.show({
            text: success
              ? `Message(s) ${archived ? 'archived' : 'restored'}.`
              : `${archived ? 'Archiving' : 'Restoring'} failed, please try again.`
          });
        }),
        catchError((error: Error) => {
          this.asyncTasksToastsService.show({
            text: `${archived ? 'Archiving' : 'Restoring'} failed, please try again.`
          });
          throw error;
        }),
      );
  }

  @warmUpObservable
  deleteMessages(filters: MessagesFilters): Observable<boolean> {
    return this.http.delete<{
      success: boolean, data: { asyncTask: AsyncTask, messages: string[] }
    }>(`${environment.baseUrl}/api/mail/messages`,  {
      params: this.formatFilters(filters)
    })
      .pipe(
        switchMap(({ data: { asyncTask, messages: movingMessages } }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `Deleting ${movingMessages ? movingMessages.length : ''} message(s).`,
                actions: ['undo']
              },
              completed: {
                text: `Message(s) deleted.`,
                actions: ['undo']
              },
              error: {
                text: `Error while deleting message(s). Please try again.`,
                actions: ['undo']
              }
            },
            actions: {
              undo: {
                text: 'Undo',
                handler: () => this.asyncTasksService
                  .undo(asyncTask)
                  .pipe(
                    switchMap(undoAsyncTaks => this.asyncTasksToastsService.showAwaitProgress(undoAsyncTaks.asyncTaks, {
                      status: {
                        processing: {
                          text:
                            `Restoring
                            ${undoAsyncTaks.data.messages ? undoAsyncTaks.data.messages.length : ''}
                            message(s).`,
                        },
                        completed: { text: `Message(s) restored.` },
                        error: { text: `Error while restoring message(s).` }
                      }
                    }))
                  )
                  .subscribe(() => this.doRefreshMailList())
              }
            }
          })
        ),
        tap(() => this.doRefreshMailList()),
        catchError(MailService.handleObserverError)
      );
  }

  pinnedMessage(message: MailMessage): Observable<boolean> {
    return this.http.put<{ success: boolean }>(
      `${environment.baseUrl}/api/message/${message.id}/pinned`,
      { pinned: !message.pinned }
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (success) {
            message.pinned = !message.pinned;
            this.doRefreshMailList();
          }
        }),
        catchError(MailService.handleObserverError)
      );
  }

  doRefreshMailList(): void {
    this.refreshAllMails.next();
    this.fetchFolders.next();
  }

  getRecentFolders(): Observable<MailFolder[]> {
    return combineLatest([this.folders, this.recentFolders])
      .pipe(
        map(([folders, recentFoldersIds]) =>
          recentFoldersIds
            .map(id => folders.find(folder => folder.id === id))
            .filter(folder => !!folder)
        )
      );
  }

  updateRecipientVipStatus(recipient: Recipient, status: boolean): Observable<boolean> {
    return this.http.put<{ success: boolean }>(
      `${environment.baseUrl}/api/contacts/${recipient.id}/vip`,
      { vip: status }
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (success) {
            recipient.vip = status;
            this.fetchFolders.next();
          }
        }),
        catchError(MailService.handleObserverError)
      );
  }

  @warmUpObservable
  cancelSendMessage(message: MailMessage): Observable<MailMessage> {
    return this.asyncTasksService
      .undo({ type: 'message-send', id: `send-message-${message.id}`})
      .pipe(
        map(({ data }: { data: MailMessage }) => data),
        tap(draftMessage => {
          this.fetchFolders.next();
          this.onMoveToFolder.next(draftMessage);
          this.asyncTasksToastsService.show({
            text: 'Message sending undone.'
          });
        }),
        catchError((error: Error) => {
          this.asyncTasksToastsService.show({
            text: 'Message sending can\'t be undone.'
          });
          throw error;
        }),
        catchError(MailService.handleObserverError)
      );
  }

}
