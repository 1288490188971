import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnChanges, SimpleChanges, OnInit, ElementRef, TemplateRef } from '@angular/core';
// RX
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
var CalendarContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarContextMenuComponent, _super);
    /**
     * Constructor
     */
    function CalendarContextMenuComponent(contextMenuService, calendarService, stateService, popoverService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.calendarService = calendarService;
        _this.stateService = stateService;
        _this.popoverService = popoverService;
        // Private
        _this.componentNotDestroyed = new Subject();
        _this.colorChanges = new Subject();
        _this.colorChanges
            .pipe(switchMap(function () { return _this.calendarForm.get('color').valueChanges; }), takeUntil(_this.componentNotDestroyed))
            .subscribe(function (value) {
            _this.calendar.color = value;
            _this.calendarService.editCalendar(_this.calendar);
        });
        return _this;
    }
    /**
     * Lifecycle
     */
    CalendarContextMenuComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    CalendarContextMenuComponent.prototype.ngOnInit = function () {
        this.calendarForm = this.calendar.asFormGroup();
        this.colorChanges.next();
    };
    CalendarContextMenuComponent.prototype.ngOnChanges = function (changes) {
        if ('calendar' in changes) {
            this.calendarForm = this.calendar.asFormGroup();
            this.colorChanges.next();
        }
    };
    /**
     * Actions
     */
    CalendarContextMenuComponent.prototype.setSelectedCalendars = function (calendarIds) {
        this.stateService.selectedCalendars = calendarIds;
    };
    CalendarContextMenuComponent.prototype.openCalendarForm = function () {
        this.showPopup(this.editCalendarForm);
    };
    CalendarContextMenuComponent.prototype.openDeleteForm = function () {
        this.showPopup(this.deleteCalendarForm);
    };
    CalendarContextMenuComponent.prototype.handleCalendarPopoverClose = function () {
        this.popoverClose.next();
    };
    CalendarContextMenuComponent.prototype.deleteCalendar = function (submitted) {
        if (submitted) {
            // TODO: Use calendar api to delete calendar when it will be ready
        }
    };
    /**
     * Helpers
     */
    CalendarContextMenuComponent.prototype.showPopup = function (content) {
        this.popoverService.create(this.originRef, {
            placement: 'right',
            arrow: true,
            content: content,
            trigger: 'click',
            allowedOutsideSelectors: '.mat-autocomplete-panel',
            showUntil: this.popoverClose
        });
    };
    return CalendarContextMenuComponent;
}(ContextMenuComponent));
export { CalendarContextMenuComponent };
