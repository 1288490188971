// Common
import { Injectable } from '@angular/core';

// Types
import { DropdownOption } from '../../dropdown/types/dropdown-option';
import { GlobalState } from '../types/global-state';
import { SplitViewState, SplitViewKey } from '../types/split-view-state';
import { SelectedTabsKey, SelectedTabsState } from '../types/selected-tabs-state';
import { EventsMainView } from '@modules/events/types/events-main-view';
import { TasksMainView } from '@modules/tasks/types/tasks-main-view';
import { ContactsMainView } from '@modules/contacts/types/contacts-main-view';
import { CalendarType } from '@modules/events/types/calendar-type';
import { SidebarFilters, SidebarFilterKey } from '../types/sidebar-filters-state';
import { SortMessagesListKey, SortMessagesListState } from '../types/sort-messages-list-state';
import { CollapsedStateKey } from '../types/collapsed-state';
import { MessagesListKey, MessagesListPersistantSettings } from '../types/messages-list-state';

// RX
import { BehaviorSubject, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

/**
 * Don`t directly use the variable from the service inside the template!
 */

@Injectable()
export class StateService {

  /**
   * Properties
   */

  private currentStates: BehaviorSubject<GlobalState>;
  private defaultStates = new GlobalState();

  /**
   * Sort
   */

  set sortKnowledgePaneRelatedTopics(value: DropdownOption) { this.updateStates({ sortKnowledgePaneRelatedTopics: value }); }
  get sortKnowledgePaneRelatedTopics(): DropdownOption { return this.currentStates.value.sortKnowledgePaneRelatedTopics; }

  set sortKnowledgePaneRelatedConnections(value: DropdownOption) { this.updateStates({ sortKnowledgePaneRelatedConnections: value }); }
  get sortKnowledgePaneRelatedConnections(): DropdownOption { return this.currentStates.value.sortKnowledgePaneRelatedConnections; }

  set sortKnowledgePaneRelatedFiles(value: DropdownOption) { this.updateStates({ sortKnowledgePaneRelatedFiles: value }); }
  get sortKnowledgePaneRelatedFiles(): DropdownOption { return this.currentStates.value.sortKnowledgePaneRelatedFiles; }

  set sortKnowledgePaneRelatedHyperlinks(value: DropdownOption) { this.updateStates({ sortKnowledgePaneRelatedHyperlinks: value }); }
  get sortKnowledgePaneRelatedHyperlinks(): DropdownOption { return this.currentStates.value.sortKnowledgePaneRelatedHyperlinks; }

  set sortKnowledgePaneUpcomingEvents(value: DropdownOption) { this.updateStates({ sortKnowledgePaneUpcomingEvents: value }); }
  get sortKnowledgePaneUpcomingEvents(): DropdownOption { return this.currentStates.value.sortKnowledgePaneUpcomingEvents; }

  set sortKnowledgePaneArchivedEvents(value: DropdownOption) { this.updateStates({ sortKnowledgePaneArchivedEvents: value }); }
  get sortKnowledgePaneArchivedEvents(): DropdownOption { return this.currentStates.value.sortKnowledgePaneArchivedEvents; }

  set sortInsightsRelatedConnections(value: DropdownOption) { this.updateStates({ sortInsightsRelatedConnections: value }); }
  get sortInsightsRelatedConnections(): DropdownOption { return this.currentStates.value.sortInsightsRelatedConnections; }

  set sortTopicMap(value: DropdownOption) { this.updateStates({ sortTopicMap: value }); }
  get sortTopicMap(): DropdownOption { return this.currentStates.value.sortTopicMap; }

  set sortPinnedTopics(value: DropdownOption) { this.updateStates({ sortPinnedTopics: value }); }
  get sortPinnedTopics(): DropdownOption { return this.currentStates.value.sortPinnedTopics; }

  set sortPinnedTags(value: DropdownOption) { this.updateStates({ sortPinnedTags: value }); }
  get sortPinnedTags(): DropdownOption { return this.currentStates.value.sortPinnedTags; }

  set sortEventsList(value: DropdownOption) { this.updateStates({ sortEventsList: value }); }
  get sortEventsList(): DropdownOption { return this.currentStates.value.sortEventsList; }

  set sortTasksProjectsList(value: DropdownOption) { this.updateStates({ sortTasksProjectsList: value }); }
  get sortTasksProjectsList(): DropdownOption { return this.currentStates.value.sortTasksProjectsList; }

  set sortNotesList(value: DropdownOption) { this.updateStates({ sortNotesList: value }); }
  get sortNotesList(): DropdownOption { return this.currentStates.value.sortNotesList; }

  setSortMessagesList(key: SortMessagesListKey, value: DropdownOption) {
    this.updateStates({sortMessagesList: {...this.currentStates.value.sortMessagesList, [key]: value}});
  }
  getSortMessagesList(key: SortMessagesListKey): Observable<DropdownOption> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.sortMessagesList),
        map((state: SortMessagesListState) => state[key]),
        distinctUntilChanged(),
      );
  }

  set sortContactsList(value: DropdownOption) { this.updateStates({ sortContactsList: value }); }
  get sortContactsList(): DropdownOption { return this.currentStates.value.sortContactsList; }

  /**
   * List settings
   */

  setMessagesListSettings(key: MessagesListKey, value: MessagesListPersistantSettings) {
    this.updateStates({ messagesLists: { ...this.currentStates.value.messagesLists, [key]: value } });
  }
  getMessagesListSettings(key: MessagesListKey): MessagesListPersistantSettings {
    return this.currentStates.value.messagesLists[key];
  }

  /**
   * Filters
   */

  set filterTopicMapCount(value: {name: string, key: number}) { this.updateStates({ filterTopicMapCount: value }); }
  get filterTopicMapCount(): {name: string, key: number} { return this.currentStates.value.filterTopicMapCount; }

  set filterTopicMapDate(value: {name: string, key: number | string, toDate: Date, fromDate: Date}) {
    this.updateStates({ filterTopicMapDate: value });
  }
  get filterTopicMapDate(): {name: string, key: number | string, toDate: Date, fromDate: Date} {
    const filter = this.currentStates.value.filterTopicMapDate;
    if (filter.fromDate) { filter.fromDate = new Date(filter.fromDate); }
    if (filter.toDate) { filter.toDate = new Date(filter.toDate); }
    return filter;
  }

  set filterTopicMapFirstSymbol(value: string) { this.updateStates({ filterTopicMapFirstSymbol: value }); }
  get filterTopicMapFirstSymbol(): string { return this.currentStates.value.filterTopicMapFirstSymbol; }

  set filterTasksList(value: DropdownOption[]) { this.updateStates({ filterTasksList: value }); }
  get filterTasksList(): DropdownOption[] { return this.currentStates.value.filterTasksList; }

  /**
   * Selected
   */

  set selectedOrderOptions(value: DropdownOption[]) { this.updateStates({ selectedOrderOptions: value }); }
  get selectedOrderOptions(): DropdownOption[] { return this.currentStates.value.selectedOrderOptions; }

  set selectedExtendedFilteringMatchTypes(value: DropdownOption[]) { this.updateStates({ selectedExtendedFilteringMatchTypes: value }); }
  get selectedExtendedFilteringMatchTypes(): DropdownOption[] { return this.currentStates.value.selectedExtendedFilteringMatchTypes; }

  set selectedKnowledgePaneContactContext(value: string) { this.updateStates({ selectedKnowledgePaneContactContext: value }); }
  get selectedKnowledgePaneContactContext(): string { return this.currentStates.value.selectedKnowledgePaneContactContext; }

  set selectedTopicMapView(value: 'cloud'|'list') { this.updateStates({ selectedTopicMapView: value }); }
  get selectedTopicMapView(): 'cloud'|'list' { return this.currentStates.value.selectedTopicMapView; }

  set selectedCalendarType(value: CalendarType) { this.updateStates({ selectedCalendarType: value }); }
  getSelectedCalendarType(): Observable<CalendarType> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.selectedCalendarType),
        distinctUntilChanged(),
      );
  }

  set selectedCalendars(value: string[]) { this.updateStates({ selectedCalendars: value }); }
  getSelectedCalendars(): Observable<string[]> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.selectedCalendars),
        distinctUntilChanged((previous, current) => previous.join() === current.join())
      );
  }

  set selectedKPCalendars(value: string[]) { this.updateStates({ selectedKPCalendars: value }); }
  getSelectedKPCalendars(): Observable<string[]> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.selectedKPCalendars),
        distinctUntilChanged((prev, curr) => prev.join() === curr.join())
      );
  }

  set eventsMainView(value: EventsMainView) { this.updateStates({ eventsMainView: value }); }
  get eventsMainView(): EventsMainView { return this.currentStates.value.eventsMainView; }

  set tasksMainView(value: TasksMainView) { this.updateStates({ tasksMainView: value }); }
  get tasksMainView(): TasksMainView { return this.currentStates.value.tasksMainView; }

  set contactsMainView(value: ContactsMainView) { this.updateStates({ contactsMainView: value }); }
  get contactsMainView(): ContactsMainView { return this.currentStates.value.contactsMainView; }

  addColumnToCollapsed(columnId: string) {
    this.updateStates({ collapsedColumns: [...this.currentStates.getValue().collapsedColumns, columnId] });
  }
  removeColumnFromCollapsed(columnId: string) {
    this.updateStates({ collapsedColumns: this.currentStates.getValue().collapsedColumns.filter(id => id !== columnId) });
  }
  getCollapsedColumns(): Observable<string[]> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.collapsedColumns),
        distinctUntilChanged((previous, current) => previous.join() === current.join())
      );
  }

  set filesMainView(value: 'empty' | 'file-form') { this.updateStates({ filesMainView: value }); }
  get filesMainView(): 'empty' | 'file-form' { return this.currentStates.value.filesMainView; }

  /**
   * Split
   */

  set splitInsightsTopics(value: number) { this.updateStates({ splitInsightsTopics: value }); }
  get splitInsightsTopics(): number { return this.currentStates.value.splitInsightsTopics; }

  set splitInsightsRelatedContacts(value: number) { this.updateStates({ splitInsightsRelatedContacts: value }); }
  get splitInsightsRelatedContacts(): number { return this.currentStates.value.splitInsightsRelatedContacts; }

  set splitKnowledgePanelTopics(value: number) { this.updateStates({ splitKnowledgePanelTopics: value }); }
  get splitKnowledgePanelTopics(): number { return this.currentStates.value.splitKnowledgePanelTopics; }

  set splitKnowledgePanelContacts(value: number) { this.updateStates({ splitKnowledgePanelContacts: value }); }
  get splitKnowledgePanelContacts(): number { return this.currentStates.value.splitKnowledgePanelContacts; }

  set splitTaskDetailsLinked(value: number) { this.updateStates({ splitTaskDetailsLinked: value }); }
  get splitTaskDetailsLinked(): number { return this.currentStates.value.splitTaskDetailsLinked; }

  setSplitState(key: SplitViewKey, currentValue: number, lastValue: number) {
    this.updateStates({
      splitState: {
        ...this.currentStates.value.splitState,
        [key]: [currentValue, lastValue]
      }
    });
  }

  getSplitState(key: SplitViewKey): Observable<{current: number, last: number}> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.splitState),
        map((state: SplitViewState) => state[key] ? state : this.defaultStates.splitState),
        map((state: SplitViewState) => ({current: state[key][0], last: state[key][1]})),
        distinctUntilChanged((a, b) => a.current === b.current && a.last === b.last),
      );
  }

  setSelectedCalendarDates(type: CalendarType, value: Date) {
    this.updateStates({
      selectedCalendarDates: {
        ...this.currentStates.value.selectedCalendarDates,
        [type]: value
      }
    });
  }

  getSelectedCalendarDates(type: CalendarType): Observable<Date> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.selectedCalendarDates[type]),
        distinctUntilChanged((previous, current) => this.datesEqual(previous, current))
      );
  }

  set linkedToolbarFormMaximized(value: boolean) { this.updateStates({ linkedToolbarFormMaximized: value }); }
  get linkedToolbarFormMaximized(): boolean { return this.currentStates.value.linkedToolbarFormMaximized; }

  set linkedToolbarFormHeight(value: number) { this.updateStates({ linkedToolbarFormHeight: value }); }
  get linkedToolbarFormHeight(): number { return this.currentStates.value.linkedToolbarFormHeight; }

  setTabsState(key: SelectedTabsKey, value: number) {
    this.updateStates({
      tabsState: {
        ...this.currentStates.value.tabsState,
        [key]: value
      },
      previousKnowledgePanelState: this.currentStates.value.tabsState.kp
    });
  }

  getTabsState(key: SelectedTabsKey): Observable<number> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.tabsState),
        map((state: SelectedTabsState) => state[key] || 0),
        distinctUntilChanged(),
      );
  }

  setSidebarFilters(key: SidebarFilterKey, value: string) {
    this.updateStates({sidebarFilters: {...this.currentStates.value.sidebarFilters, [key]: value}});
  }

  getSidebarFilters(key: SidebarFilterKey): Observable<string> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.sidebarFilters),
        map((state: SidebarFilters) => state[key]),
        distinctUntilChanged(),
      );
  }

  get previousKnowledgePanelState(): number { return this.currentStates.value.previousKnowledgePanelState; }

  /**
   * Folders
   */

  addFolderToLastSaved(folder: string) {
    const folders = this.lastFoldersMovedTo.reverse();
    const itemIndex = folders.indexOf(folder);
    if (itemIndex > -1) {
      folders.splice(itemIndex, 1);
    } else if (folders.length === 5 ) {
      folders.shift();
    }
    folders.push(folder);
    this.updateStates({ lastFoldersMovedTo: folders.reverse() });
  }

  get lastFoldersMovedTo(): string[] { return this.currentStates.value.lastFoldersMovedTo; }

  set knowledgePanelFolder(value: string) { this.updateStates({ knowledgePanelFolder: value }); }
  get knowledgePanelFolder(): string { return this.currentStates.value.knowledgePanelFolder; }

  /**
   * Settings
   */

  set settingMessageSnoozed(value: string) { this.updateStates({ settingMessageSnoozed: value }); }
  get settingMessageSnoozed(): string { return this.currentStates.value.settingMessageSnoozed; }

  set isUserFolderExpanded(value: boolean) { this.updateStates({ isUserFolderExpanded: value }); }
  get isUserFolderExpanded(): boolean { return this.currentStates.value.isUserFolderExpanded; }

  set isAppMenuExpanded(value: boolean) { this.updateStates({ isAppMenuExpanded: value }); }
  get isAppMenuExpanded(): boolean { return this.currentStates.value.isAppMenuExpanded; }

  set isTextEditorToolbarExpanded(value: boolean) { this.updateStates({ isTextEditorToolbarExpanded: value }); }
  get isTextEditorToolbarExpanded(): boolean { return this.currentStates.value.isTextEditorToolbarExpanded; }

  toggleUserFolderState(folderId: string) {
    this.updateStates({
      userFoldersState: this.userFoldersState.includes(folderId)
        ? this.userFoldersState.filter(id => id !== folderId)
        : this.userFoldersState.concat([folderId])
    });
  }
  get userFoldersState(): string[] { return this.currentStates.value.userFoldersState; }

  setCollapsed(key: CollapsedStateKey, value: boolean) {
    this.updateStates({
      collapsed: {
        ...this.currentStates.value.collapsed,
        [key]: value
      }
    });
  }

  getCollapsed(key: CollapsedStateKey): Observable<boolean> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.collapsed[key]),
        distinctUntilChanged()
      );
  }

  /**
   * Constructor
   */

  constructor() {
    this.currentStates = new BehaviorSubject<GlobalState>({
      ...this.defaultStates,
      ...this.getSavedStates()
    });
    this.currentStates.subscribe((states: GlobalState) => this.saveStates(states));
  }

  /**
   * Methods
   */

  private getSavedStates(): GlobalState {
    try {
      return new GlobalState(JSON.parse(localStorage.getItem('app.states')));
    } catch (e) {
      console.error('Can not parse JSON from localStore. ', e);
      return {} as GlobalState;
    }
  }

  private saveStates(states: GlobalState) {
    localStorage.setItem('app.states', JSON.stringify(states));
  }

  private updateStates(states: GlobalState) {
    this.currentStates.next({
      ...this.currentStates.value,
      ...states
    });
  }

  /**
   * Helpers
   */

  private datesEqual(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

}
