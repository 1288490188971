<as-split
  direction="vertical"
  unit="pixel"
  (dragEnd)="onDragEnd($event.sizes)"
  gutterSize="2"
  class="knowledge-split"
  [class.knowledge-split-with-contact]="selectedContact !== null">

  <!-- Topics -->
  <as-split-area
    [size]="splitAreaSize.topics"
    minSize="60"
    class="related-topics">
    <app-knowledge-header
      [title]="'Pellets'"
      [orders]="orderOptions.topics"
      [selected]="orders.topics"
      [showPaginationOrder]="orders.topics.key === 'name-asc' || orders.topics.key === 'name-desc'"
      (selectedChange)="selectOrder('topics',$event)"
      (selectedSymbol)="getOffsetByFirstSymbol('topics', $event)">
    </app-knowledge-header>
    <div class="knowledge-panel__content knowledge-panel__content--blue" #relatedTopicsContainer
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="10"
         [immediateCheck]="true"
         [scrollWindow]="false"
         (scrolledUp)="loadRelatedTopics(true, true)"
         (scrolled)="loadRelatedTopics(true, false)">
      <div class="related-topics__wrapper" [class.related-topics__wrapper__placeholder]="!relatedInfo?.topics?.data?.length && !loaders.topics && !errors.topics">
        <div class="loader" *ngIf="loaders.topicsTop"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
        <div *ngIf="errors.topicsTop" class="placeholder">Error occured while loading. <span (click)="loadRelatedTopics(true, true)" class="error">Click here to try again</span></div>
        <app-topic *ngFor="let topic of relatedInfo?.topics.data"
                   [topic]="topic"
                   [selected]="selectedRelatedTopics?.includes(topic)"
                   (click)="selectTopic(topic, $event)"
                   [topicStyle]="'related'">
        </app-topic>
        <div class="loader" *ngIf="loaders.topics"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
        <div *ngIf="!relatedInfo?.topics?.data?.length && !loaders.topics && !errors.topics" class="placeholder">
          <img class="logo" src="assets/placeholders/placeholder-pellets.svg"/>
          <div>No related topics</div>
        </div>
        <div *ngIf="errors.topics" class="placeholder">Error occured while loading. <span (click)="loadRelatedTopics(true, false)" class="error">Click here to try again.</span></div>
      </div>
    </div>
  </as-split-area>

  <!-- Connections -->
  <as-split-area [size]="splitAreaSize.contacts" minSize="60" class="related-connections">
    <app-knowledge-header
      [title]="'Сonnections'"
      [orders]="orderOptions.connections"
      [selected]="orders.connections"
      [showPaginationOrder]="orders.connections.key === 'name-asc' || orders.connections.key === 'name-desc'"
      (selectedChange)="selectOrder('connections',$event)"
      (selectedSymbol)="getOffsetByFirstSymbol('connections', $event)">
    </app-knowledge-header>
    <div class="knowledge-panel__content"
         #relatedConnectionsContainer
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="10"
         [immediateCheck]="true"
         [scrollWindow]="false"
         (scrolledUp)="loadRelatedConnections(true, true)"
         (scrolled)="loadRelatedConnections(true, false)">
      <div [ngClass]="{'related-connectiona__placeholder': !relatedInfo?.connections.data.length && !loaders.connections && !errors.connections}">
        <div class="loader" *ngIf="loaders.connectionsTop">
          <i class="fa fa-refresh fa-spin fa-fw"></i>
        </div>
        <div *ngIf="errors.connectionsTop" class="placeholder">Error occured while loading. <span (click)="loadRelatedConnections(true, true)" class="error">Click here to try again.</span></div>
        <div class="connection-list">
          <div *ngFor="let connection of relatedInfo?.connections?.data"
            (click)="selectConnection(connection, $event)"
            class="connection"
            [ngClass]="{'selected': selectedRelatedContacts.includes(connection)}"
            [appContactContextMenu]="connection"
            (loadInKnowledgePanel)="selectContact(connection)"
          >
            <app-user-avatar
              class="connection__avatar"
              size="28"
              [contact]="connection"
              [usePopover]="false">
            </app-user-avatar>
            <div class="connection__info">
              <div class="connection__name">{{connection.name}}</div>
              <div [ngClass]="{'connection__email': connection.name, 'connection__name': !connection.name}">{{connection.email}}</div>
            </div>
          </div>
        </div>
        <div class="loader" *ngIf="loaders.connections">
          <i class="fa fa-refresh fa-spin fa-fw"></i>
        </div>
        <div *ngIf="!relatedInfo?.connections.data.length && !loaders.connections && !errors.connections" class="placeholder">
          <img class="logo" src="assets/placeholders/placeholder-contacts.svg"/>
          <div>No related connections</div>
        </div>
        <div *ngIf="errors.connections" class="placeholder">Error occured while loading. <span (click)="loadRelatedConnections(true, false)" class="error">Click here to try again.</span></div>
      </div>
    </div>
  </as-split-area>

  <!-- Link info -->
  <as-split-area size="*" minSize="85" class="related-info">
    <app-knowledge-header
      [title]="'Information'"
      [orders]="orderOptions[reletedInfoTabs[reletedInfoSelectedTab]]"
      [selected]="orders[reletedInfoTabs[reletedInfoSelectedTab]]"
      (selectedChange)="selectOrder(reletedInfoTabs[reletedInfoSelectedTab],$event)">
    </app-knowledge-header>
    <div class="knowledge-box related-info__wrapper" [@.disabled]="true">
      <mat-tab-group
        [disableRipple]="true"
        class="related-info__tabs"
        [selectedIndex]="reletedInfoSelectedTab"
        (selectedTabChange)="selectRelatedDataTab($event)">
        <mat-tab label="Mail">
          <app-messages-list
            *ngIf="message?.id && baseTopics?.length"
            [header]="false"
          ></app-messages-list>
          <div *ngIf="!message?.id || !baseTopics?.length" class="placeholder">
            <img class="logo" src="assets/placeholders/placeholder-mail.svg"/>
            <div>There are no messages.</div>
          </div>
        </mat-tab>
        <mat-tab label="File">
          <app-related-files
            [files]="relatedInfo?.files['all']?.data"
            [totalCount]="relatedInfo?.files['all']?.count"
            [loading]="loaders.allFiles || loaders.images || loaders.videos || loaders.documents"
            [error]="errors.allFiles || errors.images || errors.videos || errors.documents"
            (loadMore)="loadRelatedFiles($event)">
          </app-related-files>
        </mat-tab>
        <mat-tab label="Link">
          <app-related-hyperlinks
            [hyperlinks]="relatedInfo?.hyperlinks?.data"
            [totalCount]="relatedInfo?.hyperlinks?.count"
            [loading]="loaders.hyperlinks"
            [error]="errors.hyperlinks"
            (loadMore)="loadRelatedHyperlinks()">
          </app-related-hyperlinks>
        </mat-tab>
      </mat-tab-group>
    </div>
  </as-split-area>
</as-split>
