/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./files-main-view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../file-form/file-form.component.ngfactory";
import * as i3 from "../file-form/file-form.component";
import * as i4 from "../../../pages/services/dynamic-panel.service";
import * as i5 from "../../../tasks/services/tasks.service";
import * as i6 from "../../../events/services/calendar.service";
import * as i7 from "../../../mail/services/mail.service";
import * as i8 from "../../../notes/services/notes.service";
import * as i9 from "@angular/common";
import * as i10 from "./files-main-view.component";
import * as i11 from "../../services/files-state.service";
import * as i12 from "../../../pages/services/layout-state.service";
var styles_FilesMainViewComponent = [i0.styles];
var RenderType_FilesMainViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilesMainViewComponent, data: {} });
export { RenderType_FilesMainViewComponent as RenderType_FilesMainViewComponent };
function View_FilesMainViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "empty-state-image"], ["src", "assets/placeholders/placeholder-tasks.svg"]], null, null, null, null, null))], null, null); }
function View_FilesMainViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-form", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FileFormComponent_0, i2.RenderType_FileFormComponent)), i1.ɵdid(1, 638976, null, 0, i3.FileFormComponent, [i4.DynamicPanelService, i5.TasksService, i6.CalendarService, i7.MailService, i8.NotesService], { file: [0, "file"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FilesMainViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesMainViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesMainViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "empty"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "file-form"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FilesMainViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-files-main-view", [], null, null, null, View_FilesMainViewComponent_0, RenderType_FilesMainViewComponent)), i1.ɵdid(1, 245760, null, 0, i10.FilesMainViewComponent, [i11.FilesStateService, i12.LayoutStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilesMainViewComponentNgFactory = i1.ɵccf("app-files-main-view", i10.FilesMainViewComponent, View_FilesMainViewComponent_Host_0, {}, {}, []);
export { FilesMainViewComponentNgFactory as FilesMainViewComponentNgFactory };
