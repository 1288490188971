<div
  *ngIf="group"
  class="group"
  [class.selected]="selected"
  [class.pinned]="group.pinned"
  (dblclick)="handleDoubleClick(group)"
  [appGroupContextMenu]="group"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'group'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="group-body">
    <div class="group-body-heading">
      <div class="group-body-heading-avatar">
        SB
      </div>

      <div class="group-body-heading-title">
        <div class="group-body-heading-title-text">
          {{group.name}}
        </div>
        <div class="group-body-heading-title-date">
          <div class="group-body-heading-title-date-item">
            Created: {{group.createdAt | date : 'MMM d'}}
          </div>
           <div class="group-body-heading-title-date-separator"></div>
          <div class="group-body-heading-title-date-item">
            Updated: {{group.createdAt | date : 'MMM d'}}
          </div>
        </div>
      </div>
    </div>

    <div class="group-body-description">
      {{group.description}}
    </div>

    <app-link-info-bar *ngIf="group.linkedInfo?.length" [linkedInfo]="group.linkedInfo"></app-link-info-bar>
  </div>

  <mat-icon class="group-pin-icon" *ngIf="group.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="group-starred-icon" *ngIf="group.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="group-marker" svgIcon="groups"></mat-icon>
  <mat-icon class="group-attachments-icon" svgIcon="attachment"></mat-icon>

  <div class="group-participants">
    <app-contacts-counter-dropdown
      [contactList]="group.participants"
      [size]="24"
      [minContactLen]="1"
    ></app-contacts-counter-dropdown>
  </div>

  <div class="group-hover-icons" *ngIf="dragEnabled && actionsButtonEnabled">
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="saved"
      [class.active]="group.starred"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="pin"
      [class.active]="group.pinned"
      [matTooltip]="group?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="crown"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="archive"
      [class.active]="group.archived"
      [matTooltip]="group?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="trash"
      [class.active]="group.deleted"
      [matTooltip]="group?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <!-- Drop zone -->
  <div class="drop-zone"></div>
</div>

<div class="placeholder" *ngIf="!group">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-group [group]="group" [dragEnabled]="false"></app-group>
</ng-template>
