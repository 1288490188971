<div class="container" *ngIf="!minimized">
  <div class="container-header">
    <app-knowledge-header
      [title]="currentSidebarFilter | getTitleBySidebarFilterKey : selectedSidebarProject"
      [orders]="orders"
      [actions]="scrollOptions"
      [selected]="selectedOrder"
      (selectedChange)="handleSelectOrder($event)"
      (actionClick)="handleScrollOptionChange($event)"
      [loading]="loading"
    ></app-knowledge-header>
  </div>

  <mat-tab-group *ngIf="listState === 'tabs'">
    <mat-tab label="Tasks">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="tasksListTemplate"></ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Projects">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="projectsListTemplate"></ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="listState === 'tasks'" [ngTemplateOutlet]="tasksListTemplate"></ng-container>
  <ng-container *ngIf="listState === 'projects'" [ngTemplateOutlet]="projectsListTemplate"></ng-container>
</div>

<!-- Template of tasks list -->
<ng-template #tasksListTemplate>
  <div class="container-quick-form">
    <div class="shadow"></div>

    <app-quick-form
      *ngIf="listState === 'tasks'"
      bundledGroup="tasksListQuickForm"
      placeholder="Add New Task"
      [toggle]="quickFormToggle"
      (collapsedChange)="quickFormExpanded = $event"
      (clickMore)="handleNewTask()"
      (itemDropped)="handleTaskQuickFormDrop($event)"
    >
      <app-task-quick-form
        bundledGroup="tasksListQuickForm"
        [task]="quickFormTask"
        (more)="handleNewTask($event)"
      ></app-task-quick-form>
    </app-quick-form>
  </div>

  <div class="shadow"></div>

  <div class="container-list">
    <app-tasks-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
      (loadInProgress)="loading = $event"
    ></app-tasks-list>
  </div>
</ng-template>

<!-- Template of projects list -->
<ng-template #projectsListTemplate>
  <div class="container-quick-form">
    <div class="shadow"></div>

    <app-quick-form
      *ngIf="listState === 'projects'"
      placeholder="Add New Project"
      bundledGroup="projectsListQuickForm"
      [toggle]="quickFormToggle"
      (collapsedChange)="quickFormExpanded = $event"
      (clickMore)="handleNewProject()"
      (itemDropped)="handleProjectQuickFormDrop($event)"
    >
      <app-project-quick-form
        bundledGroup="projectsListQuickForm"
        [project]="quickFormProject"
        (more)="handleNewProject($event)"
      ></app-project-quick-form>
    </app-quick-form>
  </div>

  <div class="shadow"></div>

  <div class="container-list">
    <app-projects-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
    ></app-projects-list>
  </div>
</ng-template>
