<div class="box">

  <div class="box-header with-border">
    <h3 class="box-title">Manage your account</h3>
  </div>

  <div class="box-body with-padding">
    <div class="alert alert-warning" role="alert">
      <strong>Warning!</strong> Account cancellation cannot be undone.
    </div>

    <p><button class="btn btn-primary" (click)="cancel()">Cancel account</button></p>

    <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
  </div>

</div>
