<context-menu #contextMenu>
  <ng-template contextMenuItem [enabled]="false">Open</ng-template>
  <ng-template contextMenuItem (execute)="pin([project])" [visible]="!project?.pinned">Pin</ng-template>
  <ng-template contextMenuItem (execute)="unpin([project])" [visible]="project?.pinned">Unpin</ng-template>
  <ng-template contextMenuItem [enabled]="false">Snooze All</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add New Task</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select All</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Copy to</ng-template>
  <ng-template contextMenuItem [enabled]="false">Move To Project</ng-template>
  <ng-template contextMenuItem [enabled]="false">Duplicate</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <!--  Stitch  -->
  <ng-template contextMenuItem [subMenu]="stitchMenu">Stitch</ng-template>
  <context-menu #stitchMenu>
    <ng-template contextMenuItem [subMenu]="stitchMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuTask">Task</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuNote">Note</ng-template>
  </context-menu>
  <context-menu #stitchMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions appearance="context-menu" [linkedInfo]="[{type: 'project', id: project.id}]" (close)="closeContextMenu()"></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions appearance="context-menu" [linkedInfo]="[{type: 'project', id: project.id}]" (close)="closeContextMenu()"></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuTask>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-task-suggestions appearance="context-menu" [linkedInfo]="[{type: 'project', id: project.id}]" (close)="closeContextMenu()"></app-link-info-task-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuNote>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-note-suggestions appearance="context-menu" [linkedInfo]="[{type: 'project', id: project.id}]" (close)="closeContextMenu()"></app-link-info-note-suggestions>
    </ng-template>
  </context-menu>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Add Threads</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Tags</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Print Project</ng-template>
  <!--  Rename Project  -->
  <ng-template contextMenuItem [subMenu]="renameProject">Rename Project</ng-template>
  <context-menu #renameProject>
    <ng-template contextMenuItem [passive]="true">
      <div class="rename-project-container">
        <div class="rename-project-container-header">
          <span>Rename Project</span>
          <mat-icon class="icon" svgIcon="close" (click)="handleRenameCancel()"></mat-icon>
        </div>
        <div class="rename-project-container-input">
          <app-input
            appearance="standard"
            icon="project"
            [placeholder]="project.title"
            [inputFormControl]="projectTitleFormControl"
          ></app-input>
        </div>
        <div class="rename-project-container-footer">
          <app-button
            class="rename-project-container-footer-item"
            (click)="handleRenameCancel()"
          >Cancel</app-button>
          <app-button
            class="rename-project-container-footer-item"
            appearance="blue"
            (click)="handleRenameSubmit()"
          >Save</app-button>
        </div>
      </div>
    </ng-template>
  </context-menu>

  <ng-template contextMenuItem (execute)="moveToArchive([project])" [visible]="!project?.archived">Archive</ng-template>
  <ng-template contextMenuItem (execute)="restoreFromArchive([project])" [visible]="project?.archived">Restore from Archive</ng-template>
  <ng-template contextMenuItem (execute)="delete([project])" [visible]="!project?.deleted">Trash</ng-template>
  <ng-template contextMenuItem (execute)="restoreFromTrash([project])" [visible]="project?.deleted">Restore from Trash</ng-template>
  <ng-template contextMenuItem (execute)="deletePermanently([project])" [visible]="project?.deleted">Delete Permanently</ng-template>
  <ng-template contextMenuItem [enabled]="false">Settings</ng-template>
</context-menu>
