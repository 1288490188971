<div
  class="color-picker-button"
  popover
  popoverPlacement="right"
  popoverTrigger="click"
  [popoverArrow]="false"
  [popoverContent]="colorPicker"
>
  <div class="selected-color" [ngStyle]="{ 'background-color': inputFormControl.value || defaultColor }"></div>
  <ng-template #colorPicker>
    <app-color-picker [inputFormControl]="inputFormControl"></app-color-picker>
  </ng-template>
</div>
