// Common
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { AccountSignature } from '@modules/account/types/account-signature';


@Component({
  selector: 'app-signature-selector',
  templateUrl: './signature-selector.component.html',
  styleUrls: ['./signature-selector.component.less']
})
export class SignatureSelectorComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() signature: string;

  // Outputs
  @Output() signatureChange = new EventEmitter<string>();

  // Public
  public signatures: AccountSignature[];
  public signaturesOptions: DropdownOption[];
  public signaturesSelectedOption: DropdownOption;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private accountService: AccountService,
    private modalService: ModalService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.accountService.getSignatures()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        catchError(() => of([]))
      )
      .subscribe(
        (signatures: AccountSignature[]) => {
          this.signatures = signatures;
          this.signaturesOptions = signatures.map(signature => ({ name: signature.title, key: signature.id }));

          if (!this.signature && !this.signaturesSelectedOption) {
            const defaultSignature = signatures.find(signature => signature.isDefault);
            if (defaultSignature) {
              this.signaturesSelectedOption = this.signaturesOptions.find(signature => signature.key === defaultSignature.id);
              this.signature = defaultSignature.content;
              this.signatureChange.emit(this.signature);
            }
          }
        }
      );
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectSignature(option: DropdownOption) {
    const signature = this.signatures.find(currentSignature => currentSignature.id === option.key);
    if (signature) {
      this.signature = signature.content;
      this.signatureChange.emit(this.signature);
    }
    this.signaturesSelectedOption = option;
  }

  addSignature() {
    this.modalService.settings('signatures');
  }

}
