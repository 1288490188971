/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circular-progress.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./circular-progress.component";
var styles_CircularProgressComponent = [i0.styles];
var RenderType_CircularProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CircularProgressComponent, data: {} });
export { RenderType_CircularProgressComponent as RenderType_CircularProgressComponent };
function View_CircularProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:path", [["d", "M11.5 13.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708l1.646 1.647z"]], null, null, null, null, null))], null, null); }
export function View_CircularProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "circular-progress"]], [[4, "width", "px"], [4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, ":svg:svg", [], [[1, "viewBox", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, ":svg:g", [["fill", "none"], ["fill-rule", "nonzero"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["class", "back"]], [[1, "r", 0], [1, "cx", 0], [1, "cy", 0], [1, "stroke-width", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["stroke-dashoffset", "0"]], [[1, "r", 0], [1, "cx", 0], [1, "cy", 0], [1, "stroke-width", 0], [1, "stroke-dasharray", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CircularProgressComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_12 = (_co.progress >= 100); _ck(_v, 6, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = _co.size; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ((("0 0 " + _co.viewBoxSize) + " ") + _co.viewBoxSize); _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.radius; var currVal_4 = (_co.viewBoxSize / 2); var currVal_5 = (_co.viewBoxSize / 2); var currVal_6 = 1.5; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.radius; var currVal_8 = (_co.viewBoxSize / 2); var currVal_9 = (_co.viewBoxSize / 2); var currVal_10 = 1.5; var currVal_11 = _co.strokeDashArray; _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_CircularProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-circular-progress", [], null, null, null, View_CircularProgressComponent_0, RenderType_CircularProgressComponent)), i1.ɵdid(1, 638976, null, 0, i3.CircularProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CircularProgressComponentNgFactory = i1.ɵccf("app-circular-progress", i3.CircularProgressComponent, View_CircularProgressComponent_Host_0, { progress: "progress", size: "size" }, {}, []);
export { CircularProgressComponentNgFactory as CircularProgressComponentNgFactory };
