// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MatTooltipModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { PopoverModule } from '@modules/popover/popover.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CalendarModule } from '@modules/calendar/calendar.module';
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DropdownModule } from '@modules/dropdown/dropdown.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';

// Components
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownSelectComponent } from './components/dropdown-select/dropdown-select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { CollapseableGroupComponent } from './components/collapseable-group/collapseable-group.component';
import {
  RepeaterDialogComponent
} from '@modules/form-controls/components/schedule-repater-dropdown/custom-repeater-dialog/custom-repeater-dialog.component';
import { ParticipantsInputComponent } from './components/participants-input/participants-input.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ScheduleRepeaterDropdownComponent } from './components/schedule-repater-dropdown/schedule-repeater-dropdown.component';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { AttachmentIconComponent } from './components/attachment-icon/attachment-icon.component';
import { TimeZoneSelectComponent } from './components/time-zone-select/time-zone-select.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPickerButtonComponent } from './components/color-picker-button/color-picker-button.component';
import { PriorityInputComponent } from './components/priority-input/priority-input.component';
// TODO it's not appropriate place for EventTitleInputComponent but it's using
// inside EventsModule and QuickFormsModule both
import { EventTitleInputComponent } from './components/event-title-input/event-title-input.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { QuickActionsFormComponent } from './components/quick-actions-form/quick-actions-form.component';
import {
  QuickActionsComponent
} from '@modules/form-controls/components/quick-actions/quick-actions.component';
import { TasksDatePickerComponent } from '@modules/form-controls/components/tasks-date-picker/tasks-date-picker.component';

// Pipes
import { CalendarColorPipe } from './pipes/calendar-color.pipe';
import { FileUploaderExpandAttachmentsPipe } from './pipes/file-uploader-expand-attachments.pipe';

// TODO FileSizePipe & AttachmentIconComponent are placed here as lesser-evil to avoid circular dependency. Find better place for those two.

@NgModule({
  imports: [
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    PopoverModule,
    UserAvatarModule,
    CalendarModule,
    BundledInputsModule,
    ColorPickerModule,
    DropdownModule,
    PipesModule,
    MatTooltipModule,
    DragNDropModule,
  ],
  declarations: [
    ButtonComponent,
    InputComponent,
    TagsInputComponent,
    DatePickerComponent,
    TimeInputComponent,
    CheckboxComponent,
    DropdownSelectComponent,
    TextareaComponent,
    CollapseableGroupComponent,
    ParticipantsInputComponent,
    FileUploaderComponent,
    RepeaterDialogComponent,
    TimePickerComponent,
    ScheduleRepeaterDropdownComponent,
    NotificationDropdownComponent,
    AttachmentIconComponent,
    TimeZoneSelectComponent,
    ColorPickerComponent,
    EventTitleInputComponent,
    PriorityInputComponent,
    ColorPickerButtonComponent,
    CalendarColorPipe,
    IconButtonComponent,
    DateTimeComponent,
    QuickActionsFormComponent,
    QuickActionsComponent,
    TasksDatePickerComponent,
    FileUploaderExpandAttachmentsPipe,
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    TagsInputComponent,
    DatePickerComponent,
    TimePickerComponent,
    CheckboxComponent,
    DropdownSelectComponent,
    TextareaComponent,
    CollapseableGroupComponent,
    ParticipantsInputComponent,
    FileUploaderComponent,
    AttachmentIconComponent,
    TimeZoneSelectComponent,
    ScheduleRepeaterDropdownComponent,
    NotificationDropdownComponent,
    ColorPickerComponent,
    ColorPickerButtonComponent,
    EventTitleInputComponent,
    PriorityInputComponent,
    CalendarColorPipe,
    IconButtonComponent,
    DateTimeComponent,
    QuickActionsFormComponent,
    QuickActionsComponent,
    TasksDatePickerComponent,
  ],
  providers: [],
  entryComponents: [
    RepeaterDialogComponent
  ]
})
export class FormControlsModule { }
