// Common
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';

@Component({
  selector: 'app-annotations-popup',
  templateUrl: './annotations-popup.component.html',
  styleUrls: ['./annotations-popup.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AnnotationsPopupComponent implements OnInit, OnDestroy {

  // Public
  public message: MailMessage;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(private route: ActivatedRoute, private mailService: MailService) {
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.componentNotDestroyed),
        filter(params => params.id),
        switchMap(params => this.mailService.getMessage(params.id))
      )
      .subscribe(message => this.message = message);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  close() {
    window.close();
  }

}
