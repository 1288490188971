// Common
import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Group } from '@modules/contacts/types/group';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';

@Component({
  selector: 'app-group-quick-form',
  templateUrl: './group-quick-form.component.html',
  styleUrls: ['./group-quick-form.component.less'],
})
export class GroupQuickFormComponent implements OnDestroy, OnChanges {

  // Inputs
  @Input() bundledGroup: string;
  @Input() group: Group;

  // Outputs
  @Output() more: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Group> = new EventEmitter();

  // Public
  public groupForm: FormGroup;
  public saveInProgress = false;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor (
    private groupsService: GroupsService,
    private contactsStateService: ContactsStateService,
  ) {
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('group' in changes) {
      this.reset();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (!this.groupForm.valid) {
      return;
    }

    if (this.save.observers.length > 0) {
      const contact = Group.fromFormGroup(this.groupForm);
      this.save.emit(contact);
      return;
    }

    this.saveInProgress = true;

    this.groupsService.upsert(this.groupForm)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleError() {
    this.saveInProgress = false;
  }

  reset() {
    if (!this.group) { return; }

    this.groupForm = this.group.asFormGroup();
    this.saveInProgress = false;
  }

  handleMoreOptions() {
    this.more.emit(Group.fromFormGroup(this.groupForm));
    this.close.emit();
  }

  handleClickCancel() {
    this.close.emit();
  }
}
