<context-menu #contextMenu>
  <ng-template contextMenuItem [visible]="!relatedContact.vip" (execute)="changeVipStatus(true)">
    Make VIP
  </ng-template>
  <ng-template contextMenuItem [visible]="relatedContact.vip" (execute)="changeVipStatus(false)">
    Remove VIP
  </ng-template>
  <ng-template contextMenuItem (execute)="copyEmail()">
    Copy Email Address
  </ng-template>
  <ng-template contextMenuItem (execute)="sendNewEmail()">
    Send New Email
  </ng-template>
  <ng-template contextMenuItem (execute)="openRelatedEmails()">
    All Emails for Connection
  </ng-template>
  <ng-template contextMenuItem (execute)="openRelatedTopics()">
    Go to Explore
  </ng-template>
  <ng-template [visible]="loadInKnowledgePanel.observers.length > 0" contextMenuItem (execute)="loadContactInKnowledgePanel()">
    Load in Knowledge Pane
  </ng-template>
</context-menu>
