<div class="calendar-month--wrapper" [@dateAnimation]="displayDate">

  <ng-template
    #dayTemplate
    let-day="day"
    let-openDay="openDay"
    let-locale="locale"
    let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay"
    let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDelay="tooltipDelay"
    let-trackByEventId="trackByEventId"
  >
    <div
      #cell
      class="full-height-flex"
      appDroppable
      appDroppableHoverClass="drag-over"
      (appDroppableDrop)="handleDrop($event, day.date, cell)"
      (appClick)="selectCalendarDate($event, day.date, cell)"
      (appDblClick)="dblClickCalendarDate(day.date)"
      [appCalendarCellContextMenu]="day.date"
      appCalendarCellContextMenuCalendarType="month"
      (appCalendarCellContextMenuLoadDayEvents)="handleLoadDayEvents(day.date)"
    >
      <div class="cal-cell-top">
        <span
          class="cal-day-number"
          (appClick)="handleLoadDayEvents(day.date)"
          (appDblClick)="dblClickDay(day.date)"
          [stopPropagation]="true"
        >
          {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
        </span>
      </div>
      <div class="cal-events" *ngIf="day.events.length > 0">
        <div class="cal-event" *ngFor="let eventItem of day.events; trackBy: trackByEventId">
          <ng-container *ngTemplateOutlet="badgeTemplate; context: { event: eventItem }"></ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <mwl-calendar-month-view
    [viewDate]="displayDate"
    [events]="events"
    [refresh]="refreshCalendar"
    [tooltipAppendToBody]="false"
    (beforeViewRender)="beforeMonthViewRender($event)"
    [cellTemplate]="dayTemplate"
    [weekStartsOn]="0"
  >
  </mwl-calendar-month-view>
</div>
