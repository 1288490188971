/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-color-select.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./dropdown-color-select.component";
var styles_DropdownColorSelectComponent = [i0.styles];
var RenderType_DropdownColorSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownColorSelectComponent, data: {} });
export { RenderType_DropdownColorSelectComponent as RenderType_DropdownColorSelectComponent };
function View_DropdownColorSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownColorSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "color-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "color"]], [[4, "background-color", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "color-item"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit === _co.rgb2hex(_co.selected))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
function View_DropdownColorSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "no-color"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removedColor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "eraser"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["None\n"]))], function (_ck, _v) { var currVal_2 = "eraser"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_DropdownColorSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null))], null, null); }
export function View_DropdownColorSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownColorSelectComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "colors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownColorSelectComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownColorSelectComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownColorSelectComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.options; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.removeColor == null) ? null : ((_co.removeColor.observers == null) ? null : _co.removeColor.observers.length)); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.divider; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_DropdownColorSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-color-select", [], null, null, null, View_DropdownColorSelectComponent_0, RenderType_DropdownColorSelectComponent)), i1.ɵdid(1, 114688, null, 0, i5.DropdownColorSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownColorSelectComponentNgFactory = i1.ɵccf("app-dropdown-color-select", i5.DropdownColorSelectComponent, View_DropdownColorSelectComponent_Host_0, { title: "title", selected: "selected", options: "options", divider: "divider" }, { selectedChange: "selectedChange", removeColor: "removeColor" }, []);
export { DropdownColorSelectComponentNgFactory as DropdownColorSelectComponentNgFactory };
