// Common
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { groupBy, sortBy, toInteger } from 'lodash';
import { FormControl } from '@angular/forms';

// RxJS
import { timer } from 'rxjs';

// Services
import { TopicService } from '../../../topic/services/topic.service';

// Types
import { DropdownOption } from '../../../dropdown/types/dropdown-option';
import { UserMailTopic } from '../../../topic/types/user-mail-topic';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

/**
 * Interfaces
 */
interface TopicsGroup {
  title: string;
  topics: UserMailTopic[];
}

@Component({
  selector: 'app-topics-list-modal',
  templateUrl: './topics-list-modal.component.html',
  styleUrls: ['./topics-list-modal.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class TopicsListModalComponent extends BaseModalComponent implements OnInit {

  // ViewChild
  @ViewChild('editTopicInput', { static: false }) editTopicInput: ElementRef;
  @ViewChild('invisibleText', { static: false }) invisibleText: ElementRef;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 300px)',
    y: 'calc(50% - 200px)',
    width: '600px',
    height: '400px'
  };

  // Public
  public topics: UserMailTopic[] = [];
  public editTopic: UserMailTopic;
  public editTopicInputText: string;
  public groupTopics: TopicsGroup[] = [];
  public search = new FormControl('');
  public selectedOrder: DropdownOption;
  public orders: DropdownOption[] = [
    {name: 'Name', key: 'name'},
    {name: 'Frequency', key: 'relevance'},
    {name: 'Date', key: 'date'},
  ];
  public loading = false;

  /**
   * Constructor
   */

  constructor(private topicService: TopicService) {
    super();
    this.selectedOrder = this.orders[0];
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
    this.loadTopics();
    this.search.valueChanges.subscribe((value: any) => {
      const filtredTopics = this.getFilteredTopics(value, this.topics);
      this.groupTopics = this.getGroupedTopics(filtredTopics, this.selectedOrder);
    });
  }

  /**
   * Actions
   */

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.groupTopics = this.getGroupedTopics(this.topics, order);
  }

  deleteTopic(topic: UserMailTopic) {
    this.topicService.deleteTopics([topic]).subscribe((success: boolean) => {
      if (success) {
        const index = this.topics.indexOf(topic);
        if (index !== -1) {
          this.topics.splice(index, 1);
          const filtredTopics = this.getFilteredTopics(this.search.value, this.topics);
          this.groupTopics = this.getGroupedTopics(filtredTopics, this.selectedOrder);
        }
      }
    });
  }

  startEditTopic(topic: UserMailTopic) {
    this.editTopic = topic;
    this.editTopicInputText = topic.name;
    this.changeEditTopic();
    timer(200).subscribe(() => {
      this.editTopicInput.nativeElement.focus();
      this.changeEditTopic();
    });
  }

  changeEditTopic() {
    setTimeout ( () => {
      this.editTopicInput.nativeElement.style.width = (this.invisibleText.nativeElement.offsetWidth + 2) + 'px';
    }, 0);
  }

  finishEditTopic(topic: UserMailTopic) {
    this.topicService.updateTopics(topic.name, this.editTopicInputText).subscribe((success: boolean) => {
      if (success) {
        topic.name = this.editTopicInputText;
      }
      this.editTopic = null;
    });
  }

  cancelEditTopic() {
    this.editTopic = null;
  }

  /**
   * Methods
   */

  loadTopics() {
    this.loading = true;
    this.topicService.getTopics({
      autodiscovered: true,
      limit: 200 // Temporary set up to 200 topics, should be replaced with proper pagination
    })
      .subscribe(({topics}) => {
        this.loading = false;
        this.topics = topics;
        const filtredTopics = this.getFilteredTopics(this.search.value, topics);
        this.groupTopics = this.getGroupedTopics(filtredTopics, this.selectedOrder);
      });
  }

  getFilteredTopics(searchText: string, topics: UserMailTopic[] = []): UserMailTopic[] {
    if (searchText && searchText.length) {
      return topics.filter(topic => topic.name.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      return topics;
    }
  }

  /**
   * Group topics
   */

  private getGroupedTopics(topics: UserMailTopic[], order: DropdownOption): TopicsGroup[] {
    let groupedTopics: TopicsGroup[] = [];
    switch (order.key) {
      case 'name':
        groupedTopics = this.groupByName(topics);
        break;
      case 'relevance':
        groupedTopics =  this.groupByFrequency(topics);
        break;
      case 'date':
        groupedTopics = this.groupByDate(topics);
        break;
    }
    return groupedTopics;
  }

  private groupByName(topics: UserMailTopic[]): TopicsGroup[] {
    const groupedObj = groupBy(topics, (topic: UserMailTopic) => topic.name[0].toUpperCase());
    const groupedTopics = Object.keys(groupedObj).map((key: string) => {
      return {
        title: key,
        topics: sortBy(groupedObj[key], topic => topic.name.toUpperCase())
      };
    });
    return groupedTopics.sort((groupA, groupB) => {
      return groupA.title.localeCompare(groupB.title);
    });
  }

  private groupByFrequency(topics: UserMailTopic[]): TopicsGroup[] {
    const groupedObj = groupBy(topics, (topic: UserMailTopic) => {
      if (topic.count < 10) {
        return 0;
      } else if (topic.count >= 10 && topic.count < 100) {
        return 10;
      } else if (topic.count >= 100 && topic.count < 1000) {
        return 100;
      } else {
        return 1000;
      }
    });
    return Object.keys(groupedObj)
      .sort((a, b) => +b - +a)
      .map((key: string) => {
        return {
          title: this.getGroupTitleToFrequencyGrouping(toInteger(key)),
          topics: sortBy(groupedObj[key], topic => topic.count)
        };
      });
  }

  private getGroupTitleToFrequencyGrouping(count: number): string {
    if (![0, 10, 100, 1000].includes(count)) {
      count = 0;
    }
    // TODO replace '< 10' to user-friendly text
    switch (count) {
      case 0:
        return 'up to 10';
      case 10:
        return '10+';
      case 100:
        return '100+';
      case 1000:
        return '1000+';
    }
  }

  private groupByDate(topics: UserMailTopic[]): TopicsGroup[] {
    const periods = {
      day: 1000 * 60 * 60 * 24,
      week: 1000 * 60 * 60 * 24 * 7,
      month: 1000 * 60 * 60 * 24 * 30
    };
    const sortRule = ['at last day', 'at last week', 'at last month', 'never'];
    const groupedObj = groupBy(topics, (topic: UserMailTopic) => {
      if (topic.date) {
        topic.date = new Date(topic.date);
        const period = Date.now() - topic.date.getTime();
        if (period < periods.day) {
          return 'at last day';
        } else if (period < periods.week) {
          return 'at last week';
        } else if (period < periods.month) {
          return 'at last month';
        } else {
          return 'never';
        }
      } else {
        return 'never';
      }
    });
    return Object.keys(groupedObj)
      .sort((a, b) => sortRule.indexOf(a) - sortRule.indexOf(b))
      .map((key: string) => {
        return {
          title: key,
          topics: sortBy(groupedObj[key], topic => topic.name.toUpperCase())
        };
      });
  }

}
