<div class="note-wrapper" *ngIf="note" (click)="openNote()">
  <div
    class="note"
    [class.short-view]="shortView"
    appDraggable
    [appDraggableEnabled]="dragEnabled"
    [appDraggableData]="{data: dragData, type: 'note'}"
    [appDraggablePlaceholder]="dragPlaceholder"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"

    [appNoteContextMenu]="note"
    (contextMenuOpened)="contextMenuOpened = $event"
  >
    <div class="note-icons">
      <mat-icon *ngIf="note.pinned" class="note-icons-small-icon pin" svgIcon="mail-pinned"></mat-icon>
      <mat-icon *ngIf="note.favorite" class="note-icons-small-icon star" svgIcon="starred"></mat-icon>
    </div>
    <div class="note-info">
      <div class="note-info-title">{{ note.title }}</div>

      <div class="note-info-date-time">
        <app-date-time
          [startDate]="note.createdAt"
          [endDate]="note.updatedAt"
          dateFormat="'Created:' MMM dd"
          endDateFormat="'Updated:' MMM dd"
          [withTime]="false"
        ></app-date-time>
      </div>

      <div class="note-info-description" [class.empty]="!note.snippet?.trim()">{{ note.snippet }}</div>

      <app-link-info-bar *ngIf="!shortView" [linkedInfo]="note.linkedInfo"></app-link-info-bar>
    </div>
    <div class="note-icons right">
      <div class="note-icons-hideable">
        <mat-icon class="note-icons-hideable-type-icon" svgIcon="note-small"></mat-icon>
        <mat-icon *ngIf="note.files?.length" class="note-icons-small-icon attachment" svgIcon="mail-attachment"></mat-icon>
        <mat-icon
          class="note-icons-small-icon unlink"
          *ngIf="shortView"
          svgIcon="unlink"
          matTooltip="Unlink"
          (click)="unlinkItem($event)"
        ></mat-icon>
      </div>

      <div class="note-icons-unlink-participants-container">
        <mat-icon
          class="note-icons-small-icon unlink"
          svgIcon="unlink"
          matTooltip="Unlink"
          (click)="unlinkItem($event)"
        ></mat-icon>
      </div>
    </div>

    <!--  Actions on hover  -->
    <div class="actions" *ngIf="actionsButtonEnabled" (click)="$event.stopPropagation(); $event.preventDefault()">
      <button
        class="button-icon-small btn-action"
        [class.selected]="note.favorite"
        (click)="favoriteNote($event)"
      >
        <mat-icon svgIcon="saved" ></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
        [class.selected]="note.pinned"
        (click)="pinNote($event)"
      >
        <mat-icon svgIcon="pin"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
        [class.selected]="note.snooze"
        (click)="snoozeNote($event)"
      >
        <mat-icon svgIcon="snooze"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
        [class.selected]="note.archived"
        (click)="archiveNote($event)"
      >
        <mat-icon svgIcon="archive"></mat-icon>
      </button>

      <button
        class="button-icon-small btn-action"
        [class.selected]="note.deleted"
        (click)="deleteNote($event)"
      >
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Note placeholder -->
<div class="note-wrapper placeholder" *ngIf="!note">
  <div class="note">
    <div class="note-icons">
      <div class="note-icons-placeholder"></div>
      <div class="note-icons-placeholder"></div>
    </div>
    <div class="note-info">
      <div class="note-info-title">
        <div class="note-icons-placeholder medium"></div>
        <div class="note-info-title-text"></div>
      </div>

      <div class="note-info-date-time">
        <div class="note-info-date-time-item"></div>
        <div class="note-info-date-time-item"></div>
      </div>

      <div class="note-info-description"></div>

      <div class="note-info-link-info-bar">
        <div class="note-icons-placeholder square"></div>
        <div class="note-icons-placeholder square"></div>
        <div class="note-icons-placeholder square"></div>
        <div class="note-icons-placeholder square"></div>
      </div>
    </div>
    <div class="note-icons right">
      <div class="note-icons-placeholder square"></div>
      <div class="note-icons-placeholder"></div>
      <div class="note-icons-placeholder big"></div>
    </div>
  </div>
</div>

<!-- Drag note placeholder -->
<ng-template #dragPlaceholder>
  <app-link-info-note
    [note]="note"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [shortView]="shortView"
  ></app-link-info-note>
</ng-template>
