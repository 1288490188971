// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// Services
import { PopupService } from '../../../popup/services/popup.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';

// Types
import { MailMessage } from '../../../mail/types/mail-message';
import { Recipient } from '../../../mail/types/recipient';
import { Topic } from '@modules/topic/types/topic';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { ModalFrame } from '@modules/modal/types/modal-frame';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

@Component({
  selector: 'app-mail-content-modal',
  templateUrl: './mail-content-modal.component.html',
  styleUrls: ['./mail-content-modal.component.less'],
})
export class MailContentModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() message: MailMessage;
  // TODO - do we need availableExpand ?
  @Input() availableExpand = true;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 500px)',
    y: '40px',
    width: '1000px',
    height: 'calc(100% - 100px)'
  };

  // Public
  public selectedTopics: string[];
  public selectedContact: Recipient;
  public selectedRelatedContacts: Recipient[] = [];
  public selectedRelatedTopics: string[] = [];
  public selectedTemporalExpressions: TemporalExpression[] = [];
  public visibleTopics: Topic[];
  public onReplayMessageCallback = this.onReplayMessage.bind(this);

  /**
   * Constructor
   */

  constructor(
    protected element: ElementRef,
    protected popupService: PopupService,
    protected ga: GoogleAnalyticsService,
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  getModalContent(): HTMLElement {
    const modalContent = this.element.nativeElement.parentElement;
    return modalContent.parentElement;
  }


  onReplayMessage(): ModalFrame {
    const modalContent = this.getModalContent();
    const frame = {
      width: `${modalContent.offsetWidth}px`,
      height: `${modalContent.offsetHeight}px`,
      x: `${modalContent.offsetLeft}px`,
      y: `${modalContent.offsetTop}px`
    };
    this.close();
    return frame;
  }

  selectTopics(topics: Topic[]) {
    this.selectedTopics = topics ? topics.map(topic => topic.name) : [];
  }

  expand() {
    this.ga.trackEvent('mail-content-modal', 'expand');
    this.popupService.mailContent(this.message.id, this.maximizedSize.popupSize());
    this.close();
  }
}
