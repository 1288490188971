<app-knowledge-header
  title="Notes"
  [orders]="orders"
  [actions]="scrollOptions"
  [selected]="selectedOrder"
  (selectedChange)="selectOrder($event)"
  (actionClick)="selectScrollOption($event)"
  (searchQueryChange)="handleSearchQueryChange($event)"
  [search]="true"
  [loading]="loading"
></app-knowledge-header>
