// Common
import { Component, ViewEncapsulation, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { KnowledgeGraphService } from '../../../mail/services/knowledge-graph.service';
import { StateService } from './../../../settings/services/state.service';
import { ModalService } from '../../../modal/services/modal.service';

// Types
import { TopicsList } from '@modules/topic/types/topic';
import { Recipient } from '../../../mail/types/recipient';
import { DropdownOption } from '../../../dropdown/types/dropdown-option';
import { MailFolder } from '../../../mail/types/mail-folder';

@Component({
  selector: 'app-insights-topic-map',
  templateUrl: './insights-topic-map.component.html',
  styleUrls: ['./insights-topic-map.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class InsightsTopicMapComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() folder: string;
  @Input() filterFolderDetails: MailFolder;
  @Input() topics: string[];
  @Input() contacts: string[];
  @Input() keywords: string[];
  @Input() relatedContact: Recipient;

  // Outputs
  @Output() selectedTopics: EventEmitter<string[]> = new EventEmitter();
  @Output() changedSelectedFolder: EventEmitter<string> = new EventEmitter();
  @Output() changedSelectDate: EventEmitter<{startDate: Date, endDate: Date}> = new EventEmitter();
  @Output() changedSelectDateDescription: EventEmitter<string> = new EventEmitter();

  // Public
  public view: 'cloud'|'list';
  public topicsFirstSymbol: string;
  public mapTopics: TopicsList;
  public topicsOrderOptions = [
    {name: 'Relevance', key: 'relevance'},
    {name: 'A-Z', key: 'name-asc'},
    {name: 'Z-A', key: 'name-desc'},
  ];
  public paginationModel = [
    ['a', 'b', 'c', 'd', 'e'],
    ['f', 'g', 'h', 'i', 'j'],
    ['k', 'l', 'm', 'n'],
    ['o', 'p', 'q', 'r'],
    ['s', 't', 'u', 'v'],
    ['w', 'x', 'y', 'z'],
    ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  ];
  public selectedTopicsOrder: DropdownOption;
  public selectedTopicsLimit: {
    name: string, key: number
  };
  public mapTopicsLimit = [
    {name: '20', key: 20},
    {name: '40', key: 40},
    {name: '60', key: 60}
  ];
  public selectedDate: Date;
  public selectedDateTo: Date;
  public selectedMapTopics: string[] = [];
  public selectedSimilarTopic: string[] = [];
  public selectedFilter: {
    name: string, key: number | string, toDate: Date, fromDate: Date
  };
  public filters = [
    {name: 'Last Hour',    key: 60 * 60},
    {name: 'Last 3 Hours', key: 60 * 60 * 3},
    {name: 'Last 5 Hours', key: 60 * 60 * 5},
    {name: 'Today',        key: 'day'},
    {name: 'This Week',    key: 60 * 60 * 24 * 7},
    {name: 'This Month',   key: 60 * 60 * 24 * 30},
    {name: 'Three Months', key: 60 * 60 * 24 * 90},
    {name: 'This Year',    key: 60 * 60 * 24 * 365},
    {name: 'Custom Date', key: 'custom'}
  ];
  public topicsMapping = [
    [53, 49, 54],
    [41, 37, 29, 25, 30, 38, 42],
    [45, 21, 13, 9, 14, 22, 46],
    [57, 33, 17, 5, 1, 6, 18, 34, 58],
    [51, 27, 11, 3, 0, 4, 12, 28, 52],
    [59, 35, 19, 7, 2, 8, 20, 36],
    [47, 23, 15, 10, 16, 24, 48],
    [43, 39, 31, 26, 32, 40, 44],
    [55, 50, 56]
  ];
  public loading = false;
  public hoveredTopic = { row : null, index: null };
  public highlightedTopics: string[];

  // Private
  private topicsParamsUpdated: Subject<void> = new Subject();
  private componentNotDestroyed: Subject<void> = new Subject();
  private initialized = false;

  /**
   * Constructor
   */

  constructor(
    private knowledgeGraphService: KnowledgeGraphService,
    private stateService: StateService,
    private modalService: ModalService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.selectedFilter = this.stateService.filterTopicMapDate;
    this.selectedTopicsLimit = this.stateService.filterTopicMapCount;
    this.selectedTopicsOrder = this.stateService.sortTopicMap;
    this.topicsFirstSymbol = this.stateService.filterTopicMapFirstSymbol;
    this.view = this.stateService.selectedTopicMapView;
    this.loadTopics();
    this.changedSelectDateDescription.emit(this.selectedFilter.name);
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const keys = Object.keys(changes);

    if (keys.includes('relatedContact')) {
      this.loadHighlightedTopics();

      // We dont need to load anything else if we have only thsese changes
      if (keys.length === 1) {
        return;
      }
    }

    if (this.initialized) {
      this.selectTopic(null);
      this.loadTopics();
    }

  }

  ngOnDestroy() {
    this.topicsParamsUpdated.next();
    this.topicsParamsUpdated.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  loadTopics(more = false) {
    if (this.loading && more) {
      return;
    }
    if (!more) {
      this.mapTopics = { data: [], count: 0 };
    }
    this.loading = true;
    this.topicsParamsUpdated.next();
    this.knowledgeGraphService
      .getRelatedTopics(
        {
          folder: this.folder,
          topics: this.topics,
          contacts: this.contacts,
          keywords: this.keywords,
          fromTime: !more ? this.updateSelectedDate() : this.selectedDate,
          toTime: this.selectedFilter.toDate,
          topicsFirstSymbol: this.view === 'list' ? this.topicsFirstSymbol : null
        },
        this.view === 'cloud' ? this.selectedTopicsLimit.key : 100,
        this.mapTopics && this.mapTopics.data ? this.mapTopics.data.length : 0,
        this.view === 'list' ? this.selectedTopicsOrder.key : 'relevance'

      )
      .pipe(
        takeUntil(this.topicsParamsUpdated)
      )
      .subscribe((topics: TopicsList) => {
        this.loading = false;
        this.mapTopics = {
          data: []
            .concat(
              more && this.mapTopics ? this.mapTopics.data : [],
              this.view === 'cloud' ? this.topics || [] : [],
              topics.data || []
            )
            .slice(0, this.view === 'cloud' ? this.selectedTopicsLimit.key : undefined),
          count: topics.count
        };
      });
  }

  loadHighlightedTopics() {
    if (!this.relatedContact) {
      this.highlightedTopics = [];
      return;
    }

    this.knowledgeGraphService
      .getRelatedTopics(
        {
          folder: this.folder,
          topics: this.topics,
          contact: this.relatedContact,
          keywords: this.keywords,
          topicsFirstSymbol: this.view === 'list' ? this.topicsFirstSymbol : null,
          // This method shouldn't update the time, as it have to request data for the same timeframe
          fromTime: this.selectedDate
        },
        this.view === 'cloud' ? this.selectedTopicsLimit.key : 100,
        0,
        this.view === 'list' ? this.selectedTopicsOrder.key : 'relevance'

      )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((topics: TopicsList) => {
        this.loading = false;
        this.highlightedTopics = topics.data;
      });
  }

  updateSelectedDate(force = false): Date {
    let fromDate = new Date();
    if (this.selectedFilter.key !== 'custom') {
      const now = new Date();
      if (this.selectedFilter.key === 'day') {
        now.setHours(0, 0, 0, 0);
      } else {
        now.setSeconds(-this.selectedFilter.key, 0);
      }
      // Set the treshold for date update to 5mins
      if (!force && this.selectedDate && (now.getTime() - this.selectedDate.getTime()) < 300000) {
        return this.selectedDate;
      }
      fromDate = now;
    } else if (this.selectedFilter.toDate && this.selectedFilter.fromDate) {
      fromDate = this.selectedFilter.fromDate;
    }
    this.selectedDate = fromDate;
    this.selectedDateTo = this.selectedFilter.toDate;
    this.changedSelectDate.emit({startDate: this.selectedDate, endDate: this.selectedDateTo});
    return fromDate;
  }

  selectFilter(filter: { name: string, key: number | string }) {
    if (filter.key === 'custom') {
      // @TODO: add custom datetime range picker here
    } else {
      this.updateFilter({name: filter.name, key: filter.key, toDate: null, fromDate: null});
    }
  }

  private updateFilter(filter: { name: string, key: number | string, fromDate: Date, toDate: Date }) {
    this.selectedFilter = filter;
    this.stateService.filterTopicMapDate = filter;
    this.updateSelectedDate(true);
    this.loadTopics();
    this.changedSelectDateDescription.emit(this.selectedFilter.name);
  }

  selectTopicsLimit(topicsLimit: {name: string, key: number}) {
    this.selectedTopicsLimit = topicsLimit;
    this.stateService.filterTopicMapCount = topicsLimit;
    this.loadTopics();
  }

  selectTopicsOrder(topicsOrder: DropdownOption) {
    this.selectedTopicsOrder = topicsOrder;
    this.stateService.sortTopicMap = topicsOrder;
    this.loadTopics();
  }

  selectFirstSymbol(firstSymbol: string) {
    this.topicsFirstSymbol = firstSymbol;
    this.stateService.filterTopicMapFirstSymbol = firstSymbol;
    this.loadTopics();
  }

  selectTopic(topic: string, event?: MouseEvent) {
    if ( topic ) {
      if (!event || !event.shiftKey) {
        if (this.selectedMapTopics.length === 1 && this.selectedMapTopics[0] === topic) {
          this.selectedMapTopics = [];
        } else {
          this.selectedMapTopics = [topic];
        }
      } else {
        if (this.selectedMapTopics.includes(topic)) {
          this.selectedMapTopics = this.selectedMapTopics.filter(t => t !== topic);
        } else {
          this.selectedMapTopics = this.selectedMapTopics.concat(topic);
        }
      }
    } else {
      this.selectedMapTopics = [];
    }

    this.selectedSimilarTopic = null;
    this.selectedTopicsChanged();
  }

  selectSimilarTopic(topics: string[]) {
    this.selectedSimilarTopic = topics;
    this.selectedTopicsChanged();
  }

  selectedTopicsChanged() {
    if (!this.selectedMapTopics && !this.selectedSimilarTopic) {
      return this.selectedTopics.emit(null);
    }
    const topics = [].concat(
      this.selectedMapTopics || [],
      this.selectedSimilarTopic || []
    );
    this.selectedTopics.emit(topics);
  }

  selectedFolderChanged(folder: string) {
    this.changedSelectedFolder.emit(folder);
  }

  onTopicMouseEnter(row: number, index: number) {
    this.hoveredTopic = { row, index };
  }

  onTopicMouseLeave() {
    this.hoveredTopic = {
      row: null,
      index: null,
    };
  }

  changeTopicsView() {
    this.view = this.view === 'cloud' ? 'list' : 'cloud';
    this.stateService.selectedTopicMapView = this.view;
    this.loadTopics();
  }
}
