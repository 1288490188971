/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-form.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../form-controls/components/input/input.component.ngfactory";
import * as i4 from "../../../form-controls/components/input/input.component";
import * as i5 from "../../../form-controls/components/time-zone-select/time-zone-select.component.ngfactory";
import * as i6 from "../../../form-controls/components/time-zone-select/time-zone-select.component";
import * as i7 from "../../../form-controls/components/button/button.component.ngfactory";
import * as i8 from "../../../form-controls/components/button/button.component";
import * as i9 from "./calendar-form.component";
var styles_CalendarFormComponent = [i0.styles];
var RenderType_CalendarFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarFormComponent, data: {} });
export { RenderType_CalendarFormComponent as RenderType_CalendarFormComponent };
export function View_CalendarFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "calendar-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.handleSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "calendar-form-horizontal-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "calendar-form-horizontal-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-input", [["width", "265px"]], null, null, null, i3.View_InputComponent_0, i3.RenderType_InputComponent)), i1.ɵdid(9, 49152, null, 0, i4.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "calendar-form-horizontal-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "calendar-form-horizontal-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-time-zone-select", [["placeholder", "Time zone"], ["width", "265px"]], null, null, null, i5.View_TimeZoneSelectComponent_0, i5.RenderType_TimeZoneSelectComponent)), i1.ɵdid(13, 770048, null, 0, i6.TimeZoneSelectComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "calendar-form-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "app-button", [["class", "calendar-form-buttons-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i8.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "app-button", [["appearance", "blue"], ["class", "calendar-form-buttons-item"], ["type", "submit"]], null, null, null, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(19, 49152, null, 0, i8.ButtonComponent, [i1.ElementRef], { type: [0, "type"], appearance: [1, "appearance"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.calendarForm; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.calendarForm.controls["name"]; var currVal_9 = (_co.calendarForm.get("id").value ? "Rename Calendar" : "New Calendar"); var currVal_10 = "265px"; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.calendarForm.controls["timeZone"]; var currVal_12 = "Time zone"; var currVal_13 = "265px"; _ck(_v, 13, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = "submit"; var currVal_15 = "blue"; _ck(_v, 19, 0, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CalendarFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-form", [], null, null, null, View_CalendarFormComponent_0, RenderType_CalendarFormComponent)), i1.ɵdid(1, 638976, null, 0, i9.CalendarFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarFormComponentNgFactory = i1.ɵccf("app-calendar-form", i9.CalendarFormComponent, View_CalendarFormComponent_Host_0, { calendar: "calendar" }, { close: "close" }, []);
export { CalendarFormComponentNgFactory as CalendarFormComponentNgFactory };
