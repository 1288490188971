/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linked-info-note-list.component.less.shim.ngstyle";
import * as i1 from "../../../styles/link-info-popover.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../note/note.component.ngfactory";
import * as i4 from "../../note/note.component";
import * as i5 from "../../../../notes/services/notes.service";
import * as i6 from "../../../../linked-info/services/linked-info.service";
import * as i7 from "../../../../topic/services/topic.service";
import * as i8 from "../../../../tag/services/tag.service";
import * as i9 from "../../../../modal/services/modal.service";
import * as i10 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "@angular/common";
import * as i13 from "./linked-info-note-list.component";
var styles_LinkedInfoNoteListComponent = [i0.styles, i1.styles];
var RenderType_LinkedInfoNoteListComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_LinkedInfoNoteListComponent, data: {} });
export { RenderType_LinkedInfoNoteListComponent as RenderType_LinkedInfoNoteListComponent };
function View_LinkedInfoNoteListComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-note", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NoteComponent_0, i3.RenderType_NoteComponent)), i2.ɵdid(1, 704512, null, 0, i4.NoteComponent, [i5.NotesService, i6.LinkedInfoService, i7.TopicService, i8.TagService, i9.ModalService, i2.ChangeDetectorRef], { note: [0, "note"], actionsButtonEnabled: [1, "actionsButtonEnabled"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LinkedInfoNoteListComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-fw"]], null, null, null, null, null))], null, null); }
export function View_LinkedInfoNoteListComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { scrollList: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 10, "div", [["class", "linked-info-popover"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "header"]], [[2, "shadow", null]], null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Notes"])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "close-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i2.ɵdid(6, 9158656, null, 0, i11.MatIcon, [i2.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(7, 0, [[1, 0], ["scrollList", 1]], null, 4, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LinkedInfoNoteListComponent_1)), i2.ɵdid(9, 278528, null, 0, i12.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LinkedInfoNoteListComponent_2)), i2.ɵdid(11, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "close"; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.items; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeaderShadow; _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵnov(_v, 6).inline; var currVal_2 = (((i2.ɵnov(_v, 6).color !== "primary") && (i2.ɵnov(_v, 6).color !== "accent")) && (i2.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_LinkedInfoNoteListComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-linked-info-note-list", [], null, null, null, View_LinkedInfoNoteListComponent_0, RenderType_LinkedInfoNoteListComponent)), i2.ɵdid(1, 4964352, null, 0, i13.LinkedInfoNoteListComponent, [i9.ModalService, i5.NotesService, i2.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkedInfoNoteListComponentNgFactory = i2.ɵccf("app-linked-info-note-list", i13.LinkedInfoNoteListComponent, View_LinkedInfoNoteListComponent_Host_0, { linkedInfo: "linkedInfo" }, { close: "close" }, []);
export { LinkedInfoNoteListComponentNgFactory as LinkedInfoNoteListComponentNgFactory };
