import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';
var LinkInfoNoteSuggestionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoNoteSuggestionsComponent, _super);
    /**
     * Constructor
     */
    function LinkInfoNoteSuggestionsComponent(modalService, notesService, linkedInfoService) {
        var _this = _super.call(this, linkedInfoService) || this;
        _this.modalService = modalService;
        _this.notesService = notesService;
        _this.linkedInfoService = linkedInfoService;
        return _this;
    }
    /**
     * Methods
     */
    //  Override method LinkInfoItemSuggestions
    LinkInfoNoteSuggestionsComponent.prototype.getSuggestions = function (inputValue) {
        if (inputValue && inputValue.length) {
            return this.notesService.getNotes({
                keywords: [inputValue],
                order: 'created-asc',
                limit: 10,
                offset: 0
            })
                .pipe(takeUntil(this.componentNotDestroyed), map(function (_a) {
                var notes = _a.notes;
                return notes || [];
            }));
        }
        return of([]);
    };
    //  Override method LinkInfoItemSuggestions
    LinkInfoNoteSuggestionsComponent.prototype.openCreateModal = function () {
    };
    return LinkInfoNoteSuggestionsComponent;
}(LinkInfoItemSuggestions));
export { LinkInfoNoteSuggestionsComponent };
