import { Component, Input, OnInit } from '@angular/core';
import { BaseModalComponent } from '@modules/modal/components/base-modal/base-modal.component';
import { Attachment } from '@modules/form-controls/types/attachment';
import { AbstractControl } from '@angular/forms';
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-attachments-list-modal',
  templateUrl: './attachments-list-modal.component.html',
  styleUrls: ['./attachments-list-modal.component.less']
})
export class AttachmentsListModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() attachments: Attachment[];
  @Input() attachmentsFormControl: AbstractControl;

  // Override
  public defaultSize: ModalFrame = {
    width: '291px',
    height: '100%'
  };
}
