// Common
import { Injectable } from '@angular/core';

// RxJS
import { Observable, BehaviorSubject } from 'rxjs';

// Types
import { DynamicPanelContent, DynamicPanelType, DynamicPanelData } from '../types/dynamic-panel-data';

@Injectable({
  providedIn: 'root'
})
export class DynamicPanelService {

  // Private
  private content = new BehaviorSubject<DynamicPanelContent>({type: 'kp'});

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Methods
   */

  setContent(type?: DynamicPanelType, data?: DynamicPanelData) {
    this.content.next({type: type || 'kp', data});
  }

  getContent(): Observable<DynamicPanelContent> {
    return this.content.asObservable();
  }

}
