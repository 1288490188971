// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

// RxJS
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.less', '../../styles/input.less'],
})
export class TimePickerComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputAppearance: 'start' | 'end';
  @Input() bundledInputConsumerGroup: string;
  @Input() optionsFromTime: Date;
  @Input() bundledInputInvisible = false;

  // Public
  public dropdownOptions: {
    value: Date,
    halfAnHourDelta: number,
    difference: string
  }[] = [];
  public popoverCustomTrigger = new Subject();
  public popoverHide = new Subject();

  // Private
  private componentNotDestroyed = new Subject();

  // View Children
  @ViewChild('dropdown', {static: false}) dropdown: ElementRef;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.generateOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('optionsFromTime' in changes) {
      this.generateOptions();
    }
  }

  ngOnDestroy() {
    this.popoverCustomTrigger.complete();
    this.popoverHide.next();
    this.popoverHide.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleOptionClick(date: Date) {
    this.inputFormControl.patchValue(date);
    this.popoverHide.next();
  }

  handlePopoverVisible(popoverVisible: boolean) {
    if (popoverVisible) {
      // popover ViewChild is not instantiated yet
      timer(0).subscribe(() => this.scrollToHour());
    }
  }

  /**
   * Helpers
   */

  private scrollToHour() {
    if (
      !this.inputFormControl ||
      !this.inputFormControl.value
    ) {
      return;
    }

    const inputValue = this.inputFormControl.value.getTime();

    const closestOptionIndex = this.dropdownOptions.reduce((closest, option, index) => (
      Math.abs(this.dropdownOptions[index].value.getTime() - inputValue) >
      Math.abs(this.dropdownOptions[closest].value.getTime() - inputValue) ?
      closest : index
    ), 0);

    this.dropdown.nativeElement.scrollTo(0, closestOptionIndex * 25);
  }

  private generateOptions() {
    this.dropdownOptions = [];

    const today = new Date();
    const from = this.optionsFromTime && new Date(
      today.getFullYear(), today.getMonth(), today.getDate(), this.optionsFromTime.getHours(), this.optionsFromTime.getMinutes()
    );
    const hourDeltaSufixes = ['(0 mins)', '(30 mins)', '(1 hr)'];

    for (let halfAnHour = 0; halfAnHour < 48; halfAnHour++) {
      const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), halfAnHour / 2, halfAnHour % 2 ? 30 : 0, 0);
      const halfAnHourDelta = from && Math.round((date.getTime() - from.getTime()) / 1800000);

      if (this.optionsFromTime && halfAnHourDelta <= 0) {
        continue;
      }

      this.dropdownOptions.push({
        value: date,
        halfAnHourDelta,
        difference: (this.optionsFromTime && halfAnHourDelta)
          ? hourDeltaSufixes[halfAnHourDelta] || `(${(halfAnHourDelta / 2)} hrs)`
          : ''
      });
    }
  }
}
