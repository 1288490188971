<div class="modal-header">
  <div class="modal-title" *ngIf="!minimized">
    {{ message?.from?.name ? message?.from?.name : message?.from?.email }}
  </div>
  <div class="modal-title" *ngIf="minimized">
    <mat-icon svgIcon="email" class="modal-title-icon"></mat-icon>
    <div class="message-modal-title">{{ (message?.from?.name ? message?.from?.name : message?.from?.email) + ': ' + message?.subject }}</div>
  </div>
  <button *ngIf="availableExpand" type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button *ngIf="availableExpand" type="button" class="button-modal" aria-label="In new window" (click)="expand()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body" *ngIf="message">
  <app-split-view 
    collapseableSide="right"
    key="knowledgePanelModal"
    [minWidth]="50"
    [magneticWidth]="200"
  >
    <app-message-content
      split-left-pane
      [message]="message"
      [(contact)]="selectedContact"
      (topicsSelected)="selectTopics($event)"
      [relatedContacts]="selectedRelatedContacts"
      [relatedTopics]="selectedRelatedTopics"
      (temporalExpressionsSelected)="selectedTemporalExpressions = $event"
      (visibleTopicsChange)="visibleTopics = $event"
      [composeModalFrameCallback]="onReplayMessageCallback"
    ></app-message-content>

    <div split-right-pane class="knowledge-panel__wrapper">
      <app-knowledge-panel
        class="modal-mail-knowledge"
        [selectedMessages]="[message]"
        [(selectedContact)]="selectedContact"
        [selectedTopics]="selectedTopics"
        [(selectedRelatedContacts)]="selectedRelatedContacts"
        [(selectedRelatedTopics)]="selectedRelatedTopics"
        [selectedTemporalExpressions]="selectedTemporalExpressions"
        [selectedMessagesVisibleTopics]="visibleTopics"
        splitViewKey="knowledgePanelModal"
      ></app-knowledge-panel>
    </div>
  </app-split-view>
</div>
