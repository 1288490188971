/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i3 from "ngx-contextmenu";
import * as i4 from "./context-menu.component";
var styles_ContextMenuComponent = [i0.styles];
var RenderType_ContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContextMenuComponent, data: {} });
export { RenderType_ContextMenuComponent as RenderType_ContextMenuComponent };
export function View_ContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "context-menu", [], null, null, null, i2.View_ContextMenuComponent_0, i2.RenderType_ContextMenuComponent)), i1.ɵdid(2, 180224, [[1, 4], ["contextMenu", 4]], 1, i3.ContextMenuComponent, [i3.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i3.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 })], null, null); }
export function View_ContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-context-menu", [], null, null, null, View_ContextMenuComponent_0, RenderType_ContextMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i4.ContextMenuComponent, [i3.ContextMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContextMenuComponentNgFactory = i1.ɵccf("app-context-menu", i4.ContextMenuComponent, View_ContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem" }, { close: "close" }, []);
export { ContextMenuComponentNgFactory as ContextMenuComponentNgFactory };
