import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarWeekViewComponent } from 'angular-calendar';
var CustomizedCalendarWeekComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomizedCalendarWeekComponent, _super);
    function CustomizedCalendarWeekComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dayEventLimit = 3;
        _this.allDayEventAmountLimit = _this.dayEventLimit;
        return _this;
    }
    CustomizedCalendarWeekComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (changes.highlightedDay && this.highlightedDay) {
            this.scrollToSelectedHour(this.highlightedDay);
        }
    };
    CustomizedCalendarWeekComponent.prototype.ngAfterViewInit = function () {
        this.scrollToSelectedHour(new Date);
    };
    CustomizedCalendarWeekComponent.prototype.toggleEventLimit = function () {
        this.allDayEventAmountLimit = this.allDayEventAmountLimit ? undefined : this.dayEventLimit;
    };
    CustomizedCalendarWeekComponent.prototype.scrollToSelectedHour = function (date) {
        this.dayRowsContainer.nativeElement.scrollTop = date.getHours() * (this.hourSegmentHeight * this.hourSegments);
    };
    return CustomizedCalendarWeekComponent;
}(CalendarWeekViewComponent));
export { CustomizedCalendarWeekComponent };
