<div class="note-form">
  <form [formGroup]="noteForm" (ngSubmit)="handleSubmit()">

    <div class="note-form-horizontal-group">
      <div class="note-form-horizontal-group-full-width-item">
        <app-input 
          placeholder="Title" 
          [inputFormControl]="noteForm.controls['title']"
          appearance="standard"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['title']"
        ></app-input>
      </div>
    </div>

    <div class="note-form-buttons">
      <app-button
        class="note-form-buttons-item"
        (click)="handleMoreOptions()"
      >
        More Options
      </app-button>

      <app-button 
        class="note-form-buttons-item"
        type="submit"
        appearance="blue"
        [disabled]="saveInProgress"
      >
        Add Note
      </app-button>
    </div>
     
  </form>
</div>
