import { Component, Input, OnInit } from '@angular/core';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Attachment } from '@modules/form-controls/types/attachment';


@Component({
  selector: 'event-popover-preview',
  templateUrl: './event-popover-preview.component.html',
  styleUrls: ['./event-popover-preview.component.less']
})
export class EventPopoverPreviewComponent implements OnInit {

  @Input() event: CalendarEvent;

  /**
   * Constructor
   */

  constructor( ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() { }

  saveAttachment(file: Attachment, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    window.open(file.url, '_blank');
  }

  saveAllAttachments() {
    this.event.files.forEach( (file: Attachment) => window.open(file.url, '_blank'));
  }
}
