/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-in.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sign-in.component";
import * as i4 from "../../services/auth.service";
import * as i5 from "@angular/router";
var styles_SignInComponent = [i0.styles];
var RenderType_SignInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
function View_SignInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "alert alert-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hey there!"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unfortunately, your email has not been authorized on our systems yet. But don't worry, we are working hard to get people access. If you haven't requested access yet, please go sign up at "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "http://glimpse.email"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["http://glimpse.email"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you and see you soon!"]))], null, null); }
function View_SignInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_SignInComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-3x fa-fw"]], null, null, null, null, null))], null, null); }
export function View_SignInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "login-box-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "login-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "login-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/logo/logo-main.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "login-box-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "login-box-welcome-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WELCOME"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "login-box-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login via your Google Mail Account"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-login"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.googleSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "icon-google-g-logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login Via Google "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignInComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignInComponent_2)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignInComponent_3)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 2019 Deepframe"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailNotAllowed; _ck(_v, 15, 0, currVal_0); var currVal_1 = _co.errorMessage; _ck(_v, 17, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 19, 0, currVal_2); }, null); }
export function View_SignInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i1.ɵdid(1, 245760, null, 0, i3.SignInComponent, [i4.AuthService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInComponentNgFactory = i1.ɵccf("app-sign-in", i3.SignInComponent, View_SignInComponent_Host_0, {}, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
