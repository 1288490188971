<div class="box">
  <div class="box-header with-border">
    <h3 class="box-title">Delayed send</h3>
  </div>
  <div class="box-body with-padding">
    <div>
      <label class="delay__title" for="delaySelect">Send whith delay by default:</label>
      <select [(ngModel)]="delay" id="delaySelect">
        <option value="0">{{'off'}}</option>
        <option value="60000">{{'in 1 minute'}}</option>
        <option value="600000">{{'in 10 minutes'}}</option>
        <option value="900000">{{'in 15 minutes'}}</option>
        <option value="1200000">{{'in 20 minutes'}}</option>
        <option value="3600000">{{'in 1 hour'}}</option>
      </select>
    </div>

    <p><button class="btn btn-primary" (click)="saveDelayedSend()">Save</button></p>
    <div class="alert alert-success" *ngIf="saved">Option is saved</div>
    <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
  </div>
</div>
