<div
  *ngIf="event"
  class="event"
  [class.selected]="selected && (!isDragging || (isDragging && dragEnabled)) "
  [class.dragging]="!dragEnabled"
  [class.hovered]="contextMenuOpened"
  [class.pinned]="event.pinned"
  (dblclick)="handleDoubleClick(event)"
  [appEventContextMenu]="event"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'event'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  popover
  [popoverPlacement]="hoverPlacement"
  popoverTrigger="hover"
  [popoverContent]="popoverTemplate"
  [popoverDelay]="800"
  [popoverDisabled]="!dragEnabled"
>
  <div class="left-border" [class.left-border-selected]="selected"></div>
  <div class="event-info">
    <div class="title" [class.title-missing]="!event.title">{{ event.title || 'No Title' }}</div>

    <div class="date-time">
      <mat-icon *ngIf="event?.repeat && event?.repeat !== 'noRepeat'" class="date-time-repeat-icon" svgIcon="repeat"></mat-icon>
      <div class="date" *ngIf="!(event.allDay || event?.when?.durationType === 'day') && event?.when?.start && event?.when?.end">
        <span *ngIf="!(event?.when?.start | sameDay:event.when.end)"> {{ event.when.start | date: 'MMM d' }} &mdash; </span>
        <span *ngIf="event?.when?.end"> {{ (event.when.end | date: 'MMM d, yyy') }}</span>
        <span class="date-border"></span>
      </div>
      <div class="time" [class.today]="event?.when?.durationType === 'timespan' && event?.when?.start | includesNow : event?.when?.end">
        <span *ngIf="event?.when?.durationType === 'day'">All Day</span>
        <span *ngIf="event?.when?.durationType === 'timespan' && (!event?.when?.start || !event?.when?.end)">No selected time</span>
        <span *ngIf="event?.when?.durationType === 'timespan' && event?.when?.start && event?.when?.end">
          {{ event.when.start | date: 'h:mm aaa' | lowercase }} &mdash; {{ event.when.end | date: 'h:mm aaa' | lowercase }}
        </span>
      </div>
    </div>
    
    <div class="description">{{ event.description || 'No description has been added to this event.' }}</div>

    <mat-icon
      class="archive-status-icon"
      [svgIcon]="event.archived ? 'archive' : 'event'"
      [style.color]="event.color || (event.calendarId | calendarColor | async)"
    ></mat-icon>

    <mat-icon class="attachment-icon" svgIcon="attachment" *ngIf="event?.files?.length > 0"></mat-icon>

    <div class="participants-container" [ngClass]="{'linked-info-offset': event.linkedInfo?.length}">
      <app-contacts-counter-dropdown
        [contactList]="event.participants"
        [size]="24"
        [minContactLen]="1"
        [showNoReplyMark]="true"
      ></app-contacts-counter-dropdown>
    </div>

    <!-- Linked Info -->
    <app-link-info-bar *ngIf="event.linkedInfo?.length" [linkedInfo]="event.linkedInfo" class="event-info-link-bar"></app-link-info-bar>
    <div *ngIf="!event.linkedInfo?.length"  class="event-info-link-bar-missing"></div>

    <div class="hover-icons-container" *ngIf="dragEnabled && actionsButtonEnabled">
      <mat-icon class="hover-icon" svgIcon="saved"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="pin" (click)="pinEvent($event)"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="snooze"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="mail" (click)="createMail($event)"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="followup"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="archive" (click)="archiveEvent($event)"></mat-icon>
      <mat-icon class="hover-icon" svgIcon="trash" [class.hover-icon-disabled]="event.readOnly"
                matTooltip="Can't be deleted, read only"
                [matTooltipDisabled]="!event.readOnly"
                (click)="deleteEvent($event)"
      ></mat-icon>
    </div>

    <mat-icon class="event-info-pin-icon" svgIcon="pin-direct" *ngIf="event.pinned"></mat-icon>
    <mat-icon class="event-info-saved-icon" svgIcon="saved" *ngIf="event?.saved"></mat-icon>

    <mat-icon
      class="drag-icon"
      svgIcon="drag-icon-small"
      *ngIf="dragEnabled && actionsButtonEnabled"
    ></mat-icon>

    <!-- Drop zone -->
    <div class="drop-zone"></div>

  </div>
</div>

<!-- Event Placeholder -->
<div class="event-skeleton" *ngIf="!event">
  <div class="event-skeleton-title">
    <div class="event-skeleton-title-icon event-skeleton-title-icon-left"></div>
    <div class="event-skeleton-title-text"></div>
    <div class="event-skeleton-title-icon"></div>
  </div>
  <div class="event-skeleton-date">
    <div class="event-skeleton-date-icon event-skeleton-date-icon-left"></div>
    <div class="event-skeleton-date-day"></div>
    <div class="event-skeleton-date-time"></div>
    <div class="event-skeleton-date-icon event-skeleton-date-icon-right"></div>
  </div>
  <div class="event-skeleton-description"></div>
  <div class="event-skeleton-linked">
    <div class="event-skeleton-linked-item"></div>
    <div class="event-skeleton-linked-item"></div>
    <div class="event-skeleton-linked-item"></div>
    <div class="event-skeleton-linked-item"></div>
  </div>
  <div class="event-skeleton-participants"></div>
</div>

<ng-template #dragPlaceholder>
  <app-event [event]="event" [dragEnabled]="false"></app-event>
</ng-template>

<ng-template #popoverTemplate>
  <event-popover-preview [event]="event"></event-popover-preview>
</ng-template>
