<app-input 
  [placeholder]="placeholder" 
  [inputFormControl]="inputFormControl" 
  appearance="standard"
  [autocompleteItemTemplateRef]="autocompleteItem"
  [suggestedOptions]="suggestedOptions | async"
  [bundledInputConsumerKeys]="bundledInputConsumerKeys"
  [bundledInputProviderKeys]="bundledInputProviderKeys"
  [bundledInputProviderGroup]="bundledInputProviderGroup"
  [bundledInputConsumerGroup]="bundledInputConsumerGroup"
  icon="event"
></app-input>

<ng-template #autocompleteItem let-event="item.value">
  <div class="option">
    <div class="option-title">{{ event.title }}</div>
    <div class="option-date">
      <mat-icon class="option-date-icon" [svgIcon]="'calendar'"></mat-icon>
      {{ event.when.start | date: 'MMM d,yyyy' }}
    </div>
  </div>
</ng-template>
