<div class="cal-week-view">
  <mwl-calendar-week-view-header
    [days]="days"
    [locale]="locale"
    [customTemplate]="headerTemplate"
    (dayHeaderClicked)="dayHeaderClicked.emit($event)"
    (eventDropped)="eventDropped({ dropData: $event }, $event.newStart, true)"
  >
  </mwl-calendar-week-view-header>
  <div class="cal-all-day-events"
    [class.cal-all-day-events-scrollable]="!allDayEventAmountLimit"
    #allDayEventsContainer
    *ngIf="view.allDayEventRows.length > 0"
    mwlDroppable
    (dragEnter)="eventDragEnter = eventDragEnter + 1"
    (dragLeave)="eventDragEnter = eventDragEnter - 1"
  >
    <div class="cal-day-columns">
      <div class="cal-time-label-column" [ngTemplateOutlet]="allDayEventsLabelTemplate">
        <div class="collapse-events" *ngIf="view.allDayEventRows.length > dayEventLimit">
          <mat-icon [svgIcon]="allDayEventAmountLimit ? 'button-collapse-down' : 'button-collapse-up'"
                    (click)="toggleEventLimit()"
                    class="collapse-button"
          ></mat-icon>
        </div>
      </div>
      <div class="cal-day-column"
        *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
        mwlDroppable
        dragOverClass="cal-drag-over"
        (drop)="eventDropped($event, day.date, true)"
      ></div>
    </div>
    <div class="cal-events-row"
      *ngFor="let eventRow of view.allDayEventRows | slice:0:allDayEventAmountLimit;  index as rowIndex; trackBy: trackById"
      #eventRowContainer
    >
      <ng-container *ngIf="view.allDayEventRows.length <= allDayEventAmountLimit || rowIndex < allDayEventAmountLimit || !allDayEventAmountLimit">
        <ng-container *ngFor="let allDayEvent of eventRow.row; trackBy: trackByDayOrWeekEvent">
          <div *ngIf="!allDayEventAmountLimit || rowIndex + 1 < allDayEventAmountLimit || (allDayEvent | sameDayEventsCount:view.allDayEventRows:rowIndex:true) <= 1"
            #event
            class="cal-event-container"
            [class.cal-draggable]="allDayEvent.event.draggable && allDayEventResizes.size === 0"
            [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
            [class.cal-ends-within-week]="!allDayEvent.endsAfterWeek"
            [ngClass]="allDayEvent.event?.cssClass"
            [style.width.%]="(100 / days.length) * allDayEvent.span"
            [style.marginLeft.%]="(100 / days.length) * allDayEvent.offset"
            mwlResizable
            [resizeSnapGrid]="{ left: dayColumnWidth, right: dayColumnWidth }"
            [validateResize]="validateResize"
            (resizeStart)="allDayEventResizeStarted(eventRowContainer, allDayEvent, $event)"
            (resizing)="allDayEventResizing(allDayEvent, $event, dayColumnWidth)"
            (resizeEnd)="allDayEventResizeEnded(allDayEvent)"
            mwlDraggable
            dragActiveClass="cal-drag-active"
            [dropData]="{ event: allDayEvent.event, calendarId: calendarId }"
            [dragAxis]="{
                x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
                y: !snapDraggedEvents && allDayEvent.event.draggable && allDayEventResizes.size === 0 }"
            [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}"
            [validateDrag]="validateDrag"
            (dragStart)="dragStarted(eventRowContainer, event)"
            (dragging)="allDayEventDragMove()"
            (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)"
          >
            <div class="cal-resize-handle cal-resize-handle-before-start"
                 *ngIf="allDayEvent.event?.resizable?.beforeStart && !allDayEvent.startsBeforeWeek"
                 mwlResizeHandle
                 [resizeEdges]="{ left: true }"
            ></div>
            <mwl-calendar-week-view-event
              [weekEvent]="allDayEvent"
              [tooltipPlacement]="tooltipPlacement"
              [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody"
              [tooltipDelay]="tooltipDelay"
              [customTemplate]="eventTemplate"
              [eventTitleTemplate]="eventTitleTemplate"
              [eventActionsTemplate]="eventActionsTemplate"
              (eventClicked)="eventClicked.emit({ event: allDayEvent.event })"
            >
            </mwl-calendar-week-view-event>
            <div class="cal-resize-handle cal-resize-handle-after-end"
                 *ngIf="allDayEvent.event?.resizable?.afterEnd && !allDayEvent.endsAfterWeek"
                 mwlResizeHandle
                 [resizeEdges]="{ right: true }"
            ></div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="allDayEventAmountLimit && view.allDayEventRows.length > allDayEventAmountLimit && rowIndex === allDayEventAmountLimit - 1">
        <div
          *ngFor="let day of days; index as index"
          class="cal-event-more"
          [style.width.%]="(100 / days.length)"
          [style.marginLeft.%]="(100 / days.length) * index"
        >
          <div
            *ngIf="({ offset: index } | sameDayEventsCount:view.allDayEventRows:rowIndex) > 0"
            (click)="toggleEventLimit()"
            class="more-events-button"
          >
            {{ ({ offset: index } | sameDayEventsCount:view.allDayEventRows:rowIndex) }} more
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="cal-time-events"
    #dayRowsContainer
    mwlDroppable
    (dragEnter)="eventDragEnter = eventDragEnter + 1"
    (dragLeave)="eventDragEnter = eventDragEnter - 1"
  >
    <div class="cal-time-label-column" *ngIf="view.hourColumns.length > 0">
      <div *ngFor="let hour of view.hourColumns[0].hours; trackBy: trackByHour; let odd = odd"
        class="cal-hour"
        [class.cal-hour-odd]="odd"
      >
        <mwl-calendar-week-view-hour-segment
          *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
          [style.height.px]="hourSegmentHeight"
          [segment]="segment"
          [segmentHeight]="hourSegmentHeight"
          [locale]="locale"
          [customTemplate]="hourSegmentTemplate"
          [isTimeLabel]="true"
        >
        </mwl-calendar-week-view-hour-segment>
      </div>
    </div>
    <div class="cal-day-columns"
      [class.cal-resize-active]="timeEventResizes.size > 0"
      #dayColumns
    >
      <div class="cal-day-column"
        *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn"
      >
        <div *ngFor="let timeEvent of column.events; trackBy: trackByDayOrWeekEvent"
          #event
          class="cal-event-container"
          [class.cal-draggable]="timeEvent.event.draggable && timeEventResizes.size === 0"
          [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
          [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
          [ngClass]="timeEvent.event.cssClass"
          [hidden]="timeEvent.height === 0 && timeEvent.width === 0"
          [style.top.px]="timeEvent.top"
          [style.height.px]="timeEvent.height"
          [style.left.%]="timeEvent.left"
          [style.width.%]="timeEvent.width"
          mwlResizable
          [resizeSnapGrid]="{
                left: dayColumnWidth,
                right: dayColumnWidth,
                top: eventSnapSize || hourSegmentHeight,
                bottom: eventSnapSize || hourSegmentHeight
              }"
          [validateResize]="validateResize"
          [allowNegativeResizes]="true"
          (resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
          (resizing)="timeEventResizing(timeEvent, $event)"
          (resizeEnd)="timeEventResizeEnded(timeEvent)"
          mwlDraggable
          dragActiveClass="cal-drag-active"
          [dropData]="{ event: timeEvent.event, calendarId: calendarId }"
          [dragAxis]="{
                x: timeEvent.event.draggable && timeEventResizes.size === 0,
                y: timeEvent.event.draggable && timeEventResizes.size === 0
              }"
          [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth, y: eventSnapSize || hourSegmentHeight } : {} "
          [ghostDragEnabled]="!snapDraggedEvents"
          [validateDrag]="validateDrag"
          (dragStart)="dragStarted(dayColumns, event, timeEvent)"
          (dragging)="dragMove(timeEvent, $event)"
          (dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)"
        >
          <div class="cal-resize-handle cal-resize-handle-before-start"
            *ngIf="timeEvent.event?.resizable?.beforeStart && !timeEvent.startsBeforeDay"
            mwlResizeHandle
            [resizeEdges]="{ left: true, top: true }"
          ></div>
          <mwl-calendar-week-view-event
            [weekEvent]="timeEvent"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipDisabled]="dragActive || timeEventResizes.size > 0"
            [tooltipDelay]="tooltipDelay"
            [customTemplate]="eventTemplate"
            [eventTitleTemplate]="eventTitleTemplate"
            [eventActionsTemplate]="eventActionsTemplate"
            [column]="column"
            (eventClicked)="eventClicked.emit({ event: timeEvent.event })"
          >
          </mwl-calendar-week-view-event>
          <div class="cal-resize-handle cal-resize-handle-after-end"
            *ngIf="timeEvent.event?.resizable?.afterEnd && !timeEvent.endsAfterDay"
            mwlResizeHandle [resizeEdges]="{ right: true, bottom: true }"
          ></div>
        </div>
        <div *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd"
          class="cal-hour"
          [class.cal-hour-odd]="odd"
        >
          <mwl-calendar-week-view-hour-segment
            *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
            [style.height.px]="hourSegmentHeight"
            [segment]="segment"
            [segmentHeight]="hourSegmentHeight"
            [locale]="locale"
            [customTemplate]="hourSegmentTemplate"
            (mwlClick)="hourSegmentClicked.emit({ date: segment.date })"
            [clickListenerDisabled]="hourSegmentClicked.observers.length === 0"
            mwlDroppable
            [dragOverClass]="!dragActive || !snapDraggedEvents ? 'cal-drag-over' : null"
            dragActiveClass="cal-drag-active"
            (drop)="eventDropped($event, segment.date, false)"
          >
          </mwl-calendar-week-view-hour-segment>
        </div>
      </div>
    </div>
  </div>
</div>
