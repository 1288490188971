/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-calendar-month.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/directives/click/click.directive";
import * as i4 from "../../../drag-n-drop/directives/droppable.directive";
import * as i5 from "../../../drag-n-drop/services/drag-n-drop.service";
import * as i6 from "../../directives/calendar-cell-context-menu.directive";
import * as i7 from "angular-calendar";
import * as i8 from "../../../../../../node_modules/angular-calendar/angular-calendar.ngfactory";
import * as i9 from "./full-calendar-month.component";
var styles_FullCalendarMonthComponent = [i0.styles];
var RenderType_FullCalendarMonthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullCalendarMonthComponent, data: { "animation": [{ type: 7, name: "dateAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: { type: 8, animation: [{ type: 6, styles: { opacity: 0, transform: "translateX({{offsetStart}}%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateX({{offsetFinish}}%)" }, offset: null }, timings: "{{ duration }} ease-out" }], options: { params: { duration: "300ms", offsetStart: -5, offsetFinish: 0 } } }, options: null }], options: {} }] } });
export { RenderType_FullCalendarMonthComponent as RenderType_FullCalendarMonthComponent };
function View_FullCalendarMonthComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FullCalendarMonthComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "cal-event"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FullCalendarMonthComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { event: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = _co.badgeTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FullCalendarMonthComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cal-events"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullCalendarMonthComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.day.events; var currVal_1 = _v.parent.context.trackByEventId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FullCalendarMonthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, [["cell", 1]], null, 10, "div", [["appCalendarCellContextMenuCalendarType", "month"], ["appDroppable", ""], ["appDroppableHoverClass", "drag-over"], ["class", "full-height-flex"]], null, [[null, "appDroppableDrop"], [null, "appClick"], [null, "appDblClick"], [null, "appCalendarCellContextMenuLoadDayEvents"], [null, "click"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleDblClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("appDroppableDrop" === en)) {
        var pd_2 = (_co.handleDrop($event, _v.context.day.date, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } if (("appClick" === en)) {
        var pd_3 = (_co.selectCalendarDate($event, _v.context.day.date, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_3 && ad);
    } if (("appDblClick" === en)) {
        var pd_4 = (_co.dblClickCalendarDate(_v.context.day.date) !== false);
        ad = (pd_4 && ad);
    } if (("appCalendarCellContextMenuLoadDayEvents" === en)) {
        var pd_5 = (_co.handleLoadDayEvents(_v.context.day.date) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ClickDirective, [], null, { appClick: "appClick", appDblClick: "appDblClick" }), i1.ɵdid(2, 737280, null, 0, i4.DroppableDirective, [i5.DragNDropService, i1.Renderer2, i1.ElementRef, i1.NgZone], { appDroppableHoverClass: [0, "appDroppableHoverClass"] }, { appDroppableDrop: "appDroppableDrop" }), i1.ɵdid(3, 212992, null, 0, i6.CalendarCellContextMenuDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ElementRef, i1.ChangeDetectorRef], { appCalendarCellContextMenu: [0, "appCalendarCellContextMenu"], appCalendarCellContextMenuCalendarType: [1, "appCalendarCellContextMenuCalendarType"] }, { appCalendarCellContextMenuLoadDayEvents: "appCalendarCellContextMenuLoadDayEvents" }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "cal-cell-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "cal-day-number"]], null, [[null, "appClick"], [null, "appDblClick"], [null, "click"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).handleDblClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("appClick" === en)) {
        var pd_2 = (_co.handleLoadDayEvents(_v.context.day.date) !== false);
        ad = (pd_2 && ad);
    } if (("appDblClick" === en)) {
        var pd_3 = (_co.dblClickDay(_v.context.day.date) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.ClickDirective, [], { stopPropagation: [0, "stopPropagation"] }, { appClick: "appClick", appDblClick: "appDblClick" }), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullCalendarMonthComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "drag-over"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.day.date; var currVal_2 = "month"; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = true; _ck(_v, 6, 0, currVal_3); var currVal_5 = (_v.context.day.events.length > 0); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _v.context.day.date, "monthViewDayNumber", _v.context.locale)); _ck(_v, 7, 0, currVal_4); }); }
export function View_FullCalendarMonthComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i7.ɵi, [i7.CalendarDateFormatter, i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "calendar-month--wrapper"]], [[24, "@dateAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵand(0, [["dayTemplate", 2]], null, 0, null, View_FullCalendarMonthComponent_1)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mwl-calendar-month-view", [], null, [[null, "beforeViewRender"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("beforeViewRender" === en)) {
        var pd_0 = (_co.beforeMonthViewRender($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CalendarMonthViewComponent_0, i8.RenderType_CalendarMonthViewComponent)), i1.ɵdid(4, 770048, null, 0, i7.CalendarMonthViewComponent, [i1.ChangeDetectorRef, i7.CalendarUtils, i1.LOCALE_ID, i7.DateAdapter], { viewDate: [0, "viewDate"], events: [1, "events"], refresh: [2, "refresh"], tooltipAppendToBody: [3, "tooltipAppendToBody"], weekStartsOn: [4, "weekStartsOn"], cellTemplate: [5, "cellTemplate"] }, { beforeViewRender: "beforeViewRender" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.displayDate; var currVal_2 = _co.events; var currVal_3 = _co.refreshCalendar; var currVal_4 = false; var currVal_5 = 0; var currVal_6 = i1.ɵnov(_v, 2); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayDate; _ck(_v, 1, 0, currVal_0); }); }
export function View_FullCalendarMonthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "full-calendar-month", [], null, null, null, View_FullCalendarMonthComponent_0, RenderType_FullCalendarMonthComponent)), i1.ɵprd(4608, null, i7.CalendarDateFormatter, i9.CalendarMonthDateFormatter, [i7.DateAdapter]), i1.ɵdid(2, 573440, null, 0, i9.FullCalendarMonthComponent, [], null, null)], null, null); }
var FullCalendarMonthComponentNgFactory = i1.ɵccf("full-calendar-month", i9.FullCalendarMonthComponent, View_FullCalendarMonthComponent_Host_0, { viewDate: "viewDate", selectedDate: "selectedDate", events: "events", badgeTemplate: "badgeTemplate", highlightedDay: "highlightedDay" }, { dateClicked: "dateClicked", dateDblClicked: "dateDblClicked", dayNumberDblClicked: "dayNumberDblClicked", eventDropped: "eventDropped", loadDayEvents: "loadDayEvents" }, []);
export { FullCalendarMonthComponentNgFactory as FullCalendarMonthComponentNgFactory };
