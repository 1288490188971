import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var EventMainViewComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventMainViewComponent(eventsStateService, layoutStateService, stateService) {
        this.eventsStateService = eventsStateService;
        this.layoutStateService = layoutStateService;
        this.stateService = stateService;
        // Private
        this.componentNotDestroyed = new Subject();
    }
    /**
     * Component lifecycle
     */
    EventMainViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([
            this.stateService.getSelectedCalendarType(),
            this.eventsStateService.getMainView(),
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), calendarType = _b[0], view = _b[1];
            _this.view = view;
            _this.layoutStateService.setLinkedInfoToolbarPresence(view === 'event-form' ||
                calendarType === 'agenda');
        });
        this.eventsStateService.getMainViewEvent()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (event) {
            _this.event = event;
        });
    };
    EventMainViewComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
        this.layoutStateService.setLinkedInfoToolbarPresence(false);
    };
    /**
     * Actions
     */
    EventMainViewComponent.prototype.handleFormClose = function () {
        this.eventsStateService.setMainView('calendar');
    };
    return EventMainViewComponent;
}());
export { EventMainViewComponent };
