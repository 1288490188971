<app-dropdown [closeOnSelect]="true">
  <ng-template #toggleButtonTemplate>
    <div class="toolbar-dropdown">
      {{ selectedOption?.name }}<mat-icon svgIcon="dropdown" class="dropdown-icon"></mat-icon>
    </div>
  </ng-template>
  <app-dropdown-template-items
    [options]="fontNamesOptions"
    [optionTemplateRef]="fontItem"
    (selectedChange)="selected($event)"
  ></app-dropdown-template-items>
</app-dropdown>
<ng-template #fontItem let-option="option" let-select="select">
  <div class="dropdown-item" (click)="select(option)">
    <span [style.font-family]="option.key">{{ option.name }}</span>
    <mat-icon svgIcon="check" class="check-icon" *ngIf="option.key === selectedOption?.key"></mat-icon>
  </div>
</ng-template>
