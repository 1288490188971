<div class="content-menu-tag-wrapper">
    <div class="header">
      <div
        class="content-menu-tag"
        [ngClass]="{active: active}"
        (click)="select($event)"
        [contextMenu]="tagContext"
        [contextMenuSubject]="tag"

        appDraggable
        [appDraggableData]="{data: [tag], type: 'tag'}"
        [appDraggablePlaceholder]="dragPlaceholder"

        appDroppable
        appDroppableHoverClass="drag-over"
        [appDroppablePredicate]="dndPredicate"
        (appDroppableDrop)="dndDrop($event)"
      >
        #{{ tag?.name }}
      </div>
      <mat-icon *ngIf="tag?.unreadCount > 0" svgIcon="mail-unread" class="unread-icon"></mat-icon>
    </div>
  </div>

  <!-- Drag message placeholder -->
  <ng-template #dragPlaceholder>
    <div class="content-menu-tag drag-item" [ngClass]="{active: active}">#{{ tag?.name }}</div>
  </ng-template>

  <!-- Context menu -->
  <context-menu #tagContext>
    <ng-template contextMenuItem>
      Add New Tag
    </ng-template>
    <ng-template contextMenuItem (execute)="unpinTag($event.item)">
      Unpin Tag
    </ng-template>
    <ng-template contextMenuItem (execute)="unpinAllTags()">
      Clear All Pinned Tags
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="openTagsModal()">
      Manage My Tags...
    </ng-template>
  </context-menu>
