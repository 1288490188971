// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { isEqual, isMatch } from 'lodash';
import { map } from 'rxjs/operators';

// Types
import { SearchQueryParams } from '../types/search-query-params';
import { SearchSuggestions } from '../types/search-suggestions';
import { SearchParam } from '../types/search-param';

// Env
import { environment } from '@environment';

@Injectable()
export class SearchService {

  // Private
  private searchParams = new BehaviorSubject<SearchQueryParams>(null);

  // Public
  public searchHistory = new BehaviorSubject<Array<SearchParam[]>>([]);

  /**
   * Static methods
   */

  static parseSearchParams(params: string): SearchQueryParams {
    if (!params) {
      return new SearchQueryParams();
    }

    try {
      const searchParams = JSON.parse(params);
      let topics = [], contacts = [], keywords = [], tags = [];
      if (searchParams) {
        searchParams.forEach(param => {
          switch (param.type) {
            case 'topic':
              topics.push(param.value);
              break;
            case 'contact':
              contacts.push(param.value);
              break;
            case 'keyword':
              keywords.push(param.value);
              break;
            case 'tag':
              tags.push(param.value);
              break;
          }
        });
        topics = topics.length > 0 ? topics : null;
        contacts = contacts.length > 0 ? contacts : null;
        keywords = keywords.length > 0 ? keywords : null;
        tags = tags.length > 0 ? tags : null;
      } else {
        topics = null;
        contacts = null;
        keywords = null;
        tags = null;
      }
      return new SearchQueryParams(topics, contacts, keywords, tags);
    } catch (e) {
      return new SearchQueryParams();
    }
  }

  /**
   * Constructor
   */

  constructor(private http: HttpClient) {
    const history = this.loadSearchHistory();
    this.searchHistory.next(history);
  }

  /**
   * API
   */

  searchSuggestions(query: string, searchTypes: string[], size: number = 10): Observable<SearchSuggestions> {
    const params: { [param: string]: string | string[]; } = {
      query: query,
      size: size + ''
    };
    if (searchTypes) {
      params['types[]'] = searchTypes;
    }
    return this.http.get<{ data: SearchSuggestions }>(`${environment.baseUrl}/api/search/suggest`, { params })
      .pipe(
        map(({ data }) => data)
      );
  }

  /**
   * Interface
   */

  setSearchParams(params: SearchQueryParams) {
    this.searchParams.next(params);
  }

  getSearchParams(): Observable<SearchQueryParams> {
    return this.searchParams.asObservable();
  }

  /**
   * Local storage
   */

  updateSearchHistory(params: SearchParam[]) {
    // Check for empry params
    if (!params.length) {
      return;
    }

    // Get history
    const history = this.loadSearchHistory();

    // Change first object if the same
    if (history.length) {
      const firstParams = history[0];
      if (isMatch(params, firstParams)) {
        history.shift();
      }
    }

    // Remove duplicate object and set first
    const index = history.findIndex(historyParams => {
      return isEqual(historyParams, params);
    });
    if (index !== -1) {
      history.splice(index, 1);
    }

    // Update history
    history.unshift(params);
    this.saveSearchHistory(history);
    this.searchHistory.next(history);
  }

  removeSearchHistory(params: SearchParam[]) {
    // Check for empry params
    if (!params.length) {
      return;
    }

    // Get history
    const history = this.loadSearchHistory();

    // Remove params from history
    const index = history.findIndex(historyParams => {
      return isEqual(historyParams, params);
    });
    if (index !== -1) {
      history.splice(index, 1);
    }

    // Update history
    this.saveSearchHistory(history);
    this.searchHistory.next(history);
  }

  private loadSearchHistory(): Array<SearchParam[]> {
    let searchHistory = [];
    try {
      searchHistory = JSON.parse(localStorage.getItem('app.search.history')) || [];
    } catch (e) {
      console.error('Can not parse JSON from localStore. ', e);
    }
    return searchHistory;
  }

  private saveSearchHistory(history: Array<SearchParam[]>) {
    const jsonStates = JSON.stringify(history);
    localStorage.setItem('app.search.history', jsonStates);
  }
}
