<div class="group-form">
  <div class="shadow"></div>

  <form
    class="group-form-main"
    [formGroup]="groupForm"
  >

    <!-- Tags -->
    <app-tag-list
      class="group-form-tags"
      [group]="group"
      [tags]="groupForm.controls['tags'].value"
      (tagsChange)="groupForm.patchValue({ tags: $event })"
      appScrollAnchor
    ></app-tag-list>

    <!-- Pellets -->
    <app-group-topics
      [groupId]="group?.id"
      [topicsControl]="groupForm.controls['topics']"
    ></app-group-topics>

    <div class="group-form-horizontal-group">
      <div class="group-form-horizontal-group-item">
        <app-input
          placeholder="Group Email"
          [inputFormControl]="groupForm.get('email')"
          width="225px"
        ></app-input>
      </div>
      <div class="group-form-horizontal-group-item">
        <app-input
          placeholder="Group Phone Number"
          [inputFormControl]="groupForm.get('phoneNumber')"
          width="225px"
        ></app-input>
      </div>
    </div>

    <div class="group-form-horizontal-group">
      <div class="group-form-horizontal-group-item">
        <app-dropdown-select
          [placeholder]="'Video'"
          [inputFormControl]="groupForm.controls['social']"
          [options]="conferencingOptions"
          width="225px"
        ></app-dropdown-select>
      </div>
      <div class="group-form-horizontal-group-item">
        <app-dropdown-select
          [placeholder]="'Social Media'"
          [inputFormControl]="groupForm.controls['social']"
          [options]="conferencingOptions"
          width="225px"
        ></app-dropdown-select>
      </div>
    </div>

    <div class="group-form-horizontal-group">
      <div class="group-form-horizontal-group-full-width-item">
        <app-linked-info-list
          class="linked-info-list"
          [parentLinkedInfo]="{type: 'group', id: group?.id}"
          [linkedInfo]="group?.linkedInfo"
          (extendAction)="openLinkedInfoDP.emit()"
        ></app-linked-info-list>
      </div>
    </div>
  </form>
</div>
