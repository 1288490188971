// Common
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-account-side-bar',
  templateUrl: './account-side-bar.component.html',
  styleUrls: ['./account-side-bar.component.less']
})
export class AccountSideBarComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() isModal = false;
  @Input() routePath: string;

  // Outputs
  @Output() routePathChange: EventEmitter<string> = new EventEmitter();

  // Public
  public sections = [
    {
      name: 'Overview',
      route: 'overview',
      icon: 'user'
    }, {
      name: 'General',
      route: 'general',
      icon: 'window-maximize'
    }, {
      name: 'Signatures',
      route: 'signatures',
      icon: 'pencil-square-o'
    }, {
      name: 'Delayed send',
      route: 'delayed-send',
      icon: 'mail-reply'
    }, {
      name: 'Reply templates',
      route: 'quick-reply-templates',
      icon: 'mail-reply'
    }, {
      name: 'Auto reply',
      route: 'auto-replies',
      icon: 'commenting'
    }, {
      name: 'Mail rules',
      route: 'mail-rules',
      icon: 'envelope'
    }, {
      name: 'Desktop Notifications',
      route: 'web-push-notifications',
      icon: 'bell'
    }, {
      name: 'Cancel my Account',
      route: 'cancel',
      icon: 'ban'
    }
  ];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private auth: AuthService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectRoute(routePath: string) {
    this.routePath = routePath;
    this.routePathChange.emit(this.routePath);
  }

  signOut() {
    if (confirm('Are you sure want logout from Pellet?')) {
      this.auth.signOut()
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe(() => window.location.replace('/signin'));
    }
  }

}
