/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-event-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../calendar/components/calendar-create-event/calendar-create-event.component.ngfactory";
import * as i3 from "../../../calendar/components/calendar-create-event/calendar-create-event.component";
import * as i4 from "../../../events/services/calendar.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../../pipes/pipes/date-time.pipe";
import * as i7 from "./calendar-event-modal.component";
var styles_CalendarEventModalComponent = [i0.styles];
var RenderType_CalendarEventModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarEventModalComponent, data: {} });
export { RenderType_CalendarEventModalComponent as RenderType_CalendarEventModalComponent };
export function View_CalendarEventModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-title text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create an event"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"], ["class", "close__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body calendar-event-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-calendar-create-event", [["class", "calendar-panel--create-event"]], null, [[null, "selectedEventChange"], [null, "eventCreated"], [null, "cancelEventChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedEventChange" === en)) {
        var pd_0 = ((_co.event = $event) !== false);
        ad = (pd_0 && ad);
    } if (("eventCreated" === en)) {
        var pd_1 = (_co.updatedEvent() !== false);
        ad = (pd_1 && ad);
    } if (("cancelEventChange" === en)) {
        var pd_2 = (_co.close() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CalendarCreateEventComponent_0, i2.RenderType_CalendarCreateEventComponent)), i1.ɵdid(8, 770048, null, 0, i3.CalendarCreateEventComponent, [i4.CalendarService, i1.ChangeDetectorRef, i5.FormBuilder, i6.DateTimePipe], { selectedDate: [0, "selectedDate"], selectedEvent: [1, "selectedEvent"] }, { selectedEventChange: "selectedEventChange", eventCreated: "eventCreated", cancelEventChange: "cancelEventChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedDate; var currVal_1 = _co.event; _ck(_v, 8, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarEventModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-event-modal", [], null, null, null, View_CalendarEventModalComponent_0, RenderType_CalendarEventModalComponent)), i1.ɵdid(1, 245760, null, 0, i7.CalendarEventModalComponent, [i4.CalendarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarEventModalComponentNgFactory = i1.ɵccf("app-calendar-event-modal", i7.CalendarEventModalComponent, View_CalendarEventModalComponent_Host_0, { event: "event" }, { closed: "closed", collapsed: "collapsed" }, []);
export { CalendarEventModalComponentNgFactory as CalendarEventModalComponentNgFactory };
