/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./note-form-popup.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../notes/components/note-form/note-form.component.ngfactory";
import * as i3 from "../../../notes/components/note-form/note-form.component";
import * as i4 from "../../../notes/services/notes.service";
import * as i5 from "../../../linked-info/services/linked-info.service";
import * as i6 from "../../../modal/services/modal.service";
import * as i7 from "../../../pages/services/dynamic-panel.service";
import * as i8 from "../../../topic/services/topic.service";
import * as i9 from "../../../tag/services/tag.service";
import * as i10 from "./note-form-popup.component";
var styles_NoteFormPopupComponent = [i0.styles];
var RenderType_NoteFormPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoteFormPopupComponent, data: {} });
export { RenderType_NoteFormPopupComponent as RenderType_NoteFormPopupComponent };
export function View_NoteFormPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-note-form", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NoteFormComponent_0, i2.RenderType_NoteFormComponent)), i1.ɵdid(2, 638976, null, 0, i3.NoteFormComponent, [i4.NotesService, i5.LinkedInfoService, i6.ModalService, i7.DynamicPanelService, i8.TopicService, i9.TagService], null, { close: "close" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_NoteFormPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-note-form-popup", [], null, null, null, View_NoteFormPopupComponent_0, RenderType_NoteFormPopupComponent)), i1.ɵdid(1, 49152, null, 0, i10.NoteFormPopupComponent, [], null, null)], null, null); }
var NoteFormPopupComponentNgFactory = i1.ɵccf("app-note-form-popup", i10.NoteFormPopupComponent, View_NoteFormPopupComponent_Host_0, {}, {}, []);
export { NoteFormPopupComponentNgFactory as NoteFormPopupComponentNgFactory };
