/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./font-name-selector.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../dropdown/components/dropdown/dropdown.component.ngfactory";
import * as i6 from "../../../../dropdown/components/dropdown/dropdown.component";
import * as i7 from "../../../../dropdown/components/dropdown-template-items/dropdown-template-items.component.ngfactory";
import * as i8 from "../../../../dropdown/components/dropdown-template-items/dropdown-template-items.component";
import * as i9 from "./font-name-selector.component";
var styles_FontNameSelectorComponent = [i0.styles];
var RenderType_FontNameSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FontNameSelectorComponent, data: {} });
export { RenderType_FontNameSelectorComponent as RenderType_FontNameSelectorComponent };
function View_FontNameSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "toolbar-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "dropdown-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "dropdown"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "dropdown"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedOption == null) ? null : _co.selectedOption.name); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_FontNameSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "check-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "check"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "check"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FontNameSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.select(_v.context.option) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], [[4, "font-family", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FontNameSelectorComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.option.key === ((_co.selectedOption == null) ? null : _co.selectedOption.key)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.option.key; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.option.name; _ck(_v, 2, 0, currVal_1); }); }
export function View_FontNameSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-dropdown", [], null, null, null, i5.View_DropdownComponent_0, i5.RenderType_DropdownComponent)), i1.ɵdid(1, 1753088, null, 6, i6.DropdownComponent, [], { closeOnSelect: [0, "closeOnSelect"] }, null), i1.ɵqud(335544320, 1, { toggleButtonTemplate: 0 }), i1.ɵqud(603979776, 2, { singleSelects: 1 }), i1.ɵqud(603979776, 3, { multiSelects: 1 }), i1.ɵqud(603979776, 4, { actionItems: 1 }), i1.ɵqud(603979776, 5, { templateItems: 1 }), i1.ɵqud(603979776, 6, { colorSelect: 1 }), (_l()(), i1.ɵand(0, [[1, 2], ["toggleButtonTemplate", 2]], 0, 0, null, View_FontNameSelectorComponent_1)), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-dropdown-template-items", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.selected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DropdownTemplateItemsComponent_0, i7.RenderType_DropdownTemplateItemsComponent)), i1.ɵdid(10, 49152, [[5, 4]], 0, i8.DropdownTemplateItemsComponent, [], { options: [0, "options"], optionTemplateRef: [1, "optionTemplateRef"] }, { selectedChange: "selectedChange" }), (_l()(), i1.ɵand(0, [["fontItem", 2]], null, 0, null, View_FontNameSelectorComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.fontNamesOptions; var currVal_2 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_1, currVal_2); }, null); }
export function View_FontNameSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-font-name-selector", [], null, null, null, View_FontNameSelectorComponent_0, RenderType_FontNameSelectorComponent)), i1.ɵdid(1, 638976, null, 0, i9.FontNameSelectorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FontNameSelectorComponentNgFactory = i1.ɵccf("app-font-name-selector", i9.FontNameSelectorComponent, View_FontNameSelectorComponent_Host_0, { currentFont: "currentFont" }, { select: "select" }, []);
export { FontNameSelectorComponentNgFactory as FontNameSelectorComponentNgFactory };
