// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { EventFilters } from '@modules/events/types/event-filters';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarType } from '@modules/events/types/calendar-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { SearchService } from '@modules/search/services/search.service';
import { StateService } from '@modules/settings/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-events-list-container',
  templateUrl: './events-list-container.component.html',
  styleUrls: ['./events-list-container.component.less'],
})
export class EventsListContainerComponent implements OnInit, OnDestroy {

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public minimized: boolean;
  public searchQuery: string;
  public quickFormExpanded = false;
  public listFilters: EventFilters = {};
  public loading: boolean;
  public scrollToDay: Observable<Date>;
  public quickFormEvent: CalendarEvent = new CalendarEvent();
  public quickFormToggle: Subject<void> = new Subject();

  // Private
  private calendarType: CalendarType;
  private componentNotDestroyed: Subject<void> = new Subject();
  private searchTitle = new BehaviorSubject('');

  /**
   * Constructor
   */

  constructor(
    private splitViewService: SplitViewService,
    private eventsStateService: EventsStateService,
    private stateService: StateService,
    private searchService: SearchService,
  ) {
    this.stateService.getSelectedCalendarType()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((calendarType: CalendarType) => this.calendarType = calendarType);

    combineLatest([
      this.eventsStateService.getSidebarFilters(),
      this.stateService.getSelectedCalendars(),
      this.searchService.getSearchParams(),
      this.searchTitle
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(([sidebarFilters, calendarIds, searchParams, title]) => {
        const keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];
        this.listFilters = {
          calendarIds,
          ...sidebarFilters,
          ...searchParams,
          keywords: !title || title.trim() === '' ? keywords : [...keywords, title]
        };
      });

    this.scrollToDay = this.eventsStateService.getScrollToDay();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('eventsList')
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleScrollOptionChange(value: number) {
    this.listScrollPosition = null;
    setTimeout(() => this.listScrollPosition = value);
  }

  handleNewEvent(event = new CalendarEvent()) {
    this.eventsStateService.openEventForm(event);
  }

  handleSearch(title: string) {
    this.searchTitle.next(title);
  }

  handleEventClick(event: CalendarEvent) {
    this.stateService.setSelectedCalendarDates(this.calendarType, event.when.start);
    this.eventsStateService.setSelectedCalendarDate(event.when.start);
  }

  handleSelectedEvents(events: CalendarEvent[]) {
    this.eventsStateService.setSelectedEvents(events);
  }

  setSelectedCalendarDate(date: Date) {
    this.eventsStateService.setSelectedCalendarDate(date);
    if (['year', 'month'].includes(this.calendarType)) {
      this.stateService.setSelectedCalendarDates(this.calendarType, date);
    }
  }

  handleQuickFormDrop(dragData: DragData) {
    const event = CalendarEvent.fromDragData(dragData);

    if (!event) { return; }

    this.quickFormEvent = new CalendarEvent(event);
    this.quickFormToggle.next();
  }
}
