// Rx
import { BehaviorSubject } from 'rxjs';
// Types
import { File } from '@modules/files/types/file';
import * as i0 from "@angular/core";
import * as i1 from "../../settings/services/state.service";
var FilesStateService = /** @class */ (function () {
    // Constructor
    function FilesStateService(stateService) {
        this.stateService = stateService;
        // Private
        this.file = new BehaviorSubject(null);
        this.selectedFiles = new BehaviorSubject([]);
        this.mainView = new BehaviorSubject('empty');
        this.mainViewFile = new BehaviorSubject(null);
    }
    FilesStateService.prototype.setFile = function (currentFile) {
        this.file.next(currentFile);
    };
    FilesStateService.prototype.getFile = function () {
        return this.file.asObservable();
    };
    FilesStateService.prototype.setSelectedFiles = function (files) {
        this.selectedFiles.next(files);
    };
    FilesStateService.prototype.getSelectedFiles = function () {
        return this.selectedFiles.asObservable();
    };
    FilesStateService.prototype.getMainView = function () {
        return this.mainView.asObservable();
    };
    FilesStateService.prototype.getMainViewFile = function () {
        return this.mainViewFile.asObservable();
    };
    FilesStateService.prototype.setMainView = function (view) {
        this.mainView.next(view);
        this.stateService.filesMainView = view;
    };
    FilesStateService.prototype.setMainViewFile = function (file) {
        this.mainViewFile.next(new File(file));
    };
    FilesStateService.prototype.openFileForm = function (file) {
        this.setMainViewFile(file);
        this.setMainView('file-form');
    };
    FilesStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilesStateService_Factory() { return new FilesStateService(i0.ɵɵinject(i1.StateService)); }, token: FilesStateService, providedIn: "root" });
    return FilesStateService;
}());
export { FilesStateService };
