import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { NoteContextMenuComponent } from '@modules/elements/components/note-context-menu/note-context-menu.component';
var NoteContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(NoteContextMenuDirective, _super);
    function NoteContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = NoteContextMenuComponent;
        return _this;
    }
    // Override
    NoteContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.note = this.appNoteContextMenu;
    };
    return NoteContextMenuDirective;
}(ContextMenuDirective));
export { NoteContextMenuDirective };
