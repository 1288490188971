// Common
import { Component, Input } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';

// Services
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-events-agenda-event',
  templateUrl: './events-agenda-event.component.html',
  styleUrls: ['./events-agenda-event.component.less'],
})

export class EventsAgendaEventComponent {

  // Public
  public isDragging = false;

  // Inputs
  @Input() event: CalendarEvent;
  @Input() dragEnabled = true;

  /**
   * Constructor
   */

  constructor(
    private knowledgePanelService: KnowledgePanelService,
    private modalService: ModalService,
  ) {

  }

  /**
   * Actions
   */

  handleClick() {
    this.knowledgePanelService.setFormItem(this.event);
  }

  handleDblClick() {
    this.modalService.calendarEventForm(this.event);
  }

}
