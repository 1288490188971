<div class="insights-topic-details">
  <h3 class="topic-details__name">{{ topics?.join(', ') }}</h3>

  <div class="topic-details__similar_topics">
    <h4 class="similar_topics__header">Similar topics</h4>
    <div
      class="similar_topics__wrapper"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="10"
      [immediateCheck]="true"
      [scrollWindow]="false"
      (scrolled)="loadSimilarTopics()">
      <app-topic
        *ngFor="let topic of similarTopics"
        [topic]="topic"
        [selected]="selectedSimilarTopics?.includes(topic)"
        (click)="selectTopic(topic, $event)"
        [topicStyle]="'discovered'">
      </app-topic>
      <div class="loader" *ngIf="similarTopicsLoader"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
      <div *ngIf="!similarTopics?.length && !similarTopicsLoader" class="no-topics-message">
        No Similar Topics found
      </div>
    </div>
  </div>

  <div class="topic-details__folder_statistics">
    <h4 class="folder_statistics__header">We have found</h4>
    <ol class="folder_statistics__view row">
      <li class="view_item col-md-4 text-center"
          *ngFor="let folder of foldersCounters"
          (click)="selectFolder(folder.id)"
          [ngClass]="{ 'view_item--selected': folder.id === selectedFolder }">
        <div class="view_item__counter">{{ folder.totalMails }}</div>
        <div class="view_item__folder_name"> {{ folder.name }}</div>
      </li>
      <li class="view_item col-md-4 text-center"
          *ngIf="foldersCountersDropdown.length">
        <div class="view_item__counter">
          {{countOtherFolders}}
        </div>
        <div class="view_item__folder_name">
          <span>Other folders <i class="fa fa-caret-down"></i></span>
        </div>
      </li>
    </ol>
    <div class="loader" *ngIf="similarTopicsCountLoader"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
    <div *ngIf="!foldersCounters?.length && !similarTopicsCountLoader" class="no-topics-message">
      No Messages found
    </div>
  </div>

</div>
