// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

// Types
import { CalendarContact } from '@modules/events/types/calendar-contact';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Topic } from '@modules/topic/types/topic';
import { Tag } from '@modules/tag/types/tag';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Priority } from '@modules/tasks/types/priority';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from './task';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Project {

  formBuilder = injector.get(FormBuilder);

  id?: string;
  title?: string;
  description?: string;
  fromTime: Date;
  toTime: Date;
  participants?: CalendarContact[];
  strangeLines?: {color: string}[] = [];
  pinned?: boolean;
  linkedInfo?: LinkedInfo[];
  files?: Attachment[];
  topics?: Topic[];
  tags?: Tag[];
  archived?: boolean;
  deleted?: boolean;
  priority?: Priority;

  constructor (projectObject: any = {}) {
    this.id = projectObject.id;
    this.title = projectObject.title;
    this.fromTime = projectObject.fromTime && new Date(projectObject.fromTime);
    this.toTime = projectObject.toTime && new Date(projectObject.toTime);
    this.strangeLines = projectObject.strangeLines;
    this.participants = projectObject.participants;
    this.pinned = projectObject.pinned;
    this.topics = projectObject.topics;
    this.tags = projectObject.tags;
    this.archived = projectObject.archived;
    this.deleted = projectObject.deleted;
    this.priority = projectObject.priority || 'normal';
    this.description = projectObject.description;
    this.linkedInfo = projectObject.linkedInfo || [];

    this.strangeLines = [
      {color: '#409aff'}, {color: '#409aff'}, {color: '#ff4666'}, {color: '#cccfdb'}, {color: '#cccfdb'},
      {color: '#cccfdb'}, {color: '#cccfdb'}, {color: '#cccfdb'}
    ];
  }

  static fromFormGroup(form: FormGroup): Project {
    const formValues = form.value;

    return new Project({
      id: formValues.id,
      title: formValues.title,
      fromTime: formValues.fromTime && formValues.fromDate && new Date(
        formValues.fromDate.getFullYear(),
        formValues.fromDate.getMonth(),
        formValues.fromDate.getDate(),
        formValues.fromTime.getHours(),
        formValues.fromTime.getMinutes(),
      ),
      toTime: formValues.toTime && formValues.toDate && new Date(
        formValues.toDate.getFullYear(),
        formValues.toDate.getMonth(),
        formValues.toDate.getDate(),
        formValues.toTime.getHours(),
        formValues.toTime.getMinutes(),
      ),
      topics: formValues.topics,
      tags: formValues.tags,
      archived: formValues.archived,
      deleted: formValues.deleted,
      priority: formValues.priority,
      description: formValues.description,
      linkedInfo: formValues.linkedInfo,
    });
  }

  static fromMailMessage(message?: MailMessage): Project {
    const project = new Project();

    if (message) {
      project.title = message.subject || '';
      project.description = message.bodyText || '';
      project.participants = message.participants || [];
      project.topics = message.topics || [];
      project.tags = message.tags || [];
      project.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return project;
  }

  static fromEvent(event?: CalendarEvent): Project {
    const project = new Project();

    if (event) {
      project.title = event.title || '';
      project.description = event.description || '';
      project.topics = event.topics || [];
      project.tags = event.tags || [];
      project.linkedInfo = event.id ? [new LinkedInfo('event', project.id)] : [];
      project.fromTime = event.when.start;
      project.toTime = event.when.end;
    }

    return project;
  }

  static fromProject(originalProject?: Project): Project {
    if (originalProject) {
      const project = new Project({...originalProject});
      project.linkedInfo = originalProject.id ? [new LinkedInfo('project', originalProject.id)] : [];
      return project;
    } else {
      return new Project();
    }
  }

  static fromTask(task?: Task): Project {
    const project = new Project();

    if (task) {
      project.title = task.title || '';
      project.description = task.description || '';
      project.participants = task.participants || [];
      project.topics = task.topics || [];
      project.tags = task.tags || [];
      project.linkedInfo = task.id ? [new LinkedInfo('task', task.id)] : [];
      project.fromTime = task.fromTime;
      project.toTime = task.toTime;
    }

    return project;
  }

  static fromNote(note?: Note): Project {
    const project = new Project();

    if (note) {
      project.title = note.title || '';
      project.topics = note.topics || [];
      project.tags = note.tags || [];
      project.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
    }

    return project;
  }

  static fromGroup(group?: Group): Project {
    const project = new Project();

    if (group) {
      project.title = group.name || '';
      project.topics = group.topics || [];
      project.tags = group.tags || [];
      project.linkedInfo = group.id ? [new LinkedInfo('group', group.id)] : [];
    }

    return project;
  }

  static fromContact(contact?: Contact): Project {
    const project = new Project();

    if (contact) {
      project.title = contact.name || '';
      project.topics = contact.topics || [];
      project.tags = contact.tags || [];
      project.linkedInfo = contact.id ? [new LinkedInfo('group', contact.id)] : [];
    }

    return project;
  }

  static fromDragData(dragData: DragData): Project {
    let project: Project = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        project = Project.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        project = Project.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        project = Project.fromProject(<Project>dragItem);
        break;
      case 'task':
        project = Project.fromTask(<Task>dragItem);
        break;
      case 'note':
        project = Project.fromNote(<Note>dragItem);
        break;
      case 'group':
        project = Project.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        project = Project.fromContact(<Contact>dragItem);
        break;
    }

    return project;
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      fromDate: [this.fromTime],
      toDate: [this.toTime],
      fromTime: [this.fromTime],
      toTime: [this.toTime],
      topics: [this.topics || []],
      tags: [this.tags || []],
      archived: [this.archived],
      deleted: [this.deleted],
      priority: [this.priority],
      description: [this.description],
      linkedInfo: [this.linkedInfo || []]
    });
  }

  asPayloadJSON() {
    return {
      title: this.title,
      tags: this.tags,
      fromTime: this.fromTime,
      toTime: this.toTime,
    };
  }
}
