/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../topic/components/topics/topics.component.less.shim.ngstyle";
import * as i1 from "./mail-topics.component.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../topic/components/temporal-expression/temporal-expression.component.ngfactory";
import * as i4 from "../../../topic/components/temporal-expression/temporal-expression.component";
import * as i5 from "../../../elements/components/topic/topic.component.ngfactory";
import * as i6 from "../../../elements/components/topic/topic.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../analytics/services/google-analytics.service";
import * as i9 from "../../../topic/services/topic.service";
import * as i10 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../topic/components/topics-form-popover/topics-form-popover.component.ngfactory";
import * as i13 from "../../../topic/components/topics-form-popover/topics-form-popover.component";
import * as i14 from "../../../search/services/search.service";
import * as i15 from "../../../pipes/pipes/includes-by.pipe";
import * as i16 from "@angular/common";
import * as i17 from "../../../form-controls/components/icon-button/icon-button.component.ngfactory";
import * as i18 from "../../../form-controls/components/icon-button/icon-button.component";
import * as i19 from "../../../popover/directives/popover.directive";
import * as i20 from "../../../popover/services/popover.service";
import * as i21 from "./mail-topics.component";
import * as i22 from "../../services/mail.service";
import * as i23 from "../../../modal/services/modal.service";
var styles_MailTopicsComponent = [i0.styles, i1.styles];
var RenderType_MailTopicsComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_MailTopicsComponent, data: {} });
export { RenderType_MailTopicsComponent as RenderType_MailTopicsComponent };
function View_MailTopicsComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [["class", "topics-placeholder"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["+ Add Threads"]))], null, null); }
function View_MailTopicsComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "app-temporal-expression", [], null, [[null, "click"], [null, "showInCalendar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTemporalExpression(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("showInCalendar" === en)) {
        var pd_1 = (_co.selectTemporalExpression(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_TemporalExpressionComponent_0, i3.RenderType_TemporalExpressionComponent)), i2.ɵdid(1, 114688, null, 0, i4.TemporalExpressionComponent, [], { expression: [0, "expression"], selected: [1, "selected"] }, { showInCalendar: "showInCalendar" }), i2.ɵppd(2, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = i2.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i2.ɵnov(_v.parent, 0), _co.selectedTemporalExpressions, _v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MailTopicsComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "app-topic", [["class", "topics-item"]], null, [[null, "click"], [null, "pinnedChange"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTopic(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("pinnedChange" === en)) {
        var pd_1 = ((_v.context.$implicit.pinned = $event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.handleDeleteTopic(_v.context.index) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_TopicComponent_0, i5.RenderType_TopicComponent)), i2.ɵdid(1, 114688, null, 0, i6.TopicComponent, [i7.Router, i8.GoogleAnalyticsService, i9.TopicService], { topic: [0, "topic"], selected: [1, "selected"], canBeDeleted: [2, "canBeDeleted"], topicStyle: [3, "topicStyle"], highlighted: [4, "highlighted"], pinned: [5, "pinned"] }, { delete: "delete", pinnedChange: "pinnedChange" }), i2.ɵppd(2, 3), i2.ɵppd(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = i2.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i2.ɵnov(_v.parent, 0), _co.selectedTopics, _v.context.$implicit.name, "name")); var currVal_2 = ((((_v.context.$implicit.source === "user") || (_v.context.$implicit.source === "auto_discovery")) || (_v.context.$implicit.source === "auto_add")) || _v.context.$implicit.autoDiscovery); var currVal_3 = (((((_v.context.$implicit.source === "user") || (_v.context.$implicit.source === "auto_discovery")) || (_v.context.$implicit.source === "auto_add")) || _v.context.$implicit.autoDiscovery) ? "manuallyAdded" : "discovered"); var currVal_4 = i2.ɵunv(_v, 1, 4, _ck(_v, 3, 0, i2.ɵnov(_v.parent, 0), _co.highlightTopics, _v.context.$implicit.name)); var currVal_5 = _v.context.$implicit.pinned; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_MailTopicsComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "topics-action mat-icon notranslate"], ["role", "img"], ["svgIcon", "topic-list"]], [[2, "no-topics", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAnnotateTopicsModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i2.ɵdid(1, 9158656, null, 0, i11.MatIcon, [i2.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "topic-list"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.topics.length === 0); var currVal_1 = i2.ɵnov(_v, 1).inline; var currVal_2 = (((i2.ɵnov(_v, 1).color !== "primary") && (i2.ɵnov(_v, 1).color !== "accent")) && (i2.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MailTopicsComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "topics-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setVisibleLength((_co.visibleLength + 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(1, null, [" + ", " more "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.topics.length - _co.visibleLength); _ck(_v, 1, 0, currVal_0); }); }
function View_MailTopicsComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "topics-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setVisibleLength(10) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, [" Show less "]))], null, null); }
function View_MailTopicsComponent_7(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-topics-form-popover", [], null, [[null, "save"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.handleAddTopics($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.handlePopoverFormClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_TopicsFormPopoverComponent_0, i12.RenderType_TopicsFormPopoverComponent)), i2.ɵdid(1, 245760, null, 0, i13.TopicsFormPopoverComponent, [i14.SearchService], { topics: [0, "topics"], error: [1, "error"], loading: [2, "loading"] }, { save: "save", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newTopics; var currVal_1 = _co.formError; var currVal_2 = _co.formLoading; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MailTopicsComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i15.IncludesByPipe, []), (_l()(), i2.ɵeld(1, 0, null, null, 17, "div", [["class", "topics"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_1)), i2.ɵdid(3, 16384, null, 0, i16.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_2)), i2.ɵdid(5, 278528, null, 0, i16.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_3)), i2.ɵdid(7, 278528, null, 0, i16.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵeld(8, 0, null, null, 2, "app-icon-button", [["class", "topics-action"], ["icon", "plus-small"], ["popover", ""], ["popoverAllowedOutsideSelectorsClick", ".mat-autocomplete-panel.mat-autocomplete-visible"], ["popoverPlacement", "bottom"], ["popoverTrigger", "click"]], [[2, "no-topics", null]], null, null, i17.View_IconButtonComponent_0, i17.RenderType_IconButtonComponent)), i2.ɵdid(9, 49152, null, 0, i18.IconButtonComponent, [], { icon: [0, "icon"] }, null), i2.ɵdid(10, 4866048, null, 0, i19.PopoverDirective, [i2.ElementRef, i20.PopoverService, i2.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverCustomTrigger: [3, "popoverCustomTrigger"], popoverAllowedOutsideSelectorsClick: [4, "popoverAllowedOutsideSelectorsClick"], popoverArrow: [5, "popoverArrow"], popoverShowUntil: [6, "popoverShowUntil"] }, null), (_l()(), i2.ɵeld(11, 0, null, null, 1, "app-icon-button", [["class", "topics-action"], ["icon", "settings-small"]], [[2, "no-topics", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showTopicsModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_IconButtonComponent_0, i17.RenderType_IconButtonComponent)), i2.ɵdid(12, 49152, null, 0, i18.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_4)), i2.ɵdid(14, 16384, null, 0, i16.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_5)), i2.ɵdid(16, 16384, null, 0, i16.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MailTopicsComponent_6)), i2.ɵdid(18, 16384, null, 0, i16.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(0, [["createTopicPopover", 2]], null, 0, null, View_MailTopicsComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.topics.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.temporalExpressions; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.visibleTopics; _ck(_v, 7, 0, currVal_2); var currVal_4 = "plus-small"; _ck(_v, 9, 0, currVal_4); var currVal_5 = i2.ɵnov(_v, 19); var currVal_6 = "bottom"; var currVal_7 = "click"; var currVal_8 = _co.popoverCustomTrigger; var currVal_9 = ".mat-autocomplete-panel.mat-autocomplete-visible"; var currVal_10 = false; var currVal_11 = _co.popoverClose; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_13 = "settings-small"; _ck(_v, 12, 0, currVal_13); var currVal_14 = _co.displayAnnotations; _ck(_v, 14, 0, currVal_14); var currVal_15 = (_co.topics.length > _co.visibleTopics.length); _ck(_v, 16, 0, currVal_15); var currVal_16 = (_co.visibleLength > 10); _ck(_v, 18, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.topics.length === 0); _ck(_v, 8, 0, currVal_3); var currVal_12 = (_co.topics.length === 0); _ck(_v, 11, 0, currVal_12); }); }
export function View_MailTopicsComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-mail-topics", [], null, null, null, View_MailTopicsComponent_0, RenderType_MailTopicsComponent)), i2.ɵdid(1, 770048, null, 0, i21.MailTopicsComponent, [i9.TopicService, i22.MailService, i23.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailTopicsComponentNgFactory = i2.ɵccf("app-mail-topics", i21.MailTopicsComponent, View_MailTopicsComponent_Host_0, { topics: "topics", temporalExpressions: "temporalExpressions", selectedTopics: "selectedTopics", selectedTemporalExpressions: "selectedTemporalExpressions", highlightedTopics: "highlightedTopics", newTopic: "newTopic", message: "message" }, { visibleTopicsChange: "visibleTopicsChange", selectedTopicsChange: "selectedTopicsChange", selectedTemporalExpressionsChange: "selectedTemporalExpressionsChange", topicsChange: "topicsChange", temporalExpressionsChange: "temporalExpressionsChange" }, []);
export { MailTopicsComponentNgFactory as MailTopicsComponentNgFactory };
