// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { FilesSidebarFilterKey, SidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';

// Rx
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

@Component({
  selector: 'app-files-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less']
})
export class SideBarComponent implements OnInit, OnDestroy {

  // Public
  public sidebarFilter: FilesSidebarFilterKey;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private readonly sidebarFiltersKey: SidebarFilterKey = 'files';

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private splitViewService: SplitViewService,
    private contentMenuService: ContentMenuService,
  ) {
    this.stateService.getSidebarFilters(this.sidebarFiltersKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((filter: FilesSidebarFilterKey) => {
        this.sidebarFilter = filter;
      });

    this.splitViewService.getMinimized('filesSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        this.contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit () {

  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */


  selectFilter(filter: FilesSidebarFilterKey, event: MouseEvent) {
    this.stateService.setSidebarFilters(this.sidebarFiltersKey, filter);
    event.stopPropagation();
  }
}
