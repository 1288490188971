<app-split-view
  collapseableSide="left"
  key="mailsSidebar"
  [minWidth]="65"
  [shadow]="true"
  [magneticWidth]="120"
>
  <app-sidebar split-left-pane splitViewKey="mailsSidebar">
    <app-mail-side-bar baseRouter="/insights"></app-mail-side-bar>
  </app-sidebar>

  <div split-right-pane class="main">
    <app-nav-bar></app-nav-bar>
    <div class="main-under-nav-bar">

      <div class="main-content">
        <as-split direction="vertical" unit="percent" gutterSize="3">
          <as-split-area [size]="50" minSize="10">
            <as-split direction="horizontal" gutterSize="3">
              <as-split-area [size]="70">
                <app-insights-topic-map
                  [filterFolderDetails]="filters.value?.folder | mailFolderDetails | async"
                  [folder]="filters.value?.folder"
                  [topics]="filters.value?.topics"
                  [contacts]="filters.value?.contacts"
                  [keywords]="filters.value?.keywords"
                  [relatedContact]="filters.value?.relatedContacts ? filters.value?.relatedContacts[0] : null"
                  (selectedTopics)="updateFilters({ relatedTopics: $event })"
                  (changedSelectDate)="updateFilters({ fromTime: $event?.startDate, toTime: $event.endDate })"
                ></app-insights-topic-map>
              </as-split-area>
              <as-split-area [size]="30">
                <app-insights-related-connections
                  [folder]="filters.value?.folder"
                  [topics]="filters.value?.topics"
                  [contacts]="filters.value?.contacts"
                  [keywords]="filters.value?.keywords"
                  [relatedTopics]="filters.value?.relatedTopics"
                  [fromTime]="filters.value?.fromTime"
                  [toTime]="filters.value?.toTime"
                  (connectionSelected)="updateFilters({ relatedContacts: $event ? [$event] : null })"
                ></app-insights-related-connections>
              </as-split-area>
            </as-split>
          </as-split-area>
          <as-split-area [size]="50" minSize="10">
            <app-split-view
              collapseableSide="left"
              key="mailsList"
              [minWidth]="240"
              [shadow]="true"
            >
              <app-messages-list split-left-pane [(selectedMessages)]="selectedMessages"></app-messages-list>
              <div
                split-right-pane
                class="message-content"
                *ngIf="selectedMessages?.length > 0"
              >
                <app-message-content [message]="selectedMessages[0]" ></app-message-content>
                <app-linked-info-toolbar [linkedInfo]="[{type: 'message', id: selectedMessages[0]?.id}]"></app-linked-info-toolbar>
              </div>
              <div split-right-pane class="no-messages-placeholder" *ngIf="selectedMessages?.length < 1">
                <img class="logo" src="assets/placeholders/placeholder-mail.svg"/>
              </div>
            </app-split-view>
          </as-split-area>
        </as-split>
      </div>

    </div>
  </div>
</app-split-view>
<app-menu></app-menu>
