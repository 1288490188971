// Common
import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.less']
})
export class DropdownItemComponent {

  // Inputs
  @Input() title: string;
  @Input() disabled = false;
  @Input() selected = false;
  @Input() checkIcon = false;
  @Input() arrowIcon = false;

  /**
   * Constructor
   */

  constructor(
    public elementRef: ElementRef
  ) {

  }
}
