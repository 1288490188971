// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { Project } from '@modules/tasks/types/project';
import { SidebarFilterKey, TasksSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';

// Services
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { StateService } from '@modules/settings/services/state.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrioritiesListItem } from '@modules/tasks/types/priority-item';
import { TasksListResponse } from '@modules/tasks/types/tasks-list-response';

@Component({
  selector: 'app-tasks-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
  providers: [ContentMenuService]
})

export class TasksSideBarComponent implements OnInit, OnDestroy {

  // Public
  public projects: Project[] = [];
  public tasksCount: number = null;
  public today = new Date();
  public nextWeekDays: Date[] = [];
  public sidebarFilter: TasksSidebarFilterKey;
  public prioritiesList: PrioritiesListItem[] = TasksStateService.prioritiesList;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private sidebarFiltersKey: SidebarFilterKey = 'tasks';

  /**
   * Constructor
   */

  constructor(
    private tasksStateService: TasksStateService,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private stateService: StateService,
    private splitViewService: SplitViewService,
    private contentMenuService: ContentMenuService,
  ) {
    this.stateService.getSidebarFilters(this.sidebarFiltersKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((filter: TasksSidebarFilterKey) => {
        this.sidebarFilter = filter;
      });

    this.splitViewService.getMinimized('tasksSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        this.contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit () {
    this.projectsService.getProjects()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({projects}) => this.projects = projects);

    this.tasksService.getTasks({ limit: 0 })
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({ count }: TasksListResponse) => this.tasksCount = count);

    for (let i = 1; i <= 7; i++) {
      this.nextWeekDays.push(new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + i, 0, 0, 0));
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleProjectClick(project: Project, event: MouseEvent) {
    // 2nd panel
    this.tasksStateService.setProject(project);
    this.selectFilter(project.id, event);

    // 3rd panel
    this.tasksStateService.setMainViewProject(project);
    this.tasksStateService.setMainView('project-form');
  }

  selectFilter(filter: TasksSidebarFilterKey, event: MouseEvent) {
    this.stateService.setSidebarFilters(this.sidebarFiltersKey, filter);
    event.stopPropagation();
  }
}
