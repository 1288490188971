
<div class="text"
  [ngStyle]="{
    fontFamily: event.provider.fontFamily,
    fontWeight: event.provider.fontWeight,
    letterSpacing: event.provider.letterSpacing,
    top: event.provider.y + 'px',
    left: event.provider.x + 'px',
    padding: event.provider.padding,
    fontSize: event.provider.fontSize,
    lineHeight: event.provider.lineHeight
  }"
  [@providerMotion]="{
    value: animationState, 
    params: {
      providerX: event.provider.x + 'px',
      providerY: event.provider.y + 'px',
      providerPadding: event.provider.padding,
      providerFontSize: event.provider.fontSize,
      providerLineHeight: event.provider.lineHeight,
      consumerX: (event.consumer.x + providerTextEndAdjustment) + 'px',
      consumerY: event.consumer.y + 'px',
      consumerPadding: event.consumer.padding,
      consumerFontSize: event.consumer.fontSize,
      consumerLineHeight: event.consumer.lineHeight
    }
  }"
  (@providerMotion.done)="handleAfterAnimation($event)"
>
  {{ event.fromValue | slice : 0 : event.fromValueHighlightRange[0] }}<span
    #highlight
    class="text-visible" 
    [ngStyle]="{color: event.provider.color}"
  >{{ event.fromValue | slice : event.fromValueHighlightRange[0] : event.fromValueHighlightRange[1] }}</span>
  {{ event.fromValue | slice : event.fromValueHighlightRange[1] : event.fromValue.length }}
</div>

<div class="text"
  [ngStyle]="{
    fontFamily: event.consumer.fontFamily,
    fontWeight: event.consumer.fontWeight,
    letterSpacing: event.consumer.letterSpacing
  }"
  [@consumerMotion]="{
    value: animationState, 
    params: {
      providerX: (event.provider.x + consumerTextStartAdjustment) + 'px',
      providerY: event.provider.y + 'px',
      providerPadding: event.provider.padding,
      providerFontSize: event.provider.fontSize,
      providerLineHeight: event.provider.lineHeight,
      consumerX: event.consumer.x + 'px',
      consumerY: event.consumer.y + 'px',
      consumerPadding: event.consumer.padding,
      consumerFontSize: event.consumer.fontSize,
      consumerLineHeight: event.consumer.lineHeight
    }
  }"
>
  <span *ngIf="event?.animationStrategy === 'entireText'">
    {{ event.toValue | slice : 0 : event.toValueHighlightRange[0] }}<span 
      class="text-visible" 
      [ngStyle]="{color: event.consumer.color}"
    >{{ event.toValue | slice : event.toValueHighlightRange[0] : event.toValueHighlightRange[1] }}</span>
    {{ event.toValue | slice : event.toValueHighlightRange[1] : event.toValue.length }}
  </span>
  <span 
    class="text-visible" 
    [ngStyle]="{color: event.consumer.color}"
    *ngIf="event?.animationStrategy === 'highlightOnly'"                           
  >
    {{ event.toValue }}
  </span>
</div>
