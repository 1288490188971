import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
// RX
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MailMessage } from '@modules/mail/types/mail-message';
var EventFormComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventFormComponent(calendarService, modalService, eventsStateService, dynamicPanelService) {
        var _this = this;
        this.calendarService = calendarService;
        this.modalService = modalService;
        this.eventsStateService = eventsStateService;
        this.dynamicPanelService = dynamicPanelService;
        // Inputs
        this.event = new CalendarEvent();
        this.bundledGroup = 'eventForm';
        // Outputs
        this.close = new EventEmitter();
        // Public
        this.eventForm = this.event.asFormGroup();
        this.calendarOptions = [];
        this.saveInProgress = false;
        this.displayFixedBigHeaderShadow = false;
        this.displayDescription = false;
        this.conferencingOptions = [
            { title: 'Hangouts', value: '?', icon: 'social-hangouts' },
            { title: 'Skype', value: '?', icon: 'social-skype' },
            { title: 'Slack', value: '?', icon: 'social-slack' },
            { title: 'Zoom', value: '?', icon: 'social-zoom' },
            { title: 'No Conferencing', value: '?', icon: 'social-no-conf' },
        ];
        // Private
        this.componentNotDestroyed = new Subject();
        this.submitSubscription = new Subject();
        this.calendars = [];
        this.calendarService.getCalendarsList()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendars) {
            _this.calendars = calendars;
            _this.calendarOptions = calendars.map(function (calendar) { return ({
                title: calendar.name,
                value: calendar.id,
                itemClick: function () { return _this.setCurrentCalendar(); }
            }); });
            _this.setDefaultCalendar();
            _this.setCurrentCalendar();
        });
        this.dynamicPanelService.getContent()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var type = _a.type;
            return _this.dynamicPanel = type;
        });
        this.reset();
    }
    EventFormComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if ('event' in changes && this.event) {
            this.reset();
        }
        if ('submit' in changes && this.submit) {
            this.submitSubscription.next();
            this.submit
                .pipe(takeUntil(this.submitSubscription))
                .subscribe(function () { return _this.handleSubmit(); });
        }
    };
    EventFormComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
        this.submitSubscription.next();
        this.submitSubscription.complete();
    };
    /**
     * Actions
     */
    EventFormComponent.prototype.setCurrentCalendar = function () {
        var _this = this;
        this.calendar = this.calendars.find(function (calendar) { return calendar.id === _this.eventForm.controls['calendarId'].value; });
    };
    EventFormComponent.prototype.handleSubmit = function () {
        var _this = this;
        if (!this.eventForm.valid) {
            return;
        }
        this.saveInProgress = true;
        var event = CalendarEvent.fromFormGroup(this.eventForm);
        event.color = event.color && this.calendar && event.color === this.calendar.color ? '' : event.color;
        var saveRequest = this.eventForm.get('id').value ?
            this.calendarService.editEvent(event) : this.calendarService.createEvent(event);
        saveRequest
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () { return _this.close.emit(); }, function () { return _this.handleError(); });
    };
    EventFormComponent.prototype.handleCancel = function () {
        this.close.emit();
    };
    EventFormComponent.prototype.handleError = function () {
        this.saveInProgress = false;
    };
    EventFormComponent.prototype.pin = function () {
        var _this = this;
        this.calendarService.pinEvent([this.event.id], !this.event.pinned)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (events) {
            if (events[0]) {
                _this.event.pinned = events[0].pinned;
            }
        });
    };
    EventFormComponent.prototype.mail = function () {
        this.modalService.compose(MailMessage.fromCalendarEvent(this.event));
    };
    EventFormComponent.prototype.archive = function () {
        var _this = this;
        this.calendarService.archiveEvents([this.event.id], !this.event.archived)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (events) {
            if (events[0]) {
                _this.event.archived = events[0].archived;
            }
        });
    };
    EventFormComponent.prototype.delete = function () {
        this.event.deleted ?
            this.calendarService.deletePermanentlyEvent([this.event.id]) :
            this.calendarService.deleteEvent([this.event.id], true);
        this.eventsStateService.setMainView('calendar');
    };
    EventFormComponent.prototype.openAttachmentsDynamicPanel = function () {
        if (this.dynamicPanel === 'attachments') {
            this.dynamicPanelService.setContent();
        }
        else {
            this.dynamicPanelService.setContent('attachments', {
                attachmentsFormControl: this.eventForm.controls['files'],
                parentTitle: this.event.title, showDropArea: true
            });
        }
    };
    EventFormComponent.prototype.openLinkedInfoDynamicPanel = function () {
        if (this.dynamicPanel === 'linkedInfo') {
            this.dynamicPanelService.setContent();
        }
        else {
            this.dynamicPanelService.setContent('linkedInfo', {
                linkedInfo: this.event.linkedInfo,
                parentLinkedInfo: { type: 'event', id: this.event.id }
            });
        }
    };
    /**
     * Helpers
     */
    EventFormComponent.prototype.reset = function () {
        var _this = this;
        this.eventForm = this.event.asFormGroup();
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        this.setDefaultCalendar();
        this.formSubscription = this.eventForm.valueChanges
            .pipe(startWith(this.eventForm.value), pairwise(), takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), previous = _b[0], current = _b[1];
            if (!_this.datesEqual(previous.startDate, current.startDate) && current.startDate && _this.eventForm.controls.endDate.pristine) {
                _this.eventForm.patchValue({ endDate: current.startDate });
            }
            if (!current.startTime && previous.startTime) {
                current.startTime = previous.startTime;
            }
            if (current.startTime && previous.startTime && !_this.datesEqual(previous.startTime, current.startTime)) {
                var timeDifference = current.startTime.getTime() - previous.startTime.getTime();
                _this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
            }
            if (current.reminder && current.reminder !== previous.reminder) {
                _this.eventForm.patchValue({
                    endDate: _this.eventForm.controls.startDate.value,
                    endTime: current.startTime && new Date(current.startTime.getTime() + 900000)
                }, { emitEvent: false });
            }
        });
        this.setCurrentCalendar();
        this.displayDescription = !!this.event.description;
        this.dynamicPanelService.setContent();
    };
    EventFormComponent.prototype.filesAttached = function (files) {
        this.eventForm.patchValue({ files: files });
    };
    EventFormComponent.prototype.datesEqual = function (date1, date2) {
        return date1 && date2 && date1.getTime() === date2.getTime();
    };
    EventFormComponent.prototype.setDefaultCalendar = function () {
        if (!this.eventForm.controls['calendarId'].value) {
            var writableCalendar = this.calendars.find(function (calendar) { return !calendar.readOnly; });
            if (writableCalendar) {
                this.eventForm.patchValue({ calendarId: writableCalendar.id });
            }
        }
    };
    return EventFormComponent;
}());
export { EventFormComponent };
