import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnInit, SimpleChanges, OnChanges, NgZone, EventEmitter } from '@angular/core';
// RxJS
import { BehaviorSubject, merge } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';
// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';
var NotesListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NotesListComponent, _super);
    /**
     * Constructor
     */
    function NotesListComponent(notesService, linkedInfoService, modalService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.notesService = notesService;
        _this.linkedInfoService = linkedInfoService;
        _this.modalService = modalService;
        _this.ngZone = ngZone;
        _this.placeholderText = 'You have no notes';
        _this.defaultFilters = {};
        // Outputs
        _this.selectedItemsChanged = new EventEmitter();
        // Public
        _this.itemHeight = 92;
        _this.isHover = false;
        _this.showCountView = new BehaviorSubject(true);
        return _this;
    }
    /**
     * Component lifecycle
     */
    NotesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showCountView
            .pipe(filter(function (value) { return !!value; }), debounceTime(5000), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.showCountView.next(false);
        });
        merge(
        // Global refresh button
        this.notesService.getRefresh(), 
        // Subscribe for linked info updates
        this.linkedInfoService.getLinkedInfoUpdate(), 
        // Update notes
        this.notesService.createNewNote, this.notesService.updatedNote, this.notesService.deletedNote)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.refreshCurrentItems();
        });
        _super.prototype.ngOnInit.call(this);
    };
    NotesListComponent.prototype.ngOnChanges = function (changes) {
        this.showCountView.next(true);
        if ('selectedOrder' in changes && this.selectedOrder) {
            this.resetItems();
        }
        if ('defaultFilters' in changes) {
            this.resetItems();
        }
        if ('scrollPosition' in changes && this.scrollPosition !== null) {
            this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
        }
    };
    /**
     * Actions
     */
    NotesListComponent.prototype.getItems = function (offset, limit) {
        return this.notesService
            .getNotes(tslib_1.__assign({}, this.defaultFilters, { order: this.selectedOrder.key, orderWithPinned: true, limit: limit,
            offset: offset }))
            .pipe(map(function (_a) {
            var notes = _a.notes, count = _a.count;
            return ({ items: notes, count: count });
        }));
    };
    NotesListComponent.prototype.resetItems = function () {
        this.selectedItemsChanged.emit([]);
        _super.prototype.resetItems.call(this);
    };
    NotesListComponent.prototype.selectItem = function (note, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        _super.prototype.selectItem.call(this, note, event, selectAll);
        this.selectedItemsChanged.emit(this.selectedItems);
    };
    NotesListComponent.prototype.openNoteInModal = function (note) {
        this.modalService.noteForm(note);
    };
    return NotesListComponent;
}(InfinityScrollListComponent));
export { NotesListComponent };
