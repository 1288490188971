/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts-counter-dropdown.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-avatar/user-avatar.component.ngfactory";
import * as i3 from "../user-avatar/user-avatar.component";
import * as i4 from "../../services/user-avatar.service";
import * as i5 from "../context-menus/context-context-menu-directive/contact-context-menu.directive";
import * as i6 from "../../../popover/directives/popover.directive";
import * as i7 from "../../../popover/services/popover.service";
import * as i8 from "@angular/common";
import * as i9 from "./contacts-counter-dropdown.component";
var styles_ContactsCounterDropdownComponent = [i0.styles];
var RenderType_ContactsCounterDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsCounterDropdownComponent, data: {} });
export { RenderType_ContactsCounterDropdownComponent as RenderType_ContactsCounterDropdownComponent };
function View_ContactsCounterDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "contacts-list-no-reply-mark"]], null, null, null, null, null))], null, null); }
function View_ContactsCounterDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "contacts-list-item"]], [[2, "selected", null], [4, "marginLeft", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectContact(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, "app-user-avatar", [], null, null, null, i2.View_UserAvatarComponent_0, i2.RenderType_UserAvatarComponent)), i1.ɵdid(2, 573440, null, 0, i3.UserAvatarComponent, [i4.UserAvatarService], { contact: [0, "contact"], usePopover: [1, "usePopover"], size: [2, "size"] }, null), i1.ɵdid(3, 212992, null, 0, i5.ContactContextMenuDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ElementRef, i1.ChangeDetectorRef], { appContactContextMenu: [0, "appContactContextMenu"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = true; var currVal_4 = _co.size; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.name === ((_co.selectedContact == null) ? null : _co.selectedContact.name)) && (_v.context.$implicit.email === ((_co.selectedContact == null) ? null : _co.selectedContact.email))); var currVal_1 = ((_v.context.index > 0) ? ((0 - _co.size) * 0.485) : 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ContactsCounterDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "contacts-load-more"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "click"]], null, null, null, null, null)), i1.ɵdid(1, 4866048, null, 0, i6.PopoverDirective, [i1.ElementRef, i7.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverShowUntil: [3, "popoverShowUntil"] }, null), (_l()(), i1.ɵted(2, null, [" +", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 7); var currVal_1 = "right"; var currVal_2 = "click"; var currVal_3 = _co.hidePopover; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.hiddenContactsState.length; _ck(_v, 2, 0, currVal_4); }); }
function View_ContactsCounterDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "hidden-contacts-list-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCollapsedContact(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "hidden-contacts-list-item-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "app-user-avatar", [["size", "30"]], null, null, null, i2.View_UserAvatarComponent_0, i2.RenderType_UserAvatarComponent)), i1.ɵdid(3, 573440, null, 0, i3.UserAvatarComponent, [i4.UserAvatarService], { contact: [0, "contact"], usePopover: [1, "usePopover"], size: [2, "size"] }, null), i1.ɵdid(4, 212992, null, 0, i5.ContactContextMenuDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ElementRef, i1.ChangeDetectorRef], { appContactContextMenu: [0, "appContactContextMenu"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "hidden-contacts-list-item-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "contact_name"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "contact_email"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; var currVal_2 = "30"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = (_v.context.$implicit.name && (_v.context.$implicit.name.length < 1)); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_v.context.$implicit.name || "no name"); _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.$implicit.email; _ck(_v, 9, 0, currVal_6); }); }
function View_ContactsCounterDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "hidden-contacts-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterDropdownComponent_5)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenContactsState; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContactsCounterDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "contacts-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterDropdownComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterDropdownComponent_2)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterDropdownComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["hiddenContacts", 2]], null, 0, null, View_ContactsCounterDropdownComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showNoReplyMark && _co.haveNoReplies); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.visibleContacts; _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.contactList == null) ? null : _co.contactList.length) > _co.minContactLen); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ContactsCounterDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts-counter-dropdown", [], null, null, null, View_ContactsCounterDropdownComponent_0, RenderType_ContactsCounterDropdownComponent)), i1.ɵdid(1, 704512, null, 0, i9.ContactsCounterDropdownComponent, [], null, null)], null, null); }
var ContactsCounterDropdownComponentNgFactory = i1.ɵccf("app-contacts-counter-dropdown", i9.ContactsCounterDropdownComponent, View_ContactsCounterDropdownComponent_Host_0, { contactList: "contactList", selectedContact: "selectedContact", minContactLen: "minContactLen", size: "size", showNoReplyMark: "showNoReplyMark" }, { selectedContactChange: "selectedContactChange" }, []);
export { ContactsCounterDropdownComponentNgFactory as ContactsCounterDropdownComponentNgFactory };
