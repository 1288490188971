import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
var TasksListContainerComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function TasksListContainerComponent(splitViewService, tasksStateService, searchService, stateService) {
        var _this = this;
        this.splitViewService = splitViewService;
        this.tasksStateService = tasksStateService;
        this.searchService = searchService;
        this.stateService = stateService;
        this.quickFormExpanded = false;
        this.quickFormProject = new Project();
        this.quickFormTask = new Task();
        this.quickFormToggle = new Subject();
        this.orders = [
            { name: 'Recency', key: 'recency' },
            { name: 'Date', key: 'date' },
            { name: 'Title', key: 'title' },
        ];
        this.scrollOptions = [
            { name: 'Scroll to top', key: 'scroll-top' },
            { name: 'Scroll to bottom', key: 'scroll-bottom' }
        ];
        // Private
        this.componentNotDestroyed = new Subject();
        this.searchTitle = new BehaviorSubject('');
        combineLatest([
            this.searchService.getSearchParams(),
            this.searchTitle,
            this.tasksStateService.getSidebarFilters()
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), searchParams = _b[0], title = _b[1], sidebarFilters = _b[2];
            var keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];
            _this.listFilters = tslib_1.__assign({}, searchParams, sidebarFilters, { keywords: !title || title.trim() === '' ? keywords : tslib_1.__spread(keywords, [title]) });
        });
    }
    /**
     * Component lifecycle
     */
    TasksListContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splitViewService.getMinimized('tasksList')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (minimized) {
            _this.minimized = minimized;
        });
        this.tasksStateService.getListState()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (state) { return _this.listState = state; });
        this.tasksStateService.getTasksFilters()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (filters) { return _this.listFilters = filters; });
        this.stateService.getSidebarFilters('tasks')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (currentSidebarFilter) { return _this.currentSidebarFilter = currentSidebarFilter; });
        this.tasksStateService.getProject()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (project) { return _this.selectedSidebarProject = project; });
        this.selectedOrder = this.stateService.sortTasksProjectsList;
    };
    TasksListContainerComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    TasksListContainerComponent.prototype.handleScrollOptionChange = function (scrollOption) {
        var _this = this;
        this.listScrollPosition = null;
        setTimeout(function () { return _this.listScrollPosition = scrollOption === 'scroll-top' ? 0 : -1; });
    };
    TasksListContainerComponent.prototype.handleSelectOrder = function (order) {
        this.selectedOrder = order;
        this.stateService.sortTasksProjectsList = order;
    };
    TasksListContainerComponent.prototype.handleNewTask = function (task) {
        if (task === void 0) { task = new Task(); }
        this.tasksStateService.openTaskForm(task);
    };
    TasksListContainerComponent.prototype.handleNewProject = function (project) {
        if (project === void 0) { project = new Project(); }
        this.tasksStateService.openProjectForm(project);
    };
    TasksListContainerComponent.prototype.handleSearchQueryChange = function (title) {
        this.searchTitle.next(title);
    };
    TasksListContainerComponent.prototype.handleProjectQuickFormDrop = function (dragData) {
        var project = Project.fromDragData(dragData);
        if (!project) {
            return;
        }
        this.quickFormProject = project;
        this.quickFormToggle.next();
    };
    TasksListContainerComponent.prototype.handleTaskQuickFormDrop = function (dragData) {
        var task = Task.fromDragData(dragData);
        if (!task) {
            return;
        }
        this.quickFormTask = task;
        this.quickFormToggle.next();
    };
    return TasksListContainerComponent;
}());
export { TasksListContainerComponent };
