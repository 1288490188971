import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnInit, SimpleChanges, OnChanges, NgZone } from '@angular/core';
// RxJS
import { interval, BehaviorSubject, merge } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';
// Env
import { environment } from '@environment';
// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';
var TasksListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TasksListComponent, _super);
    /**
     * Constructor
     */
    function TasksListComponent(tasksService, tasksStateService, linkedInfoService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.tasksService = tasksService;
        _this.tasksStateService = tasksStateService;
        _this.linkedInfoService = linkedInfoService;
        _this.ngZone = ngZone;
        _this.placeholderText = 'You have no tasks';
        _this.defaultFilters = {};
        // Public
        _this.itemHeight = 60;
        _this.isHover = false;
        _this.showCountView = new BehaviorSubject(true);
        _this.tasksStateService.getSelectedTasks()
            .pipe(takeUntil(_this.componentNotDestroyed))
            .subscribe(function (tasks) {
            _this.selectedItems = tasks;
        });
        return _this;
    }
    /**
     * Component lifecycle
     */
    TasksListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showCountView
            .pipe(filter(function (value) { return !!value; }), debounceTime(5000), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.showCountView.next(false);
        });
        merge(
        // Global refresh button
        this.tasksStateService.getRefreshAll(), 
        // Subscribe for linked info updates
        this.linkedInfoService.getLinkedInfoUpdate(), 
        // Make automatic updates for new task
        interval(environment.messageFetchInterval)
            .pipe(filter(function () { return !_this.loading && _this.selectedOrder && _this.selectedOrder.key === 'date'; })), this.tasksService.taskCreated, this.tasksService.taskUpdated, this.tasksService.taskDeleted)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.refreshCurrentItems();
        });
        _super.prototype.ngOnInit.call(this);
    };
    TasksListComponent.prototype.ngOnChanges = function (changes) {
        this.showCountView.next(true);
        if ('selectedOrder' in changes && this.selectedOrder) {
            this.resetItems();
        }
        if ('defaultFilters' in changes) {
            this.resetItems();
        }
        if ('scrollPosition' in changes && this.scrollPosition !== null) {
            this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
        }
    };
    /**
     * Actions
     */
    TasksListComponent.prototype.getItems = function (offset, limit) {
        return this.tasksService
            .getTasks(tslib_1.__assign({}, this.defaultFilters, { limit: limit,
            offset: offset }))
            .pipe(map(function (_a) {
            var tasks = _a.tasks, count = _a.count;
            return ({ items: tasks, count: count });
        }));
    };
    TasksListComponent.prototype.resetItems = function () {
        this.tasksStateService.setSelectedTasks([]);
        _super.prototype.resetItems.call(this);
    };
    TasksListComponent.prototype.selectItem = function (task, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        _super.prototype.selectItem.call(this, task, event, selectAll);
        this.tasksStateService.setSelectedTasks(this.selectedItems);
        if (this.selectedItems.length === 1) {
            this.tasksStateService.setMainViewTask(this.selectedItems[0]);
            this.tasksStateService.setMainView('task-form');
        }
    };
    TasksListComponent.prototype.compareItems = function (item1, item2) {
        return item1 && item2 && item1['id'] === item2['id'];
    };
    return TasksListComponent;
}(InfinityScrollListComponent));
export { TasksListComponent };
