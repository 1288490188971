import { Topic } from '@modules/topic/types/topic';
import { MailFolder } from './mail-folder';
import { MailMessageHighlightedSnippetPart } from './mail-message-highlighted-snippet-part';
import { Recipient } from './recipient';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { Attachment } from '@modules/form-controls/types/attachment';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarContact } from '@modules/events/types/calendar-contact';
import { Note } from '@modules/notes/types/note';

export class MailMessage {
  id: string;
  subject: string;
  snippet: string;
  highlightedSnippet: MailMessageHighlightedSnippetPart[];
  body: string;
  bodyText: string;
  from: Recipient;
  to: Recipient[];
  cc: Recipient[];
  bcc: Recipient[];
  participants: Recipient[];
  sentTime: Date;
  unread: boolean;
  pinned: boolean;
  starred: boolean;
  isDraft: boolean;
  files: Attachment[];
  thread: string;
  threadMessages: {
    count: number,
    unread_count: number,
    messages: MailMessage[]
  };
  topics: Topic[];
  temporalExpressions?: TemporalExpression[];
  labels: MailFolder[];
  draftVersion: number;
  replied: boolean;
  replyToMessage: string;
  forwarded: boolean;
  forwardOfMessage: string;
  snoozed: boolean;
  snoozedTill: Date;
  followed: boolean;
  followUpAt: Date;
  archived: boolean;
  openedAt: Date;
  trackOpens: boolean;
  delay?: Date;
  sending: {
    status: 'pending' | 'sending' | 'error',
    date: Date
  };
  linkedInfo?: LinkedInfo[];
  tags?: Tag[];

  static fromCalendarEvent(calendarEvent?: CalendarEvent): MailMessage {
    const mailMessage =  new MailMessage();

    if (calendarEvent) {
      mailMessage.subject = calendarEvent.title || '';
      mailMessage.body = calendarEvent.description || '';
      mailMessage.to = calendarEvent.participants.map((participant: CalendarContact) => ({
        email: participant.email,
        name: participant.name || ''
      }));
      mailMessage.tags = calendarEvent.tags || [];
      mailMessage.topics = calendarEvent.topics || [];
      mailMessage.linkedInfo = calendarEvent.id ? [new LinkedInfo('event', calendarEvent.id)] : [];
    }

    return mailMessage;
  }

  static fromNote(note?: Note): MailMessage {
    const mailMessage =  new MailMessage();

    if (note) {
      mailMessage.subject = note.title || '';
      mailMessage.body = note.getHtmlBody();
      mailMessage.tags = note.tags || [];
      mailMessage.topics = note.topics || [];
      mailMessage.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
    }

    return mailMessage;
  }
}
