// Common
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';

// Types
import { Group } from '@modules/contacts/types/group';

// Services
import { SectionsTreeService } from '@modules/tasks/services/sections-tree.service';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.less'],
})
export class GroupFormComponent implements OnDestroy {

  // Inputs
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';
  @Input() group: Group;
  @Input() groupForm: FormGroup;
  @Input() bundledGroup: string;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() openLinkedInfoDP: EventEmitter<void> = new EventEmitter();

  // Public
  public conferencingOptions = [
    {title: 'Hangouts', value: '?', icon: 'social-hangouts'},
    {title: 'Skype', value: '?', icon: 'social-skype'},
    {title: 'Slack', value: '?', icon: 'social-slack'},
    {title: 'Zoom', value: '?', icon: 'social-zoom'},
    {title: 'No Conferencing', value: '?', icon: 'social-no-conf'},
  ];

  // Private
  private alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor () {

  }

  /**
   * Lifecycle
   */

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
