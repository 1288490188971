  <div class="quick-form-quick-actions-form">
    <div class="inputs-container">
      <div class="inputs-container-group block">
        <app-input
          placeholder="Type in Due Date or Time"
          appearance="standard"
          width="100%"
          [inputFormControl]="innerAutoFillControl"
          bundledProviderGroup="quickFormInputs"
          [bundledProvider]="['startDate', 'startTime', 'endDate', 'endTime']"
        ></app-input>
      </div>

      <div class="inputs-container-group">
        <app-date-picker
          class="inputs-container-group-item"
          [inputFormControl]="innerStartDateFormControl"
          placeholder="Start date"
          width="103px"
          bundledInputConsumerGroup="quickFormInputs"
          [bundledInputConsumerKeys]="['startDate']"
          bundledInputAppearance="start"
        ></app-date-picker>
        <app-date-picker
          class="inputs-container-group-item"
          [inputFormControl]="innerEndDateFormControl"
          placeholder="End date"
          width="103px"
          bundledInputConsumerGroup="quickFormInputs"
          [bundledInputConsumerKeys]="['endDate']"
          bundledInputAppearance="end"
        ></app-date-picker>
      </div>

      <div class="inputs-container-group" [class.hide]="!editTime">
        <app-time-picker
          class="inputs-container-group-item"
          [inputFormControl]="innerStartTimeFormControl"
          placeholder="Start time"
          bundledInputConsumerGroup="quickFormInputs"
          [bundledInputConsumerKeys]="['startTime']"
          bundledInputAppearance="start"
          [bundledInputInvisible]="!editTime"
        ></app-time-picker>
        <app-time-picker
          class="inputs-container-group-item"
          [inputFormControl]="innerEndTimeFormControl"
          placeholder="End time"
          bundledInputConsumerGroup="quickFormInputs"
          [bundledInputConsumerKeys]="['endTime']"
          bundledInputAppearance="end"
          [bundledInputInvisible]="!editTime"
        ></app-time-picker>
      </div>

      <div
        class="inputs-container-group"
        *ngIf="!editTime && (innerStartTimeFormControl.value || innerEndTimeFormControl.value)"
      >
        <div class="inputs-container-group-item view" *ngIf="innerStartTimeFormControl.value">
          <span>{{ innerStartTimeFormControl.value | date: 'h:mm a' }}</span>
          <mat-icon
            class="inputs-container-group-item-icon"
            svgIcon="close"
            (click)="innerStartTimeFormControl.setValue(null)"
          ></mat-icon>
        </div>

        <div class="inputs-container-group-item view" *ngIf="innerEndTimeFormControl.value">
          <span>{{ innerEndTimeFormControl.value | date: 'h:mm a' }}</span>
          <mat-icon
            class="inputs-container-group-item-icon"
            svgIcon="close"
            (click)="innerEndTimeFormControl.setValue(null)"
          ></mat-icon>
        </div>
      </div>

      <div class="inputs-container-group" *ngIf="showQuickActions">
        <ng-container [ngTemplateOutlet]="quickActionsTemplate"></ng-container>
      </div>

      <div class="inputs-container-group flex-end">
        <!--    Cancel    -->
        <app-button
          *ngIf="editTime"
          appearance="link"
          (click)="handleCancelEditTime()"
        >
          Cancel
        </app-button>

        <!--    Save    -->
        <app-button
          *ngIf="editTime"
          appearance="link-blue"
          (click)="editTime = false"
        >
          Save
        </app-button>

        <!--    Add Time / Edit    -->
        <app-button
          *ngIf="!editTime"
          appearance="link-blue"
          (click)="handleAddTime()"
        >
          {{ !innerStartTimeFormControl.value && !innerEndTimeFormControl.value ? 'Add Time' : 'Edit' }}
        </app-button>
      </div>
    </div>

    <div class="calendar-container">
      <app-tasks-date-picker [inline]="true" [collapseable]="false" [inputFormControl]="innerStartDateFormControl"></app-tasks-date-picker>
    </div>

    <div class="footer">
      <app-button (click)="handleCancel()">Cancel</app-button>
      <app-button appearance="blue" (click)="handleSubmit()">Save</app-button>
    </div>
  </div>

<ng-template #quickActionsTemplate>
  <app-quick-actions
    [startDateFormControl]="innerStartDateFormControl"
    [endDateFormControl]="innerEndDateFormControl"
    [startTimeFormControl]="innerStartTimeFormControl"
    [endTimeFormControl]="innerEndTimeFormControl"
    [priorityFormControl]="innerPriorityFormControl"
    [showQuickActionsForm]="false"
  ></app-quick-actions>
</ng-template>
