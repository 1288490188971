import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
// RX
import { pairwise, startWith, takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
var EventFormPopoverComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventFormPopoverComponent(calendarService, eventsStateService) {
        var _this = this;
        this.calendarService = calendarService;
        this.eventsStateService = eventsStateService;
        // Outputs
        this.close = new EventEmitter();
        this.eventChange = new EventEmitter();
        this.saveInProgress = false;
        this.bundledGroup = 'event-form-popover';
        this.calendarOptions = [];
        // Private
        this.componentNotDestroyed = new Subject();
        this.calendarService.getCalendarsList()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendars) {
            _this.calendarOptions = calendars.map(function (calendar) { return ({
                title: calendar.name,
                value: calendar.id,
            }); });
        });
    }
    /**
     * Component lifecycle
     */
    EventFormPopoverComponent.prototype.ngOnInit = function () {
        var _this = this;
        var event = this.event || new CalendarEvent();
        this.eventForm = event.asFormGroup();
        this.calendarService.getCalendarsList()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendars) {
            var writableCalendar = calendars.find(function (calendar) { return !calendar.readOnly; });
            if (writableCalendar) {
                _this.eventForm.patchValue({ calendarId: writableCalendar.id });
            }
        });
        this.eventForm.valueChanges
            .pipe(startWith(this.eventForm.value), pairwise(), takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), previous = _b[0], current = _b[1];
            if (!_this.datesEqual(previous.startDate, current.startDate) && current.startDate && _this.eventForm.controls.endDate.pristine) {
                _this.eventForm.patchValue({ endDate: current.startDate });
            }
            if (!current.startTime && previous.startTime) {
                current.startTime = previous.startTime;
            }
            if (current.startTime && previous.startTime && !_this.datesEqual(previous.startTime, current.startTime)) {
                var timeDifference = current.startTime.getTime() - previous.startTime.getTime();
                _this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
            }
            if (current.reminder && current.reminder !== previous.reminder) {
                var startTime = _this.eventForm.controls.startTime.value;
                _this.eventForm.patchValue({
                    endDate: _this.eventForm.controls.startDate.value,
                    endTime: startTime && new Date(startTime.getTime() + 900000) // + 15 minutes for right event representing on calendar
                }, { emitEvent: false });
            }
        });
        this.eventForm.valueChanges
            .pipe(debounceTime(700), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.eventChange.emit(CalendarEvent.fromFormGroup(_this.eventForm));
        });
    };
    EventFormPopoverComponent.prototype.ngOnDestroy = function () {
        this.close.emit();
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    EventFormPopoverComponent.prototype.handleSubmit = function () {
        var _this = this;
        if (this.eventForm.invalid) {
            return;
        }
        var event = CalendarEvent.fromFormGroup(this.eventForm);
        this.saveInProgress = true;
        var saveRequest = this.eventForm.get('id').value ?
            this.calendarService.editEvent(event) : this.calendarService.createEvent(event);
        saveRequest
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () { return _this.close.emit(); }, function () { return _this.handleError(); });
    };
    EventFormPopoverComponent.prototype.handleCancel = function () {
        this.close.emit();
    };
    EventFormPopoverComponent.prototype.handleError = function () {
        this.saveInProgress = false;
    };
    EventFormPopoverComponent.prototype.handleCopy = function () {
        this.eventsStateService.addToClipboard([CalendarEvent.fromFormGroup(this.eventForm)], 'copy');
        this.close.emit();
    };
    EventFormPopoverComponent.prototype.moreOptions = function () {
        this.eventsStateService.setMainViewEvent(CalendarEvent.fromFormGroup(this.eventForm));
        this.eventsStateService.setMainView('event-form');
        this.close.emit();
    };
    EventFormPopoverComponent.prototype.datesEqual = function (date1, date2) {
        return date1 && date2 && date1.getTime() === date2.getTime();
    };
    return EventFormPopoverComponent;
}());
export { EventFormPopoverComponent };
