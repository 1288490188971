<app-link-info-suggestions
  [inputFormControl]="inputFormControl"
  [appearance]="appearance"
  title="Link To Task"
  [createTitle]="'Create New Task'"
  [items]="suggestions"
  [itemTemplateRef]="suggestionsItem"
  (close)="closed()"
  (create)="creating()"
>
</app-link-info-suggestions>

<ng-template #suggestionsItem let-task="item">
  <app-task
    [task]="task"
    [actionsButtonEnabled]="false"
    (click)="selectedLinkedInfo({type: 'event', id: event.id})"
  ></app-task>
</ng-template>
