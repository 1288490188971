// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.less'],
})
export class TagsInputComponent {

  // Inputs
  @Input() inputFormControl: FormControl;

  /**
   * Constructor
   */

  constructor() {

  }
}
