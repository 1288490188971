<div class="box">
  <div class="box-header with-border">
    <h3 class="box-title">Configure your auto reply option</h3>
  </div>

  <div class="box-body with-padding">
    <div class="alert alert-success" *ngIf="success && errorMessage.length < 1">Autoreply template is saved</div>
    <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>

    <div class="checkbox">
      <label for="enabledCheckbox"><input id="enabledCheckbox" type="checkbox" [(ngModel)]="enabled">Enable auto reply</label>
    </div>

    <div class="autoreply-container">
      <div class="date-time-pick">
        <app-date-picker [placeholder]="'Start Date'" [inputFormControl]="startDate" width="115px"></app-date-picker>
      </div>
      <div class="date-time-pick">
        <app-date-picker [placeholder]="'End Date'" [inputFormControl]="endDate" width="115px"></app-date-picker>
      </div>
    </div>

    <p>
      <app-text-editor [(content)]="autoReply.content"></app-text-editor>
    </p>
    <p><button class="btn btn-primary" (click)="saveAutoReply()">Save</button></p>
  </div>
</div>
