// Common
import { Component, OnInit } from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.less']
})
export class AppMenuComponent implements OnInit {

  // Public
  public isAppMenuExpanded = true;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private modalService: ModalService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.isAppMenuExpanded = this.stateService.isAppMenuExpanded;
  }

  /**
   * Actions
   */

  toggle() {
    this.stateService.isAppMenuExpanded = !this.stateService.isAppMenuExpanded;
    this.isAppMenuExpanded = this.stateService.isAppMenuExpanded;
  }

  openSettings() {
    this.modalService.settings();
  }
}
