import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@environment';

const API_URL = 'https://maps.googleapis.com/maps/api/staticmap?';

@Component({
  selector: 'app-image-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.less']
})

export class MapPreviewComponent implements OnInit, OnChanges {

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() mapSize = { width: 246, height: 62 };
  @Input() mapType: 'roadmap' | 'satellite' | 'hybrid' | 'terrain' = 'roadmap';
  @Input() mapFormat: 'gif' | 'jpg' | 'png' | 'png32' | 'jpg-baseline' = 'png';
  @Input() mapMarkers: { color: string, label: string, latitude: number, longitude: number, size: string }[] = [];
  @Input() mapZoom = 15;

  public imgUrl: string;

  /**
   * Constructor
   */

  constructor() {
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapMarkers && this.longitude && this.longitude) {
      this.mapMarkers = this.generateCenterMarker();
    }
    this.imgUrl = this.generateUrl();
  }

  generateCenterMarker() {
    return [{ color: 'red', label: '', latitude: this.latitude, longitude: this.longitude, size: 'mid' }];
  }

  generateUrl() {
    let url = API_URL + `center=${this.latitude},${this.longitude}&zoom=${this.mapZoom}&`;
    url += `maptype=${this.mapType}&`;
    url += `size=${this.mapSize.width}x${Math.max(this.mapSize.height, 100)}&`;
    url += `format=${this.mapFormat}&`;
    url += `key=${environment.googleMapsKey}&`;

    url += this.mapMarkers.map(marker => {
      return `markers=color:${marker.color}|label:${marker.label}|size:${marker.size || 'mid'}|${marker.latitude},${marker.longitude}&`;
    });

    return url;
  }

}
