<div class="board">
  <div class="board-area">
    <app-project-board-column
      *appDraggableListFor="
        let column of columnsTree?.columns | async;
        trackBy: columnsTrackByFn;
        repositionStream: repositionStream;
        let index = index;
        predicate: dndPredicate;
        direction: 'horizontal';
        zIndex: 1;
      "
      [column]="column"
      [columnsTree]="columnsTree"
    ></app-project-board-column>
  </div>
</div>
