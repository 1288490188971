import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoMessageListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoMessageListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoMessageListComponent(modalService, mailService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.mailService = mailService;
        _this.ngZone = ngZone;
        _this.type = 'message';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoMessageListComponent.prototype.getItems = function (ids) {
        return this.mailService.getMessages({ ids: ids }, 'date', ids.length, 0)
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.messages; }));
    };
    /**
     * Actions
     */
    LinkedInfoMessageListComponent.prototype.select = function (message) {
        if (message.isDraft &&
            !(message.sending && (message.sending.status === 'sending' || message.sending.status === 'pending'))) {
            this.modalService.compose(message);
            return;
        }
        this.modalService.mailContent(message);
    };
    return LinkedInfoMessageListComponent;
}(LinkInfoPopover));
export { LinkedInfoMessageListComponent };
