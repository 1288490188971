import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
})
export class ButtonComponent {

  // Inputs
  @Input() icon: string;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() appearance: 'default' | 'blue' | 'link' | 'link-blue' | 'big' = 'default';
  @Input() disabled = false;
  @Input() menu = false;

  // Output
  @Output() menuClick = new EventEmitter<ElementRef>();

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef
  ) {
  }

  /**
   * Actions
   */

  menuButtonAction(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.menuClick.emit(this.elementRef);
  }

}
