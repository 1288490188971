/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./container-overflow-shadow.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./container-overflow-shadow.component";
var styles_ContainerOverflowShadowComponent = [i0.styles];
var RenderType_ContainerOverflowShadowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContainerOverflowShadowComponent, data: {} });
export { RenderType_ContainerOverflowShadowComponent as RenderType_ContainerOverflowShadowComponent };
function View_ContainerOverflowShadowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "top-shadow"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "white": 0, "gray": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top-shadow"; var currVal_1 = _ck(_v, 3, 0, (_co.appearance === "white"), (_co.appearance === "gray")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ContainerOverflowShadowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bottom-shadow"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "white": 0, "gray": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bottom-shadow"; var currVal_1 = _ck(_v, 3, 0, (_co.appearance === "white"), (_co.appearance === "gray")); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ContainerOverflowShadowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contentElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-overflow-shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["content", 1]], null, 1, "div", [["class", "overflow-content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContainerOverflowShadowComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContainerOverflowShadowComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTopShadow; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showBottomShadow; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ContainerOverflowShadowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-container-overflow-shadow", [], null, null, null, View_ContainerOverflowShadowComponent_0, RenderType_ContainerOverflowShadowComponent)), i1.ɵdid(1, 6471680, null, 0, i3.ContainerOverflowShadowComponent, [], null, null)], null, null); }
var ContainerOverflowShadowComponentNgFactory = i1.ɵccf("app-container-overflow-shadow", i3.ContainerOverflowShadowComponent, View_ContainerOverflowShadowComponent_Host_0, { appearance: "appearance" }, {}, ["*"]);
export { ContainerOverflowShadowComponentNgFactory as ContainerOverflowShadowComponentNgFactory };
