import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dropdown-template-items',
  templateUrl: './dropdown-template-items.component.html',
  styleUrls: ['./dropdown-template-items.component.less']
})
export class DropdownTemplateItemsComponent {

  // Inputs
  @Input() title: string;
  @Input() options: any[];
  @Input() divider = false;
  @Input() optionTemplateRef?: TemplateRef<any>;

  // Outputs
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  // Public
  public select = (option?: any) => {
    this.selectedChange.emit(option);
  }

  /**
   * Constructor
   */

  constructor() {

  }
}
