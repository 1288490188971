// Common
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-quick-actions-form',
  templateUrl: './quick-actions-form.component.html',
  styleUrls: ['./quick-actions-form.component.less']
})
export class QuickActionsFormComponent implements OnInit {

  // Inputs
  @Input() startDateFormControl: AbstractControl;
  @Input() endDateFormControl: AbstractControl;
  @Input() startTimeFormControl: AbstractControl;
  @Input() endTimeFormControl: AbstractControl;
  @Input() priorityFormControl: AbstractControl;

  @Input() showQuickActions = true;

  // Outputs
  @Output() readonly close = new EventEmitter();
  @Output() readonly submit = new EventEmitter();

  // Public
  public innerStartDateFormControl = new FormControl();
  public innerEndDateFormControl = new FormControl();
  public innerStartTimeFormControl = new FormControl();
  public innerEndTimeFormControl = new FormControl();
  public innerPriorityFormControl = new FormControl();
  public innerAutoFillControl = new FormControl('');

  public editTime = false;

  // Private
  private previousInnerStartTimeFormControl = new FormControl();
  private previousInnerEndTimeFormControl = new FormControl();

  // Constructor
  constructor(
  ) {
  }

  // Component lifecycle
  public ngOnInit(): void {
    this.resetInnerFormControls();
  }

  // Actions
  public handleAddTime(): void {
    this.editTime = true;
    this.previousInnerStartTimeFormControl.setValue(this.innerStartTimeFormControl.value);
    this.previousInnerEndTimeFormControl.setValue(this.innerEndTimeFormControl.value);
  }

  public handleCancelEditTime(): void {
    this.innerStartTimeFormControl.setValue(this.previousInnerStartTimeFormControl.value);
    this.innerEndTimeFormControl.setValue(this.previousInnerEndTimeFormControl.value);
    this.editTime = false;

    this.previousInnerStartTimeFormControl.setValue(null);
    this.previousInnerEndTimeFormControl.setValue(null);
  }

  public handleSubmit(): void {
    if (this.editTime) { this.handleCancelEditTime(); }
    this.startDateFormControl.setValue(this.innerStartDateFormControl.value);
    this.endDateFormControl.setValue(this.innerEndDateFormControl.value);
    this.startTimeFormControl.setValue(this.innerStartTimeFormControl.value);
    this.endTimeFormControl.setValue(this.innerEndTimeFormControl.value);
    this.priorityFormControl.setValue(this.innerPriorityFormControl.value);
    this.resetInnerFormControls();
    this.submit.emit();
  }

  public handleCancel(): void {
    this.resetInnerFormControls();
    this.close.emit();
  }

  private resetInnerFormControls(): void {
    if (this.editTime) { this.editTime = false; }
    this.innerStartTimeFormControl.setValue(this.startTimeFormControl.value);
    this.innerEndTimeFormControl.setValue(this.endTimeFormControl.value);
    this.innerStartDateFormControl.setValue(this.startDateFormControl.value);
    this.innerEndDateFormControl.setValue(this.endDateFormControl.value);
    this.innerPriorityFormControl.setValue(this.priorityFormControl.value);
    this.innerAutoFillControl.setValue('');
  }
}
