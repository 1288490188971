/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-side-bar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./account-side-bar.component";
import * as i5 from "../../../auth/services/auth.service";
var styles_AccountSideBarComponent = [i0.styles];
var RenderType_AccountSideBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountSideBarComponent, data: {} });
export { RenderType_AccountSideBarComponent as RenderType_AccountSideBarComponent };
function View_AccountSideBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "account-side-bar__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectRoute(_v.parent.context.$implicit.route) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "active": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "account-side-bar__item"; var currVal_1 = _ck(_v, 4, 0, (_v.parent.context.$implicit.route === _co.routePath)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "fa fa-", _v.parent.context.$implicit.icon, " icon"); _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.name; _ck(_v, 7, 0, currVal_3); }); }
function View_AccountSideBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "account-side-bar__item"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 2), i1.ɵdid(4, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, [2, i3.RouterLink], [2, i3.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["\u00A0", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/account", _v.parent.context.$implicit.route); _ck(_v, 2, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "fa fa-", _v.parent.context.$implicit.icon, " icon"); _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.name; _ck(_v, 8, 0, currVal_3); }); }
function View_AccountSideBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSideBarComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSideBarComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isModal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isModal; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AccountSideBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "account-side-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "account-sections"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSideBarComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-default btn-flat btn-sign-out"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sections; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AccountSideBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-side-bar", [], null, null, null, View_AccountSideBarComponent_0, RenderType_AccountSideBarComponent)), i1.ɵdid(1, 245760, null, 0, i4.AccountSideBarComponent, [i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountSideBarComponentNgFactory = i1.ɵccf("app-account-side-bar", i4.AccountSideBarComponent, View_AccountSideBarComponent_Host_0, { isModal: "isModal", routePath: "routePath" }, { routePathChange: "routePathChange" }, []);
export { AccountSideBarComponentNgFactory as AccountSideBarComponentNgFactory };
