<div class="find-folder__wrapper">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="input-container app-form-controls-input-standard">
    <mat-form-field [appearance]="'standard'" [ngStyle]="{width: '100%'}" [floatLabel]="'never'">
      <input matInput [placeholder]="'Folder name...'" [formControl]="inputFormControl">
    </mat-form-field>
  </div>
  <app-container-overflow-shadow appearance="white">
    <div class="suggections">
      <div class="item" *ngFor="let folder of filteredFolders" (click)="selectFolder(folder)">{{ folder.name }}</div>
      <div class="placeholder" *ngIf="!filteredFolders?.length">Not found</div>
    </div>
  </app-container-overflow-shadow>
</div>
