// Common
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';

// Services
import { SearchService } from '@modules/search/services/search.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { MessagesListStateService, MESSAGES_LIST_STORE_KEY } from '@modules/mail/services/messages-list-state.service';

// Components
import { SplitViewComponent } from '@modules/split-view/components/split-view/split-view.component';

// Types
import { SearchQueryParams } from '@modules/search/types/search-query-params';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Recipient } from '@modules/mail/types/recipient';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { Topic } from '@modules/topic/types/topic';
import { DynamicPanelContent } from '@modules/pages/types/dynamic-panel-data';

@Component({
  selector: 'app-mail-layout',
  templateUrl: './mail-layout.component.html',
  styleUrls: ['./mail-layout.component.less'],
  providers: [
    { provide: MESSAGES_LIST_STORE_KEY, useValue: 'MainMessagesList' },
    MessagesListStateService
  ]
})
export class MailLayoutComponent implements OnInit, OnDestroy {


  @ViewChild('splitView', {static: true}) splitView: SplitViewComponent;

  // Public
  public folder: string;
  public searchParams: SearchQueryParams;
  public selectedMessages: MailMessage[] = [];
  public selectedContact: Recipient = null;
  public selectedTopics: string[] = [];
  public selectedRelatedContacts: Recipient[] = [];
  public selectedRelatedTopics: string[] = [];
  public selectedTemporalExpressions: TemporalExpression[] = [];
  public visibleTopics: Topic[];
  public pannelsSelectedMessages: {
    pinned: MailMessage[],
    main: MailMessage[],
    extraSearch: MailMessage[],
  } = {
    pinned: [],
    main: [],
    extraSearch: [],
  };
  public dynamicPanelContent: DynamicPanelContent;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private dynamicPanelService: DynamicPanelService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private messagesListStateService: MessagesListStateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((content) => {
        this.dynamicPanelContent = content;
        if (this.dynamicPanelContent.type === 'attachments' || this.dynamicPanelContent.type === 'linkedInfo') {
          this.splitView.open();
        }
      });
    this.route.paramMap
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((params: ParamMap) => {
        this.folder = params.get('folder') !== 'search' ? params.get('folder') : null;
      });

    this.searchService.getSearchParams()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((params: SearchQueryParams) => {
        this.searchParams = params;
      });

    combineLatest([
      this.route.paramMap
        .pipe(
          map((params: ParamMap) => params.get('folder') !== 'search' ? params.get('folder') : null),
          startWith(<string>null)
        ),
      this.searchService.getSearchParams()
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([folder, searchParams]) => {
        this.messagesListStateService.setFilters({
          folder,
          topics: searchParams ? searchParams.topics : null,
          tags: searchParams ? searchParams.tags : null,
          contacts: searchParams ? searchParams.contacts : null,
          keywords: searchParams ? searchParams.keywords : null,
          groupByThread: true,
          orderWithPinned: true
        });
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectMessages(messages: MailMessage[], pannel: 'pinned' | 'main' | 'extraSearch') {
    for (const key of Object.keys(this.pannelsSelectedMessages)) {
      // recreate messages array for proper ngOnChange handling
      this.pannelsSelectedMessages[key] = key === pannel ? [...messages] : [];
    }
    this.selectedMessages = messages;
    this.changeDetector.detectChanges();
  }

  selectTopics(topics: Topic[]) {
    this.selectedTopics = topics ? topics.map(topic => topic.name) : [];
    if (topics && topics.length) { this.openKnowledgePanelSplit(); }
  }

  selectTemporalExpressions(expression: TemporalExpression[]) {
    this.selectedTemporalExpressions = expression || [];
    if (expression && expression.length) { this.openKnowledgePanelSplit(); }
  }

  selectContact(contact: Recipient) {
    this.selectedContact = contact;
    if (contact) { this.openKnowledgePanelSplit(); }
  }

  openKnowledgePanelSplit() {
    this.splitView.open();
  }

}
