import { Tab } from './tab';

export class TabInfo {
  tab: Tab;
  tooltip: string;
  icon: string;
  disabled: boolean;

  constructor(tab: Tab, tooltip: string, icon: string, disabled: boolean = false) {
    this.tab = tab;
    this.tooltip = tooltip;
    this.icon = icon;
    this.disabled = disabled;
  }
}
