// Common
import { Component, Input, OnInit } from '@angular/core';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Types
import { Task } from '@modules/tasks/types/task';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';

// RX
import { tap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-task-context-menu',
  templateUrl: './task-context-menu.component.html',
  styleUrls: ['./task-context-menu.component.less']
})
export class TaskContextMenuComponent extends ContextMenuComponent implements OnInit {

  // Public
  public selectedTasks: Task[] = [];

  // Private
  private includesInSelected = false;

  // Inputs
  @Input() task: Task;

  /**
   * Constructor
   */

  constructor(
    private ga: GoogleAnalyticsService,
    protected contextMenuService: ContextMenuService,
    private tasksService: TasksService,
    private tasksStateService: TasksStateService,
  ) {
    super(contextMenuService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.tasksStateService.getSelectedTasks()
      .pipe(
        tap((tasks: Task[]) => {
          this.includesInSelected = tasks.length > 1 && tasks.map(task => task.id).includes(this.task.id);
        }),
        takeUntil(this.alive)
      )
      .subscribe((tasks: Task[]) => {
        this.selectedTasks = tasks;
      });
  }

  /**
   * Actions
   */

  pin(tasks: Task[], pinned: boolean) {
    this.ga.trackEvent('task-context-menu', pinned ? 'unpin' : 'pin');
    this.tasksService.pin(tasks.filter(task => task.pinned === pinned).map(task => task.id), !pinned);
  }

  delete(tasks: Task[], deleted: boolean) {
    this.ga.trackEvent('task-context-menu', deleted ? 'restore-from-trash' : 'delete');
    this.tasksService.delete(tasks.filter(task => task.deleted === deleted).map(task => task.id), !deleted);
  }

  archive(tasks: Task[], archived: boolean) {
    this.ga.trackEvent('task-context-menu', archived ? 'restore-from-archive' : 'move-to-archive');
    this.tasksService.archive(
      tasks.filter(task => task.archived === archived).map(task => task.id), !archived);
  }

  deletePermanently() {
    this.ga.trackEvent('task-context-menu', 'delete-permanently');
    this.tasksService.deletePermanently(
      (this.includesInSelected ? this.selectedTasks : [this.task]).map(task => task.id)
    );
  }

  edit() {
    this.ga.trackEvent('task-context-menu', 'edit');
    this.tasksStateService.setMainViewTask(this.selectedTasks[0] || this.task);
    this.tasksStateService.setMainView('task-form');
  }
}
