import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDynamicSizeVirtualScrollDirective } from './directives/cdk-dynamic-size-virtual-scroll.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CdkDynamicSizeVirtualScrollDirective],
  exports: [
    CdkDynamicSizeVirtualScrollDirective
  ]
})
export class VirtualScrollModule { }
