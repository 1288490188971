import * as tslib_1 from "tslib";
// Common
import { OnDestroy, AfterViewInit, OnInit } from '@angular/core';
// RX
import { takeUntil, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
// Other
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
var EventContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EventContextMenuComponent, _super);
    /**
     * Constructor
     */
    function EventContextMenuComponent(ga, contextMenuService, calendarService, eventsStateService, linkedInfoService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.ga = ga;
        _this.contextMenuService = contextMenuService;
        _this.calendarService = calendarService;
        _this.eventsStateService = eventsStateService;
        _this.linkedInfoService = linkedInfoService;
        // Public
        _this.selectedEvents = [];
        _this.linkedInfo = [];
        _this.allEventsDeleted = false;
        _this.allEventsArchived = false;
        _this.allEventsPinned = false;
        // Private
        _this.includesInSelected = false;
        return _this;
    }
    EventContextMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.eventsStateService.getSelectedEvents()
            .pipe(tap(function (events) {
            // To be moved in ngOnChanges after refactoring
            if (!events || events.length === 0) {
                return;
            }
            _this.allEventsDeleted = events.filter(function (event) { return event.deleted; }).length === events.length;
            _this.allEventsArchived = events.filter(function (event) { return event.archived; }).length === events.length;
            _this.allEventsPinned = events.filter(function (event) { return event.pinned; }).length === events.length;
        }), tap(function (events) {
            _this.includesInSelected = events.length > 1 && events.map(function (event) { return event.id; }).includes(_this.event.id);
        }), takeUntil(this.alive))
            .subscribe(function (events) {
            _this.selectedEvents = events;
        });
    };
    EventContextMenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        _super.prototype.ngAfterViewInit.call(this);
        merge(this.singleItemMenu.close, this.multipleItemsMenu.close)
            .pipe(takeUntil(this.alive))
            .subscribe(function () {
            _this.close.emit();
        });
    };
    /**
     * Actions
     */
    EventContextMenuComponent.prototype.getContextMenu = function () {
        if (this.includesInSelected) {
            this.linkedInfo = this.selectedEvents.map(function (selectedEvent) { return ({ type: 'event', id: selectedEvent.id }); });
            return this.multipleItemsMenu;
        }
        return this.singleItemMenu;
    };
    EventContextMenuComponent.prototype.editEvent = function (event) {
        this.ga.trackEvent('event-context-menu', 'edit');
        this.eventsStateService.setMainViewEvent(CalendarEvent.fromFormGroup(event.asFormGroup()));
        this.eventsStateService.setMainView('event-form');
    };
    EventContextMenuComponent.prototype.cutEvents = function () {
        this.ga.trackEvent('event-context-menu', 'cut');
        this.eventsStateService.addToClipboard(this.includesInSelected ? this.selectedEvents : [this.event], 'cut');
    };
    EventContextMenuComponent.prototype.copyEvents = function () {
        this.ga.trackEvent('event-context-menu', 'copy');
        this.eventsStateService.addToClipboard(this.includesInSelected ? this.selectedEvents : [this.event], 'copy');
    };
    EventContextMenuComponent.prototype.pasteEvents = function () {
        this.ga.trackEvent('event-context-menu', 'paste');
    };
    EventContextMenuComponent.prototype.pinEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'pin');
        this.calendarService.pinEvent(events.filter(function (event) { return event.pinned !== true; }).map(function (event) { return event.id; }), true);
    };
    EventContextMenuComponent.prototype.unpinEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'unpin');
        this.calendarService.pinEvent(events.filter(function (event) { return event.pinned === true; }).map(function (event) { return event.id; }), false);
    };
    EventContextMenuComponent.prototype.printEvents = function (events) {
        var _this = this;
        events.forEach(function (event) {
            _this.ga.trackEvent('event-context-menu', 'print');
        });
    };
    EventContextMenuComponent.prototype.deleteEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'delete');
        this.calendarService.deleteEvent(events.filter(function (event) { return event.deleted !== true && !event.readOnly; }).map(function (event) { return event.id; }), true);
    };
    EventContextMenuComponent.prototype.deletePermanentlyEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'delete-permanently');
        this.calendarService.deletePermanentlyEvent(events.filter(function (event) { return event.deleted === true && !event.readOnly; }).map(function (event) { return event.id; }));
    };
    EventContextMenuComponent.prototype.restoreFromTrashEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'restore-from-trash');
        this.calendarService.deleteEvent(events.filter(function (event) { return event.deleted === true; }).map(function (event) { return event.id; }), false);
    };
    EventContextMenuComponent.prototype.moveToArchiveEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'move-to-archive');
        this.calendarService.archiveEvents(events
            .filter(function (event) { return event.archived === false; })
            .map(function (event) { return event.id; }), true);
    };
    EventContextMenuComponent.prototype.restoreFromArchiveEvents = function (events) {
        this.ga.trackEvent('event-context-menu', 'restore-from-archive');
        this.calendarService.archiveEvents(events
            .filter(function (event) { return event.archived === true; })
            .map(function (event) { return event.id; }), false);
    };
    EventContextMenuComponent.prototype.linkEvents = function () {
        this.linkedInfoService.linkItems(tslib_1.__spread(this.linkedInfo));
    };
    /**
     * Methods
     */
    EventContextMenuComponent.prototype.closeContextMenu = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    return EventContextMenuComponent;
}(ContextMenuComponent));
export { EventContextMenuComponent };
