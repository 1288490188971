<div class="event-form">
  <form [formGroup]="eventForm" (ngSubmit)="handleSubmit()">

    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-full-width-item">
        <app-event-title-input 
          placeholder="Title" 
          [inputFormControl]="eventForm.controls['title']"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['title']"
        ></app-event-title-input>
      </div>
    </div>

    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-item event-form-horizontal-group-item-closer">
        <app-date-picker 
          placeholder="Start Date" 
          [inputFormControl]="eventForm.controls['startDate']" 
          width="108px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['startDate']"
          bundledInputAppearance="start"
        ></app-date-picker>
      </div>

      <div
        class="event-form-horizontal-group-item event-form-horizontal-group-item-closer"
        *ngIf="!eventForm?.value?.allDay || eventForm?.value?.reminder && !eventForm?.value?.allDay"
      >
        <app-time-picker 
          placeholder="Start Time" 
          [inputFormControl]="eventForm.controls['startTime']" 
          width="80px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['startTime']"
          bundledInputAppearance="start"
        ></app-time-picker>
      </div>
    </div>
  
    <div class="event-form-horizontal-group">
      <div
        class="event-form-horizontal-group-item event-form-horizontal-group-item-closer"
        *ngIf="!eventForm?.value?.reminder"
      >
        <app-date-picker 
          placeholder="End Date" 
          [inputFormControl]="eventForm.controls['endDate']" 
          width="108px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['endDate']"
          bundledInputAppearance="end"
        ></app-date-picker>
      </div>
      <div
        class="event-form-horizontal-group-item event-form-horizontal-group-item-closer"
        *ngIf="!eventForm?.value?.allDay && !eventForm?.value?.reminder"
      >
        <app-time-picker 
          placeholder="End Time" 
          [inputFormControl]="eventForm.controls['endTime']" 
          width="80px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['endTime']"
          bundledInputAppearance="end"
        ></app-time-picker>
      </div>
    </div>

    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-item">
        <app-dropdown-select 
          placeholder="Calendar"
          [inputFormControl]="eventForm.controls['calendarId']"
          [options]="calendarOptions"
          width="108px"
        ></app-dropdown-select>
      </div>
      <div class="event-form-horizontal-group-item">
        <app-checkbox [title]="'All day'" [inputFormControl]="eventForm.controls['allDay']"></app-checkbox>
      </div>
      <div class="event-form-horizontal-group-item">
        <app-checkbox [title]="'Reminder'" [inputFormControl]="eventForm.controls['reminder']"></app-checkbox>
      </div>
    </div>

    <div class="event-form-buttons">
      <app-button
        class="event-form-buttons-item"
        (click)="handleMoreOptions()"
      >
        More Options
      </app-button>

      <app-button 
        class="event-form-buttons-item"
        type="submit"
        appearance="blue"
        [disabled]="saveInProgress"
      >
        Add Event
      </app-button>
    </div>
     
  </form>
</div>
