// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

// Modules
import { MatIconModule } from '@angular/material/icon';

// Components
import { AppMenuComponent } from './components/app-menu/app-menu.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatIconModule,
    RouterModule,
  ],

  exports: [
    AppMenuComponent,
  ],

  declarations: [
    AppMenuComponent,
  ],

  providers: [],
  entryComponents: [],
})
export class AppMenuModule { }
