// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { MessagesListStateService, MESSAGES_LIST_STORE_KEY } from '@modules/mail/services/messages-list-state.service';

@Component({
  selector: 'app-messages-list-panel',
  templateUrl: './messages-list-panel.component.html',
  styleUrls: ['./messages-list-panel.component.less'],
  providers: [
    { provide: MESSAGES_LIST_STORE_KEY, useValue: 'KPMessagesPannel' },
    MessagesListStateService
  ]
})
export class MessagesListPanelComponent implements OnInit, OnDestroy {

  // Public
  public folder: string;

  // Private
  private alive = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private messagesListStateService: MessagesListStateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.messagesListStateService.setFilters({ folder: this.stateService.knowledgePanelFolder });
    this.messagesListStateService.getFilters()
      .pipe(
        map(filters => filters.folder),
        filter(folder => folder !== this.stateService.knowledgePanelFolder),
        takeUntil(this.alive)
      )
      .subscribe(folder => this.stateService.knowledgePanelFolder = folder);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
