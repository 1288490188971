// Common
import {
  Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy, AfterViewInit, Renderer2,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';

// RX
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';

/**
 * Type Aliases
 */
type LinkedToolbarTab = 'message' | 'event' | 'task' | 'note';

/**
 * Animation
 */
const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('closed => opened', [
    style({ opacity: 0, height: 0 }),
    animate(`.3s ease-in-out`, style({ opacity: 1, height: '{{currentHeight}}'}))
  ]),
  transition('closed => full', [
    style({ opacity: 0, height: 0 }),
    animate(`.3s ease-in-out`, style({ opacity: 1, height: '{{fullHeight}}'}))
  ]),
  transition('opened => closed', [
    style({ opacity: 1, height: '{{currentHeight}}'}),
    animate(`.3s ease-in-out`, style({ opacity: 0, height: 0 }))
  ]),
  transition('opened => full', [
    style({ height: '{{currentHeight}}'}),
    animate(`.3s ease-in-out`, style({ height: '{{fullHeight}}' }))
  ]),
  transition('full => closed', [
    style({ opacity: 1, height: '{{fullHeight}}'}),
    animate(`.3s ease-in-out`, style({ opacity: 0, height: 0 }))
  ]),
]);

@Component({
  selector: 'app-linked-info-toolbar',
  templateUrl: './linked-info-toolbar.component.html',
  styleUrls: ['./linked-info-toolbar.component.less'],
  animations: [collapseMotion],
})
export class LinkedInfoToolbarComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  // Inputs
  @Input() linkedInfo: LinkedInfo[];

  // Public
  public currentTab: LinkedToolbarTab;
  public suggestion = true;
  public title: string;
  public currentHeight: string;
  public fullHeight: string;
  public collapseMotionState: 'opened' | 'closed' | 'full' = 'closed';
  public saveAction = new Subject();
  public event: CalendarEvent;
  public task: Task;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private mouseMoveSubscription: Subscription;

  // View Children
  @ViewChild('gutter', { static: true }) gutter: ElementRef;
  @ViewChild('drawer', { static: true }) drawer: ElementRef;

  /**
   * Constructor
   */

  constructor(
    private ngZone: NgZone,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private stateService: StateService,
    private modalService: ModalService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.currentHeight = this.stateService.linkedToolbarFormHeight + 'px';
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.saveAction.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('linkedInfo' in changes) {
      this.event = new CalendarEvent();
      this.event.linkedInfo = this.linkedInfo;
      this.task = new Task();
      this.task.linkedInfo = this.linkedInfo;
    }
  }

  ngAfterViewInit() {
    this.setupEvents();
  }

  /**
   * Actions
   */

  handleTabClick(tab: LinkedToolbarTab) {
    this.currentTab = tab;
    this.suggestion = true;
    this.title = `Add ${ tab.charAt(0).toUpperCase() + tab.slice(1) }`;
    if (this.stateService.linkedToolbarFormMaximized) {
      this.fullHeight = this.parent().offsetHeight + 'px';
      this.collapseMotionState = 'full';
      this.setDrawerHeight(this.fullHeight);
    } else {
      this.collapseMotionState = 'opened';
      this.setDrawerHeight(this.currentHeight);
    }
  }

  handleClose() {
    this.currentTab = null;
    this.suggestion = true;
    this.collapseMotionState = 'closed';
    this.setDrawerHeight(null);
  }

  handleExpand() {
    if (this.collapseMotionState === 'full') {
      this.stateService.linkedToolbarFormMaximized = false;
      this.collapseMotionState = 'opened';
      this.setDrawerHeight(this.currentHeight);
    } else {
      this.stateService.linkedToolbarFormMaximized = true;
      this.fullHeight = this.parent().offsetHeight + 'px';
      this.collapseMotionState = 'full';
      this.setDrawerHeight(this.fullHeight);
    }
  }

  handleSave() {
    this.saveAction.next();
  }

  openMessageModal() {
    const message = new MailMessage();
    message.linkedInfo = this.linkedInfo;
    this.modalService.compose(message);
    this.handleClose();
  }

  private setupEvents() {
    this.ngZone.runOutsideAngular(() => {
      const mousedown = fromEvent(this.gutter.nativeElement, 'mousedown');

      mousedown
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((event: Event) => {
          event.preventDefault();
          this.subscribeToMouseMove();
        });
    });
  }

  private subscribeToMouseMove() {
    const mousemove = fromEvent(document, 'mousemove');
    const mouseup = fromEvent(document, 'mouseup');

    this.mouseMoveSubscription = mousemove
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((event: MouseEvent) => {
        event.preventDefault();
        const parentY = this.parent().getBoundingClientRect()['y'];
        this.setDrawerHeight(Math.max((this.parent().offsetHeight + Math.min(parentY - event.pageY, -5) + 8), 42) + 'px');
      });

    mouseup
      .pipe(
        first(),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        if (this.mouseMoveSubscription) {
          this.mouseMoveSubscription.unsubscribe();
        }

        this.ngZone.run(() => {
          this.stateService.linkedToolbarFormMaximized = false;
          this.collapseMotionState = 'opened';
          this.persistCurrentHeight();
        });
      });
  }

  private persistCurrentHeight() {
    const newHeight = this.drawer.nativeElement.style.height;
    if (newHeight) {
      this.currentHeight = newHeight;
      this.stateService.linkedToolbarFormHeight = parseInt(this.currentHeight, 10);
    }
  }

  private parent(): HTMLElement {
    return this.elementRef.nativeElement.parentElement;
  }

  private setDrawerHeight(value: string) {
    this.renderer.setStyle(this.drawer.nativeElement, 'height', value);
  }
}
