<ng-content></ng-content>
<ng-template
  #overlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayPositions]="[overlayPosition]"
  (positionChange)="updatePosition()"
  [cdkConnectedOverlayOpen]="overlayVisible | async"
  (overlayKeydown)="handleKeydown($event)"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
>
  <div
    *ngIf="popoverArrow"
    class="arrow-shadow"
    #arrowShadow
  ></div>
  <div class="app-popover"
    [@zoomBigMotion]="'active'"
    (@zoomBigMotion.done)="afterVisibilityAnimation($event)"
    #popoverContainer
    (mouseleave)="handleMouseLeave($event)"
  >
    <div class="app-popover-content">
      <div
        class="app-popover-inner"
        role="tooltip"
        (appOutsideClick)="handleOutsideClick()"
        [outsideClickExceptSelectors]="allowedOutsideSelectorsClick"
      >
        <div
          class="app-popover-inner-scroll"
          [ngClass]="{'with-shadow': innerShadow}"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>

        <div *ngIf="innerShadow" class="app-popover-inner-top-shadow"></div>
        <div *ngIf="innerShadow" class="app-popover-inner-bottom-shadow"></div>
      </div>
    </div>
  </div>
  <div
    *ngIf="popoverArrow"
    class="arrow"
    #arrow
  ></div>
</ng-template>
