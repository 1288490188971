<div class="mail-list-heading" *ngIf="header" (appDblClick)="scrollToIndex(0, 'smooth')">
  <div *ngIf="folderDetails && !extendedFiltering" class="mail-list__title">
    <span *ngIf="!folderDropdown">{{ folderDetails.name }}</span>
    <app-dropdown-folders
      *ngIf="folderDropdown"
      class="dropdown-folder"
      [folder]="folderDetails"
      (folderChange)="selectFolder($event)"
    ></app-dropdown-folders>
    <i class="fa fa-refresh fa-spin fa-fw" *ngIf="loading && items"></i>
    <i
      class="fa fa-exclamation-triangle fa-fw error-icon"
      *ngIf="loadingError"
      (click)="items?.length ? refreshCurrentItems() : resetItems()"
      matTooltip="Error occured while loading messages. Click to retry."
    ></i>
  </div>
  <div *ngIf="extendedFiltering" [tooltip]="filtersTooltip" placement="bottom" container="body" class="mail-list__title">
    Search results
    <i class="fa fa-refresh fa-spin fa-fw" *ngIf="loading && items"></i>
    <i
      class="fa fa-exclamation-triangle fa-fw"
      *ngIf="loadingError"
      (click)="items?.length ? refreshCurrentItems() : resetItems()"
      matTooltip="Error occured while loading messages. Click to retry."
    ></i>
  </div>
  <ng-template #filtersTooltip>
    <div *ngIf="folderDetails">Folder: {{ folderDetails.name }}</div>
    <div *ngIf="filters.topics">Topics: {{ filters.topics | join : ', ' }}</div>
    <div *ngIf="filters.contacts">Contacts: {{ filters.contacts | join : ', ' }}</div>
    <div *ngIf="filters.keywords">Keyword: {{ filters.keywords | join : ', ' }}</div>
  </ng-template>
  <app-dropdown
    align="right"
    class="mail-filter"
    [icon]="extendedFiltering && 'filter'"
    [closeOnSelect]="!extendedFiltering"
    [title]="selectedOrder?.name"
    allowedOutsideSelectors="app-date-picker, .app-date-picker-outside"
    [takeUntil]="dropdownClose"
  >
    <app-dropdown-multi-select
      *ngIf="extendedFiltering"
      title="Options"
      [options]="orderOprions"
      [selected]="selectedOrderOptions"
      (selectedChange)="selectOrderOption($event)"
      [divider]="true">
    </app-dropdown-multi-select>
    <app-dropdown-multi-select
      *ngIf="extendedFiltering"
      title="Match with"
      [options]="extendedFilteringMatchTypes"
      [selected]="selectedExtendedFilteringMatchTypes"
      (selectedChange)="selectSearchMatchType($event)"
      [divider]="true">
    </app-dropdown-multi-select>
    <app-dropdown-single-select
      [options]="orders"
      [selected]="selectedOrder"
      (selectedChange)="selectOrder($event)"
      [divider]="true">
    </app-dropdown-single-select>
    <app-dropdown-action-items
      [options]="[{name: 'Newest on top', key: 0}, {name: 'Oldest on top', key: items?.length}]"
      (actionSelected)="scrollToIndex($event.key)"
      [divider]="true">
    </app-dropdown-action-items>
    <app-dropdown-template-items
      [options]="[{name: 'Pick by date...'}]"
      [optionTemplateRef]="pickByDateTemplate"
    ></app-dropdown-template-items>
  </app-dropdown>
</div>

<!-- List -->
<cdk-virtual-scroll-viewport
  [dynamicSizeDataSource]="itemsStreamObservable"
  class="mail-list-wrapper"
>

  <!-- Messages -->
  <ng-container *cdkVirtualFor="let item of itemsStreamObservable; let i = index">

    <!-- Date header -->
    <div
      class="days-separator"
      *ngIf="!item?.data?.pinned && selectedOrder.key === 'date' && item?.data?.sentTime | differentDay: items[i - 1] && items[i - 1]['sentTime']"
    >
      <div class="days-separator-date">
        {{ item?.data?.sentTime | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <!-- Message -->
    <app-message
      [message]="item?.data"
      [selectedMessages]="selectedMessages"
      (selectedMessagesChange)="selectMessages($event)"
      (selectMessage)="selectItem(item, $event.event)"
      [folder]="filters.folder"
      [sortBy]="selectedOrder.key"
      [threadExpanded]="item?.data && isExpandedThread(item?.data?.id)"
      (threadToggle)="toggleMail(item?.data?.id)"
      (doubleClick)="openMessageModal($event)">
    </app-message>
  </ng-container>

  <!-- Placeholders -->
  <div class="placeholder" *ngIf="items?.length === 0 && !loading && !loadingError">No messages found for selected conditions</div>
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="message-list__selected" *ngIf="items && ((showCountView | async) === true || (selectedMessages && selectedMessages?.length > 1))">
  <div class="total">Items: {{items.length}}</div>
  <div *ngIf="selectedMessages?.length > 1" class="selected">Selected: {{selectedMessages?.length}}</div>
</div>

<!-- Pick By Date Template -->
<ng-template #pickByDateTemplate let-option="option">
  <app-dropdown-item
    [title]="option.name"
    popover
    popoverPlacement="right"
    [popoverArrow]="false"
    popoverTrigger="click"
    [popoverContent]="datePickerPopover"
    popoverAllowedOutsideSelectorsClick=".app-popover-content, app-date-picker, .app-date-picker-outside"
    [popoverShowUntil]="popoverClose"
  ></app-dropdown-item>
</ng-template>

<!-- Popover -->
<ng-template #datePickerPopover>
  <app-date-picker
    [maxDate]="maxDate"
    [inline]="true"
    [collapseable]="false"
    (dateSelected)="scrollToDate($event)"
  ></app-date-picker>
</ng-template>
