// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { NoteContextMenuComponent } from '@modules/elements/components/note-context-menu/note-context-menu.component';

// Types
import { Note } from '@modules/notes/types/note';

@Directive({
  selector: '[appNoteContextMenu]'
})
export class NoteContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() contextMenuComponent = NoteContextMenuComponent;
  @Input() appNoteContextMenu: Note;

  // Override
  setContextMenuInstanceData(componentRef: ComponentRef<NoteContextMenuComponent>) {
    componentRef.instance.note = this.appNoteContextMenu;
  }

}
