// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { UserAvatarModule } from '@modules/user-avatar/user-avatar.module';
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { MatAutocompleteModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@modules/pipes/pipes.module';

// Services
import { SearchService } from './services/search.service';

// Components
import { SearchInputComponent } from './components/search-input/search-input.component';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserAvatarModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    PipesModule,
  ],
  declarations: [SearchInputComponent],
  exports: [SearchInputComponent],
  providers: [
    SearchService
  ]
})
export class SearchModule { }
