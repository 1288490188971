// Common
import { TemplateRef, OnInit, ElementRef, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
// RX
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var hideWhileDragging = trigger('hideWhileDragging', [
    transition('shown => hidden', [
        style({ width: '{{ width }}', height: '{{ height }}' }),
        animate('.3s ease-in-out', style({ width: 0, height: 0 }))
    ]),
]);
var OrderedItemComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function OrderedItemComponent(dragNDropService, changeDetectorRef) {
        this.dragNDropService = dragNDropService;
        this.changeDetectorRef = changeDetectorRef;
        this.size = 0;
        this.hovered = false;
        this.neighborSize = 0;
        // Private
        this.alive = new Subject();
        this.neighborsSizesValue = [];
        // Outputs
        this.sizeChange = new EventEmitter();
        this.draggingStart = new EventEmitter();
    }
    Object.defineProperty(OrderedItemComponent.prototype, "draggingIndex", {
        get: function () { return this.draggingIndexValue; },
        set: function (value) {
            this.draggingIndexValue = value;
            this.calculateDroppableAreaSize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderedItemComponent.prototype, "neighborsSizes", {
        get: function () { return this.neighborsSizesValue; },
        set: function (value) {
            this.neighborsSizesValue = value;
            this.calculateDroppableAreaSize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderedItemComponent.prototype, "placeholderSize", {
        get: function () { return this.placeholderSizeValue; },
        set: function (value) {
            this.placeholderSizeValue = value;
            this.calculateDroppableAreaSize();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Lifecycle
     */
    OrderedItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dragNDropService.getDraggingDataChanges()
            .pipe(takeUntil(this.alive))
            .subscribe(function (data) {
            if (!data) {
                _this.dragging = false;
                return;
            }
            // TODO do we need to compare DragData type to be the same ?
            // TODO predict multiple DnD
            _this.dragging = _this.trackByFn
                ? _this.trackByFn(_this.implicit) === _this.trackByFn(data.data[0])
                : _this.implicit === data.data[0];
            if (_this.dragging) {
                _this.draggingStart.emit();
            }
            // Size is calculatiing here because it can be changed since component initialization
            if (_this.itemTemplateRef && _this.container) {
                var bounding = _this.container.nativeElement.getBoundingClientRect();
                _this.size = _this.direction === 'vertical' ? bounding.height : bounding.width;
            }
            else {
                _this.size = 0;
            }
            _this.sizeChange.emit(_this.dragging ? 0 : _this.size);
        });
    };
    OrderedItemComponent.prototype.ngOnDestroy = function () {
        this.alive.next();
        this.alive.complete();
    };
    /**
     * Actions
     */
    OrderedItemComponent.prototype.handleDropBefore = function (dragData) {
        dragData.index = this.index;
        this.repositionStream.next(dragData);
    };
    OrderedItemComponent.prototype.handleEnterLeave = function (entered) {
        var _this = this;
        this.hovered = entered;
        timer(300)
            .pipe(takeUntil(this.alive))
            .subscribe(function () { return _this.handleFinishAnimation(); });
    };
    OrderedItemComponent.prototype.handleFinishAnimation = function () {
        this.dragNDropService.recalculateAreasCoordinates();
    };
    /**
     * Helpers
     */
    OrderedItemComponent.prototype.calculateDroppableAreaSize = function () {
        if (!this.placeholderSize) {
            return;
        }
        var index = this.index - 1 - (this.draggingIndex === this.index - 1 ? 1 : 0);
        this.neighborSize = this.neighborsSizes[index] || 50;
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    };
    return OrderedItemComponent;
}());
export { OrderedItemComponent };
