// Common
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError, Subject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { SectionsFilters } from '../types/sections-filters';
import { SectionsListResponse } from '../types/sections-list-response';
import { Section } from '../types/section';

// Env
import { environment } from '@environment';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

@Injectable()
export class SectionsService {

  public sectionCreated = new Subject<Section>();
  public sectionUpdated = new Subject<Section>();
  public sectionDeleted = new Subject<string>();

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) {

  }

  /**
   * Methods
   */

  getSections(filters: SectionsFilters = {}): Observable<SectionsListResponse> {
    const params = {};
    if (filters.projectsIds) { params['projects_ids'] = filters.projectsIds; }

    return this.http.get<SectionsListResponse>(environment.baseUrl + '/api/projects/sections', { params: params })
      .pipe(
        map(({ sections }) => ({ sections: sections.map(section => new Section(section)) })),
        catchError(SectionsService.handleObserverError)
      );
  }

  @warmUpObservable
  create(newSection: Section): Observable<Section> {
    return this.http.post<{ section: Section, success: boolean }>(
      environment.baseUrl + '/api/projects/sections', newSection.asPayloadJSON()
    )
      .pipe(
        tap(({ section, success }) => {
          if (success) {
            this.sectionCreated.next();
            this.asyncTasksToastsService.show(
              {text: `Section created`}
            );
          }
        }),
        map(({ section }) => new Section(section))
      );
  }

  @warmUpObservable
  update(sectionInstance: Section): Observable<Section> {
    return this.http.put<{ section: Section, success: boolean }>(
      environment.baseUrl + '/api/projects/sections/' + sectionInstance.id, sectionInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ section, success }) => {
          if (success) {
            this.sectionUpdated.next();
            this.asyncTasksToastsService.show(
              {text: `Section updated`}
            );
          }
        }),
        map(({ section }) => new Section(section))
      );
  }

  @warmUpObservable
  reorder(sectionsIds: string[]): Observable<Section[]> {
    return this.http.put<{ tasks: Section[], success: boolean }>(
      environment.baseUrl + '/api/projects/sections',
      { saveOrder: true },
      { params: { sections_ids: sectionsIds }}
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.sectionUpdated.next();
          }
        }),
        map(({ tasks }) => tasks.map(task => new Section(task)))
      );
  }

  @warmUpObservable
  pin(sectionIds: string[], status: boolean): Observable<Section[]> {
    return forkJoin(
      sectionIds.map(sectionId => this.http.put<{ section: Section, success: boolean }>
        (environment.baseUrl + '/api/projects/sections/' + sectionId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { section: Section, success: boolean }[]) => results.map(result => result.section)),
        tap(success => {
          if (success) {
            this.sectionUpdated.next();
            this.asyncTasksToastsService.show(
              {text: `Section${sectionIds.length > 1 ? 's' : ''} ${sectionIds.length > 1 ? 'are' : 'is'} ${ status ? '' : 'un'}pinned`}
            );
          }
        })
      );
  }

  @warmUpObservable
  archive(sectionIds: string[], archived: boolean): Observable<Section[]> {
    return forkJoin(
      sectionIds.map(sectionId => this.http.put<{ section: Section, success: boolean }>
        (environment.baseUrl + '/api/projects/sections/' + sectionId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { section: Section, success: boolean }[]) => results.map(result => result.section)),
        tap(success => {
          if (success) {
            this.sectionUpdated.next();
            this.asyncTasksToastsService.show({ text: `Section(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  deletePermanently(sectionIds: string[]): Observable<boolean> {
    return forkJoin(
      sectionIds.map(sectionId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/projects/sections/' + sectionId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.sectionDeleted.next();
            this.asyncTasksToastsService.show({ text: `Section(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  delete(sectionIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      sectionIds.map(sectionId => this.http.put<{ section: Section, success: boolean }>
        (environment.baseUrl + '/api/projects/sections/' + sectionId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { section: Section, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.sectionUpdated.next();
            this.asyncTasksToastsService.show({ text: `Section(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }
}
