import { Injectable, InjectionToken, Inject } from '@angular/core';
import { isEqual } from 'lodash';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { BehaviorSubject, Observable } from 'rxjs';

// Types
import { MessagesListKey, MessagesListPersistantSettings } from '@modules/settings/types/messages-list-state';
import { MessagesListFilters } from '@modules/mail/types/messages-list-filters';

export const MESSAGES_LIST_STORE_KEY = new InjectionToken<MessagesListKey>('MessagesListKey');

@Injectable()
export class MessagesListStateService {

  private filters: BehaviorSubject<MessagesListFilters>;
  private persistentFilters = ['order', 'orderWithRelevance', 'matchingTypes'];

  constructor(
    private stateService: StateService,
    @Inject(MESSAGES_LIST_STORE_KEY) private stateStoreKey: MessagesListKey
  ) {
    this.filters = new BehaviorSubject({
      orderWithPinned: true,
      ...this.stateService.getMessagesListSettings(this.stateStoreKey)
    });
  }

  getFilters(): Observable<MessagesListFilters> {
    return this.filters.asObservable();
  }

  setFilters(filters: MessagesListFilters): void {
    const updateFilters = { ...this.filters.value, ...filters };

    if (isEqual(this.filters.value, updateFilters)) {
      // No value changed - no needs for extra updates
      return;
    }

    // Update Persistent filters if changed
    if (this.persistentFilters.some(
      filterName => this.filters.value[filterName] !== updateFilters[filterName]
    )) {
      this.stateService.setMessagesListSettings(this.stateStoreKey, this.persistentFilters.reduce(
        (result, filterName) => {
          result[filterName] = updateFilters[filterName];
          return result;
        }, {}) as MessagesListPersistantSettings
      );
    }

    this.filters.next(updateFilters);
  }
}
