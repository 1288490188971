<div
  [ngClass]="{
    'app-form-controls-input-outline': appearance === 'outline',
    'app-form-controls-input-standard': appearance === 'standard'
  }"
>
  <mat-form-field
    [appearance]="appearance"
    [style.width]="width || '100%'"
    [style.max-width]="maxWidth || '100%'"
    [ngStyle]="{width: width || '100%'}"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
    class="select-dropdown"
  >
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <mat-icon matPrefix [svgIcon]="inputFormControl.value.icon" *ngIf="inputFormControl.value?.icon"></mat-icon>
    <mat-select
      [formControl]="inputFormControl"
      [placeholder]="placeholder"
      #matSelect="matSelect"
      (appOutsideClick)="close()"
      [appOutsideClickTrackEnabled]="matSelect.openedChange | async"
      (openedChange)="handleOpenedChange($event)"
      disableOptionCentering
    >
      <mat-option
        *ngFor="let option of options"
        [value]="option.value"
        (click)="option.itemClick && option.itemClick()"
        class="edit-event-option-dropdown"
      >
        <mat-icon [svgIcon]="option.icon" *ngIf="option.icon"></mat-icon>
        {{ option.title }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix svgIcon="dropdown"></mat-icon>
  </mat-form-field>
</div>

