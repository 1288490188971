/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/scrolling";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./scroll.component";
var styles_ScrollComponent = [i0.styles];
var RenderType_ScrollComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollComponent, data: {} });
export { RenderType_ScrollComponent as RenderType_ScrollComponent };
export function View_ScrollComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { scrollBody: 0 }), i1.ɵqud(402653184, 2, { scrollBar: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["scrollBody", 1]], null, 2, "div", [["cdkScrollable", ""], ["class", "scroll-body"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.CdkScrollable, [i1.ElementRef, i2.ScrollDispatcher, i1.NgZone, [2, i3.Directionality]], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "scroll-bottom-gradient"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["scrollBar", 1]], null, 6, "div", [["class", "scroll-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "scroll-bar-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "scroll-bar-inner-arrow mat-icon notranslate"], ["role", "img"], ["svgIcon", "arrow-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "scroll-bar-inner-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "mat-icon", [["class", "scroll-bar-inner-arrow mat-icon notranslate"], ["role", "img"], ["svgIcon", "arrow-down"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { _ck(_v, 3, 0); var currVal_2 = "arrow-up"; _ck(_v, 9, 0, currVal_2); var currVal_5 = "arrow-down"; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).inline; var currVal_1 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 12).inline; var currVal_4 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_3, currVal_4); }); }
export function View_ScrollComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-scroll", [], null, null, null, View_ScrollComponent_0, RenderType_ScrollComponent)), i1.ɵdid(1, 4374528, null, 1, i6.ScrollComponent, [i1.NgZone, i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(603979776, 1, { anchors: 1 })], null, null); }
var ScrollComponentNgFactory = i1.ɵccf("app-scroll", i6.ScrollComponent, View_ScrollComponent_Host_0, {}, { top: "top" }, ["*"]);
export { ScrollComponentNgFactory as ScrollComponentNgFactory };
