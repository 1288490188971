<div class="modal-header attachment-modal-header">
  <div class="modal-title">{{ reminderType === 'followup' ? 'Follow up date' : 'Snooze until' }}</div>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body modal-body--with-footer modal-body-custom-reminder text-center">
  <div class="date-time-group">
    <app-date-picker
      class="date-time-input"
      placeholder="Date"
      width="108px"
      [inputFormControl]="date"
    ></app-date-picker>
    <app-time-picker
      class="date-time-input"
      placeholder="Time"
      width="80px"
      [inputFormControl]="time"
    ></app-time-picker>
  </div>
</div>
<div class="modal-footer">
    <button (click)="close()" class="btn btn-default btn-sm pull-left">Close</button>
    <button (click)="saveReminder()" class="btn btn-primary btn-sm pull-right" [disabled]="!date.value || !time.value">Save</button>
</div>
