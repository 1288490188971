/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-files.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-contextmenu";
import * as i3 from "../../../../form-controls/components/attachment-icon/attachment-icon.component.ngfactory";
import * as i4 from "../../../../form-controls/components/attachment-icon/attachment-icon.component";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../pipes/pipes/file-size.pipe";
import * as i14 from "../../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i15 from "./related-files.component";
import * as i16 from "../../../../modal/services/modal.service";
import * as i17 from "../../../../mail/services/mail.service";
var styles_RelatedFilesComponent = [i0.styles];
var RenderType_RelatedFilesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelatedFilesComponent, data: {} });
export { RenderType_RelatedFilesComponent as RenderType_RelatedFilesComponent };
function View_RelatedFilesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "file-size"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": ", ""]))], null, function (_ck, _v) { var currVal_0 = ((((_v.parent.context.$implicit.from == null) ? null : _v.parent.context.$implicit.from.name) || ((_v.parent.context.$implicit.from == null) ? null : _v.parent.context.$implicit.from.email)) || "Unknown"); var currVal_1 = (_v.parent.context.$implicit.subject || "(no subject)"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_RelatedFilesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "file-size"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.size))); _ck(_v, 1, 0, currVal_0); }); }
function View_RelatedFilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "file__wrapper"]], null, [[null, "click"], [null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openAttachmentModal(_v.context.$implicit, $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵa, [i2.ContextMenuService], { contextMenuSubject: [0, "contextMenuSubject"], contextMenu: [1, "contextMenu"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-attachment-icon", [["class", "file-icon"]], null, null, null, i3.View_AttachmentIconComponent_0, i3.RenderType_AttachmentIconComponent)), i1.ɵdid(4, 638976, null, 0, i4.AttachmentIconComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "file-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "file-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "file-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵppd(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "file-download hover-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 16777216, null, null, 1, "i", [["class", "fa fa-cloud-download"], ["matTooltip", "Download"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 17)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i10.AriaDescriber, i10.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = i1.ɵnov(_v.parent, 14); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.type; _ck(_v, 4, 0, currVal_2); var currVal_4 = (_v.context.$implicit.from || _v.context.$implicit.subject); _ck(_v, 9, 0, currVal_4); var currVal_5 = !(_v.context.$implicit.from || _v.context.$implicit.subject); _ck(_v, 11, 0, currVal_5); var currVal_7 = "Download"; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.name || "Unknown"); _ck(_v, 7, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent, 1), ((_v.context.$implicit == null) ? null : _v.context.$implicit.date), "MMM, d")); _ck(_v, 13, 0, currVal_6); }); }
function View_RelatedFilesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/placeholders/placeholder-files.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no attachments."]))], null, null); }
function View_RelatedFilesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error occured while loading. "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "error"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here to try again."]))], null, null); }
function View_RelatedFilesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "load-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Load more"]))], null, null); }
function View_RelatedFilesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-fw"]], null, null, null, null, null))], null, null); }
function View_RelatedFilesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open email "]))], null, null); }
function View_RelatedFilesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Preview "]))], null, null); }
function View_RelatedFilesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Save As "]))], null, null); }
function View_RelatedFilesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Send To "]))], null, null); }
function View_RelatedFilesComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Print "]))], null, null); }
export function View_RelatedFilesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.FileSizePipe, []), i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "related-files__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_6)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedFilesComponent_7)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 12, "context-menu", [], null, null, null, i14.View_ContextMenuComponent_0, i14.RenderType_ContextMenuComponent)), i1.ɵdid(14, 180224, [["fileContextMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openMessageModal($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedFilesComponent_8)), i1.ɵdid(17, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openAttachmentModal($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedFilesComponent_9)), i1.ɵdid(19, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.downloadFile($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedFilesComponent_10)), i1.ɵdid(21, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openComposeModal($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedFilesComponent_11)), i1.ɵdid(23, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.printFile($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedFilesComponent_12)), i1.ɵdid(25, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 4, 0, currVal_0); var currVal_1 = ((!((_co.files == null) ? null : _co.files.length) && !_co.loading) && !_co.error); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 8, 0, currVal_2); var currVal_3 = ((_co.totalCount && (_co.totalCount > ((_co.files == null) ? null : _co.files.length))) && !_co.loading); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.isFileFromMessage; _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.isFilePrintable; _ck(_v, 25, 0, currVal_6); }, null); }
export function View_RelatedFilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-files", [], null, null, null, View_RelatedFilesComponent_0, RenderType_RelatedFilesComponent)), i1.ɵdid(1, 245760, null, 0, i15.RelatedFilesComponent, [i16.ModalService, i17.MailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedFilesComponentNgFactory = i1.ɵccf("app-related-files", i15.RelatedFilesComponent, View_RelatedFilesComponent_Host_0, { files: "files", filesType: "filesType", totalCount: "totalCount", loading: "loading", error: "error", multiplePreview: "multiplePreview" }, { loadMore: "loadMore" }, []);
export { RelatedFilesComponentNgFactory as RelatedFilesComponentNgFactory };
