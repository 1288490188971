// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Tag } from '@modules/tag/types/tag';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

@Component({
  selector: 'app-project-form-container',
  templateUrl: './project-form-container.component.html',
  styleUrls: ['./project-form-container.component.less'],
})
export class ProjectFormContainerComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() project: Project = new Project();
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() bundledGroup = 'projectForm';

  // Public
  public projectForm: FormGroup = this.project.asFormGroup();
  public saveInProgress = false;
  public orderOptions: DropdownOption[] = [
    { name: 'By Date', key: 'date'},
    { name: 'Priority', key: 'priority'},
    { name: 'All', key: 'all'},
    { name: 'Today', key: 'today'},
    { name: 'Upcoming', key: 'upcoming'},
    { name: 'By Pellets', key: 'pellets'},
    { name: 'By Tags', key: 'tags'},
    { name: 'To Do', key: 'todo'},
    { name: 'Progress', key: 'progress'},
    { name: 'Done', key: 'done'},
    { name: 'Pick by date ...', key: 'pickByDate'},
  ];
  public selectedOrder: DropdownOption = this.orderOptions[0];
  public viewType: 'list' | 'board' | 'timeline' = 'list';
  public dynamicPanel: DynamicPanelType;
  public closeDroppedElement = new Subject<void>();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private dragData: DragData;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.project &&
    !(dragData.type === 'project' && dragData.data.length === 1 && dragData.data[0]['id'] === this.project.id) &&
    ['message', 'event', 'project', 'task', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor (
    private projectsService: ProjectsService,
    private tasksStateService: TasksStateService,
    private dynamicPanelService: DynamicPanelService,
    private linkedInfoService: LinkedInfoService,
    private topicService: TopicService,
    private tagService: TagService,
    private tasksService: TasksService
  ) {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('project' in changes && this.project) {
      this.projectForm = this.project.asFormGroup();
    }
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
  }

  handleSubmit() {
    if (this.projectForm.invalid) {
      return;
    }
    this.saveInProgress = true;

    this.projectsService.upsert(this.projectForm)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleCancel() {
    this.close.emit();
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleSearchChange(value: string) {

  }

  switchView(viewType: 'list' | 'board') {
    this.viewType = viewType;
  }

  pin() {
    this.projectsService.pin([this.project.id], !this.project.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((projects: Project[]) => {
        if (projects[0]) {
          this.project.pinned = projects[0].pinned;
        }
      });
  }

  archive() {
    this.projectsService.archive([this.project.id], !this.project.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((projects: Project[]) => {
        if (projects[0]) {
          this.project.archived = projects[0].archived;
        }
      });
  }

  delete() {
    this.project.deleted ?
      this.projectsService.deletePermanently([this.project.id]) :
      this.projectsService.delete([this.project.id], true);

      this.tasksStateService.setMainView('empty');
  }

  openAttachmentsDP() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('attachments', { attachments: this.project.files });
    }
  }

  openLinkedInfoDP() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.project.linkedInfo,
        parentLinkedInfo: {type: 'project', id: this.project.id}
      });
    }
  }

  public handleCreateTaskOnDrop(): void {
    if (!this.project || !this.dragData || !this.dragData.data) { return; }

    const task = Task.fromDragData(this.dragData);

    if (!task) { return; }

    this.tasksService.create(task);
    this.closeDroppedElement.next();
  }

  public handleLinkOnDrop(): void {
    if (!this.project || !this.dragData || !this.dragData.data) { return; }

    if (['message', 'event', 'project', 'task', 'note'].includes(this.dragData.type) && this.dragData.data) {
      const items = this.dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(this.dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('project', this.project.id), linkedItems);
    }

    this.closeDroppedElement.next();
  }

  dndDrop(dragData: DragData) {
    if (!this.project) { return; }

    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {projectsIds: [this.project.id]});
      this.closeDroppedElement.next();
    } else if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {projectsIds: [this.project.id]});
      this.closeDroppedElement.next();
    } else {
      this.dragData = dragData;
    }
  }
}
