import {
  Directive,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ElementRef,
  ComponentRef,
  Type,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';

// RX
import { Subject, fromEvent, Subscription } from 'rxjs';
import { filter, takeUntil, tap, first } from 'rxjs/operators';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

@Directive({
  selector: '[appContextMenu]'
})
export class ContextMenuDirective implements OnInit, OnDestroy {

  // Private
  private componentRef: ComponentRef<any>;
  private closeSubscription: Subscription;

  // Protected
  protected alive: Subject<void> = new Subject();

  // Inputs
  @Input() contextMenuTriggerEvent = 'contextmenu';
  @Input() contextMenuComponent: Type<ContextMenuComponent>;

  // Output
  @Output() contextMenuOpened: EventEmitter<boolean> = new EventEmitter();

  /**
   * Constructor
   */

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    protected elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    const contextMenuFactory = this.componentFactoryResolver.resolveComponentFactory(this.contextMenuComponent);

    fromEvent(this.elementRef.nativeElement, this.contextMenuTriggerEvent)
      .pipe(
        tap((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }),
        filter(() => !!this.contextMenuComponent && !this.componentRef),
        takeUntil(this.alive)
      )
      .subscribe((event: MouseEvent) => {
        this.componentRef = this.viewContainerRef.createComponent(contextMenuFactory);
        this.componentRef.instance.triggerEvent = event;
        this.setContextMenuInstanceData(this.componentRef);
        this.changeDetector.detectChanges();
        this.subscribeToClose();
        this.contextMenuOpened.emit(true);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
    this.clearContent();
  }

  /**
   * Helpers
   */

  clearContent() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  subscribeToClose() {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }

    this.closeSubscription = this.componentRef.instance.close
      .pipe(
        takeUntil(this.alive),
        first()
      )
      .subscribe(() => {
        this.clearContent();
        this.contextMenuOpened.emit(false);
      });
  }

  setContextMenuInstanceData(componentRef: ComponentRef<any>) {
    // Reload this method to set context meny specific data
    // i.e. componentRef.instance.relatedContact = contact;
  }

}
