import * as tslib_1 from "tslib";
// Rx
import { Subject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Types
import { Contact } from '../types/contact';
// Env
import { environment } from '@environment';
// Decorators
import { warmUpObservable } from '@decorators';
var ContactsService = /** @class */ (function () {
    /**
     * Constructor
     */
    function ContactsService(http, linkedInfoService, asyncTasksToastsService, topicService) {
        this.http = http;
        this.linkedInfoService = linkedInfoService;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.topicService = topicService;
        // Public
        this.createNewContact = new Subject();
        this.updatedContact = new Subject();
        this.deletedContact = new Subject();
    }
    /**
     * Settings
     */
    ContactsService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Helpers
     */
    ContactsService.prototype.formatFilters = function (filters) {
        var formatedFilters = {};
        if (filters.contactsIds) {
            formatedFilters['contacts_ids[]'] = filters.contactsIds;
        }
        if (filters.groupsIds) {
            formatedFilters['groups_ids[]'] = filters.groupsIds;
        }
        if (filters.archived) {
            formatedFilters['archived'] = filters.archived + '';
        }
        if (filters.deleted) {
            formatedFilters['deleted'] = filters.deleted + '';
        }
        if (filters.pinned) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if (filters.keywords) {
            formatedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.topics) {
            formatedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formatedFilters['tags[]'] = filters.tags;
        }
        if (filters.order) {
            formatedFilters['order'] = filters.order;
        }
        if (filters.orderWithPinned) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.limit) {
            formatedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formatedFilters['offset'] = filters.offset + '';
        }
        return formatedFilters;
    };
    /**
     * Methods
     */
    ContactsService.prototype.getContacts = function (filters) {
        if (filters === void 0) { filters = {}; }
        var requestParams = { params: this.formatFilters(filters) };
        // return this.http.get<ContactsListResponse>(environment.baseUrl + '/api/contacts', requestParams)
        return of({ contacts: mockContacts, count: 4 })
            .pipe(map(function (_a) {
            var count = _a.count, contacts = _a.contacts;
            return ({ count: count, contacts: contacts.map(function (contact) { return new Contact(contact); }) });
        }), catchError(ContactsService.handleObserverError));
    };
    ContactsService.prototype.getContact = function (id) {
        return this.http.get(environment.baseUrl + '/api/contacts/' + id)
            .pipe(map(function (_a) {
            var contact = _a.contact;
            return new Contact(contact);
        }));
    };
    ContactsService.prototype.create = function (contactInstance) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/contacts", contactInstance.asPayloadJSON())
            .pipe(map(function (_a) {
            var contact = _a.contact, success = _a.success;
            return ({ contact: new Contact(contact), success: success });
        }), tap(function (_a) {
            var contact = _a.contact, success = _a.success;
            if (success) {
                _this.createNewContact.next(contact);
                _this.asyncTasksToastsService.show({ text: "Contact(s) created." });
            }
        }), map(function (_a) {
            var contact = _a.contact;
            return contact;
        }), tap(function (contact) {
            if (contactInstance.linkedInfo) {
                _this.linkedInfoService.linkToItem({ type: 'contact', id: contact.id }, contactInstance.linkedInfo);
            }
        }), switchMap(function (contact) {
            if (contactInstance.topics.length === 0) {
                return of(contact);
            }
            return _this.topicService.createTopics(contactInstance.topics, { contactsIds: [contact.id] })
                .pipe(map(function () { return contact; }));
        }), catchError(function (error) {
            _this.asyncTasksToastsService.show({ text: error.error.error });
            return throwError(error);
        }));
    };
    ContactsService.prototype.update = function (contactInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/contacts/' + contactInstance.id, contactInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var contact = _a.contact, success = _a.success;
            if (success) {
                _this.updatedContact.next();
                _this.asyncTasksToastsService.show({ text: 'Contact updated' });
            }
        }), map(function (_a) {
            var contact = _a.contact;
            return contact;
        }));
    };
    ContactsService.prototype.pin = function (contactsIds, status) {
        var _this = this;
        return forkJoin(contactsIds.map(function (contactId) { return _this.http.put(environment.baseUrl + '/api/contacts/' + contactId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.contact; }); }), tap(function (success) {
            if (success) {
                _this.updatedContact.next();
                _this.asyncTasksToastsService.show({ text: "Contact" + (contactsIds.length > 1 ? 's' : '') + " " + (contactsIds.length > 1 ? 'are' : 'is') + " " + (status ? '' : 'un') + "pinned" });
            }
        }));
    };
    ContactsService.prototype.archive = function (contactsIds, archived) {
        var _this = this;
        return forkJoin(contactsIds.map(function (contactId) { return _this.http.put(environment.baseUrl + '/api/contacts/' + contactId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.contact; }); }), tap(function (success) {
            if (success) {
                _this.updatedContact.next();
                _this.asyncTasksToastsService.show({ text: "Contact(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    ContactsService.prototype.deletePermanently = function (contactsIds) {
        var _this = this;
        return forkJoin(contactsIds.map(function (contactId) { return _this.http.delete(environment.baseUrl + '/api/contacts/' + contactId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.deletedContact.next();
                _this.asyncTasksToastsService.show({ text: "Contact(s) successfully deleted." });
            }
        }));
    };
    ContactsService.prototype.delete = function (contactsIds, deleted) {
        var _this = this;
        return forkJoin(contactsIds.map(function (contactId) { return _this.http.put(environment.baseUrl + '/api/contacts/' + contactId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.updatedContact.next();
                _this.asyncTasksToastsService.show({ text: "Contact(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    ContactsService.prototype.upsert = function (contactForm) {
        var contact = Contact.fromFormGroup(contactForm);
        return contactForm.get('id').value ?
            this.update(contact) : this.create(contact);
    };
    tslib_1.__decorate([
        warmUpObservable
    ], ContactsService.prototype, "update", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ContactsService.prototype, "pin", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ContactsService.prototype, "archive", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ContactsService.prototype, "deletePermanently", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ContactsService.prototype, "delete", null);
    return ContactsService;
}());
export { ContactsService };
// tslint:disable: max-line-length
var mockContacts = [
    {
        id: '1',
        name: 'Angelina Jolie',
        role: 'UI/UX Designer',
        email: 'jolie1@hotmail.com',
        phoneNumber: '+380503424253',
        createdAt: new Date(),
        pinned: true,
        starred: true,
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
    {
        id: '2',
        name: 'Benedict Cumberbatch',
        role: 'Actor',
        email: 'benedict@gmail.com',
        phoneNumber: '+440503424253',
        createdAt: new Date(),
        pinned: false,
        starred: false,
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
    {
        id: '3',
        name: 'Frank Sinatra',
        role: 'Singer',
        email: 'fsinatra@gmail.com',
        phoneNumber: '+440503424253',
        createdAt: new Date(),
        pinned: false,
        starred: true,
    },
    {
        id: '4',
        name: 'Harry Potter',
        role: 'Wizard',
        email: 'potter@gmail.com',
        phoneNumber: '+440503424253',
        createdAt: new Date(),
        pinned: false,
        starred: false,
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'task',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'message',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'group',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
    {
        id: '5',
        name: 'Joanne Rowling',
        role: '',
        email: 'joanne@gmail.com',
        phoneNumber: '+440503424253',
        createdAt: new Date(),
        pinned: false,
        starred: false,
    }
];
