import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
var MailEditorModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MailEditorModalComponent, _super);
    /**
     * Constructor
     */
    function MailEditorModalComponent(ga, element, popupService, changeDetector) {
        var _this = _super.call(this) || this;
        _this.ga = ga;
        _this.element = element;
        _this.popupService = popupService;
        _this.changeDetector = changeDetector;
        // Common properties for all modals
        _this.availableExpand = true;
        // Override
        _this.defaultSize = {
            x: 'calc(100% - 705px)',
            y: 'calc(100% - 505px)',
            width: '700px',
            height: '500px'
        };
        return _this;
    }
    /**
     * Component lifecycle
     */
    MailEditorModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    /**
     * Actions
     */
    MailEditorModalComponent.prototype.messageChange = function (message) {
        this.message = message;
        this.changeDetector.detectChanges();
    };
    MailEditorModalComponent.prototype.expand = function () {
        this.ga.trackEvent('mail-editor-modal', 'expand');
        this.popupService.compose(this.message, this.maximizedSize.popupSize());
        this.close();
    };
    return MailEditorModalComponent;
}(BaseModalComponent));
export { MailEditorModalComponent };
