<div
  class="column"
  [class.column-collapsed]="collapsed || forceCollapsed"
  [class.column-animated]="!forceCollapsed"
  [class.drag-placeholder]="!dragEnabled"
  appDraggable
  [appDraggableData]="{data: [column], type: 'column'}"
  [appDraggableNoShadow]="true"
  [appDraggablePlaceholder]="dragPlaceholder"
>
  <div
    class="column-right-dot-container"
    (click)="handleNewColumnRight()"
  >
    <div class="dot"></div>
    <div class="plus">+</div>
  </div>

  <div
    class="column-marker"
    [style.background-color]="column.borderColor"
  ></div>

  <div class="column-content">
    <div
      class="column-heading"
      [appProjectBoardColumnContextMenu]="column"
      [style.border-color]="column.borderColor"
      [style.background-color]="column.color"
    >
      <input
        class="column-heading-title"
        (blur)="handleBlur()"
        placeholder="Enter section title"
        [formControl]="columnForm.controls['title']"
      >

      <div class="column-heading-left-text">
        2
      </div>
    </div>

    <app-project-section
      *ngFor="let section of column?.sectionsTree?.tree"
      [section]="section"
      [sectionsTree]="column?.sectionsTree"
      appearance="board"
    ></app-project-section>

    <div
      class="column-new-task"
      (click)="handleNewTask()"
      appDroppable
      appDroppableHoverClass="column-new-task-drag-over"
      [appDroppableZIndex]="1"
      (appDroppableDrop)="handleNewTaskDrop($event)"
    >
      + Add New Task
      <div class="drop-zone"></div>
    </div>
  </div>

  <div class="column-collapsed-title">
    {{column.title}}
  </div>

  <mat-icon
    svgIcon="dropdown-small"
    class="column-collapse-icon"
    (click)="collapse()"
  ></mat-icon>

  <div class="column-collapsed-count">3</div>
  <mat-icon svgIcon="plus-medium" class="column-collapsed-plus-icon"></mat-icon>
</div>

<ng-template #dragPlaceholder>
  <app-project-board-column
    [column]="column"
    [dragEnabled]="false"
    [columnsTree]="columnsTree"
    [forceCollapsed]="collapsed"
  ></app-project-board-column>
</ng-template>
