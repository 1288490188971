<div class="box">
  <div class="box-header with-border">
    <h3 class="box-title">Quick reply templates</h3>
  </div>

  <div>
    <button type="button" class="btn btn-success m-sm" (click)="openForm()">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Add new quick reply template
    </button>
  </div>

  <div class="box-body with-padding" [class]="!showForm ? 'hidden' : '' ">
    <div class="input-group">
      <span class="input-group-addon" id="reply-title">Title</span>
      <input [(ngModel)]="titleInputValue" type="text" class="form-control" placeholder="Enter title text" aria-describedby="reply-title">
    </div>
    <p>
      <app-text-editor [(content)]="replyTemplate.content"></app-text-editor>
    </p>
    <button class="btn btn-primary m-sm" (click)="saveTemplate()">Save</button>
    <button type="button" class="btn btn-secondary m-sm" (click)="closeForm()">Cancel</button>
    <div class="alert alert-success" *ngIf="saved">Quick reply template is saved</div>
    <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
  </div>

  <div class="list-group" *ngIf="quickReplyTemplates && quickReplyTemplates.length">
    <span class="list-group-item" *ngFor="let replyTemplate of quickReplyTemplates">
      <h4 class="list-group-item-heading">
        {{replyTemplate.title}}
        <button (click)="openForm(replyTemplate)" type="button" class="btn btn-success p-xxs">Edit</button>
        <button (click)="deleteTemplate(replyTemplate.id)"type="button" class="btn btn-danger p-xxs">Delete</button>
      </h4>
      <p class="list-group-item-text" [innerHTML]="replyTemplate.content"></p>
    </span>
  </div>

</div>
