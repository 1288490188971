/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../contact-form-container/contact-form-container.component.ngfactory";
import * as i3 from "../contact-form-container/contact-form-container.component";
import * as i4 from "../../services/contacts.service";
import * as i5 from "../../services/contacts-state.service";
import * as i6 from "../../../pages/services/dynamic-panel.service";
import * as i7 from "../../../topic/services/topic.service";
import * as i8 from "../../../tag/services/tag.service";
import * as i9 from "../group-form-container/group-form-container.component.ngfactory";
import * as i10 from "../group-form-container/group-form-container.component";
import * as i11 from "../../services/groups.service";
import * as i12 from "@angular/common";
import * as i13 from "./main-view.component";
import * as i14 from "../../../pages/services/layout-state.service";
var styles_ContactsMainViewComponent = [i0.styles];
var RenderType_ContactsMainViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsMainViewComponent, data: {} });
export { RenderType_ContactsMainViewComponent as RenderType_ContactsMainViewComponent };
function View_ContactsMainViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "empty-state-image"], ["src", "assets/placeholders/placeholder-tasks.svg"]], null, null, null, null, null))], null, null); }
function View_ContactsMainViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-form-container", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContactFormContainerComponent_0, i2.RenderType_ContactFormContainerComponent)), i1.ɵdid(1, 770048, null, 0, i3.ContactFormContainerComponent, [i4.ContactsService, i5.ContactsStateService, i6.DynamicPanelService, i7.TopicService, i8.TagService], { contact: [0, "contact"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ContactsMainViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-form-container", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_GroupFormContainerComponent_0, i9.RenderType_GroupFormContainerComponent)), i1.ɵdid(1, 770048, null, 0, i10.GroupFormContainerComponent, [i11.GroupsService, i5.ContactsStateService, i6.DynamicPanelService, i7.TopicService, i8.TagService], { group: [0, "group"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.group; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ContactsMainViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsMainViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsMainViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsMainViewComponent_3)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "empty"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "contact-form"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.view === "group-form"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ContactsMainViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts-main-view", [], null, null, null, View_ContactsMainViewComponent_0, RenderType_ContactsMainViewComponent)), i1.ɵdid(1, 245760, null, 0, i13.ContactsMainViewComponent, [i5.ContactsStateService, i14.LayoutStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsMainViewComponentNgFactory = i1.ɵccf("app-contacts-main-view", i13.ContactsMainViewComponent, View_ContactsMainViewComponent_Host_0, {}, {}, []);
export { ContactsMainViewComponentNgFactory as ContactsMainViewComponentNgFactory };
