import * as tslib_1 from "tslib";
// Common
import { OnInit, ChangeDetectorRef, ElementRef, AfterViewInit, QueryList } from '@angular/core';
import { Subject, combineLatest, merge } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
// Componnents
import { ContentMenuItemComponent } from '../content-menu-item/content-menu-item.component';
import { ContentMenuPelletComponent } from '../content-menu-pellet/content-menu-pellet.component';
var ContentMenuPelletsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentMenuPelletsComponent, _super);
    /**
     * Constructor
     */
    function ContentMenuPelletsComponent(changeDetector, elementRef, stateService, topicService, mailService, modalService, router, route, contentMenuService) {
        var _this = _super.call(this, stateService, elementRef, changeDetector, contentMenuService) || this;
        _this.topicService = topicService;
        _this.mailService = mailService;
        _this.modalService = modalService;
        _this.router = router;
        _this.route = route;
        // Public
        _this.topics = [];
        _this.selectedTopics = [];
        _this.orders = [
            { name: 'Alphabet', key: 'name' },
            { name: 'Alphabet Reverse', key: 'name-desc' },
            { name: 'Most Frequent', key: 'frequency' },
            { name: 'Most Recent', key: 'recency' },
        ];
        _this.pagination = {
            limit: 20,
            offset: 0,
            count: 0,
            step: 20
        };
        // Private
        _this.searchParams = [];
        _this.getPinnedTopics = new Subject();
        return _this;
    }
    /**
     * Component lifecycle
     */
    ContentMenuPelletsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Set states
        this.selectedOrder = this.stateService.sortPinnedTopics;
        // Get topics
        merge(this.topicService.getTopicsUpdate(), this.mailService.getFolders(), this.getPinnedTopics)
            .pipe(takeUntil(this.alive), switchMap(function () { return _this.topicService.getTopics({
            pinned: true,
            order: _this.selectedOrder.key,
            limit: _this.pagination.limit,
            offset: _this.pagination.offset
        }); }))
            .subscribe(function (response) {
            _this.topics = response.topics;
            _this.pagination.count = response.count;
        });
        // Get selected topics
        combineLatest([this.route.params, this.route.queryParams])
            .pipe(takeUntil(this.alive))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), params = _b[0], queryParams = _b[1];
            var folder = params.folder;
            var page = _this.getMainRouteSegment();
            if (((page === 'events' || page === 'tasks' || page === 'notes') ||
                (folder && folder === 'search')) &&
                queryParams.query) {
                _this.searchParams = JSON.parse(queryParams.query);
                _this.selectedTopics = _this.searchParams.filter(function (param) { return param.type === 'topic'; }).map(function (param) { return param.value; });
            }
            else {
                _this.searchParams = [];
                _this.selectedTopics = [];
            }
        });
        this.getPinnedTopics.next();
    };
    ContentMenuPelletsComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterContentInit.call(this);
    };
    /**
     * Methods
     */
    ContentMenuPelletsComponent.prototype.performSearch = function (params) {
        var page = this.getMainRouteSegment();
        if (params && params.length > 0) {
            var searchParams = JSON.stringify(params);
            this.router.navigate([page === 'mail' ? '../search' : "/" + page], { relativeTo: this.route, queryParams: { query: searchParams } });
        }
        else {
            this.router.navigate([page === 'mail' ? '../' : "/" + page], { relativeTo: this.route });
        }
    };
    /**
     * Actions
     */
    ContentMenuPelletsComponent.prototype.selectTopic = function (topic, multi) {
        var _this = this;
        if (multi === void 0) { multi = false; }
        if (multi && this.selectedTopics.some(function (selectedTopic) { return selectedTopic === topic.name; })) {
            this.searchParams = this.searchParams.filter(function (param) { return !(param.type === 'topic' && param.value === topic.name); });
        }
        else {
            if (!multi) {
                this.searchParams = this.searchParams.filter(function (param) { return !(param.type === 'topic' &&
                    _this.topics.some(function (pinnedTopic) { return pinnedTopic.name === param.value; })); });
            }
            this.searchParams.push({ type: 'topic', value: topic.name });
        }
        this.performSearch(this.searchParams);
    };
    ContentMenuPelletsComponent.prototype.selectOrder = function (order) {
        this.selectedOrder = order;
        this.stateService.sortPinnedTopics = this.selectedOrder;
        this.getPinnedTopics.next();
    };
    ContentMenuPelletsComponent.prototype.openTopicsModal = function () {
        this.modalService.topicList();
    };
    ContentMenuPelletsComponent.prototype.showMore = function () {
        this.pagination.limit += this.pagination.step;
        this.getPinnedTopics.next();
    };
    /**
     * Helpers
     */
    ContentMenuPelletsComponent.prototype.getMainRouteSegment = function () {
        return this.router.url.split(/\/|\?/)[1];
    };
    return ContentMenuPelletsComponent;
}(ContentMenuItemComponent));
export { ContentMenuPelletsComponent };
