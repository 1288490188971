<div class="participants">
  <span
    class="participants-title"
    popover
    [popoverContent]="searchTemplate"
    [popoverShowUntil]="hidePopover"
    popoverPlacement="right"
    popoverTrigger="click"
  >
    + Add Guests
  </span>
  <div class="participants-list" *ngIf="inputFormControl.value.length">
    <div
      class="participants-list-item"
      *ngFor="let contact of inputFormControl.value | slice: 0: (showAll ? undefined : itemsLimit)"
    >
      <app-user-avatar class="participants-list-item-avatar" size="16" [contact]="contact" [usePopover]="false"></app-user-avatar>
      <div class="participants-list-item-name">{{contact.name || contact.email}}</div>
    </div>
  </div>
  <span class="participants-more-label" *ngIf="inputFormControl.value.length > itemsLimit" (click)="showAll = !showAll">
    {{ showAll ? 'Show Less' : '+' + (inputFormControl.value.length - itemsLimit) + ' more' }}
  </span>
</div>

<ng-template #searchTemplate>
  <div class="participants-popover">
    <div class="participants-popover-title">Add Guests</div>
    <app-input
      class="participants-popover-input"
      placeholder="Add Guests"
      [inputFormControl]="searchControl"
    ></app-input>

    <div class="participants-popover-suggestions" *ngIf="searchSuggestions.length">
      <div
        class="participants-popover-suggestions-item"
        *ngFor="let contact of searchSuggestions"
        (click)="handleContactClick(contact)"
      >
        <app-user-avatar class="participants-popover-suggestions-item-avatar" size="24" [contact]="contact" [usePopover]="false"></app-user-avatar>
        <div class="participants-popover-suggestions-item-name">{{contact.name}}</div>
        <div class="participants-popover-suggestions-item-email">{{contact.email}}</div>
      </div>
    </div>

    <div class="participants-popover-buttons">
      <app-button class="participants-popover-buttons-item" (click)="hidePopover.next()">Cancel</app-button>
      <app-button class="participants-popover-buttons-item" appearance="blue" (click)="handleAdd()">Add</app-button>
    </div>

    <mat-icon class="participants-popover-close-icon" svgIcon="close" (click)="hidePopover.next()"></mat-icon>
  </div>
</ng-template>
