// Common
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, TemplateRef, ElementRef } from '@angular/core';
import { ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { CalendarEvent, CalendarDateFormatter, DateFormatterParams, CalendarMonthViewDay } from 'angular-calendar';
import { isSameDay } from 'date-fns';

// Pipes
import { DatePipe } from '@angular/common';

// RX
import { Subject } from 'rxjs';

// Types
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';

// Animations
import { fadeInAnimation } from '@modules/events/animations/fade-in-animation';

// Override month header week names formatter
export class CalendarMonthDateFormatter extends CalendarDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'EEEEE', locale);
  }
}

@Component({
  selector: 'full-calendar-year',
  templateUrl: './full-calendar-year.component.html',
  styleUrls: ['./full-calendar-year.component.less'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarMonthDateFormatter
    }
  ],
  animations: [ fadeInAnimation ]
})
export class FullCalendarYearComponent implements OnInit, OnChanges, AfterViewInit {

  // Inputs
  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() events: CalendarEvent[];
  @Input() quickPreviewTemplate: TemplateRef<any>;
  @Input() highlightedDay: Date;

  // Outputs
  @Output() dateClicked: EventEmitter<CalendarCellClickEvent> = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  // Public
  public refreshCalendar: Subject<any> = new Subject();
  public monthsList: Date[];
  public selectedYear: number;

  @ViewChild('monthsContainer', {static: true}) monthsContainer: ElementRef;
  @ViewChildren('monthItem') monthsRefs !: QueryList<ElementRef>;
  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate && this.viewDate.getFullYear() !== this.selectedYear) {
      this.generateMonthsList(this.viewDate.getFullYear());
      this.refreshView();
    }
    if (changes.highlightedDay || changes.selectedDate) {
      this.checkScrollNecessity(changes.highlightedDay && changes.highlightedDay.currentValue);
      this.checkScrollNecessity(changes.selectedDate && changes.selectedDate.currentValue);
      this.refreshCalendar.next();
    }
  }
  ngAfterViewInit(): void {
    this.checkScrollNecessity(this.selectedDate);
  }

  /**
   *  Angular Calendar
   */

  generateMonthsList(year: number) {
    this.selectedYear = year;
    this.monthsList = [];
    for (let month = 0; month < 12; month++) {
      this.monthsList.push(new Date(year, month, 1));
    }
    this.refreshView();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day, i) => {
      if ( i > 27 && day.date.getDate() < 8) {
        day.cssClass = 'cal-day-cell-force-show';
      } else {
        if (this.highlightedDay && isSameDay(this.highlightedDay, day.date)) {
          day.cssClass += ' cal-day-cell-highlighted';
        }
        if (this.selectedDate && isSameDay(this.selectedDate, day.date)) {
          day.cssClass += ' cal-day-cell-selected';
        }
      }
    });
  }

  refreshView() {
    this.refreshCalendar.next();
  }

  clickDay(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.loadDayEvents.emit(date);
    this.dateClicked.emit({date, originRef: new ElementRef(origin), event});
  }

  dblClickDay(date: Date) {
    this.dateDblClicked.emit(date);
  }

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }

  checkScrollNecessity(date: Date) {
    const monthRef: ElementRef = date && this.monthsRefs && this.monthsRefs.toArray()[date.getMonth()];
    if (monthRef) {
     if (monthRef.nativeElement.offsetTop + monthRef.nativeElement.clientHeight
       > this.monthsContainer.nativeElement.scrollTop + this.monthsContainer.nativeElement.clientHeight) {
       this.monthsContainer.nativeElement.scrollTop += monthRef.nativeElement.offsetTop + monthRef.nativeElement.clientHeight
         - this.monthsContainer.nativeElement.scrollTop - this.monthsContainer.nativeElement.clientHeight;
     } else if (monthRef.nativeElement.offsetTop < this.monthsContainer.nativeElement.scrollTop) {
       this.monthsContainer.nativeElement.scrollTop = monthRef.nativeElement.offsetTop;
     }
    }
  }
}
