// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Components
import { EventComponent } from '@modules/elements/components/event/event.component';

// Types
import { LinkedInfo } from '../../types/linked-info';

@Component({
  selector: 'app-link-info-event',
  templateUrl: './link-info-event.component.html',
  styleUrls: ['./link-info-event.component.less']
})
export class LinkInfoEventComponent extends EventComponent {

  // Inputs
  @Input() linkedInfo: LinkedInfo;
  @Input() shortView = false;

  // Outputs
  @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit(this.linkedInfo);
  }

  openEvent() {
    if (this.event) {
      this.modalService.calendarEventForm(this.event);
    }
  }
}
