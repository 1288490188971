// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { MatIconModule, MatTabsModule } from '@angular/material';

// Services
import { FilesService } from '@modules/files/services/files.service';
import { FilesStateService } from '@modules/files/services/files-state.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

// Modules
import { ElementsModule } from '@modules/elements/elements.module';
import { ContentMenuModule } from '@modules/content-menu/content-menu.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { CommonModule } from '@modules/common/common.module';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';

// Components
import { FilesListComponent } from './components/files-list/files-list.component';
import { FileFormComponent } from './components/file-form/file-form.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { FilesListContainerComponent } from './components/files-list-container/files-list-container.component';
import { FilesMainViewComponent } from './components/files-main-view/files-main-view.component';



@NgModule({
  imports: [
    AngularCommonModule,
    ElementsModule,
    ContentMenuModule,
    TasksModule,
    CommonModule,
    MatTabsModule,
    QuickFormsModule,
    MatIconModule,
    MatTooltipModule,
    FormControlsModule,
    ScrollModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    LinkedInfoModule,
    DragNDropModule,
  ],
  declarations: [
    FilesListComponent,
    FileFormComponent,
    SideBarComponent,
    FilesListContainerComponent,
    FilesMainViewComponent,
  ],
  exports: [
    FilesListComponent,
    FileFormComponent,
    SideBarComponent,
    FilesListContainerComponent,
    FilesMainViewComponent,
  ],
  providers: [
    FilesService,
    FilesStateService,
    ContentMenuService,
  ]
})
export class FilesModule { }
