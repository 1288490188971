<div class="message-wrapper" *ngIf="message" (click)="openMessage()">
  <!-- Message -->
  <app-message-context-menu-wrapper
    [enabled]="true"
    [message]="message"
    (movedToAnotherFolder)="movedToAnotherFolder.emit($event)">
    <div
      class="message"
      [class.unread]="message.unread"
      [class.short-view]="shortView"

      appDraggable
      [appDraggableEnabled]="dragEnabled"
      [appDraggableData]="{data: dragData, type: 'message'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerMultiple]="selectedMessages.length > 1"

      appDroppable
      appDroppableHoverClass="drag-over"
      [appDroppablePredicate]="dndPredicate"
      (appDroppableDrop)="dndDrop($event)"
    >
      <div class="message-icons">
        <mat-icon *ngIf="message.unread" class="message-icons-small-icon unread" svgIcon="mail-unread"></mat-icon>
        <mat-icon *ngIf="message.pinned" class="message-icons-small-icon pin" svgIcon="mail-pinned"></mat-icon>
        <mat-icon *ngIf="message.starred" class="message-icons-small-icon star" svgIcon="starred"></mat-icon>
      </div>

      <div class="message-info">
        <div class="message-info-title">
          <ng-container *ngIf="isSentOrDraftsFolder() || messageSentByMe(message)">
            To: {{ message.to | userNames }}
          </ng-container>
          <ng-container *ngIf="!isSentOrDraftsFolder() && !messageSentByMe(message)">
            {{ message.from?.name || message.from?.email || '(unknown)' }}
            <mat-icon *ngIf="message.from?.vip" svgIcon="crown"></mat-icon>
          </ng-container>
        </div>

        <div class="message-info-subject">{{ message.subject || '(no subject)' }}</div>

        <div class="message-info-snippet">
          <div class="message-info-snippet-text" *ngIf="!message.highlightedSnippet">{{ message.snippet }}</div>
          <app-message-snippet
            class="message-info-snippet-text"
            *ngIf="message.highlightedSnippet"
            [snippet]="message.highlightedSnippet"
          ></app-message-snippet>
          <div class="message-info-snippet-text" *ngIf="!message.snippet">This email was blank</div>
        </div>

        <app-link-info-bar *ngIf="!shortView" [linkedInfo]="message.linkedInfo"></app-link-info-bar>
      </div>

      <div class="message-icons right">
        <div class="message-icons-hideable">
          <div class="message-icons-hideable-type-icon-container">
            <div class="date">{{ message.sentTime | relativeDate : true : false }}</div>
            <mat-icon class="type-icon" svgIcon="envelope-closed"></mat-icon>
          </div>
          <mat-icon *ngIf="message.files.length" class="message-icons-small-icon attachment" svgIcon="mail-attachment"></mat-icon>
          <mat-icon *ngIf="message.replied" class="message-icons-small-icon reply" svgIcon="mail-reply"></mat-icon>
          <mat-icon
            class="message-icons-small-icon unlink"
            *ngIf="shortView"
            svgIcon="unlink"
            (click)="unlinkItem($event)"
            matTooltip="Unlink"
          ></mat-icon>
        </div>

        <div class="message-icons-unlink-thread-container">
          <mat-icon
            class="message-icons-small-icon unlink"
            svgIcon="unlink"
            (click)="unlinkItem($event)"
            matTooltip="Unlink"
          ></mat-icon>
          <div class="thread-button-wrapper" *ngIf="thread?.messages?.length">
            <div class="thread-button-background">
              <div class="thread-button">
                <div class="thread-button__text">+{{ thread?.count - 1 }}</div>
                <div *ngIf="thread?.unread_count > 0" class="thread-button__badge"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Actions on hover   -->
      <div class="actions" *ngIf="actionsButtonEnabled && !message.sending" (click)="$event.stopPropagation(); $event.preventDefault()">
        <button
          class="button-icon-small btn-action"
          [class.selected]="message.starred"
          (click)="starMessage(message)"
          [matTooltip]=" message.starred ? 'Unstar' : 'Star'"
        >
          <mat-icon svgIcon="saved"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          [class.selected]="message.pinned"
          (click)="pinMessage(message)"
          [matTooltip]="message.pinned ? 'Unpin' : 'Pin'"
        >
          <mat-icon svgIcon="pin"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          *ngIf="!message.snoozed" (click)="snoozeMessage(message)"
          [matTooltip]="'Snoozed by ' + (snoozedSetting | snoozedDate)"
        >
          <mat-icon svgIcon="snooze"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action selected"
          *ngIf="message.snoozed"
          (click)="removeSnoozeMessage(message)"
          matTooltip="Remove snoozed"
        >
          <mat-icon svgIcon="snooze"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          (click)="unreadMessage(message)"
          [matTooltip]="message.unread ? 'Mark read' : 'Mark unread'"
        >
          <mat-icon svgIcon="mail"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          *ngIf="!message.followed"
          (click)="followUpMessage(message)"
          matTooltip="Follow up by tomorrow"
        >
          <mat-icon svgIcon="followup"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action selected"
          *ngIf="message.followed"
          (click)="removeFollowUpMessage(message)"
          matTooltip="Remove follow up"
        >
          <mat-icon svgIcon="followup"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          *ngIf="!message.archived"
          (click)="moveToArchive(true, message)"
          matTooltip="Archive"
        >
          <mat-icon svgIcon="archive"></mat-icon>
        </button>

        <button
          class="button-icon-small btn-action"
          (click)="deleteMessages([message])"
          matTooltip="Delete"
        >
          <mat-icon svgIcon="trash"></mat-icon>
        </button>
      </div>
    </div>
  </app-message-context-menu-wrapper>
</div>

<!-- Message Placeholder -->
<div class="message-wrapper" *ngIf="!message">
  <div class="message-placeholder">
    <div class="message-info">
      <div class="message-title"></div>
      <div class="subject"></div>
      <div class="snippet"></div>
      <div class="snippet"></div>
      <div class="straight"></div>
    </div>
  </div>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <app-link-info-message
    [message]="message"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [threadEnabled]="false"
    [shortView]="shortView"
  >
  </app-link-info-message>
</ng-template>
