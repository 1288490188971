import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseModalComponent } from '@modules/modal/components/base-modal/base-modal.component';
var AttachmentsListModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AttachmentsListModalComponent, _super);
    function AttachmentsListModalComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Override
        _this.defaultSize = {
            width: '291px',
            height: '100%'
        };
        return _this;
    }
    return AttachmentsListModalComponent;
}(BaseModalComponent));
export { AttachmentsListModalComponent };
