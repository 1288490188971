import * as tslib_1 from "tslib";
import { Recipient } from '@modules/mail/types/recipient';
var CalendarContact = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarContact, _super);
    function CalendarContact() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CalendarContact;
}(Recipient));
export { CalendarContact };
