/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-web-push-notifications.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./account-web-push-notifications.component";
import * as i4 from "../../services/account.service";
import * as i5 from "../../../notification/services/push-notifications.service";
var styles_AccountWebPushNotificationsComponent = [i0.styles];
var RenderType_AccountWebPushNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountWebPushNotificationsComponent, data: {} });
export { RenderType_AccountWebPushNotificationsComponent as RenderType_AccountWebPushNotificationsComponent };
function View_AccountWebPushNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notifications are not supported in your browser. "]))], null, null); }
function View_AccountWebPushNotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.grantPermission() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enable notifications"]))], null, null); }
function View_AccountWebPushNotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-warning"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notifications have been disabled in this browser. You can enable them in browser settings. "]))], null, null); }
function View_AccountWebPushNotificationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableSubscription() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enable notifications"]))], null, null); }
function View_AccountWebPushNotificationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disableSubscription() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Disable notifications"]))], null, null); }
function View_AccountWebPushNotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.enabled; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.enabled; _ck(_v, 6, 0, currVal_1); }, null); }
function View_AccountWebPushNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "box-body with-padding"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_5)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permission; _ck(_v, 2, 0, currVal_0); var currVal_1 = "default"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "denied"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "granted"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AccountWebPushNotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "box-header with-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "box-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desktop notification preferences"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "box-body with-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "alert alert-info"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Allows to display popup notifications on your desktop. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountWebPushNotificationsComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSupported; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.isSupported; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_AccountWebPushNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-web-push-notifications", [], null, null, null, View_AccountWebPushNotificationsComponent_0, RenderType_AccountWebPushNotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i3.AccountWebPushNotificationsComponent, [i4.AccountService, i5.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountWebPushNotificationsComponentNgFactory = i1.ɵccf("app-account-web-push-notifications", i3.AccountWebPushNotificationsComponent, View_AccountWebPushNotificationsComponent_Host_0, {}, {}, []);
export { AccountWebPushNotificationsComponentNgFactory as AccountWebPushNotificationsComponentNgFactory };
