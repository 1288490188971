// Common
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Rx
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Types
import { Column } from '@modules/tasks/types/column';

@Component({
  selector: 'app-project-board-column-context-menu',
  templateUrl: './column-context-menu.component.html',
  styleUrls: ['./column-context-menu.component.less']
})
export class ColumnContextMenuComponent extends ContextMenuComponent implements OnInit, OnChanges {

  // Inputs
  @Input() column: Column;

  // Public
  public columnFormGroup: FormGroup;

  // Private
  private colorChanges = new Subject<void>();


  /**
   * Constructor
   */

  constructor (
    protected contextMenuService: ContextMenuService,
    private ga: GoogleAnalyticsService,
    private columnsService: ColumnsService,
  ) {
    super(contextMenuService);

    this.colorChanges
      .pipe(
        switchMap(() => this.columnFormGroup.valueChanges),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.columnsService.update(Column.fromFormGroup(this.columnFormGroup));
      });
  }

  /*
  * Lifecycle
  */
  public ngOnInit() {
    this.reset();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('column' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  delete() {
    this.ga.trackEvent('column-context-menu', 'delete-permanently');
    this.columnsService.deletePermanently([this.column.id]);
  }

  private reset() {
    this.columnFormGroup = this.column.asFormGroup();
    this.colorChanges.next();
  }
}
