<div
  class="topic-wrapper text-no-select"
  *ngIf="topic"
  #topicRef
  [ngClass]="{
    'close-icon': (selected && showRemoveIcon) || showRemoveIconAlways,
    selected: selected,
    highlighted: highlighted,
    discovered: topicStyle === 'discovered',
    related: topicStyle === 'related',
    snippet: topicStyle === 'snippet',
    'manually-added': topicStyle === 'manuallyAdded',
    'new': topicStyle === 'new'
  }"
  (contextmenu)="makeItemActive()"
  [contextMenu]="topicMenu"
  [contextMenuSubject]="topic"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [topic], type: 'topic'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  [appDraggableContainerStyles]="{'border-radius': '10px'}"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div *ngIf="pinned" class="pinned"><mat-icon svgIcon="pin-small" class="pin-icon"></mat-icon></div>
  <div class="topic">{{ topic }}</div>
  <mat-icon class="close-icon" svgIcon="close-small" *ngIf="(selected && showRemoveIcon) || showRemoveIconAlways" (click)="deselectTopic(topic, $event)"></mat-icon>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <app-topic
    [topic]="topic"
    [dragEnabled]="false"
    [selected]="selected"
    [topicStyle]="topicStyle"
    [pinned]="pinned">
  </app-topic>
</ng-template>

<!-- Context Menu -->
<context-menu #topicMenu [disabled]="disableContextMenu" (close)="makeItemInactive()">
  <ng-template contextMenuItem (execute)="pinTopic($event.item)">
    {{ pinned ? 'Unpin Pellet' : 'Pin Pellet' }}
  </ng-template>
  <ng-template contextMenuItem (execute)="extractTopics($event.item)">
    Always Show This Pellet
  </ng-template>
  <ng-template contextMenuItem (execute)="showRelatedEmails($event.item)">
    Related Emails
  </ng-template>
  <ng-template contextMenuItem (execute)="showRelatedTopics($event.item)">
    Load in Pellet Map
  </ng-template>
  <ng-template contextMenuItem (execute)="searchExternal($event.item, 'google')">
    Search on Google
  </ng-template>
  <ng-template contextMenuItem (execute)="searchExternal($event.item, 'bing')">
    Search on Bing News
  </ng-template>
  <ng-template contextMenuItem *ngIf="canBeDeleted" (execute)="deleteTopic()">
    Delete
  </ng-template>
</context-menu>
<!-- End: Context Menu -->
