// Common
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit, OnDestroy {

  // Public
  public errorMessage = '';
  public emailNotAllowed = false;
  public loading = false;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(private authService: AuthService, private router: Router) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  googleSignIn() {
    if (this.loading) {
      return;
    }

    this.errorMessage = '';
    this.emailNotAllowed = false;
    this.loading = true;
    this.authService.googleSignIn()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((isAccountReady: boolean) => {
        this.loading = false;
        if (isAccountReady) {
          this.router.navigate(['']);
        } else {
          this.router.navigate(['account/sync']);
        }
      }, error => {
        this.loading = false;
        if (error.code === 'email_not_allowed') {
          this.emailNotAllowed = true;
        } else {
          this.errorMessage = error.message;
        }
      });
  }
}
