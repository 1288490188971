/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-title-input.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../input/input.component.ngfactory";
import * as i6 from "../input/input.component";
import * as i7 from "./event-title-input.component";
import * as i8 from "../../../events/services/calendar.service";
var styles_EventTitleInputComponent = [i0.styles];
var RenderType_EventTitleInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventTitleInputComponent, data: {} });
export { RenderType_EventTitleInputComponent as RenderType_EventTitleInputComponent };
function View_EventTitleInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "option"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "option-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "option-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "option-date-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 2)], function (_ck, _v) { var currVal_3 = "calendar"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.item.value.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).inline; var currVal_2 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _v.context.item.value.when.start, "MMM d,yyyy")); _ck(_v, 6, 0, currVal_4); }); }
export function View_EventTitleInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-input", [["appearance", "standard"], ["icon", "event"]], null, null, null, i5.View_InputComponent_0, i5.RenderType_InputComponent)), i1.ɵdid(2, 49152, null, 0, i6.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], appearance: [2, "appearance"], autocompleteItemTemplateRef: [3, "autocompleteItemTemplateRef"], suggestedOptions: [4, "suggestedOptions"], bundledInputConsumerKeys: [5, "bundledInputConsumerKeys"], bundledInputProviderKeys: [6, "bundledInputProviderKeys"], bundledInputProviderGroup: [7, "bundledInputProviderGroup"], bundledInputConsumerGroup: [8, "bundledInputConsumerGroup"], icon: [9, "icon"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["autocompleteItem", 2]], null, 0, null, View_EventTitleInputComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; var currVal_1 = _co.placeholder; var currVal_2 = "standard"; var currVal_3 = i1.ɵnov(_v, 4); var currVal_4 = i1.ɵunv(_v, 2, 4, i1.ɵnov(_v, 3).transform(_co.suggestedOptions)); var currVal_5 = _co.bundledInputConsumerKeys; var currVal_6 = _co.bundledInputProviderKeys; var currVal_7 = _co.bundledInputProviderGroup; var currVal_8 = _co.bundledInputConsumerGroup; var currVal_9 = "event"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_EventTitleInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-title-input", [], null, null, null, View_EventTitleInputComponent_0, RenderType_EventTitleInputComponent)), i1.ɵdid(1, 704512, null, 0, i7.EventTitleInputComponent, [i8.CalendarService], null, null)], null, null); }
var EventTitleInputComponentNgFactory = i1.ɵccf("app-event-title-input", i7.EventTitleInputComponent, View_EventTitleInputComponent_Host_0, { inputFormControl: "inputFormControl", placeholder: "placeholder", bundledInputConsumerKeys: "bundledInputConsumerKeys", bundledInputProviderKeys: "bundledInputProviderKeys", bundledInputProviderGroup: "bundledInputProviderGroup", bundledInputConsumerGroup: "bundledInputConsumerGroup" }, {}, []);
export { EventTitleInputComponentNgFactory as EventTitleInputComponentNgFactory };
