<div class="header-bar" *ngIf="header">
  <mat-icon svgIcon="fullscreen" class="icon" (click)="save(true)"></mat-icon>
  <mat-icon svgIcon="close-small" class="icon" (click)="close()"></mat-icon>
</div>

<div
  class="message-editor"
  #droppableArea
  (drop)="dragOverEventsCounter = 0; uploadDropedFiles($event)"
  (dragenter)="dragOverEventsCounter = dragOverEventsCounter + 1;"
  (dragleave)="dragOverEventsCounter = dragOverEventsCounter - 1;"
>

  <!-- To -->
  <div class="field">
    <div class="input-title">To:</div>
    <app-recipients-input [(recipients)]="message.to" (recipientsChange)="messageChanged()"></app-recipients-input>
    <div class="btn-cc" *ngIf="!showFields.cc" (click)="showFields.cc = true">CC</div>
    <div class="btn-cc" *ngIf="!showFields.bcc" (click)="showFields.bcc = true">BCC</div>
  </div>

  <!-- CC -->
  <div class="field" *ngIf="showFields.cc || message.cc?.length">
    <div class="input-title">Cc:</div>
    <app-recipients-input [(recipients)]="message.cc" (recipientsChange)="messageChanged()"></app-recipients-input>
  </div>

  <!-- BCC -->
  <div class="field" *ngIf="showFields.bcc || message.bcc?.length">
    <div class="input-title">Bcc:</div>
    <app-recipients-input [(recipients)]="message.bcc" (recipientsChange)="messageChanged()"></app-recipients-input>
  </div>

  <!-- Subject -->
  <div class="field">
    <div class="input-title">Subject</div>
    <input type="text" class="input-subject" [(ngModel)]="message.subject"  (ngModelChange)="messageChanged()" name="subject" autocomplete="off" />
  </div>

  <!-- Editor -->
  <app-text-editor
    class="message-editor__editor"
    [ngClass]="{'dragover': dragOverEventsCounter > 0}"
    [showMessageActions]="true"
    [(content)]="message.body"
    (contentChange)="messageChanged()"
    [style.height.px]="300"
    [templates]="templates | async"
    (dragOver)="dragOverEventsCounter = 1;"
    (addAttachments)="uploadSelectedFiles($event)"
    (addNewTemplate)="addNewTemplate()">
  </app-text-editor>

  <!-- Upload files -->
  <div class="message-editor__file-uploader__group">
    <app-file-uploader
      [(attachments)]="message.files"
      [upload]="uploadFiles"
      (uploading)="uploadingFiles = $event"
    ></app-file-uploader>
  </div>

  <!-- Actions -->
  <div class="message-editor__actions">
    <app-dropdown class="dropdown-action" placement="top" [closeOnSelect]="true">
      <ng-template #toggleButtonTemplate>
        <app-setting-button
          icon="send-time"
          [text]="!message.delay ? 'Send in...' : (message.delay | relativeDate)"
          [selected]="message.delay"
          (deselect)="removeDelay()">
        </app-setting-button>
      </ng-template>
      <app-dropdown-single-select
        [options]="delayOptions"
        [selected]="delaySelectedOption"
        (selectedChange)="selectDelayOption($event)">
      </app-dropdown-single-select>
    </app-dropdown>
    <app-setting-button
      class="dropdown-action"
      icon="event"
      text="Add Event"
      [selected]="createAppointment"
      (click)="createAppointment = !createAppointment">
    </app-setting-button>
    <app-dropdown class="dropdown-action" placement="top" [closeOnSelect]="true">
      <ng-template #toggleButtonTemplate>
        <app-setting-button
          icon="followup"
          [text]="!message.delay ? 'Follow up...' : (message.followUpAt | relativeDate)"
          [selected]="message.followUpAt"
          (deselect)="removeFollowUp()">
        </app-setting-button>
      </ng-template>
      <app-dropdown-single-select
        [options]="followUpOptions"
        [selected]="followUpSelectedOption"
        (selectedChange)="selectFollowUpOption($event)">
      </app-dropdown-single-select>
    </app-dropdown>
    <app-setting-button
      class="dropdown-action"
      icon="unread-time"
      text="Notify if read"
      [selected]="message.trackOpens"
      (click)="message.trackOpens = !message.trackOpens; messageChanged()">
    </app-setting-button>

    <!-- Save | Send Buttons -->
    <button class="btn btn-send" [disabled]="processing || uploadingFiles || !(message.to?.length || message.cc?.length || message.bcc?.length)" (click)="send()">SEND</button>
    <button class="btn btn-save" [disabled]="processing || uploadingFiles || !(message.to?.length || message.cc?.length || message.bcc?.length || message.subject || message.body || message.files?.length)" (click)="save()">SAVE</button>
    <button class="btn btn-save" *ngIf="cancel?.observers?.length" [disabled]="processing" (click)="close()">CANCEL</button>
  </div>

  <div class="drop-placeholder" *ngIf="dragOverEventsCounter > 0">
    <mat-icon class="image" svgIcon="placeholder-files"></mat-icon>
    <div class="title">Drag and Drop files here</div>
  </div>

</div>
