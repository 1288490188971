// Common
import { Component, Input, OnInit, SimpleChanges, OnChanges, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable, Subject, EMPTY } from 'rxjs';
import { takeUntil, filter, debounceTime, map, first, switchMap } from 'rxjs/operators';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Base Component
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { EventFilters } from '@modules/events/types/event-filters';
import { PopoverPlacement } from '@modules/popover/types/placement';

// Env
import { environment } from '@environment';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less'],
})
export class EventsListComponent extends InfinityScrollListComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no events';
  @Input() defaultFilters: EventFilters = {};
  @Input() hoverPlacement: PopoverPlacement = 'right';
  @Input() selectedItems: CalendarEvent[];
  @Input() scrollToDay: Observable<Date>;

  // Outputs
  @Output() eventClick: EventEmitter<CalendarEvent> = new EventEmitter();
  @Output() selectedItemsChanged: EventEmitter<CalendarEvent[]> = new EventEmitter();
  @Output() viewDate: EventEmitter<Date> = new EventEmitter();

  // Public
  public itemHeight = 93;
  public showCountView = new BehaviorSubject(true);

  // Private
  private isComponentInitialized = false;
  private scrollToDayChanges = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private eventsStateService: EventsStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone
  ) {
    super(ngZone);

    this.scrollToDayChanges
      .pipe(
        takeUntil(this.componentNotDestroyed),
        switchMap(() => this.scrollToDay || EMPTY)
      )
      .subscribe((date: Date) => date && this.scrollToDate(date));
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.eventsStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Make automatic updates for new event
      interval(environment.messageFetchInterval)
        .pipe(
          filter(() => !this.loading && this.selectedOrder && this.selectedOrder.key === 'date')
        ),
      this.calendarService.createNewEvent,
      this.calendarService.updatedEvent,
      this.calendarService.deletedEvent,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.scrollToDate(new Date);

    merge(
      this.currentIndex.asObservable(),
      this.itemsStreamObservable
    )
      .pipe(
        filter(() => this.currentIndex.value >= 0),
        filter(() => this.items && this.items.length > 0 && this.items[this.currentIndex.value] &&
          this.items[this.currentIndex.value]['when'] && this.items[this.currentIndex.value]['when']['start'] !== undefined
        ),
        map(() => this.items[this.currentIndex.value]['when']['start'] as Date),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((date: Date) => this.viewDate.emit(date));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if (
      ('selectedOrder' in changes && this.selectedOrder) ||
      'defaultFilters' in changes
    ) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }

    if ('scrollToDay' in changes) {
      this.scrollToDayChanges.next();
    }
  }

  /**
   * Actions
   */

  scrollToDate(date: Date) {
    this.calendarService.getEvents(
      {
        ...this.defaultFilters,
        orderWithPinned: true,
        order: <'date'|'title'>this.selectedOrder.key,
        limit: 1,
        toDate: this.beginningOfDay(date)
      })
      .pipe(
        first(),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({count}) => {
        this.scrollToIndex(count);
        if (!this.isComponentInitialized) {
          this.isComponentInitialized = true;
          super.ngOnInit();
        }
      });
  }

  /**
   * Base Methods override
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.calendarService
      .getEvents({
        ...this.defaultFilters,
        orderWithPinned: true,
        order: <'date'|'title'>this.selectedOrder.key,
        limit,
        offset
      })
      .pipe(
        map(({ events, count }) => ({ items: events, count }))
      );
  }

  resetItems() {
    this.selectedItemsChanged.emit([]);
    super.resetItems();
  }

  selectItem(calendarEvent: CalendarEvent, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(calendarEvent, event, selectAll);

    this.selectedItemsChanged.emit(this.selectedItems as CalendarEvent[]);

    if (this.selectedItems.length === 1) {
      this.eventClick.emit(this.selectedItems[0] as CalendarEvent);
    }
  }

  /**
   * Helpers
   */

  private beginningOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
}
