import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef } from '@angular/core';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
var ContactFormModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactFormModalComponent, _super);
    /**
     * Constructor
     */
    function ContactFormModalComponent(element, popupService, ga) {
        var _this = _super.call(this) || this;
        _this.element = element;
        _this.popupService = popupService;
        _this.ga = ga;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 259px)',
            y: '5vh',
            width: '518px',
            height: '90vh'
        };
        return _this;
    }
    /**
     * Component lifecycle
     */
    ContactFormModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    /**
     * Actions
     */
    ContactFormModalComponent.prototype.expand = function () {
        this.ga.trackEvent('contact-form-modal', 'expand');
        this.popupService.contactContent(this.contact.id, this.maximizedSize.popupSize());
        this.close();
    };
    return ContactFormModalComponent;
}(BaseModalComponent));
export { ContactFormModalComponent };
