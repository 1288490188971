<div class="header" [style.height]="height">
  <div class="header-title" [style.height]="height">
    <app-dropdown *ngIf="(title || titleTemplate) && filters">
      <ng-template #toggleButtonTemplate>
        <div class="header-title-button">
          <div *ngIf="title" class="header-title-text">{{ title }}</div>
          <ng-container *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-container>
          <mat-icon svgIcon="dropdown-small" class="header-title-dropdown-icon" *ngIf="filters && filters.length"></mat-icon>
        </div>
      </ng-template>
      <app-dropdown-multi-select
        [options]="filters"
        [selected]="selectedFilters"
        (selectedChange)="selectFilters($event)"
      ></app-dropdown-multi-select>
    </app-dropdown>
    <div *ngIf="title && !filters" class="header-title-text">{{ title }}</div>
    <ng-container *ngIf="titleTemplate && !filters" [ngTemplateOutlet]="titleTemplate"></ng-container>
    <i class="fa fa-refresh fa-spin fa-fw" *ngIf="loading"></i>
  </div>

  <div class="container">
    <div class="header-search" [ngClass]="{ expanded: searchMode }">
      <mat-icon
        *ngIf="search"
        svgIcon="search"
        class="header-search-icon"
        (click)="handleSearchIconClick($event)"
      ></mat-icon>
      <input
          #searchInput
          class="header-search-input"
          (blur)="handleSearchBlur($event)"
          placeholder="Search"
          [formControl]="searchQuery"
      />
      <mat-icon
        *ngIf="searchMode"
        svgIcon="close"
        class="header-search-icon clear"
        (click)="handleClearIconClick()"
      ></mat-icon>
    </div>

    <app-dropdown
      align="right"
      *ngIf="orders"
      class="header-dropdown"
      [closeOnSelect]="true"
    >
      <ng-template #toggleButtonTemplate>
        <div class="header-dropdown-button">
          <mat-icon svgIcon="filter-sorting" class="header-dropdown-button-icon"></mat-icon>
        </div>
      </ng-template>

      <app-dropdown-single-select
        [options]="orders"
        [selected]="selected"
        (selectedChange)="selectOrder($event)"
        [divider]="!!actions"
      ></app-dropdown-single-select>
      <app-dropdown-action-items
        *ngIf="actions"
        [options]="actions"
        (actionSelected)="selectAction($event.key)"
        [divider]="false"
      ></app-dropdown-action-items>
      <app-dropdown-pagination
        *ngIf="showPaginationOrder"
        (selectedChange)="selectSymbol($event)">
      </app-dropdown-pagination>
    </app-dropdown>
  </div>

</div>
