import { NgModule } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
/*
** Overlay from Popover, Dropdown, etc are not notified of scroll events that take place within the inner containers
** and all we need to do is register inner container to make ScrollPositionStrategy work properly
*/
import { ScrollingModule } from '@angular/cdk/scrolling';

// Components
import { ScrollComponent } from './components/scroll/scroll.component';

// Directoves
import { ScrollAnchorDirective } from './directives/scroll-anchor/scroll-anchor.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ScrollingModule,
  ],
  declarations: [
    ScrollComponent,
    ScrollAnchorDirective,
  ],
  exports: [
    ScrollComponent,
    ScrollAnchorDirective,
  ]
})
export class ScrollModule { }
