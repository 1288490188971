import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var InsightsRelatedConnectionsComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function InsightsRelatedConnectionsComponent(knowledgeGraphService, accountService, stateService) {
        this.knowledgeGraphService = knowledgeGraphService;
        this.accountService = accountService;
        this.stateService = stateService;
        // Outputs
        this.connectionSelected = new EventEmitter();
        // Public
        this.relatedConnections = [];
        this.loading = false;
        this.count = 0;
        this.selectedConnection = null;
        this.orderOptions = [
            { name: 'Relevance', key: 'relevance' },
            { name: 'A-Z', key: 'name-asc' },
            { name: 'Z-A', key: 'name-desc' },
        ];
        // Private
        this.componentNotDestroyed = new Subject();
        this.connectionsLimit = 10;
        this.initialized = false;
    }
    /**
     * Component lifecycle
     */
    InsightsRelatedConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedOrder = this.stateService.sortInsightsRelatedConnections;
        this.accountService.getAccount()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (account) {
            _this.account = account;
            _this.loadConnections(true);
        });
        this.initialized = true;
    };
    InsightsRelatedConnectionsComponent.prototype.ngOnChanges = function (changes) {
        if (this.initialized) {
            this.selectConnection(null);
            this.loadConnections(true);
        }
    };
    InsightsRelatedConnectionsComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Methods
     */
    InsightsRelatedConnectionsComponent.prototype.loadConnections = function (forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        // for the case when are we invoking order
        if (forceUpdate) {
            this.relatedConnections = [];
            this.count = 0;
        }
        else if (this.loading || this.relatedConnections.length >= this.count) {
            return;
        }
        this.loading = true;
        this.knowledgeGraphService
            .getRelatedConnections({
            fromTime: this.fromTime,
            toTime: this.toTime,
            folder: this.folder,
            topics: this.topics,
            contacts: this.contacts,
            keywords: this.keywords,
            relatedTopics: this.relatedTopics,
            ignoreContact: this.account ? { email: this.account.email, name: null, id: null } : null
        }, this.connectionsLimit, this.relatedConnections.length, this.selectedOrder.key)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (resp) {
            var _a;
            (_a = _this.relatedConnections).push.apply(_a, tslib_1.__spread(resp.data));
            _this.count = resp.count;
            _this.loading = false;
        });
    };
    /**
     * Actions
     */
    InsightsRelatedConnectionsComponent.prototype.selectOrder = function (order) {
        this.selectedOrder = order;
        this.stateService.sortInsightsRelatedConnections = order;
        this.loadConnections(true);
    };
    InsightsRelatedConnectionsComponent.prototype.selectConnection = function (connection) {
        this.selectedConnection = (this.selectedConnection === connection) ? null : connection;
        this.connectionSelected.emit(this.selectedConnection);
    };
    return InsightsRelatedConnectionsComponent;
}());
export { InsightsRelatedConnectionsComponent };
