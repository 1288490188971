<div
  class="toolbar"
>
  <!-- Forms -->
  <div
    class="toolbar-drawer"
    #drawer
    [ngClass]="{active: currentTab}"
    [@collapseMotion]="{value: collapseMotionState, params: {currentHeight: currentHeight, fullHeight: fullHeight}}"
  >
    <div class="toolbar-drawer-gutter" #gutter></div>
    <div class="toolbar-drawer-title">{{ title }}</div>
    <div class="toolbar-drawer-content">
      <!-- Suggestion -->
      <app-link-info-message-suggestions *ngIf="currentTab === 'message' && suggestion" [linkedInfo]="linkedInfo" (create)="openMessageModal()" (close)="handleClose()"></app-link-info-message-suggestions>
      <app-link-info-event-suggestions *ngIf="currentTab === 'event' && suggestion" [linkedInfo]="linkedInfo" (create)="suggestion = false" (close)="handleClose()"></app-link-info-event-suggestions>
      <app-link-info-task-suggestions *ngIf="currentTab === 'task' && suggestion" [linkedInfo]="linkedInfo" (create)="suggestion = false" (close)="handleClose()"></app-link-info-task-suggestions>
      <app-link-info-note-suggestions *ngIf="currentTab === 'note' && suggestion" [linkedInfo]="linkedInfo" (create)="suggestion = false" (close)="handleClose()"></app-link-info-note-suggestions>
      <!-- New -->
      <app-event-form *ngIf="currentTab === 'event' && !suggestion" appearance="noHeader" [event]="event" [submit]="saveAction"></app-event-form>
      <app-task-form *ngIf="currentTab === 'task' && !suggestion" appearance="noHeader" [task]="task" [submit]="saveAction"></app-task-form>
      <app-note-form *ngIf="currentTab === 'note' && !suggestion"></app-note-form>
    </div>
    <mat-icon svgIcon="close" class="toolbar-drawer-close-icon" (click)="handleClose()"></mat-icon>
    <mat-icon svgIcon="button-collapse-up" class="toolbar-drawer-collapse-icon" [ngClass]="{maximized: collapseMotionState === 'full'}" (click)="handleExpand()"></mat-icon>
  </div>

  <!-- Toolbar -->
  <div class="toolbar-pane">
    <button class="toolbar-pane-button" (click)="handleTabClick('message')" *ngIf="currentTab !== 'message'; else saveButton">
      <mat-icon svgIcon="mail" class="toolbar-pane-button-icon"></mat-icon>
    </button>
    <button class="toolbar-pane-button" (click)="handleTabClick('event')" *ngIf="currentTab !== 'event'; else saveButton">
      <mat-icon svgIcon="events" class="toolbar-pane-button-icon"></mat-icon>
    </button>
    <button class="toolbar-pane-button" (click)="handleTabClick('task')" *ngIf="currentTab !== 'task'; else saveButton">
      <mat-icon svgIcon="tasks" class="toolbar-pane-button-icon"></mat-icon>
    </button>
    <button class="toolbar-pane-button" (click)="handleTabClick('note')" *ngIf="currentTab !== 'note'; else saveButton">
      <mat-icon svgIcon="app-menu-notes" class="toolbar-pane-button-icon"></mat-icon>
    </button>
  </div>
</div>

<ng-template #saveButton>
  <button class="toolbar-pane-button-save" (click)="handleSave()">SAVE</button>
</ng-template>
