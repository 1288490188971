// Common
import { Injectable } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { Note } from '@modules/notes/types/note';

// Services
import { StateService } from '@modules/settings/services/state.service';

// RX
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class KnowledgePanelService {

  // Private
  private formItem = new BehaviorSubject<CalendarEvent | Task | Project | Note>(null);

  /**
   * Constructor
   */

  constructor (
    private stateService: StateService,
  ) {

  }

  /**
   * Actions
   */

  setFormItem(item: CalendarEvent | Task | Project | Note) {
    this.formItem.next(item);
  }

  getFormItem(): Observable<CalendarEvent | Task | Project | Note> {
    return this.formItem.asObservable();
  }
}
