<div class="input-container"
  [class.app-form-controls-input-outline]="appearance === 'outline'"
  [class.app-form-controls-input-standard]="appearance === 'standard'"
>
  <mat-form-field
    [appearance]="appearance"
    [style.width]="width || '100%'"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
  >
    <mat-icon
      *ngIf="leftIcon"
      class="input-container-icon"
      [style.right.px]="'auto'"
      [style.left.px]="0"
      [svgIcon]="leftIcon"
    ></mat-icon>
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <input
      matInput
      [placeholder]="appearance === 'outline' ? null : placeholder"
      [formControl]="inputFormControl"
      autocomplete="off"
      [matAutocomplete]="auto"
      [matAutocompleteDisabled]="!autocompleteItemTemplateRef"
      [bundledConsumer]="bundledInputConsumerKeys"
      [bundledProvider]="bundledInputProviderKeys"
      [bundledProviderGroup]="bundledInputProviderGroup"
      [bundledConsumerGroup]="bundledInputConsumerGroup"
      [bundledTransformFunction]="bundledInputTransformFunction"
      [style.padding-right]="appearance === 'standard' && icon ? '30px' : 0"
      [style.padding-left.px]="leftIcon && 29"
    />
    <mat-icon
      *ngIf="appearance === 'standard'"
      class="input-container-icon"
      [svgIcon]="icon"
    ></mat-icon>
  </mat-form-field>
</div>

<mat-autocomplete #auto="matAutocomplete">
  <div class="options">
    <mat-option
      class="options-item"
      *ngFor="let item of suggestedOptions | slice: 0: (showAll ? undefined : itemsLimit)"
      [value]="item.title"
    >
      <ng-container *ngTemplateOutlet="autocompleteItemTemplateRef;context:{item: item}"></ng-container>
    </mat-option>

    <div class="options-more-label" *ngIf="suggestedOptions?.length > itemsLimit" (click)="showAll = !showAll; $event.stopPropagation()">
      <span *ngIf="!showAll">+ {{ suggestedOptions?.length - itemsLimit }} more</span>
      <span *ngIf="showAll">Show Less</span>
    </div>
  </div>
</mat-autocomplete>
