/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tasks-list-container.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../common/components/knowledge-header/knowledge-header.component.ngfactory";
import * as i7 from "../../../common/components/knowledge-header/knowledge-header.component";
import * as i8 from "../../../quick-forms/components/quick-form/quick-form.component.ngfactory";
import * as i9 from "../../../quick-forms/components/quick-form/quick-form.component";
import * as i10 from "../../../quick-forms/components/task-quick-form/task-quick-form.component.ngfactory";
import * as i11 from "../../../quick-forms/components/task-quick-form/task-quick-form.component";
import * as i12 from "../../services/tasks.service";
import * as i13 from "../../services/tasks-state.service";
import * as i14 from "../tasks-list/tasks-list.component.ngfactory";
import * as i15 from "../tasks-list/tasks-list.component";
import * as i16 from "../../../linked-info/services/linked-info.service";
import * as i17 from "../../../quick-forms/components/project-quick-form/project-quick-form.component.ngfactory";
import * as i18 from "../../../quick-forms/components/project-quick-form/project-quick-form.component";
import * as i19 from "../../services/projects.service";
import * as i20 from "../projects-list/projects-list.component.ngfactory";
import * as i21 from "../projects-list/projects-list.component";
import * as i22 from "../../pipes/get-title-by-sidebar-filter-key.pipe";
import * as i23 from "./tasks-list-container.component";
import * as i24 from "../../../split-view/services/split-view.service";
import * as i25 from "../../../search/services/search.service";
import * as i26 from "../../../settings/services/state.service";
var styles_TasksListContainerComponent = [i0.styles];
var RenderType_TasksListContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TasksListContainerComponent, data: {} });
export { RenderType_TasksListContainerComponent as RenderType_TasksListContainerComponent };
function View_TasksListContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TasksListContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_4)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent.parent, 3); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TasksListContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TasksListContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_6)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent.parent, 4); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TasksListContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i4.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵeld(3, 16777216, null, null, 5, "mat-tab", [["label", "Tasks"]], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(4, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[3, 2]], 0, 1, null, View_TasksListContainerComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 5, "mat-tab", [["label", "Projects"]], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(10, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[5, 2]], 0, 1, null, View_TasksListContainerComponent_5)), i1.ɵdid(14, 16384, null, 0, i4.MatTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_2 = "Tasks"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "Projects"; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TasksListContainerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent, 3); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TasksListContainerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent, 4); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TasksListContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-knowledge-header", [], null, [[null, "selectedChange"], [null, "actionClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.handleSelectOrder($event) !== false);
        ad = (pd_0 && ad);
    } if (("actionClick" === en)) {
        var pd_1 = (_co.handleScrollOptionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_KnowledgeHeaderComponent_0, i6.RenderType_KnowledgeHeaderComponent)), i1.ɵdid(3, 245760, null, 0, i7.KnowledgeHeaderComponent, [], { title: [0, "title"], orders: [1, "orders"], actions: [2, "actions"], selected: [3, "selected"], loading: [4, "loading"] }, { selectedChange: "selectedChange", actionClick: "actionClick" }), i1.ɵppd(4, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_8)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.currentSidebarFilter, _co.selectedSidebarProject)); var currVal_1 = _co.orders; var currVal_2 = _co.scrollOptions; var currVal_3 = _co.selectedOrder; var currVal_4 = _co.loading; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.listState === "tabs"); _ck(_v, 6, 0, currVal_5); var currVal_6 = (_co.listState === "tasks"); _ck(_v, 8, 0, currVal_6); var currVal_7 = (_co.listState === "projects"); _ck(_v, 10, 0, currVal_7); }, null); }
function View_TasksListContainerComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-quick-form", [["bundledGroup", "tasksListQuickForm"], ["placeholder", "Add New Task"]], null, [[null, "collapsedChange"], [null, "clickMore"], [null, "itemDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("collapsedChange" === en)) {
        var pd_0 = ((_co.quickFormExpanded = $event) !== false);
        ad = (pd_0 && ad);
    } if (("clickMore" === en)) {
        var pd_1 = (_co.handleNewTask() !== false);
        ad = (pd_1 && ad);
    } if (("itemDropped" === en)) {
        var pd_2 = (_co.handleTaskQuickFormDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_QuickFormComponent_0, i8.RenderType_QuickFormComponent)), i1.ɵdid(1, 4964352, null, 6, i9.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], toggle: [2, "toggle"] }, { collapsedChange: "collapsedChange", clickMore: "clickMore", itemDropped: "itemDropped" }), i1.ɵqud(335544320, 6, { eventForm: 0 }), i1.ɵqud(335544320, 7, { projectForm: 0 }), i1.ɵqud(335544320, 8, { taskForm: 0 }), i1.ɵqud(335544320, 9, { noteForm: 0 }), i1.ɵqud(335544320, 10, { contactForm: 0 }), i1.ɵqud(335544320, 11, { groupForm: 0 }), (_l()(), i1.ɵeld(8, 0, null, 1, 1, "app-task-quick-form", [["bundledGroup", "tasksListQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewTask($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TaskQuickFormComponent_0, i10.RenderType_TaskQuickFormComponent)), i1.ɵdid(9, 704512, [[8, 4]], 0, i11.TaskQuickFormComponent, [i12.TasksService, i13.TasksStateService], { bundledGroup: [0, "bundledGroup"], task: [1, "task"] }, { more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Add New Task"; var currVal_1 = "tasksListQuickForm"; var currVal_2 = _co.quickFormToggle; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "tasksListQuickForm"; var currVal_4 = _co.quickFormTask; _ck(_v, 9, 0, currVal_3, currVal_4); }, null); }
function View_TasksListContainerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-quick-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_10)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-tasks-list", [], null, [[null, "loadInProgress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadInProgress" === en)) {
        var pd_0 = ((_co.loading = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_TasksListComponent_0, i14.RenderType_TasksListComponent)), i1.ɵdid(7, 770048, null, 0, i15.TasksListComponent, [i12.TasksService, i13.TasksStateService, i16.LinkedInfoService, i1.NgZone], { selectedOrder: [0, "selectedOrder"], scrollPosition: [1, "scrollPosition"], defaultFilters: [2, "defaultFilters"] }, { loadInProgress: "loadInProgress" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listState === "tasks"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedOrder; var currVal_2 = _co.listScrollPosition; var currVal_3 = _co.listFilters; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TasksListContainerComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-quick-form", [["bundledGroup", "projectsListQuickForm"], ["placeholder", "Add New Project"]], null, [[null, "collapsedChange"], [null, "clickMore"], [null, "itemDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("collapsedChange" === en)) {
        var pd_0 = ((_co.quickFormExpanded = $event) !== false);
        ad = (pd_0 && ad);
    } if (("clickMore" === en)) {
        var pd_1 = (_co.handleNewProject() !== false);
        ad = (pd_1 && ad);
    } if (("itemDropped" === en)) {
        var pd_2 = (_co.handleProjectQuickFormDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_QuickFormComponent_0, i8.RenderType_QuickFormComponent)), i1.ɵdid(1, 4964352, null, 6, i9.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], toggle: [2, "toggle"] }, { collapsedChange: "collapsedChange", clickMore: "clickMore", itemDropped: "itemDropped" }), i1.ɵqud(335544320, 12, { eventForm: 0 }), i1.ɵqud(335544320, 13, { projectForm: 0 }), i1.ɵqud(335544320, 14, { taskForm: 0 }), i1.ɵqud(335544320, 15, { noteForm: 0 }), i1.ɵqud(335544320, 16, { contactForm: 0 }), i1.ɵqud(335544320, 17, { groupForm: 0 }), (_l()(), i1.ɵeld(8, 0, null, 2, 1, "app-project-quick-form", [["bundledGroup", "projectsListQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewProject($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_ProjectQuickFormComponent_0, i17.RenderType_ProjectQuickFormComponent)), i1.ɵdid(9, 573440, [[13, 4]], 0, i18.ProjectQuickFormComponent, [i19.ProjectsService], { bundledGroup: [0, "bundledGroup"], project: [1, "project"] }, { more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Add New Project"; var currVal_1 = "projectsListQuickForm"; var currVal_2 = _co.quickFormToggle; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "projectsListQuickForm"; var currVal_4 = _co.quickFormProject; _ck(_v, 9, 0, currVal_3, currVal_4); }, null); }
function View_TasksListContainerComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-quick-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_12)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-projects-list", [], null, null, null, i20.View_ProjectsListComponent_0, i20.RenderType_ProjectsListComponent)), i1.ɵdid(7, 770048, null, 0, i21.ProjectsListComponent, [i19.ProjectsService, i13.TasksStateService, i16.LinkedInfoService, i1.NgZone], { selectedOrder: [0, "selectedOrder"], scrollPosition: [1, "scrollPosition"], defaultFilters: [2, "defaultFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listState === "projects"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedOrder; var currVal_2 = _co.listScrollPosition; var currVal_3 = _co.listFilters; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TasksListContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i22.GetTitleBySidebarFilterKeyPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksListContainerComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["tasksListTemplate", 2]], null, 0, null, View_TasksListContainerComponent_9)), (_l()(), i1.ɵand(0, [["projectsListTemplate", 2]], null, 0, null, View_TasksListContainerComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.minimized; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TasksListContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-list-container", [], null, null, null, View_TasksListContainerComponent_0, RenderType_TasksListContainerComponent)), i1.ɵdid(1, 245760, null, 0, i23.TasksListContainerComponent, [i24.SplitViewService, i13.TasksStateService, i25.SearchService, i26.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TasksListContainerComponentNgFactory = i1.ɵccf("app-tasks-list-container", i23.TasksListContainerComponent, View_TasksListContainerComponent_Host_0, {}, {}, []);
export { TasksListContainerComponentNgFactory as TasksListContainerComponentNgFactory };
