<div
  class="task-form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableShowUntil]="closeDroppedElement"
  [appDroppableAfterDropTemplate]="dropPopoverFormTemplate"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>
  <div class="task-form-actions" *ngIf="task?.id">
    <mat-icon
      svgIcon="pin"
      class="task-form-actions-button"
      [ngClass]="{active: task?.pinned}"
      [matTooltip]="task?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>

    <mat-icon
      svgIcon="snooze"
      class="task-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="event"
      class="task-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="flag"
      class="task-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="archive"
      class="task-form-actions-button"
      [ngClass]="{active: task?.archived}"
      [matTooltip]="task?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>

    <mat-icon
      *ngIf="!task?.readOnly"
      svgIcon="trash-bucket"
      (click)="delete()"
      class="task-form-actions-button"
      matTooltip="Delete"
    ></mat-icon>

    <mat-icon
      svgIcon="more-actions"
      class="task-form-actions-button"
      matTooltip="More Options"
      contextMenuTriggerEvent="click"
      matTooltip="Coming soon"
    ></mat-icon>
  </div>

  <div class="task-form-related" *ngIf="task?.id">
    <div class="task-form-related-buttons">
      <div class="task-form-related-button" (click)="openAttachmentsDynamicPanel()">
        <div class="task-form-related-button-inner">
          <mat-icon  class="task-form-related-button-inner-icon" svgIcon="attachment-small"></mat-icon>
        </div>
      </div>
      <div class="task-form-related-button" (click)="openLinkedInfoDynamicPanel()">
        <div class="task-form-related-button-inner">
          <mat-icon  class="task-form-related-button-inner-icon" svgIcon="link-thin"></mat-icon>
        </div>
      </div>
    </div>

    <mat-icon
      appDraggable
      [appDraggableData]="{data: [task], type: 'task'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerAdjustFit]="true"
      [appDraggableContainerStyles]="{'min-width': '300px'}"
      class="task-form-related-drag-icon"
      svgIcon="drag-icon-small"
    ></mat-icon>
  </div>

  <div class="task-form-fixed-header" *ngIf="appearance === 'headerFixedBig'" [ngClass]="{shadow: displayFixedBigHeaderShadow}">
    <div class="task-form-heading task-form-fixed-header-title">Tasks / {{ taskForm.controls['title'].value }}</div>

    <div class="task-form-fixed-header-columns">
      <div class="task-form-fixed-header-columns-input">
        <app-input
          placeholder="Title"
          [inputFormControl]="taskForm.controls['title']"
          appearance="standard"
          icon="tab-tasks"
        ></app-input>
      </div>
      <div class="task-form-fixed-header-columns-buttons">
        <app-button class="task-form-fixed-header-columns-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
        <app-button class="task-form-fixed-header-columns-buttons-item" (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>
  </div>

  <div class="task-form-fixed-small-header" *ngIf="appearance === 'headerFixedSmall'">
    <div class="task-form-fixed-small-header-title">
      Add new task
    </div>
    <div class="task-form-fixed-small-header-buttons">
      <app-button class="task-form-fixed-small-header-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
      <app-button class="task-form-fixed-small-header-buttons-item" (click)="handleCancel()">Cancel</app-button>
    </div>
  </div>

  <form
    class="task-form-main"
    [formGroup]="taskForm"
    (ngSubmit)="handleSubmit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <app-scroll (top)="displayFixedBigHeaderShadow = !$event">
      <div class="task-form-main-inner">

        <div class="task-form-horizontal-group" *ngIf="appearance === 'noHeader' || appearance === 'headerFixedSmall'">
          <div class="task-form-horizontal-group-full-width-item">
            <app-input
              placeholder="Title"
              [inputFormControl]="taskForm.controls['title']"
              appearance="standard"
              icon="tab-tasks"
            ></app-input>
          </div>
        </div>

        <app-tags-input
          [inputFormControl]="taskForm.controls['tags']"
          appScrollAnchor
        ></app-tags-input>

        <!-- Pellets -->
        <app-form-collapseable-group
          title="Pellets"
          icon="pellets-small"
          class="task-form-topics-section"
          appScrollAnchor
        >
          <app-task-topics
            [taskId]="task?.id"
            [topicsControl]="taskForm.controls['topics']"
          ></app-task-topics>
        </app-form-collapseable-group>

        <app-form-collapseable-group
          title="Time Details"
          icon="time-round"
          class="task-form-time-section"
          appScrollAnchor
        >
          <div class="task-form-horizontal-group">
            <div class="task-form-horizontal-group-item task-form-horizontal-group-item-closer">
              <app-date-picker
                placeholder="Start Date"
                [inputFormControl]="taskForm.controls['fromDate']"
                width="108px"
              ></app-date-picker>
            </div>
            <div class="task-form-horizontal-group-item">
              <app-date-picker
                placeholder="Due Date"
                [inputFormControl]="taskForm.controls['toDate']"
                width="108px"
              ></app-date-picker>
            </div>

            <div class="task-form-horizontal-group-item task-form-horizontal-group-item-closer">
              <app-time-picker
                placeholder="Start Time"
                [inputFormControl]="taskForm.controls['fromTime']"
                width="80px"
              ></app-time-picker>
            </div>
            <div class="task-form-horizontal-group-item">
              <app-time-picker
                placeholder="Due Time"
                [inputFormControl]="taskForm.controls['toTime']"
                width="80px"
              ></app-time-picker>
            </div>
          </div>
          <div class="task-form-horizontal-group">
            <div class="task-form-horizontal-group-item">
              <app-dropdown-select
                placeholder="Add To Project"
                [inputFormControl]="taskForm.controls['projectId']"
                [options]="projectOptions"
              ></app-dropdown-select>
            </div>
            <div class="task-form-horizontal-group-item">
              <app-dropdown-select
                placeholder="Add To Column"
                [inputFormControl]="taskForm.controls['columnId']"
                [options]="columnOptions"
              ></app-dropdown-select>
            </div>
          </div>
        </app-form-collapseable-group>

        <app-form-collapseable-group
          title="Task Details"
          icon="info"
          appScrollAnchor
        >
          <div class="task-form-horizontal-group" [@collapseMotion]="taskForm.get('notifications')?.controls?.length">
            <div class="task-form-horizontal-group-item">
              <app-notification-dropdown [inputFormArray]="taskForm.get('notifications')"></app-notification-dropdown>
            </div>
          </div>

          <div class="task-form-horizontal-group">
            <div class="task-form-horizontal-group-full-width-item">
              <app-priority-input
                [inputFormControl]="taskForm.controls['priority']"
              ></app-priority-input>
            </div>
          </div>

          <div class="task-form-horizontal-group">
            <div class="task-form-horizontal-group-item">
              <app-dropdown-select
                placeholder="Organizer"
                [inputFormControl]="taskForm.controls['owner']"
                [options]="organizerOptions"
              ></app-dropdown-select>
            </div>
            <div class="task-form-horizontal-group-item">
              <app-color-picker-button
                [inputFormControl]="taskForm.controls['color']"
                [defaultColor]=""
              ></app-color-picker-button>
            </div>
          </div>

          <div class="task-form-horizontal-group" [@collapseMotion]="taskForm.controls['participants']?.value">
            <div class="task-form-horizontal-group-full-width-item">
              <app-participants-input [inputFormControl]="taskForm.controls['participants']"></app-participants-input>
            </div>
          </div>

          <div class="task-form-horizontal-group" [@collapseMotion]="displayDescription">
            <div class="task-form-horizontal-group-full-width-item" >
              <div *ngIf="!displayDescription">
                <span class="task-form-actions-text-button" (click)="displayDescription = true"> + Add Description </span>
              </div>
              <div *ngIf="displayDescription">
                <app-textarea placeholder="Description" [inputFormControl]="taskForm.controls['description']" width="100%" rows="5"></app-textarea>
              </div>
            </div>
          </div>
        </app-form-collapseable-group>

        <app-form-collapseable-group
          title="Subtasks"
          class="task-form-subtasks-section"
          appScrollAnchor
          [extendIconEnabled]="true"
          *ngIf="task && task.id && !task.parentId"
        >
          <app-task-form-subtasks
            [task]="task"
          ></app-task-form-subtasks>
        </app-form-collapseable-group>

        <div class="task-form-horizontal-group">
          <div class="task-form-horizontal-group-full-width-item">
            <app-linked-info-list
              class="linked-info-list"
              [parentLinkedInfo]="{type: 'task', id: task?.id}"
              [linkedInfo]="task?.linkedInfo"
              (extendAction)="openLinkedInfoDynamicPanel()"
            ></app-linked-info-list>
          </div>
        </div>
      </div>
    </app-scroll>
  </form>
</div>

<ng-template #dropPopoverFormTemplate>
  <div class="drop-popover">
    <app-button icon="subtaskAsBackground" appearance="big" (click)="handleCreateSubTaskOnDrop()">Create a Subtask</app-button>
    <div class="drop-popover-separator"></div>
    <app-button icon="link-bold" appearance="big" (click)="handleLinkOnDrop()">Link an Item</app-button>
  </div>
</ng-template>
