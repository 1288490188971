// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Rx
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { File } from '@modules/files/types/file';

// Services
import { FilesStateService } from '@modules/files/services/files-state.service';
import { LayoutStateService } from '@modules/pages/services/layout-state.service';

@Component({
  selector: 'app-files-main-view',
  templateUrl: './files-main-view.component.html',
  styleUrls: ['./files-main-view.component.less']
})
export class FilesMainViewComponent implements OnInit, OnDestroy {

  // Public
  public view: 'empty' | 'file-form';
  public file: File = new File();

  // Private
  private componentNotDestroyed = new Subject<void>();

  // Constructor
  constructor(
    private filesStateService: FilesStateService,
    private layoutStateService: LayoutStateService
  ) { }

  // Lifecycle
  public ngOnInit() {
    this.filesStateService.getMainView()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(view => {
        this.view = view;
        if (view === 'file-form') { this.layoutStateService.setLinkedInfoToolbarPresence(true); }
      });

    this.filesStateService.getMainViewFile()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((file: File) => {
        this.file = file;
      });
  }

  public ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.layoutStateService.setLinkedInfoToolbarPresence(false);
  }

  // Actions
  public handleFormClose() {
    this.filesStateService.setMainView('empty');
  }
}
