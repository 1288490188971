/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-quick-reply-templates.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../common/components/text-editor/text-editor.component.ngfactory";
import * as i5 from "../../../common/components/text-editor/text-editor.component";
import * as i6 from "../../../settings/services/state.service";
import * as i7 from "./account-quick-reply-templates.component";
import * as i8 from "../../services/account.service";
var styles_AccountQuickReplyTemplatesComponent = [i0.styles];
var RenderType_AccountQuickReplyTemplatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountQuickReplyTemplatesComponent, data: {} });
export { RenderType_AccountQuickReplyTemplatesComponent as RenderType_AccountQuickReplyTemplatesComponent };
function View_AccountQuickReplyTemplatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick reply template is saved"]))], null, null); }
function View_AccountQuickReplyTemplatesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountQuickReplyTemplatesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-3x fa-fw"]], null, null, null, null, null))], null, null); }
function View_AccountQuickReplyTemplatesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h4", [["class", "list-group-item-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-success p-xxs"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openForm(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-danger p-xxs"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteTemplate(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "list-group-item-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.content; _ck(_v, 7, 0, currVal_1); }); }
function View_AccountQuickReplyTemplatesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountQuickReplyTemplatesComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quickReplyTemplates; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AccountQuickReplyTemplatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "box-header with-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "box-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick reply templates"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-success m-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add new quick reply template "])), (_l()(), i1.ɵeld(8, 0, null, null, 22, "div", [["class", "box-body with-padding"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "input-group-addon"], ["id", "reply-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "input", [["aria-describedby", "reply-title"], ["class", "form-control"], ["placeholder", "Enter title text"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.titleInputValue = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(15, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-text-editor", [], null, [[null, "contentChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contentChange" === en)) {
        var pd_0 = ((_co.replyTemplate.content = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TextEditorComponent_0, i4.RenderType_TextEditorComponent)), i1.ɵdid(20, 770048, null, 0, i5.TextEditorComponent, [i6.StateService], { content: [0, "content"] }, { contentChange: "contentChange" }), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary m-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "btn btn-secondary m-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountQuickReplyTemplatesComponent_1)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountQuickReplyTemplatesComponent_2)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountQuickReplyTemplatesComponent_3)), i1.ɵdid(30, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountQuickReplyTemplatesComponent_4)), i1.ɵdid(32, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.titleInputValue; _ck(_v, 15, 0, currVal_8); var currVal_9 = _co.replyTemplate.content; _ck(_v, 20, 0, currVal_9); var currVal_10 = _co.saved; _ck(_v, 26, 0, currVal_10); var currVal_11 = _co.errorMessage; _ck(_v, 28, 0, currVal_11); var currVal_12 = _co.loading; _ck(_v, 30, 0, currVal_12); var currVal_13 = (_co.quickReplyTemplates && _co.quickReplyTemplates.length); _ck(_v, 32, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.showForm ? "hidden" : ""); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 17).ngClassValid; var currVal_6 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_AccountQuickReplyTemplatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-quick-reply-templates", [], null, null, null, View_AccountQuickReplyTemplatesComponent_0, RenderType_AccountQuickReplyTemplatesComponent)), i1.ɵdid(1, 114688, null, 0, i7.AccountQuickReplyTemplatesComponent, [i8.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountQuickReplyTemplatesComponentNgFactory = i1.ɵccf("app-account-quick-reply-templates", i7.AccountQuickReplyTemplatesComponent, View_AccountQuickReplyTemplatesComponent_Host_0, {}, {}, []);
export { AccountQuickReplyTemplatesComponentNgFactory as AccountQuickReplyTemplatesComponentNgFactory };
