import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  of as createObservableOf } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { UserAvatar } from '../types/user-avatar';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserAvatarService {
  private static readonly sources: string[] = [
    'GRAVATAR',
    'GOOGLE',
    'NOT_FOUND'
  ];

  private processingAvatars: {[key: string]: {
    observer: Observable<UserAvatar>
    source: string
  }} = {};

  constructor(private http: HttpClient) {}

  fetch(email: string, size: number, avatar?: UserAvatar): Observable<UserAvatar> {
    const savedAvatar = this.getSavedAvatar(email, size);
    if (savedAvatar) {
      return createObservableOf(savedAvatar);
    }

    const processingAvatar = this.processingAvatars[email];
    const nextSource = avatar
      ? this.getNextSource(avatar.source)
      : UserAvatarService.sources[0];

    if (!nextSource) {
      this.save(email, {
        isNotFound: true,
      } as UserAvatar);
      return createObservableOf({ isNotFound: true } as UserAvatar);
    }
    if (processingAvatar &&
        (processingAvatar.source === nextSource ||
        processingAvatar.source === 'NOT_FOUND')) {
      return processingAvatar.observer;
    }
    return this.getAvatarObserver(
      email,
      size,
      nextSource
    );
  }

  save(email: string, avatar: UserAvatar): void {
    if (this.processingAvatars[email]) {
      delete this.processingAvatars[email];
    }
    sessionStorage.setItem(email, JSON.stringify(avatar));
  }

  private getSavedAvatar(email: string, size: number): UserAvatar | null {
    const avatar = sessionStorage.getItem(email);
    return avatar !== null
      ? this.convertAvatarBySize(JSON.parse(avatar) as UserAvatar, size)
      : null;
  }

  private convertAvatarBySize(avatar: UserAvatar, size: number): UserAvatar {
    if (avatar.size >= size || avatar.isNotFound) {
      return avatar;
    }
    const pattern = avatar.source === 'GRAVATAR' ? '?s=' : 's';
    avatar.url = avatar.url.replace(pattern + avatar.size, pattern + size);
    avatar.size = size;
    return avatar;
  }

  private getNextSource(previousSource: string): string | undefined {
    let index = UserAvatarService.sources.indexOf(previousSource);
    index = (index === -1) ? index : index + 1;
    return UserAvatarService.sources[index];
  }

  private getAvatarObserver(
    email: string,
    size: number,
    source: string = 'GRAVATAR'
  ): Observable<UserAvatar> {
    let observer: Observable<UserAvatar>;
    switch (source) {
    case 'GRAVATAR':
      observer = this.getGravatar(email, size);
      break;
    case 'GOOGLE':
      observer = this.getGoogle(email, size);
      break;
    case 'NOT_FOUND':
      const avatar = {
        source: 'NOT_FOUND',
        isNotFound: true
      } as UserAvatar;
      this.save(email, avatar);
      return createObservableOf(avatar);
    }
    this.processingAvatars[email] = {
      observer,
      source
    };
    return observer;
  }

  private getGoogle(email: string, size: number): Observable<UserAvatar> {
    return this.http.get('//picasaweb.google.com/data/entry/api/user/' + email + '?alt=json')
      .pipe(
        map(response => {
          return response['entry']['gphoto$thumbnail']['$t'] || '';
        }),
        map(url => url.replace('s64', 's' + size)),
        map(url => {
          return {
            url,
            size,
            source: 'GOOGLE',
          } as UserAvatar;
        }),
        catchError(error => createObservableOf({
            size: size,
            source: 'GOOGLE'
          } as UserAvatar)
        )
      );
  }

  private getGravatar(email: string, size: number): Observable<UserAvatar> {
    const hash = Md5.hashStr(email).toString();
    const avatar: UserAvatar = {
        url: '//secure.gravatar.com/avatar/' + hash + '?s=' + size + '&d=404',
        source: 'GRAVATAR',
        size
      } as UserAvatar;
    return createObservableOf(avatar);
  }
}
