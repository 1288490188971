import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { AccountSignature } from '../../types/account-signature';

@Component({
  selector: 'app-account-signature',
  templateUrl: './account-signature.component.html',
  styleUrls: ['./account-signature.component.css']
})
export class AccountSignatureComponent implements OnInit {
  state: string;
  loadingSignatures: boolean;
  errorMessage: string;
  selectedSignature: AccountSignature;
  showForm: boolean;
  signatures: AccountSignature[] = [];

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.errorMessage = '';
    this.getSignatures();
    this.selectedSignature = { title: '', content: '' } as AccountSignature;
  }

  getSignatures() {
    this.loadingSignatures = true;
    this.accountService.getSignatures(true)
      .subscribe(
        signatures => {
          this.loadingSignatures = false;
          this.signatures = signatures;
        },
        error => {
          this.loadingSignatures = false;
          this.state = 'error';
          this.errorMessage = error;
        }
      );
  }

  saveSignature() {
    this.errorMessage = '';

    if (!this.selectedSignature.title || this.selectedSignature.title === '' ||
        !this.selectedSignature.content || this.selectedSignature.content === '') {
      this.errorMessage = 'Signature title and content are required';
      this.state = 'error';
      return;
    }

    this.state = 'processing';
    this.accountService.saveSignature(this.selectedSignature)
      .subscribe(
        result => {
          if (!result['success']) {
            this.errorMessage = result['error'];
            this.state = 'error';
            return;
          }
          this.state = 'saved';
          this.closeForm();
          this.getSignatures();
        },
        error => {
          this.state = 'error';
          this.errorMessage = error;
        }
      );
  }

  removeSignature(signature: AccountSignature) {
    this.state = 'processing';
    this.closeForm();
    this.accountService.removeSignature(signature.id)
      .subscribe(
        () => {
          this.state = 'deleted';
          this.getSignatures();
        },
        error => console.error(error)
      );
  }

  openForm(signature?: AccountSignature) {
    this.closeForm();
    this.state = null;
    if (signature) {
      this.selectedSignature = signature;
    }
    this.showForm = true;
  }

  closeForm() {
    this.showForm = false;
    this.resetSignature();
  }

  setDefault(signature: AccountSignature): void {
    this.closeForm();
    this.state = 'processing';
    this.accountService.saveSignature(signature, true)
      .subscribe(
        () => {
          this.state = 'defaultSet';
          this.getSignatures();
        },
        error => {
          this.state = 'error';
          this.errorMessage = error;
        }
      );
  }

  resetSignature() {
    this.selectedSignature = { title: '', content: '' } as AccountSignature;
  }
}
