<div class="message-content__wrapper">
  <div
    class="mail-info"
    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    (appDroppableDrop)="dndDrop($event)"
  >
    <!-- Message -->
    <div class="message">

      <!-- Sticky -->
      <div class="message__header-sticky" [class.shadow]="showHeaderShadow">
        <!-- Header -->
        <div class="message__header">
          <div
            class="header__actions"
            appDraggable
            [appDraggableData]="{data: [message], type: 'message'}"
            [appDraggablePlaceholder]="dragPlaceholder"
            [appDraggableContainerAdjustFit]="true"
            [appDraggableContainerStyles]="{'max-width': '300px'}"
          >
            <div class="actions">
              <button type="button" class="button-icon-small btn-action" (click)="starMessage(message)" [ngClass]="{'starred': message.starred}" [matTooltip]="message.starred ? 'Unstar' : 'Star'">
                <mat-icon svgIcon="star"></mat-icon>
              </button>
              <button type="button" class="button-icon-small btn-action" (click)="pinMessage(message)" [ngClass]="{'pinned': message.pinned}" [matTooltip]="message.pinned ? 'Unpin' : 'Pin'">
                <mat-icon svgIcon="pin"></mat-icon>
              </button>
              <button type="button" class="button-icon-small btn-action" (click)="replyMessage('reply', message)" matTooltip="Reply">
                <mat-icon svgIcon="reply"></mat-icon>
              </button>
              <button type="button" class="button-icon-small btn-action" (click)="replyMessage('forward', message)" matTooltip="Forward">
                <mat-icon svgIcon="forward-small"></mat-icon>
              </button>
              <button type="button" class="button-icon-small btn-action" (click)="moveMessageToFolder(message, 'trash')" matTooltip="Delete">
                <mat-icon svgIcon="trash"></mat-icon>
              </button>
              <app-message-context-menu-wrapper *ngIf="!message?.sending" [message]="message" [eventTrigger]="'click'" [dropdownStyle]="true">
                <button type="button" class="button-icon-small btn-action">
                  <mat-icon svgIcon="more-actions"></mat-icon>
                </button>
              </app-message-context-menu-wrapper>
            </div>
            <div class="time">
              <span>{{ message.sentTime | relativeDate }}</span>
              <span *ngIf="message.openedAt"> (Read {{ message.openedAt | relativeDate }})</span>
            </div>
            <div class="components">
              <app-icon-button icon="attachment-small" class="btn-component" (click)="openAttachmentsDynamicPanel()"></app-icon-button>
              <app-icon-button icon="link-thin" (click)="openLinkedInfoDynamicPanel()"></app-icon-button>
            </div>
            <mat-icon class="drag-icon" svgIcon="drag-icon-small"></mat-icon>
          </div>
          <div class="header__info">
            <app-user-avatar
              class="header__avatar"
              size="28"
              [contact]="message.from"
              [usePopover]="true"
              [appContactContextMenu]="message.from">
            </app-user-avatar>
            <div class="header__recipients">
              <div class="from">
                {{ message.from?.name || message.from?.email }}
                <mat-icon *ngIf="message.from?.vip" svgIcon="crown"></mat-icon>
              </div>
              <div class="to">
                <div class="label-recipients">To:</div>
                <app-contacts-counter-collapse
                  [contactList]="message.to"
                  [selectedContact]="contact"
                  (selectedContactChange)="selectContact($event)">
                </app-contacts-counter-collapse>
              </div>
              <div class="cc" *ngIf="message?.cc && message?.cc.length">
                <div class="label-recipients">CC:</div>
                <app-contacts-counter-collapse
                  [contactList]="message.cc"
                  [selectedContact]="contact"
                  (selectedContactChange)="selectContact($event)">
                </app-contacts-counter-collapse>
              </div>
              <div class="cc" *ngIf="message?.bcc && message?.bcc.length">
                <div class="label-recipients">BCC:</div>
                <app-contacts-counter-collapse
                  [contactList]="message.bcc"
                  [selectedContact]="contact"
                  (selectedContactChange)="selectContact($event)">
                </app-contacts-counter-collapse>
              </div>
            </div>
          </div>
          <div class="header__subject">
            <div class="subject">{{ message.subject || '(no subject)' }}</div>
            <mat-icon class="replied" *ngIf="message.replied" svgIcon="replied"></mat-icon>
            <mat-icon class="replied" *ngIf="message.forwarded" svgIcon="forward"></mat-icon>
            <app-icon-button
              *ngIf="thread?.messages?.length"
              class="thread"
              icon="reply"
              [text]="(thread?.count - thread?.offset) + ''"
              [badge]="thread?.unread_count > 0"
              (click)="thread.expanded = !thread.expanded"
            ></app-icon-button>
          </div>
        </div>

        <!-- Thread -->
        <div class="message__thread" *ngIf="thread?.expanded && thread?.messages?.length">
          <app-message-context-menu-wrapper *ngFor="let threadMessage of thread?.messages" [message]="threadMessage">
            <div class="thread__message" (click)="openMessageModal(threadMessage)">
              <div class="status__sent">
                <mat-icon *ngIf="threadMessage.unread" class="icon unread" svgIcon="mail-unread"></mat-icon>
                <mat-icon *ngIf="!threadMessage.unread && threadMessage.replied" class="icon" svgIcon="replied"></mat-icon>
                <mat-icon *ngIf="!threadMessage.unread && threadMessage.forwarded" class="icon" svgIcon="forward"></mat-icon>
              </div>
              <div class="status" *ngIf="threadMessage.pinned || threadMessage.starred">
                <mat-icon *ngIf="threadMessage.pinned" class="icon" svgIcon="mail-pinned"></mat-icon>
                <mat-icon *ngIf="threadMessage.starred" class="icon" svgIcon="mail-star"></mat-icon>
              </div>
              <div class="name">{{ threadMessage.from?.name || threadMessage.from?.email || '(unknown)' }}</div>
              <div class="attachment">
                <mat-icon *ngIf="threadMessage.files && threadMessage.files.length" class="icon" svgIcon="mail-attachment"></mat-icon>
              </div>
              <div class="snippet">{{ threadMessage.snippet }}</div>
              <div class="time">{{ threadMessage.sentTime | relativeDate }}</div>
              <mat-icon class="preview-icon" svgIcon="preview"></mat-icon>
            </div>
          </app-message-context-menu-wrapper>
          <div class="tread__load-more" *ngIf="!thread?.loading && thread?.count > thread?.messages?.length + thread?.offset">
            <div class="tread__label" (click)="loadTreadMessages()">
              + {{ thread?.count - thread?.messages?.length - thread?.offset }} more
            </div>
          </div>
          <div class="tread__load-more" *ngIf="thread?.loading">
            <div class="tread__label" (click)="loadTreadMessages()">
              <div class="loader"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
            </div>
          </div>
        </div>

        <!-- Tags -->
        <app-tag-list
          class="message__tags"
          [message]="message"
          [(tags)]="message.tags"
        ></app-tag-list>

        <!-- Pellets -->
        <app-form-collapseable-group title="Pellets" icon="pellets-small" class="message__topics">
          <app-mail-topics
            [message]="message"
            [(topics)]="message.topics"
            [(temporalExpressions)]="message.temporalExpressions"
            (visibleTopicsChange)="changeVisibleTopics($event)"
            [selectedTopics]="selectedTopics"
            (selectedTopicsChange)="selectTopics($event)"
            [selectedTemporalExpressions]=""
            (selectedTemporalExpressionsChange)="selectTemporalExpressions($event)"
            [highlightedTopics]="highlightTopics"
            [newTopic]="newTopicText"
          ></app-mail-topics>
        </app-form-collapseable-group>
      </div>

      <!-- Body scroll -->
      <div class="message__body-scroll" (scroll)="handleBodyScroll($event)">
        <!-- Body -->
        <div class="message__body">
          <div class="body-content" [sttSelectedTextTooltip]="selectedTextTooltipTemplate" (sttSelectedText)="selectedText = $event">
            <app-caja [body]="highlightedBody"></app-caja>
          </div>
          <!-- Tooltip for selected text -->
          <ng-template #selectedTextTooltipTemplate>
            <div class="selected-text-actions-bar" (mousedown)="$event.preventDefault()">
              <mat-icon class="selected-text-actions-button" svgIcon="pellets" (click)="addSelectedTextTopic()" matTooltip="Add as Pellet"></mat-icon>
              <mat-icon class="selected-text-actions-button" svgIcon="events" (click)="addSelectedTextAppointment()" matTooltip="Create Event"></mat-icon>
              <mat-icon class="selected-text-actions-button" svgIcon="tasks" (click)="addSelectedTextTask()" matTooltip="Create Task"></mat-icon>
              <mat-icon class="selected-text-actions-button" svgIcon="app-menu-notes" matTooltip="Create Note"></mat-icon>
              <div class="divider"></div>
              <mat-icon class="selected-text-actions-button search" svgIcon="app-menu-explore" (click)="exploreSelectedText()" matTooltip="Search in Pellet Map"></mat-icon>
              <mat-icon class="selected-text-actions-button search" svgIcon="search-small" (click)="searchSelectedText()" matTooltip="Search Emails"></mat-icon>
            </div>
          </ng-template>
        </div>

        <!-- Attachments -->
        <app-attachments-list
          class="attachments"
          *ngIf="message.files?.length"
          [attachments]="message.files"
          (extendAction)="openAttachmentsDynamicPanel()"
        ></app-attachments-list>

        <!-- Actions -->
        <div class="message__actions" *ngIf="replyState === 'selecting'">
          <app-button icon="reply" appearance="blue" (click)="selectReplyState('reply')" [menu]="message.participants?.length > 2 || message.files?.length" (menuClick)="showPopover($event, replayButtonMenu)">REPLY</app-button>
          <app-button icon="forward-bold" class="button-forward" (click)="selectReplyState('forward')" [menu]="message.files?.length" (menuClick)="showPopover($event, forwardButtonMenu)">FORWARD</app-button>

          <ng-template #replayButtonMenu>
            <div *ngIf="message.participants?.length > 2" class="dropdown-item" (click)="selectReplyState('replyAll')">Reply All</div>
            <div *ngIf="message.files?.length" class="dropdown-item" (click)="selectReplyState('reply', message.files)">Reply with Attachment</div>
            <div *ngIf="message.participants?.length > 2 && message.files?.length" class="dropdown-item" (click)="selectReplyState('replyAll', message.files)">Reply All with Attachment</div>
          </ng-template>

          <ng-template #forwardButtonMenu>
            <div *ngIf="message.files?.length" class="dropdown-item" (click)="selectReplyState('forward', message.files)">Forward with Attachment</div>
          </ng-template>
        </div>

        <!-- Reply inline -->
        <div class="message__reply" *ngIf="replyState !== 'selecting'">
          <app-message-editor
            [header]="true"
            [(message)]="editorMessage"
            [sourceMessage]="message"
            [action]="replyState"
            (sent)="closeReply()"
            (draftSaved)="closeReply()"
            (cancel)="closeReply()">
          </app-message-editor>
        </div>

        <!-- Linked Info -->
        <app-linked-info-list
          class="linked-info"
          [parentLinkedInfo]="{type: 'message', id: message.id}"
          [linkedInfo]="message.linkedInfo"
          [extendEnabled]="true"
          [extendSelected]="dynamicPanel === 'linkedInfo'"
          (extendAction)="openLinkedInfoDynamicPanel()"
        ></app-linked-info-list>
      </div>

    </div>

    <!-- Drop zone -->
    <div class="drop-zone"></div>
  </div>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <app-message
    [message]="message"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [threadEnabled]="false">
  </app-message>
</ng-template>
