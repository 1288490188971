import { Pipe, PipeTransform } from '@angular/core';
import { FileUpload } from '@modules/form-controls/types/file-upload';

@Pipe({
  name: 'expandAttachments'
})
export class FileUploaderExpandAttachmentsPipe implements PipeTransform {

  transform(value: FileUpload[], expanded: boolean, amountOfCollapsedAttachments: number): FileUpload[] {
    if (expanded || value.length <= amountOfCollapsedAttachments) {
      return value;
    } else {
      return value.slice(0, amountOfCollapsedAttachments);
    }
  }
}
