/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon-button.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./icon-button.component";
var styles_IconButtonComponent = [i0.styles];
var RenderType_IconButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconButtonComponent, data: {} });
export { RenderType_IconButtonComponent as RenderType_IconButtonComponent };
function View_IconButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "button-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_IconButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "button-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_IconButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "button-badge"]], null, null, null, null, null))], null, null); }
export function View_IconButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "button"]], [[2, "highlighted", null], [2, "disabled", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.text && _co.text.length); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.icon; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.badge; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.highlighted && !_co.disabled); var currVal_1 = _co.disabled; var currVal_2 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_IconButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon-button", [], null, null, null, View_IconButtonComponent_0, RenderType_IconButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.IconButtonComponent, [], null, null)], null, null); }
var IconButtonComponentNgFactory = i1.ɵccf("app-icon-button", i5.IconButtonComponent, View_IconButtonComponent_Host_0, { icon: "icon", text: "text", badge: "badge", disabled: "disabled", highlighted: "highlighted" }, { clicked: "clicked" }, []);
export { IconButtonComponentNgFactory as IconButtonComponentNgFactory };
