<div class="link-info-suggestions__wrapper" [ngClass]="{'context-menu': appearance === 'context-menu'}">
  <mat-icon *ngIf="appearance === 'context-menu'" svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  <div class="title" *ngIf="title && appearance === 'context-menu'">{{ title }}</div>
  <div class="input-container app-form-controls-input-standard">
    <mat-form-field [appearance]="'standard'" [ngStyle]="{width: '100%'}" [floatLabel]="'never'">
      <input matInput [placeholder]="inputPlaceholder" [formControl]="inputFormControl">
    </mat-form-field>
  </div>
  <app-container-overflow-shadow class="shadow-container" appearance="gray">
    <div class="suggections">
      <ng-container *ngFor="let item of items">
        <ng-container *ngTemplateOutlet="itemTemplateRef;context:{item: item}"></ng-container>
      </ng-container>
      <div class="placeholder" *ngIf="!items?.length">{{emptyPlaceholder}}</div>
    </div>
  </app-container-overflow-shadow>
  <div class="footer">
    <div class="button-create text-no-select" (click)="creating()">+ {{ createTitle }}</div>
  </div>
</div>
