/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./async-task-toast.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./async-task-toast.component";
import * as i6 from "ngx-toastr";
var styles_AsyncTaskToastComponent = [i0.styles];
var RenderType_AsyncTaskToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsyncTaskToastComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "active", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "removed", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "{{ easeTime }}ms {{ easing }}" }, options: null }, { type: 1, expr: "active => removed", animation: { type: 4, styles: null, timings: "{{ easeTime }}ms {{ easing }}" }, options: null }], options: {} }] } });
export { RenderType_AsyncTaskToastComponent as RenderType_AsyncTaskToastComponent };
function View_AsyncTaskToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "toast-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AsyncTaskToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "toast-action-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_AsyncTaskToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "toast-message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsyncTaskToastComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "toast-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsyncTaskToastComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-icon", [["class", "toast-close-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.actions; _ck(_v, 6, 0, currVal_2); var currVal_5 = "close"; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 8).inline; var currVal_4 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_AsyncTaskToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-async-task-toast", [], [[8, "className", 0], [40, "@flyInOut", 0], [4, "display", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AsyncTaskToastComponent_0, RenderType_AsyncTaskToastComponent)), i1.ɵdid(1, 180224, null, 0, i5.AsyncTaskToastComponent, [i6.ToastrService, i6.ToastPackage, i1.NgZone], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toastClasses; var currVal_1 = i1.ɵnov(_v, 1).state; var currVal_2 = i1.ɵnov(_v, 1).displayStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var AsyncTaskToastComponentNgFactory = i1.ɵccf("app-async-task-toast", i5.AsyncTaskToastComponent, View_AsyncTaskToastComponent_Host_0, {}, {}, []);
export { AsyncTaskToastComponentNgFactory as AsyncTaskToastComponentNgFactory };
