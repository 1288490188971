/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts-counter-collapse.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./contacts-counter-collapse.component";
var styles_ContactsCounterCollapseComponent = [i0.styles];
var RenderType_ContactsCounterCollapseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsCounterCollapseComponent, data: {} });
export { RenderType_ContactsCounterCollapseComponent as RenderType_ContactsCounterCollapseComponent };
function View_ContactsCounterCollapseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectContact(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "selected-connection": 0, "list-inline__item": 1 }), (_l()(), i1.ɵted(4, null, [" ", "", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.context.$implicit.name === ((_co.selectedContact == null) ? null : _co.selectedContact.name)) && (_v.context.$implicit.email === ((_co.selectedContact == null) ? null : _co.selectedContact.email))), true); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (_v.context.$implicit.name || _v.context.$implicit.email); var currVal_2 = (_v.context.last ? "" : ","); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_ContactsCounterCollapseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContacts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "inline-center"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["+ ", " more "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "collapse-down"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "collapse-down"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenContactsState.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).inline; var currVal_2 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_ContactsCounterCollapseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "contacts-counter list-inline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterCollapseComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterCollapseComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibleContacts; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.contactList == null) ? null : _co.contactList.length) > _co.minContactLen); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ContactsCounterCollapseComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectContact(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "selected-connection": 0, "list-inline__item": 1 }), (_l()(), i1.ɵted(4, null, [" ", "", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.context.$implicit.name === ((_co.selectedContact == null) ? null : _co.selectedContact.name)) && (_v.context.$implicit.email === ((_co.selectedContact == null) ? null : _co.selectedContact.email))), true); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (_v.context.$implicit.name || _v.context.$implicit.email); var currVal_2 = (_v.context.last ? "" : ","); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_ContactsCounterCollapseComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "contacts-counter list-inline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterCollapseComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContacts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "inline-center"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show less "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "collapse-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenContactsState; _ck(_v, 2, 0, currVal_0); var currVal_3 = "collapse-up"; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).inline; var currVal_2 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_ContactsCounterCollapseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterCollapseComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsCounterCollapseComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isCollapsed; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ContactsCounterCollapseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts-counter-collapse", [], null, null, null, View_ContactsCounterCollapseComponent_0, RenderType_ContactsCounterCollapseComponent)), i1.ɵdid(1, 573440, null, 0, i5.ContactsCounterCollapseComponent, [], null, null)], null, null); }
var ContactsCounterCollapseComponentNgFactory = i1.ɵccf("app-contacts-counter-collapse", i5.ContactsCounterCollapseComponent, View_ContactsCounterCollapseComponent_Host_0, { contactList: "contactList", selectedContact: "selectedContact", minContactLen: "minContactLen" }, { selectedContactChange: "selectedContactChange" }, []);
export { ContactsCounterCollapseComponentNgFactory as ContactsCounterCollapseComponentNgFactory };
