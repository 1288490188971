import * as tslib_1 from "tslib";
// Rx
import { Subject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Types
import { Group } from '../types/group';
// Env
import { environment } from '@environment';
// Decorators
import { warmUpObservable } from '@decorators';
var GroupsService = /** @class */ (function () {
    /**
     * Constructor
     */
    function GroupsService(http, linkedInfoService, asyncTasksToastsService, topicService) {
        this.http = http;
        this.linkedInfoService = linkedInfoService;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.topicService = topicService;
        // Public
        this.createNewGroup = new Subject();
        this.updatedGroup = new Subject();
        this.deletedGroup = new Subject();
    }
    /**
     * Settings
     */
    GroupsService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Helpers
     */
    GroupsService.prototype.formatFilters = function (filters) {
        var formatedFilters = {};
        if (filters.groupsIds) {
            formatedFilters['groups_ids[]'] = filters.groupsIds;
        }
        if (filters.groupsIds) {
            formatedFilters['groups_ids[]'] = filters.groupsIds;
        }
        if (filters.archived) {
            formatedFilters['archived'] = filters.archived + '';
        }
        if (filters.deleted) {
            formatedFilters['deleted'] = filters.deleted + '';
        }
        if (filters.pinned) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if (filters.keywords) {
            formatedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.topics) {
            formatedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formatedFilters['tags[]'] = filters.tags;
        }
        if (filters.order) {
            formatedFilters['order'] = filters.order;
        }
        if (filters.orderWithPinned) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.limit) {
            formatedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formatedFilters['offset'] = filters.offset + '';
        }
        return formatedFilters;
    };
    /**
     * Methods
     */
    GroupsService.prototype.getGroups = function (filters) {
        if (filters === void 0) { filters = {}; }
        var requestParams = { params: this.formatFilters(filters) };
        // return this.http.get<GroupsListResponse>(environment.baseUrl + '/api/groups', requestParams)
        return of({ groups: mockGroups, count: 4 })
            .pipe(map(function (_a) {
            var count = _a.count, groups = _a.groups;
            return ({ count: count, groups: groups.map(function (group) { return new Group(group); }) });
        }), catchError(GroupsService.handleObserverError));
    };
    GroupsService.prototype.getGroup = function (id) {
        return this.http.get(environment.baseUrl + '/api/groups/' + id)
            .pipe(map(function (_a) {
            var group = _a.group;
            return new Group(group);
        }));
    };
    GroupsService.prototype.create = function (groupInstance) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/groups", groupInstance.asPayloadJSON())
            .pipe(map(function (_a) {
            var group = _a.group, success = _a.success;
            return ({ group: new Group(group), success: success });
        }), tap(function (_a) {
            var group = _a.group, success = _a.success;
            if (success) {
                _this.createNewGroup.next(group);
                _this.asyncTasksToastsService.show({ text: "Group(s) created." });
            }
        }), map(function (_a) {
            var group = _a.group;
            return group;
        }), tap(function (group) {
            if (groupInstance.linkedInfo) {
                _this.linkedInfoService.linkToItem({ type: 'group', id: group.id }, groupInstance.linkedInfo);
            }
        }), switchMap(function (group) {
            if (groupInstance.topics.length === 0) {
                return of(group);
            }
            return _this.topicService.createTopics(groupInstance.topics, { groupsIds: [group.id] })
                .pipe(map(function () { return group; }));
        }), catchError(function (error) {
            _this.asyncTasksToastsService.show({ text: error.error.error });
            return throwError(error);
        }));
    };
    GroupsService.prototype.update = function (groupInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/groups/' + groupInstance.id, groupInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var group = _a.group, success = _a.success;
            if (success) {
                _this.updatedGroup.next();
                _this.asyncTasksToastsService.show({ text: 'Group updated' });
            }
        }), map(function (_a) {
            var group = _a.group;
            return group;
        }));
    };
    GroupsService.prototype.pin = function (groupsIds, status) {
        var _this = this;
        return forkJoin(groupsIds.map(function (groupId) { return _this.http.put(environment.baseUrl + '/api/groups/' + groupId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.group; }); }), tap(function (success) {
            if (success) {
                _this.updatedGroup.next();
                _this.asyncTasksToastsService.show({ text: "Group" + (groupsIds.length > 1 ? 's' : '') + " " + (groupsIds.length > 1 ? 'are' : 'is') + " " + (status ? '' : 'un') + "pinned" });
            }
        }));
    };
    GroupsService.prototype.archive = function (groupsIds, archived) {
        var _this = this;
        return forkJoin(groupsIds.map(function (groupId) { return _this.http.put(environment.baseUrl + '/api/groups/' + groupId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.group; }); }), tap(function (success) {
            if (success) {
                _this.updatedGroup.next();
                _this.asyncTasksToastsService.show({ text: "Group(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    GroupsService.prototype.deletePermanently = function (groupsIds) {
        var _this = this;
        return forkJoin(groupsIds.map(function (groupId) { return _this.http.delete(environment.baseUrl + '/api/groups/' + groupId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.deletedGroup.next();
                _this.asyncTasksToastsService.show({ text: "Group(s) successfully deleted." });
            }
        }));
    };
    GroupsService.prototype.delete = function (groupsIds, deleted) {
        var _this = this;
        return forkJoin(groupsIds.map(function (groupId) { return _this.http.put(environment.baseUrl + '/api/groups/' + groupId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.updatedGroup.next();
                _this.asyncTasksToastsService.show({ text: "Group(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    GroupsService.prototype.upsert = function (groupForm) {
        var group = Group.fromFormGroup(groupForm);
        return groupForm.get('id').value ?
            this.update(group) : this.create(group);
    };
    tslib_1.__decorate([
        warmUpObservable
    ], GroupsService.prototype, "update", null);
    tslib_1.__decorate([
        warmUpObservable
    ], GroupsService.prototype, "pin", null);
    tslib_1.__decorate([
        warmUpObservable
    ], GroupsService.prototype, "archive", null);
    tslib_1.__decorate([
        warmUpObservable
    ], GroupsService.prototype, "deletePermanently", null);
    tslib_1.__decorate([
        warmUpObservable
    ], GroupsService.prototype, "delete", null);
    return GroupsService;
}());
export { GroupsService };
var mockGroups = [
    {
        id: '1',
        name: 'Stitch Developers',
        pinned: true,
        starred: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        description: 'I have detailed below the most cost effective…',
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
    {
        id: '2',
        name: 'Stitch Business',
        createdAt: new Date(),
        updatedAt: new Date(),
        description: 'I have detailed below the most cost effective…',
        participants: [
            {
                id: 'michael.skubenych@gmail.com',
                email: 'michael.skubenych@gmail.com',
                name: null,
                normalizedName: 'michael.skubenych@gmail.com',
                vip: false
            },
            {
                id: 'vikapmtest@gmail.com',
                email: 'vikapmtest@gmail.com',
                name: null,
                normalizedName: 'vikapmtest@gmail.com',
                vip: false
            }
        ],
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
    {
        id: '3',
        name: 'Stitch Design',
        starred: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        description: 'I have detailed below the most cost effective…'
    },
    {
        id: '4',
        name: 'Stitch Management',
        starred: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        description: 'I have detailed below the most cost effective…',
        linkedInfo: [
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'note',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'event',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'task',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'message',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'group',
                createdAt: '2020-08-31T15:27:38.686Z'
            },
            {
                id: '7s3qyb0zhilxcabqxll968s8y',
                type: 'contact',
                createdAt: '2020-08-31T15:27:38.686Z'
            }
        ]
    },
];
