<span
  class="dropdown"
  popover
  [popoverPlacement]="placement"
  [popoverArrow]="false"
  [popoverTrigger]="trigger"
  [popoverContent]="dropdownContent"
  [popoverAllowedOutsideSelectorsClick]="'.dropdown-toggle-wrapper, ' + allowedOutsideSelectors"
  [popoverShowUntil]="popoverClose"
  (popoverVisibleChange)="dropdownOpen = $event"
>
  <span class="dropdown-toggle-wrapper">
    <ng-container *ngIf="toggleButtonTemplate" [ngTemplateOutlet]="toggleButtonTemplate"></ng-container>
    <div class="dropdown-toggle-button" *ngIf="!toggleButtonTemplate">
      <div class="dropdown-title">{{title}}</div>
      <i *ngIf="icon" class="fa fa-{{icon}} dropdown-toggle"></i>
      <mat-icon *ngIf="!icon" svgIcon="filter" class="dropdown-icon"></mat-icon>
    </div>
  </span>
</span>

<ng-template #dropdownContent>
  <div
    class="dropdown-content"
    [style.max-height.px]="maxHeight"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
