// Common
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { DynamicPanelContent, DynamicPanelData } from '@modules/pages/types/dynamic-panel-data';
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-attachments-dynamic-panel',
  templateUrl: './attachments-dynamic-panel.component.html',
  styleUrls: ['../styles/dynamic-panel.less', './attachments-dynamic-panel.component.less']
})
export class AttachmentsDynamicPanelComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() attachments: Attachment[];
  @Input() attachmentsFormControl: AbstractControl;
  @Input() parentTitle: string;

  // Public
  public orderOptions: DropdownOption[] = [
    { name: 'Date', key: 'date'},
  ];
  public dynamicPanelData: DynamicPanelData;

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private dynamicPanelService: DynamicPanelService,
    private modalService: ModalService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.dynamicPanelService.getContent()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({ data }: DynamicPanelContent) => this.dynamicPanelData = data);
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  closed() {
    this.dynamicPanelService.setContent();
  }

  openInModalWindow() {
    this.modalService.showAttachmentsListModal(this.dynamicPanelData.attachments, this.dynamicPanelData.attachmentsFormControl);
  }
}
