import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
var TagListComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function TagListComponent(modalService) {
        this.modalService = modalService;
        // Outputs
        this.tagsChange = new EventEmitter();
        // Public
        this.hidePopover = new Subject();
        // Private
        this.componentNotDestroyed = new Subject();
    }
    /**
     * Component lifecycle
     */
    TagListComponent.prototype.ngOnInit = function () {
    };
    TagListComponent.prototype.ngOnDestroy = function () {
        this.hidePopover.next();
        this.hidePopover.complete();
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    TagListComponent.prototype.showTagsModal = function () {
        this.modalService.topicList();
    };
    TagListComponent.prototype.deleteTag = function (tag) {
        var index = this.tags.indexOf(tag);
        if (index > -1) {
            this.tags.splice(index, 1);
            this.tagsChange.emit(this.tags);
        }
    };
    TagListComponent.prototype.createdTags = function (tags) {
        var _a;
        (_a = this.tags).push.apply(_a, tslib_1.__spread(tags));
        this.tagsChange.emit(this.tags);
        this.hidePopover.next();
    };
    TagListComponent.prototype.closeCreateTagPopover = function () {
        this.hidePopover.next();
    };
    return TagListComponent;
}());
export { TagListComponent };
