// Common
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { trigger, style, transition, animate } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';

// Types
import { Calendar } from '@modules/events/types/calendar';
import { CalendarEvent } from '@modules/events/types/calendar-event';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { MailService } from '@modules/mail/services/mail.service';

// Pipe
import { DateTimePipe } from '@modules/pipes/pipes/date-time.pipe';
import { CalendarContact } from '@modules/events/types/calendar-contact';

@Component({
  selector: 'app-calendar-create-event',
  templateUrl: './calendar-create-event.component.html',
  styleUrls: ['./calendar-create-event.component.less'],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: '0', opacity: 0}),
        animate('200ms ease-in', style({ height: '200px', opacity: 1}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ height: '0', opacity: 0}))
      ])
    ]),
  ]
})
export class CalendarCreateEventComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() selectedDate: Date;
  @Input() selectedEvent: CalendarEvent;

  // Outputs
  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() selectedEventChange: EventEmitter<CalendarEvent> = new EventEmitter<CalendarEvent>();
  @Output() eventCreated: EventEmitter<void> = new EventEmitter();
  @Output() cancelEventChange: EventEmitter<void> = new EventEmitter();

  // Public
  public calendars: Calendar[];
  public eventForm: FormGroup;
  public showMoreOptions = false;
  public participants: string[] = [];
  public formExpanded = false;
  public dropPlaceholder = {
    contact: false,
    email: false
  };

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    protected changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dateTimePipe: DateTimePipe
  ) {
    this.createForm();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendars: Calendar[]) => {
        if (calendars && calendars.length) {
          this.calendars = calendars;
          this.updateSelectedCalendar();
        } else {
          this.calendars = [];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedDate' in changes) {
      this.updateFormDates();
    }
    if ('selectedEvent' in changes && this.selectedEvent) {
      const formData: any = {};
      // Dates
      if (this.selectedEvent.when) {
        formData.startTime = new Date(this.selectedEvent.when.start);
        formData.endTime = new Date(this.selectedEvent.when.end);
        formData.startDate = new Date(this.selectedEvent.when.start);
        formData.endDate = new Date(this.selectedEvent.when.end);
      } else if (this.selectedEvent.id) {
        formData.startTime = null;
        formData.endTime = null;
        formData.startDate = this.selectedDate;
        formData.endDate = this.selectedDate;
      }
      // Title
      if (this.selectedEvent.title || this.selectedEvent.id) {
        formData.title = this.selectedEvent.title;
      }
      // Calendar ID
      const defaultCalendarId = (this.calendars && this.calendars.length) ? this.calendars[0].id : '';
      formData.calendarId = this.selectedEvent.calendarId || defaultCalendarId;
      // Description
      if (this.selectedEvent.description || this.selectedEvent.id) {
        formData.description = this.selectedEvent.description;
      }
      // AllDay
      if ((this.selectedEvent.when && this.selectedEvent.when.durationType) || this.selectedEvent.id) {
        formData.allDay = this.selectedEvent.when.durationType === 'day';
      }
      // Participants
      this.participants = (this.selectedEvent && this.selectedEvent.participants)
        ? this.selectedEvent.participants.map(contact => contact.email)
        : [];
      // Show more options
      this.showMoreOptions = (this.participants.length > 0) || !!this.selectedEvent.description;
      // Disabled form for readOnly event
      if (this.selectedEvent.readOnly) {
        this.eventForm.disable();
      } else {
        this.eventForm.enable();
      }
      // Update form data
      this.eventForm.patchValue(formData);
    }
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Forms
   */

  updateFormDates() {
    if (!this.eventForm.value.startDate || !this.eventForm.value.endDate ||
       (this.eventForm.value.startDate.getTime() === this.eventForm.value.endDate.getTime()) ||
       (this.selectedDate.getTime() > this.eventForm.value.endDate.getTime())) {
      this.eventForm.patchValue({endDate: this.selectedDate});
    }
    this.eventForm.patchValue({startDate: this.selectedDate});
  }

  updateSelectedCalendar() {
    const availableCalendars = this.calendars.filter(calendar => !calendar.readOnly);
    const defaultCalendarId = (availableCalendars && availableCalendars.length) ? availableCalendars[0].id : '';
    this.eventForm.patchValue({calendarId: defaultCalendarId});
  }

  createForm() {
    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required],
      calendarId: ['', Validators.required],
      startDate: [this.selectedDate, Validators.required],
      endDate: [this.selectedDate, Validators.required],
      startTime: null,
      endTime: null,
      description: '',
      allDay: true,
      inputParticipantEmail: '',
    });
    this.eventForm.controls['allDay'].valueChanges
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((allDay: boolean) => {
        const startTime = this.eventForm.controls['startTime'];
        const endTime = this.eventForm.controls['endTime'];
        if (allDay) {
          startTime.clearValidators();
          endTime.clearValidators();
        } else {
          startTime.setValidators(Validators.required);
          endTime.setValidators(Validators.required);
        }
        startTime.updateValueAndValidity();
        endTime.updateValueAndValidity();
        this.changeDetector.detectChanges();
      });
    this.eventForm.valueChanges
      .subscribe((formModel: any) => {
        this.formExpanded = (formModel.title && formModel.title.length) ||
                            formModel.startTime ||
                            (formModel.description && formModel.description.length) ||
                            this.participants.length;
      });
  }

  /**
   * Actions
   */

  selectStartDate(date: Date) {
    this.selectedDate = date;
    this.eventForm.patchValue({startDate: date});
    this.selectedDateChange.emit(date);
  }

  selectEndDate(date: Date) {
    this.eventForm.patchValue({endDate: date});
  }

  addParticipant() {
    const participant = this.eventForm.value.inputParticipantEmail;
    if (participant.length && MailService.emailRegExp.test(participant.toLowerCase())) {
      this.participants.push(participant);
      this.eventForm.patchValue({inputParticipantEmail: ''});
    }
  }

  deleteParticipant(participant: string) {
    const index = this.participants.indexOf(participant);
    if (index > -1) {
      this.participants.splice(index, 1);
    }
  }

  deleteEvent() {
    const eventId = this.selectedEvent.id;
    if (eventId && window.confirm('Are sure you want to delete this event?')) {
      this.calendarService.deleteEvent([eventId], true)
        .subscribe((result: boolean) => {
          if (result) {
            this.eventCreated.emit();
            this.cancel();
          }
        });
    }
  }

  onSubmit() {
    // Params
    const formModel = this.eventForm.value;
    const calendarEvent = new CalendarEvent();
    calendarEvent.calendarId = formModel.calendarId;
    calendarEvent.title = formModel.title;
    calendarEvent.description = formModel.description;
    let startTime;
    let endTime;
    if (!formModel.allDay) {
      startTime = this.dateTimePipe.transform(formModel.startDate, formModel.startTime);
      endTime = this.dateTimePipe.transform(formModel.endDate, formModel.endTime);
    } else {
      startTime = new Date(Date.UTC(formModel.startDate.getFullYear(), formModel.startDate.getMonth(), formModel.startDate.getDate()));
      endTime = new Date(Date.UTC(formModel.endDate.getFullYear(), formModel.endDate.getMonth(), formModel.endDate.getDate()));
    }
    calendarEvent.when = {start: startTime, end: endTime};
    calendarEvent.when.durationType = formModel.allDay ? 'day' : 'timespan';
    calendarEvent.participants = this.participants.map(email => {
      const calendarContact = new CalendarContact();
      calendarContact.email = email;
      return calendarContact;
    });
    if (this.selectedEvent && this.selectedEvent.id) {
      // Update event
      calendarEvent.id = this.selectedEvent.id;
      this.calendarService.editEvent(calendarEvent)
        .subscribe((result) => {
          this.eventCreated.emit();
          this.cancel();
        });
    } else {
      // Create event
      this.calendarService.createEvent(calendarEvent)
        .subscribe((result) => {
          this.eventCreated.emit();
          this.cancel();
        });
    }
  }

  cancel() {
    this.selectedEvent = null;
    this.selectedEventChange.emit(null);
    this.participants = [];
    this.showMoreOptions = false;
    this.eventForm.enable();
    this.eventForm.reset({
      description: '',
      allDay: true,
      inputParticipantEmail: ''
    });
    this.updateFormDates();
    this.cancelEventChange.emit();
    this.updateSelectedCalendar();
    if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
    }
  }

  resetDropPlaceholder() {
    this.dropPlaceholder.contact = false;
    this.dropPlaceholder.email = false;
  }

}
