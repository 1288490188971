import { Component, Input, Output, OnDestroy, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { KnowledgeGraphService } from '../../../mail/services/knowledge-graph.service';
import { MailService } from '../../../mail/services/mail.service';

// Types
import { DropdownOption } from '../../../dropdown/types/dropdown-option';
import { MailFolder } from '../../../mail/types/mail-folder';

@Component({
  selector: 'app-insights-topic-details',
  templateUrl: './insights-topic-details.component.html',
  styleUrls: ['./insights-topic-details.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class InsightsTopicDetailsComponent implements OnChanges, OnDestroy {

  // Input
  @Input() topics: string[];
  @Input() timeFrame: Date;
  @Input() toTime: Date;
  @Input() selectedFolder: string;

  // Output
  @Output() topicSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() changedSelectedFolder: EventEmitter<string> = new EventEmitter();

  // Public
  public selectedSimilarTopics: string[] = [];
  public similarTopics: string[] = [];
  public similarTopicsCount = 0;
  public similarTopicsLoader = true; // for showing when loading first data set
  public similarTopicsCountLoader = true;
  public orderOptions = [
    {name: 'Relevance', key: 'relevance'},
    {name: 'A-Z', key: 'name-asc'},
    {name: 'Z-A', key: 'name-desc'},
  ];
  public selectedOrder: DropdownOption;
  public foldersCounters: MailFolder[] = [];
  public foldersCountersDropdown: MailFolder[] = [];
  public countOtherFolders = 0;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private similarTopicsLimit: 20;

  constructor(private knowledgeGraphService: KnowledgeGraphService, private mailService: MailService) {}

  /**
   * Component lifecycle
   */

  ngOnChanges() {
    if (this.topics.length) {
      this.selectedOrder = this.orderOptions[0];
      this.selectTopic(null);
      this.loadSimilarTopics(false);
      this.loadFolderCounters(this.topics);
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  loadSimilarTopics(more: boolean = true) {
    if (!more) {
      this.similarTopics = [];
      this.similarTopicsCount = 0;
    } else if (this.similarTopicsLoader || this.similarTopics.length >= this.similarTopicsCount) {
      return;
    }

    this.similarTopicsLoader = true;
    this.knowledgeGraphService
      .getRelatedTopics(
        {
          topics: this.topics,
          fromTime: this.timeFrame,
          toTime: this.toTime,
        },
        this.similarTopicsLimit,
        this.similarTopics.length,
        this.selectedOrder.key
      )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({data, count}) => {
        this.similarTopics.push(...data);
        this.similarTopicsCount = count;
        this.similarTopicsLoader = false;
      });
  }

  loadFolderCounters(topics: string[]) {
    this.foldersCounters = [];
    this.foldersCountersDropdown = [];
    this.similarTopicsCountLoader = true;
    this.mailService
      .getFoldersCounts({
        relatedTopics: topics,
        fromTime: this.timeFrame,
        toTime: this.toTime,
      })
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((counters: MailFolder[]) => {
        this.similarTopicsCountLoader = false;
        const sliceCount = (counters.length === 3) ? 3 : 2;
        this.foldersCounters = counters.slice(0, sliceCount);
        this.foldersCountersDropdown = counters.slice(sliceCount);
        this.countOtherFolders = this.foldersCountersDropdown
          .reduce((sum, folder) => sum + folder.totalMails || 0, 0);
      });
  }

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.loadSimilarTopics(false);
  }

  selectTopic(topic: string, event?: MouseEvent) {
    if (topic) {
      if (!event.shiftKey) {
        if (this.selectedSimilarTopics.length === 1 && this.selectedSimilarTopics[0] === topic) {
          this.selectedSimilarTopics = [];
        } else {
          this.selectedSimilarTopics = [topic];
        }
      } else {
        if (this.selectedSimilarTopics.includes(topic)) {
          this.selectedSimilarTopics = this.selectedSimilarTopics.filter(t => t !== topic);
        } else {
          this.selectedSimilarTopics = this.selectedSimilarTopics.concat([topic]);
        }
      }
    }

    this.topicSelected.emit(this.selectedSimilarTopics);
    this.loadFolderCounters(this.topics.concat(this.selectedSimilarTopics));
  }

  selectFolder(id: string) {
    this.selectedFolder = id;
    this.changedSelectedFolder.emit(this.selectedFolder);
  }
}
