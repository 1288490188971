import * as tslib_1 from "tslib";
// Rx
import { Subject, throwError, of, forkJoin } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Types
import { Task } from '../types/task';
// Env
import { environment } from '@environment';
// Decorators
import { warmUpObservable } from '@decorators';
var TasksService = /** @class */ (function () {
    /**
     * Constructor
     */
    function TasksService(http, linkedInfoService, asyncTasksToastsService, topicService) {
        this.http = http;
        this.linkedInfoService = linkedInfoService;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.topicService = topicService;
        // Public
        this.taskCreated = new Subject();
        this.taskUpdated = new Subject();
        this.taskDeleted = new Subject();
    }
    /**
     * Settings
     */
    TasksService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Helpers
     */
    TasksService.prototype.formatFilters = function (filters) {
        var formattedFilters = {};
        if (filters.tasksIds) {
            formattedFilters['tasks_ids[]'] = filters.tasksIds;
        }
        if (filters.projectsIds) {
            formattedFilters['projects_ids[]'] = filters.projectsIds;
        }
        if (filters.noProject !== null && filters.noProject !== undefined) {
            formattedFilters['no_project'] = filters.noProject + '';
        }
        if (filters.parentsIds) {
            formattedFilters['parents_ids[]'] = filters.parentsIds;
        }
        if (filters.noParent !== null && filters.noParent !== undefined) {
            formattedFilters['no_parent'] = filters.noParent + '';
        }
        if (filters.archived !== null && filters.archived !== undefined) {
            formattedFilters['archived'] = filters.archived + '';
        }
        if (filters.deleted !== null && filters.deleted !== undefined) {
            formattedFilters['deleted'] = filters.deleted + '';
        }
        if (filters.pinned !== null && filters.pinned !== undefined) {
            formattedFilters['pinned'] = filters.pinned + '';
        }
        if (filters.fromTime) {
            formattedFilters['from_time'] = filters.fromTime.getTime() + '';
        }
        if (filters.toTime) {
            formattedFilters['to_time'] = filters.toTime.getTime() + '';
        }
        if (filters.scheduled !== null && filters.scheduled !== undefined) {
            formattedFilters['scheduled'] = filters.scheduled + '';
        }
        if (filters.priority) {
            formattedFilters['priority'] = filters.priority;
        }
        if (filters.keywords) {
            formattedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.topics) {
            formattedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formattedFilters['tags[]'] = filters.tags;
        }
        if (filters.contacts) {
            formattedFilters['contacts[]'] = filters.contacts;
        }
        if (filters.order) {
            formattedFilters['order'] = filters.order;
        }
        if (filters.orderWithPinned) {
            formattedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.limit) {
            formattedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formattedFilters['offset'] = filters.offset + '';
        }
        return formattedFilters;
    };
    /**
     * Methods
     */
    TasksService.prototype.getTasks = function (filters) {
        if (filters === void 0) { filters = {}; }
        var requestParams = { params: this.formatFilters(filters) };
        return this.http.get(environment.baseUrl + '/api/tasks', requestParams)
            .pipe(map(function (_a) {
            var count = _a.count, tasks = _a.tasks;
            return ({ count: count, tasks: tasks.map(function (task) { return new Task(task); }) });
        }), catchError(TasksService.handleObserverError));
    };
    TasksService.prototype.getTask = function (id) {
        return this.http.get(environment.baseUrl + '/api/tasks/' + id)
            .pipe(map(function (_a) {
            var task = _a.task;
            return new Task(task);
        }));
    };
    TasksService.prototype.create = function (taskInstance) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/tasks", taskInstance.asPayloadJSON())
            .pipe(map(function (_a) {
            var task = _a.task, success = _a.success;
            return ({ task: new Task(task), success: success });
        }), tap(function (_a) {
            var task = _a.task, success = _a.success;
            if (success) {
                _this.taskCreated.next(task);
                _this.asyncTasksToastsService.show({ text: "Task(s) created." });
            }
        }), tap(function (_a) {
            var task = _a.task;
            if (taskInstance.linkedInfo) {
                _this.linkedInfoService.linkToItem({ type: 'task', id: task.id }, taskInstance.linkedInfo);
            }
        }), map(function (_a) {
            var task = _a.task;
            return task;
        }), catchError(function (error) {
            _this.asyncTasksToastsService.show({ text: error.error.error });
            return throwError(error);
        }), switchMap(function (task) {
            if (taskInstance.topics.length === 0) {
                return of(task);
            }
            return _this.topicService.createTopics(taskInstance.topics, { tasksIds: [task.id] })
                .pipe(map(function () { return task; }));
        }));
    };
    TasksService.prototype.update = function (taskInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/tasks/' + taskInstance.id, taskInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var task = _a.task, success = _a.success;
            if (success) {
                _this.taskUpdated.next();
                _this.asyncTasksToastsService.show({ text: 'Task updated' });
            }
        }), map(function (_a) {
            var task = _a.task;
            return task;
        }));
    };
    TasksService.prototype.reorder = function (sectionId, tasksIds, projectId, columnId, parentId) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/tasks', {
            saveOrder: !columnId,
            saveBoardOrder: !!columnId,
            sectionId: sectionId || null,
            projectId: projectId,
            columnId: columnId || null,
            parentId: parentId || null
        }, { params: { 'tasks_ids[]': tasksIds } })
            .pipe(tap(function (_a) {
            var success = _a.success;
            if (success) {
                _this.taskUpdated.next();
            }
        }), map(function (_a) {
            var tasks = _a.tasks;
            return tasks.map(function (task) { return new Task(task); });
        }));
    };
    TasksService.prototype.pin = function (tasksIds, status) {
        var _this = this;
        return forkJoin(tasksIds.map(function (taskId) { return _this.http.put(environment.baseUrl + '/api/tasks/' + taskId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.task; }); }), tap(function (success) {
            if (success) {
                _this.taskUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Task" + (tasksIds.length > 1 ? 's' : '') + " " + (tasksIds.length > 1 ? 'are' : 'is') + " " + (status ? '' : 'un') + "pinned" });
            }
        }));
    };
    TasksService.prototype.archive = function (tasksIds, archived) {
        var _this = this;
        return forkJoin(tasksIds.map(function (taskId) { return _this.http.put(environment.baseUrl + '/api/tasks/' + taskId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.task; }); }), tap(function (success) {
            if (success) {
                _this.taskUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Task(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    TasksService.prototype.deletePermanently = function (tasksIds) {
        var _this = this;
        return forkJoin(tasksIds.map(function (taskId) { return _this.http.delete(environment.baseUrl + '/api/tasks/' + taskId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.taskUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Task(s) successfully deleted." });
            }
        }));
    };
    TasksService.prototype.delete = function (tasksIds, deleted) {
        var _this = this;
        return forkJoin(tasksIds.map(function (taskId) { return _this.http.put(environment.baseUrl + '/api/tasks/' + taskId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.taskUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Task(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    TasksService.prototype.upsert = function (taskForm) {
        var task = Task.fromFormGroup(taskForm);
        return taskForm.get('id').value ?
            this.update(task) : this.create(task);
    };
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "create", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "update", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "reorder", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "pin", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "archive", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "deletePermanently", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TasksService.prototype, "delete", null);
    return TasksService;
}());
export { TasksService };
