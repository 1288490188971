<div 
  *ngIf="view === 'empty'"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-tasks.svg"/>
</div>

<app-contact-form-container
  *ngIf="view === 'contact-form'"
  [contact]="contact"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-contact-form-container>

<app-group-form-container
  *ngIf="view === 'group-form'"
  [group]="group"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-group-form-container>
