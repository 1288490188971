<div 
  class="event" 
  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [event], type: 'event'}"
  [appDraggablePlaceholder]="draggablePlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  (appClick)="handleClick()"
  (appDblClick)="handleDblClick()"
  [appEventContextMenu]="event"
  popover
  popoverPlacement="right"
  popoverTrigger="hover"
  [popoverContent]="detailsView"
  [popoverDisabled]="!dragEnabled || isDragging"
>
  <mat-icon
    class="event-icon"
    svgIcon="calendar"
    [ngClass]="{today: event?.when?.durationType === 'timespan' && event?.when?.start | includesNow : event?.when?.end}"
    [ngStyle]="{ color: event?.color || (event?.calendarId | calendarColor | async) }"
  ></mat-icon>
  <mat-icon class="event-dotted-line" svgIcon="agenda-dots"></mat-icon>
  <mat-icon class="event-pin-icon" svgIcon="pin-small-color" *ngIf="!event?.pinned"></mat-icon>
  <div class="event-content">
    <div class="event-content-title">{{ event.title }}</div>
    <div class="event-content-info">
      <mat-icon class="event-content-info-icon" svgIcon="time" [ngClass]="{today: event?.when?.durationType === 'timespan' && event?.when?.start | includesNow : event?.when?.end}"></mat-icon>
      <span class="event-content-info-time" [ngClass]="{today: event?.when?.durationType === 'timespan' && event?.when?.start | includesNow : event?.when?.end}">
        <span *ngIf="event?.when?.durationType === 'day'">All Day</span>
        <span *ngIf="event?.when?.durationType === 'timespan' && (!event?.when?.start || !event?.when?.end)">No selected time</span>
        <span *ngIf="event?.when?.durationType === 'timespan' && event?.when?.start && event?.when?.end">
          {{ event.when.start | date: 'h:mm' }} - {{ event.when.end | date: 'h:mm aaa' | lowercase }}
        </span>
      </span>
      <mat-icon class="event-content-info-repeat-icon" svgIcon="repeat"></mat-icon>
    </div>
  </div>
  
  <div class="event-linked-info">
    <mat-icon class="event-linked-info-icon" svgIcon="link-thin"></mat-icon>
    <app-link-info-bar class="event-linked-info-bar" [linkedInfo]="event?.linkedInfo"></app-link-info-bar>
  </div>
</div>

<ng-template #draggablePlaceholder>
  <app-events-agenda-event [event]="event" [dragEnabled]="false"></app-events-agenda-event>
</ng-template>

<ng-template #detailsView>
  <event-popover-preview [event]="event"></event-popover-preview>
</ng-template>
