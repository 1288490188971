import * as tslib_1 from "tslib";
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
import { Task } from '@modules/tasks/types/task';
var SidebarTaskContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SidebarTaskContextMenuComponent, _super);
    // Constructor
    function SidebarTaskContextMenuComponent(contextMenuService, tasksStateService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.tasksStateService = tasksStateService;
        _this.priority = 'normal';
        return _this;
    }
    // Actions
    SidebarTaskContextMenuComponent.prototype.createTask = function () {
        var task = new Task({ priority: this.priority, fromTime: this.startDate });
        this.tasksStateService.openTaskForm(task);
    };
    return SidebarTaskContextMenuComponent;
}(ContextMenuComponent));
export { SidebarTaskContextMenuComponent };
