// Common
import {
  Component,
  Input,
  EventEmitter,
  QueryList,
  ContentChildren,
  Output,
  OnDestroy,
  AfterContentInit,
  ChangeDetectorRef,
  ElementRef,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit,
  Optional,
} from '@angular/core';

// RX
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { startWith, takeUntil, switchMap, map, scan, debounceTime, filter } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { ContentMenuService } from '../../services/content-menu.service';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';

// Types
import { CollapsedStateKey } from '@modules/settings/types/collapsed-state';

@Component({
  selector: 'app-content-menu-item',
  templateUrl: './content-menu-item.component.html',
  styleUrls: ['./content-menu-item.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuItemComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentMenuItemComponent extends ContentMenuBaseItemComponent implements AfterContentInit, OnChanges, OnDestroy {

  // Content Children
  @ContentChildren(
    ContentMenuBaseItemComponent,
    { descendants: true }
  ) items: QueryList<ContentMenuBaseItemComponent>;

  // Inputs
  @Input() icon: string;
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() active: boolean;
  @Input() hover: boolean;
  @Input() pinned: boolean;
  @Input() badge: number;
  @Input() badgeTemplate: TemplateRef<any>;
  @Input() collapsedStateKey: CollapsedStateKey;
  @Input() parent: ContentMenuItemComponent; // https://github.com/angular/angular/issues/16299
  @Input() templateOutlet: TemplateRef<any>;
  @Input() templateOutletContext: Object;
  @Input() iconColor = '';
  @Input() minimizedAppearanceType: 'popup' | 'expand' = 'popup';

  // Outputs
  @Output() createIconSelected = new EventEmitter();

  // Public
  public expandable = false;
  public collapsed = true;
  public bodyHeight = 0;
  public treeHeight = 0;
  public popoverOpened = false;
  public self: ContentMenuItemComponent = null;
  public headerHeight = 24;

  // Private
  private connectedItemsStream = new Subject<{type: 'add' | 'remove', item: ContentMenuBaseItemComponent}>();
  private connectedItems = new BehaviorSubject<ContentMenuBaseItemComponent[]>([]);
  protected contentInit = new Subject<null>();

  /**
   * Constructor
   */

  constructor (
    protected stateService: StateService,
    public elementRef: ElementRef,
    changeDetector: ChangeDetectorRef,
    @Optional() contentMenuService: ContentMenuService,
  ) {
    super(changeDetector, contentMenuService);

    this.connectedItemsStream
      .pipe(
        scan((items, action) => action.type === 'add' ?
          [...items, action.item] :
          items.filter(item => item !== action.item),
          []
        ),
        takeUntil(this.alive)
      )
      .subscribe((items: ContentMenuBaseItemComponent[]) => {
        this.connectedItems.next(items);
        this.contentInit.next(); // switchMap -> combineLatest should handle this without contentInit emitting
        // - but for some reason it doesn't
      });

    this.contentInit
      .pipe(
        debounceTime(100), // just to reassure and prevent possible performance issues
        switchMap(() => (
          combineLatest([
            this.items.changes.pipe(
              map(() => this.getItems()),
              startWith(this.getItems())
            ),
            this.connectedItems.pipe(
              startWith([]),
            )
          ])
        )),
        map(([items, connectedItems]) => [ ...items, ...connectedItems]),
        switchMap(items => (
          combineLatest(
            items.map(item => item.heightChange
              .pipe(
                startWith(item.headerHeight),
                map((height: number) => ({ height, componentType: item.constructor.name }))
              )
            )
          )
        )),
        takeUntil(this.alive)
      )
        .subscribe((items: { height: number, componentType: string }[]) => {
          if (items.length === 0) {
            return;
          }

          const newExpandable = items.length > 0;
          const newBodyHeight = items.map(item => item.height).reduce((a, b) => a + b);
          const newTreeHeight = this.calculateTreeHeight(items);

          if (
            this.bodyHeight !== newBodyHeight ||
            this.treeHeight !== newTreeHeight ||
            this.expandable !== newExpandable
          ) {
            this.expandable = newExpandable;
            this.bodyHeight = newBodyHeight;
            this.treeHeight = newTreeHeight;
            this.emitHeight();
            this.detectChanges();
          }
        });
  }

  /**
   * Component lifecycle
   */

  ngAfterContentInit() {
    this.self = this;
    this.detectChanges();
    this.contentInit.next();
    this.emitHeight();

    this.stateService.getCollapsed(this.collapsedStateKey)
      .pipe(
        filter(() => !!this.collapsedStateKey),
        takeUntil(this.alive)
      )
      .subscribe((collapsed: boolean) => {
        this.collapsed = collapsed;
        this.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('secondLevel' in changes) {
      this.detectChanges();
    }

    if ('root' in changes && this.root) {
      const items = this.getItems();
      items.forEach(i => i.secondLevel = true);
      this.detectChanges();
    }

    if ('parent' in changes && this.parent) {
      this.parent.connectItem(this);
    }
  }

  ngOnDestroy() {
    if (this.parent) {
      this.parent.disconnectItem(this);
    }
    this.connectedItems.complete();
    this.contentInit.complete();
    this.connectedItemsStream.complete();
    super.ngOnDestroy();
  }

  /**
   * Actions
   */

  emitHeight() {
    this.heightChange.emit(this.headerHeight + (this.collapsed ? 0 : this.bodyHeight));
  }

  trigger() {
    this.collapsed = !this.collapsed;
    if (this.collapsedStateKey) {
      this.stateService.setCollapsed(this.collapsedStateKey, this.collapsed);
    }
    this.emitHeight();
    this.detectChanges();
  }

  createIconSelect(event: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.createIconSelected.emit(event);
  }

  connectItem(item: ContentMenuItemComponent) {
    this.connectedItemsStream.next({ type: 'add', item });
  }

  disconnectItem(item: ContentMenuItemComponent) {
    this.connectedItemsStream.next({ type: 'remove', item });
  }

  /**
   * Helpers
   */

  getItems() {
    return this.items.filter(item => item !== this);
  }

  calculateTreeHeight(items: {height: number, componentType: string}[]): number {
    return items.slice(0, -1).reverse().reduce((memo, item) => {
      let itemFound = memo.itemFound;
      if (!itemFound && item.componentType === 'ContentMenuItemComponent') {
        itemFound = true;
      }
      return { itemFound, total: (itemFound ? item.height : 0 ) + memo.total};
    }, {total: 0, itemFound: false}).total;
  }
}
