import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
var ContactsListContainerComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function ContactsListContainerComponent(splitViewService, contactsStateService, searchService, stateService) {
        var _this = this;
        this.splitViewService = splitViewService;
        this.contactsStateService = contactsStateService;
        this.searchService = searchService;
        this.stateService = stateService;
        this.quickFormExpanded = false;
        this.orders = [
            { name: 'Recency', key: 'recency' },
            { name: 'Date', key: 'date' },
            { name: 'Title', key: 'title' },
        ];
        this.scrollOptions = [
            { name: 'Scroll to top', key: 'scroll-top' },
            { name: 'Scroll to bottom', key: 'scroll-bottom' }
        ];
        this.quickFormGroup = new Group();
        this.quickFormContact = new Contact();
        this.quickFormToggle = new Subject();
        // Private
        this.alive = new Subject();
        this.searchTitle = new BehaviorSubject('');
        combineLatest([
            this.searchService.getSearchParams(),
            this.searchTitle,
            this.contactsStateService.getSidebarFilters()
        ])
            .pipe(takeUntil(this.alive))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), searchParams = _b[0], title = _b[1], sidebarFilters = _b[2];
            var keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];
            _this.listFilters = tslib_1.__assign({}, searchParams, sidebarFilters, { keywords: !title || title.trim() === '' ? keywords : tslib_1.__spread(keywords, [title]) });
        });
    }
    /**
     * Component lifecycle
     */
    ContactsListContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splitViewService.getMinimized('contactsList')
            .pipe(takeUntil(this.alive))
            .subscribe(function (minimized) {
            _this.minimized = minimized;
        });
        this.contactsStateService.getListState()
            .pipe(takeUntil(this.alive))
            .subscribe(function (state) { return _this.listState = state; });
        this.contactsStateService.getContactsFilters()
            .pipe(takeUntil(this.alive))
            .subscribe(function (filters) { return _this.listFilters = filters; });
        this.stateService.getSidebarFilters('contacts')
            .pipe(takeUntil(this.alive))
            .subscribe(function (currentSidebarFilter) { return _this.currentSidebarFilter = currentSidebarFilter; });
        this.contactsStateService.getGroup()
            .pipe(takeUntil(this.alive))
            .subscribe(function (group) { return _this.selectedSidebarGroup = group; });
        this.selectedOrder = this.stateService.sortContactsList;
    };
    ContactsListContainerComponent.prototype.ngOnDestroy = function () {
        this.alive.next();
        this.alive.complete();
    };
    /**
     * Actions
     */
    ContactsListContainerComponent.prototype.handleScrollOptionChange = function (scrollOption) {
        var _this = this;
        this.listScrollPosition = null;
        setTimeout(function () { return _this.listScrollPosition = scrollOption === 'scroll-top' ? 0 : -1; });
    };
    ContactsListContainerComponent.prototype.handleSelectOrder = function (order) {
        this.selectedOrder = order;
        this.stateService.sortContactsList = order;
    };
    ContactsListContainerComponent.prototype.handleNewContact = function (contact) {
        if (contact === void 0) { contact = new Contact(); }
        this.contactsStateService.openContactForm(contact);
    };
    ContactsListContainerComponent.prototype.handleNewGroup = function (group) {
        if (group === void 0) { group = new Group(); }
        this.contactsStateService.openGroupForm(group);
    };
    ContactsListContainerComponent.prototype.handleSearchQueryChange = function (title) {
        this.searchTitle.next(title);
    };
    ContactsListContainerComponent.prototype.handleGroupQuickFormDrop = function (dragData) {
        var group = Group.fromDragData(dragData);
        if (!group) {
            return;
        }
        this.quickFormGroup = group;
        this.quickFormToggle.next();
    };
    ContactsListContainerComponent.prototype.handleContactQuickFormDrop = function (dragData) {
        var contact = Contact.fromDragData(dragData);
        if (!contact) {
            return;
        }
        this.quickFormContact = contact;
        this.quickFormToggle.next();
    };
    return ContactsListContainerComponent;
}());
export { ContactsListContainerComponent };
