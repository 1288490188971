<app-content-menu>
  <app-content-menu-item
    icon="events"
    title="Calendars"
    [active]="sidebarFilter === 'calendars'"
    collapsedStateKey="eventsSidebarCalendars"
    (click)="selectCategory('calendars')"
    [contextMenu]="calendarsContext"
    (createIconSelected)="createCalendarDialog($event)"
    #calendarPopoverOrigin
  >
    <app-content-menu-checkbox
      *ngFor="let calendar of calendars; trackBy: calendarTracker"
      [title]="calendar.name"
      [color]="calendar.color"
      [appCalendarContextMenu]="calendar"
      [checked]="selectedCalendarIds | includesBy : calendar.id"
      (checkedChange)="handleCalendarSelect($event, calendar)"
      (click)="$event.stopPropagation()"
    ></app-content-menu-checkbox>
  </app-content-menu-item>

  <app-content-menu-item
    title="Archive"
    icon="folder-archive"
    [active]="sidebarFilter === 'archived'"
    (click)="selectCategory('archived')"
  ></app-content-menu-item>
  <app-content-menu-item
    title="Trash"
    icon="trash-bucket"
    [active]="sidebarFilter === 'deleted'"
    (click)="selectCategory('deleted')"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-item
    title="Filters"
    icon="filter"
    collapsedStateKey="eventsSidebarFilters"
  >
    <app-content-menu-checkbox icon="reminder" title="Reminders"></app-content-menu-checkbox>
    <app-content-menu-checkbox icon="app-menu-notes" title="Notes"></app-content-menu-checkbox>
    <app-content-menu-checkbox icon="tasks" title="Tasks"></app-content-menu-checkbox>
    <app-content-menu-checkbox icon="app-menu-projects" title="Missions"></app-content-menu-checkbox>
  </app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <!-- Spaces -->
  <app-content-menu-item [icon]="'spaces'" [title]="'Spaces'">
    <app-content-menu-item [title]="'test'"></app-content-menu-item>
    <app-content-menu-item [title]="'test'"></app-content-menu-item>
  </app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <!-- Pellets -->
  <app-content-menu-pellets
    collapsedStateKey="eventsSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <!-- Tags -->
  <app-content-menu-tags
    collapsedStateKey="eventsSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>

<context-menu #calendarsContext>
  <ng-template (execute)="createCalendarDialog($event.event)" contextMenuItem>Add New Calendar</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem let-item>Settings</ng-template>
</context-menu>

<ng-template #calendarCreateForm>
  <app-calendar-form (close)="handleCalendarPopoverClose()"></app-calendar-form>
</ng-template>
