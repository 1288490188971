// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

// Third-party modules
import { AngularSplitModule } from 'angular-split';

// Modules
import { PagesRoutingModule } from './pages-routing.module';
import { MailModule } from '@modules/mail/mail.module';
import { EventsModule } from '@modules/events/events.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { NotesModule } from '@modules/notes/notes.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { NavBarModule } from '@modules/nav-bar/nav-bar.module';
import { KnowledgePanelModule } from '../knowledge-panel/knowledge-panel.module';
import { AppMenuModule } from '@modules/app-menu/app-menu.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { InsightsModule } from '@modules/insights/insights.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { FilesModule } from '@modules/files/files.module';

// Services
import { LayoutStateService } from './services/layout-state.service';

// Components
import { MailLayoutComponent } from './components/mail-layout/mail-layout.component';
import { EventsLayoutComponent } from './components/events-layout/events-layout.component';
import { TaskingLayoutComponent } from './components/tasking-layout/tasking-layout.component';
import { NotesLayoutComponent } from './components/notes-layout/notes-layout.component';
import { ContactsLayoutComponent } from './components/contacts-layout/contacts-layout.component';
import { LinkedInfoToolbarComponent } from './components/linked-info-toolbar/linked-info-toolbar.component';
import { LinkedInfoDynamicPanelComponent } from './components/dynamic-panel/linked-info-dynamic-panel/linked-info-dynamic-panel.component';
import { AttachmentsDynamicPanelComponent } from './components/dynamic-panel/attachments-dynamic-panel/attachments-dynamic-panel.component';
import { InsightsLayoutComponent } from './components/insights-layout/insights-layout.component';
import { FilesLayoutComponent } from './components/files-layout/files-layout.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AngularSplitModule,
    MailModule,
    EventsModule,
    TasksModule,
    NotesModule,
    ContactsModule,
    FilesModule,
    PagesRoutingModule,
    CommonModule,
    AppCommonModule,
    AppSplitViewModule,
    NavBarModule,
    KnowledgePanelModule,
    AppMenuModule,
    LinkedInfoModule,
    MatIconModule,
    FormControlsModule,
    ElementsModule,
    AttachmentsModule,
    InsightsModule,
  ],
  declarations: [
    MailLayoutComponent,
    EventsLayoutComponent,
    TaskingLayoutComponent,
    LinkedInfoToolbarComponent,
    LinkedInfoDynamicPanelComponent,
    AttachmentsDynamicPanelComponent,
    NotesLayoutComponent,
    InsightsLayoutComponent,
    ContactsLayoutComponent,
    FilesLayoutComponent,
  ],
  exports: [
    LinkedInfoToolbarComponent,
  ],
  providers: [
    LayoutStateService
  ],
})
export class PagesModule { }
