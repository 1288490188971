// Common
import { VirtualScrollStrategy, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// RxJS
import { Observable, Subject, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

// Types
import { DynamicSizeScrollingItem } from '../types/dynamic-size-scrolling-item';

export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {
  // Public
  public scrolledIndexChange: Observable<number>;

  // Private
  private viewport: CdkVirtualScrollViewport;
  private scrolledIndex: Subject<number>;

  private dataSource = new Subject<Observable<DynamicSizeScrollingItem<Object>[]>>();
  private totalSize = 0;
  private clusters: { size: number, count: number }[] = [];

  private buffer = 1760;

  constructor(dataSource: Observable<DynamicSizeScrollingItem<Object>[]>) {
    this.scrolledIndex = new Subject<number>();
    this.scrolledIndexChange = this.scrolledIndex.pipe(distinctUntilChanged());

    this.dataSource
      .pipe(
        map(source => source || of([])),
        switchMap(source => source),
        startWith([])
      )
      .subscribe(items => {
        this.clusters = [];

        let totalSize = 0;

        for (const item of items) {
          if (!this.clusters.length || this.clusters[this.clusters.length - 1].size !== item.size) {
            this.clusters.push({ size: item.size, count: 1 });
          } else {
            this.clusters[this.clusters.length - 1].count++;
          }
          totalSize += item.size;
        }

        if (this.totalSize !== totalSize) {
          this.totalSize = totalSize;
          this.viewport.setTotalContentSize(this.totalSize);
        }

        this.updateRenderedRange();
      });

    this.dataSource.next(dataSource);
  }

  updateDataSource(dataSource: Observable<DynamicSizeScrollingItem<Object>[]>) {
    this.dataSource.next(dataSource);
  }

  attach(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;
    this.viewport.setTotalContentSize(this.totalSize);
    this.updateRenderedRange();
  }

  detach(): void {
    this.viewport = null;
    this.scrolledIndex.complete();
  }

  onContentScrolled(): void {
    this.updateRenderedRange();
  }

  onDataLengthChanged(): void { }

  onContentRendered(): void { }

  onRenderedOffsetChanged(): void { }

  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (this.viewport) {
      let offset = 0;
      let currentIndex = 0;
      for (const cluster of this.clusters) {
        currentIndex += cluster.count;
        offset += cluster.size * cluster.count;
        if (currentIndex > index) {
          offset = offset - (currentIndex - index) * cluster.size;
          break;
        }
      }
      this.viewport.scrollToOffset(offset, behavior);
    }
  }

  private updateRenderedRange() {
    if (!this.viewport) {
      return;
    }

    const viewportSize = this.viewport.getViewportSize();
    const offset = this.viewport.measureScrollOffset();

    const startOffset = Math.max(offset - this.buffer, 0);
    const endOffset = Math.min(offset + viewportSize + this.buffer, this.totalSize);

    const newRenderRange = { start: null, end: null };
    let newRenderContentOffset = 0;
    let newFirstVisibleIndex: number;
    let currentIndex = 0;
    let currentSize = 0;

    for (const cluster of this.clusters) {
      currentIndex += cluster.count;
      currentSize += cluster.size * cluster.count;

      if (currentSize < startOffset) {
        continue;
      }

      if (newRenderRange.start === null && currentSize >= startOffset) {
        newRenderRange.start = currentIndex - Math.floor((currentSize - startOffset) / cluster.size);
        newRenderContentOffset = currentSize - (currentIndex - newRenderRange.start) * cluster.size;
      }

      if (newFirstVisibleIndex === undefined && currentSize >= offset) {
        newFirstVisibleIndex = currentIndex - Math.floor((currentSize - offset) / cluster.size);
      }

      if (newRenderRange.end === null && currentSize >= endOffset) {
        newRenderRange.end = currentIndex - Math.floor((currentSize - endOffset) / cluster.size);
      }

      if (newRenderRange.start !== null && newRenderRange.end !== null && newFirstVisibleIndex !== undefined) {
        break;
      }
    }

    // Set default values if nothing where found in the loop above (i.e. when array is empty)
    newRenderRange.start = newRenderRange.start || 0;
    newRenderRange.end = newRenderRange.end || 0;
    newFirstVisibleIndex = newFirstVisibleIndex || 0;

    this.viewport.setRenderedRange(newRenderRange);
    this.viewport.setRenderedContentOffset(newRenderContentOffset);
    this.scrolledIndex.next(newFirstVisibleIndex);
  }

}
