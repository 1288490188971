import {
  Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter,
  SimpleChanges, OnChanges, OnDestroy, ChangeDetectorRef, TemplateRef, ElementRef, NgZone, AfterViewInit
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, interval, fromEvent, merge, timer } from 'rxjs';
import {
  CalendarEvent as AngularCalendarEvent,
  CalendarDateFormatter,
  DateFormatterParams,
  CalendarWeekViewBeforeRenderEvent
} from 'angular-calendar';
import { isSameDay, startOfDay } from 'date-fns';
import { debounceTime, takeUntil } from 'rxjs/operators';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarType } from '@modules/events/types/calendar-type';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { DayViewHour, DayViewHourSegment, WeekViewHourColumn } from 'calendar-utils';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Animations
import { slideAnimation } from '@modules/events/animations/slide-animation';

// Override week time formatter
export class CalendarWeekDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date).toLowerCase();
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return `${date.getDate()}`;
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'E', locale);
  }
}

@Component({
  selector: 'full-calendar-week',
  templateUrl: './full-calendar-week.component.html',
  styleUrls: ['./full-calendar-week.component.less'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarWeekDateFormatter
    }
  ],
  animations: [slideAnimation]
})

export class FullCalendarWeekComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  // Inputs
  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() events: AngularCalendarEvent[];
  @Input() eventTemplate: TemplateRef<any>;
  @Input() highlightedDay: Date;

  // Outputs
  @Output() dateClicked: EventEmitter<CalendarCellClickEvent> = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dayNumberDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() eventDropped: EventEmitter<CalendarDropEvent> = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  // Public
  public displayDate: Date;
  public excludeDays: number[] = [];
  public refreshCalendar: Subject<any> = new Subject();
  public timeZoneName = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  public currentTimeLineOffset = 0;
  public currentTime: Date = new Date();
  public hourSegmentHeight = 48;
  public weekStartsOn = 0; // Calendar week starts on 0 - Sunday, 1 - Monday

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private stateService: StateService,
  ) {
    this.stateService.getSelectedCalendarType()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarType: CalendarType) => {
        this.excludeDays = calendarType === 'workWeek' ? [0, 6] : [];
        this.changeDetector.markForCheck();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(200),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(() => this.calculateSegmentHeight());
    });

    // Update current time line
    merge(
      interval(15000), // update every 15 sec
      this.refreshCalendar.asObservable()
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.updateCurrentTimeLine();
      });
    this.updateCurrentTimeLine();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate) {
      if (changes.viewDate.firstChange || this.weekDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
        this.displayDate = this.viewDate;
        this.refreshView();
      }
    }
    if (changes.highlightedDay || changes.selectedDate) {
      this.refreshView();
    }
  }

  ngAfterViewInit(): void {
    timer(0).subscribe( () => this.calculateSegmentHeight());
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Helpers
   */

  updateCurrentTimeLine() {
    // Calc offset for current time line
    this.currentTime = new Date();
    const hoursOffset = this.currentTime.getHours() * this.hourSegmentHeight;
    const minutesOffset = (this.currentTime.getMinutes() / 60) * this.hourSegmentHeight;
    this.currentTimeLineOffset = hoursOffset + minutesOffset;

    this.changeDetector.markForCheck();
  }

  isToday(time: Date) {
    return ( startOfDay(time).getTime() === startOfDay(this.currentTime).getTime() );
  }

  /**
   * Action
   */

  beforeRender(data: CalendarWeekViewBeforeRenderEvent) {
    if (this.highlightedDay) {
      data.hourColumns.filter((column: WeekViewHourColumn) => (isSameDay(column.date, this.highlightedDay)))
        .forEach((column: WeekViewHourColumn) =>
          column.hours.forEach((hour: DayViewHour) =>
            hour.segments.forEach((segment: DayViewHourSegment) => segment.cssClass = 'highlighted')
        ));
    }
  }

  refreshView() {
    this.refreshCalendar.next();
  }

  clickHourSegment(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.dateClicked.emit({date, originRef: new ElementRef(origin), event});
  }

  dblClickHourSegment(date: Date) {
    this.dateDblClicked.emit(date);
  }

  handleDrop(dragData: DragData, newStart: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart,
      newEnd: new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate(), newStart.getHours() + 1, newStart.getMinutes()),
      originRef: new ElementRef(origin)
    });
  }

  dblClickDay(date: Date) {
    this.dayNumberDblClicked.emit(date);
  }

  private weekDiffers(date1: Date, date2: Date) {
    const weekStartTimestamp = new Date(
      date1.getFullYear(), date1.getMonth(), date1.getDate() - (7 + date1.getDay() - this.weekStartsOn) % 7, 0, 0, 0, 0
    ).getTime();

    return date2.getTime() < weekStartTimestamp || date2.getTime() >= weekStartTimestamp + 7 * 24 * 60 * 60 * 1000;
  }

  private calculateSegmentHeight() {
    // Set the height of hour segment between 40px and 60px according to available space on the screen
    this.hourSegmentHeight = Math.min(Math.max(40, Math.round((this.element.nativeElement.getBoundingClientRect().height) / 11 )), 60);
    this.changeDetector.markForCheck();
    this.refreshCalendar.next();
  }

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }
}
