import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy, EventEmitter, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormControl } from '@angular/forms';
// Components
import { EventQuickFormComponent } from '../event-quick-form/event-quick-form.component';
import { TaskQuickFormComponent } from '../task-quick-form/task-quick-form.component';
import { ProjectQuickFormComponent } from '../project-quick-form/project-quick-form.component';
import { NoteQuickFormComponent } from '../note-quick-form/note-quick-form.component';
import { ContactQuickFormComponent } from '../contact-quick-form/contact-quick-form.component';
import { GroupQuickFormComponent } from '../group-quick-form/group-quick-form.component';
// RX
import { Subject, EMPTY, timer } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
// entered text should start with followed keywords to open quick form
var keywords = {
    'calendar': 'event',
    'cal': 'event',
    'event': 'event',
    'appointment': 'event',
    'appt': 'event',
    'meeting': 'event',
    'mtg': 'event',
    'reminder': 'event',
    'rem': 'event',
    'call': 'event',
    'telephone': 'event',
    'tel': 'event',
    'conference': 'event',
    'conf': 'event',
    'convention': 'event',
    'interview': 'event',
    'todo': 'task',
    'to-do': 'task',
    'task': 'task',
    'errand': 'task',
    'note': 'note',
    'txt': 'note',
    'record': 'note',
    'scribe': 'note',
    'entry': 'note',
    'log': 'note',
    'project': 'project',
    'contact': 'contact',
    'group': 'group',
};
var collapseMotion = trigger('collapseMotion', [
    transition('collapsed => expanded', [
        style({ height: '56px' }),
        animate(".3s ease-in-out", style({
            height: '{{height}}',
        }))
    ]),
    transition('expanded => collapsed', [
        style({ height: '{{height}}', }),
        animate(".3s ease-in-out", style({
            height: '56px',
        }))
    ])
]);
var QuickFormComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function QuickFormComponent() {
        // Outputs
        this.collapsedChange = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.clickMore = new EventEmitter();
        this.itemDropped = new EventEmitter();
        // Public
        this.titleControl = new FormControl();
        this.expanded = false;
        this.formHeight = '10px';
        // Private
        this.componentNotDestroyed = new Subject();
        this.updatedForm = new Subject();
        this.toggleChanged = new Subject();
    }
    /**
     * Lifecycle
     */
    QuickFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.forms = {
            event: this.eventForm,
            project: this.projectForm,
            task: this.taskForm,
            note: this.noteForm,
            contact: this.contactForm,
            group: this.groupForm,
        };
        this.titleControl.valueChanges
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (value) {
            var expanded = false;
            if (_this.smart) {
                var currentFormSelector = keywords[value.trim().split(' ')[0].toLowerCase()];
                if (currentFormSelector) {
                    expanded = true;
                    _this.currentFormSelector = currentFormSelector;
                    _this.currentForm = _this.forms[currentFormSelector];
                    _this.updatedForm.next();
                }
            }
            else {
                expanded = value.length > 0;
                if (expanded) {
                    _this.initForm();
                }
            }
            if (expanded !== _this.expanded) {
                _this.collapsedChange.emit(_this.expanded);
            }
            if (expanded) {
                _this.calculateFormHeight();
            }
            else {
                _this.currentFormSelector = null;
            }
            _this.expanded = expanded;
            _this.valueChange.emit(value);
        });
        this.updatedForm
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            if (_this.currentForm) {
                _this.currentForm.close
                    .pipe(takeUntil(_this.updatedForm))
                    .subscribe(function () { return _this.handleClose(); });
            }
        });
        this.toggleChanged
            .pipe(switchMap(function () { return _this.toggle || EMPTY; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.expanded) {
                    this.initForm();
                    timer(0).subscribe(function () {
                        _this.calculateFormHeight();
                        _this.expanded = true;
                    });
                }
                else {
                    this.expanded = false;
                }
                return [2 /*return*/];
            });
        }); });
        this.toggleChanged.next();
    };
    QuickFormComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    QuickFormComponent.prototype.ngAfterViewInit = function () {
    };
    QuickFormComponent.prototype.ngOnChanges = function (changes) {
        if ('toggle' in changes) {
            this.toggleChanged.next();
        }
    };
    /**
     * Actions
     */
    QuickFormComponent.prototype.handleClose = function () {
        this.titleControl.setValue('');
        if (this.currentForm) {
            this.currentForm.reset();
        }
    };
    QuickFormComponent.prototype.handleMore = function () {
        this.clickMore.emit();
    };
    QuickFormComponent.prototype.handleDrop = function (dragData) {
        this.itemDropped.emit(dragData);
    };
    QuickFormComponent.prototype.initForm = function () {
        var _a = tslib_1.__read(Object.entries(this.forms).filter(function (_a) {
            var _b = tslib_1.__read(_a, 2), formType = _b[0], formComponent = _b[1];
            return !!formComponent;
        }), 1), _b = tslib_1.__read(_a[0], 2), selector = _b[0], form = _b[1];
        this.currentFormSelector = selector;
        this.currentForm = form;
        this.updatedForm.next();
    };
    QuickFormComponent.prototype.calculateFormHeight = function () {
        this.formHeight = this.formContainer.nativeElement.offsetHeight + 56 + 'px';
    };
    return QuickFormComponent;
}());
export { QuickFormComponent };
