// Common
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { PopoverComponent } from './components/popover/popover.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';

// Services
import { PopoverService } from './services/popover.service';

// Directives
import { PopoverDirective } from './directives/popover.directive';
import { OutsideClickDirective } from './directives/outside-click.directive';

@NgModule({
  entryComponents: [PopoverComponent],
  exports: [
    PopoverDirective,
    PopoverComponent,
    OutsideClickDirective,
  ],
  declarations: [
    PopoverDirective,
    PopoverComponent,
    OutsideClickDirective,
  ],
  imports: [AngularCommonModule, OverlayModule],
  providers: [PopoverService]
})
export class PopoverModule {}
