// Common
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, Optional } from '@angular/core';

// Services
import { TagService } from '@modules/tag/services/tag.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

// Types
import { Tag } from '@modules/tag/types/tag';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';

@Component({
  selector: 'app-content-menu-tag',
  templateUrl: './content-menu-tag.component.html',
  styleUrls: ['./content-menu-tag.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuTagComponent }]
})
export class ContentMenuTagComponent extends ContentMenuBaseItemComponent {
  // Inputs
  @Input() tag: Tag;
  @Input() active: boolean;

  // Outputs
  @Output() selected = new EventEmitter<boolean>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => ['message', 'event', 'task', 'note'].includes(dragData.type);

  /**
   * Constructor
   */

  constructor (
    changeDetector: ChangeDetectorRef,
    private tagService: TagService,
    private modalService: ModalService,
    @Optional() contentMenuService: ContentMenuService
  ) {
    super(changeDetector, contentMenuService);
    this.headerHeight = 24;
  }

  /**
   * Actions
   */

  select(event: MouseEvent) {
    const isMultiSelect = event.ctrlKey || event.shiftKey || event.metaKey;
    this.selected.emit(isMultiSelect);
  }

  unpinTag(tag: Tag) {
    this.tagService.pinTags([tag], false);
  }

  unpinAllTags() {
    this.tagService.unpinAllTags();
  }

  openTagsModal() {
    this.modalService.topicList();
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    this.tagService.createTags(
      [this.tag],
      {
        messagesIds: dragData.type === 'message' && dragData.data ? (dragData.data as MailMessage[]).map(mail => mail.id) : [],
        eventsIds: dragData.type === 'event' && dragData.data ? (dragData.data as CalendarEvent[]).map(event => event.id) : [],
        projectsIds: dragData.type === 'project' && dragData.data ? (dragData.data as Project[]).map(project => project.id) : [],
        tasksIds: dragData.type === 'task' && dragData.data ? (dragData.data as Task[]).map(task => task.id) : [],
        notesIds: dragData.type === 'note' && dragData.data ? (dragData.data as Note[]).map(note => note.id) : []
      }
    );
  }

}
