// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash';

// Types
import { DropdownOption } from '../../types/dropdown-option';

// Pipes
import { IncludesByPipe } from '@modules/pipes/pipes/includes-by.pipe';

@Component({
  selector: 'app-dropdown-multi-select',
  templateUrl: './dropdown-multi-select.component.html',
  styleUrls: ['./dropdown-multi-select.component.less']
})
export class DropdownMultiSelectComponent implements OnInit {

  // Inputs
  @Input() title: string;
  @Input() selected: DropdownOption[];
  @Input() options: DropdownOption[];
  @Input() divider = false;

  // Outputs
  @Output() selectedChange: EventEmitter<DropdownOption[]> = new EventEmitter();

  /**
   * Constructor
   */

  constructor(
    private includesByPipe: IncludesByPipe
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  select(option: DropdownOption) {
    if (!this.selected) {
      this.selected = [option];
    } else if (this.includesByPipe.transform(this.selected, option.key, 'key')) {
      this.selected = this.selected.filter(selectedOption => !isEqual(selectedOption, option));
    } else {
      this.selected = [].concat(this.selected, [option]);
    }
    this.selectedChange.emit(this.selected);
  }
}
