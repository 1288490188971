<div class="container" *ngIf="!minimized">
  <div class="container-header">
    <app-knowledge-header
      title="Files"
      [actions]="scrollOptions"
      [selected]="selectedOrder"
      [loading]="loading"
    ></app-knowledge-header>
  </div>

  <mat-tab-group>
    <mat-tab label="Files">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="filesListTemplate"></ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Folders" disabled>
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="filesListTemplate"></ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Template of files list -->
<ng-template #filesListTemplate>
  <div class="container-quick-form">
    <div class="shadow"></div>

    <app-quick-form
      (collapsedChange)="quickFormExpanded = $event"
      placeholder="Upload New File"
      bundledGroup="filesListQuickForm"
    >
      <app-task-quick-form
        bundledGroup="filesListQuickForm"
      ></app-task-quick-form>
    </app-quick-form>
  </div>

  <div class="shadow"></div>

  <div class="container-list">
    <app-files-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
      (loadInProgress)="loading = $event"
    ></app-files-list>
  </div>
</ng-template>
