import * as tslib_1 from "tslib";
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Types
import { Project } from '@modules/tasks/types/project';
var SidebarProjectsContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SidebarProjectsContextMenuComponent, _super);
    // Constructor
    function SidebarProjectsContextMenuComponent(contextMenuService, tasksStateService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.tasksStateService = tasksStateService;
        return _this;
    }
    // Actions
    SidebarProjectsContextMenuComponent.prototype.createProject = function () {
        var project = new Project();
        this.tasksStateService.openProjectForm(project);
    };
    return SidebarProjectsContextMenuComponent;
}(ContextMenuComponent));
export { SidebarProjectsContextMenuComponent };
