// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { AsyncTask } from '@modules/async-tasks/types/async-task';
import { TagFilters } from '../types/tag-filters';
import { Tag } from '../types/tag';
import { UpdateType } from '@modules/common/types/update-type';

// Env
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  // Private
  private tagsUpdated = new Subject<UpdateType>();

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) { }

  /**
   * Static methods
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Helpers
   */

  private formatFilters(filters: TagFilters): { [param: string]: string | string[]; } {
    const formatedFilters = {};

    if ('pinned' in filters) { formatedFilters['pinned'] = filters.pinned + ''; }
    if ('orderWithPinned' in filters) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }
    if (filters.order) { formatedFilters['order'] = filters.order; }
    if (filters.limit) { formatedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formatedFilters['offset'] = filters.offset + ''; }

    return formatedFilters;
  }

  /**
   * Methods
   */

  getTagsUpdate(): Observable<UpdateType> {
    return this.tagsUpdated.asObservable();
  }

  getTags(filters: TagFilters): Observable<{tags: Tag[], count: number}> {
    return this.http.get<{tags: Tag[], count: number}>(`${environment.baseUrl}/api/tags`, { params: this.formatFilters(filters) })
      .pipe(
        catchError(TagService.handleObserverError)
      );
  }

  @warmUpObservable
  createTags(
    tags: Tag[],
    items: {
      messagesIds?: string[],
      eventsIds?: string[],
      tasksIds?: string[],
      projectsIds?: string[],
      notesIds?: string[],
      contactsIds?: string[],
      groupsIds?: string[],
    } = {}
  ): Observable<boolean> {
    return this.http.post<{ asyncTask: AsyncTask }>(
      `${environment.baseUrl}/api/tags`,
      {
        tags,
        messagesIds: items.messagesIds,
        eventsIds: items.eventsIds,
        tasksIds: items.tasksIds,
        notesIds: items.notesIds,
        projectsIds: items.projectsIds,
        contactsIds: items.contactsIds,
        groupsIds: items.groupsIds,
      }
    )
      .pipe(
        switchMap(({ asyncTask }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: 'Creating new Tag(s).',
                icon: 'tags'
              },
              completed: {
                text: 'New Tag(s) added.',
                icon: 'tags'
              },
              error: {
                text: `Error while creating new Tag(s). Please try again.`,
                icon: 'tags'
              }
            }
          })
        ),
        tap(() => this.tagsUpdated.next(UpdateType.Create)),
        catchError(TagService.handleObserverError)
      );
  }

  @warmUpObservable
  deleteTags(
    tags: Tag[],
    messagesIds?: string[],
    eventsIds?: string[],
    projectsIds?: string[],
    tasksIds?: string[],
    notesIds?: string[],
    contactsIds?: string[],
    groupsIds?: string[]
  ): Observable<boolean> {
    return this.http.request<{ asyncTask: AsyncTask }>(
      'DELETE',
      `${environment.baseUrl}/api/tags`,
      {
        body: {
          tags,
          messagesIds,
          eventsIds,
          tasksIds,
          projectsIds,
          notesIds,
          groupsIds,
          contactsIds
        } }
    )
      .pipe(
        switchMap(({ asyncTask }) =>
          this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
            status: {
              processing: {
                text: `Deleting Tag(s).`,
                icon: 'tags'
              },
              completed: {
                text: 'Tag(s) deleted.',
                icon: 'tags'
              },
              error: {
                text: `Error while deleting Tag(s). Please try again.`,
                icon: 'tags'
              }
            }
          })
        ),
        tap(() => this.tagsUpdated.next(UpdateType.Delete)),
        catchError(TagService.handleObserverError)
      );
  }

  @warmUpObservable
  pinTags(tags: Tag[], pinned: boolean): Observable<boolean> {
    return this.http.put<{success: boolean}>(
      `${environment.baseUrl}/api/tags/pinned`,
      { tags, pinned }
    )
      .pipe(
        tap(({ success }) => {
          this.asyncTasksToastsService.show({
            text: success
              ? `Tag(s) ${pinned ? 'pinned' : 'unpinned'}.`
              : `Error while pinning tag(s). Please try again.`,
            icon: 'tags'
          });
          this.tagsUpdated.next(UpdateType.Pin);
        }),
        map(({ success }) => success),
        catchError(TagService.handleObserverError)
      );
  }

  @warmUpObservable
  unpinAllTags(): Observable<boolean> {
    const filters: TagFilters = {
      pinned: true,
      limit: 0,
      offset: 0
    };
    return this.getTags(filters)
      .pipe(
        tap(response => filters.limit = response.count),
        switchMap(() => this.getTags(filters)),
        map(({tags}) => tags.map(tag => ({name: tag.name}))),
        switchMap(tags => this.pinTags(tags, false)),
        catchError(TagService.handleObserverError)
      );
  }

}
