/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./base-modal.component";
var styles_BaseModalComponent = [i0.styles];
var RenderType_BaseModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaseModalComponent, data: {} });
export { RenderType_BaseModalComponent as RenderType_BaseModalComponent };
export function View_BaseModalComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BaseModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-base-modal", [], null, null, null, View_BaseModalComponent_0, RenderType_BaseModalComponent)), i1.ɵdid(1, 245760, null, 0, i2.BaseModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaseModalComponentNgFactory = i1.ɵccf("app-base-modal", i2.BaseModalComponent, View_BaseModalComponent_Host_0, {}, { closed: "closed", collapsed: "collapsed" }, []);
export { BaseModalComponentNgFactory as BaseModalComponentNgFactory };
