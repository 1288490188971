<div
  *ngIf="contact"
  class="contact"
  [class.selected]="selected"
  [class.pinned]="contact.pinned"
  (dblclick)="handleDoubleClick(contact)"
  [appGroupContextMenu]="contact"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'contact'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="contact-body">
    <div class="contact-body-heading">
      <div class="contact-body-heading-avatar">
        SB
      </div>

      <div class="contact-body-heading-title">
        <div class="contact-body-heading-title-text">
          {{contact.name}}
        </div>
        <div class="contact-body-heading-title-role">
          {{contact.role}}
        </div>
      </div>
    </div>

    <div class="contact-body-info">
      <div class="contact-body-info-item">
        {{contact.email}}
      </div>
      <div class="contact-body-info-separator"></div>
      <div class="contact-body-info-item">
        {{contact.phoneNumber}}
      </div>
    </div>

    <app-link-info-bar *ngIf="contact.linkedInfo?.length" [linkedInfo]="contact.linkedInfo"></app-link-info-bar>
  </div>

  <mat-icon class="contact-pin-icon" *ngIf="contact.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="contact-starred-icon" *ngIf="contact.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="contact-marker" svgIcon="contacts"></mat-icon>
  <mat-icon class="contact-attachments-icon" svgIcon="attachment"></mat-icon>
  <div class="contact-date">{{contact.createdAt | date : 'h:mm a z'}}</div>
  <div class="contact-hover-icons" *ngIf="dragEnabled && actionsButtonEnabled">
    <mat-icon class="contact-hover-icons-item" svgIcon="saved"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="pin"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="crown"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="mail"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="event"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="archive"></mat-icon>
    <mat-icon class="contact-hover-icons-item" svgIcon="trash"></mat-icon>
  </div>
  <!-- Drop zone -->
  <div class="drop-zone"></div>
</div>

<div class="placeholder" *ngIf="!contact">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-contact [contact]="contact" [dragEnabled]="false"></app-contact>
</ng-template>
