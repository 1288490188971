import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
import { MailMessage } from '@modules/mail/types/mail-message';
var NoteContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NoteContextMenuComponent, _super);
    /**
     * Constructor
     */
    function NoteContextMenuComponent(contextMenuService, notesService, modalService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.notesService = notesService;
        _this.modalService = modalService;
        _this.selectedNotes = [];
        // Private
        _this.includesInSelected = false;
        return _this;
    }
    /**
     * Lifecycle
     */
    NoteContextMenuComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    /**
     * Actions
     */
    NoteContextMenuComponent.prototype.favoriteNote = function () {
        this.note.favorite = !this.note.favorite;
        this.notesService.updateNote(this.note);
    };
    NoteContextMenuComponent.prototype.pinNote = function () {
        this.note.pinned = !this.note.pinned;
        this.notesService.updateNote(this.note);
    };
    NoteContextMenuComponent.prototype.snoozeNote = function () {
        // TODO: Add handler
    };
    NoteContextMenuComponent.prototype.createMail = function () {
        this.modalService.compose(MailMessage.fromNote(this.note));
    };
    NoteContextMenuComponent.prototype.followUpNote = function () {
        // TODO: Add handler
    };
    NoteContextMenuComponent.prototype.archiveNote = function () {
        this.note.archived = !this.note.archived;
        this.notesService.archiveNotes({ notesIds: [this.note.id] }, this.note.archived);
    };
    NoteContextMenuComponent.prototype.restoreFromTrash = function () {
        this.note.deleted = false;
        this.notesService.restoreNotes({ notesIds: [this.note.id] });
    };
    NoteContextMenuComponent.prototype.deleteNote = function () {
        var _this = this;
        if (this.note.deleted) {
            this.modalService.confirmationModal('Would you like to delete note(s) from trash?', 'Delete Note(s)', 'Yes', 'No', function (success) {
                if (success) {
                    _this.notesService.deleteNotes({ notesIds: [_this.note.id] });
                }
            });
        }
        else {
            this.notesService.deleteNotes({ notesIds: [this.note.id] });
        }
    };
    NoteContextMenuComponent.prototype.closeContextMenu = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    return NoteContextMenuComponent;
}(ContextMenuComponent));
export { NoteContextMenuComponent };
