/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-list.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../elements/components/tag/tag.component.ngfactory";
import * as i3 from "../../../elements/components/tag/tag.component";
import * as i4 from "../../services/tag.service";
import * as i5 from "../create-tag/create-tag.component.ngfactory";
import * as i6 from "../create-tag/create-tag.component";
import * as i7 from "../../../search/services/search.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../form-controls/components/icon-button/icon-button.component.ngfactory";
import * as i10 from "../../../form-controls/components/icon-button/icon-button.component";
import * as i11 from "../../../popover/directives/popover.directive";
import * as i12 from "../../../popover/services/popover.service";
import * as i13 from "./tag-list.component";
import * as i14 from "../../../modal/services/modal.service";
var styles_TagListComponent = [i0.styles];
var RenderType_TagListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagListComponent, data: {} });
export { RenderType_TagListComponent as RenderType_TagListComponent };
function View_TagListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["# Tags:"]))], null, null); }
function View_TagListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-tag", [["class", "tag-item"]], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.deleteTag(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TagComponent_0, i2.RenderType_TagComponent)), i1.ɵdid(1, 245760, null, 0, i3.TagComponent, [i4.TagService], { tag: [0, "tag"], messages: [1, "messages"], events: [2, "events"], projects: [3, "projects"] }, { delete: "delete" }), i1.ɵpad(2, 1), i1.ɵpad(3, 1), i1.ɵpad(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (_co.message ? _ck(_v, 2, 0, _co.message.id) : i1.ɵEMPTY_ARRAY); var currVal_2 = (_co.event ? _ck(_v, 3, 0, _co.event.id) : i1.ɵEMPTY_ARRAY); var currVal_3 = (_co.project ? _ck(_v, 4, 0, _co.project.id) : i1.ɵEMPTY_ARRAY); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TagListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "placeholder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 8).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+ Add Tags"]))], null, null); }
function View_TagListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-create-tag", [], null, [[null, "saved"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.createdTags($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeCreateTagPopover() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_CreateTagComponent_0, i5.RenderType_CreateTagComponent)), i1.ɵdid(1, 245760, null, 0, i6.CreateTagComponent, [i4.TagService, i7.SearchService], { messages: [0, "messages"], events: [1, "events"], projects: [2, "projects"] }, { saved: "saved", close: "close" }), i1.ɵpad(2, 1), i1.ɵpad(3, 1), i1.ɵpad(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.message ? _ck(_v, 2, 0, _co.message.id) : i1.ɵEMPTY_ARRAY); var currVal_1 = (_co.event ? _ck(_v, 3, 0, _co.event.id) : i1.ɵEMPTY_ARRAY); var currVal_2 = (_co.project ? _ck(_v, 4, 0, _co.project.id) : i1.ɵEMPTY_ARRAY); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TagListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "tags"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagListComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagListComponent_2)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagListComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-icon-button", [["class", "btn-action"], ["icon", "plus-small"], ["popover", ""], ["popoverAllowedOutsideSelectorsClick", ".mat-autocomplete-panel.mat-autocomplete-visible"], ["popoverPlacement", "bottom"], ["popoverTrigger", "click"]], null, null, null, i9.View_IconButtonComponent_0, i9.RenderType_IconButtonComponent)), i1.ɵdid(8, 49152, [["newTagButton", 4]], 0, i10.IconButtonComponent, [], { icon: [0, "icon"] }, null), i1.ɵdid(9, 4866048, null, 0, i11.PopoverDirective, [i1.ElementRef, i12.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverAllowedOutsideSelectorsClick: [3, "popoverAllowedOutsideSelectorsClick"], popoverArrow: [4, "popoverArrow"], popoverShowUntil: [5, "popoverShowUntil"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-icon-button", [["class", "btn-action"], ["icon", "settings-small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showTagsModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_IconButtonComponent_0, i9.RenderType_IconButtonComponent)), i1.ɵdid(11, 49152, null, 0, i10.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i1.ɵand(0, [["createTagPopover", 2]], null, 0, null, View_TagListComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tags == null) ? null : _co.tags.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tags; _ck(_v, 4, 0, currVal_1); var currVal_2 = !((_co.tags == null) ? null : _co.tags.length); _ck(_v, 6, 0, currVal_2); var currVal_3 = "plus-small"; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 12); var currVal_5 = "bottom"; var currVal_6 = "click"; var currVal_7 = ".mat-autocomplete-panel.mat-autocomplete-visible"; var currVal_8 = false; var currVal_9 = _co.hidePopover; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = "settings-small"; _ck(_v, 11, 0, currVal_10); }, null); }
export function View_TagListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-list", [], null, null, null, View_TagListComponent_0, RenderType_TagListComponent)), i1.ɵdid(1, 245760, null, 0, i13.TagListComponent, [i14.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagListComponentNgFactory = i1.ɵccf("app-tag-list", i13.TagListComponent, View_TagListComponent_Host_0, { message: "message", event: "event", project: "project", group: "group", contact: "contact", tags: "tags" }, { tagsChange: "tagsChange" }, []);
export { TagListComponentNgFactory as TagListComponentNgFactory };
