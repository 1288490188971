import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';
var LinkInfoTaskSuggestionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoTaskSuggestionsComponent, _super);
    /**
     * Constructor
     */
    function LinkInfoTaskSuggestionsComponent(modalService, tasksService, linkedInfoService) {
        var _this = _super.call(this, linkedInfoService) || this;
        _this.modalService = modalService;
        _this.tasksService = tasksService;
        _this.linkedInfoService = linkedInfoService;
        return _this;
    }
    /**
     * Methods
     */
    //  Override method LinkInfoItemSuggestions
    LinkInfoTaskSuggestionsComponent.prototype.getSuggestions = function (inputValue) {
        if (inputValue && inputValue.length) {
            return this.tasksService.getTasks({
                keywords: [inputValue],
                order: 'created',
                limit: 10,
                offset: 0
            })
                .pipe(map(function (response) { return response.tasks || []; }));
        }
        return of([]);
    };
    //  Override method LinkInfoItemSuggestions
    LinkInfoTaskSuggestionsComponent.prototype.openCreateModal = function () {
        this.modalService.taskForm(null);
    };
    return LinkInfoTaskSuggestionsComponent;
}(LinkInfoItemSuggestions));
export { LinkInfoTaskSuggestionsComponent };
