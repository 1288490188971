/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-picker.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@iplab/ngx-color-picker/iplab-ngx-color-picker.ngfactory";
import * as i6 from "@iplab/ngx-color-picker";
import * as i7 from "../input/input.component.ngfactory";
import * as i8 from "../input/input.component";
import * as i9 from "../button/button.component.ngfactory";
import * as i10 from "../button/button.component";
import * as i11 from "../../../popover/directives/popover.directive";
import * as i12 from "../../../popover/services/popover.service";
import * as i13 from "@angular/material/tooltip";
import * as i14 from "@angular/cdk/overlay";
import * as i15 from "@angular/cdk/scrolling";
import * as i16 from "@angular/cdk/platform";
import * as i17 from "@angular/cdk/a11y";
import * as i18 from "@angular/cdk/bidi";
import * as i19 from "@angular/platform-browser";
import * as i20 from "./color-picker.component";
var styles_ColorPickerComponent = [i0.styles];
var RenderType_ColorPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPickerComponent, data: {} });
export { RenderType_ColorPickerComponent as RenderType_ColorPickerComponent };
function View_ColorPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "check mat-icon notranslate"], ["role", "img"], ["svgIcon", "check"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "check"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ColorPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "color-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "round-picker"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setColor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _v.context.$implicit); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.inputFormControl && (_co.inputFormControl.value === _v.context.$implicit)); _ck(_v, 6, 0, currVal_1); }, null); }
function View_ColorPickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "color-picker-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preventClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Choose custom color "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Choose a background color for this calendar. The text color will be automatically adjusted. "])), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "color-picker-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "saturation-palette"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "saturation-component", [["class", "saturation-component"]], [[4, "backgroundColor", null]], [[null, "colorChange"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("colorChange" === en)) {
        var pd_2 = ((_co.colorPickerControl.value = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ɵb_0, i5.RenderType_ɵb)), i1.ɵdid(8, 770048, null, 0, i6.ɵb, [i1.Renderer2, i4.DOCUMENT, i1.ElementRef], { hue: [0, "hue"], color: [1, "color"] }, { colorChange: "colorChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "hue-component", [["class", "hue-component"]], null, [[null, "hueChange"], [null, "colorChange"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("hueChange" === en)) {
        var pd_2 = ((_co.colorPickerControl.hue = $event) !== false);
        ad = (pd_2 && ad);
    } if (("colorChange" === en)) {
        var pd_3 = ((_co.colorPickerControl.value = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_ɵe_0, i5.RenderType_ɵe)), i1.ɵdid(10, 704512, null, 0, i6.ɵe, [i1.Renderer2, i4.DOCUMENT, i1.ElementRef], { hue: [0, "hue"], color: [1, "color"] }, { hueChange: "hueChange", colorChange: "colorChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "color-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "sample"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(15, { "background-color": 0 }), (_l()(), i1.ɵted(-1, null, [" A "])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "input-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-input", [["placeholder", "Hex"], ["width", "80px"]], null, null, null, i7.View_InputComponent_0, i7.RenderType_InputComponent)), i1.ɵdid(19, 49152, null, 0, i8.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "form-columns-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "app-button", [["appearance", "blue"], ["class", "form-columns-buttons-item"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(22, 49152, null, 0, i10.ButtonComponent, [i1.ElementRef], { type: [0, "type"], appearance: [1, "appearance"] }, null), (_l()(), i1.ɵted(-1, 0, [" Save "])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "app-button", [["class", "form-columns-buttons-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(25, 49152, null, 0, i10.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.colorPickerControl.hue; var currVal_2 = _co.colorPickerControl.value; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.colorPickerControl.hue; var currVal_4 = _co.colorPickerControl.value; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 15, 0, _co.colorPickerControl.value.toHexString()); _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.hexFormControl; var currVal_7 = "Hex"; var currVal_8 = "80px"; _ck(_v, 19, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = "submit"; var currVal_10 = "blue"; _ck(_v, 22, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).backgroundColor; _ck(_v, 7, 0, currVal_0); }); }
export function View_ColorPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "color-picker-palette"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "color-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 4, "div", [["class", "round-picker round-picker-custom"], ["matTooltip", "Add custom color"], ["matTooltipPosition", "above"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "click"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 4866048, null, 0, i11.PopoverDirective, [i1.ElementRef, i12.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverArrow: [3, "popoverArrow"], popoverShowUntil: [4, "popoverShowUntil"] }, null), i1.ɵdid(6, 212992, null, 0, i13.MatTooltip, [i14.Overlay, i1.ElementRef, i15.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i16.Platform, i17.AriaDescriber, i17.FocusMonitor, i13.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i18.Directionality], [2, i13.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i19.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-icon", [["class", "check plus-small mat-icon notranslate"], ["role", "img"], ["svgIcon", "plus-bold"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(0, [["colorPickerDialog", 2]], null, 0, null, View_ColorPickerComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colors; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9); var currVal_2 = "right"; var currVal_3 = "click"; var currVal_4 = false; var currVal_5 = _co.popoverShowUntil; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "above"; var currVal_7 = "Add custom color"; _ck(_v, 6, 0, currVal_6, currVal_7); var currVal_10 = "plus-bold"; _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var currVal_8 = i1.ɵnov(_v, 8).inline; var currVal_9 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_8, currVal_9); }); }
export function View_ColorPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, View_ColorPickerComponent_0, RenderType_ColorPickerComponent)), i1.ɵdid(1, 245760, null, 0, i20.ColorPickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorPickerComponentNgFactory = i1.ɵccf("app-color-picker", i20.ColorPickerComponent, View_ColorPickerComponent_Host_0, { inputFormControl: "inputFormControl" }, { close: "close" }, []);
export { ColorPickerComponentNgFactory as ColorPickerComponentNgFactory };
