import * as tslib_1 from "tslib";
// RxJS
import { throwError, Subject } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Decorators
import { warmUpObservable } from '@decorators';
import { UpdateType } from '@modules/common/types/update-type';
// Env
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../async-tasks/services/async-tasks-toasts.service";
var TopicService = /** @class */ (function () {
    /**
     * Constructor
     */
    function TopicService(http, asyncTasksToastsService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        // Private
        this.topicsUpdated = new Subject();
    }
    /**
     * Static methods
     */
    TopicService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Methods
     */
    TopicService.prototype.formatFilters = function (filters) {
        var formatedFilters = {};
        if ('autodiscovered' in filters) {
            formatedFilters['autodiscovered'] = filters.autodiscovered + '';
        }
        if ('pinned' in filters) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if ('orderWithPinned' in filters) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.order) {
            formatedFilters['order'] = filters.order;
        }
        if (filters.limit) {
            formatedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formatedFilters['offset'] = filters.offset + '';
        }
        return formatedFilters;
    };
    TopicService.prototype.getTopicsUpdate = function () {
        return this.topicsUpdated.asObservable();
    };
    TopicService.prototype.getTopics = function (filters) {
        return this.http.get(environment.baseUrl + "/api/topics", { params: this.formatFilters(filters) })
            .pipe(catchError(TopicService.handleObserverError));
    };
    TopicService.prototype.createTopics = function (topics, items) {
        var _this = this;
        if (items === void 0) { items = {}; }
        return this.http.post(environment.baseUrl + "/api/topics", {
            topics: topics,
            messagesIds: items.messagesIds,
            eventsIds: items.eventsIds,
            projectsIds: items.projectsIds,
            tasksIds: items.tasksIds,
            notesIds: items.notesIds,
            groupsIds: items.groupsIds,
            contactsIds: items.contactsIds,
            foldersIds: items.foldersIds,
            filesIds: items.filesIds
        })
            .pipe(switchMap(function (_a) {
            var asyncTask = _a.asyncTask;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: 'Creating new Pellet(s).'
                    },
                    completed: {
                        text: 'New Pellet(s) added.'
                    },
                    error: {
                        text: "Error while creating new Pellet(s). Please try again."
                    }
                }
            });
        }), tap(function () { return _this.topicsUpdated.next(UpdateType.Create); }), catchError(TopicService.handleObserverError));
    };
    TopicService.prototype.updateTopics = function (oldTopic, newTopic) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/topics", { oldTopic: oldTopic, newTopic: newTopic })
            .pipe(switchMap(function (_a) {
            var asyncTask = _a.asyncTask;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: "Updating Pellet '" + oldTopic + "' to '" + newTopic + "'."
                    },
                    completed: {
                        text: 'Pellet updated.'
                    },
                    error: {
                        text: "Error while updating Pellet. Please try again."
                    }
                }
            });
        }), tap(function () { return _this.topicsUpdated.next(UpdateType.Update); }), catchError(TopicService.handleObserverError));
    };
    TopicService.prototype.deleteTopics = function (topics, items) {
        var _this = this;
        if (items === void 0) { items = {}; }
        return this.http.request('DELETE', environment.baseUrl + "/api/topics", {
            body: {
                topics: topics,
                messagesIds: items.messagesIds,
                eventsIds: items.eventsIds,
                projectsIds: items.projectsIds,
                tasksIds: items.tasksIds,
                notesIds: items.notesIds,
                groupsIds: items.groupsIds,
                contactsIds: items.contactsIds,
                foldersIds: items.foldersIds,
                filesIds: items.filesIds
            }
        })
            .pipe(switchMap(function (_a) {
            var asyncTask = _a.asyncTask;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: "Deleting Pellet(s)."
                    },
                    completed: {
                        text: 'Pellet(s) deleted.'
                    },
                    error: {
                        text: "Error while deleting Pellet(s). Please try again."
                    }
                }
            });
        }), tap(function () { return _this.topicsUpdated.next(UpdateType.Delete); }), catchError(TopicService.handleObserverError));
    };
    TopicService.prototype.pinTopics = function (topics, pinned) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/topics/pinned", { topics: topics, pinned: pinned })
            .pipe(tap(function (_a) {
            var success = _a.success;
            _this.asyncTasksToastsService.show({
                text: success
                    ? "Pellet(s) " + (pinned ? 'pinned' : 'unpinned') + "."
                    : "Error while pinning Pellet(s). Please try again."
            });
            _this.topicsUpdated.next(UpdateType.Pin);
        }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(TopicService.handleObserverError));
    };
    TopicService.prototype.unpinAllTopics = function () {
        var _this = this;
        var filters = {
            pinned: true,
            limit: 0,
            offset: 0
        };
        return this.getTopics(filters)
            .pipe(tap(function (response) { return filters.limit = response.count; }), switchMap(function () { return _this.getTopics(filters); }), map(function (_a) {
            var topics = _a.topics;
            return topics.map(function (topic) { return ({ name: topic.name }); });
        }), switchMap(function (topics) { return _this.pinTopics(topics, false); }), catchError(TopicService.handleObserverError));
    };
    TopicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TopicService_Factory() { return new TopicService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AsyncTasksToastsService)); }, token: TopicService, providedIn: "root" });
    tslib_1.__decorate([
        warmUpObservable
    ], TopicService.prototype, "createTopics", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TopicService.prototype, "updateTopics", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TopicService.prototype, "deleteTopics", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TopicService.prototype, "pinTopics", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TopicService.prototype, "unpinAllTopics", null);
    return TopicService;
}());
export { TopicService };
