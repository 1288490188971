import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { ProjectContextMenuComponent } from '../components/project-context-menu/project-context-menu.component';
var ProjectContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ProjectContextMenuDirective, _super);
    function ProjectContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = ProjectContextMenuComponent;
        return _this;
    }
    ProjectContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.project = this.appProjectContextMenu;
    };
    return ProjectContextMenuDirective;
}(ContextMenuDirective));
export { ProjectContextMenuDirective };
