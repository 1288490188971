// Common
import { OnInit, ViewChild, OnDestroy, NgZone, Output, EventEmitter } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// RxJS
import { Subject, Observable, of, timer, fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, map, filter, tap, switchMap, takeUntil, retryWhen, startWith } from 'rxjs/operators';

// Types
import { DynamicSizeScrollingItem } from '@modules/virtual-scroll/types/dynamic-size-scrolling-item';

export class VirtualScrollListComponent<T> implements OnInit, OnDestroy {

  // ViewChildren
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  // Public
  public loading = false;
  public loadingError = false;
  public items: DynamicSizeScrollingItem<T>[];
  public itemsStream: Subject<DynamicSizeScrollingItem<T>[]> = new Subject();
  public itemsStreamObservable = this.itemsStream.asObservable().pipe(startWith([]));
  public selectedItems: DynamicSizeScrollingItem<T>[];
  public focused = false;

  // Protected
  protected alive: Subject<void> = new Subject();
  protected currentIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  protected itemType = Object;

  // Private
  private loadItems: Subject<{start: number, end: number, rewrite: boolean}> = new Subject();
  private initialIndex = 0;

  @Output() loadInProgress = new EventEmitter<boolean>();

  /**
   * Constructor
   */

  constructor(
    protected ngZone: NgZone
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    /* Item Loading logic */
    this.loadItems
      .pipe(
        debounceTime(200),
        map(range => {
          if (this.initialIndex) {
            range = {...range, start: this.initialIndex, end: this.initialIndex + 20};
          }
          if (!this.items || range.rewrite) {
            return range;
          }
          const updatedRange = { start: undefined, end: undefined, rewrite: range.rewrite };
          for (let i = range.start; i <= range.end && i < this.items.length; i++) {
            if (!this.items[i].data) {
              updatedRange.start = updatedRange.start !== undefined ? updatedRange.start : i;
              updatedRange.end = i;
            }
          }
          return updatedRange;
        }),
        filter(range => range && range.end > 0 && range.start < range.end),
        tap(() => {
          this.loadInProgress.next(true);
          this.loadingError = false;
        }),
        switchMap(range =>
          this.getItems(range.start, range.end - range.start + 1)
            .pipe(
              map(response => ({ range, response }))
            )
        ),
        takeUntil(this.alive),
        map(({ range, response }) => {
          this.loadInProgress.next(false);
          if (!this.items) {
            this.items = new Array(response.count || 0).fill(this.itemFactory(null));
          } else if (range.rewrite) {
            // Compare if new messages are not the same messages in selected range
            if (
              !response.items.length ||
              this.items.length !== response.count ||
              response.items.some(
                (item, index) =>
                  !this.items[range.start + index] ||
                  !this.items[range.start + index].data ||
                  !this.compareItems(item, this.items[range.start + index])
              )
            ) {
              this.items = new Array(response.count || 0).fill(this.itemFactory(null));
            }
          } else if (this.items.length !== response.count) {
            this.refreshCurrentItems();
          }

          this.items.splice(range.start, response.items.length, ...response.items);

          if (this.initialIndex) {
            timer(0).subscribe(() => {
              this.scrollToIndex(this.initialIndex);
              this.initialIndex = 0;
            });
          }
          return this.items;
        }),
        retryWhen(errors =>
          errors.pipe(
            tap(() => {
              this.loadInProgress.next(false);
              this.loadingError = true;
            })
          )
        )
      )
      .subscribe(items => this.itemsStream.next(items));

    this.viewport.renderedRangeStream
      .pipe(
        filter(range => range && range.end > 0 && range.start <= range.end),
        takeUntil(this.alive),
      )
      .subscribe(range => {
        this.loadItems.next({start: range.start, end: range.end, rewrite: false});
      });

    // Add subs for event required for keyboard navigation. Defined outside of angular for performance reasons
    this.ngZone.runOutsideAngular(() => {
      this.viewport.scrolledIndexChange
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe(index => this.currentIndex.next(index));

      fromEvent(window.document, 'click')
        .pipe(
          filter(() => !!(this.viewport && this.viewport.elementRef)),
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) =>
          this.focused =
            this.viewport.elementRef.nativeElement.contains(event.target as Node) ||
            event['path'].includes(this.viewport.elementRef.nativeElement)
        );

      fromEvent(window.document, 'keydown')
        .pipe(
          filter((event: KeyboardEvent) => this.focused && !(
            event.target instanceof Element &&
            event.target.tagName.toLowerCase() === 'input'
          )),
          tap((event: KeyboardEvent) => {
            event.preventDefault();
            event.stopPropagation();
          }),
          takeUntil(this.alive)
        )
        .subscribe((event: KeyboardEvent) => this.keydown(event));
    });

    this.loadInProgress.asObservable()
      .pipe(takeUntil(this.alive))
      .subscribe((value: boolean) => this.loading = value);

    this.resetItems();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  resetItems() {
    this.items = null;
    this.itemsStream.next([]); // Viewport accepts only arrays, so can't send null
    this.selectedItems = [];
    this.loadItems.next({start: 0, end: 20, rewrite: true});
  }

  refreshCurrentItems() {
    const renderedRange = this.viewport.getRenderedRange();
    if (renderedRange && !renderedRange.start && !renderedRange.end) {
      renderedRange.end = 20;
    }
    this.loadItems.next({...renderedRange, rewrite: true});
  }

  scrollToIndex(index: number, behavior?: ScrollBehavior) {
    if (!this.items) {
      this.initialIndex = index;
    } else {
      this.viewport.scrollToIndex(index, behavior);
    }
  }

  // This method have to be overload, to get appropriate items
  getItems(offset: number, limit: number): Observable<{ items: DynamicSizeScrollingItem<T>[], count: number }> {
    return of({ items: [], count: 0});
  }

  // Optionally overload this method for items comparison. For example if id field named differently
  compareItems(item1: DynamicSizeScrollingItem<T>, item2: DynamicSizeScrollingItem<T>): boolean {
    return item1 && item2 && item1.data && item2.data && item1.data['id'] === item2.data['id'];
  }

  // This method have to be overloaded in order to get proper sizes calculations
  itemFactory(data: T): DynamicSizeScrollingItem<T> {
    throw new Error('itemFactory not implemented');
  }

  selectItem(item: DynamicSizeScrollingItem<T>, event: MouseEvent|KeyboardEvent, selectAll = false) {
    if (!item || !item.data) {
      return;
    }

    const multi = event.ctrlKey || event.shiftKey || event.metaKey;
    const range = event.shiftKey || selectAll;

    if (multi && this.selectedItems.length === 1 && this.compareItems(this.selectedItems[0], item)) {
      return;
    }

    if (!multi || this.selectedItems.length === 0) {
      this.selectedItems = [item];
    } else if (!range) {
      const index = this.selectedItems.findIndex(currentItem => this.compareItems(currentItem, item));
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    } else {
      const lastSelectedIndex = this.items
        .findIndex(currentItem => this.compareItems(currentItem, this.selectedItems[this.selectedItems.length - 1]));
      const currentSelectedIndex = this.items.findIndex(currentItem => this.compareItems(currentItem, item));
      const startSelection = Math.min(lastSelectedIndex, currentSelectedIndex);
      const endSelection = Math.max(lastSelectedIndex, currentSelectedIndex);
      for (let i = startSelection; i <= endSelection; i++) {
        const indexInSelection = this.selectedItems.findIndex(currentItem => this.compareItems(currentItem, this.items[i]));
        if (indexInSelection !== -1) {
          this.selectedItems.splice(indexInSelection, 1);
        }
        this.selectedItems.push(this.items[i]);
      }
    }
  }

  private keydown(event: KeyboardEvent) {
    const selectAllPressed = event.code === 'KeyA' && (event.ctrlKey || event.metaKey);

    if (
      !this.items ||
      !this.items.length ||
      (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && !selectAllPressed)
    ) {
      return;
    }

    let selectAll = false;
    let nextSelectedPosition = 0;

    if (this.selectedItems.length === 0) {
      nextSelectedPosition = 0;
    } else if (event.key === 'ArrowUp') {
      const firstSelectedPosition = this.items.findIndex(item => this.compareItems(item, this.selectedItems[0]));
      nextSelectedPosition = Math.max(firstSelectedPosition - 1, 0);
    } else if (event.key === 'ArrowDown') {
      const lastSelectedPosition = this.items
        .findIndex(item => this.compareItems(item, this.selectedItems[this.selectedItems.length - 1]));
      nextSelectedPosition = Math.min(lastSelectedPosition + 1, this.items.length - 1);
    } else if (selectAllPressed) {
      selectAll = true;
      this.selectedItems = [this.items[0]];
      nextSelectedPosition = this.items.length - 1;
    }

    this.selectItem(this.items[nextSelectedPosition], event, selectAll);
    this.scrollToSelected(nextSelectedPosition);
  }

  private scrollToSelected(selectedIndex: number) {
    if (selectedIndex < this.currentIndex.value) {
      this.viewport.scrollToIndex(selectedIndex);
      return;
    }

    const viewportBottom = this.viewport.measureScrollOffset() + this.viewport.getViewportSize();
    let offset = 0;

    for (let i = 0; i <= selectedIndex; i++) {
      offset += this.items[i].size;
    }

    if (offset > viewportBottom) {
      this.viewport.scrollToOffset(offset - this.viewport.getViewportSize());
    }
  }
}
