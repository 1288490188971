// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Calendar {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  name?: string;
  description?: string;
  readOnly?: boolean;
  color?: string;
  timeZone?: string;

  constructor(calendarObject: object = {}) {
    this.id = calendarObject['id'];
    this.name = calendarObject['name'];
    this.description = calendarObject['description'];
    this.readOnly = calendarObject['readOnly'];
    this.color = calendarObject['color'];
    this.timeZone = calendarObject['timeZone'];
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      name: [this.name],
      description: [this.description],
      color: [this.color],
      timeZone: [this.timeZone],
    });
  }
}
