import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/services/auth-guard.service';

// Components
import { MailLayoutComponent } from './components/mail-layout/mail-layout.component';
import { EventsLayoutComponent } from './components/events-layout/events-layout.component';
import { TaskingLayoutComponent } from './components/tasking-layout/tasking-layout.component';
import { NotesLayoutComponent } from './components/notes-layout/notes-layout.component';
import { InsightsLayoutComponent } from './components/insights-layout/insights-layout.component';
import { ContactsLayoutComponent } from './components/contacts-layout/contacts-layout.component';
import { FilesLayoutComponent } from './components/files-layout/files-layout.component';

const pagesRoutes: Routes = [
  { path: 'mail/:folder/:type/:query', canActivate: [AuthGuard], component: MailLayoutComponent },
  { path: 'mail/:folder', canActivate: [AuthGuard], component: MailLayoutComponent },
  { path: 'mail', canActivate: [AuthGuard], redirectTo: '/mail/inbox', pathMatch: 'full' },
  { path: 'insights/:folder', canActivate: [AuthGuard], component: InsightsLayoutComponent },
  { path: 'insights', canActivate: [AuthGuard], redirectTo: '/insights/inbox', pathMatch: 'full' },
  { path: 'events', canActivate: [AuthGuard], component: EventsLayoutComponent },
  { path: 'tasks', canActivate: [AuthGuard], component: TaskingLayoutComponent },
  { path: 'notes', canActivate: [AuthGuard], component: NotesLayoutComponent },
  { path: 'contacts', canActivate: [AuthGuard], component: ContactsLayoutComponent },
  { path: 'files', canActivate: [AuthGuard], component: FilesLayoutComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(pagesRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PagesRoutingModule { }
