// Common
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';

// Types
import { Note, NoteBody } from '@modules/notes/types/note';
import { MailMessage } from '@modules/mail/types/mail-message';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';

@Component({
  selector: 'app-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.less'],
})
export class NoteFormComponent implements OnInit, OnChanges {

  // Inputs
  @Input() note = new Note();
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public title = new FormControl();
  public body: NoteBody[] = [];
  public dynamicPanel: DynamicPanelType;

  // Private
  private updateNote = new Subject();
  private componentNotDestroyed = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'note' && dragData.data.length === 1 && dragData.data[0]['id'] === this.note.id) &&
    ['message', 'event', 'project', 'task', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    private notesService: NotesService,
    private linkedInfoService: LinkedInfoService,
    private modalService: ModalService,
    private dynamicPanelService: DynamicPanelService,
    private topicService: TopicService,
    private tagService: TagService,
  ) {
    this.reset();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.subscribeUpdateNote();
    this.subscribeDynamicPanel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('note' in changes && this.note) {
      this.reset();
    }
  }

  /**
   * Methods
   */

  subscribeUpdateNote() {
    this.updateNote
      .pipe(
        filter(() => !!this.note.id),
        debounceTime(300),
        takeUntil(this.componentNotDestroyed)
      ).subscribe(() => {
        this.notesService.updateNote(this.note);
      });
  }

  subscribeDynamicPanel() {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  /**
   * Actions
   */

  handleSave() {
    if (this.note.id) {
      this.notesService.updateNote(this.note)
        .subscribe(() => {
          this.close.emit();
        });
    } else {
      this.notesService.createNote(this.note)
        .subscribe(() => {
          this.close.emit();
        });
    }
  }

  handleCancel() {
    this.close.emit();
  }

  favoriteNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.favorite = !this.note.favorite;
    this.notesService.updateNote(this.note);
  }

  pinNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.pinned = !this.note.pinned;
    this.notesService.updateNote(this.note);
  }

  snoozeNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: Add handler
  }

  createMail(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.compose(MailMessage.fromNote(this.note));
  }

  followUpNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: Add handler
  }

  archiveNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.archived = !this.note.archived;
    this.notesService.archiveNotes({notesIds: [this.note.id]}, this.note.archived);
    this.close.emit();
  }

  deleteNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.note.deleted) {
      this.modalService.confirmationModal(
        'Would you like to delete note(s) from trash?',
        'Delete Note(s)',
        'Yes',
        'No',
        (success: boolean) => {
          if (success) {
            this.notesService.deleteNotes({notesIds: [this.note.id]});
            this.close.emit();
          }
        });
    } else {
      this.notesService.deleteNotes({notesIds: [this.note.id]});
      this.close.emit();
    }
  }

  openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else if (this.note.files && this.note.files.length) {
      this.dynamicPanelService.setContent('attachments', { attachments: this.note.files, parentTitle: this.note.title });
    }
  }

  openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else if (this.note.linkedInfo.length) {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.note.linkedInfo,
        parentLinkedInfo: { type: 'note', id: this.note.id },
        parentTitle: this.note.title
      });
    }
  }

  bodyChanged(body: NoteBody[]) {
    this.body = body;
    this.note.body = body;
    this.updateNote.next();
  }

  /**
   * Helpers
   */

  reset() {
    this.title = new FormControl(this.note.title);
    this.title.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.componentNotDestroyed)
      ).subscribe((value: string) => {
        this.note.title = value;
        this.updateNote.next();
      });
    this.body = this.note.body;
  }

    /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    if (!dragData.data) {
      return;
    }
    // Message | Event | Project | Task | Note
    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('note', this.note.id), linkedItems);
    }
    // Topic
    if (dragData.type === 'topic') {
      const topic = dragData.data[0] as string;
      this.topicService.createTopics([{name: topic}], {notesIds: [this.note.id]});
    }
    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {notesIds: [this.note.id]});
      this.note.tags = [...this.note.tags, ...tags];
    }
  }

}
