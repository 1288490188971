import * as tslib_1 from "tslib";
import { EventEmitter, SimpleChanges, OnChanges, TemplateRef, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isSameDay } from 'date-fns';
// RX
import { Subject } from 'rxjs';
// Types
import { CalendarDateFormatter } from 'angular-calendar';
// Override month header week names formatter
var CalendarMonthDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarMonthDateFormatter, _super);
    function CalendarMonthDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CalendarMonthDateFormatter.prototype.monthViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new DatePipe(locale).transform(date, 'E', locale);
    };
    return CalendarMonthDateFormatter;
}(CalendarDateFormatter));
export { CalendarMonthDateFormatter };
var FullCalendarMonthComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FullCalendarMonthComponent() {
        // Outputs
        this.dateClicked = new EventEmitter();
        this.dateDblClicked = new EventEmitter();
        this.dayNumberDblClicked = new EventEmitter();
        this.eventDropped = new EventEmitter();
        this.loadDayEvents = new EventEmitter();
        this.refreshCalendar = new Subject();
    }
    /**
     * Component lifecycle
     */
    FullCalendarMonthComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewDate) {
            if (changes.viewDate.firstChange || this.monthDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
                this.displayDate = this.viewDate;
            }
        }
        if (changes.highlightedDay || changes.selectedDate) {
            this.refreshCalendar.next();
        }
    };
    /**
     *  Angular Calendar
     */
    FullCalendarMonthComponent.prototype.beforeMonthViewRender = function (_a) {
        var _this = this;
        var body = _a.body;
        body.forEach(function (day) {
            if (_this.highlightedDay && isSameDay(day.date, _this.highlightedDay)) {
                day.cssClass += ' cal-day-cell-highlighted';
            }
            if (_this.selectedDate && isSameDay(_this.selectedDate, day.date)) {
                day.cssClass += ' cal-day-cell-selected';
            }
        });
    };
    FullCalendarMonthComponent.prototype.selectCalendarDate = function (event, date, origin) {
        this.dateClicked.emit({ date: date, originRef: new ElementRef(origin), event: event });
    };
    FullCalendarMonthComponent.prototype.dblClickCalendarDate = function (date) {
        this.dateDblClicked.emit(date);
    };
    FullCalendarMonthComponent.prototype.dblClickDay = function (date) {
        this.dayNumberDblClicked.emit(date);
    };
    FullCalendarMonthComponent.prototype.handleDrop = function (dragData, day, origin) {
        this.eventDropped.emit({
            dragData: dragData,
            newStart: this.beginningOfDay(day),
            newEnd: this.endOfDay(day),
            originRef: new ElementRef(origin)
        });
    };
    /**
     * Helpers
     */
    FullCalendarMonthComponent.prototype.beginningOfDay = function (date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    };
    FullCalendarMonthComponent.prototype.endOfDay = function (date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    };
    FullCalendarMonthComponent.prototype.monthDiffers = function (date1, date2) {
        return date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
    };
    /**
     * Actions
     */
    FullCalendarMonthComponent.prototype.handleLoadDayEvents = function (date) {
        this.loadDayEvents.emit(date);
    };
    return FullCalendarMonthComponent;
}());
export { FullCalendarMonthComponent };
