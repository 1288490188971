<div class="dropdown-header" *ngIf="title">{{ title }}</div>
<div class="colors">
  <div
    class="color-item"
    *ngFor="let option of options"
    [ngClass]="{'selected': option === rgb2hex(selected)}"
    (click)="select(option)"
  >
    <div class="color" [style.background-color]="option"></div>
  </div>
</div>
<div *ngIf="removeColor?.observers?.length" class="no-color" (click)="removedColor()">
  <mat-icon svgIcon="eraser" class="icon"></mat-icon>None
</div>
<div class="divider" *ngIf="divider"></div>
