// Common
import { Injectable} from '@angular/core';

// Rx
import { Observable, combineLatest, merge } from 'rxjs';
import { map, switchMap, startWith, tap } from 'rxjs/operators';

// Types
import { Task } from '../types/task';
import { ColumnsTree } from '../types/columns-tree';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { SectionsService } from '@modules/tasks/services/sections.service';
import { TasksStateService } from './tasks-state.service';
import { Column } from '../types/column';


@Injectable()
export class ColumnsTreeService {

  /**
   * Constructor
   */

  constructor (
    private sectionsService: SectionsService,
    private tasksStateService: TasksStateService,
    private tasksService: TasksService,
    private columnsService: ColumnsService
  ) {

  }

  /**
   * Methods
   */

  getColumns(projectId: string): Observable<ColumnsTree> {
    return merge (
      this.sectionsService.sectionCreated,
      this.sectionsService.sectionUpdated,
      this.sectionsService.sectionDeleted,
      this.columnsService.columnCreated,
      this.columnsService.columnUpdated,
      this.columnsService.columnDeleted,
      this.tasksService.taskCreated,
      this.tasksService.taskUpdated,
      this.tasksService.taskDeleted,
      this.tasksStateService.getRefreshAll()
    )
      .pipe(
        startWith(null as Task),
        switchMap(() => (
          combineLatest([
            this.columnsService.getColumns({ projectsIds: [projectId] }),
            this.sectionsService.getSections({ projectsIds: [projectId] }),
            this.tasksService.getTasks({ projectsIds: [projectId] })
          ])
        )),
        map(([ { columns }, { sections }, { tasks } ]) => (
          new ColumnsTree({
            projectId,
            columns,
            sections,
            tasks
          })
        ))
      );
  }

  saveColumnsOrder(tree: ColumnsTree, newColumnId?: string) {
    const filteredColumns = tree.columns.getValue()
      .map(column => column.temp ? newColumnId : column.id)
      .filter(id => !!id);

    if (filteredColumns.length === 0) {
      return;
    }

    return this.columnsService.reorder(filteredColumns);
  }

  submitColumn(tree: ColumnsTree, columnInstance: Column): Observable<Column> {
    return this.columnsService.create(columnInstance)
      .pipe(
        tap((column: Column) => {
          this.saveColumnsOrder(tree, column.id);
        })
      );
  }

  moveColumn (
    tree: ColumnsTree,
    column: Column,
    index: number
  ) {
    tree.moveColumn(column, index);
    this.saveColumnsOrder(tree);
  }
}
