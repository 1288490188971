// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Calendar } from '@modules/events/types/calendar';


@Component({
  selector: 'app-calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.less'],
})
export class CalendarFormComponent implements OnInit, OnChanges {

  // Inputs
  @Input() calendar: Calendar;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public calendarForm: FormGroup;

  /**
   * Constructor
   */

  constructor() {
    this.calendar = new Calendar();
    this.initCalendarForm();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.initCalendarForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('calendar' in changes) {
      this.initCalendarForm();
    }
  }

  /**
   * Actions
   */

  initCalendarForm() {
    if (!this.calendar) {
      return;
    }

    this.calendarForm = this.calendar.asFormGroup();
  }

  handleSubmit() {
    if (this.calendarForm.get('id').value) {
      // TODO waiting for API - will be implemented in next PR
    } else {
      // TODO waiting for API - will be implemented in next PR
    }
    this.close.emit();
  }

  handleCancel() {
    this.close.emit();
  }
}
