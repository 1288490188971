import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef } from '@angular/core';
// RxJS
import { zip } from 'rxjs';
import { BaseModalComponent } from '../base-modal/base-modal.component';
var TopicsAnnotationsModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TopicsAnnotationsModalComponent, _super);
    /**
     * Constructor
     */
    function TopicsAnnotationsModalComponent(mailService, popupService, topicService, element) {
        var _this = _super.call(this) || this;
        _this.mailService = mailService;
        _this.popupService = popupService;
        _this.topicService = topicService;
        _this.element = element;
        // Inputs
        _this.availableExpand = true;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 300px)',
            y: '40px',
            width: '600px',
            height: 'calc(100% - 100px)'
        };
        // Public
        _this.annotations = [];
        _this.shareText = true;
        _this.autoAdd = false;
        _this.newTopics = [];
        _this.annotationsStatus = {};
        _this.initialized = false;
        return _this;
    }
    Object.defineProperty(TopicsAnnotationsModalComponent.prototype, "message", {
        get: function () {
            return this.messageValue;
        },
        set: function (message) {
            this.messageValue = message;
            if (this.initialized) {
                this.getAnnotations();
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Component lifecycle
     */
    TopicsAnnotationsModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.message) {
            this.getAnnotations();
        }
        this.initialized = true;
    };
    /**
     * Actions
     */
    TopicsAnnotationsModalComponent.prototype.getAnnotations = function () {
        var _this = this;
        this.status = 'loadingAnnotations';
        this.annotations = [];
        this.mailService.getTopicsAnnotations(this.message.id)
            .subscribe(function (result) {
            _this.status = null;
            _this.shareText = result.shareText;
            _this.annotations = result.annotations;
        });
    };
    TopicsAnnotationsModalComponent.prototype.addTopic = function () {
        if (this.newTopic) {
            this.newTopics.push(this.newTopic);
            this.newTopic = '';
        }
    };
    TopicsAnnotationsModalComponent.prototype.removeTopic = function (topic) {
        this.newTopics = this.newTopics.filter(function (listTopic) { return listTopic !== topic; });
    };
    TopicsAnnotationsModalComponent.prototype.deleteTopic = function (topic) {
        var _this = this;
        this.annotationsStatus[topic.name] = 'loading';
        this.topicService
            .deleteTopics([topic], { messagesIds: [this.message.id] })
            .subscribe(function (success) {
            _this.annotationsStatus[topic.name] = success ? 'deleted' : 'error';
        }, function () {
            _this.annotationsStatus[topic.name] = 'error';
        });
        return false;
    };
    TopicsAnnotationsModalComponent.prototype.save = function () {
        var _this = this;
        this.status = 'saving';
        this.statusError = '';
        this.addTopic();
        zip(this.mailService.saveTopicsAnnotations(this.message.id, this.annotations
            .filter(function (annotation) { return !!annotation.rating; })
            .concat(this.newTopics.map(function (topic) {
            return { name: topic, rating: 'good' };
        })), this.shareText), this.topicService.createTopics(this.newTopics.map(function (topic) {
            return { name: topic };
        }), { messagesIds: [this.message.id] })).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), isSavedTopicsAnnotations = _b[0], isCreatedTopics = _b[1];
            if (isSavedTopicsAnnotations && isCreatedTopics) {
                _this.close();
            }
            else {
                _this.status = (isSavedTopicsAnnotations && isCreatedTopics) ? 'saved' : 'error';
            }
        }, function () {
            _this.status = 'error';
            _this.statusError = 'Unexpected error while saving data. Please try again.';
        });
    };
    TopicsAnnotationsModalComponent.prototype.reprocess = function () {
        var _this = this;
        this.status = 'loadingAnnotations';
        this.mailService.getTopics(this.message, true)
            .subscribe(function () { return _this.getAnnotations(); }, function () {
            _this.status = 'error';
            _this.statusError = 'Unexpected error while re-processing message. Please try again.';
        });
    };
    TopicsAnnotationsModalComponent.prototype.getModalContent = function () {
        var modalContent = this.element.nativeElement.parentElement;
        return modalContent.parentElement;
    };
    TopicsAnnotationsModalComponent.prototype.expandPopup = function () {
        var modalContent = this.getModalContent();
        this.popupService.annotations(this.messageValue.id, {
            width: modalContent.offsetWidth,
            height: modalContent.offsetHeight,
        });
        this.close();
    };
    return TopicsAnnotationsModalComponent;
}(BaseModalComponent));
export { TopicsAnnotationsModalComponent };
