// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable, Subject, BehaviorSubject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { Calendar } from '../types/calendar';
import { CalendarEvent } from '../types/calendar-event';
import { EventFilters } from '@modules/events/types/event-filters';
import { EventsListResponse } from '@modules/events/types/events-list-response';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { TopicService } from '@modules/topic/services/topic.service';

@Injectable()
export class CalendarService {

  public createNewEvent = new Subject<CalendarEvent>();
  public updatedEvent = new Subject<CalendarEvent>();
  public deletedEvent = new Subject<string>();
  private reloadCalendarsList = new Subject<void>();
  private calendarsList = new BehaviorSubject<Calendar[]>([]);

  /**
   * Settings
   */

  public monthViewState = new BehaviorSubject<'month' | 'week'>(CalendarService.getMonthViewState());

  static getMonthViewState(): 'month' | 'week' {
    return (localStorage.getItem('calendar.month.state') || 'month') as 'month' | 'week';
  }

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  setMonthViewState(state: 'month' | 'week') {
    localStorage.setItem('calendar.month.state', state);
    this.monthViewState.next(state);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private linkedInfoService: LinkedInfoService,
    private topicService: TopicService
  ) {
    this.reloadCalendarsList
      .pipe(
        switchMap(() => this.getCalendars())
      )
      .subscribe((calendars: Calendar[]) => {
        this.calendarsList.next(calendars);
      });
    this.reloadCalendarsList.next();
  }

  /**
   * Calendars
   */

  getCalendarsList(): Observable<Calendar[]> {
    return this.calendarsList.asObservable();
  }

  @warmUpObservable
  private getCalendars(): Observable<Calendar[]> {
    return this.http.get<{ calendars: Calendar[] }>(environment.baseUrl + '/api/calendars')
      .pipe(
        map(({ calendars }) => calendars.map(calendar => new Calendar(calendar))),
        catchError(CalendarService.handleObserverError)
      );
  }

  @warmUpObservable
  editCalendar(calendar: Calendar): Observable<boolean> {
    return this.http.put<{ calendar: Calendar, success: boolean }>(
      environment.baseUrl + '/api/calendars/' + calendar.id, { color: calendar.color }
    )
      .pipe(
        map(({ success }) => success),
        tap(() => this.reloadCalendarsList.next())
      );
  }

  /**
   * Events
   */

  getEvents(filters: EventFilters = {}): Observable<EventsListResponse> {
    const params = {};
    if (filters.eventsIds) { params['events_ids[]'] = filters.eventsIds; }
    if (filters.fromDate) { params['from_time'] = filters.fromDate.getTime().toString(); }
    if (filters.toDate) { params['to_time'] = filters.toDate.getTime().toString(); }
    if (filters.offset) { params['offset'] = filters.offset.toString(); }
    if (filters.limit !== null && filters.limit !== undefined) { params['limit'] = filters.limit.toString(); }
    if (filters.order) { params['order'] = filters.order === 'date' ? 'date-asc' : filters.order; }
    if (filters.orderWithPinned) { params['order_with_pinned'] = true; }
    if (filters.archived !== null && filters.archived !== undefined) { params['archived'] = filters.archived.toString(); }
    if (filters.deleted !== null && filters.deleted !== undefined) { params['deleted'] = filters.deleted.toString(); }
    if (filters.calendarIds) { params['calendars[]'] = filters.calendarIds; }
    if (filters.topics) { params['topics[]'] = filters.topics; }
    if (filters.tags) { params['tags[]'] = filters.tags; }
    if (filters.contacts) { params['contacts[]'] = filters.contacts; }
    if (filters.keywords) { params['keywords[]'] = filters.keywords; }

    return this.http.get<EventsListResponse>(environment.baseUrl + '/api/calendars/events', {params: params})
      .pipe(
        map(({ count, events }) => ({ count, events: events.map(event => new CalendarEvent(event)) })),
        catchError(CalendarService.handleObserverError)
      );
  }

  getEvent(id: string): Observable<CalendarEvent> {
    return this.http.get<{ event: CalendarEvent }>(environment.baseUrl + '/api/calendars/events/' + id)
      .pipe(
        map(({ event }) => new CalendarEvent(event))
      );
  }

  @warmUpObservable
  createEvent(newEvent: CalendarEvent): Observable<CalendarEvent> {
    return this.http.post<{ event: CalendarEvent }>(environment.baseUrl + '/api/calendars/events', newEvent.asPayloadJson())
      .pipe(
        map(({ event }) => event),
        tap(event => {
          if (newEvent.linkedInfo && newEvent.linkedInfo.length) {
            this.linkedInfoService.linkToItem({type: 'event', id: event.id}, newEvent.linkedInfo);
          }
        }),
        tap(event => this.createNewEvent.next(event)),
        switchMap(event => {
          if (newEvent.topics.length === 0) { return of(event); }

          return this.topicService.createTopics(
            newEvent.topics, {eventsIds: [event.id]}
          )
            .pipe(
              map(() => event)
            );
        })
      );
  }

  @warmUpObservable
  editEvent(calendarEvent: CalendarEvent): Observable<CalendarEvent> {
    return this.http.put<{ event: CalendarEvent, success: boolean }>
    (environment.baseUrl + '/api/calendars/events/' + calendarEvent.id, calendarEvent.asPayloadJson())
      .pipe(
        tap(({ event }) => this.updatedEvent.next(event)),
        map(({ event }) => event)
      );
  }

  @warmUpObservable
  deletePermanentlyEvent(eventIds: string[]): Observable<boolean> {
    return forkJoin(
      eventIds.map(eventId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/calendars/events/' + eventId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.deletedEvent.next();
            this.asyncTasksToastsService.show({ text: `Event(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  deleteEvent(eventIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      eventIds.map(eventId => this.http.put<{ event: CalendarEvent, success: boolean }>
        (environment.baseUrl + '/api/calendars/events/' + eventId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { event: CalendarEvent, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.updatedEvent.next();
            this.asyncTasksToastsService.show({ text: `Event(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }

  @warmUpObservable
  archiveEvents(eventIds: string[], archived: boolean): Observable<CalendarEvent[]> {
    return forkJoin(
      eventIds.map(eventId => this.http.put<{ event: CalendarEvent, success: boolean }>
        (environment.baseUrl + '/api/calendars/events/' + eventId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { event: CalendarEvent, success: boolean }[]) => results.map(result => result.event)),
        tap(success => {
          if (success) {
            this.updatedEvent.next();
            this.asyncTasksToastsService.show({ text: `Event(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  pinEvent(eventIds: string[], status: boolean): Observable<CalendarEvent[]> {
    return forkJoin(
      eventIds.map(eventId => this.http.put<{ event: CalendarEvent, success: boolean }>
        (environment.baseUrl + '/api/calendars/events/' + eventId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { event: CalendarEvent, success: boolean }[]) => results.map(result => result.event)),
        tap(success => {
          if (success) {
            this.updatedEvent.next();
            this.asyncTasksToastsService.show(
              {text: `Event${eventIds.length > 1 ? 's' : ''} ${eventIds.length > 1 ? 'are' : 'is'} ${ status ? 'pinned' : 'unpinned'}`}
            );
          }
        })
      );
  }

}
