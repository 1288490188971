import * as tslib_1 from "tslib";
// Common
import { OnChanges, SimpleChanges, OnInit, EventEmitter } from '@angular/core';
// RX
import { Subject, zip } from 'rxjs';
import { takeUntil, filter, switchMap, tap } from 'rxjs/operators';
// Components
import { TopicsComponent } from '@modules/topic/components/topics/topics.component';
var MailTopicsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MailTopicsComponent, _super);
    function MailTopicsComponent(topicService, mailService, modalService) {
        var _this = _super.call(this, modalService) || this;
        _this.topicService = topicService;
        _this.mailService = mailService;
        _this.modalService = modalService;
        // Private
        _this.loadTopics = new Subject();
        _this.loadTemporalExpression = new Subject();
        // Outputs
        _this.topicsChange = new EventEmitter();
        _this.visibleTopicsChange = new EventEmitter();
        _this.temporalExpressionsChange = new EventEmitter();
        return _this;
    }
    /**
     * Component lifecycle
     */
    MailTopicsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.displayAnnotations = true;
        this.topicService.getTopicsUpdate()
            .pipe(takeUntil(this.alive))
            .subscribe(function () {
            _this.loadTopics.next(true);
        });
        this.loadTopics
            .pipe(filter(function (force) { return _this.message && (force || !_this.message.topics || !_this.message.topics.length); }), switchMap(function () { return _this.mailService.getTopics(_this.message); }), 
        // Update only if there are new topics in response, or we need to clear the local topics which no more exists
        filter(function (topics) { return ((topics && !!topics.length) ||
            (topics && !topics.length && _this.message.topics && !!_this.message.topics.length)); }), takeUntil(this.alive))
            .subscribe(function (topics) {
            _this.topics = topics || [];
            _this.topicsChange.emit(topics || []);
        });
        this.loadTemporalExpression
            .pipe(filter(function () { return _this.message && (!_this.temporalExpressions || !_this.temporalExpressions.length); }), switchMap(function () { return _this.mailService.getTemporalExpressions(_this.message); }), filter(function (temporalExpressions) { return (temporalExpressions && !!temporalExpressions.length); }), takeUntil(this.alive))
            .subscribe(function (temporalExpressions) {
            _this.temporalExpressions = temporalExpressions;
            _this.temporalExpressionsChange.emit(_this.temporalExpressions);
        });
    };
    MailTopicsComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if ('message' in changes) {
            this.loadTemporalExpression.next();
            this.loadTopics.next(false);
            if (changes.message.previousValue && changes.message.previousValue.id !== changes.message.currentValue.id) {
                this.setVisibleLength(10);
            }
        }
    };
    /**
     * Actions
     */
    MailTopicsComponent.prototype.changeVisibleTopics = function (newVisibleTopics) {
        _super.prototype.changeVisibleTopics.call(this, newVisibleTopics);
        this.loadTopics.next(false);
    };
    MailTopicsComponent.prototype.handleDeleteTopic = function (index) {
        var _this = this;
        var topic = this.message.topics[index];
        this.topicService
            .deleteTopics([topic], { messagesIds: [this.message.id] })
            .subscribe(function () {
            _this.loadTopics.next(true);
        });
    };
    MailTopicsComponent.prototype.handleAddTopics = function (topics) {
        var _this = this;
        if (topics && topics.length && this.message) {
            this.formLoading = true;
            this.formError = null;
            zip(this.mailService.saveTopicsAnnotations(this.message.id, topics.map(function (topic) { return ({ name: topic.name, rating: 'good' }); }), true), this.topicService.createTopics(topics, { messagesIds: [this.message.id] }))
                .pipe(tap(function () { return _this.formLoading = false; }), takeUntil(this.alive))
                .subscribe(function () {
                _this.increaseVisibleLenght(topics.length);
                _this.popoverClose.next();
                _this.newTopics = [];
                _this.formError = null;
            }, function (error) { return _this.formError = error.message; });
        }
    };
    MailTopicsComponent.prototype.showAnnotateTopicsModal = function () {
        var _this = this;
        this.modalService.topicsAnnotations(this.message, function () { return _this.loadTopics.next(true); });
    };
    return MailTopicsComponent;
}(TopicsComponent));
export { MailTopicsComponent };
