// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

// RX
import { Subject, zip } from 'rxjs';
import { takeUntil, filter, switchMap, tap } from 'rxjs/operators';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { Topic } from '@modules/topic/types/topic';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';

// Services
import { TopicService } from '@modules/topic/services/topic.service';
import { MailService } from '@modules/mail/services/mail.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Components
import { TopicsComponent } from '@modules/topic/components/topics/topics.component';

@Component({
  selector: 'app-mail-topics',
  templateUrl: '../../../topic/components/topics/topics.component.html',
  styleUrls: ['../../../topic/components/topics/topics.component.less', './mail-topics.component.less']
})
export class MailTopicsComponent extends TopicsComponent implements OnInit, OnChanges {

  // Private
  private loadTopics: Subject<boolean> = new Subject();
  private loadTemporalExpression: Subject<void> = new Subject();

  // Inputs
  @Input() message: MailMessage;

  // Outputs
  @Output() topicsChange = new EventEmitter<Topic[]>();
  @Output() visibleTopicsChange = new EventEmitter<Topic[]>();
  @Output() temporalExpressionsChange = new EventEmitter<TemporalExpression[]>();

  constructor (
    private topicService: TopicService,
    private mailService: MailService,
    protected modalService: ModalService
  ) {
    super(modalService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.displayAnnotations = true;

    this.topicService.getTopicsUpdate()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.loadTopics.next(true);
      });

    this.loadTopics
      .pipe(
        filter(force => this.message && (force || !this.message.topics || !this.message.topics.length)),
        switchMap(() => this.mailService.getTopics(this.message)),
        // Update only if there are new topics in response, or we need to clear the local topics which no more exists
        filter(topics => (
          (topics && !!topics.length) ||
          (topics && !topics.length && this.message.topics && !!this.message.topics.length)
        )),
        takeUntil(this.alive)
      )
      .subscribe((topics: Topic[]) => {
        this.topics = topics || [];
        this.topicsChange.emit(topics || []);
      });

    this.loadTemporalExpression
      .pipe(
        filter(() => this.message && (!this.temporalExpressions || !this.temporalExpressions.length)),
        switchMap(() => this.mailService.getTemporalExpressions(this.message)),
        filter(temporalExpressions => (temporalExpressions && !!temporalExpressions.length)),
        takeUntil(this.alive)
      )
      .subscribe(temporalExpressions => {
        this.temporalExpressions = temporalExpressions;
        this.temporalExpressionsChange.emit(this.temporalExpressions);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('message' in changes) {
      this.loadTemporalExpression.next();
      this.loadTopics.next(false);

      if (changes.message.previousValue && changes.message.previousValue.id !== changes.message.currentValue.id) {
        this.setVisibleLength(10);
      }
    }
  }

  /**
   * Actions
   */

  changeVisibleTopics(newVisibleTopics: Topic[]) {
    super.changeVisibleTopics(newVisibleTopics);
    this.loadTopics.next(false);
  }

  handleDeleteTopic(index: number) {
    const topic = this.message.topics[index];

    this.topicService
      .deleteTopics(
        [topic],
        { messagesIds: [this.message.id] }
      )
      .subscribe(() => {
        this.loadTopics.next(true);
      });
  }

  handleAddTopics(topics: Topic[]): void {
    if (topics && topics.length && this.message) {
      this.formLoading = true;
      this.formError = null;

      zip(
        this.mailService.saveTopicsAnnotations(
          this.message.id,
          topics.map(topic => ({name: topic.name, rating: 'good'})),
          true
        ),
        this.topicService.createTopics(
          topics,
          {messagesIds: [this.message.id]}
        )
      )
        .pipe(
          tap(() => this.formLoading = false),
          takeUntil(this.alive)
        )
        .subscribe(
          () => {
            this.increaseVisibleLenght(topics.length);
            this.popoverClose.next();
            this.newTopics = [];
            this.formError = null;
          },
          (error: Error) => this.formError = error.message
        );
    }
  }

  showAnnotateTopicsModal() {
    this.modalService.topicsAnnotations(this.message, () => this.loadTopics.next(true));
  }
}
