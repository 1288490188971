<div
  *ngIf="view === 'empty'"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-tasks.svg"/>
</div>

<app-file-form
  *ngIf="view === 'file-form'"
  [file]="file"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-file-form>
