// Common
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';

// Types
import { MailFolder } from '@modules/mail/types/mail-folder';

@Component({
  selector: 'app-folder-select',
  templateUrl: './folder-select.component.html',
  styleUrls: ['./folder-select.component.less']
})
export class FolderSelectComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() title: string;

  // Outputs
  @Output() selected = new EventEmitter<MailFolder>();

  // Public
  public folders: MailFolder[] = [];
  public filteredFolders: MailFolder[] = [];
  public inputFormControl = new FormControl();

  // Private
  private componentNotDestroyed = new Subject();

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    // Get folders
    this.mailService.getFolders()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((folders: MailFolder[]) => {
        this.folders = folders.filter(folder => !MailService.disallowMoveFolderIds.includes(folder.id));
        this.filteredFolders = this.filterFolders(this.folders, this.inputFormControl.value);
      });
    // Handle input changes
    this.inputFormControl.valueChanges
      .pipe(
        takeUntil(this.componentNotDestroyed),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filteredFolders = this.filterFolders(this.folders, text);
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  filterFolders(folders: MailFolder[], text: string): MailFolder[] {
    if (!text || !text.length) {
      return folders;
    }
    return this.folders.filter(folder => folder.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
  }

  /**
   * Actions
   */

  selectFolder(folder: MailFolder) {
    this.selected.emit(folder);
  }

}
