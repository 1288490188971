<div class="container" [ngClass]="{expanded: !minimized}">
  <div class="container-header">
    <app-events-list-header
      *ngIf="!minimized"
      (selectedOrderChange)="selectedOrder = $event"
      (scrollOptionChange)="handleScrollOptionChange($event)"
      (searchQueryChange)="handleSearch($event)"
      [loading]="loading"
    ></app-events-list-header>
    <div class="shadow"></div>
  </div>
  
  <div class="container-quick-form" *ngIf="!minimized">
    <app-quick-form
      placeholder="Add New Event"
      bundledGroup="eventsListQuickForm"
      [toggle]="quickFormToggle"
      (clickMore)="handleNewEvent()"
      (collapsedChange)="quickFormExpanded = $event"
      (itemDropped)="handleQuickFormDrop($event)"
    >
      <app-event-quick-form
        bundledGroup="eventsListQuickForm"
        [event]="quickFormEvent"
        (more)="handleNewEvent($event)"
      ></app-event-quick-form>
    </app-quick-form>
    <div class="shadow" [ngClass]="{hidden: !quickFormExpanded}"></div>
  </div>

  <div class="container-list" *ngIf="!minimized">
    <app-events-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [scrollToDay]="scrollToDay"
      [defaultFilters]="listFilters"
      (eventClick)="handleEventClick($event)"
      [selectedItems]="eventsStateService.getSelectedEvents() | async"
      (selectedItemsChanged)="handleSelectedEvents($event)"
      (viewDate)="setSelectedCalendarDate($event)"
      (loadInProgress)="loading = $event"
    ></app-events-list>
  </div>
</div>
