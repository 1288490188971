<div
  *ngIf="!task.temp"
  class="task"
  [class.drag-placeholder]="!dragEnabled"
>
  <div
    class="task-body"
    [class.subtask]="subtask"
    [class.pinned]="task.pinned"
    [class.drag-placeholder]="!dragEnabled"
    [appTaskContextMenu]="task"

    appDraggable
    [appDraggableEnabled]="dragEnabled"
    [appDraggableData]="{data: [task], type: 'task'}"
    [appDraggableNoShadow]="true"
    [appDraggablePlaceholder]="dragPlaceholder"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate"
    [appDroppableZIndex]="subtask ? 2 : 1"
    (appDroppableDrop)="dndDrop($event)"
  >
    <div
      class="task-body-indicator"
      [class.subtask]="subtask"
    ></div>

    <div class="task-body-title">
      <div class="task-body-title-pinned">
        <mat-icon
          *ngIf="task.pinned"
          class="task-body-title-pinned-icon"
          svgIcon="pin-direct"
        ></mat-icon>
      </div>
      <div class="task-body-title-circle">
        <mat-icon
          class="task-body-title-circle-icon"
          [svgIcon]="task.checked ? 'circle-checked' : 'circle'"
          [ngStyle]="{color: task.circleColor || '#e4e5e8'}"
        ></mat-icon>
      </div>
      <div
        class="task-body-title-text"
      >
        {{ task.title }}
      </div>
      <div class="task-body-title-column">
        <div class="task-body-title-column-hover">
          <mat-icon
            svgIcon="pin"
            class="task-body-title-column-hover-icon"
            (click)="pin()"
            [ngClass]="{active: task?.pinned}"
            [matTooltip]="task?.pinned ? 'Unpin' : 'Pin'"
          ></mat-icon>

          <mat-icon
            svgIcon="snooze"
            class="task-body-title-column-hover-icon"
            matTooltip="Coming soon"
          ></mat-icon>

          <mat-icon
            svgIcon="flag"
            class="task-body-title-column-hover-icon"
            matTooltip="Coming soon"
          ></mat-icon>

          <mat-icon
            svgIcon="mail"
            class="task-body-title-column-hover-icon"
            matTooltip="Coming soon"
          ></mat-icon>

          <mat-icon
            svgIcon="events"
            class="task-body-title-column-hover-icon"
            matTooltip="Coming soon"
          ></mat-icon>

          <mat-icon
            svgIcon="archive"
            class="task-body-title-column-hover-icon"
            [ngClass]="{active: task?.archived}"
            (click)="archive()"
            [matTooltip]="task?.archived ? 'Remove From Archive' : 'Add To Archive'"
          ></mat-icon>

          <mat-icon
            svgIcon="trash-bucket"
            class="task-body-title-column-hover-icon"
            [ngClass]="{active: task?.deleted}"
            (click)="delete()"
            [matTooltip]="task?.deleted ? 'Delete Permanently' : 'Delete'"
          ></mat-icon>
        </div>
        <span *ngIf="appearance === 'default'">Completed</span>
        <mat-icon
          *ngIf="!subtask"
          class="task-body-title-column-icon"
          svgIcon="link-thin"
        ></mat-icon>
      </div>
    </div>

    <div class="task-body-date" *ngIf="!subtask">
      <div class="task-body-date-saved">
        <mat-icon
          class="task-body-date-saved-icon"
          svgIcon="saved-small"
        ></mat-icon>
      </div>
      <span class="task-body-date-date" *ngIf="task.fromTime">{{ task.fromTime | date : 'MMM d'}}</span>
      <div class="task-body-date-date-placeholder" *ngIf="!task.fromTime && task.fromTime"></div>
      <span class="task-body-date-time" *ngIf="task.fromTime">{{ task.fromTime | date : 'h:mm a' | lowercase }}</span>
      <div class="task-body-date-time-placeholder" *ngIf="!task.fromTime && task.fromTime"></div>
      <div class="task-body-date-date-time-placeholder" *ngIf="!task.fromTime && !task.fromTime"></div>
      <div class="task-body-date-separator"></div>
      <span class="task-body-date-date" *ngIf="task.toTime">{{ task.toTime | date : 'MMM d'}}</span>
      <div class="task-body-date-date-placeholder" *ngIf="!task.toTime && task.toTime"></div>
      <span class="task-body-date-time" *ngIf="task.toTime">{{ task.toTime | date : 'h:mm a' | lowercase }}</span>
      <div class="task-body-date-time-placeholder" *ngIf="!task.toTime && task.toTime"></div>
      <div class="task-body-date-date-time-placeholder" *ngIf="!task.toTime && !task.toTime"></div>
    </div>

    <mat-icon
      class="task-body-attach"
      svgIcon="attachment"
    ></mat-icon>

    <div class="drop-zone"></div>
  </div>

  <div class="task-subtasks" *ngIf="!subtask">
    <app-project-section-task
      *appDraggableListFor="
        let subtask of task.subtasks;
        trackBy: tasksTrackByFn;
        repositionStream: repositionStream;
        let index = index;
        predicate: reorderPredicate;
        zIndex: 2;
        disabled: !dragEnabled;
      "
      [task]="subtask"
      [subtask]="true"
      [sectionsTree]="sectionsTree"
      [appearance]="appearance"
    ></app-project-section-task>
  </div>

  <div
    class="task-left-dot-container"
    *ngIf="!(subtask && last) && dragEnabled && allowedNewTasksSections"
    (click)="handleNewSection()"
  >
    <div class="dot"></div>
    <div class="plus">+</div>
  </div>

  <div
    class="task-center-dot-container"
    *ngIf="!subtask && dragEnabled && allowedNewTasksSections"
    (click)="handleNewTask()"
  >
    <div class="dot"></div>
    <div class="plus">+</div>
  </div>
</div>

<app-task-quick-form
  *ngIf="task.temp"
  [task]="task"
  (save)="handleSubmitTask($event)"
></app-task-quick-form>

<ng-template #dragPlaceholder>
  <app-project-section-task
    [appearance]="appearance"
    [task]="task"
    [dragEnabled]="false"
    [subtask]="subtask"
  ></app-project-section-task>
</ng-template>
