// Common
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { ElectronService } from 'ngx-electron';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { SplitViewKey } from '@modules/settings/types/split-view-state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})

export class SidebarComponent implements OnInit, OnDestroy {

  // Public
  public minimized: boolean;
  public extraTopPadding: boolean;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Inputs
  @Input() splitViewKey: SplitViewKey;

  /**
   * Constructor
   */

  constructor(
    private splitViewService: SplitViewService,
    private changeDetector: ChangeDetectorRef,
    private electronService: ElectronService
  ) {
    // For electron version we need extra padding on top, to avoid intersect with system close buttons
    this.extraTopPadding = this.electronService.isElectronApp && this.electronService.isMacOS;
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.splitViewService
      .getMinimized(this.splitViewKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }
}
