// Common
import { OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// RxJS
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';


export class LinkInfoItemSuggestions implements OnInit, OnDestroy {


  // Inputs
  @Input() linkedInfo: LinkedInfo[];
  @Input() appearance: 'default' | 'context-menu' = 'default';

  // Outputs
  @Output() close = new EventEmitter<void>();
  @Output() create = new EventEmitter<void>();

  // Public
  public inputFormControl = new FormControl();
  public suggestions: any[] = [];

  // Private
  protected componentNotDestroyed = new Subject();

  /**
   * Constructor
   */

  constructor(
    protected linkedInfoService: LinkedInfoService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.inputFormControl.valueChanges
      .pipe(
        takeUntil(this.componentNotDestroyed),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this.getSuggestions(value))
      )
      .subscribe((suggestions: any[]) => this.suggestions = suggestions || []);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  getSuggestions(inputValue: string): Observable<any[]> {
    return of([]);
  }

  openCreateModal() { }

  /**
   * Actions
   */

  selectedLinkedInfo(currentLinkedInfo: LinkedInfo) {
    this.linkedInfoService.linkToItem(currentLinkedInfo, this.linkedInfo);
    this.closed();
  }

  closed() {
    this.close.emit();
  }

  creating() {
    this.create.emit();
    if (!this.create.observers.length) {
      this.openCreateModal();
    }
  }

}
