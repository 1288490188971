// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { ContactContextMenuComponent } from '../components/contact-context-menu/contact-context-menu.component';

// Types
import { Contact } from '@modules/contacts/types/contact';

@Directive({
  selector: '[appContactContextMenu]'
})
export class ContactContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() contextMenuComponent = ContactContextMenuComponent;
  @Input() appContactContextMenu: Contact;

  setContextMenuInstanceData(componentRef: ComponentRef<ContactContextMenuComponent>) {
    componentRef.instance.contact = this.appContactContextMenu;
  }
}
