// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { NotesService } from '@modules/notes/services/notes.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Type
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-linked-info-note-list',
  templateUrl: './linked-info-note-list.component.html',
  styleUrls: ['./linked-info-note-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoNoteListComponent  extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private notesService: NotesService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'note';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<Note[]> {
    return this.notesService.getNotes({notesIds: ids, order: 'created-asc', limit: ids.length, offset: 0})
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response) => response.notes)
      );
  }

  /**
   * Actions
   */

  select(note: Note) {
    this.modalService.noteForm(note);
  }

}
