// Common
import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, switchMap } from 'rxjs/operators';

// Types
import { Column } from '@modules/tasks/types/column';
import { ColumnsTree } from '@modules/tasks/types/columns-tree';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Task } from '@modules/tasks/types/task';

// Services
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { ColumnsTreeService } from '@modules/tasks/services/columns-tree.service';
import { StateService } from '@modules/settings/services/state.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-project-board-column',
  templateUrl: './project-board-column.component.html',
  styleUrls: ['./project-board-column.component.less'],
})
export class ProjectBoardColumnComponent implements OnInit, OnChanges, OnDestroy {

  // Public
  public columnForm: FormGroup;
  public collapsed = false;

  // Private
  private alive = new Subject<void>();
  private columnChanged = new Subject<void>();

  // Inputs
  @Input() column: Column;
  @Input() columnsTree: ColumnsTree;
  @Input() dragEnabled = true;
  @Input() forceCollapsed = false;

  /**
   * Constructor
   */

  constructor (
    private columnsService: ColumnsService,
    private columnsTreeService: ColumnsTreeService,
    private stateService: StateService,
    private tasksService: TasksService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columnForm = this.column.asFormGroup();

    this.columnChanged
      .pipe(
        switchMap(() => this.stateService.getCollapsedColumns()),
        map((columnsIds: string[]) => columnsIds.includes(this.column.id || this.columnsTree.projectId)),
        takeUntil(this.alive)
      )
      .subscribe((value: boolean) => this.collapsed = value);

    this.columnChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('column' in changes) {
      this.columnChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleError() {

  }

  handleBlur() {
    if (
      this.columnForm.get('title').dirty &&
      this.columnForm.get('title').value.trim() !== ''
    ) {
      const column = Column.fromFormGroup(this.columnForm);

      const saveRequest: Observable<Column> = this.columnForm.get('id').value
        ? this.columnsService.update(column)
        : this.columnsTreeService.submitColumn(this.columnsTree, column);

      saveRequest
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe(
          () => {},
          () => this.handleError()
        );
    }
  }

  handleNewColumnRight () {
    if (this.column.temp) {
      return;
    }
    this.columnsTree.insertColumn(this.column);
  }

  handleNewTask() {
    this.column.sectionsTree.insertTask(null);
  }

  collapse() {
    this.collapsed = !this.collapsed;
    const collapsedStateKey = this.column.id || this.columnsTree.projectId;
    if (this.collapsed) {
      this.stateService.addColumnToCollapsed(collapsedStateKey);
    } else {
      this.stateService.removeColumnFromCollapsed(collapsedStateKey);
    }
  }

  public handleNewTaskDrop(dragData: DragData) {
    if (!this.column) { return; }

    const task = Task.fromDragData(dragData);

    if (!task) { return; }

    this.tasksService.create(task);
  }
}
