import * as tslib_1 from "tslib";
// Common
import { EventEmitter } from '@angular/core';
// Components
import { EventComponent } from '@modules/elements/components/event/event.component';
var LinkInfoEventComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoEventComponent, _super);
    function LinkInfoEventComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shortView = false;
        // Outputs
        _this.unlink = new EventEmitter();
        return _this;
    }
    /**
     * Actions
     */
    LinkInfoEventComponent.prototype.unlinkItem = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.unlink.emit(this.linkedInfo);
    };
    LinkInfoEventComponent.prototype.openEvent = function () {
        if (this.event) {
            this.modalService.calendarEventForm(this.event);
        }
    };
    return LinkInfoEventComponent;
}(EventComponent));
export { LinkInfoEventComponent };
