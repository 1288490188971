/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reminder-date-time-picker-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../form-controls/components/date-picker/date-picker.component.ngfactory";
import * as i5 from "angular-calendar";
import * as i6 from "../../../form-controls/components/date-picker/date-picker.component";
import * as i7 from "ngx-contextmenu";
import * as i8 from "@angular/common";
import * as i9 from "../../../settings/services/state.service";
import * as i10 from "../../../form-controls/components/time-picker/time-picker.component.ngfactory";
import * as i11 from "../../../form-controls/components/time-picker/time-picker.component";
import * as i12 from "./reminder-date-time-picker-modal.component";
import * as i13 from "../../../mail/services/mail.service";
import * as i14 from "../../../analytics/services/google-analytics.service";
var styles_ReminderDateTimePickerModalComponent = [i0.styles];
var RenderType_ReminderDateTimePickerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReminderDateTimePickerModalComponent, data: {} });
export { RenderType_ReminderDateTimePickerModalComponent as RenderType_ReminderDateTimePickerModalComponent };
export function View_ReminderDateTimePickerModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header attachment-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "modal-body modal-body--with-footer modal-body-custom-reminder text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "date-time-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-date-picker", [["class", "date-time-input"], ["placeholder", "Date"], ["width", "108px"]], null, null, null, i4.View_DatePickerComponent_0, i4.RenderType_DatePickerComponent)), i1.ɵprd(4608, null, i5.CalendarDateFormatter, i6.CalendarMonthDateFormatter, [i5.DateAdapter]), i1.ɵdid(10, 770048, null, 0, i6.DatePickerComponent, [i7.ContextMenuService, i8.DatePipe, i9.StateService], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-time-picker", [["class", "date-time-input"], ["placeholder", "Time"], ["width", "80px"]], null, null, null, i10.View_TimePickerComponent_0, i10.RenderType_TimePickerComponent)), i1.ɵdid(12, 770048, null, 0, i11.TimePickerComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-default btn-sm pull-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm pull-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveReminder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "close"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.date; var currVal_5 = "Date"; var currVal_6 = "108px"; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.time; var currVal_8 = "Time"; var currVal_9 = "80px"; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.reminderType === "followup") ? "Follow up date" : "Snooze until"); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).inline; var currVal_2 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_10 = (!_co.date.value || !_co.time.value); _ck(_v, 16, 0, currVal_10); }); }
export function View_ReminderDateTimePickerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reminder-date-time-picker-modal", [], null, null, null, View_ReminderDateTimePickerModalComponent_0, RenderType_ReminderDateTimePickerModalComponent)), i1.ɵdid(1, 245760, null, 0, i12.ReminderDateTimePickerModalComponent, [i13.MailService, i14.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReminderDateTimePickerModalComponentNgFactory = i1.ɵccf("app-reminder-date-time-picker-modal", i12.ReminderDateTimePickerModalComponent, View_ReminderDateTimePickerModalComponent_Host_0, { message: "message", reminderType: "reminderType" }, { closed: "closed", collapsed: "collapsed" }, []);
export { ReminderDateTimePickerModalComponentNgFactory as ReminderDateTimePickerModalComponentNgFactory };
