import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { EventsAgendaContextMenuComponent } from '../../components/events-agenda-context-menu/events-agenda-context-menu.component';
var EventsAgendaContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(EventsAgendaContextMenuDirective, _super);
    function EventsAgendaContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contextMenuComponent = EventsAgendaContextMenuComponent;
        return _this;
    }
    EventsAgendaContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
    };
    return EventsAgendaContextMenuDirective;
}(ContextMenuDirective));
export { EventsAgendaContextMenuDirective };
