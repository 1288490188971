// Common
import { Component, OnInit, Input } from '@angular/core';

// RxJS
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { MailMessage } from '../../../mail/types/mail-message';
import { MailService } from '../../../mail/services/mail.service';
import { MailFolder } from '../../../mail/types/mail-folder';
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-select-folder-modal',
  templateUrl: './select-folder-modal.component.html',
  styleUrls: ['./select-folder-modal.component.less']
})
export class SelectFolderModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() messages: MailMessage[];

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 250px)',
    y: '40px',
    width: '500px',
    height: 'calc(100% - 100px)'
  };

  // Public
  public folders: MailFolder[];
  public selectedFolder?: MailFolder;

  // Private
  private defaultFolderIds: string[] = [
    'all', 'outbox', 'drafts', 'starred', 'snoozed',
    'followed', 'important', 'archive'
  ];

  /**
   * Constructor
   */

  constructor(
    protected mailService: MailService,
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
    this.mailService.getFolders()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((folders: MailFolder[]) => {
        this.folders = this.filterUserFolders(folders);
      });
  }

  /**
   * Actions
   */

  confirmMove() {
    this.mailService.moveMessages({ messages: this.messages }, [this.selectedFolder.id])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((moved: boolean) => {
        if (moved) { this.close(); }
      });
  }

  openFolder(folder: MailFolder, event: MouseEvent) {
    event.stopPropagation();
    if (folder.hasOwnProperty('subFolders')) {
      if (folder.hasOwnProperty('open') === false) {
        folder['open'] = false;
      }
      if (folder['open'] === false) {
        folder['open'] = true;
      } else {
        folder['open'] = false;
      }
    }
  }

  private filterUserFolders(folders: MailFolder[]): MailFolder[] {
    return folders.filter(item => !this.defaultFolderIds.some(id => item.id === id)).map(x => Object.assign({}, x));
  }

}
