import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
var ConfirmationModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfirmationModalComponent, _super);
    /**
     * Constructor
     */
    function ConfirmationModalComponent() {
        var _this = _super.call(this) || this;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 128px)',
            y: '40px',
            width: '256px',
        };
        return _this;
    }
    /**
     * Component lifecycle
     */
    ConfirmationModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    /**
     * Actions
     */
    ConfirmationModalComponent.prototype.submitModal = function () {
        this.action(true);
        this.close();
    };
    ConfirmationModalComponent.prototype.closeModal = function () {
        this.action(false);
        this.close();
    };
    return ConfirmationModalComponent;
}(BaseModalComponent));
export { ConfirmationModalComponent };
