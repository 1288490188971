// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FormControlsModule } from '@modules/form-controls/form-controls.module';

// Components
import { AttachmentsListComponent } from './components/attachments-list/attachments-list.component';

@NgModule({
  declarations: [AttachmentsListComponent],
  imports: [
    CommonModule,
    FormControlsModule
  ],
  exports: [AttachmentsListComponent]
})
export class AttachmentsModule { }
