<div
  *ngIf="file"
  class="file"
  [ngClass]="{selected: selected}"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'file'}"
  [appDraggablePlaceholder]="dragPlaceholder"

  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"
>
  <div class="file-icons">
    <mat-icon *ngIf="file.pinned" class="file-icons-small-icon pin" svgIcon="mail-pinned"></mat-icon>
    <mat-icon *ngIf="file.starred" class="file-icons-small-icon star" svgIcon="starred"></mat-icon>
  </div>

  <div class="file-info">
    <mat-icon
      class="file-info-type-icon"
      [svgIcon]="file.type + '-new'"
    ></mat-icon>

    <div class="file-info-container">
      <span class="file-info-container-text">{{ file.title }}</span>

      <div class="file-info-container-description" [class.empty]="!file.description?.trim()">{{ file.description }}</div>

      <app-link-info-bar [linkedInfo]="file.linkedInfo"></app-link-info-bar>
    </div>
  </div>

  <div class="file-icons right">
    <div class="file-icons-hideable">
      <mat-icon class="file-icons-hideable-type-icon" svgIcon="file"></mat-icon>
    </div>
  </div>
</div>

<!-- Drag placeholder -->
<ng-template #dragPlaceholder>
  <app-file [file]="file" [dragEnabled]="false"></app-file>
</ng-template>

<!-- File Placeholder -->
<div class="file-placeholder" *ngIf="!file">
  <div class="file-placeholder-icons"></div>
  <div class="file-placeholder-info">
    <div class="file-placeholder-info-title">
      <div class="file-placeholder-info-title-name"></div>
      <div class="file-placeholder-info-title-date"></div>
    </div>
    <div class="file-placeholder-info-subject"></div>
    <div class="file-placeholder-info-subject"></div>
  </div>
</div>
