// Commons
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// Modules
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { NavBarModule } from '../nav-bar/nav-bar.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { TasksModule } from './../tasks/tasks.module';
import { CalendarModule } from '../calendar/calendar.module';
import { TopicModule } from '../topic/topic.module';
import { MatAutocompleteModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectedTextTooltipModule} from 'ng-selected-text-tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { AppMenuModule } from '../app-menu/app-menu.module';
import { MatChipsModule } from '@angular/material/chips';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ContextMenuModule } from '../context-menu/context-menu.module';
import { LinkedInfoModule } from './../linked-info/linked-info.module';
import { MatMenuModule } from '@angular/material/menu';
import { ContentMenuModule } from '../content-menu/content-menu.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ElementsModule } from '../elements/elements.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { TagModule } from '@modules/tag/tag.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';

// Services
import { MailService } from './services/mail.service';
import { PrintService } from './services/print.service';
import { KnowledgeGraphService } from './services/knowledge-graph.service';

// Components
import { MailSideBarComponent } from './components/mail-side-bar/mail-side-bar.component';
import { ContactsCounterCollapseComponent } from './components/contacts-counter-collapse/contacts-counter-collapse.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { MessageEditorComponent } from './components/message-editor/message-editor.component';
import { RecipientsInputComponent } from './components/message-editor/recipients-input/recipients-input.component';
import { SettingButtonComponent } from './components/message-editor/setting-button/setting-button.component';
import { MessageContentComponent } from './components/message-content/message-content.component';
import { DropdownFoldersComponent } from './components/dropdown-folders/dropdown-folders.component';
import { MailTopicsComponent } from './components/mail-topics/mail-topics.component';
import { MailFolderDetailsPipe } from './pipes/mail-folder-details.pipe';
import { VirtualScrollModule } from '@modules/virtual-scroll/virtual-scroll.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppCommonModule,
    InfiniteScrollModule,
    NgxContextMenuModule.forRoot(),
    UserAvatarModule,
    NavBarModule,
    TooltipModule.forRoot(),
    CalendarModule,
    TasksModule,
    TopicModule,
    MatAutocompleteModule,
    MatTabsModule,
    NgSelectedTextTooltipModule,
    MatTooltipModule,
    MatIconModule,
    AppMenuModule,
    MatChipsModule,
    EditorModule,
    ContextMenuModule,
    LinkedInfoModule,
    MatMenuModule,
    ContentMenuModule,
    ScrollingModule,
    FormControlsModule,
    AppSplitViewModule,
    DropdownModule,
    ElementsModule,
    DragNDropModule,
    TagModule,
    PopoverModule,
    PipesModule,
    AttachmentsModule,
    VirtualScrollModule
  ],

  exports: [
    MailSideBarComponent, MessageContentComponent, MessageEditorComponent, MessagesListComponent,
    MailFolderDetailsPipe
  ],

  declarations: [
    MailSideBarComponent, ContactsCounterCollapseComponent, MessagesListComponent,
    MessageEditorComponent, RecipientsInputComponent, SettingButtonComponent, MessageContentComponent,
    DropdownFoldersComponent, MailTopicsComponent, MailFolderDetailsPipe,
  ],

  providers: [
    MailService,
    PrintService,
    DatePipe,
    KnowledgeGraphService,
  ],
})
export class MailModule { }
