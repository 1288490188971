// Common
import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { CollapsedStateKey } from '@modules/settings/types/collapsed-state';
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarType } from '@modules/events/types/calendar-type';
import { MailMessage } from '@modules/mail/types/mail-message';
import { EventFilters } from '@modules/events/types/event-filters';

// RX
import { Subject, merge, interval } from 'rxjs';
import { takeUntil, debounceTime, switchMap, map, startWith, take, tap } from 'rxjs/operators';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { StateService } from '@modules/settings/services/state.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';
import { PopoverService } from '@modules/popover/services/popover.service';

// Environment
import { environment } from '@environment';

@Component({
  selector: 'app-events-date-picker',
  templateUrl: './events-date-picker.component.html',
  styleUrls: ['./events-date-picker.component.less'],
})
export class EventsDatePickerComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() storeDateKey: CalendarType;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() disabled = false;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() inline: boolean;
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputAppearance: 'start' | 'end' = 'start';
  @Input() bundledInputConsumerGroup: string;
  @Input() collapsedStateKey: CollapsedStateKey;
  @Input() maxDate: Date;
  @Input() collapseable = true;
  @Input() filters: EventFilters = {};

  // Public
  public events: AngularCalendarEvent[] = [];
  public dragEvent: CalendarEvent;

  // Private
  private activeDate: Date = new Date();
  private loadEvents: Subject<void> = new Subject();
  private componentNotDestroyed: Subject<void> = new Subject();
  private calendarIds: string[] = [];
  private popoverClose: Subject<void> = new Subject();

  // View Children
  @ViewChild('popoverFormTemplate', {static: true}) public popoverFormTemplate: TemplateRef<any>;

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private stateService: StateService,
    private eventsStateService: EventsStateService,
    private popoverService: PopoverService,
    private knowledgePanelService: KnowledgePanelService,
  ) {
    this.loadEvents
      .pipe(
        tap(() => this.events = []),
        debounceTime(600),
        startWith(),
        switchMap(() => this.calendarService
          .getEvents({
            calendarIds: this.calendarIds,
            fromDate: this.beginningOfMonth(this.activeDate),
            toDate: this.endOfMonth(this.activeDate),
            ...this.filters
          })
          .pipe(
            map((response: EventsListResponse): CalendarEvent[] => response.events),
            map((events: CalendarEvent[]) => events.map(event => event.asAngularCalendarEvent())),
          )
        ),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(events => {
        this.events = events;
      });

    this.stateService.getSelectedKPCalendars()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarIds: string[]) => {
        this.calendarIds = calendarIds;
        this.loadEvents.next();
      });

    merge(
      this.eventsStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.calendarService.createNewEvent,
      this.calendarService.updatedEvent,
      this.calendarService.deletedEvent,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.loadEvents.next();
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit(): void {
    if (this.storeDateKey && this.inputFormControl) {
      this.stateService.getSelectedCalendarDates(this.storeDateKey)
        .pipe(
          take(1),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe((date: Date) => this.inputFormControl.patchValue(date));

      this.inputFormControl.valueChanges
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((date: Date) => this.stateService.setSelectedCalendarDates(this.storeDateKey, date));
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleActiveDateChange(date: Date): void {
    if (this.storeDateKey) {
      this.stateService.setSelectedCalendarDates(this.storeDateKey, date);
      this.inputFormControl.patchValue(date);
    }

    if (
      this.activeDate.getFullYear() === date.getFullYear() &&
      this.activeDate.getMonth() === date.getMonth()
    ) {
      return;
    }
    this.activeDate = date;
    this.loadEvents.next();
  }

  handleDrop(dragData: DragData, date: Date, origin: HTMLElement): void {
    if (dragData.type === 'event') {
      this.dragEvent = CalendarEvent.fromCalendarCell(<CalendarEvent>dragData.data[0], date, 'year', true);

      this.popoverService.create(
        new ElementRef(origin),
        {
          content: this.popoverFormTemplate,
          placement: 'left',
          innerShadow: false,
          allowedOutsideSelectors: '.mat-option-text, custom-repeater-dialog, .app-popover-content, .mat-autocomplete-panel, .dropdown',
          arrow: true,
          trigger: 'click',
          showUntil: this.popoverClose
        }
      );
    }

    if (dragData.type === 'message') {
      const calendarEvent = CalendarEvent.fromMailMessage(<MailMessage>dragData.data[0]);
      calendarEvent.when = {
        durationType: 'day',
        start: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0 )),
        end: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0 ))
      };
      this.knowledgePanelService.setFormItem(calendarEvent);
    }
  }

  handlePopoverFormClose() {
    this.popoverClose.next();
  }

  /**
   * Helpers
   */

  private beginningOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  }

  private endOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
  }
}
