// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { EventContextMenuComponent } from '../components/event-context-menu/event-context-menu.component';

@Directive({
  selector: '[appEventContextMenu]'
})
export class EventContextMenuDirective extends ContextMenuDirective {

  @Input() appEventContextMenu: CalendarEvent;
  @Input() contextMenuComponent = EventContextMenuComponent;

  setContextMenuInstanceData(componentRef: ComponentRef<EventContextMenuComponent>) {
    componentRef.instance.event = this.appEventContextMenu;
  }
}
