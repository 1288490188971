// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.less']
})
export class IconButtonComponent {

  // Inputs
  @Input() icon: string;
  @Input() text: string;
  @Input() badge = false;
  @Input() disabled = false;
  @Input() highlighted = true;

  // Outputs
  @Output() public clicked = new EventEmitter<MouseEvent>();

  /**
   * Constructor
   */

  constructor() { }

}
