<div class="event-tooltip-container">
  <div class="title-container flexRow">
    <div class="text-ellipsis"> {{ event.title || '(No Title)'}} </div>
    <mat-icon
      svgIcon="events"
      class="standard-blue-icon"
      [ngStyle]="{ 'color': (event.color || (event.calendarId | calendarColor | async)) }"></mat-icon>
  </div>
  <div class="description" *ngIf="event?.description?.length > 0">
    <span> {{ event.description }} </span>
  </div>
  <app-contacts-counter-dropdown class="contacts-list" *ngIf="event?.participants?.length > 0"
    [contactList]="event.participants"
    [size]="16"
  ></app-contacts-counter-dropdown>
  <hr/>

  <div class="event-detailed">
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.when?.start">
      <mat-icon class="gray-icon" svgIcon="events"></mat-icon>
      <span class="text"> {{ event.when.start | relativeDate:true:false}} </span>
    </div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.when?.start && event?.when?.durationType !== 'day'">
      <mat-icon class="gray-icon gray-icon-clock" svgIcon="followup-small"></mat-icon>
      <span class="text">{{ event.when.start | date:'shortTime'}}</span>
      <span class="text" *ngIf="event?.when?.end"> - {{ event.when.end | date:'shortTime'}} </span>
    </div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.when?.start">
      <mat-icon class="gray-icon gray-icon-time-zone" svgIcon="time-zone"></mat-icon>
      <span class="text"> GMT+02:00 Eastern Europian Time - Kiev </span></div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.location?.address">
      <mat-icon class="gray-icon gray-icon-map-pin" svgIcon="map-pin"></mat-icon>
      <span class="text"> {{ event?.location?.address }} </span></div>
  </div>

  <div class="map-container" *ngIf="event?.location?.coordinates?.latitude && event?.location?.coordinates?.longitude">
    <app-image-map-preview [latitude]="event.location.coordinates.latitude"
                           [longitude]="event.location.coordinates.longitude">
    </app-image-map-preview>
  </div>

  <div class="event-attachments" *ngIf="event?.files && event?.files.length > 0">
    <div class="title-container">
      <mat-icon class="title-blue-icon" svgIcon="attachment" (click)="saveAllAttachments()"></mat-icon>
      <span class="title"> Attachments </span>
    </div>

    <li *ngFor="let file of event?.files" class="attachments-list-item">
      <div class="attachment-item">
        <app-attachment-icon [type]="file.type"></app-attachment-icon>
        <div class="flex-column">
          <div class="attachment-title text-ellipsis" (click)="saveAttachment(file, $event)"> {{ file.name?.length > 0 ? file.name : 'No name' }} </div>
          <div class="attachment-size"> {{ file.size | fileSize }} </div>
        </div>
        <div class="attachment-date"> {{ file.date | date:'MMM, dd' }} </div>
      </div>
    </li>

  </div>

  <div class="linked-information" *ngIf="event?.linkedInfo?.length > 0">
    <div class="title-container"><mat-icon class="title-blue-icon" svgIcon="link-bold"></mat-icon>
      <span class="title"> Linked Information </span>
    </div>
   <app-link-info-bar class="linked-list" [linkedInfo]="event.linkedInfo"></app-link-info-bar>
  </div>
</div>
