// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Components
import { ProjectComponent } from '@modules/elements/components/project/project.component';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

@Component({
  selector: 'app-link-info-project',
  templateUrl: './link-info-project.component.html',
  styleUrls: ['./link-info-project.component.less']
})
export class LinkInfoProjectComponent extends ProjectComponent {

  // Inputs
  @Input() linkedInfo: LinkedInfo;
  @Input() shortView = false;

  // Outputs
  @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

  // Actions
  public unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit(this.linkedInfo);
  }

  public openProject() {
    if (this.project) {
      this.modalService.projectForm(this.project);
    }
  }
}
