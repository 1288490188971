
export type LinkedInfoType = 'message' | 'event' | 'task' | 'project' | 'note' | 'contact' | 'group';

export class LinkedInfo {
  type: LinkedInfoType;
  id: string;
  createdAt?: Date;

  constructor(type: LinkedInfoType, id: string) {
    this.type = type;
    this.id = id;
  }
}
