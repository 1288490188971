import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snoozedDate'
})
export class SnoozedDatePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    switch (value) {
      case 'hour':
        return '1 hour';
      case 'tomorrow':
        return 'Tomorrow';
      case 'afterTomorrow':
        return 'Day after tomorrow';
      case 'endOfWeek':
        return 'End of week';
      case 'nextWeek':
        return 'Next monday';
      default:
        break;
    }
    return value;
  }

}
