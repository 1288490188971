// Common
import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';

@Component({
  selector: 'app-task-quick-form',
  templateUrl: './task-quick-form.component.html',
  styleUrls: ['./task-quick-form.component.less'],
})
export class TaskQuickFormComponent implements OnDestroy, OnChanges {

  // Inputs
  @Input() bundledGroup: string;
  @Input() task: Task = new Task();

  // Outputs
  @Output() more: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Task> = new EventEmitter();

  // Public
  public taskForm: FormGroup;
  public saveInProgress = false;

  // Private
  private alive = new Subject<void>();
  private selectedProject: Project;

  /**
   * Constructor
   */

  constructor (
    private tasksService: TasksService,
    private tasksStateService: TasksStateService,
  ) {
    this.tasksStateService.getProject()
      .pipe(takeUntil(this.alive))
      .subscribe((project: Project) => this.selectedProject = project);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('task' in changes) {
      this.reset();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    this.setProjectIdToTaskForm();
    if (!this.taskForm.valid) {
      return;
    }

    if (this.save.observers.length > 0) {
      const task = Task.fromFormGroup(this.taskForm);
      this.save.emit(task);
      return;
    }

    this.saveInProgress = true;

    this.tasksService.upsert(this.taskForm)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleError() {
    this.saveInProgress = false;
  }

  reset() {
    if (!this.task) { return; }

    this.taskForm = this.task.asFormGroup();
    this.saveInProgress = false;
  }

  handleMoreOptions() {
    this.setProjectIdToTaskForm();
    this.more.emit(Task.fromFormGroup(this.taskForm));
    this.close.emit();
  }

  public handleClickCancel() {
    this.close.emit();
  }

  private setProjectIdToTaskForm() {
    if (this.taskForm.get('projectId').value) { return; }
    this.taskForm.patchValue({ projectId: this.selectedProject && this.selectedProject.id });
  }
}
