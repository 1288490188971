// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { ButtonsModule, ProgressbarModule } from 'ngx-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MailModule } from '../mail/mail.module';
import { EventsModule } from '@modules/events/events.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { NotesModule } from '@modules/notes/notes.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { TopicModule } from '../topic/topic.module';
import { CalendarModule } from '../calendar/calendar.module';
import { AccountModule } from '../account/account.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KnowledgePanelModule } from './../knowledge-panel/knowledge-panel.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PopoverModule } from '../popover/popover.module';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { PagesModule } from '../pages/pages.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';

// Services
import { ModalService } from './services/modal.service';

// Directives
import { ModalHostDirective } from './directives/modal-host.directive';

// Components
import { AttachmentPreviewModalComponent } from './components/attachment-preview-modal/attachment-preview-modal.component';
import { BaseModalComponent } from './components/base-modal/base-modal.component';
import { MailContentModalComponent } from './components/mail-content-modal/mail-content-modal.component';
import { MailEditorModalComponent } from './components/mail-editor-modal/mail-editor-modal.component';
import { CalendarEventModalComponent } from './components/calendar-event-modal/calendar-event-modal.component';
import { CalendarEventFormModalComponent } from './components/calendar-event-form-modal/calendar-event-form-modal.component';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';
import { TopicsAnnotationsModalComponent } from './components/topics-annotations-modal/topics-annotations-modal.component';
import {
  ReminderDateTimePickerModalComponent
} from './components/reminder-date-time-picker-modal/reminder-date-time-picker-modal.component';
import { SelectFolderModalComponent } from './components/select-folder-modal/select-folder-modal.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AccountModalComponent } from './components/account-modal/account-modal.component';
import { TopicsListModalComponent } from './components/topics-list-modal/topics-list-modal.component';
import { ProjectFormModalComponent } from './components/project-form-modal/project-form-modal.component';
import { TaskFormModalComponent } from './components/task-form-modal/task-form-modal.component';
import { NoteFormModalComponent } from './components/note-form-modal/note-form-modal.component';
import { GroupFormModalComponent } from './components/group-form-modal/group-form-modal.component';
import { ContactFormModalComponent } from './components/contact-form-modal/contact-form-modal.component';
import { AttachmentsListModalComponent } from './components/attachments-list-modal/attachments-list-modal.component';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PdfViewerModule,
    ProgressbarModule.forRoot(),
    ButtonsModule.forRoot(),
    ContextMenuModule.forRoot(),
    MailModule,
    EventsModule,
    TasksModule,
    NotesModule,
    ContactsModule,
    UserAvatarModule,
    TopicModule,
    CalendarModule,
    ImageViewerModule.forRoot(),
    AccountModule,
    MatIconModule,
    MatTooltipModule,
    KnowledgePanelModule,
    DragDropModule,
    PopoverModule,
    AppSplitViewModule,
    DropdownModule,
    LinkedInfoModule,
    PagesModule,
    FormControlsModule,
    ElementsModule,
    PipesModule,
    AttachmentsModule,
  ],
  exports: [
    BaseModalComponent, MailEditorModalComponent, AttachmentPreviewModalComponent,
    MailContentModalComponent, TopicsAnnotationsModalComponent, ModalHostDirective, ModalWrapperComponent,
    ReminderDateTimePickerModalComponent, SelectFolderModalComponent, CalendarEventModalComponent,
    ConfirmationModalComponent, AccountModalComponent, TopicsListModalComponent, CalendarEventFormModalComponent,
    AttachmentsListModalComponent,
  ],
  declarations: [
    AttachmentPreviewModalComponent, MailEditorModalComponent, TopicsAnnotationsModalComponent,
    MailContentModalComponent, BaseModalComponent, ModalHostDirective, ModalWrapperComponent,
    ReminderDateTimePickerModalComponent, SelectFolderModalComponent, CalendarEventModalComponent,
    ConfirmationModalComponent, AccountModalComponent, TopicsListModalComponent, CalendarEventFormModalComponent,
    TaskFormModalComponent, ProjectFormModalComponent, NoteFormModalComponent, GroupFormModalComponent,
    ContactFormModalComponent, AttachmentsListModalComponent,
  ],
  entryComponents: [
    TopicsAnnotationsModalComponent, AttachmentPreviewModalComponent,
    MailEditorModalComponent, MailContentModalComponent, BaseModalComponent, ModalWrapperComponent,
    ReminderDateTimePickerModalComponent, SelectFolderModalComponent, CalendarEventModalComponent,
    ConfirmationModalComponent, AccountModalComponent, TopicsListModalComponent, CalendarEventFormModalComponent,
    TaskFormModalComponent, ProjectFormModalComponent, NoteFormModalComponent, GroupFormModalComponent,
    ContactFormModalComponent, AttachmentsListModalComponent,
  ],
  providers: [ModalService],
})
export class ModalModule { }
