// Common
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { MatTabChangeEvent } from '@angular/material';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

@Component({
  selector: 'app-tasks-panel',
  templateUrl: './tasks-panel.component.html',
  styleUrls: ['./tasks-panel.component.less']
})
export class TasksPanelComponent implements OnInit, OnDestroy {

  // Public
  public selectedDate: Date = new Date();
  public dateFromControl: FormControl = new FormControl(new Date());
  public orderOptions: {
    all: DropdownOption[],
    today: DropdownOption[],
    next7: DropdownOption[],
    projects: DropdownOption[],
    archive: DropdownOption[],
  } = {
    all: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
    ],
    today: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
    ],
    next7: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
    ],
    projects: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
    ],
    archive: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
    ],
  };
  public scrollOptions: {
    all: DropdownOption[],
    today: DropdownOption[],
    next7: DropdownOption[],
    projects: DropdownOption[],
    archive: DropdownOption[],
  } = {
    all: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
    today: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
    next7: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
    projects: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
    archive: [
      {name: 'Scroll to top', key: 'scroll-top'},
      {name: 'Scroll to bottom', key: 'scroll-bottom'},
    ],
  };
  public tabs = ['all', 'today', 'next7', 'projects', 'archive'];
  public selectedTab: number;
  public orders: {
    all: DropdownOption,
    today: DropdownOption,
    next7: DropdownOption,
    projects: DropdownOption,
    archive: DropdownOption,
  };
  public searchQuery: string;
  public quickFormValue = '';
  public upcomingScrollPosition: number;
  public archivedScrollPosition: number;
  public quickFormProject: Project = new Project();
  public quickFormTask: Task = new Task();
  public quickFormTaskToggle: Subject<void> = new Subject();
  public quickFormProjectToggle: Subject<void> = new Subject();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private knowledgePanelService: KnowledgePanelService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.getPersistedState();
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSelectTab(tab: MatTabChangeEvent) {
    this.stateService.setTabsState('kpTasks', tab.index);
  }

  handleAdd(task = new Task()) {
    this.knowledgePanelService.setFormItem(task);
  }

  handleAddProject(project = new Project()) {
    this.knowledgePanelService.setFormItem(project);
  }

  selectOrder(type: string, order: DropdownOption) {
    this.orders[type] = order;

    switch (type) {
      case 'today':
        break;
    }
  }

  getPersistedState() {
    this.stateService.getTabsState('kpTasks')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((value: number) => {
        this.selectedTab = value;
      });

    this.orders = {
      all: null,
      today: null,
      next7: null,
      projects: null,
      archive: null,
    };
  }

  selectScrollOption(type: string, scrollOption: 'scroll-top' | 'scroll-bottom') {
    switch (type) {
      case 'today':
        break;
      case 'upcoming':
        // this.upcomingScrollPosition = scrollOption === 'scroll-top' ? 0 : -1;
        break;
      case 'archived':
        // this.archivedScrollPosition = scrollOption === 'scroll-top' ? 0 : -1;
        break;
    }
  }

  handleProjectQuickFormDrop(dragData: DragData) {
    const project = Project.fromDragData(dragData);

    if (!project) { return; }

    this.quickFormProject = project;
    this.quickFormProjectToggle.next();
  }

  handleTaskQuickFormDrop(dragData: DragData) {
    const task = Task.fromDragData(dragData);

    if (!task) { return; }

    this.quickFormTask = task;
    this.quickFormTaskToggle.next();
  }
}
