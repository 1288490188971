<div class="dynamic-panel">
  <app-knowledge-header
    height="45px"
    [orders]="orderOptions"
    [titleTemplate]="titleTemplate"
  ></app-knowledge-header>
  <div class="dynamic-panel__body">
    <app-attachments-list
      [attachments]="dynamicPanelData.attachments"
      [attachmentsFormControl]="dynamicPanelData.attachmentsFormControl"
      (extendAction)="openInModalWindow()"
      [dynamicPanel]="true"
    ></app-attachments-list>
  </div>
</div>

<ng-template #titleTemplate>
  <mat-icon svgIcon="arrow-up" class="btn-back" (click)="closed()"></mat-icon>
  <div class="title">Attachments</div>
</ng-template>
