// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';

// RX
import { Subject, timer } from 'rxjs';
import { filter, switchMap, map, tap } from 'rxjs/operators';

// Types
import { Task } from '@modules/tasks/types/task';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';

@Component({
  selector: 'app-task-form-subtasks',
  templateUrl: './task-form-subtasks.component.html',
  styleUrls: ['./task-form-subtasks.component.less']
})
export class TaskFormSubtasksComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() task: Task;

  // Public
  public subtasks: Task[] = [];

  // Private
  private alive: Subject<void> = new Subject();
  private tasksUpdated: Subject<void> = new Subject();

  // Content Children
  @ViewChildren('input') items: QueryList<ElementRef>;

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService
  ) {

  }

  ngOnInit() {
    this.tasksUpdated
      .pipe(
        tap(() => this.subtasks = []),
        filter(() => !!this.task && !!this.task.id),
        switchMap(() => this.tasksService.getTasks({ parentsIds: [this.task.id] })),
        map(({ tasks }) => tasks)
      )
      .subscribe((tasks: Task[]) => {
        this.subtasks = tasks.sort((a, b) => (a.position > b.position) ? 1 : -1);
      });

    this.tasksUpdated.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('task' in changes) {
      this.tasksUpdated.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  focusLastInput() {
    timer(0).subscribe(() => {
      if (this.items.last) {
        this.items.last.nativeElement.focus();
      }
    });
  }

  handleNewTask() {
    this.subtasks.push(new Task());
    this.focusLastInput();
  }

  handleEnter() {
    this.handleNewTask();
    this.focusLastInput();
  }

  handleBlur(index: number) {
    if (!this.task || !this.task.id) { return; }

    const subtask = this.subtasks[index];

    if (!subtask || !subtask.title || subtask.title.trim() === '') { return; }

    subtask.parentId = this.task.id;
    subtask.projectId = this.task.projectId;
    subtask.position = this.subtasks.length;

    (
      subtask.id
      ? this.tasksService.update(subtask)
      : this.tasksService.create(subtask)
    )
      .subscribe(() => {});
  }
}
