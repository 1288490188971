// Common
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, Optional } from '@angular/core';

// Services
import { TopicService } from '@modules/topic/services/topic.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';

@Component({
  selector: 'app-content-menu-pellet',
  templateUrl: './content-menu-pellet.component.html',
  styleUrls: ['./content-menu-pellet.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuPelletComponent }]
})
export class ContentMenuPelletComponent extends ContentMenuBaseItemComponent {

  // Inputs
  @Input() title: string;
  @Input() active: boolean;
  @Input() unread: boolean;

  // Outputs
  @Output() selected = new EventEmitter<boolean>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => ['message', 'event', 'task', 'note'].includes(dragData.type);

  /**
   * Constructor
   */

  constructor(
    changeDetector: ChangeDetectorRef,
    private topicService: TopicService,
    private modalService: ModalService,
    @Optional() contentMenuService: ContentMenuService
  ) {
    super(changeDetector, contentMenuService);
    this.headerHeight = 24;
  }

  /**
   * Actions
   */

  select(event: MouseEvent) {
    const isMultiSelect = event.ctrlKey || event.shiftKey || event.metaKey;
    this.selected.emit(isMultiSelect);
  }

  unpinTopic(topic: string) {
    this.topicService.pinTopics([{name: topic}], false);
  }

  unpinAllTopics() {
    this.topicService.unpinAllTopics();
  }

  openTopicsModal() {
    this.modalService.topicList();
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    this.topicService.createTopics(
      [{name: this.title}],
      {
        messagesIds: dragData.type === 'message' && dragData.data ? (dragData.data as MailMessage[]).map(mail => mail.id) : [],
        eventsIds: dragData.type === 'event' && dragData.data ? (dragData.data as CalendarEvent[]).map(event => event.id) : [],
        projectsIds: dragData.type === 'project' && dragData.data ? (dragData.data as Project[]).map(project => project.id) : [],
        tasksIds: dragData.type === 'task' && dragData.data ? (dragData.data as Task[]).map(task => task.id) : [],
        notesIds: dragData.type === 'note' && dragData.data ? (dragData.data as Note[]).map(note => note.id) : []
      }
    );
  }

}
