<context-menu #contextMenu>
  <ng-template contextMenuItem [enabled]="false">Open</ng-template>
  <ng-template contextMenuItem (execute)="edit()">Edit</ng-template>
  <ng-template contextMenuItem (execute)="pin([task], task.pinned)">
    {{ task.pinned ? 'Unpin' : 'Pin' }}
  </ng-template>
  <ng-template contextMenuItem [enabled]="false">Flag</ng-template>
  <ng-template contextMenuItem [enabled]="false">Snooze</ng-template>
  <ng-template contextMenuItem [enabled]="false">Schedule</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select All</ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="false">Email task</ng-template>
  <!--  Copy to-->
  <ng-template contextMenuItem [subMenu]="copyTo">Copy to</ng-template>
  <context-menu #copyTo>
    <ng-template contextMenuItem>Today</ng-template>
    <ng-template contextMenuItem>Next 7 days</ng-template>
  </context-menu>
  <!--  Move to-->
  <ng-template contextMenuItem [subMenu]="moveTo">Move to</ng-template>
  <context-menu #moveTo>
    <ng-template contextMenuItem>Today</ng-template>
    <ng-template contextMenuItem>Next 7 days</ng-template>
  </context-menu>
  <ng-template contextMenuItem [enabled]="false">Duplicate</ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <!--  Stitch-->
  <ng-template contextMenuItem [subMenu]="stitch">Stitch</ng-template>
  <context-menu #stitch>
    <ng-template contextMenuItem>Event</ng-template>
    <ng-template contextMenuItem>Task</ng-template>
    <ng-template contextMenuItem>Note</ng-template>
    <ng-template contextMenuItem>Email</ng-template>
  </context-menu>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Threads</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Tags</ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="false">Rename Task</ng-template>
  <ng-template contextMenuItem [enabled]="false">Print Task</ng-template>
  <ng-template contextMenuItem (execute)="archive([task], task.archived)">
    {{ task.archived ? 'Restore from Archive' : 'Archive' }}
  </ng-template>
  <ng-template contextMenuItem (execute)="delete([task], task.deleted)">
    {{ task.deleted ? 'Restore from Trash' : 'Trash' }}
  </ng-template>
  <ng-template contextMenuItem [enabled]="false">Settings</ng-template>
</context-menu>
