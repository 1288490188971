// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone, Output, EventEmitter } from '@angular/core';

// RxJS
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { Note } from '@modules/notes/types/note';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.less']
})
export class NotesListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no notes';
  @Input() defaultFilters: NotesFilters = {};
  @Input() selectedItems: Note[];

  // Outputs
  @Output() selectedItemsChanged: EventEmitter<Note[]> = new EventEmitter();

  // Public
  public itemHeight = 92;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);

  /**
   * Constructor
   */

  constructor(
    private notesService: NotesService,
    private linkedInfoService: LinkedInfoService,
    private modalService: ModalService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.notesService.getRefresh(),
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Update notes
      this.notesService.createNewNote,
      this.notesService.updatedNote,
      this.notesService.deletedNote
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('defaultFilters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.notesService
      .getNotes({
        ...this.defaultFilters,
        order: this.selectedOrder.key as any,
        orderWithPinned: true,
        limit,
        offset
      })
      .pipe(
        map(({ notes, count }) => ({ items: notes, count }))
      );
  }

  resetItems() {
    this.selectedItemsChanged.emit([]);
    super.resetItems();
  }

  selectItem(note: Note, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(note, event, selectAll);
    this.selectedItemsChanged.emit(this.selectedItems);
  }

  openNoteInModal(note: Note) {
    this.modalService.noteForm(note);
  }

}
