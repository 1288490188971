// Common
import { Pipe, PipeTransform } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';

// Services
import { MailService } from '../services/mail.service';

// Types
import { MailFolder } from '../types/mail-folder';

@Pipe({
  name: 'mailFolderDetails'
})
export class MailFolderDetailsPipe implements PipeTransform {
  constructor(private mailService: MailService) { }

  transform(folder: string): Observable<MailFolder> {
    return this.mailService.getFolderDetails(folder);
  }

}
