import * as tslib_1 from "tslib";
import { warmUpObservable } from '@decorators';
// Rx
import { throwError, Subject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Section } from '../types/section';
// Env
import { environment } from '@environment';
var SectionsService = /** @class */ (function () {
    /**
     * Constructor
     */
    function SectionsService(http, asyncTasksToastsService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.sectionCreated = new Subject();
        this.sectionUpdated = new Subject();
        this.sectionDeleted = new Subject();
    }
    SectionsService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Methods
     */
    SectionsService.prototype.getSections = function (filters) {
        if (filters === void 0) { filters = {}; }
        var params = {};
        if (filters.projectsIds) {
            params['projects_ids'] = filters.projectsIds;
        }
        return this.http.get(environment.baseUrl + '/api/projects/sections', { params: params })
            .pipe(map(function (_a) {
            var sections = _a.sections;
            return ({ sections: sections.map(function (section) { return new Section(section); }) });
        }), catchError(SectionsService.handleObserverError));
    };
    SectionsService.prototype.create = function (newSection) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/projects/sections', newSection.asPayloadJSON())
            .pipe(tap(function (_a) {
            var section = _a.section, success = _a.success;
            if (success) {
                _this.sectionCreated.next();
                _this.asyncTasksToastsService.show({ text: "Section created" });
            }
        }), map(function (_a) {
            var section = _a.section;
            return new Section(section);
        }));
    };
    SectionsService.prototype.update = function (sectionInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/projects/sections/' + sectionInstance.id, sectionInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var section = _a.section, success = _a.success;
            if (success) {
                _this.sectionUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Section updated" });
            }
        }), map(function (_a) {
            var section = _a.section;
            return new Section(section);
        }));
    };
    SectionsService.prototype.reorder = function (sectionsIds) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/projects/sections', { saveOrder: true }, { params: { sections_ids: sectionsIds } })
            .pipe(tap(function (_a) {
            var success = _a.success;
            if (success) {
                _this.sectionUpdated.next();
            }
        }), map(function (_a) {
            var tasks = _a.tasks;
            return tasks.map(function (task) { return new Section(task); });
        }));
    };
    SectionsService.prototype.pin = function (sectionIds, status) {
        var _this = this;
        return forkJoin(sectionIds.map(function (sectionId) { return _this.http.put(environment.baseUrl + '/api/projects/sections/' + sectionId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.section; }); }), tap(function (success) {
            if (success) {
                _this.sectionUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Section" + (sectionIds.length > 1 ? 's' : '') + " " + (sectionIds.length > 1 ? 'are' : 'is') + " " + (status ? '' : 'un') + "pinned" });
            }
        }));
    };
    SectionsService.prototype.archive = function (sectionIds, archived) {
        var _this = this;
        return forkJoin(sectionIds.map(function (sectionId) { return _this.http.put(environment.baseUrl + '/api/projects/sections/' + sectionId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.section; }); }), tap(function (success) {
            if (success) {
                _this.sectionUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Section(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    SectionsService.prototype.deletePermanently = function (sectionIds) {
        var _this = this;
        return forkJoin(sectionIds.map(function (sectionId) { return _this.http.delete(environment.baseUrl + '/api/projects/sections/' + sectionId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.sectionDeleted.next();
                _this.asyncTasksToastsService.show({ text: "Section(s) successfully deleted." });
            }
        }));
    };
    SectionsService.prototype.delete = function (sectionIds, deleted) {
        var _this = this;
        return forkJoin(sectionIds.map(function (sectionId) { return _this.http.put(environment.baseUrl + '/api/projects/sections/' + sectionId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.sectionUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Section(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "create", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "update", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "reorder", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "pin", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "archive", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "deletePermanently", null);
    tslib_1.__decorate([
        warmUpObservable
    ], SectionsService.prototype, "delete", null);
    return SectionsService;
}());
export { SectionsService };
