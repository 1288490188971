import * as tslib_1 from "tslib";
// RxJS
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Decorators
import { warmUpObservable } from '@decorators';
import { UpdateType } from '@modules/common/types/update-type';
// Env
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../async-tasks/services/async-tasks-toasts.service";
var TagService = /** @class */ (function () {
    /**
     * Constructor
     */
    function TagService(http, asyncTasksToastsService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        // Private
        this.tagsUpdated = new Subject();
    }
    /**
     * Static methods
     */
    TagService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Helpers
     */
    TagService.prototype.formatFilters = function (filters) {
        var formatedFilters = {};
        if ('pinned' in filters) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if ('orderWithPinned' in filters) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.order) {
            formatedFilters['order'] = filters.order;
        }
        if (filters.limit) {
            formatedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formatedFilters['offset'] = filters.offset + '';
        }
        return formatedFilters;
    };
    /**
     * Methods
     */
    TagService.prototype.getTagsUpdate = function () {
        return this.tagsUpdated.asObservable();
    };
    TagService.prototype.getTags = function (filters) {
        return this.http.get(environment.baseUrl + "/api/tags", { params: this.formatFilters(filters) })
            .pipe(catchError(TagService.handleObserverError));
    };
    TagService.prototype.createTags = function (tags, items) {
        var _this = this;
        if (items === void 0) { items = {}; }
        return this.http.post(environment.baseUrl + "/api/tags", {
            tags: tags,
            messagesIds: items.messagesIds,
            eventsIds: items.eventsIds,
            tasksIds: items.tasksIds,
            notesIds: items.notesIds,
            projectsIds: items.projectsIds,
            contactsIds: items.contactsIds,
            groupsIds: items.groupsIds,
        })
            .pipe(switchMap(function (_a) {
            var asyncTask = _a.asyncTask;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: 'Creating new Tag(s).',
                        icon: 'tags'
                    },
                    completed: {
                        text: 'New Tag(s) added.',
                        icon: 'tags'
                    },
                    error: {
                        text: "Error while creating new Tag(s). Please try again.",
                        icon: 'tags'
                    }
                }
            });
        }), tap(function () { return _this.tagsUpdated.next(UpdateType.Create); }), catchError(TagService.handleObserverError));
    };
    TagService.prototype.deleteTags = function (tags, messagesIds, eventsIds, projectsIds, tasksIds, notesIds, contactsIds, groupsIds) {
        var _this = this;
        return this.http.request('DELETE', environment.baseUrl + "/api/tags", {
            body: {
                tags: tags,
                messagesIds: messagesIds,
                eventsIds: eventsIds,
                tasksIds: tasksIds,
                projectsIds: projectsIds,
                notesIds: notesIds,
                groupsIds: groupsIds,
                contactsIds: contactsIds
            }
        })
            .pipe(switchMap(function (_a) {
            var asyncTask = _a.asyncTask;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: "Deleting Tag(s).",
                        icon: 'tags'
                    },
                    completed: {
                        text: 'Tag(s) deleted.',
                        icon: 'tags'
                    },
                    error: {
                        text: "Error while deleting Tag(s). Please try again.",
                        icon: 'tags'
                    }
                }
            });
        }), tap(function () { return _this.tagsUpdated.next(UpdateType.Delete); }), catchError(TagService.handleObserverError));
    };
    TagService.prototype.pinTags = function (tags, pinned) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/tags/pinned", { tags: tags, pinned: pinned })
            .pipe(tap(function (_a) {
            var success = _a.success;
            _this.asyncTasksToastsService.show({
                text: success
                    ? "Tag(s) " + (pinned ? 'pinned' : 'unpinned') + "."
                    : "Error while pinning tag(s). Please try again.",
                icon: 'tags'
            });
            _this.tagsUpdated.next(UpdateType.Pin);
        }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(TagService.handleObserverError));
    };
    TagService.prototype.unpinAllTags = function () {
        var _this = this;
        var filters = {
            pinned: true,
            limit: 0,
            offset: 0
        };
        return this.getTags(filters)
            .pipe(tap(function (response) { return filters.limit = response.count; }), switchMap(function () { return _this.getTags(filters); }), map(function (_a) {
            var tags = _a.tags;
            return tags.map(function (tag) { return ({ name: tag.name }); });
        }), switchMap(function (tags) { return _this.pinTags(tags, false); }), catchError(TagService.handleObserverError));
    };
    TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AsyncTasksToastsService)); }, token: TagService, providedIn: "root" });
    tslib_1.__decorate([
        warmUpObservable
    ], TagService.prototype, "createTags", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TagService.prototype, "deleteTags", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TagService.prototype, "pinTags", null);
    tslib_1.__decorate([
        warmUpObservable
    ], TagService.prototype, "unpinAllTags", null);
    return TagService;
}());
export { TagService };
