import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { ContactContextMenuComponent } from '../components/contact-context-menu/contact-context-menu.component';
var ContactContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ContactContextMenuDirective, _super);
    function ContactContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = ContactContextMenuComponent;
        return _this;
    }
    ContactContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.contact = this.appContactContextMenu;
    };
    return ContactContextMenuDirective;
}(ContextMenuDirective));
export { ContactContextMenuDirective };
