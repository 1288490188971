import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoEventListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoEventListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoEventListComponent(modalService, calendarService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.calendarService = calendarService;
        _this.ngZone = ngZone;
        _this.type = 'event';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoEventListComponent.prototype.getItems = function (ids) {
        return this.calendarService.getEvents({ eventsIds: ids, limit: ids.length })
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.events; }));
    };
    /**
     * Actions
     */
    LinkedInfoEventListComponent.prototype.select = function (event) {
        this.modalService.calendarEventForm(event);
    };
    return LinkedInfoEventListComponent;
}(LinkInfoPopover));
export { LinkedInfoEventListComponent };
