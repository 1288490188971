import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { TaskComponent } from '@modules/elements/components/task/task.component';
var LinkInfoTaskComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoTaskComponent, _super);
    function LinkInfoTaskComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shortView = false;
        // Outputs
        _this.unlink = new EventEmitter();
        return _this;
    }
    // Actions
    LinkInfoTaskComponent.prototype.unlinkItem = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.unlink.emit(this.linkedInfo);
    };
    LinkInfoTaskComponent.prototype.openTask = function () {
        if (this.task) {
            this.modalService.taskForm(this.task);
        }
    };
    return LinkInfoTaskComponent;
}(TaskComponent));
export { LinkInfoTaskComponent };
