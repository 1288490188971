<div class="linked-info-popover">
  <div class="header" [class.shadow]="showHeaderShadow">
    <div class="title">Groups</div>
    <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  </div>
  <div class="list" #scrollList>
    <app-group
      *ngFor="let group of items"
      [group]="group"
      (click)="select(group)"
    ></app-group>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>
