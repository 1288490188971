// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { GoogleAnalyticsService } from './services/google-analytics.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [GoogleAnalyticsService]
})
export class AnalyticsModule { }
