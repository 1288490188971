<div class="notification" *ngFor="let notification of inputFormArray.controls; let i = index">
  <div class="notification-field">
    <app-dropdown-select placeholder="Notification" [inputFormControl]="notification.get('type')" [options]="notificationByOptions"></app-dropdown-select>
  </div>
  <div class="notification-field">
    <app-input [inputFormControl]="notification.get('duration')" width="35px"></app-input>
  </div>
  <div class="notification-field">
    <app-dropdown-select placeholder="Time" [inputFormControl]="notification.get('units')"  [options]="notificationByTimeOptions"></app-dropdown-select>
  </div>
  <div class="notification-field" >
    <mat-icon class="notification-field-close-icon" svgIcon="close" (click)="removeNotification(i)"></mat-icon>
  </div>
</div>

<div class="notification">
  <span class="notification-text-button" (click)="addNotification()"> + Add Notification </span>
</div>
