<!-- Single event -->
<context-menu #singleItemMenu>
  <ng-template contextMenuItem (execute)="pinEvents([event])" [visible]="!event?.pinned"> Pin </ng-template>
  <ng-template contextMenuItem (execute)="unpinEvents([event])" [visible]="event?.pinned"> Unpin </ng-template>
  <ng-template contextMenuItem (execute)="editEvent(event)"> Edit </ng-template>
  <ng-template contextMenuItem (execute)="printEvents([event])" [enabled]="false"> Print </ng-template>
  <ng-template contextMenuItem (execute)="deleteEvents([event])" [visible]="!event?.deleted" [enabled]="!event?.readOnly"> Delete </ng-template>
  <ng-template contextMenuItem (execute)="restoreFromTrashEvents([event])" [visible]="event?.deleted"> Restore from Trash </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="cutEvents()" [enabled]="!event?.readOnly">Cut</ng-template>
  <ng-template contextMenuItem (execute)="copyEvents()" [enabled]="!event?.readOnly">Copy</ng-template>
  <ng-template contextMenuItem (execute)="pasteEvents()" [enabled]="false"> Paste </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <!-- Link-->
  <ng-template contextMenuItem [subMenu]="addMenu"> Link to </ng-template>
  <context-menu #addMenu>
    <ng-template contextMenuItem [subMenu]="linkMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [enabled]="false">Task</ng-template>
    <ng-template contextMenuItem [enabled]="false">Note</ng-template>
  </context-menu>
  <context-menu #linkMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions
        appearance="context-menu"
        [linkedInfo]="[{type: 'event', id: event?.id}]"
        (close)="closeContextMenu()"
      ></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions
        appearance="context-menu"
        [linkedInfo]="[{type: 'event', id: event?.id}]"
        (close)="closeContextMenu()"
      ></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>

  <!-- Move -->
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="moveToArchiveEvents([event])" [visible]="!event?.archived"> Move to Archive </ng-template>
  <ng-template contextMenuItem (execute)="restoreFromArchiveEvents([event])" [visible]="event?.archived"> Restore from Archive </ng-template>
  <ng-template contextMenuItem (execute)="deletePermanentlyEvents([event])" [visible]="event?.deleted" [enabled]="!event?.readOnly"> Delete Permanently </ng-template>
</context-menu>

<!-- Multiple events -->
<context-menu #multipleItemsMenu>
  <ng-template contextMenuItem [visible]="!allEventsPinned" (execute)="pinEvents(selectedEvents)"> Pin Events </ng-template>
  <ng-template contextMenuItem [visible]="allEventsPinned" (execute)="unpinEvents(selectedEvents)"> Unpin events </ng-template>
  <ng-template contextMenuItem (execute)="printEvents(selectedEvents)" [enabled]="false"> Print </ng-template>
  <ng-template contextMenuItem [visible]="!allEventsDeleted" (execute)="deleteEvents(selectedEvents)"> Delete </ng-template>
  <ng-template contextMenuItem [visible]="allEventsDeleted" (execute)="restoreFromTrashEvents(selectedEvents)" > Restore from Trash </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="cutEvents()" [enabled]="!event?.readOnly">Cut</ng-template>
  <ng-template contextMenuItem (execute)="copyEvents()" [enabled]="!event?.readOnly">Copy</ng-template>
  <ng-template contextMenuItem (execute)="pasteEvents()" [enabled]="false"> Paste </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>
  <!-- Link-->
  <ng-template contextMenuItem (execute)="linkEvents()">
    Link each other
  </ng-template>
  <ng-template contextMenuItem [subMenu]="addMenu">Link to </ng-template>
  <context-menu #addMenu>
    <ng-template contextMenuItem [subMenu]="linkMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [enabled]="false">Task</ng-template>
    <ng-template contextMenuItem [enabled]="false">Note</ng-template>
  </context-menu>
  <context-menu #linkMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions
        appearance="context-menu"
        [linkedInfo]="linkedInfo"
        (close)="closeContextMenu()"
      ></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions
        appearance="context-menu"
        [linkedInfo]="linkedInfo"
        (close)="closeContextMenu()"
      ></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>

  <!-- Move -->
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [visible]="!allEventsArchived" (execute)="moveToArchiveEvents(selectedEvents)"> Move to Archive </ng-template>
  <ng-template contextMenuItem [visible]="allEventsArchived" (execute)="restoreFromArchiveEvents(selectedEvents)"> Restore from Archive </ng-template>
  <ng-template contextMenuItem [visible]="allEventsDeleted" (execute)="deletePermanentlyEvents(selectedEvents)"> Delete Permanently </ng-template>
</context-menu>
