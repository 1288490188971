// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { LayoutStateService } from '@modules/pages/services/layout-state.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';

// Types
import { TasksMainView } from '@modules/tasks/types/tasks-main-view';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tasks-main-view',
  templateUrl: './tasks-main-view.component.html',
  styleUrls: ['./tasks-main-view.component.less'],
})
export class TasksMainViewComponent implements OnInit, OnDestroy {

  // Public
  public view: TasksMainView;
  public task: Task;
  public project: Project;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private tasksStateService: TasksStateService,
    private layoutStateService: LayoutStateService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.tasksStateService.getMainView()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((view: TasksMainView) => {
        this.view = view;
        switch (this.view) {
          case 'empty':
            this.layoutStateService.setLinkedInfoToolbarPresence(false);
            break;
          case 'task-form':
          case 'project-form':
            this.layoutStateService.setLinkedInfoToolbarPresence(true);
            break;
        }
      });

    this.tasksStateService.getMainViewTask()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((task: Task) => this.task = task);

    this.tasksStateService.getMainViewProject()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((project: Project) => this.project = project);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.layoutStateService.setLinkedInfoToolbarPresence(false);
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.tasksStateService.setMainView('empty');
  }
}
