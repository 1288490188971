// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { AssignPipe } from './pipes/assign.pipe';
import { CheckDatePipe } from './pipes/check-date.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DifferentDayPipe } from './pipes/different-day.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { IncludesByPipe } from './pipes/includes-by.pipe';
import { IncludesNowPipe } from './pipes/includes-now.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { RoundToK } from './pipes/round-to-k.pipe';
import { SameDayPipe } from './pipes/same-day.pipe';
import { SnoozedDatePipe } from './pipes/snoozed-date.pipe';
import { UserNamesPipe } from './pipes/user-names.pipe';
import { DateByDayIndexPipe } from '@modules/pipes/pipes/date-by-day-index.pipe';
import { JoinPipe } from './pipes/join.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AssignPipe,
    CheckDatePipe, DateTimePipe, DifferentDayPipe, FileSizePipe, IncludesByPipe, IncludesNowPipe,
    RelativeDatePipe, RoundToK, SameDayPipe, SnoozedDatePipe, UserNamesPipe, RelativeDatePipe,
    DateByDayIndexPipe, JoinPipe,
  ],
  exports: [
    AssignPipe,
    CheckDatePipe, DateTimePipe, DifferentDayPipe, FileSizePipe, IncludesByPipe, IncludesNowPipe,
    RelativeDatePipe, RoundToK, SameDayPipe, SnoozedDatePipe, UserNamesPipe,
    DateByDayIndexPipe, JoinPipe,
  ],
  providers: [
    AssignPipe,
    CheckDatePipe, DateTimePipe, DifferentDayPipe, FileSizePipe, IncludesByPipe, IncludesNowPipe,
    RelativeDatePipe, RoundToK, SameDayPipe, SnoozedDatePipe, UserNamesPipe,
  ],
})
export class PipesModule { }
