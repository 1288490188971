// Common
import { Component, OnInit, OnDestroy } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';

// RxJS
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { Note } from '@modules/notes/types/note';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

/**
 * Type Aliases
 */
class Tab {
  title: string;
  selected: boolean;
  filters: NotesFilters;
}

@Component({
  selector: 'app-notes-panel',
  templateUrl: './notes-panel.component.html',
  styleUrls: ['./notes-panel.component.less']
})
export class NotesPanelComponent implements OnInit, OnDestroy {

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public quickFormExpanded = false;
  public notesFilters: NotesFilters = {};
  public searchFilters: NotesFilters = {};
  public listFilters: NotesFilters = {};
  public loading: Boolean;
  public today = new Date();
  public week = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 7, 0, 0, 0);
  public tabs: Tab[] = [
    {title: 'All', selected: true, filters: {}},
    {title: 'Today', selected: false, filters: {fromTime: startOfDay(this.today), toTime: endOfDay(this.today)}},
    {title: 'Next 7 Days', selected: false, filters: {fromTime: startOfDay(this.today), toTime: endOfDay(this.week)}},
    {title: 'Notebook', selected: false, filters: {}},
    {title: 'Archive', selected: false, filters: {archived: true}},
  ];
  public quickFormNote: Note = new Note();
  public quickFormToggle: Subject<void> = new Subject();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private searchTitle = new BehaviorSubject('');

  /**
   * Constructor
   */

  constructor() {
    this.searchTitle
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((title) => {
        this.searchFilters = {
          keywords: !title || title.trim() === '' ? [] : [title]
        };
        this.updateListFilters();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  updateListFilters() {
    this.listFilters = {
      ...this.notesFilters,
      ...this.searchFilters
    };
  }

  /**
   * Actions
   */

  handleScrollOptionChange(value: number) {
    this.listScrollPosition = null;
    setTimeout(() => this.listScrollPosition = value);
  }

  handleNewNote(note = new Note()) {
  }

  handleSearchQueryChange(title: string) {
    this.searchTitle.next(title);
  }

  selectTab(tab: Tab) {
    this.tabs.forEach(item => {
      item.selected = false;
    });
    tab.selected = true;
    this.notesFilters = tab.filters;
    this.updateListFilters();
  }

  handleQuickFormDrop(dragData: DragData) {
    const note = Note.fromDragData(dragData);

    if (!note) { return; }

    this.quickFormNote = note;
    this.quickFormToggle.next();
  }
}
