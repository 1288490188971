import * as tslib_1 from "tslib";
// Common
import { OnInit, OnChanges, EventEmitter, SimpleChanges, OnDestroy, NgZone } from '@angular/core';
// RxJS
import { Subject, BehaviorSubject, interval, merge } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';
// Services
import { MailService } from '../../services/mail.service';
// Base Component
import { VirtualScrollListComponent } from '@modules/virtual-scroll/classes/virtual-scroll-list';
import { MatTooltipDefaultOptions } from '@angular/material';
import { DynamicSizeMailMessage } from '@modules/mail/types/dynamic-size-mail-message';
// Env
import { environment } from '@environment';
/** Custom options the configure the tooltip's default show/hide delays. */
export var tooltipDefaults = {
    showDelay: 600,
    hideDelay: 200,
    touchendHideDelay: 1000,
};
var MessagesListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MessagesListComponent, _super);
    /**
     * Constructor
     */
    function MessagesListComponent(mailService, ga, modalService, linkedInfoService, messagesListStateService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.mailService = mailService;
        _this.ga = ga;
        _this.modalService = modalService;
        _this.linkedInfoService = linkedInfoService;
        _this.messagesListStateService = messagesListStateService;
        _this.ngZone = ngZone;
        // Inputs
        _this.selectedMessages = [];
        _this.folderDropdown = false;
        _this.header = true;
        // Outputs
        _this.selectedMessagesChange = new EventEmitter();
        // Public
        _this.extendedFiltering = false;
        _this.orders = [
            { name: 'Date', key: 'date' },
            { name: 'Thread', key: 'thread' },
            { name: 'Sender', key: 'sender' },
            { name: 'Attachments', key: 'attachments' },
            { name: 'Unread', key: 'unread' }
        ];
        _this.selectedOrder = { name: 'Date', key: 'date' };
        _this.extendedFilteringMatchTypes = [
            { name: 'Exact Match', key: 'phrase' },
            { name: 'Partial Match', key: 'match' },
            { name: 'Word Overlap', key: 'ngram' },
        ];
        _this.orderOprions = [
            { name: 'Relevance', key: 'relevance' }
        ];
        _this.expandedMessagesId = [];
        _this.showCountView = new BehaviorSubject(true);
        _this.dropdownClose = new Subject();
        _this.maxDate = new Date();
        _this.popoverClose = new Subject();
        return _this;
    }
    /**
     * Component lifecycle
     */
    MessagesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Receiving folders for further title displaying
        this.mailService.getFolders()
            .pipe(takeUntil(this.alive))
            .subscribe(function (folders) {
            _this.folders = folders;
            if (_this.filters && _this.filters.folder) {
                _this.folderDetails = MailService.findFolder(_this.folders, _this.filters.folder);
                _this.showCountView.next(true);
            }
        });
        this.showCountView
            .pipe(filter(function (value) { return !!value; }), debounceTime(5000), takeUntil(this.alive))
            .subscribe(function () {
            _this.showCountView.next(false);
        });
        merge(
        // Subscribe for global refresh all messages button
        this.mailService.refreshAllMails$, 
        // Subscribe for global notification of message moved
        this.mailService.onMoveToFolder, this.mailService.onSentMessage, 
        // Subscribe for linked info updates
        this.linkedInfoService.getLinkedInfoUpdate(), 
        // Make automatic updates for new messages
        interval(environment.messageFetchInterval)
            .pipe(filter(function () {
            return !_this.loading
                && _this.selectedOrder && _this.selectedOrder.key === 'date'
                && !_this.extendedFiltering;
        })))
            .pipe(takeUntil(this.alive))
            .subscribe(function () { return _this.refreshCurrentItems(); });
        this.messagesListStateService.getFilters()
            .pipe(takeUntil(this.alive))
            .subscribe(function (filters) {
            // Prepare filters list for messages
            _this.filters = {
                folder: filters.folder,
                orderWithPinned: filters.orderWithPinned,
                topics: filters.topics,
                tags: filters.tags,
                contacts: filters.contacts,
                keywords: filters.keywords,
                relatedTopics: filters.relatedTopics,
                relatedContacts: filters.relatedContacts,
                relatedMessageId: filters.relatedMessageId,
                relatedMessageTopics: filters.relatedMessageTopics,
                fromTime: filters.fromTime,
                toTime: filters.toTime,
                groupBy: filters.groupByThread ? 'thread' : null,
                relevance: filters.orderWithRelevance,
                matchingTypes: filters.matchingTypes
            };
            // Set current folder details
            if (_this.folders) {
                _this.folderDetails = MailService.findFolder(_this.folders, _this.filters.folder);
            }
            // Set selected dropdown properties
            _this.selectedOrder = _this.orders.find(function (option) { return option.key === filters.order; }) || { name: '', key: filters.order };
            _this.selectedOrderOptions = filters.orderWithRelevance ? _this.orderOprions : [];
            _this.selectedExtendedFilteringMatchTypes =
                _this.extendedFilteringMatchTypes.filter(function (option) { return filters.matchingTypes && filters.matchingTypes.includes(option.key); });
            // Set extanded filtering flag to show proper sorting
            _this.extendedFiltering = !!((filters.topics && filters.topics.length) ||
                (filters.tags && filters.tags.length) ||
                (filters.contacts && filters.contacts.length) ||
                (filters.keywords && filters.keywords.length) ||
                (filters.relatedTopics && filters.relatedTopics.length) ||
                (filters.relatedContacts && filters.relatedContacts.length) ||
                (filters.relatedMessageId) ||
                (filters.relatedMessageTopics && filters.relatedMessageTopics.length));
            _this.resetItems();
        });
        _super.prototype.ngOnInit.call(this);
    };
    MessagesListComponent.prototype.ngOnChanges = function (changes) {
        if ('selectedMessages' in changes) {
            this.selectedItems = (this.selectedMessages || []).map(this.itemFactory);
        }
    };
    MessagesListComponent.prototype.ngOnDestroy = function () {
        this.popoverClose.next();
        this.popoverClose.complete();
        this.dropdownClose.next();
        this.dropdownClose.complete();
        _super.prototype.ngOnDestroy.call(this);
    };
    /**
     * Base Methods override
     */
    MessagesListComponent.prototype.getItems = function (offset, limit) {
        var _this = this;
        return this.mailService
            .getMessages(this.filters, this.selectedOrder.key, limit, offset)
            .pipe(map(function (_a) {
            var messages = _a.messages, count = _a.count;
            return ({
                items: messages.map(_this.itemFactory),
                count: count
            });
        }));
    };
    MessagesListComponent.prototype.itemFactory = function (message) {
        return new DynamicSizeMailMessage(message);
    };
    MessagesListComponent.prototype.resetItems = function () {
        this.expandedMessagesId = [];
        this.selectedItems = [];
        this.selectedMessagesChange.emit([]);
        _super.prototype.resetItems.call(this);
    };
    MessagesListComponent.prototype.selectItem = function (item, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        if (!item || !item.data) {
            return;
        }
        if (item.data.isDraft && !item.data.sending) {
            // Drafts are handled in custom way
            return this.modalService.compose(item.data);
        }
        if (item.data.unread && (!this.selectedItems.length || !(event.ctrlKey || event.shiftKey || event.metaKey))) {
            this.mailService.updateMessagesUnreadStatus({ messagesIds: [item.data.id] }, !item.data.unread);
        }
        _super.prototype.selectItem.call(this, item, event, selectAll);
        this.selectMessages(this.selectedItems.map(function (selectedItem) { return selectedItem.data; }));
    };
    /**
     * Actions
     */
    MessagesListComponent.prototype.openMessageModal = function (message) {
        if (message.isDraft &&
            !(message.sending && (message.sending.status === 'sending' || message.sending.status === 'pending'))) {
            return;
        }
        this.ga.trackEvent('mail-messages-list', 'open-email');
        this.modalService.mailContent(message);
    };
    // Used for output from child component, which can manipulate the selection as well
    MessagesListComponent.prototype.selectMessages = function (messages) {
        var e_1, _a;
        var _this = this;
        this.selectedItems = [];
        this.selectedMessages = [];
        var _loop_1 = function (message) {
            var selectedItem = this_1.items.find(function (item) { return _this.compareItems(item, _this.itemFactory(message)); });
            if (this_1.selectedItems) {
                this_1.selectedItems.push(selectedItem);
                this_1.selectedMessages.push(selectedItem.data);
            }
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values((messages || [])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var message = _c.value;
                _loop_1(message);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.selectedMessagesChange.emit(this.selectedMessages);
    };
    MessagesListComponent.prototype.selectFolder = function (folder) {
        this.messagesListStateService.setFilters({ folder: folder.id });
    };
    MessagesListComponent.prototype.selectOrder = function (order) {
        this.messagesListStateService.setFilters({ order: order.key });
    };
    MessagesListComponent.prototype.selectSearchMatchType = function (matchTypes) {
        this.messagesListStateService.setFilters({ matchingTypes: matchTypes.map(function (matchingType) { return matchingType.key; }) });
    };
    MessagesListComponent.prototype.selectOrderOption = function (options) {
        this.messagesListStateService.setFilters({ orderWithRelevance: !!options.length });
    };
    MessagesListComponent.prototype.scrollToDate = function (pagesDate) {
        var _this = this;
        this.popoverClose.next();
        this.dropdownClose.next();
        var date = new Date(pagesDate);
        date.setHours(0, 0, 0, 0);
        date.setHours(+24);
        this.mailService.getMessagesOffset(tslib_1.__assign({}, this.filters, { fromTime: date }), this.selectedOrder.key)
            .pipe(takeUntil(this.alive))
            .subscribe(function (res) {
            _this.scrollToIndex(res.offset);
        });
    };
    MessagesListComponent.prototype.scrollToLetter = function (letter) {
        var _this = this;
        this.mailService.getMessagesOffset(tslib_1.__assign({}, this.filters, { firstSymbol: letter }), this.selectedOrder.key)
            .pipe(takeUntil(this.alive))
            .subscribe(function (res) {
            _this.scrollToIndex(res.offset);
        });
    };
    MessagesListComponent.prototype.toggleMail = function (messageId) {
        var index = this.expandedMessagesId.findIndex(function (msgId) { return msgId === messageId; });
        if (index === -1) {
            this.expandedMessagesId.push(messageId);
        }
        else {
            this.expandedMessagesId.splice(index, 1);
        }
    };
    MessagesListComponent.prototype.isExpandedThread = function (messageId) {
        return this.expandedMessagesId.findIndex(function (msgId) { return msgId === messageId; }) !== -1 ? true : false;
    };
    return MessagesListComponent;
}(VirtualScrollListComponent));
export { MessagesListComponent };
