/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-agenda.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../events-agenda-event/events-agenda-event.component.ngfactory";
import * as i3 from "../events-agenda-event/events-agenda-event.component";
import * as i4 from "../../../knowledge-panel/services/knowledge-panel.service";
import * as i5 from "../../../modal/services/modal.service";
import * as i6 from "@angular/common";
import * as i7 from "../../directives/events-agenda-context-menu-directive/events-agenda-context-menu.directive";
import * as i8 from "../../../common/components/knowledge-header/knowledge-header.component.ngfactory";
import * as i9 from "../../../common/components/knowledge-header/knowledge-header.component";
import * as i10 from "../../../quick-forms/components/quick-form/quick-form.component.ngfactory";
import * as i11 from "../../../quick-forms/components/quick-form/quick-form.component";
import * as i12 from "../../../quick-forms/components/event-quick-form/event-quick-form.component.ngfactory";
import * as i13 from "../../../quick-forms/components/event-quick-form/event-quick-form.component";
import * as i14 from "../../services/calendar.service";
import * as i15 from "../../../quick-forms/components/task-quick-form/task-quick-form.component.ngfactory";
import * as i16 from "../../../quick-forms/components/task-quick-form/task-quick-form.component";
import * as i17 from "../../../tasks/services/tasks.service";
import * as i18 from "../../../tasks/services/tasks-state.service";
import * as i19 from "../../../quick-forms/components/note-quick-form/note-quick-form.component.ngfactory";
import * as i20 from "../../../quick-forms/components/note-quick-form/note-quick-form.component";
import * as i21 from "../../../notes/services/notes.service";
import * as i22 from "../../../quick-forms/components/project-quick-form/project-quick-form.component.ngfactory";
import * as i23 from "../../../quick-forms/components/project-quick-form/project-quick-form.component";
import * as i24 from "../../../tasks/services/projects.service";
import * as i25 from "./events-agenda.component";
import * as i26 from "../../../settings/services/state.service";
import * as i27 from "../../services/events-state.service";
var styles_EventsAgendaComponent = [i0.styles];
var RenderType_EventsAgendaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsAgendaComponent, data: { "animation": [{ type: 7, name: "dateAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: { type: 8, animation: [{ type: 6, styles: { opacity: 0, transform: "translateX({{offsetStart}}%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateX({{offsetFinish}}%)" }, offset: null }, timings: "{{ duration }} ease-out" }], options: { params: { duration: "300ms", offsetStart: -5, offsetFinish: 0 } } }, options: null }], options: {} }] } });
export { RenderType_EventsAgendaComponent as RenderType_EventsAgendaComponent };
function View_EventsAgendaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events-agenda-event", [], null, null, null, i2.View_EventsAgendaEventComponent_0, i2.RenderType_EventsAgendaEventComponent)), i1.ɵdid(1, 49152, null, 0, i3.EventsAgendaEventComponent, [i4.KnowledgePanelService, i5.ModalService], { event: [0, "event"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EventsAgendaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "header-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleHeaderTitleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header-title-day-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "header-title-day"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.viewDate, "d")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.viewDate, "EEEE")); _ck(_v, 5, 0, currVal_1); }); }
export function View_EventsAgendaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "agenda"]], [[24, "@dateAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 4, "div", [["appEventsAgendaContextMenu", ""], ["class", "agenda-header"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i7.EventsAgendaContextMenuDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ElementRef, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-knowledge-header", [["class", "agenda-header-content"], ["height", "38px"]], null, [[null, "selectedChange"], [null, "searchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.selectOrder($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchChange" === en)) {
        var pd_1 = (_co.handleSearchChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_KnowledgeHeaderComponent_0, i8.RenderType_KnowledgeHeaderComponent)), i1.ɵdid(5, 245760, null, 0, i9.KnowledgeHeaderComponent, [], { titleTemplate: [0, "titleTemplate"], orders: [1, "orders"], selected: [2, "selected"], search: [3, "search"], height: [4, "height"] }, { selectedChange: "selectedChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "agenda-quick-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 15, "app-quick-form", [["bundledGroup", "eventsAgendaQuickForm"], ["placeholder", "Add Event, Task or Reminder"]], null, [[null, "clickMore"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickMore" === en)) {
        var pd_0 = (_co.handleNewEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_QuickFormComponent_0, i10.RenderType_QuickFormComponent)), i1.ɵdid(9, 4964352, null, 6, i11.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], smart: [2, "smart"] }, { clickMore: "clickMore" }), i1.ɵqud(335544320, 1, { eventForm: 0 }), i1.ɵqud(335544320, 2, { projectForm: 0 }), i1.ɵqud(335544320, 3, { taskForm: 0 }), i1.ɵqud(335544320, 4, { noteForm: 0 }), i1.ɵqud(335544320, 5, { contactForm: 0 }), i1.ɵqud(335544320, 6, { groupForm: 0 }), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "app-event-quick-form", [["bundledGroup", "eventsAgendaQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_EventQuickFormComponent_0, i12.RenderType_EventQuickFormComponent)), i1.ɵdid(17, 770048, [[1, 4]], 0, i13.EventQuickFormComponent, [i14.CalendarService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(18, 0, null, 1, 1, "app-task-quick-form", [["bundledGroup", "eventsAgendaQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewTask($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_TaskQuickFormComponent_0, i15.RenderType_TaskQuickFormComponent)), i1.ɵdid(19, 704512, [[3, 4]], 0, i16.TaskQuickFormComponent, [i17.TasksService, i18.TasksStateService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(20, 0, null, 3, 1, "app-note-quick-form", [["bundledGroup", "eventsAgendaQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewNote($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_NoteQuickFormComponent_0, i19.RenderType_NoteQuickFormComponent)), i1.ɵdid(21, 573440, [[4, 4]], 0, i20.NoteQuickFormComponent, [i21.NotesService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(22, 0, null, 2, 1, "app-project-quick-form", [["bundledGroup", "eventsAgendaQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewProject($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_ProjectQuickFormComponent_0, i22.RenderType_ProjectQuickFormComponent)), i1.ɵdid(23, 573440, [[2, 4]], 0, i23.ProjectQuickFormComponent, [i24.ProjectsService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "agenda-scroll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "agenda-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsAgendaComponent_1)), i1.ɵdid(27, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["headerTitleTemplate", 2]], null, 0, null, View_EventsAgendaComponent_2))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = i1.ɵnov(_v, 28); var currVal_2 = _co.orderOptions; var currVal_3 = _co.selectedOrder; var currVal_4 = true; var currVal_5 = "38px"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "Add Event, Task or Reminder"; var currVal_7 = "eventsAgendaQuickForm"; var currVal_8 = true; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = "eventsAgendaQuickForm"; _ck(_v, 17, 0, currVal_9); var currVal_10 = "eventsAgendaQuickForm"; _ck(_v, 19, 0, currVal_10); var currVal_11 = "eventsAgendaQuickForm"; _ck(_v, 21, 0, currVal_11); var currVal_12 = "eventsAgendaQuickForm"; _ck(_v, 23, 0, currVal_12); var currVal_13 = _co.events; _ck(_v, 27, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; _ck(_v, 1, 0, currVal_0); }); }
export function View_EventsAgendaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events-agenda", [], null, null, null, View_EventsAgendaComponent_0, RenderType_EventsAgendaComponent)), i1.ɵdid(1, 770048, null, 0, i25.EventsAgendaComponent, [i26.StateService, i27.EventsStateService, i14.CalendarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventsAgendaComponentNgFactory = i1.ɵccf("app-events-agenda", i25.EventsAgendaComponent, View_EventsAgendaComponent_Host_0, { viewDate: "viewDate" }, { headerDateClick: "headerDateClick" }, []);
export { EventsAgendaComponentNgFactory as EventsAgendaComponentNgFactory };
