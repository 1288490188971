// Common
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { Tag } from '@modules/tag/types/tag';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.less']
})
export class TagListComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() message: MailMessage;
  @Input() event: CalendarEvent;
  @Input() project: Project;
  @Input() group: Group;
  @Input() contact: Contact;
  @Input() tags: Tag[];

  // Outputs
  @Output() tagsChange = new EventEmitter<Tag[]>();

  // Public
  public hidePopover = new Subject();

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.hidePopover.next();
    this.hidePopover.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  showTagsModal() {
    this.modalService.topicList();
  }

  deleteTag(tag: Tag) {
    const index = this.tags.indexOf(tag);
    if (index > -1) {
      this.tags.splice(index, 1);
      this.tagsChange.emit(this.tags);
    }
  }

  createdTags(tags: Tag[]) {
    this.tags.push(...tags);
    this.tagsChange.emit(this.tags);
    this.hidePopover.next();
  }

  closeCreateTagPopover() {
    this.hidePopover.next();
  }

}
