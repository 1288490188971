// Common
import { Pipe, PipeTransform } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { CalendarService } from '@modules/events/services/calendar.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'calendarColor',
  pure: true
})
export class CalendarColorPipe implements PipeTransform {
  private defaultColor = '#cccfdb';

  constructor(private calendarService: CalendarService) {}

  transform(calendarId: string): Observable<string> {
    return this.calendarService.getCalendarsList()
      .pipe(
        map(calendars => calendars.find(calendar => calendar.id === calendarId)),
        map(calendar => calendar && calendar.color),
        map(color => color || this.defaultColor )
      );
  }
}
