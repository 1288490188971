// Common
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil, tap, retryWhen, switchMap, map, throttleTime } from 'rxjs/operators';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Project } from '@modules/tasks/types/project';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';

// Animations
import { slideAnimation } from '@modules/events/animations/slide-animation';

@Component({
  selector: 'app-events-agenda',
  templateUrl: './events-agenda.component.html',
  styleUrls: ['./events-agenda.component.less'],
  animations: [slideAnimation]
})

export class EventsAgendaComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() viewDate: Date;

  // Outputs
  @Output() headerDateClick: EventEmitter<Date> = new EventEmitter<Date>();

  // Public
  public orderOptions: DropdownOption[] = [
    { name: 'Date', key: 'date'},
    { name: 'Title', key: 'title'},
  ];
  public selectedOrder: DropdownOption = this.orderOptions[0];
  public events: CalendarEvent[] = [];
  public loading = false;
  public errors = { events: false };

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private loadEvents: Subject<void> = new Subject();
  private calendarIds: string[];
  private searchTerm: string;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private eventsStateService: EventsStateService,
    private calendarService: CalendarService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.loadEvents
      .pipe(
        throttleTime(300),
        tap(() => {
          this.loading = true;
          this.errors.events = false;
        }),
        switchMap(() => this.calendarService
          .getEvents({
            title: this.searchTerm,
            order: <'date' | 'title'>this.selectedOrder.key,
            calendarIds: this.calendarIds,
            fromDate: this.beginningOfDay(this.viewDate),
            toDate: this.endOfDay(this.viewDate),
          })
          .pipe(
            map((response: EventsListResponse): CalendarEvent[] => response.events)
          )
        ),
        tap(() => {
          this.loading = false;
        }),
        retryWhen(errors =>
          errors.pipe(
            tap(() => {
              this.loading = false;
              this.errors.events = true;
            })
          )
        ),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(events => {
        this.events = events;
      });

    this.stateService.getSelectedCalendars()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarIds: string[]) => {
        this.calendarIds = calendarIds;
        this.resetEvents();
      });

    this.eventsStateService.getRefreshAll()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.resetEvents();
      });

    this.resetEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate) {
      this.resetEvents();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Action
   */

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.resetEvents();
  }

  handleNewEvent(event = new CalendarEvent()) {
    this.eventsStateService.openEventForm(event);
  }

  handleNewTask(task: Task) {

  }

  handleNewNote(note: Note) {

  }

  handleNewProject(project: Project) {

  }

  handleSearchChange(value: string) {
    this.searchTerm = value;
    this.resetEvents();
  }

  resetEvents() {
    this.events = [];
    this.loadEvents.next();
  }

  handleHeaderTitleClick() {
    this.headerDateClick.emit(this.viewDate);
  }

  /**
   * Helpers
   */

  private beginningOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }

  private endOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
}
