<context-menu #contextMenu>
  <ng-template contextMenuItem (execute)="setSelectedCalendars([calendar.id])">Display This Only</ng-template>
  <ng-template contextMenuItem>
    <div #calendarPopoverOrigin (click)="openCalendarForm()">
      Rename Calendar
    </div>
  </ng-template>
  <ng-template contextMenuItem [enabled]="false">Copy Link To Calendar</ng-template>
  <ng-template contextMenuItem (execute)="openDeleteForm()">Delete Calendar</ng-template>
  <ng-template contextMenuItem [enabled]="false">Settings</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [passive]="true">
    <app-color-picker [inputFormControl]="calendarForm?.controls['color']"></app-color-picker>
  </ng-template>
</context-menu>

<ng-template #editCalendarForm>
  <app-calendar-form (close)="handleCalendarPopoverClose()" [calendar]="calendar"></app-calendar-form>
</ng-template>

<ng-template #deleteCalendarForm>
  <app-confirmation-form
    title="Delete Calendar"
    text="Are you sure you want to delete this calendar?"
    cancelButtonLabel="Cancel"
    submitButtonLabel="Delete"
    [action]="deleteCalendar"
    (close)="handleCalendarPopoverClose()"
  ></app-confirmation-form>
</ng-template>
