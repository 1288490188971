// Common
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Types
import { Project } from '@modules/tasks/types/project';

@Component({
  selector: 'app-project-context-menu',
  templateUrl: './project-context-menu.component.html',
  styleUrls: ['./project-context-menu.component.less']
})
export class ProjectContextMenuComponent extends ContextMenuComponent implements OnInit {

  // Inputs
  @Input() project: Project;

  // Public
  public projectForm: FormGroup;
  public projectTitleFormControl = new FormControl();

  /**
   * Constructor
   */

  constructor (
    protected contextMenuService: ContextMenuService,
    private tasksStateService: TasksStateService,
    private ga: GoogleAnalyticsService,
    private projectsService: ProjectsService,
  ) {
    super(contextMenuService);
  }

  /**
   * Component lifecycle
   */

  public ngOnInit() {
    this.projectForm = this.project.asFormGroup();
  }

  /**
   * Actions
   */

  handleEdit() {
    this.tasksStateService.setMainViewProject(this.project);
    this.tasksStateService.setMainView('project-form');
  }

  pin(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'pin');
    this.projectsService.pin(projects.filter(project => project.pinned !== true).map(project => project.id), true);
  }

  unpin(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'unpin');
    this.projectsService.pin(projects.filter(project => project.pinned === true).map(project => project.id), false);
  }

  delete(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'delete');
    this.projectsService.delete(projects.filter(project => project.deleted !== true).map(project => project.id), true);
  }

  deletePermanently(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'delete-permanently');
    this.projectsService.deletePermanently(
      projects.filter(project => project.deleted === true).map(project => project.id));
  }

  restoreFromTrash(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'restore-from-trash');
    this.projectsService.delete(projects.filter(project => project.deleted === true).map(project => project.id), false);
  }

  moveToArchive(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'move-to-archive');
    this.projectsService.archive(
      projects
        .filter(project => project.archived === false)
        .map(project => project.id),
      true
    );
  }

  restoreFromArchive(projects: Project[]) {
    this.ga.trackEvent('project-context-menu', 'restore-from-archive');
    this.projectsService.archive(
      projects
        .filter(project => project.archived === true)
        .map(project => project.id),
      false
    );
  }

  public handleRenameSubmit() {
    this.projectForm.patchValue({ title: this.projectTitleFormControl.value });
    this.projectsService.edit(Project.fromFormGroup(this.projectForm));
    this.closeContextMenu();
  }

  public handleRenameCancel() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  public closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }
}
