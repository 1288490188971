// Common
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { TopicService } from '@modules/topic/services/topic.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';

// Types
import { SearchParam } from '@modules/search/types/search-param';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.less']
})
export class TopicComponent implements OnInit {
  @ViewChild('topicRef', { static: false }) topicRef: ElementRef;

  // Inputs
  @Input() topic: string;
  @Input() selected = false;
  @Input() disableContextMenu = false;
  @Input() showRemoveIcon = false;
  @Input() showRemoveIconAlways = false;
  @Input() canBeDeleted = false;
  @Input() topicStyle: 'discovered' | 'related' | 'snippet' | 'manuallyAdded' | 'new' = 'discovered';
  @Input() dragEnabled = true;
  @Input() highlighted = false;
  @Input() pinned = false;

  // Outputs
  @Output() deselect = new EventEmitter<string>();
  @Output() delete = new EventEmitter();
  @Output() pinnedChange = new EventEmitter<boolean>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => ['message', 'event', 'task', 'note'].includes(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private router: Router,
    private ga: GoogleAnalyticsService,
    private topicService: TopicService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Context Menu actions
   */

  showRelatedEmails(query: string): void {
    this.ga.trackEvent('topic', 'topic-related-mail');
    const searchParams: SearchParam[] = [new SearchParam(query, 'topic')];
    const params = JSON.stringify(searchParams);
    this.router.navigate(['/mail/search'], {queryParams: {query: params}});
  }

  showRelatedTopics(query: string): void {
    const searchParams: SearchParam[] = [new SearchParam(query, 'topic')];
    const params = JSON.stringify(searchParams);
    this.router.navigate(['/insights/search'], {queryParams: {query: params}});
  }

  searchExternal(topicName: string, searchEngine: string = 'google'): void {
    const topicNameParsed = topicName.replace(/\s+/g, '+').toLowerCase();

    switch (searchEngine) {
      case 'bing':
        window.open('https://www.bing.com/news/search?q=' + topicNameParsed, '_blank');
        break;
      case 'google':
      default:
        window.open('https://www.google.com/search?q=' + topicNameParsed, '_blank');
        break;
    }
  }

  deselectTopic(topic: string, event: MouseEvent) {
    event.stopPropagation();
    this.deselect.emit(topic);
  }

  deleteTopic() {
    this.ga.trackEvent('topic', 'delete-topic');
    this.delete.emit();
  }

  pinTopic(topic: string) {
    this.ga.trackEvent('topic', 'pin-topic');
    this.topicService
      .pinTopics([{name: topic}], !this.pinned)
      .subscribe((success: boolean) => {
        if (success) {
          this.pinned = !this.pinned;
          this.pinnedChange.emit(this.pinned);
        }
      });
  }

  extractTopics(topic: string) {
    this.ga.trackEvent('topic', 'create-topic');
    this.topicService.createTopics([{name: topic}]);
  }

  makeItemActive() {
    this.topicRef.nativeElement.classList.add('active');
  }

  makeItemInactive() {
    this.topicRef.nativeElement.classList.remove('active');
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    this.topicService.createTopics(
      [{name: this.topic}],
      {
        messagesIds: dragData.type === 'message' && dragData.data ? (dragData.data as MailMessage[]).map(mail => mail.id) : [],
        eventsIds: dragData.type === 'event' && dragData.data ? (dragData.data as CalendarEvent[]).map(event => event.id) : [],
        projectsIds: dragData.type === 'project' && dragData.data ? (dragData.data as Project[]).map(project => project.id) : [],
        tasksIds: dragData.type === 'task' && dragData.data ? (dragData.data as Task[]).map(task => task.id) : [],
        notesIds: dragData.type === 'note' && dragData.data ? (dragData.data as Note[]).map(note => note.id) : []
      }
    );
  }

}
