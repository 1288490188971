<div 
  class="textarea"
  [ngClass]="{
    'app-form-controls-input-outline': appearance === 'outline',
    'app-form-controls-input-standard': appearance === 'standard'
  }"
>
  <mat-form-field 
    [appearance]="appearance"
    [ngStyle]="{width: width || '100%'}"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
  >
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <textarea 
      [placeholder]="appearance === 'outline' ? null : placeholder" 
      matInput
      [formControl]="inputFormControl"
      [rows]="rows"
    ></textarea>
  </mat-form-field>
</div>
