// Common
import { Injectable } from '@angular/core';

// RxJS
import { Observable, of, Subscriber } from 'rxjs';

// Services
import { GoogleApiService } from './google-api.service';

// Types
import GoogleAuth = gapi.auth2.GoogleAuth;

@Injectable()
export class GoogleAuthService {
  private googleAuth: GoogleAuth = undefined;

  constructor(private googleApi: GoogleApiService) {
    googleApi.onLoad(() => {
      this.loadGapiAuth();
    });
  }

  public getAuth(): Observable<GoogleAuth> {
    if (!this.googleAuth) {
      return this.loadGapiAuth();
    }
    return of(this.googleAuth);
  }

  private loadGapiAuth(): Observable<GoogleAuth> {
    return new Observable((observer: Subscriber<GoogleAuth>) => {
      gapi.load('auth2', () => {
        let auth: GoogleAuth;
        try {
          auth = gapi.auth2.init(this.googleApi.getConfig().getConfigs());
        } catch (error) {
          observer.error(error);
        }
        observer.next(auth);
        observer.complete();
        this.googleAuth = auth;
        return auth;
      });
    });
  }
}
