import * as tslib_1 from "tslib";
// Types
import { Section } from './section';
import { Task } from './task';
var SectionsTree = /** @class */ (function () {
    function SectionsTree(_a) {
        var projectId = _a.projectId, columnId = _a.columnId, sections = _a.sections, tasks = _a.tasks;
        this.sections = [];
        this.tasks = [];
        this.tree = [];
        this.projectId = projectId;
        this.columnId = columnId;
        this.sections = tslib_1.__spread([
            new Section({
                default: true,
                position: -1,
                id: null
            })
        ], sections);
        this.tasks = tasks;
        this.buildTree();
    }
    SectionsTree.sortByPosition = function (items, columnId) {
        return items.sort(function (a, b) { return (columnId ? (a.boardPosition > b.boardPosition) : (a.position > b.position)) ? 1 : -1; });
    };
    SectionsTree.prototype.buildTree = function () {
        var _this = this;
        // if section was removed and there are tasks left with removed section Id
        // or task was moved from another project
        var sectionsIds = this.sections.map(function (section) { return section.id; });
        this.tasks.forEach(function (task) {
            if (!sectionsIds.includes(task.sectionId)) {
                task.sectionId = null;
            }
        });
        var topLevelTasks = this.tasks.filter(function (task) { return task.parentId === null || task.parentId === undefined; });
        this.tree = SectionsTree.sortByPosition(this.sections, this.columnId)
            .map(function (section, sectionIndex) {
            section.position = sectionIndex;
            var sectionTasks = SectionsTree.sortByPosition(topLevelTasks.filter(function (task) { return task.sectionId === section.id; }), _this.columnId);
            sectionTasks.forEach(function (task, index) { return task.position = index; });
            return new Section(tslib_1.__assign({}, section, { tasks: sectionTasks
                    .map(function (task) { return new Task(tslib_1.__assign({}, task, { subtasks: SectionsTree.sortByPosition(_this.tasks.filter(function (subtask) { return subtask.parentId === task.id; }), _this.columnId) })); }) }));
        });
    };
    SectionsTree.prototype.insertSection = function (afterTask) {
        var _this = this;
        // if there was temp section - remove it and put all tasks to previous one
        var tempSection = this.sections.find(function (section) { return section.id === 'temp'; });
        if (tempSection) {
            var previousSection_1 = this.sections.find(function (section) { return section.position === tempSection.position - 1; });
            var previousSectionsTasksCount_1 = previousSection_1
                ? this.tasks.filter(function (task) { return task.sectionId === previousSection_1.id; }).length
                : 0;
            this.tasks
                .filter(function (task) { return task.sectionId === 'temp'; })
                .forEach(function (task, index) {
                if (_this.columnId) {
                    task.boardPosition = previousSectionsTasksCount_1 + index;
                }
                else {
                    task.position = previousSectionsTasksCount_1 + index;
                }
                task.sectionId = previousSection_1 ? previousSection_1.id : null;
            });
        }
        var newSection = new Section({
            id: 'temp',
            projectId: this.projectId
        });
        if (afterTask) {
            var afterSection_1 = this.sections.find(function (section) { return section.id === afterTask.sectionId; });
            newSection.position = afterSection_1.position + 0.5;
            this.tasks
                .filter(function (task) { return task.sectionId === afterSection_1.id && task.position > afterTask.position; })
                .forEach(function (task, index) {
                if (_this.columnId) {
                    task.boardPosition = index;
                }
                else {
                    task.position = index;
                }
                task.sectionId = 'temp';
            });
        }
        else {
            newSection.position = this.sections.length;
        }
        this.sections = this.sections.filter(function (section) { return section.id !== 'temp'; });
        this.sections.push(newSection);
        this.buildTree();
    };
    SectionsTree.prototype.insertTask = function (afterTask) {
        this.tempTask = this.tempTask || new Task({
            temp: true,
            projectId: this.projectId,
            columnId: this.columnId,
            sectionId: afterTask ? afterTask.sectionId : null
        });
        this.tempTask[this.columnId ? 'boardPosition' : 'position'] = afterTask ? afterTask.position + 0.5 :
            this.tasks.filter(function (task) { return task.sectionId === null; }).length;
        var tasks = this.tasks.filter(function (task) { return !task.temp; });
        this.tasks = tslib_1.__spread(tasks, [this.tempTask]);
        this.buildTree();
    };
    SectionsTree.prototype.moveTask = function (task, targetSection, index) {
        var newTask = new Task(tslib_1.__assign({}, task, { sectionId: targetSection.id, projectId: this.projectId, position: index - 0.5, columnId: this.columnId || task.columnId, parentId: null }));
        this.tasks = tslib_1.__spread(this.tasks.filter(function (item) { return item.id !== task.id; }), [newTask]);
        this.buildTree();
    };
    SectionsTree.prototype.moveSubtask = function (subtask, targetTask, index) {
        var newSubtask = new Task(tslib_1.__assign({}, subtask, { sectionId: null, projectId: this.projectId, position: index - 0.5, columnId: null, parentId: targetTask.id }));
        this.tasks = tslib_1.__spread(this.tasks.filter(function (item) { return item.id !== subtask.id; }), [newSubtask]);
        this.buildTree();
    };
    SectionsTree.prototype.moveSection = function (sectionToMove, direction) {
        this.sections = this.sections.map(function (section) {
            return new Section(tslib_1.__assign({}, section, { position: section.id === sectionToMove.id ?
                    section.position + direction * 1.5
                    :
                        section.position }));
        });
        this.buildTree();
    };
    return SectionsTree;
}());
export { SectionsTree };
