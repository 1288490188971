/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./compass-panel.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/knowledge-header/knowledge-header.component.ngfactory";
import * as i3 from "../../../common/components/knowledge-header/knowledge-header.component";
import * as i4 from "../../../quick-forms/components/quick-form/quick-form.component.ngfactory";
import * as i5 from "../../../quick-forms/components/quick-form/quick-form.component";
import * as i6 from "../../../quick-forms/components/event-quick-form/event-quick-form.component.ngfactory";
import * as i7 from "../../../quick-forms/components/event-quick-form/event-quick-form.component";
import * as i8 from "../../../events/services/calendar.service";
import * as i9 from "../../../quick-forms/components/task-quick-form/task-quick-form.component.ngfactory";
import * as i10 from "../../../quick-forms/components/task-quick-form/task-quick-form.component";
import * as i11 from "../../../tasks/services/tasks.service";
import * as i12 from "../../../tasks/services/tasks-state.service";
import * as i13 from "../../../quick-forms/components/note-quick-form/note-quick-form.component.ngfactory";
import * as i14 from "../../../quick-forms/components/note-quick-form/note-quick-form.component";
import * as i15 from "../../../notes/services/notes.service";
import * as i16 from "../../../quick-forms/components/project-quick-form/project-quick-form.component.ngfactory";
import * as i17 from "../../../quick-forms/components/project-quick-form/project-quick-form.component";
import * as i18 from "../../../tasks/services/projects.service";
import * as i19 from "../../../events/components/form-controls/events-date-picker/events-date-picker.component.ngfactory";
import * as i20 from "../../../events/components/form-controls/events-date-picker/events-date-picker.component";
import * as i21 from "../../../settings/services/state.service";
import * as i22 from "../../../events/services/events-state.service";
import * as i23 from "../../../popover/services/popover.service";
import * as i24 from "../../services/knowledge-panel.service";
import * as i25 from "./compass-panel.component";
var styles_CompassPanelComponent = [i0.styles];
var RenderType_CompassPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompassPanelComponent, data: {} });
export { RenderType_CompassPanelComponent as RenderType_CompassPanelComponent };
export function View_CompassPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "compass"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-knowledge-header", [["title", "Daily Focus"]], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.selectOrder($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_KnowledgeHeaderComponent_0, i2.RenderType_KnowledgeHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.KnowledgeHeaderComponent, [], { title: [0, "title"], orders: [1, "orders"], selected: [2, "selected"], search: [3, "search"] }, { selectedChange: "selectedChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "compass-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "app-quick-form", [["bundledGroup", "kpCompassQuickForm"], ["placeholder", "Add Event, Task or Reminder"]], null, null, null, i4.View_QuickFormComponent_0, i4.RenderType_QuickFormComponent)), i1.ɵdid(5, 4964352, null, 6, i5.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], smart: [2, "smart"] }, null), i1.ɵqud(335544320, 1, { eventForm: 0 }), i1.ɵqud(335544320, 2, { projectForm: 0 }), i1.ɵqud(335544320, 3, { taskForm: 0 }), i1.ɵqud(335544320, 4, { noteForm: 0 }), i1.ɵqud(335544320, 5, { contactForm: 0 }), i1.ɵqud(335544320, 6, { groupForm: 0 }), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "app-event-quick-form", [["bundledGroup", "kpCompassQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_EventQuickFormComponent_0, i6.RenderType_EventQuickFormComponent)), i1.ɵdid(13, 770048, [[1, 4]], 0, i7.EventQuickFormComponent, [i8.CalendarService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(14, 0, null, 1, 1, "app-task-quick-form", [["bundledGroup", "kpCompassQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewTask($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TaskQuickFormComponent_0, i9.RenderType_TaskQuickFormComponent)), i1.ɵdid(15, 704512, [[3, 4]], 0, i10.TaskQuickFormComponent, [i11.TasksService, i12.TasksStateService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(16, 0, null, 3, 1, "app-note-quick-form", [["bundledGroup", "kpCompassQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewNote($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_NoteQuickFormComponent_0, i13.RenderType_NoteQuickFormComponent)), i1.ɵdid(17, 573440, [[4, 4]], 0, i14.NoteQuickFormComponent, [i15.NotesService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(18, 0, null, 2, 1, "app-project-quick-form", [["bundledGroup", "kpCompassQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewProject($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ProjectQuickFormComponent_0, i16.RenderType_ProjectQuickFormComponent)), i1.ɵdid(19, 573440, [[2, 4]], 0, i17.ProjectQuickFormComponent, [i18.ProjectsService], { bundledGroup: [0, "bundledGroup"] }, { more: "more" }), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "compass-content-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-events-date-picker", [["collapsedStateKey", "dpCompass"], ["storeDateKey", "kpCompass"]], null, null, null, i19.View_EventsDatePickerComponent_0, i19.RenderType_EventsDatePickerComponent)), i1.ɵdid(22, 245760, null, 0, i20.EventsDatePickerComponent, [i8.CalendarService, i21.StateService, i22.EventsStateService, i23.PopoverService, i24.KnowledgePanelService], { inputFormControl: [0, "inputFormControl"], storeDateKey: [1, "storeDateKey"], inline: [2, "inline"], collapsedStateKey: [3, "collapsedStateKey"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Daily Focus"; var currVal_1 = _co.orderOptions; var currVal_2 = _co.selectedOrder; var currVal_3 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Add Event, Task or Reminder"; var currVal_5 = "kpCompassQuickForm"; var currVal_6 = true; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = "kpCompassQuickForm"; _ck(_v, 13, 0, currVal_7); var currVal_8 = "kpCompassQuickForm"; _ck(_v, 15, 0, currVal_8); var currVal_9 = "kpCompassQuickForm"; _ck(_v, 17, 0, currVal_9); var currVal_10 = "kpCompassQuickForm"; _ck(_v, 19, 0, currVal_10); var currVal_11 = _co.dateFromControl; var currVal_12 = "kpCompass"; var currVal_13 = true; var currVal_14 = "dpCompass"; _ck(_v, 22, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, null); }
export function View_CompassPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-compass-panel", [], null, null, null, View_CompassPanelComponent_0, RenderType_CompassPanelComponent)), i1.ɵdid(1, 49152, null, 0, i25.CompassPanelComponent, [i24.KnowledgePanelService], null, null)], null, null); }
var CompassPanelComponentNgFactory = i1.ɵccf("app-compass-panel", i25.CompassPanelComponent, View_CompassPanelComponent_Host_0, {}, {}, []);
export { CompassPanelComponentNgFactory as CompassPanelComponentNgFactory };
