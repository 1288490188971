// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { StateService } from './services/state.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [StateService]
})
export class SettingsModule { }
