import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// RxJS
import { takeUntil } from 'rxjs/operators';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
var SelectFolderModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectFolderModalComponent, _super);
    /**
     * Constructor
     */
    function SelectFolderModalComponent(mailService) {
        var _this = _super.call(this) || this;
        _this.mailService = mailService;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 250px)',
            y: '40px',
            width: '500px',
            height: 'calc(100% - 100px)'
        };
        // Private
        _this.defaultFolderIds = [
            'all', 'outbox', 'drafts', 'starred', 'snoozed',
            'followed', 'important', 'archive'
        ];
        return _this;
    }
    /**
     * Component lifecycle
     */
    SelectFolderModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.mailService.getFolders()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (folders) {
            _this.folders = _this.filterUserFolders(folders);
        });
    };
    /**
     * Actions
     */
    SelectFolderModalComponent.prototype.confirmMove = function () {
        var _this = this;
        this.mailService.moveMessages({ messages: this.messages }, [this.selectedFolder.id])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (moved) {
            if (moved) {
                _this.close();
            }
        });
    };
    SelectFolderModalComponent.prototype.openFolder = function (folder, event) {
        event.stopPropagation();
        if (folder.hasOwnProperty('subFolders')) {
            if (folder.hasOwnProperty('open') === false) {
                folder['open'] = false;
            }
            if (folder['open'] === false) {
                folder['open'] = true;
            }
            else {
                folder['open'] = false;
            }
        }
    };
    SelectFolderModalComponent.prototype.filterUserFolders = function (folders) {
        var _this = this;
        return folders.filter(function (item) { return !_this.defaultFolderIds.some(function (id) { return item.id === id; }); }).map(function (x) { return Object.assign({}, x); });
    };
    return SelectFolderModalComponent;
}(BaseModalComponent));
export { SelectFolderModalComponent };
