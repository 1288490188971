<div class="dropdown-header" *ngIf="title">{{ title }}</div>
<div class="input-group input-group-sm dropdown-input">
  <input type="text" class="page-number--btn form-control"
         [(ngModel)]="firstSymbol"
         pattern="/^[a-z]$/i"
         (keydown.enter)="onGoFirstSymbol(firstSymbol)"
         placeholder="Go to #a, b...">
  <span class="input-group-btn">
    <button type="button" class="btn btn-primary"
            [disabled]="!firstSymbol"
            (click)="onGoFirstSymbol(firstSymbol)">
      Go!
    </button>
  </span>
</div><!-- /input-group -->
<div class="divider" *ngIf="divider"></div>
