<!-- Recipients field -->
<div class="recipients-input-group" matAutocompleteOrigin #originAutocomplete="matAutocompleteOrigin">
  <mat-chip-list #chipList class="chips-list">
    <mat-chip
      *ngFor="let recipient of recipients"
      class="chips-item"
      [disableRipple]="true"
      [selectable]="true"
      [removable]="true"
      (removed)="removeRecipient(recipient)">
      <div class="chips-item__icon">
        <mat-icon class="user-icon" svgIcon="user-round"></mat-icon>
      </div>
      <div class="chips-item__text">{{ recipient?.name || recipient.email }}</div>
      <div matChipRemove class="chips-item__delete">
        <mat-icon svgIcon="close"></mat-icon>
      </div>
    </mat-chip>
    <input
      class="recipients-input"
      type="email"
      name="input"
      autocomplete="off"
      [formControl]="searchQuery"
      (blur)="inputBlur()"
      [matAutocomplete]="autoComplete"
      [matAutocompleteConnectedTo]="originAutocomplete"
      [matChipInputFor]="chipList"
      [matChipInputAddOnBlur]="false"
      #input/>
  </mat-chip-list>
</div>
<!-- Recipients field -->

<!-- Autocomplete -->
<mat-autocomplete #autoComplete="matAutocomplete" autoActiveFirstOption (optionSelected)="autocompleteContactSelected($event.option.value)">
  <mat-option *ngFor="let contact of contacts | async" [value]="contact" class="autocomplete-option">
    <div class="autocomplete__user">
      <app-user-avatar size="30" [contact]="contact" [usePopover]="false"></app-user-avatar>
      <div class="autocomplete__user__info">
        <div class="autocomplete__user__name">{{ contact.name || contact.email }}</div>
        <div class="autocomplete__user__email" *ngIf="contact.name">{{ contact.email }}</div>
      </div>
    </div>
  </mat-option>
</mat-autocomplete>
<!-- Autocomplete -->
