/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linked-info-message-list.component.less.shim.ngstyle";
import * as i1 from "../../../styles/link-info-popover.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../message/message.component.ngfactory";
import * as i4 from "../../message/message.component";
import * as i5 from "../../../../mail/services/mail.service";
import * as i6 from "../../../../analytics/services/google-analytics.service";
import * as i7 from "../../../../settings/services/state.service";
import * as i8 from "../../../../modal/services/modal.service";
import * as i9 from "../../../../topic/services/topic.service";
import * as i10 from "../../../../linked-info/services/linked-info.service";
import * as i11 from "../../../../tag/services/tag.service";
import * as i12 from "@angular/material/tooltip";
import * as i13 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "@angular/common";
import * as i16 from "./linked-info-message-list.component";
var styles_LinkedInfoMessageListComponent = [i0.styles, i1.styles];
var RenderType_LinkedInfoMessageListComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_LinkedInfoMessageListComponent, data: {} });
export { RenderType_LinkedInfoMessageListComponent as RenderType_LinkedInfoMessageListComponent };
function View_LinkedInfoMessageListComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "app-message", [], null, [[null, "selectMessage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectMessage" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MessageComponent_0, i3.RenderType_MessageComponent)), i2.ɵdid(1, 770048, null, 0, i4.MessageComponent, [i5.MailService, i6.GoogleAnalyticsService, i7.StateService, i8.ModalService, i9.TopicService, i10.LinkedInfoService, i11.TagService], { message: [0, "message"], actionsButtonEnabled: [1, "actionsButtonEnabled"], threadEnabled: [2, "threadEnabled"] }, { selectMessage: "selectMessage" }), i2.ɵprd(256, null, i12.MAT_TOOLTIP_DEFAULT_OPTIONS, i4.tooltipDefaults, [])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LinkedInfoMessageListComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-fw"]], null, null, null, null, null))], null, null); }
export function View_LinkedInfoMessageListComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { scrollList: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 10, "div", [["class", "linked-info-popover"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "header"]], [[2, "shadow", null]], null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Emails"])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "close-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i2.ɵdid(6, 9158656, null, 0, i14.MatIcon, [i2.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(7, 0, [[1, 0], ["scrollList", 1]], null, 4, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LinkedInfoMessageListComponent_1)), i2.ɵdid(9, 278528, null, 0, i15.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LinkedInfoMessageListComponent_2)), i2.ɵdid(11, 16384, null, 0, i15.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "close"; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.items; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeaderShadow; _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵnov(_v, 6).inline; var currVal_2 = (((i2.ɵnov(_v, 6).color !== "primary") && (i2.ɵnov(_v, 6).color !== "accent")) && (i2.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_LinkedInfoMessageListComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-linked-info-message-list", [], null, null, null, View_LinkedInfoMessageListComponent_0, RenderType_LinkedInfoMessageListComponent)), i2.ɵdid(1, 4964352, null, 0, i16.LinkedInfoMessageListComponent, [i8.ModalService, i5.MailService, i2.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkedInfoMessageListComponentNgFactory = i2.ɵccf("app-linked-info-message-list", i16.LinkedInfoMessageListComponent, View_LinkedInfoMessageListComponent_Host_0, { linkedInfo: "linkedInfo" }, { close: "close" }, []);
export { LinkedInfoMessageListComponentNgFactory as LinkedInfoMessageListComponentNgFactory };
