import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { ColumnContextMenuComponent } from '@modules/tasks/components/column-context-menu/column-context-menu.component';
var BoardColumnContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(BoardColumnContextMenuDirective, _super);
    function BoardColumnContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = ColumnContextMenuComponent;
        return _this;
    }
    BoardColumnContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.column = this.appProjectBoardColumnContextMenu;
    };
    return BoardColumnContextMenuDirective;
}(ContextMenuDirective));
export { BoardColumnContextMenuDirective };
