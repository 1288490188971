/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-form-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../pages/components/linked-info-toolbar/linked-info-toolbar.component.ngfactory";
import * as i5 from "../../../pages/components/linked-info-toolbar/linked-info-toolbar.component";
import * as i6 from "../../../settings/services/state.service";
import * as i7 from "../../services/modal.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../tasks/components/task-form/task-form.component.ngfactory";
import * as i10 from "../../../tasks/components/task-form/task-form.component";
import * as i11 from "../../../tasks/services/tasks.service";
import * as i12 from "../../../tasks/services/projects.service";
import * as i13 from "../../../pages/services/dynamic-panel.service";
import * as i14 from "../../../tasks/services/columns.service";
import * as i15 from "../../../linked-info/services/linked-info.service";
import * as i16 from "../../../topic/services/topic.service";
import * as i17 from "../../../tag/services/tag.service";
import * as i18 from "../../../tasks/services/tasks-state.service";
import * as i19 from "./task-form-modal.component";
import * as i20 from "../../../popup/services/popup.service";
import * as i21 from "../../../analytics/services/google-analytics.service";
var styles_TaskFormModalComponent = [i0.styles];
var RenderType_TaskFormModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskFormModalComponent, data: {} });
export { RenderType_TaskFormModalComponent as RenderType_TaskFormModalComponent };
function View_TaskFormModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "modal-title-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "task"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "task"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TaskFormModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-linked-info-toolbar", [], null, null, null, i4.View_LinkedInfoToolbarComponent_0, i4.RenderType_LinkedInfoToolbarComponent)), i1.ɵdid(1, 4964352, null, 0, i5.LinkedInfoToolbarComponent, [i1.NgZone, i1.ElementRef, i1.Renderer2, i6.StateService, i7.ModalService], { linkedInfo: [0, "linkedInfo"] }, null), i1.ɵpod(2, { type: 0, id: 1 }), i1.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, "task", _co.task.id)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TaskFormModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFormModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Collapse"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "collapse-modal"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "In new window"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "fullscreen"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(13, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-task-form", [["appearance", "headerFixedBig"], ["class", "modal-form"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TaskFormComponent_0, i9.RenderType_TaskFormComponent)), i1.ɵdid(17, 770048, null, 0, i10.TaskFormComponent, [i11.TasksService, i12.ProjectsService, i13.DynamicPanelService, i14.ColumnsService, i15.LinkedInfoService, i16.TopicService, i17.TagService, i18.TasksStateService], { task: [0, "task"], appearance: [1, "appearance"] }, { close: "close" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFormModalComponent_2)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minimized; _ck(_v, 3, 0, currVal_0); var currVal_4 = "collapse-modal"; _ck(_v, 7, 0, currVal_4); var currVal_7 = "fullscreen"; _ck(_v, 10, 0, currVal_7); var currVal_10 = "close"; _ck(_v, 13, 0, currVal_10); var currVal_11 = _co.task; var currVal_12 = "headerFixedBig"; _ck(_v, 17, 0, currVal_11, currVal_12); var currVal_13 = ((_co.task == null) ? null : _co.task.id); _ck(_v, 19, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.task == null) ? null : _co.task.title); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 7).inline; var currVal_3 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 13).inline; var currVal_9 = (((i1.ɵnov(_v, 13).color !== "primary") && (i1.ɵnov(_v, 13).color !== "accent")) && (i1.ɵnov(_v, 13).color !== "warn")); _ck(_v, 12, 0, currVal_8, currVal_9); }); }
export function View_TaskFormModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-form-modal", [], null, null, null, View_TaskFormModalComponent_0, RenderType_TaskFormModalComponent)), i1.ɵdid(1, 245760, null, 0, i19.TaskFormModalComponent, [i1.ElementRef, i20.PopupService, i21.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskFormModalComponentNgFactory = i1.ɵccf("app-task-form-modal", i19.TaskFormModalComponent, View_TaskFormModalComponent_Host_0, { task: "task" }, { closed: "closed", collapsed: "collapsed" }, []);
export { TaskFormModalComponentNgFactory as TaskFormModalComponentNgFactory };
