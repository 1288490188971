<as-split 
  #splitElement
  direction="horizontal"
  unit="pixel"
  [useTransition]="true"
  gutterSize="3"
  (dragStart)="handleDragStart()"
  (dragEnd)="handleDragEnd($event.sizes)"
  (gutterClick)="handleSplitGutterClick()"
>
  <as-split-area 
    [size]="collapseableSide === 'left' ? currentSize : '*'" 
    [minSize]="collapseableSide === 'left' ? minWidth : null" 
    [ngClass]="{shadow: shadow}"
  >
    <ng-content select="[split-left-pane]"></ng-content>
  </as-split-area>
  <as-split-area 
    [size]="collapseableSide === 'right' ? currentSize : '*'"
    [minSize]="collapseableSide === 'right' ? minWidth : null"
  >
    <ng-content select="[split-right-pane]"></ng-content>
  </as-split-area>

  <mat-icon
    *ngIf="icon && !dragging"
    class="split-icon"
    [svgIcon]="minimized ? icon : 'button-split-expanded'"
    (click)="toggle()"
    [style.left.px]="currentSize"
  ></mat-icon>
</as-split>
