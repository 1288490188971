// Common
import { Component, OnInit, AfterViewInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContextMenuService, ContextMenuComponent as NgxContextMenuComponent } from 'ngx-contextmenu';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.less']
})
export class ContextMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  // Inputs
  @Input() triggerEvent: MouseEvent;
  @Input() anchorElement: Element;
  @Input() contextMenuItem: any;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // ViewChildren
  @ViewChild('contextMenu', { static: true }) contextMenu: NgxContextMenuComponent;

  // Private
  protected alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(protected contextMenuService: ContextMenuService) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.contextMenuService.show.next({
      anchorElement: this.anchorElement,
      contextMenu: this.getContextMenu(),
      event: this.triggerEvent,
      item: this.contextMenuItem
    });

    if (this.contextMenu) {
      this.contextMenu.close
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe(() => {
          this.close.emit();
        });
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Helpers
   */

  getContextMenu(): NgxContextMenuComponent {
    return this.contextMenu;
  }
}
