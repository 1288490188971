// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ElementsModule } from '../elements/elements.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { UserAvatarModule } from '@modules/user-avatar/user-avatar.module';

// Components
import { LinkedInfoListComponent } from './components/linked-info-list/linked-info-list.component';
import { LinkInfoMessageComponent } from './components/link-info-message/link-info-message.component';
import { LinkInfoEventComponent } from './components/link-info-event/link-info-event.component';
import { LinkInfoNoteComponent } from './components/link-info-note/link-info-note.component';
import { LinkInfoProjectComponent } from './components/link-info-project/link-info-project.component';
import { LinkInfoTaskComponent } from './components/link-info-task/link-info-task.component';
import { LinkInfoGroupComponent } from './components/link-info-group/link-info-group.component';
import { LinkInfoContactComponent } from './components/link-info-contact/link-info-contact.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    FormControlsModule,
    AppCommonModule,
    DropdownModule,
    ElementsModule,
    PipesModule,
    PopoverModule,
    DragNDropModule,
    UserAvatarModule
  ],
  declarations: [
    LinkedInfoListComponent,
    LinkInfoMessageComponent,
    LinkInfoEventComponent,
    LinkInfoNoteComponent,
    LinkInfoProjectComponent,
    LinkInfoTaskComponent,
    LinkInfoGroupComponent,
    LinkInfoContactComponent,
  ],
  exports: [
    LinkedInfoListComponent, LinkInfoMessageComponent, LinkInfoEventComponent,
  ],
})
export class LinkedInfoModule { }
