import * as tslib_1 from "tslib";
import { DynamicSizeScrollingItem } from '@modules/virtual-scroll/types/dynamic-size-scrolling-item';
var DynamicSizeMailMessage = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicSizeMailMessage, _super);
    function DynamicSizeMailMessage(data) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.sizes = {
            placeholder: 87,
            base: 89,
            linkedInfo: 24
        };
        _this.size = data ? _this.sizes.base : _this.sizes.placeholder;
        if (data && data.linkedInfo && data.linkedInfo.length) {
            _this.size += _this.sizes.linkedInfo;
        }
        return _this;
    }
    return DynamicSizeMailMessage;
}(DynamicSizeScrollingItem));
export { DynamicSizeMailMessage };
