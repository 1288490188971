/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar-task-context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i3 from "ngx-contextmenu";
import * as i4 from "./sidebar-task-context-menu.component";
import * as i5 from "../../services/tasks-state.service";
var styles_SidebarTaskContextMenuComponent = [i0.styles];
var RenderType_SidebarTaskContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarTaskContextMenuComponent, data: {} });
export { RenderType_SidebarTaskContextMenuComponent as RenderType_SidebarTaskContextMenuComponent };
function View_SidebarTaskContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Add New Task"]))], null, null); }
function View_SidebarTaskContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Settings"]))], null, null); }
export function View_SidebarTaskContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "context-menu", [], null, null, null, i2.View_ContextMenuComponent_0, i2.RenderType_ContextMenuComponent)), i1.ɵdid(2, 180224, [[1, 4], ["contextMenu", 4]], 1, i3.ContextMenuComponent, [i3.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i3.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createTask() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SidebarTaskContextMenuComponent_1)), i1.ɵdid(5, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_SidebarTaskContextMenuComponent_2)), i1.ɵdid(7, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], { enabled: [0, "enabled"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_SidebarTaskContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar-task-context-menu", [], null, null, null, View_SidebarTaskContextMenuComponent_0, RenderType_SidebarTaskContextMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i4.SidebarTaskContextMenuComponent, [i3.ContextMenuService, i5.TasksStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarTaskContextMenuComponentNgFactory = i1.ɵccf("app-sidebar-task-context-menu", i4.SidebarTaskContextMenuComponent, View_SidebarTaskContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem", startDate: "startDate", priority: "priority" }, { close: "close" }, []);
export { SidebarTaskContextMenuComponentNgFactory as SidebarTaskContextMenuComponentNgFactory };
