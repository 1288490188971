import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[appScrollAnchor]'
})
export class ScrollAnchorDirective {

  /**
   * Constructor
   */

  constructor(
    public element: ElementRef
  ) { }
}
