<div class="calendar-form">
  <form [formGroup]="calendarForm" (ngSubmit)="handleSubmit()">
    <div class="calendar-form-horizontal-group">
      <div class="calendar-form-horizontal-group-item">
        <app-input
          [placeholder]="calendarForm.get('id').value ? 'Rename Calendar' : 'New Calendar'"
          [inputFormControl]="calendarForm.controls['name']"
          width="265px"
        ></app-input>
      </div>
    </div>

    <div class="calendar-form-horizontal-group">
      <div class="calendar-form-horizontal-group-item">
        <app-time-zone-select
          placeholder="Time zone"
          width="265px"
          [inputFormControl]="calendarForm.controls['timeZone']"
        ></app-time-zone-select>
      </div>
    </div>

    <div class="calendar-form-buttons">
      <app-button class="calendar-form-buttons-item" (click)="handleCancel()">Cancel</app-button>
      <app-button class="calendar-form-buttons-item" appearance="blue" type="submit">Save</app-button>
    </div>
  </form>
</div>
