// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
})
export class CheckboxComponent {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() title: string;

  /**
   * Constructor
   */

  constructor() {

  }
}
