<div class="modal-header">
  <div class="modal-title">
    <mat-icon *ngIf="minimized" svgIcon="note" class="modal-title-icon"></mat-icon>
    {{ note?.title || 'Note' }}
  </div>
  <button type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="In new window" (click)="expand()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="modal-content">
    <app-note-form [note]="note" (close)="close()" class="modal-form"></app-note-form>
  </div>
</div>
