// Common
import { Directive, Input, OnChanges, forwardRef } from '@angular/core';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';

// RxJS
import { Observable } from 'rxjs';

// Classes
import { DynamicSizeVirtualScrollStrategy } from '../classes/dynamic-size-virtual-scroll-strategy';
import { DynamicSizeScrollingItem } from '../types/dynamic-size-scrolling-item';

/**
 * Provider factory for `DynamicSizeVirtualScrollStrategy` that simply extracts the already created
 * `DynamicSizeVirtualScrollStrategy` from the given directive.
 * @param dynamicSizeDir The instance of `CdkDynamicSizeVirtualScrollDirective` to extract the
 *     `DynamicSizeVirtualScrollStrategy` from.
 */
export function DynamicSizeVirtualScrollStrategyFactory(dynamicSizeDir: CdkDynamicSizeVirtualScrollDirective) {
  return dynamicSizeDir.scrollStrategy;
}

@Directive({
  selector: 'cdk-virtual-scroll-viewport[dynamicSizeDataSource]',
  providers: [{
    provide: VIRTUAL_SCROLL_STRATEGY,
    useFactory: DynamicSizeVirtualScrollStrategyFactory,
    deps: [forwardRef(() => CdkDynamicSizeVirtualScrollDirective)],
  }]
})
export class CdkDynamicSizeVirtualScrollDirective implements OnChanges {

  // Inputs
  @Input() dynamicSizeDataSource: Observable<DynamicSizeScrollingItem<Object>[]>;

  // Public
  /** The scroll strategy used by this directive. */
  public scrollStrategy = new DynamicSizeVirtualScrollStrategy(this.dynamicSizeDataSource);

  constructor() { }

  ngOnChanges() {
    this.scrollStrategy.updateDataSource(this.dynamicSizeDataSource);
  }

}
