// Common
import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MailService } from '@modules/mail/services/mail.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Types
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { LinkedInfoFilterType } from '@modules/linked-info/types/linked-info-filter';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { ResponseMessages } from '@modules/mail/types/mail-response.model';
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';

type ViewType = 'cards-view' | 'list-view';

class LinkedInfoData extends LinkedInfo {
  data?: MailMessage | CalendarEvent | Project | Task | Note | Group | Contact;
}

@Component({
  selector: 'app-linked-info-list',
  templateUrl: './linked-info-list.component.html',
  styleUrls: ['./linked-info-list.component.less']
})
export class LinkedInfoListComponent implements OnInit, OnChanges, OnDestroy {
  // Static constants
  static readonly initialLinkedInfoFilters: LinkedInfoFilterType = {
    message: true,
    event: true,
    note: true,
    project: true,
    task: true,
    group: true,
    contact: true
  };

  // Inputs
  @Input() parentLinkedInfo: LinkedInfo;
  @Input() linkedInfo: LinkedInfo[] = [];
  @Input() collapseble = true;
  @Input() extendEnabled = false;
  @Input() extendSelected = false;

  // Outputs
  @Output() extendAction = new EventEmitter();

  // Public
  public linkedInfoData: LinkedInfoData[] = [];
  public linkedInfoFilters: LinkedInfoFilterType = LinkedInfoListComponent.initialLinkedInfoFilters;
  public sortAsc = true;
  public viewType: ViewType = 'cards-view';

  // Private
  private cancelRequest = new Subject();
  private messages: MailMessage[] = [];
  private events: CalendarEvent[] = [];
  private tasks: Task[] = [];
  private projects: Project[] = [];
  private notes: Note[] = [];
  private groups: Group[] = [];
  private contacts: Contact[] = [];

  /**
   * Constructor
   */

  constructor(
    private linkedInfoService: LinkedInfoService,
    private mailService: MailService,
    private calendarService: CalendarService,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private notesService: NotesService,
    private groupsService: GroupsService,
    private contactsService: ContactsService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('linkedInfo' in changes) {
      this.linkedInfoData = [...(this.linkedInfo || [])];
      this.updateLinkedInfoData();
      this.loadLinkedInfoData();
      this.resetFilters();
    }
  }

  ngOnDestroy() {
    this.cancelRequest.next();
    this.cancelRequest.complete();
  }

  /**
   * Methods
   */

  loadLinkedInfoData() {
    // Ids
    const ids: {
      message: string[],
      event: string[],
      project: string[],
      task: string[],
      note: string[],
      group: string[],
      contact: string[]
    } = this.linkedInfoData.reduce((result, item) => {
      result[item.type].push(item.id);
      return result;
    }, {
      message: [],
      event: [],
      project: [],
      task: [],
      note: [],
      group: [],
      contact: []
    });

    this.cancelRequest.next();
    // Messages
    if (ids.message.length) {
      this.mailService.getMessages({messagesIds: ids.message}, 'date', ids.message.length, 0)
        .pipe(takeUntil(this.cancelRequest))
        .subscribe((response: ResponseMessages) => {
          this.messages = response.messages;
          this.updateLinkedInfoData();
      });
    } else {
      this.messages = [];
      this.updateLinkedInfoData();
    }
    // Events
    if (ids.event.length) {
      this.calendarService.getEvents({eventsIds: ids.event, limit: ids.event.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe((response: EventsListResponse) => {
          this.events = response.events;
          this.updateLinkedInfoData();
      });
    } else {
      this.events = [];
      this.updateLinkedInfoData();
    }
    // Projects
    if (ids.project.length) {
      this.projectsService.getProjects({projectsIds: ids.project, limit: ids.project.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe(({projects}) => {
          this.projects = projects;
          this.updateLinkedInfoData();
      });
    } else {
      this.projects = [];
      this.updateLinkedInfoData();
    }
    // Tasks
    if (ids.task.length) {
      this.tasksService.getTasks({tasksIds: ids.task, limit: ids.task.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe(({tasks}) => {
          this.tasks = tasks;
          this.updateLinkedInfoData();
      });
    } else {
      this.tasks = [];
      this.updateLinkedInfoData();
    }
    // Notes
    if (ids.note.length) {
      this.notesService.getNotes({notesIds: ids.note, limit: ids.note.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe(({notes}) => {
          this.notes = notes;
          this.updateLinkedInfoData();
      });
    } else {
      this.notes = [];
      this.updateLinkedInfoData();
    }
    // Groups
    if (ids.group.length) {
      this.groupsService.getGroups({groupsIds: ids.group, limit: ids.group.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe(({groups}) => {
          this.groups = groups;
          this.updateLinkedInfoData();
      });
    } else {
      this.groups = [];
      this.updateLinkedInfoData();
    }
    // Contacts
    if (ids.contact.length) {
      this.contactsService.getContacts({contactsIds: ids.contact, limit: ids.contact.length})
        .pipe(takeUntil(this.cancelRequest))
        .subscribe(({contacts}) => {
          this.contacts = contacts;
          this.updateLinkedInfoData();
      });
    } else {
      this.contacts = [];
      this.updateLinkedInfoData();
    }
  }

  updateLinkedInfoData() {
    this.messages.forEach(message => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === message.id && info.type === 'message');
      if (linkedInfo) {
        linkedInfo.data = message;
      }
    });
    this.events.forEach(event => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === event.id && info.type === 'event');
      if (linkedInfo) {
        linkedInfo.data = event;
      }
    });
    this.projects.forEach(project => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === project.id && info.type === 'project');
      if (linkedInfo) {
        linkedInfo.data = project;
      }
    });
    this.tasks.forEach(task => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === task.id && info.type === 'task');
      if (linkedInfo) {
        linkedInfo.data = task;
      }
    });
    this.notes.forEach(note => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === note.id && info.type === 'note');
      if (linkedInfo) {
        linkedInfo.data = note;
      }
    });
    this.groups.forEach(group => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === group.id && info.type === 'group');
      if (linkedInfo) {
        linkedInfo.data = group;
      }
    });
    this.contacts.forEach(contact => {
      const linkedInfo = this.linkedInfoData.find(info => info.id === contact.id && info.type === 'contact');
      if (linkedInfo) {
        linkedInfo.data = contact;
      }
    });
  }

  /**
   * Actions
   */

  unlinkItem(info: LinkedInfo) {
    if (this.parentLinkedInfo && info) {
      this.linkedInfoService.unlinkItems([info, this.parentLinkedInfo]);
    }
  }

  public unlinkAll() {
    if (this.parentLinkedInfo) {
      this.linkedInfoService.unlinkItems([...this.linkedInfo, this.parentLinkedInfo]);
    }
  }

  handleExtendIcon() {
    this.extendAction.emit();
  }

  public toggleFilter(type: LinkedInfoType): void {
    this.linkedInfoFilters[type] = !this.linkedInfoFilters[type];
  }

  public toggleSort(): void {
    this.sortAsc = !this.sortAsc;
    this.linkedInfoData = [
      ...this.linkedInfoData.sort((a, b) =>
        (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) * (this.sortAsc ? 1 : -1) > 0 ? 1 : -1
      )
    ];
  }

  public changeViewType(viewType: ViewType): void {
    this.viewType = viewType;
  }

  private resetFilters(): void {
    this.linkedInfoFilters = LinkedInfoListComponent.initialLinkedInfoFilters;
  }
}
