import * as tslib_1 from "tslib";
// Common
import { OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
// Components
import { TopicsComponent } from '@modules/topic/components/topics/topics.component';
// RX
import { Subject } from 'rxjs';
import { takeUntil, startWith, switchMap } from 'rxjs/operators';
var TaskTopicsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaskTopicsComponent, _super);
    /**
     * Constructor
     */
    function TaskTopicsComponent(modalService, topicsService) {
        var _this = _super.call(this, modalService) || this;
        _this.topicsService = topicsService;
        // Private
        _this.controlChanges = new Subject();
        _this.controlChanges
            .pipe(switchMap(function () {
            return _this.topicsControl.valueChanges
                .pipe(startWith((_this.topicsControl && _this.topicsControl.value) || []));
        }), takeUntil(_this.alive))
            .subscribe(function (topics) {
            var previousValue = _this.topics;
            _this.topics = topics;
            _super.prototype.ngOnChanges.call(_this, { topics: { previousValue: previousValue, currentValue: _this.topics, firstChange: false, isFirstChange: function () { return false; } } });
        });
        return _this;
    }
    /**
     * Lifecycle
     */
    TaskTopicsComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if ('topicsControl' in changes) {
            this.controlChanges.next();
        }
    };
    TaskTopicsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.controlChanges.next();
        this.controlChanges.complete();
    };
    /**
     * Actions
     */
    TaskTopicsComponent.prototype.handleDeleteTopic = function (index) {
        if (this.taskId) {
            this.topicsService.deleteTopics([this.topicsControl.value[index]], { tasksIds: [this.taskId] });
        }
        this.topicsControl.setValue(this.topicsControl.value.filter(function (topic, topicIndex) { return topicIndex !== index; }));
    };
    TaskTopicsComponent.prototype.handleAddTopics = function (topics) {
        if (this.taskId) {
            this.topicsService.createTopics(topics, { projectsIds: [this.taskId] });
        }
        this.topicsControl.patchValue(tslib_1.__spread(topics, this.topicsControl.value));
        this.popoverClose.next();
        this.newTopics = [];
    };
    return TaskTopicsComponent;
}(TopicsComponent));
export { TaskTopicsComponent };
