import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dropdown-color-select',
  templateUrl: './dropdown-color-select.component.html',
  styleUrls: ['./dropdown-color-select.component.less']
})
export class DropdownColorSelectComponent implements OnInit {

  // Static
  static hexRegExp = /^#([A-Fa-f0-9]{3}){1,2}$/;
  static rgbRegExp = /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i;

  // Inputs
  @Input() title: string;
  @Input() selected: string;
  @Input() options: string[] = [
    '#001f3f', '#0074D9', '#7FDBFF', '#39CCCC', '#3D9970',
    '#2ECC40', '#01FF70', '#FFDC00', '#FF851B', '#FF4136',
    '#85144b', '#F012BE', '#B10DC9', '#000000', '#AAAAAA',
    '#DDDDDD'
  ];
  @Input() divider = false;

  // Outputs
  @Output() selectedChange: EventEmitter<string> = new EventEmitter();
  @Output() removeColor: EventEmitter<void> = new EventEmitter();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Methods
   */

  rgb2hex(color: string) {
    // Check for hex
    if (DropdownColorSelectComponent.hexRegExp.test(color)) {
      return color.toUpperCase();
    }
    // Convert to hex
    if (DropdownColorSelectComponent.rgbRegExp.test(color)) {
      const rgb = color.match(DropdownColorSelectComponent.rgbRegExp);
      const hex = (rgb && rgb.length === 4) ? '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
      return hex.toUpperCase();
    }
    return color;
  }

  /**
   * Actions
   */

  select(option: string) {
    this.selected = option;
    this.selectedChange.emit(this.selected);
  }

  removedColor() {
    this.removeColor.emit();
  }

}
