// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { ProjectContextMenuComponent } from '../components/project-context-menu/project-context-menu.component';

// Types
import { Project } from '@modules/tasks/types/project';

@Directive({
  selector: '[appProjectContextMenu]'
})
export class ProjectContextMenuDirective extends ContextMenuDirective {


  // Inputs
  @Input() contextMenuComponent = ProjectContextMenuComponent;
  @Input() appProjectContextMenu: Project;

  setContextMenuInstanceData(componentRef: ComponentRef<ProjectContextMenuComponent>) {
    componentRef.instance.project = this.appProjectContextMenu;
  }
}
