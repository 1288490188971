<app-content-menu>

  <!-- Notes -->
  <app-content-menu-item icon="notes-small" title="Notes" (click)="resetFilters()" [active]="allNotesSelected">
  </app-content-menu-item>
  <app-content-menu-item icon="calendar-today" title="Today" (click)="filterByDate(today)" [active]="(notesFilters?.fromTime | sameDay:today) && (notesFilters?.toTime | sameDay:today)"
  ></app-content-menu-item>
  <app-content-menu-item icon="calendar-week" title="Next 7 Days">
    <app-content-menu-item
      *ngFor="let day of nextWeekDays; first as isFirst"
      [title]="isFirst ? 'Tomorrow' : day | date: 'EEEE'"
      [active]="(notesFilters?.fromTime | sameDay:day) && (notesFilters?.toTime | sameDay:day)"
      (click)="filterByDate(day)"
    ></app-content-menu-item>
  </app-content-menu-item>
  <app-content-menu-item icon="saved" title="Favorites" (click)="filterByFavorite()" [active]="notesFilters?.favorite"></app-content-menu-item>
  <app-content-menu-item icon="folder-archive" title="Archive" (click)="filterDyArchive()" [active]="notesFilters?.archived"></app-content-menu-item>
  <app-content-menu-item icon="trash-bucket" title="Trash" (click)="filterByTrash()" [active]="notesFilters?.deleted"></app-content-menu-item>
  <app-content-menu-divider></app-content-menu-divider>

  <!-- Notebooks -->
  <app-content-menu-item icon="notebook" title="Notebooks">
    <app-content-menu-item
      *ngFor="let notebook of notebooks"
      [title]="notebook"
    >
    </app-content-menu-item>
  </app-content-menu-item>
  <app-content-menu-divider></app-content-menu-divider>

  <!-- Pellets -->
  <app-content-menu-pellets
    collapsedStateKey="notesSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <!-- Tags -->
  <app-content-menu-tags
    collapsedStateKey="notesSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>
