/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-menu-checkbox.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/checkbox";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../content-menu-base-item/content-menu-base-item.component";
import * as i11 from "./content-menu-checkbox.component";
import * as i12 from "../../services/content-menu.service";
var styles_ContentMenuCheckboxComponent = [i0.styles];
var RenderType_ContentMenuCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentMenuCheckboxComponent, data: {} });
export { RenderType_ContentMenuCheckboxComponent as RenderType_ContentMenuCheckboxComponent };
function View_ContentMenuCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ContentMenuCheckboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_ContentMenuCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "content-menu-checkbox-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { minimized: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "checkbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-checkbox", [["class", "checkbox-item-checkbox mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatCheckbox_0, i5.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.MatCheckbox]), i1.ɵdid(7, 8568832, null, 0, i7.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i8.FocusMonitor, i1.NgZone, [8, null], [2, i7.MAT_CHECKBOX_CLICK_ACTION], [2, i9.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"], checked: [1, "checked"], disabled: [2, "disabled"] }, { change: "change" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentMenuCheckboxComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentMenuCheckboxComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content-menu-checkbox-wrapper"; var currVal_1 = _ck(_v, 3, 0, _co.minimized); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_9 = true; var currVal_10 = _co.checked; var currVal_11 = _co.disabled; _ck(_v, 7, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.icon; _ck(_v, 9, 0, currVal_12); var currVal_13 = !_co.minimized; _ck(_v, 11, 0, currVal_13); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).id; var currVal_3 = null; var currVal_4 = i1.ɵnov(_v, 7).indeterminate; var currVal_5 = i1.ɵnov(_v, 7).checked; var currVal_6 = i1.ɵnov(_v, 7).disabled; var currVal_7 = (i1.ɵnov(_v, 7).labelPosition == "before"); var currVal_8 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ContentMenuCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-content-menu-checkbox", [], null, null, null, View_ContentMenuCheckboxComponent_0, RenderType_ContentMenuCheckboxComponent)), i1.ɵprd(6144, null, i10.ContentMenuBaseItemComponent, null, [i11.ContentMenuCheckboxComponent]), i1.ɵdid(2, 704512, null, 0, i11.ContentMenuCheckboxComponent, [i1.ChangeDetectorRef, i1.ElementRef, [2, i12.ContentMenuService]], null, null)], null, null); }
var ContentMenuCheckboxComponentNgFactory = i1.ɵccf("app-content-menu-checkbox", i11.ContentMenuCheckboxComponent, View_ContentMenuCheckboxComponent_Host_0, { secondLevel: "secondLevel", icon: "icon", title: "title", checked: "checked", disabled: "disabled", color: "color" }, { heightChange: "heightChange", checkedChange: "checkedChange" }, []);
export { ContentMenuCheckboxComponentNgFactory as ContentMenuCheckboxComponentNgFactory };
