import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { EventContextMenuComponent } from '../components/event-context-menu/event-context-menu.component';
var EventContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(EventContextMenuDirective, _super);
    function EventContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contextMenuComponent = EventContextMenuComponent;
        return _this;
    }
    EventContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.event = this.appEventContextMenu;
    };
    return EventContextMenuDirective;
}(ContextMenuDirective));
export { EventContextMenuDirective };
