import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef, OnDestroy, SimpleChanges, OnChanges, TemplateRef, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import * as Chrono from 'chrono-node';
import { CalendarDateFormatter } from 'angular-calendar';
// RX
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, startWith } from 'rxjs/operators';
// Pipes
import { DatePipe } from '@angular/common';
var collapseMotion = trigger('collapseMotion', [
    transition('collapsed => expanded', [
        style({ height: '43px' }),
        animate(".3s ease-in-out", style({
            height: '{{height}}',
        }))
    ]),
    transition('expanded => collapsed', [
        style({ height: '{{height}}', }),
        animate(".3s ease-in-out", style({
            height: '43px',
        }))
    ])
]);
var CalendarMonthDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarMonthDateFormatter, _super);
    function CalendarMonthDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CalendarMonthDateFormatter.prototype.monthViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new DatePipe(locale).transform(date, 'EEEEE', locale);
    };
    return CalendarMonthDateFormatter;
}(CalendarDateFormatter));
export { CalendarMonthDateFormatter };
var DatePickerComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function DatePickerComponent(contextMenuService, datePipe, stateService) {
        this.contextMenuService = contextMenuService;
        this.datePipe = datePipe;
        this.stateService = stateService;
        // Inputs
        this.inputFormControl = new FormControl();
        this.disabled = false;
        this.appearance = 'outline';
        this.bundledInputAppearance = 'start';
        this.collapseable = true;
        this.activeDate = new Date();
        this.events = [];
        // Output
        this.activeDateChange = new EventEmitter();
        this.dateSelected = new EventEmitter();
        // Public
        this.collapsed = false;
        this.dateStringFormControl = new FormControl();
        this.years = [];
        this.months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ].map(function (month, index) { return ({ name: month, key: index.toString() }); });
        this.hidePopover = new Subject();
        // Private
        this.componentNotDestroyed = new Subject();
        this.yearsShift = 0;
        this.bundledTransformFunction = this.bundledTransformFunction.bind(this);
        this.subscribeToFormControl();
    }
    /**
     * Lifecycle
     */
    DatePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextMenuService.show
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () { return _this.close(); });
        this.getPersistedState();
        this.generateYearsList();
    };
    DatePickerComponent.prototype.ngOnDestroy = function () {
        this.hidePopover.next();
        this.hidePopover.complete();
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    DatePickerComponent.prototype.ngOnChanges = function (changes) {
        if ('inputFormControl' in changes && this.inputFormControl) {
            this.subscribeToFormControl();
        }
    };
    /**
     * Actions
     */
    DatePickerComponent.prototype.handlePreviousClick = function () {
        this.setActiveDate(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() - 1, Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() - 1)))));
    };
    DatePickerComponent.prototype.handleNextClick = function () {
        this.setActiveDate(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() + 1, Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() + 1)))));
    };
    DatePickerComponent.prototype.handleChangeYearsViewport = function (direction) {
        this.yearsShift += direction === 'up' ? -1 : 1;
        this.generateYearsList();
    };
    DatePickerComponent.prototype.handleTodayClick = function () {
        this.setActiveDate(new Date());
    };
    DatePickerComponent.prototype.handleMonthClick = function (monthIndex) {
        this.setActiveDate(new Date(this.activeDate.getFullYear(), parseInt(monthIndex, 10), Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), parseInt(monthIndex, 10))))));
    };
    DatePickerComponent.prototype.handleYearClick = function (year) {
        this.setActiveDate(new Date(parseInt(year, 10), this.activeDate.getMonth(), Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(parseInt(year, 10), this.activeDate.getMonth())))));
    };
    DatePickerComponent.prototype.handleDateSelect = function (date) {
        if (this.maxDate && date >= this.maxDate) {
            return;
        }
        this.setActiveDate(date);
        this.inputFormControl.markAsDirty();
        this.inputFormControl.setValue(date);
        this.setDateStringFormControl();
        if (!this.inline) {
            this.close();
        }
    };
    DatePickerComponent.prototype.close = function () {
        this.hidePopover.next();
    };
    DatePickerComponent.prototype.handleCollapse = function () {
        this.inlineHeight = this.calendarContainer.nativeElement.offsetHeight + 'px';
        this.collapsed = !this.collapsed;
        if (this.inline && this.collapsedStateKey) {
            this.stateService.setCollapsed(this.collapsedStateKey, this.collapsed);
        }
    };
    DatePickerComponent.prototype.setDateStringFormControl = function () {
        this.dateStringFormControl.setValue(this.datePipe.transform(this.inputFormControl.value, 'MMM d, yyyy'));
    };
    DatePickerComponent.prototype.setActiveDate = function (date) {
        this.activeDate = date;
        this.activeDateChange.emit(date);
    };
    /**
     * Helpers
     */
    DatePickerComponent.prototype.generateYearsList = function () {
        var selectedYear = this.activeDate.getFullYear() + this.yearsShift * 20;
        this.years = tslib_1.__spread(Array(20).keys()).map(function (index) { return selectedYear - 10 + index; })
            .map(function (year) { return ({ name: year.toString(), key: year.toString() }); });
    };
    DatePickerComponent.prototype.getPersistedState = function () {
        var _this = this;
        if (this.inline && this.collapsedStateKey) {
            this.stateService.getCollapsed(this.collapsedStateKey)
                .pipe(takeUntil(this.componentNotDestroyed), distinctUntilChanged())
                .subscribe(function (value) { return _this.collapsed = value; });
        }
    };
    DatePickerComponent.prototype.bundledTransformFunction = function (event) {
        var chronoResult = Chrono.parse(event.fromValue);
        var date = this.bundledInputAppearance === 'start' ?
            (chronoResult && chronoResult[0] && chronoResult[0].start)
            :
                (chronoResult && ((chronoResult[0] && chronoResult[0].end) || (chronoResult[1] && chronoResult[1].start)));
        if (date &&
            date.knownValues &&
            (date.knownValues.day ||
                date.knownValues.month ||
                date.knownValues.year ||
                date.knownValues.weekday)) {
            var dateParts = tslib_1.__assign({}, date.impliedValues, date.knownValues);
            var dateObject = new Date(dateParts.year, dateParts.month - 1, dateParts.day);
            event.toValue = this.datePipe.transform(dateObject, 'MMM d, yyyy');
            event.formControlValue = dateObject;
            event.formControlValueChanged = !this.inputFormControl.value ||
                dateObject.getTime() !== this.inputFormControl.value.getTime();
            var index = 1;
            if (this.bundledInputAppearance === 'start' ||
                (this.bundledInputAppearance === 'end' && chronoResult[0].end)) {
                index = 0;
            }
            event.fromValueHighlightRange = [chronoResult[index].index, chronoResult[index].index + chronoResult[index].text.length];
        }
        else {
            event.toValue = null;
        }
    };
    DatePickerComponent.prototype.bundledValueCompareFunction = function (value, bundledValue) {
        return (value &&
            bundledValue &&
            value.getFullYear() === bundledValue.getFullYear() &&
            value.getMonth() === bundledValue.getMonth() &&
            value.getDate() === bundledValue.getDate());
    };
    DatePickerComponent.prototype.subscribeToFormControl = function () {
        var _this = this;
        if (this.inputFormControlSubscription) {
            this.inputFormControlSubscription.unsubscribe();
        }
        this.inputFormControlSubscription = this.inputFormControl.valueChanges
            .pipe(startWith(this.inputFormControl.value), distinctUntilChanged(function (a, b) { return (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()); }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (date) {
            if (!date) {
                return;
            }
            _this.setActiveDate(date);
            _this.dateSelected.emit(date);
            _this.setDateStringFormControl();
        });
    };
    /**
     * Helpers
     */
    DatePickerComponent.prototype.daysInMonth = function (date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
