import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { MessagesListStateService } from '@modules/mail/services/messages-list-state.service';
var MailLayoutComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function MailLayoutComponent(dynamicPanelService, changeDetector, route, searchService, messagesListStateService) {
        this.dynamicPanelService = dynamicPanelService;
        this.changeDetector = changeDetector;
        this.route = route;
        this.searchService = searchService;
        this.messagesListStateService = messagesListStateService;
        this.selectedMessages = [];
        this.selectedContact = null;
        this.selectedTopics = [];
        this.selectedRelatedContacts = [];
        this.selectedRelatedTopics = [];
        this.selectedTemporalExpressions = [];
        this.pannelsSelectedMessages = {
            pinned: [],
            main: [],
            extraSearch: [],
        };
        // Private
        this.componentNotDestroyed = new Subject();
    }
    /**
     * Component lifecycle
     */
    MailLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicPanelService.getContent()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (content) {
            _this.dynamicPanelContent = content;
            if (_this.dynamicPanelContent.type === 'attachments' || _this.dynamicPanelContent.type === 'linkedInfo') {
                _this.splitView.open();
            }
        });
        this.route.paramMap
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (params) {
            _this.folder = params.get('folder') !== 'search' ? params.get('folder') : null;
        });
        this.searchService.getSearchParams()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (params) {
            _this.searchParams = params;
        });
        combineLatest([
            this.route.paramMap
                .pipe(map(function (params) { return params.get('folder') !== 'search' ? params.get('folder') : null; }), startWith(null)),
            this.searchService.getSearchParams()
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), folder = _b[0], searchParams = _b[1];
            _this.messagesListStateService.setFilters({
                folder: folder,
                topics: searchParams ? searchParams.topics : null,
                tags: searchParams ? searchParams.tags : null,
                contacts: searchParams ? searchParams.contacts : null,
                keywords: searchParams ? searchParams.keywords : null,
                groupByThread: true,
                orderWithPinned: true
            });
        });
    };
    MailLayoutComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    MailLayoutComponent.prototype.selectMessages = function (messages, pannel) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(Object.keys(this.pannelsSelectedMessages)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                // recreate messages array for proper ngOnChange handling
                this.pannelsSelectedMessages[key] = key === pannel ? tslib_1.__spread(messages) : [];
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.selectedMessages = messages;
        this.changeDetector.detectChanges();
    };
    MailLayoutComponent.prototype.selectTopics = function (topics) {
        this.selectedTopics = topics ? topics.map(function (topic) { return topic.name; }) : [];
        if (topics && topics.length) {
            this.openKnowledgePanelSplit();
        }
    };
    MailLayoutComponent.prototype.selectTemporalExpressions = function (expression) {
        this.selectedTemporalExpressions = expression || [];
        if (expression && expression.length) {
            this.openKnowledgePanelSplit();
        }
    };
    MailLayoutComponent.prototype.selectContact = function (contact) {
        this.selectedContact = contact;
        if (contact) {
            this.openKnowledgePanelSplit();
        }
    };
    MailLayoutComponent.prototype.openKnowledgePanelSplit = function () {
        this.splitView.open();
    };
    return MailLayoutComponent;
}());
export { MailLayoutComponent };
