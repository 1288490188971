<div class="link-info-bar__wrapper">
  <mat-icon *ngIf="linkIcon" class="link-info-icon" svgIcon="link-thin"></mat-icon>
  <!-- Notes -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'note' : 'type'"
    class="link-icon note"
    svgIcon="note-small"
    popover
    [popoverContent]="notesPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Events -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'event' : 'type'"
    class="link-icon event"
    svgIcon="events"
    popover
    [popoverContent]="eventsPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Projects -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'project' : 'type'"
    class="link-icon project"
    svgIcon="project"
    popover
    [popoverContent]="projectsPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Tasks -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'task' : 'type'"
    class="link-icon task"
    svgIcon="tasks"
    popover
    [popoverContent]="tasksPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Messages -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'message' : 'type'"
    class="link-icon mail"
    svgIcon="mail"
    popover
    [popoverContent]="messagesPopover"

    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Groups -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'group' : 'type'"
    class="link-icon group"
    svgIcon="groups"
    popover
    [popoverContent]="groupsPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
  <!-- Contacts -->
  <mat-icon
    *ngIf="linkedInfo | includesBy: 'contact' : 'type'"
    class="link-icon contact"
    svgIcon="contacts"
    popover
    [popoverContent]="contactsPopover"
    [popoverArrow]="true"
    popoverPlacement="right"
    popoverTrigger="hover"
    [popoverShowUntil]="popoverClose" 
  ></mat-icon>
</div>


<!-- Messages popover -->
<ng-template #messagesPopover>
  <app-linked-info-message-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-message-list>
</ng-template>

<!-- Events popover -->
<ng-template #eventsPopover>
  <app-linked-info-event-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-event-list>
</ng-template>

<!-- Projects popover -->
<ng-template #projectsPopover>
  <app-linked-info-project-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-project-list>
</ng-template>

<!-- Tasks popover -->
<ng-template #tasksPopover>
  <app-linked-info-task-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-task-list>
</ng-template>

<!-- Notes popover -->
<ng-template #notesPopover>
  <app-linked-info-note-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-note-list>
</ng-template>

<!-- Groups popover -->
<ng-template #groupsPopover>
  <app-linked-info-group-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-group-list>
</ng-template>

<!-- Contacts popover -->
<ng-template #contactsPopover>
  <app-linked-info-contact-list [linkedInfo]="linkedInfo" (close)="closePopover()"></app-linked-info-contact-list>
</ng-template>
