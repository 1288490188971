import * as tslib_1 from "tslib";
// Common
import { OnDestroy, OnInit, SimpleChanges, OnChanges, NgZone } from '@angular/core';
// RxJS
import { interval, BehaviorSubject, merge } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';
// Env
import { environment } from '@environment';
// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';
var GroupsListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GroupsListComponent, _super);
    /**
     * Constructor
     */
    function GroupsListComponent(groupsService, contactsStateService, linkedInfoService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.groupsService = groupsService;
        _this.contactsStateService = contactsStateService;
        _this.linkedInfoService = linkedInfoService;
        _this.ngZone = ngZone;
        _this.placeholderText = 'You have no groups';
        _this.defaultFilters = {};
        // Public
        _this.itemHeight = 92;
        _this.isHover = false;
        _this.showCountView = new BehaviorSubject(true);
        _this.contactsStateService.getSelectedGroups()
            .pipe(takeUntil(_this.componentNotDestroyed))
            .subscribe(function (groups) {
            _this.selectedItems = groups;
        });
        return _this;
    }
    /**
     * Component lifecycle
     */
    GroupsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showCountView
            .pipe(filter(function (value) { return !!value; }), debounceTime(5000), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.showCountView.next(false);
        });
        merge(
        // Global refresh button
        this.contactsStateService.getRefreshAll(), 
        // Subscribe for linked info updates
        this.linkedInfoService.getLinkedInfoUpdate(), 
        // Make automatic updates for new task
        interval(environment.messageFetchInterval)
            .pipe(filter(function () { return !_this.loading && _this.selectedOrder && _this.selectedOrder.key === 'date'; })), this.groupsService.createNewGroup, this.groupsService.updatedGroup, this.groupsService.deletedGroup)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.refreshCurrentItems();
        });
        _super.prototype.ngOnInit.call(this);
    };
    GroupsListComponent.prototype.ngOnChanges = function (changes) {
        this.showCountView.next(true);
        if ('selectedOrder' in changes && this.selectedOrder) {
            this.resetItems();
        }
        if ('defaultFilters' in changes) {
            this.resetItems();
        }
        if ('scrollPosition' in changes && this.scrollPosition !== null) {
            this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
        }
    };
    /**
     * Actions
     */
    GroupsListComponent.prototype.getItems = function (offset, limit) {
        return this.groupsService
            .getGroups(tslib_1.__assign({}, this.defaultFilters, { limit: limit,
            offset: offset }))
            .pipe(map(function (_a) {
            var groups = _a.groups, count = _a.count;
            return ({ items: groups, count: count });
        }));
    };
    GroupsListComponent.prototype.resetItems = function () {
        this.contactsStateService.setSelectedGroups([]);
        _super.prototype.resetItems.call(this);
    };
    GroupsListComponent.prototype.selectItem = function (group, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        _super.prototype.selectItem.call(this, group, event, selectAll);
        this.contactsStateService.setSelectedGroups(this.selectedItems);
        if (this.selectedItems.length === 1) {
            this.contactsStateService.setMainViewGroup(this.selectedItems[0]);
            this.contactsStateService.setMainView('group-form');
        }
    };
    GroupsListComponent.prototype.compareItems = function (item1, item2) {
        return item1 && item2 && item1['id'] === item2['id'];
    };
    return GroupsListComponent;
}(InfinityScrollListComponent));
export { GroupsListComponent };
