<div class="quick-form-quick-actions">
  <!--  Today  -->
  <mat-icon
    class="quick-form-quick-actions-icon"
    [class.active]="startDateFormControl.value | checkDate: 'today'"
    svgIcon="controls-button-today"
    (click)="handleQuickSetStartDate('today')"
    matTooltip="Today"
  ></mat-icon>

  <!--  Tomorrow  -->
  <mat-icon
    class="quick-form-quick-actions-icon"
    [class.active]="startDateFormControl.value | checkDate: 'tomorrow'"
    svgIcon="controls-button-tomorrow"
    (click)="handleQuickSetStartDate('tomorrow')"
    matTooltip="Tomorrow"
  ></mat-icon>

  <!--  Next Monday  -->
  <mat-icon
    class="quick-form-quick-actions-icon"
    [class.active]="startDateFormControl.value | checkDate: 'nextMonday'"
    svgIcon="controls-button-next-week"
    (click)="handleQuickSetStartDate('nextMonday')"
    matTooltip="Next Monday"
  ></mat-icon>

  <!--  Calendar  -->
  <div class="quick-form-quick-actions-icon">
    <mat-icon
      *ngIf="showQuickActionsForm"
      svgIcon="controls-button-calendar"
      popover
      popoverAllowedOutsideSelectorsClick="body"
      popoverPlacement="right"
      [popoverContent]="quickFormQuickActionsFormPopoverTemplate"
      popoverTrigger="click"
      [popoverShowUntil]="hidePopoverObservable"
      [popoverArrow]="false"
      matTooltip="Calendar"
    ></mat-icon>
  </div>

  <!--  Priority  -->
  <div class="quick-form-quick-actions-icon">
    <app-priority-input
      [inputFormControl]="priorityFormControl"
      [appearanceType]="showQuickActionsForm && 'expandable'"
    ></app-priority-input>
  </div>
</div>

<ng-template #quickFormQuickActionsFormPopoverTemplate>
  <app-quick-actions-form
    [startDateFormControl]="startDateFormControl"
    [endDateFormControl]="endDateFormControl"
    [startTimeFormControl]="startTimeFormControl"
    [endTimeFormControl]="endTimeFormControl"
    [priorityFormControl]="priorityFormControl"
    [showQuickActions]="false"
    (close)="handleClosePopover()"
  ></app-quick-actions-form>
</ng-template>
