<app-content-menu class="content-menu">
  <app-content-menu-item
    *ngFor="let folder of defaultFolders"
    appDroppable
    [appDroppablePredicate]="allowDropFolder(folder)"
    (appDroppableEnter)="dndEnter($event, folder)"
    (appDroppableLeave)="dndLeave(folder)"
    (appDroppableDrop)="dndDrop($event, folder)"
    [hover]="folder?.id === dragHoverFolderId"
    icon="folder-{{folder.icon}}"
    [title]="folder.name"
    [contextMenu]="(folder?.name === 'Spam' || folder?.name === 'Trash') ? clearAllFolderContext : defaultFolderContext"
    [contextMenuSubject]="folder"
    [active]=rla.isActive
    [badge]="folder.unreadMails"
    (click)="openFolder(folder, $event)"
    [routerLink]="[baseRouter, folder.id]" routerLinkActive="active" #rla="routerLinkActive"
  >
    <ng-container *ngIf="folder.subFolders">
      <app-content-menu-item
        *ngFor="let subFolder of folder.subFolders"
        appDroppable
        [appDroppablePredicate]="allowDropFolder(subFolder)"
        (appDroppableEnter)="dndEnter($event, subFolder)"
        (appDroppableLeave)="dndLeave(subFolder)"
        (appDroppableDrop)="dndDrop($event, subFolder)"
        [hover]="subFolder?.id === dragHoverFolderId"
        [title]="subFolder.name"
        [contextMenu]="defaultFolderContext"
        [contextMenuSubject]="subFolder"
        [active]="rla.isActive"
        [badge]="subFolder.unreadMails"
        (click)="openFolder(subFolder, $event)"
        [routerLink]="[baseRouter, subFolder.id]" routerLinkActive="active" #rla="routerLinkActive"
      ></app-content-menu-item>
    </ng-container>
  </app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-item
    icon="folder-folder"
    title="Folders"
    [active]="userFolders | includesBy : selectedFolder : 'id'"
    (createIconSelected)="createFolder()"
    [templateOutlet]="userFoldersList"
    [templateOutletContext]="{ folders: userFolders }"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <!-- Pellets -->
  <app-content-menu-pellets
    collapsedStateKey="mailsSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <!-- Tags -->
  <app-content-menu-tags
    collapsedStateKey="mailsSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>


<!-- Context menus -->
<context-menu #defaultFolderContext>
  <ng-template contextMenuItem (execute)="readAllMessages($event.item)">
    Mark all as read
  </ng-template>
</context-menu>

<context-menu #clearAllFolderContext>
  <ng-template contextMenuItem (execute)="readAllMessages($event.item)" let-item >
    Mark all as read
  </ng-template>
  <ng-template contextMenuItem (execute)="deleteAll($event.item)" let-item>
    {{ item?.name === 'Trash' ? "Empty All Trash" : "Delete All Spam" }}
  </ng-template>
</context-menu>

<context-menu #folderContext>
  <ng-template contextMenuItem (execute)="pinFolder($event.item)" let-item>
    {{ item.pinned ? 'Unpin folder' : 'Pin folder' }}
  </ng-template>
  <ng-template contextMenuItem (execute)="readAllMessages($event.item)">
    Mark all as read
  </ng-template>

  <ng-template contextMenuItem (execute)="renameFolder($event.item)">
    Rename folder
  </ng-template>

  <ng-template contextMenuItem (execute)="deleteFolder($event.item)">
    Delete folder
  </ng-template>

  <ng-template contextMenuItem (execute)="openFolderInExploreScreen($event.item)">
    Go to Explore
  </ng-template>
</context-menu>

<ng-template #userFoldersList let-folders="folders" let-parent="parent">
  <app-content-menu-item
    *ngFor="let folder of folders; trackBy: foldersTrackByFunction"
    [parent]="parent"
    [templateOutlet]="folder.subFolders && folder.subFolders.length > 0 && userFoldersList"
    [templateOutletContext]="{folders: folder.subFolders}"
    appDroppable
    (appDroppableEnter)="dndEnter($event, folder)"
    (appDroppableLeave)="dndLeave(folder)"
    (appDroppableDrop)="dndDrop($event, folder)"
    [contextMenu]="folderContext"
    [contextMenuSubject]="folder"
    [hover]="folder?.id === dragHoverFolderId"
    [active]="rla.isActive"
    [title]="folder.name"
    [badge]="folder.unreadMails"
    [pinned]="folder.pinned"
    (click)="openFolder(folder, $event)"
    [routerLink]="[baseRouter, folder.id]"
    routerLinkActive="active"
    #rla="routerLinkActive"
  ></app-content-menu-item>
</ng-template>
