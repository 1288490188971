// Common
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';

@Component({
  selector: 'app-font-name-selector',
  templateUrl: './font-name-selector.component.html',
  styleUrls: ['./font-name-selector.component.less']
})
export class FontNameSelectorComponent implements OnInit, OnChanges {

  // Input
  @Input() currentFont: string;

  // Output
  @Output() select = new EventEmitter<string>();

  // Public
  public selectedOption: DropdownOption;
  public fontNamesOptions: DropdownOption[] = [
    {name: 'Andale Mono', key: 'andale mono,monospace'},
    {name: 'Arial', key: 'arial,helvetica,sans-serif'},
    {name: 'Arial Black', key: 'arial black,sans-serif'},
    {name: 'Book Antiqua', key: 'book antiqua,palatino,serif'},
    {name: 'Comic Sans MS', key: 'comic sans ms,sans-serif'},
    {name: 'Courier New', key: 'courier new,courier,monospace'},
    {name: 'Georgia', key: 'georgia,palatino,serif'},
    {name: 'Helvetica', key: 'helvetica,arial,sans-serif'},
    {name: 'Impact', key: 'impact,sans-serif'},
    {name: 'Symbol', key: 'symbol'},
    {name: 'Tahoma', key: 'tahoma,arial,helvetica,sans-serif'},
    {name: 'Terminal', key: 'terminal,monaco,monospace'},
    {name: 'Times New Roman', key: 'times new roman,times,serif'},
    {name: 'Trebuchet MS', key: 'trebuchet ms,geneva,sans-serif'},
    {name: 'Verdana', key: 'verdana,geneva,sans-serif'},
    {name: 'Webdings', key: 'webdings'},
    {name: 'Wingdings', key: 'wingdings,zapf dingbats'},
  ];

  // Private
  // A list of fonts that must be in a font family for the font to be recognised as the system stack
  // Note: Don't include 'BlinkMacSystemFont', as Chrome on Mac converts it to different names
  private systemFonts = [ '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'sans-serif' ];

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('currentFont' in changes) {
      this.findCurrentFont();
    }
  }

  /**
   * Methods
   */

  // Split the fonts into an array and strip away any start/end quotes
  splitFonts(fontFamily: string): string[] {
    const fonts = fontFamily.split(/\s*,\s*/);
    return fonts.map(font => font.replace(/^['"]+|['"]+$/g, ''));
  }

  // The system font stack will be similar to the following. (Note: each has minor variants)
  // Oxide: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // Bootstrap: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  //            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // Wordpress: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  isSystemFont(fontFamily: string): boolean {
    const matchesSystemStack = (): boolean => {
      const fonts = this.splitFonts(fontFamily.toLowerCase());
      return this.systemFonts.every(font => fonts.indexOf(font.toLowerCase()) > -1);
    };
    return fontFamily.indexOf('-apple-system') === 0 && matchesSystemStack();
  }

  getFirstFont(fontFamily: string): string {
    return fontFamily ? this.splitFonts(fontFamily)[0] : '';
  }

  findCurrentFont() {
    const font = this.currentFont ? this.currentFont.toLowerCase() : '';
    let currentOption = this.fontNamesOptions.find(item => item.key === font || this.getFirstFont(item.key) === this.getFirstFont(font));
    if (!currentOption && this.isSystemFont(font)) {
      currentOption = {name: 'System Font', key: font};
    }
    if (!currentOption) {
      currentOption = {name: 'None', key: ''};
    }
    this.selectedOption = currentOption;
  }

  /**
   * Actions
   */

  selected(option: DropdownOption) {
    this.select.emit(option.key);
  }

}
