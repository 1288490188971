<!-- Avatar -->
<div
  class="user-image"
  container="body"
  [class.shadow]="!avatar || avatar.isNotFound"
  [ngStyle]="{
    'width.px': size,
    'height.px': size,
    'line-height.px': size,
    'background-color': !avatar || avatar.isNotFound ? color : '',
    'cursor': usePopover && contact ? 'pointer' : 'default'
  }"
  popover
  [popoverContent]="userPopover"
  [popoverDisabled]="!usePopover"
  popoverTrigger="hover"
  popoverPlacement="top"
>
  <p
    class="initials text-no-select"
    [ngStyle]="{
      'fontSize.px': size / 2
    }"
  >
    {{ initials }}
  </p>

  <img class="photo"
    *ngIf="avatar && !avatar.isNotFound"
    [style.opacity]="opacity"
    [src]="avatar?.url || ''"
    (load)="saveAvatar()"
    (error)="getAvatar()"
   />
  <ng-content></ng-content>
</div>
<!-- Popover -->
<ng-template #userPopover>
  <div class="contact-popover">
    <div class="name">{{contact?.name}}</div>
    <div class="email">{{contact?.email}}</div>
  </div>
</ng-template>
