// Common
import { Injectable } from '@angular/core';

// RX
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Contact } from '../types/contact';
import { Group } from '../types/group';
import { ContactsMainView } from '../types/contacts-main-view';
import { ContactsSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { ListState } from '@modules/contacts/types/list-state';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Injectable()
export class ContactsStateService {
  // Private
  private group = new BehaviorSubject<Group>(null);
  private selectedContacts = new BehaviorSubject<Contact[]>([]);
  private selectedGroups = new BehaviorSubject<Group[]>([]);
  private refreshAll = new Subject<void>();
  private mainView = new BehaviorSubject<ContactsMainView>('empty');
  private mainViewContact = new BehaviorSubject<Contact>(null);
  private mainViewGroup = new BehaviorSubject<Group>(null);
  private contactsFilters = new BehaviorSubject<ContactsFilters>({});

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
  ) {
    this.setMainView(this.stateService.contactsMainView);
  }

  /**
   * Actions
   */

  setGroup(currentGroup: Group) {
    this.group.next(currentGroup);
  }

  getGroup(): Observable<Group> {
    return this.group.asObservable();
  }

  setSelectedContacts(events: Contact[]) {
    this.selectedContacts.next(events);
  }

  getSelectedContacts(): Observable<Contact[]> {
    return this.selectedContacts.asObservable();
  }

  setSelectedGroups(groups: Group[]) {
    this.selectedGroups.next(groups);
  }

  getSelectedGroups(): Observable<Group[]> {
    return this.selectedGroups.asObservable();
  }

  triggerRefreshAll(): void {
    this.refreshAll.next();
  }

  getRefreshAll(): Observable<void> {
    return this.refreshAll.asObservable();
  }

  getMainView(): Observable<ContactsMainView> {
    return this.mainView.asObservable();
  }

  getMainViewContact(): Observable<Contact> {
    return this.mainViewContact.asObservable();
  }

  setMainView(view: ContactsMainView) {
    this.mainView.next(view);
    this.stateService.contactsMainView = view;
  }

  setMainViewContact(contact: Contact) {
    this.mainViewContact.next(
      new Contact({
        ...contact,
        groupId: contact.groupId || (this.group.value && this.group.value.id) || null
      })
    );
  }

  getMainViewGroup(): Observable<Group> {
    return this.mainViewGroup.asObservable();
  }

  setMainViewGroup(group: Group) {
    this.mainViewGroup.next(group);
  }

  openContactForm(contact: Contact) {
    this.setMainViewContact(contact);
    this.setMainView('contact-form');
  }

  openGroupForm(group: Group) {
    this.setMainViewGroup(group);
    this.setMainView('group-form');
  }

  setContactsFilters(filters: ContactsFilters) {
    this.contactsFilters.next(filters);
  }

  getContactsFilters(): Observable<ContactsFilters> {
    return this.contactsFilters.asObservable();
  }

  getSidebarFilters(): Observable<ContactsFilters | GroupsFilters> {
    return this.stateService
      .getSidebarFilters('contacts')
      .pipe(
        map((value: ContactsSidebarFilterKey) => {
          switch (value) {
            case 'archived':
              return { archived: true };
            case 'deleted':
              return { deleted: true };
            case 'contacts':
            case 'vip':
            case 'flagged':
            case 'upcoming':
            case 'frequent':
            case 'recent':
            case 'other':
            case 'groups':
              return {};
            default:
              return {};
          }
        })
      );
  }

  getListState(): Observable<ListState> {
    return this.stateService
      .getSidebarFilters('contacts')
      .pipe(
        map((value: ContactsSidebarFilterKey) => {
          switch (value) {
            case 'archived':
            case 'deleted':
            case 'vip':
            case 'flagged':
            case 'upcoming':
            case 'frequent':
            case 'recent':
            case 'other':
              return 'tabs';
            case 'groups':
              return 'groups';
            case 'contacts':
              return 'contacts';
            default:
              return 'contacts';
          }
        })
      );
  }
}
