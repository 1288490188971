// Common
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { pairwise, startWith, takeUntil, tap, debounceTime } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { EventsStateService } from '@modules/events/services/events-state.service';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Calendar } from '@modules/events/types/calendar';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-event-form-popover',
  templateUrl: './event-form-popover.component.html',
  styleUrls: ['./event-form-popover.component.less'],
})

export class EventFormPopoverComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() event: CalendarEvent;

  // Outputs
  @Output() close = new EventEmitter<void>();
  @Output() eventChange = new EventEmitter<CalendarEvent>();

  // Public
  public eventForm: FormGroup;
  public saveInProgress = false;
  public bundledGroup = 'event-form-popover';
  public calendarOptions: DropdownSelectItem[] = [];

  // Private
  private componentNotDestroyed = new Subject();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private eventsStateService: EventsStateService,
  ) {
    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendars: Calendar[]) => {
        this.calendarOptions = calendars.map((calendar: Calendar) => ({
          title: calendar.name,
          value: calendar.id,
        }));
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    const event = this.event || new CalendarEvent();
    this.eventForm = event.asFormGroup();

    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendars: Calendar[]) => {
        const writableCalendar = calendars.find(calendar => !calendar.readOnly);
        if (writableCalendar) {
          this.eventForm.patchValue({calendarId: writableCalendar.id});
        }
      });

    this.eventForm.valueChanges
      .pipe(
        startWith(this.eventForm.value as Object),
        pairwise(),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([previous, current]) => {
        if (!this.datesEqual(previous.startDate, current.startDate) && current.startDate && this.eventForm.controls.endDate.pristine) {
          this.eventForm.patchValue({ endDate: current.startDate });
        }

        if (!current.startTime && previous.startTime) {
          current.startTime = previous.startTime;
        }

        if (current.startTime && previous.startTime && !this.datesEqual(previous.startTime, current.startTime)) {
          const timeDifference = current.startTime.getTime() - previous.startTime.getTime();
          this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
        }

        if (current.reminder && current.reminder !== previous.reminder) {
          const startTime: Date = this.eventForm.controls.startTime.value;
          this.eventForm.patchValue({
            endDate: this.eventForm.controls.startDate.value,
            endTime: startTime && new Date(startTime.getTime() + 900000) // + 15 minutes for right event representing on calendar
          }, { emitEvent: false });
        }
      });

    this.eventForm.valueChanges
      .pipe(
        debounceTime(700),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.eventChange.emit(CalendarEvent.fromFormGroup(this.eventForm));
      });
  }

  ngOnDestroy() {
    this.close.emit();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.eventForm.invalid) {
      return;
    }
    const event = CalendarEvent.fromFormGroup(this.eventForm);
    this.saveInProgress = true;

    const saveRequest: Observable<CalendarEvent> = this.eventForm.get('id').value ?
      this.calendarService.editEvent(event) : this.calendarService.createEvent(event);

    saveRequest
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleCancel() {
    this.close.emit();
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleCopy() {
    this.eventsStateService.addToClipboard([CalendarEvent.fromFormGroup(this.eventForm)], 'copy');
    this.close.emit();
  }

  moreOptions() {
    this.eventsStateService.setMainViewEvent(CalendarEvent.fromFormGroup(this.eventForm));
    this.eventsStateService.setMainView('event-form');
    this.close.emit();
  }

  private datesEqual(date1: Date, date2: Date) {
    return date1 && date2 && date1.getTime() === date2.getTime();
  }
}
