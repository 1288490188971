// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { TopicModule } from '../topic/topic.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ElementsModule } from '../elements/elements.module';
import { ContentMenuModule } from '../content-menu/content-menu.module';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { DropdownModule } from '@modules/dropdown/dropdown.module';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { ContextMenuModule } from '@modules/context-menu/context-menu.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { TagModule } from '@modules/tag/tag.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatTabsModule } from '@angular/material';

// Services
import { ContactsService } from './services/contacts.service';
import { GroupsService } from './services/groups.service';
import { ContactsStateService } from './services/contacts-state.service';

// Components
import { ContactsSideBarComponent } from './components/side-bar/side-bar.component';
import { ContactsListContainerComponent } from './components/list-container/list-container.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { GroupsListComponent } from './components/groups-list/groups-list.component';
import { GroupFormContainerComponent } from './components/group-form-container/group-form-container.component';
import { GroupFormComponent } from './components/group-form/group-form.component';
import { ContactFormContainerComponent } from './components/contact-form-container/contact-form-container.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactsMainViewComponent } from './components/main-view/main-view.component';
import { ContactTopicsComponent } from './components/form-controls/contact-topics/contact-topics.component';
import { GroupTopicsComponent } from './components/form-controls/group-topics/group-topics.component';

// Pipes
import { TitleBySidebarFilterPipe } from '@modules/contacts/pipes/title-by-sidebar-filter.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    BundledInputsModule,
    ContentMenuModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    ScrollingModule,
    TopicModule,
    CommonModule,
    QuickFormsModule,
    ScrollModule,
    MatTooltipModule,
    DragNDropModule,
    DropdownModule,
    NgxContextMenuModule.forRoot(),
    ContextMenuModule,
    PipesModule,
    PopoverModule,
    TagModule,
    LinkedInfoModule,
    MatTabsModule,
  ],
  declarations: [
    ContactsSideBarComponent,
    ContactsListContainerComponent,
    ContactsListComponent,
    GroupsListComponent,
    ContactsMainViewComponent,
    GroupFormContainerComponent,
    GroupFormComponent,
    ContactFormContainerComponent,
    ContactFormComponent,
    GroupTopicsComponent,
    ContactTopicsComponent,

    TitleBySidebarFilterPipe,
  ],
  exports: [
    ContactsSideBarComponent,
    ContactsListComponent,
    GroupsListComponent,
    ContactsListContainerComponent,
    ContactsMainViewComponent,
    GroupFormContainerComponent,
    GroupFormComponent,
    ContactFormContainerComponent,
    ContactFormComponent,

    TitleBySidebarFilterPipe,
  ],
  providers: [
    ContactsService,
    GroupsService,
    ContactsStateService,
  ],
  entryComponents: [

  ]
})
export class ContactsModule { }
