import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';
import { CalendarEvent } from '@modules/events/types/calendar-event';
var LinkInfoEventSuggestionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoEventSuggestionsComponent, _super);
    /**
     * Constructor
     */
    function LinkInfoEventSuggestionsComponent(modalService, calendarService, linkedInfoService) {
        var _this = _super.call(this, linkedInfoService) || this;
        _this.modalService = modalService;
        _this.calendarService = calendarService;
        _this.linkedInfoService = linkedInfoService;
        return _this;
    }
    /**
     * Methods
     */
    //  Override method LinkInfoItemSuggestions
    LinkInfoEventSuggestionsComponent.prototype.getSuggestions = function (inputValue) {
        if (inputValue && inputValue.length) {
            return this.calendarService.getEvents({
                title: inputValue,
                order: 'date',
                limit: 10,
                offset: 0
            })
                .pipe(map(function (response) { return response.events || []; }));
        }
        return of([]);
    };
    //  Override method LinkInfoItemSuggestions
    LinkInfoEventSuggestionsComponent.prototype.openCreateModal = function () {
        var event = new CalendarEvent();
        event.linkedInfo = this.linkedInfo;
        this.modalService.calendarEventForm(event);
    };
    return LinkInfoEventSuggestionsComponent;
}(LinkInfoItemSuggestions));
export { LinkInfoEventSuggestionsComponent };
