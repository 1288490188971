import * as tslib_1 from "tslib";
// Common
import { OnInit, EventEmitter, ElementRef, ChangeDetectorRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
// RxJS
import { Subject, timer } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';
import { MessagesListStateService } from '@modules/mail/services/messages-list-state.service';
// Types
import { KnowledgeGraphRelatedInfo } from '../../../mail/types/knowledge-graph-related-info';
import { Recipient } from '../../../mail/types/recipient';
var MailPanelComponent = /** @class */ (function () {
    function MailPanelComponent(knowledgeGraphService, modalService, accountService, ga, calendarService, changeDetector, stateService, mailService, tasksService, messagesListStateService) {
        var _this = this;
        this.knowledgeGraphService = knowledgeGraphService;
        this.modalService = modalService;
        this.accountService = accountService;
        this.ga = ga;
        this.calendarService = calendarService;
        this.changeDetector = changeDetector;
        this.stateService = stateService;
        this.mailService = mailService;
        this.tasksService = tasksService;
        this.messagesListStateService = messagesListStateService;
        this.contactView = false;
        this.contactChange = new EventEmitter();
        this.contactOnly = false;
        this.selectedRelatedContactsChange = new EventEmitter();
        this.selectedRelatedTopics = [];
        this.selectedRelatedTopicsChange = new EventEmitter();
        this.relatedInfoContainers = {
            topics: null,
            connections: null
        };
        this.relatedInfo = new KnowledgeGraphRelatedInfo();
        this.orderOptions = {
            messages: [
                { name: 'Date', key: 'date' },
                { name: 'Subject', key: 'subject' },
                { name: 'Thread', key: 'thread' },
                { name: 'Sender', key: 'sender' },
                { name: 'Attachments', key: 'attachments' }
            ],
            connections: [
                { name: 'Relevance', key: 'relevance' },
                { name: 'A-Z', key: 'name-asc' },
                { name: 'Z-A', key: 'name-desc' },
            ],
            topics: [
                { name: 'Relevance', key: 'relevance' },
                { name: 'A-Z', key: 'name-asc' },
                { name: 'Z-A', key: 'name-desc' },
            ],
            files: [
                { name: 'Relevance', key: 'relevance' },
                { name: 'A-Z', key: 'name-asc' },
                { name: 'Z-A', key: 'name-desc' },
            ],
            hyperlinks: [
                { name: 'Relevance', key: 'relevance' },
                { name: 'A-Z', key: 'name-asc' },
                { name: 'Z-A', key: 'name-desc' },
            ],
        };
        this.reletedInfoTabs = ['messages', 'files', 'hyperlinks'];
        this.pageSizes = {
            topics: 45,
            connections: 7,
            allFiles: 10,
            images: 9,
            documents: 6,
            videos: 2,
            hyperlinks: 10
        };
        this.pageOffsets = {
            topics: 0,
            connections: 0,
            messages: 0,
            allFiles: 0,
            images: 0,
            documents: 0,
            videos: 0,
            hyperlinks: 0
        };
        this.loaders = {
            topics: false,
            topicsTop: false,
            connections: false,
            connectionsTop: false,
            allFiles: false,
            images: false,
            videos: false,
            documents: false,
            hyperlinks: false
        };
        this.errors = {
            topics: false,
            topicsTop: false,
            connections: false,
            connectionsTop: false,
            allFiles: false,
            images: false,
            videos: false,
            documents: false,
            hyperlinks: false
        };
        this.splitAreaSize = {
            topics: null,
            contacts: null
        };
        // Private
        this.initialized = false;
        this.componentNotDestroyed = new Subject();
        this.loadingSubjects = {
            topics: new Subject(),
            connections: new Subject(),
            messages: new Subject(),
            allFiles: new Subject(),
            images: new Subject(),
            videos: new Subject(),
            documents: new Subject(),
            hyperlinks: new Subject(),
        };
        this.stateService.getTabsState('kpMails')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (value) {
            _this.reletedInfoSelectedTab = value;
        });
        this.mode = this.stateService.selectedKnowledgePaneContactContext;
        this.orders = {
            messages: null,
            connections: this.stateService.sortKnowledgePaneRelatedConnections,
            topics: this.stateService.sortKnowledgePaneRelatedTopics,
            files: this.stateService.sortKnowledgePaneRelatedFiles,
            hyperlinks: this.stateService.sortKnowledgePaneRelatedHyperlinks,
        };
    }
    Object.defineProperty(MailPanelComponent.prototype, "message", {
        get: function () {
            return this.messageValue;
        },
        set: function (message) {
            var _this = this;
            if (this.messageValue !== message) {
                this.messageValue = message;
                this.topicsValue = [];
                this.selectedRelatedTopics = [];
                this.selectedRelatedContacts = [];
                timer(0).subscribe(function () {
                    _this.selectedRelatedTopicsChange.emit(_this.selectedRelatedTopics);
                    _this.selectedRelatedContactsChange.emit(_this.selectedRelatedContacts);
                });
                if (this.initialized) {
                    var contactToSelect = (this.contactView && (message && message.from)) ? message.from : null;
                    this.selectContact(contactToSelect);
                }
                this.updateDropdownParticipants();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailPanelComponent.prototype, "topics", {
        get: function () {
            return this.topicsValue;
        },
        set: function (topics) {
            var _this = this;
            if (!isEqual(this.topicsValue, topics)) {
                this.topicsValue = topics || [];
                this.selectedRelatedTopics = [];
                this.selectedRelatedContacts = [];
                timer(0).subscribe(function () {
                    _this.selectedRelatedTopicsChange.emit(_this.selectedRelatedTopics);
                    _this.selectedRelatedContactsChange.emit(_this.selectedRelatedContacts);
                });
                if (this.initialized && this.mode === 'context') {
                    this.contactView = false;
                    this.loadRelatedInfo();
                    this.stateService.selectedKnowledgePaneContactContext = this.mode;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailPanelComponent.prototype, "contact", {
        set: function (contact) {
            if (this.selectedContact === contact) {
                return;
            }
            if (this.initialized) {
                this.selectContact(contact);
            }
            else {
                this.selectedContact = contact;
                this.updateDropdownParticipants();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailPanelComponent.prototype, "relatedTopicsContainer", {
        set: function (container) {
            this.relatedInfoContainers.topics = container;
            this.loadMoreRelatedInfoIfNeeded('topics');
            this.changeDetector.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MailPanelComponent.prototype, "relatedConnectionsContainer", {
        set: function (container) {
            this.relatedInfoContainers.connections = container;
            this.loadMoreRelatedInfoIfNeeded('connections');
            this.changeDetector.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Component Lifecycle
     */
    MailPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setMessagesListFilters();
        this.messagesListStateService.getFilters()
            .pipe(map(function (filters) { return filters.order; }), filter(function (order) { return !_this.orders.messages || order !== _this.orders.messages.key; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (order) { return _this.orders.messages =
            _this.orderOptions.messages.find(function (option) { return option.key === order; }) ||
                { name: '', key: order }; });
        // Subscribe for global notification of message moved
        // TODO: change that approach to may be propagate the event with
        // folder message moved from and folder it moved to for proper cache update
        this.mailService.onMoveToFolder
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (message) {
            if (_this.relatedInfo
                && _this.relatedInfo.messages
                && _this.relatedInfo.messages.data.find(function (m) { return m.id === message.id; })
                && _this.messageValue) {
                // TODO remove when backend will be updating immediately
                setTimeout(function () {
                    _this.loadRelatedInfo();
                }, 1000);
            }
        });
        if (this.contactOnly) {
            this.mode = 'all';
            this.stateService.selectedKnowledgePaneContactContext = this.mode;
        }
        this.selectedContact = this.selectedContact || null;
        this.selectedRelatedTopics = [];
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.initialized = true;
        this.accountService.getAccount()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (account) {
            _this.account = account;
            if (_this.message || (_this.contactOnly && _this.selectedContact)) {
                _this.loadRelatedInfo();
            }
        });
        this.setDefaultSplitAreaSize();
        // Show calendar tab when creating a new event from a message
        this.calendarService.createNewEvent
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (event) {
            _this.calendarEvent = event;
        });
        // Show tasks tab when creating a new task from a message
        this.tasksService.taskCreated
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (task) {
            _this.task = task;
        });
    };
    MailPanelComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if ('baseTopics' in changes) {
            this.selectedRelatedTopics = [];
            this.selectedRelatedContacts = [];
            this.relatedMessageTopics = this.message && this.mode === 'context' && this.baseTopics
                ? this.baseTopics.map(function (topic) { return topic.name; })
                : null;
            timer(0).subscribe(function () {
                _this.selectedRelatedTopicsChange.emit(_this.selectedRelatedTopics);
                _this.selectedRelatedContactsChange.emit(_this.selectedRelatedContacts);
            });
            this.loadRelatedInfo();
        }
    };
    MailPanelComponent.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(Object.keys(this.loadingSubjects)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                this.loadingSubjects[key].next();
                this.loadingSubjects[key].complete();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Split area size
     * - Set default state
     * - Handle drag changes
     */
    MailPanelComponent.prototype.setDefaultSplitAreaSize = function () {
        this.splitAreaSize.topics = this.stateService.splitKnowledgePanelTopics;
        this.splitAreaSize.contacts = this.stateService.splitKnowledgePanelContacts;
    };
    MailPanelComponent.prototype.onDragEnd = function (splitSizes) {
        // Update values
        this.splitAreaSize.topics = splitSizes[0];
        this.splitAreaSize.contacts = splitSizes[1];
        // Save to local storage
        this.stateService.splitKnowledgePanelTopics = this.splitAreaSize.topics;
        this.stateService.splitKnowledgePanelContacts = this.splitAreaSize.contacts;
        this.loadMoreRelatedInfoIfNeeded();
    };
    /**
     * UI intractions actions
     */
    /**
     * Select tab button and change content
     * @param tab Can contain the following values: 'message', 'calendar', 'contacts' or 'tasks'
     */
    MailPanelComponent.prototype.switchMode = function (mode) {
        this.mode = mode;
        this.stateService.selectedKnowledgePaneContactContext = mode;
        this.loadRelatedInfo();
    };
    MailPanelComponent.prototype.selectSenderContact = function (contact) {
        this.ga.trackEvent('knowledge-pane-action', 'switch-sender-dropdown');
        this.selectContact(contact);
    };
    MailPanelComponent.prototype.selectContact = function (contact) {
        if (this.selectedContact !== contact) {
            this.selectedContact = contact;
            this.contactChange.emit(contact);
            this.changeDetector.detectChanges();
        }
        if (this.selectedContact === null) {
            if (!this.contactView) {
                this.updateDropdownParticipants();
                this.mode = 'context';
                this.loadRelatedInfo();
            }
        }
        else {
            this.updateDropdownParticipants();
            if (!this.message) {
                this.contactOnly = true;
                this.switchMode('all');
            }
            this.loadRelatedInfo();
        }
        this.stateService.selectedKnowledgePaneContactContext = this.mode;
    };
    MailPanelComponent.prototype.topicHasChanged = function () {
        this.ga.trackEvent('knowledge-pane-action', 'select-related-topic');
        this.loadRelatedConnections(false);
        this.loadRelatedFiles('images', false);
        this.loadRelatedFiles('videos', false);
        this.loadRelatedFiles('documents', false);
        this.loadRelatedFiles('all', false);
        this.loadRelatedHyperlinks(false);
        this.setMessagesListFilters();
    };
    MailPanelComponent.prototype.deleteTopic = function (topic) {
        this.selectedRelatedTopics = this.selectedRelatedTopics.filter(function (t) { return t !== topic; });
        this.selectedRelatedContacts = [];
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
    };
    MailPanelComponent.prototype.selectTopic = function (topic, event) {
        if (!event.shiftKey) {
            if (this.selectedRelatedTopics.length === 1 && this.selectedRelatedTopics[0] === topic) {
                this.deleteTopic(topic);
            }
            else {
                this.selectedRelatedTopics = [topic];
            }
        }
        else {
            if (this.selectedRelatedTopics.includes(topic)) {
                this.deleteTopic(topic);
            }
            else {
                this.selectedRelatedTopics.push(topic);
            }
        }
        this.selectedRelatedContacts = [];
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.topicHasChanged();
    };
    MailPanelComponent.prototype.getOffsetByFirstSymbol = function (type, firstSymbol) {
        var _this = this;
        switch (type) {
            case 'topics':
                this.knowledgeGraphService.getRelatedTopicsOffset(tslib_1.__assign({}, this.getFilters(), { firstSymbol: firstSymbol }), this.orders.topics.key)
                    .subscribe(function (offset) {
                    _this.pageOffsets.topics = offset;
                    _this.loadRelatedTopics(false);
                });
                break;
            case 'connections':
                this.knowledgeGraphService.getRelatedConnectionsOffset(tslib_1.__assign({}, this.getFilters(), { firstSymbol: firstSymbol }), this.orders.connections.key)
                    .subscribe(function (offset) {
                    _this.pageOffsets.connections = offset;
                    _this.loadRelatedConnections(false);
                });
                break;
        }
    };
    MailPanelComponent.prototype.selectConnection = function (connection, event) {
        this.ga.trackEvent('knowledge-pane-action', 'select-related-connection');
        if (event.shiftKey || event.ctrlKey) {
            if (this.selectedRelatedContacts.includes(connection)) {
                this.selectedRelatedContacts = this.selectedRelatedContacts.filter(function (contact) { return contact !== connection; });
            }
            else {
                this.selectedRelatedContacts.push(connection);
            }
        }
        else {
            if (this.selectedRelatedContacts.includes(connection)) {
                this.selectedRelatedContacts = [];
            }
            else {
                this.selectedRelatedContacts = [connection];
            }
        }
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
        this.loadRelatedFiles('images', false);
        this.loadRelatedFiles('videos', false);
        this.loadRelatedFiles('documents', false);
        this.loadRelatedFiles('all', false);
        this.setMessagesListFilters();
    };
    MailPanelComponent.prototype.selectOrder = function (type, order) {
        this.orders[type] = order;
        switch (type) {
            case 'messages':
                this.messagesListStateService.setFilters({ order: order.key });
                break;
            case 'topics':
                this.stateService.sortKnowledgePaneRelatedTopics = order;
                this.pageOffsets.topics = 0;
                this.loadRelatedTopics(false);
                break;
            case 'connections':
                this.stateService.sortKnowledgePaneRelatedConnections = order;
                this.pageOffsets.connections = 0;
                this.loadRelatedConnections(false);
                break;
            case 'files':
                this.stateService.sortKnowledgePaneRelatedFiles = order;
                this.loadRelatedFiles('all', false);
                break;
            case 'hyperlinks':
                this.stateService.sortKnowledgePaneRelatedHyperlinks = order;
                this.loadRelatedHyperlinks(false);
                break;
        }
    };
    MailPanelComponent.prototype.openMessage = function (message) {
        this.ga.trackEvent('knowledge-pane-action', 'open-email');
        this.modalService.mailContent(message);
    };
    MailPanelComponent.prototype.updateDropdownParticipants = function () {
        var _this = this;
        if (!this.message) {
            this.dropdownParticipants = null;
            return;
        }
        if (!this.selectedContact) {
            this.dropdownParticipants = this.message.participants;
            return;
        }
        this.dropdownParticipants = this.message.participants.filter(function (participant) {
            return !((participant.name === _this.selectedContact.name) && (participant.email === _this.selectedContact.email));
        });
    };
    MailPanelComponent.prototype.selectRelatedDataTab = function (tab) {
        this.stateService.setTabsState('kpMails', tab.index);
    };
    /**
     * Set messages filters state method. Required to update filters for messages list and cause update.
     * Duplicate requrests protection present in state service. These calls are really excrsive,
     * but that the most efficient way to do this at the moment unfortunatelly. Can be changed once component refactored
     */
    MailPanelComponent.prototype.setMessagesListFilters = function () {
        this.messagesListStateService.setFilters({
            orderWithPinned: false,
            groupByThread: false,
            matchingTypes: ['phrase'],
            topics: this.topics,
            relatedTopics: this.selectedRelatedTopics,
            relatedContacts: this.selectedRelatedContacts,
            relatedMessageId: this.message && this.message.id,
            relatedMessageTopics: this.relatedMessageTopics
        });
    };
    /**
     * Load Related info methods
     */
    MailPanelComponent.prototype.getFilters = function () {
        var filters = {
            messageId: this.message && this.mode === 'context' ? this.message.id : null,
            topics: this.mode === 'context' ? this.topics : null,
            contact: this.selectedContact,
            relatedTopics: this.selectedRelatedTopics,
            relatedContacts: this.selectedRelatedContacts,
            baseTopics: this.message && this.mode === 'context' && this.baseTopics ? this.baseTopics.map(function (topic) { return topic.name; }) : null
        };
        if (!this.message && this.selectedContact && this.mode === 'all' && this.contactView && !this.selectedContact.id) {
            filters.contact = null;
            filters.contacts = [this.selectedContact.email];
        }
        return filters;
    };
    MailPanelComponent.prototype.loadRelatedInfo = function () {
        var e_2, _a, e_3, _b;
        if (!(this.message && this.baseTopics && this.baseTopics.length) &&
            !this.contact &&
            !(this.selectedContact && this.mode === 'all' && this.contactView)) {
            this.relatedInfo = new KnowledgeGraphRelatedInfo();
            try {
                // Cancel all ongoing requests
                for (var _c = tslib_1.__values(Object.keys(this.loadingSubjects)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var key = _d.value;
                    this.loadingSubjects[key].next();
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
            try {
                for (var _e = tslib_1.__values(Object.keys(this.loaders)), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var key = _f.value;
                    this.loaders[key] = false;
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_3) throw e_3.error; }
            }
            return;
        }
        this.loadRelatedTopics(false, false);
        this.loadRelatedConnections(false, false);
        this.loadRelatedFiles('all', false);
        this.loadRelatedFiles('images', false);
        this.loadRelatedFiles('documents', false);
        this.loadRelatedFiles('videos', false);
        this.loadRelatedHyperlinks(false);
        this.setMessagesListFilters();
    };
    MailPanelComponent.prototype.loadRelatedTopics = function (more, reverse) {
        var _this = this;
        if (more === void 0) { more = true; }
        if (reverse === void 0) { reverse = false; }
        if (!more) {
            this.relatedInfo.topics = { data: [], count: 0 };
        }
        else if ((this.loaders.topics && !reverse) ||
            (this.loaders.topicsTop && reverse) ||
            (this.pageOffsets.topics + this.relatedInfo.topics.data.length >= this.relatedInfo.topics.count && !reverse && !this.errors.topics) ||
            (this.pageOffsets.topics <= 0 && reverse)) {
            return;
        }
        this.loadingSubjects['topics'].next();
        this.loaders.topics = !reverse;
        this.loaders.topicsTop = reverse;
        this.errors.topics = false;
        this.errors.topicsTop = false;
        this.knowledgeGraphService.getRelatedTopics(this.getFilters(), this.pageSizes.topics, !reverse ?
            this.pageOffsets.topics + this.relatedInfo.topics.data.length :
            this.pageOffsets.topics > this.pageSizes.topics ?
                this.pageOffsets.topics - this.pageSizes.topics : 0, this.orders.topics.key)
            .pipe(takeUntil(this.loadingSubjects['topics']))
            .subscribe(function (topics) {
            var _a, _b;
            _this.loaders.topics = false;
            _this.loaders.topicsTop = false;
            if (!reverse) {
                (_a = _this.relatedInfo.topics.data).push.apply(_a, tslib_1.__spread(topics.data));
            }
            else {
                _this.pageOffsets.topics = _this.pageOffsets.topics > _this.pageSizes.topics ?
                    _this.pageOffsets.topics - _this.pageSizes.topics : 0;
                (_b = _this.relatedInfo.topics.data).unshift.apply(_b, tslib_1.__spread(topics.data));
            }
            _this.relatedInfo.topics.count = topics.count;
        }, function () {
            _this.loaders.topics = false;
            _this.loaders.topicsTop = false;
            if (!reverse) {
                _this.errors.topics = true;
            }
            else {
                _this.errors.topicsTop = true;
            }
        });
    };
    MailPanelComponent.prototype.loadRelatedConnections = function (more, reverse) {
        var _this = this;
        if (more === void 0) { more = true; }
        if (reverse === void 0) { reverse = false; }
        if (!more) {
            this.relatedInfo.connections = { data: [], count: 0 };
        }
        else if ((this.loaders.connections && !reverse) ||
            (this.loaders.connectionsTop && reverse) ||
            (this.pageOffsets.connections + this.relatedInfo.connections.data.length >= this.relatedInfo.connections.count
                && !reverse
                && !this.errors.connections) ||
            (this.pageOffsets.connections <= 0 && reverse)) {
            return;
        }
        this.loadingSubjects['connections'].next();
        this.loaders.connections = !reverse;
        this.loaders.connectionsTop = reverse;
        this.errors.connections = false;
        this.errors.connectionsTop = false;
        var filters = this.getFilters();
        if (this.account) {
            var ignoreContact = new Recipient();
            ignoreContact.email = this.account.email;
            filters.ignoreContact = ignoreContact;
        }
        this.knowledgeGraphService.getRelatedConnections(filters, this.pageSizes.connections, !reverse ?
            this.pageOffsets.connections + this.relatedInfo.connections.data.length :
            this.pageOffsets.connections > this.pageSizes.connections ?
                this.pageOffsets.connections - this.pageSizes.connections : 0, this.orders.connections.key)
            .pipe(takeUntil(this.loadingSubjects['connections']))
            .subscribe(function (connections) {
            var _a, _b;
            _this.loaders.connections = false;
            _this.loaders.connectionsTop = false;
            if (!reverse) {
                (_a = _this.relatedInfo.connections.data).push.apply(_a, tslib_1.__spread(connections.data));
            }
            else {
                _this.pageOffsets.connections = _this.pageOffsets.connections > _this.pageSizes.connections ?
                    _this.pageOffsets.connections - _this.pageSizes.connections : 0;
                (_b = _this.relatedInfo.connections.data).unshift.apply(_b, tslib_1.__spread(connections.data));
            }
            _this.relatedInfo.connections.count = connections.count;
        }, function () {
            _this.loaders.connections = false;
            _this.loaders.connectionsTop = false;
            if (!reverse) {
                _this.errors.connections = true;
            }
            else {
                _this.errors.connectionsTop = true;
            }
        });
    };
    MailPanelComponent.prototype.loadRelatedFiles = function (type, more) {
        var _this = this;
        if (more === void 0) { more = true; }
        var loaderName = type === 'all' ? 'allFiles' : type;
        if (!more) {
            this.relatedInfo.files[type] = { data: [], count: 0 };
            this.loadingSubjects[loaderName].next();
        }
        this.loaders[loaderName] = true;
        this.errors[loaderName] = false;
        this.knowledgeGraphService.getRelatedFiles(type, this.getFilters(), this.pageSizes[loaderName], this.relatedInfo.files[type].data.length, this.orders.files.key)
            .pipe(takeUntil(this.loadingSubjects[loaderName]))
            .subscribe(function (files) {
            var _a;
            _this.loaders[loaderName] = false;
            (_a = _this.relatedInfo.files[type].data).push.apply(_a, tslib_1.__spread(files.data));
            _this.relatedInfo.files[type].count = files.count;
        }, function () {
            _this.loaders[loaderName] = false;
            _this.errors[loaderName] = true;
        });
    };
    MailPanelComponent.prototype.loadRelatedHyperlinks = function (more) {
        var _this = this;
        if (more === void 0) { more = true; }
        if (!more) {
            this.relatedInfo.hyperlinks = { data: [], count: 0 };
            this.loadingSubjects['hyperlinks'].next();
        }
        this.loaders.hyperlinks = true;
        this.errors.hyperlinks = false;
        this.knowledgeGraphService
            .getRelatedHyperlinks(this.getFilters(), this.pageSizes.hyperlinks, this.relatedInfo.hyperlinks.data.length, this.orders.hyperlinks.key)
            .pipe(takeUntil(this.loadingSubjects['hyperlinks']))
            .subscribe(function (hyperlinks) {
            var _a;
            _this.loaders.hyperlinks = false;
            (_a = _this.relatedInfo.hyperlinks.data).push.apply(_a, tslib_1.__spread(hyperlinks.data));
            _this.relatedInfo.hyperlinks.count = hyperlinks.count;
        }, function () {
            _this.loaders.hyperlinks = false;
            _this.errors.hyperlinks = true;
        });
    };
    MailPanelComponent.prototype.loadMoreRelatedInfoIfNeeded = function (section) {
        if ((!section || section === 'topics') && this.moreRelatedInfoNeeded('topics')) {
            this.loadRelatedTopics(true);
        }
        if ((!section || section === 'connections') && this.moreRelatedInfoNeeded('connections')) {
            this.loadRelatedConnections(true);
        }
    };
    MailPanelComponent.prototype.moreRelatedInfoNeeded = function (section) {
        return !!(section &&
            !this.loaders[section] &&
            this.relatedInfoContainers &&
            this.relatedInfoContainers[section] &&
            this.relatedInfoContainers[section].nativeElement &&
            this.relatedInfoContainers[section].nativeElement.offsetHeight &&
            this.relatedInfoContainers[section].nativeElement.scrollHeight &&
            this.relatedInfoContainers[section].nativeElement.offsetHeight >= this.relatedInfoContainers[section].nativeElement.scrollHeight);
    };
    return MailPanelComponent;
}());
export { MailPanelComponent };
