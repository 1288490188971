/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../split-view/directives/split-view-toggle.directive";
import * as i5 from "../../../split-view/services/split-view.service";
import * as i6 from "@angular/common";
import * as i7 from "./sidebar.component";
import * as i8 from "ngx-electron";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button-icon button-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "button-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), i1.ɵdid(3, 4341760, null, 0, i4.SplitViewToggleDirective, [i1.ElementRef, i5.SplitViewService], { splitViewKey: [0, "splitViewKey"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "button-arrow"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.splitViewKey; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "app-sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "logo"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "extra-top-padding": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "logo-large": 0, "logo-small": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "sidebar-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "logo"; var currVal_1 = _ck(_v, 4, 0, _co.extraTopPadding); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _ck(_v, 8, 0, !_co.minimized, _co.minimized); _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.minimized; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.minimized ? "assets/logo/logo.svg" : "assets/logo/logo-main.svg"); _ck(_v, 5, 0, currVal_2); }); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 245760, null, 0, i7.SidebarComponent, [i5.SplitViewService, i1.ChangeDetectorRef, i8.ElectronService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i7.SidebarComponent, View_SidebarComponent_Host_0, { splitViewKey: "splitViewKey" }, {}, ["*"]);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
