<div class="topics">
  <span *ngIf="!topics.length" class="topics-placeholder">+ Add Threads</span>

  <app-temporal-expression
    *ngFor="let expression of temporalExpressions"
    [expression]="expression"
    [selected]="selectedTemporalExpressions | includesBy: expression"
    (click)="selectTemporalExpression(expression, $event)"
    (showInCalendar)="selectTemporalExpression(expression)"
  ></app-temporal-expression>

  <app-topic
    *ngFor="let topic of visibleTopics; let index = index"
    class="topics-item"
    [topic]="topic.name"
    [selected]="selectedTopics | includesBy: topic.name : 'name'"
    (click)="selectTopic(topic, $event)"
    [highlighted]="highlightTopics | includesBy: topic.name"
    [(pinned)]="topic.pinned"
    [canBeDeleted]="topic.source === 'user' || topic.source === 'auto_discovery' || topic.source === 'auto_add' || topic.autoDiscovery"
    (delete)="handleDeleteTopic(index)"
    [topicStyle]="(topic.source === 'user' || topic.source === 'auto_discovery' || topic.source === 'auto_add' || topic.autoDiscovery) ? 'manuallyAdded' : 'discovered'"
  ></app-topic>

  <app-icon-button
    class="topics-action"
    [class.no-topics]="topics.length === 0"
    icon="plus-small"
    popover
    popoverTrigger="click"
    popoverPlacement="bottom"
    [popoverArrow]="false"
    [popoverContent]="createTopicPopover"
    [popoverCustomTrigger]="popoverCustomTrigger"
    [popoverShowUntil]="popoverClose"
    popoverAllowedOutsideSelectorsClick=".mat-autocomplete-panel.mat-autocomplete-visible"
  ></app-icon-button>

  <app-icon-button
    class="topics-action"
    [class.no-topics]="topics.length === 0"
    icon="settings-small"
    (click)="showTopicsModal()"
  ></app-icon-button>

  <mat-icon
    *ngIf="displayAnnotations"
    class="topics-action"
    [class.no-topics]="topics.length === 0"
    svgIcon="topic-list"
    (click)="showAnnotateTopicsModal()"
  ></mat-icon>

  <div
    *ngIf="topics.length > visibleTopics.length"
    (click)="setVisibleLength(visibleLength + 10)"
    class="topics-more"
  >
    + {{ topics.length - visibleLength }} more
  </div>
  <div
    *ngIf="visibleLength > 10"
    (click)="setVisibleLength(10)"
    class="topics-more"
  >
    Show less
  </div>
</div>

<!-- Popover -->
<ng-template #createTopicPopover>
  <app-topics-form-popover
    [topics]="newTopics"
    (save)="handleAddTopics($event)"
    (close)="handlePopoverFormClose()"
    [loading]="formLoading"
    [error]="formError"
  ></app-topics-form-popover>
</ng-template>
