<div
  class="tag-wrapper text-no-select"
  *ngIf="tag"
  [contextMenu]="tagMenu"
  [contextMenuSubject]="tag"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [tag], type: 'tag'}"
  [appDraggablePlaceholder]="dragPlaceholder"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <mat-icon *ngIf="tag.pinned" svgIcon="pin-small-color" class="pin-icon"></mat-icon>
  <div class="tag">#{{ tag?.name }}</div>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <app-tag [tag]="tag" [dragEnabled]="false"> </app-tag>
</ng-template>

<!-- Context Menu -->
<context-menu #tagMenu>
  <ng-template contextMenuItem (execute)="pinTag()">
    {{ tag.pinned ? 'Unpin tag' : 'Pin tag' }}
  </ng-template>
  <ng-template contextMenuItem (execute)="deleteTag()">
    Delete
  </ng-template>
</context-menu>
<!-- End: Context Menu -->
