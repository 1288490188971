// Common
import { Component } from '@angular/core';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
// Types
import { Project } from '@modules/tasks/types/project';

@Component({
  selector: 'app-sidebar-projects-context-menu',
  templateUrl: './sidebar-projects-context-menu.component.html',
  styleUrls: ['./sidebar-projects-context-menu.component.less']
})
export class SidebarProjectsContextMenuComponent extends ContextMenuComponent {

  // Constructor
  constructor(
    protected contextMenuService: ContextMenuService,
    private tasksStateService: TasksStateService
  ) {
    super(contextMenuService);
  }

  // Actions
  public createProject() {
    const project = new Project();
    this.tasksStateService.openProjectForm(project);
  }
}
