// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from '@angular/animations';

// RX
import { Subject, Observable, Subscription } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Calendar } from '@modules/events/types/calendar';
import { Attachment } from '@modules/form-controls/types/attachment';
import { MailMessage } from '@modules/mail/types/mail-message';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

// Animations
import { collapseMotion } from '@modules/form-controls/animations/collapse-animation';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.less'],
  animations: [collapseMotion]
})
export class EventFormComponent implements OnChanges, OnDestroy {

  // Inputs
  @Input() event: CalendarEvent = new CalendarEvent();
  @Input() bundledGroup = 'eventForm';
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';
  @Input() submit: Observable<void>;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public eventForm: FormGroup = this.event.asFormGroup();
  public calendarOptions: DropdownSelectItem[] = [];
  public saveInProgress = false;
  public displayFixedBigHeaderShadow = false;
  public displayDescription = false;
  conferencingOptions = [
    {title: 'Hangouts', value: '?', icon: 'social-hangouts'},
    {title: 'Skype', value: '?', icon: 'social-skype'},
    {title: 'Slack', value: '?', icon: 'social-slack'},
    {title: 'Zoom', value: '?', icon: 'social-zoom'},
    {title: 'No Conferencing', value: '?', icon: 'social-no-conf'},
  ];
  public calendar: Calendar;
  public dynamicPanel: DynamicPanelType;

  // Private
  private componentNotDestroyed = new Subject();
  private submitSubscription = new Subject();
  private formSubscription: Subscription;
  private calendars: Calendar[] = [];

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private modalService: ModalService,
    private eventsStateService: EventsStateService,
    private dynamicPanelService: DynamicPanelService
  ) {
    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendars: Calendar[]) => {
        this.calendars = calendars;
        this.calendarOptions = calendars.map((calendar: Calendar) => ({
          title: calendar.name,
          value: calendar.id,
          itemClick: () => this.setCurrentCalendar()
        }));
        this.setDefaultCalendar();
        this.setCurrentCalendar();
      });

    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('event' in changes && this.event) {
      this.reset();
    }
    if ('submit' in changes && this.submit) {
      this.submitSubscription.next();
      this.submit
        .pipe(takeUntil(this.submitSubscription))
        .subscribe(() => this.handleSubmit());
    }
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.submitSubscription.next();
    this.submitSubscription.complete();
  }

  /**
   * Actions
   */

  setCurrentCalendar(): void {
    this.calendar = this.calendars.find(calendar => calendar.id === this.eventForm.controls['calendarId'].value);
  }

  handleSubmit() {
    if (!this.eventForm.valid) {
      return;
    }

    this.saveInProgress = true;
    const event = CalendarEvent.fromFormGroup(this.eventForm);
    event.color = event.color && this.calendar && event.color === this.calendar.color ? '' : event.color;

    const saveRequest: Observable<CalendarEvent> = this.eventForm.get('id').value ?
      this.calendarService.editEvent(event) : this.calendarService.createEvent(event);

    saveRequest
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleCancel() {
    this.close.emit();
  }

  handleError() {
    this.saveInProgress = false;
  }

  pin() {
    this.calendarService.pinEvent([this.event.id], !this.event.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((events: CalendarEvent[]) => {
        if (events[0]) {
          this.event.pinned = events[0].pinned;
        }
      });
  }

  mail() {
    this.modalService.compose(MailMessage.fromCalendarEvent(this.event));
  }

  archive() {
    this.calendarService.archiveEvents([this.event.id], !this.event.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((events: CalendarEvent[]) => {
        if (events[0]) {
          this.event.archived = events[0].archived;
        }
      });
  }

  delete() {
    this.event.deleted ?
      this.calendarService.deletePermanentlyEvent([this.event.id]) :
      this.calendarService.deleteEvent([this.event.id], true);

      this.eventsStateService.setMainView('calendar');
  }

  openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent(
        'attachments',
        {
          attachmentsFormControl: this.eventForm.controls['files'],
          parentTitle: this.event.title, showDropArea: true
        }
      );
    }
  }

  openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent(
        'linkedInfo',
        {
          linkedInfo: this.event.linkedInfo,
          parentLinkedInfo: {type: 'event', id: this.event.id}
        });
    }
  }

  /**
   * Helpers
   */

  reset() {
    this.eventForm = this.event.asFormGroup();

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    this.setDefaultCalendar();
    this.formSubscription = this.eventForm.valueChanges
      .pipe(
        startWith(this.eventForm.value as Object),
        pairwise(),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([previous, current]) => {
        if (!this.datesEqual(previous.startDate, current.startDate) && current.startDate && this.eventForm.controls.endDate.pristine) {
          this.eventForm.patchValue({ endDate: current.startDate });
        }

        if (!current.startTime && previous.startTime) {
          current.startTime = previous.startTime;
        }

        if (current.startTime && previous.startTime && !this.datesEqual(previous.startTime, current.startTime)) {
          const timeDifference = current.startTime.getTime() - previous.startTime.getTime();
          this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
        }

        if (current.reminder && current.reminder !== previous.reminder) {
          this.eventForm.patchValue({
            endDate: this.eventForm.controls.startDate.value,
            endTime: current.startTime && new Date(current.startTime.getTime() + 900000)
          }, { emitEvent: false });
        }
      });
    this.setCurrentCalendar();
    this.displayDescription = !!this.event.description;

    this.dynamicPanelService.setContent();
  }

  filesAttached(files: Attachment[]) {
    this.eventForm.patchValue({ files: files });
  }

  private datesEqual(date1: Date, date2: Date) {
    return date1 && date2 && date1.getTime() === date2.getTime();
  }

  private setDefaultCalendar() {
    if (!this.eventForm.controls['calendarId'].value) {
      const writableCalendar = this.calendars.find(calendar => !calendar.readOnly);
      if (writableCalendar) {
        this.eventForm.patchValue({ calendarId: writableCalendar.id });
      }
    }
  }
}
