// Common
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Project } from '@modules/tasks/types/project';

// Services
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';

@Component({
  selector: 'app-compass-panel',
  templateUrl: './compass-panel.component.html',
  styleUrls: ['./compass-panel.component.less']
})
export class CompassPanelComponent {

  // Public
  public orderOptions: DropdownOption[] = [
    { name: 'Date', key: 'date'},
  ];
  public selectedOrder: DropdownOption;
  public dateFromControl: FormControl = new FormControl(new Date());

  /**
   * Constructor
   */

  constructor(
    private knowledgePanelService: KnowledgePanelService,
  ) {

  }

  /**
   * Actions
   */

  handleNewEvent(event: CalendarEvent) {
    this.knowledgePanelService.setFormItem(event);
  }

  handleNewTask(task: Task) {
    this.knowledgePanelService.setFormItem(task);
  }

  handleNewNote(note: Note) {
    this.knowledgePanelService.setFormItem(note);
  }

  handleNewProject(project: Project) {
    this.knowledgePanelService.setFormItem(project);
  }

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
  }
}
