// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// Rx
import { Observable, Subject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { TopicService } from '@modules/topic/services/topic.service';

// Types
import { Group } from '../types/group';
import { GroupsFilters } from '../types/groups-filters';
import { GroupsListResponse } from '../types/groups-list-response';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class GroupsService {

  // Public
  public createNewGroup = new Subject<Group>();
  public updatedGroup = new Subject<Group>();
  public deletedGroup = new Subject<string>();

  /**
   * Settings
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private linkedInfoService: LinkedInfoService,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private topicService: TopicService
  ) {

  }

  /**
   * Helpers
   */

  private formatFilters(filters: GroupsFilters): { [param: string]: string | string[]; } {
    const formatedFilters = {};
    if (filters.groupsIds) { formatedFilters['groups_ids[]'] = filters.groupsIds; }
    if (filters.groupsIds) { formatedFilters['groups_ids[]'] = filters.groupsIds; }

    if (filters.archived) { formatedFilters['archived'] = filters.archived + ''; }
    if (filters.deleted) { formatedFilters['deleted'] = filters.deleted + ''; }
    if (filters.pinned) { formatedFilters['pinned'] = filters.pinned + ''; }

    if (filters.keywords) { formatedFilters['keywords[]'] = filters.keywords; }
    if (filters.topics) { formatedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formatedFilters['tags[]'] = filters.tags; }

    if (filters.order) { formatedFilters['order'] = filters.order; }
    if (filters.orderWithPinned) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }

    if (filters.limit) { formatedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formatedFilters['offset'] = filters.offset + ''; }

    return formatedFilters;
  }

  /**
   * Methods
   */

  getGroups(filters: GroupsFilters = {}): Observable<GroupsListResponse> {
    const requestParams = {params: this.formatFilters(filters)};

    // return this.http.get<GroupsListResponse>(environment.baseUrl + '/api/groups', requestParams)
    return of({ groups: mockGroups, count: 4 })
      .pipe(
        map(({ count, groups }) => ({count, groups: groups.map(group => new Group(group))})),
        catchError(GroupsService.handleObserverError)
      );
  }

  getGroup(id: string): Observable<Group> {
    return this.http.get<{ group: Group }>(environment.baseUrl + '/api/groups/' + id)
      .pipe(
        map(({ group }) => new Group(group)),
      );
  }

  create(groupInstance: Group): Observable<Group> {
    return this.http.post<{ group: Group, success: boolean }>(`${environment.baseUrl}/api/groups`, groupInstance.asPayloadJSON())
      .pipe(
        map(({ group, success }) => ({ group: new Group(group), success })),
        tap(({ group, success }) => {
          if (success) {
            this.createNewGroup.next(group);
            this.asyncTasksToastsService.show({ text: `Group(s) created.` });
          }
        }),
        map(({ group }) => group),
        tap(group => {
          if (groupInstance.linkedInfo) {
            this.linkedInfoService.linkToItem({ type: 'group', id: group.id }, groupInstance.linkedInfo);
          }
        }),
        switchMap(group => {
          if (groupInstance.topics.length === 0) { return of(group); }

          return this.topicService.createTopics(
            groupInstance.topics, {groupsIds: [group.id]}
          )
            .pipe(
              map(() => group)
            );
        }),
        catchError((error: HttpErrorResponse) => {
          this.asyncTasksToastsService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(groupInstance: Group): Observable<Group> {
    return this.http.put<{ group: Group, success: boolean }>(
      environment.baseUrl + '/api/groups/' + groupInstance.id, groupInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ group, success }) => {
          if (success) {
            this.updatedGroup.next();
            this.asyncTasksToastsService.show({ text: 'Group updated' });
          }
        }),
        map(({ group }) => group)
      );
  }

  @warmUpObservable
  pin(groupsIds: string[], status: boolean): Observable<Group[]> {
    return forkJoin(
      groupsIds.map(groupId => this.http.put<{ group: Group, success: boolean }>
        (environment.baseUrl + '/api/groups/' + groupId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { group: Group, success: boolean }[]) => results.map(result => result.group)),
        tap(success => {
          if (success) {
            this.updatedGroup.next();
            this.asyncTasksToastsService.show(
              {text: `Group${groupsIds.length > 1 ? 's' : ''} ${groupsIds.length > 1 ? 'are' : 'is'} ${ status ? '' : 'un'}pinned`}
            );
          }
        })
      );
  }

  @warmUpObservable
  archive(groupsIds: string[], archived: boolean): Observable<Group[]> {
    return forkJoin(
      groupsIds.map(groupId => this.http.put<{ group: Group, success: boolean }>
        (environment.baseUrl + '/api/groups/' + groupId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { group: Group, success: boolean }[]) => results.map(result => result.group)),
        tap(success => {
          if (success) {
            this.updatedGroup.next();
            this.asyncTasksToastsService.show({ text: `Group(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  deletePermanently(groupsIds: string[]): Observable<boolean> {
    return forkJoin(
      groupsIds.map(groupId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/groups/' + groupId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.deletedGroup.next();
            this.asyncTasksToastsService.show({ text: `Group(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  delete(groupsIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      groupsIds.map(groupId => this.http.put<{ group: Group, success: boolean }>
        (environment.baseUrl + '/api/groups/' + groupId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { group: Group, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.updatedGroup.next();
            this.asyncTasksToastsService.show({ text: `Group(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }

  upsert(groupForm: FormGroup): Observable<Group> {
    const group = Group.fromFormGroup(groupForm);

    return groupForm.get('id').value ?
      this.update(group) : this.create(group);
  }
}

const mockGroups = [
  {
    id: '1',
    name: 'Stitch Developers',
    pinned: true,
    starred: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    description: 'I have detailed below the most cost effective…',
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
  {
    id: '2',
    name: 'Stitch Business',
    createdAt: new Date(),
    updatedAt: new Date(),
    description: 'I have detailed below the most cost effective…',
    participants: [
      {
        id: 'michael.skubenych@gmail.com',
        email: 'michael.skubenych@gmail.com',
        name: null,
        normalizedName: 'michael.skubenych@gmail.com',
        vip: false
      },
      {
        id: 'vikapmtest@gmail.com',
        email: 'vikapmtest@gmail.com',
        name: null,
        normalizedName: 'vikapmtest@gmail.com',
        vip: false
      }
    ],
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
  {
    id: '3',
    name: 'Stitch Design',
    starred: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    description: 'I have detailed below the most cost effective…'
  },
  {
    id: '4',
    name: 'Stitch Management',
    starred: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    description: 'I have detailed below the most cost effective…',
    linkedInfo: [
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'note',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'event',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'task',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'message',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'group',
        createdAt: '2020-08-31T15:27:38.686Z'
      },
      {
        id: '7s3qyb0zhilxcabqxll968s8y',
        type: 'contact',
        createdAt: '2020-08-31T15:27:38.686Z'
      }
    ]
  },
];
