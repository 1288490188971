<div class="modal-header">
  <div class="modal-title">
    <mat-icon *ngIf="minimized" svgIcon="attachment" class="modal-title-icon"></mat-icon>
    Attachments
  </div>
  <button type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="modal-content">
    <app-attachments-list
      [attachments]="attachments"
      [attachmentsFormControl]="attachmentsFormControl"
      [extendIconEnabled]="false"
    ></app-attachments-list>
    <app-linked-info-toolbar></app-linked-info-toolbar>
  </div>
</div>
