// Common
import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

// Types
import { Topic } from '@modules/topic/types/topic';
import { MailMessage } from '../../mail/types/mail-message';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Hyperlink } from './../../mail/types/hyperlink';
import { Recipient } from '../../mail/types/recipient';
import { Project } from './project';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { TaskStatus } from './task-status';
import { TaskType } from './task-type';
import { NotificationDetails } from '@modules/form-controls/types/notification-details';
import { Priority } from '@modules/tasks/types/priority';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Task {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  projectId?: string;
  sectionId?: string;
  columnId?: string;
  parentId?: string;
  title?: string;
  priority?: Priority;
  description?: string;
  remainder?: boolean;
  repetition?: string;
  type?: TaskType;
  status?: TaskStatus;
  participants?: Recipient[];
  topics?: Topic[];
  subtasks?: Task[];
  files?: Attachment[];
  hyperlinks?: Hyperlink[];
  updatedAt?: Date;
  createdAt?: Date;
  strangeLines?: any[] = [];
  tags?: any[];
  fromTime?: Date;
  toTime?: Date;
  owner?: string;
  pinned?: boolean;
  archived?: boolean;
  deleted?: boolean;
  linkedInfo?: LinkedInfo[];
  color?: string;
  notifications?: NotificationDetails[];
  position?: number;
  boardPosition?: number;
  columnTitle?: string;

  // TODO properties not included in server response
  circleColor?: string;
  checked?: boolean;

  // Tech properties

  /**
   * temp - new task created but not sync with backend
   */
  temp?: boolean;

  constructor(taskObject: any = {}) {
    this.id = taskObject.id;
    this.sectionId = taskObject.sectionId;
    this.projectId = taskObject.projectId;
    this.columnId = taskObject.columnId;
    this.parentId = taskObject.parentId;
    this.title = taskObject.title;
    this.priority = taskObject.priority || 'normal';
    this.description = taskObject.description;
    this.fromTime = taskObject.fromTime && new Date(taskObject.fromTime);
    this.toTime = taskObject.toTime && new Date(taskObject.toTime);
    this.remainder = taskObject.remainder;
    this.repetition = taskObject.repetition || 'none';
    this.status = taskObject.status;
    this.participants = taskObject.participants || [];
    this.subtasks = taskObject.subtasks || [];
    this.topics = taskObject.topics || [];
    this.files = taskObject.files;
    this.hyperlinks = taskObject.hyperlinks;
    this.updatedAt = taskObject.updatedAt && new Date(taskObject.updatedAt);
    this.createdAt = taskObject.createdAt && new Date(taskObject.createdAt);
    this.strangeLines = strangeLines[Math.floor(Math.random() * 4)];
    this.pinned = taskObject.pinned;
    this.circleColor = taskObject.circleColor;
    this.checked = taskObject.checked;
    this.notifications = taskObject.notifications || [];
    this.color = taskObject.color;
    this.position = taskObject.position;
    this.boardPosition = taskObject.boardPosition;
    this.archived = taskObject.archived;
    this.deleted = taskObject.deleted;
    this.temp = taskObject.temp;
    this.linkedInfo = taskObject.linkedInfo || [];
    this.columnTitle = 'To Do';
  }

  static fromFormGroup(form: FormGroup): Task {
    const formValues = form.value;

    return new Task({
      id: formValues.id,
      projectId: formValues.projectId,
      sectionId: formValues.sectionId,
      columnId: formValues.columnId,
      parentId: formValues.parentId,
      title: formValues.title,
      description: formValues.description,
      fromTime: formValues.fromTime && formValues.fromDate && new Date(
        formValues.fromDate.getFullYear(),
        formValues.fromDate.getMonth(),
        formValues.fromDate.getDate(),
        formValues.fromTime.getHours(),
        formValues.fromTime.getMinutes(),
      ),
      toTime: formValues.toTime && formValues.toDate && new Date(
        formValues.toDate.getFullYear(),
        formValues.toDate.getMonth(),
        formValues.toDate.getDate(),
        formValues.toTime.getHours(),
        formValues.toTime.getMinutes(),
      ),
      owner: formValues.owner,
      participants: formValues.participants,
      status: formValues.status,
      topics: formValues.topics,
      tags: formValues.tags,
      project: formValues.project,
      priority: formValues.priority,
      color: formValues.color,
      notifications: formValues.notifications,
      position: formValues.position,
      boardPosition: formValues.boardPosition,
      linkedInfo: formValues.linkedInfo
    });
  }

  static fromMailMessage(
    message?: MailMessage,
    projectId?: string,
    columnId?: string
  ): Task {
    const task = new Task();

    if (message) {
      task.projectId = projectId;
      task.columnId = columnId;
      task.title = message.subject;
      task.description = message.bodyText;
      task.participants = message.participants || [];
      task.topics = message.topics || [];
      task.tags = message.tags || [];
      task.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return task;
  }

  static fromEvent(
    event?: CalendarEvent,
    projectId?: string,
    columnId?: string
  ): Task {
    const task = new Task();

    if (event) {
      task.projectId = projectId;
      task.columnId = columnId;
      task.title = event.title;
      task.description = event.description;
      task.fromTime = event.when && event.when.start;
      task.toTime = event.when && event.when.end;
      task.linkedInfo = event.id ? [new LinkedInfo('event', event.id)] : [];
      task.topics = event.topics || [];
      task.tags = event.tags || [];
    }

    return task;
  }

  static fromProject(
    project?: Project,
    projectId?: string,
    columnId?: string
  ): Task {
    const task = new Task();

    if (project) {
      task.projectId = projectId;
      task.columnId = columnId;
      task.title = project.title;
      task.description = project.description;
      task.fromTime = project.fromTime;
      task.toTime = project.toTime;
      task.linkedInfo = project.id ? [new LinkedInfo('project', project.id)] : [];
      task.topics = project.topics || [];
      task.tags = project.tags || [];
    }

    return task;
  }

  static fromTask(
    originalTask: Task = new Task(),
    projectId?: string,
    columnId?: string
  ): Task {
    const task = new Task({
      ...originalTask,
      projectId,
      columnId,
      linkedInfo: originalTask.id ? [new LinkedInfo('task', originalTask.id)] : []
    });

    return task;
  }

  static fromNote(
    note?: Note,
    projectId?: string,
    columnId?: string
  ): Task {
    const task = new Task();

    if (note) {
      task.projectId = projectId;
      task.columnId = columnId;
      task.title = note.title;
      task.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
      task.topics = note.topics || [];
      task.tags = note.tags || [];
    }

    return task;
  }

  static fromGroup(group?: Group): Task {
    const task = new Task();

    if (group) {
      task.title = group.name || '';
      task.topics = group.topics || [];
      task.tags = group.tags || [];
      task.linkedInfo = group.id ? [new LinkedInfo('group', group.id)] : [];
    }

    return task;
  }

  static fromContact(contact?: Contact): Task {
    const task = new Task();

    if (contact) {
      task.title = contact.name || '';
      task.topics = contact.topics || [];
      task.tags = contact.tags || [];
      task.linkedInfo = contact.id ? [new LinkedInfo('group', contact.id)] : [];
    }

    return task;
  }

  static fromDragData(dragData: DragData): Task {
    let task: Task = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        task = Task.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        task = Task.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        task = Task.fromProject(<Project>dragItem);
        break;
      case 'task':
        task = Task.fromTask(<Task>dragItem);
        break;
      case 'note':
        task = Task.fromNote(<Note>dragItem);
        break;
      case 'group':
        task = Task.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        task = Task.fromContact(<Contact>dragItem);
        break;
    }

    return task;
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      projectId: [this.projectId],
      sectionId: [this.sectionId],
      columnId: [this.columnId],
      parentId: [this.parentId],
      title: [this.title],
      description: [this.description || ''],
      fromDate: [this.fromTime],
      toDate: [this.toTime],
      fromTime: [this.fromTime],
      toTime: [this.toTime],
      owner: [this.owner],
      participants: [this.participants || []],
      status: [this.status],
      topics: [this.topics],
      tags: [this.tags || ['advertising', 'alarming', 'marketing', 'media', 'growthhacking']],
      priority: [this.priority],
      color: [this.color],
      notifications: this.formBuilder.array(this.notifications.map(
        notification => this.formBuilder.group({ type: notification.type, duration: notification.duration, units: notification.units })
      )),
      position: [this.position],
      boardPosition: [this.boardPosition],
      linkedInfo: [this.linkedInfo || []]
    });
  }

  asPayloadJSON() {
    return {
      title: this.title,
      projectId: this.projectId,
      sectionId: this.sectionId,
      columnId: this.columnId,
      parentId: this.parentId,
      priority: this.priority,
      description: this.description,
      fromTime: this.fromTime,
      toTime: this.toTime,
      remainder: this.remainder,
      repetition: this.repetition,
      participants: this.participants,
      subtasks: this.subtasks,
      color: this.color,
      notifications: this.notifications,
      position: this.position,
      boardPosition: this.boardPosition
    };
  }
}

const strangeLines = [
  [{color: '#409aff'}, {color: '#ff4666'}],
  [{color: '#409aff'}, {color: '#409aff'}, {color: '#409aff'}],
  [
    {color: '#409aff'}, {color: '#409aff'}, {color: '#ff4666'}, {color: '#cccfdb'}, {color: '#cccfdb'}, {color: '#cccfdb'},
    {color: '#cccfdb'}, {color: '#cccfdb'}
  ],
  [
    {color: '#409aff'}, {color: '#409aff'}, {color: '#409aff'}, {color: '#409aff'}, {color: '#409aff'}, {color: '#ff4666'},
    {color: '#cccfdb'}, {color: '#cccfdb'}, {color: '#cccfdb'}, {color: '#cccfdb'}
  ],
  [{color: '#409aff'}, {color: '#409aff'}, {color: '#409aff'}, {color: '#ff4666'}, {color: '#cccfdb'}],
];
