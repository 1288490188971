// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

// Types
import { SectionsTree } from './sections-tree';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Column {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  title?: string;
  position?: number;
  projectId?: string;
  color?: string;
  uncategorized?: boolean;

  // Tech properties

  /**
   * tasks - build columns tree structures
   */
  sectionsTree: SectionsTree;

  /**
   * temp - new column created but not sync with backend
   */
  temp?: boolean;

  borderColor?: string;

  constructor(columnObject: any = {}) {
    this.id = columnObject.id;
    this.title = columnObject.title;
    this.position = columnObject.position;
    this.projectId = columnObject.projectId;
    this.uncategorized = columnObject.uncategorized;
    this.temp = columnObject.temp;
    this.sectionsTree = columnObject.sectionsTree;

    const color = ['#fff3d0', '#ffebed', '#ddeec9'][Math.floor(Math.random() * 3)];
    this.color = this.hex2rgba(color, .5);
    this.borderColor = color;
  }

  static fromFormGroup(form: FormGroup): Column {
    const formValues = form.value;

    return new Column({
      id: formValues.id,
      title: formValues.title,
      position: formValues.position,
      projectId: formValues.projectId,
      color: formValues.color
    });
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      position: [this.position],
      projectId: [this.projectId],
      color: [this.color]
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      position: this.position,
      projectId: this.projectId,
      color: this.color,
    };
  }

  // TODO this will be removed
  private hex2rgba = (hex, alpha) => {
    let c;

    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // tslint:disable-next-line: no-bitwise
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${alpha})`;
  }
}
