<div class="tags">

  <span *ngIf="tags?.length" class="heading"># Tags:</span>

  <!-- Tags -->
  <app-tag
    *ngFor="let tag of tags"
    class="tag-item"
    [tag]="tag"
    [messages]="message ? [message.id] : []"
    [events]="event ? [event.id] : []"
    [projects]="project ? [project.id] : []"
    (delete)="deleteTag(tag)"
  ></app-tag>

  <!-- Placeholder -->
  <span *ngIf="!tags?.length" class="placeholder" (click)="newTagButton.click()">+ Add Tags</span>

  <!-- Add topic -->
  <app-icon-button 
    #newTagButton
    icon="plus-small"
    class="btn-action"
    popover
    [popoverContent]="createTagPopover"
    [popoverArrow]="false"
    popoverTrigger="click"
    popoverPlacement="bottom"
    [popoverShowUntil]="hidePopover"
    popoverAllowedOutsideSelectorsClick=".mat-autocomplete-panel.mat-autocomplete-visible"
  ></app-icon-button>

  <!-- Show Tag list -->
  <app-icon-button class="btn-action" icon="settings-small" (click)="showTagsModal()"></app-icon-button>
</div>

<!-- Popover -->
<ng-template #createTagPopover>
  <app-create-tag
    [messages]="message ? [message.id] : []"
    [events]="event ? [event.id] : []"
    [projects]="project ? [project.id] : []"
    (saved)="createdTags($event)"
    (close)="closeCreateTagPopover()"
  ></app-create-tag>
</ng-template>
