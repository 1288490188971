import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoGroupListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoGroupListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoGroupListComponent(modalService, groupsService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.groupsService = groupsService;
        _this.ngZone = ngZone;
        _this.type = 'group';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoGroupListComponent.prototype.getItems = function (ids) {
        return this.groupsService.getGroups({ groupsIds: ids, limit: ids.length })
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.groups; }));
    };
    /**
     * Actions
     */
    LinkedInfoGroupListComponent.prototype.select = function (group) {
        this.modalService.groupForm(group);
    };
    return LinkedInfoGroupListComponent;
}(LinkInfoPopover));
export { LinkedInfoGroupListComponent };
