<div class="modal-header">
  <div class="modal-title">Manage your pellets</div>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="topics-modal">
    <!-- Header -->
    <div class="topics-modal__header">
      <!-- Search -->
      <div class="topics-modal__search">
        <div class="input-prefix">
          <mat-icon svgIcon="search"></mat-icon>
        </div>
        <input type="text" class="search-input" placeholder="Search..." [formControl]="search">
      </div>
      <!-- Sort -->
      <div class="topics-modal__sort">
        <app-dropdown
          align="right"
          [title]="selectedOrder.name">
          <app-dropdown-single-select
            [options]="orders"
            [selected]="selectedOrder"
            (selectedChange)="selectOrder($event)">
          </app-dropdown-single-select>
        </app-dropdown>
      </div>
    </div>
    <!-- Body -->
    <div class="topics-modal__body" *ngIf="!loading">
      <div class="topics-list">
          <div class="topic-group" *ngFor="let group of groupTopics">
            <div class="topic-group__title">{{group.title}}</div>
            <ng-container *ngFor="let topic of group.topics">
              <div class="topic" *ngIf="topic !== editTopic">
                <div class="topic__title">{{topic.name}}</div>
                <div class="topic__icons">
                  <button class="button-icon-small btn-action" (click)="startEditTopic(topic)" matTooltip="Edit" matTooltipPosition="below">
                    <mat-icon svgIcon="edit"></mat-icon>
                  </button>
                  <button class="button-icon-small btn-action" (click)="deleteTopic(topic)" matTooltip="Delete" matTooltipPosition="below">
                    <mat-icon svgIcon="close"></mat-icon>
                  </button>
                </div>
              </div>
              <div class="topic--edit" *ngIf="topic === editTopic">
                <input
                  #editTopicInput
                  class="topic--edit__input"
                  [(ngModel)]="editTopicInputText"
                  type="text"
                  aria-describedby="pellet"
                  (keydown.enter)="finishEditTopic(topic)"
                  (keydown.esc)="cancelEditTopic()"
                  (paste)="changeEditTopic()"
                  (cut)="changeEditTopic()"
                  (input)="changeEditTopic()"
                  (focusout)="finishEditTopic(topic)"
                  >
                  <span #invisibleText class="invisible-text">{{ editTopicInputText }}</span>
                  <div class="topic__icons">
                    <button class="button-icon-small btn-action" (click)="finishEditTopic(topic)" matTooltip="Save" matTooltipPosition="below">
                      <mat-icon svgIcon="check" class="check-icon"></mat-icon>
                    </button>
                    <button class="button-icon-small btn-action" (click)="cancelEditTopic()" matTooltip="Cancel" matTooltipPosition="below">
                      <mat-icon svgIcon="close"></mat-icon>
                    </button>
                  </div>
              </div>
            </ng-container>
          </div>
      </div>
    </div>
    <div class="topics-modal__loader" *ngIf="loading">
      <div class="loader"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
    </div>
  </div>
</div>
