// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { Note } from '@modules/notes/types/note';

// Services
import { LayoutStateService } from '@modules/pages/services/layout-state.service';
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-knowledge-panel-form',
  templateUrl: './knowledge-panel-form.component.html',
  styleUrls: ['./knowledge-panel-form.component.less']
})
export class KnowledgePanelFormComponent implements OnInit, OnDestroy {

  // Public
  public formItem: CalendarEvent | Task | Project | Note;
  public formType: 'event' | 'task' | 'project' | 'note';

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private layoutStateService: LayoutStateService,
    private knowledgePanelService: KnowledgePanelService,
  ) {
    this.knowledgePanelService.getFormItem()
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((item: CalendarEvent | Task | Project | Note) => {
        this.formItem = item;

        switch (true) {
          case item instanceof CalendarEvent:
            this.formType = 'event';
            break;
          case item instanceof Task:
            this.formType = 'task';
            break;
          case item instanceof Project:
            this.formType = 'project';
            break;
          case item instanceof Note:
            this.formType = 'note';
            break;
        }
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.layoutStateService.setKPLinkedInfoToolbarPresence(true);
  }

  ngOnDestroy() {
    this.layoutStateService.setKPLinkedInfoToolbarPresence(false);
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.knowledgePanelService.setFormItem(null);
  }
}
