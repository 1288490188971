// Common
import { Injectable} from '@angular/core';

// Rx
import { Observable, combineLatest, merge } from 'rxjs';
import { map, switchMap, startWith, tap } from 'rxjs/operators';

// Types
import { SectionsTree } from '../types/sections-tree';
import { Task } from '../types/task';
import { Section } from '../types/section';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { SectionsService } from '@modules/tasks/services/sections.service';
import { TasksStateService } from './tasks-state.service';

@Injectable()
export class SectionsTreeService {

  /**
   * Constructor
   */

  constructor (
    private sectionsService: SectionsService,
    private tasksStateService: TasksStateService,
    private tasksService: TasksService,
  ) {

  }

  /**
   * Methods
   */

  getSections(projectId: string): Observable<SectionsTree> {
    return merge (
      this.sectionsService.sectionCreated,
      this.sectionsService.sectionUpdated,
      this.sectionsService.sectionDeleted,
      this.tasksService.taskCreated,
      this.tasksService.taskUpdated,
      this.tasksService.taskDeleted,
      this.tasksStateService.getRefreshAll()
    )
      .pipe(
        startWith(null as Task),
        switchMap(() => (
          combineLatest([
            this.sectionsService.getSections({ projectsIds: [projectId] }),
            this.tasksService.getTasks({ projectsIds: [projectId] })
          ])
        )),
        map(([ { sections }, { tasks } ]) => (
          new SectionsTree({ projectId, sections, tasks })
        ))
      );
  }

  saveSectionsOrder({ tree }: SectionsTree, newSectionId?: string) {
    const filteredSections = tree.map(section => section.id === 'temp' ? newSectionId : section.id);

    if (filteredSections.length === 0) { return; }

    return this.sectionsService.reorder(filteredSections);
  }

  saveSubtasksOrder(sectionId: string, parentId: string, subtasks: Task[], projectId?: string, columnId?: string) {
    this.tasksService.reorder(
      sectionId,
      (SectionsTree.sortByPosition(subtasks, columnId) as Task[]).map(task => task.id),
      projectId,
      columnId,
      parentId
    );
  }

  saveTasksOrder(sectionId: string, tasks: Task[], projectId?: string, columnId?: string) {
    const filteredTasks = tasks.filter(task => !task.temp);
    if (filteredTasks.length === 0) { return; }

    this.tasksService.reorder(sectionId, filteredTasks.map(task => task.id), projectId, columnId);
  }

  submitTask({tree}: SectionsTree, task: Task) {
    const section = tree.find(item => item.id === task.sectionId);

    this.tasksService.create(task)
      .subscribe(() => {
        if (section) {
          this.saveTasksOrder(section.id, section.tasks);
        }
      });
  }

  submitSection(tree: SectionsTree, sectionInstance: Section): Observable<Section> {
    return this.sectionsService.create(sectionInstance)
      .pipe(
        tap((section: Section) => {
          this.saveSectionsOrder(tree, section.id);
          this.saveTasksOrder(section.id, sectionInstance.tasks);
        })
      );
  }

  moveTask (
    tree: SectionsTree,
    task: Task,
    sectionInstance: Section,
    index: number
  ) {
    tree.moveTask(task, sectionInstance, index);

    const section = tree.tree.find(item => item.id === sectionInstance.id);

    this.saveTasksOrder(section.id, section.tasks, tree.projectId, tree.columnId);
  }

  moveSubtask (
    tree: SectionsTree,
    subtask: Task,
    taskInstance: Task,
    index: number
  ) {
    tree.moveSubtask(subtask, taskInstance, index);

    const section = tree.tree.find(item => item.id === taskInstance.sectionId);
    const task = section.tasks.find(item => item.id === taskInstance.id);

    this.saveSubtasksOrder(section.id, task.id, task.subtasks, tree.projectId, tree.columnId);
  }

  public moveSection(
    tree: SectionsTree,
    section: Section,
    direction: 1 | -1
  ) {
    tree.moveSection(section, direction);
    this.saveSectionsOrder(tree);
}
}
