// Common
import { Optional, Component, OnInit, ChangeDetectorRef, ElementRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest, merge } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

// Services
import { TagService } from '@modules/tag/services/tag.service';
import { MailService } from '@modules/mail/services/mail.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { StateService } from '@modules/settings/services/state.service';
import { ContentMenuService } from '../../services/content-menu.service';

// Types
import { Tag } from '@modules/tag/types/tag';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { SearchParam } from '@modules/search/types/search-param';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';
import { ContentMenuItemComponent } from '../content-menu-item/content-menu-item.component';
import { ContentMenuTagComponent } from '../content-menu-tag/content-menu-tag.component';

@Component({
  selector: 'app-content-menu-tags',
  templateUrl: './content-menu-tags.component.html',
  styleUrls: ['./content-menu-tags.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuTagsComponent }]
})
export class ContentMenuTagsComponent extends ContentMenuItemComponent implements OnInit, AfterViewInit {

  // Content Children
  @ViewChildren(
    ContentMenuTagComponent
  ) items: QueryList<ContentMenuTagComponent>;

  // Public
  public tags: Tag[] = [];
  public selectedTags: string[] = [];
  public orders: DropdownOption[] = [
    {name: 'Alphabet', key: 'name'},
    {name: 'Alphabet Reverse', key: 'name-desc'},
    {name: 'Most Frequent', key: 'frequency'},
    {name: 'Most Recent', key: 'recency'},
  ];
  public selectedOrder: DropdownOption;
  public collapsed = true;
  public pagination: {limit: number, offset: number, count: number, step: number} = {
    limit: 20,
    offset: 0,
    count: 0,
    step: 20
  };

  // Private
  private searchParams: SearchParam[] = [];
  private getPinnedTags = new Subject();

  /**
   * Constructor
   */

  constructor (
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef,
    stateService: StateService,
    private tagService: TagService,
    private mailService: MailService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() contentMenuService: ContentMenuService,
  ) {
    super(stateService, elementRef, changeDetector, contentMenuService);
  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit() {
    super.ngAfterContentInit();
  }

  ngOnInit() {
    // Set states
    this.selectedOrder = this.stateService.sortPinnedTags;

    // Get topics
    merge(this.tagService.getTagsUpdate(), this.mailService.getFolders(), this.getPinnedTags)
      .pipe(
        takeUntil(this.alive),
        switchMap(() => this.tagService.getTags({
            pinned: true,
            order: this.selectedOrder.key as 'name' | 'name-desc' | 'recency' | 'frequency',
            limit: this.pagination.limit,
            offset: this.pagination.offset
          })
        )
      )
      .subscribe(({tags, count}) => {
        this.tags = tags;
        this.pagination.count = count;
      });

    // Get selected topics
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.alive))
      .subscribe(([params, queryParams]) => {
        const folder = params.folder;
        const page = this.getMainRouteSegment();

        if (
          (
            (page === 'events' || page === 'tasks' || page === 'notes') ||
            (folder && folder === 'search')
          ) &&
          queryParams.query
        ) {
          this.searchParams = JSON.parse(queryParams.query);
          this.selectedTags = this.searchParams.filter(param => param.type === 'tag').map(param => param.value);
        } else {
          this.searchParams = [];
          this.selectedTags = [];
        }
      });

    this.getPinnedTags.next();
  }

  /**
   * Methods
   */

  performSearch(params: SearchParam[]) {
    const page = this.getMainRouteSegment();

    if (params && params.length > 0) {
      const searchParams = JSON.stringify(params);

      this.router.navigate(
        [page === 'mail' ? '../search' : `/${ page }`],
        { relativeTo: this.route, queryParams: {query: searchParams}}
      );
    } else {
      this.router.navigate(
        [page === 'mail' ? '../' : `/${ page }`],
        { relativeTo: this.route }
      );
    }
  }

  /**
   * Actions
   */

  selectTag(tag: Tag, multi: boolean = false) {
    if (multi && this.selectedTags.some(selectedTag => selectedTag === tag.name)) {
      this.searchParams = this.searchParams.filter(param => !(param.type === 'tag' && param.value === tag.name));
    } else {
      if (!multi) {
        this.searchParams = this.searchParams.filter(param => !(
          param.type === 'tag' &&
          this.tags.some(pinnedTag => pinnedTag.name === param.value)
        ));
      }
      this.searchParams.push({type: 'tag', value: tag.name});
    }
    this.performSearch(this.searchParams);
  }

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.stateService.sortPinnedTags = this.selectedOrder;
    this.getPinnedTags.next();
  }

  openTagsModal() {
    this.modalService.topicList();
  }

  showMore() {
    this.pagination.limit += this.pagination.step;
    this.getPinnedTags.next();
  }

  /**
   * Helpers
   */

  getMainRouteSegment(): string {
    return this.router.url.split(/\/|\?/)[1];
  }
}
