// Common
import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

declare var caja: any;

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.less']
})
export class CajaComponent implements OnInit, OnChanges, AfterViewInit {

  // Public
  public uniqueBodyID: string;

  // Private
  private preparedBody: string;
  private cajaFrame: any;

  // Inputs
  @Input() body: string;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.uniqueBodyID = 'caja-body' + Math.random();

    if (caja.state === 'UNREADY') {
      caja.initialize({
        cajaServer: window.location.origin + '/assets/caja/',
        debug: true
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('body' in changes) {
      this.preparedBody = this.body;

      // This replacement required due to caja issue: https://github.com/google/caja/issues/2031
      const multilineTags = this.preparedBody.match(/<[^>]*\n+.*?>/gm);
      if (multilineTags) {
        multilineTags.forEach(tag => {
          this.preparedBody = this.preparedBody.replace(tag, tag.replace(/\n/g, ' '));
        });
      }

      if (this.uniqueBodyID) {
        this.displayBody();
      }
    }
  }

  ngAfterViewInit() {
    if (this.preparedBody) {
      this.displayBody();
    }
  }

  /**
   * Actions
   */

  private displayBody() {
    if (this.cajaFrame) {
      this.cajaFrame.code('', 'text/html', this.preparedBody)
        .run();
    } else {
      caja.load(document.getElementById(this.uniqueBodyID), {
        'rewrite': uri => String(uri),
        'fetch': (url, mime, callback) => {
          setTimeout(() => callback({}), 0);
        }
      }, frame => {
        this.cajaFrame = frame;
        this.cajaFrame.code('', 'text/html', this.preparedBody)
          .run();
      });
    }
  }

}
