import { ExtendedAttachment } from './extended-attachment';
import { Hyperlink } from './hyperlink';
import { MailMessage } from './mail-message';
import { Recipient } from './recipient';

export class KnowledgeGraphRelatedInfo {
  topics: {
    count: number;
    data: string[];
  };
  connections: {
    count: number;
    data: Recipient[];
  };
  messages: {
    count: number;
    data: MailMessage[];
  };
  files: {
    images: {
      count: number;
      data: ExtendedAttachment[];
    };
    videos: {
      count: number;
      data: ExtendedAttachment[];
    };
    documents: {
      count: number;
      data: ExtendedAttachment[];
    };
    all: {
      count: number,
      data: ExtendedAttachment[]
    }
  };
  hyperlinks: {
    count: number;
    data: Hyperlink[];
  };

  constructor() {
    this.topics = { count: 0, data: [] };
    this.connections = { count: 0, data: [] };
    this.messages = { count: 0, data: [] };
    this.files = {
      images: {count: 0, data: [] },
      videos: {count: 0, data: [] },
      documents: {count: 0, data: [] },
      all: {count: 0, data: [] }
    };
    this.hyperlinks = { count: 0, data: [] };
  }
}
