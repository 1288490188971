/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tasks-date-picker.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../drag-n-drop/directives/droppable.directive";
import * as i4 from "../../../drag-n-drop/services/drag-n-drop.service";
import * as i5 from "../../../pipes/pipes/different-day.pipe";
import * as i6 from "../../pipes/calendar-color.pipe";
import * as i7 from "../../../events/services/calendar.service";
import * as i8 from "../date-picker/date-picker.component.ngfactory";
import * as i9 from "angular-calendar";
import * as i10 from "../date-picker/date-picker.component";
import * as i11 from "ngx-contextmenu";
import * as i12 from "../../../settings/services/state.service";
import * as i13 from "./tasks-date-picker.component";
import * as i14 from "../../../tasks/services/tasks.service";
import * as i15 from "../../../popover/services/popover.service";
import * as i16 from "../../../tasks/services/tasks-state.service";
var styles_TasksDatePickerComponent = [i0.styles];
var RenderType_TasksDatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TasksDatePickerComponent, data: {} });
export { RenderType_TasksDatePickerComponent as RenderType_TasksDatePickerComponent };
function View_TasksDatePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "day-cell-circle"]], [[2, "selected", null], [2, "disabled", null]], null, null, null, null)), i1.ɵppd(1, 2), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputFormControl.value && !i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.day.date, _co.inputFormControl.value))); var currVal_1 = (_co.maxDate < _v.parent.context.day.date); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.day.date, "d")); _ck(_v, 2, 0, currVal_2); }); }
function View_TasksDatePickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "day-cell-dots-item"]], [[4, "background-color", null]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.meta.color || i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 2), _v.context.$implicit.meta.calendarId))))); _ck(_v, 0, 0, currVal_0); }); }
function View_TasksDatePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["cell", 1]], null, 6, "div", [["appDroppable", ""], ["appDroppableHoverClass", "drag-over"], ["class", "day-cell"]], null, [[null, "appDroppableDrop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDroppableDrop" === en)) {
        var pd_0 = (_co.handleDrop($event, _v.context.day.date, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, null, 0, i3.DroppableDirective, [i4.DragNDropService, i1.Renderer2, i1.ElementRef, i1.NgZone], { appDroppableHoverClass: [0, "appDroppableHoverClass"] }, { appDroppableDrop: "appDroppableDrop" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksDatePickerComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "day-cell-dots"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksDatePickerComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "drag-over"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.day.inMonth; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.day.events.slice(0, 3); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_TasksDatePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DifferentDayPipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i6.CalendarColorPipe, [i7.CalendarService]), i1.ɵqud(402653184, 1, { popoverFormTemplate: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-date-picker", [], null, [[null, "activeDateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeDateChange" === en)) {
        var pd_0 = (_co.handleActiveDateChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DatePickerComponent_0, i8.RenderType_DatePickerComponent)), i1.ɵprd(4608, null, i9.CalendarDateFormatter, i10.CalendarMonthDateFormatter, [i9.DateAdapter]), i1.ɵdid(6, 770048, null, 0, i10.DatePickerComponent, [i11.ContextMenuService, i2.DatePipe, i12.StateService], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"], disabled: [3, "disabled"], appearance: [4, "appearance"], inline: [5, "inline"], bundledInputConsumerKeys: [6, "bundledInputConsumerKeys"], bundledInputAppearance: [7, "bundledInputAppearance"], bundledInputConsumerGroup: [8, "bundledInputConsumerGroup"], collapsedStateKey: [9, "collapsedStateKey"], maxDate: [10, "maxDate"], collapseable: [11, "collapseable"], dayTemplate: [12, "dayTemplate"], events: [13, "events"] }, { activeDateChange: "activeDateChange" }), (_l()(), i1.ɵand(0, [["dayTemplate", 2]], null, 0, null, View_TasksDatePickerComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; var currVal_1 = _co.placeholder; var currVal_2 = _co.width; var currVal_3 = _co.disabled; var currVal_4 = _co.appearance; var currVal_5 = _co.inline; var currVal_6 = _co.bundledInputConsumerKeys; var currVal_7 = _co.bundledInputAppearance; var currVal_8 = _co.bundledInputConsumerGroup; var currVal_9 = _co.collapsedStateKey; var currVal_10 = _co.maxDate; var currVal_11 = _co.collapseable; var currVal_12 = i1.ɵnov(_v, 7); var currVal_13 = _co.events; _ck(_v, 6, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
export function View_TasksDatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-date-picker", [], null, null, null, View_TasksDatePickerComponent_0, RenderType_TasksDatePickerComponent)), i1.ɵdid(1, 245760, null, 0, i13.TasksDatePickerComponent, [i14.TasksService, i12.StateService, i15.PopoverService, i16.TasksStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TasksDatePickerComponentNgFactory = i1.ɵccf("app-tasks-date-picker", i13.TasksDatePickerComponent, View_TasksDatePickerComponent_Host_0, { inputFormControl: "inputFormControl", placeholder: "placeholder", width: "width", disabled: "disabled", appearance: "appearance", inline: "inline", bundledInputConsumerKeys: "bundledInputConsumerKeys", bundledInputAppearance: "bundledInputAppearance", bundledInputConsumerGroup: "bundledInputConsumerGroup", collapsedStateKey: "collapsedStateKey", maxDate: "maxDate", collapseable: "collapseable", filters: "filters" }, {}, []);
export { TasksDatePickerComponentNgFactory as TasksDatePickerComponentNgFactory };
