// Common
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Print
import print, { Configuration } from 'print-js';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { MailService } from '@modules/mail/services/mail.service';

// Types
import { ExtendedAttachment } from '@modules/mail/types/extended-attachment';
import { MailMessage } from '@modules/mail/types/mail-message';

/**
 * Type Aliases
 */
type FileType = 'all' | 'images' | 'videos' | 'documents';

@Component({
  selector: 'app-related-files',
  templateUrl: './related-files.component.html',
  styleUrls: ['./related-files.component.less']
})
export class RelatedFilesComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() files: ExtendedAttachment[];
  @Input() filesType: FileType = 'all';
  @Input() totalCount: number;
  @Input() loading = false;
  @Input() error = false;
  @Input() multiplePreview = false;

  // Outputs
  @Output() loadMore: EventEmitter<string> = new EventEmitter();

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private mailService: MailService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  openAttachmentModal(file: ExtendedAttachment, $event?: MouseEvent) {
    if ($event) {
      $event.stopPropagation();
    }
    if (this.multiplePreview && this.files.indexOf(file) > -1) {
      const index = this.files.indexOf(file);
      this.modalService.showAttachmentsModal(this.files, index);
    } else {
      this.modalService.showAttachmentsModal([file], 0);
    }
  }

  openMessageModal(file: ExtendedAttachment) {
    if (file.messageId) {
      this.mailService.getMessage(file.messageId)
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((message: MailMessage) => this.modalService.mailContent(message));
    }
  }

  openComposeModal(file: ExtendedAttachment) {
    const message = new MailMessage();
    message.files = [file];
    this.modalService.compose(message);
  }

  downloadFile(file: ExtendedAttachment, $event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    window.open(file.url, '_blank');
  }

  isFileFromMessage(file: ExtendedAttachment): boolean {
    return !!file.messageId;
  }

  isFilePrintable(file: ExtendedAttachment): boolean {
    return file ? file.type.match(/^image\//) !== null || file.type.match('application/pdf') !== null : false;
  }

  printFile(file: ExtendedAttachment) {
    // Image
    if (file && file.type.match(/^image\//)) {
      print({printable: file.url, type: 'image'} as Configuration);
    }
    // PDF
    if (file && file.type.match('application/pdf')) {
      print({printable: file.url, type: 'pdf'} as Configuration);
    }
  }

  onLoadMore() {
    this.loadMore.emit(this.filesType);
  }

}
