import * as tslib_1 from "tslib";
// RxJS
import { Observable, Subject, throwError, from, combineLatest, of, interval, BehaviorSubject } from 'rxjs';
import { map, concatMap, toArray, catchError, tap, switchMap, filter, retryWhen, distinctUntilChanged } from 'rxjs/operators';
// Decorators
import { warmUpObservable } from '@decorators';
// Env
import { environment } from '@environment';
var MessagesFilters = /** @class */ (function () {
    function MessagesFilters() {
    }
    return MessagesFilters;
}());
export { MessagesFilters };
var MailService = /** @class */ (function () {
    /**
     * Constructor
     */
    function MailService(http, stateService, asyncTasksService, asyncTasksToastsService, linkedInfoService) {
        var _this = this;
        this.http = http;
        this.stateService = stateService;
        this.asyncTasksService = asyncTasksService;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.linkedInfoService = linkedInfoService;
        this.onMoveToFolder = new Subject();
        this.onSentMessage = new Subject();
        this.refreshAllMails = new Subject();
        this.fetchFolders = new Subject();
        // To prevent calling .next() from Component
        this.refreshAllMails$ = this.refreshAllMails.asObservable();
        // Private
        this.folders = new BehaviorSubject([]);
        this.recentFolders = new BehaviorSubject([]);
        this.recentFolders.next(this.stateService.lastFoldersMovedTo);
        // Fetch Folders
        this.fetchFolders
            .pipe(switchMap(function () { return _this.http.get(environment.baseUrl + "/api/mail/folders"); }), retryWhen(function (error) { return error; }))
            .subscribe(function (folders) { return _this.folders.next(folders); });
        interval(30000)
            .pipe(filter(function () { return _this.folders.observers.length > 0; }))
            .subscribe(function () { return _this.fetchFolders.next(); });
    }
    /**
     * Static methods
     */
    MailService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    MailService.handlePromiseError = function (error) {
        console.error(error);
        return Promise.resolve(false);
    };
    MailService.findFolder = function (folders, folderId) {
        if (!folders) {
            return;
        }
        var recursiveFindFolder = (function (folderList) {
            var e_1, _a;
            try {
                for (var folderList_1 = tslib_1.__values(folderList), folderList_1_1 = folderList_1.next(); !folderList_1_1.done; folderList_1_1 = folderList_1.next()) {
                    var folder = folderList_1_1.value;
                    if (folder.id === folderId) {
                        return folder;
                    }
                    if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
                        var result = recursiveFindFolder(folder.subFolders);
                        if (result !== undefined) {
                            return result;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (folderList_1_1 && !folderList_1_1.done && (_a = folderList_1.return)) _a.call(folderList_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
        return recursiveFindFolder(folders);
    };
    MailService.attachmentIcon = function (type) {
        var iconsClasses = {
            'audio': 'file-audio',
            'default': 'file-archive',
            'image': 'file-image',
            'text': 'file-text',
            'video': 'file-video',
            'application/ics': 'calendar',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
            'application/javascript': 'file-code',
            'application/msword': 'file-word',
            'application/pdf': 'file-pdf',
            'text/html': 'file-code',
            'text/xml': 'file-excel',
            'text/calendar': 'calendar',
        };
        return "fa fa-" + (iconsClasses[type] || iconsClasses[type.split('/')[0]] || iconsClasses.default) + "-o";
    };
    /**
     * Methods
     */
    MailService.prototype.formatFilters = function (filters, sortBy, limit, offset) {
        var formatedFilters = {};
        if (filters.ids) {
            formatedFilters['messages_ids[]'] = filters.ids;
        }
        if (filters.folder) {
            formatedFilters['folder'] = filters.folder;
        }
        if (filters.messagesIds) {
            formatedFilters['messages_ids[]'] = filters.messagesIds;
        }
        if (filters.threadIds) {
            formatedFilters['thread_ids[]'] = filters.threadIds;
        }
        if (filters.groupBy) {
            formatedFilters['groupby'] = filters.groupBy;
        }
        if (filters.fromTime) {
            formatedFilters['from_time'] = filters.fromTime.getTime() + '';
        }
        if (filters.toTime) {
            formatedFilters['to_time'] = filters.toTime.getTime() + '';
        }
        if ('starred' in filters) {
            formatedFilters['starred'] = filters.starred + '';
        }
        if ('pinned' in filters) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if ('snoozed' in filters) {
            formatedFilters['snoozed'] = filters.snoozed + '';
        }
        if ('followed' in filters) {
            formatedFilters['followed'] = filters.followed + '';
        }
        if ('relevance' in filters) {
            formatedFilters['use_relevance'] = filters.relevance + '';
        }
        if ('orderWithPinned' in filters) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.topics) {
            formatedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formatedFilters['tags[]'] = filters.tags;
        }
        if (filters.contacts) {
            formatedFilters['contacts[]'] = filters.contacts;
        }
        if (filters.keywords) {
            formatedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.relatedTopics) {
            formatedFilters['related_topics[]'] = filters.relatedTopics;
        }
        if (filters.relatedContacts) {
            formatedFilters['related_contacts[]'] = filters.relatedContacts.map(function (contact) { return contact.id; });
        }
        if (filters.relatedMessageId) {
            formatedFilters['related_message_id'] = filters.relatedMessageId;
        }
        if (filters.relatedMessageTopics) {
            formatedFilters['related_message_topics[]'] = filters.relatedMessageTopics;
        }
        if (filters.matchingTypes) {
            formatedFilters['matching_types[]'] = filters.matchingTypes;
        }
        if (filters.firstSymbol) {
            formatedFilters['first_symbol'] = filters.firstSymbol;
        }
        if (sortBy) {
            formatedFilters['sort_by'] = sortBy;
        }
        if (limit) {
            formatedFilters['limit'] = limit + '';
        }
        if (offset) {
            formatedFilters['offset'] = offset + '';
        }
        formatedFilters['timing'] = localStorage.getItem('timing');
        return formatedFilters;
    };
    MailService.prototype.getFolders = function () {
        this.fetchFolders.next();
        return this.folders.asObservable();
    };
    MailService.prototype.getFoldersCounts = function (filters) {
        return this.http.get(environment.baseUrl + '/api/mail/folders/counters', { params: this.formatFilters(filters) })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    MailService.prototype.getFolderDetails = function (folder) {
        return this.getFolders()
            .pipe(map(function (folders) { return MailService.findFolder(folders, folder); }), distinctUntilChanged(function (oldDetails, newDetails) {
            return oldDetails && newDetails &&
                oldDetails.id === newDetails.id &&
                oldDetails.folderId === newDetails.folderId &&
                oldDetails.name === newDetails.name &&
                oldDetails.totalMails === newDetails.totalMails &&
                oldDetails.unreadMails === newDetails.unreadMails &&
                oldDetails.icon === newDetails.icon &&
                oldDetails.pinned === newDetails.pinned;
        }));
    };
    MailService.prototype.createFolder = function (folderName) {
        var _this = this;
        var body = {
            display_name: folderName
        };
        var createFolder$ = this.http.post(environment.baseUrl + '/api/mail/folders/', body)
            .pipe(map(function (response) { return response['data']; }));
        var fetchFolders$ = this.http.get(environment.baseUrl + '/api/mail/folders');
        return from([createFolder$, fetchFolders$])
            .pipe(concatMap(function (observer) { return observer; }), toArray(), map(function (result) {
            var folder = result[0];
            var folders = result[1];
            _this.folders.next(folders);
            return MailService.findFolder(_this.folders.value, folder.id);
        }));
    };
    MailService.prototype.renameFolder = function (folderId, folderName) {
        var _this = this;
        var body = {
            display_name: folderName
        };
        return this.http.put(environment.baseUrl + '/api/mail/folders/' + folderId, body)
            .pipe(tap(function () { return _this.fetchFolders.next(); }), map(function (response) { return response['success']; }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.deleteFolder = function (folderId) {
        var _this = this;
        return this.http.delete(environment.baseUrl + '/api/mail/folders/' + folderId)
            .pipe(tap(function () { return _this.fetchFolders.next(); }), map(function (response) { return response['success']; }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.pinFolder = function (folders, pinned) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/mail/folders/pinned', { folders: folders, pinned: pinned })
            .pipe(tap(function () { return _this.fetchFolders.next(); }), map(function (response) { return response['success']; }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.getMessages = function (filters, sortBy, limit, offset) {
        var requestParams = {
            params: this.formatFilters(filters, sortBy, limit, offset)
        };
        return this.http.get(environment.baseUrl + '/api/mail/messages', requestParams)
            .pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.getMessage = function (id) {
        return this.http.get(environment.baseUrl + "/api/mail/messages/" + id)
            .pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    MailService.prototype.getMessagesOffset = function (filters, sortBy) {
        var requestParams = {
            params: this.formatFilters(filters, sortBy)
        };
        return this.http.get(environment.baseUrl + '/api/mail/message-offset', requestParams)
            .pipe(catchError(MailService.handleObserverError));
    };
    MailService.prototype.updateMessagesUnreadStatus = function (filters, unread) {
        var _this = this;
        if (unread === void 0) { unread = false; }
        return this.http.put(environment.baseUrl + '/api/mail/messages/unread', { unread: unread }, { params: this.formatFilters(filters) })
            .pipe(switchMap(function (_a) {
            var success = _a.success, asyncTask = _a.asyncTask;
            if (filters.messagesIds && filters.messagesIds.length === 1) {
                return of(success);
            }
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: "Marking message(s) as " + (unread ? 'unread' : 'read') + ".",
                    },
                    completed: {
                        text: "Message(s) marked as " + (unread ? 'unread' : 'read') + ".",
                    },
                    error: {
                        text: "Error while marking message(s) as " + (unread ? 'unread' : 'read') + ". Please try again.",
                    }
                }
            });
        }), tap(function () { return _this.doRefreshMailList(); }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.starMessage = function (message) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/mail/messages/star', {
            message_id: message.id,
            starred: !message.starred
        })
            .pipe(tap(function () { return _this.fetchFolders.next(); }), map(function () {
            message.starred = !message.starred;
            return true;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.dateConvector = function (currentDate, alertTime) {
        switch (alertTime) {
            case 'hour':
                currentDate.setHours(currentDate.getHours() + 1);
                break;
            case 'tomorrow':
                currentDate.setDate(currentDate.getDate() + 1);
                break;
            case 'afterTomorrow':
                currentDate.setDate(currentDate.getDate() + 2);
                break;
            case 'endOfWeek':
                currentDate.setDate(currentDate.getDate() - (currentDate.getDay() - 5));
                break;
            case 'nextWeek':
                currentDate.setDate(currentDate.getDate() + (-currentDate.getDay() + 7) % 7 + 1);
                break;
            default:
                break;
        }
        return currentDate;
    };
    MailService.prototype.snoozeMessage = function (snooze, messageId, customDate) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/mail/messages/snooze", {
            id: messageId,
            snooze_schedule: this.dateConvector(customDate || new Date(), snooze)
        })
            .pipe(tap(function () { return _this.doRefreshMailList(); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.followMessage = function (snooze, messageId, customDate) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/mail/messages/follow", {
            id: messageId,
            follow_schedule: this.dateConvector(customDate || new Date(), snooze)
        })
            .pipe(tap(function () { return _this.doRefreshMailList(); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.removeSnoozeMessage = function (messageId) {
        var _this = this;
        return this.http.request('DELETE', environment.baseUrl + "/api/mail/messages/snooze", { body: { id: messageId } })
            .pipe(tap(function () { return _this.doRefreshMailList(); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.removeFollowMessage = function (messageId) {
        var _this = this;
        return this.http.request('DELETE', environment.baseUrl + "/api/mail/messages/follow", { body: { id: messageId } })
            .pipe(tap(function () { return _this.doRefreshMailList(); }), map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.sendMessage = function (message) {
        var _this = this;
        var linkedInfo = message.linkedInfo;
        return this.http.post(environment.baseUrl + '/api/mail/messages/send', { message: message })
            .pipe(tap(function (_a) {
            var sentMessage = _a.message;
            _this.fetchFolders.next();
            _this.onSentMessage.next(sentMessage);
        }), tap(function (_a) {
            var sentMessage = _a.message;
            if (linkedInfo && linkedInfo.length) {
                _this.linkedInfoService.linkToItem({ type: 'message', id: sentMessage.id }, linkedInfo);
            }
        }), map(function (_a) {
            var sentMessage = _a.message;
            return sentMessage;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.saveMessageToDrafts = function (message) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/mail/messages/drafts', { message: message })
            .pipe(tap(function (_a) {
            var draft = _a.draft;
            if (message.linkedInfo && message.linkedInfo.length) {
                _this.linkedInfoService.linkToItem({ type: 'message', id: draft.id }, message.linkedInfo);
            }
        }), map(function (_a) {
            var draft = _a.draft;
            return draft;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.deleteDraft = function (message) {
        return this.http.request('DELETE', environment.baseUrl + '/api/mail/messages/drafts/' + message.id, { body: { message: message } })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.uploadFile = function (file) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(environment.baseUrl + '/api/mail/files', formData)
            .pipe(map(function (_a) {
            var attachment = _a.file;
            return attachment;
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.downloadFile = function (url) {
        return this.http.get(url, { responseType: 'blob' });
    };
    MailService.prototype.getTopics = function (message, forceProcess) {
        if (forceProcess === void 0) { forceProcess = false; }
        var params = {};
        if (forceProcess) {
            params['force_process'] = 'true';
        }
        return this.http.get(environment.baseUrl + '/api/mail/messages/' + message.id + '/topics', { params: params })
            .pipe(map(function (res) { return res['data']; }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.getTopicsAnnotations = function (messageId) {
        return this.http.get(environment.baseUrl + "/api/mail/messages/" + messageId + "/topics/annotations");
    };
    MailService.prototype.saveTopicsAnnotations = function (messageId, annotations, shareText) {
        return this.http.post(environment.baseUrl + "/api/mail/messages/" + messageId + "/topics/annotations", { annotations: annotations, shareText: shareText })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }));
    };
    MailService.prototype.getTemporalExpressions = function (message) {
        return this.http.get(environment.baseUrl + '/api/messages/' + message.id + '/temporal-expressions', { params: {
                timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                timezone_offset: new Date().getTimezoneOffset() + ''
            } })
            .pipe(map(function (response) { return response['expressions'].map(function (expression) {
            expression.fromTime = expression.fromTime ? new Date(expression.fromTime) : null;
            expression.toTime = expression.toTime ? new Date(expression.toTime) : null;
            return expression;
        }); }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.moveMessages = function (_a, folders, copy) {
        var _this = this;
        var messages = _a.messages, filters = _a.filters;
        if (copy === void 0) { copy = false; }
        return this.http.put(environment.baseUrl + '/api/mail/messages/folders', {
            messages: messages ? messages.map(function (message) { return message.id; }) : null,
            filters: filters ? this.formatFilters(filters) : null,
            // Convert folders ids to folderId values, which requried for api
            folders: folders.map(function (folder) { return MailService.findFolder(_this.folders.value, folder).folderId; }),
            copy: copy
        })
            .pipe(switchMap(function (_a) {
            var _b = _a.data, asyncTask = _b.asyncTask, movingMessages = _b.messages;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: (!copy ? 'Moving' : 'Copy') + " " + (movingMessages ? movingMessages.length : '') + " message(s).",
                        actions: ['undo']
                    },
                    completed: {
                        text: "Message(s) " + (!copy ? 'moved' : 'copied') + ".",
                        actions: ['undo']
                    },
                    error: {
                        text: "Error while " + (!copy ? 'moving' : 'copy') + " message(s). Please try again.",
                        actions: ['undo']
                    }
                },
                actions: {
                    undo: {
                        text: 'Undo',
                        handler: function () { return _this.asyncTasksService
                            .undo(asyncTask)
                            .pipe(switchMap(function (undoAsyncTaks) { return _this.asyncTasksToastsService.showAwaitProgress(undoAsyncTaks.asyncTaks, {
                            status: {
                                processing: {
                                    text: "Undoing\n                            " + (!copy ? 'moving' : 'copy') + " of\n                            " + (undoAsyncTaks.data.messages ? undoAsyncTaks.data.messages.length : '') + "\n                            message(s).",
                                },
                                completed: { text: (!copy ? 'Moving' : 'Copy') + " undone." },
                                error: { text: "Error while undo " + (!copy ? 'moving' : 'copy') + " message(s)." }
                            }
                        }); }))
                            .subscribe(function () { return _this.doRefreshMailList(); }); }
                    }
                }
            });
        }), tap(function () { return _this.doRefreshMailList(); }));
    };
    MailService.prototype.archiveMessages = function (messages, archived) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/mail/archivation/", {
            messages: messages.map(function (msg) { return msg.id; }),
            archived: archived
        })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), tap(function (success) {
            _this.doRefreshMailList();
            _this.asyncTasksToastsService.show({
                text: success
                    ? "Message(s) " + (archived ? 'archived' : 'restored') + "."
                    : (archived ? 'Archiving' : 'Restoring') + " failed, please try again."
            });
        }), catchError(function (error) {
            _this.asyncTasksToastsService.show({
                text: (archived ? 'Archiving' : 'Restoring') + " failed, please try again."
            });
            throw error;
        }));
    };
    MailService.prototype.deleteMessages = function (filters) {
        var _this = this;
        return this.http.delete(environment.baseUrl + "/api/mail/messages", {
            params: this.formatFilters(filters)
        })
            .pipe(switchMap(function (_a) {
            var _b = _a.data, asyncTask = _b.asyncTask, movingMessages = _b.messages;
            return _this.asyncTasksToastsService.showAwaitProgress(asyncTask, {
                status: {
                    processing: {
                        text: "Deleting " + (movingMessages ? movingMessages.length : '') + " message(s).",
                        actions: ['undo']
                    },
                    completed: {
                        text: "Message(s) deleted.",
                        actions: ['undo']
                    },
                    error: {
                        text: "Error while deleting message(s). Please try again.",
                        actions: ['undo']
                    }
                },
                actions: {
                    undo: {
                        text: 'Undo',
                        handler: function () { return _this.asyncTasksService
                            .undo(asyncTask)
                            .pipe(switchMap(function (undoAsyncTaks) { return _this.asyncTasksToastsService.showAwaitProgress(undoAsyncTaks.asyncTaks, {
                            status: {
                                processing: {
                                    text: "Restoring\n                            " + (undoAsyncTaks.data.messages ? undoAsyncTaks.data.messages.length : '') + "\n                            message(s).",
                                },
                                completed: { text: "Message(s) restored." },
                                error: { text: "Error while restoring message(s)." }
                            }
                        }); }))
                            .subscribe(function () { return _this.doRefreshMailList(); }); }
                    }
                }
            });
        }), tap(function () { return _this.doRefreshMailList(); }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.pinnedMessage = function (message) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/message/" + message.id + "/pinned", { pinned: !message.pinned })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), tap(function (success) {
            if (success) {
                message.pinned = !message.pinned;
                _this.doRefreshMailList();
            }
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.doRefreshMailList = function () {
        this.refreshAllMails.next();
        this.fetchFolders.next();
    };
    MailService.prototype.getRecentFolders = function () {
        return combineLatest([this.folders, this.recentFolders])
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), folders = _b[0], recentFoldersIds = _b[1];
            return recentFoldersIds
                .map(function (id) { return folders.find(function (folder) { return folder.id === id; }); })
                .filter(function (folder) { return !!folder; });
        }));
    };
    MailService.prototype.updateRecipientVipStatus = function (recipient, status) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/contacts/" + recipient.id + "/vip", { vip: status })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), tap(function (success) {
            if (success) {
                recipient.vip = status;
                _this.fetchFolders.next();
            }
        }), catchError(MailService.handleObserverError));
    };
    MailService.prototype.cancelSendMessage = function (message) {
        var _this = this;
        return this.asyncTasksService
            .undo({ type: 'message-send', id: "send-message-" + message.id })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }), tap(function (draftMessage) {
            _this.fetchFolders.next();
            _this.onMoveToFolder.next(draftMessage);
            _this.asyncTasksToastsService.show({
                text: 'Message sending undone.'
            });
        }), catchError(function (error) {
            _this.asyncTasksToastsService.show({
                text: 'Message sending can\'t be undone.'
            });
            throw error;
        }), catchError(MailService.handleObserverError));
    };
    // tslint:disable-next-line:max-line-length
    MailService.emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    MailService.defaultFolderIds = [
        'inbox', 'all', 'vips', 'prioritized', 'starred', 'important', 'outbox',
        'sent', 'drafts', 'snoozed', 'followed', 'archive', 'spam', 'trash'
    ];
    MailService.disallowMoveFolderIds = [
        'vips', 'prioritized', 'starred', 'outbox', 'sent', 'drafts',
        'snoozed', 'followed', 'archive'
    ];
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "updateMessagesUnreadStatus", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "snoozeMessage", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "followMessage", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "removeSnoozeMessage", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "removeFollowMessage", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "sendMessage", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "saveMessageToDrafts", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "deleteDraft", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "moveMessages", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "archiveMessages", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "deleteMessages", null);
    tslib_1.__decorate([
        warmUpObservable
    ], MailService.prototype, "cancelSendMessage", null);
    return MailService;
}());
export { MailService };
