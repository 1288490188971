<context-menu #contextMenu>
  <ng-template contextMenuItem [enabled]="false">Open</ng-template>
  <ng-template contextMenuItem (execute)="pin([group])" [visible]="!group?.pinned">Pin</ng-template>
  <ng-template contextMenuItem (execute)="unpin([group])" [visible]="group?.pinned">Unpin</ng-template>
  <ng-template contextMenuItem [enabled]="false">Snooze All</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add New Task</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select</ng-template>
  <ng-template contextMenuItem [enabled]="false">Select All</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Copy to</ng-template>
  <ng-template contextMenuItem [enabled]="false">Move To group</ng-template>
  <ng-template contextMenuItem [enabled]="false">Duplicate</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <!--  Stitch  -->
  <ng-template contextMenuItem [subMenu]="stitchMenu">Stitch</ng-template>
  <context-menu #stitchMenu>
    <ng-template contextMenuItem [subMenu]="stitchMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuTask">Task</ng-template>
    <ng-template contextMenuItem [subMenu]="stitchMenuNote">Note</ng-template>
  </context-menu>
  <context-menu #stitchMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions appearance="context-menu" [linkedInfo]="[{type: 'group', id: group.id}]" (close)="closeContextMenu()"></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions appearance="context-menu" [linkedInfo]="[{type: 'group', id: group.id}]" (close)="closeContextMenu()"></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuTask>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-task-suggestions appearance="context-menu" [linkedInfo]="[{type: 'group', id: group.id}]" (close)="closeContextMenu()"></app-link-info-task-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #stitchMenuNote>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-note-suggestions appearance="context-menu" [linkedInfo]="[{type: 'group', id: group.id}]" (close)="closeContextMenu()"></app-link-info-note-suggestions>
    </ng-template>
  </context-menu>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Add Threads</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Tags</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem [enabled]="false">Print group</ng-template>
  <!--  Rename group  -->
  <ng-template contextMenuItem [subMenu]="renamegroup">Rename group</ng-template>
  <context-menu #renamegroup>
    <ng-template contextMenuItem [passive]="true">
      <div class="rename-group-container">
        <div class="rename-group-container-header">
          <span>Rename group</span>
          <mat-icon class="icon" svgIcon="close" (click)="handleRenameCancel()"></mat-icon>
        </div>
        <div class="rename-group-container-input">
          <app-input
            appearance="standard"
            icon="group"
            [placeholder]="group.title"
            [inputFormControl]="groupTitleFormControl"
          ></app-input>
        </div>
        <div class="rename-group-container-footer">
          <app-button
            class="rename-group-container-footer-item"
            (click)="handleRenameCancel()"
          >Cancel</app-button>
          <app-button
            class="rename-group-container-footer-item"
            appearance="blue"
            (click)="handleRenameSubmit()"
          >Save</app-button>
        </div>
      </div>
    </ng-template>
  </context-menu>

  <ng-template contextMenuItem (execute)="moveToArchive([group])" [visible]="!group?.archived">Archive</ng-template>
  <ng-template contextMenuItem (execute)="restoreFromArchive([group])" [visible]="group?.archived">Restore from Archive</ng-template>
  <ng-template contextMenuItem (execute)="delete([group])" [visible]="!group?.deleted">Trash</ng-template>
  <ng-template contextMenuItem (execute)="restoreFromTrash([group])" [visible]="group?.deleted">Restore from Trash</ng-template>
  <ng-template contextMenuItem [enabled]="false">Settings</ng-template>
</context-menu>
