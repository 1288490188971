/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes-panel.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../notes/components/notes-list-header/notes-list-header.component.ngfactory";
import * as i4 from "../../../notes/components/notes-list-header/notes-list-header.component";
import * as i5 from "../../../settings/services/state.service";
import * as i6 from "../../../quick-forms/components/quick-form/quick-form.component.ngfactory";
import * as i7 from "../../../quick-forms/components/quick-form/quick-form.component";
import * as i8 from "../../../quick-forms/components/note-quick-form/note-quick-form.component.ngfactory";
import * as i9 from "../../../quick-forms/components/note-quick-form/note-quick-form.component";
import * as i10 from "../../../notes/services/notes.service";
import * as i11 from "../../../notes/components/notes-list/notes-list.component.ngfactory";
import * as i12 from "../../../notes/components/notes-list/notes-list.component";
import * as i13 from "../../../linked-info/services/linked-info.service";
import * as i14 from "../../../modal/services/modal.service";
import * as i15 from "./notes-panel.component";
var styles_NotesPanelComponent = [i0.styles];
var RenderType_NotesPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesPanelComponent, data: {} });
export { RenderType_NotesPanelComponent as RenderType_NotesPanelComponent };
function View_NotesPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "selected-line"]], null, null, null, null, null))], null, null); }
function View_NotesPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tab"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesPanelComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.selected; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.selected; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_1); }); }
export function View_NotesPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-notes-list-header", [], null, [[null, "selectedOrderChange"], [null, "scrollOptionChange"], [null, "searchQueryChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOrderChange" === en)) {
        var pd_0 = ((_co.selectedOrder = $event) !== false);
        ad = (pd_0 && ad);
    } if (("scrollOptionChange" === en)) {
        var pd_1 = (_co.handleScrollOptionChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("searchQueryChange" === en)) {
        var pd_2 = (_co.handleSearchQueryChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NotesListHeaderComponent_0, i3.RenderType_NotesListHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i4.NotesListHeaderComponent, [i5.StateService], { loading: [0, "loading"] }, { selectedOrderChange: "selectedOrderChange", scrollOptionChange: "scrollOptionChange", searchQueryChange: "searchQueryChange" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "tab-filters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesPanelComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 14, "div", [["class", "container-quick-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "app-quick-form", [["bundledGroup", "notesListQuickForm"], ["placeholder", "Add New Note"]], null, [[null, "clickMore"], [null, "collapsedChange"], [null, "itemDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickMore" === en)) {
        var pd_0 = (_co.handleNewNote() !== false);
        ad = (pd_0 && ad);
    } if (("collapsedChange" === en)) {
        var pd_1 = ((_co.quickFormExpanded = $event) !== false);
        ad = (pd_1 && ad);
    } if (("itemDropped" === en)) {
        var pd_2 = (_co.handleQuickFormDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_QuickFormComponent_0, i6.RenderType_QuickFormComponent)), i1.ɵdid(10, 4964352, null, 6, i7.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], toggle: [2, "toggle"] }, { collapsedChange: "collapsedChange", clickMore: "clickMore", itemDropped: "itemDropped" }), i1.ɵqud(335544320, 1, { eventForm: 0 }), i1.ɵqud(335544320, 2, { projectForm: 0 }), i1.ɵqud(335544320, 3, { taskForm: 0 }), i1.ɵqud(335544320, 4, { noteForm: 0 }), i1.ɵqud(335544320, 5, { contactForm: 0 }), i1.ɵqud(335544320, 6, { groupForm: 0 }), (_l()(), i1.ɵeld(17, 0, null, 3, 1, "app-note-quick-form", [["bundledGroup", "notesListQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewNote($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NoteQuickFormComponent_0, i8.RenderType_NoteQuickFormComponent)), i1.ɵdid(18, 573440, [[4, 4]], 0, i9.NoteQuickFormComponent, [i10.NotesService], { bundledGroup: [0, "bundledGroup"], note: [1, "note"] }, { more: "more" }), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "shadow"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { hidden: 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "container-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "app-notes-list", [], null, [[null, "loadInProgress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadInProgress" === en)) {
        var pd_0 = ((_co.loading = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_NotesListComponent_0, i11.RenderType_NotesListComponent)), i1.ɵdid(25, 770048, null, 0, i12.NotesListComponent, [i10.NotesService, i13.LinkedInfoService, i14.ModalService, i1.NgZone], { selectedOrder: [0, "selectedOrder"], scrollPosition: [1, "scrollPosition"], defaultFilters: [2, "defaultFilters"] }, { loadInProgress: "loadInProgress" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.tabs; _ck(_v, 6, 0, currVal_1); var currVal_2 = "Add New Note"; var currVal_3 = "notesListQuickForm"; var currVal_4 = _co.quickFormToggle; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "notesListQuickForm"; var currVal_6 = _co.quickFormNote; _ck(_v, 18, 0, currVal_5, currVal_6); var currVal_7 = "shadow"; var currVal_8 = _ck(_v, 22, 0, !_co.quickFormExpanded); _ck(_v, 21, 0, currVal_7, currVal_8); var currVal_9 = _co.selectedOrder; var currVal_10 = _co.listScrollPosition; var currVal_11 = _co.listFilters; _ck(_v, 25, 0, currVal_9, currVal_10, currVal_11); }, null); }
export function View_NotesPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notes-panel", [], null, null, null, View_NotesPanelComponent_0, RenderType_NotesPanelComponent)), i1.ɵdid(1, 245760, null, 0, i15.NotesPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesPanelComponentNgFactory = i1.ɵccf("app-notes-panel", i15.NotesPanelComponent, View_NotesPanelComponent_Host_0, {}, {}, []);
export { NotesPanelComponentNgFactory as NotesPanelComponentNgFactory };
