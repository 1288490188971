<div class="modal-header attachment-modal-header">
  <div class="modal-title">
    <mat-icon *ngIf="minimized" svgIcon="attachment" class="modal-title-icon"></mat-icon>
    <div class="modal-title-text">{{files[currentIndex].name}}</div>
  </div>
  <button type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button *ngIf="!minimized" type="button" class="button-modal" aria-label="Print" (click)="printAttachment()">
    <mat-icon svgIcon="print"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body" *ngIf="!minimized">
  <div class="modal-attachment" >
    <div *ngIf="checkType() === 'image'" class="modal-img">
      <ngx-image-viewer [src]="[files[currentIndex].url]"></ngx-image-viewer>
    </div>
    <div *ngIf="checkType() === 'video'" class="modal-video">
      <video class="video" controls src="{{files[currentIndex].url}}" preload="metadata">
        <source data-src="{{files[currentIndex].url}}" type="video/mp4"/>
      </video>
    </div>
    <div *ngIf="checkType() === 'pdf'" class="modal-pdf">
      <pdf-viewer [src]="{url: files[currentIndex].url, withCredentials: true}"
                  [page]="1"
                  [zoom]="pdfZoom"
                  [rotation]="pdfRotation"
                  [original-size]="true"
                  style="display: block;"
                  (after-load-complete)="pdfLoadComplete($event)"
                  (on-progress)="pdfOnProgress($event)"
                  (error)="pdfErrorLoad($event)">
      </pdf-viewer>
      <div class="modal-attachment__icons">
        <button class="button" (click)="zoomIn()">
          <span class="fa fa-plus"></span>
        </button>
        <button class="button" (click)="zoomOut()">
          <i class="fa fa-minus"></i>
        </button>
        <button class="button" (click)="rotateLeft()">
          <i class="fa fa-repeat"></i>
        </button>
        <button class="button" (click)="rotateRight()">
          <i class="fa fa-undo"></i>
        </button>
      </div>
      <div class="loader" *ngIf="loading">
        <progressbar [max]="progress.total" [value]="progress.loaded"></progressbar>
        <div>{{progress.loaded | fileSize}} / {{progress.total | fileSize}}</div>
      </div>
      <div *ngIf="errorMessage">
        <div type="alert alert-danger">{{errorMessage}}</div>
      </div>
    </div>
    <div *ngIf="!contentType" class="modal-download">
      <div class="no-preview">
        <div class="no-preview-text">
          <p>No preview available</p>
          <small>Please download an attachment</small>
        </div>
        <div class="download-btn">
          <a class="download-btn__url" href="{{files[currentIndex].url}}" download="{{files[currentIndex].url}}">
            <i class="download-btn__icon fa fa-download"></i>

            <!--
              This condition provides us aligning icon in a centre of button,
              if with some circumstances we get the empty/undefined filename.
            -->
            <span *ngIf="files[currentIndex].name" class="download-btn__text">{{files[currentIndex].name}}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="modal-attachment__navigation-buttons">
      <button class="button button-prev" (click)="prev()" *ngIf="currentIndex">
        <span class="fa fa-angle-left"></span>
      </button>
      <button class="button button-next" (click)="next()" *ngIf="currentIndex !== files.length - 1">
        <span class="fa fa-angle-right"></span>
      </button>
    </div>
  </div>
</div>
