<app-content-menu>

  <app-content-menu-item
    icon="tasks"
    title="Tasks"
    [badgeTemplate]="badgeTemplate"
    [active]="sidebarFilter === 'all_tasks'"
    (click)="selectFilter('all_tasks', $event)"
    appSidebarTaskContextMenu
  >
    <app-content-menu-item
      title="Scheduled"
      [active]="sidebarFilter === 'scheduled'"
      (click)="selectFilter('scheduled', $event)"
      appSidebarTaskContextMenu
    ></app-content-menu-item>

    <app-content-menu-item
      title="Unscheduled"
      [active]="sidebarFilter === 'unscheduled'"
      (click)="selectFilter('unscheduled', $event)"
      appSidebarTaskContextMenu
    ></app-content-menu-item>
  </app-content-menu-item>

  <app-content-menu-item
    icon="calendar-today"
    title="Today"
    [active]="sidebarFilter === 'today'"
    (click)="selectFilter('today', $event)"
    appSidebarTaskContextMenu
    [appTaskContextMenuStartDate]="'today' | dateByDayIndex"
  ></app-content-menu-item>
  <app-content-menu-item
    icon="calendar-week"
    title="Next 7 Days"
    (click)="selectFilter('week', $event)"
    [active]="sidebarFilter === 'week'"
    appSidebarTaskContextMenu
    [appTaskContextMenuStartDate]="'week' | dateByDayIndex"
  >
    <app-content-menu-item
      *ngFor="let day of nextWeekDays; first as isFirst; index as index"
      [title]="isFirst ? 'Tomorrow' : day | date: 'EEEE'"
      [active]="sidebarFilter === 'day' + index"
      (click)="selectFilter('day' + index, $event)"
      appSidebarTaskContextMenu
      [appTaskContextMenuStartDate]="'day' + index | dateByDayIndex"
    ></app-content-menu-item>
  </app-content-menu-item>
  <app-content-menu-item
    icon="flag"
    title="Priority"
    [active]="sidebarFilter === 'priority'"
    (click)="selectFilter('priority', $event)"
    [minimizedAppearanceType]="'expand'"
  >
    <app-content-menu-item
      *ngFor="let priority of prioritiesList"
      icon="flag"
      [iconColor]="priority.color"
      [title]="priority.title"
      [active]="sidebarFilter === priority.priorityName"
      (click)="selectFilter(priority.priorityName, $event)"
      appSidebarTaskContextMenu
      [appTaskContextMenuPriority]="priority.priorityName"
    >
    </app-content-menu-item>
  </app-content-menu-item>

  <app-content-menu-item
    icon="folder-archive"
    title="Archive"
    [active]="sidebarFilter === 'archived'"
    (click)="selectFilter('archived', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="trash-bucket"
    title="Trash"
    [active]="sidebarFilter === 'deleted'"
    (click)="selectFilter('deleted', $event)"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-item
    icon="project"
    title="Projects"
    [active]="sidebarFilter === 'all_projects' "
    (click)="selectFilter('all_projects', $event)"
    appSidebarProjectsContextMenu
  >
    <app-content-menu-item
      *ngFor="let project of projects"
      [title]="project.title"
      [active]="sidebarFilter === project.id"
      (click)="handleProjectClick(project, $event)"
      [appProjectContextMenu]="project"
    ></app-content-menu-item>
  </app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-pellets
    collapsedStateKey="tasksSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <app-content-menu-tags
    collapsedStateKey="tasksSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>

<ng-template #badgeTemplate>
  <div class="badge-container">
    <div class="highlighted-item">1</div>
    <div>/</div>
    <div>{{ tasksCount }}</div>
  </div>
</ng-template>
