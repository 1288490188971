import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskComponent } from '@modules/elements/components/task/task.component';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

@Component({
  selector: 'app-link-info-task',
  templateUrl: './link-info-task.component.html',
  styleUrls: ['./link-info-task.component.less']
})
export class LinkInfoTaskComponent extends TaskComponent {

  // Inputs
  @Input() linkedInfo: LinkedInfo;
  @Input() shortView = false;

  // Outputs
  @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

  // Actions
  public unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit(this.linkedInfo);
  }

  public openTask() {
    if (this.task) {
      this.modalService.taskForm(this.task);
    }
  }
}
