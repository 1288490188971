import * as tslib_1 from "tslib";
// Common
import { OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as Chrono from 'chrono-node';
// RX
import { Subject, timer } from 'rxjs';
import { takeUntil, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
var TimeInputComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function TimeInputComponent(formBuilder, focusMonitor, elementRef, ngControl, datePipe) {
        var _this = this;
        this.focusMonitor = focusMonitor;
        this.elementRef = elementRef;
        this.ngControl = ngControl;
        this.datePipe = datePipe;
        this.bundledInputInvisible = false;
        // Outputs
        this.focus = new EventEmitter();
        this.blur = new EventEmitter();
        this.stateChanges = new Subject();
        this.focused = false;
        this.errorState = false;
        this.controlType = 'time-input';
        this.id = "time-input-" + TimeInputComponent.nextId++;
        this.describedBy = '';
        this.requiredValue = false;
        this.disabledValue = false;
        this.alive = new Subject();
        this.focusSubject = new Subject(); // subject with delay to prevent floating label blinking
        this.onChange = function (_) { };
        this.onTouched = function () { };
        this.parts = formBuilder.group({
            minutes: '',
            hours: '01',
            period: 'am',
        });
        focusMonitor.monitor(elementRef, true).subscribe(function (origin) {
            if (_this.focused && !origin) {
                _this.onTouched();
            }
            _this.focusSubject.next(!!origin);
            _this.stateChanges.next();
        });
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        this.parts.valueChanges
            .pipe(takeUntil(this.alive))
            .subscribe(function (_a) {
            var minutes = _a.minutes, hours = _a.hours, period = _a.period;
            return _this.onChange(_this.value);
        });
        this.bundledTransformFunction = this.bundledTransformFunction.bind(this);
        this.focusSubject
            .pipe(distinctUntilChanged(), switchMap(function (value) { return (timer(value ? 0 : 300)
            .pipe(map(function () { return value; }))); }), takeUntil(this.alive))
            .subscribe(function (value) {
            if (value) {
                _this.focus.emit();
            }
            else {
                _this.blur.emit();
            }
            _this.focused = value;
        });
    }
    Object.defineProperty(TimeInputComponent.prototype, "empty", {
        get: function () {
            var _a = this.parts.value, minutes = _a.minutes, hours = _a.hours, period = _a.period;
            return !minutes || !hours || !period;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "shouldLabelFloat", {
        get: function () { return this.focused || !this.empty; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "placeholder", {
        get: function () { return this.placeholderValue; },
        set: function (value) {
            this.placeholderValue = value;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "required", {
        get: function () { return this.requiredValue; },
        set: function (value) {
            this.requiredValue = coerceBooleanProperty(value);
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "disabled", {
        get: function () { return this.disabledValue; },
        set: function (value) {
            this.disabledValue = coerceBooleanProperty(value);
            this.disabledValue ? this.parts.disable() : this.parts.enable();
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "value", {
        get: function () {
            var _a = this.parts.value, minutes = _a.minutes, period = _a.period;
            var hours = this.parts.value.hours;
            if (minutes.length === 2 && hours.length >= 1 && period.length === 2) {
                if (period === 'pm') {
                    hours = +hours + 12;
                }
                var time = new Date();
                time.setHours(+hours);
                time.setMinutes(+minutes);
                time.setSeconds(0, 0);
                return time;
            }
            return null;
        },
        set: function (date) {
            if (date) {
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var period = hours > 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = "" + (minutes < 10 ? '0' : '') + minutes;
                this.parts.setValue({ minutes: minutes, hours: hours.toString(), period: period });
            }
            else {
                this.parts.setValue({ minutes: '', hours: '', period: '' });
            }
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Component Lifecycle
     */
    TimeInputComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef);
        this.alive.next();
        this.alive.complete();
    };
    /**
     * Event Handlers
     */
    TimeInputComponent.prototype.handleHoursFocus = function (event) {
        this.hoursInput.nativeElement.select();
    };
    TimeInputComponent.prototype.handleHoursKeyPress = function (event) {
        var _this = this;
        var nextValue = this.hoursInput.nativeElement.value.slice(0, this.hoursInput.nativeElement.selectionStart) + event.key +
            this.hoursInput.nativeElement.value.slice(this.hoursInput.nativeElement.selectionEnd, this.hoursInput.nativeElement.value.length);
        var regexp = this.parts.value.period === 'pm' ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
        if (regexp.test(nextValue)) {
            var nextValueNum = parseInt(nextValue, 10);
            if (nextValueNum > 1 || nextValue.length === 2) {
                setTimeout(function () { return _this.minutesInput.nativeElement.focus(); });
            }
        }
        else {
            event.preventDefault();
        }
    };
    TimeInputComponent.prototype.handleHoursBlur = function (event) {
        var value = this.hoursInput.nativeElement.value;
        var regexp = this.parts.value.period === 'pm' ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
        if (regexp.test(value)) {
            var nextValueNum = parseInt(value, 10);
            this.parts.controls.hours.setValue(nextValueNum.toString());
        }
        else {
            this.parts.controls.hours.setValue('');
        }
    };
    TimeInputComponent.prototype.handleHoursKeyDown = function (event) {
        if (event.key === 'ArrowRight' && this.hoursInput.nativeElement.selectionStart === this.hoursInput.nativeElement.value.length) {
            this.minutesInput.nativeElement.focus();
        }
    };
    TimeInputComponent.prototype.handleMinutesFocus = function (event) {
        this.minutesInput.nativeElement.select();
    };
    TimeInputComponent.prototype.handleMinutesKeyPress = function (event) {
        var _this = this;
        var nextValue = this.minutesInput.nativeElement.value.slice(0, this.minutesInput.nativeElement.selectionStart) + event.key +
            this.minutesInput.nativeElement.value.slice(this.minutesInput.nativeElement.selectionEnd, this.minutesInput.nativeElement.value.length);
        if (/^([0-5]?[0-9])$/.test(nextValue)) {
            var nextValueNum = parseInt(nextValue, 10);
            if (nextValueNum > 5 || nextValue.length === 2) {
                setTimeout(function () { return _this.periodInput.nativeElement.focus(); });
            }
        }
        else {
            event.preventDefault();
        }
    };
    TimeInputComponent.prototype.handleMinutesBlur = function (event) {
        var value = this.minutesInput.nativeElement.value;
        if (/^([0-5]?[0-9])$/.test(value)) {
            var nextValueNum = parseInt(value, 10);
            if (nextValueNum <= 9 && value.length < 2) {
                this.parts.controls.minutes.setValue('0' + nextValueNum);
            }
        }
        else {
            this.parts.controls.minutes.setValue('');
        }
    };
    TimeInputComponent.prototype.handleMinutesKeyDown = function (event) {
        var _this = this;
        if (event.key === 'Backspace') {
            if (this.minutesInput.nativeElement.value === '') {
                this.hoursInput.nativeElement.focus();
            }
        }
        if (event.key === 'ArrowLeft' && this.minutesInput.nativeElement.selectionStart === 0) {
            setTimeout(function () { return _this.hoursInput.nativeElement.focus(); });
        }
        if (event.key === 'ArrowRight' && this.minutesInput.nativeElement.selectionStart === this.minutesInput.nativeElement.value.length) {
            setTimeout(function () { return _this.periodInput.nativeElement.focus(); });
        }
    };
    TimeInputComponent.prototype.handlePeriodKeyPress = function (event) {
        var value = this.periodInput.nativeElement.value;
        var nextValue = '';
        if (event.key.toLowerCase() === 'a') {
            nextValue = 'am';
        }
        else if (event.key.toLowerCase() === 'p') {
            nextValue = 'pm';
        }
        else if (event.key.toLowerCase() === 'm') {
            nextValue = value;
        }
        if (value !== nextValue) {
            this.parts.controls.period.setValue(nextValue);
        }
        event.preventDefault();
    };
    TimeInputComponent.prototype.handlePeriodBlur = function (event) {
        var value = this.periodInput.nativeElement.value;
        if (value === 'am') {
        }
        else if (value === 'pm') {
            if (this.parts.value.hours === '12') {
                this.parts.controls.hours.setValue('00');
            }
        }
        else {
            this.parts.controls.period.setValue('');
        }
    };
    TimeInputComponent.prototype.handlePeriodKeyDown = function (event) {
        var _this = this;
        if (event.key === 'Backspace') {
            if (this.periodInput.nativeElement.value === '') {
                this.minutesInput.nativeElement.focus();
            }
            else {
                this.parts.controls.period.setValue('');
            }
            event.preventDefault();
        }
        if (event.key === 'ArrowLeft' && this.periodInput.nativeElement.selectionStart === 0) {
            setTimeout(function () { return _this.minutesInput.nativeElement.focus(); });
        }
    };
    TimeInputComponent.prototype.handlePeriodFocus = function (event) {
        this.periodInput.nativeElement.select();
    };
    /**
     * MatForm Interface Implementing
     */
    TimeInputComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    TimeInputComponent.prototype.onContainerClick = function (event) {
        if (event.target.tagName.toLowerCase() !== 'input') {
            this.hoursInput.nativeElement.focus();
        }
    };
    TimeInputComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    TimeInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TimeInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TimeInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    /**
     * Helpers
     */
    TimeInputComponent.prototype.bundledTransformFunction = function (event) {
        var chronoResult = Chrono.parse(event.fromValue);
        var date = chronoResult && this.bundledInputAppearance === 'start' ?
            (chronoResult[0] && chronoResult[0].start)
            :
                ((chronoResult[0] && chronoResult[0].end) || (chronoResult[1] && chronoResult[1].start));
        if (date &&
            date.knownValues &&
            (date.knownValues.hour ||
                date.knownValues.minute ||
                date.knownValues.meridiem)) {
            var dateParts = tslib_1.__assign({}, date.impliedValues, date.knownValues);
            var dateObject = new Date(dateParts.year, dateParts.month, dateParts.day, dateParts.hour, dateParts.minute);
            event.toValue = this.datePipe.transform(dateObject, 'hh:mm a');
            event.formControlValue = dateObject;
            event.formControlValueChanged = !this.bundledInputFormControl.value ||
                dateObject.getHours() !== this.bundledInputFormControl.value.getHours() ||
                dateObject.getMinutes() !== this.bundledInputFormControl.value.getMinutes();
            var index = 1;
            if (this.bundledInputAppearance === 'start' ||
                (this.bundledInputAppearance === 'end' && chronoResult[0].end)) {
                index = 0;
            }
            event.fromValueHighlightRange = [chronoResult[index].index, chronoResult[index].index + chronoResult[index].text.length];
        }
        else {
            event.toValue = null;
        }
    };
    TimeInputComponent.prototype.bundledValueCompareFunction = function (value, bundledValue) {
        return (value &&
            bundledValue &&
            value.getHours() === bundledValue.getHours() &&
            value.getMinutes() === bundledValue.getMinutes());
    };
    // Static
    TimeInputComponent.nextId = 0;
    return TimeInputComponent;
}());
export { TimeInputComponent };
