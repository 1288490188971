// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone } from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { Group } from '@modules/contacts/types/group';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.less'],
})
export class GroupsListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no groups';
  @Input() defaultFilters: GroupsFilters = {};

  // Public
  public itemHeight = 92;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);

  /**
   * Constructor
   */

  constructor(
    private groupsService: GroupsService,
    private contactsStateService: ContactsStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone
  ) {
    super(ngZone);

    this.contactsStateService.getSelectedGroups()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((groups: Group[]) => {
        this.selectedItems = groups;
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.contactsStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Make automatic updates for new task
      interval(environment.messageFetchInterval)
        .pipe(
          filter(() => !this.loading && this.selectedOrder && this.selectedOrder.key === 'date')
        ),
      this.groupsService.createNewGroup,
      this.groupsService.updatedGroup,
      this.groupsService.deletedGroup,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('defaultFilters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.groupsService
      .getGroups({
        ...this.defaultFilters,
        limit,
        offset
      })
      .pipe(
        map(({ groups, count }) => ({ items: groups, count }))
      );
  }

  resetItems() {
    this.contactsStateService.setSelectedGroups([]);
    super.resetItems();
  }

  selectItem(group: Group, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(group, event, selectAll);

    this.contactsStateService.setSelectedGroups(this.selectedItems as Group[]);

    if (this.selectedItems.length === 1) {
      this.contactsStateService.setMainViewGroup(this.selectedItems[0] as Group);
      this.contactsStateService.setMainView('group-form');
    }
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
