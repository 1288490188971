<div class="dropdown-header" *ngIf="title">{{ title }}</div>

<app-dropdown-item
  *ngFor="let option of options"
  [selected]="option.key === selected?.key"
  [disabled]="disabled | includesBy : option.key : 'key'"
  (click)="select(option)"
  [title]="option.name"
  [checkIcon]="option.key === selected?.key"
></app-dropdown-item>

<div class="divider" *ngIf="divider"></div>
