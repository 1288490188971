import * as tslib_1 from "tslib";
// Common
import { AfterViewInit, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Rx
import { BehaviorSubject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';
var FilesListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FilesListComponent, _super);
    /**
     * Constructor
     */
    function FilesListComponent(ngZone, filesService, filesStateService) {
        var _this = _super.call(this, ngZone) || this;
        _this.ngZone = ngZone;
        _this.filesService = filesService;
        _this.filesStateService = filesStateService;
        _this.placeholderText = 'You have no files';
        _this.defaultFilters = {};
        // Public
        _this.itemHeight = 76;
        _this.isHover = false;
        _this.showCountView = new BehaviorSubject(true);
        // Private
        _this.initialized = false;
        _this.filesStateService.getSelectedFiles()
            .pipe(takeUntil(_this.componentNotDestroyed))
            .subscribe(function (files) {
            _this.selectedItems = files;
        });
        return _this;
    }
    /**
     * Component lifecycle
     */
    FilesListComponent.prototype.ngOnInit = function () {
    };
    FilesListComponent.prototype.ngAfterViewInit = function () {
        this.refreshCurrentItems();
        _super.prototype.ngOnInit.call(this);
    };
    FilesListComponent.prototype.ngOnChanges = function (changes) {
        this.showCountView.next(true);
        if ('selectedOrder' in changes && this.selectedOrder) {
            this.resetItems();
        }
        if ('defaultFilters' in changes) {
            this.resetItems();
        }
        if ('scrollPosition' in changes && this.scrollPosition !== null) {
            this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
        }
    };
    /**
     * Actions
     */
    FilesListComponent.prototype.getItems = function (offset, limit) {
        var _this = this;
        return this.filesService
            .getFiles(tslib_1.__assign({}, this.defaultFilters, { limit: limit,
            offset: offset }))
            .pipe(map(function (_a) {
            var files = _a.files, count = _a.count;
            return ({ items: files, count: count });
        }), tap(function () {
            if (!_this.initialized) {
                _this.initialized = true;
                setTimeout(function () {
                    _this.scrollToIndex(100);
                    _super.prototype.ngOnInit.call(_this);
                }, 0);
            }
        }));
    };
    FilesListComponent.prototype.selectItem = function (file, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        _super.prototype.selectItem.call(this, file, event, selectAll);
        this.filesStateService.setSelectedFiles(this.selectedItems);
        if (this.selectedItems.length === 1) {
            this.filesStateService.setMainViewFile(this.selectedItems[0]);
            this.filesStateService.setMainView('file-form');
        }
    };
    FilesListComponent.prototype.resetItems = function () {
        _super.prototype.resetItems.call(this);
    };
    FilesListComponent.prototype.compareItems = function (item1, item2) {
        return item1 && item2 && item1['id'] === item2['id'];
    };
    return FilesListComponent;
}(InfinityScrollListComponent));
export { FilesListComponent };
