// Common
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Libs
import { JwtModule } from '@auth0/angular-jwt';
import * as Raven from 'raven-js';

// Components
import { AppComponent } from './app.component';

// Route
import { AppRoutingModule } from './app-routing.module';

// Modules
import { AccountModule } from './modules/account/account.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { AuthModule } from './modules/auth/auth.module';
import { InsightsModule } from './modules/insights/insights.module';
import { MailModule } from './modules/mail/mail.module';
import { PagesModule } from './modules/pages/pages.module';
import { ModalModule } from './modules/modal/modal.module';
import { NotificationModule } from './modules/notification/notification.module';
import { PopupModule } from './modules/popup/popup.module';
import { SettingsModule } from './modules/settings/settings.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxElectronModule } from 'ngx-electron';
import { AsyncTasksModule } from '@modules/async-tasks/async-tasks.module';

// Env
import { environment } from '../environments/environment';

export function tokenGetter() {
  return localStorage.getItem('token');
}

if (environment.sentry.enabled) {
  Raven
    .config(environment.sentry.dsn)
    .install();
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.sentry.enabled) {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.authDomains
      }
    }),
    AuthModule,
    AnalyticsModule,
    MailModule,
    PagesModule,
    InsightsModule,
    AccountModule,
    AppRoutingModule,
    NotificationModule,
    PopupModule,
    ModalModule,
    SettingsModule,
    MatIconModule,
    AsyncTasksModule,
    NgxElectronModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
