import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { GroupComponent } from '@modules/elements/components/group/group.component';
var LinkInfoGroupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoGroupComponent, _super);
    function LinkInfoGroupComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shortView = false;
        // Outputs
        _this.unlink = new EventEmitter();
        return _this;
    }
    // Actions
    LinkInfoGroupComponent.prototype.unlinkItem = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.unlink.emit(this.linkedInfo);
    };
    LinkInfoGroupComponent.prototype.openGroup = function () {
        if (this.group) {
            this.modalService.groupForm(this.group);
        }
    };
    return LinkInfoGroupComponent;
}(GroupComponent));
export { LinkInfoGroupComponent };
