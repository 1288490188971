<div class="dynamic-panel">
    <app-knowledge-header
      height="45px"
      [orders]="orderOptions"
      [titleTemplate]="titleTemplate"
    ></app-knowledge-header>
  <div class="dynamic-panel__body">
    <app-linked-info-list
      class="related-linked-info"
      [parentLinkedInfo]="parentLinkedInfo"
      [linkedInfo]="linkedInfo"
    ></app-linked-info-list>
  </div>
</div>

<ng-template #titleTemplate>
  <mat-icon svgIcon="arrow-up" class="btn-back" (click)="closed()"></mat-icon>
  <div class="title">Linked Info</div>
</ng-template>
