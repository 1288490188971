/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-info-event-suggestions.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../event/event.component.ngfactory";
import * as i3 from "../../event/event.component";
import * as i4 from "../../../../analytics/services/google-analytics.service";
import * as i5 from "../../../../modal/services/modal.service";
import * as i6 from "../../../../events/services/events-state.service";
import * as i7 from "../../../../events/services/calendar.service";
import * as i8 from "../../../../linked-info/services/linked-info.service";
import * as i9 from "../../../../topic/services/topic.service";
import * as i10 from "../../../../tag/services/tag.service";
import * as i11 from "@angular/material/tooltip";
import * as i12 from "../link-info-suggestions/link-info-suggestions.component.ngfactory";
import * as i13 from "../link-info-suggestions/link-info-suggestions.component";
import * as i14 from "./link-info-event-suggestions.component";
var styles_LinkInfoEventSuggestionsComponent = [i0.styles];
var RenderType_LinkInfoEventSuggestionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkInfoEventSuggestionsComponent, data: {} });
export { RenderType_LinkInfoEventSuggestionsComponent as RenderType_LinkInfoEventSuggestionsComponent };
function View_LinkInfoEventSuggestionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-event", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedLinkedInfo({ type: "event", id: _v.context.item.id }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EventComponent_0, i2.RenderType_EventComponent)), i1.ɵdid(1, 704512, null, 0, i3.EventComponent, [i4.GoogleAnalyticsService, i5.ModalService, i6.EventsStateService, i1.ChangeDetectorRef, i7.CalendarService, i8.LinkedInfoService, i9.TopicService, i10.TagService], { event: [0, "event"], actionsButtonEnabled: [1, "actionsButtonEnabled"] }, null), i1.ɵprd(256, null, i11.MAT_TOOLTIP_DEFAULT_OPTIONS, i3.tooltipDefaults, [])], function (_ck, _v) { var currVal_0 = _v.context.item; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LinkInfoEventSuggestionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-info-suggestions", [["title", "Link To Event"]], null, [[null, "close"], [null, "create"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } if (("create" === en)) {
        var pd_1 = (_co.creating() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_LinkInfoSuggestionsComponent_0, i12.RenderType_LinkInfoSuggestionsComponent)), i1.ɵdid(1, 114688, null, 0, i13.LinkInfoSuggestionsComponent, [], { inputFormControl: [0, "inputFormControl"], items: [1, "items"], itemTemplateRef: [2, "itemTemplateRef"], title: [3, "title"], createTitle: [4, "createTitle"], appearance: [5, "appearance"] }, { create: "create", close: "close" }), (_l()(), i1.ɵand(0, [["suggestionsItem", 2]], null, 0, null, View_LinkInfoEventSuggestionsComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; var currVal_1 = _co.suggestions; var currVal_2 = i1.ɵnov(_v, 2); var currVal_3 = "Link To Event"; var currVal_4 = "Create New Event"; var currVal_5 = _co.appearance; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_LinkInfoEventSuggestionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-info-event-suggestions", [], null, null, null, View_LinkInfoEventSuggestionsComponent_0, RenderType_LinkInfoEventSuggestionsComponent)), i1.ɵdid(1, 245760, null, 0, i14.LinkInfoEventSuggestionsComponent, [i5.ModalService, i7.CalendarService, i8.LinkedInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkInfoEventSuggestionsComponentNgFactory = i1.ɵccf("app-link-info-event-suggestions", i14.LinkInfoEventSuggestionsComponent, View_LinkInfoEventSuggestionsComponent_Host_0, { linkedInfo: "linkedInfo", appearance: "appearance" }, { close: "close", create: "create" }, []);
export { LinkInfoEventSuggestionsComponentNgFactory as LinkInfoEventSuggestionsComponentNgFactory };
