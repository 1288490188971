import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarWeekViewComponent } from 'angular-calendar';

@Component({
  selector: 'app-customized-calendar-week',
  templateUrl: './customized-calendar-week.component.html',
})

export class CustomizedCalendarWeekComponent extends CalendarWeekViewComponent implements OnChanges, AfterViewInit {

  @Input() highlightedDay: Date;
  @Input() dayEventLimit = 3;
  @ViewChild('dayRowsContainer', { static: false }) dayRowsContainer: ElementRef<any>;

  public allDayEventAmountLimit: number = this.dayEventLimit;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.highlightedDay && this.highlightedDay) {
      this.scrollToSelectedHour(this.highlightedDay);
    }
  }

  ngAfterViewInit(): void {
    this.scrollToSelectedHour(new Date);
  }

  toggleEventLimit() {
    this.allDayEventAmountLimit = this.allDayEventAmountLimit ? undefined : this.dayEventLimit;
  }

  private scrollToSelectedHour(date: Date) {
    this.dayRowsContainer.nativeElement.scrollTop = date.getHours() * (this.hourSegmentHeight * this.hourSegments);
  }
}
