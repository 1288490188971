/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-tag.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../form-controls/components/input/input.component.ngfactory";
import * as i5 from "../../../form-controls/components/input/input.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../form-controls/components/button/button.component.ngfactory";
import * as i8 from "../../../form-controls/components/button/button.component";
import * as i9 from "./create-tag.component";
import * as i10 from "../../services/tag.service";
import * as i11 from "../../../search/services/search.service";
var styles_CreateTagComponent = [i0.styles];
var RenderType_CreateTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateTagComponent, data: {} });
export { RenderType_CreateTagComponent as RenderType_CreateTagComponent };
function View_CreateTagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tag-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" #", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "delete-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close-small"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteTagFromList(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "close-small"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_CreateTagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You can add multiple tags with enter e.g. personal must_read educational"]))], null, null); }
function View_CreateTagComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "tag-autocomplete"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.item.value.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_CreateTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "create-tag__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "close-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Tags"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-input", [["class", "tag-input"], ["placeholder", "Add Tags"]], null, [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.addTagToList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_InputComponent_0, i4.RenderType_InputComponent)), i1.ɵdid(6, 49152, null, 0, i5.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], autocompleteItemTemplateRef: [2, "autocompleteItemTemplateRef"], suggestedOptions: [3, "suggestedOptions"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "tags"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTagComponent_1)), i1.ɵdid(10, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTagComponent_2)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "app-button", [["class", "btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(15, 49152, null, 0, i8.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["CANCEL"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "app-button", [["appearance", "blue"], ["class", "btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(18, 49152, null, 0, i8.ButtonComponent, [i1.ElementRef], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵted(-1, 0, ["SAVE"])), (_l()(), i1.ɵand(0, [["autocompleteItem", 2]], null, 0, null, View_CreateTagComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "close"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.inputFormControl; var currVal_4 = "Add Tags"; var currVal_5 = i1.ɵnov(_v, 20); var currVal_6 = i1.ɵunv(_v, 6, 3, i1.ɵnov(_v, 7).transform(_co.suggestedOptions)); _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.tags; _ck(_v, 10, 0, currVal_7); var currVal_8 = !_co.tags.length; _ck(_v, 12, 0, currVal_8); var currVal_9 = "blue"; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CreateTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-tag", [], null, null, null, View_CreateTagComponent_0, RenderType_CreateTagComponent)), i1.ɵdid(1, 245760, null, 0, i9.CreateTagComponent, [i10.TagService, i11.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateTagComponentNgFactory = i1.ɵccf("app-create-tag", i9.CreateTagComponent, View_CreateTagComponent_Host_0, { inputFormControl: "inputFormControl", tags: "tags", messages: "messages", events: "events", projects: "projects" }, { saved: "saved", close: "close" }, []);
export { CreateTagComponentNgFactory as CreateTagComponentNgFactory };
