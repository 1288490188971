// Common
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DropdownOption } from '../../../dropdown/types/dropdown-option';

@Component({
  selector: 'app-knowledge-header',
  templateUrl: './knowledge-header.component.html',
  styleUrls: ['./knowledge-header.component.less'],
})
export class KnowledgeHeaderComponent implements OnInit, OnDestroy {

  // ViewChildren
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  // Inputs
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() orders: DropdownOption[];
  @Input() actions: DropdownOption[];
  @Input() selected: DropdownOption;
  @Input() showPaginationOrder = false;
  @Input() search = false;
  @Input() height: string;
  @Input() filters: DropdownOption[];
  @Input() selectedFilters: DropdownOption[];
  @Input() loading: Boolean;

  // Outputs
  @Output() selectedChange = new EventEmitter<DropdownOption>();
  @Output() selectedSymbol = new EventEmitter<string>();
  @Output() searchQueryChange = new EventEmitter<string>();
  @Output() actionClick = new EventEmitter<string>();
  @Output() filtersChange = new EventEmitter<DropdownOption[]>();

  // Public
  public searchMode = false;
  public searchQuery: FormControl = new FormControl();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.searchQuery.valueChanges
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((value: string) => {
        this.searchQueryChange.emit(value);
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectOrder(order: DropdownOption) {
    this.selected = order;
    this.selectedChange.emit(order);
  }

  selectSymbol(symbol: string) {
    this.selectedSymbol.emit(symbol);
  }

  selectAction(action: string) {
    this.actionClick.emit(action);
  }

  selectFilters(filters: DropdownOption[]) {
    this.filtersChange.emit(filters);
  }

  handleSearchIconClick(event: MouseEvent) {
    this.searchMode = true;
    this.focusOnInput();
  }
  public handleClearIconClick() {
    this.searchQuery.reset();
    this.focusOnInput();
  }
  handleSearchBlur(event: FocusEvent) {
    if (!this.searchQuery.value || this.searchQuery.value.trim() === '') {
      this.searchMode = false;
    }
  }
  public focusOnInput() {
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.focus();
    }
  }
}
