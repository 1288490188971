import * as tslib_1 from "tslib";
import { GlobalState } from '../types/global-state';
// RX
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
/**
 * Don`t directly use the variable from the service inside the template!
 */
var StateService = /** @class */ (function () {
    /**
     * Constructor
     */
    function StateService() {
        var _this = this;
        this.defaultStates = new GlobalState();
        this.currentStates = new BehaviorSubject(tslib_1.__assign({}, this.defaultStates, this.getSavedStates()));
        this.currentStates.subscribe(function (states) { return _this.saveStates(states); });
    }
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneRelatedTopics", {
        get: function () { return this.currentStates.value.sortKnowledgePaneRelatedTopics; },
        /**
         * Sort
         */
        set: function (value) { this.updateStates({ sortKnowledgePaneRelatedTopics: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneRelatedConnections", {
        get: function () { return this.currentStates.value.sortKnowledgePaneRelatedConnections; },
        set: function (value) { this.updateStates({ sortKnowledgePaneRelatedConnections: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneRelatedFiles", {
        get: function () { return this.currentStates.value.sortKnowledgePaneRelatedFiles; },
        set: function (value) { this.updateStates({ sortKnowledgePaneRelatedFiles: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneRelatedHyperlinks", {
        get: function () { return this.currentStates.value.sortKnowledgePaneRelatedHyperlinks; },
        set: function (value) { this.updateStates({ sortKnowledgePaneRelatedHyperlinks: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneUpcomingEvents", {
        get: function () { return this.currentStates.value.sortKnowledgePaneUpcomingEvents; },
        set: function (value) { this.updateStates({ sortKnowledgePaneUpcomingEvents: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortKnowledgePaneArchivedEvents", {
        get: function () { return this.currentStates.value.sortKnowledgePaneArchivedEvents; },
        set: function (value) { this.updateStates({ sortKnowledgePaneArchivedEvents: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortInsightsRelatedConnections", {
        get: function () { return this.currentStates.value.sortInsightsRelatedConnections; },
        set: function (value) { this.updateStates({ sortInsightsRelatedConnections: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortTopicMap", {
        get: function () { return this.currentStates.value.sortTopicMap; },
        set: function (value) { this.updateStates({ sortTopicMap: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortPinnedTopics", {
        get: function () { return this.currentStates.value.sortPinnedTopics; },
        set: function (value) { this.updateStates({ sortPinnedTopics: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortPinnedTags", {
        get: function () { return this.currentStates.value.sortPinnedTags; },
        set: function (value) { this.updateStates({ sortPinnedTags: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortEventsList", {
        get: function () { return this.currentStates.value.sortEventsList; },
        set: function (value) { this.updateStates({ sortEventsList: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortTasksProjectsList", {
        get: function () { return this.currentStates.value.sortTasksProjectsList; },
        set: function (value) { this.updateStates({ sortTasksProjectsList: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "sortNotesList", {
        get: function () { return this.currentStates.value.sortNotesList; },
        set: function (value) { this.updateStates({ sortNotesList: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.setSortMessagesList = function (key, value) {
        var _a;
        this.updateStates({ sortMessagesList: tslib_1.__assign({}, this.currentStates.value.sortMessagesList, (_a = {}, _a[key] = value, _a)) });
    };
    StateService.prototype.getSortMessagesList = function (key) {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.sortMessagesList; }), map(function (state) { return state[key]; }), distinctUntilChanged());
    };
    Object.defineProperty(StateService.prototype, "sortContactsList", {
        get: function () { return this.currentStates.value.sortContactsList; },
        set: function (value) { this.updateStates({ sortContactsList: value }); },
        enumerable: true,
        configurable: true
    });
    /**
     * List settings
     */
    StateService.prototype.setMessagesListSettings = function (key, value) {
        var _a;
        this.updateStates({ messagesLists: tslib_1.__assign({}, this.currentStates.value.messagesLists, (_a = {}, _a[key] = value, _a)) });
    };
    StateService.prototype.getMessagesListSettings = function (key) {
        return this.currentStates.value.messagesLists[key];
    };
    Object.defineProperty(StateService.prototype, "filterTopicMapCount", {
        get: function () { return this.currentStates.value.filterTopicMapCount; },
        /**
         * Filters
         */
        set: function (value) { this.updateStates({ filterTopicMapCount: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "filterTopicMapDate", {
        get: function () {
            var filter = this.currentStates.value.filterTopicMapDate;
            if (filter.fromDate) {
                filter.fromDate = new Date(filter.fromDate);
            }
            if (filter.toDate) {
                filter.toDate = new Date(filter.toDate);
            }
            return filter;
        },
        set: function (value) {
            this.updateStates({ filterTopicMapDate: value });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "filterTopicMapFirstSymbol", {
        get: function () { return this.currentStates.value.filterTopicMapFirstSymbol; },
        set: function (value) { this.updateStates({ filterTopicMapFirstSymbol: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "filterTasksList", {
        get: function () { return this.currentStates.value.filterTasksList; },
        set: function (value) { this.updateStates({ filterTasksList: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "selectedOrderOptions", {
        get: function () { return this.currentStates.value.selectedOrderOptions; },
        /**
         * Selected
         */
        set: function (value) { this.updateStates({ selectedOrderOptions: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "selectedExtendedFilteringMatchTypes", {
        get: function () { return this.currentStates.value.selectedExtendedFilteringMatchTypes; },
        set: function (value) { this.updateStates({ selectedExtendedFilteringMatchTypes: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "selectedKnowledgePaneContactContext", {
        get: function () { return this.currentStates.value.selectedKnowledgePaneContactContext; },
        set: function (value) { this.updateStates({ selectedKnowledgePaneContactContext: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "selectedTopicMapView", {
        get: function () { return this.currentStates.value.selectedTopicMapView; },
        set: function (value) { this.updateStates({ selectedTopicMapView: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "selectedCalendarType", {
        set: function (value) { this.updateStates({ selectedCalendarType: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.getSelectedCalendarType = function () {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.selectedCalendarType; }), distinctUntilChanged());
    };
    Object.defineProperty(StateService.prototype, "selectedCalendars", {
        set: function (value) { this.updateStates({ selectedCalendars: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.getSelectedCalendars = function () {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.selectedCalendars; }), distinctUntilChanged(function (previous, current) { return previous.join() === current.join(); }));
    };
    Object.defineProperty(StateService.prototype, "selectedKPCalendars", {
        set: function (value) { this.updateStates({ selectedKPCalendars: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.getSelectedKPCalendars = function () {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.selectedKPCalendars; }), distinctUntilChanged(function (prev, curr) { return prev.join() === curr.join(); }));
    };
    Object.defineProperty(StateService.prototype, "eventsMainView", {
        get: function () { return this.currentStates.value.eventsMainView; },
        set: function (value) { this.updateStates({ eventsMainView: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "tasksMainView", {
        get: function () { return this.currentStates.value.tasksMainView; },
        set: function (value) { this.updateStates({ tasksMainView: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "contactsMainView", {
        get: function () { return this.currentStates.value.contactsMainView; },
        set: function (value) { this.updateStates({ contactsMainView: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.addColumnToCollapsed = function (columnId) {
        this.updateStates({ collapsedColumns: tslib_1.__spread(this.currentStates.getValue().collapsedColumns, [columnId]) });
    };
    StateService.prototype.removeColumnFromCollapsed = function (columnId) {
        this.updateStates({ collapsedColumns: this.currentStates.getValue().collapsedColumns.filter(function (id) { return id !== columnId; }) });
    };
    StateService.prototype.getCollapsedColumns = function () {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.collapsedColumns; }), distinctUntilChanged(function (previous, current) { return previous.join() === current.join(); }));
    };
    Object.defineProperty(StateService.prototype, "filesMainView", {
        get: function () { return this.currentStates.value.filesMainView; },
        set: function (value) { this.updateStates({ filesMainView: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "splitInsightsTopics", {
        get: function () { return this.currentStates.value.splitInsightsTopics; },
        /**
         * Split
         */
        set: function (value) { this.updateStates({ splitInsightsTopics: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "splitInsightsRelatedContacts", {
        get: function () { return this.currentStates.value.splitInsightsRelatedContacts; },
        set: function (value) { this.updateStates({ splitInsightsRelatedContacts: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "splitKnowledgePanelTopics", {
        get: function () { return this.currentStates.value.splitKnowledgePanelTopics; },
        set: function (value) { this.updateStates({ splitKnowledgePanelTopics: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "splitKnowledgePanelContacts", {
        get: function () { return this.currentStates.value.splitKnowledgePanelContacts; },
        set: function (value) { this.updateStates({ splitKnowledgePanelContacts: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "splitTaskDetailsLinked", {
        get: function () { return this.currentStates.value.splitTaskDetailsLinked; },
        set: function (value) { this.updateStates({ splitTaskDetailsLinked: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.setSplitState = function (key, currentValue, lastValue) {
        var _a;
        this.updateStates({
            splitState: tslib_1.__assign({}, this.currentStates.value.splitState, (_a = {}, _a[key] = [currentValue, lastValue], _a))
        });
    };
    StateService.prototype.getSplitState = function (key) {
        var _this = this;
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.splitState; }), map(function (state) { return state[key] ? state : _this.defaultStates.splitState; }), map(function (state) { return ({ current: state[key][0], last: state[key][1] }); }), distinctUntilChanged(function (a, b) { return a.current === b.current && a.last === b.last; }));
    };
    StateService.prototype.setSelectedCalendarDates = function (type, value) {
        var _a;
        this.updateStates({
            selectedCalendarDates: tslib_1.__assign({}, this.currentStates.value.selectedCalendarDates, (_a = {}, _a[type] = value, _a))
        });
    };
    StateService.prototype.getSelectedCalendarDates = function (type) {
        var _this = this;
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.selectedCalendarDates[type]; }), distinctUntilChanged(function (previous, current) { return _this.datesEqual(previous, current); }));
    };
    Object.defineProperty(StateService.prototype, "linkedToolbarFormMaximized", {
        get: function () { return this.currentStates.value.linkedToolbarFormMaximized; },
        set: function (value) { this.updateStates({ linkedToolbarFormMaximized: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "linkedToolbarFormHeight", {
        get: function () { return this.currentStates.value.linkedToolbarFormHeight; },
        set: function (value) { this.updateStates({ linkedToolbarFormHeight: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.setTabsState = function (key, value) {
        var _a;
        this.updateStates({
            tabsState: tslib_1.__assign({}, this.currentStates.value.tabsState, (_a = {}, _a[key] = value, _a)),
            previousKnowledgePanelState: this.currentStates.value.tabsState.kp
        });
    };
    StateService.prototype.getTabsState = function (key) {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.tabsState; }), map(function (state) { return state[key] || 0; }), distinctUntilChanged());
    };
    StateService.prototype.setSidebarFilters = function (key, value) {
        var _a;
        this.updateStates({ sidebarFilters: tslib_1.__assign({}, this.currentStates.value.sidebarFilters, (_a = {}, _a[key] = value, _a)) });
    };
    StateService.prototype.getSidebarFilters = function (key) {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.sidebarFilters; }), map(function (state) { return state[key]; }), distinctUntilChanged());
    };
    Object.defineProperty(StateService.prototype, "previousKnowledgePanelState", {
        get: function () { return this.currentStates.value.previousKnowledgePanelState; },
        enumerable: true,
        configurable: true
    });
    /**
     * Folders
     */
    StateService.prototype.addFolderToLastSaved = function (folder) {
        var folders = this.lastFoldersMovedTo.reverse();
        var itemIndex = folders.indexOf(folder);
        if (itemIndex > -1) {
            folders.splice(itemIndex, 1);
        }
        else if (folders.length === 5) {
            folders.shift();
        }
        folders.push(folder);
        this.updateStates({ lastFoldersMovedTo: folders.reverse() });
    };
    Object.defineProperty(StateService.prototype, "lastFoldersMovedTo", {
        get: function () { return this.currentStates.value.lastFoldersMovedTo; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "knowledgePanelFolder", {
        get: function () { return this.currentStates.value.knowledgePanelFolder; },
        set: function (value) { this.updateStates({ knowledgePanelFolder: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "settingMessageSnoozed", {
        get: function () { return this.currentStates.value.settingMessageSnoozed; },
        /**
         * Settings
         */
        set: function (value) { this.updateStates({ settingMessageSnoozed: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "isUserFolderExpanded", {
        get: function () { return this.currentStates.value.isUserFolderExpanded; },
        set: function (value) { this.updateStates({ isUserFolderExpanded: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "isAppMenuExpanded", {
        get: function () { return this.currentStates.value.isAppMenuExpanded; },
        set: function (value) { this.updateStates({ isAppMenuExpanded: value }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "isTextEditorToolbarExpanded", {
        get: function () { return this.currentStates.value.isTextEditorToolbarExpanded; },
        set: function (value) { this.updateStates({ isTextEditorToolbarExpanded: value }); },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.toggleUserFolderState = function (folderId) {
        this.updateStates({
            userFoldersState: this.userFoldersState.includes(folderId)
                ? this.userFoldersState.filter(function (id) { return id !== folderId; })
                : this.userFoldersState.concat([folderId])
        });
    };
    Object.defineProperty(StateService.prototype, "userFoldersState", {
        get: function () { return this.currentStates.value.userFoldersState; },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.setCollapsed = function (key, value) {
        var _a;
        this.updateStates({
            collapsed: tslib_1.__assign({}, this.currentStates.value.collapsed, (_a = {}, _a[key] = value, _a))
        });
    };
    StateService.prototype.getCollapsed = function (key) {
        return this.currentStates
            .asObservable()
            .pipe(map(function (state) { return state.collapsed[key]; }), distinctUntilChanged());
    };
    /**
     * Methods
     */
    StateService.prototype.getSavedStates = function () {
        try {
            return new GlobalState(JSON.parse(localStorage.getItem('app.states')));
        }
        catch (e) {
            console.error('Can not parse JSON from localStore. ', e);
            return {};
        }
    };
    StateService.prototype.saveStates = function (states) {
        localStorage.setItem('app.states', JSON.stringify(states));
    };
    StateService.prototype.updateStates = function (states) {
        this.currentStates.next(tslib_1.__assign({}, this.currentStates.value, states));
    };
    /**
     * Helpers
     */
    StateService.prototype.datesEqual = function (date1, date2) {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    };
    return StateService;
}());
export { StateService };
