// Common
import { Component, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Print
import { PDFProgressData, PDFDocumentProxy } from 'pdfjs-dist';
import print from 'print-js';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Attachment } from '@modules/form-controls/types/attachment';

@Component({
  selector: 'app-attachment-preview-modal',
  templateUrl: './attachment-preview-modal.component.html',
  styleUrls: ['./attachment-preview-modal.component.less']
})
export class AttachmentPreviewModalComponent extends BaseModalComponent {

  @Input() files: Attachment[];
  @Input() currentIndex: number;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 300px)',
    y: 'calc(50% - 300px)',
    width: '600px',
    height: '600px'
  };

  // Public
  public contentType: string;
  public loading = false;
  public errorMessage: string;
  public progress = {
    loaded: 0,
    total: 0
  };
  public pdfZoom = 1;
  public zoomStep = 0.25;
  public pdfRotation = 0;
  public rotationStep = 90;

  // Private
  private pdfData: Uint8Array[] = [];

  /**
   * Constructor
   */

  constructor(protected element: ElementRef, protected router: Router) {
    super();
  }

  /**
   * Actions
   */

  printAttachment() {
    this.contentType = this.checkType();
    switch (this.contentType) {
      case 'image':
        print({printable: this.files[this.currentIndex].url, type: this.contentType});
        break;
      case 'pdf':
        const fileURL = URL.createObjectURL(
          new Blob([new Uint8Array(this.pdfData[this.currentIndex])], {type: this.files[this.currentIndex].type})
        );
        print({printable: fileURL, type: this.contentType});
        break;
      default:
        break;
    }
  }

  /**
   * PDF methods
   */

  zoomIn () {
    this.pdfZoom += this.zoomStep;
  }

  zoomOut () {
    this.pdfZoom -= this.zoomStep;
  }

  rotateLeft () {
    this.pdfRotation += this.rotationStep;
  }

  rotateRight () {
    this.pdfRotation -= this.rotationStep;
  }

  pdfLoadComplete(pdf: PDFDocumentProxy) {
    this.loading = false;
    pdf.getData().then(arrayBytes => this.pdfData[this.currentIndex] = arrayBytes);
  }

  pdfErrorLoad(error: any) {
    this.loading = false;
    this.errorMessage = error;
  }

  checkType() {
    if (this.files[this.currentIndex] && this.files[this.currentIndex].type) {
      if (this.files[this.currentIndex].type.match(/^image\//)) {
        return 'image';
      } else if (this.files[this.currentIndex].type.match(/^video\//)) {
        return 'video';
      } else if (this.files[this.currentIndex].type.match('application/pdf')) {
        return 'pdf';
        this.loading = true;
        this.progress.total = this.files[this.currentIndex].size;
      } else {
        return '';
      }
    }

  }

  prev() {
    !this.currentIndex ? this.currentIndex = this.files.length - 1 : --this.currentIndex;
  }
  next() {
    this.currentIndex === this.files.length - 1 ? this.currentIndex = 0 : ++this.currentIndex;
  }

  pdfOnProgress(progressData: PDFProgressData) {
    this.loading = true;
    this.progress.loaded = progressData.loaded;
    this.progress.total = this.files[this.currentIndex].size;
  }

}
