// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { TasksSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { Group } from '@modules/contacts/types/group';

@Pipe({
  name: 'titleBySidebarFilter'
})
export class TitleBySidebarFilterPipe implements PipeTransform {

  transform(value: TasksSidebarFilterKey, group?: Group): any {
    switch (value) {
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'contacts':
        return 'Contacts';
      case 'vip':
        return 'VIP';
      case 'flagged':
        return 'Flagged';
      case 'upcoming':
        return 'Upcoming';
      case 'frequent':
        return 'Frequent';
      case 'recent':
        return 'Recent';
      case 'other':
        return 'Other';
      case 'groups':
        return 'Groups';
      default:
        return group ? group.name : '';
    }
  }
}
