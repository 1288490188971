<div class="events">
  <app-knowledge-header
    title="Events"
    [search]="true"
    [orders]="orderOptions[tabs[selectedTab]]"
    [selected]="orders[tabs[selectedTab]]"
    [actions]="scrollOptions[tabs[selectedTab]]"
    [filters]="filterOptions"
    [selectedFilters]="selectedFilters"
    (selectedChange)="selectOrder(tabs[selectedTab], $event)"
    (actionClick)="selectScrollOption(tabs[selectedTab], $event)"
    (searchQueryChange)="searchQuery = $event"
    (filtersChange)="handleFiltersChange($event)"
  ></app-knowledge-header>

  <mat-tab-group
    class="full-height"
    [@.disabled]="!renderFinished"
    [disableRipple]="true"
    [selectedIndex]="selectedTab"
    (selectedTabChange)="handleSelectTab($event)"
  >
    <mat-tab label="Today">
      <div class="events-today">
        <app-quick-form
          placeholder="Add New Event"
          bundledGroup="kpEventsListQuickForm"
          [toggle]="quickFormToggle"
          (clickMore)="handleAdd()"
          (itemDropped)="handleQuickFormDrop($event)"
        >
          <app-event-quick-form
            bundledGroup="kpEventsListQuickForm"
            [event]="quickFormEvent"
            (more)="handleAdd($event)"
          ></app-event-quick-form>
        </app-quick-form>

        <app-events-date-picker
          class="events-today-date-picker"
          [inline]="true"
          storeDateKey="kpEventsToday"
          [inputFormControl]="todayFormControl"
          collapsedStateKey="dpEventsToday"
        ></app-events-date-picker>

        <full-calendar-day
          *ngIf="selectedTab === 0"
          class="events-today-calendar"
          [viewDate]="todayFormControl.value"
          [events]="events"
          [hideDateHeader]="true"
          [eventTemplate]="badgeTemplate"
          (dateClicked)="handleDateClicked($event)"
          (eventDropped)="handleCellDrop($event)"
        ></full-calendar-day>

        <div class="shadow"></div>
      </div>
    </mat-tab>
    <mat-tab label="Upcoming">
      <div class="events-upcoming">
        <app-quick-form
          placeholder="Add New Event"
          bundledGroup="DPEventsListUpcomingQuickForm"
          [toggle]="quickFormToggle"
          (clickMore)="handleAdd()"
          (itemDropped)="handleQuickFormDrop($event)"
        >
          <app-event-quick-form
            bundledGroup="DPEventsListUpcomingQuickForm"
            [event]="quickFormEvent"
            (more)="handleAdd($event)"
          ></app-event-quick-form>
        </app-quick-form>

        <app-events-date-picker
          class="events-upcoming-date-picker" 
          [inline]="true"
          [inputFormControl]="upcomingDateControl"
          collapsedStateKey="dpEventsUpcoming"
        ></app-events-date-picker>
        
        <app-events-list 
          class="events-upcoming-list"
          placeholderText="You have no upcoming events"
          hoverPlacement="left"
          [selectedOrder]="orders.upcoming"
          [defaultFilters]="{archived: false, deleted: false, title: searchQuery, calendarIds: calendarIds}"
          [scrollPosition]="upcomingScrollPosition"
          [scrollToDay]="upcomingDateControl.valueChanges"
        ></app-events-list>

        <div class="shadow"></div>
      </div>
    </mat-tab>
    <mat-tab label="Archive">
      <div class="events-archive">
        <app-events-date-picker
          class="events-archive-date-picker"
          [inline]="true"
          [inputFormControl]="archiveDateControl"
          [filters]="{archived: true, deleted: false}"
          collapsedStateKey="dpEventsArchive"
        ></app-events-date-picker>

        <app-events-list 
          class="events-archive-list"
          placeholderText="You have no archived events"
          hoverPlacement="left"
          [selectedOrder]="orders.archived"
          [defaultFilters]="{archived: true, deleted: false, title: searchQuery, calendarIds: calendarIds}"
          [scrollPosition]="archivedScrollPosition"
          [scrollToDay]="archiveDateControl.valueChanges"
          (eventClick)="handleCellDrop($event)"
        ></app-events-list>

        <div class="shadow"></div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #badgeTemplate let-weekEvent="weekEvent" let-showTime="showTime">
  <app-event-badge
    [event]="weekEvent.event.meta"
    [showTime]="showTime"
    view="day"
    [popoverOffsetX]="weekEvent?.left ? -((hostWidth - 74) * weekEvent?.left / 100) - 74 : -74"
    hoverPlacement="left"
    (popoverFormClose)="handlePopoverFormClose()"
  ></app-event-badge>
</ng-template>
