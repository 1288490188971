import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoTaskListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoTaskListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoTaskListComponent(modalService, taskService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.taskService = taskService;
        _this.ngZone = ngZone;
        _this.type = 'task';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoTaskListComponent.prototype.getItems = function (ids) {
        return this.taskService.getTasks({ tasksIds: ids, limit: ids.length })
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.tasks; }));
    };
    /**
     * Actions
     */
    LinkedInfoTaskListComponent.prototype.select = function (task) {
        this.modalService.taskForm(task);
    };
    return LinkedInfoTaskListComponent;
}(LinkInfoPopover));
export { LinkedInfoTaskListComponent };
