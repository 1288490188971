import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var InsightsTopicDetailsComponent = /** @class */ (function () {
    function InsightsTopicDetailsComponent(knowledgeGraphService, mailService) {
        this.knowledgeGraphService = knowledgeGraphService;
        this.mailService = mailService;
        // Output
        this.topicSelected = new EventEmitter();
        this.changedSelectedFolder = new EventEmitter();
        // Public
        this.selectedSimilarTopics = [];
        this.similarTopics = [];
        this.similarTopicsCount = 0;
        this.similarTopicsLoader = true; // for showing when loading first data set
        this.similarTopicsCountLoader = true;
        this.orderOptions = [
            { name: 'Relevance', key: 'relevance' },
            { name: 'A-Z', key: 'name-asc' },
            { name: 'Z-A', key: 'name-desc' },
        ];
        this.foldersCounters = [];
        this.foldersCountersDropdown = [];
        this.countOtherFolders = 0;
        // Private
        this.componentNotDestroyed = new Subject();
    }
    /**
     * Component lifecycle
     */
    InsightsTopicDetailsComponent.prototype.ngOnChanges = function () {
        if (this.topics.length) {
            this.selectedOrder = this.orderOptions[0];
            this.selectTopic(null);
            this.loadSimilarTopics(false);
            this.loadFolderCounters(this.topics);
        }
    };
    InsightsTopicDetailsComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    InsightsTopicDetailsComponent.prototype.loadSimilarTopics = function (more) {
        var _this = this;
        if (more === void 0) { more = true; }
        if (!more) {
            this.similarTopics = [];
            this.similarTopicsCount = 0;
        }
        else if (this.similarTopicsLoader || this.similarTopics.length >= this.similarTopicsCount) {
            return;
        }
        this.similarTopicsLoader = true;
        this.knowledgeGraphService
            .getRelatedTopics({
            topics: this.topics,
            fromTime: this.timeFrame,
            toTime: this.toTime,
        }, this.similarTopicsLimit, this.similarTopics.length, this.selectedOrder.key)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b;
            var data = _a.data, count = _a.count;
            (_b = _this.similarTopics).push.apply(_b, tslib_1.__spread(data));
            _this.similarTopicsCount = count;
            _this.similarTopicsLoader = false;
        });
    };
    InsightsTopicDetailsComponent.prototype.loadFolderCounters = function (topics) {
        var _this = this;
        this.foldersCounters = [];
        this.foldersCountersDropdown = [];
        this.similarTopicsCountLoader = true;
        this.mailService
            .getFoldersCounts({
            relatedTopics: topics,
            fromTime: this.timeFrame,
            toTime: this.toTime,
        })
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (counters) {
            _this.similarTopicsCountLoader = false;
            var sliceCount = (counters.length === 3) ? 3 : 2;
            _this.foldersCounters = counters.slice(0, sliceCount);
            _this.foldersCountersDropdown = counters.slice(sliceCount);
            _this.countOtherFolders = _this.foldersCountersDropdown
                .reduce(function (sum, folder) { return sum + folder.totalMails || 0; }, 0);
        });
    };
    InsightsTopicDetailsComponent.prototype.selectOrder = function (order) {
        this.selectedOrder = order;
        this.loadSimilarTopics(false);
    };
    InsightsTopicDetailsComponent.prototype.selectTopic = function (topic, event) {
        if (topic) {
            if (!event.shiftKey) {
                if (this.selectedSimilarTopics.length === 1 && this.selectedSimilarTopics[0] === topic) {
                    this.selectedSimilarTopics = [];
                }
                else {
                    this.selectedSimilarTopics = [topic];
                }
            }
            else {
                if (this.selectedSimilarTopics.includes(topic)) {
                    this.selectedSimilarTopics = this.selectedSimilarTopics.filter(function (t) { return t !== topic; });
                }
                else {
                    this.selectedSimilarTopics = this.selectedSimilarTopics.concat([topic]);
                }
            }
        }
        this.topicSelected.emit(this.selectedSimilarTopics);
        this.loadFolderCounters(this.topics.concat(this.selectedSimilarTopics));
    };
    InsightsTopicDetailsComponent.prototype.selectFolder = function (id) {
        this.selectedFolder = id;
        this.changedSelectedFolder.emit(this.selectedFolder);
    };
    return InsightsTopicDetailsComponent;
}());
export { InsightsTopicDetailsComponent };
