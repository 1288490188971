<span>
  <ng-content></ng-content>
</span>

<!-- Context Menu -->
<context-menu #messageContextMenu (close)="makeItemInactive()">

  <!-- Star/Snooze/FollowUp/Important/Pin -->
  <ng-template contextMenuItem (execute)="pinMessage($event.item)">
    {{message.pinned ? "Unpin Message" : "Pin Message"}}
  </ng-template>

  <ng-template [visible]="!includeMessageInBulk()" contextMenuItem (execute)="selectForBulk($event.item)">
    Select Message
  </ng-template>

  <ng-template [visible]="includeMessageInBulk()" contextMenuItem (execute)="unselectMessage($event.item)">
    Unselect Message
  </ng-template>

  <ng-template contextMenuItem (execute)="starMessage($event.item)">
    <div class="message-context-menu">{{message.starred ? "Unstar Message" : "Star Message"}}</div>
  </ng-template>

  <ng-template contextMenuItem [subMenu]="snoozeMenu" [visible]="!message.snoozed">
    Snooze
  </ng-template>
  <ng-template contextMenuItem [visible]="message.snoozed" (execute)="removeSnoozeMessage($event.item)">
    Remove Snooze
  </ng-template>
  <context-menu #snoozeMenu>
    <ng-template contextMenuItem (execute)="snoozeMessage('tomorrow', $event.item)">{{'tomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snoozeMessage('afterTomorrow', $event.item)">{{'afterTomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snoozeMessage('endOfWeek', $event.item)">{{'endOfWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snoozeMessage('nextWeek', $event.item)">{{'nextWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="openCustomDateModal($event.item, 'snooze')">Custom date...</ng-template>
  </context-menu>

  <ng-template contextMenuItem [subMenu]="followUpMenu" [visible]="!message.followed">
    Follow Up
  </ng-template>
  <ng-template contextMenuItem [visible]="message.followed" (execute)="removeFollowMessage($event.item)">
    Remove Follow Up
  </ng-template>
  <context-menu #followUpMenu>
    <ng-template contextMenuItem (execute)="followMessage('tomorrow', $event.item)">{{'tomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="followMessage('afterTomorrow', $event.item)">{{'afterTomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="followMessage('endOfWeek', $event.item)">{{'endOfWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="followMessage('nextWeek', $event.item)">{{'nextWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="openCustomDateModal($event.item, 'followup')">Custom date...</ng-template>
  </context-menu>

  <ng-template contextMenuItem *ngIf="!messageFolders.includes('important')" (execute)="markAsImportant($event.item)">
    Mark as Important
  </ng-template>

  <ng-template [visible]="message.unread" contextMenuItem (execute)="markMessageAsReadUnread($event.item)">
    Mark As Read
  </ng-template>

  <ng-template [visible]="!message.unread" contextMenuItem (execute)="markMessageAsReadUnread($event.item)">
    Mark As Unread
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Replay/Forward -->
  <ng-template contextMenuItem (execute)="reply($event.item, 'reply')">
    Reply
  </ng-template>

  <ng-template contextMenuItem [visible]="message.files?.length" (execute)="reply($event.item, 'reply', $event.item.files)">
    Reply with Attachment
  </ng-template>

  <ng-template [visible]="message.participants?.length > 2" contextMenuItem (execute)="reply($event.item, 'replyAll')">
    Reply All
  </ng-template>

  <ng-template [visible]="message.participants?.length > 2 && message.files?.length" contextMenuItem (execute)="reply($event.item, 'replyAll', $event.item.files)">
    Reply All with Attachment
  </ng-template>

  <ng-template contextMenuItem (execute)="reply($event.item, 'forward')">
    Forward
  </ng-template>

  <ng-template contextMenuItem [visible]="message.files?.length" (execute)="reply($event.item, 'forward', $event.item.files)">
    Forward with Attachment
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Create-->
  <ng-template contextMenuItem [subMenu]="createMenu">
    Create
  </ng-template>
  <context-menu #createMenu>
    <ng-template contextMenuItem (execute)="createCalendarEvent($event.item)">Event</ng-template>
    <ng-template contextMenuItem [enabled]="false">Task</ng-template>
    <ng-template contextMenuItem [enabled]="false">Note</ng-template>
  </context-menu>

  <!-- Link-->
  <ng-template contextMenuItem [subMenu]="addMenu">
    Link to
  </ng-template>
  <context-menu #addMenu>
    <ng-template contextMenuItem [subMenu]="linkMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuTask">Task</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuNote">Note</ng-template>
  </context-menu>
  <context-menu #linkMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions appearance="context-menu" [linkedInfo]="[{type: 'message', id: message.id}]" (close)="closeContextMenu()"></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions appearance="context-menu" [linkedInfo]="[{type: 'message', id: message.id}]" (close)="closeContextMenu()"></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuTask>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-task-suggestions appearance="context-menu" [linkedInfo]="[{type: 'message', id: message.id}]" (close)="closeContextMenu()"></app-link-info-task-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuNote>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-note-suggestions appearance="context-menu" [linkedInfo]="[{type: 'message', id: message.id}]" (close)="closeContextMenu()"></app-link-info-note-suggestions>
    </ng-template>
  </context-menu>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Move to folder -->
  <ng-template contextMenuItem [visible]="!message.archived" (execute)="moveToArchive(true, [message])">
    Move to Archive
  </ng-template>

  <ng-template contextMenuItem [visible]="message.archived" (execute)="moveToArchive(false, [message])">
    Restore from Archive
  </ng-template>

  <ng-template contextMenuItem [visible]="!messageFolders.includes('spam')" (execute)="moveToFolder($event.item, 'spam')">
    Move to Spam
  </ng-template>

  <ng-template contextMenuItem [visible]="messageFolders.includes('spam')" (execute)="moveToFolder($event.item, 'inbox')">
    Restore from Spam
  </ng-template>

  <ng-template contextMenuItem (execute)="moveToFolder($event.item, 'trash')">
    Move to Trash
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToNewFolder([$event.item])">
    Move to New folder
  </ng-template>

  <ng-template contextMenuItem [subMenu]="moveToFolderSubmenu">
    Move to
  </ng-template>
  <context-menu #moveToFolderSubmenu>
    <ng-template contextMenuItem passive="true" let-item>
      <app-folder-select [title]="'Move to:'" (selected)="moveToFolder(item, $event.id)"></app-folder-select>
    </ng-template>
  </context-menu>

  <!-- Copy to folder -->
  <ng-template contextMenuItem [subMenu]="copyToFolderSubmenu">
    Copy to Folder
  </ng-template>
  <context-menu #copyToFolderSubmenu>
    <ng-template contextMenuItem passive="true" let-item>
      <app-folder-select [title]="'Copy to:'" (selected)="copyToFolder(item, $event.id)"></app-folder-select>
    </ng-template>
  </context-menu>

</context-menu>

<!-- Context-Menu for drafts -->
<context-menu #draftContextMenu>
  <ng-template contextMenuItem (execute)="pinMessage($event.item)">
    {{message.pinned ? "Unpin Message" : "Pin Message"}}
  </ng-template>
  <ng-template contextMenuItem (execute)="deleteDraft($event.item)">
    Delete
  </ng-template>
</context-menu>

<!-- Context-Menu for trash folder -->
<context-menu #trashContextMenu>
  <ng-template contextMenuItem (execute)="moveToFolder($event.item, 'inbox')">
    Restore from Trash
  </ng-template>
  <ng-template contextMenuItem (execute)="deleteMessage($event.item)">
    Delete Permanently
  </ng-template>
</context-menu>

<!-- Context-Menu for bulk of messages -->
<context-menu #bulkContextMenu>

  <ng-template contextMenuItem (execute)="clearBulk()">
    Reset All Selections
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToFolder('trash')">
    Delete Selected Messages
  </ng-template>

  <!-- Select/Unselect -->
  <ng-template contextMenuItem (execute)="unselectMessage($event.item)">
    Unselect Message
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Star/Important/Pin -->
  <ng-template contextMenuItem (execute)="starMessagesInBulk()">
    Star Messages
  </ng-template>

  <ng-template contextMenuItem [visible]="includeStarMessages()" (execute)="unstarMessagesInBulk()">
    Unstar Messages
  </ng-template>

  <ng-template contextMenuItem (execute)="pinMessagesInBulk()">
    Pin Messages
  </ng-template>

  <ng-template contextMenuItem [visible]="includePinMessages()" (execute)="unpinMessagesInBulk()">
    Unpin Messages
  </ng-template>

  <ng-template contextMenuItem (execute)="importantMessageInBulk()">
    Mark as Important
  </ng-template>

  <!-- Read/Unread -->
  <ng-template [visible]="includeUnreadMessages()" contextMenuItem (execute)="readMessagesInBulk()">
    Mark As Read
  </ng-template>

  <ng-template contextMenuItem (execute)="unreadMessagesInBulk()">
    Mark As Unread
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Link-->
  <ng-template contextMenuItem (execute)="linkMessages()">
    Link each other
  </ng-template>
  <ng-template contextMenuItem [subMenu]="addBulkMenu">
    Link to
  </ng-template>
  <context-menu #addBulkMenu>
    <ng-template contextMenuItem [subMenu]="bulkLinkMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="bulkLinkMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [subMenu]="bulkLinkMenuTask">Task</ng-template>
    <ng-template contextMenuItem [subMenu]="bulkLinkMenuNote">Note</ng-template>
  </context-menu>
  <context-menu #bulkLinkMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions [linkedInfo]="linkedInfo" (close)="closeContextMenu()"></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #bulkLinkMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions [linkedInfo]="linkedInfo" (close)="closeContextMenu()"></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #bulkLinkMenuTask>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-task-suggestions [linkedInfo]="linkedInfo" (close)="closeContextMenu()"></app-link-info-task-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #bulkLinkMenuNote>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-note-suggestions [linkedInfo]="linkedInfo" (close)="closeContextMenu()"></app-link-info-note-suggestions>
    </ng-template>
  </context-menu>

  <ng-template contextMenuItem divider="true"></ng-template>

  <!-- Move to folder -->
  <ng-template contextMenuItem (execute)="moveToArchive(true)">
    Move to Archive
  </ng-template>

  <ng-template contextMenuItem [visible]="includeArchiveMessages()" (execute)="moveToArchive(false)">
    Restore from Archive
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToFolder('spam')">
    Move to Spam
  </ng-template>

  <ng-template contextMenuItem [visible]="includeFolderInMessages('spam')" (execute)="moveMessagesToInbox()">
    Restore from Spam
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToFolder('trash')">
    Move to Trash
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToNewFolder()">
    Move to New folder
  </ng-template>

  <ng-template contextMenuItem [subMenu]="moveBulkToFolderSubmenu">
    Move to
  </ng-template>
  <context-menu #moveBulkToFolderSubmenu>
    <ng-template contextMenuItem passive="true" let-item>
      <app-folder-select [title]="'Move to:'" (selected)="moveBulkToFolder($event.id)"></app-folder-select>
    </ng-template>
  </context-menu>

  <!-- Copy to folder -->
  <ng-template contextMenuItem [subMenu]="copyBulkToFolderSubmenu">
    Copy to Folder
  </ng-template>
  <context-menu #copyBulkToFolderSubmenu>
    <ng-template contextMenuItem passive="true" let-item>
      <app-folder-select [title]="'Copy to:'" (selected)="copyBulkToFolder($event.id)"></app-folder-select>
    </ng-template>
  </context-menu>

</context-menu>

<!-- Context-Menu for bulk of trash messages -->
<context-menu #trashBulkContextMenu>
  <ng-template contextMenuItem (execute)="clearBulk()">
    Reset All Selections
  </ng-template>

  <ng-template contextMenuItem (execute)="moveMessagesToInbox()">
    Restore From Trash
  </ng-template>

  <ng-template contextMenuItem (execute)="deleteAllInBulk()">
    Delete Permanently Selected Messages
  </ng-template>
</context-menu>

<context-menu #pendingBulkContextMenu>
  <ng-template contextMenuItem [visible]="message?.sending && message?.sending?.status === 'error'" (execute)="cancelSendingMessage($event.item)">
    Resend Message
  </ng-template>
  <ng-template contextMenuItem [visible]="message?.sending && message?.sending?.status === 'error'" (execute)="editSendingMessage($event.item)">
    Edit Message
  </ng-template>
  <ng-template contextMenuItem [visible]="message?.sending && message?.sending?.status === 'pending'" (execute)="cancelSendingMessage($event.item)">
    Cancel Message
  </ng-template>
</context-menu>
