// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { BsDropdownModule } from 'ngx-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { PopoverModule } from '@modules/popover/popover.module';
import { PipesModule } from '@modules/pipes/pipes.module';

// Components
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { DropdownActionItemsComponent } from './components/dropdown-action-items/dropdown-action-items.component';
import { DropdownMultiSelectComponent } from './components/dropdown-multi-select/dropdown-multi-select.component';
import { DropdownSingleSelectComponent } from './components/dropdown-single-select/dropdown-single-select.component';
import { DropdownPaginationComponent } from './components/dropdown-pagination/dropdown-pagination.component';
import { DropdownTemplateItemsComponent } from './components/dropdown-template-items/dropdown-template-items.component';
import { DropdownColorSelectComponent } from './components/dropdown-color-select/dropdown-color-select.component';
import { DropdownNestedComponent } from './components/dropdown-nested/dropdown-nested.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    MatDatepickerModule,
    MatIconModule,
    PopoverModule,
    PipesModule,
  ],
  declarations: [
    DropdownComponent,
    DropdownItemComponent,
    DropdownActionItemsComponent,
    DropdownMultiSelectComponent,
    DropdownSingleSelectComponent,
    DropdownPaginationComponent,
    DropdownTemplateItemsComponent,
    DropdownColorSelectComponent,
    DropdownNestedComponent,
  ],
  exports: [
    DropdownComponent, DropdownItemComponent, DropdownActionItemsComponent,
    DropdownMultiSelectComponent, DropdownSingleSelectComponent, DropdownPaginationComponent,
    DropdownTemplateItemsComponent, DropdownColorSelectComponent, DropdownNestedComponent,
  ],
})
export class DropdownModule { }
