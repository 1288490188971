// Common
import { Component, OnInit } from '@angular/core';

// Services
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-account-delayed-send',
  templateUrl: './account-delayed-send.component.html',
  styleUrls: ['./account-delayed-send.component.less']
})
export class AccountDelayedSendComponent implements OnInit {
  loading: boolean;
  saved: boolean;
  errorMessage: string;
  delay: Number;

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.loading = false;
    this.saved = false;
    this.errorMessage = '';
    this.delay = 0;
    this.accountService.getDelayedSend()
      .subscribe(
        delay => {
          this.delay = delay;
        },
        error => this.errorMessage = error
      );
  }

  saveDelayedSend() {
    this.loading = true;
    this.saved = false;
    this.errorMessage = '';
    this.accountService.saveDelayedSend(this.delay)
      .subscribe(
        result => {
          this.loading = false;
          this.saved = result['success'];
          if (!result['success']) {
            this.errorMessage = result['error'];
          }
        },
        error => {
          this.loading = false;
          this.saved = false;
          this.errorMessage = error;
        }
      );
  }

}
