export class SearchQueryParams {
  topics: string[];
  contacts: string[];
  keywords: string[];
  tags: string[];

  constructor(
    topics: string[] = null,
    contacts: string[] = null,
    keywords: string[] = null,
    tags: string[] = null
  ) {
    this.topics = topics;
    this.contacts = contacts;
    this.keywords = keywords;
    this.tags = tags;
  }
}
