/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../popover/directives/popover.directive";
import * as i6 from "../../../popover/services/popover.service";
import * as i7 from "./dropdown.component";
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toggleButtonTemplate; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa fa-", _co.icon, " dropdown-toggle"); _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "dropdown-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "filter"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "filter"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropdown-toggle-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "dropdown-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.icon; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_DropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-content"]], [[4, "max-height", "px"]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxHeight; _ck(_v, 0, 0, currVal_0); }); }
export function View_DropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "dropdown"], ["popover", ""]], null, [[null, "popoverVisibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("popoverVisibleChange" === en)) {
        var pd_0 = ((_co.dropdownOpen = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4866048, null, 0, i5.PopoverDirective, [i1.ElementRef, i6.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverAllowedOutsideSelectorsClick: [3, "popoverAllowedOutsideSelectorsClick"], popoverArrow: [4, "popoverArrow"], popoverShowUntil: [5, "popoverShowUntil"] }, { popoverVisibleChange: "popoverVisibleChange" }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["class", "dropdown-toggle-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["dropdownContent", 2]], null, 0, null, View_DropdownComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7); var currVal_1 = _co.placement; var currVal_2 = _co.trigger; var currVal_3 = (".dropdown-toggle-wrapper, " + _co.allowedOutsideSelectors); var currVal_4 = false; var currVal_5 = _co.popoverClose; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.toggleButtonTemplate; _ck(_v, 4, 0, currVal_6); var currVal_7 = !_co.toggleButtonTemplate; _ck(_v, 6, 0, currVal_7); }, null); }
export function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-dropdown", [], null, null, null, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 1753088, null, 6, i7.DropdownComponent, [], null, null), i1.ɵqud(335544320, 1, { toggleButtonTemplate: 0 }), i1.ɵqud(603979776, 2, { singleSelects: 1 }), i1.ɵqud(603979776, 3, { multiSelects: 1 }), i1.ɵqud(603979776, 4, { actionItems: 1 }), i1.ɵqud(603979776, 5, { templateItems: 1 }), i1.ɵqud(603979776, 6, { colorSelect: 1 })], null, null); }
var DropdownComponentNgFactory = i1.ɵccf("app-dropdown", i7.DropdownComponent, View_DropdownComponent_Host_0, { icon: "icon", align: "align", placement: "placement", closeOnSelect: "closeOnSelect", title: "title", trigger: "trigger", maxHeight: "maxHeight", allowedOutsideSelectors: "allowedOutsideSelectors", takeUntil: "takeUntil" }, {}, ["*"]);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
