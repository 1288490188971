<div class="input-container"
  [ngClass]="{
    'app-form-controls-input-outline': appearance === 'outline',
    'app-form-controls-input-standard': appearance === 'standard'
  }"
>
  <mat-form-field
    [appearance]="appearance"
    [ngStyle]="{width: width || '100%'}"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
  >
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <input
      matInput 
      [placeholder]="appearance === 'outline' ? null : placeholder" 
      [formControl]="textControl" 
      autocomplete="off"
      [matAutocomplete]="autocompletePanel"
    >
    <mat-icon matSuffix svgIcon="dropdown"></mat-icon>
  </mat-form-field>
</div>

<mat-autocomplete
  #autocompletePanel="matAutocomplete"
  class="autocomplete-panel"
  panelWidth="auto"
>
  
  <div class="options">
    <mat-option 
      class="options-item"
      *ngFor="let item of suggestions | async" 
      [value]="item.title"
      (click)="handleSelectOption(item, $event)"
    >
      {{ item.title }}
    </mat-option>
  </div>
</mat-autocomplete>
