import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assign'
})
export class AssignPipe implements PipeTransform {

  transform(originalObject: object, newKey: any, newValue?: any): object {

    if (!originalObject || !newKey || !newValue) {
      return {};
    }

    if (newKey && newValue) {
      return {
        ...originalObject,
        [newKey]: newValue
      };
    }
  }
}
