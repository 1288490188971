/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachments-list.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../form-controls/components/icon-button/icon-button.component.ngfactory";
import * as i3 from "../../../form-controls/components/icon-button/icon-button.component";
import * as i4 from "../../../form-controls/components/collapseable-group/collapseable-group.component.ngfactory";
import * as i5 from "../../../form-controls/components/collapseable-group/collapseable-group.component";
import * as i6 from "../../../form-controls/components/file-uploader/file-uploader.component.ngfactory";
import * as i7 from "../../../form-controls/components/file-uploader/file-uploader.component";
import * as i8 from "../../../mail/services/mail.service";
import * as i9 from "../../../modal/services/modal.service";
import * as i10 from "./attachments-list.component";
import * as i11 from "../../../pages/services/dynamic-panel.service";
var styles_AttachmentsListComponent = [i0.styles];
var RenderType_AttachmentsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentsListComponent, data: {} });
export { RenderType_AttachmentsListComponent as RenderType_AttachmentsListComponent };
function View_AttachmentsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon-button", [["class", "right-icon"], ["icon", "download"]], null, [[null, "clicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clicked" === en)) {
        var pd_0 = (_co.downloadAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IconButtonComponent_0, i2.RenderType_IconButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconButtonComponent, [], { icon: [0, "icon"], disabled: [1, "disabled"] }, { clicked: "clicked" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-icon-button", [["class", "right-icon"], ["icon", "preview"]], null, [[null, "clicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clicked" === en)) {
        var pd_0 = (_co.showAttachments() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IconButtonComponent_0, i2.RenderType_IconButtonComponent)), i1.ɵdid(3, 49152, null, 0, i3.IconButtonComponent, [], { icon: [0, "icon"], disabled: [1, "disabled"] }, { clicked: "clicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "download"; var currVal_1 = (!((_co.attachments == null) ? null : _co.attachments.length) && !((_co.attachmentsFormControl == null) ? null : ((_co.attachmentsFormControl.value == null) ? null : _co.attachmentsFormControl.value.length))); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "preview"; var currVal_3 = (!((_co.attachments == null) ? null : _co.attachments.length) && !((_co.attachmentsFormControl == null) ? null : ((_co.attachmentsFormControl.value == null) ? null : _co.attachmentsFormControl.value.length))); _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
export function View_AttachmentsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-form-collapseable-group", [["title", "Attachments"]], null, [[null, "extendIconAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("extendIconAction" === en)) {
        var pd_0 = (_co.extendAction.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CollapseableGroupComponent_0, i4.RenderType_CollapseableGroupComponent)), i1.ɵdid(1, 49152, null, 0, i5.CollapseableGroupComponent, [], { title: [0, "title"], headerRightIconsTemplate: [1, "headerRightIconsTemplate"], extendIconEnabled: [2, "extendIconEnabled"], extendIcon: [3, "extendIcon"], extendIconSelected: [4, "extendIconSelected"] }, { extendIconAction: "extendIconAction" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-file-uploader", [], null, [[null, "attachmentsChange"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("attachmentsChange" === en)) {
        var pd_0 = (_co.attachmentsChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_FileUploaderComponent_0, i6.RenderType_FileUploaderComponent)), i1.ɵdid(3, 4964352, null, 0, i7.FileUploaderComponent, [i8.MailService, i9.ModalService, i1.NgZone], { attachments: [0, "attachments"], attachmentsFormControl: [1, "attachmentsFormControl"], upload: [2, "upload"] }, { attachmentsChange: "attachmentsChange", uploading: "uploading" }), (_l()(), i1.ɵand(0, [["rightIcons", 2]], null, 0, null, View_AttachmentsListComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Attachments"; var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = _co.extendIconEnabled; var currVal_3 = (_co.dynamicPanel && "open-modal"); var currVal_4 = ((_co.dynamicPanelType === "attachments") && !_co.dynamicPanel); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.attachments; var currVal_6 = _co.attachmentsFormControl; var currVal_7 = _co.upload; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7); }, null); }
export function View_AttachmentsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-attachments-list", [], null, null, null, View_AttachmentsListComponent_0, RenderType_AttachmentsListComponent)), i1.ɵdid(1, 245760, null, 0, i10.AttachmentsListComponent, [i9.ModalService, i11.DynamicPanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentsListComponentNgFactory = i1.ɵccf("app-attachments-list", i10.AttachmentsListComponent, View_AttachmentsListComponent_Host_0, { attachments: "attachments", attachmentsFormControl: "attachmentsFormControl", upload: "upload", dynamicPanel: "dynamicPanel", extendIconEnabled: "extendIconEnabled" }, { attachmentsChange: "attachmentsChange", uploading: "uploading", extendAction: "extendAction" }, []);
export { AttachmentsListComponentNgFactory as AttachmentsListComponentNgFactory };
