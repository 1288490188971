// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { DropdownModule } from '@modules/dropdown/dropdown.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ContextMenuModule } from 'ngx-contextmenu';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PopoverModule } from '@modules/popover/popover.module';
import { PipesModule } from '@modules/pipes/pipes.module';

// Directives
import { AutosizeDirective } from './directives/autosize/autosize.directive';
import { ClickDirective } from './directives/click/click.directive';

// Components
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CajaComponent } from './components/caja/caja.component';
import { LoaderComponent } from './components/loader/loader.component';
import { KnowledgeHeaderComponent } from './components/knowledge-header/knowledge-header.component';
import { FolderSelectComponent } from './components/folder-select/folder-select.component';
import { ContainerOverflowShadowComponent } from './components/container-overflow-shadow/container-overflow-shadow.component';
import { SignatureSelectorComponent } from './components/text-editor/signature-selector/signature-selector.component';
import { FontNameSelectorComponent } from './components/text-editor/font-name-selector/font-name-selector.component';
import { MapPreviewComponent } from '@modules/common/components/map-preview/map-preview.component';
import { InfinityScrollListComponent } from './components/infinity-scroll-list/infinity-scroll-list.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';

@NgModule({
  imports: [
    AngularCommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    ContextMenuModule,
    AppSplitViewModule,
    EditorModule,
    MatFormFieldModule,
    MatInputModule,
    FormControlsModule,
    BundledInputsModule,
    PickerModule,
    PopoverModule,
    PipesModule,
  ],
  declarations: [
    AutosizeDirective,
    ClickDirective,
    CajaComponent,
    ContainerOverflowShadowComponent,
    FolderSelectComponent,
    KnowledgeHeaderComponent,
    LoaderComponent,
    SidebarComponent,
    TextEditorComponent,
    SignatureSelectorComponent,
    FontNameSelectorComponent,
    InfinityScrollListComponent,
    MapPreviewComponent,
    CircularProgressComponent
  ],
  exports: [
    AutosizeDirective,
    ClickDirective,
    CajaComponent,
    ContainerOverflowShadowComponent,
    FolderSelectComponent,
    KnowledgeHeaderComponent,
    LoaderComponent,
    SidebarComponent,
    TextEditorComponent,
    MapPreviewComponent,
    CircularProgressComponent
  ],
})
export class CommonModule { }
