/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linked-info-dynamic-panel.component.less.shim.ngstyle";
import * as i1 from "../styles/dynamic-panel.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../common/components/knowledge-header/knowledge-header.component.ngfactory";
import * as i6 from "../../../../common/components/knowledge-header/knowledge-header.component";
import * as i7 from "../../../../linked-info/components/linked-info-list/linked-info-list.component.ngfactory";
import * as i8 from "../../../../linked-info/components/linked-info-list/linked-info-list.component";
import * as i9 from "../../../../linked-info/services/linked-info.service";
import * as i10 from "../../../../mail/services/mail.service";
import * as i11 from "../../../../events/services/calendar.service";
import * as i12 from "../../../../tasks/services/projects.service";
import * as i13 from "../../../../tasks/services/tasks.service";
import * as i14 from "../../../../notes/services/notes.service";
import * as i15 from "../../../../contacts/services/groups.service";
import * as i16 from "../../../../contacts/services/contacts.service";
import * as i17 from "./linked-info-dynamic-panel.component";
import * as i18 from "../../../services/dynamic-panel.service";
var styles_LinkedInfoDynamicPanelComponent = [i0.styles, i1.styles];
var RenderType_LinkedInfoDynamicPanelComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_LinkedInfoDynamicPanelComponent, data: {} });
export { RenderType_LinkedInfoDynamicPanelComponent as RenderType_LinkedInfoDynamicPanelComponent };
function View_LinkedInfoDynamicPanelComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "btn-back mat-icon notranslate"], ["role", "img"], ["svgIcon", "arrow-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i2.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i2.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Linked Info"]))], function (_ck, _v) { var currVal_2 = "arrow-up"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).inline; var currVal_1 = (((i2.ɵnov(_v, 1).color !== "primary") && (i2.ɵnov(_v, 1).color !== "accent")) && (i2.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LinkedInfoDynamicPanelComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "dynamic-panel"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "app-knowledge-header", [["height", "45px"]], null, null, null, i5.View_KnowledgeHeaderComponent_0, i5.RenderType_KnowledgeHeaderComponent)), i2.ɵdid(2, 245760, null, 0, i6.KnowledgeHeaderComponent, [], { titleTemplate: [0, "titleTemplate"], orders: [1, "orders"], height: [2, "height"] }, null), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "dynamic-panel__body"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "app-linked-info-list", [["class", "related-linked-info"]], null, null, null, i7.View_LinkedInfoListComponent_0, i7.RenderType_LinkedInfoListComponent)), i2.ɵdid(5, 770048, null, 0, i8.LinkedInfoListComponent, [i9.LinkedInfoService, i10.MailService, i11.CalendarService, i12.ProjectsService, i13.TasksService, i14.NotesService, i15.GroupsService, i16.ContactsService], { parentLinkedInfo: [0, "parentLinkedInfo"], linkedInfo: [1, "linkedInfo"] }, null), (_l()(), i2.ɵand(0, [["titleTemplate", 2]], null, 0, null, View_LinkedInfoDynamicPanelComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵnov(_v, 6); var currVal_1 = _co.orderOptions; var currVal_2 = "45px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.parentLinkedInfo; var currVal_4 = _co.linkedInfo; _ck(_v, 5, 0, currVal_3, currVal_4); }, null); }
export function View_LinkedInfoDynamicPanelComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-linked-info-dynamic-panel", [], null, null, null, View_LinkedInfoDynamicPanelComponent_0, RenderType_LinkedInfoDynamicPanelComponent)), i2.ɵdid(1, 114688, null, 0, i17.LinkedInfoDynamicPanelComponent, [i18.DynamicPanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkedInfoDynamicPanelComponentNgFactory = i2.ɵccf("app-linked-info-dynamic-panel", i17.LinkedInfoDynamicPanelComponent, View_LinkedInfoDynamicPanelComponent_Host_0, { parentLinkedInfo: "parentLinkedInfo", linkedInfo: "linkedInfo" }, {}, []);
export { LinkedInfoDynamicPanelComponentNgFactory as LinkedInfoDynamicPanelComponentNgFactory };
