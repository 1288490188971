import * as tslib_1 from "tslib";
// Common
import { ChangeDetectorRef, EventEmitter, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';
var ContentMenuCheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentMenuCheckboxComponent, _super);
    /**
     * Constructor
     */
    function ContentMenuCheckboxComponent(changeDetector, element, contentMenuService) {
        var _this = _super.call(this, changeDetector, contentMenuService) || this;
        _this.element = element;
        // Public
        _this.headerHeight = 24;
        _this.checked = false;
        _this.disabled = false;
        // Outputs
        _this.checkedChange = new EventEmitter();
        return _this;
    }
    ContentMenuCheckboxComponent.prototype.ngOnChanges = function (changes) {
        if ('color' in changes) {
            this.element.nativeElement.style.setProperty('--checkboxColor', this.color);
        }
    };
    /**
     * Actions
     */
    ContentMenuCheckboxComponent.prototype.toggle = function () {
        if (this.disabled) {
            return;
        }
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    };
    return ContentMenuCheckboxComponent;
}(ContentMenuBaseItemComponent));
export { ContentMenuCheckboxComponent };
