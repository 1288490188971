import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy, NgZone, EventEmitter } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
// RxJS
import { Subject, of, timer, fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, map, filter, tap, switchMap, takeUntil, retryWhen } from 'rxjs/operators';
var InfinityScrollListComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function InfinityScrollListComponent(ngZone) {
        this.ngZone = ngZone;
        // Public
        this.loading = false;
        this.loadingError = false;
        this.itemsStream = new Subject();
        this.itemsStreamObservable = this.itemsStream.asObservable();
        this.focused = false;
        this.itemHeight = 88; // Used for programmatic scroll calculations
        // Protected
        this.componentNotDestroyed = new Subject();
        this.currentIndex = new BehaviorSubject(0);
        // Private
        this.loadItems = new Subject();
        this.initialIndex = 0;
        this.loadInProgress = new EventEmitter();
    }
    /**
     * Component lifecycle
     */
    InfinityScrollListComponent.prototype.ngOnInit = function () {
        var _this = this;
        /* Item Loading logic */
        this.loadItems
            .pipe(debounceTime(200), map(function (range) {
            if (_this.initialIndex) {
                range = tslib_1.__assign({}, range, { start: _this.initialIndex, end: _this.initialIndex + 20 });
            }
            if (!_this.items || range.rewrite) {
                return range;
            }
            var updatedRange = { start: undefined, end: undefined, rewrite: range.rewrite };
            for (var i = range.start; i <= range.end && i < _this.items.length; i++) {
                if (!_this.items[i]) {
                    updatedRange.start = updatedRange.start !== undefined ? updatedRange.start : i;
                    updatedRange.end = i;
                }
            }
            return updatedRange;
        }), filter(function (range) { return range && range.end > 0 && range.start < range.end; }), tap(function () {
            _this.loadInProgress.next(true);
            _this.loadingError = false;
        }), switchMap(function (range) {
            return _this.getItems(range.start, range.end - range.start + 1)
                .pipe(map(function (response) { return ({ range: range, response: response }); }));
        }), takeUntil(this.componentNotDestroyed), map(function (_a) {
            var _b;
            var range = _a.range, response = _a.response;
            _this.loadInProgress.next(false);
            if (!_this.items) {
                _this.items = new Array(response.count || 0);
            }
            else if (range.rewrite) {
                // Compare if new messages are not the same messages in selected range
                if (!response.items.length ||
                    _this.items.length !== response.count ||
                    response.items.some(function (item, index) {
                        return !_this.items[range.start + index] ||
                            !_this.compareItems(response.items[index], _this.items[range.start + index]);
                    })) {
                    _this.items = new Array(response.count || 0);
                }
            }
            else if (_this.items.length !== response.count) {
                _this.refreshCurrentItems();
            }
            // Saving object pointer if message is selected
            // TODO: think of better way of doing this
            if (_this.selectedItems && _this.selectedItems.length) {
                var _loop_1 = function (index) {
                    var selectedItem = _this.selectedItems
                        .find(function (item) { return _this.compareItems(item, response.items[index]); });
                    if (selectedItem) {
                        response.items[index] = Object.assign(selectedItem, response.items[index]);
                    }
                };
                for (var index = 0; index < response.items.length; index++) {
                    _loop_1(index);
                }
            }
            (_b = _this.items).splice.apply(_b, tslib_1.__spread([range.start, response.items.length], response.items));
            if (_this.initialIndex) {
                timer(0).subscribe(function () {
                    _this.scrollToIndex(_this.initialIndex);
                    _this.initialIndex = 0;
                });
            }
            return _this.items;
        }), retryWhen(function (errors) {
            return errors.pipe(tap(function () {
                _this.loadInProgress.next(false);
                _this.loadingError = true;
            }));
        }))
            .subscribe(function (items) { return _this.itemsStream.next(items); });
        this.viewport.renderedRangeStream
            .pipe(filter(function (range) { return range && range.end > 0 && range.start <= range.end; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (range) {
            _this.loadItems.next({ start: range.start, end: range.end, rewrite: false });
        });
        // Add subs for event required for keyboard navigation. Defined outside of angular for performance reasons
        this.ngZone.runOutsideAngular(function () {
            _this.viewport.scrolledIndexChange
                .pipe(takeUntil(_this.componentNotDestroyed))
                .subscribe(function (index) { return _this.currentIndex.next(index); });
            fromEvent(window.document, 'click')
                .pipe(filter(function () { return !!(_this.viewport && _this.viewport.elementRef); }), takeUntil(_this.componentNotDestroyed))
                .subscribe(function (event) {
                return _this.focused =
                    _this.viewport.elementRef.nativeElement.contains(event.target) ||
                        event['path'].includes(_this.viewport.elementRef.nativeElement);
            });
            fromEvent(window.document, 'keydown')
                .pipe(filter(function (event) { return _this.focused && !(event.target instanceof Element &&
                event.target.tagName.toLowerCase() === 'input'); }), tap(function (event) {
                event.preventDefault();
                event.stopPropagation();
            }), takeUntil(_this.componentNotDestroyed))
                .subscribe(function (event) { return _this.keydown(event); });
        });
        this.loadInProgress.asObservable()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (value) { return _this.loading = value; });
        this.resetItems();
    };
    InfinityScrollListComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    InfinityScrollListComponent.prototype.resetItems = function () {
        this.items = null;
        this.itemsStream.next([]); // Viewport accepts only arrays, so can't send null
        this.selectedItems = [];
        this.loadItems.next({ start: 0, end: 20, rewrite: true });
    };
    InfinityScrollListComponent.prototype.refreshCurrentItems = function () {
        var renderedRange = this.viewport.getRenderedRange();
        if (renderedRange && !renderedRange.start && !renderedRange.end) {
            renderedRange.end = 20;
        }
        this.loadItems.next(tslib_1.__assign({}, renderedRange, { rewrite: true }));
    };
    InfinityScrollListComponent.prototype.scrollToIndex = function (offset, behavior) {
        if (!this.items) {
            this.initialIndex = offset;
        }
        else {
            this.viewport.scrollToIndex(offset, behavior);
        }
    };
    // This method have to be overload, to get appropriate items
    InfinityScrollListComponent.prototype.getItems = function (offset, limit) {
        return of({ items: [], count: 0 });
    };
    // Optionally overload this method for items comparison. For example if id field named differently
    InfinityScrollListComponent.prototype.compareItems = function (item1, item2) {
        return item1 && item2 && item1['id'] === item2['id'];
    };
    InfinityScrollListComponent.prototype.selectItem = function (item, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        if (!item) {
            return;
        }
        var multi = event.ctrlKey || event.shiftKey || event.metaKey;
        var range = event.shiftKey || selectAll;
        if (multi && this.selectedItems.length === 1 && this.compareItems(this.selectedItems[0], item)) {
            return;
        }
        if (!multi || this.selectedItems.length === 0) {
            this.selectedItems = [item];
        }
        else if (!range) {
            var index = this.selectedItems.indexOf(item);
            if (index === -1) {
                this.selectedItems.push(item);
            }
            else {
                this.selectedItems.splice(index, 1);
            }
        }
        else {
            var lastSelectedIndex = this.items.indexOf(this.selectedItems[this.selectedItems.length - 1]);
            var currentSelectedIndex = this.items.indexOf(item);
            var startSelection = lastSelectedIndex < currentSelectedIndex ? lastSelectedIndex : currentSelectedIndex;
            var endSelection = lastSelectedIndex > currentSelectedIndex ? lastSelectedIndex : currentSelectedIndex;
            for (var i = startSelection; i <= endSelection; i++) {
                var indexInSelection = this.selectedItems.indexOf(this.items[i]);
                if (indexInSelection !== -1) {
                    this.selectedItems.splice(indexInSelection, 1);
                }
                this.selectedItems.push(this.items[i]);
            }
        }
    };
    InfinityScrollListComponent.prototype.keydown = function (event) {
        var selectAllPressed = event.code === 'KeyA' && (event.ctrlKey || event.metaKey);
        if (!this.items ||
            !this.items.length ||
            (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && !selectAllPressed)) {
            return;
        }
        var selectAll = false;
        var nextSelectedPosition = 0;
        if (this.selectedItems.length === 0) {
            nextSelectedPosition = 0;
        }
        else if (event.key === 'ArrowUp') {
            var firstSelectedPosition = this.items.indexOf(this.selectedItems[0]);
            nextSelectedPosition = firstSelectedPosition - 1 > 0 ? firstSelectedPosition - 1 : 0;
        }
        else if (event.key === 'ArrowDown') {
            var lastSelectedPosition = this.items.indexOf(this.selectedItems[this.selectedItems.length - 1]);
            nextSelectedPosition = lastSelectedPosition + 1 < this.items.length ? lastSelectedPosition + 1 : this.items.length - 1;
        }
        else if (selectAllPressed) {
            selectAll = true;
            this.selectedItems = [this.items[0]];
            nextSelectedPosition = this.items.length - 1;
        }
        this.selectItem(this.items[nextSelectedPosition], event, selectAll);
        this.scrollToSelected(nextSelectedPosition);
    };
    InfinityScrollListComponent.prototype.scrollToSelected = function (selectedIndex) {
        if (selectedIndex <= this.currentIndex.value) {
            this.viewport.scrollToIndex(selectedIndex);
            return;
        }
        // These manipulations required because of CDK Viewport throwing error when call measureRangeSize outside of rendered content
        var renderedRange = this.viewport.getRenderedRange();
        var measuringRangeEnd = selectedIndex + 1 < renderedRange.end ? selectedIndex + 1 : renderedRange.end;
        var notRenderedTopItemsHeight = renderedRange.start * this.itemHeight;
        var notRenderedBottomItemsHeight = (selectedIndex + 1 - measuringRangeEnd) * this.itemHeight;
        // Calculates is selected item visible, < 0 visible, > 0 invisible
        var visibleRangeDelta = this.viewport.measureRangeSize({ start: this.currentIndex.value, end: measuringRangeEnd })
            + notRenderedBottomItemsHeight
            - this.viewport.getViewportSize();
        if (visibleRangeDelta > 0) {
            this.viewport.scrollToOffset(this.viewport.measureRangeSize({ start: renderedRange.start, end: measuringRangeEnd })
                + notRenderedTopItemsHeight
                + notRenderedBottomItemsHeight
                - this.viewport.getViewportSize());
        }
    };
    return InfinityScrollListComponent;
}());
export { InfinityScrollListComponent };
