// Common
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.less'],
})
export class ColorPickerComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;

  // Outputs
  @Output() close = new EventEmitter<void>();

  // Public
  public colors = ['#ffeb3b', '#9d93ff', '#409aff', '#c5e1a5', '#cccfdb', '#ff758c', '#ffbb6f'];
  public colorPickerControl = new ColorPickerControl();
  public hexFormControl =  new FormControl(['#9E69AF']);
  public popoverShowUntil = new Subject<void>();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private hexPattern = /^#([a-f0-9]{6})/i;

  /**
   * Constructor
   */

  constructor( ) {
    this.colorPickerControl.setValueFrom('#9E69AF');

    this.colorPickerControl.valueChanges
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((color: Color) => {
        this.hexFormControl.setValue(color.toHexString());
      });

    this.hexFormControl.valueChanges
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe( (color: string) => {
        if (this.colorPickerControl.value.toHexString() !== color  && this.hexPattern.test(color)) {
          this.colorPickerControl.setValueFrom(color);
        }
      });
  }

  ngOnInit() {
    if (this.inputFormControl && this.inputFormControl.value) {
      this.inputFormControl.valueChanges
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe( color => {
          this.colorPickerControl.setValueFrom(color);
        });
      this.colorPickerControl.setValueFrom(this.inputFormControl.value);
    }
  }

  /**
   * Component lifecycle
   */
  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();

    this.popoverShowUntil.next();
    this.popoverShowUntil.complete();
  }

  /**
   * Actions
   */
  setColor(color: string) {
    this.inputFormControl.patchValue(color);
  }

  save() {
    this.setColor(this.colorPickerControl.value.toHexString());
    this.popoverShowUntil.next();
  }

  cancel() {
    this.popoverShowUntil.next();
  }

  /**
   * Helpers
   */
  // To prevent closing popover if attached to context menu
  preventClose(event) {
    event.stopPropagation();
  }
}
