import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { TaskContextMenuComponent } from '../components/task-context-menu/task-context-menu.component';
var TaskContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(TaskContextMenuDirective, _super);
    function TaskContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = TaskContextMenuComponent;
        return _this;
    }
    TaskContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        componentRef.instance.task = this.appTaskContextMenu;
    };
    return TaskContextMenuDirective;
}(ContextMenuDirective));
export { TaskContextMenuDirective };
