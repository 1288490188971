<div class="login-box-wrapper">
  <div class="login-box">
    <div class="login-logo">
      <a href="#"><img src="assets/logo/logo-main.svg"/></a>
    </div>
    <!-- /.login-logo -->
    <div class="login-box-body">
      <h1 class="login-box-welcome-msg">WELCOME</h1>
      <h2 class="login-box-msg">Login via your Google Mail Account</h2>
      <div class="form-group">
        <button class="btn btn-login" (click)="googleSignIn()"><span class="icon-google-g-logo"></span>Login Via
          Google
        </button>
      </div>
      <div class="alert alert-warning" *ngIf="emailNotAllowed">Hey there!<br>Unfortunately, your email has not been authorized on our systems yet. But don't worry, we are working hard to get people access. If you haven't requested access yet, please go sign up at <a href="http://glimpse.email">http://glimpse.email</a>.<br><br>Thank you and see you soon!</div>
      <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
      <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
    </div>
    <!-- /.login-box-body -->
  </div>
  <div class="copyright">© 2019 Deepframe</div>
</div>

