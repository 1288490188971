import * as tslib_1 from "tslib";
// Common
import { ComponentRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { CalendarContextMenuComponent } from '../../components/calendar-context-menu/calendar-context-menu.component';
var CalendarContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarContextMenuDirective, _super);
    function CalendarContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contextMenuComponent = CalendarContextMenuComponent;
        _this.popoverClose = new Subject();
        return _this;
    }
    CalendarContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        // TODO call this every time Inputs are changed ?
        componentRef.instance.calendar = this.appCalendarContextMenu;
        componentRef.instance.originRef = this.elementRef;
        componentRef.instance.popoverClose = this.popoverClose;
    };
    CalendarContextMenuDirective.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.popoverClose.next();
        this.popoverClose.complete();
    };
    return CalendarContextMenuDirective;
}(ContextMenuDirective));
export { CalendarContextMenuDirective };
