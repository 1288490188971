// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { AccountRoutingModule } from './account-routing.module';
import { AngularSplitModule } from 'angular-split';
import { MailModule } from '../mail/mail.module';
import { NavBarModule } from '../nav-bar/nav-bar.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { PipesModule } from '@modules/pipes/pipes.module';

// Services
import { AccountService } from './services/account.service';

// Components
import { AccountComponent } from './components/account/account.component';
import { AccountSideBarComponent } from './components/account-side-bar/account-side-bar.component';
import { AccountOverviewComponent } from './components/account-overview/account-overview.component';
import { AccountSignatureComponent } from './components/account-signature/account-signature.component';
import { AccountQuickReplyTemplatesComponent } from './components/account-quick-reply-templates/account-quick-reply-templates.component';
import { AccountDelayedSendComponent } from './components/account-delayed-send/account-delayed-send.component';
import { AccountAutorepliesComponent } from './components/account-autoreplies/account-autoreplies.component';
import { AccountMailRulesComponent } from './components/account-mail-rules/account-mail-rules.component';
import { AccountWebPushNotificationsComponent } from './components/account-web-push-notifications/account-web-push-notifications.component';
import { AccountCancelComponent } from './components/account-cancel/account-cancel.component';
import { AccountSyncMailsComponent } from './components/account-sync-mails/account-sync-mails.component';
import { AccountGeneralComponent } from './components/account-general/account-general.component';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    MailModule,
    AccountRoutingModule,
    AngularSplitModule,
    FormsModule,
    NavBarModule,
    FormControlsModule,
    PipesModule,
  ],
  exports: [
    AccountComponent
  ],
  declarations: [
    AccountSideBarComponent, AccountOverviewComponent, AccountComponent, AccountSignatureComponent,
    AccountQuickReplyTemplatesComponent, AccountDelayedSendComponent, AccountAutorepliesComponent,
    AccountMailRulesComponent, AccountWebPushNotificationsComponent, AccountCancelComponent, AccountSyncMailsComponent,
    AccountGeneralComponent,
  ],
  providers: [
    AccountService
  ]
})
export class AccountModule { }
