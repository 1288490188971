// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-setting-button',
  templateUrl: './setting-button.component.html',
  styleUrls: ['./setting-button.component.less']
})
export class SettingButtonComponent implements OnInit {

  // Inputs
  @Input() icon: string;
  @Input() text: string;
  @Input() selected: boolean;

  // Outputs
  @Output() deselect = new EventEmitter<void>();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() { }

  /**
   * Actions
   */

  deselectClicked() {
    this.deselect.emit();
  }

}
