// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Topic } from '@modules/topic/types/topic';
import { Tag } from '@modules/tag/types/tag';
import { Attachment } from '@modules/form-controls/types/attachment';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class NoteBody {
  type: 'text' | 'html';
  value: string;
}

export class Note {
  id?: string;
  title?: string;
  body?: NoteBody[];
  snippet?: string;

  dueDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;

  pinned?: boolean;
  archived?: boolean;
  favorite?: boolean;
  deleted?: boolean;

  topics?: Topic[];
  tags?: Tag[];
  files?: Attachment[];
  linkedInfo?: LinkedInfo[];

  formBuilder = injector.get(FormBuilder);

  constructor(eventObject: any = {}) {
    this.id = eventObject.id;
    this.title = eventObject.title;
    this.body = eventObject.body;
    this.snippet = eventObject.snippet;
    this.dueDate = eventObject.dueDate;
    this.createdAt = eventObject.createdAt;
    this.updatedAt = eventObject.updatedAt;
    this.pinned = eventObject.pinned;
    this.archived = eventObject.archived;
    this.favorite = eventObject.favorite;
    this.deleted = eventObject.deleted;
    this.topics = eventObject.topics;
    this.tags = eventObject.tags;
    this.files = eventObject.files;
    this.linkedInfo = eventObject.linkedInfo;
  }

  static fromFormGroup(form: FormGroup): Note {
    const formValues = form.value;

    return new Note({
      id: formValues.id,
      title: formValues.title,
      body: formValues.body,
      snippet: formValues.snippet,
      dueDate: formValues.dueDate,
      createdAt: formValues.createdAt,
      updatedAt: formValues.updatedAt,
      pinned: formValues.pinned,
      archived: formValues.archived,
      favorite: formValues.favorite,
      deleted: formValues.deleted,
      topics: formValues.topics,
      tags: formValues.tags,
      files: formValues.files,
      linkedInfo: formValues.linkedInfo,
    });
  }

  static fromMailMessage(message?: MailMessage): Note {
    const event = new Note();

    if (message) {
      event.title = message.subject || '';
      event.topics = message.topics || [];
      event.tags = message.tags || [];
      event.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return event;
  }

  static fromEvent(event?: CalendarEvent): Note {
    const note = new Note();

    if (note) {
      note.title = event.title || '';
      note.topics = event.topics || [];
      note.tags = event.tags || [];
      note.linkedInfo = event.id ? [new LinkedInfo('event', note.id)] : [];
    }

    return note;
  }

  static fromProject(project?: Project): Note {
    const note = new Note();

    if (project) {
      note.title = project.title || '';
      note.topics = project.topics || [];
      note.tags = project.tags || [];
      note.linkedInfo = project.id ? [new LinkedInfo('project', project.id)] : [];
    }

    return note;
  }

  static fromTask(task?: Task): Note {
    const note = new Note();

    if (task) {
      note.title = task.title || '';
      note.topics = task.topics || [];
      note.tags = task.tags || [];
      note.linkedInfo = task.id ? [new LinkedInfo('task', task.id)] : [];
    }

    return note;
  }

  static fromNote(originalNote?: Note): Note {
    if (originalNote) {
      const note = new Note({...originalNote});
      note.id = null;
      note.linkedInfo = originalNote.id ? [new LinkedInfo('note', originalNote.id)] : [];
      return note;
    } else {
      return new Note();
    }
  }

  static fromGroup(group?: Group): Note {
    const note = new Note();

    if (group) {
      note.title = group.name || '';
      note.topics = group.topics || [];
      note.tags = group.tags || [];
      note.linkedInfo = group.id ? [new LinkedInfo('group', group.id)] : [];
    }

    return note;
  }

  static fromContact(contact?: Contact): Note {
    const note = new Note();

    if (contact) {
      note.title = contact.name || '';
      note.topics = contact.topics || [];
      note.tags = contact.tags || [];
      note.linkedInfo = contact.id ? [new LinkedInfo('group', contact.id)] : [];
    }

    return note;
  }

  static fromDragData(dragData: DragData): Note {
    let note: Note = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        note = Note.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        note = Note.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        note = Note.fromProject(<Project>dragItem);
        break;
      case 'task':
        note = Note.fromTask(<Task>dragItem);
        break;
      case 'note':
        note = Note.fromNote(<Note>dragItem);
        break;
      case 'group':
        note = Note.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        note = Note.fromContact(<Contact>dragItem);
        break;
    }

    return note;
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      body: [this.body],
      snippet: [this.snippet],
      dueDate: [this.dueDate],
      createdAt: [this.createdAt],
      updatedAt: [this.updatedAt],
      pinned: [this.pinned],
      archived: [this.archived],
      favorite: [this.favorite],
      deleted: [this.deleted],
      topics: [this.topics],
      tags: [this.tags],
      files: [this.files],
      linkedInfo: [this.linkedInfo],
    });
  }

  asPayloadJSON() {
    return {
      title: this.title,
      body: this.body,
      dueDate: this.dueDate ? this.dueDate.getTime() : undefined,
      pinned: this.pinned,
      archived: this.archived,
      favorite: this.favorite,
      deleted: this.deleted,
    };
  }

  getHtmlBody(): string {
    return '';
  }

}
