<app-content-menu>
  <app-content-menu-item
    icon="contacts"
    title="Contacts"
    [badgeTemplate]="badgeTemplate"
    [active]="sidebarFilter === 'contacts'"
    (click)="selectFilter('contacts', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="vip"
    title="VIP"
    [active]="sidebarFilter === 'vip'"
    (click)="selectFilter('vip', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="flagged"
    title="Flagged"
    [active]="sidebarFilter === 'flagged'"
    (click)="selectFilter('flagged', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="upcoming"
    title="Upcoming"
    [active]="sidebarFilter === 'upcoming'"
    (click)="selectFilter('upcoming', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="frequent"
    title="Frequent"
    [active]="sidebarFilter === 'frequent'"
    (click)="selectFilter('frequent', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="recent"
    title="Recent"
    [active]="sidebarFilter === 'recent'"
    (click)="selectFilter('recent', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="other"
    title="Other"
    [active]="sidebarFilter === 'other'"
    (click)="selectFilter('other', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="folder-archive"
    title="Archive"
    [active]="sidebarFilter === 'archived'"
    (click)="selectFilter('archived', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="trash-bucket"
    title="Trash"
    [active]="sidebarFilter === 'deleted'"
    (click)="selectFilter('deleted', $event)"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-item
    icon="groups"
    title="Groups"
    [active]="sidebarFilter === 'groups'"
    (click)="selectFilter('groups', $event)"
  >
    <app-content-menu-item
      *ngFor="let group of groups"
      [title]="group.name"
      [active]="sidebarFilter === group.id"
      (click)="handleGroupClick(group, $event)"
      [appGroupContextMenu]="group"
    ></app-content-menu-item>
  </app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-pellets
    collapsedStateKey="contactsSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <app-content-menu-tags
    collapsedStateKey="contactsSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>

<ng-template #badgeTemplate>
  <div class="badge-container">
    <div>{{ contactsCount }}</div>
  </div>
</ng-template>
