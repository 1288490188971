// Common
import { Component, Input, OnInit } from '@angular/core';

// RxJS
import { tap, takeUntil } from 'rxjs/operators';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { NotesService } from '@modules/notes/services/notes.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Types
import { Note } from '@modules/notes/types/note';
import { MailMessage } from '@modules/mail/types/mail-message';

@Component({
  selector: 'app-note-context-menu',
  templateUrl: './note-context-menu.component.html',
  styleUrls: ['./note-context-menu.component.less']
})
export class NoteContextMenuComponent extends ContextMenuComponent implements OnInit {

  // Inputs
  @Input() note: Note;
  @Input() selectedNotes: Note[] = [];

  // Private
  private includesInSelected = false;

  /**
   * Constructor
   */

  constructor(
    protected contextMenuService: ContextMenuService,
    protected notesService: NotesService,
    protected modalService: ModalService
  ) {
    super(contextMenuService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  favoriteNote() {
    this.note.favorite = !this.note.favorite;
    this.notesService.updateNote(this.note);
  }

  pinNote() {
    this.note.pinned = !this.note.pinned;
    this.notesService.updateNote(this.note);
  }

  snoozeNote() {
    // TODO: Add handler
  }

  createMail() {
    this.modalService.compose(MailMessage.fromNote(this.note));
  }

  followUpNote() {
    // TODO: Add handler
  }

  archiveNote() {
    this.note.archived = !this.note.archived;
    this.notesService.archiveNotes({notesIds: [this.note.id]}, this.note.archived);
  }

  restoreFromTrash() {
    this.note.deleted = false;
    this.notesService.restoreNotes({notesIds: [this.note.id]});
  }

  deleteNote() {
    if (this.note.deleted) {
      this.modalService.confirmationModal(
        'Would you like to delete note(s) from trash?',
        'Delete Note(s)',
        'Yes',
        'No',
        (success: boolean) => {
          if (success) {
            this.notesService.deleteNotes({notesIds: [this.note.id]});
          }
        });
    } else {
      this.notesService.deleteNotes({notesIds: [this.note.id]});
    }
  }

  closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

}
