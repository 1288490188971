/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../form-controls/components/color-picker/color-picker.component.ngfactory";
import * as i3 from "../../../form-controls/components/color-picker/color-picker.component";
import * as i4 from "../calendar-form/calendar-form.component.ngfactory";
import * as i5 from "../calendar-form/calendar-form.component";
import * as i6 from "../confirmation-form/confirmation-form.component.ngfactory";
import * as i7 from "../confirmation-form/confirmation-form.component";
import * as i8 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i9 from "ngx-contextmenu";
import * as i10 from "./calendar-context-menu.component";
import * as i11 from "../../services/calendar.service";
import * as i12 from "../../../settings/services/state.service";
import * as i13 from "../../../popover/services/popover.service";
var styles_CalendarContextMenuComponent = [i0.styles];
var RenderType_CalendarContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarContextMenuComponent, data: {} });
export { RenderType_CalendarContextMenuComponent as RenderType_CalendarContextMenuComponent };
function View_CalendarContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Display This Only"]))], null, null); }
function View_CalendarContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["calendarPopoverOrigin", 1]], null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCalendarForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Rename Calendar "]))], null, null); }
function View_CalendarContextMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Copy Link To Calendar"]))], null, null); }
function View_CalendarContextMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Delete Calendar"]))], null, null); }
function View_CalendarContextMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Settings"]))], null, null); }
function View_CalendarContextMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CalendarContextMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, i2.View_ColorPickerComponent_0, i2.RenderType_ColorPickerComponent)), i1.ɵdid(1, 245760, null, 0, i3.ColorPickerComponent, [], { inputFormControl: [0, "inputFormControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.calendarForm == null) ? null : _co.calendarForm.controls["color"]); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CalendarContextMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-form", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCalendarPopoverClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CalendarFormComponent_0, i4.RenderType_CalendarFormComponent)), i1.ɵdid(1, 638976, null, 0, i5.CalendarFormComponent, [], { calendar: [0, "calendar"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendar; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CalendarContextMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-form", [["cancelButtonLabel", "Cancel"], ["submitButtonLabel", "Delete"], ["text", "Are you sure you want to delete this calendar?"], ["title", "Delete Calendar"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCalendarPopoverClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ConfirmationFormComponent_0, i6.RenderType_ConfirmationFormComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmationFormComponent, [], { title: [0, "title"], text: [1, "text"], cancelButtonLabel: [2, "cancelButtonLabel"], submitButtonLabel: [3, "submitButtonLabel"], action: [4, "action"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Delete Calendar"; var currVal_1 = "Are you sure you want to delete this calendar?"; var currVal_2 = "Cancel"; var currVal_3 = "Delete"; var currVal_4 = _co.deleteCalendar; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CalendarContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), i1.ɵqud(671088640, 2, { calendarPopoverOrigin: 0 }), i1.ɵqud(402653184, 3, { editCalendarForm: 0 }), i1.ɵqud(402653184, 4, { deleteCalendarForm: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 16, "context-menu", [], null, null, null, i8.View_ContextMenuComponent_0, i8.RenderType_ContextMenuComponent)), i1.ɵdid(5, 180224, [[1, 4], ["contextMenu", 4]], 1, i9.ContextMenuComponent, [i9.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i9.ɵc]], null, null), i1.ɵqud(603979776, 5, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.setSelectedCalendars([_co.calendar.id]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarContextMenuComponent_1)), i1.ɵdid(8, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarContextMenuComponent_2)), i1.ɵdid(10, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarContextMenuComponent_3)), i1.ɵdid(12, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openDeleteForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarContextMenuComponent_4)), i1.ɵdid(14, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarContextMenuComponent_5)), i1.ɵdid(16, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarContextMenuComponent_6)), i1.ɵdid(18, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarContextMenuComponent_7)), i1.ɵdid(20, 16384, [[5, 4]], 0, i9.ɵb, [i1.TemplateRef, i1.ElementRef], { passive: [0, "passive"] }, null), (_l()(), i1.ɵand(0, [[3, 2], ["editCalendarForm", 2]], null, 0, null, View_CalendarContextMenuComponent_8)), (_l()(), i1.ɵand(0, [[4, 2], ["deleteCalendarForm", 2]], null, 0, null, View_CalendarContextMenuComponent_9))], function (_ck, _v) { var currVal_0 = false; _ck(_v, 12, 0, currVal_0); var currVal_1 = false; _ck(_v, 16, 0, currVal_1); var currVal_2 = "true"; _ck(_v, 18, 0, currVal_2); var currVal_3 = true; _ck(_v, 20, 0, currVal_3); }, null); }
export function View_CalendarContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-context-menu", [], null, null, null, View_CalendarContextMenuComponent_0, RenderType_CalendarContextMenuComponent)), i1.ɵdid(1, 4964352, null, 0, i10.CalendarContextMenuComponent, [i9.ContextMenuService, i11.CalendarService, i12.StateService, i13.PopoverService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarContextMenuComponentNgFactory = i1.ɵccf("app-calendar-context-menu", i10.CalendarContextMenuComponent, View_CalendarContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem", calendar: "calendar", originRef: "originRef", popoverClose: "popoverClose" }, { close: "close" }, []);
export { CalendarContextMenuComponentNgFactory as CalendarContextMenuComponentNgFactory };
