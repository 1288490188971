<div class="modal-header editor-modal-header">
  <div class="modal-title" *ngIf="!minimized">
    Compose message
  </div>
  <div
    class="modal-title"
    *ngIf="minimized"
    popover
    [popoverContent]="messagePopover"
    [popoverArrow]="false"
    popoverTrigger="hover"
    popoverPlacement="top"
  >
    <mat-icon svgIcon="compose" class="modal-title-icon"></mat-icon>
    <div *ngIf="message?.to?.length" class="title-recipient">To: {{ message?.to[0]?.name || message?.to[0]?.email }}</div>
    <div *ngIf="message?.to?.length > 1" class="title-count">{{ '+' + (message?.to?.length -1) }}</div>
    <div *ngIf="!message?.to?.length">Compose message</div>
  </div>
  <button *ngIf="availableExpand" type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button *ngIf="availableExpand" type="button" class="button-modal" aria-label="In new window" (click)="expand()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <app-message-editor
    [message]="message"
    (messageChange)="messageChange($event)"
    [sourceMessage]="sourceMessage"
    [action]="action"
    (sent)="close()"
    (draftSaved)="close()">
  </app-message-editor>
</div>

<!-- Popover -->
<ng-template #messagePopover>
  <div class="message-popover">
    <div class="message-popover__info">
      <div class="message-popover__title">To:</div>
      <div class="message-popover__value">{{ message?.to | userNames }}</div>
    </div>
    <div class="message-popover__info" *ngIf="message?.subject && message?.subject.length">
      <div class="message-popover__title">Subj:</div>
      <div class="message-popover__value">{{ message?.subject }}</div>
    </div>
  </div>
</ng-template>
