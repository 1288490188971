// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { EventsStateService } from '@modules/events/services/events-state.service';
import { LayoutStateService } from '@modules/pages/services/layout-state.service';
import { StateService } from '@modules/settings/services/state.service';

// Types
import { EventsMainView } from '@modules/events/types/events-main-view';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarType } from '@modules/events/types/calendar-type';

// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-events-main-view',
  templateUrl: './events-main-view.component.html',
  styleUrls: ['./events-main-view.component.less'],
})
export class EventMainViewComponent implements OnInit, OnDestroy {

  // Public
  public view: EventsMainView;
  public event: CalendarEvent;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private eventsStateService: EventsStateService,
    private layoutStateService: LayoutStateService,
    private stateService: StateService
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.stateService.getSelectedCalendarType(),
      this.eventsStateService.getMainView(),
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([calendarType, view]) => {
        this.view = view;
        this.layoutStateService.setLinkedInfoToolbarPresence(
          view === 'event-form' ||
          calendarType === 'agenda'
        );
      });

    this.eventsStateService.getMainViewEvent()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((event: CalendarEvent) => {
        this.event = event;
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.layoutStateService.setLinkedInfoToolbarPresence(false);
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.eventsStateService.setMainView('calendar');
  }
}
