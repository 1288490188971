<div class="event-form">

  <div class="event-form-actions" *ngIf="event?.id">
    <mat-icon
      svgIcon="pin"
      (click)="pin()"
      class="event-form-actions-button"
      [class.active]="event?.pinned"
      [matTooltip]="event?.pinned ? 'Unpin' : 'Pin'"
    ></mat-icon>

    <mat-icon
      svgIcon="snooze"
      class="event-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="mail"
      (click)="mail()"
      class="event-form-actions-button"
      matTooltip="Mail"
    ></mat-icon>

    <mat-icon
      svgIcon="archive"
      (click)="archive()"
      class="event-form-actions-button"
      [class.active]="event?.archived"
      [matTooltip]="event?.archived ? 'Remove From Archive' : 'Add To Archive'"
    ></mat-icon>

    <mat-icon
      *ngIf="!event?.readOnly"
      svgIcon="trash-bucket"
      (click)="delete()"
      class="event-form-actions-button"
      matTooltip="Delete"
    ></mat-icon>

    <mat-icon
      svgIcon="more-actions"
      class="event-form-actions-button"
      matTooltip="More Options"
      [appEventContextMenu]="event"
      contextMenuTriggerEvent="click"
    ></mat-icon>
  </div>

  <div class="event-form-related" *ngIf="event?.id && appearance === 'headerFixedBig'">
    <div class="event-form-related-buttons">
      <div class="event-form-related-button" (click)="openAttachmentsDynamicPanel()">
        <div class="event-form-related-button-inner">
          <mat-icon class="event-form-related-button-inner-icon" svgIcon="attachment-small"></mat-icon>
        </div>
      </div>
      <div class="event-form-related-button" (click)="openLinkedInfoDynamicPanel()">
        <div class="event-form-related-button-inner">
          <mat-icon class="event-form-related-button-inner-icon" svgIcon="link-thin"></mat-icon>
        </div>
      </div>
    </div>

    <mat-icon
      appDraggable
      [appDraggableData]="{data: [event], type: 'event'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerAdjustFit]="true"
      [appDraggableContainerStyles]="{'min-width': '300px'}"
      class="event-form-related-drag-icon"
      svgIcon="drag-icon-small"
    ></mat-icon>
  </div>

  <div class="event-form-fixed-header" *ngIf="appearance === 'headerFixedBig'" [class.shadow]="displayFixedBigHeaderShadow">
    <div class="event-form-heading event-form-fixed-header-title" *ngIf="!event?.id">Calendar / {{ eventForm.controls['title'].value }}</div>

    <div class="event-form-fixed-header-columns" [class.event-form-fixed-header-columns-editable]="event?.id">
      <div class="event-form-fixed-header-columns-input">
        <app-event-title-input
          placeholder="Title"
          [inputFormControl]="eventForm.controls['title']"
          [bundledInputProviderGroup]="bundledGroup"
          [bundledInputProviderKeys]="['startDate', 'endDate', 'startTime', 'endTime']"
        ></app-event-title-input>
      </div>
      <div class="event-form-fixed-header-columns-buttons">
        <app-button class="event-form-fixed-header-columns-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
        <app-button class="event-form-fixed-header-columns-buttons-item" (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>
  </div>

  <div class="event-form-fixed-small-header" *ngIf="appearance === 'headerFixedSmall'">
    <div class="event-form-fixed-small-header-title">
      {{ event?.id ? event.title : 'Add new event' }}
    </div>
    <div class="event-form-fixed-small-header-buttons">
      <app-button class="event-form-fixed-small-header-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
      <app-button class="event-form-fixed-small-header-buttons-item" (click)="handleCancel()">Cancel</app-button>
    </div>
  </div>

  <form
    class="event-form-main"
    [formGroup]="eventForm"
    (ngSubmit)="handleSubmit()"
  >
    <app-scroll (top)="displayFixedBigHeaderShadow = !$event">
      <div class="event-form-main-inner">

        <div class="event-form-horizontal-group" *ngIf="appearance === 'noHeader' || appearance === 'headerFixedSmall'">
          <div class="event-form-horizontal-group-full-width-item">
            <app-event-title-input placeholder="Title" [inputFormControl]="eventForm.controls['title']"></app-event-title-input>
          </div>
        </div>

        <!-- Tags -->
        <app-tag-list
          class="event__tags"
          [event]="event"
          [tags]="eventForm.controls['tags'].value"
          (tagsChange)="eventForm.patchValue({ tags: $event })"
          appScrollAnchor
        ></app-tag-list>

        <!-- Pellets -->
        <app-form-collapseable-group
          title="Pellets"
          icon="pellets-small"
          class="event-form-topics-section"
          appScrollAnchor
        >
          <app-event-topics
            [eventId]="event?.id"
            [topicsControl]="eventForm.controls['topics']"
          ></app-event-topics>
        </app-form-collapseable-group>

        <app-form-collapseable-group
          title="Time Details"
          icon="time-round"
          class="event-form-time-section"
          appScrollAnchor
        >
          <div class="event-form-horizontal-group">
            <div class="event-form-horizontal-group-item event-form-horizontal-group-item-closer">
              <app-date-picker
                placeholder="Start Date"
                [inputFormControl]="eventForm.controls['startDate']"
                width="108px"
                [bundledInputConsumerGroup]="bundledGroup"
                [bundledInputConsumerKeys]="['startDate']"
                bundledInputAppearance="start"
              ></app-date-picker>
            </div>
            <div class="event-form-horizontal-group-item" *ngIf="!eventForm?.value?.reminder">
              <app-date-picker
                placeholder="End Date"
                [inputFormControl]="eventForm.controls['endDate']"
                [bundledInputConsumerGroup]="bundledGroup"
                [bundledInputConsumerKeys]="['endDate']"
                bundledInputAppearance="end"
                width="108px"
              ></app-date-picker>
            </div>
            <div class="event-form-horizontal-group event-form-horizontal-group-inner">
              <div
                class="event-form-horizontal-group-item event-form-horizontal-group-item-closer"
                *ngIf="!eventForm?.value?.allDay || eventForm?.value?.reminder && !eventForm?.value?.allDay"
            >
                <app-time-picker
                  placeholder="Start Time"
                  [inputFormControl]="eventForm.controls['startTime']"
                  width="80px"
                  [bundledInputConsumerGroup]="bundledGroup"
                  [bundledInputConsumerKeys]="['startTime']"
                  bundledInputAppearance="start"
                ></app-time-picker>
              </div>
              <div class="event-form-horizontal-group-item" *ngIf="!eventForm?.value?.allDay && !eventForm?.value?.reminder">
                <app-time-picker
                  placeholder="End Time"
                  [inputFormControl]="eventForm.controls['endTime']"
                  width="80px"
                  [bundledInputConsumerGroup]="bundledGroup"
                  [bundledInputConsumerKeys]="['endTime']"
                  bundledInputAppearance="end"
                  [optionsFromTime]="eventForm?.value?.startTime"
                ></app-time-picker>
              </div>
            </div>
          </div>
          <div class="event-form-horizontal-group">
            <div class="event-form-horizontal-group-item">
              <app-schedule-repeater-dropdown
                [inputFormControl]="eventForm.controls['repeatType']"
                [customRepeatFormControl]="eventForm.controls['customRepeat']"
                [selectedDate]="eventForm.value.startDate"
              ></app-schedule-repeater-dropdown>
            </div>
            <div class="event-form-horizontal-group-item">
              <app-checkbox [title]="'All day'" [inputFormControl]="eventForm.controls['allDay']"></app-checkbox>
            </div>
            <div class="event-form-horizontal-group-item">
              <app-checkbox [title]="'Reminder'" [inputFormControl]="eventForm.controls['reminder']"></app-checkbox>
            </div>
          </div>

          <div class="event-form-horizontal-group">
            <div
              [class.event-form-horizontal-group-item]="appearance !== 'headerFixedSmall'"
              [class.event-form-horizontal-group-full-width-item]="appearance === 'headerFixedSmall'"
            >
              <app-time-zone-select
                placeholder="Time zone"
                [inputFormControl]="eventForm.controls['timeZone']"
                [width]="appearance === 'headerFixedSmall' ? null : '265px'"
              ></app-time-zone-select>
            </div>
          </div>
        </app-form-collapseable-group>

        <app-form-collapseable-group
          title="Event Details"
          icon="info"
          appScrollAnchor
        >
          <div class="event-form-horizontal-group">
            <div
              [class.event-form-horizontal-group-item]="appearance !== 'headerFixedSmall'"
              [class.event-form-horizontal-group-full-width-item]="appearance === 'headerFixedSmall'"
            >
              <app-input
                placeholder="Location"
                [inputFormControl]="eventForm.get('location').get('address')"
                [width]="appearance === 'headerFixedSmall' ? null : '383px'"
              ></app-input>
            </div>
          </div>

          <div class="event-form-horizontal-group" [@collapseMotion]="eventForm.get('notifications')?.controls?.length">
            <div class="event-form-horizontal-group-item">
              <app-notification-dropdown [inputFormArray]="eventForm.get('notifications')"></app-notification-dropdown>
            </div>
          </div>

          <div class="event-form-horizontal-group">
            <div class="event-form-horizontal-group-item">
              <app-dropdown-select [placeholder]="'Availability'" [inputFormControl]="eventForm.controls['availability']" [options]="[{title: 'Busy', value: ''}]" ></app-dropdown-select>
            </div>
            <div class="event-form-horizontal-group-item">
              <app-dropdown-select [placeholder]="'Visibility'" [inputFormControl]="eventForm.controls['visibility']" [options]="[{title: 'Default visibility', value: ''}]" ></app-dropdown-select>
            </div>
            <div class="event-form-horizontal-group-item">
              <app-dropdown-select [placeholder]="'Conferencing'" [inputFormControl]="eventForm.controls['conferencing']" [options]="conferencingOptions"></app-dropdown-select>
            </div>
          </div>

          <div class="event-form-horizontal-group">
            <div class="event-form-horizontal-group-item">
              <app-dropdown-select
                placeholder="Calendar"
                [inputFormControl]="eventForm.controls['calendarId']"
                [options]="calendarOptions"
              ></app-dropdown-select>
            </div>
            <div class="event-form-horizontal-group-item">
              <app-color-picker-button
                [inputFormControl]="eventForm.controls['color']"
                [defaultColor]="calendar?.color"
              ></app-color-picker-button>
            </div>
          </div>

          <div class="event-form-horizontal-group" [@collapseMotion]="eventForm.controls['participants']?.value">
            <div class="event-form-horizontal-group-full-width-item">
              <app-participants-input [inputFormControl]="eventForm.controls['participants']"></app-participants-input>
            </div>
          </div>

          <div class="event-form-horizontal-group" [@collapseMotion]="displayDescription">
            <div class="event-form-horizontal-group-full-width-item" >
              <div *ngIf="!displayDescription">
                <span class="event-form-actions-text-button" (click)="displayDescription = true"> + Add Description </span>
              </div>
              <div *ngIf="displayDescription">
                <app-textarea placeholder="Description" [inputFormControl]="eventForm.controls['description']" width="100%" rows="5"></app-textarea>
              </div>
            </div>
          </div>
        </app-form-collapseable-group>

        <!-- Attachments -->
        <div class="event-form-horizontal-group">
          <div class="event-form-horizontal-group-full-width-item">
            <app-attachments-list
              [attachmentsFormControl]="eventForm.controls['files']"
              (extendAction)="openAttachmentsDynamicPanel()"
              appScrollAnchor
            ></app-attachments-list>
          </div>
        </div>


        <!-- Linked Information -->
        <div class="event-form-horizontal-group">
          <div class="event-form-horizontal-group-full-width-item">
            <app-linked-info-list
              class="linked-info-list"
              [parentLinkedInfo]="{type: 'event', id: event?.id}"
              [linkedInfo]="event?.linkedInfo"
              (extendAction)="openLinkedInfoDynamicPanel()"
              appScrollAnchor
            ></app-linked-info-list>
          </div>
        </div>
      </div>
    </app-scroll>
  </form>
</div>

<ng-template #dragPlaceholder>
  <app-event [event]="event" [dragEnabled]="false"></app-event>
</ng-template>
