import {
  Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter,
  SimpleChanges, OnChanges, OnDestroy, ChangeDetectorRef, TemplateRef, ElementRef, NgZone, AfterViewInit
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject, interval, fromEvent, merge, timer } from 'rxjs';
import {
  CalendarEvent as AngularCalendarEvent,
  CalendarDateFormatter,
  DateFormatterParams,
  CalendarUtils,
} from 'angular-calendar';
import { endOfDay, startOfDay } from 'date-fns';
import { GetWeekViewArgs, WeekView, GetWeekViewHeaderArgs, WeekDay } from 'calendar-utils';
import { debounceTime, takeUntil } from 'rxjs/operators';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';

// Animations
import { slideAnimation } from '@modules/events/animations/slide-animation';

// Override week time formatter
export class CalendarWeekDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date).toLowerCase();
  }
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return `${date.getDate()}`;
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'EEEE', locale);
  }
}

export class DayCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgs): WeekView {
    args.viewStart = args.viewDate;
    args.viewEnd = args.viewDate;
    return super.getWeekView(args);
  }
  getWeekViewHeader(args: GetWeekViewHeaderArgs): WeekDay[] {
    args.viewStart = startOfDay(args.viewDate);
    args.viewEnd = endOfDay(args.viewDate);
    return super.getWeekViewHeader(args);
  }
}

@Component({
  selector: 'full-calendar-day',
  templateUrl: './full-calendar-day.component.html',
  styleUrls: ['./full-calendar-day.component.less'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarWeekDateFormatter
    },
    {
      provide: CalendarUtils,
      useClass: DayCalendarUtils
    }
  ],
  animations: [slideAnimation]
})

export class FullCalendarDayComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  // Inputs
  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() highlightedDay: Date;
  @Input() events: AngularCalendarEvent[];
  @Input() hideDateHeader = false;
  @Input() eventTemplate: TemplateRef<any>;

  // Outputs
  @Output() dateClicked: EventEmitter<CalendarCellClickEvent> = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() eventDropped: EventEmitter<CalendarDropEvent> = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  // Public
  public displayDate: Date;
  public refreshCalendar: Subject<any> = new Subject();
  public timeZoneName = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  public currentTimeLineOffset = 0;
  public currentTime: Date = new Date();
  public hourSegmentHeight = 48;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.displayDate = this.viewDate;

    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(200),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(() => this.calculateSegmentHeight());
    });

    // Update current time line
    merge(
      interval(15000), // update every 15 sec
      this.refreshCalendar.asObservable()
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.updateCurrentTimeLine();
      });
    this.updateCurrentTimeLine();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate) {
      if (changes.viewDate.firstChange || this.dayDiffers(changes.viewDate.previousValue, changes.viewDate.currentValue)) {
        this.displayDate = this.viewDate;
        this.refreshView();
      }
      if (changes.selectedDate) {
        this.refreshView();
      }
    }
  }

  ngAfterViewInit(): void {
    timer(0).subscribe( () => this.calculateSegmentHeight());
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Helpers
   */

  updateCurrentTimeLine() {

    // Calc offset for current time line
    this.currentTime = new Date();
    const hoursOffset = this.currentTime.getHours() * this.hourSegmentHeight;
    const minutesOffset = (this.currentTime.getMinutes() / 60) * this.hourSegmentHeight;
    this.currentTimeLineOffset = Math.round(hoursOffset + minutesOffset - 1);

    this.changeDetector.markForCheck();
  }

  /**
   * Action
   */

  refreshView() {
    this.refreshCalendar.next();
  }

  clickHourSegment(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.dateClicked.emit({date, originRef: new ElementRef(origin), event});
  }

  dblClickHourSegment(date: Date) {
    this.dateDblClicked.emit(date);
  }

  handleDrop(dragData: DragData, newStart: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart,
      newEnd: new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate(), newStart.getHours() + 1, newStart.getMinutes()),
      originRef: new ElementRef(origin)
    });
  }

  private dayDiffers(date1: Date, date2: Date) {
    return date1.getDate() !== date2.getDate() || date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
  }

  private calculateSegmentHeight() {
    // Set the height of hour segment between 40px and 60px according to available space on the screen
    this.hourSegmentHeight = Math.min(Math.max(40, Math.round((this.element.nativeElement.getBoundingClientRect().height) / 11 )), 60);
    this.changeDetector.markForCheck();
    this.refreshCalendar.next();
  }

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }
}
