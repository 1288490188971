<div class="event-form">
  <form [formGroup]="eventForm" (ngSubmit)="handleSubmit()" (keydown.enter)="$event.preventDefault()" novalidate>

    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-full-width-item">
        <app-event-title-input
          placeholder="Title"
          [inputFormControl]="eventForm.controls['title']"
          [bundledInputProviderGroup]="bundledGroup"
          [bundledInputProviderKeys]="['start_date', 'end_date', 'start_time', 'end_time']"
        ></app-event-title-input>
      </div>
    </div>

    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-item event-form-horizontal-group-item-closer">
        <app-date-picker
          placeholder="Start Date"
          [inputFormControl]="eventForm.controls['startDate']"
          width="108px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['startDate']"
          bundledInputAppearance="start"
        ></app-date-picker>
      </div>
      <div class="event-form-horizontal-group-item" *ngIf="!eventForm?.value?.reminder">
        <app-date-picker
          placeholder="End Date"
          [inputFormControl]="eventForm.controls['endDate']"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['endDate']"
          bundledInputAppearance="end"
          width="108px"
        ></app-date-picker>
      </div>
      <div
        class="event-form-horizontal-group-item event-form-horizontal-group-item-closer"
        *ngIf="!eventForm?.value?.allDay || eventForm?.value?.reminder && !eventForm?.value?.allDay"
      >
        <app-time-picker
          placeholder="Start Time"
          [inputFormControl]="eventForm.controls['startTime']"
          width="80px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['startTime']"
          bundledInputAppearance="start"
        ></app-time-picker>
      </div>
      <div class="event-form-horizontal-group-item" *ngIf="!eventForm?.value?.allDay && !eventForm?.value?.reminder">
        <app-time-picker
          placeholder="End Time"
          [inputFormControl]="eventForm.controls['endTime']"
          width="80px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['endTime']"
          bundledInputAppearance="end"
          [optionsFromTime]="eventForm?.value?.startTime"
        ></app-time-picker>
      </div>
    </div>
    <div class="event-form-horizontal-group">
      <div class="event-form-horizontal-group-item">
        <app-checkbox title="All day" [inputFormControl]="eventForm.controls['allDay']"></app-checkbox>
      </div>
      <div class="event-form-horizontal-group-item">
        <app-checkbox title="Reminder" [inputFormControl]="eventForm.controls['reminder']"></app-checkbox>
      </div>
    </div>
    <div class="event-form-horizontal-group" >
      <div class="event-form-horizontal-group-item">
        <app-schedule-repeater-dropdown
          [inputFormControl]="eventForm.controls['repeatType']"
          [customRepeatFormControl]="eventForm.controls['customRepeat']"
          [selectedDate]="eventForm.value.startDate"
        ></app-schedule-repeater-dropdown>
      </div>
      <div class="event-form-horizontal-group-item">
        <app-dropdown-select
          placeholder="Calendar"
          [inputFormControl]="eventForm.controls['calendarId']"
          [options]="calendarOptions"
          maxWidth="200px"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="event-form-actions">
      <div class="event-form-actions-more" (click)="moreOptions()">More options</div>
      <div class="event-form-actions-buttons">
        <app-button
          class="event-form-actions-buttons-item"
          (click)="handleCancel()"
        >
          Cancel
        </app-button>
        <app-button
          class="event-form-actions-buttons-item"
          (click)="handleCopy()"
          *ngIf="eventForm?.value?.id"
        >
          Copy
        </app-button>
        <app-button
          class="event-form-actions-buttons-item"
          appearance="blue"
          (click)="handleSubmit()"
          [disabled]="eventForm.invalid || saveInProgress"
        >
          {{ eventForm?.value?.id ? 'Reschedule' : 'Save' }}
        </app-button>
      </div>
    </div>
  </form>
</div>
