import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesNow'
})
export class IncludesNowPipe implements PipeTransform {

  transform(firstDate: Date, secondDate: Date): boolean {
    const now = new Date();
    return secondDate && firstDate && now > firstDate && now < secondDate;
  }
}
