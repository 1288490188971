<div class="repeater-dialog-container">
  <div mat-dialog-title>Custom Recurrence</div>

  <form [formGroup]="data.customRepeatForm" (ngSubmit)="submit()" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>
      <div class="event-form-horizontal-group">
        <div class="event-form-horizontal-group-item">Repeat every</div>
        <div class="event-form-horizontal-group-item">
          <app-input [inputFormControl]="data.customRepeatForm.controls['repeatTimes']" width="35px"></app-input>
        </div>
        <div class="event-form-horizontal-group-item">
          <app-dropdown-select placeholder="Time Period"
                               [inputFormControl]="data.customRepeatForm.controls['repeatPeriod']" [options]="data.timePeriodOptions"
                               width="80px"></app-dropdown-select>
        </div>
      </div>

      <div class="event-form-horizontal-group">
        <div class="event-form-horizontal-group-item">Repeat on</div>
        <div class="event-form-horizontal-group-item round-option-group">
          <div *ngFor="let day of days" class="round-option" [ngClass]="{'active': daySelected(day.value)}"
               (click)="dayClick(day.value)">
            {{ day.title }}
          </div>
        </div>
      </div>

      <div class="event-form-horizontal-group">
        <div class="event-form-horizontal-group-item">Ends</div>
      </div>

      <mat-radio-group [formControl]="data.customRepeatForm.controls['endType']">
        <div class="event-form-horizontal-group">
          <div class="event-form-horizontal-group-item">
            <mat-radio-button value="never">Never</mat-radio-button>
          </div>
        </div>
        <div class="event-form-horizontal-group">
          <div class="event-form-horizontal-group-item">
            <mat-radio-button value="on">On</mat-radio-button>
          </div>
          <div class="event-form-horizontal-group-item">
            <app-date-picker 
              placeholder="Date" 
              width="108px"
              [inputFormControl]="data.customRepeatForm.controls['endDate']"
              [disabled]="data.customRepeatForm.controls['endType'].value !== 'on'"
            ></app-date-picker>
          </div>
        </div>
        <div class="event-form-horizontal-group">
          <div class="event-form-horizontal-group-item">
            <mat-radio-button value="after">After</mat-radio-button>
          </div>
          <div class="event-form-horizontal-group-item">
            <app-input [inputFormControl]="data.customRepeatForm.controls['endAfterOccurrenceTimes']"
                       width="35px"></app-input>
          </div>
          <div class="event-form-horizontal-group-item regular">occurrences</div>
        </div>
      </mat-radio-group>
    </div>
    <div mat-dialog-actions>
      <div class="event-form-columns-buttons">
        <app-button class="event-form-columns-buttons-item" (click)="cancel()">Cancel</app-button>
        <app-button class="event-form-columns-buttons-item" appearance="blue" type="submit">Save</app-button>
      </div>
    </div>
  </form>
</div>
