// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RxJS
import { Subject } from 'rxjs';

// Services
import { NotesService } from '@modules/notes/services/notes.service';

// Types
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-note-quick-form',
  templateUrl: './note-quick-form.component.html',
  styleUrls: ['./note-quick-form.component.less'],
})
export class NoteQuickFormComponent implements OnChanges {

  // Inputs
  @Input() bundledGroup: string;
  @Input() note: Note = new Note();

  // Outputs
  @Output() more: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public noteForm: FormGroup;
  public saveInProgress = false;

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private notesService: NotesService,
  ) {
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('note' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (!this.noteForm.valid) {
      return;
    }

    this.saveInProgress = true;
    const note = Note.fromFormGroup(this.noteForm);

    this.notesService.createNote(note)
      .subscribe(() => {
        this.saveInProgress = false;
        this.close.emit();
        this.reset();
      });
  }

  reset() {
    if (!this.note) { return; }

    this.noteForm = this.note.asFormGroup();
  }

  handleMoreOptions() {
    this.more.emit(Note.fromFormGroup(this.noteForm));
    this.close.emit();
  }
}
