<div class="modal-header">
  <div class="modal-title text-center">Create an event</div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="close__icon" aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body calendar-event-modal-body">
  <app-calendar-create-event
    class="calendar-panel--create-event"
    [selectedDate]="selectedDate"
    [(selectedEvent)]="event"
    (eventCreated)="updatedEvent()"
    (cancelEventChange)="close()">
  </app-calendar-create-event>
</div>
