import * as tslib_1 from "tslib";
import { Toast } from 'ngx-toastr';
var AsyncTaskToastComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AsyncTaskToastComponent, _super);
    function AsyncTaskToastComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Methods to init data from service
     */
    AsyncTaskToastComponent.prototype.setActions = function (actions) {
        this.actions = actions;
    };
    AsyncTaskToastComponent.prototype.setIcon = function (icon) {
        this.icon = icon;
    };
    /**
     * Actions
     */
    AsyncTaskToastComponent.prototype.action = function (action, event) {
        event.stopPropagation();
        action.handler();
        this.toastPackage.triggerAction();
        this.remove();
    };
    return AsyncTaskToastComponent;
}(Toast));
export { AsyncTaskToastComponent };
