// Common
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

// Types
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-notes-main-view',
  templateUrl: './notes-main-view.component.html',
  styleUrls: ['./notes-main-view.component.less']
})
export class NotesMainViewComponent implements OnInit, OnChanges {

  // Inputs
  @Input() selectedNotes: Note[];

  // public
  public note: Note;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedNotes' in changes) {
      this.note = this.selectedNotes.length > 0 ? this.selectedNotes[0] : null;
    }
  }

  /**
   * Actions
   */

  handleCancel() {
    this.note = null;
  }

}
