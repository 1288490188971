import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sameDay'
})
export class SameDayPipe implements PipeTransform {

  transform(day1: Date, day2: Date): boolean {
    return day1 &&
      day2 &&
      day1.getFullYear() === day2.getFullYear() &&
      day1.getMonth() === day2.getMonth() &&
      day1.getDate() === day2.getDate();
  }
}
