<div class="related-hyperlinks__wrapper">
  <div
    class="hyperlink__wrapper"
    *ngFor="let hyperlink of hyperlinks"
    (click)="openLink(hyperlink)"
    [contextMenu]="hyperlinkContextMenu"
    [contextMenuSubject]="hyperlink"
  >
    <div class="hyperlink">
      <div class="icon-mail">
        <mat-icon svgIcon="button-link" class="hyperlink-icon"></mat-icon>
      </div>
      <div class="hyperlink__info">
        <div class="hyperlink__link">{{hyperlink.url}}</div>
        <div *ngIf="hyperlink.from || hyperlink.subject" class="hyperlink__sender">{{hyperlink.from?.name || hyperlink.from?.email || 'Unknown'}}: {{hyperlink.subject || '(no subject)'}}</div>
      </div>
      <mat-icon svgIcon="copy" class="copy-icon" matTooltip="Copy" (click)="copyLink(hyperlink, $event)"></mat-icon>
    </div>
  </div>
  <div *ngIf="!hyperlinks?.length && !loading && !error" class="placeholder">
    <img class="logo" src="assets/placeholders/placeholder-links.svg"/>
    <div>There are no links.</div>
  </div>
  <div class="placeholder" *ngIf="error">Error occured while loading. <span (click)="onLoadMore()" class="error">Click here to try again.</span></div>
  <div class="load-more" (click)="onLoadMore()" *ngIf="totalCount && totalCount > hyperlinks?.length && !loading">Load more</div>
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
</div>

<!-- Context Menu -->
<context-menu #hyperlinkContextMenu>
  <ng-template contextMenuItem (execute)="openLink($event.item)">
    Open Link
  </ng-template>
  <ng-template contextMenuItem (execute)="forwardLink($event.item)">
    Forward Link
  </ng-template>
  <ng-template contextMenuItem (execute)="copyLink($event.item)">
    Copy
  </ng-template>
</context-menu>
<!-- End: Context Menu -->
