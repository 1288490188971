// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';
import { Hyperlink } from '../types/hyperlink';
import { MailMessage } from '../types/mail-message';
import { Recipient } from '../types/recipient';
import { TopicsList } from '@modules/topic/types/topic';

// Env
import { environment } from '@environment';

export class KnowledgeInfoFilters {
  messageId?: string;
  baseTopics?: string[];
  folder?: string;
  contact?: Recipient;
  contacts?: string[];
  topics?: string[];
  keywords?: string[];
  relatedTopics?: string[];
  relatedContacts?: Recipient[];
  ignoreContact?: Recipient;
  fromTime?: Date;
  toTime?: Date;
  topicsFirstSymbol?: string;
  firstSymbol?: string;
  onlyRelatedMessageTopics?: boolean;
}

@Injectable()
export class KnowledgeGraphService {

  constructor(private http: HttpClient) { }

  private formatFilters(
    filters: KnowledgeInfoFilters,
    limit?: number,
    offset?: number,
    order?: string
  ): { [param: string]: string | string[]; } {

    const params = {};
    if (filters.messageId) { params['related_message_id'] = filters.messageId; }
    if (filters.baseTopics) { params['related_message_topics[]'] = filters.baseTopics; }
    if (filters.fromTime) { params['from_time'] = filters.fromTime.getTime() + ''; }
    if (filters.toTime) { params['to_time'] = filters.toTime.getTime() + ''; }
    if (filters.folder) { params['folder'] = filters.folder; }
    if (filters.topics) { params['topics[]'] = filters.topics; }
    if (filters.relatedTopics) { params['related_topics[]'] = filters.relatedTopics; }
    if (filters.contact) {
      params['contacts[]'] = [filters.contact.id];
    }
    if (filters.contacts) {
      params['contacts_emails[]'] = filters.contacts;
    }
    if (filters.relatedContacts && filters.relatedContacts.length) {
      params['related_contacts[]'] = filters.relatedContacts.map(contact => contact.id);
    }
    if (filters.ignoreContact) {
      params['ignore_contact_email'] = filters.ignoreContact.email;
    }
    if (filters.keywords) {
      params['keywords[]'] = filters.keywords;
    }
    if (filters.topicsFirstSymbol) {
      params['topics_first_symbol'] = filters.topicsFirstSymbol;
    }
    if (filters.onlyRelatedMessageTopics) {
      params['only_related_message_topics'] = true;
    }
    if (limit) { params['limit'] = limit + ''; }
    if (offset) { params['offset'] = offset + ''; }
    if (order) { params['order'] = order; }
    if (filters.firstSymbol) { params['first_symbol'] = filters.firstSymbol; }

    return params;
  }

  getRelatedTopicsOffset(
    filters: KnowledgeInfoFilters,
    order?: string
  ): Observable<number> {
    const params = this.formatFilters(filters, undefined, undefined, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/topics-offset', {params: params})
      .pipe(map(response => response['data'].offset));
  }

  getRelatedConnectionsOffset(
    filters: KnowledgeInfoFilters,
    order?: string
  ): Observable<number> {
    const params = this.formatFilters(filters, undefined, undefined, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/connections-offset', {params: params})
      .pipe(map(response => response['data'].offset));
  }

  getRelatedTopics(
    filters: KnowledgeInfoFilters,
    limit: number,
    offset: number,
    order?: string
  ): Observable<TopicsList> {
    const params = this.formatFilters(filters, limit, offset, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/topics', {params: params})
      .pipe(map(response => response['data'] as TopicsList));
  }

  getRelatedConnections(
    filters: KnowledgeInfoFilters,
    limit: number,
    offset: number,
    order?: string
  ): Observable<{data: Recipient[], count: number}> {
    const params = this.formatFilters(filters, limit, offset, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/connections', {params: params})
      .pipe(map(response => response['data'] as {data: Recipient[], count: number}));
  }

  getRelatedMessages(
    filters: KnowledgeInfoFilters,
    limit: number,
    offset: number,
    order?: string
  ): Observable<{data: MailMessage[], count: number}> {
    const params = this.formatFilters(filters, limit, offset, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/messages', {params: params})
      .pipe(map(response => response['data'] as {data: MailMessage[], count: number}));
  }

  getRelatedFiles(
    type: string,
    filters: KnowledgeInfoFilters,
    limit: number,
    offset: number,
    order?: string
  ): Observable<{data: Attachment[], count: number}> {
    const params = this.formatFilters(filters, limit, offset, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/files/' + type, {params: params})
      .pipe(map(response => response['data'] as {data: Attachment[], count: number}));
  }

  getRelatedHyperlinks(
    filters: KnowledgeInfoFilters,
    limit: number,
    offset: number,
    order?: string
  ): Observable<{ data: Hyperlink[], count: number }> {
    const params = this.formatFilters(filters, limit, offset, order);
    return this.http.get(environment.baseUrl + '/api/knowledge-graph/hyperlinks/', {params: params})
      .pipe(map(response => response['data'] as { data: Hyperlink[], count: number }));
  }
}

