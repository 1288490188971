// Common
import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { StateService } from '@modules/settings/services/state.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { SearchService } from '@modules/search/services/search.service';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { CalendarType } from '@modules/events/types/calendar-type';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { EventFilters } from '@modules/events/types/event-filters';

// RX
import { switchMap, takeUntil, map, throttleTime } from 'rxjs/operators';
import { combineLatest, interval, merge, Subject, BehaviorSubject } from 'rxjs';

// Environment
import { environment } from '@environment';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.less']
})

export class EventCalendarComponent implements OnDestroy {

  // Public
  public selectedDates: {[K in CalendarType]?: Date} = {
    year: new Date(),
    month: new Date(),
    week: new Date(),
    workWeek: new Date(),
    day: new Date(),
    agenda: new Date(),
  };
  public events: AngularCalendarEvent[] = [];
  public displayHeaderTitle: string;
  public calendarTypes: DropdownOption[] = [
    { name: 'Year', key: 'year' },
    { name: 'Month', key: 'month' },
    { name: 'Week', key: 'week' },
    { name: 'Work Week', key: 'workWeek' },
    { name: 'Day', key: 'day' },
    { name: 'Agenda', key: 'agenda' },
  ];
  public calendarType: DropdownOption = this.calendarTypes[0];
  public highlightedDate: Date;
  public selectedDate: Date;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private loadingEvents: Subject<void> = new Subject();
  private virtualEvent = new BehaviorSubject<CalendarEvent>(null);
  private filters: EventFilters = {};
  private popoverClose: Subject<void> = new Subject();

  // View Children
  @ViewChild('popoverFormTemplate', {static: true}) public popoverFormTemplate: TemplateRef<any>;

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private stateService: StateService,
    private eventsStateService: EventsStateService,
    private searchService: SearchService,
    private popoverService: PopoverService,
  ) {
    combineLatest([
      this.stateService.getSelectedCalendars(),
      this.eventsStateService.getSidebarFilters(),
      this.searchService.getSearchParams(),
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([calendarIds, sidebarFilters, searchParams]) => {
        this.filters = {
          ...sidebarFilters,
          ...searchParams,
          calendarIds,
        };
        this.loadingEvents.next();
      });

    merge(
      // Global refresh button
      this.eventsStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.calendarService.createNewEvent,
      this.calendarService.updatedEvent,
      this.calendarService.deletedEvent,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.loadingEvents.next();
      });

    this.stateService.getSelectedCalendarType()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarType: CalendarType) => {
        this.calendarType = this.calendarTypes.find(type => type.key === calendarType);
        this.refreshHeader();
        this.loadingEvents.next();
      });

    combineLatest([
      this.stateService.getSelectedCalendarDates('year'),
      this.stateService.getSelectedCalendarDates('month'),
      this.stateService.getSelectedCalendarDates('week'),
      this.stateService.getSelectedCalendarDates('workWeek'),
      this.stateService.getSelectedCalendarDates('day'),
      this.stateService.getSelectedCalendarDates('agenda'),
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([year, month, week, workWeek, day, agenda]) => {
        this.selectedDates = { year, month, week, workWeek, day, agenda };
        this.refreshHeader();
        this.loadingEvents.next();
        this.popoverClose.next();
      });

    this.loadingEvents
      .pipe(
        throttleTime(1000),
        switchMap(() => this.calendarService.getEvents({
          ...this.filters,
          fromDate: this.beginningOfPeriod(this.currentCalendarDate()),
          toDate: this.endOfPeriod(this.currentCalendarDate()),
        })),
        map((response: EventsListResponse) => response.events),
        switchMap((events: CalendarEvent[]) => this.virtualEvent.pipe(
          map((virtualEvent: CalendarEvent) => virtualEvent ? [...events, virtualEvent] : events)
        )),
        map((events: CalendarEvent[]) => events.map(event => event.asAngularCalendarEvent())),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((events: AngularCalendarEvent[]) => this.events = events);

    this.loadingEvents.next();

    this.eventsStateService.getSelectedEvents()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((events: CalendarEvent[]) => {
        if (events.length === 1) {
          // as we get all day events with custom hours (depends on timezone) we need to set 0 hour for right scroll
          this.highlightedDate = events[0].when.durationType === 'day'
            ? new Date(new Date(events[0].when.start).setHours(0))
            : events[0].when.start;
        } else {
          this.highlightedDate = null;
        }
      });
    this.eventsStateService.getSelectedCalendarDate()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((date: Date) => this.selectedDate = date);
  }

  /**
   * Component lifecycle
   */

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleDateClicked(cellEvent: CalendarCellClickEvent) {
    this.virtualEvent.next(
      CalendarEvent.fromCalendarCell(null, cellEvent.date, this.calendarType.key as CalendarType, true, cellEvent.event)
    );

    if (this.calendarType.key === 'year') {
      this.popoverService.create(
        cellEvent.originRef,
        {
          content: this.popoverFormTemplate,
          placement: 'right',
          innerShadow: false,
          allowedOutsideSelectors: '.mat-option-text, custom-repeater-dialog, .app-popover-content, .mat-autocomplete-panel, .dropdown',
          arrow: true,
          trigger: 'click',
          showUntil: this.popoverClose
        },
        cellEvent.event
      );
    }
  }

  handleDateDblClicked(date: Date) {
    switch (this.calendarType.key) {
      case 'year':
        this.selectCalendarType('day');
        this.setDate(date, 'day');
        break;
      case 'month':
      case 'week':
      case 'workWeek':
      case 'day':
        this.eventsStateService.openEventForm(
          CalendarEvent.fromCalendarCell(null, date, this.calendarType.key as CalendarType, false)
        );
        break;
    }
  }

  handleCellDrop(event: CalendarDropEvent) {
    const calendarAppointment = CalendarEvent.fromDragData(event.dragData);

    if (!calendarAppointment) { return; }

    this.virtualEvent.next(
      CalendarEvent.fromCalendarCell(
        calendarAppointment,
        event.newStart,
        this.calendarType.key as CalendarType,
        true
      )
    );
  }

  handlePopoverFormClose() {
    this.popoverClose.next();
    this.virtualEvent.next(null);
  }

  handleDayNumberDblClick(date: Date): void {
    this.selectCalendarType('day');
    this.setDate(date, 'day');
  }

  handleOpenInListView(day: Date) {
    this.eventsStateService.setScrollToDay(day);
  }

  setDate(newDate: Date, calendarType: CalendarType = this.calendarType.key as CalendarType) {
    this.stateService.setSelectedCalendarDates(calendarType, newDate);
  }

  setToday() {
    this.eventsStateService.setScrollToDay(new Date());
    this.setDate(new Date());
  }

  nextDate() {
    this.setDate(this.calculateDate(1));
  }

  prevDate() {
    this.setDate(this.calculateDate(-1));
  }

  calculateDate (multiplier: number) {
    const date = this.currentCalendarDate();

    const newDate = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };

    switch (this.calendarType.key) {
      case 'day':
      case 'agenda':
        newDate.day += multiplier;
        break;
      case 'week':
      case 'workWeek':
        newDate.day += 7 * multiplier;
        break;
      case 'month':
        newDate.month += multiplier;
        newDate.day = Math.min(newDate.day, this.daysInMonth( newDate.year, newDate.month + 1));
        break;
      case 'year':
        newDate.year += multiplier;
        break;
    }

    return new Date( newDate.year,  newDate.month, newDate.day );
  }

  daysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  selectCalendarType(calendarType: CalendarType) {
    this.stateService.selectedCalendarType = calendarType;
  }

  refreshHeader() {
    const date = this.currentCalendarDate();

    this.displayHeaderTitle = this.calendarType.key === 'year' ?
      date.getFullYear().toString() :
      date.toLocaleString('en-us', {month: 'long'}) + ` ${date.getFullYear()}`;
  }

  setYear(year: number) {
    const date = this.currentCalendarDate();
    this.setDate(new Date(year, date.getMonth(), date.getDate()));
  }

  /**
   * Helpers
   */

  private currentCalendarDate(): Date {
    return this.selectedDates[this.calendarType.key];
  }

  beginningOfPeriod(date: Date): Date {
    switch (this.calendarType.key) {
      case 'day':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
      case 'week':
      case 'workWeek':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()) - 1, 23, 59, 59, 999);
      case 'month':
        return new Date(date.getFullYear(), date.getMonth(), -5, 0, 0, 0, 0);
      case 'year':
        return new Date(date.getFullYear(), 0, -5, 0, 0, 0, 0);
    }
  }

  endOfPeriod(date: Date): Date {
    switch (this.calendarType.key) {
      case 'day':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
      case 'week':
      case 'workWeek':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()), 0, 0, 0, 0);
      case 'month':
        return new Date(date.getFullYear(), date.getMonth() + 1, 6, 0, 0, 0, 0);
      case 'year':
        return new Date(date.getFullYear() + 1, 0, 6, 0, 0, 0, 0);
    }
  }
}
