<div
  *ngIf="contact"
  class="contact"
  [class.short-view]="shortView"
  [class.pinned]="contact.pinned"
  (click)="openContact()"
  [appContactContextMenu]="contact"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [contact], type: 'contact'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="contact-body">
    <div class="contact-body-heading">
      <div class="contact-body-heading-avatar">
        HP
      </div>

      <div class="contact-body-heading-title">
        <div class="contact-body-heading-title-text">
          {{contact.name}}
        </div>
        <div class="contact-body-heading-title-info">
          <div class="contact-body-heading-title-info-item">
            {{contact.email}}
          </div>
          <div class="contact-body-heading-title-info-separator"></div>
          <div class="contact-body-heading-title-info-item">
            {{contact.phoneNumber}}
          </div>
        </div>
      </div>
    </div>

    <app-link-info-bar
      class="contact-body-info-bar"
      *ngIf="!shortView && contact.linkedInfo?.length"
      [linkedInfo]="contact.linkedInfo"
    ></app-link-info-bar>
  </div>

  <mat-icon class="contact-pin-icon" *ngIf="contact.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="contact-starred-icon" *ngIf="!contact.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="contact-marker" svgIcon="contacts"></mat-icon>
  <mat-icon class="contact-attachments-icon" *ngIf="contact.files?.length" svgIcon="attachment"></mat-icon>

  <!-- TODO move this to a separate component -->
  <div
    class="contact-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  >
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="saved"
      [class.active]="contact.starred"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="pin"
      [class.active]="contact.pinned"
      [matTooltip]="contact?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="crown"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="archive"
      [class.active]="contact.archived"
      [matTooltip]="contact?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="trash"
      [class.active]="contact.deleted"
      [matTooltip]="contact?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="contact-bottom-container">
    <mat-icon
      class="contact-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
    <app-contacts-counter-dropdown
      *ngIf="!shortView && contact.participants?.length"
      class="contact-bottom-container-participants"
      [contactList]="contact.participants"
      [size]="24"
      [minContactLen]="1"
    ></app-contacts-counter-dropdown>
  </div>

  <div class="drop-zone"></div>
</div>

<!-- TODO waiting for design -->
<div
  class="contact placeholder"
  *ngIf="!contact"
  [class.short-view]="shortView"  
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-contact
    [contact]="contact"
    [dragEnabled]="false"
    [shortView]="shortView"
  ></app-link-info-contact>
</ng-template>
