import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Types
import { Project } from '@modules/tasks/types/project';
var ProjectContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProjectContextMenuComponent, _super);
    /**
     * Constructor
     */
    function ProjectContextMenuComponent(contextMenuService, tasksStateService, ga, projectsService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.tasksStateService = tasksStateService;
        _this.ga = ga;
        _this.projectsService = projectsService;
        _this.projectTitleFormControl = new FormControl();
        return _this;
    }
    /**
     * Component lifecycle
     */
    ProjectContextMenuComponent.prototype.ngOnInit = function () {
        this.projectForm = this.project.asFormGroup();
    };
    /**
     * Actions
     */
    ProjectContextMenuComponent.prototype.handleEdit = function () {
        this.tasksStateService.setMainViewProject(this.project);
        this.tasksStateService.setMainView('project-form');
    };
    ProjectContextMenuComponent.prototype.pin = function (projects) {
        this.ga.trackEvent('project-context-menu', 'pin');
        this.projectsService.pin(projects.filter(function (project) { return project.pinned !== true; }).map(function (project) { return project.id; }), true);
    };
    ProjectContextMenuComponent.prototype.unpin = function (projects) {
        this.ga.trackEvent('project-context-menu', 'unpin');
        this.projectsService.pin(projects.filter(function (project) { return project.pinned === true; }).map(function (project) { return project.id; }), false);
    };
    ProjectContextMenuComponent.prototype.delete = function (projects) {
        this.ga.trackEvent('project-context-menu', 'delete');
        this.projectsService.delete(projects.filter(function (project) { return project.deleted !== true; }).map(function (project) { return project.id; }), true);
    };
    ProjectContextMenuComponent.prototype.deletePermanently = function (projects) {
        this.ga.trackEvent('project-context-menu', 'delete-permanently');
        this.projectsService.deletePermanently(projects.filter(function (project) { return project.deleted === true; }).map(function (project) { return project.id; }));
    };
    ProjectContextMenuComponent.prototype.restoreFromTrash = function (projects) {
        this.ga.trackEvent('project-context-menu', 'restore-from-trash');
        this.projectsService.delete(projects.filter(function (project) { return project.deleted === true; }).map(function (project) { return project.id; }), false);
    };
    ProjectContextMenuComponent.prototype.moveToArchive = function (projects) {
        this.ga.trackEvent('project-context-menu', 'move-to-archive');
        this.projectsService.archive(projects
            .filter(function (project) { return project.archived === false; })
            .map(function (project) { return project.id; }), true);
    };
    ProjectContextMenuComponent.prototype.restoreFromArchive = function (projects) {
        this.ga.trackEvent('project-context-menu', 'restore-from-archive');
        this.projectsService.archive(projects
            .filter(function (project) { return project.archived === true; })
            .map(function (project) { return project.id; }), false);
    };
    ProjectContextMenuComponent.prototype.handleRenameSubmit = function () {
        this.projectForm.patchValue({ title: this.projectTitleFormControl.value });
        this.projectsService.edit(Project.fromFormGroup(this.projectForm));
        this.closeContextMenu();
    };
    ProjectContextMenuComponent.prototype.handleRenameCancel = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    ProjectContextMenuComponent.prototype.closeContextMenu = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    return ProjectContextMenuComponent;
}(ContextMenuComponent));
export { ProjectContextMenuComponent };
