/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./knowledge-panel-form.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../events/components/event-form/event-form.component.ngfactory";
import * as i3 from "../../../events/components/event-form/event-form.component";
import * as i4 from "../../../events/services/calendar.service";
import * as i5 from "../../../modal/services/modal.service";
import * as i6 from "../../../events/services/events-state.service";
import * as i7 from "../../../pages/services/dynamic-panel.service";
import * as i8 from "../../../tasks/components/task-form/task-form.component.ngfactory";
import * as i9 from "../../../tasks/components/task-form/task-form.component";
import * as i10 from "../../../tasks/services/tasks.service";
import * as i11 from "../../../tasks/services/projects.service";
import * as i12 from "../../../tasks/services/columns.service";
import * as i13 from "../../../linked-info/services/linked-info.service";
import * as i14 from "../../../topic/services/topic.service";
import * as i15 from "../../../tag/services/tag.service";
import * as i16 from "../../../tasks/services/tasks-state.service";
import * as i17 from "../../../tasks/components/project-form-container/project-form-container.component.ngfactory";
import * as i18 from "../../../tasks/components/project-form-container/project-form-container.component";
import * as i19 from "../../../notes/components/note-form/note-form.component.ngfactory";
import * as i20 from "../../../notes/components/note-form/note-form.component";
import * as i21 from "../../../notes/services/notes.service";
import * as i22 from "@angular/common";
import * as i23 from "./knowledge-panel-form.component";
import * as i24 from "../../../pages/services/layout-state.service";
import * as i25 from "../../services/knowledge-panel.service";
var styles_KnowledgePanelFormComponent = [i0.styles];
var RenderType_KnowledgePanelFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KnowledgePanelFormComponent, data: {} });
export { RenderType_KnowledgePanelFormComponent as RenderType_KnowledgePanelFormComponent };
function View_KnowledgePanelFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-form", [["appearance", "headerFixedSmall"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EventFormComponent_0, i2.RenderType_EventFormComponent)), i1.ɵdid(1, 704512, null, 0, i3.EventFormComponent, [i4.CalendarService, i5.ModalService, i6.EventsStateService, i7.DynamicPanelService], { event: [0, "event"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formItem; var currVal_1 = "headerFixedSmall"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_KnowledgePanelFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-form", [["appearance", "headerFixedSmall"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TaskFormComponent_0, i8.RenderType_TaskFormComponent)), i1.ɵdid(1, 770048, null, 0, i9.TaskFormComponent, [i10.TasksService, i11.ProjectsService, i7.DynamicPanelService, i12.ColumnsService, i13.LinkedInfoService, i14.TopicService, i15.TagService, i16.TasksStateService], { task: [0, "task"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formItem; var currVal_1 = "headerFixedSmall"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_KnowledgePanelFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-form-container", [["appearance", "headerFixedSmall"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_ProjectFormContainerComponent_0, i17.RenderType_ProjectFormContainerComponent)), i1.ɵdid(1, 770048, null, 0, i18.ProjectFormContainerComponent, [i11.ProjectsService, i16.TasksStateService, i7.DynamicPanelService, i13.LinkedInfoService, i14.TopicService, i15.TagService, i10.TasksService], { project: [0, "project"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formItem; var currVal_1 = "headerFixedSmall"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_KnowledgePanelFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-note-form", [["appearance", "headerFixedSmall"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_NoteFormComponent_0, i19.RenderType_NoteFormComponent)), i1.ɵdid(1, 638976, null, 0, i20.NoteFormComponent, [i21.NotesService, i13.LinkedInfoService, i5.ModalService, i7.DynamicPanelService, i14.TopicService, i15.TagService], { note: [0, "note"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formItem; var currVal_1 = "headerFixedSmall"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_KnowledgePanelFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "form-container"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i22.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KnowledgePanelFormComponent_1)), i1.ɵdid(3, 278528, null, 0, i22.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KnowledgePanelFormComponent_2)), i1.ɵdid(5, 278528, null, 0, i22.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KnowledgePanelFormComponent_3)), i1.ɵdid(7, 278528, null, 0, i22.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KnowledgePanelFormComponent_4)), i1.ɵdid(9, 278528, null, 0, i22.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "event"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "task"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "project"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "note"; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_KnowledgePanelFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-knowledge-panel-form", [], null, null, null, View_KnowledgePanelFormComponent_0, RenderType_KnowledgePanelFormComponent)), i1.ɵdid(1, 245760, null, 0, i23.KnowledgePanelFormComponent, [i24.LayoutStateService, i25.KnowledgePanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KnowledgePanelFormComponentNgFactory = i1.ɵccf("app-knowledge-panel-form", i23.KnowledgePanelFormComponent, View_KnowledgePanelFormComponent_Host_0, {}, {}, []);
export { KnowledgePanelFormComponentNgFactory as KnowledgePanelFormComponentNgFactory };
