<div class="date-time-show">
  <div class="date-time-show-group" [class.void]="!startDate && !startTime">
    <div class="date-time-show-group-item" [class.void]="!startDate">
      {{ startDate | date: dateFormat }}
    </div>
    <div class="date-time-show-group-item" [class.void]="!startTime" *ngIf="withTime">
      {{ startTime | date: timeFormat | lowercase }}
    </div>
  </div>

  <div class="date-time-show-separator"></div>

  <div class="date-time-show-group" [class.void]="!endDate && !endTime">
    <div class="date-time-show-group-item" [class.void]="!endDate">
      {{ endDate | date: endDateFormat || dateFormat }}
    </div>
    <div class="date-time-show-group-item" [class.void]="!endTime" *ngIf="withTime">
      {{ endTime | date: endTimeFormat || timeFormat | lowercase }}
    </div>
  </div>
</div>

