// Common
import {
  Component, ContentChildren, QueryList, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';

// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';

@Component({
  selector: 'app-content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.less']
})
export class ContentMenuComponent implements AfterContentChecked {

  // Content Children
  @ContentChildren(ContentMenuBaseItemComponent) private items: QueryList<ContentMenuBaseItemComponent>;

  // ViewChildren
  @ViewChild('container', { static: true }) container: ElementRef;


  /**
   * Constructor
   */

  constructor(

  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterContentChecked() {
    // Yes - it's very bad approach to call public method from outside of the component
    this.items.forEach(i => i.setRoot(true));
  }
}
