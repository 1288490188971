// Common
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

// Services
import { GoogleAnalyticsService } from './modules/analytics/services/google-analytics.service';
import { PushNotificationsService } from './modules/notification/services/push-notifications.service';
import { AuthService } from './modules/auth/services/auth.service';
import { AccountService } from './modules/account/services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public router: Router,
              private authService: AuthService,
              private accountService: AccountService,
              private ga: GoogleAnalyticsService,
              private pushNotifications: PushNotificationsService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    // Track screens to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ga.trackPage(event.urlAfterRedirects);
      }
    });

    // Auth changes
    this.authService.authenticated.subscribe(authenticated => {
      if (authenticated) {
        this.pushNotifications.register();
        this.accountService.getAccount()
          .subscribe(account => this.pushNotifications.setUser(account.id));
      }
    });

    // Icons
    /* tslint:disable:max-line-length */
    // Common
    this.matIconRegistry.addSvgIcon('activity', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/activity.svg'));
    this.matIconRegistry.addSvgIcon('add-topic', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add-topic.svg'));
    this.matIconRegistry.addSvgIcon('add-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add-small.svg'));
    this.matIconRegistry.addSvgIcon('attachment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/attachment.svg'));
    this.matIconRegistry.addSvgIcon('attachment-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/attachment-small.svg'));
    this.matIconRegistry.addSvgIcon('attachment-round', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/attachment-round.svg'));
    this.matIconRegistry.addSvgIcon('alarm-clock', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/alarm-clock.svg'));
    this.matIconRegistry.addSvgIcon('agenda-dots', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/agenda-dots.svg'));
    this.matIconRegistry.addSvgIcon('arrow-left', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-left.svg'));
    this.matIconRegistry.addSvgIcon('arrow-left-thin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-left-thin.svg'));
    this.matIconRegistry.addSvgIcon('arrow-right', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-right.svg'));
    this.matIconRegistry.addSvgIcon('arrow-right-thin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-right-thin.svg'));
    this.matIconRegistry.addSvgIcon('board-view', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/board-view.svg'));
    this.matIconRegistry.addSvgIcon('contacts', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/contacts.svg'));
    this.matIconRegistry.addSvgIcon('flagged', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flagged.svg'));
    this.matIconRegistry.addSvgIcon('frequent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/frequent.svg'));
    this.matIconRegistry.addSvgIcon('groups', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/groups.svg'));
    this.matIconRegistry.addSvgIcon('arrow-up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-up.svg'));
    this.matIconRegistry.addSvgIcon('arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-down.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar.svg'));
    this.matIconRegistry.addSvgIcon('calendar-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar-new.svg'));
    this.matIconRegistry.addSvgIcon('calendar-week', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar-week.svg'));
    this.matIconRegistry.addSvgIcon('calendar-today', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar-today.svg'));
    this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/check.svg'));
    this.matIconRegistry.addSvgIcon('circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/circle.svg'));
    this.matIconRegistry.addSvgIcon('circle-checked', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/circle-checked.svg'));
    this.matIconRegistry.addSvgIcon('circle-plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/circle-plus.svg'));
    this.matIconRegistry.addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/close.svg'));
    this.matIconRegistry.addSvgIcon('close-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/close-small.svg'));
    this.matIconRegistry.addSvgIcon('collapse-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collapse-down.svg'));
    this.matIconRegistry.addSvgIcon('collapse-modal', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collapse-modal.svg'));
    this.matIconRegistry.addSvgIcon('collapse-open', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collapse-open.svg'));
    this.matIconRegistry.addSvgIcon('open-modal', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/open-modal.svg'));
    this.matIconRegistry.addSvgIcon('copy', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/copy.svg'));
    this.matIconRegistry.addSvgIcon('crown', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/crown.svg'));
    this.matIconRegistry.addSvgIcon('collapse', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/collapse.svg'));
    this.matIconRegistry.addSvgIcon('compose', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/compose.svg'));
    this.matIconRegistry.addSvgIcon('description', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/description.svg'));
    this.matIconRegistry.addSvgIcon('down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/down.svg'));
    this.matIconRegistry.addSvgIcon('drag-icon-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/drag-icon-small.svg'));
    this.matIconRegistry.addSvgIcon('drag-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/drag-icon.svg'));
    this.matIconRegistry.addSvgIcon('dropdown', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dropdown.svg'));
    this.matIconRegistry.addSvgIcon('dropdown-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dropdown-down.svg'));
    this.matIconRegistry.addSvgIcon('dropdown-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dropdown-small.svg'));
    this.matIconRegistry.addSvgIcon('download-viewer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download-viewer.svg'));
    this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit.svg'));
    this.matIconRegistry.addSvgIcon('edit-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit-small.svg'));
    this.matIconRegistry.addSvgIcon('envelope-closed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/envelope-closed.svg'));
    this.matIconRegistry.addSvgIcon('event', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/event.svg'));
    this.matIconRegistry.addSvgIcon('event-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/event-small.svg'));
    this.matIconRegistry.addSvgIcon('events', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/events.svg'));
    this.matIconRegistry.addSvgIcon('email', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/email.svg'));
    this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download.svg'));
    this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter.svg'));
    this.matIconRegistry.addSvgIcon('filter-sorting', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter-sorting.svg'));
    this.matIconRegistry.addSvgIcon('flag', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flag.svg'));
    this.matIconRegistry.addSvgIcon('followup', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/followup.svg'));
    this.matIconRegistry.addSvgIcon('followup-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/followup-small.svg'));
    this.matIconRegistry.addSvgIcon('forward-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward-small.svg'));
    this.matIconRegistry.addSvgIcon('forward-bold', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward-bold.svg'));
    this.matIconRegistry.addSvgIcon('forward', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward.svg'));
    this.matIconRegistry.addSvgIcon('fullscreen', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fullscreen.svg'));
    this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/info.svg'));
    this.matIconRegistry.addSvgIcon('important', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/important.svg'));
    this.matIconRegistry.addSvgIcon('link-bold', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/link-bold.svg'));
    this.matIconRegistry.addSvgIcon('link-thin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/link-thin.svg'));
    this.matIconRegistry.addSvgIcon('link-round', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/link-round.svg'));
    this.matIconRegistry.addSvgIcon('list-view', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/list-view.svg'));
    this.matIconRegistry.addSvgIcon('list-view-big', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/list-view-big.svg'));
    this.matIconRegistry.addSvgIcon('cards-view', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cards-view.svg'));
    this.matIconRegistry.addSvgIcon('mail', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail.svg'));
    this.matIconRegistry.addSvgIcon('mail-tiny', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-tiny.svg'));
    this.matIconRegistry.addSvgIcon('map-pin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/map-pin.svg'));
    this.matIconRegistry.addSvgIcon('archive', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/archive.svg'));
    this.matIconRegistry.addSvgIcon('more', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/more.svg'));
    this.matIconRegistry.addSvgIcon('more-actions', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/more-actions.svg'));
    this.matIconRegistry.addSvgIcon('new-email', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/new-email.svg'));
    this.matIconRegistry.addSvgIcon('note', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/note.svg'));
    this.matIconRegistry.addSvgIcon('note-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/note-small.svg'));
    this.matIconRegistry.addSvgIcon('notes-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/notes-small.svg'));
    this.matIconRegistry.addSvgIcon('notebook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/notebook.svg'));
    this.matIconRegistry.addSvgIcon('notification-indicator', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/notification-indicator.svg'));
    this.matIconRegistry.addSvgIcon('other', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/other.svg'));
    this.matIconRegistry.addSvgIcon('pending', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pending.svg'));
    this.matIconRegistry.addSvgIcon('pin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin.svg'));
    this.matIconRegistry.addSvgIcon('pin-direct', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin-direct.svg'));
    this.matIconRegistry.addSvgIcon('pin-direct-large', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin-direct-large.svg'));
    this.matIconRegistry.addSvgIcon('pin-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin-small.svg'));
    this.matIconRegistry.addSvgIcon('pin-small-color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pin-small-color.svg'));
    this.matIconRegistry.addSvgIcon('preview', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/preview.svg'));
    this.matIconRegistry.addSvgIcon('print', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/print.svg'));
    this.matIconRegistry.addSvgIcon('priority-flag', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-flag.svg'));
    this.matIconRegistry.addSvgIcon('project', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/project.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon('plus-medium', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus-medium.svg'));
    this.matIconRegistry.addSvgIcon('plus-bold', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus-bold.svg'));
    this.matIconRegistry.addSvgIcon('plus-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus-small.svg'));
    this.matIconRegistry.addSvgIcon('recent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/recent.svg'));
    this.matIconRegistry.addSvgIcon('repeat', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/repeat.svg'));
    this.matIconRegistry.addSvgIcon('replied', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/replied.svg'));
    this.matIconRegistry.addSvgIcon('reply-all', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/reply-all.svg'));
    this.matIconRegistry.addSvgIcon('reply', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/reply.svg'));
    this.matIconRegistry.addSvgIcon('resize', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/resize.svg'));
    this.matIconRegistry.addSvgIcon('saved', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/saved.svg'));
    this.matIconRegistry.addSvgIcon('saved-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/saved-small.svg'));
    this.matIconRegistry.addSvgIcon('search', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/search.svg'));
    this.matIconRegistry.addSvgIcon('search-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/search-small.svg'));
    this.matIconRegistry.addSvgIcon('send-time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/send-time.svg'));
    this.matIconRegistry.addSvgIcon('snooze', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/snooze.svg'));
    this.matIconRegistry.addSvgIcon('star', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/star.svg'));
    this.matIconRegistry.addSvgIcon('starred', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/starred.svg'));
    this.matIconRegistry.addSvgIcon('topic-add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/topic-add.svg'));
    this.matIconRegistry.addSvgIcon('topic-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/topic-list.svg'));
    this.matIconRegistry.addSvgIcon('topic-edit', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/topic-edit.svg'));
    this.matIconRegistry.addSvgIcon('topics', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/topics.svg'));
    this.matIconRegistry.addSvgIcon('trash', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trash.svg'));
    this.matIconRegistry.addSvgIcon('trash-bucket', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trash-bucket.svg'));
    this.matIconRegistry.addSvgIcon('unread-time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/unread-time.svg'));
    this.matIconRegistry.addSvgIcon('unread', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/unread.svg'));
    this.matIconRegistry.addSvgIcon('unlink', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/unlink.svg'));
    this.matIconRegistry.addSvgIcon('reminder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/reminder.svg'));
    this.matIconRegistry.addSvgIcon('reminder-bell', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/reminder-bell.svg'));
    this.matIconRegistry.addSvgIcon('spaces', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/spaces.svg'));
    this.matIconRegistry.addSvgIcon('subtask', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/subtask.svg'));
    this.matIconRegistry.addSvgIcon('subtaskAsBackground', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/subtask-as-background.svg'));
    this.matIconRegistry.addSvgIcon('tags', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tags.svg'));
    this.matIconRegistry.addSvgIcon('tags-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tags-small.svg'));
    this.matIconRegistry.addSvgIcon('task', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/task.svg'));
    this.matIconRegistry.addSvgIcon('task-done-0', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/task-done-0.svg'));
    this.matIconRegistry.addSvgIcon('task-done-25', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/task-done-25.svg'));
    this.matIconRegistry.addSvgIcon('task-done-100', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/task-done-100.svg'));
    this.matIconRegistry.addSvgIcon('tasks', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tasks.svg'));
    this.matIconRegistry.addSvgIcon('tasks-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tasks-small.svg'));
    this.matIconRegistry.addSvgIcon('open-r', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/open-r.svg'));
    this.matIconRegistry.addSvgIcon('pellets', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pellets.svg'));
    this.matIconRegistry.addSvgIcon('pellets-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pellets-small.svg'));
    this.matIconRegistry.addSvgIcon('user-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user-small.svg'));
    this.matIconRegistry.addSvgIcon('user-round', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user-round.svg'));
    this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon('settings-small', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/settings-small.svg'));
    this.matIconRegistry.addSvgIcon('time', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/time.svg'));
    this.matIconRegistry.addSvgIcon('time-nine', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/time-nine.svg'));
    this.matIconRegistry.addSvgIcon('time-zone', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/time-zone.svg'));
    this.matIconRegistry.addSvgIcon('time-round', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/time-round.svg'));
    this.matIconRegistry.addSvgIcon('timeline-view', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/timeline-view.svg'));
    this.matIconRegistry.addSvgIcon('up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/up.svg'));
    this.matIconRegistry.addSvgIcon('upcoming', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/upcoming.svg'));
    this.matIconRegistry.addSvgIcon('vip', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/vip.svg'));
    this.matIconRegistry.addSvgIcon('recent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/recent.svg'));
    // Mail
    this.matIconRegistry.addSvgIcon('mail-attachment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-attachment.svg'));
    this.matIconRegistry.addSvgIcon('mail-pinned', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-pinned.svg'));
    this.matIconRegistry.addSvgIcon('mail-reply', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-reply.svg'));
    this.matIconRegistry.addSvgIcon('mail-star', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-star.svg'));
    this.matIconRegistry.addSvgIcon('mail-unread', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail-unread.svg'));
    // Buttons
    this.matIconRegistry.addSvgIcon('button-add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-add.svg'));
    this.matIconRegistry.addSvgIcon('button-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-arrow.svg'));
    this.matIconRegistry.addSvgIcon('button-link', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-link.svg'));
    this.matIconRegistry.addSvgIcon('button-collapse-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-collapse-down.svg'));
    this.matIconRegistry.addSvgIcon('button-collapse-up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-collapse-up.svg'));
    this.matIconRegistry.addSvgIcon('button-mail', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-mail.svg'));
    this.matIconRegistry.addSvgIcon('button-collapse', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-collapse.svg'));
    this.matIconRegistry.addSvgIcon('button-nav-compose', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-nav-compose.svg'));
    this.matIconRegistry.addSvgIcon('button-nav-create', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-nav-create.svg'));
    this.matIconRegistry.addSvgIcon('button-nav-refresh', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-nav-refresh.svg'));
    this.matIconRegistry.addSvgIcon('button-nav-modal', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-nav-modal.svg'));
    this.matIconRegistry.addSvgIcon('button-nav-notification', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-nav-notification.svg'));
    this.matIconRegistry.addSvgIcon('button-pagination-left', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-pagination-left.svg'));
    this.matIconRegistry.addSvgIcon('button-pagination-right', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-pagination-right.svg'));
    this.matIconRegistry.addSvgIcon('button-topic-edit', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-topic-edit.svg'));
    this.matIconRegistry.addSvgIcon('button-split-mails-collapsed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-split-mails-collapsed.svg'));
    this.matIconRegistry.addSvgIcon('button-split-calendar-collapsed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-split-calendar-collapsed.svg'));
    this.matIconRegistry.addSvgIcon('button-split-expanded', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-split-expanded.svg'));
    this.matIconRegistry.addSvgIcon('button-split-projects-collapsed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/button-split-projects-collapsed.svg'));
    // Controls Buttons
    this.matIconRegistry.addSvgIcon('controls-button-today', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/controls-buttons/today.svg'));
    this.matIconRegistry.addSvgIcon('controls-button-tomorrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/controls-buttons/tomorrow.svg'));
    this.matIconRegistry.addSvgIcon('controls-button-next-week', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/controls-buttons/week.svg'));
    this.matIconRegistry.addSvgIcon('controls-button-calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/controls-buttons/calendar.svg'));
    this.matIconRegistry.addSvgIcon('controls-button-priority', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/buttons/controls-buttons/flag.svg'));
    // Search
    this.matIconRegistry.addSvgIcon('search-connection', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/search-connection.svg'));
    this.matIconRegistry.addSvgIcon('search-pellet', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/search-pellet.svg'));
    // Files
    this.matIconRegistry.addSvgIcon('pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pdf.svg'));
    this.matIconRegistry.addSvgIcon('pdf-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pdf-new.svg'));
    this.matIconRegistry.addSvgIcon('jpg', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/jpg.svg'));
    this.matIconRegistry.addSvgIcon('jpg-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/jpg-new.svg'));
    this.matIconRegistry.addSvgIcon('doc-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/doc.svg'));
    this.matIconRegistry.addSvgIcon('xls-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/xls.svg'));
    this.matIconRegistry.addSvgIcon('file', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/file.svg'));
    this.matIconRegistry.addSvgIcon('download-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download-new.svg'));
    // Folders
    this.matIconRegistry.addSvgIcon('folder-add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/add.svg'));
    this.matIconRegistry.addSvgIcon('folder-all', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/all.svg'));
    this.matIconRegistry.addSvgIcon('folder-archive', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/archive.svg'));
    this.matIconRegistry.addSvgIcon('folder-drafts', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/drafts.svg'));
    this.matIconRegistry.addSvgIcon('folder-flow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/flow.svg'));
    this.matIconRegistry.addSvgIcon('folder-folder', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/folder.svg'));
    this.matIconRegistry.addSvgIcon('folder-followed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/followed.svg'));
    this.matIconRegistry.addSvgIcon('folder-important', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/important.svg'));
    this.matIconRegistry.addSvgIcon('folder-inbox', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/inbox.svg'));
    this.matIconRegistry.addSvgIcon('folder-insights', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/insights.svg'));
    this.matIconRegistry.addSvgIcon('folder-outbox', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/outbox.svg'));
    this.matIconRegistry.addSvgIcon('folder-sent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/sent.svg'));
    this.matIconRegistry.addSvgIcon('folder-snoozed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/snoozed.svg'));
    this.matIconRegistry.addSvgIcon('folder-spam', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/spam.svg'));
    this.matIconRegistry.addSvgIcon('folder-starred', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/starred.svg'));
    this.matIconRegistry.addSvgIcon('folder-trash', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/trash.svg'));
    this.matIconRegistry.addSvgIcon('folder-vips', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/folders/vips.svg'));
    // App menu
    this.matIconRegistry.addSvgIcon('app-menu-collapse-down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/collapse-down.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-contacts', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/contacts.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-explore', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/explore.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-inbox', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/inbox.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/menu.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-notes', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/notes.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-projects', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/projects.svg'));
    this.matIconRegistry.addSvgIcon('app-menu-settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/app-menu/settings.svg'));
    // Text editor
    this.matIconRegistry.addSvgIcon('alignleft', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/alignleft.svg'));
    this.matIconRegistry.addSvgIcon('alignright', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/alignright.svg'));
    this.matIconRegistry.addSvgIcon('aligncenter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/aligncenter.svg'));
    this.matIconRegistry.addSvgIcon('alignjustify', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/alignjustify.svg'));
    this.matIconRegistry.addSvgIcon('bold', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/bold.svg'));
    this.matIconRegistry.addSvgIcon('italic', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/italic.svg'));
    this.matIconRegistry.addSvgIcon('underline', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/underline.svg'));
    this.matIconRegistry.addSvgIcon('bullet-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/bullet-list.svg'));
    this.matIconRegistry.addSvgIcon('numbered-list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/numbered-list.svg'));
    this.matIconRegistry.addSvgIcon('link', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/link.svg'));
    this.matIconRegistry.addSvgIcon('plain-text', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plain-text.svg'));
    this.matIconRegistry.addSvgIcon('undo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/undo.svg'));
    this.matIconRegistry.addSvgIcon('redo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/redo.svg'));
    this.matIconRegistry.addSvgIcon('intdent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/intdent.svg'));
    this.matIconRegistry.addSvgIcon('outdent', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/outdent.svg'));
    this.matIconRegistry.addSvgIcon('eraser', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/eraser.svg'));
    this.matIconRegistry.addSvgIcon('strikeout', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/strikeout.svg'));
    this.matIconRegistry.addSvgIcon('pic', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pic.svg'));
    this.matIconRegistry.addSvgIcon('h1', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/h1.svg'));
    this.matIconRegistry.addSvgIcon('h2', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/h2.svg'));
    this.matIconRegistry.addSvgIcon('color', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/color.svg'));
    this.matIconRegistry.addSvgIcon('marker', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/marker.svg'));
    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add.svg'));
    this.matIconRegistry.addSvgIcon('table', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/table.svg'));
    this.matIconRegistry.addSvgIcon('signature', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/signature.svg'));
    this.matIconRegistry.addSvgIcon('interval', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/interval.svg'));
    // Tabs
    this.matIconRegistry.addSvgIcon('tab-attachment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/attachment.svg'));
    this.matIconRegistry.addSvgIcon('tab-calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/calendar.svg'));
    this.matIconRegistry.addSvgIcon('tab-compass', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/compass.svg'));
    this.matIconRegistry.addSvgIcon('tab-contact', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/contact.svg'));
    this.matIconRegistry.addSvgIcon('tab-explore', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/explore.svg'));
    this.matIconRegistry.addSvgIcon('tab-kp', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/kp.svg'));
    this.matIconRegistry.addSvgIcon('tab-mail', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/mail.svg'));
    this.matIconRegistry.addSvgIcon('tab-notes', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/notes.svg'));
    this.matIconRegistry.addSvgIcon('tab-project', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/project.svg'));
    this.matIconRegistry.addSvgIcon('tab-tasks', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/tabs/tasks.svg'));
    // Tasks
    this.matIconRegistry.addSvgIcon('today', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/today.svg'));
    this.matIconRegistry.addSvgIcon('tomorrow', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tomorrow.svg'));
    this.matIconRegistry.addSvgIcon('no-date', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/no-date.svg'));
    this.matIconRegistry.addSvgIcon('week', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/week.svg'));
    // Social
    this.matIconRegistry.addSvgIcon('social-hangouts', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/hangouts.svg'));
    this.matIconRegistry.addSvgIcon('social-no-conf', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/no-conf.svg'));
    this.matIconRegistry.addSvgIcon('social-skype', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/skype.svg'));
    this.matIconRegistry.addSvgIcon('social-slack', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/slack.svg'));
    this.matIconRegistry.addSvgIcon('social-zoom', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/zoom.svg'));
    // Placeholders
    this.matIconRegistry.addSvgIcon('placeholder-files', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/placeholders/placeholder-files.svg'));
    /* tslint:enable:max-line-length */
  }
}
