// Common
import { Injectable } from '@angular/core';

// Types
import { MailMessage } from '../../mail/types/mail-message';
import { PopupSize } from './../types/popup-size';

@Injectable()
export class PopupService {

  private defaultSize: PopupSize = {
    width: 1000,
    height: 500
  };

  // Helpers

  private frameParams(size: PopupSize): string {
    const left = Number((screen.width / 2) - (size.width / 2));
    const top = Number((screen.height / 2) - (size.height / 2));
    return `width=${size.width},height=${size.height},left=${left},top=${top}`;
  }

  // Show popups methods

  mailContent(messageId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open('compact/mail/message/' + messageId,
      'Message',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  eventContent(calendarEventId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/event/${ calendarEventId }/edit/`,
      'Calendar Event',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  projectContent(projectId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/project/${ projectId }/edit/`,
      'Project',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  taskContent(taskId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/task/${ taskId }/edit/`,
      'Task',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  noteContent(noteId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/note/${ noteId }/edit/`,
      'Note',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  groupContent(groupId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/group/${ groupId }/edit/`,
      'Group',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  contactContent(contactId: string, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(`compact/contact/${ contactId }/edit/`,
      'Contact',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`);
  }

  annotations(messageId: string, size: PopupSize = this.defaultSize) {
    window.open(`compact/mail/message/${messageId}/annotations`,
      'Annotations',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${this.frameParams(size)}`);
  }

  compose(message: MailMessage, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    const popup = window.open(
      'compact/mail/compose',
      'Compose',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`
    );
    popup.addEventListener('load', this.receiveMessage.bind(this, popup, message), false);
  }

  receiveMessage(popupWindow: Window, message?: MailMessage, event?: Event) {
    const eventTarget = event.target as Window;
    const currentOrigin = `${ window.location.protocol }//${ window.location.host }`;
    if (eventTarget.location.origin !== currentOrigin) { return; }
    popupWindow.postMessage({mailMessage: message}, currentOrigin);
  }

}
