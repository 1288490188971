// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Types
import { CalendarContact } from './calendar-contact';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Topic } from '@modules/topic/types/topic';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Tag } from '@modules/tag/types/tag';
import { NotificationDetails } from '@modules/form-controls/types/notification-details';
import { CalendarType } from './calendar-type';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class CalendarEvent {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  accountId?: string;
  messageId?: string;
  calendarId?: string;
  color?: string;
  title?: string;
  description?: string;
  location?: {
    address: string,
    coordinates: {
      longitude: number,
      latitude: number
    }
  };
  when?: {
    durationType?: 'timespan' | 'day';
    start: Date;
    end: Date;
  };
  owner?: CalendarContact;
  participants?: CalendarContact[];
  pinned?: boolean;
  archived?: boolean;
  reminder?: boolean;
  deleted?: boolean;
  busy?: boolean;
  readOnly?: boolean;
  status?: 'confirmed' | 'tentative' | 'cancelled';
  topics?: Topic[];
  tags?: Tag[];
  files?: Attachment[];
  linkedInfo?: LinkedInfo[];
  notifications?: NotificationDetails[];

  // Tech properties

  /**
   * virtual - temporal event object - used for instance on calendar quick form badge
   *    can't be draged, hovered, clicked, etc
   */
  virtual: boolean;
  /**
   * originEvent - MouseEvent which causes creating of virtual event
   * needed for popover positioning
   */
  originEvent: MouseEvent;

  constructor(eventObject: any = {}) {
    this.id = eventObject.id;
    this.accountId = eventObject.accountId;
    this.messageId = eventObject.messageId;
    this.calendarId = eventObject.calendarId;
    this.color = eventObject.color;
    this.title = eventObject.title;
    this.description = eventObject.description;
    this.location = {
      address: eventObject.location && eventObject.location.address,
      coordinates: {
        longitude: eventObject.location && eventObject.location.coordinates && eventObject.location.coordinates.longitude,
        latitude: eventObject.location  && eventObject.location.coordinates && eventObject.location.coordinates.latitude,
      }
    };
    this.when = {
      durationType: eventObject.when && eventObject.when.durationType,
      start: eventObject.when && eventObject.when.start ? new Date(eventObject.when.start) : this.generateClosestDate(),
      end: eventObject.when && eventObject.when.end && new Date(eventObject.when.end),
    };
    if (!this.when.end) {
      this.when.end = new Date(this.when.start.getTime() + 3600000); // + 1 hour
    }
    this.owner = eventObject.owner;
    this.participants = eventObject.participants || [];
    this.pinned = eventObject.pinned;
    this.archived = eventObject.archived;
    this.reminder = eventObject.reminder;
    this.deleted = eventObject.deleted;
    this.busy = eventObject.busy;
    this.readOnly = eventObject.readOnly;
    this.status = eventObject.status;
    this.topics = eventObject.topics;
    this.tags = eventObject.tags;
    this.files = eventObject.files;
    this.linkedInfo = eventObject.linkedInfo || [];
    this.notifications = eventObject.notifications || [];
  }

  static fromMailMessage(message?: MailMessage): CalendarEvent {
    const event = new CalendarEvent();

    if (message) {
      event.title = message.subject || '';
      event.description = message.bodyText || '';
      event.participants = message.participants || [];
      event.topics = message.topics || [];
      event.tags = message.tags || [];
      event.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return event;
  }

  static fromEvent(originalEvent?: CalendarEvent): CalendarEvent {
    if (originalEvent) {
      const event = new CalendarEvent({...originalEvent});
      event.linkedInfo = originalEvent.id ? [new LinkedInfo('event', originalEvent.id)] : [];
      return event;
    } else {
      return new CalendarEvent();
    }
  }

  static fromProject(project?: Project): CalendarEvent {
    const event = new CalendarEvent();

    if (project) {
      event.title = project.title || '';
      event.description = project.description || '';
      event.topics = project.topics || [];
      event.tags = project.tags || [];
      event.linkedInfo = project.id ? [new LinkedInfo('project', project.id)] : [];
      event.when.start = project.fromTime;
      event.when.end = project.toTime;
      event.when.durationType =  project.fromTime && project.toTime ? 'timespan' : 'day';
    }

    return event;
  }

  static fromTask(task?: Task): CalendarEvent {
    const event = new CalendarEvent();

    if (task) {
      event.title = task.title || '';
      event.description = task.description || '';
      event.participants = task.participants || [];
      event.topics = task.topics || [];
      event.tags = task.tags || [];
      event.linkedInfo = task.id ? [new LinkedInfo('task', task.id)] : [];
      event.when.start = task.fromTime;
      event.when.end = task.toTime;
      event.when.durationType =  task.fromTime && task.toTime ? 'timespan' : 'day';
    }

    return event;
  }

  static fromNote(note?: Note): CalendarEvent {
    const event = new CalendarEvent();

    if (note) {
      event.title = note.title || '';
      event.topics = note.topics || [];
      event.tags = note.tags || [];
      event.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
    }

    return event;
  }

  static fromGroup(group?: Group): CalendarEvent {
    const event = new CalendarEvent();

    if (group) {
      event.title = group.name || '';
      event.topics = group.topics || [];
      event.tags = group.tags || [];
      event.linkedInfo = group.id ? [new LinkedInfo('group', group.id)] : [];
    }

    return event;
  }

  static fromContact(contact?: Contact): CalendarEvent {
    const event = new CalendarEvent();

    if (contact) {
      event.title = contact.name || '';
      event.topics = contact.topics || [];
      event.tags = contact.tags || [];
      event.linkedInfo = contact.id ? [new LinkedInfo('group', contact.id)] : [];
    }

    return event;
  }

  static fromDragData(dragData: DragData): CalendarEvent {
    let event: CalendarEvent = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        event = CalendarEvent.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        event = CalendarEvent.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        event = CalendarEvent.fromProject(<Project>dragItem);
        break;
      case 'task':
        event = CalendarEvent.fromTask(<Task>dragItem);
        break;
      case 'note':
        event = CalendarEvent.fromNote(<Note>dragItem);
        break;
      case 'group':
        event = CalendarEvent.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        event = CalendarEvent.fromContact(<Contact>dragItem);
        break;
    }

    return event;
  }

  static fromFormGroup(form: FormGroup): CalendarEvent {
    const formValues = form.value;

    let startTime, endTime;

    if (formValues.allDay) {
      startTime = new Date(
        Date.UTC(
          formValues.startDate.getFullYear(),
          formValues.startDate.getMonth(),
          formValues.startDate.getDate()
        )
      );
      if (formValues.endDate) {
        endTime = new Date(
          Date.UTC(
            formValues.endDate.getFullYear(),
            formValues.endDate.getMonth(),
            formValues.endDate.getDate()
          )
        );
      }
    } else {
      startTime = new Date(
        formValues.startDate.getFullYear(),
        formValues.startDate.getMonth(),
        formValues.startDate.getDate(),
        formValues.startTime && formValues.startTime.getHours() || 0,
        formValues.startTime && formValues.startTime.getMinutes() || 0, 0);
      if (formValues.endDate && formValues.endTime) {
        endTime = new Date(
          formValues.endDate.getFullYear(),
          formValues.endDate.getMonth(),
          formValues.endDate.getDate(),
          formValues.endTime && formValues.endTime.getHours() || 0,
          formValues.endTime && formValues.endTime.getMinutes() || 0, 0);
      }
    }

    return new CalendarEvent({
      id: formValues.id,
      accountId: formValues.accountId,
      messageId: formValues.messageId,
      calendarId: formValues.calendarId,
      color: formValues.color,
      title: formValues.title,
      description: formValues.description,
      location: {
        address: formValues.location && formValues.location.address
      },
      when: {
        start: startTime,
        end: endTime,
        durationType: formValues.allDay === true ? 'day' : 'timespan'
      },
      owner: formValues.owner,
      participants: formValues.participants,
      pinned: formValues.pinned,
      archived: formValues.archived,
      reminder: formValues.reminder,
      busy: formValues.busy,
      readOnly: formValues.readOnly,
      status: formValues.status,
      topics: formValues.topics,
      tags: formValues.tags,
      files: formValues.files,
      linkedInfo: formValues.linkedInfo,
      notifications: formValues.notifications
    });
  }

  static fromCalendarCell(
    originalEvent: CalendarEvent,
    newDate: Date,
    calendarType: CalendarType,
    virtual: boolean,
    originEvent?: MouseEvent,
  ): CalendarEvent {
    const event = originalEvent ? new CalendarEvent(originalEvent) : new CalendarEvent();
    let allDay = false;
    let rangeType = null;

    switch (calendarType) {
      case 'year':
      case 'month':
        rangeType = 'day';
        allDay = true;
        break;
      case 'week':
      case 'workWeek':
      case 'day':
        rangeType = 'hour';
        allDay = false;
        break;
    }

    event.when.durationType = allDay ? 'day' : 'timespan';

    if (newDate) {
      event.when.start = newDate;
      event.when.end = newDate;
    }

    if (
      rangeType === 'hour' &&
      newDate &&
      originalEvent &&
      originalEvent.when &&
      originalEvent.when.end &&
      originalEvent.when.start
    ) {
      const duration = originalEvent.when.end.getTime() - originalEvent.when.start.getTime();
      event.when.end = new Date(newDate.getTime() + duration);
    }

    if (!originalEvent && newDate) {
      event.when.end = new Date(newDate.getTime() + (rangeType === 'hour' ? 60 * 60 * 1000 : 0));
    }

    event.virtual = virtual;
    event.originEvent = originEvent;

    return event;
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    const eventColor = {
      primary: (this.color && this.color.length) ? this.color : '',
      secondary: '#ffffff' // Any color. Need for `AngularCalendarEvent` model compatibility.
    };
    return  {
      id: this.id,
      start: new Date(this.when.start),
      end: this.normalizedEndDate(),
      title: this.title,
      color: eventColor,
      actions: undefined,
      allDay: this.when.durationType === 'day',
      cssClass: undefined,
      resizable: undefined,
      draggable: undefined,
      meta: this
    };
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      accountId: [this.accountId],
      messageId: [this.messageId],
      calendarId: [this.calendarId],
      color: [this.color],
      title: [this.title || ''],
      description: [this.description || ''],
      location: this.formBuilder.group({
        address: [this.location && this.location.address || '']
      }),
      // Against using group when, we have to use separates fields for now
      startDate: [this.when && this.when.start, Validators.required],
      endDate: [this.when && this.when.end, Validators.required],
      startTime: [this.when && this.when.start],
      endTime: [this.when && this.when.end],
      allDay: [this.when && this.when.durationType === 'day'],
      owner: [this.owner],
      participants: [this.participants || []],
      pinned: [this.pinned],
      archived: [this.archived],
      reminder: [this.reminder],
      busy: [this.busy],
      readOnly: [this.readOnly],
      status: [this.status],
      topics: [this.topics || []],
      tags: [this.tags || []],
      files: [this.files || []],
      linkedInfo: [this.linkedInfo || []],
      // Fields not implemented in calendar event yet
      timeZone: [null],
      notifications: this.formBuilder.array(this.notifications.map(
        notification => this.formBuilder.group({ type: notification.type, duration: notification.duration, units: notification.units })
      )),
      availability: [null],
      visibility: [null],
      conferencing: [null],
      repeatType: ['noRepeat'],
      customRepeat: [null]
    });
  }

  asPayloadJson() {
    return {
      calendarId: this.calendarId,
      accountId: this.accountId,
      messageId: this.messageId,
      title: this.title,
      description: this.description,
      location: {
        address: this.location && this.location.address
      },
      busy: this.busy,
      participants: this.participants,
      status: this.status,
      when: {
        startTime: this.when.start && this.when.start.getTime(),
        endTime: this.when.end && this.when.end.getTime(),
        durationType: this.when.durationType
      },
      owner: this.owner,
      readOnly: this.readOnly,
      color: this.color,
      pinned: this.pinned,
      archived: this.archived,
      reminder: this.reminder,
      tags: this.tags,
      files: this.files,
      linkedInfo: this.linkedInfo,
      notifications: this.notifications
    };
  }

  // Returns next closest Date value, rounded to 30 minutes, like GC do
  private generateClosestDate(): Date {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      (now.getMinutes() - now.getMinutes() % 30 + 30),
      0,
      0
    );
  }

  private normalizedEndDate(): Date {
    if (this.when.durationType !== 'day') {
      if (this.when.end.getTime() - this.when.start.getTime() < 900000) {
        return new Date(this.when.start.getTime() + 900000); // needed for angular calendar right representation of less then 15 min events
      }

      // If event ends on beginning of a day, need to set on previous day, to prevent showing event dot where it should not be.
      if (this.when.end.getHours() === 0 && this.when.end.getMinutes() === 0 && this.when.end.getSeconds() === 0) {
        return new Date(this.when.end.getTime() - 1);
      }
    }

    return new Date(this.when.end);
  }
}
