import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Types
import { Group } from '@modules/contacts/types/group';
var GroupContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GroupContextMenuComponent, _super);
    /**
     * Constructor
     */
    function GroupContextMenuComponent(contextMenuService, contactsStateService, ga, groupsService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.contactsStateService = contactsStateService;
        _this.ga = ga;
        _this.groupsService = groupsService;
        _this.groupTitleFormControl = new FormControl();
        return _this;
    }
    /**
     * Component lifecycle
     */
    GroupContextMenuComponent.prototype.ngOnInit = function () {
        this.groupForm = this.group.asFormGroup();
    };
    /**
     * Actions
     */
    GroupContextMenuComponent.prototype.handleEdit = function () {
        this.contactsStateService.setMainViewGroup(this.group);
        this.contactsStateService.setMainView('group-form');
    };
    GroupContextMenuComponent.prototype.pin = function (groups) {
        this.ga.trackEvent('group-context-menu', 'pin');
        this.groupsService.pin(groups.filter(function (group) { return group.pinned !== true; }).map(function (group) { return group.id; }), true);
    };
    GroupContextMenuComponent.prototype.unpin = function (groups) {
        this.ga.trackEvent('group-context-menu', 'unpin');
        this.groupsService.pin(groups.filter(function (group) { return group.pinned === true; }).map(function (group) { return group.id; }), false);
    };
    GroupContextMenuComponent.prototype.delete = function (groups) {
        this.ga.trackEvent('group-context-menu', 'delete');
        this.groupsService.delete(groups.filter(function (group) { return group.deleted !== true; }).map(function (group) { return group.id; }), true);
    };
    GroupContextMenuComponent.prototype.deletePermanently = function (groups) {
        this.ga.trackEvent('group-context-menu', 'delete-permanently');
        this.groupsService.deletePermanently(groups.filter(function (group) { return group.deleted === true; }).map(function (group) { return group.id; }));
    };
    GroupContextMenuComponent.prototype.restoreFromTrash = function (groups) {
        this.ga.trackEvent('group-context-menu', 'restore-from-trash');
        this.groupsService.delete(groups.filter(function (group) { return group.deleted === true; }).map(function (group) { return group.id; }), false);
    };
    GroupContextMenuComponent.prototype.moveToArchive = function (groups) {
        this.ga.trackEvent('group-context-menu', 'move-to-archive');
        this.groupsService.archive(groups
            .filter(function (group) { return group.archived === false; })
            .map(function (group) { return group.id; }), true);
    };
    GroupContextMenuComponent.prototype.restoreFromArchive = function (groups) {
        this.ga.trackEvent('group-context-menu', 'restore-from-archive');
        this.groupsService.archive(groups
            .filter(function (group) { return group.archived === true; })
            .map(function (group) { return group.id; }), false);
    };
    GroupContextMenuComponent.prototype.handleRenameSubmit = function () {
        this.groupForm.patchValue({ title: this.groupTitleFormControl.value });
        this.groupsService.update(Group.fromFormGroup(this.groupForm));
        this.closeContextMenu();
    };
    GroupContextMenuComponent.prototype.handleRenameCancel = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    GroupContextMenuComponent.prototype.closeContextMenu = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    return GroupContextMenuComponent;
}(ContextMenuComponent));
export { GroupContextMenuComponent };
