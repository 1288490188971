import { Component, Input, OnChanges} from '@angular/core';
import { Contact } from '../../../mail/types/contact';
import { UserAvatarService } from '../../services/user-avatar.service';
import { UserAvatar } from '../../types/user-avatar';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.less']
})
export class UserAvatarComponent implements OnChanges {

  static readonly defaultColor: string = '#cccfdb';

  @Input() contact: Contact;
  @Input() usePopover = false;
  @Input() size = 32;
  @Input() color: string = UserAvatarComponent.defaultColor;

  avatar: UserAvatar;
  initials: string;
  opacity = 0;

  constructor(
    private userAvatarService: UserAvatarService
  ) {}

  ngOnChanges(changes): void {
    if (changes.contact || changes.size) {
      this.getInitials();
      this.getAvatar();
    }
  }

  getAvatar(): void {
    if (!this.contact) {
      this.avatar = null;
      return;
    }
    this.userAvatarService
      .fetch(this.contact.email, this.size, this.avatar)
        .subscribe(avatar => {
          this.avatar = avatar || null;
        });
  }

  saveAvatar(): void {
    this.opacity = 1;
    this.userAvatarService.save(
      this.contact.email,
      this.avatar
    );
  }

  private getInitials(): void {
    if (!this.contact) {
      this.initials = '';
      return;
    }
    const name = this.contact.name || this.contact.email || '';
    this.initials = name
      .trim()
      .split(' ')
      .filter(word => word && word.length > 0)
      .slice(0, 2)
      .map(word => word[0].toUpperCase())
      .join('');
  }
}
