// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ContentMenuComponent } from './components/content-menu/content-menu.component';
import { ContentMenuItemComponent } from './components/content-menu-item/content-menu-item.component';
import { ContentMenuTitleComponent } from './components/content-menu-title/content-menu-title.component';
import { ContentMenuCheckboxComponent } from './components/content-menu-checkbox/content-menu-checkbox.component';
import { ContentMenuDividerComponent } from './components/content-menu-divider/content-menu-divider.component';
import { ContentMenuTagComponent } from './components/content-menu-tag/content-menu-tag.component';
import { ContentMenuPelletComponent } from './components/content-menu-pellet/content-menu-pellet.component';
import { ContentMenuPelletsComponent } from './components/content-menu-pellets/content-menu-pellets.component';
import { PipesModule } from '@modules/pipes/pipes.module';

// Modules
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PopoverModule } from '../popover/popover.module';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { DropdownModule } from '../dropdown/dropdown.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ContentMenuTagsComponent } from './components/content-menu-tags/content-menu-tags.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    PopoverModule,
    NgxContextMenuModule.forRoot(),
    DropdownModule,
    AppCommonModule,
    DragNDropModule,
    PipesModule,
  ],
  declarations: [
    ContentMenuComponent,
    ContentMenuItemComponent,
    ContentMenuDividerComponent,
    ContentMenuTitleComponent,
    ContentMenuCheckboxComponent,
    ContentMenuPelletComponent,
    ContentMenuPelletsComponent,
    ContentMenuTagComponent,
    ContentMenuTagsComponent,
  ],
  exports: [
    ContentMenuComponent,
    ContentMenuItemComponent,
    ContentMenuDividerComponent,
    ContentMenuTitleComponent,
    ContentMenuCheckboxComponent,
    ContentMenuPelletComponent,
    ContentMenuPelletsComponent,
    ContentMenuTagComponent,
    ContentMenuTagsComponent,
  ],
  providers: []
})
export class ContentMenuModule { }
