<div
  class="group-form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableAfterDropTemplate]="dropPopoverFormTemplate"
  [appDroppableShowUntil]="closeDroppedElement"
  [appDroppableZIndex]="0"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>
  <div class="group-form-actions" *ngIf="group?.id">

    <mat-icon
      svgIcon="saved"
      class="group-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="pin"
      (click)="pin()"
      class="group-form-actions-button"
      [ngClass]="{active: group?.pinned}"
      [matTooltip]="group?.pinned ? 'Unpin' : 'Pin'"
    ></mat-icon>

    <mat-icon
      svgIcon="crown"
      class="group-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="mail"
      class="group-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="event"
      class="group-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="archive"
      (click)="archive()"
      class="group-form-actions-button"
      [ngClass]="{active: group?.archived}"
      [matTooltip]="group?.archived ? 'Remove From Archive' : 'Add To Archive'"
    ></mat-icon>

    <mat-icon
      svgIcon="trash-bucket"
      (click)="delete()"
      class="group-form-actions-button"
      [ngClass]="{active: group?.deleted}"
      [matTooltip]="group?.deleted ? 'Delete Permanently' : 'Delete'"
    ></mat-icon>

    <mat-icon
      svgIcon="more-actions"
      class="group-form-actions-button"
      matTooltip="More Options"
      [appProjectContextMenu]="group"
      contextMenuTriggerEvent="click"
    ></mat-icon>
  </div>

  <div class="group-form-related" *ngIf="group?.id && appearance === 'headerFixedBig'">
    <div class="group-form-related-buttons">
      <div class="group-form-related-button">
        <div class="group-form-related-button-inner">
          <mat-icon class="group-form-related-button-inner-icon" svgIcon="task" matTooltip="Coming soon"></mat-icon>
        </div>
      </div>
      <div class="group-form-related-button" (click)="openAttachmentsDP()">
        <div class="group-form-related-button-inner">
          <mat-icon class="group-form-related-button-inner-icon" svgIcon="attachment-small"></mat-icon>
        </div>
      </div>
      <div class="group-form-related-button" (click)="openLinkedInfoDP()">
        <div class="group-form-related-button-inner">
          <mat-icon class="group-form-related-button-inner-icon" svgIcon="link-thin"></mat-icon>
        </div>
      </div>
      <div class="group-form-related-button">
        <div class="group-form-related-button-inner">
          <mat-icon class="group-form-related-button-inner-icon" svgIcon="activity" matTooltip="Coming soon"></mat-icon>
        </div>
      </div>
    </div>

    <mat-icon
      appDraggable
      [appDraggableData]="{data: [event], type: 'event'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerAdjustFit]="true"
      [appDraggableContainerStyles]="{'min-width': '300px'}"
      class="group-form-related-drag-icon"
      svgIcon="drag-icon"
    ></mat-icon>
  </div>

  <div class="group-form-fixed-header" *ngIf="appearance === 'headerFixedBig'">
    <div class="group-form-fixed-header-columns">
      <div class="group-form-fixed-header-columns-input">
        <app-input
          placeholder="Name"
          [inputFormControl]="groupForm.controls['name']"
          appearance="standard"
          icon="group"
        ></app-input>
      </div>
      <div class="group-form-fixed-header-columns-buttons">
        <app-button class="group-form-fixed-header-columns-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
        <app-button class="group-form-fixed-header-columns-buttons-item" (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>
  </div>

  <div class="group-form-fixed-small-header" *ngIf="appearance === 'headerFixedSmall'">
    <div class="group-form-fixed-small-header-title">
      Add new group
    </div>
    <div class="group-form-fixed-small-header-buttons">
      <app-button class="group-form-fixed-small-header-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
      <app-button class="group-form-fixed-small-header-buttons-item" (click)="handleCancel()">Cancel</app-button>
    </div>
  </div>

  <form
    class="group-form-main"
    [formGroup]="groupForm"
    (ngSubmit)="handleSubmit()"
    *ngIf="appearance === 'noHeader' || appearance === 'headerFixedSmall'"
  >
    <div class="group-form-horizontal-group">
      <div class="group-form-horizontal-group-full-width-item">
        <app-input
          placeholder="Name"
          [inputFormControl]="groupForm.controls['name']"
          appearance="standard"
          icon="group"
        ></app-input>
      </div>
    </div>
  </form>

  <app-knowledge-header
    class="group-form-header"
    height="32px"
    [orders]="orderOptions"
    [selected]="selectedOrder"
    [search]="true"
    [titleTemplate]="headerTitleTemplate"
    (selectedChange)="selectOrder($event)"
    (searchChange)="handleSearchChange($event)"
    [appProjectContextMenu]="group"
    *ngIf="appearance === 'headerFixedBig'"
  ></app-knowledge-header>

  <app-group-form
    [group]="group"
    [groupForm]="groupForm"
    [appearance]="appearance"
    [bundledGroup]="bundledGroup"
    (openLinkedInfoDP)="openLinkedInfoDP()"
  ></app-group-form>

</div>

<ng-template #headerTitleTemplate>
  <div class="group-form-header-buttons">

  </div>
</ng-template>

<ng-template #dropPopoverFormTemplate>
  <div class="drop-popover">
    <app-button icon="tasks" appearance="big" (click)="handleCreateTaskOnDrop()">Create a Task</app-button>
    <div class="drop-popover-separator"></div>
    <app-button icon="link-bold" appearance="big" (click)="handleLinkOnDrop()">Link an Item</app-button>
  </div>
</ng-template>
