<div class="empty-state" *ngIf="!note">
  <img class="empty-state-image" src="assets/placeholders/placeholder-notes.svg"/>
</div>

<app-note-form
  *ngIf="note"
  appearance="headerFixedSmall"
  (close)="handleCancel()"
  [note]="note"
></app-note-form>
