// Common
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable, Subject, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

// Types
import { Note } from '../types/note';
import { NotesFilters } from '../types/notes-filters';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  // Public
  public createNewNote = new Subject<Note>();
  public updatedNote = new Subject<Note>();
  public deletedNote = new Subject<string>();

  // Private
  private refreshAll = new Subject<void>();

  /**
   * Settings
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private linkedInfoService: LinkedInfoService,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) {

  }

  /**
   * Helpers
   */

  private formatFilters(filters: NotesFilters): { [param: string]: string | string[]; } {
    const formatedFilters = {};
    if (filters.notesIds) { formatedFilters['notes_ids[]'] = filters.notesIds; }

    if (filters.archived) { formatedFilters['archived'] = filters.archived + ''; }
    if (filters.deleted) { formatedFilters['deleted'] = filters.deleted + ''; }
    if (filters.pinned) { formatedFilters['pinned'] = filters.pinned + ''; }
    if (filters.favorite) { formatedFilters['favorite'] = filters.favorite + ''; }

    if (filters.fromTime) { formatedFilters['from_time'] = filters.fromTime + ''; }
    if (filters.toTime) { formatedFilters['to_time'] = filters.toTime + ''; }
    if (filters.noDueDate) { formatedFilters['no_due_date'] = filters.noDueDate + ''; }

    if (filters.keywords) { formatedFilters['keywords[]'] = filters.keywords; }
    if (filters.topics) { formatedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formatedFilters['tags[]'] = filters.tags; }

    if (filters.order) { formatedFilters['order'] = filters.order; }
    if (filters.orderWithPinned) { formatedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }

    if (filters.limit) { formatedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formatedFilters['offset'] = filters.offset + ''; }

    return formatedFilters;
  }

  /**
   * Methods
   */

  doRefreshNotes(): void {
    this.refreshAll.next();
  }

  getRefresh(): Observable<void> {
    return this.refreshAll.asObservable();
  }

  getNotebooks(): Observable<string[]> {
    return of([]);
  }

  getNotes(filters: NotesFilters = {}): Observable<{notes: Note[], count: number}> {
    return this.http.get<{notes: Note[], count: number}>(
      `${environment.baseUrl}/api/notes`,
      { params: this.formatFilters(filters) }
    )
      .pipe(
        map(({ count, notes }) => ({count, notes: notes.map(note => new Note(note))})),
        catchError(NotesService.handleObserverError)
      );
  }

  getNote(id: string): Observable<Note> {
    return this.http.get<{ note: Note }>(`${environment.baseUrl}/api/notes/${id}`)
      .pipe(
        map(({ note }) => new Note(note)),
      );
  }

  @warmUpObservable
  createNote(note: Note): Observable<Note> {
    return this.http.post<{ note: Note }>(`${environment.baseUrl}/api/notes`, note.asPayloadJSON())
      .pipe(
        map(({ note: newNote }) => new Note(newNote)),
        tap(newNote => {
          if (note.linkedInfo) {
            this.linkedInfoService.linkToItem({type: 'note', id: newNote.id}, note.linkedInfo);
          }
        }),
        tap((newNote: Note) => {
          this.createNewNote.next(newNote);
          this.asyncTasksToastsService.show({ text: 'Note successfully created.' });
        })
      );
  }

  @warmUpObservable
  updateNote(note: Note): Observable<Note> {
    return this.http.put<{ note: Note }>(
      `${environment.baseUrl}/api/notes/${note.id}`,
      note.asPayloadJSON()
    )
      .pipe(
        map(({ note: newNote }) => new Note(newNote)),
        tap((newNote: Note) => this.updatedNote.next(newNote)),
      );
  }

  @warmUpObservable
  updateNotes(
    filters: NotesFilters = {},
    updates: {
      favorite?: boolean,
      pinned?: boolean,
      deleted?: boolean,
      archived?: boolean
    }
  ): Observable<boolean> {
    return this.http.put<{ notes: Note[] }>(
      `${environment.baseUrl}/api/notes/`,
      updates,
      { params: this.formatFilters(filters) }
    )
      .pipe(
        map(({ notes }) => !!notes),
        tap(() => this.updatedNote.next()),
      );
  }

  @warmUpObservable
  deleteNotes(filters: NotesFilters = {}): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/notes`,
      { params: this.formatFilters(filters) }
    )
      .pipe(
        map(({success}) => success),
        tap(success => {
          if (success) {
            this.deletedNote.next();
            this.asyncTasksToastsService.show({
              text: `Note${ filters.notesIds && filters.notesIds.length > 1 ? 's' : ''} successfully deleted.`
            });
          }
        })
      );
  }

  @warmUpObservable
  restoreNotes(filters: NotesFilters = {}): Observable<boolean> {
    return this.http.put<{ notes: Note[] }>(
      `${environment.baseUrl}/api/notes`,
      { deleted: false },
      { params: this.formatFilters({ ...filters, deleted: true }) }
    )
      .pipe(
        map(({ notes }) => !!notes),
        tap(success => {
          if (success) {
            this.updatedNote.next();
            this.asyncTasksToastsService.show({
              text: `Note${ filters.notesIds && filters.notesIds.length > 1 ? 's' : ''} successfully restored from trash.`
            });
          }
        })
      );
  }

  @warmUpObservable
  archiveNotes(filters: NotesFilters = {}, archive: boolean = true): Observable<boolean> {
    return this.http.put<{ notes: Note[] }>(
      `${environment.baseUrl}/api/notes`,
      { archived: archive },
      { params: this.formatFilters({ ...filters, archived: !archive }) }
    )
      .pipe(
        map(({ notes }) => !!notes),
        tap(success => {
          if (success) {
            this.updatedNote.next();
            this.asyncTasksToastsService.show({
              text: `Note${ filters.notesIds.length > 1 ? 's' : ''} successfully ${ archive ? 'archived' : 'restored from archive'}.`,
              actions: [{
                text: 'Undo',
                handler: () => this.archiveNotes(filters, !archive)
              }]
            });
          }
        })
      );
  }

}
