// Common
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Animations
import { collapseMotion } from '@modules/form-controls/animations/collapse-animation';

// Rx
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

// Types
import { File } from '@modules/files/types/file';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { MailService } from '@modules/mail/services/mail.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

@Component({
  selector: 'app-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.less'],
  animations: [collapseMotion]
})
export class FileFormComponent implements OnInit, OnChanges {

  // Inputs
  @Input() file: File = new File();
  @Input() appearance: any;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public fileForm: FormGroup = this.file.asFormGroup();
  public linkedInfo: any[];

  // Private
  private dynamicPanel: string;
  private componentNotDestroyed = new Subject<void>();

  // Constructor
  constructor(
    private dynamicPanelService: DynamicPanelService,
    private tasksService: TasksService,
    private eventsService: CalendarService,
    private mailsService: MailService,
    private notesService: NotesService,
  ) {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  // LifeCycle
  ngOnInit() {
    this.fileForm = this.file.asFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('file' in changes && this.file) {
      this.fileForm = this.file.asFormGroup();
      this.dynamicPanelService.setContent();

      this.linkedInfo = [];

      combineLatest([
        this.tasksService.getTasks({ limit: 2, offset: this.getRandomNumber() }),
        this.eventsService.getEvents({ limit: 1, offset: this.getRandomNumber() }),
        this.mailsService.getMessages({ folder: 'inbox' }, 'ASC', 3, this.getRandomNumber()),
        this.notesService.getNotes({ limit: 2, offset: this.getRandomNumber() })
      ])
        .pipe(
          map(([{ tasks }, { events }, { messages }, { notes }]) => {
            return [
              ...this.transformData(tasks, 'task'),
              ...this.transformData(events, 'event'),
              ...this.transformData(messages, 'message'),
              ...this.transformData(notes, 'note')
            ];
          }),
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(linkedInfo => this.linkedInfo = linkedInfo);
    }
  }

  // Actions
  public handleCancel() {
    this.close.emit();
  }

  public openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('attachments', { attachments: [], parentTitle: this.file.title });
    }
  }

  public openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.linkedInfo,
        parentLinkedInfo: { type: 'task', id: this.file.id },
        parentTitle: this.file.title
      });
    }
  }

  // Helpers
  private transformData(data: Array<any>, type: string): Array<any> {
    return data.map(item => ({ id: item.id, type: type, createdAt: item.createdAt }));
  }

  private getRandomNumber(): number {
    return Math.floor(Math.random() * 10);
  }
}
