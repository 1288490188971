/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-menu-divider.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./content-menu-divider.component";
var styles_ContentMenuDividerComponent = [i0.styles];
var RenderType_ContentMenuDividerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentMenuDividerComponent, data: {} });
export { RenderType_ContentMenuDividerComponent as RenderType_ContentMenuDividerComponent };
export function View_ContentMenuDividerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-menu-divider-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [["class", "divider"]], null, null, null, null, null))], null, null); }
export function View_ContentMenuDividerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-menu-divider", [], null, null, null, View_ContentMenuDividerComponent_0, RenderType_ContentMenuDividerComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContentMenuDividerComponent, [], null, null)], null, null); }
var ContentMenuDividerComponentNgFactory = i1.ɵccf("app-content-menu-divider", i2.ContentMenuDividerComponent, View_ContentMenuDividerComponent_Host_0, {}, {}, []);
export { ContentMenuDividerComponentNgFactory as ContentMenuDividerComponentNgFactory };
