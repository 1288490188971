/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachment-icon.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./attachment-icon.component";
var styles_AttachmentIconComponent = [i0.styles];
var RenderType_AttachmentIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentIconComponent, data: {} });
export { RenderType_AttachmentIconComponent as RenderType_AttachmentIconComponent };
function View_AttachmentIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileType.text; _ck(_v, 1, 0, currVal_0); }); }
function View_AttachmentIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.fileType.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AttachmentIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "attachment-icon"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentIconComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentIconComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fileType.text; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.fileType.icon && !_co.fileType.text); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileType.color; _ck(_v, 0, 0, currVal_0); }); }
export function View_AttachmentIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-attachment-icon", [], null, null, null, View_AttachmentIconComponent_0, RenderType_AttachmentIconComponent)), i1.ɵdid(1, 638976, null, 0, i5.AttachmentIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentIconComponentNgFactory = i1.ɵccf("app-attachment-icon", i5.AttachmentIconComponent, View_AttachmentIconComponent_Host_0, { type: "type" }, {}, []);
export { AttachmentIconComponentNgFactory as AttachmentIconComponentNgFactory };
