// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Components
import { DraggableElementComponent } from './components/draggable-element/draggable-element.component';
import { OrderedItemComponent } from './components/ordered-item/ordered-item.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';

// Directives
import { DraggableDirective } from './directives/draggable.directive';
import { DroppableDirective } from './directives/droppable.directive';
import { ForOrderedItemsDirective } from './directives/app-draggable-list-for.directive';

// Services
import { DragNDropService } from './services/drag-n-drop.service';

// Pipes
import { OrderedItemZonePositionPipe } from './pipes/ordered-item-zone-position.pipe';
import { OrderedItemPlaceholderSizePipe } from './pipes/ordered-item-placeholder-size.pipe';

@NgModule({
  exports: [
    DraggableDirective,
    DroppableDirective,
    ForOrderedItemsDirective,
  ],
  declarations: [
    DraggableDirective,
    DroppableDirective,
    ForOrderedItemsDirective,
    DraggableElementComponent,
    OrderedItemComponent,
    OrderedItemZonePositionPipe,
    OrderedItemPlaceholderSizePipe
  ],
  imports: [
    AngularCommonModule,
    OverlayModule,
  ],
  providers: [
    DragNDropService,
    OrderedItemZonePositionPipe,
    OrderedItemPlaceholderSizePipe
  ],
  entryComponents: [
    DraggableElementComponent,
    OrderedItemComponent
  ]
})
export class DragNDropModule {}
