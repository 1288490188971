// Common
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { Note } from '@modules/notes/types/note';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Tag } from '@modules/tag/types/tag';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.less']
})
export class NoteComponent implements OnChanges, OnDestroy {

  // Inputs
  @Input() note: Note;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() selectedNotes: Note[] = [];

  // Public
  public selected = false;
  public contextMenuOpened = false;
  public isDragging = false;
  public dragData: Note[] = [];

  // Private
  private componentNotDestroyed = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'note' && dragData.data.length === 1 && dragData.data[0]['id'] === this.note.id) &&
    ['message', 'event', 'task', 'project', 'note', 'topic', 'tag', 'file'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected notesService: NotesService,
    protected linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService,
    protected modalService: ModalService,
    protected changeDetector: ChangeDetectorRef,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('note' in changes) {
      this.dragData = [this.note];
    }
    if ('selectedNotes' in changes || 'note' in changes) {
      if (
        this.note &&
        this.selectedNotes &&
        this.selectedNotes.some(selected => selected.id === this.note.id)
      ) {
        this.dragData = this.selectedNotes;
        this.selected = true;
      } else {
        this.selected = false;
        this.dragData = [this.note];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  favoriteNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.favorite = !this.note.favorite;
    this.notesService.updateNote(this.note);
  }

  pinNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.pinned = !this.note.pinned;
    this.notesService.updateNote(this.note);
  }

  snoozeNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: Add handler
  }

  createMail(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.compose(MailMessage.fromNote(this.note));
  }

  followUpNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: Add handler
  }

  archiveNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.note.archived = !this.note.archived;
    this.notesService.archiveNotes({notesIds: [this.note.id]}, this.note.archived);
  }

  deleteNote(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.note.deleted) {
      this.modalService.confirmationModal(
        'Would you like to delete note(s) from trash?',
        'Delete Note(s)',
        'Yes',
        'No',
        (success: boolean) => {
          if (success) {
            this.notesService.deleteNotes({notesIds: [this.note.id]});
          }
        });
    } else {
      this.notesService.deleteNotes({notesIds: [this.note.id]});
    }
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    // Message | Event | Project | Task | Note
    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('note', this.note.id), linkedItems);
    }
    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {notesIds: [this.note.id]});
    }
    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {notesIds: [this.note.id]});
    }
  }

}
