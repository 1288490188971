import * as tslib_1 from "tslib";
// Common
import { OnInit, EventEmitter, SimpleChanges, OnChanges, TemplateRef, ElementRef } from '@angular/core';
import { QueryList, AfterViewInit } from '@angular/core';
import { CalendarDateFormatter } from 'angular-calendar';
import { isSameDay } from 'date-fns';
// Pipes
import { DatePipe } from '@angular/common';
// RX
import { Subject } from 'rxjs';
// Override month header week names formatter
var CalendarMonthDateFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarMonthDateFormatter, _super);
    function CalendarMonthDateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CalendarMonthDateFormatter.prototype.monthViewColumnHeader = function (_a) {
        var date = _a.date, locale = _a.locale;
        return new DatePipe(locale).transform(date, 'EEEEE', locale);
    };
    return CalendarMonthDateFormatter;
}(CalendarDateFormatter));
export { CalendarMonthDateFormatter };
var FullCalendarYearComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function FullCalendarYearComponent() {
        // Outputs
        this.dateClicked = new EventEmitter();
        this.dateDblClicked = new EventEmitter();
        this.loadDayEvents = new EventEmitter();
        // Public
        this.refreshCalendar = new Subject();
    }
    /**
     * Component lifecycle
     */
    FullCalendarYearComponent.prototype.ngOnInit = function () { };
    FullCalendarYearComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewDate && this.viewDate.getFullYear() !== this.selectedYear) {
            this.generateMonthsList(this.viewDate.getFullYear());
            this.refreshView();
        }
        if (changes.highlightedDay || changes.selectedDate) {
            this.checkScrollNecessity(changes.highlightedDay && changes.highlightedDay.currentValue);
            this.checkScrollNecessity(changes.selectedDate && changes.selectedDate.currentValue);
            this.refreshCalendar.next();
        }
    };
    FullCalendarYearComponent.prototype.ngAfterViewInit = function () {
        this.checkScrollNecessity(this.selectedDate);
    };
    /**
     *  Angular Calendar
     */
    FullCalendarYearComponent.prototype.generateMonthsList = function (year) {
        this.selectedYear = year;
        this.monthsList = [];
        for (var month = 0; month < 12; month++) {
            this.monthsList.push(new Date(year, month, 1));
        }
        this.refreshView();
    };
    FullCalendarYearComponent.prototype.beforeMonthViewRender = function (_a) {
        var _this = this;
        var body = _a.body;
        body.forEach(function (day, i) {
            if (i > 27 && day.date.getDate() < 8) {
                day.cssClass = 'cal-day-cell-force-show';
            }
            else {
                if (_this.highlightedDay && isSameDay(_this.highlightedDay, day.date)) {
                    day.cssClass += ' cal-day-cell-highlighted';
                }
                if (_this.selectedDate && isSameDay(_this.selectedDate, day.date)) {
                    day.cssClass += ' cal-day-cell-selected';
                }
            }
        });
    };
    FullCalendarYearComponent.prototype.refreshView = function () {
        this.refreshCalendar.next();
    };
    FullCalendarYearComponent.prototype.clickDay = function (event, date, origin) {
        this.loadDayEvents.emit(date);
        this.dateClicked.emit({ date: date, originRef: new ElementRef(origin), event: event });
    };
    FullCalendarYearComponent.prototype.dblClickDay = function (date) {
        this.dateDblClicked.emit(date);
    };
    FullCalendarYearComponent.prototype.handleLoadDayEvents = function (date) {
        this.loadDayEvents.emit(date);
    };
    FullCalendarYearComponent.prototype.checkScrollNecessity = function (date) {
        var monthRef = date && this.monthsRefs && this.monthsRefs.toArray()[date.getMonth()];
        if (monthRef) {
            if (monthRef.nativeElement.offsetTop + monthRef.nativeElement.clientHeight
                > this.monthsContainer.nativeElement.scrollTop + this.monthsContainer.nativeElement.clientHeight) {
                this.monthsContainer.nativeElement.scrollTop += monthRef.nativeElement.offsetTop + monthRef.nativeElement.clientHeight
                    - this.monthsContainer.nativeElement.scrollTop - this.monthsContainer.nativeElement.clientHeight;
            }
            else if (monthRef.nativeElement.offsetTop < this.monthsContainer.nativeElement.scrollTop) {
                this.monthsContainer.nativeElement.scrollTop = monthRef.nativeElement.offsetTop;
            }
        }
    };
    return FullCalendarYearComponent;
}());
export { FullCalendarYearComponent };
