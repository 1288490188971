import * as tslib_1 from "tslib";
// Common
import { EventEmitter, ChangeDetectorRef } from '@angular/core';
// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';
var ContentMenuPelletComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentMenuPelletComponent, _super);
    /**
     * Constructor
     */
    function ContentMenuPelletComponent(changeDetector, topicService, modalService, contentMenuService) {
        var _this = _super.call(this, changeDetector, contentMenuService) || this;
        _this.topicService = topicService;
        _this.modalService = modalService;
        // Outputs
        _this.selected = new EventEmitter();
        // Callable attributes
        _this.dndPredicate = function (dragData) { return ['message', 'event', 'task', 'note'].includes(dragData.type); };
        _this.headerHeight = 24;
        return _this;
    }
    /**
     * Actions
     */
    ContentMenuPelletComponent.prototype.select = function (event) {
        var isMultiSelect = event.ctrlKey || event.shiftKey || event.metaKey;
        this.selected.emit(isMultiSelect);
    };
    ContentMenuPelletComponent.prototype.unpinTopic = function (topic) {
        this.topicService.pinTopics([{ name: topic }], false);
    };
    ContentMenuPelletComponent.prototype.unpinAllTopics = function () {
        this.topicService.unpinAllTopics();
    };
    ContentMenuPelletComponent.prototype.openTopicsModal = function () {
        this.modalService.topicList();
    };
    /**
     * Drag and drop
     */
    ContentMenuPelletComponent.prototype.dndDrop = function (dragData) {
        this.topicService.createTopics([{ name: this.title }], {
            messagesIds: dragData.type === 'message' && dragData.data ? dragData.data.map(function (mail) { return mail.id; }) : [],
            eventsIds: dragData.type === 'event' && dragData.data ? dragData.data.map(function (event) { return event.id; }) : [],
            projectsIds: dragData.type === 'project' && dragData.data ? dragData.data.map(function (project) { return project.id; }) : [],
            tasksIds: dragData.type === 'task' && dragData.data ? dragData.data.map(function (task) { return task.id; }) : [],
            notesIds: dragData.type === 'note' && dragData.data ? dragData.data.map(function (note) { return note.id; }) : []
        });
    };
    return ContentMenuPelletComponent;
}(ContentMenuBaseItemComponent));
export { ContentMenuPelletComponent };
