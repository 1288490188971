<app-dropdown align="right" [closeOnSelect]="true">
  <ng-template #toggleButtonTemplate>
    <mat-icon svgIcon="signature" class="toolbar-icon" matTooltip="Signature"></mat-icon>
  </ng-template>
  <app-dropdown-single-select
    title="Signatures"
    [options]="signaturesOptions"
    [selected]="signaturesSelectedOption"
    (selectedChange)="selectSignature($event)"
    [divider]="signaturesOptions?.length"
  >
  </app-dropdown-single-select>
  <app-dropdown-action-items
    [options]="[{name: 'Add new signature...', key: 'add'}]"
    (actionSelected)="addSignature()"
    *ngIf="signaturesOptions"
  >
  </app-dropdown-action-items>
  <app-loader *ngIf="!signaturesOptions"></app-loader>
</app-dropdown>
