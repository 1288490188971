// Common
import { Injectable } from '@angular/core';

// RxJS
import { Observable, Subscriber } from 'rxjs';

// Types
import { GoogleApiConfig } from '../types/google-config';

// Env
import { environment } from '@environment';

@Injectable()
export class GoogleApiService {
  private readonly gapiUrl: string = 'https://apis.google.com/js/platform.js';
  private isLoaded = false;
  private config: GoogleApiConfig;

  constructor() {
    this.config = new GoogleApiConfig({
      clientId: environment.socialAuth.google.clientId,
      discoveryDocs: [],
      scope: environment.socialAuth.google.scope.join(' ')
    });
    this.loadGapi();
  }

  public onLoad(callback: () => void) {
    if (this.isLoaded) {
      callback();
      return;
    }
    this.loadGapi().subscribe(callback);
  }

  public getConfig(): GoogleApiConfig {
    return this.config;
  }

  private loadGapi(): Observable<void> {
    return new Observable((observer: Subscriber<void>) => {
      const node = document.createElement('script');
      node.src = this.gapiUrl;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
      node.onload = () => {
        observer.next();
        observer.complete();
        this.isLoaded = true;
      };
    });
  }
}
