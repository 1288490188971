// Common
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Editor } from 'tinymce';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Types
import { TextEditorTemplate } from '../../types/text-editor-template';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.less'],
})
export class TextEditorComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() content: string;
  @Input() templates: TextEditorTemplate[];
  @Input() showMessageActions = false;

  // Outputs
  @Output() contentChange = new EventEmitter<string>();
  @Output() addNewTemplate = new EventEmitter<void>();
  @Output() addAttachments = new EventEmitter<FileList>();
  @Output() dragOver = new EventEmitter<DragEvent>();

  // Public
  public toolbarExpanded = true;
  public editorSettings = {
    content_style: `
      blockquote table.mce-item-table, blockquote table.mce-item-table td {border: none;}
      .mce-content-body {font-size: 14px;}
    `,
    forced_root_block: 'div',
    plugins: ['lists', 'link', 'paste', 'image', 'table'],
    toolbar: false,
    menubar: false,
    browser_spellcheck: true,
    link_context_toolbar: true,
    statusbar: false,
    elementpath: false
  };

  public formats = {
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    pasteText: false,
    h1: false,
    h2: false,
    fontname: null,
    fontsize: '14px',
    forecolor: null,
    hilitecolor: null,
    align: 'alignleft',
    lineheight: null
  };

  public signature: string;
  public fontSizeOptions: DropdownOption[];
  public templatesOptions: DropdownOption[];
  public alignOptions = [
    {name: 'Left', key: 'alignleft', action: 'JustifyLeft'},
    {name: 'Right', key: 'alignright', action: 'JustifyRight'},
    {name: 'Center', key: 'aligncenter', action: 'JustifyCenter'},
    {name: 'Justift', key: 'alignjustify', action: 'JustifyFull'}
  ];
  public lineHeightOptions: DropdownOption[] = [
    {name: 'Normal', key: 'normal'},
    {name: '1.2', key: '1.2'},
    {name: '1.5', key: '1.5'},
    {name: '1.8', key: '1.8'},
    {name: '2', key: '2'},
  ];
  public hideEmojiPopover = new Subject();

  // Protected
  protected editor: Editor;
  protected fontSizes = ['8px', '10px', '12pt', '14pt', '18pt', '24pt', '36pt'];

  /**
   * Constructor
   */

  constructor(
    protected stateService: StateService
  ) {}

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.fontSizeOptions = this.fontSizes.map(fontSize => ({name: fontSize, key: fontSize}));
    this.toolbarExpanded = this.stateService.isTextEditorToolbarExpanded;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('templates' in changes && this.templates) {
      this.templatesOptions = this.templates.map(template => ({ name: template.title, key: template.id }));
    }
  }

  ngOnDestroy() {
    this.hideEmojiPopover.next();
    this.hideEmojiPopover.complete();
  }

  /**
   * Actions
   */

  updateContent() {
    if (this.signature) {
      this.applySignature(this.signature, false);
    }
  }

  contentChanged(content: string) {
    this.content = content;
    this.contentChange.emit(this.content);
  }

  toggleToolbar() {
    this.toolbarExpanded = !this.toolbarExpanded;
    this.stateService.isTextEditorToolbarExpanded = this.toolbarExpanded;
  }

  editorInitialized({ editor }: { editor: Editor }) {
    if (!editor) {
      return;
    }

    editor.formatter.register({
      lineheight: {
        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
        styles: { 'line-height': '%value' }
      }
    });

    // Work around to make undo remve last word and not entire line
    editor.on('keyup', (event: KeyboardEvent) => {
      if (event && event.code === 'Space') {
        editor.undoManager.add();
      }
    });
    const formats = ['bold', 'italic', 'underline', 'strikethrough', '', 'h1', 'h2'];
    const alignFormats = ['alignleft', 'aligncenter', 'alignright', 'alignjustify'];
    formats.forEach(format => {
      // @ts-ignore: Incorrect type definition in tinymce interface
      editor.formatter.formatChanged(format, state => {
        this.formats[format] = state;
      }, false);
    });
    alignFormats.forEach(format => {
      // @ts-ignore: Incorrect type definition in tinymce interface
      editor.formatter.formatChanged(format, state => {
        if (state) {
          this.formats.align = format;
        }
      }, false);
    });
    editor.on('NodeChange', nodeChange => {
      this.formats.fontname = editor.queryCommandValue('FontName');
      this.formats.fontsize = editor.queryCommandValue('FontSize') as string;
      this.formats.forecolor = editor.queryCommandValue('ForeColor');
      this.formats.hilitecolor = editor.queryCommandValue('HiliteColor');
    });
    editor.on('PastePlainTextToggle', ({ state }: { state: boolean }) => {
      this.formats.pasteText = state;
    });

    this.editor = editor;
  }

  editorDragOver(event: EventObj<DragEvent>) {
    this.dragOver.emit(event.event);
  }

  execCommand(command: string, value?: string) {
    if (this.editor) {
      this.editor.execCommand(command, false, value);
    }
  }

  applyFormat(name: string, value: string) {
    if (this.editor) {
      this.editor.formatter.apply(name, { value });
    }
  }

  /**
   * Custom actions
   */

  selectLineHeight(option: DropdownOption) {
    this.editor.formatter.apply('lineheight', {value: option.key});
  }

  insertEmoji(emoji: { emoji: { native: string } }) {
    this.execCommand('mceInsertContent', emoji.emoji.native);
    this.hideEmojiPopover.next();
  }

  selectedFiles(event: Event) {
    const filesInput = (event.target as HTMLInputElement);
    if (filesInput && filesInput.files) {
      this.addAttachments.emit(filesInput.files);
    }
    filesInput.value = null;
  }

  /**
   * Template
   */

  insertTemplate(option: DropdownOption) {
    const template = this.templates.find(currentTemplate => currentTemplate.id === option.key);
    if (template) {
      this.execCommand('mceInsertContent', template.content);
    }
  }

  addTemplate() {
    this.addNewTemplate.emit();
  }

  /**
   * Signature
   */

  applySignature(signature: string, rewrite = true) {
    this.signature = signature;
    this.content = this.content || '<p></p>';
    const signatureContent = `<!-- Signature start -->${signature}<!-- Signature end -->`;
    const signaturePlace = this.content.match(/<!-- Signature start -->(.*?)<!-- Signature end -->/is);
    if (!signaturePlace) {
      this.content = this.content + signatureContent;
    } else if (!signaturePlace[1] || (signaturePlace[1] && rewrite)) {
      this.content = this.content.replace(signaturePlace[0], signatureContent);
    }
    this.contentChange.emit(this.content);
  }

}
