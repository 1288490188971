// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Components
import { MessageComponent } from '@modules/elements/components/message/message.component';

// Types
import { LinkedInfo } from '../../types/linked-info';

@Component({
  selector: 'app-link-info-message',
  templateUrl: './link-info-message.component.html',
  styleUrls: ['./link-info-message.component.less']
})
export class LinkInfoMessageComponent extends MessageComponent implements OnInit {

  // Inputs
  @Input() linkedInfo: LinkedInfo;
  @Input() shortView = false;

  // Outputs
  @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit(this.linkedInfo);
  }

  openMessage() {
    if (this.message) {
      this.modalService.mailContent(this.message);
    }
  }
}
