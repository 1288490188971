<div class="subtasks">
  <div
    class="subtasks-item"
    *ngFor="let task of subtasks; let index = index"
  >
    <mat-icon *ngIf="index % 3 === 0" class="subtasks-item-icon" svgIcon="task-done-0"></mat-icon>
    <mat-icon *ngIf="index % 3 === 1" class="subtasks-item-icon" svgIcon="task-done-25"></mat-icon>
    <mat-icon *ngIf="index % 3 === 2" class="subtasks-item-icon" svgIcon="task-done-100"></mat-icon>
    <input
      #input
      class="subtasks-item-input"
      [(ngModel)]="task.title"
      placeholder="Type something"
      (keyup.enter)="handleEnter($event)"
      (blur)="handleBlur(index)"
    >
  </div>
</div>

<div class="text-button" (click)="handleNewTask()">+ Add Subtask</div>
