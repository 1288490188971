import { Injectable } from '@angular/core';

// RX
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutStateService {

  // Private
  private linkedInfoToolbarPresent = new BehaviorSubject<boolean>(false);
  private linkedKPInfoToolbarPresent = new BehaviorSubject<boolean>(false);

  // TODO there will be also LinkedInfo toolbars for the columns 2,3,4 - need to find better naming of it
  setLinkedInfoToolbarPresence(value: boolean) {
    this.linkedInfoToolbarPresent.next(value);
  }

  getLinkedInfoToolbarPresence(): Observable<boolean> {
    return this.linkedInfoToolbarPresent.asObservable();
  }

  setKPLinkedInfoToolbarPresence(value: boolean) {
    this.linkedKPInfoToolbarPresent.next(value);
  }

  getKPLinkedInfoToolbarPresence(): Observable<boolean> {
    return this.linkedKPInfoToolbarPresent.asObservable();
  }

}
