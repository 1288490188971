// RxJS
import { timer } from 'rxjs';
import { map, switchMap, takeWhile, last } from 'rxjs/operators';
// Env
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AsyncTasksService = /** @class */ (function () {
    function AsyncTasksService(http) {
        this.http = http;
    }
    AsyncTasksService.prototype.get = function (task) {
        return this.http.get(environment.baseUrl + "/api/async-tasks/" + task.type + "/" + task.id)
            .pipe(map(function (_a) {
            var asyncTask = _a.asyncTask;
            return asyncTask;
        }));
    };
    AsyncTasksService.prototype.await = function (task) {
        var _this = this;
        return timer(0, 1000)
            .pipe(switchMap(function () { return _this.get(task); }), map(function (asyncTask) {
            if (asyncTask.status === 'error') {
                throw new Error("Async task failed: " + (asyncTask.errorMessage || 'unknown'));
            }
            return asyncTask.status === 'completed';
        }), takeWhile(function (done) { return !done; }, true), last());
    };
    AsyncTasksService.prototype.undo = function (task) {
        return this.http.post(environment.baseUrl + "/api/async-tasks/" + task.type + "/" + task.id + "/undo", {})
            .pipe(map(function (_a) {
            var asyncTask = _a.asyncTask, data = _a.data;
            return ({ asyncTask: asyncTask, data: data });
        }));
    };
    AsyncTasksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AsyncTasksService_Factory() { return new AsyncTasksService(i0.ɵɵinject(i1.HttpClient)); }, token: AsyncTasksService, providedIn: "root" });
    return AsyncTasksService;
}());
export { AsyncTasksService };
