/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./temporal-expression.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-contextmenu";
import * as i4 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i5 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i6 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i7 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i8 from "./temporal-expression.component";
var styles_TemporalExpressionComponent = [i0.styles];
var RenderType_TemporalExpressionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemporalExpressionComponent, data: {} });
export { RenderType_TemporalExpressionComponent as RenderType_TemporalExpressionComponent };
function View_TemporalExpressionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["class", "temporal-expression-wrapper text-no-select"], ["container", "body"], ["placement", "top"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected": 0, "status-available": 1, "status-partially": 2, "status-busy": 3 }), i1.ɵdid(4, 16384, null, 0, i3.ɵa, [i3.ContextMenuService], { contextMenuSubject: [0, "contextMenuSubject"], contextMenu: [1, "contextMenu"] }, null), i1.ɵdid(5, 212992, null, 0, i4.TooltipDirective, [i1.ViewContainerRef, i1.Renderer2, i1.ElementRef, i5.ComponentLoaderFactory, i6.TooltipConfig], { tooltip: [0, "tooltip"], placement: [1, "placement"], container: [2, "container"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", "\n"])), i1.ɵppd(8, 2), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "temporal-expression-wrapper text-no-select"; var currVal_1 = _ck(_v, 3, 0, _co.selected, (_co.expression.availability === "available"), (_co.expression.availability === "partially"), (_co.expression.availability === "busy")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.expression; var currVal_3 = i1.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 10); var currVal_5 = "top"; var currVal_6 = "body"; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.expression.fromTime, "medium")); _ck(_v, 7, 0, currVal_7); }); }
function View_TemporalExpressionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Show Calendar "]))], null, null); }
function View_TemporalExpressionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Create Appointment "]))], null, null); }
function View_TemporalExpressionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Direct Time: "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _co.expression.fromTime, "medium")); _ck(_v, 3, 0, currVal_0); }); }
function View_TemporalExpressionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["From Time: "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To Time: "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.expression.fromTime, "medium")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _co.expression.toTime, "medium")); _ck(_v, 9, 0, currVal_1); }); }
function View_TemporalExpressionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Original text: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemporalExpressionComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemporalExpressionComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.expression.toTime; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.expression.toTime; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expression.text; _ck(_v, 3, 0, currVal_0); }); }
export function View_TemporalExpressionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemporalExpressionComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "context-menu", [], null, null, null, i7.View_ContextMenuComponent_0, i7.RenderType_ContextMenuComponent)), i1.ɵdid(4, 180224, [["expressionMenu", 4]], 1, i3.ContextMenuComponent, [i3.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i3.ɵc]], { disabled: [0, "disabled"] }, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.showInCalendarHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TemporalExpressionComponent_2)), i1.ɵdid(7, 16384, [[1, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createAppointmentHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TemporalExpressionComponent_3)), i1.ɵdid(9, 16384, [[1, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, [["tooltipTemplate", 2]], null, 0, null, View_TemporalExpressionComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expression; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.disableContextMenu; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TemporalExpressionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-temporal-expression", [], null, null, null, View_TemporalExpressionComponent_0, RenderType_TemporalExpressionComponent)), i1.ɵdid(1, 114688, null, 0, i8.TemporalExpressionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemporalExpressionComponentNgFactory = i1.ɵccf("app-temporal-expression", i8.TemporalExpressionComponent, View_TemporalExpressionComponent_Host_0, { expression: "expression", selected: "selected", disableContextMenu: "disableContextMenu" }, { showInCalendar: "showInCalendar", createAppointment: "createAppointment" }, []);
export { TemporalExpressionComponentNgFactory as TemporalExpressionComponentNgFactory };
