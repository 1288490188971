import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { File } from '@modules/files/types/file';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.less']
})
export class FileComponent implements OnInit, OnDestroy, OnChanges {
// Inputs
  @Input() file: File;
  @Input() selectedFiles: File[];
  @Input() dragEnabled = true;

  // Public
  public selected = false;
  public dragData: File[] = [];
  public isDragging = false;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('file' in changes) {
      this.dragData = [this.file];
    }
    if ('selectedFiles' in changes) {
      if (
        this.file &&
        this.selectedFiles &&
        this.selectedFiles.some(selected => selected.id === this.file.id)
      ) {
        this.selected = true;
        this.dragData = this.selectedFiles;
      } else {
        this.selected = false;
        this.dragData = [this.file];
      }
    }
  }

  /**
   * Actions
   */
}
