import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MailMessage } from '@modules/mail/types/mail-message';
// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';
var LinkInfoMessageSuggestionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoMessageSuggestionsComponent, _super);
    /**
     * Constructor
     */
    function LinkInfoMessageSuggestionsComponent(modalService, mailService, linkedInfoService) {
        var _this = _super.call(this, linkedInfoService) || this;
        _this.modalService = modalService;
        _this.mailService = mailService;
        _this.linkedInfoService = linkedInfoService;
        return _this;
    }
    /**
     * Methods
     */
    //  Override method LinkInfoItemSuggestions
    LinkInfoMessageSuggestionsComponent.prototype.getSuggestions = function (inputValue) {
        if (inputValue && inputValue.length) {
            return this.mailService.getMessages({ relevance: true, keywords: [inputValue] }, 'date', 10, 0)
                .pipe(map(function (response) { return response.messages || []; }));
        }
        return of([]);
    };
    //  Override method LinkInfoItemSuggestions
    LinkInfoMessageSuggestionsComponent.prototype.openCreateModal = function () {
        var message = new MailMessage;
        message.linkedInfo = this.linkedInfo;
        this.modalService.compose(message);
    };
    return LinkInfoMessageSuggestionsComponent;
}(LinkInfoItemSuggestions));
export { LinkInfoMessageSuggestionsComponent };
