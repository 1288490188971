<div class="contact-form">
  <div class="shadow"></div>

  <form
    class="contact-form-main"
    [formGroup]="contactForm"
  >

    <!-- Tags -->
    <app-tag-list
      class="contact-form-tags"
      [contact]="contact"
      [tags]="contactForm.controls['tags'].value"
      (tagsChange)="contactForm.patchValue({ tags: $event })"
      appScrollAnchor
    ></app-tag-list>

    <!-- Pellets -->
    <app-contact-topics
      [contactId]="contact?.id"
      [topicsControl]="contactForm.controls['topics']"
    ></app-contact-topics>

    <div class="contact-form-horizontal-group">
      <div class="contact-form-horizontal-group-item">
        <app-input
          placeholder="Email"
          [inputFormControl]="contactForm.get('email')"
          width="225px"
        ></app-input>
      </div>
      <div class="contact-form-horizontal-group-item">
        <app-input
          placeholder="Phone Number"
          [inputFormControl]="contactForm.get('phoneNumber')"
          width="225px"
        ></app-input>
      </div>
    </div>

    <div class="contact-form-horizontal-group">
      <div class="contact-form-horizontal-group-item">
        <app-dropdown-select
          [placeholder]="'Video'"
          [inputFormControl]="contactForm.controls['social']"
          [options]="conferencingOptions"
          width="225px"
        ></app-dropdown-select>
      </div>
      <div class="contact-form-horizontal-group-item">
        <app-dropdown-select
          [placeholder]="'Social Media'"
          [inputFormControl]="contactForm.controls['social']"
          [options]="conferencingOptions"
          width="225px"
        ></app-dropdown-select>
      </div>
    </div>

    <div class="contact-form-horizontal-group">
      <div class="contact-form-horizontal-group-full-width-item">
        <app-linked-info-list
          class="linked-info-list"
          [parentLinkedInfo]="{type: 'contact', id: contact?.id}"
          [linkedInfo]="contact?.linkedInfo"
          (extendAction)="openLinkedInfoDP.emit()"
        ></app-linked-info-list>
      </div>
    </div>
  </form>
</div>
