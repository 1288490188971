// RX
import { BehaviorSubject, merge } from 'rxjs';

// Types
import { Task } from './task';
import { Column } from './column';
import { Section } from './section';
import { SectionsTree } from './sections-tree';

export class ColumnsTree {
  public projectId: string;
  public columns = new BehaviorSubject<Column[]>([]);

  constructor ({
    projectId,
    columns,
    sections,
    tasks
  }: {
    projectId: string,
    columns: Column[],
    sections: Section[],
    tasks: Task[]
  }) {
    this.projectId = projectId;

    // if column was removed and there are tasks left with removed column Id
    // or task was moved from another project
    const columnsIds = columns.map(column => column.id);
    tasks.forEach(task => {
      if (!columnsIds.includes(task.columnId)) {
        task.columnId = undefined;
      }
    });

    this.columns.next(
      columns
        .sort((a, b) => (a.position > b.position) ? 1 : -1)
        .map((column, index) => new Column({
          ...column,
          position: index + 1,
          sectionsTree: new SectionsTree({
            projectId,
            columnId: column.id,
            sections,
            tasks: tasks
              .filter(task => task.columnId === column.id || (column.uncategorized && task.columnId === undefined))
          })
        }))
      );
  }

  public insertColumn(afterColumn: Column) {
    const columns = this.columns.getValue().filter(column => !column.temp);

    const newColumn = new Column({
      projectId: this.projectId,
      temp: true,
    });

    const newColumns = [
      ...columns.slice(0, afterColumn.position + 1),
      newColumn,
      ...columns.slice(afterColumn.position + 1, columns.length),
    ];

    this.columns.next(newColumns);
  }

  public moveColumn(columnInstace: Column, index: number) {
    columnInstace.projectId = this.projectId;
    const columns = this.columns.getValue();

    const columnsBefore = columns.slice(0, index).filter(column => column.id !== columnInstace.id);
    const columnsAfter = columns.slice(index, columns.length).filter(column => column.id !== columnInstace.id);
    const newColumns = [...columnsBefore, columnInstace, ...columnsAfter];

    newColumns.forEach((column, newIndex) => column.position = newIndex);

    this.columns.next(newColumns);
  }
}
