<div
  *ngIf="task"
  class="task"
  [class.short-view]="shortView"
  [class.pinned]="task.pinned"
  [class.with-description]="task.description"
  (click)="openTask()"
  [appTaskContextMenu]="task"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [task], type: 'task'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="task-body">
    <div class="task-body-heading">

      <app-circular-progress
        progress="25"
        class="task-body-heading-progress"
      ></app-circular-progress>

      <div class="task-body-heading-title">
        {{task.title}}
      </div>

      <div class="task-body-heading-column">
        {{task.columnTitle}}
      </div>
    </div>

    <app-date-time
      class="task-body-date-time"
      [startDate]="task.fromTime"
      [startTime]="task.fromTime"
      [endDate]="task.toTime"
      [endTime]="task.toTime"
    ></app-date-time>

    <div class="task-body-description" *ngIf="task.description && !shortView">
      {{task.description}}
    </div>

    <app-link-info-bar
      class="task-body-info-bar"
      *ngIf="!shortView && task.linkedInfo?.length"
      [linkedInfo]="task.linkedInfo"
    ></app-link-info-bar>
  </div>

  <mat-icon class="task-pin-icon" *ngIf="task.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="task-starred-icon" *ngIf="!task.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="task-marker" svgIcon="tasks"></mat-icon>
  <mat-icon class="task-attachments-icon" *ngIf="task.files?.length" svgIcon="attachment"></mat-icon>

  <!-- TODO move this to a separate component -->
  <div
    class="task-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  >
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="saved"
      [class.active]="task.starred"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="pin"
      [class.active]="task.pinned"
      [matTooltip]="task?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="snooze"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="flag"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="archive"
      [class.active]="task.archived"
      [matTooltip]="task?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="trash"
      [class.active]="task.deleted"
      [matTooltip]="task?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="task-bottom-container">
    <mat-icon
      class="task-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
    <app-contacts-counter-dropdown
      *ngIf="!shortView && task.participants?.length"
      class="task-bottom-container-participants"
      [contactList]="task.participants"
      [size]="24"
      [minContactLen]="1"
    ></app-contacts-counter-dropdown>
  </div>

  <div class="drop-zone"></div>
</div>

<!-- TODO waiting for design -->
<div
  class="task placeholder"
  *ngIf="!task"
  [class.short-view]="shortView"  
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-task
    [task]="task"
    [dragEnabled]="false"
    [shortView]="shortView"
  ></app-link-info-task>
</ng-template>
