// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, ReplaySubject ,  of as createObservableOf } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Account } from '../types/account';
import { AccountQuickReplyTemplate } from '../types/account-quick-reply-template';
import { AccountMailRule } from '../types/account-mail-rule';
import { AccountSignature } from '../types/account-signature';
import { AutoReply } from '../types/auto-reply';

// Env
import { environment } from '../../../../environments/environment';


@Injectable()
export class AccountService {

  accountSubject: ReplaySubject<Account>;
  account: Account;
  accountLoading: boolean;
  autoReply: AutoReply;
  mailRules: AccountMailRule[];
  quickReplyTemplates: AccountQuickReplyTemplate[];
  signatures: AccountSignature[];

  constructor(private http: HttpClient) {
    this.accountSubject = new ReplaySubject<Account>(1);
    this.accountLoading = false;
  }

  getAccount(force: boolean = false): ReplaySubject<Account> {
    if (force || (!this.account && !this.accountLoading)) {
      this.accountLoading = true;
      this.http.get(environment.baseUrl + '/api/account').subscribe(
        response => {
          this.accountLoading = false;
          this.account = response as Account;
          this.accountSubject.next(this.account);
        },
        error => {
          console.error(error);
          this.accountLoading = false;
          this.account = null;
          this.accountSubject.next({} as Account);
        }
      );
    }
    return this.accountSubject;
  }

  getSignatures(force: boolean = false): Observable<AccountSignature[]> {
    if (this.signatures && !force) {
      return createObservableOf(this.signatures);
    }

    return this.http.get(environment.baseUrl + '/api/account/signatures').pipe(map(
      response => {
        this.signatures = response['signatures'];
        return this.signatures;
      }
    ));
  }

  saveSignature(signature, isDefault?: boolean): Observable<{ success: boolean, data: AccountSignature }> {
    if (!signature.hasOwnProperty('id')) {
      return this.http
        .post<{ success: boolean, data: AccountSignature }>(environment.baseUrl + '/api/account/signatures', {signature: signature});
    } else {
      if (isDefault) {
        signature.isDefault = true;
      }
      return this.http
        .put<{ success: boolean, data: AccountSignature }>(environment.baseUrl + '/api/account/signatures/' + signature.id, {
          signature: signature
        });
    }
  }

  removeSignature(signatureId: string): Observable<boolean> {
    return this.http.delete<boolean>(environment.baseUrl + '/api/account/signatures/' + signatureId);
  }

  getDelayedSend(): Observable<Number> {
    return this.http.get(environment.baseUrl + '/api/account/delayed-send').pipe(map(
      response => response['delay'] || 0
    ));
  }

  saveDelayedSend(value): Observable<object> {
    return this.http.post<object>(environment.baseUrl + '/api/account/delayed-send', {delay: value});
  }

  getQuickReplyTemplates(): Observable<AccountQuickReplyTemplate[]> {
    return this.http.get(environment.baseUrl + '/api/account/quick-reply-templates').pipe(map(
      response => {
        this.quickReplyTemplates = response['quickReplyTemplates'] || [] as AccountQuickReplyTemplate[];
        return this.quickReplyTemplates;
      }
    ));
  }

  createQuickReplyTemplate(title, content): Observable<object> {
    return this.http.post(
      environment.baseUrl + '/api/account/quick-reply-templates',
      {title, content});
  }

  updateQuickReplyTemplate(id, title, content): Observable<object> {
    return this.http.put(
      environment.baseUrl + '/api/account/quick-reply-templates/' + id,
      {title, content});
  }

  removeQuickReplyTemplate(id): Observable<object> {
    return this.http.delete(environment.baseUrl + '/api/account/quick-reply-templates/' + id);
  }

  getMailRules(): Observable<AccountMailRule[]> {
    return this.http.get(environment.baseUrl + '/api/account/mail-rules').pipe(map(
      response => {
        this.mailRules = response['data']['mailRules'] || [] as AccountMailRule[];
        return this.mailRules;
      }
    ));
  }

  createMailRule(rule) {
    return this.http.post(
      environment.baseUrl + '/api/account/mail-rules',
      rule
    );
  }

  updateMailRule(id, rule): Observable<object> {
    return this.http.put(
      environment.baseUrl + '/api/account/mail-rules/' + id,
      rule
    );
  }

  removeMailRule(id): Observable<object> {
    return this.http.delete(environment.baseUrl + '/api/account/mail-rules/' + id);
  }

  getAutoReply(): Observable<AutoReply> {
    return this.http.get(environment.baseUrl + '/api/account/auto-reply').pipe(map(
      response => {
        const data = response;

        this.autoReply = {
          content: data['autoReply'].content || '',
          dateFrom: data['autoReply'].dateFrom || null,
          dateTo: data['autoReply'].dateTo || null,
          enabled: data['autoReply'].enabled || false,
        };

        return this.autoReply;
      }
    ));
  }

  saveAutoReply(autoReply): Observable<object> {
    return this.http.post(environment.baseUrl + '/api/account/auto-reply', autoReply);
  }

  cancel(): Observable<object> {
    return this.http.delete(environment.baseUrl + '/api/account');
  }

  isAccountReady(): Observable<boolean> {
    return this.http.get(environment.baseUrl + '/api/account/ready').pipe(map(
      response => response['account_ready'] || false
    ));
  }

}
