import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactComponent } from '@modules/elements/components/contact/contact.component';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';

@Component({
  selector: 'app-link-info-contact',
  templateUrl: './link-info-contact.component.html',
  styleUrls: ['./link-info-contact.component.less']
})
export class LinkInfoContactComponent extends ContactComponent {

  // Inputs
  @Input() linkedInfo: LinkedInfo;
  @Input() shortView = false;

  // Outputs
  @Output() unlink: EventEmitter<LinkedInfo> = new EventEmitter<LinkedInfo>();

  // Actions
  public unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit(this.linkedInfo);
  }

  public openGroup() {
    if (this.contact) {
      this.modalService.contactForm(this.contact);
    }
  }
}
