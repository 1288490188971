// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-picker-button',
  templateUrl: './color-picker-button.component.html',
  styleUrls: ['./color-picker-button.component.less'],
})
export class ColorPickerButtonComponent {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() defaultColor: string;

  /**
   * Constructor
   */

  constructor() { }

}
