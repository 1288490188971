// Common
import { Injectable } from '@angular/core';

// RX
import { Observable, BehaviorSubject, Subject, zip } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { EventsMainView } from '../types/events-main-view';
import { CalendarType } from '../types/calendar-type';
import { EventsSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { Clipboard } from '../types/clipboard';
import { EventFilters } from '../types/event-filters';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { CalendarService } from './calendar.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

@Injectable()
export class EventsStateService {

  // Private
  private selectedEvents = new BehaviorSubject<CalendarEvent[]>([]);
  private mainView = new BehaviorSubject<EventsMainView>('calendar');
  private mainViewEvent = new BehaviorSubject<CalendarEvent>(null);
  private refreshAll = new Subject<void>();
  private selectedCalendarDate = new Subject<Date>();
  private scrollToDay = new Subject<Date>();
  private clipboard = new BehaviorSubject<Clipboard>(null);

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private calendarService: CalendarService,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) {
    this.setMainView(this.stateService.eventsMainView);
  }

  /**
   * Actions
   */

  openEventForm(event: CalendarEvent) {
    this.setMainView('event-form');
    this.setMainViewEvent(event);
  }

  setSelectedEvents(events: CalendarEvent[]) {
    if (this.selectedEvents.value.length === 1 && events.length === 1 &&  this.selectedEvents.value[0].id === events[0].id) {
      this.selectedEvents.next([]);
      return;
    }
    // recreate events array for proper ngOnChange handling
    this.selectedEvents.next([...events]);
  }

  getSelectedEvents(): Observable<CalendarEvent[]> {
    return this.selectedEvents.asObservable();
  }

  setMainView(view: EventsMainView) {
    this.mainView.next(view);
    this.stateService.eventsMainView = view;
  }

  setCalendarView(type: CalendarType, date: Date) {
    this.stateService.setSelectedCalendarDates(type, date);
    this.stateService.selectedCalendarType = type;
  }

  getMainView(): Observable<EventsMainView> {
    return this.mainView.asObservable();
  }

  setMainViewEvent(event: CalendarEvent) {
    this.mainViewEvent.next(event);
  }

  getMainViewEvent(): Observable<CalendarEvent> {
    return this.mainViewEvent.asObservable();
  }

  triggerRefreshAll(): void {
    this.refreshAll.next();
  }

  getRefreshAll(): Observable<void> {
    return this.refreshAll.asObservable();
  }

  getSidebarFilters(): Observable<EventFilters> {
    return this.stateService
      .getSidebarFilters('events')
      .pipe(
        map((value: EventsSidebarFilterKey) => ({
          archived: value === 'archived',
          deleted: value === 'deleted'
        }))
      );
  }

  setSelectedCalendarDate(date: Date) {
    this.selectedCalendarDate.next(date);
  }

  getSelectedCalendarDate(): Observable<Date> {
    return this.selectedCalendarDate
      .asObservable()
      .pipe(
        distinctUntilChanged((date1, date2) => (
          date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
        )),
      );
  }

  setScrollToDay(day: Date) {
    this.scrollToDay.next(day);
  }

  getScrollToDay(): Observable<Date> {
    return this.scrollToDay
      .asObservable()
      .pipe(
        filter(day => !!day)
      );
  }

  addToClipboard(events: CalendarEvent[], clipboardType: 'cut' | 'copy') {
    this.clipboard.next({
      data: events.filter(event => !event.readOnly),
      type: clipboardType
    });

    this.asyncTasksToastsService.show({
      text: `Event${this.clipboard.value.data.length > 1 ? 's' : ''} ${clipboardType === 'copy' ? 'copied' : 'cut'}.`
    });
  }

  getClipboard(): Observable<Clipboard> {
    return this.clipboard.asObservable();
  }

  performPaste(date: Date) {
    const events = this.clipboard.value.data;

    if (events && events.length > 0) {
      const observables: Observable<CalendarEvent>[] = [];

      events.forEach(item => {
        const event = this.clipboard.value.type === 'copy' ? new CalendarEvent({...item, id: null}) : item;

        const duration = event.when && event.when.start && event.when.end && event.when.end.getTime() - event.when.start.getTime();

        if (duration) {
          event.when.start = date;
          event.when.end = new Date(date.getTime() + duration);
        }

        observables.push(
          this.clipboard.value.type === 'cut' ? this.calendarService.editEvent(event) : this.calendarService.createEvent(event)
        );
      });

      zip(...observables).subscribe(() => {
        this.asyncTasksToastsService.show({ text: `Event${events.length > 1 ? 's' : ''} has been succesfully pasted.` });
      });

      this.clipboard.next(null);
    }
  }
}
