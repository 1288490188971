// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { EventsAgendaContextMenuComponent } from '../../components/events-agenda-context-menu/events-agenda-context-menu.component';

@Directive({
  selector: '[appEventsAgendaContextMenu]'
})
export class EventsAgendaContextMenuDirective extends ContextMenuDirective {

  @Input() contextMenuComponent = EventsAgendaContextMenuComponent;

  setContextMenuInstanceData(componentRef: ComponentRef<EventsAgendaContextMenuComponent>) {

  }
}
