<div class="insights-related-connections"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="10"
  [immediateCheck]="true"
  [scrollWindow]="false"
  (scrolled)="loadConnections()">
  <h5 class="connections-header">
    Related connections
    <span class="badge badge-connections">{{count}}</span>
    <app-dropdown
      align="right"
      class="sorting"
      [title]="selectedOrder.name"
      [closeOnSelect]="true">
      <app-dropdown-single-select
        [options]="orderOptions"
        [selected]="selectedOrder"
        (selectedChange)="selectOrder($event)">
      </app-dropdown-single-select>
    </app-dropdown>
  </h5>
  <ul class="connections-list">
    <li *ngFor="let connection of relatedConnections"
        (click)="selectConnection(connection)"
        [ngClass]="{'connections-list__item--selected': connection === selectedConnection}"
        class="connections-list__item"
        [appContactContextMenu]="connection">
      <app-user-avatar size="30"
                       [contact]="connection"
                       [usePopover]="false"
                       [color]="'#767d82'">
      </app-user-avatar>
      <div class="description text-no-select">
        <div class="description__name">{{connection.name}}</div>
        <div class="description__email">{{connection.email}}</div>
      </div>
    </li>
    <li class="loader" *ngIf="loading">
      <i class="fa fa-refresh fa-spin fa-2x fa-fw"></i>
    </li>
    <li class="loader" *ngIf="!loading && count === 0">
      No related connections
    </li>
  </ul>
</div>
