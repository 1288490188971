<app-link-info-suggestions
  [inputFormControl]="inputFormControl"
  [appearance]="appearance"
  title="Link To Note"
  [createTitle]="'Create New Note'"
  [items]="suggestions"
  [itemTemplateRef]="suggestionsItem"
  (close)="closed()"
  (create)="creating()"
>
</app-link-info-suggestions>

<ng-template #suggestionsItem let-note="item">
  <app-note
    [note]="note"
    [actionsButtonEnabled]="false"
    (click)="selectedLinkedInfo({type: 'note', id: note.id})"
  ></app-note>
</ng-template>
