<div class="calendar-create-event--wrapper">

  <!-- Event Form -->
  <form [formGroup]="eventForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" novalidate>

    <!-- Event title -->
    <div class="calendar-create-event__header"
        [ngClass]="{'calendar-create-event__header--expanded': formExpanded}">
      <input type="text"
            class="calendar-create-event__input form-control"
            formControlName="title"
            placeholder="Add Quick Appointment..."
            (keydown.enter)="onSubmit()"/>
    </div>

    <!-- Event field -->
    <div class="calendar-create-event__body" *ngIf="formExpanded" [@slideUpDown]>

      <!-- Select calendar -->
      <div class="select-event-calendar">
        <label class="select-event-calendar__label" for="calendarId">Calendar</label>
        <select class="select-event-calendar__input form-control" id="calendarId" formControlName="calendarId">
          <option *ngFor="let calendar of calendars" [value]="calendar.id" [disabled]="calendar.readOnly">{{calendar.name}}</option>
        </select>
      </div>

      <!-- Select Time -->
      <div class="select-event-date">
        <div class="select-event-day__label">All-day</div>
        <input type="checkbox" class="select-event-day__checkbox" formControlName="allDay">
      </div>
      <div class="select-event-date">
        <label class="select-event-date__label">Start</label>
        <!-- Date -->
        <div class="select-event-date__field">
          <input
            class="select-event-date__input"
            [ngModel]="eventForm?.value?.startDate"
            (ngModelChange)="selectStartDate($event)"
            [ngModelOptions]="{standalone: true}"
            [matDatepicker]="startDatePicker"
            [disabled]="selectedEvent?.readOnly"
            [ngClass]="{'form-control--disabled': selectedEvent?.readOnly}"
          >
          <div
            class="select-event-date__icon select-event-date__icon__calendar"
            [ngClass]="{'form-control--disabled': selectedEvent?.readOnly}"
            (click)="startDatePicker.open()">
            <i class="fa fa-calendar-o"></i>
          </div>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </div>
        <!-- Time -->
        <div class="select-event-date__field" *ngIf="!eventForm?.value?.allDay">

        </div>
      </div>

      <!-- Select end date and time -->
      <div class="select-event-date">
        <label class="select-event-date__label">End</label>
        <!-- Date -->
        <div class="select-event-date__field">
          <input
            class="select-event-date__input"
            [ngModel]="eventForm?.value?.endDate"
            (ngModelChange)="selectEndDate($event)"
            [ngModelOptions]="{standalone: true}"
            [matDatepicker]="endDatePicker"
            [disabled]="selectedEvent?.readOnly"
            [ngClass]="{'form-control--disabled': selectedEvent?.readOnly}"
            [min]="eventForm?.value?.startDate"
          >
          <div
            class="select-event-date__icon select-event-date__icon__calendar"
            [ngClass]="{'form-control--disabled': selectedEvent?.readOnly}"
            (click)="endDatePicker.open()">
            <i class="fa fa-calendar-o"></i>
          </div>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
        <!-- Time -->
        <div class="select-event-date__field" *ngIf="!eventForm?.value?.allDay">

        </div>
      </div>

      <!-- Show more options -->
      <div *ngIf="showMoreOptions">
        <!-- Participants -->
        <div *ngIf="!(selectedEvent?.readOnly && participants?.length === 0)" class="calendar-create-event__field">
          <div class="calendar-create-event__label">Invite people</div>
          <div class="select-event-participant">
            <div class="select-event-participant__row" *ngFor="let participant of participants">
              <div class="select-event-participant__user">
                <i class="fa fa-user"></i>
                <span class="select-event-participant__email">{{participant}}</span>
                <i *ngIf="!selectedEvent?.readOnly" class="fa fa-times select-event-participant__delete" (click)="deleteParticipant(participant)"></i>
              </div>
            </div>
            <div *ngIf="!selectedEvent?.readOnly" class="select-event-participant__row">
                <i class="fa fa-plus-circle"></i>
                <input
                  class="select-event-participant__input"
                  type="text"
                  placeholder="Add participant"
                  formControlName="inputParticipantEmail"
                  (keydown.enter)="addParticipant()"
                >
            </div>
          </div>
        </div>
        <!-- Description -->
        <div class="select-event-description">
          <div class="calendar-create-event__label">Description</div>
          <textarea
            class="select-event-description__textarea"
            rows="5"
            formControlName="description">
          </textarea>
        </div>
      </div>

      <!-- Submit button -->
      <div class="select-event-submit">
        <div class="select-event-submit__more" (click)="showMoreOptions = !showMoreOptions" [title]="showMoreOptions? 'Hide options' : 'More options'">
          <i class="fa fa-angle-down fa-2x" [ngClass]="{'fa-rotate-180': showMoreOptions}"></i>
        </div>
        <!-- Note: This <div> is needed to center the submit button-->
        <div class="select-event-submit__space"></div>
        <button *ngIf="!selectedEvent?.readOnly && selectedEvent?.id" type="button" class="btn btn-default btn-only-icon select-event-submit__delete" (click)="deleteEvent()"><i class="fa fa-trash-o fa-lg"></i></button>
        <button *ngIf="!selectedEvent?.readOnly" type="submit" class="btn btn-primary" [disabled]="eventForm.invalid">{{selectedEvent?.id ? 'Update event' : 'Create event'}}</button>
        <button type="button" class="btn btn-default btn-flat select-event-submit__cancel" (click)="cancel()">{{selectedEvent?.readOnly ? 'Close' : 'Cancel'}}</button>
      </div>

    </div>
    <!-- End: Event field -->

  </form>
  <!-- End: Event Form -->

</div>
