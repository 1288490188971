/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-date-picker.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../popover/directives/popover.directive";
import * as i3 from "../../../../popover/services/popover.service";
import * as i4 from "@angular/common";
import * as i5 from "../../events-quick-preview/events-quick-preview.component.ngfactory";
import * as i6 from "../../events-quick-preview/events-quick-preview.component";
import * as i7 from "../../../../drag-n-drop/directives/droppable.directive";
import * as i8 from "../../../../drag-n-drop/services/drag-n-drop.service";
import * as i9 from "../../event-form-popover/event-form-popover.component.ngfactory";
import * as i10 from "../../event-form-popover/event-form-popover.component";
import * as i11 from "../../../services/calendar.service";
import * as i12 from "../../../services/events-state.service";
import * as i13 from "../../../../pipes/pipes/different-day.pipe";
import * as i14 from "../../../../form-controls/pipes/calendar-color.pipe";
import * as i15 from "../../../../form-controls/components/date-picker/date-picker.component.ngfactory";
import * as i16 from "angular-calendar";
import * as i17 from "../../../../form-controls/components/date-picker/date-picker.component";
import * as i18 from "ngx-contextmenu";
import * as i19 from "../../../../settings/services/state.service";
import * as i20 from "./events-date-picker.component";
import * as i21 from "../../../../knowledge-panel/services/knowledge-panel.service";
var styles_EventsDatePickerComponent = [i0.styles];
var RenderType_EventsDatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsDatePickerComponent, data: {} });
export { RenderType_EventsDatePickerComponent as RenderType_EventsDatePickerComponent };
function View_EventsDatePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "day-cell-circle"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "hover"]], [[2, "selected", null], [2, "disabled", null]], null, null, null, null)), i1.ɵdid(1, 4866048, null, 0, i2.PopoverDirective, [i1.ElementRef, i3.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverDisabled: [2, "popoverDisabled"], popoverTrigger: [3, "popoverTrigger"] }, null), i1.ɵppd(2, 2), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2)], function (_ck, _v) { var currVal_2 = i1.ɵnov(_v.parent, 7); var currVal_3 = "right"; var currVal_4 = (_v.parent.context.day.events.length === 0); var currVal_5 = "hover"; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputFormControl.value && !i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.day.date, _co.inputFormControl.value))); var currVal_1 = (_co.maxDate < _v.parent.context.day.date); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.day.date, "d")); _ck(_v, 3, 0, currVal_6); }); }
function View_EventsDatePickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "day-cell-dots-item"]], [[4, "background-color", null]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.meta.color || i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 2), _v.context.$implicit.meta.calendarId))))); _ck(_v, 0, 0, currVal_0); }); }
function View_EventsDatePickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "events-quick-preview", [], null, null, null, i5.View_EventQuickPreviewComponent_0, i5.RenderType_EventQuickPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i6.EventQuickPreviewComponent, [], { day: [0, "day"], events: [1, "events"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.day.date; var currVal_1 = _v.parent.context.day.events; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EventsDatePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["cell", 1]], null, 6, "div", [["appDroppable", ""], ["appDroppableHoverClass", "drag-over"], ["class", "day-cell"]], null, [[null, "appDroppableDrop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDroppableDrop" === en)) {
        var pd_0 = (_co.handleDrop($event, _v.context.day.date, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, null, 0, i7.DroppableDirective, [i8.DragNDropService, i1.Renderer2, i1.ElementRef, i1.NgZone], { appDroppableHoverClass: [0, "appDroppableHoverClass"] }, { appDroppableDrop: "appDroppableDrop" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsDatePickerComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "day-cell-dots"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsDatePickerComponent_3)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["quickPreviewTemplate", 2]], null, 0, null, View_EventsDatePickerComponent_4))], function (_ck, _v) { var currVal_0 = "drag-over"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.day.inMonth; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.day.events.slice(0, 3); _ck(_v, 6, 0, currVal_2); }, null); }
function View_EventsDatePickerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-form-popover", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handlePopoverFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_EventFormPopoverComponent_0, i9.RenderType_EventFormPopoverComponent)), i1.ɵdid(1, 245760, null, 0, i10.EventFormPopoverComponent, [i11.CalendarService, i12.EventsStateService], { event: [0, "event"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dragEvent; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EventsDatePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.DifferentDayPipe, []), i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i14.CalendarColorPipe, [i11.CalendarService]), i1.ɵqud(402653184, 1, { popoverFormTemplate: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-date-picker", [], null, [[null, "activeDateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeDateChange" === en)) {
        var pd_0 = (_co.handleActiveDateChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_DatePickerComponent_0, i15.RenderType_DatePickerComponent)), i1.ɵprd(4608, null, i16.CalendarDateFormatter, i17.CalendarMonthDateFormatter, [i16.DateAdapter]), i1.ɵdid(6, 770048, null, 0, i17.DatePickerComponent, [i18.ContextMenuService, i4.DatePipe, i19.StateService], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], width: [2, "width"], disabled: [3, "disabled"], appearance: [4, "appearance"], inline: [5, "inline"], bundledInputConsumerKeys: [6, "bundledInputConsumerKeys"], bundledInputAppearance: [7, "bundledInputAppearance"], bundledInputConsumerGroup: [8, "bundledInputConsumerGroup"], collapsedStateKey: [9, "collapsedStateKey"], maxDate: [10, "maxDate"], collapseable: [11, "collapseable"], dayTemplate: [12, "dayTemplate"], events: [13, "events"] }, { activeDateChange: "activeDateChange" }), (_l()(), i1.ɵand(0, [["dayTemplate", 2]], null, 0, null, View_EventsDatePickerComponent_1)), (_l()(), i1.ɵand(0, [[1, 2], ["popoverFormTemplate", 2]], null, 0, null, View_EventsDatePickerComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; var currVal_1 = _co.placeholder; var currVal_2 = _co.width; var currVal_3 = _co.disabled; var currVal_4 = _co.appearance; var currVal_5 = _co.inline; var currVal_6 = _co.bundledInputConsumerKeys; var currVal_7 = _co.bundledInputAppearance; var currVal_8 = _co.bundledInputConsumerGroup; var currVal_9 = _co.collapsedStateKey; var currVal_10 = _co.maxDate; var currVal_11 = _co.collapseable; var currVal_12 = i1.ɵnov(_v, 7); var currVal_13 = _co.events; _ck(_v, 6, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
export function View_EventsDatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events-date-picker", [], null, null, null, View_EventsDatePickerComponent_0, RenderType_EventsDatePickerComponent)), i1.ɵdid(1, 245760, null, 0, i20.EventsDatePickerComponent, [i11.CalendarService, i19.StateService, i12.EventsStateService, i3.PopoverService, i21.KnowledgePanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventsDatePickerComponentNgFactory = i1.ɵccf("app-events-date-picker", i20.EventsDatePickerComponent, View_EventsDatePickerComponent_Host_0, { inputFormControl: "inputFormControl", storeDateKey: "storeDateKey", placeholder: "placeholder", width: "width", disabled: "disabled", appearance: "appearance", inline: "inline", bundledInputConsumerKeys: "bundledInputConsumerKeys", bundledInputAppearance: "bundledInputAppearance", bundledInputConsumerGroup: "bundledInputConsumerGroup", collapsedStateKey: "collapsedStateKey", maxDate: "maxDate", collapseable: "collapseable", filters: "filters" }, {}, []);
export { EventsDatePickerComponentNgFactory as EventsDatePickerComponentNgFactory };
