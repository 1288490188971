/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages-list-panel.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../mail/components/messages-list/messages-list.component.ngfactory";
import * as i3 from "../../../mail/components/messages-list/messages-list.component";
import * as i4 from "../../../mail/services/mail.service";
import * as i5 from "../../../analytics/services/google-analytics.service";
import * as i6 from "../../../modal/services/modal.service";
import * as i7 from "../../../linked-info/services/linked-info.service";
import * as i8 from "../../../mail/services/messages-list-state.service";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "../../../settings/services/state.service";
import * as i11 from "./messages-list-panel.component";
var styles_MessagesListPanelComponent = [i0.styles];
var RenderType_MessagesListPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesListPanelComponent, data: {} });
export { RenderType_MessagesListPanelComponent as RenderType_MessagesListPanelComponent };
export function View_MessagesListPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-messages-list", [], null, null, null, i2.View_MessagesListComponent_0, i2.RenderType_MessagesListComponent)), i1.ɵdid(1, 770048, null, 0, i3.MessagesListComponent, [i4.MailService, i5.GoogleAnalyticsService, i6.ModalService, i7.LinkedInfoService, i8.MessagesListStateService, i1.NgZone], { folderDropdown: [0, "folderDropdown"] }, null), i1.ɵprd(256, null, i9.MAT_TOOLTIP_DEFAULT_OPTIONS, i3.tooltipDefaults, [])], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessagesListPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-messages-list-panel", [], null, null, null, View_MessagesListPanelComponent_0, RenderType_MessagesListPanelComponent)), i1.ɵprd(256, null, i8.MESSAGES_LIST_STORE_KEY, "KPMessagesPannel", []), i1.ɵprd(512, null, i8.MessagesListStateService, i8.MessagesListStateService, [i10.StateService, i8.MESSAGES_LIST_STORE_KEY]), i1.ɵdid(3, 245760, null, 0, i11.MessagesListPanelComponent, [i10.StateService, i8.MessagesListStateService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var MessagesListPanelComponentNgFactory = i1.ɵccf("app-messages-list-panel", i11.MessagesListPanelComponent, View_MessagesListPanelComponent_Host_0, {}, {}, []);
export { MessagesListPanelComponentNgFactory as MessagesListPanelComponentNgFactory };
