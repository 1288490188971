// Common
import { Directive, Input, ComponentRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Types
import { Calendar } from '@modules/events/types/calendar';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { CalendarContextMenuComponent } from '../../components/calendar-context-menu/calendar-context-menu.component';

@Directive({
  selector: '[appCalendarContextMenu]'
})

export class CalendarContextMenuDirective extends ContextMenuDirective implements OnDestroy {

  @Input() appCalendarContextMenu: Calendar;
  @Input() contextMenuComponent = CalendarContextMenuComponent;

  private popoverClose: Subject<void> = new Subject();

  setContextMenuInstanceData(componentRef: ComponentRef<CalendarContextMenuComponent>) {
    // TODO call this every time Inputs are changed ?
    componentRef.instance.calendar = this.appCalendarContextMenu;
    componentRef.instance.originRef = this.elementRef;
    componentRef.instance.popoverClose = this.popoverClose;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.popoverClose.next();
    this.popoverClose.complete();
  }
}
