/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-form.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./confirmation-form.component";
var styles_ConfirmationFormComponent = [i0.styles];
var RenderType_ConfirmationFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationFormComponent, data: {} });
export { RenderType_ConfirmationFormComponent as RenderType_ConfirmationFormComponent };
export function View_ConfirmationFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "confirmation-modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "confirmation-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "confirmation-modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "confirmation-button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "confirmation-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "confirmation-modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "close"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _co.text; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.cancelButtonLabel; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.submitButtonLabel; _ck(_v, 13, 0, currVal_6); }); }
export function View_ConfirmationFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-form", [], null, null, null, View_ConfirmationFormComponent_0, RenderType_ConfirmationFormComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmationFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationFormComponentNgFactory = i1.ɵccf("app-confirmation-form", i4.ConfirmationFormComponent, View_ConfirmationFormComponent_Host_0, { title: "title", text: "text", cancelButtonLabel: "cancelButtonLabel", submitButtonLabel: "submitButtonLabel", action: "action" }, { close: "close" }, []);
export { ConfirmationFormComponentNgFactory as ConfirmationFormComponentNgFactory };
