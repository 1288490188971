// Common
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AnimationTriggerMetadata, trigger, style, transition, animate, AnimationEvent } from '@angular/animations';

// Types
import { BundledEvent } from '@modules/bundled-inputs/types/bundled-event';

const providerMotion: AnimationTriggerMetadata = trigger('providerMotion', [
  transition('void => active', [
    style({
      opacity: 1,
      top: '{{providerY}}',
      left: '{{providerX}}',
      padding: '{{providerPadding}}',
      fontSize: '{{providerFontSize}}',
      lineHeight: '{{providerLineHeight}}',
    }),
    animate(
      `.4s ease-in-out`,
      style({
        opacity: 0,
        top: '{{consumerY}}',
        left: '{{consumerX}}',
        padding: '{{consumerPadding}}',
        fontSize: '{{consumerFontSize}}',
        lineHeight: '{{consumerLineHeight}}',
      })
    )
  ])
]);

const consumerMotion: AnimationTriggerMetadata = trigger('consumerMotion', [
  transition('void => active', [
    style({
      opacity: 0,
      top: '{{providerY}}',
      left: '{{providerX}}',
      padding: '{{providerPadding}}',
      fontSize: '{{providerFontSize}}',
      lineHeight: '{{providerLineHeight}}',
    }),
    animate(
      `.4s ease-in-out`,
      style({
        opacity: 1,
        top: '{{consumerY}}',
        left: '{{consumerX}}',
        padding: '{{consumerPadding}}',
        fontSize: '{{consumerFontSize}}',
        lineHeight: '{{consumerLineHeight}}',
      })
    )
  ])
]);

@Component({
  selector: 'falling-letters',
  animations: [providerMotion, consumerMotion],
  templateUrl: './falling-letters.component.html',
  styleUrls: ['./falling-letters.component.less'],
})
export class FallingLettersComponent implements AfterViewInit {

  // Inputs
  @Input() event: BundledEvent;

  // Outputs
  @Output() readonly animationFinished = new EventEmitter<void>();

  // View Children
  @ViewChild('highlight', { static: true }) highlight: ElementRef;

  // Public
  animationState: 'void' | 'active' = 'void';
  providerTextEndAdjustment = 0;
  consumerTextStartAdjustment = 0;

  /**
   * Constructor
   */

  constructor(
    private changeDetector: ChangeDetectorRef,
  ) {

  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    if (this.event.animationStrategy === 'highlightOnly') {
      const boundingRect = this.highlight.nativeElement.getBoundingClientRect();
      const highlightOffset = boundingRect.left - this.event.provider.x;
      this.providerTextEndAdjustment = -(highlightOffset);
      this.consumerTextStartAdjustment = highlightOffset;
    }

    this.animationState = 'active';
    this.changeDetector.detectChanges();
  }

  /**
   * Actions
   */

  handleAfterAnimation(e: AnimationEvent): void {
    if (e.toState === 'active') {
      this.animationFinished.emit();
    }
  }
}
