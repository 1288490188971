/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-main-view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../event-calendar/event-calendar.component.ngfactory";
import * as i3 from "../event-calendar/event-calendar.component";
import * as i4 from "../../services/calendar.service";
import * as i5 from "../../../settings/services/state.service";
import * as i6 from "../../services/events-state.service";
import * as i7 from "../../../search/services/search.service";
import * as i8 from "../../../popover/services/popover.service";
import * as i9 from "../event-form/event-form.component.ngfactory";
import * as i10 from "../event-form/event-form.component";
import * as i11 from "../../../modal/services/modal.service";
import * as i12 from "../../../pages/services/dynamic-panel.service";
import * as i13 from "@angular/common";
import * as i14 from "./events-main-view.component";
import * as i15 from "../../../pages/services/layout-state.service";
var styles_EventMainViewComponent = [i0.styles];
var RenderType_EventMainViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventMainViewComponent, data: {} });
export { RenderType_EventMainViewComponent as RenderType_EventMainViewComponent };
function View_EventMainViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-calendar", [], null, null, null, i2.View_EventCalendarComponent_0, i2.RenderType_EventCalendarComponent)), i1.ɵdid(1, 180224, null, 0, i3.EventCalendarComponent, [i4.CalendarService, i5.StateService, i6.EventsStateService, i7.SearchService, i8.PopoverService], null, null)], null, null); }
function View_EventMainViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-form", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_EventFormComponent_0, i9.RenderType_EventFormComponent)), i1.ɵdid(1, 704512, null, 0, i10.EventFormComponent, [i4.CalendarService, i11.ModalService, i6.EventsStateService, i12.DynamicPanelService], { event: [0, "event"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EventMainViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMainViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMainViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "calendar"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "event-form"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EventMainViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events-main-view", [], null, null, null, View_EventMainViewComponent_0, RenderType_EventMainViewComponent)), i1.ɵdid(1, 245760, null, 0, i14.EventMainViewComponent, [i6.EventsStateService, i15.LayoutStateService, i5.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventMainViewComponentNgFactory = i1.ɵccf("app-events-main-view", i14.EventMainViewComponent, View_EventMainViewComponent_Host_0, {}, {}, []);
export { EventMainViewComponentNgFactory as EventMainViewComponentNgFactory };
