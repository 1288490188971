<div class="content-menu-pellet-wrapper">
  <div class="header">
    <div
      class="content-menu-pellet"
      [ngClass]="{active: active}"
      (click)="select($event)"
      [contextMenu]="pelletsContext"
      [contextMenuSubject]="title"

      appDraggable
      [appDraggableData]="{data: [title], type: 'topic'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerStyles]="{'border-radius': '10px'}"

      appDroppable
      appDroppableHoverClass="drag-over"
      [appDroppablePredicate]="dndPredicate"
      (appDroppableDrop)="dndDrop($event)"
    >
      {{ title }}
    </div>
    <mat-icon *ngIf="unread" svgIcon="mail-unread" class="notifications-icon"></mat-icon>
  </div>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <div class="content-menu-pellet drag-item" [ngClass]="{active: active}">{{ title }}</div>
</ng-template>

<!-- Context menu -->
<context-menu #pelletsContext>
  <ng-template contextMenuItem>
    Add New Pellet
  </ng-template>
  <ng-template contextMenuItem (execute)="unpinTopic($event.item)">
    Unpin Pellet
  </ng-template>
  <ng-template contextMenuItem (execute)="unpinAllTopics()">
    Clear All Pinned Pellets
  </ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="openTopicsModal()">
    Manage My Pellets...
  </ng-template>
</context-menu>
