import * as tslib_1 from "tslib";
// RX
import { BehaviorSubject } from 'rxjs';
import { Column } from './column';
import { SectionsTree } from './sections-tree';
var ColumnsTree = /** @class */ (function () {
    function ColumnsTree(_a) {
        var projectId = _a.projectId, columns = _a.columns, sections = _a.sections, tasks = _a.tasks;
        this.columns = new BehaviorSubject([]);
        this.projectId = projectId;
        // if column was removed and there are tasks left with removed column Id
        // or task was moved from another project
        var columnsIds = columns.map(function (column) { return column.id; });
        tasks.forEach(function (task) {
            if (!columnsIds.includes(task.columnId)) {
                task.columnId = undefined;
            }
        });
        this.columns.next(columns
            .sort(function (a, b) { return (a.position > b.position) ? 1 : -1; })
            .map(function (column, index) { return new Column(tslib_1.__assign({}, column, { position: index + 1, sectionsTree: new SectionsTree({
                projectId: projectId,
                columnId: column.id,
                sections: sections,
                tasks: tasks
                    .filter(function (task) { return task.columnId === column.id || (column.uncategorized && task.columnId === undefined); })
            }) })); }));
    }
    ColumnsTree.prototype.insertColumn = function (afterColumn) {
        var columns = this.columns.getValue().filter(function (column) { return !column.temp; });
        var newColumn = new Column({
            projectId: this.projectId,
            temp: true,
        });
        var newColumns = tslib_1.__spread(columns.slice(0, afterColumn.position + 1), [
            newColumn
        ], columns.slice(afterColumn.position + 1, columns.length));
        this.columns.next(newColumns);
    };
    ColumnsTree.prototype.moveColumn = function (columnInstace, index) {
        columnInstace.projectId = this.projectId;
        var columns = this.columns.getValue();
        var columnsBefore = columns.slice(0, index).filter(function (column) { return column.id !== columnInstace.id; });
        var columnsAfter = columns.slice(index, columns.length).filter(function (column) { return column.id !== columnInstace.id; });
        var newColumns = tslib_1.__spread(columnsBefore, [columnInstace], columnsAfter);
        newColumns.forEach(function (column, newIndex) { return column.position = newIndex; });
        this.columns.next(newColumns);
    };
    return ColumnsTree;
}());
export { ColumnsTree };
