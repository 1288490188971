<div class="related-files__wrapper">
  <div
    class="file__wrapper"
    *ngFor="let file of files"
    (click)="openAttachmentModal(file, $event)"
    [contextMenu]="fileContextMenu"
    [contextMenuSubject]="file"
  >
    <div class="file">
      <app-attachment-icon [type]="file.type" class="file-icon"></app-attachment-icon>
      <div class="file-info">
        <div class="file-name">{{file.name || 'Unknown'}}</div>
        <div *ngIf="file.from || file.subject" class="file-size">{{file.from?.name || file.from?.email || 'Unknown'}}: {{file.subject || '(no subject)'}}</div>
        <div *ngIf="!(file.from || file.subject)" class="file-size">{{file?.size | fileSize }}</div>
      </div>
      <div class="file-date">{{file?.date | date:'MMM, d' }}</div>
      <div class="file-download hover-icon" (click)="downloadFile(file, $event)">
        <i class="fa fa-cloud-download" matTooltip="Download"></i>
      </div>
    </div>
  </div>
  <div *ngIf="!files?.length && !loading && !error" class="placeholder">
    <img class="logo" src="assets/placeholders/placeholder-files.svg"/>
    <div>There are no attachments.</div>
  </div>
  <div class="placeholder" *ngIf="error">Error occured while loading. <span (click)="onLoadMore()" class="error">Click here to try again.</span></div>
  <div class="load-more" *ngIf="totalCount && totalCount > files?.length && !loading"><a (click)="onLoadMore()">Load more</a></div>
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
</div>

<!-- Context Menu -->
<context-menu #fileContextMenu>
  <ng-template contextMenuItem [visible]="isFileFromMessage" (execute)="openMessageModal($event.item)">
    Open email
  </ng-template>
  <ng-template contextMenuItem (execute)="openAttachmentModal($event.item)">
    Preview
  </ng-template>
  <ng-template contextMenuItem (execute)="downloadFile($event.item)">
    Save As
  </ng-template>
  <ng-template contextMenuItem (execute)="openComposeModal($event.item)">
    Send To
  </ng-template>
  <ng-template contextMenuItem [visible]="isFilePrintable" (execute)="printFile($event.item)">
    Print
  </ng-template>
</context-menu>
<!-- End: Context Menu -->
