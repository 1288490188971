// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { CalendarService } from '@modules/events/services/calendar.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { EventsListResponse } from '@modules/events/types/events-list-response';

@Component({
  selector: 'app-linked-info-event-list',
  templateUrl: './linked-info-event-list.component.html',
  styleUrls: ['./linked-info-event-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoEventListComponent extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private calendarService: CalendarService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'event';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<CalendarEvent[]> {
    return this.calendarService.getEvents({eventsIds: ids, limit: ids.length})
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response: EventsListResponse) => response.events)
      );
  }

  /**
   * Actions
   */

  select(event: CalendarEvent) {
    this.modalService.calendarEventForm(event);
  }

}
