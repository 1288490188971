// Common
import {
  Component, Input, TemplateRef, OnInit, ViewChild, ElementRef, EventEmitter, Output, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate, state } from '@angular/animations';

// Services
import { DragNDropService } from '@modules/drag-n-drop/services/drag-n-drop.service';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Direction } from '../../types/direction';

// RX
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const hideWhileDragging: AnimationTriggerMetadata = trigger(
  'hideWhileDragging',
  [
    transition('shown => hidden', [
      style({ width: '{{ width }}', height: '{{ height }}' }),
      animate('.3s ease-in-out', style({ width: 0, height: 0 }))
    ]),
  ]
);

@Component({
  selector: 'app-ordered-item',
  templateUrl: './ordered-item.component.html',
  styleUrls: ['./ordered-item.component.less'],
  animations: [
    hideWhileDragging
  ],
})
export class OrderedItemComponent implements OnInit, OnDestroy {

  // Public
  public dragging: boolean;
  public size = 0;
  public hovered = false;
  public neighborSize = 0;

  // Private
  private alive: Subject<void> = new Subject();
  private draggingIndexValue: number;
  private neighborsSizesValue: number[] = [];
  private placeholderSizeValue: number;

  // Inputs
  @Input() itemTemplateRef: TemplateRef<any>;
  @Input() index: number;
  @Input() implicit: any;
  @Input() trackByFn: Function;
  @Input() direction: Direction;
  @Input() repositionStream: Subject<DragData>;
  @Input() predicate: (dragData: DragData) => boolean;
  @Input() zIndex: number;
  @Input() disabled: boolean;

  @Input()
  get draggingIndex(): number { return this.draggingIndexValue; }
  set draggingIndex(value: number) {
    this.draggingIndexValue = value;
    this.calculateDroppableAreaSize();
  }

  @Input()
  get neighborsSizes(): number[] { return this.neighborsSizesValue; }
  set neighborsSizes(value: number[]) {
    this.neighborsSizesValue = value;
    this.calculateDroppableAreaSize();
  }

  @Input()
  get placeholderSize(): number { return this.placeholderSizeValue; }
  set placeholderSize(value: number) {
    this.placeholderSizeValue = value;
    this.calculateDroppableAreaSize();
  }

  // Outputs
  @Output() sizeChange: EventEmitter<number> = new EventEmitter();
  @Output() draggingStart: EventEmitter<void> = new EventEmitter();

  // View Children
  @ViewChild('container', { static: false }) container: ElementRef;

  /**
   * Constructor
   */

  constructor (
    private dragNDropService: DragNDropService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dragNDropService.getDraggingDataChanges()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((data: DragData) => {
        if (!data) {
          this.dragging = false;
          return;
        }

        // TODO do we need to compare DragData type to be the same ?
        // TODO predict multiple DnD
        this.dragging = this.trackByFn
          ? this.trackByFn(this.implicit) === this.trackByFn(data.data[0])
          : this.implicit === data.data[0];

        if (this.dragging) {
          this.draggingStart.emit();
        }

        // Size is calculatiing here because it can be changed since component initialization
        if (this.itemTemplateRef && this.container) {
          const bounding = this.container.nativeElement.getBoundingClientRect();
          this.size = this.direction === 'vertical' ? bounding.height : bounding.width;
        } else {
          this.size = 0;
        }

        this.sizeChange.emit(this.dragging ? 0 : this.size);
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleDropBefore(dragData: DragData) {
    dragData.index = this.index;
    this.repositionStream.next(dragData);
  }

  handleEnterLeave(entered: boolean) {
    this.hovered = entered;
    timer(300)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.handleFinishAnimation());
  }

  handleFinishAnimation() {
    this.dragNDropService.recalculateAreasCoordinates();
  }

  /**
   * Helpers
   */

  calculateDroppableAreaSize() {
    if (!this.placeholderSize) { return; }

    const index = this.index - 1 - (this.draggingIndex === this.index - 1 ? 1 : 0);
    this.neighborSize = this.neighborsSizes[index] || 50;

    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
