// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Calendar } from '@modules/events/types/calendar';
import { CalendarEvent } from '@modules/events/types/calendar-event';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';

// RX
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-event-quick-form',
  templateUrl: './event-quick-form.component.html',
  styleUrls: ['./event-quick-form.component.less'],
})
export class EventQuickFormComponent implements OnInit, OnChanges,  OnDestroy {

  // Inputs
  @Input() bundledGroup: string;
  @Input() event: CalendarEvent = new CalendarEvent();

  // Outputs
  @Output() more: EventEmitter<CalendarEvent> = new EventEmitter<CalendarEvent>();
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public eventForm: FormGroup;
  public calendarOptions: DropdownSelectItem[] = [];
  public saveInProgress = false;
  public changesSubscription: Subscription;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
  ) {
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((calendars: Calendar[]) => calendars.filter((calendar: Calendar) => !calendar.readOnly))
      )
      .subscribe((calendars: Calendar[]) => {
        this.calendarOptions = calendars.map((calendar: Calendar) => ({title: calendar.name, value: calendar.id}));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('event' in changes) {
      this.reset();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (!this.eventForm.valid) {
      return;
    }

    this.saveInProgress = true;
    const event = CalendarEvent.fromFormGroup(this.eventForm);

    this.calendarService.createEvent(event)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => {
          this.saveInProgress = false;
          this.close.emit();
        },
        error => this.handleError(error)
      );
  }

  handleError(error) {
    this.saveInProgress = false;
  }

  reset() {
    if (!this.event) { return; }

    this.eventForm = this.event.asFormGroup();

    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }

    this.changesSubscription = this.eventForm.valueChanges
      .pipe(
        startWith(this.eventForm.value as Object),
        pairwise(),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(([previous, current]) => {
        if (!this.datesEqual(previous.startDate, current.startDate) && current.startDate && this.eventForm.controls.endDate.pristine) {
          this.eventForm.patchValue({ endDate: current.startDate });
        }

        if (!current.startTime && previous.startTime) {
          current.startTime = previous.startTime;
        }

        if (current.startTime && previous.startTime && !this.datesEqual(previous.startTime, current.startTime)) {
          const timeDifference = current.startTime.getTime() - previous.startTime.getTime();
          this.eventForm.patchValue({ endTime: new Date(current.endTime.getTime() + timeDifference) }, { emitEvent: false });
        }

        if (current.reminder && current.reminder !== previous.reminder) {
          this.eventForm.patchValue({
            endDate: this.eventForm.controls.startDate.value,
            endTime: current.startTime && new Date(current.startTime.getTime() + 900000)
          }, { emitEvent: false });
        }
      });
  }

  handleMoreOptions() {
    this.more.emit(CalendarEvent.fromFormGroup(this.eventForm));
    this.close.emit();
  }

  private datesEqual(date1: Date, date2: Date) {
    return date1 && date2 && date1.getTime() === date2.getTime();
  }
}
