// Common
import { Injectable, NgZone, TemplateRef } from '@angular/core';

// RX
import { Observable, Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';

// Types
import { DragData } from '../types/drag-data';
import { DroppableArea } from '../types/droppable-area';
import { Coordinates } from '../types/coordinates';

@Injectable()
export class DragNDropService {

  // Private
  private dataThread = new BehaviorSubject<DragData>(null);
  private droppableAreas: DroppableArea[] = [];
  private enteredThread = new BehaviorSubject<symbol>(null);
  private leavedThread = new Subject<symbol>();
  private droppedThread = new Subject<symbol>();
  private droppedOutsideThread = new Subject<void>();
  private controlledDropThread = new BehaviorSubject<TemplateRef<any>>(null);
  private currentPlaceholderElement = new BehaviorSubject<HTMLElement>(null);
  private repositionThread = new Subject<boolean>();

  constructor(
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mouseup')
        .pipe(
          filter(() => !!this.dataThread.value && !this.controlledDropThread.value),
        )
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          this.ngZone.run(() => this.emitDrop());
        });
    });
  }

  setDragging(dragData: DragData) {
    this.dataThread.next(dragData);
  }

  getDraggingDataChanges(): Observable<DragData> {
    return this.dataThread
      .asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  registerDroppableArea(
    key: symbol,
    element: HTMLElement,
    zIndex: number,
    afterDropTemplate: TemplateRef<any>,
    noReposition: boolean,
    predicateValidated
  ) {
    if (!this.droppableAreas.some(area => area.key === key)) {

      const { left: x, top: y, width, height } = element.getBoundingClientRect();

      const coordinates = this.getAreaCoordinates({x, width, y, height}, element.parentElement);

      if (coordinates) {
        this.droppableAreas.push({
          key,
          entered: false,
          zIndex,
          coordinates: coordinates,
          afterDropTemplate,
          noReposition,
          element,
          predicateValidated
        });
      }
    }
  }

  recalculateAreasCoordinates() {
    this.droppableAreas.forEach(area => {
      const { left: x, top: y, width, height } = area.element.getBoundingClientRect();
      const coordinates = this.getAreaCoordinates({x, width, y, height}, area.element.parentElement);
      area.coordinates = coordinates;
    });
  }

  unregisterDroppableArea(key: symbol) {
    const index = this.droppableAreas.findIndex(area => area.key === key);

    if (index >= 0) {
      this.droppableAreas.splice(index, 1);
    }
  }

  checkDroppableAreas(x: number, y: number): void {
    const enteredAreas = [];

    this.droppableAreas.forEach((area: DroppableArea) => {
      if (
        area.coordinates &&
        area.coordinates.x < x && area.coordinates.x + area.coordinates.width > x &&
        area.coordinates.y < y && area.coordinates.y + area.coordinates.height > y
      ) {
        enteredAreas.push(area);
      }
    });

    const topMostArea = this.getTopMostArea(enteredAreas);

    if (topMostArea) {
      if (!topMostArea.entered) {
        topMostArea.entered = true;
        this.enteredThread.next(topMostArea.key);
      }
    }

    this.droppableAreas.forEach(area => {
      if (area.entered && (!topMostArea || area.key !== topMostArea.key)) {
        area.entered = false;
        this.leavedThread.next(area.key);
      }
    });

    this.checkNearAreas(x, y);
  }

  emitDrop() {
    let dropOutside = true;

    const enteredDroppableAreas = this.droppableAreas
      .filter((area: DroppableArea) => area.entered);

    enteredDroppableAreas.forEach((area: DroppableArea) => {
      this.leavedThread.next(area.key);
      area.entered = false;
      dropOutside = false;
    });

    const dropArea = this.getTopMostArea(enteredDroppableAreas);

    if (dropArea) {
      this.droppedThread.next(dropArea.key);
    }

    if (dropOutside) {
      this.droppedOutsideThread.next();
      this.repositionThread.next(false);
    } else if (dropArea.predicateValidated && dropArea.afterDropTemplate) {
      this.setControlledDrop(dropArea.afterDropTemplate);
    } else {
      this.dataThread.next(null);
    }
  }

  getDragEnter(key: symbol): Observable<void> {
    return this.enteredThread
      .asObservable()
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDragLeave(key: symbol): Observable<void> {
    return this.leavedThread
      .asObservable()
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDrop(key: symbol): Observable<void> {
    return this.droppedThread
      .asObservable()
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDropOutside(): Observable<void> {
    return this.droppedOutsideThread
      .asObservable()
      .pipe(
        map(() => null)
      );
  }

  getControlledDrop(): Observable<TemplateRef<any>> {
    return this.controlledDropThread.asObservable();
  }

  setControlledDrop(template: TemplateRef<any>) {
    this.controlledDropThread.next(template);
  }

  setCurrentPlaceholder(placeholder: HTMLElement) {
    this.currentPlaceholderElement.next(placeholder);
  }

  getCurrentPlaceholder(): Observable<HTMLElement> {
    return this.currentPlaceholderElement.asObservable();
  }

  getRepositionThread(): Observable<boolean> {
    return this.repositionThread
      .pipe(
        distinctUntilChanged(),
      );
  }

  checkNearAreas(x: number, y: number) {
    if (!this.currentPlaceholderElement) {
      return;
    }

    const { width: placeholderWidth, height: placeholderHeight } = this.currentPlaceholderElement.value.getBoundingClientRect();
    const tolerance = 15;

    this.repositionThread.next(
      this.droppableAreas.some((area: DroppableArea) => (
        !area.noReposition &&
        area.coordinates &&
        area.coordinates.x + area.coordinates.width + tolerance > x &&
        area.coordinates.x - tolerance < x &&
        area.coordinates.y - tolerance < y &&
        area.coordinates.y + area.coordinates.height + tolerance > y &&
        (
          area.coordinates.width < placeholderWidth ||
          area.coordinates.height < placeholderHeight
        )
      ))
    );
  }

  getAreaCoordinates(coordinates: Coordinates, parent: HTMLElement): Coordinates {
    if (!parent) {
      return coordinates;
    }

    const { x, y, width, height } = coordinates;
    const newCoordinates = { x, y, height, width };

    if (
      parent.scrollHeight > parent.clientHeight &&
      // TODO possible performance issues
      // maybe we can only compare event target ?
      ['scroll', 'hidden'].includes(window.getComputedStyle(parent).overflow)
    ) {
      const { height: parentHeight, top: parentTop } = parent.getBoundingClientRect();

      if (y < parentTop) {
        if (y + height > parentTop) { // partially hidden
          newCoordinates.y = parentTop;
          newCoordinates.height = y + height - parentTop;
        } else {
          return null;
        }
      } else if (y + height > parentTop + parentHeight) {
        if (y < parentTop + parentHeight) { // partially hidden
          newCoordinates.height = parentTop + parentHeight - y;
        } else {
          return null;
        }
      }
    }

    return this.getAreaCoordinates(newCoordinates, parent.parentElement);
  }

  private getTopMostArea(areas): DroppableArea {
    return areas.reduce(
      (topMostArea: DroppableArea, area: DroppableArea) => (
        !topMostArea || area.zIndex > topMostArea.zIndex ? area : topMostArea
      ),
      null
    );
  }
}
