<div
  class="content-menu-item-wrapper"
  [class.minimized]="root && minimized"
  [class.popover-wrapper]="secondLevel"
>
  <div
    class="header"
    [class.collapsed]="collapsed"
    [class.active]="active"
    [class.second-level]="!root"
    [class.popup]="minimizedAppearanceType === 'popup'"
    popover
    [popoverContent]="contentTemplate"
    [popoverInnerShadow]="false"
    popoverPlacement="bottom"
    [popoverDisabled]="!expandable || !root || !minimized || minimizedAppearanceType !== 'popup'"
    popoverTrigger="click"
    [popoverArrow]="false"
    (popoverVisibleChange)="popoverOpened = $event"
  >
    <div class="mask">
      <div class="active-marker" *ngIf="active && root"></div>
      <mat-icon svgIcon="dropdown-down" class="dropdown-icon" [class.no-icon]="!expandable" (click)="trigger()"></mat-icon>
      <div class="icon-container" [class.no-icon]="!icon">
        <mat-icon
          class="icon"
          *ngIf="icon"
          [svgIcon]="icon"
          [class.hover]="hover"
          [style.color]="iconColor"
        ></mat-icon>
        <div *ngIf="badge && !badgeTemplate && minimized" class="notifications-icon"></div>
        <mat-icon *ngIf="pinned" svgIcon="pin-small-color" class="pin-icon"></mat-icon>
      </div>
      <mat-icon *ngIf="createIconSelected?.observers?.length && !minimized" svgIcon="folder-add" class="create-icon" (click)="createIconSelect($event)"></mat-icon>
      <ng-content *ngIf="!minimized" select="[context-menu-item-icon]"></ng-content>

      <div
        class="title"
        [class.subtitle]="!root"
        [class.hover]="hover"
        *ngIf="!(root && minimized)"
      >
        <ng-container *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-container>
        <div *ngIf="title && !titleTemplate" class="title-text">{{ title }}</div>
        <div *ngIf="badge" class="header-badge">{{ badge }}</div>
        <ng-container *ngIf="badgeTemplate && !badge" [ngTemplateOutlet]="badgeTemplate"></ng-container>
      </div>
    </div>
  </div>

  <div
    class="collapseable-body"
    [style.height.px]="collapsed ? 0 : bodyHeight"
    *ngIf="!popoverOpened && (!minimized || minimizedAppearanceType === 'expand')"
  >
    <div class="container" [class.minimized]="minimized">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #contentTemplate>
  <ng-content></ng-content>
  <ng-container
    [ngTemplateOutlet]="templateOutlet"
    [ngTemplateOutletContext]="templateOutletContext | assign : 'parent' : self"
  ></ng-container>
</ng-template>
