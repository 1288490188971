// Common
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

// Components
import { MailContentModalComponent } from '../components/mail-content-modal/mail-content-modal.component';
import {
  ReminderDateTimePickerModalComponent
 } from '../components/reminder-date-time-picker-modal/reminder-date-time-picker-modal.component';
import { TopicsAnnotationsModalComponent } from '../components/topics-annotations-modal/topics-annotations-modal.component';
import { AttachmentPreviewModalComponent } from '../components/attachment-preview-modal/attachment-preview-modal.component';
import { MailEditorModalComponent } from '../components/mail-editor-modal/mail-editor-modal.component';
import { CalendarEventModalComponent } from '../components/calendar-event-modal/calendar-event-modal.component';
import { SelectFolderModalComponent } from '../components/select-folder-modal/select-folder-modal.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { AccountModalComponent } from '../components/account-modal/account-modal.component';
import { TopicsListModalComponent } from '../components/topics-list-modal/topics-list-modal.component';
import { CalendarEventFormModalComponent } from '../components/calendar-event-form-modal/calendar-event-form-modal.component';
import { ProjectFormModalComponent } from '../components/project-form-modal/project-form-modal.component';
import { TaskFormModalComponent } from '../components/task-form-modal/task-form-modal.component';
import { NoteFormModalComponent } from '../components/note-form-modal/note-form-modal.component';
import { GroupFormModalComponent } from '../components/group-form-modal/group-form-modal.component';
import { ContactFormModalComponent } from '../components/contact-form-modal/contact-form-modal.component';
import { AttachmentsListModalComponent } from '@modules/modal/components/attachments-list-modal/attachments-list-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { MailMessage } from '../../mail/types/mail-message';
import { Attachment } from '@modules/form-controls/types/attachment';
import { ModalData } from '../types/modal-data';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';

@Injectable()
export class ModalService {

  // Private
  private show = new Subject<ModalData>();

  // Public
  // To prevent calling .next() from Component
  public show$ = this.show.asObservable();


  /**
   * Constructor
   */

  constructor() { }

  /**
   * Helpers
   */

  private showModal(setting: ModalData) {
    if (this.show.observers.length < 1) {
      console.warn('No subscription from appModalHost');
    }

    if (this.show.observers.length > 1) {
      console.warn('More then one appModalHost defined');
    }

    this.show.next(setting);
  }

  /**
   * Methods
   */

  confirmationModal(modalText: string, modalTitle: string, submitButtonLabel: string, cancelButtonLabel: string, action: Function) {
    this.showModal({
      component: ConfirmationModalComponent,
      name: 'ConfirmationModalComponent',
      content: {
        modalText,
        modalTitle,
        cancelButtonLabel,
        submitButtonLabel,
        action,
      },
      disabledResize: true,
    });
  }

  topicsAnnotations(message: MailMessage, closed?: Function) {
    this.showModal({
      component: TopicsAnnotationsModalComponent,
      name: 'TopicsAnnotationsModalComponent',
      content: {
        message,
      },
      closed
    });
  }

  mailContent(message: MailMessage) {
    this.showModal({
      component: MailContentModalComponent,
      name: 'MailContentModalComponent',
      content: {
        message,
      }
    });
  }

  selectFolder(messages: MailMessage[]) {
    this.showModal({
      component: SelectFolderModalComponent,
      name: 'SelectFolderModalComponent',
      content: {
        messages
      }
    });
  }

  showAttachmentsModal(files: Attachment[], currentIndex: number) {
    if (!files) {
      return;
    }

    this.showModal({
      component: AttachmentPreviewModalComponent,
      name: 'AttachmentPreviewModalComponent',
      content: {
        files,
        currentIndex
      }
    });
  }

  showAttachmentsListModal(attachments?: Attachment[], attachmentsFormControl?: AbstractControl) {
    this.showModal({
      component: AttachmentsListModalComponent,
      name: 'AttachmentsListModalComponent',
      content: { attachments, attachmentsFormControl },
      disabledResize: true
    });
  }

  reminderDateTimePicker(message: MailMessage, reminderType: 'followup'|'snooze') {
    this.showModal({
      component: ReminderDateTimePickerModalComponent,
      name: 'ReminderDateTimePickerModalComponent',
      content: {
        message,
        reminderType
      },
      disabledResize: true,
    });
  }

  compose(message?: MailMessage, sourceMessage?: MailMessage, action?: 'reply' | 'replyAll' | 'forward', customFrame?: ModalFrame) {
    this.showModal({
      component: MailEditorModalComponent,
      name: 'MailEditorModalComponent',
      content: {
        message,
        sourceMessage,
        action
      },
      customFrame: customFrame,
    });
  }

  calendarEvent(event: CalendarEvent) {
    this.showModal({
      component: CalendarEventModalComponent,
      name: 'CalendarEventModalComponent',
      content: {
        event,
      }
    });
  }

  calendarEventForm(event: CalendarEvent) {
    this.showModal({
      component: CalendarEventFormModalComponent,
      name: 'CalendarEventFormModalComponent',
      content: {
        event,
      }
    });
  }

  projectForm(project: Project) {
    this.showModal({
      component: ProjectFormModalComponent,
      name: 'ProjectFormModalComponent',
      content: {
        project,
      }
    });
  }

  taskForm(task: Task) {
    this.showModal({
      component: TaskFormModalComponent,
      name: 'TaskFormModalComponent',
      content: {
        task,
      }
    });
  }

  noteForm(note: Note = new Note()) {
    this.showModal({
      component: NoteFormModalComponent,
      name: 'NoteFormModalComponent',
      content: {
        note,
      }
    });
  }

  groupForm(group: Group = new Group()) {
    this.showModal({
      component: GroupFormModalComponent,
      name: 'GroupFormModalComponent',
      content: {
        group,
      }
    });
  }

  contactForm(contact: Contact = new Contact()) {
    this.showModal({
      component: ContactFormModalComponent,
      name: 'ContactFormModalComponent',
      content: {
        contact,
      }
    });
  }

  topicList(): void {
    this.showModal({
      component: TopicsListModalComponent,
      name: 'TopicsListModalComponent',
      content: {}
    });
  }

  settings(routerPath: string = 'overview') {
    this.showModal({
      component: AccountModalComponent,
      name: 'AccountModalComponent',
      content: {
        routePath: routerPath,
      },
      maxModals: 1,
    });
  }

}
