// Common
import { Pipe, PipeTransform } from '@angular/core';

// Pipes
import { DatePipe } from '@angular/common';
import { DateByDayIndexPipe } from '@modules/pipes/pipes/date-by-day-index.pipe';

// Types
import { TasksSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { Project } from '@modules/tasks/types/project';

@Pipe({
  name: 'getTitleBySidebarFilterKey'
})
export class GetTitleBySidebarFilterKeyPipe implements PipeTransform {

  transform(value: TasksSidebarFilterKey, project?: Project): any {
    switch (value) {
      case 'all_tasks':
        return 'Tasks';
      case 'scheduled':
        return 'Scheduled';
      case 'unscheduled':
        return 'Unscheduled';
      case 'today':
          return 'Today';
      case 'week':
        return 'Next 7 days';
      case 'day0':
        return 'Tomorrow';
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const datePipe = new DatePipe('en-us');
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(value);
        return datePipe.transform(day, 'EEEE');
      case 'priority':
        return 'Priority';
      case 'normal':
        return 'Normal';
      case 'medium':
        return 'Medium';
      case 'high':
        return 'High';
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'all_projects':
        return 'Projects';
      default:
        return project ? project.title : '';
    }
  }

}
