// Common
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError, Subject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { ColumnsFilters } from '../types/columns-filters';
import { ColumnsListResponse } from '../types/columns-list-response';
import { Column } from '../types/column';

// Env
import { environment } from '@environment';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

@Injectable()
export class ColumnsService {

  public columnCreated = new Subject<Column>();
  public columnUpdated = new Subject<Column>();
  public columnDeleted = new Subject<string>();

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService
  ) {

  }

  /**
   * Methods
   */

  getColumns(filters: ColumnsFilters = {}): Observable<ColumnsListResponse> {
    const params = {};
    if (filters.projectsIds) { params['projects_ids'] = filters.projectsIds; }

    return this.http.get<ColumnsListResponse>(environment.baseUrl + '/api/projects/columns', { params: params })
      .pipe(
        map(({ columns }) => ({ columns: columns.map(column => new Column(column)) })),
        catchError(ColumnsService.handleObserverError)
      );
  }

  @warmUpObservable
  create(newColumn: Column): Observable<Column> {
    return this.http.post<{ column: Column, success: boolean }>(
      environment.baseUrl + '/api/projects/columns', newColumn.asPayloadJSON()
    )
      .pipe(
        tap(({ column, success }) => {
          if (success) {
            this.columnCreated.next();
            this.asyncTasksToastsService.show(
              {text: `Column created`}
            );
          }
        }),
        map(({ column }) => new Column(column))
      );
  }

  @warmUpObservable
  update(columnInstance: Column): Observable<Column> {
    return this.http.put<{ column: Column, success: boolean }>(
      environment.baseUrl + '/api/projects/columns/' + columnInstance.id, columnInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ column, success }) => {
          if (success) {
            this.columnUpdated.next();
            this.asyncTasksToastsService.show(
              {text: `Column updated`}
            );
          }
        }),
        map(({ column }) => new Column(column))
      );
  }

  @warmUpObservable
  reorder(columnsIds: string[]): Observable<Column[]> {
    return this.http.put<{ columns: Column[], success: boolean }>(
      environment.baseUrl + '/api/projects/columns',
      { saveOrder: true },
      { params: { columns_ids: columnsIds }}
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.columnUpdated.next();
          }
        }),
        map(({ columns }) => columns.map(column => new Column(column)))
      );
  }

  @warmUpObservable
  deletePermanently(columnIds: string[]): Observable<boolean> {
    return forkJoin(
      columnIds.map(columnId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/projects/columns/' + columnId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.columnDeleted.next();
            this.asyncTasksToastsService.show({ text: `Column(s) successfully deleted.` });
          }
        })
      );
  }
}
