// Common
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { StateService } from '@modules/settings/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TagService } from '@modules/tag/services/tag.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Tag } from '@modules/tag/types/tag';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class MessageComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() message: MailMessage;
  @Input() selectedMessages: MailMessage[] = [];
  @Input() folder: string;
  @Input() sortBy: string;
  @Input() threadExpanded = false;
  @Input() dragEnabled = true;
  @Input() draggable = false;
  @Input() actionsButtonEnabled = true;
  @Input() contextMenuEnabled = true;
  @Input() threadEnabled = true;

  // Outputs
  @Output() selectedMessagesChange: EventEmitter<MailMessage[]> = new EventEmitter();
  @Output() selectMessage: EventEmitter<{message: MailMessage, event: MouseEvent}> = new EventEmitter();
  @Output() doubleClick: EventEmitter<MailMessage> = new EventEmitter();
  @Output() movedToAnotherFolder: EventEmitter<MailMessage> = new EventEmitter();
  @Output() threadToggle: EventEmitter<boolean> = new EventEmitter();

  // ViewChildren
  @ViewChild('actionIcons', { static: false }) actionIcons: ElementRef;
  @ViewChild('threadButton', { static: false }) threadButton: ElementRef;

  // Public
  public snoozedSetting: string;
  public thread: {
    offset: number,
    count: number,
    unread_count: number,
    messages: MailMessage[]
  };
  public dragData: MailMessage[] = [];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'message' && dragData.data.length === 1 && dragData.data[0]['id'] === this.message.id) &&
    ['message', 'event', 'task', 'project', 'note', 'topic', 'tag', 'file'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected mailService: MailService,
    protected ga: GoogleAnalyticsService,
    protected stateService: StateService,
    protected modalService: ModalService,
    protected topicService: TopicService,
    protected linkedInfoService: LinkedInfoService,
    protected tagService: TagService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.snoozedSetting = this.stateService.settingMessageSnoozed;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      if (!this.message || !this.message.threadMessages) {
        this.thread = null;
      } else if (!changes['message'].previousValue || changes['message'].previousValue['id'] !== this.message.id) {
        this.thread = {
          ...this.message.threadMessages,
          offset: 0
        };
        if (this.thread.messages[0].id === this.message.id) {
          this.thread.offset = 1;
          this.thread.messages = this.thread.messages.slice(1);
        }
      }
      this.dragData = [this.message];
    }
    if ('selectedMessages' in changes) {
      if (
        this.message &&
        this.selectedMessages &&
        this.selectedMessages.some(selected => selected.id === this.message.id)
      ) {
        this.dragData = this.selectedMessages;
      } else {
        this.dragData = [this.message];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Helpers
   */

  isSentOrDraftsFolder(): boolean {
    return this.folder && (this.folder === 'sent' || this.folder === 'drafts');
  }

  messageSentByMe(message: MailMessage): boolean {
    return message.labels.map(folder => folder.name).includes('sent');
  }

  /**
   * Methods
   */

  removeThreadMessageFromCache(parentMessage: MailMessage, message: MailMessage) {
    if (!parentMessage || !message) {
      return;
    }
    const index = parentMessage.threadMessages.messages.findIndex(msg => msg.id === message.id);
    if (index !== -1) {
      parentMessage.threadMessages.messages.splice(index, 1);
      parentMessage.threadMessages.count--;
    }
    if (this.selectedMessages && this.selectedMessages.length && this.selectedMessages.includes(message)) {
      this.selectedMessages = this.selectedMessages
        .filter(selectedMessage => selectedMessage.id !== message.id);
      this.selectedMessagesChange.emit(this.selectedMessages);
    }
  }

  /**
   * Actions
   */

  onSelectMessage(message: MailMessage, event: MouseEvent) {
    if ((this.actionIcons && this.actionIcons.nativeElement.contains(event.target)) ||
        (this.threadButton && this.threadButton.nativeElement.contains(event.target))) {
      return;
    }
    this.selectMessage.emit({message: message, event: event});
  }

  // Used for output from child component, which can manipulate the selection as well
  onSelectMessages(messages: MailMessage[]) {
    this.selectedMessages = messages;
    this.selectedMessagesChange.emit(this.selectedMessages);
  }

  onDoubleClick(message: MailMessage) {
    this.doubleClick.emit(message);
  }

  onThreadToggle() {
    this.threadExpanded = !this.threadExpanded;
    this.threadToggle.emit(this.threadExpanded);
  }

  onKeyUp(event: KeyboardEvent) {
    const key = event.key.toLocaleLowerCase();
    if ((key === 'delete' || key === 'backspace') && this.selectedMessages.length) {
      this.deleteMessages(this.selectedMessages);
    }
  }

  starMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'star');
    this.mailService.starMessage(message).subscribe();
  }

  pinMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'pin-message');
    this.mailService.pinnedMessage(message).subscribe();
  }

  unreadMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'mark-as-read');
    this.mailService.updateMessagesUnreadStatus({messagesIds: [message.id]}, !message.unread);
  }

  snoozeMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'snooze');
    this.mailService.snoozeMessage(this.snoozedSetting, message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.snoozed = true);
  }

  removeSnoozeMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'remove-snooze');
    this.mailService.removeSnoozeMessage(message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.snoozed = false);
  }

  followUpMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'follow-up');
    this.mailService.followMessage('tomorrow', message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.followed = true);
  }

  removeFollowUpMessage(message: MailMessage) {
    this.ga.trackEvent('messages-list', 'remove-followed');
    this.mailService.removeFollowMessage(message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.followed = false);
  }

  moveToArchive(archived: boolean, message: MailMessage) {
    this.ga.trackEvent('messages-list', `move-to-archive`);
    this.mailService.archiveMessages([message], archived);
  }

  deleteMessages(messages: MailMessage[]) {
    if (this.folder === 'trash') {
      this.ga.trackEvent('messages-list', 'delete');
      this.mailService.deleteMessages({ ids: messages.map(message => message.id) })
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((deleted: boolean) => {
          if (deleted) { this.messagesMoved(messages); }
        });
    } else {
      this.ga.trackEvent('messages-list', 'move-to-trash');
      if (messages.some(msg => !!msg.threadMessages)) {
        this.modalService.confirmationModal(
          'Selected message(s) are part of the thread. Would you like to move all messages in the thread?',
          'Move Message(s)',
          'Yes',
          'No',
          (withThread: boolean) => {
            this.mailService.moveMessages({
              messages,
              filters: withThread ? { threadIds: messages.map(message => message.thread) } : null
            }, ['trash'])
              .pipe(
                takeUntil(this.componentNotDestroyed)
              )
              .subscribe((moved: boolean) => {
                if (moved) { this.messagesMoved(messages); }
              });
          });
      } else {
        this.mailService.moveMessages({ messages }, ['trash'])
          .pipe(
            takeUntil(this.componentNotDestroyed)
          )
          .subscribe((moved: boolean) => {
            if (moved) { this.messagesMoved(messages); }
          });
      }
    }
  }

  messagesMoved(messages: MailMessage[]) {
    messages.forEach(message => {
      this.movedToAnotherFolder.emit(message);
      this.selectedMessages = this.selectedMessages.filter(
        selectedMessage => selectedMessage.id === message.id
      );
    });
    this.selectedMessagesChange.emit(this.selectedMessages);
  }

  loadTreadMessages(treadId: string) {
    if (
      !this.thread ||
      !this.thread.messages ||
      this.thread.count <= this.thread.messages.length + this.thread.offset
    ) {
      return;
    }

    this.mailService.getMessages({
      threadIds: [treadId]
    }, this.sortBy, this.thread.count, this.thread.offset)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(({ messages }) => this.thread.messages = messages);
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    if (!dragData.data) {
      return;
    }
    // Message | Event | Project | Task | Note
    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('message', this.message.id), linkedItems);
    }
    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {messagesIds: [this.message.id]});
    }
    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {messagesIds: [this.message.id]});
    }
  }

}
