<div class="linked-info-popover">
  <div class="header" [class.shadow]="showHeaderShadow">
    <div class="title">Events</div>
    <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  </div>
  <div class="list" #scrollList>
    <app-event
      *ngFor="let event of items"
      [event]="event"
      (click)="select(event)"
    ></app-event>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>
