// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Tag } from '@modules/tag/types/tag';
import { Contact } from '@modules/contacts/types/contact';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';

@Component({
  selector: 'app-contact-form-container',
  templateUrl: './contact-form-container.component.html',
  styleUrls: ['./contact-form-container.component.less'],
})
export class ContactFormContainerComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() contact: Contact = new Contact();
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() bundledGroup = 'contactForm';

  // Public
  public contactForm: FormGroup = this.contact.asFormGroup();
  public saveInProgress = false;
  public orderOptions: DropdownOption[] = [
    { name: 'By Date', key: 'date'},
    { name: 'Priority', key: 'priority'},
    { name: 'All', key: 'all'},
    { name: 'Today', key: 'today'},
    { name: 'Upcoming', key: 'upcoming'},
    { name: 'By Pellets', key: 'pellets'},
    { name: 'By Tags', key: 'tags'},
    { name: 'To Do', key: 'todo'},
    { name: 'Progress', key: 'progress'},
    { name: 'Done', key: 'done'},
    { name: 'Pick by date ...', key: 'pickByDate'},
  ];
  public selectedOrder: DropdownOption = this.orderOptions[0];
  public viewType: 'form' | 'linkedInfo' | 'activity' = 'form';
  public dynamicPanel: DynamicPanelType;
  public closeDroppedElement = new Subject<void>();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private dragData: DragData;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.contact &&
    !(dragData.type === 'contact' && dragData.data.length === 1 && dragData.data[0]['id'] === this.contact.id) &&
    ['message', 'event', 'project', 'task', 'note', 'group', 'contact', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor (
    private contactsService: ContactsService,
    private contactsStateService: ContactsStateService,
    private dynamicPanelService: DynamicPanelService,
    private topicService: TopicService,
    private tagService: TagService,
  ) {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('contact' in changes && this.contact) {
      this.contactForm = this.contact.asFormGroup();
    }
  }

  ngOnDestroy(): void {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
  }

  handleSubmit() {
    if (this.contactForm.invalid) {
      return;
    }
    this.saveInProgress = true;

    this.contactsService.upsert(this.contactForm)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  handleCancel() {
    this.close.emit();
  }

  handleError() {
    this.saveInProgress = false;
  }

  handleSearchChange(value: string) {

  }

  switchView(viewType: 'form' | 'linkedInfo' | 'activity') {
    this.viewType = viewType;
  }

  pin() {
    this.contactsService.pin([this.contact.id], !this.contact.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((contacts: Contact[]) => {
        if (contacts[0]) {
          this.contact.pinned = contacts[0].pinned;
        }
      });
  }

  archive() {
    this.contactsService.archive([this.contact.id], !this.contact.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((contacts: Contact[]) => {
        if (contacts[0]) {
          this.contact.archived = contacts[0].archived;
        }
      });
  }

  delete() {
    this.contact.deleted ?
      this.contactsService.deletePermanently([this.contact.id]) :
      this.contactsService.delete([this.contact.id], true);

      this.contactsStateService.setMainView('empty');
  }

  openAttachmentsDP() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('attachments', { attachments: this.contact.files });
    }
  }

  openLinkedInfoDP() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.contact.linkedInfo,
        parentLinkedInfo: {type: 'contact', id: this.contact.id}
      });
    }
  }

  dndDrop(dragData: DragData) {
    if (!this.contact) { return; }

    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {contactsIds: [this.contact.id]});
      this.closeDroppedElement.next();
    } else if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {contactsIds: [this.contact.id]});
      this.closeDroppedElement.next();
    } else {
      this.dragData = dragData;
    }
  }
}
