<div class="project-form">
  <form [formGroup]="projectForm" (ngSubmit)="handleSubmit()">

      <div class="project-form-horizontal-group-full-width-item">
        <app-input
          placeholder="Title"
          appearance="standard"
          icon="project"
          [inputFormControl]="projectForm.controls['title']"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['title']"
        ></app-input>
      </div>

    <div
      class="project-form-horizontal-group"
      *ngIf="projectForm.controls['fromDate'].value || projectForm.controls['fromTime'].value ||
             projectForm.controls['toDate'].value || projectForm.controls['toTime'].value"
    >
      <app-date-time
        [startDate]="projectForm.controls['fromDate'].value"
        [startTime]="projectForm.controls['fromTime'].value"
        [endDate]="projectForm.controls['toDate'].value"
        [endTime]="projectForm.controls['toTime'].value"
      ></app-date-time>
    </div>

    <div class="project-form-horizontal-group">
      <app-quick-actions
        [startDateFormControl]="projectForm.controls['fromDate']"
        [startTimeFormControl]="projectForm.controls['fromTime']"
        [endDateFormControl]="projectForm.controls['toDate']"
        [endTimeFormControl]="projectForm.controls['toTime']"
        [priorityFormControl]="projectForm.controls['priority']"
      ></app-quick-actions>
    </div>

    <div class="project-form-buttons">
      <app-button
        class="project-form-buttons-item"
        appearance="link-blue"
        (click)="handleMoreOptions()"
      >
        More Options
      </app-button>

      <div class="project-form-buttons-container">
        <app-button
          class="project-form-buttons-item"
          appearance="link"
          [disabled]="saveInProgress"
          (click)="handleClickCancel()"
        >
          Cancel
        </app-button>

        <app-button
          class="project-form-buttons-item"
          type="submit"
          appearance="link-blue"
          [disabled]="saveInProgress"
        >
          Save
        </app-button>
      </div>
    </div>

  </form>
</div>
