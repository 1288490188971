// Common
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { SectionsTreeService } from '@modules/tasks/services/sections-tree.service';

// Types
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { Project } from '@modules/tasks/types/project';
import { Section } from '@modules/tasks/types/section';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';
import { SectionsTree } from '@modules/tasks/types/sections-tree';

// RX
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';


/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

const expandMotion: AnimationTriggerMetadata = trigger('expandMotion', [
  transition(':enter', [
    style({ height: 0 }), animate('0.3s ease-in-out', style({ height: '*' }))
  ]),
  transition(':leave', [
    style({ height: '*' }), animate('0.3s ease-in-out', style({ height: 0 }))
  ])
]);

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
  animations: [expandMotion]
})
export class ProjectComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() project: Project;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() selectedProjects: Project[] = [];

  // Public
  public selected = false;
  public isDragging = false;
  public contextMenuOpened = false;
  public sections: Section[] = [];
  public dragData: Project[] = [];
  public tasksCount = 0;
  public threadExpanded = false;
  public sectionsTree: SectionsTree;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'project' && dragData.data.length === 1 && dragData.data[0]['id'] === this.project.id) &&
    ['message', 'event', 'project', 'task', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected modalService: ModalService,
    protected tasksStateService: TasksStateService,
    protected changeDetector: ChangeDetectorRef,
    private projectsService: ProjectsService,
    protected linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService,
    private tasksService: TasksService,
    private sectionsTreeService: SectionsTreeService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.tasksStateService.getSelectedProjects()
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((projects: Project[]) => this.project && projects.some(project => project.id === this.project.id))
      )
      .subscribe((selected: boolean) => {
        this.selected = selected;
        if (selected) {
          this.sectionsTreeService.getSections(this.project.id)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe((sectionsTree: SectionsTree) => {
              this.sectionsTree = sectionsTree;
            });
        }
        this.changeDetector.detectChanges();
      });

    if (this.project) {
      this.tasksService.getTasks({ projectsIds: [this.project.id], limit: 0 })
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe(({ count }) => this.tasksCount = count);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('project' in changes) {
      this.dragData = [this.project];
    }
    if ('selectedProjects' in changes) {
      if (
        this.project &&
        this.selectedProjects &&
        this.selectedProjects.some(selected => selected.id === this.project.id)
      ) {
        this.dragData = this.selectedProjects;
        this.selected = true;
      } else {
        this.selected = false;
        this.dragData = [this.project];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleDoubleClick(project: Project) {

  }

  dndDrop(dragData: DragData) {
    if (!dragData.data) { return; }

    if (['message', 'event', 'project', 'task', 'note', 'group', 'contact'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('project', this.project.id), linkedItems);
    }

    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {projectsIds: [this.project.id]});
    }

    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {projectsIds: [this.project.id]});
    }
  }

  pin() {
    this.projectsService.pin([this.project.id], !this.project.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((projects: Project[]) => {
        if (projects[0]) {
          this.project.pinned = projects[0].pinned;
        }
      });
  }

  archive() {
    this.projectsService.archive([this.project.id], !this.project.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((projects: Project[]) => {
        if (projects[0]) {
          this.project.archived = projects[0].archived;
        }
      });
  }

  delete() {
    this.project.deleted ?
      this.projectsService.deletePermanently([this.project.id]) :
      this.projectsService.delete([this.project.id], true);
  }

  public handleExpand() {
    this.threadExpanded = !this.threadExpanded;
  }
}
