<div class="message-wrapper" *ngIf="message" (keyup)="onKeyUp($event)" tabindex="1">
  <app-message-context-menu-wrapper
    [enabled]="contextMenuEnabled"
    [message]="message"
    [selectedMessages]="selectedMessages"
    (selectedMessagesChange)="onSelectMessages($event)"
    (movedToAnotherFolder)="movedToAnotherFolder.emit($event)">
    <div
      class="message"
      [class.selected]="selectedMessages | includesBy : message.id : 'id'"
      [class.unread]="message.unread"
      [class.draggable]="draggable"
      [class.pinned]="message.pinned"
      (appClick)="onSelectMessage(message, $event)"
      (appDblClick)="onDoubleClick(message)"

      appDraggable
      [appDraggableEnabled]="dragEnabled"
      [appDraggableData]="{data: dragData, type: 'message'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerMultiple]="selectedMessages.length > 1"

      appDroppable
      appDroppableHoverClass="drag-over"
      [appDroppablePredicate]="dndPredicate"
      (appDroppableDrop)="dndDrop($event)"
    >
      <div class="icons">
        <mat-icon [class.enabled]="message.unread" class="small-icon unread" svgIcon="mail-unread"></mat-icon>
        <mat-icon
          class="small-icon attachment"
          [class.enabled]="message.files.length > 0"
          svgIcon="mail-attachment"
          popover
          [popoverDisabled]="!(message.files.length > 0)"
          [popoverContent]="attachmentPopover"
          [popoverArrow]="true"
          popoverPlacement="right"
          popoverTrigger="hover"
        ></mat-icon>
        <mat-icon [class.enabled]="message.pinned" class="small-icon pin" svgIcon="mail-pinned"></mat-icon>
        <mat-icon [class.enabled]="message.starred" class="small-icon star" svgIcon="mail-star"></mat-icon>
      </div>
      <mat-icon *ngIf="message.replied" class="small-icon mail-reply" svgIcon="mail-reply"></mat-icon>
      <mat-icon *ngIf="!message.replied && message.forwarded" class="small-icon mail-reply" svgIcon="forward"></mat-icon>
      <div class="message-info">
        <!-- Actions -->
        <ng-container *ngTemplateOutlet="messageActionTemplate; context:{ message: message }"></ng-container>
        <!-- Title -->
        <div class="message-title">
          <div class="name">
            <ng-container *ngIf="isSentOrDraftsFolder() || messageSentByMe(message)">
                To: {{ message.to | userNames }}
            </ng-container>
            <ng-container *ngIf="!isSentOrDraftsFolder() && !messageSentByMe(message)">
                {{ message.from?.name || message.from?.email || '(unknown)' }}
                <mat-icon *ngIf="message.from?.vip" svgIcon="crown"></mat-icon>
            </ng-container>
          </div>
          <div class="date">
            <ng-container *ngIf="!message.sending">
              {{ message.sentTime | relativeDate }}
            </ng-container>
            <ng-container *ngIf="message.sending" [ngSwitch]="message.sending?.status">
              <span *ngSwitchCase="'pending'" class="sending-default"><mat-icon svgIcon="pending"></mat-icon> {{'Will be sent ' + (message.sending?.date | relativeDate)}}</span>
              <span *ngSwitchCase="'sending'" class="sending-default"><mat-icon svgIcon="pending"></mat-icon> Sending...</span>
              <span *ngSwitchCase="'error'" class="sending-error"><mat-icon svgIcon="folder-spam"></mat-icon> Sending failed</span>
            </ng-container>
          </div>
        </div>
        <div class="subject">{{ message.subject || '(no subject)' }}</div>
        <div class="snippet">
          <div class="snippet-text" *ngIf="!message.highlightedSnippet">{{ message.snippet }}</div>
          <app-message-snippet class="snippet-text" *ngIf="message.highlightedSnippet" [snippet]="message.highlightedSnippet"></app-message-snippet>
          <div class="snippet-text" *ngIf="!message.snippet">This email was blank</div>
        </div>
        <mat-icon class="drag-icon" svgIcon="drag-icon"></mat-icon>
        <!-- Thread icon -->
        <div class="thread-button-wrapper" *ngIf="thread?.messages?.length && threadEnabled" (click)="onThreadToggle()" #threadButton>
          <div class="thread-button-shadow"></div>
          <div class="thread-button-background">
            <div class="thread-button">
              <div class="thread-button__text">+{{ thread?.count - 1 }}</div>
              <mat-icon class="thread-button__icon" [class.open]="threadExpanded" svgIcon="dropdown-small"></mat-icon>
              <div *ngIf="thread?.unread_count > 0" class="thread-button__badge"></div>
            </div>
          </div>
        </div>
        <app-link-info-bar *ngIf="message.linkedInfo?.length" [linkedInfo]="message.linkedInfo"></app-link-info-bar>
      </div>
      <!-- Selected Line -->
      <div *ngIf="selectedMessages | includesBy : message.id : 'id'" class="selected-line"></div>
      <!-- Drop zone -->
      <div class="drop-zone"></div>
    </div>
  </app-message-context-menu-wrapper>
  <div class="thread" *ngIf="threadExpanded">
    <app-message-context-menu-wrapper
      [enabled]="contextMenuEnabled"
      *ngFor="let threadMessage of thread?.messages"
      [message]="threadMessage"
      [selectedMessages]="selectedMessages"
      (selectedMessagesChange)="onSelectMessages($event)"
      (movedToAnotherFolder)="removeThreadMessageFromCache(message, $event)">
      <div
        class="message thread-message"
        [class.selected]="selectedMessages | includesBy : threadMessage.id : 'id'"
        (appClick)="onSelectMessage(threadMessage, $event)"
        (appDblClick)="onDoubleClick(threadMessage)"
        appDraggable
        [appDraggableEnabled]="dragEnabled && !threadMessage.isDraft"
        [appDraggableData]="{data: [threadMessage], type: 'message'}"
        [appDraggablePlaceholder]="dragThreadPlaceholder"
        [appDraggableContainerMultiple]="selectedMessages.length > 1"
      >
        <!-- Drag message placeholder -->
        <ng-template #dragThreadPlaceholder>
          <app-message
            [message]="threadMessage"
            [dragEnabled]="false"
            [draggable]="true"
            [actionsButtonEnabled]="false"
            [threadEnabled]="false">
          </app-message>
        </ng-template>
        <div class="icons thread">
          <mat-icon [class.enabled]="threadMessage.unread" class="small-icon unread thread" svgIcon="mail-unread"></mat-icon>
          <mat-icon *ngIf="threadMessage.replied" class="small-icon reply thread" svgIcon="mail-reply"></mat-icon>
          <mat-icon *ngIf="!threadMessage.replied && threadMessage.forwarded" class="small-icon reply thread" svgIcon="forward"></mat-icon>
          <mat-icon [class.enabled]="threadMessage.files.length > 0" class="small-icon attachment thread" svgIcon="mail-attachment"></mat-icon>
          <mat-icon [class.enabled]="threadMessage.pinned" class="small-icon pin thread" svgIcon="mail-pinned"></mat-icon>
          <mat-icon [class.enabled]="threadMessage.starred" class="small-icon star thread" svgIcon="mail-star"></mat-icon>
        </div>
        <div class="message-info">
          <ng-container *ngTemplateOutlet="messageActionTemplate; context:{ message: threadMessage }"></ng-container>
          <div class="message-title thread">
            <div class="name">
              <ng-container *ngIf="isSentOrDraftsFolder() || messageSentByMe(threadMessage)">
                  To: {{ threadMessage.to | userNames }}
              </ng-container>
              <ng-container *ngIf="!isSentOrDraftsFolder() && !messageSentByMe(threadMessage)">
                  {{ threadMessage.from?.name || threadMessage.from?.email || '(unknown)' }}
              </ng-container>
            </div>
            <div class="date">{{ threadMessage.sentTime | relativeDate }}</div>
          </div>
          <div class="subject">{{ threadMessage.subject || '(no subject)' }}</div>
          <div class="snippet thread">
            <div class="snippet-text thread" *ngIf="!threadMessage.highlightedSnippet">{{ threadMessage.snippet }}</div>
            <app-message-snippet class="snippet-text thread" *ngIf="threadMessage.highlightedSnippet" [snippet]="threadMessage.highlightedSnippet"></app-message-snippet>
            <div class="snippet-text thread" *ngIf="!threadMessage.snippet">This email was blank</div>
          </div>
          <mat-icon class="drag-icon" svgIcon="drag-icon-small"></mat-icon>
          <app-link-info-bar *ngIf="threadMessage.linkedInfo?.length" [linkedInfo]="threadMessage.linkedInfo"></app-link-info-bar>
        </div>
      </div>
    </app-message-context-menu-wrapper>
    <div class="thread-message__load-more">
      <div class="thread-message__label" *ngIf="thread?.count > thread?.messages?.length + thread?.offset" (click)="loadTreadMessages(message.thread)">
        + {{ thread?.count - thread?.messages?.length - thread?.offset }} more
      </div>
      <mat-icon class="thread-message__hide" svgIcon="arrow-left" (click)="onThreadToggle()"></mat-icon>
    </div>
  </div>
</div>

<!-- Drag message placeholder -->
<ng-template #dragPlaceholder>
  <app-message
    [message]="message"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [threadEnabled]="false">
  </app-message>
</ng-template>

<!-- Attachment popover -->
<ng-template #attachmentPopover>
  <app-related-files class="popover-attachments" [files]="message.files"></app-related-files>
</ng-template>

<!-- Message Placeholder -->
<div class="message-wrapper" *ngIf="!message">
  <div class="message-placeholder">
    <div class="icons">
      <div class="unread"></div>
      <div class="pin"></div>
      <div class="star"></div>
      <div class="attachment"></div>
    </div>
    <div class="message-info">
      <div class="message-title">
        <div class="name"></div>
        <div class="date"></div>
      </div>
      <div class="subject"></div>
      <div class="snippet"></div>
      <div class="snippet"></div>
    </div>
  </div>
</div>

<!-- Message Actions (hover) -->
<ng-template #messageActionTemplate let-message="message">
  <div class="actions" *ngIf="actionsButtonEnabled && !message.sending" #actionIcons>
    <div class="actions-gradient"></div>
    <div class="actions-background">
      <button class="button-icon-small btn-action" *ngIf="!message.starred" (click)="starMessage(message)" matTooltip="Star">
        <mat-icon svgIcon="star"></mat-icon>
      </button>
      <button class="button-icon-small btn-action selected" *ngIf="message.starred" (click)="starMessage(message)" matTooltip="Unstar">
        <mat-icon svgIcon="star"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="!message.pinned" (click)="pinMessage(message)" matTooltip="Pin">
        <mat-icon svgIcon="pin"></mat-icon>
      </button>
      <button class="button-icon-small btn-action selected" *ngIf="message.pinned" (click)="pinMessage(message)" matTooltip="Unpin">
        <mat-icon svgIcon="pin"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="!message.snoozed" (click)="snoozeMessage(message)" [matTooltip]="'Snoozed by ' + (snoozedSetting | snoozedDate)">
        <mat-icon svgIcon="snooze"></mat-icon>
      </button>
      <button class="button-icon-small btn-action selected" *ngIf="message.snoozed" (click)="removeSnoozeMessage(message)" matTooltip="Remove snoozed">
        <mat-icon svgIcon="snooze"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="message.unread" (click)="unreadMessage(message)" matTooltip="Mark read">
        <mat-icon svgIcon="mail"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="!message.unread" (click)="unreadMessage(message)" matTooltip="Mark unread">
        <mat-icon svgIcon="mail"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="!message.followed" (click)="followUpMessage(message)" matTooltip="Follow up by tomorrow">
        <mat-icon svgIcon="followup"></mat-icon>
      </button>
      <button class="button-icon-small btn-action selected" *ngIf="message.followed" (click)="removeFollowUpMessage(message)" matTooltip="Remove follow up">
        <mat-icon svgIcon="followup"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" *ngIf="!message.archived" (click)="moveToArchive(true, message)" matTooltip="Archive">
        <mat-icon svgIcon="archive"></mat-icon>
      </button>
      <button class="button-icon-small btn-action" (click)="deleteMessages([message])" matTooltip="Delete">
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
