// Common
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// RxJS
import { from , Subject, fromEvent } from 'rxjs';
import { mergeMap, takeUntil, filter } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Attachment } from '@modules/form-controls/types/attachment';

@Component({
  selector: 'app-message-context-menu-wrapper',
  templateUrl: './message-context-menu-wrapper.component.html',
  styleUrls: ['./message-context-menu-wrapper.component.less']
})
export class MessageContextMenuWrapperComponent implements OnInit, OnChanges, OnDestroy {

  // ViewChild
  @ViewChild('messageContextMenu', { static: true }) messageContextMenu: ContextMenuComponent;
  @ViewChild('draftContextMenu', { static: true }) draftContextMenu: ContextMenuComponent;
  @ViewChild('trashContextMenu', { static: true }) trashContextMenu: ContextMenuComponent;
  @ViewChild('bulkContextMenu', { static: true }) bulkContextMenu: ContextMenuComponent;
  @ViewChild('trashBulkContextMenu', { static: true }) trashBulkContextMenu: ContextMenuComponent;
  @ViewChild('pendingBulkContextMenu', { static: true }) pendingBulkContextMenu: ContextMenuComponent;

  // Inputs
  @Input() enabled = true;
  @Input() message: MailMessage;
  @Input() selectedMessages: MailMessage[];
  @Input() eventTrigger = 'contextmenu';
  @Input() dropdownStyle = false;

  // Outputs
  @Output() selectedMessagesChange: EventEmitter<MailMessage[]> = new EventEmitter<MailMessage[]>();
  // TODO: Remove that output as outdated
  @Output() movedToAnotherFolder: EventEmitter<MailMessage> = new EventEmitter<MailMessage>();

  // Public
  public messageFolders: string[];
  public linkedInfo: LinkedInfo[] = [];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private element: Element;

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService,
    private ga: GoogleAnalyticsService,
    private modalService: ModalService,
    private contextMenuService: ContextMenuService,
    private elementRef: ElementRef,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private linkedInfoService: LinkedInfoService
  ) { }


  /**
   * Component lifecycle
   */

  ngOnInit() {
    fromEvent(this.elementRef.nativeElement, this.eventTrigger)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((event: MouseEvent) => this.onContextMenu(event));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
      this.messageFolders = this.message.labels.map(value => value.name);
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  onContextMenu($event) {
    if (!this.enabled) {
      return;
    }

    // Show contextMenu like dropdown
    let element: Element;
    this.element = $event.currentTarget.children[0];
    this.element.classList.add('active');

    if (this.dropdownStyle) {
      element = $event.currentTarget.children[0];
    }

    // Context menu, depending on the message type
    let contextMenu: ContextMenuComponent = this.messageContextMenu;
    if (this.selectedMessages && (this.selectedMessages.length > 1) && this.selectedMessages.includes(this.message)) {
      if (this.messageFolders.includes('trash')) {
        contextMenu = this.trashBulkContextMenu;
      } else {
        contextMenu = this.bulkContextMenu;
        this.linkedInfo = this.selectedMessages.map(selectedMessage => ({type: 'message', id: selectedMessage.id}));
      }
    } else if (this.message.sending) {
      if (this.message.sending.status === 'sending' || !this.message.sending.status) {
        return;
      }
      contextMenu = this.pendingBulkContextMenu;
    } else if (this.message.isDraft) {
      contextMenu = this.draftContextMenu;
    } else if (this.messageFolders.includes('trash')) {
      contextMenu = this.trashContextMenu;
    }

    this.contextMenuService.show.next({
      anchorElement: element,
      contextMenu: contextMenu,
      event: $event,
      item: this.message,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  /**
   * Context menu
   */

  // Snooze
  snoozeMessage(snooze: string, message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'snooze');
    this.mailService.snoozeMessage(snooze, message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.snoozed = true);
  }

  removeSnoozeMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'remove-snooze');
    this.mailService.removeSnoozeMessage(message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.snoozed = false);
  }

  // Follow up
  followMessage(snooze: string, message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'follow-up');
    this.mailService.followMessage(snooze, message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.followed = true);
  }

  removeFollowMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'remove-follow-up');
    this.mailService.removeFollowMessage(message.id)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => message.followed = false);
  }

  // Reply/ReplyAll/Forward
  reply(sourceMessage: MailMessage, action: 'reply' | 'replyAll' | 'forward', files?: Attachment[]) {
    this.ga.trackEvent('message-context-menu', action);
    const message = new MailMessage();
    message.files = files || [];
    this.modalService.compose(message, sourceMessage, action);
  }

  // Star/Read/Pin/Important
  starMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'star');
    this.mailService.starMessage(message).subscribe();
  }

  markMessageAsReadUnread(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'read');
    this.mailService.updateMessagesUnreadStatus({messagesIds: [message.id]}, !message.unread);
  }

  pinMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'pin');
    this.mailService.pinnedMessage(message).subscribe();
  }

  markAsImportant(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'important');
    this.mailService.moveMessages({ messages: [message] }, ['important'], true);
  }

  // Delete
  deleteMessage(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'delete');
    this.mailService.deleteMessages({ ids: [message.id] })
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((isMoved: boolean) => {
        if (isMoved) {
          this.movedToAnotherFolder.emit(this.message);
        }
      });
  }

  deleteDraft(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'delete-draft');

    this.mailService.deleteDraft(message)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((isDeleted: boolean) => {
        if (isDeleted) {
          this.mailService.doRefreshMailList();
        }
      });
  }

  // Move
  moveMessages(folder: string, messages?: MailMessage[]) {
    if ((messages || this.selectedMessages).some(msg => !!msg.threadMessages)) {
      this.modalService.confirmationModal(
        'Selected message(s) are part of the thread. Would you like to move all messages in the thread?',
        'Move Message(s)',
        'Yes',
        'Cancel',
        (withThread: boolean) => {
          this.mailService.moveMessages({
            messages: messages || this.selectedMessages,
            filters: withThread ?
              { threadIds: (messages || this.selectedMessages).map(message => message.thread) } :
              null
          }, [folder])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe((moved: boolean) => {
              if (moved) { this.clearBulk(); }
            });
        });
    } else {
      this.mailService.moveMessages({ messages: messages || this.selectedMessages }, [folder])
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((moved: boolean) => {
          if (moved) { this.clearBulk(); }
        });
    }

  }

  moveMessagesToInbox() {
    this.ga.trackEvent('message-context-menu', 'move-to-inbox');
    this.moveMessages('inbox');
  }

  moveMessagesToNewFolder(messages?: MailMessage[]) {
    this.ga.trackEvent('message-context-menu', 'move-to-new-folder');
    const folderName = prompt('Enter new folder name:');
    if (!folderName) {
      return;
    }

    this.mailService.createFolder(folderName).subscribe(folder => {
      if (folder.id) {
       this.moveMessages(folder.id, (messages || this.selectedMessages));
      }
    });
  }

  moveToArchive(archived: boolean, messages?: MailMessage[]) {
    this.ga.trackEvent('message-context-menu', `move-to-archive`);
    this.mailService.archiveMessages(messages || this.selectedMessages, archived)
      .subscribe((isMoved: boolean) => {
        if (isMoved) {
          (messages || this.selectedMessages).forEach((message: MailMessage) => {
            this.movedToAnotherFolder.emit(message);
          });
          this.clearBulk();
        }
      });
  }

  moveToFolder(message: MailMessage, folder: string) {
    this.ga.trackEvent('message-context-menu', `move-to-${folder}`);
    this.moveMessages(folder, [message]);
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  moveBulkToFolder(folder: string) {
    this.ga.trackEvent('message-context-menu', `move-to-${folder}`);
    this.moveMessages(folder, this.selectedMessages);
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  copyToFolder(message: MailMessage, folder: string) {
    this.ga.trackEvent('message-context-menu', `copy-to-${folder}`);
    this.mailService.moveMessages({ messages: [message] }, [folder], true);
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  copyBulkToFolder(folder: string) {
    this.ga.trackEvent('message-context-menu', `copy-to-${folder}`);
    this.mailService.moveMessages({ messages: this.selectedMessages }, [folder], true);
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  selectForBulk(message: MailMessage) {
    const index = this.selectedMessages.indexOf(message);
    if (index === -1) {
      this.selectedMessages.push(message);
      this.selectedMessagesChange.emit(this.selectedMessages);
    }
  }

  unselectMessage(message: MailMessage) {
    const index = this.selectedMessages.indexOf(message);
    if (index !== -1) {
      this.selectedMessages.splice(index, 1);
      this.selectedMessagesChange.emit(this.selectedMessages);
    }
  }

  createCalendarEvent(message: MailMessage) {
    this.ga.trackEvent('message-context-menu', 'create-appointment');
    const calendarEvent = CalendarEvent.fromMailMessage(message);
    this.modalService.calendarEventForm(calendarEvent);
  }

  closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  /**
   * Bulk context menu
   */

  clearBulk() {
    this.selectedMessages = [];
    this.selectedMessagesChange.emit(this.selectedMessages);
  }

  pinMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'pin');
    const messages = this.selectedMessages.filter(message => !message.pinned);
    from(messages).pipe(
      mergeMap(message => this.mailService.pinnedMessage(message))
    ).subscribe();
  }

  unpinMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'unpin');
    const messages = this.selectedMessages.filter(message => message.pinned);
    from(messages).pipe(
      mergeMap(message => this.mailService.pinnedMessage(message))
    ).subscribe();
  }

  starMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'star');
    const messages = this.selectedMessages.filter(message => !message.starred);
    from(messages).pipe(
      mergeMap(message => this.mailService.starMessage(message))
    ).subscribe();
  }

  unstarMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'unstar');
    const messages = this.selectedMessages.filter(message => message.starred);
    from(messages).pipe(
      mergeMap(message => this.mailService.starMessage(message))
    ).subscribe();
  }

  readMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'read');
    const messages = this.selectedMessages.filter(message => message.unread);
    const messagesIds = messages.map(message => message.id);
    this.mailService.updateMessagesUnreadStatus({messagesIds}, false);
  }

  unreadMessagesInBulk() {
    this.ga.trackEvent('message-context-menu', 'unread');
    const messages = this.selectedMessages.filter(message => !message.unread);
    const messagesIds = messages.map(message => message.id);
    this.mailService.updateMessagesUnreadStatus({messagesIds}, true);
  }

  importantMessageInBulk() {
    this.ga.trackEvent('message-context-menu', 'important');
    this.mailService.moveMessages({ messages: this.selectedMessages }, ['important'], true);
  }

  moveMessagesToFolder(folder: string) {
    this.moveMessages(folder);
  }

  linkMessages() {
    this.linkedInfoService.linkItems([...this.linkedInfo]);
  }

  deleteAllInBulk() {
    this.ga.trackEvent('message-context-menu', 'delete');
    this.mailService.deleteMessages({
      ids: this.selectedMessages.map(message => message.id)
    })
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((isMoved: boolean) => {
        if (isMoved) {
          this.selectedMessages.forEach(
            message => this.movedToAnotherFolder.emit(message)
          );
        }
      });
  }

  openCustomDateModal(message: MailMessage, reminderType: 'followup'|'snooze') {
    this.modalService.reminderDateTimePicker(this.message, reminderType);
  }

  cancelSendingMessage(message: MailMessage) {
    this.mailService.cancelSendMessage(message)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((draftMessage: MailMessage) => {
        if (draftMessage) {
          this.modalService.compose(draftMessage);
        }
      });
  }

  editSendingMessage(message: MailMessage) {
    this.modalService.compose(message);
  }

  resendSendingMessage(message: MailMessage) {
    this.mailService.sendMessage(message)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((sentMessage: MailMessage)  => {
        if (sentMessage) {
          this.movedToAnotherFolder.emit(sentMessage);
          const actions = [{
            text: 'View',
            handler: () => this.modalService.mailContent(message)
          }];
          if (message.sending) {
            actions.push({
              text: 'Undo',
              handler: () => this.mailService.cancelSendMessage(message)
            });
          }
          this.asyncTasksToastsService.show({
            text: 'Your message has been sent.',
            actions
          });
        }
      }, (error: Error) => {
        this.asyncTasksToastsService.show({
          text: `Error while sending message: ${error.message}.`
        });
      });
  }

  /**
   * Methods
   */

  makeItemInactive() {
    if (this.element) {
      this.element.classList.remove('active');
    }
  }

  includeMessageInBulk() {
    return this.selectedMessages && this.message && this.selectedMessages.includes(this.message);
  }

  includePinMessages() {
    return this.selectedMessages && this.selectedMessages.findIndex(message => message.pinned) !== -1;
  }

  includeUnreadMessages() {
    return this.selectedMessages && this.selectedMessages.findIndex(message => message.unread) !== -1;
  }

  includeStarMessages() {
    return this.selectedMessages && this.selectedMessages.findIndex(message => message.starred) !== -1;
  }

  includeArchiveMessages() {
    return this.selectedMessages && this.selectedMessages.findIndex(message => message.archived) !== -1;
  }

  includeFolderInMessages(folder: string) {
    return this.selectedMessages && this.selectedMessages
      .findIndex(message => message.labels.map(value => value.name).includes(folder)) !== -1;
  }
}
