// Common
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromPairs } from 'lodash';
import { ElectronService } from 'ngx-electron';

// RxJS
import { BehaviorSubject, Observable , of, throwError, Subscriber } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

// Services
import { GoogleAuthService } from './google-auth.service';

// Env
import { environment } from '@environment';


@Injectable()
export class AuthService {

  authenticated: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private googleAuth: GoogleAuthService,
    private electronService: ElectronService
  ) {
    this.authenticated = new BehaviorSubject<boolean>(this.isAuthenticated());
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  private authenticate(token: string) {
    localStorage.setItem('token', token);
    this.authenticated.next(true);
  }

  signOut(): Observable<boolean> {
    return this.http.post(environment.baseUrl + '/api/auth/logout', {}, {withCredentials: true})
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        tap(() => {
          localStorage.removeItem('token');
          this.authenticated.next(false);
        })
      );
  }

  signIn(code: string, redirectUri: string): Observable<boolean> {
    return this.http.post(environment.baseUrl + '/api/auth/google', {
      code: code,
      redirect_uri: redirectUri
    }, { withCredentials: true })
      .pipe(
        // Unify errors from API and other sources
        catchError(error => throwError({
            message: error.error.error || error.message,
            code: error.error.error_code || 'internal_error'
        })),
        map((data: {token: string, account_ready: boolean}) => {
          if (!data['token']) {
            throw new Error('Error while generating access token. Please try again.');
          }
          this.authenticate(data['token']);
          return !!data['account_ready'];
        })
      );
  }

  googleSignIn(): Observable<boolean> {
    if (this.electronService.isElectronApp) {
      return this.grantOfflineAccess()
        .pipe(
          switchMap((code: string) => this.signIn(code, environment.socialAuth.google.redirectUri))
        );
    }

    return this.googleAuth.getAuth()
      .pipe(
        switchMap(auth => new Observable((observer: Subscriber<string>) => {
          auth.grantOfflineAccess({prompt: 'consent'})
            .then(
              res => this.ngZone.run(() => {
                observer.next(res.code);
                observer.complete();
              }),
              ({error}) => this.ngZone.run(() => {
                let errorMessage = 'Unknown error. Please try again.';
                switch (error) {
                  case 'popup_closed_by_user':
                    errorMessage = 'Auth window was closed before authorization process finished. Please try again.';
                    break;
                  case 'access_denied':
                    errorMessage = 'You have denied access to required scope. Please try again and grant the access.';
                    break;
                }
                observer.error(new Error(errorMessage));
              })
            );
        })),
        switchMap((code: string) => this.signIn(code, window.location.origin))
      );
  }

  grantOfflineAccess(): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      const authWindow = new this.electronService.remote.BrowserWindow({
        width: 500,
        height: 600,
        show: false,
        alwaysOnTop: true
      });

      const authUrl =
        `https://accounts.google.com/o/oauth2/v2/auth?` +
        `response_type=code&` +
        `prompt=${encodeURIComponent(environment.socialAuth.google.prompt)}&&` +
        `redirect_uri=${encodeURIComponent(environment.socialAuth.google.redirectUri)}&` +
        `client_id=${encodeURIComponent(environment.socialAuth.google.clientId)}&` +
        `scope=${encodeURIComponent(environment.socialAuth.google.scope.join(' '))}`;

      const handleNavigation = (url: string) => this.ngZone.run(() => {
        const { code, error } = fromPairs(
          new URL(url).search
            .slice(1)
            .split('&')
            .map((param: string) => param.split('='))
        );

        if (code) {
          observer.next(code);
          observer.complete();
        } else if (error) {
          observer.error(new Error(error));
        } else if (url.startsWith(environment.socialAuth.google.redirectUri)) {
          observer.error(new Error('Unknown error. Redirected without code and error.'));
        } else {
          return;
        }

        authWindow.removeAllListeners('closed');
        setTimeout(() => authWindow.close(), 0);
      });

      authWindow.on('closed', () => this.ngZone.run(() => observer.error(
        new Error('Auth window was closed before authorization process finished. Please try again.'))
      ));
      authWindow.webContents.on('will-navigate', (event, url) => handleNavigation(url));
      authWindow.webContents.on('will-redirect', (event, url) => handleNavigation(url));
      authWindow.once('ready-to-show', () => authWindow.show());

      authWindow.loadURL(authUrl);
    });
  }
}
