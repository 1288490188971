/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-selector.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../dropdown/components/dropdown-action-items/dropdown-action-items.component.ngfactory";
import * as i12 from "../../../../dropdown/components/dropdown-action-items/dropdown-action-items.component";
import * as i13 from "../../loader/loader.component.ngfactory";
import * as i14 from "../../loader/loader.component";
import * as i15 from "../../../../dropdown/components/dropdown/dropdown.component.ngfactory";
import * as i16 from "../../../../dropdown/components/dropdown/dropdown.component";
import * as i17 from "../../../../dropdown/components/dropdown-single-select/dropdown-single-select.component.ngfactory";
import * as i18 from "../../../../dropdown/components/dropdown-single-select/dropdown-single-select.component";
import * as i19 from "@angular/common";
import * as i20 from "./signature-selector.component";
import * as i21 from "../../../../account/services/account.service";
import * as i22 from "../../../../modal/services/modal.service";
var styles_SignatureSelectorComponent = [i0.styles];
var RenderType_SignatureSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureSelectorComponent, data: {} });
export { RenderType_SignatureSelectorComponent as RenderType_SignatureSelectorComponent };
function View_SignatureSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "mat-icon", [["class", "toolbar-icon mat-icon notranslate"], ["matTooltip", "Signature"], ["role", "img"], ["svgIcon", "signature"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), i1.ɵdid(2, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "signature"; _ck(_v, 1, 0, currVal_2); var currVal_3 = "Signature"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SignatureSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-dropdown-action-items", [], null, [[null, "actionSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionSelected" === en)) {
        var pd_0 = (_co.addSignature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_DropdownActionItemsComponent_0, i11.RenderType_DropdownActionItemsComponent)), i1.ɵdid(1, 114688, [[4, 4]], 0, i12.DropdownActionItemsComponent, [], { options: [0, "options"] }, { actionSelected: "actionSelected" }), i1.ɵpod(2, { name: 0, key: 1 }), i1.ɵpad(3, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, "Add new signature...", "add")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SignatureSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i13.View_LoaderComponent_0, i13.RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i14.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SignatureSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "app-dropdown", [["align", "right"]], null, null, null, i15.View_DropdownComponent_0, i15.RenderType_DropdownComponent)), i1.ɵdid(1, 1753088, null, 6, i16.DropdownComponent, [], { align: [0, "align"], closeOnSelect: [1, "closeOnSelect"] }, null), i1.ɵqud(335544320, 1, { toggleButtonTemplate: 0 }), i1.ɵqud(603979776, 2, { singleSelects: 1 }), i1.ɵqud(603979776, 3, { multiSelects: 1 }), i1.ɵqud(603979776, 4, { actionItems: 1 }), i1.ɵqud(603979776, 5, { templateItems: 1 }), i1.ɵqud(603979776, 6, { colorSelect: 1 }), (_l()(), i1.ɵand(0, [[1, 2], ["toggleButtonTemplate", 2]], 0, 0, null, View_SignatureSelectorComponent_1)), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-dropdown-single-select", [["title", "Signatures"]], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.selectSignature($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_DropdownSingleSelectComponent_0, i17.RenderType_DropdownSingleSelectComponent)), i1.ɵdid(10, 114688, [[2, 4]], 0, i18.DropdownSingleSelectComponent, [], { title: [0, "title"], selected: [1, "selected"], options: [2, "options"], divider: [3, "divider"] }, { selectedChange: "selectedChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SignatureSelectorComponent_2)), i1.ɵdid(12, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SignatureSelectorComponent_3)), i1.ɵdid(14, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right"; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "Signatures"; var currVal_3 = _co.signaturesSelectedOption; var currVal_4 = _co.signaturesOptions; var currVal_5 = ((_co.signaturesOptions == null) ? null : _co.signaturesOptions.length); _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.signaturesOptions; _ck(_v, 12, 0, currVal_6); var currVal_7 = !_co.signaturesOptions; _ck(_v, 14, 0, currVal_7); }, null); }
export function View_SignatureSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature-selector", [], null, null, null, View_SignatureSelectorComponent_0, RenderType_SignatureSelectorComponent)), i1.ɵdid(1, 245760, null, 0, i20.SignatureSelectorComponent, [i21.AccountService, i22.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureSelectorComponentNgFactory = i1.ɵccf("app-signature-selector", i20.SignatureSelectorComponent, View_SignatureSelectorComponent_Host_0, { signature: "signature" }, { signatureChange: "signatureChange" }, []);
export { SignatureSelectorComponentNgFactory as SignatureSelectorComponentNgFactory };
