// Common
import {
  Component,
  OnInit,
  ViewContainerRef,
  Type,
  ComponentFactoryResolver,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  NgZone,
  OnDestroy,
  ComponentRef,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { DragDrop, DragRef } from '@angular/cdk/drag-drop';

// Components
import { BaseModalComponent } from './../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.less']
})
export class ModalWrapperComponent implements OnInit, OnDestroy {

  // Outputs
  @Output() closed = new EventEmitter();
  @Output() collapsed = new EventEmitter<boolean>();

  // Public
  public childComponent: Type<any>;
  public childComponentName: string;
  public childComponentData: Object;
  public overlaps: boolean;
  public defaultFrame: ModalFrame;
  public customFrame: ModalFrame;
  public disabledResize: boolean;
  public minimized: boolean;

  // ViewChild
  @ViewChild('modalAnchor', { read: ViewContainerRef, static: true }) private modalAnchor: ViewContainerRef;

  // Private
  private componentRef: ComponentRef<BaseModalComponent>;
  private sizeChanges = new Subject();
  private componentNotDestroyed = new Subject();
  private dragElement: DragRef;
  private minSize = {
    width: 400,
    height: 300
  };

  /**
   * Constructor
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private dragDrop: DragDrop,
    private elementRef: ElementRef
  ) { }

  /**
   * Component lifecycle
   */
  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.childComponent);
    this.componentRef = this.modalAnchor.createComponent(componentFactory);

    // Inject data and handlers
    if (this.childComponentData) {
      Object.keys(this.childComponentData).forEach(key => {
        this.componentRef.instance[key] = this.childComponentData[key];
      });
    }
    if (this.componentRef.instance.collapsed) {
      this.componentRef.instance.collapsed.subscribe((minimized) => {
        if (this.dragElement) {
          this.dragElement.disabled = minimized;
        }
        this.collapsed.emit(minimized);
      });
    }
    if (this.componentRef.instance.closed) {
      this.componentRef.instance.closed.subscribe(() => this.closed.emit());
    } else {
      console.warn('Modal window without close output. There is no way to close it.');
    }

    // Make draggable and handle drag event
    this.dragElement = this.dragDrop.createDrag(this.elementRef.nativeElement);
    const dragHandler = this.componentRef.location.nativeElement.querySelector('div.modal-header');
    if (this.dragElement && dragHandler) {
      this.dragElement.withHandles([dragHandler]);
      this.dragElement.ended
        .pipe(
          takeUntil(this.componentNotDestroyed)
        )
        .subscribe(() => {
          this.saveSizeAndPosition();
        });
    }

    // Set modal size and position
    this.defaultFrame = this.componentRef.instance.defaultSize;
    this.setSizeAndPosition(this.defaultFrame, this.customFrame);
    if (this.overlaps) {
      this.overlapsPosition();
    }
  }

  ngOnDestroy() {
    this.sizeChanges.next();
    this.sizeChanges.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Public Methods
   */

  public collapse(minimized: boolean, index: number = 0) {
    this.minimized = minimized;
    if (minimized) {
      const minimizedFrame: ModalFrame = {
        x: 'calc(100% - 370px)',
        y: `calc(100% - ${(index + 1) * 32 + index * 1}px)`,
        width: '320px',
        height: '32px'
      };
      this.setSizeAndPosition(this.defaultFrame, minimizedFrame);
    } else {
      this.setSizeAndPosition(this.defaultFrame, this.customFrame);
    }
  }

  /**
   * Resize
   */

  resize(event: MouseEvent) {
    this.ngZone.runOutsideAngular(() => {
      this.sizeChanges.next();

      let lastCoordinates = {
        x: event.x,
        y: event.y
      };

      fromEvent(window, 'mousemove')
        .pipe(
          takeUntil(this.sizeChanges)
        )
        .subscribe((moveEvent: MouseEvent) => {
          const delta = {
            x: moveEvent.x - lastCoordinates.x,
            y: moveEvent.y - lastCoordinates.y,
          };

          const currentSize = {
            width: this.hostElement.nativeElement.clientWidth,
            height: this.hostElement.nativeElement.clientHeight
          };

          const newSize = {
            width: currentSize.width + delta.x > this.minSize.width ? currentSize.width + delta.x : this.minSize.width,
            height: currentSize.height + delta.y > this.minSize.height ? currentSize.height + delta.y : this.minSize.height
          };

          if (currentSize.width !== newSize.width) {
            this.renderer.setStyle(this.hostElement.nativeElement, 'width', `${newSize.width}px`);
          }

          if (currentSize.height !== newSize.height) {
            this.renderer.setStyle(this.hostElement.nativeElement, 'height', `${newSize.height}px`);
          }

          // Ovverride previous coordinates to actual amount of pixels windiw size changed
          lastCoordinates.x = lastCoordinates.x + (this.hostElement.nativeElement.clientWidth - currentSize.width);
          lastCoordinates.y = lastCoordinates.y + (this.hostElement.nativeElement.clientHeight - currentSize.height);
        });

      fromEvent(window, 'mouseup')
        .pipe(
          takeUntil(this.sizeChanges)
        )
        .subscribe((mouseEvent: MouseEvent) => {
          this.saveSizeAndPosition();
          lastCoordinates = null;
          this.sizeChanges.next();
        });
    });
  }

  overlapsPosition() {
    const modalShiftSize = 5;
    const rect = this.hostElement.nativeElement.getBoundingClientRect();
    localStorage.setItem(`${this.childComponentName}.modal.x`, rect.x + modalShiftSize);
    localStorage.setItem(`${this.childComponentName}.modal.y`, rect.y + modalShiftSize);
    this.setSizeAndPosition();
  }

  saveSizeAndPosition() {
    const rect = this.hostElement.nativeElement.getBoundingClientRect();
    localStorage.setItem(`${this.childComponentName}.modal.x`, rect.x);
    localStorage.setItem(`${this.childComponentName}.modal.y`, rect.y);
    if (!this.disabledResize) {
      localStorage.setItem(`${this.childComponentName}.modal.width`, rect.width);
      localStorage.setItem(`${this.childComponentName}.modal.height`, rect.height);
    }
  }

  setSizeAndPosition(defaultFrame?: ModalFrame, customFrame?: ModalFrame) {
    const element = this.hostElement.nativeElement;
    let frame = new ModalFrame();

    // Set default frame
    if (defaultFrame) {
      frame = Object.assign({}, defaultFrame);
    }

    // Local store
    const x = localStorage.getItem(`${this.childComponentName}.modal.x`);
    const y = localStorage.getItem(`${this.childComponentName}.modal.y`);
    if (x) { frame.x = `${x}px`; }
    if (y) { frame.y = `${y}px`; }

    if (!this.disabledResize) {
      const width = localStorage.getItem(`${this.childComponentName}.modal.width`);
      const height = localStorage.getItem(`${this.childComponentName}.modal.height`);
      if (width) { frame.width = `${width}px`; }
      if (height) { frame.height = `${height}px`; }
    }

    // Custom frame
    if (customFrame) {
      const custom = Object.assign({}, customFrame);
      if (custom.x) { frame.x = custom.x; }
      if (custom.y) { frame.y = custom.y; }
      if (custom.width) { frame.width = custom.width; }
      if (custom.height) { frame.height = custom.height; }
    }

    // Set styles
    if (frame.x) { this.renderer.setStyle(element, 'left', frame.x); }
    if (frame.y) { this.renderer.setStyle(element, 'top', frame.y); }
    if (frame.width) { this.renderer.setStyle(element, 'width', frame.width); }
    if (frame.height) { this.renderer.setStyle(element, 'height', frame.height); }
    if (this.minimized) { this.renderer.removeStyle(element, 'transform'); }
    this.renderer.setStyle(element, 'z-index', (this.minimized ? 210 : 200));

    // Set last size to child modal
    if (!this.minimized) {
      const rect = this.hostElement.nativeElement.getBoundingClientRect();
      this.componentRef.instance.maximizedSize = new ModalFrame(rect.width, rect.height, rect.x, rect.y);
    }
  }
}
