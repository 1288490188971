// RxJS
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DynamicPanelService = /** @class */ (function () {
    /**
     * Constructor
     */
    function DynamicPanelService() {
        // Private
        this.content = new BehaviorSubject({ type: 'kp' });
    }
    /**
     * Methods
     */
    DynamicPanelService.prototype.setContent = function (type, data) {
        this.content.next({ type: type || 'kp', data: data });
    };
    DynamicPanelService.prototype.getContent = function () {
        return this.content.asObservable();
    };
    DynamicPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicPanelService_Factory() { return new DynamicPanelService(); }, token: DynamicPanelService, providedIn: "root" });
    return DynamicPanelService;
}());
export { DynamicPanelService };
