/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-picker-button.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../color-picker/color-picker.component.ngfactory";
import * as i3 from "../color-picker/color-picker.component";
import * as i4 from "../../../popover/directives/popover.directive";
import * as i5 from "../../../popover/services/popover.service";
import * as i6 from "@angular/common";
import * as i7 from "./color-picker-button.component";
var styles_ColorPickerButtonComponent = [i0.styles];
var RenderType_ColorPickerButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPickerButtonComponent, data: {} });
export { RenderType_ColorPickerButtonComponent as RenderType_ColorPickerButtonComponent };
function View_ColorPickerButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, i2.View_ColorPickerComponent_0, i2.RenderType_ColorPickerComponent)), i1.ɵdid(1, 245760, null, 0, i3.ColorPickerComponent, [], { inputFormControl: [0, "inputFormControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputFormControl; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ColorPickerButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "color-picker-button"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "click"]], null, null, null, null, null)), i1.ɵdid(1, 4866048, null, 0, i4.PopoverDirective, [i1.ElementRef, i5.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverArrow: [3, "popoverArrow"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "selected-color"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgStyleImpl, i6.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i6.NgStyle, [i6.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "background-color": 0 }), (_l()(), i1.ɵand(0, [["colorPicker", 2]], null, 0, null, View_ColorPickerButtonComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6); var currVal_1 = "right"; var currVal_2 = "click"; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _ck(_v, 5, 0, (_co.inputFormControl.value || _co.defaultColor)); _ck(_v, 4, 0, currVal_4); }, null); }
export function View_ColorPickerButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker-button", [], null, null, null, View_ColorPickerButtonComponent_0, RenderType_ColorPickerButtonComponent)), i1.ɵdid(1, 49152, null, 0, i7.ColorPickerButtonComponent, [], null, null)], null, null); }
var ColorPickerButtonComponentNgFactory = i1.ɵccf("app-color-picker-button", i7.ColorPickerButtonComponent, View_ColorPickerButtonComponent_Host_0, { inputFormControl: "inputFormControl", defaultColor: "defaultColor" }, {}, []);
export { ColorPickerButtonComponentNgFactory as ColorPickerButtonComponentNgFactory };
