<app-event-calendar 
  *ngIf="view === 'calendar'"
></app-event-calendar>

<app-event-form 
  *ngIf="view === 'event-form'"
  [event]="event"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-event-form>
