// Common
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-confirmation-form',
  templateUrl: './confirmation-form.component.html',
  styleUrls: ['./confirmation-form.component.less'],
})
export class ConfirmationFormComponent implements OnInit {

  // Inputs
  @Input() title: string;
  @Input() text: string;
  @Input() cancelButtonLabel: string;
  @Input() submitButtonLabel: string;
  @Input() action?: Function;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();



  /**
   * Constructor
   */

  constructor() { }

  /**
   * Lifecycle
   */

  ngOnInit() {

  }

  /**
   * Actions
   */

  submit() {
    this.action(true);
    this.close.emit();
  }

  cancel() {
    this.action(false);
    this.close.emit();
  }
}
