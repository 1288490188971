/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i3 from "ngx-contextmenu";
import * as i4 from "./contact-context-menu.component";
import * as i5 from "../../../../analytics/services/google-analytics.service";
import * as i6 from "../../../../modal/services/modal.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../../mail/services/mail.service";
var styles_ContactContextMenuComponent = [i0.styles];
var RenderType_ContactContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactContextMenuComponent, data: {} });
export { RenderType_ContactContextMenuComponent as RenderType_ContactContextMenuComponent };
function View_ContactContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Make VIP "]))], null, null); }
function View_ContactContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Remove VIP "]))], null, null); }
function View_ContactContextMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Copy Email Address "]))], null, null); }
function View_ContactContextMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Send New Email "]))], null, null); }
function View_ContactContextMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" All Emails for Connection "]))], null, null); }
function View_ContactContextMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Go to Explore "]))], null, null); }
function View_ContactContextMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Load in Knowledge Pane "]))], null, null); }
export function View_ContactContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "context-menu", [], null, null, null, i2.View_ContextMenuComponent_0, i2.RenderType_ContextMenuComponent)), i1.ɵdid(2, 180224, [[1, 4], ["contextMenu", 4]], 1, i3.ContextMenuComponent, [i3.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i3.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.changeVipStatus(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_1)), i1.ɵdid(5, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.changeVipStatus(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_2)), i1.ɵdid(7, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.copyEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_3)), i1.ɵdid(9, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.sendNewEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_4)), i1.ɵdid(11, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openRelatedEmails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_5)), i1.ɵdid(13, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openRelatedTopics() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_6)), i1.ɵdid(15, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.loadContactInKnowledgePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContactContextMenuComponent_7)), i1.ɵdid(17, 16384, [[2, 4]], 0, i3.ɵb, [i1.TemplateRef, i1.ElementRef], { visible: [0, "visible"] }, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.relatedContact.vip; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.relatedContact.vip; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.loadInKnowledgePanel.observers.length > 0); _ck(_v, 17, 0, currVal_2); }, null); }
export function View_ContactContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-context-menu", [], null, null, null, View_ContactContextMenuComponent_0, RenderType_ContactContextMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i4.ContactContextMenuComponent, [i5.GoogleAnalyticsService, i6.ModalService, i7.Router, i8.MailService, i3.ContextMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactContextMenuComponentNgFactory = i1.ɵccf("app-user-context-menu", i4.ContactContextMenuComponent, View_ContactContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem", relatedContact: "relatedContact" }, { close: "close", loadInKnowledgePanel: "loadInKnowledgePanel" }, []);
export { ContactContextMenuComponentNgFactory as ContactContextMenuComponentNgFactory };
