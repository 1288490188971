<div
  class="section"
>
  <div
    *ngIf="!section.default"
    class="section-title"
    [class.board]="appearance === 'board'"
  >
    <div class="section-title-marker"></div>
    <input
      class="section-title-input"
      (blur)="handleBlur()"
      placeholder="Enter section title"
      [formControl]="sectionForm.controls['title']"
    >
    <span class="section-title-count-prioritized" *ngIf="appearance === 'default'">1</span>
    <span class="section-title-count" *ngIf="appearance === 'default'">/3</span>
    <div class="section-title-icons">
      <mat-icon
        svgIcon="open-r"
        class="section-title-icon"
      ></mat-icon>
      <mat-icon
        svgIcon="dropdown"
        class="section-title-icon"
        [ngClass]="{collapsed: collapsed}"
        (click)="toggleCollapse()"
      ></mat-icon>
    </div>

    <div class="section-title-hover-icons">
      <div class="section-title-hover-icons-actions">
        <mat-icon
          svgIcon="up"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Move Up"
          (click)="handleMove(-1)"
        ></mat-icon>

        <mat-icon
          svgIcon="down"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Move Down"
          (click)="handleMove(1)"
        ></mat-icon>

        <mat-icon
          svgIcon="pin"
          class="section-title-hover-icons-actions-icon"
          (click)="pin()"
          [ngClass]="{active: section?.pinned}"
          [matTooltip]="section?.pinned ? 'Unpin' : 'Pin'"
        ></mat-icon>

        <mat-icon
          svgIcon="tasks"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Coming soon"
        ></mat-icon>

        <mat-icon
          svgIcon="snooze"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Coming soon"
        ></mat-icon>

        <mat-icon
          svgIcon="flag"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Coming soon"
        ></mat-icon>

        <mat-icon
          svgIcon="mail"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Coming soon"
        ></mat-icon>

        <mat-icon
          svgIcon="events"
          class="section-title-hover-icons-actions-icon"
          matTooltip="Coming soon"
        ></mat-icon>

        <mat-icon
          svgIcon="archive"
          class="section-title-hover-icons-actions-icon"
          [ngClass]="{active: section?.archived}"
          (click)="archive()"
          [matTooltip]="section?.archived ? 'Remove From Archive' : 'Add To Archive'"
        ></mat-icon>

        <mat-icon
          svgIcon="trash-bucket"
          class="section-title-hover-icons-actions-icon"
          [ngClass]="{active: section?.deleted}"
          (click)="delete()"
          [matTooltip]="section?.deleted ? 'Delete Permanently' : 'Delete'"
        ></mat-icon>
      </div>
      <mat-icon
        svgIcon="drag-icon"
        class="section-title-icon hover"
      ></mat-icon>
    </div>
  </div>

  <div
    class="section-collapseable-body"
    [ngClass]="{collapsed: collapsed}"
    [@collapseMotion]="{value: collapsed ? 'collapsed' : 'expanded', params: {height: contentHeight}}"
  >
    <div #collapseableContent>
      <div
        class="section-collapseable-body-content"
        [class.board]="appearance === 'board'"
      >
        <app-project-section-task
          *appDraggableListFor="
            let task of section.tasks;
            trackBy: tasksTrackByFn;
            repositionStream: repositionStream;
            let index = index;
            predicate: dndPredicate;
            zIndex: 1;
          "
          [task]="task"
          [sectionsTree]="sectionsTree"
          [appearance]="appearance"
          [allowedNewTasksSections]="allowedNewTasksSections"
        ></app-project-section-task>
      </div>
    </div>
  </div>
</div>
