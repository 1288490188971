// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { GroupContextMenuComponent } from '../components/group-context-menu/group-context-menu.component';

// Types
import { Group } from '@modules/contacts/types/group';

@Directive({
  selector: '[appGroupContextMenu]'
})
export class GroupContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() contextMenuComponent = GroupContextMenuComponent;
  @Input() appGroupContextMenu: Group;

  setContextMenuInstanceData(componentRef: ComponentRef<GroupContextMenuComponent>) {
    componentRef.instance.group = this.appGroupContextMenu;
  }
}
