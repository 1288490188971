<div class="create-topic__wrapper">
  <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  <div class="title">Add Pellets</div>
  <app-input
    class="topic-input"
    placeholder="Add Pellets"
    [inputFormControl]="inputFormControl"
    (keydown.enter)="addTopicToList()"
    [suggestedOptions]="suggestedOptions | async"
    [autocompleteItemTemplateRef]="autocompleteItem"
  ></app-input>
  <div class="topics">
    <app-topic *ngFor="let topic of topics"
      [topic]="topic.name"
      [(pinned)]="topic.pinned"
      [canBeDeleted]="false"
      [showRemoveIconAlways]="true"
      (deselect)="deleteTopicFromList(topic)"
      [topicStyle]="(topic.source === 'user' || topic.source === 'auto_discovery' || topic.source === 'auto_add' || topic.autoDiscovery) ? 'manuallyAdded' : 'discovered'"
    >
    </app-topic>
    <div class="placeholder" *ngIf="!topics.length">You can add multiple Pellets with enter e.g. personal must_read educational</div>
    <div class="placeholder error" *ngIf="error && error.length">Error while saving Pellets: {{error}}</div>
  </div>
  <div class="footer">
    <app-button class="btn-action" *ngIf="!loading" (click)="closed()">CANCEL</app-button>
    <app-button class="btn-action" *ngIf="!loading" appearance="blue" (click)="handleSave()">SAVE</app-button>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>

<ng-template #autocompleteItem let-topic="item.value">
  <div class="topic-autocomplete">{{topic.name}}</div>
</ng-template>
