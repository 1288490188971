// Common
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutStateService } from '@modules/pages/services/layout-state.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

// Components
import { SplitViewComponent } from '@modules/split-view/components/split-view/split-view.component';

// Types
import { DynamicPanelContent } from '@modules/pages/types/dynamic-panel-data';

@Component({
  selector: 'app-contacts-layout',
  templateUrl: './contacts-layout.component.html',
  styleUrls: ['./contacts-layout.component.less']
})
export class ContactsLayoutComponent implements OnInit, OnDestroy {

  // Public
  public linkedInfoToolbarPresent = false;
  public linkedKPInfoToolbarPresent = false;
  public dynamicPanelContent: DynamicPanelContent;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // View Children
  @ViewChild('splitView', {static: true}) splitView: SplitViewComponent;

  /**
   * Constructor
   */

  constructor(
    private layoutStateService: LayoutStateService,
    private changeDetector: ChangeDetectorRef,
    private dynamicPanelService: DynamicPanelService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.layoutStateService.getLinkedInfoToolbarPresence()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((presence: boolean) => {
        this.linkedInfoToolbarPresent = presence;
        this.changeDetector.detectChanges();
      });

    this.layoutStateService.getKPLinkedInfoToolbarPresence()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((presence: boolean) => {
        this.linkedKPInfoToolbarPresent = presence;
        this.changeDetector.detectChanges();
      });

    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((content) => {
        this.dynamicPanelContent = content;
        if (
          this.dynamicPanelContent.type === 'attachments' ||
          this.dynamicPanelContent.type === 'linkedInfo'
        ) {
          this.splitView.open();
        }
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }
}
