// Common
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';

@Component({
  selector: 'app-notes-list-header',
  templateUrl: './notes-list-header.component.html',
  styleUrls: ['./notes-list-header.component.less']
})
export class NotesListHeaderComponent implements OnInit {

  @Input() loading: Boolean;

  // Outputs
  @Output() selectedOrderChange: EventEmitter<DropdownOption> = new EventEmitter();
  @Output() scrollOptionChange: EventEmitter<number> = new EventEmitter();
  @Output() searchQueryChange: EventEmitter<string> = new EventEmitter();

  // Public
  public orders: DropdownOption[] = [
    {name: 'Create date', key: 'created-asc'},
    {name: 'Reverse create date', key: 'created-desc'},
    {name: 'Due date', key: 'due-date-asc'},
    {name: 'Reverse due date', key: 'due-date-desc'},
    {name: 'Title', key: 'title'},
  ];
  public scrollOptions = [
    {name: 'Scroll to top', key: 'scroll-top'},
    {name: 'Scroll to bottom', key: 'scroll-bottom'}
  ];
  public selectedOrder: DropdownOption;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.updateSelectedOrder();
  }

  /**
   * Actions
   */

  handleSearchQueryChange(value: string) {
    this.searchQueryChange.emit(value);
  }

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.saveSelectedOrder(order);
    this.selectedOrderChange.emit(this.selectedOrder);
  }

  updateSelectedOrder() {
    this.selectedOrder = this.stateService.sortNotesList;
    this.selectedOrderChange.emit(this.selectedOrder);
  }

  saveSelectedOrder(order: DropdownOption) {
    this.stateService.sortNotesList = order;
  }

  selectScrollOption(scrollOption: 'scroll-top' | 'scroll-bottom') {
    this.scrollOptionChange.emit(scrollOption === 'scroll-top' ? 0 : -1);
  }
}
