<div class="modal-header">
  <div class="modal-title">Select Folder</div>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body modal-body--with-footer">
  <div class="folders-container" *ngIf="messages">
    <ng-template #foldersList let-currentFolders>
      <ul class="select-folder-user-folders">
        <li  *ngFor="let folder of currentFolders">

          <div class="folder-wrapper" [ngClass]="{'selected': folder === selectedFolder}" (click)="selectedFolder = folder">
            <i class="fa fa-folder fa-fw folder-icon"></i>
            <span class="folder-name-wrap">{{folder.name}}</span>
            <button class="btn btn-link open-button"
                  (click)="openFolder(folder, $event)"
                  *ngIf="folder.subFolders && folder.subFolders.length > 0">
              <i class="fa fa-fw" [ngClass]="{'fa-caret-right': !folder.open, 'fa-caret-down': folder.open}"></i>
            </button>
          </div>

          <ng-container *ngIf="folder?.subFolders && folder.open">
              <ng-container class="subfolders-list" *ngTemplateOutlet="foldersList; context:{ $implicit: folder.subFolders }"></ng-container>
          </ng-container>
        </li>
      </ul>
    </ng-template>
    <ng-container *ngTemplateOutlet="foldersList; context:{ $implicit: folders }"></ng-container>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" class="btn btn-default btn-sm pull-left">Cancel</button>
  <button (click)="confirmMove()" class="btn btn-primary btn-sm pull-right" [disabled]="!selectedFolder">Move</button>
</div>
