// Common
import { Component, Input, OnInit } from '@angular/core';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.less']
})
export class ConfirmationModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() modalText: string;
  @Input() modalTitle: string;
  @Input() cancelButtonLabel: string;
  @Input() submitButtonLabel: string;
  @Input() action?: Function;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 128px)',
    y: '40px',
    width: '256px',
  };

  /**
   * Constructor
   */

  constructor() {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  submitModal() {
    this.action(true);
    this.close();
  }

  closeModal() {
    this.action(false);
    this.close();
  }

}
