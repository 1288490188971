// Common
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, of as createObservableOf } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

// Services
import { SearchService } from '../../services/search.service';
import { MailService, MessagesFilters } from './../../../mail/services/mail.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { ModalService } from './../../../modal/services/modal.service';

// Types
import { SearchParam } from '../../types/search-param';
import { SearchSuggestions } from './../../types/search-suggestions';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { MailMessage } from '../../../mail/types/mail-message';
import { ResponseMessages } from '../../../mail/types/mail-response.model';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.less']
})
export class SearchInputComponent implements OnInit {

  // ViewChild
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  // Publics
  public searchCtrl = new FormControl();
  public searchParams: SearchParam[] = [];
  public searchSuggestions: SearchSuggestions;
  public searchEmails: MailMessage[] = [];
  public searchHistory: Array<SearchParam[]>;

  /**
   * Constructor
   */

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected searchService: SearchService,
              protected mailService: MailService,
              protected modalService: ModalService,
              protected ga: GoogleAnalyticsService) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    // Get search params from Route
    combineLatest([this.route.params, this.route.queryParams])
      .subscribe(([params, queryParams]) => {
        const folder = params.folder;
        const page = this.getMainRouteSegment();

        if (
          (
            (page === 'events' || page === 'tasks' || page === 'notes') ||
            (folder && folder === 'search')
          ) &&
          queryParams.query
        ) {
          this.searchParams = JSON.parse(queryParams.query);
          this.searchService.setSearchParams(SearchService.parseSearchParams(queryParams.query));
        } else {
          this.searchParams = [];
          this.searchService.setSearchParams(null);
        }
      });

    // Get search suggestions from input
    this.searchCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (value && value.length) {
          if (value.charAt(0) === '#' && value.length > 1) {
            value = value.substr(1);
          }
          return this.searchService.searchSuggestions(value, undefined, 5);
        }
        return createObservableOf(new SearchSuggestions());
      })
    ).subscribe((result: SearchSuggestions) => {
      this.searchSuggestions = result;
    });
    // Get search suggestions emails from input
    this.searchCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (value && value.length) {
          const topics = this.searchParams.filter(param => param.type === 'topic').map(param => param.value);
          const contacts = this.searchParams.filter(param => param.type === 'contact').map(param => param.value);
          const keywords = this.searchParams.filter(param => param.type === 'keyword').map(param => param.value);
          const tags = this.searchParams.filter(param => param.type === 'tag').map(param => param.value);
          if (value.charAt(0) === '#' && value.length > 1) {
            tags.push(value.substr(1));
          } else {
            keywords.push(value);
          }
          const filters: MessagesFilters = {
            relevance: true, topics, contacts, keywords, tags
          };
          return this.mailService.getMessages(filters, 'date', 3, 0);
        }
        return createObservableOf(new ResponseMessages());
      })
    ).subscribe((response: ResponseMessages) => {
      this.searchEmails = response.messages || [];
    });
    // Get Input history
    this.searchService.searchHistory
      .pipe(
        map(history => history.slice(0, 5))
      )
      .subscribe((history: Array<SearchParam[]>) => {
        this.searchHistory = history;
      });
  }

  /**
   * Actions
   */

  onAutocompleteSearchParamSelected(event: MatAutocompleteSelectedEvent) {
    let value = event.option.value;
    if (value.message) {
      this.modalService.mailContent(value.message);
      this.searchCtrl.setValue(value.input);
      return;
    }
    if (value.length === 1 && value[0].type === 'keyword' && (value[0].value.charAt(0) === '#' && value[0].value.length > 1)) {
      value = [{value: value[0].value.substr(1), type: 'tag'}];
    }
    this.ga.trackEvent('search-email', 'search');
    const params: SearchParam[] = value;
    this.searchParams.push(...params);
    this.searchCtrl.setValue(null);
    this.searchService.updateSearchHistory(this.searchParams);
    this.performSearch();
  }

  onRemoveSearchParam(param: SearchParam) {
    const index = this.searchParams.indexOf(param);
    if (index !== -1) {
      this.searchParams.splice(index, 1);
    }
    this.searchInput.nativeElement.focus();
    this.performSearch();
  }

  onRemoveSearchHistory(params: SearchParam[], event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.searchService.removeSearchHistory(params);
  }

  collapseSearch() {
    this.searchCtrl.setValue(null);
    this.searchInput.nativeElement.blur();
    this.searchParams = [];
    this.performSearch();
  }

  /**
   * Methods
   */

  performSearch() {
    const page = this.getMainRouteSegment();

    if (this.searchParams && this.searchParams.length > 0) {
      const params = JSON.stringify(this.searchParams);

      this.router.navigate(
        [page === 'mail' || page === 'insights' ? '../search' : `/${ page }`],
        { relativeTo: this.route, queryParams: {query: params}}
      );
    } else {
      this.router.navigate(
        [page === 'mail' || page === 'insights' ? '../' : `/${ page }`],
        { relativeTo: this.route }
      );
    }
  }

  /**
   * Helpers
   */

  getMainRouteSegment(): string {
    return this.router.url.split(/\/|\?/)[1];
  }
}
