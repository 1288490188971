// Common
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.less']
})
export class AccountModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() routePath: string;

  // Override
  public defaultSize: ModalFrame = {
    x: '5vw',
    y: '5vh',
    width: '90vw',
    height: '90vh'
  };

  /**
   * Constructor
   */

  constructor(protected router: Router) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

}
