<div class="modal-header">
  <div class="modal-title">
    <mat-icon *ngIf="minimized" svgIcon="group" class="modal-title-icon"></mat-icon>
    {{ group?.name }}
  </div>
  <button type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="In new window" (click)="expand()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="modal-content">
    <app-group-form-container [group]="group" (close)="close()" class="modal-form" appearance="headerFixedBig"></app-group-form-container>
    <app-linked-info-toolbar *ngIf="group?.id" [linkedInfo]="[{type: 'group', id: group.id}]"></app-linked-info-toolbar>
  </div>
</div>
