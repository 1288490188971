// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { LayoutStateService } from '@modules/pages/services/layout-state.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';

// Types
import { ContactsMainView } from '@modules/contacts/types/contacts-main-view';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contacts-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.less'],
})
export class ContactsMainViewComponent implements OnInit, OnDestroy {

  // Public
  public view: ContactsMainView;
  public contact: Contact;
  public group: Group;

  // Private
  private alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private contactsStateService: ContactsStateService,
    private layoutStateService: LayoutStateService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.contactsStateService.getMainView()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((view: ContactsMainView) => {
        this.view = view;
        switch (this.view) {
          case 'empty':
            this.layoutStateService.setLinkedInfoToolbarPresence(false);
            break;
          case 'contact-form':
          case 'group-form':
            this.layoutStateService.setLinkedInfoToolbarPresence(true);
            break;
        }
      });

    this.contactsStateService.getMainViewContact()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((contact: Contact) => this.contact = contact);

    this.contactsStateService.getMainViewGroup()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((group: Group) => this.group = group);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
    this.layoutStateService.setLinkedInfoToolbarPresence(false);
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.contactsStateService.setMainView('empty');
  }
}
