<div
  *ngIf="group"
  class="group"
  [class.short-view]="shortView"
  [class.pinned]="group.pinned"
  [class.with-description]="group.description"
  (click)="openGroup()"
  [appGroupContextMenu]="group"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: [group], type: 'group'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="group-body">
    <div class="group-body-heading">
      <div class="group-body-heading-avatar">
        SB
      </div>

      <div class="group-body-heading-title">
        <div class="group-body-heading-title-text">
          {{group.name}}
        </div>
        <div class="group-body-heading-title-date">
          <div class="group-body-heading-title-date-item">
            Created: {{group.createdAt | date : 'MMM d'}}
          </div>
           <div class="group-body-heading-title-date-separator"></div>
          <div class="group-body-heading-title-date-item">
            Updated: {{group.createdAt | date : 'MMM d'}}
          </div>
        </div>
      </div>
    </div>

    <div class="group-body-description" *ngIf="group.description && !shortView">
      {{group.description}}
    </div>

    <app-link-info-bar
      class="group-body-info-bar"
      *ngIf="!shortView && group.linkedInfo?.length"
      [linkedInfo]="group.linkedInfo"
    ></app-link-info-bar>
  </div>

  <mat-icon class="group-pin-icon" *ngIf="group.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="group-starred-icon" *ngIf="!group.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="group-marker" svgIcon="groups"></mat-icon>
  <mat-icon class="group-attachments-icon" *ngIf="group.files?.length" svgIcon="attachment"></mat-icon>

  <!-- TODO move this to a separate component -->
  <div
    class="group-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  >
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="saved"
      [class.active]="group.starred"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="pin"
      [class.active]="group.pinned"
      [matTooltip]="group?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="crown"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="archive"
      [class.active]="group.archived"
      [matTooltip]="group?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="group-hover-icons-item"
      svgIcon="trash"
      [class.active]="group.deleted"
      [matTooltip]="group?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="group-bottom-container">
    <mat-icon
      class="group-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
    <app-contacts-counter-dropdown
      *ngIf="!shortView && group.participants?.length"
      class="group-bottom-container-participants"
      [contactList]="group.participants"
      [size]="24"
      [minContactLen]="1"
    ></app-contacts-counter-dropdown>
  </div>

  <div class="drop-zone"></div>
</div>

<!-- TODO waiting for design -->
<div
  class="group placeholder"
  *ngIf="!group"
  [class.short-view]="shortView"  
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-group
    [group]="group"
    [dragEnabled]="false"
    [shortView]="shortView"
  ></app-link-info-group>
</ng-template>
