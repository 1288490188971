// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// Types
import { TasksListResponse } from '@modules/tasks/types/tasks-list-response';

// Class
import { LinkInfoItemSuggestions } from '../link-info-item-suggestions';

@Component({
  selector: 'app-link-info-task-suggestions',
  templateUrl: './link-info-task-suggestions.component.html',
  styleUrls: ['./link-info-task-suggestions.component.less']
})
export class LinkInfoTaskSuggestionsComponent extends LinkInfoItemSuggestions implements OnInit, OnDestroy {

  /**
   * Constructor
   */

  constructor(
    protected modalService: ModalService,
    protected tasksService: TasksService,
    protected linkedInfoService: LinkedInfoService
  ) {
    super(linkedInfoService);
  }

  /**
   * Methods
   */

  //  Override method LinkInfoItemSuggestions
  getSuggestions(inputValue: string): Observable<any[]> {
    if (inputValue && inputValue.length) {
      return this.tasksService.getTasks({
        keywords: [inputValue],
        order: 'created',
        limit: 10,
        offset: 0
      })
        .pipe(
          map((response: TasksListResponse) => response.tasks || [])
        );
    }
    return of([]);
  }

  //  Override method LinkInfoItemSuggestions
  openCreateModal() {
    this.modalService.taskForm(null);
  }

}
