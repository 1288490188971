<div class="project-form">
  <div class="shadow"></div>

  <form
    class="project-form-main"
    [formGroup]="projectForm"
  >

    <!-- Tags -->
    <app-tag-list
      class="project-form-tags"
      [project]="project"
      [tags]="projectForm.controls['tags'].value"
      (tagsChange)="projectForm.patchValue({ tags: $event })"
      appScrollAnchor
    ></app-tag-list>

    <!-- Pellets -->
    <app-project-topics
      [projectId]="project?.id"
      [topicsControl]="projectForm.controls['topics']"
    ></app-project-topics>

    <div class="project-form-horizontal-group">
      <div class="project-form-horizontal-group-item">
        <app-date-picker
          placeholder="Start Date"
          [inputFormControl]="projectForm.controls['fromDate']"
          width="108px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['fromDate']"
          bundledInputAppearance="start"
        ></app-date-picker>
      </div>
      <div class="project-form-horizontal-group-item">
        <app-time-picker
          placeholder="Start Time"
          [inputFormControl]="projectForm.controls['fromTime']"
          width="108px"
          [bundledInputConsumerGroup]="bundledGroup"
          [bundledInputConsumerKeys]="['fromTime']"
          bundledInputAppearance="start"
        ></app-time-picker>
      </div>
      <div class="project-form-horizontal-group project-form-horizontal-group-inner">
        <div class="project-form-horizontal-group-item">
          <app-date-picker
            placeholder="Due Date"
            [inputFormControl]="projectForm.controls['toDate']"
            [bundledInputConsumerGroup]="bundledGroup"
            [bundledInputConsumerKeys]="['toDate']"
            bundledInputAppearance="end"
            width="108px"
          ></app-date-picker>
        </div>
        <div class="project-form-horizontal-group-item">
          <app-time-picker
            placeholder="Due Time"
            [inputFormControl]="projectForm.controls['toTime']"
            width="108px"
            [bundledInputConsumerGroup]="bundledGroup"
            [bundledInputConsumerKeys]="['toTime']"
            bundledInputAppearance="end"
            [optionsFromTime]="projectForm?.value?.fromTime"
          ></app-time-picker>
        </div>
      </div>
    </div>

    <div class="project-form-horizontal-group">
      <div class="project-form-horizontal-group-full-width-item">
        <app-priority-input
          [inputFormControl]="projectForm.controls['priority']"
        ></app-priority-input>
      </div>
    </div>

    <div class="project-form-horizontal-group">
      <div class="project-form-horizontal-group-full-width-item">
        <app-textarea placeholder="Description" [inputFormControl]="projectForm.controls['description']" width="100%" rows="5"></app-textarea>
      </div>
    </div>

    <div class="project-form-tasks">
      <app-form-collapseable-group
        title="Tasks"
        class="task-form-tasks-section"
        appScrollAnchor
        [extendIconEnabled]="true"
      >
        <app-project-section
          *ngFor="let section of sectionsTree?.tree; let last = last"
          [section]="section"
          [sectionsTree]="sectionsTree"
          [last]="last"
        ></app-project-section>

        <span
          class="project-form-text-button"
          (click)="addSection()"
          *ngIf="project"
        >
          + Add Section
        </span>

        <span
          class="project-form-text-button"
          (click)="addTask()"
          *ngIf="project"
        >
          + Add Task
        </span>
      </app-form-collapseable-group>

      <div class="project-form-horizontal-group">
        <div class="project-form-horizontal-group-full-width-item">
          <app-linked-info-list
            class="linked-info-list"
            [parentLinkedInfo]="{type: 'project', id: project?.id}"
            [linkedInfo]="project?.linkedInfo"
            (extendAction)="openLinkedInfoDP.emit()"
          ></app-linked-info-list>
        </div>
      </div>
    </div>
  </form>
</div>
