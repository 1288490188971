<div
  class="draggable-container"
  [class.multiple-container]="multipleStyle"
  [class.no-shadow]="noShadow"
  #container
  [style.height]="height > 0 ? height + 'px' : 'auto'"
  [style.width]="width > 0 ? width + 'px' : 'auto'"
  [style.transform]="repositionStyles"
  [ngStyle]="customStyles"
  [@returnBackMotion]="{
    value: returnBackMotionState,
    params: {
      destinationTop: homeTop + 'px',
      destinationLeft: homeLeft + 'px',
      currentTop: currentTop + 'px',
      currentLeft: currentLeft + 'px'
    }
  }"
  (@returnBackMotion.done)="handleAfterReturnBackAnimation($event)"
>
  <div [class.after-drop-container]="!!afterDropTemplate">
    <ng-container
      *ngTemplateOutlet="!!afterDropTemplate ? afterDropTemplate : dragPlaceholder"
    ></ng-container>
  </div>
</div>
