// Common
import { Injectable} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError, Subject, of, forkJoin } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Project } from '../types/project';
import { ProjectsListResponse } from '../types/projects-list-response';
import { ProjectsFilters } from '../types/projects-filters';
import { Task } from '../types/task';
import { Section } from '../types/section';
import { Topic } from '@modules/topic/types/topic';

// Env
import { environment } from '@environment';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TopicService } from '@modules/topic/services/topic.service';

@Injectable()
export class ProjectsService {

  public projectCreated = new Subject<Project>();
  public projectUpdated = new Subject<Project>();
  public projectDeleted = new Subject<string>();

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private linkedInfoService: LinkedInfoService,
    private topicService: TopicService
  ) {

  }

  /*
  * Helpers
  * */

  private formatFilters(filters: ProjectsFilters): { [param: string]: string | string[]; } {
    const formattedFilters = {};

    if (filters.projectsIds) { formattedFilters['projects_ids[]'] = filters.projectsIds; }

    if (filters.archived !== null && filters.archived !== undefined) { formattedFilters['archived'] = filters.archived + ''; }
    if (filters.deleted !== null && filters.deleted !== undefined) { formattedFilters['deleted'] = filters.deleted + ''; }
    if (filters.pinned !== null && filters.pinned !== undefined) { formattedFilters['pinned'] = filters.pinned + ''; }

    if (filters.fromTime) { formattedFilters['from_time'] = filters.fromTime.getTime() + ''; }
    if (filters.toTime) { formattedFilters['to_time'] = filters.toTime.getTime() + ''; }
    if (filters.scheduled !== null && filters.scheduled !== undefined) { formattedFilters['scheduled'] = filters.scheduled + ''; }

    if (filters.priority) { formattedFilters['priority'] = filters.priority; }

    if (filters.keywords) { formattedFilters['keywords[]'] = filters.keywords; }
    if (filters.topics) { formattedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formattedFilters['tags[]'] = filters.tags; }
    if (filters.contacts) { formattedFilters['contacts[]'] = filters.contacts; }

    if (filters.order) { formattedFilters['order'] = filters.order; }
    if (filters.orderWithPinned) { formattedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }

    if (filters.limit) { formattedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formattedFilters['offset'] = filters.offset + ''; }

    return formattedFilters;
  }

  /**
   * Methods
   */

  getProjects(filters: ProjectsFilters = {}): Observable<ProjectsListResponse> {
    const requestParams = {params: this.formatFilters(filters)};

    return this.http.get<ProjectsListResponse>(environment.baseUrl + '/api/projects', requestParams)
      .pipe(
        map(({ count, projects }) => ({ count, projects: projects.map(project => new Project(project)) })),
        catchError(ProjectsService.handleObserverError)
      );
  }

  getProjectSections(projectId?: string): Observable<Section[]> { // TODO remove this
    return of(
      sectionsMock
        .map(section => ({...section, tasks: tasksMock.filter(task => task.sectionId === section.id)}))
        .map(section => new Section(section))
    );
  }

  getProject(id: string): Observable<Project> {
    return this.http.get<{ project: Project }>(environment.baseUrl + '/api/projects/' + id)
      .pipe(
        map(({ project }) => new Project(project))
      );
  }

  @warmUpObservable
  create(newProject: Project): Observable<Project> {
    return this.http.post<{ project: Project }>(environment.baseUrl + '/api/projects', newProject.asPayloadJSON())
      .pipe(
        map(({ project }) => project),
        tap(project => {
          if (newProject.linkedInfo && newProject.linkedInfo.length) {
            this.linkedInfoService.linkToItem({type: 'project', id: project.id}, newProject.linkedInfo);
          }
        }),
        tap(event => this.projectCreated.next(event)),
        switchMap(project => {
          if (newProject.topics.length === 0) { return of(project); }

          return this.topicService.createTopics(
            newProject.topics, {projectsIds: [project.id]}
          )
            .pipe(
              map(() => project)
            );
        })
      );
  }

  @warmUpObservable
  edit(projectInstance: Project): Observable<Project> {
    return this.http.put<{ project: Project, success: boolean }>
    (environment.baseUrl + '/api/projects/' + projectInstance.id, projectInstance.asPayloadJSON())
      .pipe(
        tap(({ project }) => this.projectUpdated.next(project)),
        map(({ project }) => project)
      );
  }

  @warmUpObservable
  pin(projectIds: string[], status: boolean): Observable<Project[]> {
    return forkJoin(
      projectIds.map(projectId => this.http.put<{ project: Project, success: boolean }>
        (environment.baseUrl + '/api/projects/' + projectId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { project: Project, success: boolean }[]) => results.map(result => result.project)),
        tap(success => {
          if (success) {
            this.projectUpdated.next();
            this.asyncTasksToastsService.show(
              {text: `Project${projectIds.length > 1 ? 's' : ''} ${projectIds.length > 1 ? 'are' : 'is'} ${ status ? '' : 'un'}pinned`}
            );
          }
        })
      );
  }

  @warmUpObservable
  archive(projectIds: string[], archived: boolean): Observable<Project[]> {
    return forkJoin(
      projectIds.map(projectId => this.http.put<{ project: Project, success: boolean }>
        (environment.baseUrl + '/api/projects/' + projectId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { project: Project, success: boolean }[]) => results.map(result => result.project)),
        tap(success => {
          if (success) {
            this.projectUpdated.next();
            this.asyncTasksToastsService.show({ text: `Project(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  deletePermanently(projectIds: string[]): Observable<boolean> {
    return forkJoin(
      projectIds.map(projectId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/projects/' + projectId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.projectDeleted.next();
            this.asyncTasksToastsService.show({ text: `Project(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  delete(projectIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      projectIds.map(projectId => this.http.put<{ project: Project, success: boolean }>
        (environment.baseUrl + '/api/projects/' + projectId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { project: Project, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.projectUpdated.next();
            this.asyncTasksToastsService.show({ text: `Project(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }

  upsert(projectForm: FormGroup): Observable<Project> {
    const project = Project.fromFormGroup(projectForm);

    return projectForm.get('id').value ?
      this.edit(project) : this.create(project);
  }
}

const tasksMock = [
  new Task({
    id: '1',
    sectionId: '1',
    columnId: '1',
    title: 'Identify Pain Point',
    fromTime: new Date(2020, 2, 10, 8, 30),
    toTime: new Date(2020, 2, 10, 16, 45),
    description: 'Upload new design screens in Zeplin today and...',
    subtasks: [
      new Task({
        title: 'Discussing With Team'
      }),
      new Task({
        title: 'Discussing With Team'
      })
    ]
  }),
  new Task({
    id: '2',
    sectionId: '2',
    columnId: '1',
    title: 'Test Current Solution Features',
    circleColor: '#ffc820',
  }),
  new Task({
    id: '3',
    sectionId: '1',
    columnId: '2',
    title: 'Interview Users',
    fromTime: new Date(2020, 2, 10),
    toTime: new Date(2020, 2, 10),
    pinned: true
  }),
  new Task({
    id: '4',
    sectionId: '2',
    columnId: '2',
    title: 'Identify Current Solution',
    fromTime: new Date(2020, 2, 10, 8, 30),
    toTime: new Date(2020, 2, 10, 16, 45),
    circleColor: '#ff4666'
  }),
  new Task({
    id: '5',
    sectionId: '3',
    columnId: '2',
    title: 'Develop Prototype Features',
    fromTIme: new Date(2020, 2, 10, 8, 30),
    toTime: new Date(2020, 2, 10, 16, 45),
  }),
  new Task({
    id: '6',
    sectionId: '2',
    columnId: '3',
    title: 'Identify Problems and Limitations With Current Solutions. Identify Problems and Limitations.',
    checked: true
  }),
  new Task({
    id: '7',
    sectionId: '3',
    columnId: '3',
    title: 'Develop Prototype Core Functions',
    fromTime: new Date(2020, 2, 10, 8, 30),
    toTime: new Date(2020, 2, 10, 16, 45),
  })
];

const sectionsMock = [
  {
    id: '1',
    title: 'User Requirements',
  },
  {
    id: '2',
    title: 'Product Concept',
  },
  {
    id: '3',
    title: 'Prototype Design',
  }
];
