<context-menu #contextMenu>
  <ng-template contextMenuItem>Open In Window (Coming soon)</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="pinNote()">{{ note?.pinned ? 'Unpin' : 'Pin'}}</ng-template>
  <ng-template contextMenuItem (execute)="favoriteNote()">{{ note?.favorite ? 'Remove from favorite' : 'Favorite'}}</ng-template>
  <ng-template contextMenuItem (execute)="snoozeNote()">Snooze (Coming soon)</ng-template>
  <ng-template contextMenuItem (execute)="followUpNote()">Follow up (Coming soon)</ng-template>
  <ng-template contextMenuItem (execute)="archiveNote()">{{ note?.archived ? 'Restore from archive' : 'Archive'}}</ng-template>
  <ng-template contextMenuItem [visible]="note?.deleted" (execute)="restoreFromTrash()">Restore from trash</ng-template>
  <ng-template contextMenuItem (execute)="deleteNote()">{{ note?.deleted ? 'Delete' : 'Move to trash'}}</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem>Add Pellet (Coming soon)</ng-template>
  <ng-template contextMenuItem>Add Tag (Coming soon)</ng-template>
  <ng-template contextMenuItem>Add To Notebook (Coming soon)</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="createMail()">Create email via note</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  
  <!-- Link-->
  <ng-template contextMenuItem [subMenu]="addMenu">
    Link to
  </ng-template>
  <context-menu #addMenu>
    <ng-template contextMenuItem [subMenu]="linkMenuMail">Mail</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuEvent">Event</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuTask">Task</ng-template>
    <ng-template contextMenuItem [subMenu]="linkMenuNote">Note</ng-template>
  </context-menu>
  <context-menu #linkMenuMail>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-message-suggestions appearance="context-menu" [linkedInfo]="[{type: 'note', id: note.id}]" (close)="closeContextMenu()"></app-link-info-message-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuEvent>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-event-suggestions appearance="context-menu" [linkedInfo]="[{type: 'note', id: note.id}]" (close)="closeContextMenu()"></app-link-info-event-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuTask>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-task-suggestions appearance="context-menu" [linkedInfo]="[{type: 'note', id: note.id}]" (close)="closeContextMenu()"></app-link-info-task-suggestions>
    </ng-template>
  </context-menu>
  <context-menu #linkMenuNote>
    <ng-template contextMenuItem passive="true" let-item>
      <app-link-info-note-suggestions appearance="context-menu" [linkedInfo]="[{type: 'note', id: note.id}]" (close)="closeContextMenu()"></app-link-info-note-suggestions>
    </ng-template>
  </context-menu>

</context-menu>
