// Common
import { Directive, ViewContainerRef, OnInit, OnDestroy, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ModalService } from '../services/modal.service';

// Components
import { ModalWrapperComponent } from '../components/modal-wrapper/modal-wrapper.component';

// Types
import { ModalData } from '../types/modal-data';

@Directive({
  selector: '[appModalHost]'
})
export class ModalHostDirective implements OnInit, OnDestroy {

  // Private
  private modalServiceSubscription: Subscription;
  private modals = {};
  private maxNumberOfSameTypeModals = 5;
  private minimizedModals: ComponentRef<ModalWrapperComponent>[] = [];

  /**
   * Constructor
   */

  constructor(private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver,
              private modalService: ModalService) {
  }

  /**
   * Directive lifecycle
   */

  ngOnInit() {
    this.modalServiceSubscription = this.modalService.show$.subscribe((data: ModalData) => {
      if (!this.isAvailableForCreatingMore(data)) {
        return;
      }
      const wrapperComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalWrapperComponent);
      const wrapperComponentRef = this.viewContainerRef.createComponent(wrapperComponentFactory);
      wrapperComponentRef.instance.overlaps = this.isModalExists(data.name);
      wrapperComponentRef.instance.childComponent = data.component;
      wrapperComponentRef.instance.childComponentName = data.name;
      wrapperComponentRef.instance.childComponentData = data.content;
      wrapperComponentRef.instance.customFrame = data.customFrame;
      wrapperComponentRef.instance.disabledResize = data.disabledResize;
      this.addModal(data.name);
      wrapperComponentRef.instance.closed.subscribe(() => {
        this.removeModal(data.name);
        this.removeFromMinimized(wrapperComponentRef);
        if (data.closed) {
          data.closed();
        }
        wrapperComponentRef.destroy();
      });
      wrapperComponentRef.instance.collapsed.subscribe((minimized) => {
        this.collapseModal(wrapperComponentRef, minimized);
      });
    });
  }

  ngOnDestroy() {
    if (this.modalServiceSubscription) {
      this.modalServiceSubscription.unsubscribe();
    }
  }

  /**
   * Methods
   */

  addModal(modalName: string) {
    if (this.modals[modalName]) {
      this.modals[modalName]++;
    } else {
      this.modals[modalName] = 1;
    }
  }

  removeModal(modalName: string) {
    if (this.modals[modalName]) {
      this.modals[modalName]--;
    }
  }

  isModalExists(modalName: string) {
    return this.modals[modalName] > 0;
  }

  isAvailableForCreatingMore(data: ModalData): boolean {
    const modalName = data.name;
    const maxSameModal = data.maxModals || this.maxNumberOfSameTypeModals;
    return (!this.modals[modalName] || (this.modals[modalName] < maxSameModal));
  }

  removeFromMinimized(component: ComponentRef<ModalWrapperComponent>) {
    const removeIndex = this.minimizedModals.indexOf(component);
    if (removeIndex !== -1) {
      this.minimizedModals.splice(removeIndex, 1);
    }
    this.minimizedModals.forEach((value, index) => {
      value.instance.collapse(true, index);
    });
  }

  collapseModal(component: ComponentRef<ModalWrapperComponent>, minimized: boolean) {
    if (minimized) {
      const index = this.minimizedModals.push(component) - 1;
      component.instance.collapse(minimized, index);
    } else {
      this.removeFromMinimized(component);
      component.instance.collapse(minimized);
    }
  }
}
