// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../../auth/services/auth.service';

// Types
import { Account } from '../../types/account';

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.css']
})
export class AccountOverviewComponent implements OnInit, OnDestroy {

  // Public
  public account: Account;
  public loading = false;
  public errorMessage: string;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(private accountService: AccountService,
              private authService: AuthService) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.accountService.getAccount()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(account => this.account = account);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  googleSignIn($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.errorMessage = '';
    this.loading = true;
    this.authService.googleSignIn()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        this.accountService.getAccount(true)
          .pipe(takeUntil(this.componentNotDestroyed))
          .subscribe(account => {
            this.loading = false;
            this.account = account;
          });
      }, error => {
        this.loading = false;
        if (error.code === 'email_not_allowed') {
          this.errorMessage = 'Unfortunately, your email has not been authorized on our systems yet. \
          But don\'t worry, we are working hard to get people access.';
        } else {
          this.errorMessage = error.message;
        }
      });
  }

}
