<section class="account-side-bar">
  <div class="account-sections">
    <div *ngFor="let section of sections">
      <ng-container *ngIf="isModal">
        <div class="account-side-bar__item" [ngClass]="{'active': section.route === routePath }" (click)="selectRoute(section.route)">
          <i class="fa fa-{{section.icon}} icon"></i><span>{{section.name}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!isModal">
        <div [routerLink]="['/account', section.route]" routerLinkActive="active" class="account-side-bar__item">
          <i class="fa fa-{{section.icon}} icon"></i>&nbsp;{{section.name}}
        </div>
      </ng-container>
    </div>
  </div>
  <button class="btn btn-default btn-flat btn-sign-out" (click)="signOut()">Sign out</button>
</section>
