<app-content-menu>
  <app-content-menu-item
    icon="file"
    title="Files"
    [active]="sidebarFilter === 'files'"
    (click)="selectFilter('files', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="upcoming"
    title="Upcoming"
    [active]="sidebarFilter === 'upcoming'"
    (click)="selectFilter('upcoming', $event)"
  ></app-content-menu-item>
  <app-content-menu-item
    icon="saved"
    title="Flagged"
    (click)="selectFilter('flagged', $event)"
    [active]="sidebarFilter === 'flagged'"
  ></app-content-menu-item>
  <app-content-menu-item
    icon="recent"
    title="Recent"
    [active]="sidebarFilter === 'recent'"
    (click)="selectFilter('recent', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="folder-archive"
    title="Archive"
    [active]="sidebarFilter === 'archived'"
    (click)="selectFilter('archived', $event)"
  ></app-content-menu-item>

  <app-content-menu-item
    icon="trash-bucket"
    title="Trash"
    [active]="sidebarFilter === 'deleted'"
    (click)="selectFilter('deleted', $event)"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-item
    icon="folder-folder"
    title="Folders"
    [active]="sidebarFilter === 'folders'"
  ></app-content-menu-item>

  <app-content-menu-divider></app-content-menu-divider>

  <app-content-menu-pellets
    collapsedStateKey="filesSidebarPinnedPellets"
  ></app-content-menu-pellets>

  <app-content-menu-tags
    collapsedStateKey="filesSidebarPinnedTags"
  ></app-content-menu-tags>

</app-content-menu>
