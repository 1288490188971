<div class="button-group" *ngIf="appearance !== 'big'">
  <button
    [type]="type"
    class="button"
    [ngClass]="appearance"
    [class.with-icon]="icon"
    [class.with-menu]="menu"
    [class.disabled]="disabled"
    [disabled]="disabled"
  >
    <mat-icon *ngIf="icon" [svgIcon]="icon" class="button-icon"></mat-icon>
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </button>
  <button
    class="button-menu"
    *ngIf="menu"
    [class.button-menu-blue]="appearance === 'blue'"
    [class.disabled]="disabled"
    (click)="menuButtonAction($event)"
  >
    <mat-icon svgIcon="dropdown-small" class="icon"></mat-icon>
  </button>
</div>

<button
  class="big-button"
  *ngIf="appearance === 'big'"
  [type]="type"
  [disabled]="disabled"
>
  <div class="big-button-icon-container" *ngIf="icon">
    <mat-icon [svgIcon]="icon" class="big-button-icon"></mat-icon>
  </div>
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</button>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
