<button
  class="button"
  [class.highlighted]="highlighted && !disabled"
  [class.disabled]="disabled"
  [disabled]="disabled"
  (click)="clicked.emit($event)"
>
  <div *ngIf="text && text.length" class="button-text">{{ text }}</div>
  <div *ngIf="icon" class="button-icon">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>
  <div *ngIf="badge" class="button-badge"></div>
</button>
