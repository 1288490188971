<div class="compass">
  <app-knowledge-header
    title="Daily Focus"
    [orders]="orderOptions"
    [selected]="selectedOrder"
    [search]="true"
    (selectedChange)="selectOrder($event)"
  ></app-knowledge-header>
 
  <div class="compass-content">
    <app-quick-form
      placeholder="Add Event, Task or Reminder"
      [smart]="true"
      bundledGroup="kpCompassQuickForm"
    >
      <app-event-quick-form 
        bundledGroup="kpCompassQuickForm"
        (more)="handleNewEvent($event)"
      ></app-event-quick-form>
      <app-task-quick-form 
        bundledGroup="kpCompassQuickForm"
        (more)="handleNewTask($event)"
      ></app-task-quick-form>
      <app-note-quick-form
        bundledGroup="kpCompassQuickForm"
        (more)="handleNewNote($event)"
      ></app-note-quick-form>
      <app-project-quick-form 
        bundledGroup="kpCompassQuickForm"
        (more)="handleNewProject($event)"
      ></app-project-quick-form>
    </app-quick-form>
    <div class="compass-content-calendar">
      <app-events-date-picker
        [inline]="true"
        storeDateKey="kpCompass"
        [inputFormControl]="dateFromControl"
        collapsedStateKey="dpCompass"
      ></app-events-date-picker>
    </div>
  </div>
</div>
