// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { MailService } from '@modules/mail/services/mail.service';

// Types
import { MailFolder } from '@modules/mail/types/mail-folder';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';

@Component({
  selector: 'app-dropdown-folders',
  templateUrl: './dropdown-folders.component.html',
  styleUrls: ['./dropdown-folders.component.less']
})
export class DropdownFoldersComponent implements OnInit {

  // Inputs
  @Input() folder: MailFolder;

  // Outputs
  @Output() folderChange = new EventEmitter<MailFolder>();

  // Public
  public folders: MailFolder[];
  public defaultFolders: DropdownOption[];
  public userFolders: DropdownOption[];

  // Private
  private defaultFolderIds: string[] = MailService.defaultFolderIds;
  private componentNotDestroyed = new Subject();

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.mailService.getFolders()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(folders => {
        this.folders = folders;
        this.defaultFolders = [];
        this.userFolders = [];
        this.folders.forEach(folder => {
          if (this.defaultFolderIds.some(id => folder.id === id)) {
            this.defaultFolders.push({name: folder.name, key: folder.id});
          } else {
            this.userFolders.push({name: folder.name, key: folder.id});
          }
        });
     });
  }

  /**
   * Actions
   */

  select(option: DropdownOption) {
    const folder = this.folders.find(item => item.id === option.key);
    if (folder) {
      this.folder = folder;
      this.folderChange.emit(folder);
    }
  }

}
