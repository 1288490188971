// Common
import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// RX
import { Observable, of, Subject } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map, takeUntil } from 'rxjs/operators';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';

// Types
import { EventsListResponse } from '@modules/events/types/events-list-response';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-event-title-input',
  templateUrl: './event-title-input.component.html',
  styleUrls: ['./event-title-input.component.less'],
})
export class EventTitleInputComponent implements OnChanges, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputProviderKeys: string[];
  @Input() bundledInputProviderGroup: string;
  @Input() bundledInputConsumerGroup: string;

  // Public
  suggestedOptions: Observable<DropdownSelectItem[]>;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.suggestedOptions = this.inputFormControl.valueChanges
        .pipe(
          startWith([]),
          debounceTime(600),
          distinctUntilChanged(),
          switchMap((title: string) => {
            if (title && title.length) {
              return this.calendarService
                .getEvents({title})
                .pipe(
                  map((response: EventsListResponse) => this.convertEventsToDropdownItems(response.events)),
                );
            }
            return of([]);
          }),
          takeUntil(this.componentNotDestroyed),
        );
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Helpers
   */

  private convertEventsToDropdownItems(events: CalendarEvent[]): DropdownSelectItem[] {
    return events.map((event: CalendarEvent) => ({
      title: event.title,
      value: event,
    }));
  }
}
