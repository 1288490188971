import * as tslib_1 from "tslib";
// Common
import { OnInit, SimpleChanges, OnChanges, EventEmitter, NgZone, OnDestroy } from '@angular/core';
// RxJS
import { interval, BehaviorSubject, merge, Subject, EMPTY } from 'rxjs';
import { takeUntil, filter, debounceTime, map, first, switchMap } from 'rxjs/operators';
// Base Component
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';
// Env
import { environment } from '@environment';
var EventsListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EventsListComponent, _super);
    /**
     * Constructor
     */
    function EventsListComponent(calendarService, eventsStateService, linkedInfoService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.calendarService = calendarService;
        _this.eventsStateService = eventsStateService;
        _this.linkedInfoService = linkedInfoService;
        _this.ngZone = ngZone;
        _this.placeholderText = 'You have no events';
        _this.defaultFilters = {};
        _this.hoverPlacement = 'right';
        // Outputs
        _this.eventClick = new EventEmitter();
        _this.selectedItemsChanged = new EventEmitter();
        _this.viewDate = new EventEmitter();
        // Public
        _this.itemHeight = 93;
        _this.showCountView = new BehaviorSubject(true);
        // Private
        _this.isComponentInitialized = false;
        _this.scrollToDayChanges = new Subject();
        _this.scrollToDayChanges
            .pipe(takeUntil(_this.componentNotDestroyed), switchMap(function () { return _this.scrollToDay || EMPTY; }))
            .subscribe(function (date) { return date && _this.scrollToDate(date); });
        return _this;
    }
    /**
     * Component lifecycle
     */
    EventsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showCountView
            .pipe(filter(function (value) { return !!value; }), debounceTime(5000), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.showCountView.next(false);
        });
        merge(
        // Global refresh button
        this.eventsStateService.getRefreshAll(), 
        // Subscribe for linked info updates
        this.linkedInfoService.getLinkedInfoUpdate(), 
        // Make automatic updates for new event
        interval(environment.messageFetchInterval)
            .pipe(filter(function () { return !_this.loading && _this.selectedOrder && _this.selectedOrder.key === 'date'; })), this.calendarService.createNewEvent, this.calendarService.updatedEvent, this.calendarService.deletedEvent)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.refreshCurrentItems();
        });
        this.scrollToDate(new Date);
        merge(this.currentIndex.asObservable(), this.itemsStreamObservable)
            .pipe(filter(function () { return _this.currentIndex.value >= 0; }), filter(function () { return _this.items && _this.items.length > 0 && _this.items[_this.currentIndex.value] &&
            _this.items[_this.currentIndex.value]['when'] && _this.items[_this.currentIndex.value]['when']['start'] !== undefined; }), map(function () { return _this.items[_this.currentIndex.value]['when']['start']; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (date) { return _this.viewDate.emit(date); });
    };
    EventsListComponent.prototype.ngOnChanges = function (changes) {
        this.showCountView.next(true);
        if (('selectedOrder' in changes && this.selectedOrder) ||
            'defaultFilters' in changes) {
            this.resetItems();
        }
        if ('scrollPosition' in changes && this.scrollPosition !== null) {
            this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
        }
        if ('scrollToDay' in changes) {
            this.scrollToDayChanges.next();
        }
    };
    /**
     * Actions
     */
    EventsListComponent.prototype.scrollToDate = function (date) {
        var _this = this;
        this.calendarService.getEvents(tslib_1.__assign({}, this.defaultFilters, { orderWithPinned: true, order: this.selectedOrder.key, limit: 1, toDate: this.beginningOfDay(date) }))
            .pipe(first(), takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var count = _a.count;
            _this.scrollToIndex(count);
            if (!_this.isComponentInitialized) {
                _this.isComponentInitialized = true;
                _super.prototype.ngOnInit.call(_this);
            }
        });
    };
    /**
     * Base Methods override
     */
    EventsListComponent.prototype.getItems = function (offset, limit) {
        return this.calendarService
            .getEvents(tslib_1.__assign({}, this.defaultFilters, { orderWithPinned: true, order: this.selectedOrder.key, limit: limit,
            offset: offset }))
            .pipe(map(function (_a) {
            var events = _a.events, count = _a.count;
            return ({ items: events, count: count });
        }));
    };
    EventsListComponent.prototype.resetItems = function () {
        this.selectedItemsChanged.emit([]);
        _super.prototype.resetItems.call(this);
    };
    EventsListComponent.prototype.selectItem = function (calendarEvent, event, selectAll) {
        if (selectAll === void 0) { selectAll = false; }
        _super.prototype.selectItem.call(this, calendarEvent, event, selectAll);
        this.selectedItemsChanged.emit(this.selectedItems);
        if (this.selectedItems.length === 1) {
            this.eventClick.emit(this.selectedItems[0]);
        }
    };
    /**
     * Helpers
     */
    EventsListComponent.prototype.beginningOfDay = function (date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    };
    return EventsListComponent;
}(InfinityScrollListComponent));
export { EventsListComponent };
