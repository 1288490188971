// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ContextMenuModule } from 'ngx-contextmenu';
import { PopoverModule } from '../popover/popover.module';

// Services
import { UserAvatarService } from './services/user-avatar.service';
import { ModalService } from '../modal/services/modal.service';

// Directives
import { ContactContextMenuDirective } from './components/context-menus/context-context-menu-directive/contact-context-menu.directive';

// Components
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ContactsCounterDropdownComponent } from './components/contacts-counter-dropdown/contacts-counter-dropdown.component';
import { ContactContextMenuComponent } from './components/context-menus/contact-context-menu/contact-context-menu.component';

@NgModule({
  declarations: [
    UserAvatarComponent, ContactContextMenuDirective, ContactContextMenuComponent, ContactsCounterDropdownComponent
  ],
  exports: [
    UserAvatarComponent, ContactContextMenuDirective, ContactContextMenuComponent, ContactsCounterDropdownComponent
  ],
  imports: [
    CommonModule,
    PopoverModule,
    ContextMenuModule.forRoot(),
  ],
  providers: [
    UserAvatarService,
    ModalService
  ],
  entryComponents: [
    ContactContextMenuComponent,
  ],
})
export class UserAvatarModule { }
