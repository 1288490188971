// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, throwError, Subject, ConnectableObservable } from 'rxjs';
import { map, catchError, tap, publish } from 'rxjs/operators';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { LinkedInfo, LinkedInfoType } from './../types/linked-info';

// Env
import { environment } from '@environment';

// Services
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';

enum UpdateType {
  Add = 1,
  Delete,
}

@Injectable({
  providedIn: 'root'
})
export class LinkedInfoService {

  // Private
  private linkedInfoUpdated = new Subject<UpdateType>();

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private asyncTasksToastsService: AsyncTasksToastsService,
  ) { }

  /**
   * Static methods
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Methods
   */

  getLinkedInfoUpdate(): Observable<UpdateType> {
    return this.linkedInfoUpdated.asObservable();
  }

  getLinkedInfo(type: LinkedInfoType, id: string): Observable<LinkedInfo[]> {
    return this.http.get<{linkedItems: LinkedInfo[]}>(`${environment.baseUrl}/api/linked-info/${type}/${id}`)
      .pipe(
        map(({ linkedItems }) => linkedItems),
        catchError(LinkedInfoService.handleObserverError)
      );
  }

  @warmUpObservable
  linkItems(items: LinkedInfo[]): Observable<boolean> {
    return this.http.post<{success: boolean}>(environment.baseUrl + '/api/linked-info', { linkedItems: items })
      .pipe(
        tap(({ success }) => {
          this.linkedInfoUpdated.next(UpdateType.Add);
          if (success) {
            this.asyncTasksToastsService.show({ text: `Item(s) linked.` });
          }
        }),
        map(({ success }) => success),
        catchError(LinkedInfoService.handleObserverError)
      );
  }

  @warmUpObservable
  linkToItem(item: LinkedInfo, items: LinkedInfo[]): Observable<boolean> {
    return this.http.post<{success: boolean}>(
      environment.baseUrl + '/api/linked-info/' + item.type + 's/' + item.id,
      { linkedItems: items }
      )
      .pipe(
        tap(() => this.linkedInfoUpdated.next(UpdateType.Add)),
        map(({ success }) => success),
        catchError(LinkedInfoService.handleObserverError)
      );
  }

  @warmUpObservable
  unlinkItems(items: LinkedInfo[]): Observable<boolean> {
    const params = {body: {
      linkedItems: items
    }};
    return this.http.request<{success: boolean}>('DELETE', environment.baseUrl + '/api/linked-info', params)
      .pipe(
        tap(() => this.linkedInfoUpdated.next(UpdateType.Delete)),
        map(({ success }) => success),
        catchError(LinkedInfoService.handleObserverError)
      );
  }

}
