<div
  class="app-menu-wrapper"
  [ngClass]="{
    expanded: isAppMenuExpanded,
    collapsed: !isAppMenuExpanded
  }"
>
  <div *ngIf="isAppMenuExpanded" (click)="toggle()">
    <mat-icon class="app-icon active menu" svgIcon="app-menu-collapse-down"></mat-icon>
  </div>
  <div *ngIf="!isAppMenuExpanded" (click)="toggle()">
    <mat-icon class="app-icon active menu" svgIcon="app-menu-menu"></mat-icon>
  </div>
  <a [routerLink]="['/mail']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="app-menu-inbox"></mat-icon>
  </a>
  <a [routerLink]="['/insights']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="app-menu-explore"></mat-icon>
  </a>
  <a [routerLink]="['/events']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="events"></mat-icon>
  </a>
  <a [routerLink]="['/tasks']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="tasks"></mat-icon>
  </a>
  <a [routerLink]="['/notes']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="app-menu-notes"></mat-icon>
  </a>
  <a [routerLink]="['/contacts']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="app-menu-contacts"></mat-icon>
  </a>
  <a [routerLink]="['/files']" [routerLinkActive]="['active']">
    <mat-icon class="app-icon" svgIcon="file"></mat-icon>
  </a>
  <mat-icon (click)="openSettings()" class="app-icon" svgIcon="app-menu-settings"></mat-icon>
</div>
