import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PopupWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
