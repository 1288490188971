<div
  class="temporal-expression-wrapper text-no-select"
  *ngIf="expression"
  [ngClass]="{
    'selected': selected,
    'status-available': expression.availability === 'available',
    'status-partially': expression.availability === 'partially',
    'status-busy': expression.availability === 'busy'
  }"
  [contextMenu]="expressionMenu"
  [contextMenuSubject]="expression"
  [tooltip]="tooltipTemplate"
  placement="top"
  container="body">
  <i class="fa fa-calendar"></i> {{ expression.fromTime | date:'medium' }}
</div>

<!-- Context Menu -->
<context-menu #expressionMenu [disabled]="disableContextMenu">
  <ng-template contextMenuItem (execute)="showInCalendarHandler()">
    Show Calendar
  </ng-template>
  <ng-template contextMenuItem (execute)="createAppointmentHandler()">
    Create Appointment
  </ng-template>
</context-menu>
<!-- End: Context Menu -->

<!-- Tooltip -->
<ng-template #tooltipTemplate>
  <div>Original text: <b>{{ expression.text }}</b></div>
  <div *ngIf="!expression.toTime">Direct Time: <b>{{ expression.fromTime | date:'medium' }}</b></div>
  <ng-container *ngIf="expression.toTime">
    <div>From Time: <b>{{ expression.fromTime | date:'medium' }}</b></div>
    <div>To Time: <b>{{ expression.toTime | date:'medium' }}</b></div>
  </ng-container>
</ng-template>
<!-- End: Tooltip -->
