import { DynamicSizeScrollingItem } from '@modules/virtual-scroll/types/dynamic-size-scrolling-item';
import { MailMessage } from './mail-message';

export class DynamicSizeMailMessage extends DynamicSizeScrollingItem<MailMessage> {
  private sizes = {
    placeholder: 87,
    base: 89,
    linkedInfo: 24
  };

  constructor(public data: MailMessage) {
    super();

    this.size = data ? this.sizes.base : this.sizes.placeholder;

    if (data && data.linkedInfo && data.linkedInfo.length) {
      this.size += this.sizes.linkedInfo;
    }
  }
}
