// Common
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';

// Types
import { NotesFilters } from '@modules/notes/types/notes-filters';

@Component({
  selector: 'app-notes-side-bar',
  templateUrl: './notes-side-bar.component.html',
  styleUrls: ['./notes-side-bar.component.less'],
  providers: [ContentMenuService]
})
export class NotesSideBarComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() notesFilters: NotesFilters = {};

  // Outputs
  @Output() notesFiltersChange = new EventEmitter<NotesFilters>();

  // Public
  public notebooks: string[] = [];
  public today = new Date();
  public nextWeekDays: Date[] = [];
  public allNotesSelected = true;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Coonstructor
   */

  constructor(
    private notesService: NotesService,
    private splitViewService: SplitViewService,
    private contentMenuService: ContentMenuService
  ) {
    for (let i = 1; i <= 7; i++) {
      this.nextWeekDays.push(new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + i, 0, 0, 0));
    }

    this.splitViewService.getMinimized('notesSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        this.contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit () {
    this.notesService.getNotebooks()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((notebooks: string[]) => this.notebooks = notebooks);
    this.notesFiltersChange
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => this.updateTabs());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('notesFilters' in changes) {
      this.updateTabs();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  updateTabs() {
    this.allNotesSelected = Object.keys(this.notesFilters).length === 0;
  }

  /**
   * Actions
   */

  resetFilters() {
    this.notesFilters = {};
    this.notesFiltersChange.emit(this.notesFilters);
  }

  filterByDate(date: Date): void {
    this.notesFilters = {
      fromTime: date ? startOfDay(date) : null,
      toTime: date ? endOfDay(date) : null
    };
    this.notesFiltersChange.emit(this.notesFilters);
  }

  filterByFavorite() {
    this.notesFilters = {favorite: true};
    this.notesFiltersChange.emit(this.notesFilters);
  }

  filterDyArchive() {
    this.notesFilters = {archived: true};
    this.notesFiltersChange.emit(this.notesFilters);
  }

  filterByTrash() {
    this.notesFilters = {deleted: true};
    this.notesFiltersChange.emit(this.notesFilters);
  }

}
