<!-- List -->
<ng-template #linkedInfoList let-linkedInfoData="linkedInfoData">
  <div class="linked-info__list">
    <ng-container *ngFor="let info of linkedInfoData" [ngSwitch]="info.type">

      <!-- Message -->
      <div class="item" *ngSwitchCase="'message'">
        <app-link-info-message
          *ngIf="linkedInfoFilters.message"
          [linkedInfo]="info"
          [message]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-message>
      </div>

      <!-- Event -->
      <div class="item" *ngSwitchCase="'event'">
        <app-link-info-event
          *ngIf="linkedInfoFilters.event"
          [linkedInfo]="info"
          [event]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-event>
      </div>

      <!-- Note -->
      <div class="item" *ngSwitchCase="'note'">
        <app-link-info-note
          *ngIf="linkedInfoFilters.note"
          [linkedInfo]="info"
          [note]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-note>
      </div>

      <!-- Project -->
      <div class="item" *ngSwitchCase="'project'">
        <app-link-info-project
          *ngIf="linkedInfoFilters.project"
          [linkedInfo]="info"
          [project]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-project>
      </div>

      <!-- Task -->
      <div class="item" *ngSwitchCase="'task'">
        <app-link-info-task
          *ngIf="linkedInfoFilters.task"
          [linkedInfo]="info"
          [task]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-task>
      </div>

      <!-- Group -->
      <div class="item" *ngSwitchCase="'group'">
        <app-link-info-group
          *ngIf="linkedInfoFilters.group"
          [linkedInfo]="info"
          [group]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-group>
      </div>

      <!-- Contact -->
      <div class="item" *ngSwitchCase="'contact'">
        <app-link-info-contact
          *ngIf="linkedInfoFilters.contact"
          [linkedInfo]="info"
          [contact]="info.data"
          (unlink)="unlinkItem($event)"
          [shortView]="viewType === 'list-view'"
        ></app-link-info-contact>
      </div>

    </ng-container>
    <div *ngIf="!linkedInfoData.length" class="placeholder">You haven't Linked Information yet.</div>
  </div>
</ng-template>

<!-- Header -->
<app-form-collapseable-group
  *ngIf="collapseble"
  [headerTitleTemplate]="headerTitleTemplate"
  [headerRightIconsTemplate]="headerRightIconsTemplate"
  [extendIconEnabled]="extendEnabled"
  [extendIconSelected]="extendSelected"
  (extendIconAction)="handleExtendIcon()"
>
  <div class="view-type-buttons">
    <mat-icon
      class="view-type-buttons-icon"
      [class.selected]="viewType === 'cards-view'"
      svgIcon="cards-view"
      (click)="changeViewType('cards-view')"
    ></mat-icon>
    <mat-icon
      class="view-type-buttons-icon"
      [class.selected]="viewType === 'list-view'"
      svgIcon="list-view-big"
      (click)="changeViewType('list-view')"
    ></mat-icon>
  </div>
  <ng-container *ngTemplateOutlet="linkedInfoList; context:{ linkedInfoData: linkedInfoData }"></ng-container>
  <app-button
    class="unlink-all"
    appearance="link-blue"
    *ngIf="linkedInfoData.length"
    (click)="unlinkAll()"
  >
    Unlink All
  </app-button>
</app-form-collapseable-group>

<!-- Without header -->
<ng-container *ngIf="!collapseble">
  <ng-container *ngTemplateOutlet="linkedInfoList; context: { linkedInfoData: linkedInfoData }"></ng-container>
</ng-container>

<!-- Header Title Template -->
<ng-template #headerTitleTemplate>
  <div class="group-header">
    <span class="group-header-title">Linked Info</span>
    <div
      class="group-header-icon-container"
      (click)="toggleSort()"
      [class.DESC]="!sortAsc"
    >
      <mat-icon class="icon" svgIcon="arrow-up"></mat-icon>
    </div>
  </div>
</ng-template>

<!-- Header Right Icons Template -->
<ng-template #headerRightIconsTemplate>
  <div class="right-icons-group">
    <app-icon-button
      icon="email"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.message"
      (clicked)="toggleFilter('message')"
      [matTooltip]="linkedInfoFilters.message ? 'Hide messages' : 'Show messages'"
    ></app-icon-button>
    <app-icon-button
      icon="event-small"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.event"
      (clicked)="toggleFilter('event')"
      [matTooltip]="linkedInfoFilters.event ? 'Hide events' : 'Show events'"
    ></app-icon-button>
    <app-icon-button
      icon="project"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.project"
      (clicked)="toggleFilter('project')"
      [matTooltip]="linkedInfoFilters.project ? 'Hide projects' : 'Show projects'"
    ></app-icon-button>
    <app-icon-button
      icon="tasks-small"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.task"
      (clicked)="toggleFilter('task')"
      [matTooltip]="linkedInfoFilters.task ? 'Hide tasks' : 'Show tasks'"
    ></app-icon-button>
    <app-icon-button
      icon="note"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.note"
      (clicked)="toggleFilter('note')"
      [matTooltip]="linkedInfoFilters.note ? 'Hide notes' : 'Show notes'"
    ></app-icon-button>
    <app-icon-button
      icon="groups"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.group"
      (clicked)="toggleFilter('group')"
      [matTooltip]="linkedInfoFilters.group ? 'Hide groups' : 'Show groups'"
    ></app-icon-button>
    <app-icon-button
      icon="contacts"
      class="right-icons-group-item"
      [highlighted]="linkedInfoFilters.contact"
      (clicked)="toggleFilter('contact')"
      [matTooltip]="linkedInfoFilters.contact ? 'Hide contacts' : 'Show contacts'"
    ></app-icon-button>
  </div>
</ng-template>
