// Common
import { Component, Input, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges  } from '@angular/core';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { TagService } from '@modules/tag/services/tag.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// Types
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { Task } from '@modules/tasks/types/task';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Custom options the configure the tooltip's default show/hide delays. */
export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class TaskComponent implements OnChanges, OnDestroy {

  // Inputs
  @Input() task: Task;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() selectedTasks: Task[] = [];

  // Public
  public selected = false;
  public contextMenuOpened = false;
  public isDragging = false;
  public dragData: Task[] = [];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'task' && dragData.data.length === 1 && dragData.data[0]['id'] === this.task.id) &&
    ['message', 'event', 'project', 'task', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected modalService: ModalService,
    protected tasksStateService: TasksStateService,
    protected changeDetector: ChangeDetectorRef,
    protected linkedInfoService: LinkedInfoService,
    protected topicService: TopicService,
    protected tagService: TagService,
    protected tasksService: TasksService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('task' in changes) {
      this.dragData = [this.task];
    }
    if ('selectedTasks' in changes) {
      if (
        this.task &&
        this.selectedTasks &&
        this.selectedTasks.some(selected => selected.id === this.task.id)
      ) {
        this.dragData = this.selectedTasks;
        this.selected = true;
      } else {
        this.selected = false;
        this.dragData = [this.task];
      }
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleDoubleClick() {
    this.ga.trackEvent('tasks-list', 'open-task-modal');
    this.modalService.taskForm(this.task);
  }

  dndDrop(dragData: DragData) {
    if (!dragData.data) { return; }

    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('task', this.task.id), linkedItems);
    }

    // Topic
    if (dragData.type === 'topic') {
      const topics = (dragData.data as string[]).map(topic => ({name: topic}));
      this.topicService.createTopics(topics, {tasksIds: [this.task.id]});
    }

    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {tasksIds: [this.task.id]});
    }
  }

  pin() {
    this.tasksService.pin([this.task.id], !this.task.pinned)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.pinned = tasks[0].pinned;
        }
      });
  }

  archive() {
    this.tasksService.archive([this.task.id], !this.task.archived)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((tasks: Task[]) => {
        if (tasks[0]) {
          this.task.archived = tasks[0].archived;
        }
      });
  }

  delete() {
    this.task.deleted ?
      this.tasksService.deletePermanently([this.task.id]) :
      this.tasksService.delete([this.task.id], true);
  }
}
