<app-split-view
  collapseableSide="left"
  key="contactsSidebar"
  [minWidth]="65"
  [shadow]="true"
  [magneticWidth]="120"
>
  <app-sidebar split-left-pane splitViewKey="contactsSidebar">
    <app-contacts-side-bar></app-contacts-side-bar>
  </app-sidebar>

  <app-split-view
    #splitView
    split-right-pane
    collapseableSide="right"
    key="knowledgePanel"
    [minWidth]="50"
    [magneticWidth]="200"
    [shadow]="dynamicPanelContent?.type !== 'kp'"
  >
    <div split-left-pane class="main">
      <app-nav-bar></app-nav-bar>
      <div class="main-under-nav-bar">
        <app-split-view
          collapseableSide="left"
          key="contactsList"
          [minWidth]="15"
          [shadow]="true"
          [magneticWidth]="300"
          icon="button-split-projects-collapsed"
        >
          <app-contacts-list-container split-left-pane></app-contacts-list-container>
          <div class="main-detail" split-right-pane>
            <app-contacts-main-view></app-contacts-main-view>
            <app-linked-info-toolbar *ngIf="linkedInfoToolbarPresent"></app-linked-info-toolbar>
          </div>
        </app-split-view>
      </div>
    </div>
    <div split-right-pane class="knowledge-panel" [ngSwitch]="dynamicPanelContent?.type">
      <app-knowledge-panel
        *ngSwitchDefault
      ></app-knowledge-panel>
      <app-attachments-dynamic-panel
        split-right-pane
        *ngSwitchCase="'attachments'"
      ></app-attachments-dynamic-panel>
      <app-linked-info-dynamic-panel
        split-right-pane
        *ngSwitchCase="'linkedInfo'"
        [parentLinkedInfo]="dynamicPanelContent?.data?.parentLinkedInfo"
        [linkedInfo]="dynamicPanelContent?.data?.linkedInfo"
      ></app-linked-info-dynamic-panel>
      <app-linked-info-toolbar *ngIf="linkedKPInfoToolbarPresent"></app-linked-info-toolbar>
    </div>
  </app-split-view>
</app-split-view>
<app-menu></app-menu>
