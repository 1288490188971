/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draggable-element.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./draggable-element.component";
import * as i4 from "../../services/drag-n-drop.service";
import * as i5 from "@angular/platform-browser";
var styles_DraggableElementComponent = [i0.styles];
var RenderType_DraggableElementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DraggableElementComponent, data: { "animation": [{ type: 7, name: "returnBackMotion", definitions: [{ type: 1, expr: "field => home", animation: [{ type: 6, styles: { top: "{{ currentTop }}", left: "{{ currentLeft }}", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { top: "{{ destinationTop }}", left: "{{ destinationLeft }}", opacity: 0.6 }, offset: null }, timings: ".5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_DraggableElementComponent as RenderType_DraggableElementComponent };
function View_DraggableElementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_DraggableElementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 7, "div", [["class", "draggable-container"]], [[2, "multiple-container", null], [2, "no-shadow", null], [4, "height", null], [4, "width", null], [4, "transform", null], [24, "@returnBackMotion", 0]], [[null, "@returnBackMotion.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@returnBackMotion.done" === en)) {
        var pd_0 = (_co.handleAfterReturnBackAnimation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { destinationTop: 0, destinationLeft: 1, currentTop: 2, currentLeft: 3 }), i1.ɵpod(5, { value: 0, params: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], [[2, "after-drop-container", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DraggableElementComponent_1)), i1.ɵdid(8, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.customStyles; _ck(_v, 3, 0, currVal_6); var currVal_8 = (!!_co.afterDropTemplate ? _co.afterDropTemplate : _co.dragPlaceholder); _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multipleStyle; var currVal_1 = _co.noShadow; var currVal_2 = ((_co.height > 0) ? (_co.height + "px") : "auto"); var currVal_3 = ((_co.width > 0) ? (_co.width + "px") : "auto"); var currVal_4 = _co.repositionStyles; var currVal_5 = _ck(_v, 5, 0, _co.returnBackMotionState, _ck(_v, 4, 0, (_co.homeTop + "px"), (_co.homeLeft + "px"), (_co.currentTop + "px"), (_co.currentLeft + "px"))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = !!_co.afterDropTemplate; _ck(_v, 6, 0, currVal_7); }); }
export function View_DraggableElementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-draggable-element", [], null, null, null, View_DraggableElementComponent_0, RenderType_DraggableElementComponent)), i1.ɵdid(1, 4440064, null, 0, i3.DraggableElementComponent, [i1.NgZone, i1.Renderer2, i4.DragNDropService, i5.DomSanitizer, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DraggableElementComponentNgFactory = i1.ɵccf("app-draggable-element", i3.DraggableElementComponent, View_DraggableElementComponent_Host_0, { dragPlaceholder: "dragPlaceholder", dragPlaceholderNode: "dragPlaceholderNode", mouseXAdjustment: "mouseXAdjustment", mouseYAdjustment: "mouseYAdjustment", height: "height", width: "width", customStyles: "customStyles", multipleStyle: "multipleStyle", homeTop: "homeTop", homeLeft: "homeLeft", noShadow: "noShadow" }, { animationFinish: "animationFinish" }, []);
export { DraggableElementComponentNgFactory as DraggableElementComponentNgFactory };
