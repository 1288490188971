/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./participants-input.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../user-avatar/components/user-avatar/user-avatar.component.ngfactory";
import * as i3 from "../../../user-avatar/components/user-avatar/user-avatar.component";
import * as i4 from "../../../user-avatar/services/user-avatar.service";
import * as i5 from "@angular/common";
import * as i6 from "../input/input.component.ngfactory";
import * as i7 from "../input/input.component";
import * as i8 from "../button/button.component.ngfactory";
import * as i9 from "../button/button.component";
import * as i10 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../popover/directives/popover.directive";
import * as i13 from "../../../popover/services/popover.service";
import * as i14 from "./participants-input.component";
import * as i15 from "../../../search/services/search.service";
var styles_ParticipantsInputComponent = [i0.styles];
var RenderType_ParticipantsInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParticipantsInputComponent, data: {} });
export { RenderType_ParticipantsInputComponent as RenderType_ParticipantsInputComponent };
function View_ParticipantsInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "participants-list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-avatar", [["class", "participants-list-item-avatar"], ["size", "16"]], null, null, null, i2.View_UserAvatarComponent_0, i2.RenderType_UserAvatarComponent)), i1.ɵdid(2, 573440, null, 0, i3.UserAvatarComponent, [i4.UserAvatarService], { contact: [0, "contact"], usePopover: [1, "usePopover"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "participants-list-item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; var currVal_2 = "16"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.name || _v.context.$implicit.email); _ck(_v, 4, 0, currVal_3); }); }
function View_ParticipantsInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "participants-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ParticipantsInputComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i5.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.inputFormControl.value, 0, (_co.showAll ? undefined : _co.itemsLimit))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ParticipantsInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "participants-more-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showAll = !_co.showAll) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showAll ? "Show Less" : (("+" + (_co.inputFormControl.value.length - _co.itemsLimit)) + " more")); _ck(_v, 1, 0, currVal_0); }); }
function View_ParticipantsInputComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "participants-popover-suggestions-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleContactClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-avatar", [["class", "participants-popover-suggestions-item-avatar"], ["size", "24"]], null, null, null, i2.View_UserAvatarComponent_0, i2.RenderType_UserAvatarComponent)), i1.ɵdid(2, 573440, null, 0, i3.UserAvatarComponent, [i4.UserAvatarService], { contact: [0, "contact"], usePopover: [1, "usePopover"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "participants-popover-suggestions-item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "participants-popover-suggestions-item-email"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = false; var currVal_2 = "24"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.email; _ck(_v, 6, 0, currVal_4); }); }
function View_ParticipantsInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "participants-popover-suggestions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantsInputComponent_6)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchSuggestions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ParticipantsInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "participants-popover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "participants-popover-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Guests"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-input", [["class", "participants-popover-input"], ["placeholder", "Add Guests"]], null, null, null, i6.View_InputComponent_0, i6.RenderType_InputComponent)), i1.ɵdid(4, 49152, null, 0, i7.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantsInputComponent_5)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "participants-popover-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-button", [["class", "participants-popover-buttons-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePopover.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(9, 49152, null, 0, i9.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-button", [["appearance", "blue"], ["class", "participants-popover-buttons-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(12, 49152, null, 0, i9.ButtonComponent, [i1.ElementRef], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "mat-icon", [["class", "participants-popover-close-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hidePopover.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchControl; var currVal_1 = "Add Guests"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.searchSuggestions.length; _ck(_v, 6, 0, currVal_2); var currVal_3 = "blue"; _ck(_v, 12, 0, currVal_3); var currVal_6 = "close"; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 15).inline; var currVal_5 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_4, currVal_5); }); }
export function View_ParticipantsInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "participants"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "participants-title"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "click"]], null, null, null, null, null)), i1.ɵdid(2, 4866048, null, 0, i12.PopoverDirective, [i1.ElementRef, i13.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverShowUntil: [3, "popoverShowUntil"] }, null), (_l()(), i1.ɵted(-1, null, [" + Add Guests "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantsInputComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantsInputComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["searchTemplate", 2]], null, 0, null, View_ParticipantsInputComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 8); var currVal_1 = "right"; var currVal_2 = "click"; var currVal_3 = _co.hidePopover; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.inputFormControl.value.length; _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.inputFormControl.value.length > _co.itemsLimit); _ck(_v, 7, 0, currVal_5); }, null); }
export function View_ParticipantsInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-participants-input", [], null, null, null, View_ParticipantsInputComponent_0, RenderType_ParticipantsInputComponent)), i1.ɵdid(1, 245760, null, 0, i14.ParticipantsInputComponent, [i15.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParticipantsInputComponentNgFactory = i1.ɵccf("app-participants-input", i14.ParticipantsInputComponent, View_ParticipantsInputComponent_Host_0, { inputFormControl: "inputFormControl" }, {}, []);
export { ParticipantsInputComponentNgFactory as ParticipantsInputComponentNgFactory };
