<div class="linked-info-popover">
  <div class="header" [class.shadow]="showHeaderShadow">
    <div class="title">Projects</div>
    <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  </div>
  <div class="list" #scrollList>
    <app-project
      *ngFor="let project of items"
      [project]="project"
      (click)="select(project)"
    ></app-project>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>
