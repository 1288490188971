// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// Models
import { Priority } from '@modules/tasks/types/priority';
import { PrioritiesListItem } from '@modules/tasks/types/priority-item';
import { prioritiesList } from '@modules/tasks/types/priorities-list';

@Component({
  selector: 'app-priority-input',
  templateUrl: './priority-input.component.html',
  styleUrls: ['./priority-input.component.less'],
})
export class PriorityInputComponent {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() public appearanceType: 'default' | 'expandable' = 'default';

  // Public
  public prioritiesList: PrioritiesListItem[] = prioritiesList;

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Actions
   */

  handleClick(priority: Priority) {
    this.inputFormControl.setValue(priority === this.inputFormControl.value ? 'none' : priority);
  }
}
