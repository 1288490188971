import * as tslib_1 from "tslib";
// RxJS
import { Subject, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
var DynamicSizeVirtualScrollStrategy = /** @class */ (function () {
    function DynamicSizeVirtualScrollStrategy(dataSource) {
        var _this = this;
        this.dataSource = new Subject();
        this.totalSize = 0;
        this.clusters = [];
        this.buffer = 1760;
        this.scrolledIndex = new Subject();
        this.scrolledIndexChange = this.scrolledIndex.pipe(distinctUntilChanged());
        this.dataSource
            .pipe(map(function (source) { return source || of([]); }), switchMap(function (source) { return source; }), startWith([]))
            .subscribe(function (items) {
            var e_1, _a;
            _this.clusters = [];
            var totalSize = 0;
            try {
                for (var items_1 = tslib_1.__values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    if (!_this.clusters.length || _this.clusters[_this.clusters.length - 1].size !== item.size) {
                        _this.clusters.push({ size: item.size, count: 1 });
                    }
                    else {
                        _this.clusters[_this.clusters.length - 1].count++;
                    }
                    totalSize += item.size;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (_this.totalSize !== totalSize) {
                _this.totalSize = totalSize;
                _this.viewport.setTotalContentSize(_this.totalSize);
            }
            _this.updateRenderedRange();
        });
        this.dataSource.next(dataSource);
    }
    DynamicSizeVirtualScrollStrategy.prototype.updateDataSource = function (dataSource) {
        this.dataSource.next(dataSource);
    };
    DynamicSizeVirtualScrollStrategy.prototype.attach = function (viewport) {
        this.viewport = viewport;
        this.viewport.setTotalContentSize(this.totalSize);
        this.updateRenderedRange();
    };
    DynamicSizeVirtualScrollStrategy.prototype.detach = function () {
        this.viewport = null;
        this.scrolledIndex.complete();
    };
    DynamicSizeVirtualScrollStrategy.prototype.onContentScrolled = function () {
        this.updateRenderedRange();
    };
    DynamicSizeVirtualScrollStrategy.prototype.onDataLengthChanged = function () { };
    DynamicSizeVirtualScrollStrategy.prototype.onContentRendered = function () { };
    DynamicSizeVirtualScrollStrategy.prototype.onRenderedOffsetChanged = function () { };
    DynamicSizeVirtualScrollStrategy.prototype.scrollToIndex = function (index, behavior) {
        var e_2, _a;
        if (this.viewport) {
            var offset = 0;
            var currentIndex = 0;
            try {
                for (var _b = tslib_1.__values(this.clusters), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var cluster = _c.value;
                    currentIndex += cluster.count;
                    offset += cluster.size * cluster.count;
                    if (currentIndex > index) {
                        offset = offset - (currentIndex - index) * cluster.size;
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this.viewport.scrollToOffset(offset, behavior);
        }
    };
    DynamicSizeVirtualScrollStrategy.prototype.updateRenderedRange = function () {
        var e_3, _a;
        if (!this.viewport) {
            return;
        }
        var viewportSize = this.viewport.getViewportSize();
        var offset = this.viewport.measureScrollOffset();
        var startOffset = Math.max(offset - this.buffer, 0);
        var endOffset = Math.min(offset + viewportSize + this.buffer, this.totalSize);
        var newRenderRange = { start: null, end: null };
        var newRenderContentOffset = 0;
        var newFirstVisibleIndex;
        var currentIndex = 0;
        var currentSize = 0;
        try {
            for (var _b = tslib_1.__values(this.clusters), _c = _b.next(); !_c.done; _c = _b.next()) {
                var cluster = _c.value;
                currentIndex += cluster.count;
                currentSize += cluster.size * cluster.count;
                if (currentSize < startOffset) {
                    continue;
                }
                if (newRenderRange.start === null && currentSize >= startOffset) {
                    newRenderRange.start = currentIndex - Math.floor((currentSize - startOffset) / cluster.size);
                    newRenderContentOffset = currentSize - (currentIndex - newRenderRange.start) * cluster.size;
                }
                if (newFirstVisibleIndex === undefined && currentSize >= offset) {
                    newFirstVisibleIndex = currentIndex - Math.floor((currentSize - offset) / cluster.size);
                }
                if (newRenderRange.end === null && currentSize >= endOffset) {
                    newRenderRange.end = currentIndex - Math.floor((currentSize - endOffset) / cluster.size);
                }
                if (newRenderRange.start !== null && newRenderRange.end !== null && newFirstVisibleIndex !== undefined) {
                    break;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        // Set default values if nothing where found in the loop above (i.e. when array is empty)
        newRenderRange.start = newRenderRange.start || 0;
        newRenderRange.end = newRenderRange.end || 0;
        newFirstVisibleIndex = newFirstVisibleIndex || 0;
        this.viewport.setRenderedRange(newRenderRange);
        this.viewport.setRenderedContentOffset(newRenderContentOffset);
        this.scrolledIndex.next(newFirstVisibleIndex);
    };
    return DynamicSizeVirtualScrollStrategy;
}());
export { DynamicSizeVirtualScrollStrategy };
