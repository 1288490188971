<div
  class="contact-form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableAfterDropTemplate]="dropPopoverFormTemplate"
  [appDroppableShowUntil]="closeDroppedElement"
  [appDroppableZIndex]="0"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>
  <div class="contact-form-actions" *ngIf="contact?.id">

    <mat-icon
      svgIcon="saved"
      class="contact-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="pin"
      (click)="pin()"
      class="contact-form-actions-button"
      [ngClass]="{active: contact?.pinned}"
      [matTooltip]="contact?.pinned ? 'Unpin' : 'Pin'"
    ></mat-icon>

    <mat-icon
      svgIcon="crown"
      class="contact-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="mail"
      class="contact-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="event"
      class="contact-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="archive"
      (click)="archive()"
      class="contact-form-actions-button"
      [ngClass]="{active: contact?.archived}"
      [matTooltip]="contact?.archived ? 'Remove From Archive' : 'Add To Archive'"
    ></mat-icon>

    <mat-icon
      svgIcon="trash-bucket"
      (click)="delete()"
      class="contact-form-actions-button"
      [ngClass]="{active: contact?.deleted}"
      [matTooltip]="contact?.deleted ? 'Delete Permanently' : 'Delete'"
    ></mat-icon>

    <mat-icon
      svgIcon="more-actions"
      class="contact-form-actions-button"
      matTooltip="More Options"
      [appProjectContextMenu]="contact"
      contextMenuTriggerEvent="click"
    ></mat-icon>
  </div>

  <div class="contact-form-related" *ngIf="contact?.id && appearance === 'headerFixedBig'">
    <div class="contact-form-related-buttons">
      <div class="contact-form-related-button">
        <div class="contact-form-related-button-inner">
          <mat-icon class="contact-form-related-button-inner-icon" svgIcon="task" matTooltip="Coming soon"></mat-icon>
        </div>
      </div>
      <div class="contact-form-related-button" (click)="openAttachmentsDP()">
        <div class="contact-form-related-button-inner">
          <mat-icon class="contact-form-related-button-inner-icon" svgIcon="attachment-small"></mat-icon>
        </div>
      </div>
      <div class="contact-form-related-button" (click)="openLinkedInfoDP()">
        <div class="contact-form-related-button-inner">
          <mat-icon class="contact-form-related-button-inner-icon" svgIcon="link-thin"></mat-icon>
        </div>
      </div>
      <div class="contact-form-related-button">
        <div class="contact-form-related-button-inner">
          <mat-icon class="contact-form-related-button-inner-icon" svgIcon="activity" matTooltip="Coming soon"></mat-icon>
        </div>
      </div>
    </div>

    <mat-icon
      appDraggable
      [appDraggableData]="{data: [event], type: 'event'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerAdjustFit]="true"
      [appDraggableContainerStyles]="{'min-width': '300px'}"
      class="contact-form-related-drag-icon"
      svgIcon="drag-icon"
    ></mat-icon>
  </div>

  <div class="contact-form-fixed-header" *ngIf="appearance === 'headerFixedBig'">
    <div class="contact-form-fixed-header-columns">
      <div class="contact-form-fixed-header-columns-input">
        <app-input
          placeholder="Name"
          [inputFormControl]="contactForm.controls['name']"
          appearance="standard"
          icon="contact"
        ></app-input>
      </div>
      <div class="contact-form-fixed-header-columns-buttons">
        <app-button class="contact-form-fixed-header-columns-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
        <app-button class="contact-form-fixed-header-columns-buttons-item" (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>
  </div>

  <div class="contact-form-fixed-small-header" *ngIf="appearance === 'headerFixedSmall'">
    <div class="contact-form-fixed-small-header-title">
      Add new contact
    </div>
    <div class="contact-form-fixed-small-header-buttons">
      <app-button class="contact-form-fixed-small-header-buttons-item" appearance="blue" (click)="handleSubmit()">Save</app-button>
      <app-button class="contact-form-fixed-small-header-buttons-item" (click)="handleCancel()">Cancel</app-button>
    </div>
  </div>

  <form
    class="contact-form-main"
    [formGroup]="contactForm"
    (ngSubmit)="handleSubmit()"
    *ngIf="appearance === 'noHeader' || appearance === 'headerFixedSmall'"
  >
    <div class="contact-form-horizontal-group">
      <div class="contact-form-horizontal-group-full-width-item">
        <app-input
          placeholder="Name"
          [inputFormControl]="contactForm.controls['name']"
          appearance="standard"
          icon="contact"
        ></app-input>
      </div>
    </div>
  </form>

  <app-knowledge-header
    class="contact-form-header"
    height="32px"
    [orders]="orderOptions"
    [selected]="selectedOrder"
    [search]="true"
    [titleTemplate]="headerTitleTemplate"
    (selectedChange)="selectOrder($event)"
    (searchChange)="handleSearchChange($event)"
    [appProjectContextMenu]="contact"
    *ngIf="appearance === 'headerFixedBig'"
  ></app-knowledge-header>

  <app-contact-form
    *ngIf="viewType === 'form'"
    [contact]="contact"
    [contactForm]="contactForm"
    [appearance]="appearance"
    [bundledGroup]="bundledGroup"
    (openLinkedInfoDP)="openLinkedInfoDP()"
  ></app-contact-form>

</div>

<ng-template #headerTitleTemplate>
  <div class="contact-form-header-buttons">
    <mat-icon
      svgIcon="contacts"
      class="contact-form-header-buttons-icon"
      [class.active]="viewType === 'form'"
      (click)="switchView('form')"
    ></mat-icon>
    <mat-icon
      svgIcon="link-thin"
      class="contact-form-header-buttons-icon"
      [class.active]="viewType === 'linkedInfo'"
      (click)="switchView('linkedInfo')"
    ></mat-icon>
    <mat-icon
      svgIcon="list-view"
      class="contact-form-header-buttons-icon"
      [class.active]="viewType === 'activity'"
      (click)="switchView('activity')"
    ></mat-icon>
  </div>
</ng-template>

<ng-template #dropPopoverFormTemplate>
  <div class="drop-popover">
    <app-button icon="tasks" appearance="big" (click)="handleCreateTaskOnDrop()">Create a Task</app-button>
    <div class="drop-popover-separator"></div>
    <app-button icon="link-bold" appearance="big" (click)="handleLinkOnDrop()">Link an Item</app-button>
  </div>
</ng-template>
