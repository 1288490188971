// Common
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from './../../../settings/services/state.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';

// Types
import { Tab } from './../../types/tab';
import { TabInfo } from './../../types/tab-info';
import { MailMessage } from '../../../mail/types/mail-message';
import { Recipient } from '../../../mail/types/recipient';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { Topic } from '@modules/topic/types/topic';
import { SplitViewKey } from '@modules/settings/types/split-view-state';

/** Custom options the configure the tooltip's default show/hide delays. */
const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 600,
  hideDelay: 200,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-knowledge-panel',
  templateUrl: './knowledge-panel.component.html',
  styleUrls: ['./knowledge-panel.component.less'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ],
})
export class KnowledgePanelComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() selectedMessages: MailMessage[] = [];
  @Input() selectedMessagesVisibleTopics: Topic[];
  @Input() selectedContact: Recipient;
  @Input() selectedTopics: string[];
  @Input() selectedRelatedContacts: Recipient[] = [];
  @Input() selectedRelatedTopics: string[] = [];
  @Input() selectedTemporalExpressions: TemporalExpression[] = [];
  @Input() splitViewKey: SplitViewKey = 'knowledgePanel';

  // Outputs
  @Output() selectedContactChange = new EventEmitter<Recipient>();
  @Output() selectedRelatedContactsChange = new EventEmitter<Recipient[]>();
  @Output() selectedRelatedTopicsChange = new EventEmitter<string[]>();

  // Public
  public selectedTab: Tab = 'message';
  public tabs: TabInfo[] = [
    new TabInfo('compass', 'Compass', 'compass'),
    new TabInfo('message', 'KP', 'kp'),
    new TabInfo('mail', 'Mail', 'mail'),
    new TabInfo('calendar', 'Calendar', 'calendar'),
    new TabInfo('tasks', 'Tasks', 'tasks'),
    new TabInfo('notes', 'Notes', 'notes'),
    new TabInfo('contacts', 'Contacts', 'contact', true),
    new TabInfo('explore', 'Explore', 'explore', true),
    new TabInfo('attachments', 'Attachments', 'attachment', true),
  ];
  public minimized: boolean;
  public formOpened: boolean;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private splitViewService: SplitViewService,
    private changeDetector: ChangeDetectorRef,
    private knowledgePanelService: KnowledgePanelService,
  ) {
    this.knowledgePanelService.getFormItem()
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(item => this.formOpened = !!item);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.stateService.getTabsState('kp')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((index: number) => this.selectedTab = this.tabs[index].tab);

    this.splitViewService
      .getMinimized(this.splitViewKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  selectTab(tab: TabInfo, index: number) {
    if (tab.disabled) {
      return;
    }
    this.stateService.setTabsState('kp', index);
  }

  /**
   * Outputs methods
   */

  selectContact(contact: Recipient) {
    this.selectedContact = contact;
    this.selectedContactChange.emit(this.selectedContact);
  }

  selectRelatedContacts(contacts: Recipient[]) {
    this.selectedRelatedContacts = contacts;
    this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
  }

  selectRelatedTopics(topics: string[]) {
    this.selectedRelatedTopics = topics;
    this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
  }

}
