import * as tslib_1 from "tslib";
// Common
import { ElementRef } from '@angular/core';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
import print from 'print-js';
var AttachmentPreviewModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AttachmentPreviewModalComponent, _super);
    /**
     * Constructor
     */
    function AttachmentPreviewModalComponent(element, router) {
        var _this = _super.call(this) || this;
        _this.element = element;
        _this.router = router;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 300px)',
            y: 'calc(50% - 300px)',
            width: '600px',
            height: '600px'
        };
        _this.loading = false;
        _this.progress = {
            loaded: 0,
            total: 0
        };
        _this.pdfZoom = 1;
        _this.zoomStep = 0.25;
        _this.pdfRotation = 0;
        _this.rotationStep = 90;
        // Private
        _this.pdfData = [];
        return _this;
    }
    /**
     * Actions
     */
    AttachmentPreviewModalComponent.prototype.printAttachment = function () {
        this.contentType = this.checkType();
        switch (this.contentType) {
            case 'image':
                print({ printable: this.files[this.currentIndex].url, type: this.contentType });
                break;
            case 'pdf':
                var fileURL = URL.createObjectURL(new Blob([new Uint8Array(this.pdfData[this.currentIndex])], { type: this.files[this.currentIndex].type }));
                print({ printable: fileURL, type: this.contentType });
                break;
            default:
                break;
        }
    };
    /**
     * PDF methods
     */
    AttachmentPreviewModalComponent.prototype.zoomIn = function () {
        this.pdfZoom += this.zoomStep;
    };
    AttachmentPreviewModalComponent.prototype.zoomOut = function () {
        this.pdfZoom -= this.zoomStep;
    };
    AttachmentPreviewModalComponent.prototype.rotateLeft = function () {
        this.pdfRotation += this.rotationStep;
    };
    AttachmentPreviewModalComponent.prototype.rotateRight = function () {
        this.pdfRotation -= this.rotationStep;
    };
    AttachmentPreviewModalComponent.prototype.pdfLoadComplete = function (pdf) {
        var _this = this;
        this.loading = false;
        pdf.getData().then(function (arrayBytes) { return _this.pdfData[_this.currentIndex] = arrayBytes; });
    };
    AttachmentPreviewModalComponent.prototype.pdfErrorLoad = function (error) {
        this.loading = false;
        this.errorMessage = error;
    };
    AttachmentPreviewModalComponent.prototype.checkType = function () {
        if (this.files[this.currentIndex] && this.files[this.currentIndex].type) {
            if (this.files[this.currentIndex].type.match(/^image\//)) {
                return 'image';
            }
            else if (this.files[this.currentIndex].type.match(/^video\//)) {
                return 'video';
            }
            else if (this.files[this.currentIndex].type.match('application/pdf')) {
                return 'pdf';
                this.loading = true;
                this.progress.total = this.files[this.currentIndex].size;
            }
            else {
                return '';
            }
        }
    };
    AttachmentPreviewModalComponent.prototype.prev = function () {
        !this.currentIndex ? this.currentIndex = this.files.length - 1 : --this.currentIndex;
    };
    AttachmentPreviewModalComponent.prototype.next = function () {
        this.currentIndex === this.files.length - 1 ? this.currentIndex = 0 : ++this.currentIndex;
    };
    AttachmentPreviewModalComponent.prototype.pdfOnProgress = function (progressData) {
        this.loading = true;
        this.progress.loaded = progressData.loaded;
        this.progress.total = this.files[this.currentIndex].size;
    };
    return AttachmentPreviewModalComponent;
}(BaseModalComponent));
export { AttachmentPreviewModalComponent };
