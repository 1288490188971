// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// Rx
import { Observable, Subject, throwError, of, forkJoin } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { AsyncTasksToastsService } from '@modules/async-tasks/services/async-tasks-toasts.service';
import { TopicService } from '@modules/topic/services/topic.service';

// Types
import { Task } from '../types/task';
import { TasksFilters } from '../types/tasks-filters';
import { TasksListResponse } from '../types/tasks-list-response';
import { Topic } from '@modules/topic/types/topic';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class TasksService {

  // Public
  public taskCreated = new Subject<Task>();
  public taskUpdated = new Subject<Task>();
  public taskDeleted = new Subject<string>();

  /**
   * Settings
   */

  static handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient,
    private linkedInfoService: LinkedInfoService,
    private asyncTasksToastsService: AsyncTasksToastsService,
    private topicService: TopicService
  ) {

  }

  /**
   * Helpers
   */

  private formatFilters(filters: TasksFilters): { [param: string]: string | string[]; } {
    const formattedFilters = {};
    if (filters.tasksIds) { formattedFilters['tasks_ids[]'] = filters.tasksIds; }
    if (filters.projectsIds) { formattedFilters['projects_ids[]'] = filters.projectsIds; }

    if (filters.noProject !== null && filters.noProject !== undefined) { formattedFilters['no_project'] = filters.noProject + ''; }
    if (filters.parentsIds) { formattedFilters['parents_ids[]'] = filters.parentsIds; }
    if (filters.noParent !== null && filters.noParent !== undefined) { formattedFilters['no_parent'] = filters.noParent + ''; }

    if (filters.archived !== null && filters.archived !== undefined) { formattedFilters['archived'] = filters.archived + ''; }
    if (filters.deleted !== null && filters.deleted !== undefined) { formattedFilters['deleted'] = filters.deleted + ''; }
    if (filters.pinned !== null && filters.pinned !== undefined) { formattedFilters['pinned'] = filters.pinned + ''; }

    if (filters.fromTime) { formattedFilters['from_time'] = filters.fromTime.getTime() + ''; }
    if (filters.toTime) { formattedFilters['to_time'] = filters.toTime.getTime() + ''; }
    if (filters.scheduled !== null && filters.scheduled !== undefined) { formattedFilters['scheduled'] = filters.scheduled + ''; }

    if (filters.priority) { formattedFilters['priority'] = filters.priority; }

    if (filters.keywords) { formattedFilters['keywords[]'] = filters.keywords; }
    if (filters.topics) { formattedFilters['topics[]'] = filters.topics; }
    if (filters.tags) { formattedFilters['tags[]'] = filters.tags; }
    if (filters.contacts) { formattedFilters['contacts[]'] = filters.contacts; }

    if (filters.order) { formattedFilters['order'] = filters.order; }
    if (filters.orderWithPinned) { formattedFilters['order_with_pinned'] = filters.orderWithPinned + ''; }

    if (filters.limit) { formattedFilters['limit'] = filters.limit + ''; }
    if (filters.offset) { formattedFilters['offset'] = filters.offset + ''; }

    return formattedFilters;
  }

  /**
   * Methods
   */

  getTasks(filters: TasksFilters = {}): Observable<TasksListResponse> {
    const requestParams = {params: this.formatFilters(filters)};

    return this.http.get<TasksListResponse>(environment.baseUrl + '/api/tasks', requestParams)
      .pipe(
        map(({ count, tasks }) => ({count, tasks: tasks.map(task => new Task(task))})),
        catchError(TasksService.handleObserverError)
      );
  }

  getTask(id: string): Observable<Task> {
    return this.http.get<{ task: Task }>(environment.baseUrl + '/api/tasks/' + id)
      .pipe(
        map(({ task }) => new Task(task)),
      );
  }

  @warmUpObservable
  create(taskInstance: Task): Observable<Task> {
    return this.http.post<{ task: Task, success: boolean }>(`${environment.baseUrl}/api/tasks`, taskInstance.asPayloadJSON())
      .pipe(
        map(({ task, success }) => ({ task: new Task(task), success })),
        tap(({ task, success }) => {
          if (success) {
            this.taskCreated.next(task);
            this.asyncTasksToastsService.show({ text: `Task(s) created.` });
          }
        }),
        tap(({ task }) => {
          if (taskInstance.linkedInfo) {
            this.linkedInfoService.linkToItem({ type: 'task', id: task.id }, taskInstance.linkedInfo);
          }
        }),
        map(({ task }) => task),
        catchError((error: HttpErrorResponse) => {
          this.asyncTasksToastsService.show({ text: error.error.error });
          return throwError(error);
        }),
        switchMap(task => {
          if (taskInstance.topics.length === 0) { return of(task); }

          return this.topicService.createTopics(
            taskInstance.topics, {tasksIds: [task.id]}
          )
            .pipe(
              map(() => task)
            );
        })
      );
  }

  @warmUpObservable
  update(taskInstance: Task): Observable<Task> {
    return this.http.put<{ task: Task, success: boolean }>(
      environment.baseUrl + '/api/tasks/' + taskInstance.id, taskInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ task, success }) => {
          if (success) {
            this.taskUpdated.next();
            this.asyncTasksToastsService.show({ text: 'Task updated' });
          }
        }),
        map(({ task }) => task)
      );
  }

  @warmUpObservable
  reorder(
    sectionId: string,
    tasksIds: string[],
    projectId?: string,
    columnId?: string,
    parentId?: string,
  ): Observable<Task[]> {
    return this.http.put<{ tasks: Task[], success: boolean }>(
      environment.baseUrl + '/api/tasks',
      {
        saveOrder: !columnId,
        saveBoardOrder: !!columnId,
        sectionId: sectionId || null,
        projectId,
        columnId: columnId || null,
        parentId: parentId || null
      },
      { params: { 'tasks_ids[]': tasksIds }}
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.taskUpdated.next();
          }
        }),
        map(({ tasks }) => tasks.map(task => new Task(task)))
      );
  }

  @warmUpObservable
  pin(tasksIds: string[], status: boolean): Observable<Task[]> {
    return forkJoin(
      tasksIds.map(taskId => this.http.put<{ task: Task, success: boolean }>
        (environment.baseUrl + '/api/tasks/' + taskId, { pinned: status === true })
      )
    )
      .pipe(
        map((results: { task: Task, success: boolean }[]) => results.map(result => result.task)),
        tap(success => {
          if (success) {
            this.taskUpdated.next();
            this.asyncTasksToastsService.show(
              {text: `Task${tasksIds.length > 1 ? 's' : ''} ${tasksIds.length > 1 ? 'are' : 'is'} ${ status ? '' : 'un'}pinned`}
            );
          }
        })
      );
  }

  @warmUpObservable
  archive(tasksIds: string[], archived: boolean): Observable<Task[]> {
    return forkJoin(
      tasksIds.map(taskId => this.http.put<{ task: Task, success: boolean }>
        (environment.baseUrl + '/api/tasks/' + taskId, { archived: archived === true, deleted: false })
      )
    )
      .pipe(
        map((results: { task: Task, success: boolean }[]) => results.map(result => result.task)),
        tap(success => {
          if (success) {
            this.taskUpdated.next();
            this.asyncTasksToastsService.show({ text: `Task(s) ${ archived ? 'moved to' : 'restored from'} archive.` });
          }
        })
      );
  }

  @warmUpObservable
  deletePermanently(tasksIds: string[]): Observable<boolean> {
    return forkJoin(
      tasksIds.map(taskId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/tasks/' + taskId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.taskUpdated.next();
            this.asyncTasksToastsService.show({ text: `Task(s) successfully deleted.` });
          }
        })
      );
  }

  @warmUpObservable
  delete(tasksIds: string[], deleted: boolean): Observable<boolean> {
    return forkJoin(
      tasksIds.map(taskId => this.http.put<{ task: Task, success: boolean }>
        (environment.baseUrl + '/api/tasks/' + taskId, { deleted: deleted === true, archived: false })
      )
    )
      .pipe(
        map((results: { task: Task, success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            this.taskUpdated.next();
            this.asyncTasksToastsService.show({ text: `Task(s) ${ deleted ? 'moved to' : 'restored from' } trash.` });
          }
        })
      );
  }

  upsert(taskForm: FormGroup): Observable<Task> {
    const task = Task.fromFormGroup(taskForm);

    return taskForm.get('id').value ?
      this.update(task) : this.create(task);
  }
}
