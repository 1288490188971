// Common
import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, animate, style } from '@angular/animations';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({
      height: '10px',
      opacity: 0,
    }),
    animate(`.5s ease-in-out`,
      style({
        height: '{{height}}',
        opacity: 1,
      })
    )
  ]),
  transition('expanded => collapsed', [
    style({
      height: '{{height}}',
      opacity: 1,
    }),
    animate(`.5s ease-in-out`,
      style({
        height: '10px',
        opacity: 0,
      })
    )
  ])
]);

@Component({
  selector: 'app-form-collapseable-group',
  templateUrl: './collapseable-group.component.html',
  styleUrls: ['./collapseable-group.component.less', '../../styles/input.less'],
  animations: [collapseMotion],
})
export class CollapseableGroupComponent {

  // Public
  public collapsed = false;
  public contentHeight = '0px';

  // Inputs
  @Input() title: string;
  @Input() icon: string;
  @Input() headerTitleTemplate: TemplateRef<any>;
  @Input() headerRightIconsTemplate: TemplateRef<any>;
  @Input() extendIconEnabled = false;
  @Input() extendIcon: string;
  @Input() extendIconSelected = false;

  // Outputs
  @Output() extendIconAction = new EventEmitter();

  // View Children
  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Actions
   */

  toggle() {
    this.contentHeight = this.contentContainer.nativeElement.offsetHeight + 'px';
    this.collapsed = !this.collapsed;
  }

  handleExtendIcon($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.extendIconAction.emit();
  }
}
