<div class="linked-info-popover">
  <div class="header" [class.shadow]="showHeaderShadow">
    <div class="title">Emails</div>
    <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  </div>
  <div class="list" #scrollList>
    <app-message
      *ngFor="let message of items"
      [message]="message"
      (selectMessage)="select(message)"
      [actionsButtonEnabled]="false"
      [threadEnabled]="false">
    </app-message>
    <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  </div>
</div>
