// Common
import {
  Input,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Optional
} from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ContentMenuService } from '../../services/content-menu.service';

export class ContentMenuBaseItemComponent implements OnDestroy {

  @Input() secondLevel = false;

  // Public
  public headerHeight = 20;
  public root = false;
  public minimized: boolean;

  // Private
  protected alive: Subject<void> = new Subject();

  // Outputs
  @Output() heightChange = new EventEmitter<number>();

  /**
   * Constructor
   */

  constructor (
    protected changeDetector: ChangeDetectorRef,
    @Optional() contentMenuService: ContentMenuService
  ) {
    if (!contentMenuService) { return; }

    contentMenuService.getMinimized()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });
  }

  /**
   * Component lifecycle
   */


  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setRoot(root: boolean) {
    this.root = root;
    this.detectChanges();
  }

  detectChanges() {
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }
}
