// Common
import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

// Types
import { Topic } from '@modules/topic/types/topic';
import { Tag } from '@modules/tag/types/tag';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { CalendarContact } from '@modules/events/types/calendar-contact';
import { Attachment } from '@modules/form-controls/types/attachment';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Project } from '@modules/tasks/types/project';
import { Contact } from '@modules/contacts/types/contact';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Group {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  groupId?: string;
  name?: string;
  avatar?: string;
  email?: string;
  phoneNumber?: string;
  description: string;
  topics?: Topic[];
  updatedAt?: Date;
  createdAt?: Date;
  tags?: Tag[];
  pinned?: boolean;
  starred?: boolean;
  archived?: boolean;
  deleted?: boolean;
  participants?: CalendarContact[];
  linkedInfo?: LinkedInfo[];
  files?: Attachment[];

  constructor(contactObject: any = {}) {
    this.id = contactObject.id;
    this.groupId = contactObject.groupId;
    this.name = contactObject.name;
    this.avatar = contactObject.avatar;
    this.email = contactObject.email;
    this.phoneNumber = contactObject.phoneNumber;
    this.description = contactObject.description;
    this.topics = contactObject.topics;
    this.updatedAt = contactObject.updatedAt;
    this.createdAt = contactObject.createdAt;
    this.tags = contactObject.tags;
    this.pinned = contactObject.pinned;
    this.starred = contactObject.starred;
    this.archived = contactObject.archived;
    this.deleted = contactObject.deleted;
    this.linkedInfo = contactObject.linkedInfo;
    this.participants = contactObject.participants || [];
  }

  static fromFormGroup(form: FormGroup): Group {
    const formValues = form.value;

    return new Group({
      id: formValues.id,
      groupId: formValues.groupId,
      name: formValues.name,
      avatar: formValues.avatar,
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
      topics: formValues.topics,
      updatedAt: formValues.updatedAt,
      createdAt: formValues.createdAt,
      tags: formValues.tags,
      pinned: formValues.pinned,
      starred: formValues.starred,
      archived: formValues.archived,
      deleted: formValues.deleted,
      linkedInfo: formValues.linkedInfo,
    });
  }

  static fromMailMessage(message?: MailMessage): Group {
    const group = new Group();

    if (message) {
      group.name = message.subject || '';
      group.description = message.bodyText || '';
      group.participants = message.participants || [];
      group.topics = message.topics || [];
      group.tags = message.tags || [];
      group.linkedInfo = message.id ? [new LinkedInfo('message', message.id)] : [];
    }

    return group;
  }

  static fromEvent(event?: CalendarEvent): Group {
    const group = new Group();

    if (event) {
      group.name = event.title || '';
      group.topics = event.topics || [];
      group.tags = event.tags || [];
      group.linkedInfo = event.id ? [new LinkedInfo('event', event.id)] : [];
    }

    return group;
  }

  static fromProject(project?: Project): Group {
    const group = new Group();

    if (project) {
      group.name = project.title || '';
      group.description = project.description || '';
      group.topics = project.topics || [];
      group.tags = project.tags || [];
      group.linkedInfo = project.id ? [new LinkedInfo('project', project.id)] : [];
    }

    return group;
  }

  static fromTask(task?: Task): Group {
    const group = new Group();

    if (task) {
      group.name = task.title || '';
      group.description = task.description || '';
      group.participants = task.participants || [];
      group.topics = task.topics || [];
      group.tags = task.tags || [];
      group.linkedInfo = task.id ? [new LinkedInfo('task', task.id)] : [];
    }

    return group;
  }

  static fromNote(note?: Note): Group {
    const group = new Group();

    if (note) {
      group.name = note.title || '';
      group.topics = note.topics || [];
      group.tags = note.tags || [];
      group.linkedInfo = note.id ? [new LinkedInfo('note', note.id)] : [];
    }

    return group;
  }


  static fromGroup(originalGroup?: Group): Group {
    if (originalGroup) {
      const group = new Group({...originalGroup});
      group.linkedInfo = originalGroup.id ? [new LinkedInfo('group', originalGroup.id)] : [];
      return group;
    } else {
      return new Group();
    }
  }

  static fromContact(contact?: Contact): Group {
    const group = new Group();

    if (contact) {
      group.name = contact.name || '';
      group.topics = contact.topics || [];
      group.tags = contact.tags || [];
      group.linkedInfo = contact.id ? [new LinkedInfo('group', contact.id)] : [];
    }

    return group;
  }

  static fromDragData(dragData: DragData): Group {
    let group: Group = null;
    const dragItem = dragData.data[0];

    switch (dragData.type) {
      case 'message':
        group = Group.fromMailMessage(<MailMessage>dragItem);
        break;
      case 'event':
        group = Group.fromEvent(<CalendarEvent>dragItem);
        break;
      case 'project':
        group = Group.fromProject(<Project>dragItem);
        break;
      case 'task':
        group = Group.fromTask(<Task>dragItem);
        break;
      case 'note':
        group = Group.fromNote(<Note>dragItem);
        break;
      case 'group':
        group = Group.fromGroup(<Group>dragItem);
        break;
      case 'contact':
        group = Group.fromContact(<Contact>dragItem);
        break;
    }

    return group;
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      groupId: [this.groupId],
      name: [this.name],
      avatar: [this.avatar],
      email: [this.email],
      phoneNumber: [this.phoneNumber],
      topics: [this.topics],
      updatedAt: [this.updatedAt],
      createdAt: [this.createdAt],
      tags: [this.tags],
      pinned: [this.pinned],
      starred: [this.starred],
      archived: [this.archived],
      deleted: [this.deleted],
      linkedInfo: [this.linkedInfo],
      social: ['']
    });
  }

  asPayloadJSON() {
    return {
      groupId: this.groupId,
      name: this.name,
      avatar: this.avatar,
      email: this.email,
      phoneNumber: this.phoneNumber,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      pinned: this.pinned,
      starred: this.starred,
      archived: this.archived,
      deleted: this.deleted,
    };
  }
}
