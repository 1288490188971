// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { ListState } from '@modules/contacts/types/list-state';
import { ContactsSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { SearchService } from '@modules/search/services/search.service';
import { StateService } from '@modules/settings/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-contacts-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.less'],
})
export class ContactsListContainerComponent implements OnInit, OnDestroy {

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public currentSidebarFilter: ContactsSidebarFilterKey;
  public selectedSidebarGroup: Group;
  public quickFormExpanded = false;
  public listFilters: ContactsFilters | GroupsFilters;
  public loading: Boolean;
  public orders: DropdownOption[] = [
    {name: 'Recency', key: 'recency'},
    {name: 'Date', key: 'date'},
    {name: 'Title', key: 'title'},
  ];
  public scrollOptions = [
    {name: 'Scroll to top', key: 'scroll-top'},
    {name: 'Scroll to bottom', key: 'scroll-bottom'}
  ];
  public quickFormGroup: Group = new Group();
  public quickFormContact: Contact = new Contact();
  public quickFormToggle: Subject<void> = new Subject();

  // Private
  private alive: Subject<void> = new Subject();
  private searchTitle = new BehaviorSubject('');

  /**
   * Constructor
   */

  constructor(
    private splitViewService: SplitViewService,
    private contactsStateService: ContactsStateService,
    private searchService: SearchService,
    private stateService: StateService,
  ) {
    combineLatest([
      this.searchService.getSearchParams(),
      this.searchTitle,
      this.contactsStateService.getSidebarFilters()
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([searchParams, title, sidebarFilters]) => {
        const keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];

        this.listFilters = {
          ...searchParams,
          ...sidebarFilters,
          keywords: !title || title.trim() === '' ? keywords : [...keywords, title]
        };
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('contactsList')
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.contactsStateService.getListState()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => this.listState = state);

    this.contactsStateService.getContactsFilters()
      .pipe(takeUntil(this.alive))
      .subscribe((filters: ContactsFilters) => this.listFilters = filters);

    this.stateService.getSidebarFilters('contacts')
      .pipe(takeUntil(this.alive))
      .subscribe((currentSidebarFilter: ContactsSidebarFilterKey) => this.currentSidebarFilter = currentSidebarFilter);

    this.contactsStateService.getGroup()
      .pipe(takeUntil(this.alive))
      .subscribe((group: Group) => this.selectedSidebarGroup = group);

    this.selectedOrder = this.stateService.sortContactsList;
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleScrollOptionChange(scrollOption: 'scroll-top' | 'scroll-bottom'): void {
    this.listScrollPosition = null;
    setTimeout(() => this.listScrollPosition = scrollOption === 'scroll-top' ? 0 : -1);
  }

  handleSelectOrder(order: DropdownOption): void {
    this.selectedOrder = order;
    this.stateService.sortContactsList = order;
  }

  handleNewContact(contact = new Contact()) {
    this.contactsStateService.openContactForm(contact);
  }

  handleNewGroup(group = new Group()) {
    this.contactsStateService.openGroupForm(group);
  }

  handleSearchQueryChange(title: string) {
    this.searchTitle.next(title);
  }

  handleGroupQuickFormDrop(dragData: DragData) {
    const group = Group.fromDragData(dragData);

    if (!group) { return; }

    this.quickFormGroup = group;
    this.quickFormToggle.next();
  }

  handleContactQuickFormDrop(dragData: DragData) {
    const contact = Contact.fromDragData(dragData);

    if (!contact) { return; }

    this.quickFormContact = contact;
    this.quickFormToggle.next();
  }
}
