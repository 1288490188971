// Common
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

// RxJS
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

/// Services
import { SearchService } from '@modules/search/services/search.service';
import { MessagesListStateService, MESSAGES_LIST_STORE_KEY } from '@modules/mail/services/messages-list-state.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { SearchQueryParams } from '@modules/search/types/search-query-params';
import { Recipient } from '@modules/mail/types/recipient';

interface InsightsFilters {
  folder?: string;
  topics?: string[];
  tags?: string[];
  contacts?: string[];
  keywords?: string[];
  fromTime?: Date;
  toTime?: Date;
  relatedTopics?: string[];
  relatedContacts?: Recipient[];
}

@Component({
  selector: 'app-insights-layout',
  templateUrl: './insights-layout.component.html',
  styleUrls: ['./insights-layout.component.less'],
  providers: [
    { provide: MESSAGES_LIST_STORE_KEY, useValue: 'InsightsMessagesList' },
    MessagesListStateService
  ]
})
export class InsightsLayoutComponent implements OnInit, OnDestroy {

  // Public
  public selectedMessages: MailMessage[] = [];
  public filters: BehaviorSubject<InsightsFilters> = new BehaviorSubject(null);

  // Private
  private alive: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private messagesListStateService: MessagesListStateService
  ) {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => params.get('folder') !== 'search' ? params.get('folder') : null),
        startWith(<string>null),
        takeUntil(this.alive)
      )
      .subscribe(folder => this.updateFilters({ folder }));

    this.searchService.getSearchParams()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(searchParams => this.updateFilters({
        topics: searchParams ? searchParams.topics : null,
        tags: searchParams ? searchParams.tags : null,
        contacts: searchParams ? searchParams.contacts : null,
        keywords: searchParams ? searchParams.keywords : null,
      }));
  }

  // Component Lifecycle
  ngOnInit() {
    this.filters
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(filters => this.messagesListStateService.setFilters({
        ...filters,
        groupByThread: true,
        orderWithPinned: true
      }));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  // Actions
  updateFilters(filters: InsightsFilters) {
    this.filters.next({
      ...(this.filters.value || {}),
      ...filters
    });
  }

}
