// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { PushNotificationsService } from './services/push-notifications.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [PushNotificationsService]
})
export class NotificationModule { }
