import * as tslib_1 from "tslib";
import { CalendarEventTitleFormatter } from 'angular-calendar';
var HideTooltipEventTitleFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(HideTooltipEventTitleFormatter, _super);
    function HideTooltipEventTitleFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HideTooltipEventTitleFormatter.prototype.monthTooltip = function (event) {
        return;
    };
    HideTooltipEventTitleFormatter.prototype.weekTooltip = function (event) {
        return;
    };
    HideTooltipEventTitleFormatter.prototype.dayTooltip = function (event) {
        return;
    };
    return HideTooltipEventTitleFormatter;
}(CalendarEventTitleFormatter));
export { HideTooltipEventTitleFormatter };
