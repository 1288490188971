// Common
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

// Services
import { SplitViewService } from '../services/split-view.service';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[split-view-toggle]',
})
export class SplitViewToggleDirective implements AfterViewInit, OnDestroy {

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Inputs
  @Input('split-view-toggle') splitViewKey: string;

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private splitViewService: SplitViewService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit(): void {
    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => this.toggleClick());
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  toggleClick() {
    this.splitViewService.toggleClicked(this.splitViewKey);
  }
}
