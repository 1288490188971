<div
  class="quick-form"
  [class.collapsed]="!expanded"
  [@collapseMotion]="{value: expanded ? 'expanded' : 'collapsed', params: {height: formHeight}}"
>
  <div
    class="quick-form-button"
    appDroppable
    appDroppableHoverClass="drag-over"
    (appDroppableDrop)="handleDrop($event)"
  >
    <mat-icon svgIcon="plus-medium" class="quick-form-button-icon" *ngIf="!expanded" (click)="handleMore()"></mat-icon>
    <mat-icon svgIcon="close" class="quick-form-button-close-icon" *ngIf="expanded" (click)="handleClose()"></mat-icon>
    <input
      [formControl]="titleControl"
      class="quick-form-button-input"
      [class.active]="expanded"
      [placeholder]="placeholder"
      [bundledProviderGroup]="bundledGroup"
      [bundledProvider]="['title', 'startDate', 'endDate', 'startTime', 'endTime']"
    />
  </div>
  <div #formContainer>
    <ng-content *ngIf="currentFormSelector === 'event'" select="app-event-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'task'" select="app-task-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'project'" select="app-project-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'note'" select="app-note-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'contact'" select="app-contact-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'group'" select="app-group-quick-form"></ng-content>
  </div>
</div>
