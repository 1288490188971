// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Project } from '@modules/tasks/types/project';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';

@Component({
  selector: 'app-project-quick-form',
  templateUrl: './project-quick-form.component.html',
  styleUrls: ['./project-quick-form.component.less'],
})
export class ProjectQuickFormComponent implements OnChanges {

  // Inputs
  @Input() bundledGroup: string;
  @Input() project: Project = new Project();

  // Outputs
  @Output() more: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() close: EventEmitter<void> = new EventEmitter();

  // Public
  public projectForm: FormGroup;
  public saveInProgress = false;

  // Private
  private componentNotDestroyed = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private projectsService: ProjectsService
  ) {
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('project' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.projectForm.invalid) {
      return;
    }
    this.saveInProgress = true;

    this.projectsService.upsert(this.projectForm)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(
        () => this.close.emit(),
        () => this.handleError()
      );
  }

  reset() {
    if (!this.project) { return; }

    this.projectForm = this.project.asFormGroup();
    this.saveInProgress = false;
  }

  handleMoreOptions() {
    this.more.emit(Project.fromFormGroup(this.projectForm));
    this.close.emit();
  }

  public handleClickCancel() {
    this.close.emit();
  }

  private handleError() {
    this.saveInProgress = false;
  }
}
