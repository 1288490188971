// Common
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { MailService } from '@modules/mail/services/mail.service';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-reminder-date-time-picker-modal',
  templateUrl: './reminder-date-time-picker-modal.component.html',
  styleUrls: ['./reminder-date-time-picker-modal.component.less']
})
export class ReminderDateTimePickerModalComponent extends BaseModalComponent {

  // Inputs
  @Input() message: MailMessage;
  @Input() reminderType: 'followup'|'snooze';

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 140px)',
    y: 'calc(50% - 85px)',
    width: '280px',
    height: '170px'
  };

  // Public
  public date = new FormControl();
  public time = new FormControl();

  /**
   * Constructor
   */

  constructor(
    private mailService: MailService,
    private ga: GoogleAnalyticsService
  ) {
    super();
  }

  /**
   * Actions
   */

  saveReminder() {
    if (!this.date.value || !this.time.value) {
      // @TODO: add error notification
      return;
    }

    const dateTime = new Date(
      this.date.value.getFullYear(), this.date.value.getMonth(), this.date.value.getDate(),
      this.time.value.getHours(), this.time.value.getMinutes()
    );

    return this.reminderType === 'followup' ? this.saveFollowup(dateTime) : this.saveSnooze(dateTime);
  }

  saveFollowup(dateTime: Date) {
    this.ga.trackEvent('message-context-menu', 'follow-up');
    this.mailService.followMessage('custom', this.message.id, dateTime)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.message.followed = true;
        this.close();
      });
  }

  saveSnooze(dateTime: Date) {
    this.ga.trackEvent('message-context-menu', 'snooze');
    this.mailService.snoozeMessage('custom', this.message.id, dateTime)
      .pipe(
        filter(success => success),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.message.snoozed = true;
        this.close();
      });
  }
}
