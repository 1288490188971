<div
  class="file-form"
  appDroppable
  appDroppableHoverClass="drag-over"
>
  <div class="drop-area-border"></div>
  <div class="file-form-actions" *ngIf="file?.id">
    <mat-icon
      svgIcon="saved"
      class="file-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="pin"
      class="file-form-actions-button"
      [ngClass]="{active: file?.pinned}"
      [matTooltip]="file?.pinned ? 'Unpin' : 'Pin'"
    ></mat-icon>

    <mat-icon
      svgIcon="download-new"
      class="file-form-actions-button"
      matTooltip="Coming soon"
    ></mat-icon>

    <mat-icon
      svgIcon="archive"
      class="file-form-actions-button"
      [ngClass]="{active: file?.archived}"
      [matTooltip]="file?.archived ? 'Remove From Archive' : 'Add To Archive'"
    ></mat-icon>

    <mat-icon
      *ngIf="!file?.readOnly"
      svgIcon="trash-bucket"
      class="file-form-actions-button"
      matTooltip="Delete"
    ></mat-icon>

    <mat-icon
      svgIcon="more-actions"
      class="file-form-actions-button"
      matTooltip="More Options"
    ></mat-icon>
  </div>

  <div class="file-form-related" *ngIf="file?.id">
    <div class="file-form-related-buttons">
      <div class="file-form-related-button" (click)="openAttachmentsDynamicPanel()">
        <div class="file-form-related-button-inner">
          <mat-icon  class="file-form-related-button-inner-icon" svgIcon="attachment-small"></mat-icon>
        </div>
      </div>
      <div class="file-form-related-button" (click)="openLinkedInfoDynamicPanel()">
        <div class="file-form-related-button-inner">
          <mat-icon  class="file-form-related-button-inner-icon" svgIcon="link-thin"></mat-icon>
        </div>
      </div>
    </div>

    <mat-icon
      appDraggable
      class="file-form-related-drag-icon"
      svgIcon="drag-icon-small"
    ></mat-icon>
  </div>

  <div class="file-form-fixed-header" *ngIf="appearance === 'headerFixedBig'">
    <div class="file-form-fixed-header-columns">
      <div class="file-form-fixed-header-columns-input">
        <app-input
          placeholder="Title"
          [inputFormControl]="fileForm.controls['title']"
          appearance="standard"
          icon="file"
          [leftIcon]="file.type + '-new'"
        ></app-input>
      </div>
      <div class="file-form-fixed-header-columns-buttons">
        <app-button class="file-form-fixed-header-columns-buttons-item" appearance="blue">Save</app-button>
        <app-button class="file-form-fixed-header-columns-buttons-item" (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>

    <div class="file-form-fixed-header-columns">
      <mat-tab-group [selectedIndex]="3">
        <mat-tab label="File" disabled><ng-template matTabContent></ng-template></mat-tab>
        <mat-tab label="Details" disabled><ng-template matTabContent></ng-template></mat-tab>
        <mat-tab label="Related Info" disabled><ng-template matTabContent></ng-template></mat-tab>
        <mat-tab label="Linked Info">
          <ng-container *ngTemplateOutlet="linkedInfoTabTemplate"></ng-container>
        </mat-tab>
        <mat-tab label="Activity" disabled><ng-template matTabContent></ng-template></mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #linkedInfoTabTemplate matTabContent>
  <div class="shadow"></div>
  <app-linked-info-list
    class="linked-info"
    [linkedInfo]="linkedInfo"
    [collapseble]="true"
  ></app-linked-info-list>
</ng-template>
