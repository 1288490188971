<app-dropdown [closeOnSelect]="true">
  <ng-template #toggleButtonTemplate>
    <div class="dropdown-title-wrapper">
      <div class="dropdown-title">{{ folder?.name }}</div>
      <mat-icon svgIcon="dropdown-small" class="dropdown-icon"></mat-icon>
    </div>
  </ng-template>
  <app-dropdown-single-select
    [options]="defaultFolders"
    [selected]="{name: folder?.name, key: folder?.id}"
    (selectedChange)="select($event)"
    [divider]="true"
  ></app-dropdown-single-select>
  <app-dropdown-nested name="Folders">
    <app-dropdown-single-select
      [options]="userFolders"
      [selected]="{name: folder?.name, key: folder?.id}"
      (selectedChange)="select($event)"
    ></app-dropdown-single-select>
  </app-dropdown-nested>
</app-dropdown>