/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes-list-container.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notes-list-header/notes-list-header.component.ngfactory";
import * as i3 from "../notes-list-header/notes-list-header.component";
import * as i4 from "../../../settings/services/state.service";
import * as i5 from "../../../quick-forms/components/quick-form/quick-form.component.ngfactory";
import * as i6 from "../../../quick-forms/components/quick-form/quick-form.component";
import * as i7 from "../../../quick-forms/components/note-quick-form/note-quick-form.component.ngfactory";
import * as i8 from "../../../quick-forms/components/note-quick-form/note-quick-form.component";
import * as i9 from "../../services/notes.service";
import * as i10 from "@angular/common";
import * as i11 from "../notes-list/notes-list.component.ngfactory";
import * as i12 from "../notes-list/notes-list.component";
import * as i13 from "../../../linked-info/services/linked-info.service";
import * as i14 from "../../../modal/services/modal.service";
import * as i15 from "./notes-list-container.component";
import * as i16 from "../../../split-view/services/split-view.service";
import * as i17 from "../../../search/services/search.service";
var styles_NotesListContainerComponent = [i0.styles];
var RenderType_NotesListContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesListContainerComponent, data: {} });
export { RenderType_NotesListContainerComponent as RenderType_NotesListContainerComponent };
function View_NotesListContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-notes-list-header", [], null, [[null, "selectedOrderChange"], [null, "scrollOptionChange"], [null, "searchQueryChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOrderChange" === en)) {
        var pd_0 = ((_co.selectedOrder = $event) !== false);
        ad = (pd_0 && ad);
    } if (("scrollOptionChange" === en)) {
        var pd_1 = (_co.handleScrollOptionChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("searchQueryChange" === en)) {
        var pd_2 = (_co.handleSearchQueryChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NotesListHeaderComponent_0, i2.RenderType_NotesListHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.NotesListHeaderComponent, [i4.StateService], { loading: [0, "loading"] }, { selectedOrderChange: "selectedOrderChange", scrollOptionChange: "scrollOptionChange", searchQueryChange: "searchQueryChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "shadow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotesListContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "container-quick-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "app-quick-form", [["bundledGroup", "notesListQuickForm"], ["placeholder", "Add New Note"]], null, [[null, "collapsedChange"], [null, "clickMore"], [null, "itemDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("collapsedChange" === en)) {
        var pd_0 = ((_co.quickFormExpanded = $event) !== false);
        ad = (pd_0 && ad);
    } if (("clickMore" === en)) {
        var pd_1 = (_co.handleNewNote() !== false);
        ad = (pd_1 && ad);
    } if (("itemDropped" === en)) {
        var pd_2 = (_co.handleQuickFormDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_QuickFormComponent_0, i5.RenderType_QuickFormComponent)), i1.ɵdid(2, 4964352, null, 6, i6.QuickFormComponent, [], { placeholder: [0, "placeholder"], bundledGroup: [1, "bundledGroup"], toggle: [2, "toggle"] }, { collapsedChange: "collapsedChange", clickMore: "clickMore", itemDropped: "itemDropped" }), i1.ɵqud(335544320, 1, { eventForm: 0 }), i1.ɵqud(335544320, 2, { projectForm: 0 }), i1.ɵqud(335544320, 3, { taskForm: 0 }), i1.ɵqud(335544320, 4, { noteForm: 0 }), i1.ɵqud(335544320, 5, { contactForm: 0 }), i1.ɵqud(335544320, 6, { groupForm: 0 }), (_l()(), i1.ɵeld(9, 0, null, 3, 1, "app-note-quick-form", [["bundledGroup", "notesListQuickForm"]], null, [[null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("more" === en)) {
        var pd_0 = (_co.handleNewNote($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_NoteQuickFormComponent_0, i7.RenderType_NoteQuickFormComponent)), i1.ɵdid(10, 573440, [[4, 4]], 0, i8.NoteQuickFormComponent, [i9.NotesService], { bundledGroup: [0, "bundledGroup"], note: [1, "note"] }, { more: "more" }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "shadow"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { hidden: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Add New Note"; var currVal_1 = "notesListQuickForm"; var currVal_2 = _co.quickFormToggle; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "notesListQuickForm"; var currVal_4 = _co.quickFormNote; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = "shadow"; var currVal_6 = _ck(_v, 14, 0, !_co.quickFormExpanded); _ck(_v, 13, 0, currVal_5, currVal_6); }, null); }
function View_NotesListContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-notes-list", [], null, [[null, "selectedItemsChanged"], [null, "loadInProgress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItemsChanged" === en)) {
        var pd_0 = (_co.selectItems($event) !== false);
        ad = (pd_0 && ad);
    } if (("loadInProgress" === en)) {
        var pd_1 = ((_co.loading = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_NotesListComponent_0, i11.RenderType_NotesListComponent)), i1.ɵdid(2, 770048, null, 0, i12.NotesListComponent, [i9.NotesService, i13.LinkedInfoService, i14.ModalService, i1.NgZone], { selectedOrder: [0, "selectedOrder"], scrollPosition: [1, "scrollPosition"], defaultFilters: [2, "defaultFilters"], selectedItems: [3, "selectedItems"] }, { loadInProgress: "loadInProgress", selectedItemsChanged: "selectedItemsChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedOrder; var currVal_1 = _co.listScrollPosition; var currVal_2 = _co.listFilters; var currVal_3 = _co.selectedItems; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_NotesListContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { expanded: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesListContainerComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesListContainerComponent_2)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesListContainerComponent_3)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container"; var currVal_1 = _ck(_v, 3, 0, !_co.minimized); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.minimized; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.minimized; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.minimized; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_NotesListContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notes-list-container", [], null, null, null, View_NotesListContainerComponent_0, RenderType_NotesListContainerComponent)), i1.ɵdid(1, 770048, null, 0, i15.NotesListContainerComponent, [i16.SplitViewService, i17.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesListContainerComponentNgFactory = i1.ɵccf("app-notes-list-container", i15.NotesListContainerComponent, View_NotesListContainerComponent_Host_0, { notesFilters: "notesFilters", selectedItems: "selectedItems" }, { selectedItemsChanged: "selectedItemsChanged" }, []);
export { NotesListContainerComponentNgFactory as NotesListContainerComponentNgFactory };
