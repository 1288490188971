// Common
import {
  Component, ViewChild, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy,
  OnDestroy, AfterViewInit, NgZone,
} from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// Components
import { SplitComponent } from 'angular-split';

// Rx
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Types
import { SplitViewKey } from '@modules/settings/types/split-view-state';

@Component({
  selector: 'app-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitViewComponent implements OnInit, OnDestroy, AfterViewInit {

  // Inputs
  @Input() collapseableSide: 'left' | 'right';
  @Input() key: SplitViewKey;
  @Input() minWidth: number;
  @Input() magneticWidth: number;
  @Input() icon: string;
  @Input() shadow = false;

  // View Children
  @ViewChild('splitElement', { static: true }) splitElement: SplitComponent;

  // Public
  public dragging = false;
  public minimized: boolean;
  public currentSize: number;

  // Private
  private lastSize: number;
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    private splitViewService: SplitViewService,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.stateService.getSplitState(this.key)
      .pipe(
        takeUntil(this.componentNotDestroyed),
        distinctUntilChanged(),
      )
      .subscribe((value: {current: number, last: number}) => {
        this.currentSize = value.current;
        this.lastSize = value.last;
        this.minimized = this.isMinimized();
        this.changeDetector.detectChanges();

        this.splitViewService.setMinimized(this.key, this.minimized);
      });

    this.splitViewService.getToggleIconClick(this.key)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => this.toggle());
  }

  ngAfterViewInit() {
    this.splitElement.dragProgress$
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((event) => <number>event.sizes[this.collapseableSide === 'left' ? 0 : 1]),
        map((size: number) => this.isMinimized(size)),
        distinctUntilChanged()
      )
      .subscribe((minimized: boolean) => {
        this.ngZone.run(() => {
          this.minimized = minimized;
          this.splitViewService.setMinimized(this.key, minimized);
        });
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  close() {
    this.persistSize(this.minWidth, this.currentSize);
  }

  open() {
    this.persistSize(this.lastSize, this.lastSize);
  }

  toggle() {
    if (this.isMinimized()) {
      this.open();
    } else {
      this.close();
    }
  }

  private persistSize(current: number, last: number) {
    this.stateService.setSplitState(this.key, current, last);
  }

  /**
   * Helpers
   */

  private isMinimized(size: number = this.currentSize): boolean {
    return size <= Math.max(this.minWidth, this.magneticWidth);
  }

  /**
   * Event handlers
   */

  handleDragEnd(sizes: number[]) {
    const size = sizes[this.collapseableSide === 'left' ? 0 : 1];
    const lastSize = this.lastSize;

    // setting currentSize twice. because as-split-area[size] doesn't feel changes
    this.persistSize(size, this.currentSize);

    if (
      this.minWidth &&
      this.magneticWidth &&
      this.magneticWidth > this.minWidth &&
      size < this.magneticWidth &&
      size > this.minWidth
    ) {
      this.persistSize(this.minWidth, lastSize);
    }

    this.dragging = false;
    this.changeDetector.detectChanges();
  }

  handleSplitGutterClick() {
    //  strange behaviour - as-split fires dragStart on gutterClick
    this.dragging = false;

    this.toggle();
  }

  handleDragStart() {
    this.dragging = true;
  }
}
