<div
  *ngIf="task"
  class="task"
  [ngClass]="{selected: selected && !isDragging, dragging: !dragEnabled}"
  (dblclick)="handleDoubleClick()"
  [appTaskContextMenu]="task"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'task'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="task-info">
    <app-circular-progress class="task-info-icon"></app-circular-progress>
    <div class="title">{{ task.title }}</div>

    <div class="date-time">
      <div class="date">
        <mat-icon class="icon" svgIcon="calendar"></mat-icon>
        {{ task.fromTime ? (task.fromTime | date: 'MMM d,yyyy') : 'No selected date'}}
      </div>
      <div class="time">
        <mat-icon class="icon" svgIcon="time"></mat-icon>
        <span *ngIf="!task.fromTime || !task.toTime">No selected time</span>
        <span *ngIf="task.fromTime && task.toTime">
          {{ task.fromTime | date: 'h:mm' }} - {{ task.toTime | date: 'h:mm aaa' | lowercase }}
        </span>
      </div>
    </div>

    <mat-icon class="archive-status-icon" [svgIcon]="task.archived ? 'folder-archive' : 'tasks'"></mat-icon>

    <div class="participants-container" [ngClass]="{'linked-info-offset': task.linkedInfo?.length}">
      <app-contacts-counter-dropdown
        [contactList]="task.participants"
        [size]="16"
      ></app-contacts-counter-dropdown>
    </div>

    <!-- Linked Info -->
    <mat-icon *ngIf="task.linkedInfo?.length" class="link-info-icon" svgIcon="link-thin"></mat-icon>
    <app-link-info-bar *ngIf="task.linkedInfo?.length" [linkedInfo]="task.linkedInfo"></app-link-info-bar>

    <mat-icon class="task-info-pin-icon" svgIcon="pin-direct" *ngIf="task.pinned"></mat-icon>

    <div class="task-info-lines">
      <div class="task-info-lines-item" *ngFor="let line of task.strangeLines" [ngStyle]="{backgroundColor: line.color}"></div>
    </div>

    <!-- Drop zone -->
    <div class="drop-zone"></div>
  </div>
</div>

<!-- Drag placeholder -->
<ng-template #dragPlaceholder>
  <app-task [task]="task" [dragEnabled]="false"></app-task>
</ng-template>

<!-- Task Placeholder -->
<div class="task-placeholder" *ngIf="!task">
  <div class="task-placeholder-icons"></div>
  <div class="task-placeholder-info">
    <div class="task-placeholder-info-title">
      <div class="task-placeholder-info-title-name"></div>
      <div class="task-placeholder-info-title-date"></div>
    </div>
    <div class="task-placeholder-info-subject"></div>
    <div class="task-placeholder-info-subject"></div>
  </div>
</div>
