import { Pipe, PipeTransform } from '@angular/core';
import { addDays, isMonday, isSameDay, isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'checkDate'
})
export class CheckDatePipe implements PipeTransform {

  transform(date: Date, parameter: 'today' | 'beginningOfToday' | 'tomorrow' | 'nextMonday' ): boolean {
    switch (parameter) {
      case 'today':
        return isToday(date);
      case 'beginningOfToday':
        return isToday(date) && date.getHours() === 0 && date.getMinutes() === 0;
      case 'tomorrow':
        return isTomorrow(date);
      case 'nextMonday':
        let delta = 0;
        const today = new Date();
        if (today.getDay() !== 0) {
          delta = 7 - today.getDay();
        }
        delta += 1;
        return isSameDay(date, addDays(today, delta)) && isMonday(date);
      default:
        return false;
    }
  }

}
