import * as tslib_1 from "tslib";
// Common
import { OnDestroy, TemplateRef } from '@angular/core';
// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
// RX
import { switchMap, takeUntil, map, throttleTime } from 'rxjs/operators';
import { combineLatest, interval, merge, Subject, BehaviorSubject } from 'rxjs';
// Environment
import { environment } from '@environment';
var EventCalendarComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function EventCalendarComponent(calendarService, stateService, eventsStateService, searchService, popoverService) {
        var _this = this;
        this.calendarService = calendarService;
        this.stateService = stateService;
        this.eventsStateService = eventsStateService;
        this.searchService = searchService;
        this.popoverService = popoverService;
        // Public
        this.selectedDates = {
            year: new Date(),
            month: new Date(),
            week: new Date(),
            workWeek: new Date(),
            day: new Date(),
            agenda: new Date(),
        };
        this.events = [];
        this.calendarTypes = [
            { name: 'Year', key: 'year' },
            { name: 'Month', key: 'month' },
            { name: 'Week', key: 'week' },
            { name: 'Work Week', key: 'workWeek' },
            { name: 'Day', key: 'day' },
            { name: 'Agenda', key: 'agenda' },
        ];
        this.calendarType = this.calendarTypes[0];
        // Private
        this.componentNotDestroyed = new Subject();
        this.loadingEvents = new Subject();
        this.virtualEvent = new BehaviorSubject(null);
        this.filters = {};
        this.popoverClose = new Subject();
        combineLatest([
            this.stateService.getSelectedCalendars(),
            this.eventsStateService.getSidebarFilters(),
            this.searchService.getSearchParams(),
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), calendarIds = _b[0], sidebarFilters = _b[1], searchParams = _b[2];
            _this.filters = tslib_1.__assign({}, sidebarFilters, searchParams, { calendarIds: calendarIds });
            _this.loadingEvents.next();
        });
        merge(
        // Global refresh button
        this.eventsStateService.getRefreshAll(), interval(environment.messageFetchInterval), this.calendarService.createNewEvent, this.calendarService.updatedEvent, this.calendarService.deletedEvent)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.loadingEvents.next();
        });
        this.stateService.getSelectedCalendarType()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (calendarType) {
            _this.calendarType = _this.calendarTypes.find(function (type) { return type.key === calendarType; });
            _this.refreshHeader();
            _this.loadingEvents.next();
        });
        combineLatest([
            this.stateService.getSelectedCalendarDates('year'),
            this.stateService.getSelectedCalendarDates('month'),
            this.stateService.getSelectedCalendarDates('week'),
            this.stateService.getSelectedCalendarDates('workWeek'),
            this.stateService.getSelectedCalendarDates('day'),
            this.stateService.getSelectedCalendarDates('agenda'),
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 6), year = _b[0], month = _b[1], week = _b[2], workWeek = _b[3], day = _b[4], agenda = _b[5];
            _this.selectedDates = { year: year, month: month, week: week, workWeek: workWeek, day: day, agenda: agenda };
            _this.refreshHeader();
            _this.loadingEvents.next();
            _this.popoverClose.next();
        });
        this.loadingEvents
            .pipe(throttleTime(1000), switchMap(function () { return _this.calendarService.getEvents(tslib_1.__assign({}, _this.filters, { fromDate: _this.beginningOfPeriod(_this.currentCalendarDate()), toDate: _this.endOfPeriod(_this.currentCalendarDate()) })); }), map(function (response) { return response.events; }), switchMap(function (events) { return _this.virtualEvent.pipe(map(function (virtualEvent) { return virtualEvent ? tslib_1.__spread(events, [virtualEvent]) : events; })); }), map(function (events) { return events.map(function (event) { return event.asAngularCalendarEvent(); }); }), takeUntil(this.componentNotDestroyed))
            .subscribe(function (events) { return _this.events = events; });
        this.loadingEvents.next();
        this.eventsStateService.getSelectedEvents()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (events) {
            if (events.length === 1) {
                // as we get all day events with custom hours (depends on timezone) we need to set 0 hour for right scroll
                _this.highlightedDate = events[0].when.durationType === 'day'
                    ? new Date(new Date(events[0].when.start).setHours(0))
                    : events[0].when.start;
            }
            else {
                _this.highlightedDate = null;
            }
        });
        this.eventsStateService.getSelectedCalendarDate()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (date) { return _this.selectedDate = date; });
    }
    /**
     * Component lifecycle
     */
    EventCalendarComponent.prototype.ngOnDestroy = function () {
        this.popoverClose.next();
        this.popoverClose.complete();
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Actions
     */
    EventCalendarComponent.prototype.handleDateClicked = function (cellEvent) {
        this.virtualEvent.next(CalendarEvent.fromCalendarCell(null, cellEvent.date, this.calendarType.key, true, cellEvent.event));
        if (this.calendarType.key === 'year') {
            this.popoverService.create(cellEvent.originRef, {
                content: this.popoverFormTemplate,
                placement: 'right',
                innerShadow: false,
                allowedOutsideSelectors: '.mat-option-text, custom-repeater-dialog, .app-popover-content, .mat-autocomplete-panel, .dropdown',
                arrow: true,
                trigger: 'click',
                showUntil: this.popoverClose
            }, cellEvent.event);
        }
    };
    EventCalendarComponent.prototype.handleDateDblClicked = function (date) {
        switch (this.calendarType.key) {
            case 'year':
                this.selectCalendarType('day');
                this.setDate(date, 'day');
                break;
            case 'month':
            case 'week':
            case 'workWeek':
            case 'day':
                this.eventsStateService.openEventForm(CalendarEvent.fromCalendarCell(null, date, this.calendarType.key, false));
                break;
        }
    };
    EventCalendarComponent.prototype.handleCellDrop = function (event) {
        var calendarAppointment = CalendarEvent.fromDragData(event.dragData);
        if (!calendarAppointment) {
            return;
        }
        this.virtualEvent.next(CalendarEvent.fromCalendarCell(calendarAppointment, event.newStart, this.calendarType.key, true));
    };
    EventCalendarComponent.prototype.handlePopoverFormClose = function () {
        this.popoverClose.next();
        this.virtualEvent.next(null);
    };
    EventCalendarComponent.prototype.handleDayNumberDblClick = function (date) {
        this.selectCalendarType('day');
        this.setDate(date, 'day');
    };
    EventCalendarComponent.prototype.handleOpenInListView = function (day) {
        this.eventsStateService.setScrollToDay(day);
    };
    EventCalendarComponent.prototype.setDate = function (newDate, calendarType) {
        if (calendarType === void 0) { calendarType = this.calendarType.key; }
        this.stateService.setSelectedCalendarDates(calendarType, newDate);
    };
    EventCalendarComponent.prototype.setToday = function () {
        this.eventsStateService.setScrollToDay(new Date());
        this.setDate(new Date());
    };
    EventCalendarComponent.prototype.nextDate = function () {
        this.setDate(this.calculateDate(1));
    };
    EventCalendarComponent.prototype.prevDate = function () {
        this.setDate(this.calculateDate(-1));
    };
    EventCalendarComponent.prototype.calculateDate = function (multiplier) {
        var date = this.currentCalendarDate();
        var newDate = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate(),
        };
        switch (this.calendarType.key) {
            case 'day':
            case 'agenda':
                newDate.day += multiplier;
                break;
            case 'week':
            case 'workWeek':
                newDate.day += 7 * multiplier;
                break;
            case 'month':
                newDate.month += multiplier;
                newDate.day = Math.min(newDate.day, this.daysInMonth(newDate.year, newDate.month + 1));
                break;
            case 'year':
                newDate.year += multiplier;
                break;
        }
        return new Date(newDate.year, newDate.month, newDate.day);
    };
    EventCalendarComponent.prototype.daysInMonth = function (year, month) {
        return new Date(year, month, 0).getDate();
    };
    EventCalendarComponent.prototype.selectCalendarType = function (calendarType) {
        this.stateService.selectedCalendarType = calendarType;
    };
    EventCalendarComponent.prototype.refreshHeader = function () {
        var date = this.currentCalendarDate();
        this.displayHeaderTitle = this.calendarType.key === 'year' ?
            date.getFullYear().toString() :
            date.toLocaleString('en-us', { month: 'long' }) + (" " + date.getFullYear());
    };
    EventCalendarComponent.prototype.setYear = function (year) {
        var date = this.currentCalendarDate();
        this.setDate(new Date(year, date.getMonth(), date.getDate()));
    };
    /**
     * Helpers
     */
    EventCalendarComponent.prototype.currentCalendarDate = function () {
        return this.selectedDates[this.calendarType.key];
    };
    EventCalendarComponent.prototype.beginningOfPeriod = function (date) {
        switch (this.calendarType.key) {
            case 'day':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            case 'week':
            case 'workWeek':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()) - 1, 23, 59, 59, 999);
            case 'month':
                return new Date(date.getFullYear(), date.getMonth(), -5, 0, 0, 0, 0);
            case 'year':
                return new Date(date.getFullYear(), 0, -5, 0, 0, 0, 0);
        }
    };
    EventCalendarComponent.prototype.endOfPeriod = function (date) {
        switch (this.calendarType.key) {
            case 'day':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
            case 'week':
            case 'workWeek':
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()), 0, 0, 0, 0);
            case 'month':
                return new Date(date.getFullYear(), date.getMonth() + 1, 6, 0, 0, 0, 0);
            case 'year':
                return new Date(date.getFullYear() + 1, 0, 6, 0, 0, 0, 0);
        }
    };
    return EventCalendarComponent;
}());
export { EventCalendarComponent };
