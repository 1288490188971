// Common
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { CollapsedStateKey } from '@modules/settings/types/collapsed-state';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { CalendarEvent  as AngularCalendarEvent} from 'calendar-utils';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { Task } from '@modules/tasks/types/task';
import { TasksListResponse } from '@modules/tasks/types/tasks-list-response';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';

// RX
import { Subject, merge, interval } from 'rxjs';
import { takeUntil, debounceTime, switchMap, map, startWith, tap } from 'rxjs/operators';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { StateService } from '@modules/settings/services/state.service';
import { PopoverService } from '@modules/popover/services/popover.service';

// Environment
import { environment } from '@environment';



@Component({
  selector: 'app-tasks-date-picker',
  templateUrl: './tasks-date-picker.component.html',
  styleUrls: ['./tasks-date-picker.component.less'],
})
export class TasksDatePickerComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() disabled = false;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() inline: boolean;
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputAppearance: 'start' | 'end' = 'start';
  @Input() bundledInputConsumerGroup: string;
  @Input() collapsedStateKey: CollapsedStateKey;
  @Input() maxDate: Date;
  @Input() collapseable = true;
  @Input() filters: TasksFilters = {};

  // Public
  public events: AngularCalendarEvent[] = [];

  // Private
  private activeDate: Date = new Date();
  private loadTasks: Subject<void> = new Subject();
  private componentNotDestroyed: Subject<void> = new Subject();
  private popoverClose: Subject<void> = new Subject();

  // View Children
  @ViewChild('popoverFormTemplate', {static: true}) public popoverFormTemplate: TemplateRef<any>;

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService,
    private stateService: StateService,
    private popoverService: PopoverService,
    private tasksStateService: TasksStateService,
  ) {
    this.loadTasks
      .pipe(
        tap(() => this.events = []),
        debounceTime(600),
        startWith(),
        switchMap(() => this.tasksService
          .getTasks({
            fromTime: this.beginningOfMonth(this.activeDate),
            toTime: this.endOfMonth(this.activeDate),
            ...this.filters
          })
          .pipe(
            map((response: TasksListResponse): Task[] => response.tasks),
            map(tasks => tasks.map(task => CalendarEvent.fromTask(task).asAngularCalendarEvent())),
          )
        ),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(taskEvents => this.events = taskEvents);

    merge(
      this.tasksStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.tasksService.taskCreated,
      this.tasksService.taskUpdated,
      this.tasksService.taskDeleted,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.loadTasks.next();
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit(): void {  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleActiveDateChange(date: Date): void {
    if (
      this.activeDate.getFullYear() === date.getFullYear() &&
      this.activeDate.getMonth() === date.getMonth()
    ) {
      return;
    }
    this.activeDate = date;
    this.loadTasks.next();
  }

  handleDrop(dragData: DragData, date: Date, origin: HTMLElement): void {

  }

  handlePopoverFormClose() {
    this.popoverClose.next();
  }

  /**
   * Helpers
   */

  private beginningOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  }

  private endOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
  }
}
