import * as tslib_1 from "tslib";
// Common
import { ComponentRef } from '@angular/core';
// Components
import { SidebarProjectsContextMenuComponent } from '@modules/tasks/components/sidebar-projects-context-menu/sidebar-projects-context-menu.component';
// Directives
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
var SidebarProjectsContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(SidebarProjectsContextMenuDirective, _super);
    function SidebarProjectsContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Inputs
        _this.contextMenuComponent = SidebarProjectsContextMenuComponent;
        return _this;
    }
    SidebarProjectsContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
    };
    return SidebarProjectsContextMenuDirective;
}(ContextMenuDirective));
export { SidebarProjectsContextMenuDirective };
