// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { MatIconModule } from '@angular/material/icon';
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';

// Components
import { QuickFormComponent } from './components/quick-form/quick-form.component';
import { EventQuickFormComponent } from './components/event-quick-form/event-quick-form.component';
import { ProjectQuickFormComponent } from './components/project-quick-form/project-quick-form.component';
import { TaskQuickFormComponent } from './components/task-quick-form/task-quick-form.component';
import { NoteQuickFormComponent } from './components/note-quick-form/note-quick-form.component';
import { ContactQuickFormComponent } from './components/contact-quick-form/contact-quick-form.component';
import { GroupQuickFormComponent } from './components/group-quick-form/group-quick-form.component';

// Pipes
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    BundledInputsModule,
    FormControlsModule,
    DragNDropModule,
    PipesModule
  ],
  declarations: [
    QuickFormComponent,
    EventQuickFormComponent,
    ProjectQuickFormComponent,
    TaskQuickFormComponent,
    NoteQuickFormComponent,
    ContactQuickFormComponent,
    GroupQuickFormComponent,
  ],
  exports: [
    QuickFormComponent,
    EventQuickFormComponent,
    ProjectQuickFormComponent,
    TaskQuickFormComponent,
    NoteQuickFormComponent,
    ContactQuickFormComponent,
    GroupQuickFormComponent,
  ],
})
export class QuickFormsModule { }
