// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ContentMenuModule } from '@modules/content-menu/content-menu.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from '@modules/pipes/pipes.module';
import { ElementsModule } from '../elements/elements.module';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { DropdownModule } from '@modules/dropdown/dropdown.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { EditorModule } from '@tinymce/tinymce-angular';

// Components
import { NoteFormComponent } from './components/note-form/note-form.component';
import { NotesSideBarComponent } from './components/notes-side-bar/notes-side-bar.component';
import { NotesMainViewComponent } from './components/notes-main-view/notes-main-view.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { NotesListContainerComponent } from './components/notes-list-container/notes-list-container.component';
import { NotesListHeaderComponent } from './components/notes-list-header/notes-list-header.component';
import { NoteEditorComponent } from './components/note-editor/note-editor.component';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    ContentMenuModule,
    ElementsModule,
    FormControlsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    ScrollingModule,
    PipesModule,
    QuickFormsModule,
    LinkedInfoModule,
    DropdownModule,
    DragNDropModule,
    EditorModule,
    FormsModule,
  ],
  declarations: [
    NoteFormComponent,
    NotesSideBarComponent,
    NotesMainViewComponent,
    NotesListComponent,
    NotesListContainerComponent,
    NotesListHeaderComponent,
    NoteEditorComponent,
  ],
  exports: [
    NoteFormComponent,
    NotesSideBarComponent,
    NotesMainViewComponent,
    NotesListComponent,
    NotesListContainerComponent,
    NotesListHeaderComponent,
  ],
  providers: [

  ],
})
export class NotesModule { }
