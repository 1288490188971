/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-menu-tag.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "ngx-contextmenu";
import * as i6 from "../../../drag-n-drop/directives/draggable.directive";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "../../../drag-n-drop/services/drag-n-drop.service";
import * as i9 from "../../../drag-n-drop/directives/droppable.directive";
import * as i10 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i11 from "../content-menu-base-item/content-menu-base-item.component";
import * as i12 from "./content-menu-tag.component";
import * as i13 from "../../../tag/services/tag.service";
import * as i14 from "../../../modal/services/modal.service";
import * as i15 from "../../services/content-menu.service";
var styles_ContentMenuTagComponent = [i0.styles];
var RenderType_ContentMenuTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentMenuTagComponent, data: {} });
export { RenderType_ContentMenuTagComponent as RenderType_ContentMenuTagComponent };
function View_ContentMenuTagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "unread-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "mail-unread"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "mail-unread"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ContentMenuTagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "content-menu-tag drag-item"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(4, null, ["#", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content-menu-tag drag-item"; var currVal_1 = _ck(_v, 3, 0, _co.active); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.tag == null) ? null : _co.tag.name); _ck(_v, 4, 0, currVal_2); }); }
function View_ContentMenuTagComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Add New Tag "]))], null, null); }
function View_ContentMenuTagComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Unpin Tag "]))], null, null); }
function View_ContentMenuTagComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Clear All Pinned Tags "]))], null, null); }
function View_ContentMenuTagComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ContentMenuTagComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Manage My Tags... "]))], null, null); }
export function View_ContentMenuTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "content-menu-tag-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["appDraggable", ""], ["appDroppable", ""], ["appDroppableHoverClass", "drag-over"], ["class", "content-menu-tag"]], [[8, "draggable", 0]], [[null, "click"], [null, "appDroppableDrop"], [null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.select($event) !== false);
        ad = (pd_1 && ad);
    } if (("appDroppableDrop" === en)) {
        var pd_2 = (_co.dndDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { active: 0 }), i1.ɵdid(6, 16384, null, 0, i5.ɵa, [i5.ContextMenuService], { contextMenuSubject: [0, "contextMenuSubject"], contextMenu: [1, "contextMenu"] }, null), i1.ɵdid(7, 212992, null, 0, i6.DraggableDirective, [i1.ElementRef, i7.Overlay, i8.DragNDropService, i1.NgZone], { appDraggableData: [0, "appDraggableData"], appDraggablePlaceholder: [1, "appDraggablePlaceholder"] }, null), i1.ɵpad(8, 1), i1.ɵpod(9, { data: 0, type: 1 }), i1.ɵdid(10, 737280, null, 0, i9.DroppableDirective, [i8.DragNDropService, i1.Renderer2, i1.ElementRef, i1.NgZone], { appDroppableHoverClass: [0, "appDroppableHoverClass"], appDroppablePredicate: [1, "appDroppablePredicate"] }, { appDroppableDrop: "appDroppableDrop" }), (_l()(), i1.ɵted(11, null, [" #", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentMenuTagComponent_1)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["dragPlaceholder", 2]], null, 0, null, View_ContentMenuTagComponent_2)), (_l()(), i1.ɵeld(15, 0, null, null, 12, "context-menu", [], null, null, null, i10.View_ContextMenuComponent_0, i10.RenderType_ContextMenuComponent)), i1.ɵdid(16, 180224, [["tagContext", 4]], 1, i5.ContextMenuComponent, [i5.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i5.ɵc]], null, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ContentMenuTagComponent_3)), i1.ɵdid(19, 16384, [[1, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.unpinTag($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContentMenuTagComponent_4)), i1.ɵdid(21, 16384, [[1, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.unpinAllTags() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContentMenuTagComponent_5)), i1.ɵdid(23, 16384, [[1, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ContentMenuTagComponent_6)), i1.ɵdid(25, 16384, [[1, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openTagsModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContentMenuTagComponent_7)), i1.ɵdid(27, 16384, [[1, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "content-menu-tag"; var currVal_2 = _ck(_v, 5, 0, _co.active); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.tag; var currVal_4 = i1.ɵnov(_v, 16); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 9, 0, _ck(_v, 8, 0, _co.tag), "tag"); var currVal_6 = i1.ɵnov(_v, 14); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = "drag-over"; var currVal_8 = _co.dndPredicate; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_10 = (((_co.tag == null) ? null : _co.tag.unreadCount) > 0); _ck(_v, 13, 0, currVal_10); var currVal_11 = "true"; _ck(_v, 25, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).appDraggableEnabled; _ck(_v, 2, 0, currVal_0); var currVal_9 = ((_co.tag == null) ? null : _co.tag.name); _ck(_v, 11, 0, currVal_9); }); }
export function View_ContentMenuTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-content-menu-tag", [], null, null, null, View_ContentMenuTagComponent_0, RenderType_ContentMenuTagComponent)), i1.ɵprd(6144, null, i11.ContentMenuBaseItemComponent, null, [i12.ContentMenuTagComponent]), i1.ɵdid(2, 180224, null, 0, i12.ContentMenuTagComponent, [i1.ChangeDetectorRef, i13.TagService, i14.ModalService, [2, i15.ContentMenuService]], null, null)], null, null); }
var ContentMenuTagComponentNgFactory = i1.ɵccf("app-content-menu-tag", i12.ContentMenuTagComponent, View_ContentMenuTagComponent_Host_0, { secondLevel: "secondLevel", tag: "tag", active: "active" }, { heightChange: "heightChange", selected: "selected" }, []);
export { ContentMenuTagComponentNgFactory as ContentMenuTagComponentNgFactory };
