// Common
import { ComponentRef, Directive, Input } from '@angular/core';
// Directives
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import {
  SidebarTaskContextMenuComponent
} from '@modules/tasks/components/sidebar-task-context-menu/sidebar-task-context-menu.component';
// Types
import { Priority } from '@modules/tasks/types/priority';

@Directive({
  selector: '[appSidebarTaskContextMenu]'
})
export class SidebarTaskContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() contextMenuComponent = SidebarTaskContextMenuComponent;
  @Input() appTaskContextMenuStartDate: Date;
  @Input() appTaskContextMenuPriority: Priority;

  setContextMenuInstanceData(componentRef: ComponentRef<SidebarTaskContextMenuComponent>) {
    componentRef.instance.startDate = this.appTaskContextMenuStartDate;
    componentRef.instance.priority = this.appTaskContextMenuPriority;
  }
}
