<div class="form-container" [ngSwitch]="formType">
  <app-event-form
    *ngSwitchCase="'event'"
    appearance="headerFixedSmall"
    (close)="handleCancel()"
    [event]="formItem"
  ></app-event-form>

  <app-task-form
    *ngSwitchCase="'task'"
    appearance="headerFixedSmall"
    (close)="handleCancel()"
    [task]="formItem"
  ></app-task-form>

  <app-project-form-container
    *ngSwitchCase="'project'"
    appearance="headerFixedSmall"
    (close)="handleCancel()"
    [project]="formItem"
  ></app-project-form-container>

  <app-note-form
    *ngSwitchCase="'note'"
    appearance="headerFixedSmall"
    (close)="handleCancel()"
    [note]="formItem"
  ></app-note-form>
</div>
