// RX
import { Observable, Subject } from 'rxjs';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';

export class FileUpload {
  file: File;
  attachment: Attachment;
  status: 'uploading' | 'uploaded' | 'error';
  error?: string;
  request?: Observable<Attachment>;
  cancel?: Subject<void>;
}
