<div 
  *ngIf="view === 'empty'"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-tasks.svg"/>
</div>

<app-task-form 
  *ngIf="view === 'task-form'"
  [task]="task"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-task-form>

<app-project-form-container
  *ngIf="view === 'project-form'"
  [project]="project"
  (close)="handleFormClose()"
  appearance="headerFixedBig"
></app-project-form-container>
