// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// RxJS
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { Hyperlink } from '@modules/mail/types/hyperlink';
import { MailMessage } from '@modules/mail/types/mail-message';

@Component({
  selector: 'app-related-hyperlinks',
  templateUrl: './related-hyperlinks.component.html',
  styleUrls: ['./related-hyperlinks.component.less']
})
export class RelatedHyperlinksComponent implements OnInit {

  // Inputs
  @Input() hyperlinks: Hyperlink[];
  @Input() totalCount: number;
  @Input() loading = false;
  @Input() error = false;

  // Outputs
  @Output() loadMore = new EventEmitter();

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  openLink(link: Hyperlink) {
    window.open(link.url, '_blank');
  }

  forwardLink(link: Hyperlink) {
    const message = new MailMessage();
    message.body = `<a href="${link.url}">${link.url}</a>`;
    this.modalService.compose(message);
  }

  copyLink(link: Hyperlink, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const url = link.url;
    fromEvent(document, 'copy')
      .pipe(take(1))
      .subscribe((clipboard: ClipboardEvent) => {
        clipboard.clipboardData.setData('text/plain', url);
        clipboard.preventDefault();
      });
    document.execCommand('copy');
  }

  onLoadMore() {
    this.loadMore.emit();
  }

}
