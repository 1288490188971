// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-content-menu-divider',
  templateUrl: './content-menu-divider.component.html',
  styleUrls: ['./content-menu-divider.component.less']
})
export class ContentMenuDividerComponent {

  /**
   * Constructor
   */

  constructor() {

  }
}
