// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

// Modules
import { AngularSplitModule } from 'angular-split';
import { MatIconModule } from '@angular/material/icon';

// Components
import { SplitViewComponent } from './components/split-view/split-view.component';
import { SplitViewToggleDirective } from './directives/split-view-toggle.directive';
import { SplitViewService } from './services/split-view.service';

// Services

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AngularSplitModule,
    MatIconModule,
  ],
  exports: [
    SplitViewComponent,
    SplitViewToggleDirective,
  ],
  declarations: [
    SplitViewComponent,
    SplitViewToggleDirective,
  ],
  providers: [
    SplitViewService,
  ],
  entryComponents: [],
})
export class AppSplitViewModule { }
