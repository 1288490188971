<div class="agenda" [@dateAnimation]="viewDate">
  <div class="agenda-header" appEventsAgendaContextMenu>
    <app-knowledge-header
      class="agenda-header-content"
      height="38px"
      [orders]="orderOptions"
      [selected]="selectedOrder"
      [search]="true"
      [titleTemplate]="headerTitleTemplate"
      (selectedChange)="selectOrder($event)"
      (searchChange)="handleSearchChange($event)"
    ></app-knowledge-header>
    <div class="shadow"></div>
  </div>
  
  <div class="agenda-quick-form">
    <app-quick-form
      placeholder="Add Event, Task or Reminder"
      (clickMore)="handleNewEvent()"
      [smart]="true"
      bundledGroup="eventsAgendaQuickForm"
    >
      <app-event-quick-form
        bundledGroup="eventsAgendaQuickForm"
        (more)="handleNewEvent($event)"
      ></app-event-quick-form>
      <app-task-quick-form
        bundledGroup="eventsAgendaQuickForm"
        (more)="handleNewTask($event)"
      ></app-task-quick-form>
      <app-note-quick-form
        bundledGroup="eventsAgendaQuickForm"
        (more)="handleNewNote($event)"
      ></app-note-quick-form>
      <app-project-quick-form
        bundledGroup="eventsAgendaQuickForm"
        (more)="handleNewProject($event)"
      ></app-project-quick-form>
    </app-quick-form>
  </div>

  <div class="agenda-scroll">
    <div class="agenda-list">
      <app-events-agenda-event *ngFor="let event of events" [event]="event"></app-events-agenda-event>
    </div>
  </div>
</div>

<ng-template #headerTitleTemplate>
  <div class="header-title" (click)="handleHeaderTitleClick()">
    <div class="header-title-day-number">
      {{ viewDate | date: 'd' }}
    </div>
    <div class="header-title-day">
      {{ viewDate | date: 'EEEE' }}
    </div>
  </div>
</ng-template>
