/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-event-form-popup.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../events/components/event-form/event-form.component.ngfactory";
import * as i3 from "../../../events/components/event-form/event-form.component";
import * as i4 from "../../../events/services/calendar.service";
import * as i5 from "../../../modal/services/modal.service";
import * as i6 from "../../../events/services/events-state.service";
import * as i7 from "../../../pages/services/dynamic-panel.service";
import * as i8 from "./calendar-event-form-popup.component";
import * as i9 from "@angular/router";
var styles_CalendarEventFormPopupComponent = [i0.styles];
var RenderType_CalendarEventFormPopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CalendarEventFormPopupComponent, data: {} });
export { RenderType_CalendarEventFormPopupComponent as RenderType_CalendarEventFormPopupComponent };
export function View_CalendarEventFormPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-event-form", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EventFormComponent_0, i2.RenderType_EventFormComponent)), i1.ɵdid(2, 704512, null, 0, i3.EventFormComponent, [i4.CalendarService, i5.ModalService, i6.EventsStateService, i7.DynamicPanelService], { event: [0, "event"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; var currVal_1 = "headerFixedBig"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarEventFormPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-event-form-popup", [], null, null, null, View_CalendarEventFormPopupComponent_0, RenderType_CalendarEventFormPopupComponent)), i1.ɵdid(1, 180224, null, 0, i8.CalendarEventFormPopupComponent, [i9.ActivatedRoute, i4.CalendarService], null, null)], null, null); }
var CalendarEventFormPopupComponentNgFactory = i1.ɵccf("app-calendar-event-form-popup", i8.CalendarEventFormPopupComponent, View_CalendarEventFormPopupComponent_Host_0, {}, {}, []);
export { CalendarEventFormPopupComponentNgFactory as CalendarEventFormPopupComponentNgFactory };
