// Common
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../../auth/services/auth.service';


@Component({
  selector: 'app-account-cancel',
  templateUrl: './account-cancel.component.html',
  styleUrls: ['./account-cancel.component.css'],
})
export class AccountCancelComponent implements OnInit, OnDestroy {

  public errorMessage: string;
  public loading: boolean;

  private componentNotDestroyed: Subject<void> = new Subject();

  constructor(private accountService: AccountService,
              private auth: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.loading = false;
    this.errorMessage = '';
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  cancel() {
    this.loading = true;
    this.errorMessage = '';

    if (!confirm('Are you sure? The following action cannot be undone.')) {
      return;
    }

    this.accountService.cancel()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(
        () => {
          this.loading = false;
          this.auth.signOut()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(() => this.router.navigate(['signin']));
        },
        error => {
          this.loading = false;
          this.errorMessage = error.json().error;
        }
    );
  }
}
