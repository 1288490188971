import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// RX
import { tap, takeUntil } from 'rxjs/operators';
var TaskContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaskContextMenuComponent, _super);
    /**
     * Constructor
     */
    function TaskContextMenuComponent(ga, contextMenuService, tasksService, tasksStateService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.ga = ga;
        _this.contextMenuService = contextMenuService;
        _this.tasksService = tasksService;
        _this.tasksStateService = tasksStateService;
        // Public
        _this.selectedTasks = [];
        // Private
        _this.includesInSelected = false;
        return _this;
    }
    /**
     * Lifecycle
     */
    TaskContextMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tasksStateService.getSelectedTasks()
            .pipe(tap(function (tasks) {
            _this.includesInSelected = tasks.length > 1 && tasks.map(function (task) { return task.id; }).includes(_this.task.id);
        }), takeUntil(this.alive))
            .subscribe(function (tasks) {
            _this.selectedTasks = tasks;
        });
    };
    /**
     * Actions
     */
    TaskContextMenuComponent.prototype.pin = function (tasks, pinned) {
        this.ga.trackEvent('task-context-menu', pinned ? 'unpin' : 'pin');
        this.tasksService.pin(tasks.filter(function (task) { return task.pinned === pinned; }).map(function (task) { return task.id; }), !pinned);
    };
    TaskContextMenuComponent.prototype.delete = function (tasks, deleted) {
        this.ga.trackEvent('task-context-menu', deleted ? 'restore-from-trash' : 'delete');
        this.tasksService.delete(tasks.filter(function (task) { return task.deleted === deleted; }).map(function (task) { return task.id; }), !deleted);
    };
    TaskContextMenuComponent.prototype.archive = function (tasks, archived) {
        this.ga.trackEvent('task-context-menu', archived ? 'restore-from-archive' : 'move-to-archive');
        this.tasksService.archive(tasks.filter(function (task) { return task.archived === archived; }).map(function (task) { return task.id; }), !archived);
    };
    TaskContextMenuComponent.prototype.deletePermanently = function () {
        this.ga.trackEvent('task-context-menu', 'delete-permanently');
        this.tasksService.deletePermanently((this.includesInSelected ? this.selectedTasks : [this.task]).map(function (task) { return task.id; }));
    };
    TaskContextMenuComponent.prototype.edit = function () {
        this.ga.trackEvent('task-context-menu', 'edit');
        this.tasksStateService.setMainViewTask(this.selectedTasks[0] || this.task);
        this.tasksStateService.setMainView('task-form');
    };
    return TaskContextMenuComponent;
}(ContextMenuComponent));
export { TaskContextMenuComponent };
