// Common
import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef,
  OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';
import { isEqual } from 'lodash';

// RxJS
import { Subject, timer } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';

// Services
import { ModalService } from '../../../modal/services/modal.service';
import { KnowledgeGraphService, KnowledgeInfoFilters } from '../../../mail/services/knowledge-graph.service';
import { StateService } from './../../../settings/services/state.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { MailService } from '../../../mail/services/mail.service';
import { AccountService } from '../../../account/services/account.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { TasksService } from '../../../tasks/services/tasks.service';
import { MessagesListStateService, MESSAGES_LIST_STORE_KEY } from '@modules/mail/services/messages-list-state.service';

// Types
import { KnowledgeGraphRelatedInfo } from '../../../mail/types/knowledge-graph-related-info';
import { Account } from '../../../account/types/account';
import { MailMessage } from '../../../mail/types/mail-message';
import { Recipient } from '../../../mail/types/recipient';
import { DropdownOption } from '../../../dropdown/types/dropdown-option';
import { Hyperlink } from '../../../mail/types/hyperlink';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { ExtendedAttachment } from './../../../mail/types/extended-attachment';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Task } from '../../../tasks/types/task';
import { Topic } from '@modules/topic/types/topic';

/**
 * Type Aliases
 */
type ContactModeTab = 'all' | 'context';

@Component({
  selector: 'app-mail-panel',
  templateUrl: './mail-panel.component.html',
  styleUrls: ['./mail-panel.component.less'],
  providers: [
    { provide: MESSAGES_LIST_STORE_KEY, useValue: 'KPRelatedMessagesPannel' },
    MessagesListStateService
  ]
})
export class MailPanelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contactView = false;

  private messageValue: MailMessage;
  @Input()
  set message(message: MailMessage) {
    if (this.messageValue !== message) {
      this.messageValue = message;
      this.topicsValue = [];
      this.selectedRelatedTopics = [];
      this.selectedRelatedContacts = [];
      timer(0).subscribe(() => {
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
      });
      if (this.initialized) {
        const contactToSelect = (this.contactView && (message && message.from)) ? message.from : null;
        this.selectContact(contactToSelect);
      }
      this.updateDropdownParticipants();
    }
  }
  get message(): MailMessage {
    return this.messageValue;
  }
  private topicsValue: string[];
  @Input()
  set topics(topics: string[]) {
    if (!isEqual(this.topicsValue, topics)) {
      this.topicsValue = topics || [];
      this.selectedRelatedTopics = [];
      this.selectedRelatedContacts = [];
      timer(0).subscribe(() => {
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
      });
      if (this.initialized && this.mode === 'context') {
        this.contactView = false;
        this.loadRelatedInfo();
        this.stateService.selectedKnowledgePaneContactContext = this.mode;
      }
    }
  }
  get topics(): string[] {
    return this.topicsValue;
  }
  @Input()
  set contact(contact: Recipient) {
    if (this.selectedContact === contact) {
      return;
    }
    if (this.initialized) {
      this.selectContact(contact);
    } else {
      this.selectedContact = contact;
      this.updateDropdownParticipants();
    }
  }
  @Output() contactChange = new EventEmitter<Recipient>();

  @Input() contactOnly = false;
  @Input() temporalExpression: TemporalExpression;
  @Input() selectedRelatedContacts: Recipient[];
  @Output() selectedRelatedContactsChange = new EventEmitter<Recipient[]>();
  @Input() selectedRelatedTopics: string[] = [];
  @Output() selectedRelatedTopicsChange = new EventEmitter<string[]>();
  @Input() baseTopics: Topic[];

  private relatedInfoContainers: {
    topics: ElementRef;
    connections: ElementRef;
  } = {
    topics: null,
    connections: null
  };
  @ViewChild('relatedTopicsContainer', { static: true })
  private set relatedTopicsContainer(container: ElementRef) {
    this.relatedInfoContainers.topics = container;
    this.loadMoreRelatedInfoIfNeeded('topics');
    this.changeDetector.detectChanges();
  }
  @ViewChild('relatedConnectionsContainer', { static: true })
  private set relatedConnectionsContainer(container: ElementRef) {
    this.relatedInfoContainers.connections = container;
    this.loadMoreRelatedInfoIfNeeded('connections');
    this.changeDetector.detectChanges();
  }
  public relatedInfo = new KnowledgeGraphRelatedInfo();
  public orderOptions: {
    messages: DropdownOption[],
    connections: DropdownOption[],
    topics: DropdownOption[],
    files: DropdownOption[],
    hyperlinks: DropdownOption[],
  } = {
    messages: [
      {name: 'Date', key: 'date'},
      {name: 'Subject', key: 'subject'},
      {name: 'Thread', key: 'thread'},
      {name: 'Sender', key: 'sender'},
      {name: 'Attachments', key: 'attachments'}
    ],
    connections: [
      {name: 'Relevance', key: 'relevance'},
      {name: 'A-Z', key: 'name-asc'},
      {name: 'Z-A', key: 'name-desc'},
    ],
    topics: [
      {name: 'Relevance', key: 'relevance'},
      {name: 'A-Z', key: 'name-asc'},
      {name: 'Z-A', key: 'name-desc'},
    ],
    files: [
      {name: 'Relevance', key: 'relevance'},
      {name: 'A-Z', key: 'name-asc'},
      {name: 'Z-A', key: 'name-desc'},
    ],
    hyperlinks: [
      {name: 'Relevance', key: 'relevance'},
      {name: 'A-Z', key: 'name-asc'},
      {name: 'Z-A', key: 'name-desc'},
    ],
  };
  public openNestedDropdown: string;
  public mode: ContactModeTab;
  public selectedContact: Recipient;
  public reletedInfoSelectedTab: number;
  public reletedInfoTabs = ['messages', 'files', 'hyperlinks'];
  public pageSizes = {
    topics: 45,
    connections: 7,
    allFiles: 10,
    images: 9,
    documents: 6,
    videos: 2,
    hyperlinks: 10
  };
  public orders: {
    messages: DropdownOption,
    connections: DropdownOption,
    topics: DropdownOption,
    files: DropdownOption,
    hyperlinks: DropdownOption,
  };
  public pageOffsets = {
    topics: 0,
    connections: 0,
    messages: 0,
    allFiles: 0,
    images: 0,
    documents: 0,
    videos: 0,
    hyperlinks: 0
  };
  public loaders = {
    topics: false,
    topicsTop: false,
    connections: false,
    connectionsTop: false,
    allFiles: false,
    images: false,
    videos: false,
    documents: false,
    hyperlinks: false
  };
  public errors = {
    topics: false,
    topicsTop: false,
    connections: false,
    connectionsTop: false,
    allFiles: false,
    images: false,
    videos: false,
    documents: false,
    hyperlinks: false
  };
  public dropdownParticipants: Recipient[];
  public splitAreaSize = {
    topics: null,
    contacts: null
  };
  public calendarEvent: CalendarEvent;
  public task: Task;
  public relatedMessageTopics: string[];

  // Private
  private initialized = false;
  private componentNotDestroyed: Subject<void> = new Subject();
  private loadingSubjects: {
    topics: Subject<void>,
    connections: Subject<void>,
    messages: Subject<void>,
    allFiles: Subject<void>,
    images: Subject<void>,
    videos: Subject<void>,
    documents: Subject<void>,
    hyperlinks: Subject<void>,

  } = {
    topics: new Subject(),
    connections: new Subject(),
    messages: new Subject(),
    allFiles: new Subject(),
    images: new Subject(),
    videos: new Subject(),
    documents: new Subject(),
    hyperlinks: new Subject(),
  };
  private account: Account;

  constructor(
    private knowledgeGraphService: KnowledgeGraphService,
    private modalService: ModalService,
    private accountService: AccountService,
    private ga: GoogleAnalyticsService,
    private calendarService: CalendarService,
    private changeDetector: ChangeDetectorRef,
    private stateService: StateService,
    private mailService: MailService,
    private tasksService: TasksService,
    private messagesListStateService: MessagesListStateService
  ) {
    this.stateService.getTabsState('kpMails')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((value: number) => {
        this.reletedInfoSelectedTab = value;
      });
    this.mode = this.stateService.selectedKnowledgePaneContactContext as ContactModeTab;
    this.orders = {
      messages: null,
      connections: this.stateService.sortKnowledgePaneRelatedConnections,
      topics: this.stateService.sortKnowledgePaneRelatedTopics,
      files: this.stateService.sortKnowledgePaneRelatedFiles,
      hyperlinks: this.stateService.sortKnowledgePaneRelatedHyperlinks,
    };
  }

  /**
   * Component Lifecycle
   */

  ngOnInit() {
    this.setMessagesListFilters();
    this.messagesListStateService.getFilters()
      .pipe(
        map(filters => filters.order),
        filter(order => !this.orders.messages || order !== this.orders.messages.key),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(order => this.orders.messages =
        this.orderOptions.messages.find(option => option.key === order) ||
        { name: '', key: order }
      );


    // Subscribe for global notification of message moved
    // TODO: change that approach to may be propagate the event with
    // folder message moved from and folder it moved to for proper cache update
    this.mailService.onMoveToFolder
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((message: MailMessage) => {
        if (this.relatedInfo
          && this.relatedInfo.messages
          && this.relatedInfo.messages.data.find(m => m.id === message.id)
          && this.messageValue) {
          // TODO remove when backend will be updating immediately
          setTimeout(() => {
            this.loadRelatedInfo();
          }, 1000);
        }
      });

    if (this.contactOnly) {
      this.mode = 'all';
      this.stateService.selectedKnowledgePaneContactContext = this.mode;
    }
    this.selectedContact = this.selectedContact || null;
    this.selectedRelatedTopics = [];
    this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
    this.initialized = true;
    this.accountService.getAccount()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((account: Account) => {
        this.account = account;
        if (this.message || (this.contactOnly && this.selectedContact)) {
          this.loadRelatedInfo();
        }
      });
    this.setDefaultSplitAreaSize();
    // Show calendar tab when creating a new event from a message
    this.calendarService.createNewEvent
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((event: CalendarEvent) => {
        this.calendarEvent = event;
      });
    // Show tasks tab when creating a new task from a message
    this.tasksService.taskCreated
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((task: Task) => {
        this.task = task;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('baseTopics' in changes) {
      this.selectedRelatedTopics = [];
      this.selectedRelatedContacts = [];
      this.relatedMessageTopics = this.message && this.mode === 'context' && this.baseTopics
        ? this.baseTopics.map(topic => topic.name)
        : null;
      timer(0).subscribe(() => {
        this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
        this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
      });
      this.loadRelatedInfo();
    }
  }

  ngOnDestroy() {
    for (const key of Object.keys(this.loadingSubjects)) {
      this.loadingSubjects[key].next();
      this.loadingSubjects[key].complete();
    }
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Split area size
   * - Set default state
   * - Handle drag changes
   */

  private setDefaultSplitAreaSize() {
    this.splitAreaSize.topics = this.stateService.splitKnowledgePanelTopics;
    this.splitAreaSize.contacts = this.stateService.splitKnowledgePanelContacts;
  }

  onDragEnd(splitSizes: number[]) {
    // Update values
    this.splitAreaSize.topics = splitSizes[0];
    this.splitAreaSize.contacts = splitSizes[1];
    // Save to local storage
    this.stateService.splitKnowledgePanelTopics = this.splitAreaSize.topics;
    this.stateService.splitKnowledgePanelContacts = this.splitAreaSize.contacts;
    this.loadMoreRelatedInfoIfNeeded();
  }

  /**
   * UI intractions actions
   */

  /**
   * Select tab button and change content
   * @param tab Can contain the following values: 'message', 'calendar', 'contacts' or 'tasks'
   */

  switchMode(mode: ContactModeTab) {
    this.mode = mode;
    this.stateService.selectedKnowledgePaneContactContext = mode;
    this.loadRelatedInfo();
  }

  selectSenderContact(contact: Recipient) {
    this.ga.trackEvent('knowledge-pane-action', 'switch-sender-dropdown');
    this.selectContact(contact);
  }

  selectContact(contact: Recipient) {
    if (this.selectedContact !== contact) {
      this.selectedContact = contact;
      this.contactChange.emit(contact);
      this.changeDetector.detectChanges();
    }

    if (this.selectedContact === null) {
      if (!this.contactView) {
        this.updateDropdownParticipants();
        this.mode = 'context';
        this.loadRelatedInfo();
      }
    } else {
      this.updateDropdownParticipants();
      if (!this.message) {
        this.contactOnly = true;
        this.switchMode('all');
      }
      this.loadRelatedInfo();
    }
    this.stateService.selectedKnowledgePaneContactContext = this.mode;
  }

  topicHasChanged() {
    this.ga.trackEvent('knowledge-pane-action', 'select-related-topic');

    this.loadRelatedConnections(false);
    this.loadRelatedFiles('images', false);
    this.loadRelatedFiles('videos', false);
    this.loadRelatedFiles('documents', false);
    this.loadRelatedFiles('all', false);
    this.loadRelatedHyperlinks(false);
    this.setMessagesListFilters();
  }

  deleteTopic(topic: string) {
    this.selectedRelatedTopics = this.selectedRelatedTopics.filter(t => t !== topic);
    this.selectedRelatedContacts = [];
    this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
    this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
  }

  selectTopic(topic: string, event: MouseEvent) {
    if (!event.shiftKey) {
      if (this.selectedRelatedTopics.length === 1 && this.selectedRelatedTopics[0] === topic) {
        this.deleteTopic(topic);
      } else {
        this.selectedRelatedTopics = [topic];
      }
    } else {
      if (this.selectedRelatedTopics.includes(topic)) {
        this.deleteTopic(topic);
      } else {
        this.selectedRelatedTopics.push(topic);
      }
    }
    this.selectedRelatedContacts = [];
    this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
    this.selectedRelatedTopicsChange.emit(this.selectedRelatedTopics);
    this.topicHasChanged();
  }

  getOffsetByFirstSymbol(type: string, firstSymbol: string) {
    switch (type) {
      case 'topics':
        this.knowledgeGraphService.getRelatedTopicsOffset(
          {...this.getFilters(), firstSymbol: firstSymbol},
          this.orders.topics.key
        )
          .subscribe((offset: number) => {
            this.pageOffsets.topics = offset;
            this.loadRelatedTopics(false);
          });
        break;
      case 'connections':
        this.knowledgeGraphService.getRelatedConnectionsOffset(
          {...this.getFilters(), firstSymbol: firstSymbol},
          this.orders.connections.key
        )
          .subscribe((offset: number) => {
            this.pageOffsets.connections = offset;
            this.loadRelatedConnections(false);
          });
        break;
    }
  }

  selectConnection(connection: Recipient, event: MouseEvent) {
    this.ga.trackEvent('knowledge-pane-action', 'select-related-connection');
    if (event.shiftKey || event.ctrlKey) {
      if (this.selectedRelatedContacts.includes(connection)) {
        this.selectedRelatedContacts = this.selectedRelatedContacts.filter(contact => contact !== connection);
      } else {
        this.selectedRelatedContacts.push(connection);
      }
    } else {
      if (this.selectedRelatedContacts.includes(connection)) {
        this.selectedRelatedContacts = [];
      } else {
        this.selectedRelatedContacts = [connection];
      }
    }
    this.selectedRelatedContactsChange.emit(this.selectedRelatedContacts);
    this.loadRelatedFiles('images', false);
    this.loadRelatedFiles('videos', false);
    this.loadRelatedFiles('documents', false);
    this.loadRelatedFiles('all', false);
    this.setMessagesListFilters();
  }

  selectOrder(type: string, order: DropdownOption) {
    this.orders[type] = order;

    switch (type) {
      case 'messages':
        this.messagesListStateService.setFilters({ order: order.key });
        break;
      case 'topics':
        this.stateService.sortKnowledgePaneRelatedTopics = order;
        this.pageOffsets.topics = 0;
        this.loadRelatedTopics(false);
        break;
      case 'connections':
        this.stateService.sortKnowledgePaneRelatedConnections = order;
        this.pageOffsets.connections = 0;
        this.loadRelatedConnections(false);
        break;
      case 'files':
        this.stateService.sortKnowledgePaneRelatedFiles = order;
        this.loadRelatedFiles('all', false);
        break;
      case 'hyperlinks':
        this.stateService.sortKnowledgePaneRelatedHyperlinks = order;
        this.loadRelatedHyperlinks(false);
        break;
    }
  }

  openMessage(message: MailMessage) {
    this.ga.trackEvent('knowledge-pane-action', 'open-email');
    this.modalService.mailContent(message);
  }

  updateDropdownParticipants() {
    if (!this.message) {
      this.dropdownParticipants = null;
      return;
    }
    if (!this.selectedContact) {
      this.dropdownParticipants = this.message.participants;
      return;
    }
    this.dropdownParticipants = this.message.participants.filter(participant => {
      return !((participant.name === this.selectedContact.name) && (participant.email === this.selectedContact.email));
    });
  }

  selectRelatedDataTab(tab: MatTabChangeEvent) {
    this.stateService.setTabsState('kpMails', tab.index);
  }

  /**
   * Set messages filters state method. Required to update filters for messages list and cause update.
   * Duplicate requrests protection present in state service. These calls are really excrsive,
   * but that the most efficient way to do this at the moment unfortunatelly. Can be changed once component refactored
   */

  private setMessagesListFilters() {
    this.messagesListStateService.setFilters({
      orderWithPinned: false,
      groupByThread: false,
      matchingTypes: ['phrase'],
      topics: this.topics,
      relatedTopics: this.selectedRelatedTopics,
      relatedContacts: this.selectedRelatedContacts,
      relatedMessageId: this.message && this.message.id,
      relatedMessageTopics: this.relatedMessageTopics
    });
  }

  /**
   * Load Related info methods
   */
  private getFilters(): KnowledgeInfoFilters {
    const filters: KnowledgeInfoFilters = {
      messageId: this.message && this.mode === 'context' ? this.message.id : null,
      topics:  this.mode === 'context' ? this.topics : null,
      contact: this.selectedContact,
      relatedTopics: this.selectedRelatedTopics,
      relatedContacts: this.selectedRelatedContacts,
      baseTopics: this.message && this.mode === 'context' && this.baseTopics ? this.baseTopics.map(topic => topic.name) : null
    };

    if (!this.message && this.selectedContact && this.mode === 'all' && this.contactView && !this.selectedContact.id) {
      filters.contact = null;
      filters.contacts = [this.selectedContact.email];
    }
    return filters;
  }

  loadRelatedInfo() {
    if (
      !(this.message && this.baseTopics && this.baseTopics.length) &&
      !this.contact &&
      !(this.selectedContact && this.mode === 'all' && this.contactView)
    ) {
      this.relatedInfo = new KnowledgeGraphRelatedInfo();

      // Cancel all ongoing requests
      for (const key of Object.keys(this.loadingSubjects)) {
        this.loadingSubjects[key].next();
      }
      for (const key of Object.keys(this.loaders)) {
        this.loaders[key] = false;
      }
      return;
    }

    this.loadRelatedTopics(false, false);
    this.loadRelatedConnections(false, false);
    this.loadRelatedFiles('all', false);
    this.loadRelatedFiles('images', false);
    this.loadRelatedFiles('documents', false);
    this.loadRelatedFiles('videos', false);
    this.loadRelatedHyperlinks(false);
    this.setMessagesListFilters();
  }

  loadRelatedTopics(more: boolean = true, reverse: boolean = false) {
    if (!more) {
      this.relatedInfo.topics = {data: [], count: 0};
    } else if (
      (this.loaders.topics && !reverse) ||
      (this.loaders.topicsTop && reverse) ||
      (this.pageOffsets.topics + this.relatedInfo.topics.data.length >= this.relatedInfo.topics.count && !reverse && !this.errors.topics) ||
      (this.pageOffsets.topics <= 0 && reverse)) {
      return;
    }

    this.loadingSubjects['topics'].next();

    this.loaders.topics = !reverse;
    this.loaders.topicsTop = reverse;
    this.errors.topics = false;
    this.errors.topicsTop = false;

    this.knowledgeGraphService.getRelatedTopics(
      this.getFilters(),
      this.pageSizes.topics,
      !reverse ?
        this.pageOffsets.topics + this.relatedInfo.topics.data.length :
        this.pageOffsets.topics > this.pageSizes.topics ?
          this.pageOffsets.topics - this.pageSizes.topics : 0 ,
      this.orders.topics.key
    )
    .pipe(
      takeUntil(this.loadingSubjects['topics'])
    )
    .subscribe((topics: {data: string[], count: number}) => {
      this.loaders.topics = false;
      this.loaders.topicsTop = false;
      if (!reverse) {
        this.relatedInfo.topics.data.push(...topics.data);
      } else {
        this.pageOffsets.topics = this.pageOffsets.topics > this.pageSizes.topics ?
          this.pageOffsets.topics - this.pageSizes.topics : 0;
        this.relatedInfo.topics.data.unshift(...topics.data);
      }
      this.relatedInfo.topics.count = topics.count;
    }, () => {
      this.loaders.topics = false;
      this.loaders.topicsTop = false;
      if (!reverse) {
        this.errors.topics = true;
      } else {
        this.errors.topicsTop = true;
      }
    });
  }

  loadRelatedConnections(more: boolean = true, reverse: boolean = false) {
    if (!more) {
      this.relatedInfo.connections = {data: [], count: 0};
    } else if (
      (this.loaders.connections && !reverse) ||
      (this.loaders.connectionsTop && reverse) ||
      (this.pageOffsets.connections + this.relatedInfo.connections.data.length >= this.relatedInfo.connections.count
        && !reverse
        && !this.errors.connections
      ) ||
      (this.pageOffsets.connections <= 0 && reverse)) {
      return;
    }

    this.loadingSubjects['connections'].next();

    this.loaders.connections = !reverse;
    this.loaders.connectionsTop = reverse;

    this.errors.connections = false;
    this.errors.connectionsTop = false;

    const filters = this.getFilters();
    if (this.account) {
      const ignoreContact = new Recipient();
      ignoreContact.email = this.account.email;
      filters.ignoreContact = ignoreContact;
    }

    this.knowledgeGraphService.getRelatedConnections(
      filters,
      this.pageSizes.connections,
      !reverse ?
        this.pageOffsets.connections + this.relatedInfo.connections.data.length :
        this.pageOffsets.connections > this.pageSizes.connections ?
          this.pageOffsets.connections - this.pageSizes.connections : 0 ,
      this.orders.connections.key
    )
    .pipe(
      takeUntil(this.loadingSubjects['connections'])
    )
    .subscribe((connections: { data: Recipient[], count: number }) => {
      this.loaders.connections = false;
      this.loaders.connectionsTop = false;
      if (!reverse) {
        this.relatedInfo.connections.data.push(...connections.data);
      } else {
        this.pageOffsets.connections = this.pageOffsets.connections > this.pageSizes.connections ?
          this.pageOffsets.connections - this.pageSizes.connections : 0;
        this.relatedInfo.connections.data.unshift(...connections.data);
      }
      this.relatedInfo.connections.count = connections.count;
    }, () => {
      this.loaders.connections = false;
      this.loaders.connectionsTop = false;
      if (!reverse) {
        this.errors.connections = true;
      } else {
        this.errors.connectionsTop = true;
      }
    });
  }

  loadRelatedFiles(type: string, more: boolean = true) {
    const loaderName = type === 'all' ? 'allFiles' : type;
    if (!more) {
      this.relatedInfo.files[type] = {data: [], count: 0};
      this.loadingSubjects[loaderName].next();
    }
    this.loaders[loaderName] = true;
    this.errors[loaderName] = false;
    this.knowledgeGraphService.getRelatedFiles(
      type,
      this.getFilters(),
      this.pageSizes[loaderName],
      this.relatedInfo.files[type].data.length,
      this.orders.files.key
     )
     .pipe(
       takeUntil(this.loadingSubjects[loaderName])
     )
     .subscribe((files: {data: ExtendedAttachment[], count: number}) => {
      this.loaders[loaderName] = false;
      this.relatedInfo.files[type].data.push(...files.data);
      this.relatedInfo.files[type].count = files.count;
    }, () => {
      this.loaders[loaderName] = false;
      this.errors[loaderName] = true;
    });
  }

  loadRelatedHyperlinks(more: boolean = true) {
    if (!more) {
      this.relatedInfo.hyperlinks = {data: [], count: 0};
      this.loadingSubjects['hyperlinks'].next();
    }
    this.loaders.hyperlinks = true;
    this.errors.hyperlinks = false;
    this.knowledgeGraphService
      .getRelatedHyperlinks(
        this.getFilters(),
        this.pageSizes.hyperlinks,
        this.relatedInfo.hyperlinks.data.length,
        this.orders.hyperlinks.key
      )
      .pipe(
        takeUntil(this.loadingSubjects['hyperlinks'])
      )
      .subscribe((hyperlinks: {data: Hyperlink[], count: number}) => {
        this.loaders.hyperlinks = false;
        this.relatedInfo.hyperlinks.data.push(...hyperlinks.data);
        this.relatedInfo.hyperlinks.count = hyperlinks.count;
      }, () => {
        this.loaders.hyperlinks = false;
        this.errors.hyperlinks = true;
      });
  }

  loadMoreRelatedInfoIfNeeded(section?: string) {
    if ( (!section || section === 'topics') && this.moreRelatedInfoNeeded('topics')) {
      this.loadRelatedTopics(true);
    }

    if ( (!section || section === 'connections') && this.moreRelatedInfoNeeded('connections')) {
      this.loadRelatedConnections(true);
    }
  }

  moreRelatedInfoNeeded(section: string): boolean {
    return !!(
      section &&
      !this.loaders[section] &&
      this.relatedInfoContainers &&
      this.relatedInfoContainers[section] &&
      this.relatedInfoContainers[section].nativeElement &&
      this.relatedInfoContainers[section].nativeElement.offsetHeight &&
      this.relatedInfoContainers[section].nativeElement.scrollHeight &&
      this.relatedInfoContainers[section].nativeElement.offsetHeight >= this.relatedInfoContainers[section].nativeElement.scrollHeight
    );
  }


}
