<context-menu #contextMenu>
  <ng-template contextMenuItem [enabled]="false">Rename</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Column to the Left</ng-template>
  <ng-template contextMenuItem [enabled]="false">Add Column to the Right</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="false">Cut</ng-template>
  <ng-template contextMenuItem [enabled]="false">Copy</ng-template>
  <ng-template contextMenuItem [enabled]="false">Paste</ng-template>
  <ng-template contextMenuItem [enabled]="false">Duplicate</ng-template>
  <ng-template contextMenuItem [enabled]="!column.uncategorized" (execute)="delete()">
    <div [matTooltipDisabled]="!column.uncategorized" matTooltip="Can't delete uncategorized column">Delete</div>
  </ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [enabled]="false">Open In Window</ng-template>
  <ng-template contextMenuItem [enabled]="false">Copy Link To Column</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [passive]="true">
    <app-color-picker [inputFormControl]="columnFormGroup?.controls['color']"></app-color-picker>
  </ng-template>
</context-menu>
