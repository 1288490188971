// Common
import {
  Component, Input, SimpleChanges, OnChanges, OnDestroy, OnInit, ElementRef, ViewChild, TemplateRef, Output, EventEmitter
} from '@angular/core';
import { isToday } from 'date-fns';

// Types
import { CalendarType } from '@modules/events/types/calendar-type';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { MailMessage } from '@modules/mail/types/mail-message';

// Services
import { KnowledgePanelService } from '@modules/knowledge-panel/services/knowledge-panel.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { PopoverService } from '@modules/popover/services/popover.service';

// RX
import { timer, Subject, fromEvent } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-event-badge',
  templateUrl: './event-badge.component.html',
  styleUrls: ['./event-badge.component.less']
})
export class EventBadgeComponent implements OnInit, OnChanges, OnDestroy {

  // Public
  public currentTimestamp = new Date().getTime();
  public rowsCount: number;
  public eventFinished: boolean;
  public isDragging = false;
  public popoverDisabled = false;
  public linkedInfoSuspect = false;
  public highlighted = false;
  public formPopoverClose = new Subject<void>();

  // Private
  private selectedEventId: string;
  private componentNotDestroyed: Subject<void> = new Subject();
  private isSingleClick = false;

  // Inputs
  @Input() view?: CalendarType;
  @Input() event: CalendarEvent;
  @Input() showTime = false;
  @Input() dragEnabled = true;
  @Input() hoverPlacement: PopoverPlacement = 'right';
  @Input() popoverTrackMouse = false;
  @Input() popoverOffsetX = 0;

  // Outputs
  @Output() popoverFormClose = new EventEmitter();

  // View Children
  @ViewChild('popoverFormTemplate', {static: true}) public popoverFormTemplate: TemplateRef<any>;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => {
    let isSame = false;
    if (dragData.type === 'event' && dragData.data.length === 1) {
      const event = dragData.data[0] as CalendarEvent;
      isSame = event.id === this.event.id;
    }
    return ['event', 'message', 'topic', 'tag'].includes(dragData.type) && !isSame;
  }

  /**
   * Constructor
   */

  constructor(
    private knowledgePanelService: KnowledgePanelService,
    private eventsService: EventsStateService,
    private linkedInfoService: LinkedInfoService,
    private modalService: ModalService,
    private popoverService: PopoverService,
    private elementRef: ElementRef,
  ) {
    this.eventsService.getSelectedEvents()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((events: CalendarEvent[]) => {
        this.selectedEventId = events.length === 1 ? events[0].id : null;
        this.checkSelectedEvent();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    if (this.event && this.event.virtual) {
      this.popoverService.create(
        this.elementRef,
        {
          content: this.popoverFormTemplate,
          placement: this.hoverPlacement,
          innerShadow: false,
          allowedOutsideSelectors: '.mat-option-text, custom-repeater-dialog, .app-popover-content, .mat-autocomplete-panel, .dropdown',
          arrow: true,
          trigger: 'click',
          showUntil: this.formPopoverClose
        },
        this.event.originEvent
      );
    }

    fromEvent(this.elementRef.nativeElement, 'mousedown')
      .pipe(
        tap(() => this.isSingleClick = true),
        switchMap(() => timer(700)),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => this.isSingleClick = false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      this.rowsCount = this.calculateRows();
      this.eventFinished = (
        this.event.when.end.getTime() < this.currentTimestamp &&
        !(this.event.when.durationType === 'day' && isToday(this.event.when.end))
      );
      this.checkSelectedEvent();
    }
  }

  ngOnDestroy() {
    this.formPopoverClose.next();
    this.formPopoverClose.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleClick() {
    if (this.event.virtual || !this.isSingleClick || this.isDragging) {
      return;
    }
    this.knowledgePanelService.setFormItem(this.event);
  }

  handleDoubleClick() {
    if (this.event.virtual) {
      return;
    }
    this.modalService.calendarEventForm(this.event);
  }

  handleDrop(dragData: DragData) {
    if (!dragData.data) {
      return;
    }

    let linkedItems = null;

    switch (dragData.type) {
      case 'message':
        linkedItems = (dragData.data as MailMessage[]).map(message => new LinkedInfo('message', message.id));
        break;
      case 'event':
        linkedItems = (dragData.data as CalendarEvent[]).map(event => new LinkedInfo('event', event.id));
        break;
    }

    if (linkedItems) {
      this.linkedInfoService.linkToItem(new LinkedInfo('event', this.event.id), linkedItems);
      this.popoverDisabled = true;
      this.linkedInfoSuspect = true;
    }

    timer(3000)
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.popoverDisabled = false;
      });
  }

  handleDraggingChanged(isDragging) {
    this.isDragging = isDragging;
    if (isDragging) {
      this.formPopoverClose.next();
    }
  }

  handlePopoverFormClose() {
    this.formPopoverClose.next();
    if (!this.isDragging) {
      this.popoverFormClose.emit();
    }
  }

  /**
   * Helpers
   */

  calculateRows() {
    let rows = 1;

    if (
      ['day', 'week', 'workWeek'].includes(this.view) &&
      this.event.when.start &&
      this.event.when.end &&
      this.event.when.durationType !== 'day'
    ) {
      const quater = 1000 * 60 * 15;
      const diff = this.event.when.end.getTime() - this.event.when.start.getTime();
      rows = Math.min(4, Math.floor(diff / quater)); /* Calculate how many segments can take*/
    }
    return Math.max(rows, 1);
  }

  checkSelectedEvent() {
    this.highlighted = this.event && this.event.id === this.selectedEventId;
  }
}
