// Common
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

// Types
import { Contact } from '@modules/contacts/types/contact';

@Component({
  selector: 'app-conntact-context-menu',
  templateUrl: './contact-context-menu.component.html',
  styleUrls: ['./contact-context-menu.component.less']
})
export class ContactContextMenuComponent extends ContextMenuComponent implements OnInit {

  // Inputs
  @Input() contact: Contact;

  // Public
  public contactForm: FormGroup;
  public contactTitleFormControl = new FormControl();

  /**
   * Constructor
   */

  constructor (
    protected contextMenuService: ContextMenuService,
    private contactsStateService: ContactsStateService,
    private ga: GoogleAnalyticsService,
    private contactsService: ContactsService,
  ) {
    super(contextMenuService);
  }

  /**
   * Component lifecycle
   */

  public ngOnInit() {
    this.contactForm = this.contact.asFormGroup();
  }

  /**
   * Actions
   */

  handleEdit() {
    this.contactsStateService.setMainViewContact(this.contact);
    this.contactsStateService.setMainView('contact-form');
  }

  pin(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'pin');
    this.contactsService.pin(contacts.filter(contact => contact.pinned !== true).map(contact => contact.id), true);
  }

  unpin(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'unpin');
    this.contactsService.pin(contacts.filter(contact => contact.pinned === true).map(contact => contact.id), false);
  }

  delete(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'delete');
    this.contactsService.delete(contacts.filter(contact => contact.deleted !== true).map(contact => contact.id), true);
  }

  deletePermanently(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'delete-permanently');
    this.contactsService.deletePermanently(
      contacts.filter(contact => contact.deleted === true).map(contact => contact.id));
  }

  restoreFromTrash(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'restore-from-trash');
    this.contactsService.delete(contacts.filter(contact => contact.deleted === true).map(contact => contact.id), false);
  }

  moveToArchive(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'move-to-archive');
    this.contactsService.archive(
      contacts
        .filter(contact => contact.archived === false)
        .map(contact => contact.id),
      true
    );
  }

  restoreFromArchive(contacts: Contact[]) {
    this.ga.trackEvent('contact-context-menu', 'restore-from-archive');
    this.contactsService.archive(
      contacts
        .filter(contact => contact.archived === true)
        .map(contact => contact.id),
      false
    );
  }

  public handleRenameSubmit() {
    this.contactForm.patchValue({ title: this.contactTitleFormControl.value });
    this.contactsService.update(Contact.fromFormGroup(this.contactForm));
    this.closeContextMenu();
  }

  public handleRenameCancel() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }

  public closeContextMenu() {
    this.contextMenuService.closeAllContextMenus({eventType: 'cancel'});
  }
}
