// Common
import { Component, Input } from '@angular/core';

// Types
import { CalendarEvent as AngularCalendarEvent } from 'angular-calendar';

@Component({
  selector: 'events-quick-preview',
  templateUrl: './events-quick-preview.component.html',
  styleUrls: ['./events-quick-preview.component.less']
})

export class EventQuickPreviewComponent {

  // Inputs
  @Input() day: Date;
  @Input() events: AngularCalendarEvent[];

}
