// Common
import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Toast } from 'ngx-toastr';

// Types
import { AsyncTaskToastAction } from '../../types/async-task-toast-action';

@Component({
  selector: 'app-async-task-toast',
  templateUrl: './async-task-toast.component.html',
  styleUrls: ['./async-task-toast.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
})
export class AsyncTaskToastComponent extends Toast {

  // Public
  public actions: AsyncTaskToastAction[];
  public icon: string;

  /**
   * Methods to init data from service
   */
  setActions(actions: AsyncTaskToastAction[]) {
    this.actions = actions;
  }

  setIcon(icon: string) {
    this.icon = icon;
  }

  /**
   * Actions
   */
  action(action: AsyncTaskToastAction, event: Event) {
    event.stopPropagation();
    action.handler();
    this.toastPackage.triggerAction();
    this.remove();
  }

}
