// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// Services
import { PopupService } from '../../../popup/services/popup.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { ModalFrame } from '@modules/modal/types/modal-frame';

@Component({
  selector: 'app-calendar-event-form-modal',
  templateUrl: './calendar-event-form-modal.component.html',
  styleUrls: ['./calendar-event-form-modal.component.less']
})
export class CalendarEventFormModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() event: CalendarEvent;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(50% - 259px)',
    y: '5vh',
    width: '518px',
    height: '90vh'
  };

  /**
   * Constructor
   */

  constructor(
    protected element: ElementRef,
    protected popupService: PopupService,
    protected ga: GoogleAnalyticsService,
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  expand() {
    this.ga.trackEvent('calendar-event-form-modal', 'expand');
    this.popupService.eventContent(this.event.id, this.maximizedSize.popupSize());
    this.close();
  }
}
