// Common
import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// Components
import { TopicsComponent } from '@modules/topic/components/topics/topics.component';

// Types
import { Topic } from '@modules/topic/types/topic';

// RX
import { Subject } from 'rxjs';
import { takeUntil, startWith, switchMap, tap } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { TopicService } from '@modules/topic/services/topic.service';

@Component({
  selector: 'app-event-topics',
  templateUrl: '../../../../topic/components/topics/topics.component.html',
  styleUrls: ['../../../../topic/components/topics/topics.component.less', './event-topics.component.less']
})
export class EventTopicsComponent extends TopicsComponent implements OnChanges, OnDestroy {

  // Private
  private controlChanges = new Subject<void>();

  // Inputs
  @Input() eventId: string;
  @Input() topicsControl: FormControl;


  /**
   * Constructor
   */

  constructor (
    modalService: ModalService,
    private topicsService: TopicService
  ) {
    super(modalService);

    this.controlChanges
      .pipe(
        switchMap(() =>
          this.topicsControl.valueChanges
            .pipe(startWith((this.topicsControl && this.topicsControl.value) || []))
        ),
        takeUntil(this.alive)
      )
      .subscribe((topics: Topic[]) => {
        const previousValue = this.topics;
        this.topics = topics;
        super.ngOnChanges({topics: {previousValue, currentValue: this.topics, firstChange: false, isFirstChange: () => false}});
      });
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('topicsControl' in changes) {
      this.controlChanges.next();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.controlChanges.next();
    this.controlChanges.complete();
  }

  /**
   * Actions
   */

  handleDeleteTopic(index: number) {
    if (this.eventId) {
      this.topicsService.deleteTopics(
        [this.topicsControl.value[index]],
        { eventsIds: [this.eventId] }
      );
    }

    this.topicsControl.setValue(
      this.topicsControl.value.filter((topic, topicIndex) => topicIndex !== index)
    );
  }

  handleAddTopics(topics: Topic[]) {
    if (this.eventId) {
      this.topicsService.createTopics(
        topics,
        {eventsIds: [this.eventId]}
      );
    }

    this.topicsControl.patchValue([
      ...topics,
      ...this.topicsControl.value
    ]);
    this.popoverClose.next();
    this.newTopics = [];
  }
}
