/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-agenda-context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/pipes/snoozed-date.pipe";
import * as i3 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i4 from "ngx-contextmenu";
import * as i5 from "./events-agenda-context-menu.component";
import * as i6 from "../../../modal/services/modal.service";
var styles_EventsAgendaContextMenuComponent = [i0.styles];
var RenderType_EventsAgendaContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsAgendaContextMenuComponent, data: {} });
export { RenderType_EventsAgendaContextMenuComponent as RenderType_EventsAgendaContextMenuComponent };
function View_EventsAgendaContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Add New Item "]))], null, null); }
function View_EventsAgendaContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Option 1"]))], null, null); }
function View_EventsAgendaContextMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Option 2"]))], null, null); }
function View_EventsAgendaContextMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Select All "]))], null, null); }
function View_EventsAgendaContextMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Snooze "]))], null, null); }
function View_EventsAgendaContextMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), "tomorrow")); _ck(_v, 0, 0, currVal_0); }); }
function View_EventsAgendaContextMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), "afterTomorrow")); _ck(_v, 0, 0, currVal_0); }); }
function View_EventsAgendaContextMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), "endOfWeek")); _ck(_v, 0, 0, currVal_0); }); }
function View_EventsAgendaContextMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), "nextWeek")); _ck(_v, 0, 0, currVal_0); }); }
function View_EventsAgendaContextMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EventsAgendaContextMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Custom date..."]))], null, null); }
function View_EventsAgendaContextMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Move To Archive "]))], null, null); }
function View_EventsAgendaContextMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Delete Agenda "]))], null, null); }
function View_EventsAgendaContextMenuComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EventsAgendaContextMenuComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Copy "]))], null, null); }
function View_EventsAgendaContextMenuComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Paste "]))], null, null); }
function View_EventsAgendaContextMenuComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Print "]))], null, null); }
function View_EventsAgendaContextMenuComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EventsAgendaContextMenuComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open In Window "]))], null, null); }
function View_EventsAgendaContextMenuComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Copy Link To Agenda "]))], null, null); }
export function View_EventsAgendaContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SnoozedDatePipe, []), i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 48, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(3, 180224, [[1, 4], ["contextMenu", 4]], 1, i4.ContextMenuComponent, [i4.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i4.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_1)), i1.ɵdid(6, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], { subMenu: [0, "subMenu"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(8, 180224, [["newItemMenu", 4]], 1, i4.ContextMenuComponent, [i4.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i4.ɵc]], null, null), i1.ɵqud(603979776, 3, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_2)), i1.ɵdid(11, 16384, [[3, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_3)), i1.ɵdid(13, 16384, [[3, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.selectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_4)), i1.ɵdid(15, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_5)), i1.ɵdid(17, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], { subMenu: [0, "subMenu"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 14, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(19, 180224, [["snoozeMenu", 4]], 1, i4.ContextMenuComponent, [i4.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i4.ɵc]], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.snooze("tomorrow") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_6)), i1.ɵdid(22, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.snooze("afterTomorrow") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_7)), i1.ɵdid(24, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.snooze("endOfWeek") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_8)), i1.ɵdid(26, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.snooze("nextWeek") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_9)), i1.ɵdid(28, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_10)), i1.ɵdid(30, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCustomDateModal("snooze") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_11)), i1.ɵdid(32, 16384, [[4, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.moveToArchive() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_12)), i1.ɵdid(34, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.deleteAgenda() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_13)), i1.ɵdid(36, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_14)), i1.ɵdid(38, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_15)), i1.ɵdid(40, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.paste() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_16)), i1.ɵdid(42, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_17)), i1.ɵdid(44, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_EventsAgendaContextMenuComponent_18)), i1.ɵdid(46, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openInWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_19)), i1.ɵdid(48, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.copyLinkToAgenda() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EventsAgendaContextMenuComponent_20)), i1.ɵdid(50, 16384, [[2, 4]], 0, i4.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 19); _ck(_v, 17, 0, currVal_1); var currVal_2 = "true"; _ck(_v, 30, 0, currVal_2); var currVal_3 = "true"; _ck(_v, 38, 0, currVal_3); var currVal_4 = "true"; _ck(_v, 46, 0, currVal_4); }, null); }
export function View_EventsAgendaContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events-agenda-context-menu", [], null, null, null, View_EventsAgendaContextMenuComponent_0, RenderType_EventsAgendaContextMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i5.EventsAgendaContextMenuComponent, [i4.ContextMenuService, i6.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventsAgendaContextMenuComponentNgFactory = i1.ɵccf("app-events-agenda-context-menu", i5.EventsAgendaContextMenuComponent, View_EventsAgendaContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem" }, { close: "close" }, []);
export { EventsAgendaContextMenuComponentNgFactory as EventsAgendaContextMenuComponentNgFactory };
