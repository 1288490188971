import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
var AccountModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AccountModalComponent, _super);
    /**
     * Constructor
     */
    function AccountModalComponent(router) {
        var _this = _super.call(this) || this;
        _this.router = router;
        // Override
        _this.defaultSize = {
            x: '5vw',
            y: '5vh',
            width: '90vw',
            height: '90vh'
        };
        return _this;
    }
    /**
     * Component lifecycle
     */
    AccountModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    return AccountModalComponent;
}(BaseModalComponent));
export { AccountModalComponent };
