/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./priority-input.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../popover/directives/popover.directive";
import * as i6 from "../../../popover/services/popover.service";
import * as i7 from "@angular/material/tooltip";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./priority-input.component";
var styles_PriorityInputComponent = [i0.styles];
var RenderType_PriorityInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriorityInputComponent, data: {} });
export { RenderType_PriorityInputComponent as RenderType_PriorityInputComponent };
function View_PriorityInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-icon", [["class", "priority-input-flag trigger mat-icon notranslate"], ["matTooltip", "Priority"], ["popover", ""], ["popoverPlacement", "right"], ["popoverTrigger", "click"], ["role", "img"], ["svgIcon", "controls-button-priority"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), i1.ɵdid(4, 4866048, null, 0, i5.PopoverDirective, [i1.ElementRef, i6.PopoverService, i1.NgZone], { popoverContent: [0, "popoverContent"], popoverPlacement: [1, "popoverPlacement"], popoverTrigger: [2, "popoverTrigger"], popoverArrow: [3, "popoverArrow"] }, null), i1.ɵdid(5, 212992, null, 0, i7.MatTooltip, [i8.Overlay, i1.ElementRef, i9.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i10.Platform, i11.AriaDescriber, i11.FocusMonitor, i7.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i7.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "priority-input-flag trigger"; var currVal_3 = ((_co.inputFormControl == null) ? null : _co.inputFormControl.value); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = "controls-button-priority"; _ck(_v, 3, 0, currVal_4); var currVal_5 = i1.ɵnov(_v.parent, 6); var currVal_6 = "right"; var currVal_7 = "click"; var currVal_8 = false; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Priority"; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PriorityInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PriorityInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityInputComponent_3)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PriorityInputComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "priority-input-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_PriorityInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "priority-input-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityInputComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-icon", [["class", "priority-input-flag mat-icon notranslate"], ["role", "img"], ["svgIcon", "controls-button-priority"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick(_v.context.$implicit.priorityName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(9, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "priority-input-container"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.priorityName === ((_co.inputFormControl == null) ? null : _co.inputFormControl.value))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.appearanceType === "expandable"); _ck(_v, 5, 0, currVal_2); var currVal_5 = "priority-input-flag"; var currVal_6 = _v.context.$implicit.priorityName; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = "controls-button-priority"; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 9).inline; var currVal_4 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 6, 0, currVal_3, currVal_4); }); }
function View_PriorityInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "priority-input"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityInputComponent_5)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "priority-input"; var currVal_1 = _co.appearanceType; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.prioritiesList; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PriorityInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "priority-input-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityInputComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriorityInputComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], null, null), (_l()(), i1.ɵand(0, [["prioritiesListTemplate", 2]], null, 0, null, View_PriorityInputComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appearanceType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "expandable"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PriorityInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-priority-input", [], null, null, null, View_PriorityInputComponent_0, RenderType_PriorityInputComponent)), i1.ɵdid(1, 49152, null, 0, i14.PriorityInputComponent, [], null, null)], null, null); }
var PriorityInputComponentNgFactory = i1.ɵccf("app-priority-input", i14.PriorityInputComponent, View_PriorityInputComponent_Host_0, { inputFormControl: "inputFormControl", appearanceType: "appearanceType" }, {}, []);
export { PriorityInputComponentNgFactory as PriorityInputComponentNgFactory };
