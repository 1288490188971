import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Services
import { MailService } from '../../services/mail.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';
var MailSideBarComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function MailSideBarComponent(mailService, router, ga, modalService, route, stateService, splitViewService, contentMenuService) {
        this.mailService = mailService;
        this.router = router;
        this.ga = ga;
        this.modalService = modalService;
        this.route = route;
        this.stateService = stateService;
        this.splitViewService = splitViewService;
        // Inputs
        this.baseRouter = '/mail';
        // Public
        this.loading = false;
        this.folders = [];
        // Private
        this.defaultFolderIds = MailService.defaultFolderIds;
        this.componentNotDestroyed = new Subject();
        this.splitViewService.getMinimized('mailsSidebar')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (minimized) {
            contentMenuService.setMinimized(minimized);
        });
    }
    /**
     * Static methods
     */
    MailSideBarComponent.recursiveFindFoldersTree = function (folders, folderId) {
        var e_1, _a;
        try {
            for (var folders_1 = tslib_1.__values(folders), folders_1_1 = folders_1.next(); !folders_1_1.done; folders_1_1 = folders_1.next()) {
                var folder = folders_1_1.value;
                if (folder.id === folderId) {
                    return [folder];
                }
                if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
                    var foundFolders = this.recursiveFindFoldersTree(folder.subFolders, folderId);
                    if (foundFolders !== undefined) {
                        foundFolders.push(folder);
                        return foundFolders;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (folders_1_1 && !folders_1_1.done && (_a = folders_1.return)) _a.call(folders_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Component lifecycle
     */
    MailSideBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isUserFolderExpanded = this.stateService.isUserFolderExpanded;
        this.userFoldersState = this.stateService.userFoldersState;
        this.route.params
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (params) {
            _this.selectedFolderId = params['folder'];
            if ((_this.selectedFolderId !== undefined) && (_this.folders !== undefined)) {
                _this.openFolderWithSubfolders(_this.selectedFolderId);
                _this.selectedFolder = _this.findFolder(_this.folders, _this.selectedFolderId);
            }
        });
        this.getFolders();
    };
    MailSideBarComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Methods
     */
    MailSideBarComponent.prototype.filterDefaultFolders = function (folders) {
        var _this = this;
        return folders
            .filter(function (item) { return _this.defaultFolderIds.some(function (id) { return item.id === id; }); })
            .sort(function (a, b) { return _this.defaultFolderIds.indexOf(a.id) - _this.defaultFolderIds.indexOf(b.id); });
    };
    MailSideBarComponent.prototype.filterUserFolders = function (folders) {
        var _this = this;
        return folders.filter(function (item) { return !_this.defaultFolderIds.some(function (id) { return item.id === id; }); });
    };
    MailSideBarComponent.prototype.findFolder = function (folders, id) {
        var recursiveFindFolder = (function (folderList) {
            var e_2, _a;
            try {
                for (var folderList_1 = tslib_1.__values(folderList), folderList_1_1 = folderList_1.next(); !folderList_1_1.done; folderList_1_1 = folderList_1.next()) {
                    var folder = folderList_1_1.value;
                    if (folder.id === id) {
                        return folder;
                    }
                    if (folder.hasOwnProperty('subFolders') && folder.subFolders.length > 0) {
                        var result = recursiveFindFolder(folder.subFolders);
                        if (result !== undefined) {
                            return result;
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (folderList_1_1 && !folderList_1_1.done && (_a = folderList_1.return)) _a.call(folderList_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        return recursiveFindFolder(folders);
    };
    MailSideBarComponent.prototype.getFolders = function () {
        var _this = this;
        this.loading = true;
        this.mailService.getFolders()
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (folders) {
            _this.loading = false;
            _this.folders = folders;
            _this.defaultFolders = _this.filterDefaultFolders(_this.folders);
            _this.userFolders = _this.filterUserFolders(_this.folders);
            if (_this.selectedFolderId) {
                _this.openFolderWithSubfolders(_this.selectedFolderId);
                _this.selectedFolder = _this.findFolder(_this.folders, _this.selectedFolderId);
            }
        });
    };
    /**
     * Actions
     */
    MailSideBarComponent.prototype.createFolder = function ($event) {
        if ($event !== undefined) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        this.ga.trackEvent('side-menu', 'create-folder');
        var folderName = prompt('Enter new folder name:');
        if (!folderName) {
            return;
        }
        this.mailService.createFolder(folderName)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe();
    };
    MailSideBarComponent.prototype.openFolder = function (folder, event) {
        event.stopPropagation();
        if (folder.hasOwnProperty('subFolders')) {
            this.stateService.toggleUserFolderState(folder.folderId);
            this.userFoldersState = this.stateService.userFoldersState;
        }
    };
    MailSideBarComponent.prototype.openFolderWithSubfolders = function (folderId) {
        if (this.userFolders === undefined) {
            return;
        }
        var foundFolders = MailSideBarComponent.recursiveFindFoldersTree(this.userFolders, folderId);
        if (foundFolders !== undefined) {
            foundFolders.forEach(function (folder) {
                folder['open'] = true;
                folder.icon = 'folder-open';
            });
        }
    };
    MailSideBarComponent.prototype.renameFolder = function (folder) {
        var folderName = prompt('Enter new folder name:', folder.name);
        if (!folderName || folder.name === folderName) {
            return;
        }
        this.mailService.renameFolder(folder.folderId, folderName)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe();
    };
    MailSideBarComponent.prototype.deleteFolder = function (folder) {
        if (!confirm('Are you sure you want to delete the folder?')) {
            return;
        }
        this.mailService.deleteFolder(folder.folderId)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe();
    };
    MailSideBarComponent.prototype.readAllMessages = function (folder) {
        this.mailService.updateMessagesUnreadStatus({ folder: folder.folderId });
    };
    MailSideBarComponent.prototype.pinFolder = function (folder) {
        this.mailService.pinFolder([folder], !folder.pinned)
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe();
    };
    MailSideBarComponent.prototype.moveMessages = function (messages, folderId) {
        var _this = this;
        switch (folderId) {
            case 'important':
                this.ga.trackEvent('side-menu', 'star');
                break;
            case 'spam':
                this.ga.trackEvent('side-menu', 'move-to-spam');
                break;
            case 'trash':
                this.ga.trackEvent('side-menu', 'delete');
                break;
            default:
                break;
        }
        if (messages.some(function (msg) { return !!msg.threadMessages; })) {
            this.modalService.confirmationModal('Selected message(s) are part of the thread. Would you like to move all messages in the thread?', 'Move Message(s)', 'Yes', 'No', function (withThread) {
                _this.mailService.moveMessages({
                    messages: messages,
                    filters: {
                        threadIds: withThread ? messages.map(function (message) { return message.thread; }) : null
                    }
                }, [folderId]);
            });
        }
        else {
            this.mailService.moveMessages({ messages: messages }, [folderId]);
        }
    };
    MailSideBarComponent.prototype.toggleUserFolder = function () {
        this.stateService.isUserFolderExpanded = !this.stateService.isUserFolderExpanded;
        this.isUserFolderExpanded = this.stateService.isUserFolderExpanded;
    };
    MailSideBarComponent.prototype.openAccountSettings = function () {
        this.modalService.settings();
    };
    MailSideBarComponent.prototype.deleteAll = function (folder) {
        var _this = this;
        this.mailService
            .deleteMessages({ folder: folder.folderId })
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            if (_this.selectedFolder.folderId === folder.folderId) {
                _this.mailService.doRefreshMailList();
            }
        });
    };
    MailSideBarComponent.prototype.openFolderInExploreScreen = function (folder) {
        this.router.navigate(['/insights', folder.id]);
    };
    /**
     * Drag and drop
     */
    MailSideBarComponent.prototype.allowDropFolder = function (_a) {
        var id = _a.id;
        return function () { return !MailService.disallowMoveFolderIds.includes(id); };
    };
    MailSideBarComponent.prototype.dndLeave = function (_a) {
        var id = _a.id;
        if (this.dragHoverFolderId === id) {
            this.dragHoverFolderId = null;
        }
    };
    MailSideBarComponent.prototype.dndEnter = function (dragData, _a) {
        var id = _a.id;
        if (dragData.type === 'message' || dragData.type === 'topic') {
            this.dragHoverFolderId = id;
        }
    };
    MailSideBarComponent.prototype.dndDrop = function (dragData, folder) {
        var _this = this;
        var allowFolder = this.allowDropFolder(folder);
        if (folder.id && allowFolder()) {
            // Message
            if (dragData.type === 'message') {
                var messages = dragData.data;
                this.moveMessages(messages, folder.id);
                this.dragHoverFolderId = null;
            }
            // Topic
            if (dragData.type === 'topic' && dragData.data) {
                var topic_1 = dragData.data[0];
                this.modalService.confirmationModal("Are you sure want to move messages relater to the topic '" + topic_1 + "'?", 'Move Message(s)', 'Yes', 'No', function (confirm) {
                    if (confirm) {
                        _this.mailService.moveMessages({ filters: { topics: [topic_1] } }, [folder.id]);
                    }
                });
            }
        }
    };
    MailSideBarComponent.prototype.foldersTrackByFunction = function (index, folder) {
        return folder.id;
    };
    return MailSideBarComponent;
}());
export { MailSideBarComponent };
