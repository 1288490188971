// Common
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { ContextMenuService } from 'ngx-contextmenu';
import { StateService } from '@modules/settings/services/state.service';
import { PopoverService } from '@modules/popover/services/popover.service';

// Types
import { Calendar } from '@modules/events/types/calendar';

// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';

@Component({
  selector: 'app-calendar-context-menu',
  templateUrl: './calendar-context-menu.component.html',
  styleUrls: ['./calendar-context-menu.component.less']
})
export class CalendarContextMenuComponent extends ContextMenuComponent implements OnDestroy, OnChanges, OnInit {

  // Public
  public calendarForm: FormGroup;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private colorChanges: Subject<void> = new Subject();

  // Inputs
  @Input() calendar: Calendar;
  @Input() originRef: ElementRef;
  @Input() popoverClose: Subject<void>;

  // View Children
  @ViewChild('calendarPopoverOrigin', { static: false }) calendarPopoverOrigin: ElementRef;
  @ViewChild('editCalendarForm', {static: true}) public editCalendarForm: TemplateRef<any>;
  @ViewChild('deleteCalendarForm', {static: true}) public deleteCalendarForm: TemplateRef<any>;

  /**
   * Constructor
   */

  constructor(
    protected contextMenuService: ContextMenuService,
    private calendarService: CalendarService,
    private stateService: StateService,
    private popoverService: PopoverService,
  ) {
    super(contextMenuService);
    this.colorChanges
      .pipe(
        switchMap(() => this.calendarForm.get('color').valueChanges),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((value: string) => {
        this.calendar.color = value;
        this.calendarService.editCalendar(this.calendar);
      });
  }

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  ngOnInit() {
    this.calendarForm = this.calendar.asFormGroup();
    this.colorChanges.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('calendar' in changes) {
      this.calendarForm = this.calendar.asFormGroup();
      this.colorChanges.next();
    }
  }

  /**
   * Actions
   */

  setSelectedCalendars(calendarIds: string[]) {
    this.stateService.selectedCalendars = calendarIds;
  }

  openCalendarForm() {
    this.showPopup(this.editCalendarForm);
  }

  openDeleteForm() {
    this.showPopup(this.deleteCalendarForm);
  }

  handleCalendarPopoverClose() {
    this.popoverClose.next();
  }

  deleteCalendar(submitted: boolean) {
    if (submitted) {
      // TODO: Use calendar api to delete calendar when it will be ready
    }
  }

  /**
   * Helpers
   */

  private showPopup(content: TemplateRef<any>) {
    this.popoverService.create(
      this.originRef,
      {
        placement: 'right',
        arrow: true,
        content: content,
        trigger: 'click',
        allowedOutsideSelectors: '.mat-autocomplete-panel',
        showUntil: this.popoverClose
      }
    );
  }
}
