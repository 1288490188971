/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../styles/dynamic-panel.less.shim.ngstyle";
import * as i1 from "./attachments-dynamic-panel.component.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../common/components/knowledge-header/knowledge-header.component.ngfactory";
import * as i6 from "../../../../common/components/knowledge-header/knowledge-header.component";
import * as i7 from "../../../../attachments/components/attachments-list/attachments-list.component.ngfactory";
import * as i8 from "../../../../attachments/components/attachments-list/attachments-list.component";
import * as i9 from "../../../../modal/services/modal.service";
import * as i10 from "../../../services/dynamic-panel.service";
import * as i11 from "./attachments-dynamic-panel.component";
var styles_AttachmentsDynamicPanelComponent = [i0.styles, i1.styles];
var RenderType_AttachmentsDynamicPanelComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_AttachmentsDynamicPanelComponent, data: {} });
export { RenderType_AttachmentsDynamicPanelComponent as RenderType_AttachmentsDynamicPanelComponent };
function View_AttachmentsDynamicPanelComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "btn-back mat-icon notranslate"], ["role", "img"], ["svgIcon", "arrow-up"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i2.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i2.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Attachments"]))], function (_ck, _v) { var currVal_2 = "arrow-up"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).inline; var currVal_1 = (((i2.ɵnov(_v, 1).color !== "primary") && (i2.ɵnov(_v, 1).color !== "accent")) && (i2.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AttachmentsDynamicPanelComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "dynamic-panel"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "app-knowledge-header", [["height", "45px"]], null, null, null, i5.View_KnowledgeHeaderComponent_0, i5.RenderType_KnowledgeHeaderComponent)), i2.ɵdid(2, 245760, null, 0, i6.KnowledgeHeaderComponent, [], { titleTemplate: [0, "titleTemplate"], orders: [1, "orders"], height: [2, "height"] }, null), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "dynamic-panel__body"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "app-attachments-list", [], null, [[null, "extendAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("extendAction" === en)) {
        var pd_0 = (_co.openInModalWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AttachmentsListComponent_0, i7.RenderType_AttachmentsListComponent)), i2.ɵdid(5, 245760, null, 0, i8.AttachmentsListComponent, [i9.ModalService, i10.DynamicPanelService], { attachments: [0, "attachments"], attachmentsFormControl: [1, "attachmentsFormControl"], dynamicPanel: [2, "dynamicPanel"] }, { extendAction: "extendAction" }), (_l()(), i2.ɵand(0, [["titleTemplate", 2]], null, 0, null, View_AttachmentsDynamicPanelComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵnov(_v, 6); var currVal_1 = _co.orderOptions; var currVal_2 = "45px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.dynamicPanelData.attachments; var currVal_4 = _co.dynamicPanelData.attachmentsFormControl; var currVal_5 = true; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_AttachmentsDynamicPanelComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-attachments-dynamic-panel", [], null, null, null, View_AttachmentsDynamicPanelComponent_0, RenderType_AttachmentsDynamicPanelComponent)), i2.ɵdid(1, 245760, null, 0, i11.AttachmentsDynamicPanelComponent, [i10.DynamicPanelService, i9.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentsDynamicPanelComponentNgFactory = i2.ɵccf("app-attachments-dynamic-panel", i11.AttachmentsDynamicPanelComponent, View_AttachmentsDynamicPanelComponent_Host_0, { attachments: "attachments", attachmentsFormControl: "attachmentsFormControl", parentTitle: "parentTitle" }, {}, []);
export { AttachmentsDynamicPanelComponentNgFactory as AttachmentsDynamicPanelComponentNgFactory };
