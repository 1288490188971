<div class="container">
  <div class="container-header">
    <app-notes-list-header
      (selectedOrderChange)="selectedOrder = $event"
      (scrollOptionChange)="handleScrollOptionChange($event)"
      (searchQueryChange)="handleSearchQueryChange($event)"
      [loading]="loading"
    ></app-notes-list-header>

    <div class="tab-filters">
      <div
        *ngFor="let tab of tabs"
        class="tab"
        [class.selected]="tab.selected"
        (click)="selectTab(tab)"
      >
        {{ tab.title }}
        <div *ngIf="tab.selected" class="selected-line"></div>
      </div>
    </div>

    <div class="shadow"></div>
  </div>
  
  <div class="container-quick-form">
    <app-quick-form
      placeholder="Add New Note"
      bundledGroup="notesListQuickForm"
      [toggle]="quickFormToggle"
      (clickMore)="handleNewNote()"
      (collapsedChange)="quickFormExpanded = $event"
      (itemDropped)="handleQuickFormDrop($event)"
    >
      <app-note-quick-form
        bundledGroup="notesListQuickForm"
        [note]="quickFormNote"
        (more)="handleNewNote($event)"
      ></app-note-quick-form>
    </app-quick-form>
    <div class="shadow" [ngClass]="{hidden: !quickFormExpanded}"></div>
  </div>

  <div class="container-list">
    <app-notes-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
      (loadInProgress)="loading = $event"
    ></app-notes-list>
  </div>
</div>
