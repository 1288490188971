import * as tslib_1 from "tslib";
import { FormControl } from '@angular/forms';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { takeUntil, filter } from 'rxjs/operators';
var ReminderDateTimePickerModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReminderDateTimePickerModalComponent, _super);
    /**
     * Constructor
     */
    function ReminderDateTimePickerModalComponent(mailService, ga) {
        var _this = _super.call(this) || this;
        _this.mailService = mailService;
        _this.ga = ga;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 140px)',
            y: 'calc(50% - 85px)',
            width: '280px',
            height: '170px'
        };
        // Public
        _this.date = new FormControl();
        _this.time = new FormControl();
        return _this;
    }
    /**
     * Actions
     */
    ReminderDateTimePickerModalComponent.prototype.saveReminder = function () {
        if (!this.date.value || !this.time.value) {
            // @TODO: add error notification
            return;
        }
        var dateTime = new Date(this.date.value.getFullYear(), this.date.value.getMonth(), this.date.value.getDate(), this.time.value.getHours(), this.time.value.getMinutes());
        return this.reminderType === 'followup' ? this.saveFollowup(dateTime) : this.saveSnooze(dateTime);
    };
    ReminderDateTimePickerModalComponent.prototype.saveFollowup = function (dateTime) {
        var _this = this;
        this.ga.trackEvent('message-context-menu', 'follow-up');
        this.mailService.followMessage('custom', this.message.id, dateTime)
            .pipe(filter(function (success) { return success; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.message.followed = true;
            _this.close();
        });
    };
    ReminderDateTimePickerModalComponent.prototype.saveSnooze = function (dateTime) {
        var _this = this;
        this.ga.trackEvent('message-context-menu', 'snooze');
        this.mailService.snoozeMessage('custom', this.message.id, dateTime)
            .pipe(filter(function (success) { return success; }), takeUntil(this.componentNotDestroyed))
            .subscribe(function () {
            _this.message.snoozed = true;
            _this.close();
        });
    };
    return ReminderDateTimePickerModalComponent;
}(BaseModalComponent));
export { ReminderDateTimePickerModalComponent };
