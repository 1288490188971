/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-cell-context-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-contextmenu";
import * as i3 from "../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./calendar-cell-context-menu.component";
import * as i6 from "../../../events/services/events-state.service";
var styles_CalendarCellContextMenuComponent = [i0.styles];
var RenderType_CalendarCellContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarCellContextMenuComponent, data: {} });
export { RenderType_CalendarCellContextMenuComponent as RenderType_CalendarCellContextMenuComponent };
function View_CalendarCellContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Paste "]))], null, null); }
function View_CalendarCellContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.pasteEvents() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_2)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_CalendarCellContextMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CalendarCellContextMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, null, View_CalendarCellContextMenuComponent_4)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], { divider: [0, "divider"] }, null)], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CalendarCellContextMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Create Event "]))], null, null); }
function View_CalendarCellContextMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Load Day's Events in List View "]))], null, null); }
function View_CalendarCellContextMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Day "]))], null, null); }
function View_CalendarCellContextMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCalendar("day") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_8)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_CalendarCellContextMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Week "]))], null, null); }
function View_CalendarCellContextMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCalendar("week") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_10)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_CalendarCellContextMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Month "]))], null, null); }
function View_CalendarCellContextMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCalendar("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_12)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_CalendarCellContextMenuComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Year "]))], null, null); }
function View_CalendarCellContextMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCalendar("year") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_14)), i1.ɵdid(1, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_CalendarCellContextMenuComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Agenda "]))], null, null); }
export function View_CalendarCellContextMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contextMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(2, 180224, [[1, 4], ["contextMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_5)), i1.ɵdid(9, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.handleLoadDayEvents() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_6)), i1.ɵdid(11, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_7)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_9)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_11)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarCellContextMenuComponent_13)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openCalendar("agenda") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarCellContextMenuComponent_15)), i1.ɵdid(21, 16384, [[2, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clipboard; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.clipboard; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.calendarType !== "day"); _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.calendarType !== "week"); _ck(_v, 15, 0, currVal_3); var currVal_4 = (_co.calendarType !== "month"); _ck(_v, 17, 0, currVal_4); var currVal_5 = (_co.calendarType !== "year"); _ck(_v, 19, 0, currVal_5); }, null); }
export function View_CalendarCellContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-cell-context-menu", [], null, null, null, View_CalendarCellContextMenuComponent_0, RenderType_CalendarCellContextMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i5.CalendarCellContextMenuComponent, [i2.ContextMenuService, i6.EventsStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarCellContextMenuComponentNgFactory = i1.ɵccf("app-calendar-cell-context-menu", i5.CalendarCellContextMenuComponent, View_CalendarCellContextMenuComponent_Host_0, { triggerEvent: "triggerEvent", anchorElement: "anchorElement", contextMenuItem: "contextMenuItem", date: "date", calendarType: "calendarType" }, { close: "close", loadDayEvents: "loadDayEvents" }, []);
export { CalendarCellContextMenuComponentNgFactory as CalendarCellContextMenuComponentNgFactory };
