// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { ColumnContextMenuComponent } from '@modules/tasks/components/column-context-menu/column-context-menu.component';

// Types
import { Column } from '@modules/tasks/types/column';

@Directive({
  selector: '[appProjectBoardColumnContextMenu]'
})
export class BoardColumnContextMenuDirective extends ContextMenuDirective {


  // Inputs
  @Input() contextMenuComponent = ColumnContextMenuComponent;
  @Input() appProjectBoardColumnContextMenu: Column;

  setContextMenuInstanceData(componentRef: ComponentRef<ColumnContextMenuComponent>) {
    componentRef.instance.column = this.appProjectBoardColumnContextMenu;
  }
}
