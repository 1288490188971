// Common
import { Component, OnInit } from '@angular/core';

// Services
import { AuthService } from './../../../auth/services/auth.service';
import { MailService } from '../../../mail/services/mail.service';
import { ModalService } from '../../../modal/services/modal.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { EventsStateService } from '@modules/events/services/events-state.service';
import { TasksStateService } from '@modules/tasks/services/tasks-state.service';
import { NotesService } from '@modules/notes/services/notes.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.less']
})
export class NavBarComponent implements OnInit {

  /**
   * Constructor
   */

  constructor(
    private auth: AuthService,
    private mailService: MailService,
    private ga: GoogleAnalyticsService,
    private eventsStateService: EventsStateService,
    private tasksStateService: TasksStateService,
    private modalService: ModalService,
    private notesService: NotesService
  ) {}

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  refreshMailList(): void {
    this.eventsStateService.triggerRefreshAll();
    this.tasksStateService.triggerRefreshAll();
    this.mailService.doRefreshMailList();
    this.notesService.doRefreshNotes();
  }

  composeNewMessage($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.ga.trackEvent('nav-bar', 'compose-mail');
    this.modalService.compose();
  }

  createEvent() {
    this.ga.trackEvent('nav-bar', 'new-event');
    this.modalService.calendarEventForm(null);
  }

  createTask() {
    this.ga.trackEvent('nav-bar', 'new-task');
    this.modalService.taskForm(null);
  }

  createNote() {
    this.ga.trackEvent('nav-bar', 'new-note');
    this.modalService.noteForm();
  }

  createProject() {
    this.ga.trackEvent('nav-bar', 'new-project');
  }

}
