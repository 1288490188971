import * as tslib_1 from "tslib";
// Common
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Rx
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Types
import { Column } from '@modules/tasks/types/column';
var ColumnContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ColumnContextMenuComponent, _super);
    /**
     * Constructor
     */
    function ColumnContextMenuComponent(contextMenuService, ga, columnsService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.ga = ga;
        _this.columnsService = columnsService;
        // Private
        _this.colorChanges = new Subject();
        _this.colorChanges
            .pipe(switchMap(function () { return _this.columnFormGroup.valueChanges; }), takeUntil(_this.alive))
            .subscribe(function () {
            _this.columnsService.update(Column.fromFormGroup(_this.columnFormGroup));
        });
        return _this;
    }
    /*
    * Lifecycle
    */
    ColumnContextMenuComponent.prototype.ngOnInit = function () {
        this.reset();
    };
    ColumnContextMenuComponent.prototype.ngOnChanges = function (changes) {
        if ('column' in changes) {
            this.reset();
        }
    };
    /**
     * Actions
     */
    ColumnContextMenuComponent.prototype.delete = function () {
        this.ga.trackEvent('column-context-menu', 'delete-permanently');
        this.columnsService.deletePermanently([this.column.id]);
    };
    ColumnContextMenuComponent.prototype.reset = function () {
        this.columnFormGroup = this.column.asFormGroup();
        this.colorChanges.next();
    };
    return ColumnContextMenuComponent;
}(ContextMenuComponent));
export { ColumnContextMenuComponent };
