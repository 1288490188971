// Common
import { Injectable } from '@angular/core';

// Rx
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, filter } from 'rxjs/operators';

// Types
import { MinimizedState } from '../types/minimized-state';
import { SplitViewKey } from '@modules/settings/types/split-view-state';

@Injectable()
export class SplitViewService {

  // Private
  private toggleClick: Subject<string> = new Subject<string>();
  private minimizedState: BehaviorSubject<MinimizedState> = new BehaviorSubject<MinimizedState>({});

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Interface
   */

  toggleClicked(key: string): void {
    this.toggleClick.next(key);
  }

  getToggleIconClick(key: string): Observable<void> {
    return this.toggleClick
      .asObservable()
      .pipe(
        filter((toggleKey: string) => toggleKey === key),
        map(() => null),
      );
  }

  setMinimized(key: string, value: boolean): void {
    this.minimizedState.next({
      ...this.minimizedState.value,
      [key]: value
    });
  }

  getMinimized(key: SplitViewKey): Observable<boolean> {
    return this.minimizedState
      .asObservable()
      .pipe(
        map((minimized: MinimizedState) => minimized[key]),
        distinctUntilChanged()
      );
  }
}
