/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-hyperlinks.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-contextmenu";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i14 from "./related-hyperlinks.component";
import * as i15 from "../../../../modal/services/modal.service";
var styles_RelatedHyperlinksComponent = [i0.styles];
var RenderType_RelatedHyperlinksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelatedHyperlinksComponent, data: {} });
export { RenderType_RelatedHyperlinksComponent as RenderType_RelatedHyperlinksComponent };
function View_RelatedHyperlinksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "hyperlink__sender"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": ", ""]))], null, function (_ck, _v) { var currVal_0 = ((((_v.parent.context.$implicit.from == null) ? null : _v.parent.context.$implicit.from.name) || ((_v.parent.context.$implicit.from == null) ? null : _v.parent.context.$implicit.from.email)) || "Unknown"); var currVal_1 = (_v.parent.context.$implicit.subject || "(no subject)"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_RelatedHyperlinksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "hyperlink__wrapper"]], null, [[null, "click"], [null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openLink(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵa, [i2.ContextMenuService], { contextMenuSubject: [0, "contextMenuSubject"], contextMenu: [1, "contextMenu"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "hyperlink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "icon-mail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "hyperlink-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "button-link"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "hyperlink__info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "hyperlink__link"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 16777216, null, null, 2, "mat-icon", [["class", "copy-icon mat-icon notranslate"], ["matTooltip", "Copy"], ["role", "img"], ["svgIcon", "copy"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.copyLink(_v.context.$implicit, $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), i1.ɵdid(13, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i10.AriaDescriber, i10.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = i1.ɵnov(_v.parent, 12); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = "button-link"; _ck(_v, 5, 0, currVal_4); var currVal_6 = (_v.context.$implicit.from || _v.context.$implicit.subject); _ck(_v, 10, 0, currVal_6); var currVal_9 = "copy"; _ck(_v, 12, 0, currVal_9); var currVal_10 = "Copy"; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 5).inline; var currVal_3 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.url; _ck(_v, 8, 0, currVal_5); var currVal_7 = i1.ɵnov(_v, 12).inline; var currVal_8 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_7, currVal_8); }); }
function View_RelatedHyperlinksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/placeholders/placeholder-links.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no links."]))], null, null); }
function View_RelatedHyperlinksComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error occured while loading. "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "error"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here to try again."]))], null, null); }
function View_RelatedHyperlinksComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "load-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Load more"]))], null, null); }
function View_RelatedHyperlinksComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-refresh fa-spin fa-fw"]], null, null, null, null, null))], null, null); }
function View_RelatedHyperlinksComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Open Link "]))], null, null); }
function View_RelatedHyperlinksComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Forward Link "]))], null, null); }
function View_RelatedHyperlinksComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Copy "]))], null, null); }
export function View_RelatedHyperlinksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "related-hyperlinks__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedHyperlinksComponent_6)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "context-menu", [], null, null, null, i13.View_ContextMenuComponent_0, i13.RenderType_ContextMenuComponent)), i1.ɵdid(12, 180224, [["hyperlinkContextMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.openLink($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedHyperlinksComponent_7)), i1.ɵdid(15, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.forwardLink($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedHyperlinksComponent_8)), i1.ɵdid(17, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.copyLink($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelatedHyperlinksComponent_9)), i1.ɵdid(19, 16384, [[1, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hyperlinks; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!((_co.hyperlinks == null) ? null : _co.hyperlinks.length) && !_co.loading) && !_co.error); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.totalCount && (_co.totalCount > ((_co.hyperlinks == null) ? null : _co.hyperlinks.length))) && !_co.loading); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_RelatedHyperlinksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-hyperlinks", [], null, null, null, View_RelatedHyperlinksComponent_0, RenderType_RelatedHyperlinksComponent)), i1.ɵdid(1, 114688, null, 0, i14.RelatedHyperlinksComponent, [i15.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedHyperlinksComponentNgFactory = i1.ɵccf("app-related-hyperlinks", i14.RelatedHyperlinksComponent, View_RelatedHyperlinksComponent_Host_0, { hyperlinks: "hyperlinks", totalCount: "totalCount", loading: "loading", error: "error" }, { loadMore: "loadMore" }, []);
export { RelatedHyperlinksComponentNgFactory as RelatedHyperlinksComponentNgFactory };
