/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-content-popup.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modal/components/mail-content-modal/mail-content-modal.component.ngfactory";
import * as i3 from "../../../modal/components/mail-content-modal/mail-content-modal.component";
import * as i4 from "../../services/popup.service";
import * as i5 from "../../../analytics/services/google-analytics.service";
import * as i6 from "./mail-content-popup.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../mail/services/mail.service";
var styles_MailContentPopupComponent = [i0.styles];
var RenderType_MailContentPopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MailContentPopupComponent, data: {} });
export { RenderType_MailContentPopupComponent as RenderType_MailContentPopupComponent };
export function View_MailContentPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mail-content-modal", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MailContentModalComponent_0, i2.RenderType_MailContentModalComponent)), i1.ɵdid(2, 245760, null, 0, i3.MailContentModalComponent, [i1.ElementRef, i4.PopupService, i5.GoogleAnalyticsService], { message: [0, "message"], availableExpand: [1, "availableExpand"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_MailContentPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-content-popup", [], null, null, null, View_MailContentPopupComponent_0, RenderType_MailContentPopupComponent)), i1.ɵdid(1, 245760, null, 0, i6.MailContentPopupComponent, [i7.ActivatedRoute, i8.MailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailContentPopupComponentNgFactory = i1.ɵccf("app-mail-content-popup", i6.MailContentPopupComponent, View_MailContentPopupComponent_Host_0, {}, {}, []);
export { MailContentPopupComponentNgFactory as MailContentPopupComponentNgFactory };
