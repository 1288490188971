// Common
import { Injectable } from '@angular/core';
import { endOfDay, startOfDay, addDays } from 'date-fns';

// RX
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Task } from '../types/task';
import { Project } from '../types/project';
import { TasksMainView } from '../types/tasks-main-view';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { TasksSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';
import { ListState } from '@modules/tasks/types/list-state';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { PrioritiesListItem } from '@modules/tasks/types/priority-item';

// Pipes
import { DateByDayIndexPipe } from '@modules/pipes/pipes/date-by-day-index.pipe';

@Injectable()
export class TasksStateService {
  public static readonly prioritiesList: PrioritiesListItem[] = [
    { priorityName: 'normal', title: 'Normal',  color: '#0F84FF' },
    { priorityName: 'medium', title: 'Medium',  color: '#FFBC00' },
    { priorityName: 'high',   title: 'High',    color: '#FF0050' }
  ];

  // Private
  private project = new BehaviorSubject<Project>(null);
  private selectedTasks = new BehaviorSubject<Task[]>([]);
  private selectedProjects = new BehaviorSubject<Project[]>([]);
  private refreshAll = new Subject<void>();
  private mainView = new BehaviorSubject<TasksMainView>('empty');
  private mainViewTask = new BehaviorSubject<Task>(null);
  private mainViewProject = new BehaviorSubject<Project>(null);
  private taskFilters = new BehaviorSubject<TasksFilters>({});

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
  ) {
    this.setMainView(this.stateService.tasksMainView);
  }

  /**
   * Actions
   */

  setProject(currentProject: Project) {
    this.project.next(currentProject);
  }

  getProject(): Observable<Project> {
    return this.project.asObservable();
  }

  setSelectedTasks(events: Task[]) {
    this.selectedTasks.next(events);
  }

  getSelectedTasks(): Observable<Task[]> {
    return this.selectedTasks.asObservable();
  }

  setSelectedProjects(projects: Project[]) {
    this.selectedProjects.next(projects);
  }

  getSelectedProjects(): Observable<Project[]> {
    return this.selectedProjects.asObservable();
  }

  getListState(): Observable<ListState> {
    return this.stateService
      .getSidebarFilters('tasks')
      .pipe(
        map((value: TasksSidebarFilterKey) => {
          switch (value) {
            case 'all_tasks':
            case 'scheduled':
            case 'unscheduled':
              return 'tasks';
            case 'all_projects':
              return 'projects';
            case 'priority':
            case 'normal':
            case 'medium':
            case 'high':
            case 'today':
            case 'week':
            case 'day0':
            case 'day1':
            case 'day2':
            case 'day3':
            case 'day4':
            case 'day5':
            case 'day6':
            case 'archived':
            case 'deleted':
              return 'tabs';
            default:
              return 'tasks';
          }
        })
      );
  }

  triggerRefreshAll(): void {
    this.refreshAll.next();
  }

  getRefreshAll(): Observable<void> {
    return this.refreshAll.asObservable();
  }

  getMainView(): Observable<TasksMainView> {
    return this.mainView.asObservable();
  }

  getMainViewTask(): Observable<Task> {
    return this.mainViewTask.asObservable();
  }

  setMainView(view: TasksMainView) {
    this.mainView.next(view);
    this.stateService.tasksMainView = view;
  }

  setMainViewTask(task: Task) {
    this.mainViewTask.next(
      new Task({
        ...task,
        projectId: task.projectId || (this.project.value && this.project.value.id) || null
      })
    );
  }

  getMainViewProject(): Observable<Project> {
    return this.mainViewProject.asObservable();
  }

  setMainViewProject(project: Project) {
    this.mainViewProject.next(project);
  }

  openTaskForm(task: Task) {
    this.setMainViewTask(task);
    this.setMainView('task-form');
  }

  openProjectForm(project: Project) {
    this.setMainViewProject(project);
    this.setMainView('project-form');
  }

  setTasksFilters(filters: TasksFilters) {
    this.taskFilters.next(filters);
  }

  getTasksFilters(): Observable<TasksFilters> {
    return this.taskFilters.asObservable();
  }

  getSidebarFilters(): Observable<TasksFilters | ProjectsFilters> {
    return this.stateService
      .getSidebarFilters('tasks')
      .pipe(
        map((value: TasksSidebarFilterKey) => {
          const today = Date.now();
          switch (value) {
            case 'archived':
              return { archived: true };
            case 'deleted':
              return { deleted: true };
            case 'today':
              return { fromTime: startOfDay(today), toTime: endOfDay(today), noProject: true };
            case 'day0':
            case 'day1':
            case 'day2':
            case 'day3':
            case 'day4':
            case 'day5':
            case 'day6':
              const dateByDayIndexPipe = new DateByDayIndexPipe();
              const day = dateByDayIndexPipe.transform(value);
              return { fromTime: startOfDay(day), toTime: endOfDay(day), noProject: true };
            case 'week':
              return { fromTime: startOfDay(addDays(today, 1)), toTime: endOfDay(addDays(today, 7)), noProject: true };
            case 'all_tasks':
              return { noProject: true };
            case 'scheduled':
              return { scheduled: true, noProject: true };
            case 'unscheduled':
              return { scheduled: false, noProject: true };
            case 'normal':
              return { priority: 'normal', noProject: true };
            case 'medium':
              return { priority: 'medium', noProject: true };
            case 'high':
              return { priority: 'high', noProject: true };
            case 'all_projects':
              return {};
            case 'priority':
              return { noProject: true };
            default:
              const currentProjectId = this.project.getValue() && this.project.getValue().id;
              return { projectsIds: [currentProjectId] };
          }
        })
      );
  }
}
