import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
var AssignPipe = /** @class */ (function () {
    function AssignPipe() {
    }
    AssignPipe.prototype.transform = function (originalObject, newKey, newValue) {
        var _a;
        if (!originalObject || !newKey || !newValue) {
            return {};
        }
        if (newKey && newValue) {
            return tslib_1.__assign({}, originalObject, (_a = {}, _a[newKey] = newValue, _a));
        }
    };
    return AssignPipe;
}());
export { AssignPipe };
