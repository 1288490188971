// Common
import { NgZone } from '@angular/core';
import { fromPairs } from 'lodash';
// RxJS
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
// Env
import { environment } from '@environment';
var AuthService = /** @class */ (function () {
    function AuthService(http, ngZone, googleAuth, electronService) {
        this.http = http;
        this.ngZone = ngZone;
        this.googleAuth = googleAuth;
        this.electronService = electronService;
        this.authenticated = new BehaviorSubject(this.isAuthenticated());
    }
    AuthService.prototype.isAuthenticated = function () {
        return !!localStorage.getItem('token');
    };
    AuthService.prototype.authenticate = function (token) {
        localStorage.setItem('token', token);
        this.authenticated.next(true);
    };
    AuthService.prototype.signOut = function () {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/auth/logout', {}, { withCredentials: true })
            .pipe(map(function () { return true; }), catchError(function () { return of(false); }), tap(function () {
            localStorage.removeItem('token');
            _this.authenticated.next(false);
        }));
    };
    AuthService.prototype.signIn = function (code, redirectUri) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/auth/google', {
            code: code,
            redirect_uri: redirectUri
        }, { withCredentials: true })
            .pipe(
        // Unify errors from API and other sources
        catchError(function (error) { return throwError({
            message: error.error.error || error.message,
            code: error.error.error_code || 'internal_error'
        }); }), map(function (data) {
            if (!data['token']) {
                throw new Error('Error while generating access token. Please try again.');
            }
            _this.authenticate(data['token']);
            return !!data['account_ready'];
        }));
    };
    AuthService.prototype.googleSignIn = function () {
        var _this = this;
        if (this.electronService.isElectronApp) {
            return this.grantOfflineAccess()
                .pipe(switchMap(function (code) { return _this.signIn(code, environment.socialAuth.google.redirectUri); }));
        }
        return this.googleAuth.getAuth()
            .pipe(switchMap(function (auth) { return new Observable(function (observer) {
            auth.grantOfflineAccess({ prompt: 'consent' })
                .then(function (res) { return _this.ngZone.run(function () {
                observer.next(res.code);
                observer.complete();
            }); }, function (_a) {
                var error = _a.error;
                return _this.ngZone.run(function () {
                    var errorMessage = 'Unknown error. Please try again.';
                    switch (error) {
                        case 'popup_closed_by_user':
                            errorMessage = 'Auth window was closed before authorization process finished. Please try again.';
                            break;
                        case 'access_denied':
                            errorMessage = 'You have denied access to required scope. Please try again and grant the access.';
                            break;
                    }
                    observer.error(new Error(errorMessage));
                });
            });
        }); }), switchMap(function (code) { return _this.signIn(code, window.location.origin); }));
    };
    AuthService.prototype.grantOfflineAccess = function () {
        var _this = this;
        return new Observable(function (observer) {
            var authWindow = new _this.electronService.remote.BrowserWindow({
                width: 500,
                height: 600,
                show: false,
                alwaysOnTop: true
            });
            var authUrl = "https://accounts.google.com/o/oauth2/v2/auth?" +
                "response_type=code&" +
                ("prompt=" + encodeURIComponent(environment.socialAuth.google.prompt) + "&&") +
                ("redirect_uri=" + encodeURIComponent(environment.socialAuth.google.redirectUri) + "&") +
                ("client_id=" + encodeURIComponent(environment.socialAuth.google.clientId) + "&") +
                ("scope=" + encodeURIComponent(environment.socialAuth.google.scope.join(' ')));
            var handleNavigation = function (url) { return _this.ngZone.run(function () {
                var _a = fromPairs(new URL(url).search
                    .slice(1)
                    .split('&')
                    .map(function (param) { return param.split('='); })), code = _a.code, error = _a.error;
                if (code) {
                    observer.next(code);
                    observer.complete();
                }
                else if (error) {
                    observer.error(new Error(error));
                }
                else if (url.startsWith(environment.socialAuth.google.redirectUri)) {
                    observer.error(new Error('Unknown error. Redirected without code and error.'));
                }
                else {
                    return;
                }
                authWindow.removeAllListeners('closed');
                setTimeout(function () { return authWindow.close(); }, 0);
            }); };
            authWindow.on('closed', function () { return _this.ngZone.run(function () { return observer.error(new Error('Auth window was closed before authorization process finished. Please try again.')); }); });
            authWindow.webContents.on('will-navigate', function (event, url) { return handleNavigation(url); });
            authWindow.webContents.on('will-redirect', function (event, url) { return handleNavigation(url); });
            authWindow.once('ready-to-show', function () { return authWindow.show(); });
            authWindow.loadURL(authUrl);
        });
    };
    return AuthService;
}());
export { AuthService };
