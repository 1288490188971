// Common
import { Component, Input, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { BundledEvent } from '@modules/bundled-inputs/types/bundled-event';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.less', '../../styles/input.less'],
})
export class InputComponent {

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() width: string;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() autocompleteItemTemplateRef?: TemplateRef<any>;
  @Input() suggestedOptions: DropdownSelectItem[] = [];
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputProviderKeys: string[];
  @Input() bundledInputTransformFunction: (key: string, event: BundledEvent) => void;
  @Input() bundledInputProviderGroup: string;
  @Input() bundledInputConsumerGroup: string;
  @Input() icon: string;
  @Input() leftIcon: string;

  // Public
  public itemsLimit = 3;
  public showAll = false;

  /**
   * Constructor
   */

  constructor() {

  }
}
