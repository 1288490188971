import * as tslib_1 from "tslib";
// Common
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
// Components
import { ContextMenuComponent } from '@modules/context-menu/components/context-menu/context-menu.component';
// Types
import { Contact } from '@modules/contacts/types/contact';
var ContactContextMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactContextMenuComponent, _super);
    /**
     * Constructor
     */
    function ContactContextMenuComponent(contextMenuService, contactsStateService, ga, contactsService) {
        var _this = _super.call(this, contextMenuService) || this;
        _this.contextMenuService = contextMenuService;
        _this.contactsStateService = contactsStateService;
        _this.ga = ga;
        _this.contactsService = contactsService;
        _this.contactTitleFormControl = new FormControl();
        return _this;
    }
    /**
     * Component lifecycle
     */
    ContactContextMenuComponent.prototype.ngOnInit = function () {
        this.contactForm = this.contact.asFormGroup();
    };
    /**
     * Actions
     */
    ContactContextMenuComponent.prototype.handleEdit = function () {
        this.contactsStateService.setMainViewContact(this.contact);
        this.contactsStateService.setMainView('contact-form');
    };
    ContactContextMenuComponent.prototype.pin = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'pin');
        this.contactsService.pin(contacts.filter(function (contact) { return contact.pinned !== true; }).map(function (contact) { return contact.id; }), true);
    };
    ContactContextMenuComponent.prototype.unpin = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'unpin');
        this.contactsService.pin(contacts.filter(function (contact) { return contact.pinned === true; }).map(function (contact) { return contact.id; }), false);
    };
    ContactContextMenuComponent.prototype.delete = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'delete');
        this.contactsService.delete(contacts.filter(function (contact) { return contact.deleted !== true; }).map(function (contact) { return contact.id; }), true);
    };
    ContactContextMenuComponent.prototype.deletePermanently = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'delete-permanently');
        this.contactsService.deletePermanently(contacts.filter(function (contact) { return contact.deleted === true; }).map(function (contact) { return contact.id; }));
    };
    ContactContextMenuComponent.prototype.restoreFromTrash = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'restore-from-trash');
        this.contactsService.delete(contacts.filter(function (contact) { return contact.deleted === true; }).map(function (contact) { return contact.id; }), false);
    };
    ContactContextMenuComponent.prototype.moveToArchive = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'move-to-archive');
        this.contactsService.archive(contacts
            .filter(function (contact) { return contact.archived === false; })
            .map(function (contact) { return contact.id; }), true);
    };
    ContactContextMenuComponent.prototype.restoreFromArchive = function (contacts) {
        this.ga.trackEvent('contact-context-menu', 'restore-from-archive');
        this.contactsService.archive(contacts
            .filter(function (contact) { return contact.archived === true; })
            .map(function (contact) { return contact.id; }), false);
    };
    ContactContextMenuComponent.prototype.handleRenameSubmit = function () {
        this.contactForm.patchValue({ title: this.contactTitleFormControl.value });
        this.contactsService.update(Contact.fromFormGroup(this.contactForm));
        this.closeContextMenu();
    };
    ContactContextMenuComponent.prototype.handleRenameCancel = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    ContactContextMenuComponent.prototype.closeContextMenu = function () {
        this.contextMenuService.closeAllContextMenus({ eventType: 'cancel' });
    };
    return ContactContextMenuComponent;
}(ContextMenuComponent));
export { ContactContextMenuComponent };
