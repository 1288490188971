// Common
import { Component, OnInit, Input } from '@angular/core';

// Services
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';

@Component({
  selector: 'app-linked-info-dynamic-panel',
  templateUrl: './linked-info-dynamic-panel.component.html',
  styleUrls: ['./linked-info-dynamic-panel.component.less', '../styles/dynamic-panel.less']
})
export class LinkedInfoDynamicPanelComponent implements OnInit {

  // Inputs
  @Input() parentLinkedInfo: LinkedInfo;
  @Input() linkedInfo: LinkedInfo[] = [];

  // Public
  public orderOptions: DropdownOption[] = [
    { name: 'Date', key: 'date'},
  ];

  /**
   * Constructor
   */

  constructor(
    private dynamicPanelService: DynamicPanelService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Methods
   */

  closed() {
    this.dynamicPanelService.setContent();
  }

}
