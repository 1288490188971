<div
  class="note"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>

  <!-- Header -->
  <div class="header">

    <div class="info">
      
      <!-- Actions -->
      <div class="actions">
        <mat-icon class="btn-action" svgIcon="saved" (click)="favoriteNote($event)" [matTooltip]="note?.favorite ? 'Remove from favorite' : 'Favorite'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="pin" (click)="pinNote($event)" [matTooltip]="note?.pinned ? 'Unpin' : 'Pin'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="snooze" (click)="snoozeNote($event)" matTooltip="Snooze (Coming soon)"></mat-icon>
        <mat-icon class="btn-action" svgIcon="mail" (click)="createMail($event)" matTooltip="New mail via note"></mat-icon>
        <mat-icon class="btn-action" svgIcon="followup" (click)="followUpNote($event)" matTooltip="Follow up (Coming soon)"></mat-icon>
        <mat-icon class="btn-action" svgIcon="archive" (click)="archiveNote($event)" [matTooltip]="note?.archived ? 'Restore from archive' : 'Archive'"></mat-icon>
        <mat-icon class="btn-action" svgIcon="trash" (click)="deleteNote($event)" [matTooltip]="note?.deleted ? 'Delete' : 'Move to trash'"></mat-icon>
      </div>

      <div class="title">

        <!-- Title -->
        <app-input
          class="title-input"
          placeholder="Untitled"
          [inputFormControl]="title"
          appearance="standard"
          icon="note"
        ></app-input>

        <!-- Cancel -->
        <app-button *ngIf="!note.id" class="btn-save" appearance="blue" (click)="handleSave()">Save</app-button>
        <app-button (click)="handleCancel()">Cancel</app-button>
      </div>
    </div>

    <!-- Components -->
    <div class="components">
      <app-icon-button icon="attachment-small" class="btn-component" [disabled]="!note?.files?.length" (click)="openAttachmentsDynamicPanel()"></app-icon-button>
      <app-icon-button icon="link-thin" [disabled]="!note?.linkedInfo?.length" (click)="openLinkedInfoDynamicPanel()"></app-icon-button>
    </div>
    <!-- Drag -->
    <mat-icon
      class="drag-icon"
      svgIcon="drag-icon-small"
      appDraggable
      [appDraggableData]="{data: [note], type: 'note'}"
      [appDraggablePlaceholder]="dragPlaceholder"
      [appDraggableContainerAdjustFit]="true"
      [appDraggableContainerStyles]="{'max-width': '300px'}"
    ></mat-icon>
  </div>

  <div class="note-content">
    <!-- Content -->
    <app-note-editor [body]="body" (bodyChanged)="bodyChanged($event)"></app-note-editor>

    <!-- Linked Info -->
    <app-linked-info-list
      *ngIf="note?.linkedInfo?.length"
      class="linked-info"
      [parentLinkedInfo]="{type: 'note', id: note.id}"
      [linkedInfo]="note.linkedInfo"
      [extendEnabled]="true"
      [extendSelected]="dynamicPanel === 'linkedInfo'"
      (extendAction)="openLinkedInfoDynamicPanel()"
    ></app-linked-info-list>
  </div>

  <!-- Drop zone -->
  <div class="drop-zone"></div>

</div>

<!-- Drag placeholder -->
<ng-template #dragPlaceholder>
  <app-note [note]="note" [dragEnabled]="false" [actionsButtonEnabled]="false"></app-note>
</ng-template>
