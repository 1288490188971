// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContextMenuModule } from 'ngx-contextmenu';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { ContentMenuModule } from '../content-menu/content-menu.module';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';

// Components
import { MessageComponent } from './components/message/message.component';
import { MessageSnippetComponent } from './components/message-snippet/message-snippet.component';
import { MessageContextMenuWrapperComponent } from './components/message-context-menu-wrapper/message-context-menu-wrapper.component';
import { EventComponent } from './components/event/event.component';
import { EventContextMenuComponent } from './components/event-context-menu/event-context-menu.component';
import { EventPopoverPreviewComponent } from './components/event-popover-preview/event-popover-preview.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectContextMenuComponent } from './components/project-context-menu/project-context-menu.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactContextMenuComponent } from './components/contact-context-menu/contact-context-menu.component';
import { GroupComponent } from './components/group/group.component';
import { GroupContextMenuComponent } from './components/group-context-menu/group-context-menu.component';
import { TaskComponent } from './components/task/task.component';
import { TaskContextMenuComponent } from './components/task-context-menu/task-context-menu.component';
import { LinkInfoBarComponent } from './components/link-info/link-info-bar/link-info-bar.component';
import { LinkInfoSuggestionsComponent } from './components/link-info/link-info-suggestions/link-info-suggestions.component';
import {
  LinkInfoMessageSuggestionsComponent
} from './components/link-info/link-info-message-suggestions/link-info-message-suggestions.component';
import {
  LinkInfoEventSuggestionsComponent
} from './components/link-info/link-info-event-suggestions/link-info-event-suggestions.component';
import { TopicComponent } from './components/topic/topic.component';
import { RelatedMailsComponent } from './components/related/related-mails/related-mails.component';
import { RelatedFilesComponent } from './components/related/related-files/related-files.component';
import { RelatedHyperlinksComponent } from './components/related/related-hyperlinks/related-hyperlinks.component';
import { SectionComponent } from '@modules/elements/components/section/section.component';
import { SectionTaskComponent } from '@modules/elements/components/section-task/section-task.component';

// Directives
import { EventContextMenuDirective } from './directives/event-context-menu.directive';
import { ProjectContextMenuDirective } from './directives/project-context-menu.directive';
import { TaskContextMenuDirective } from './directives/task-context-menu.directive';
import { GroupContextMenuDirective } from './directives/group-context-menu.directive';
import { ContactContextMenuDirective } from './directives/contact-context-menu.directive';
import { TagComponent } from './components/tag/tag.component';
import { LinkInfoTaskSuggestionsComponent } from './components/link-info/link-info-task-suggestions/link-info-task-suggestions.component';
import { LinkInfoNoteSuggestionsComponent } from './components/link-info/link-info-note-suggestions/link-info-note-suggestions.component';
import { LinkedInfoMessageListComponent } from './components/link-info-popover/linked-info-message-list/linked-info-message-list.component';
import { LinkedInfoEventListComponent } from './components/link-info-popover/linked-info-event-list/linked-info-event-list.component';
import { LinkedInfoProjectListComponent } from './components/link-info-popover/linked-info-project-list/linked-info-project-list.component';
import { LinkedInfoTaskListComponent } from './components/link-info-popover/linked-info-task-list/linked-info-task-list.component';
import { LinkedInfoNoteListComponent } from './components/link-info-popover/linked-info-note-list/linked-info-note-list.component';
import { LinkedInfoGroupListComponent } from './components/link-info-popover/linked-info-group-list/linked-info-group-list.component';
import { LinkedInfoContactListComponent } from './components/link-info-popover/linked-info-contact-list/linked-info-contact-list.component';
import { NoteComponent } from './components/note/note.component';
import { NoteContextMenuDirective } from './directives/note-context-menu.directive';
import { NoteContextMenuComponent } from './components/note-context-menu/note-context-menu.component';
import { FileComponent } from './components/file/file.component';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    UserAvatarModule,
    DragNDropModule,
    FormControlsModule,
    PopoverModule,
    PipesModule,
    ContentMenuModule,
    QuickFormsModule,
  ],
  declarations: [
    MessageComponent,
    MessageContextMenuWrapperComponent,
    MessageSnippetComponent,
    EventComponent,
    EventContextMenuComponent,
    EventContextMenuDirective,
    EventPopoverPreviewComponent,
    LinkInfoSuggestionsComponent,
    LinkInfoMessageSuggestionsComponent,
    LinkInfoEventSuggestionsComponent,
    LinkInfoTaskSuggestionsComponent,
    LinkInfoNoteSuggestionsComponent,
    LinkInfoBarComponent,
    ProjectComponent,
    ProjectContextMenuComponent,
    ProjectContextMenuDirective,
    GroupComponent,
    GroupContextMenuComponent,
    GroupContextMenuDirective,
    ContactComponent,
    ContactContextMenuComponent,
    ContactContextMenuDirective,
    TaskComponent,
    TaskContextMenuComponent,
    TaskContextMenuDirective,
    TopicComponent,
    TagComponent,
    RelatedMailsComponent,
    RelatedFilesComponent,
    RelatedHyperlinksComponent,
    LinkedInfoMessageListComponent,
    LinkedInfoEventListComponent,
    LinkedInfoProjectListComponent,
    LinkedInfoTaskListComponent,
    LinkedInfoNoteListComponent,
    LinkedInfoGroupListComponent,
    LinkedInfoContactListComponent,
    NoteComponent,
    NoteContextMenuDirective,
    NoteContextMenuComponent,
    SectionComponent,
    SectionTaskComponent,
    FileComponent,
  ],
  exports: [
    MessageComponent,
    MessageSnippetComponent,
    MessageContextMenuWrapperComponent,
    EventComponent,
    EventContextMenuDirective,
    EventPopoverPreviewComponent,
    LinkInfoSuggestionsComponent,
    LinkInfoMessageSuggestionsComponent,
    LinkInfoEventSuggestionsComponent,
    LinkInfoTaskSuggestionsComponent,
    LinkInfoNoteSuggestionsComponent,
    LinkInfoBarComponent,
    ProjectComponent,
    ProjectContextMenuDirective,
    TaskComponent,
    TaskContextMenuDirective,
    GroupComponent,
    GroupContextMenuDirective,
    ContactComponent,
    ContactContextMenuDirective,
    TopicComponent,
    TagComponent,
    RelatedMailsComponent,
    RelatedFilesComponent,
    RelatedHyperlinksComponent,
    NoteComponent,
    NoteContextMenuDirective,
    SectionComponent,
    FileComponent,
  ],
  entryComponents: [
    EventContextMenuComponent,
    ProjectContextMenuComponent,
    GroupContextMenuComponent,
    ContactContextMenuComponent,
    TaskContextMenuComponent,
    NoteContextMenuComponent,
  ],
})
export class ElementsModule { }
