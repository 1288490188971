import { Pipe, PipeTransform } from '@angular/core';
import { WeekViewAllDayEventRow, WeekViewAllDayEvent } from 'calendar-utils';

@Pipe({
  name: 'sameDayEventsCount'
})
export class SameDayEventsCountPipe implements PipeTransform {

  transform(allDayEvent: WeekViewAllDayEvent, rows: WeekViewAllDayEventRow[], startFrom = 0, checkOverflow = false): number {
    if (!allDayEvent || !rows || rows.length === 0) {
      return 0;
    }

    const currentEvent: WeekViewAllDayEvent = {
      ...allDayEvent,
      span: checkOverflow ? allDayEvent.span || 1 : 1
    };

    return [].concat(...rows.slice(startFrom).map(eventRows => eventRows.row.map(event => event)))
      .filter(event => (
        event.offset === currentEvent.offset
        || event.offset < currentEvent.offset && (event.offset + event.span) > currentEvent.offset)
        || event.offset > currentEvent.offset && event.offset <= currentEvent.offset + currentEvent.span - 1
      ).length;
  }
}
