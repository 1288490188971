/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./annotations-popup.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modal/components/topics-annotations-modal/topics-annotations-modal.component.ngfactory";
import * as i3 from "../../../modal/components/topics-annotations-modal/topics-annotations-modal.component";
import * as i4 from "../../../mail/services/mail.service";
import * as i5 from "../../services/popup.service";
import * as i6 from "../../../topic/services/topic.service";
import * as i7 from "./annotations-popup.component";
import * as i8 from "@angular/router";
var styles_AnnotationsPopupComponent = [i0.styles];
var RenderType_AnnotationsPopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AnnotationsPopupComponent, data: {} });
export { RenderType_AnnotationsPopupComponent as RenderType_AnnotationsPopupComponent };
export function View_AnnotationsPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-topics-annotations-modal", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TopicsAnnotationsModalComponent_0, i2.RenderType_TopicsAnnotationsModalComponent)), i1.ɵdid(2, 245760, null, 0, i3.TopicsAnnotationsModalComponent, [i4.MailService, i5.PopupService, i6.TopicService, i1.ElementRef], { availableExpand: [0, "availableExpand"], message: [1, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.message; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AnnotationsPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-annotations-popup", [], null, null, null, View_AnnotationsPopupComponent_0, RenderType_AnnotationsPopupComponent)), i1.ɵdid(1, 245760, null, 0, i7.AnnotationsPopupComponent, [i8.ActivatedRoute, i4.MailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnnotationsPopupComponentNgFactory = i1.ɵccf("app-annotations-popup", i7.AnnotationsPopupComponent, View_AnnotationsPopupComponent_Host_0, {}, {}, []);
export { AnnotationsPopupComponentNgFactory as AnnotationsPopupComponentNgFactory };
