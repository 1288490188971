<div class="create-tag__wrapper">
  <mat-icon svgIcon="close" class="close-icon" (click)="closed()"></mat-icon>
  <div class="title">Add Tags</div>
  <app-input
    class="tag-input"
    placeholder="Add Tags"
    [inputFormControl]="inputFormControl"
    (keydown.enter)="addTagToList()"
    [suggestedOptions]="suggestedOptions | async"
    [autocompleteItemTemplateRef]="autocompleteItem"
  ></app-input>
  <div class="tags">
    <div *ngFor="let tag of tags" class="tag-item">
      #{{ tag.name }}
      <mat-icon svgIcon="close-small" class="delete-icon" (click)="deleteTagFromList(tag)"></mat-icon>
    </div>
    <div class="placeholder" *ngIf="!tags.length">You can add multiple tags with enter e.g. personal must_read educational</div>
  </div>
  <div class="footer">
    <app-button class="btn-action" (click)="closed()">CANCEL</app-button>
    <app-button class="btn-action" appearance="blue" (click)="save()">SAVE</app-button>
  </div>
</div>

<ng-template #autocompleteItem let-tag="item.value">
  <div class="tag-autocomplete">{{tag.name}}</div>
</ng-template>
