<div
  *ngIf="!inline"
  class="date-input"
  [class.app-form-controls-input-outline]="appearance === 'outline'"
  [class.app-form-controls-input-standard]="appearance === 'standard'"
>
  <mat-form-field
    [appearance]="appearance"
    [style.width]="width || '100%'"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
  >
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <input
      matInput
      [formControl]="dateStringFormControl"
      autocomplete="off"
      [placeholder]="appearance === 'outline' ? null : placeholder"
      [bundledConsumer]="bundledInputConsumerKeys"
      [bundledTransformFunction]="bundledTransformFunction"
      bundledAnimationStrategy="highlightOnly"
      [bundledConsumerGroup]="bundledInputConsumerGroup"
      [bundledFormControl]="inputFormControl"
      [bundledValueCompareFunction]="bundledValueCompareFunction"
    >
    <mat-icon matSuffix svgIcon="dropdown"></mat-icon>
  </mat-form-field>

  <div
    class="date-input-overlay"
    popover
    #popover="popover"
    [popoverDisabled]="disabled"
    [popoverContent]="picker"
    [popoverArrow]="false"
    [popoverShowUntil]="hidePopover"
    popoverTrigger="click"
    popoverPlacement="bottom"
    popoverAllowedOutsideSelectorsClick=".app-date-picker-outside"
  ></div>
</div>

<div *ngIf="inline">
  <ng-container *ngTemplateOutlet="picker"></ng-container>
</div>

<ng-template #picker>
  <div
    class="picker"
    [ngClass]="{inline: inline, collapsed: collapsed}"
    [@collapseMotion]="{value: collapsed ? 'collapsed' : 'expanded', params: {height: inlineHeight}}"
  >
    <div #calendarContainer>
      <div class="picker-header">
        <app-dropdown
          [maxHeight]="194"
          [closeOnSelect]="true"
        >
          <ng-template #toggleButtonTemplate>
            <div class="picker-header-label">
              {{ activeDate | date : 'MMMM' }}
              <mat-icon svgIcon="dropdown-small" class="picker-header-label-dropdown-icon"></mat-icon>
            </div>
          </ng-template>
          <app-dropdown-single-select
            class="app-date-picker-outside"
            [options]="months"
            (selectedChange)="handleMonthClick($event.key)"
          ></app-dropdown-single-select>
        </app-dropdown>
        <div class="picker-header-icons">
          <mat-icon class="picker-header-icons-item" svgIcon="arrow-left-thin" (click)="handlePreviousClick()"></mat-icon>
          <div
            class="picker-header-icons-today"
            (click)="handleTodayClick()"
          >
            Today
          </div>
          <mat-icon class="picker-header-icons-item" svgIcon="arrow-right-thin" (click)="handleNextClick()"></mat-icon>
        </div>
        <app-dropdown
          [maxHeight]="194"
          [closeOnSelect]="true"
        >
          <ng-template #toggleButtonTemplate>
            <div
              class="picker-header-label right"
              [ngClass]="{'with-collapse-icon': inline && collapseable}"
            >
              <mat-icon svgIcon="dropdown-small" class="picker-header-label-dropdown-icon"></mat-icon>
              {{ activeDate | date: 'y' }}
            </div>
          </ng-template>
          <app-dropdown-template-items
            class="app-date-picker-outside"
            [options]="[{key: 'up'}]"
            [optionTemplateRef]="yearsArrowTemplate"
          ></app-dropdown-template-items>
          <app-dropdown-single-select
            class="app-date-picker-outside"
            [options]="years"
            (selectedChange)="handleYearClick($event.key)"
          ></app-dropdown-single-select>
          <app-dropdown-template-items
            class="app-date-picker-outside"
            [options]="[{key: 'down'}]"
            [optionTemplateRef]="yearsArrowTemplate"
          ></app-dropdown-template-items>
        </app-dropdown>
      </div>

      <div class="picker-days">
        <mwl-calendar-month-view
          [viewDate]="activeDate"
          [events]="events"
          [cellTemplate]="dayTemplate || defaultDayTemplate"
          [weekStartsOn]="0"
          (dayClicked)="handleDateSelect($event.day.date)"
        ></mwl-calendar-month-view>
      </div>
    </div>

    <mat-icon
      *ngIf="inline && collapseable"
      svgIcon="collapse-down"
      class="picker-collapse-icon"
      [ngClass]="{collapsed: collapsed}"
      (click)="handleCollapse()"
    ></mat-icon>
  </div>
</ng-template>

<ng-template
  #defaultDayTemplate
  let-day="day"
  let-locale="locale"
  let-trackByEventId="trackByEventId"
>
  <div class="picker-days-cell">
    <div
      *ngIf="day.inMonth"
      class="picker-days-cell-circle"
      [ngClass]="{
        selected: day.date | sameDay : inputFormControl.value,
        disabled: maxDate < day.date
      }"
    >
      {{ day.date | calendarDate : 'monthViewDayNumber' : locale }}
    </div>
  </div>
</ng-template>

<ng-template #yearsArrowTemplate let-option="option">
  <div class="picker-header-list">
    <mat-icon
      svgIcon="collapse-down"
      class="picker-header-list-icon"
      [ngClass]="{down: option.key === 'up'}"
      (click)="handleChangeYearsViewport(option.key)"
    ></mat-icon>
  </div>
</ng-template>
