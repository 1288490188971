<div class="modal-header annotations-modal-header">
  <div class="modal-title">Topics annotations</div>
  <button *ngIf="availableExpand" type="button" class="button-modal" aria-label="In new window" (click)="expandPopup()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body" *ngIf="status === 'saving' || status === 'loadingAnnotations'">
  <div class="loader"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
</div>
<div class="modal-body modal-body--with-footer modal-body-topic-annotation" *ngIf="status !== 'loadingAnnotations'">
  <div class="message-info">Message ID: {{message?.id}}</div>
  <table class="table table-striped">
    <caption class="table-header">Please rate the topics</caption>
    <thead>
      <tr>
        <th>Topic Name</th>
        <th>Source</th>
        <th>Score</th>
        <th>Rating</th>
      </tr>
    </thead>
    <tbody *ngIf="message?.topics">
      <tr *ngFor="let annotation of annotations">
        <td>
          {{annotation.name}}
          <ng-container *ngIf="annotation.source === 'user' || annotation.source === 'auto_discovery' || annotation.source === 'auto_add'" [ngSwitch]="annotationsStatus[annotation.name]">
            <ng-container *ngSwitchCase="'confirm'">
              (<a href="" (click)="deleteTopic(annotation)">this email</a> or <a href="" (click)="deleteTopic(annotation)">all emails</a>)
            </ng-container>
            <ng-container *ngSwitchCase="'loading'">
              (deleting)
            </ng-container>
            <ng-container *ngSwitchCase="'deleted'">
              (topic is deleted)
            </ng-container>
            <ng-container *ngSwitchCase="'error'">
              (error occured, <a href="" (click)="deleteTopic(annotation)">retry</a>)
            </ng-container>
            <ng-container *ngSwitchDefault>
              (<a href="" (click)="annotationsStatus[annotation.name] = 'confirm'; $event.preventDefault();">delete</a>)
            </ng-container>
          </ng-container>
        </td>
        <td>{{ annotation.source }}</td>
        <td>{{ annotation.score }}</td>
        <td>
          <div class="btn-group">
            <label class="btn btn-default btn-sm" [(ngModel)]="annotation.rating" btnRadio="worst">WORST</label>
            <label class="btn btn-default btn-sm" [(ngModel)]="annotation.rating" btnRadio="average">AVERAGE</label>
            <label class="btn btn-default btn-sm" [(ngModel)]="annotation.rating" btnRadio="good">GOOD</label>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!annotations?.length">
      <tr>
        <td colspan="4">No Topics</td>
      </tr>
    </tbody>
  </table>
  <div class="form-group add-topic-input">
    <label>Add Topic</label>
    <input type="text" class="form-control" placeholder="Topic" (keyup.enter)="addTopic()" [(ngModel)]="newTopic" />
  </div>
  <app-topic *ngFor="let topic of newTopics"
    [showRemoveIconAlways]="true"
    [topic]="topic"
    (deselect)="removeTopic(topic)"
    [topicStyle]="'discovered'">
  </app-topic>
  <div class="checkbox">
    <label>
      <input type="checkbox" [(ngModel)]="shareText"> Share this email for evaluation in plain text
    </label>
  </div>
  <div class="loader" *ngIf="status === 'saving'"><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
  <div class="alert alert-success" *ngIf="status === 'saved'">Annotations are saved</div>
  <div class="alert alert-danger" *ngIf="status === 'error'">Error occured while saving: {{statusError}}</div>
</div>
<div class="modal-footer">
  <button class="btn btn-default btn-sm pull-left" (click)="close()">Close</button>
  <button class="btn btn-primary btn-sm pull-right" (click)="save()">Save</button>
  <button class="btn btn-default btn-sm pull-right" (click)="reprocess()">Re-process</button>
</div>
