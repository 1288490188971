<div class="content-wrapper">
  <as-split direction="horizontal" unit="pixel" gutterSize="2">
    <as-split-area size="200" class="sidebar-shadow sidebar-gradient">
      <aside>
        <app-account-side-bar [isModal]="isModal" [(routePath)]="routePath"></app-account-side-bar>
      </aside>
    </as-split-area>
    <as-split-area size="*">
      <section class="content with-padding">
        <ng-container *ngIf="isModal">
          <app-account-overview *ngIf="routePath === 'overview'"></app-account-overview>
          <app-account-general *ngIf="routePath === 'general'"></app-account-general>
          <app-account-signature *ngIf="routePath === 'signatures'"></app-account-signature>
          <app-account-delayed-send *ngIf="routePath === 'delayed-send'"></app-account-delayed-send>
          <app-account-quick-reply-templates *ngIf="routePath === 'quick-reply-templates'"></app-account-quick-reply-templates>
          <app-account-autoreplies *ngIf="routePath === 'auto-replies'"></app-account-autoreplies>
          <app-account-mail-rules *ngIf="routePath === 'mail-rules'"></app-account-mail-rules>
          <app-account-web-push-notifications *ngIf="routePath === 'web-push-notifications'"></app-account-web-push-notifications>
          <app-account-cancel *ngIf="routePath === 'cancel'"></app-account-cancel>
        </ng-container>
        <ng-container *ngIf="!isModal">
          <router-outlet></router-outlet>
        </ng-container>
      </section>
    </as-split-area>
  </as-split>
</div>
