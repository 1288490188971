// Common
import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

// Types
import { Calendar } from '@modules/events/types/calendar';
import { SidebarFilterKey, EventsSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';

// Services
import { CalendarService } from '@modules/events/services/calendar.service';
import { StateService } from '@modules/settings/services/state.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { ContentMenuItemComponent } from '@modules/content-menu/components/content-menu-item/content-menu-item.component';

@Component({
  selector: 'app-events-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
  providers: [ContentMenuService]
})

export class EventsSideBarComponent implements OnDestroy {

  // Public
  public calendars: Calendar[] = [];
  public selectedCalendarIds: string[] = [];
  public sidebarFilter: EventsSidebarFilterKey;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private popoverClose: Subject<void> = new Subject();
  private sidebarFiltersKey: SidebarFilterKey = 'events';

  // View Children
  @ViewChild(ContentMenuItemComponent, { static: false }) calendarPopoverOrigin: ContentMenuItemComponent;
  @ViewChild('calendarCreateForm', {static: true}) public calendarCreateForm: TemplateRef<any>;

  /**
   * Constructor
   */

  constructor(
    private calendarService: CalendarService,
    private stateService: StateService,
    private popoverService: PopoverService,
    private splitViewService: SplitViewService,
    private contentMenuService: ContentMenuService
  ) {
    this.stateService.getSidebarFilters(this.sidebarFiltersKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((filter: EventsSidebarFilterKey) => {
        this.sidebarFilter = filter;
      });

    this.calendarService.getCalendarsList()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendars: Calendar[]) => {
        this.calendars = calendars;
      });

    this.stateService.getSelectedCalendars()
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((calendarIds: string[]) => {
        this.selectedCalendarIds = calendarIds;
      });

    this.splitViewService.getMinimized('eventsSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        this.contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleCalendarSelect(checked: boolean, calendar: Calendar) {
    const selectedIds = new Set(this.selectedCalendarIds);

    if (checked) {
      selectedIds.add(calendar.id);
    } else {
      selectedIds.delete(calendar.id);
    }
    this.stateService.selectedCalendars = [...selectedIds];
  }

  createCalendarDialog(event) {
    this.popoverService.create(
      this.calendarPopoverOrigin.elementRef,
      {
        placement: 'right',
        arrow: false,
        content: this.calendarCreateForm,
        allowedOutsideSelectors: '.mat-autocomplete-panel',
        trigger: 'click',
        showUntil: this.popoverClose
      },
      event
    );
  }

  calendarTracker(index: number, item: Calendar) {
    return item && item.id || index;
  }

  selectCategory(filter: EventsSidebarFilterKey) {
    this.stateService.setSidebarFilters(this.sidebarFiltersKey, filter);
  }

  handleCalendarPopoverClose() {
    this.popoverClose.next();
  }
}
