/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-folder-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./select-folder-modal.component";
import * as i6 from "../../../mail/services/mail.service";
var styles_SelectFolderModalComponent = [i0.styles];
var RenderType_SelectFolderModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectFolderModalComponent, data: {} });
export { RenderType_SelectFolderModalComponent as RenderType_SelectFolderModalComponent };
function View_SelectFolderModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-link open-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFolder(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["class", "fa fa-fw"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "fa-caret-right": 0, "fa-caret-down": 1 })], function (_ck, _v) { var currVal_0 = "fa fa-fw"; var currVal_1 = _ck(_v, 4, 0, !_v.parent.context.$implicit.open, _v.parent.context.$implicit.open); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_SelectFolderModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SelectFolderModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectFolderModalComponent_6)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit.subFolders); var currVal_1 = i1.ɵnov(_v.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SelectFolderModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "folder-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedFolder = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-folder fa-fw folder-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "folder-name-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectFolderModalComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectFolderModalComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "folder-wrapper"; var currVal_1 = _ck(_v, 4, 0, (_v.context.$implicit === _co.selectedFolder)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = (_v.context.$implicit.subFolders && (_v.context.$implicit.subFolders.length > 0)); _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.subFolders) && _v.context.$implicit.open); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_SelectFolderModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "select-folder-user-folders"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectFolderModalComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SelectFolderModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SelectFolderModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "folders-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["foldersList", 2]], null, 0, null, View_SelectFolderModalComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectFolderModalComponent_7)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(4, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.folders); var currVal_1 = i1.ɵnov(_v, 1); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SelectFolderModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Folder"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body modal-body--with-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectFolderModalComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default btn-sm pull-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm pull-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmMove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Move"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "close"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.messages; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).inline; var currVal_1 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = !_co.selectedFolder; _ck(_v, 12, 0, currVal_4); }); }
export function View_SelectFolderModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-folder-modal", [], null, null, null, View_SelectFolderModalComponent_0, RenderType_SelectFolderModalComponent)), i1.ɵdid(1, 245760, null, 0, i5.SelectFolderModalComponent, [i6.MailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectFolderModalComponentNgFactory = i1.ɵccf("app-select-folder-modal", i5.SelectFolderModalComponent, View_SelectFolderModalComponent_Host_0, { messages: "messages" }, { closed: "closed", collapsed: "collapsed" }, []);
export { SelectFolderModalComponentNgFactory as SelectFolderModalComponentNgFactory };
