// Common
import {
  Component, OnDestroy, AfterContentInit, Input, ContentChild, ContentChildren, QueryList,
  TemplateRef, OnChanges, SimpleChanges
} from '@angular/core';

// RxJS
import { Subject, merge, Subscription } from 'rxjs';
import { takeUntil, startWith, switchMap } from 'rxjs/operators';

// Components
import { DropdownSingleSelectComponent } from '../dropdown-single-select/dropdown-single-select.component';
import { DropdownMultiSelectComponent } from '../dropdown-multi-select/dropdown-multi-select.component';
import { DropdownActionItemsComponent } from '../dropdown-action-items/dropdown-action-items.component';
import { DropdownTemplateItemsComponent } from '../dropdown-template-items/dropdown-template-items.component';
import { DropdownColorSelectComponent } from '../dropdown-color-select/dropdown-color-select.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less']
})
export class DropdownComponent implements OnDestroy, AfterContentInit, OnChanges {

  // Inputs
  @Input() icon: string;
  @Input() align = 'left';
  @Input() placement: PopoverPlacement = 'bottom';
  @Input() closeOnSelect = false;
  @Input() title: string;
  @Input() trigger: 'click' | 'hover' = 'click';
  @Input() maxHeight: number;
  @Input() allowedOutsideSelectors: string;
  @Input() takeUntil: Subject<void> = new Subject();

  // ContentChild
  @ContentChild('toggleButtonTemplate', { static: true }) toggleButtonTemplate: TemplateRef<any>;
  @ContentChildren(DropdownSingleSelectComponent) private singleSelects: QueryList<DropdownSingleSelectComponent>;
  @ContentChildren(DropdownMultiSelectComponent) private multiSelects: QueryList<DropdownMultiSelectComponent>;
  @ContentChildren(DropdownActionItemsComponent) private actionItems: QueryList<DropdownActionItemsComponent>;
  @ContentChildren(DropdownTemplateItemsComponent) private templateItems: QueryList<DropdownTemplateItemsComponent>;
  @ContentChildren(DropdownColorSelectComponent) private colorSelect: QueryList<DropdownColorSelectComponent>;

  // Publics
  public dropdownOpen = false;
  public popoverClose: Subject<void> = new Subject();

  // Privates
  private componentNotDestroyed: Subject<void> = new Subject();
  private outsideCloseSubscription: Subscription;

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Component lifecycle
   */

  ngAfterContentInit() {

    merge(
      this.singleSelects.changes,
      this.multiSelects.changes,
      this.actionItems.changes,
      this.templateItems.changes,
      this.colorSelect.changes
    )
      .pipe(
        startWith(<void>null),
        switchMap(() => (
          merge(
            ...[
              ...this.singleSelects.toArray().map(item => item.selectedChange),
              ...this.multiSelects.toArray().map(item => item.selectedChange),
              ...this.actionItems.toArray().map(item => item.actionSelected),
              ...this.templateItems.toArray().map(item => item.selectedChange),
              ...this.colorSelect.toArray().map(item => item.selectedChange),
              ...this.colorSelect.toArray().map(item => item.removeColor),
            ]
          )
        )),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(() => this.autoClose());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('takeUntil' in changes) {
      if (this.outsideCloseSubscription) {
        this.outsideCloseSubscription.unsubscribe();
      }

      this.outsideCloseSubscription = this.takeUntil
        .subscribe(() => {
          this.popoverClose.next();
        });
    }
  }

  ngOnDestroy() {
    if (this.outsideCloseSubscription) {
      this.outsideCloseSubscription.unsubscribe();
    }
    this.popoverClose.next();
    this.popoverClose.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  private autoClose() {
    if (this.closeOnSelect) {
      this.dropdownOpen = false;
      this.popoverClose.next();
    }
  }
}
