<!-- Toolbar -->
<div class="toolbar">
  <mat-icon svgIcon="undo" class="toolbar-icon" (click)="execCommand('Undo')" matTooltip="Undo"></mat-icon>
  <mat-icon svgIcon="redo" class="toolbar-icon" (click)="execCommand('Redo')" matTooltip="Redo"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- Font Name -->
  <app-font-name-selector [currentFont]="formats.fontname" (select)="execCommand('fontname', $event)"></app-font-name-selector>
  <div class="toolbar-devider"></div>
  <!-- Font Size -->
  <app-dropdown [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        {{ formats.fontsize }}<mat-icon svgIcon="dropdown" class="dropdown-icon"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-single-select
      [options]="fontSizeOptions"
      [selected]="{name: formats.fontsize, key: formats.fontsize}"
      (selectedChange)="execCommand('fontsize', $event.key)"
    ></app-dropdown-single-select>
  </app-dropdown>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="bold" class="toolbar-icon" [class.selected]="formats.bold" (click)="execCommand('mceToggleFormat', 'bold')" matTooltip="Bold"></mat-icon>
  <mat-icon svgIcon="italic" class="toolbar-icon" [class.selected]="formats.italic" (click)="execCommand('mceToggleFormat', 'italic')" matTooltip="Italic"></mat-icon>
  <mat-icon svgIcon="underline" class="toolbar-icon" [class.selected]="formats.underline" (click)="execCommand('mceToggleFormat', 'underline')" matTooltip="Underline"></mat-icon>
  <!-- Forecolor -->
  <app-dropdown align="right" [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon svgIcon="color" class="toolbar-icon" [style.color]="formats.forecolor || '#000000'" matTooltip="Forecolor"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-color-select
      [selected]="formats.forecolor"
      (selectedChange)="execCommand('ForeColor', $event)"
    ></app-dropdown-color-select>
  </app-dropdown>
  <!-- Hilitecolor -->
  <app-dropdown align="right" [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon svgIcon="marker" class="toolbar-icon" [style.color]="formats.hilitecolor || '#FFDC00'" [class.selected]="formats.hilitecolor" matTooltip="Hilitecolor"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-color-select
      [selected]="formats.hilitecolor"
      (selectedChange)="execCommand('HiliteColor', $event)"
      (removeColor)="execCommand('mceToggleFormat', 'hilitecolor')"
    ></app-dropdown-color-select>
  </app-dropdown>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="add" class="toolbar-icon" (click)="fileUploadInput.click()" matTooltip="Add attachment"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="numbered-list" class="toolbar-icon" (click)="execCommand('InsertOrderedList')" matTooltip="Numeric list"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="bullet-list" class="toolbar-icon" (click)="execCommand('InsertUnorderedList')" matTooltip="Bulet list"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="outdent" class="toolbar-icon" (click)="execCommand('Outdent')" matTooltip="Outdent"></mat-icon>
  <mat-icon svgIcon="intdent" class="toolbar-icon" (click)="execCommand('Indent')" matTooltip="Indent"></mat-icon>
  <div *ngIf="showMessageActions" class="toolbar-devider"></div>
  <app-signature-selector *ngIf="showMessageActions" [signature]="signature" (signatureChange)="applySignature($event)"></app-signature-selector>
  <mat-icon svgIcon="collapse-down" class="collapse-icon right" [ngClass]="{collapsed: !toolbarExpanded}" (click)="toggleToolbar()"></mat-icon>
</div>
<!-- Second line -->
<div class="toolbar" *ngIf="toolbarExpanded">
  <!-- Line height -->
  <app-dropdown [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon svgIcon="interval" class="toolbar-icon" matTooltip="Vertical interval"></mat-icon>
        <mat-icon svgIcon="dropdown-small" class="dropdown-icon--small"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-single-select
      [options]="lineHeightOptions"
      [selected]="{name: formats.lineheight, key: formats.lineheight}"
      (selectedChange)="selectLineHeight($event)"
    ></app-dropdown-single-select>
  </app-dropdown>
  <div class="toolbar-devider"></div>
  <!-- Align text -->
  <app-dropdown [closeOnSelect]="true">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-dropdown">
        <mat-icon [svgIcon]="formats.align || 'alignleft'" class="toolbar-icon" matTooltip="Align text"></mat-icon>
        <mat-icon svgIcon="dropdown-small" class="dropdown-icon--small"></mat-icon>
      </div>
    </ng-template>
    <app-dropdown-template-items
      [options]="alignOptions"
      [optionTemplateRef]="alignItem"
      (selectedChange)="execCommand($event.action)"
    ></app-dropdown-template-items>
  </app-dropdown>
  <ng-template #alignItem let-option="option" let-select="select">
    <div class="dropdown-item" (click)="select(option)">
      <div class="text-with-icon">
        <mat-icon [svgIcon]="option.key" class="dropdown-item-icon"></mat-icon>
        <span>{{ option.name }}</span>
      </div>
      <mat-icon svgIcon="check" class="check-icon" *ngIf="option.key === formats.fontname"></mat-icon>
    </div>
  </ng-template>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="h1" class="toolbar-icon" [class.selected]="formats.h1" (click)="execCommand('mceToggleFormat', 'h1')" matTooltip="H1"></mat-icon>
  <mat-icon svgIcon="h2" class="toolbar-icon" [class.selected]="formats.h2" (click)="execCommand('mceToggleFormat', 'h2')" matTooltip="H2"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="strikeout" class="toolbar-icon" [class.selected]="formats.strikethrough" (click)="execCommand('mceToggleFormat', 'strikethrough')" matTooltip="Strikethrough"></mat-icon>
  <mat-icon svgIcon="plain-text" class="toolbar-icon" [class.selected]="formats.pasteText" (click)="execCommand('mceTogglePlainTextPaste')" matTooltip="Paste as text"></mat-icon>
  <mat-icon svgIcon="eraser" class="toolbar-icon" (click)="execCommand('delete')" matTooltip="Delete"></mat-icon>
  <mat-icon svgIcon="link" class="toolbar-icon" (click)="execCommand('mceLink')" matTooltip="Link"></mat-icon>
  <div class="toolbar-devider"></div>
  <mat-icon svgIcon="table" class="toolbar-icon" (click)="execCommand('mceInsertTable')" matTooltip="Add table"></mat-icon>
  <mat-icon svgIcon="pic" class="toolbar-icon" (click)="execCommand('mceImage')" matTooltip="Insert image"></mat-icon>
  <div class="toolbar-devider"></div>
  <!-- Emoji -->
  <div
    class="toolbar-icon"
    matTooltip="Emoji"
    popover
    popoverTrigger="click"
    [popoverContent]="emojiPopover"
    [popoverArrow]="false"
    [popoverShowUntil]="hideEmojiPopover"
  >🙂</div>
  <ng-template #emojiPopover>
    <emoji-mart
      class="emoji-wrapper"
      [showPreview]="false"
      [emojiSize]="16"
      [color]="'#409aff'"
      (emojiClick)="insertEmoji($event)"
    ></emoji-mart>
  </ng-template>

  <!-- Template -->
  <app-dropdown align="right" [closeOnSelect]="true" *ngIf="templatesOptions?.length || addNewTemplate.observers.length" class="right">
    <ng-template #toggleButtonTemplate>
      <div class="toolbar-text">Use Template</div>
    </ng-template>
    <app-dropdown-action-items
      [options]="templatesOptions"
      (actionSelected)="insertTemplate($event)"
      [divider]="templatesOptions?.length && addNewTemplate.observers.length"
    >
    </app-dropdown-action-items>
    <app-dropdown-action-items
      [options]="[{name: 'Add new template...', key: 'add'}]"
      (actionSelected)="addTemplate()"
      *ngIf="templatesOptions && addNewTemplate.observers.length"
    >
    </app-dropdown-action-items>
  </app-dropdown>
</div>

<!-- File input -->
<input #fileUploadInput type="file" class="hidden" (change)="selectedFiles($event)" multiple>

<!-- Editor -->
<div class="editor-wrapper">
  <editor
    class="editor"
    [ngModel]="content"
    (ngModelChange)="contentChanged($event)"
    [init]="editorSettings"
    (onInit)="editorInitialized($event)"
    (onDragOver)="editorDragOver($event)"
  >
  </editor>
</div>
