// Common
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

// RxJS
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { SearchService } from '@modules/search/services/search.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { Note } from '@modules/notes/types/note';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

@Component({
  selector: 'app-notes-list-container',
  templateUrl: './notes-list-container.component.html',
  styleUrls: ['./notes-list-container.component.less']
})
export class NotesListContainerComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() notesFilters: NotesFilters = {};
  @Input() selectedItems: Note[];

  // Outputs
  @Output() selectedItemsChanged: EventEmitter<Note[]> = new EventEmitter();

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public minimized: boolean;
  public searchQuery: string;
  public quickFormExpanded = false;
  public listFilters: NotesFilters = {};
  public searchFilters: NotesFilters = {};
  public loading: Boolean;
  public quickFormNote: Note = new Note();
  public quickFormToggle: Subject<void> = new Subject();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private searchTitle = new BehaviorSubject('');

  /**
   * Constructor
   */

  constructor(
    private splitViewService: SplitViewService,
    private searchService: SearchService,
  ) {
    combineLatest([
      this.searchService.getSearchParams(),
      this.searchTitle
    ])
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(([searchParams, title]) => {
        const keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];

        this.searchFilters = {
          ...searchParams,
          keywords: !title || title.trim() === '' ? keywords : [...keywords, title]
        };
        this.updateListFilters();
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('notesList')
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('notesFilters' in changes) {
      this.updateListFilters();
    }
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Methods
   */

  updateListFilters() {
    this.listFilters = {
      ...this.notesFilters,
      ...this.searchFilters
    };
  }

  /**
   * Actions
   */

  handleScrollOptionChange(value: number) {
    this.listScrollPosition = null;
    setTimeout(() => this.listScrollPosition = value);
  }

  handleNewNote(note = new Note()) {

  }

  handleSearchQueryChange(title: string) {
    this.searchTitle.next(title);
  }

  selectItems(notes: Note[]) {
    this.selectedItems = notes;
    this.selectedItemsChanged.emit(notes);
  }

  handleQuickFormDrop(dragData: DragData) {
    const note = Note.fromDragData(dragData);

    if (!note) { return; }

    this.quickFormNote = new Note(note);
    this.quickFormToggle.next();
  }
}
