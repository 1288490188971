// Common
import {
  Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef,
  ComponentFactoryResolver, ComponentRef, OnInit, ChangeDetectorRef
} from '@angular/core';

// Components
import { OrderedItemComponent } from '../components/ordered-item/ordered-item.component';

// RX
import { Subject, combineLatest, ReplaySubject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

// Types
import { Direction } from '../types/direction';
import { DragData } from '../types/drag-data';

// Services
import { DragNDropService } from '../services/drag-n-drop.service';

@Directive({
  selector: '[appDraggableListFor]'
})
export class ForOrderedItemsDirective implements OnInit, OnChanges {
  // https://github.com/angular/angular/issues/12121

  // Private
  private alive: Subject<void> = new Subject();
  private children: ComponentRef<OrderedItemComponent>[] = [];
  private childredSizeCollection: ReplaySubject<void> = new ReplaySubject();
  private placeholderSize = 0;

  // Inputs
  @Input() appDraggableListForOf: any[];
  @Input() appDraggableListForTrackBy: Function;
  @Input() appDraggableListForRepositionStream: Subject<DragData>;
  @Input() appDraggableListForDirection: Direction = 'vertical';
  @Input() appDraggableListForPredicate: (dragData: DragData) => boolean;
  @Input() appDraggableListForZIndex: number;
  @Input() appDraggableListForDisabled = false;

  /**
   * Constructor
   */

  constructor (
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private dragNDropService: DragNDropService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.childredSizeCollection
      .pipe(
        switchMap(() => (
          combineLatest(
            this.children.map(child => child.instance.sizeChange)
          )
        )),
        takeUntil(this.alive)
      )
      .subscribe((sizes: number[]) => {
        this.children.forEach(child => {
          child.instance.neighborsSizes = sizes;
        });
      });

    this.dragNDropService.getCurrentPlaceholder()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((element: HTMLElement) => {
        if (!element) { return; }

        this.placeholderSize = element.getBoundingClientRect()[
          this.appDraggableListForDirection === 'vertical' ? 'height' : 'width'
        ];

        this.children.forEach(child => {
          child.instance.placeholderSize = this.placeholderSize;
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.appDraggableListForOf &&
      this.appDraggableListForOf
    ) {
      // TODO we can use trackBy and check somehow whether do we need to recreate component
      // or use existing with new inputs to decrease DOM manipulations count
      this.viewContainerRef.clear();
      this.children = [];

      this.appDraggableListForOf.forEach((item: any, index: number) => {
        const factory = this.resolver.resolveComponentFactory(OrderedItemComponent);
        const orderedItemRef = this.viewContainerRef.createComponent(factory);
        orderedItemRef.instance.itemTemplateRef = this.templateRef;
        orderedItemRef.instance.index = index;
        orderedItemRef.instance.implicit = item;
        orderedItemRef.instance.trackByFn = this.appDraggableListForTrackBy;
        orderedItemRef.instance.direction = this.appDraggableListForDirection;
        orderedItemRef.instance.zIndex = this.appDraggableListForZIndex;
        orderedItemRef.instance.repositionStream = this.appDraggableListForRepositionStream;
        orderedItemRef.instance.predicate = this.appDraggableListForPredicate;
        orderedItemRef.instance.draggingStart.subscribe(() => this.setDragging(index));
        orderedItemRef.instance.disabled = this.appDraggableListForDisabled;

        this.children.push(orderedItemRef);
      });

      const lastItemfactory = this.resolver.resolveComponentFactory(OrderedItemComponent);
      const lastOrderedItemRef = this.viewContainerRef.createComponent(lastItemfactory);
      lastOrderedItemRef.instance.index = this.appDraggableListForOf.length;
      lastOrderedItemRef.instance.direction = this.appDraggableListForDirection;
      lastOrderedItemRef.instance.zIndex = this.appDraggableListForZIndex;
      lastOrderedItemRef.instance.predicate = this.appDraggableListForPredicate;
      lastOrderedItemRef.instance.repositionStream = this.appDraggableListForRepositionStream;
      lastOrderedItemRef.instance.disabled = this.appDraggableListForDisabled;

      this.children.push(lastOrderedItemRef);

      this.childredSizeCollection.next();
    }
  }

  /**
   * Actions
   */

  setDragging(index: number) {
    this.children.forEach(child => {
      child.instance.draggingIndex = index;
    });
  }
}
