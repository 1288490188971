// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Direction } from '../types/direction';

@Pipe({
  name: 'orderedItemPlaceholderSize'
})
export class OrderedItemPlaceholderSizePipe implements PipeTransform {

  transform(direction: Direction, hovered: boolean, placeholderSize: number): Object {
    if (direction === 'vertical') {
      return {
        height: `${hovered ? placeholderSize : 0}px`,
        width: '100%'
      };
    } else {
      return {
        width: `${hovered ? placeholderSize : 0}px`,
        height: '100%'
      };
    }
  }
}
