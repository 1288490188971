/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-form-popup.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tasks/components/task-form/task-form.component.ngfactory";
import * as i3 from "../../../tasks/components/task-form/task-form.component";
import * as i4 from "../../../tasks/services/tasks.service";
import * as i5 from "../../../tasks/services/projects.service";
import * as i6 from "../../../pages/services/dynamic-panel.service";
import * as i7 from "../../../tasks/services/columns.service";
import * as i8 from "../../../linked-info/services/linked-info.service";
import * as i9 from "../../../topic/services/topic.service";
import * as i10 from "../../../tag/services/tag.service";
import * as i11 from "../../../tasks/services/tasks-state.service";
import * as i12 from "./task-form-popup.component";
var styles_TaskFormPopupComponent = [i0.styles];
var RenderType_TaskFormPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskFormPopupComponent, data: {} });
export { RenderType_TaskFormPopupComponent as RenderType_TaskFormPopupComponent };
export function View_TaskFormPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-task-form", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TaskFormComponent_0, i2.RenderType_TaskFormComponent)), i1.ɵdid(2, 770048, null, 0, i3.TaskFormComponent, [i4.TasksService, i5.ProjectsService, i6.DynamicPanelService, i7.ColumnsService, i8.LinkedInfoService, i9.TopicService, i10.TagService, i11.TasksStateService], { appearance: [0, "appearance"] }, { close: "close" })], function (_ck, _v) { var currVal_0 = "headerFixedBig"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TaskFormPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-form-popup", [], null, null, null, View_TaskFormPopupComponent_0, RenderType_TaskFormPopupComponent)), i1.ɵdid(1, 49152, null, 0, i12.TaskFormPopupComponent, [], null, null)], null, null); }
var TaskFormPopupComponentNgFactory = i1.ɵccf("app-task-form-popup", i12.TaskFormPopupComponent, View_TaskFormPopupComponent_Host_0, {}, {}, []);
export { TaskFormPopupComponentNgFactory as TaskFormPopupComponentNgFactory };
