// Common
import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';

// RX
import { Subject } from 'rxjs';

// Types
import { Project } from '@modules/tasks/types/project';
import { ColumnsTree } from '@modules/tasks/types/columns-tree';
import { Column } from '@modules/tasks/types/column';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Services
import { switchMap, takeUntil } from 'rxjs/operators';
import { ColumnsTreeService } from '@modules/tasks/services/columns-tree.service';

@Component({
  selector: 'app-project-board',
  templateUrl: './project-board.component.html',
  styleUrls: ['./project-board.component.less'],
})
export class ProjectBoardComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() project: Project;

  // Public
  public columnsTree: ColumnsTree;
  public repositionStream: Subject<DragData> = new Subject<null>();

  // Private
  private alive: Subject<void> = new Subject();
  private projectChanged = new Subject<string>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => dragData.type === 'column';

  /**
   * Constructor
   */

  constructor (
    private columnsTreeService: ColumnsTreeService
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.projectChanged
      .pipe(
        switchMap((projectId => this.columnsTreeService.getColumns(projectId))),
        takeUntil(this.alive)
      )
      .subscribe((tree: ColumnsTree) => {
        this.columnsTree = tree;
      });

    if (this.project) {
      this.projectChanged.next(this.project.id);
    }

    this.repositionStream.subscribe((data: DragData) => {
      this.columnsTreeService.moveColumn(this.columnsTree, data.data[0] as Column, data.index);
    });
  }

  ngOnChanges (changes: SimpleChanges) {
    if ('project' in changes && this.project) {
      this.projectChanged.next(this.project.id);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Helpers
   */

  columnsTrackByFn(column: Column): string {
    return column && column.id;
  }
}
