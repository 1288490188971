<div class="container" [ngClass]="{expanded: !minimized}">
  <div class="container-header" *ngIf="!minimized">
    <app-notes-list-header
      (selectedOrderChange)="selectedOrder = $event"
      (scrollOptionChange)="handleScrollOptionChange($event)"
      (searchQueryChange)="handleSearchQueryChange($event)"
      [loading]="loading"
    ></app-notes-list-header>

    <div class="shadow"></div>
  </div>
  
  <div class="container-quick-form" *ngIf="!minimized">
    <app-quick-form
      bundledGroup="notesListQuickForm"
      placeholder="Add New Note"
      [toggle]="quickFormToggle"
      (collapsedChange)="quickFormExpanded = $event"
      (clickMore)="handleNewNote()"
      (itemDropped)="handleQuickFormDrop($event)"
    >
      <app-note-quick-form
        bundledGroup="notesListQuickForm"
        [note]="quickFormNote"
        (more)="handleNewNote($event)"
      ></app-note-quick-form>
    </app-quick-form>
    <div class="shadow" [ngClass]="{hidden: !quickFormExpanded}"></div>
  </div>

  <div class="container-list" *ngIf="!minimized">
    <app-notes-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
      [selectedItems]="selectedItems"
      (selectedItemsChanged)="selectItems($event)"
      (loadInProgress)="loading = $event"
    ></app-notes-list>
  </div>
</div>
