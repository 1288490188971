import { Injectable } from '@angular/core';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PopupWrapperComponent } from '../components/popup-wrapper/popup-wrapper.component';

@Injectable()
export class PopupGuardService implements CanDeactivate<PopupWrapperComponent> {

  constructor(private router: Router) {}

  canDeactivate(component: PopupWrapperComponent,
             currentRoute: ActivatedRouteSnapshot,
             currentState: RouterStateSnapshot,
             nextState?: RouterStateSnapshot) {
    alert('Sorry, but you can`t navigate to another screen in compact mode.');
    return false;
  }

}
