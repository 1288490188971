import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
// RxJS
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { Note } from '@modules/notes/types/note';
var NotesListContainerComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function NotesListContainerComponent(splitViewService, searchService) {
        var _this = this;
        this.splitViewService = splitViewService;
        this.searchService = searchService;
        // Inputs
        this.notesFilters = {};
        // Outputs
        this.selectedItemsChanged = new EventEmitter();
        this.quickFormExpanded = false;
        this.listFilters = {};
        this.searchFilters = {};
        this.quickFormNote = new Note();
        this.quickFormToggle = new Subject();
        // Private
        this.componentNotDestroyed = new Subject();
        this.searchTitle = new BehaviorSubject('');
        combineLatest([
            this.searchService.getSearchParams(),
            this.searchTitle
        ])
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), searchParams = _b[0], title = _b[1];
            var keywords = searchParams && searchParams.keywords ? searchParams.keywords : [];
            _this.searchFilters = tslib_1.__assign({}, searchParams, { keywords: !title || title.trim() === '' ? keywords : tslib_1.__spread(keywords, [title]) });
            _this.updateListFilters();
        });
    }
    /**
     * Component lifecycle
     */
    NotesListContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splitViewService.getMinimized('notesList')
            .pipe(takeUntil(this.componentNotDestroyed))
            .subscribe(function (minimized) {
            _this.minimized = minimized;
        });
    };
    NotesListContainerComponent.prototype.ngOnChanges = function (changes) {
        if ('notesFilters' in changes) {
            this.updateListFilters();
        }
    };
    NotesListContainerComponent.prototype.ngOnDestroy = function () {
        this.componentNotDestroyed.next();
        this.componentNotDestroyed.complete();
    };
    /**
     * Methods
     */
    NotesListContainerComponent.prototype.updateListFilters = function () {
        this.listFilters = tslib_1.__assign({}, this.notesFilters, this.searchFilters);
    };
    /**
     * Actions
     */
    NotesListContainerComponent.prototype.handleScrollOptionChange = function (value) {
        var _this = this;
        this.listScrollPosition = null;
        setTimeout(function () { return _this.listScrollPosition = value; });
    };
    NotesListContainerComponent.prototype.handleNewNote = function (note) {
        if (note === void 0) { note = new Note(); }
    };
    NotesListContainerComponent.prototype.handleSearchQueryChange = function (title) {
        this.searchTitle.next(title);
    };
    NotesListContainerComponent.prototype.selectItems = function (notes) {
        this.selectedItems = notes;
        this.selectedItemsChanged.emit(notes);
    };
    NotesListContainerComponent.prototype.handleQuickFormDrop = function (dragData) {
        var note = Note.fromDragData(dragData);
        if (!note) {
            return;
        }
        this.quickFormNote = new Note(note);
        this.quickFormToggle.next();
    };
    return NotesListContainerComponent;
}());
export { NotesListContainerComponent };
