<div class="file-uploader">
  <div
    class="file-uploader-droppable-area"
    *ngIf="!!attachmentsFormControl"

    (drop)="dragOverEventsCounter = 0; uploadDroppedFiles($event)"
    (dragenter)="dragOverEventsCounter = dragOverEventsCounter + 1;"
    (dragleave)="dragOverEventsCounter = dragOverEventsCounter - 1;"
    [class.file-uploader-drag-over]="dragOverEventsCounter > 0"
    #droppableArea
  >
    <div class="file-uploader-droppable-area-inner">
      Drop files to attach, or&nbsp;<a class="file-uploader-droppable-area-inner-link" (click)="fileUploadInput.click()">browse</a>
    </div>
  </div>

  <input #fileUploadInput type="file" class="hidden" (change)="uploadSelectedFiles($event)" multiple>

  <div
    class="attachments"
    [class.empty]="uploads.length === 0"
    [@expandAnimation]="expandedAttachments ? 'expanded' : 'collapsed'"
  >
    <div
      class="attachment"
      [class.to-hide]="i > 4"
      *ngFor="let upload of uploads | expandAttachments: expandedAttachments: amountOfCollapsedAttachments; index as i"
    >
      <div class="attachment-file">
        <app-attachment-icon [type]="upload.attachment?.type" class="attachment-file-icon"></app-attachment-icon>
        <div class="attachment-file-name" (click)="showAttachment(upload)" *ngIf="upload.status !== 'error'">
          {{ upload.attachment?.name || upload.file?.name || 'No name' }}
        </div>
        <mat-progress-bar *ngIf="upload.status === 'uploading'" class="progress-bar" mode="indeterminate"></mat-progress-bar>
        <div class="attachment-file-name error" *ngIf="upload.status === 'error'">
          {{ upload.error || 'Can\'t upload file'}}
        </div>
        <mat-icon *ngIf="upload.status === 'uploaded' || upload.status === 'error'" class="attachment-file-remove-icon" svgIcon="close" (click)="removeFile(upload)"></mat-icon>
        <mat-icon *ngIf="upload.status === 'uploading'" class="attachment-file-remove-icon uploading" svgIcon="close" (click)="cancelRequest(upload)"></mat-icon>
      </div>
    </div>
    <div
      class="attachments-show-more"
      *ngIf="uploads.length > amountOfCollapsedAttachments"
      (click)="handleExpand()"
    >
      <span
        class="attachments-show-more-text"
        *ngIf="!expandedAttachments"
      >+{{ uploads.length - amountOfCollapsedAttachments }}</span>
      <mat-icon
        class="attachments-show-more-icon"
        *ngIf="expandedAttachments"
        svgIcon="arrow-left"
      ></mat-icon>
    </div>
  </div>
</div>

