<div class="container" *ngIf="!minimized">
  <div class="container-header">
    <app-knowledge-header
      [title]="currentSidebarFilter | titleBySidebarFilter : selectedSidebarGroup"
      [orders]="orders"
      [actions]="scrollOptions"
      [selected]="selectedOrder"
      (selectedChange)="handleSelectOrder($event)"
      (actionClick)="handleScrollOptionChange($event)"
      [loading]="loading"
    ></app-knowledge-header>
  </div>

  <mat-tab-group *ngIf="listState === 'tabs'">
    <mat-tab label="Contacts">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="contactsListTemplate"></ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Groups">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="groupsListTemplate"></ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="listState === 'contacts'" [ngTemplateOutlet]="contactsListTemplate"></ng-container>
  <ng-container *ngIf="listState === 'groups'" [ngTemplateOutlet]="groupsListTemplate"></ng-container>
</div>

<ng-template #contactsListTemplate>
  <div class="container-quick-form">
    <div class="shadow"></div>

    <app-quick-form
      placeholder="Add New Contact"
      bundledGroup="contactsListQuickForm"
      [toggle]="quickFormToggle"
      (clickMore)="handleNewContact()"
      (collapsedChange)="quickFormExpanded = $event"
      (itemDropped)="handleContactQuickFormDrop($event)"
    >
      <app-contact-quick-form
        bundledGroup="contactsListQuickForm"
        [contact]="quickFormContact"
        (more)="handleNewContact($event)"
      ></app-contact-quick-form>
    </app-quick-form>
  </div>

  <div class="shadow"></div>

  <div class="container-list">
    <app-contacts-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
      (loadInProgress)="loading = $event"
    ></app-contacts-list>
  </div>
</ng-template>

<ng-template #groupsListTemplate>
  <div class="container-quick-form">
    <div class="shadow"></div>

    <app-quick-form
      placeholder="Add New Group"
      bundledGroup="groupsListQuickForm"
      [toggle]="quickFormToggle"
      (clickMore)="handleNewGroup()"
      (collapsedChange)="quickFormExpanded = $event"
      (itemDropped)="handleGroupQuickFormDrop($event)"
    >
      <app-group-quick-form
        bundledGroup="groupsListQuickForm"
        [group]="quickFormGroup"
        (more)="handleNewGroup($event)"
      ></app-group-quick-form>
    </app-quick-form>
  </div>

  <div class="shadow"></div>

  <div class="container-list">
    <app-groups-list
      [selectedOrder]="selectedOrder"
      [scrollPosition]="listScrollPosition"
      [defaultFilters]="listFilters"
    ></app-groups-list>
  </div>
</ng-template>
