import * as tslib_1 from "tslib";
// Common
import { OnInit, ElementRef } from '@angular/core';
// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
var MailContentModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MailContentModalComponent, _super);
    /**
     * Constructor
     */
    function MailContentModalComponent(element, popupService, ga) {
        var _this = _super.call(this) || this;
        _this.element = element;
        _this.popupService = popupService;
        _this.ga = ga;
        // TODO - do we need availableExpand ?
        _this.availableExpand = true;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 500px)',
            y: '40px',
            width: '1000px',
            height: 'calc(100% - 100px)'
        };
        _this.selectedRelatedContacts = [];
        _this.selectedRelatedTopics = [];
        _this.selectedTemporalExpressions = [];
        _this.onReplayMessageCallback = _this.onReplayMessage.bind(_this);
        return _this;
    }
    /**
     * Component lifecycle
     */
    MailContentModalComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    /**
     * Actions
     */
    MailContentModalComponent.prototype.getModalContent = function () {
        var modalContent = this.element.nativeElement.parentElement;
        return modalContent.parentElement;
    };
    MailContentModalComponent.prototype.onReplayMessage = function () {
        var modalContent = this.getModalContent();
        var frame = {
            width: modalContent.offsetWidth + "px",
            height: modalContent.offsetHeight + "px",
            x: modalContent.offsetLeft + "px",
            y: modalContent.offsetTop + "px"
        };
        this.close();
        return frame;
    };
    MailContentModalComponent.prototype.selectTopics = function (topics) {
        this.selectedTopics = topics ? topics.map(function (topic) { return topic.name; }) : [];
    };
    MailContentModalComponent.prototype.expand = function () {
        this.ga.trackEvent('mail-content-modal', 'expand');
        this.popupService.mailContent(this.message.id, this.maximizedSize.popupSize());
        this.close();
    };
    return MailContentModalComponent;
}(BaseModalComponent));
export { MailContentModalComponent };
