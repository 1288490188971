// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';

// Services
import { AsyncTasksService } from './services/async-tasks.service';
import { AsyncTasksToastsService } from './services/async-tasks-toasts.service';

// Components
import { AsyncTaskToastComponent } from './components/async-task-toast/async-task-toast.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ToastrModule.forRoot({
      preventDuplicates: false,
      timeOut: 10000,
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      tapToDismiss: false,
      toastComponent: AsyncTaskToastComponent
    }),
  ],
  declarations: [
    AsyncTaskToastComponent,
  ],
  providers: [
    AsyncTasksService, AsyncTasksToastsService
  ],
  entryComponents: [AsyncTaskToastComponent]
})
export class AsyncTasksModule { }
