// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';
import { SidebarFilterKey, ContactsSidebarFilterKey } from '@modules/settings/types/sidebar-filters-state';

// Services
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { StateService } from '@modules/settings/services/state.service';
import { ContentMenuService } from '@modules/content-menu/services/content-menu.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactsListResponse } from '@modules/contacts/types/contacts-list-response';

@Component({
  selector: 'app-contacts-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
  providers: [ContentMenuService]
})

export class ContactsSideBarComponent implements OnInit, OnDestroy {

  // Public
  public groups: Group[] = [];
  public contactsCount: number = null;
  public sidebarFilter: ContactsSidebarFilterKey;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private sidebarFiltersKey: SidebarFilterKey = 'contacts';

  /**
   * Constructor
   */

  constructor(
    private contactsStateService: ContactsStateService,
    private groupsService: GroupsService,
    private contactsService: ContactsService,
    private stateService: StateService,
    private splitViewService: SplitViewService,
    private contentMenuService: ContentMenuService,
  ) {
    this.stateService.getSidebarFilters(this.sidebarFiltersKey)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((filter: ContactsSidebarFilterKey) => {
        this.sidebarFilter = filter;
      });

    this.splitViewService.getMinimized('contactsSidebar')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(minimized => {
        this.contentMenuService.setMinimized(minimized);
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit () {
    this.groupsService.getGroups()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({ groups }) => this.groups = groups);

    this.contactsService.getContacts({ limit: 0 })
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({ count }: ContactsListResponse) => this.contactsCount = count);
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleGroupClick(group: Group, event: MouseEvent) {
    this.contactsStateService.setGroup(group);
    // this.contactsStateService.setListState('contacts');
    this.selectFilter(group.id, event);
  }

  selectFilter(filter: ContactsSidebarFilterKey, event: MouseEvent) {
    this.stateService.setSidebarFilters(this.sidebarFiltersKey, filter);
    event.stopPropagation();
  }
}
