// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone } from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { ContactsStateService } from '@modules/contacts/services/contacts-state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { Contact } from '@modules/contacts/types/contact';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.less'],
})
export class ContactsListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no contacts';
  @Input() defaultFilters: ContactsFilters = {};

  // Public
  public itemHeight = 72;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);

  /**
   * Constructor
   */

  constructor(
    private contactsService: ContactsService,
    private contactsStateService: ContactsStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone
  ) {
    super(ngZone);

    this.contactsStateService.getSelectedContacts()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((contacts: Contact[]) => {
        this.selectedItems = contacts;
      });
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      // Global refresh button
      this.contactsStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getLinkedInfoUpdate(),
      // Make automatic updates for new contact
      interval(environment.messageFetchInterval)
        .pipe(
          filter(() => !this.loading && this.selectedOrder && this.selectedOrder.key === 'date')
        ),
      this.contactsService.createNewContact,
      this.contactsService.updatedContact,
      this.contactsService.deletedContact,
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('defaultFilters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.contactsService
      .getContacts({
        ...this.defaultFilters,
        limit,
        offset
      })
      .pipe(
        map(({ contacts, count }) => ({ items: contacts, count }))
      );
  }

  resetItems() {
    this.contactsStateService.setSelectedContacts([]);
    super.resetItems();
  }

  selectItem(contact: Contact, event: MouseEvent|KeyboardEvent, selectAll = false) {
    super.selectItem(contact, event, selectAll);

    this.contactsStateService.setSelectedContacts(this.selectedItems as Contact[]);

    if (this.selectedItems.length === 1) {
      this.contactsStateService.setMainViewContact(this.selectedItems[0] as Contact);
      this.contactsStateService.setMainView('contact-form');
    }
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
