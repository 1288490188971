<div
  *ngIf="project"
  class="project"
  [ngClass]="{selected: selected}"
  (dblclick)="handleDoubleClick(project)"
  [appProjectContextMenu]="project"
  (contextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableEnabled]="dragEnabled"
  [appDraggableData]="{data: dragData, type: 'project'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"
  [appDraggableContainerMultiple]="dragData.length > 1"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="project-icons">
    <mat-icon *ngIf="!project.pinned" class="project-icons-small-icon pin" svgIcon="mail-pinned"></mat-icon>
    <mat-icon *ngIf="!project.starred" class="project-icons-small-icon star" svgIcon="starred"></mat-icon>
  </div>

  <div class="project-info">
    <div class="project-info-title">
      <mat-icon class="project-info-title-icon" svgIcon="circle"></mat-icon>
      <span class="project-info-title-text">{{ project.title }}</span>
    </div>

    <div class="project-info-date-time">
      <app-date-time
        [startDate]="project.fromTime"
        [startTime]="project.fromTime"
        [endDate]="project.toTime"
        [endTime]="project.toTime"
      ></app-date-time>
    </div>

    <div class="project-info-description" [class.empty]="!project.description?.trim()">{{ project.description }}</div>

    <app-link-info-bar [linkedInfo]="project.linkedInfo"></app-link-info-bar>
  </div>

  <div class="project-icons right">
    <div class="project-icons-hideable">
      <mat-icon class="project-icons-hideable-type-icon" svgIcon="project"></mat-icon>
    </div>

    <div
      *ngIf="tasksCount > 0"
      class="project-icons-thread-button"
      (click)="handleExpand()"
    >
      <div class="project-icons-thread-button-title">{{ tasksCount }}</div>
      <mat-icon class="project-icons-thread-button-icon" [class.open]="threadExpanded" svgIcon="dropdown-small"></mat-icon>
    </div>
  </div>
</div>
<div
  *ngIf="threadExpanded && sectionsTree?.tree.length"
  class="sections-container"
  [@expandMotion]
>
  <app-project-section
    *ngFor="let section of sectionsTree?.tree; let last = last"
    [section]="section"
    [sectionsTree]="sectionsTree"
    [last]="last"
    [allowedNewTasksSections]="false"
  ></app-project-section>
</div>

<!-- Project Placeholder -->
<div class="project-placeholder" *ngIf="!project">
  <div class="project-placeholder-icons"></div>
  <div class="project-placeholder-info">
    <div class="project-placeholder-info-title">
      <div class="project-placeholder-info-title-name"></div>
      <div class="project-placeholder-info-title-date"></div>
    </div>
    <div class="project-placeholder-info-subject"></div>
    <div class="project-placeholder-info-subject"></div>
  </div>
</div>

<!-- Drag Placeholder -->
<ng-template #dragPlaceholder>
  <app-project [project]="project" [dragEnabled]="false"></app-project>
</ng-template>
