// Common
import { Component, OnInit, Input } from '@angular/core';

// Types
import { MailMessageHighlightedSnippetPart } from '../../../mail/types/mail-message-highlighted-snippet-part';

@Component({
  selector: 'app-message-snippet',
  templateUrl: './message-snippet.component.html',
  styleUrls: ['./message-snippet.component.less']
})
export class MessageSnippetComponent implements OnInit {

  // Inputs
  @Input() snippet: MailMessageHighlightedSnippetPart[];

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

}
