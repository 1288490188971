import * as tslib_1 from "tslib";
import { warmUpObservable } from '@decorators';
// Rx
import { throwError, Subject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Column } from '../types/column';
// Env
import { environment } from '@environment';
var ColumnsService = /** @class */ (function () {
    /**
     * Constructor
     */
    function ColumnsService(http, asyncTasksToastsService) {
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.columnCreated = new Subject();
        this.columnUpdated = new Subject();
        this.columnDeleted = new Subject();
    }
    ColumnsService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Methods
     */
    ColumnsService.prototype.getColumns = function (filters) {
        if (filters === void 0) { filters = {}; }
        var params = {};
        if (filters.projectsIds) {
            params['projects_ids'] = filters.projectsIds;
        }
        return this.http.get(environment.baseUrl + '/api/projects/columns', { params: params })
            .pipe(map(function (_a) {
            var columns = _a.columns;
            return ({ columns: columns.map(function (column) { return new Column(column); }) });
        }), catchError(ColumnsService.handleObserverError));
    };
    ColumnsService.prototype.create = function (newColumn) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/projects/columns', newColumn.asPayloadJSON())
            .pipe(tap(function (_a) {
            var column = _a.column, success = _a.success;
            if (success) {
                _this.columnCreated.next();
                _this.asyncTasksToastsService.show({ text: "Column created" });
            }
        }), map(function (_a) {
            var column = _a.column;
            return new Column(column);
        }));
    };
    ColumnsService.prototype.update = function (columnInstance) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/projects/columns/' + columnInstance.id, columnInstance.asPayloadJSON())
            .pipe(tap(function (_a) {
            var column = _a.column, success = _a.success;
            if (success) {
                _this.columnUpdated.next();
                _this.asyncTasksToastsService.show({ text: "Column updated" });
            }
        }), map(function (_a) {
            var column = _a.column;
            return new Column(column);
        }));
    };
    ColumnsService.prototype.reorder = function (columnsIds) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/projects/columns', { saveOrder: true }, { params: { columns_ids: columnsIds } })
            .pipe(tap(function (_a) {
            var success = _a.success;
            if (success) {
                _this.columnUpdated.next();
            }
        }), map(function (_a) {
            var columns = _a.columns;
            return columns.map(function (column) { return new Column(column); });
        }));
    };
    ColumnsService.prototype.deletePermanently = function (columnIds) {
        var _this = this;
        return forkJoin(columnIds.map(function (columnId) { return _this.http.delete(environment.baseUrl + '/api/projects/columns/' + columnId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.columnDeleted.next();
                _this.asyncTasksToastsService.show({ text: "Column(s) successfully deleted." });
            }
        }));
    };
    tslib_1.__decorate([
        warmUpObservable
    ], ColumnsService.prototype, "create", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ColumnsService.prototype, "update", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ColumnsService.prototype, "reorder", null);
    tslib_1.__decorate([
        warmUpObservable
    ], ColumnsService.prototype, "deletePermanently", null);
    return ColumnsService;
}());
export { ColumnsService };
