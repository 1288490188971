// Common
import { Component, AfterViewInit, AfterContentChecked, ElementRef, ViewChild, Input, OnDestroy } from '@angular/core';

// RX
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-container-overflow-shadow',
  templateUrl: './container-overflow-shadow.component.html',
  styleUrls: ['./container-overflow-shadow.component.less']
})
export class ContainerOverflowShadowComponent implements AfterViewInit, AfterContentChecked, OnDestroy {

  // View Children
  @ViewChild('content', { static: true }) contentElement: ElementRef;

  // Input
  @Input() appearance: 'white' | 'gray' = 'white';

  // Public
  public showTopShadow = false;
  public showBottomShadow = false;

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngAfterViewInit() {
    this.updateShadow();
    fromEvent(this.contentElement.nativeElement, 'scroll')
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => this.updateShadow());
  }

  ngAfterContentChecked() {
    this.updateShadow();
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  updateShadow() {
    const element = this.contentElement.nativeElement;
    const allowScroll = element.scrollHeight > element.offsetHeight;
    this.showTopShadow = allowScroll && (element.scrollTop > 0);
    this.showBottomShadow = allowScroll && (element.scrollHeight - element.offsetHeight) !== element.scrollTop;
  }

}
