import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class FilesService {

  private files = [
    {
      id: 1,
      title: 'July Invoice.pdf',
      description: 'Eva Martins: New design for Stich App',
      type: 'pdf',
      pinned: true,
      starred: false
    },
    {
      id: 2,
      title: 'Questions for discussing.doc',
      description: 'Kseniia Novytska: Detailed below the most…',
      type: 'doc',
      pinned: false,
      starred: true
    },
    {
      id: 3,
      title: 'Combined price list.pdf',
      description: 'Olga Savchenko: Design Review',
      type: 'pdf',
      pinned: false,
      starred: false
    },
    {
      id: 4,
      title: 'June Invoice.pdf',
      description: 'Eva Martins: No Subject',
      type: 'pdf',
      pinned: false,
      starred: true
    },
    {
      id: 5,
      title: 'Price List.xls',
      description: 'Task: Design Review',
      type: 'xls',
      pinned: false,
      starred: true
    },
    {
      id: 6,
      title: 'List of participants.doc',
      description: 'Note: QA Test',
      type: 'doc',
      pinned: false,
      starred: false
    },
    {
      id: 7,
      title: 'Concept for icons.jpg',
      description: 'Kseniia Novytska: Detailed below the most…',
      type: 'jpg',
      pinned: false,
      starred: true
    },
    {
      id: 8,
      title: 'New concept of Stitch.pdf',
      description: 'Eva Martins: Design for Icons',
      type: 'pdf',
      pinned: false,
      starred: false
    },
    {
      id: 9,
      title: 'eTicket-546.jpg',
      description: 'Payoneer: International Prepaid Mastercard…',
      type: 'jpg',
      pinned: false,
      starred: false
    }
  ];

  constructor() { }

  /**
   * Methods
   */

  getFiles(filters: any = {}): Observable<any> {
    return of({ files: this.files, count: this.files.length });
  }
}
