import * as tslib_1 from "tslib";
// Common
import { NgZone } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
// Class
import { LinkInfoPopover } from '../link-info-popover';
var LinkedInfoNoteListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkedInfoNoteListComponent, _super);
    /**
     * Constructor
     */
    function LinkedInfoNoteListComponent(modalService, notesService, ngZone) {
        var _this = _super.call(this, ngZone) || this;
        _this.modalService = modalService;
        _this.notesService = notesService;
        _this.ngZone = ngZone;
        _this.type = 'note';
        return _this;
    }
    /**
     * Methods
     */
    LinkedInfoNoteListComponent.prototype.getItems = function (ids) {
        return this.notesService.getNotes({ notesIds: ids, order: 'created-asc', limit: ids.length, offset: 0 })
            .pipe(takeUntil(this.componentNotDestroyed), map(function (response) { return response.notes; }));
    };
    /**
     * Actions
     */
    LinkedInfoNoteListComponent.prototype.select = function (note) {
        this.modalService.noteForm(note);
    };
    return LinkedInfoNoteListComponent;
}(LinkInfoPopover));
export { LinkedInfoNoteListComponent };
