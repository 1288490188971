// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// Components
import { TextEditorComponent } from '@modules/common/components/text-editor/text-editor.component';

// Types
import { NoteBody } from '@modules/notes/types/note';
import { StateService } from '@modules/settings/services/state.service';

@Component({
  selector: 'app-note-editor',
  templateUrl: './note-editor.component.html',
  styleUrls: ['./note-editor.component.less']
})
export class NoteEditorComponent extends TextEditorComponent implements OnChanges {

  // Inputs
  @Input() body: NoteBody[] = [];

  // Output
  @Output() bodyChanged: EventEmitter<NoteBody[]> = new EventEmitter();

  // Public
  public content: string;

  /**
   * Constructor
   */

  constructor(
    protected stateService: StateService
  ) {
    super(stateService);

    // Update Editor settings
    this.editorSettings.content_style = `
      blockquote table.mce-item-table, blockquote table.mce-item-table td {border: none;}
      .mce-content-body {font-size: 14px; margin: 0; caret-color: #409aff;}
    `;
    this.editorSettings['autoresize_bottom_margin'] = 0;
    this.editorSettings.plugins.push('autoresize');
  }

  /**
   * Component lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if ('body' in changes) {
      this.content = this.body && this.body.length > 0 ? this.body[0].value : null;
    }
  }

  /**
   * Methods
   */

  contentChanged(content: string) {
    const item: NoteBody = {type: 'html', value: content};
    this.bodyChanged.emit([item]);
  }

}
