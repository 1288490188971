/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-board.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-board-column/project-board-column.component.ngfactory";
import * as i3 from "../project-board-column/project-board-column.component";
import * as i4 from "../../services/columns.service";
import * as i5 from "../../services/columns-tree.service";
import * as i6 from "../../../settings/services/state.service";
import * as i7 from "../../services/tasks.service";
import * as i8 from "../../../drag-n-drop/directives/app-draggable-list-for.directive";
import * as i9 from "../../../drag-n-drop/services/drag-n-drop.service";
import * as i10 from "@angular/common";
import * as i11 from "./project-board.component";
var styles_ProjectBoardComponent = [i0.styles];
var RenderType_ProjectBoardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectBoardComponent, data: {} });
export { RenderType_ProjectBoardComponent as RenderType_ProjectBoardComponent };
function View_ProjectBoardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-board-column", [], null, null, null, i2.View_ProjectBoardColumnComponent_0, i2.RenderType_ProjectBoardColumnComponent)), i1.ɵdid(1, 770048, null, 0, i3.ProjectBoardColumnComponent, [i4.ColumnsService, i5.ColumnsTreeService, i6.StateService, i7.TasksService], { column: [0, "column"], columnsTree: [1, "columnsTree"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.columnsTree; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectBoardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "board"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "board-area"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProjectBoardComponent_1)), i1.ɵdid(3, 606208, null, 0, i8.ForOrderedItemsDirective, [i1.TemplateRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i9.DragNDropService], { appDraggableListForOf: [0, "appDraggableListForOf"], appDraggableListForTrackBy: [1, "appDraggableListForTrackBy"], appDraggableListForRepositionStream: [2, "appDraggableListForRepositionStream"], appDraggableListForDirection: [3, "appDraggableListForDirection"], appDraggableListForPredicate: [4, "appDraggableListForPredicate"], appDraggableListForZIndex: [5, "appDraggableListForZIndex"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(((_co.columnsTree == null) ? null : _co.columnsTree.columns))); var currVal_1 = _co.columnsTrackByFn; var currVal_2 = _co.repositionStream; var currVal_3 = "horizontal"; var currVal_4 = _co.dndPredicate; var currVal_5 = 1; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ProjectBoardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-board", [], null, null, null, View_ProjectBoardComponent_0, RenderType_ProjectBoardComponent)), i1.ɵdid(1, 770048, null, 0, i11.ProjectBoardComponent, [i5.ColumnsTreeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectBoardComponentNgFactory = i1.ɵccf("app-project-board", i11.ProjectBoardComponent, View_ProjectBoardComponent_Host_0, { project: "project" }, {}, []);
export { ProjectBoardComponentNgFactory as ProjectBoardComponentNgFactory };
