/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tasks-main-view.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../task-form/task-form.component.ngfactory";
import * as i3 from "../task-form/task-form.component";
import * as i4 from "../../services/tasks.service";
import * as i5 from "../../services/projects.service";
import * as i6 from "../../../pages/services/dynamic-panel.service";
import * as i7 from "../../services/columns.service";
import * as i8 from "../../../linked-info/services/linked-info.service";
import * as i9 from "../../../topic/services/topic.service";
import * as i10 from "../../../tag/services/tag.service";
import * as i11 from "../../services/tasks-state.service";
import * as i12 from "../project-form-container/project-form-container.component.ngfactory";
import * as i13 from "../project-form-container/project-form-container.component";
import * as i14 from "@angular/common";
import * as i15 from "./tasks-main-view.component";
import * as i16 from "../../../pages/services/layout-state.service";
var styles_TasksMainViewComponent = [i0.styles];
var RenderType_TasksMainViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TasksMainViewComponent, data: {} });
export { RenderType_TasksMainViewComponent as RenderType_TasksMainViewComponent };
function View_TasksMainViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "empty-state-image"], ["src", "assets/placeholders/placeholder-tasks.svg"]], null, null, null, null, null))], null, null); }
function View_TasksMainViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-form", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TaskFormComponent_0, i2.RenderType_TaskFormComponent)), i1.ɵdid(1, 770048, null, 0, i3.TaskFormComponent, [i4.TasksService, i5.ProjectsService, i6.DynamicPanelService, i7.ColumnsService, i8.LinkedInfoService, i9.TopicService, i10.TagService, i11.TasksStateService], { task: [0, "task"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TasksMainViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-form-container", [["appearance", "headerFixedBig"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleFormClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ProjectFormContainerComponent_0, i12.RenderType_ProjectFormContainerComponent)), i1.ɵdid(1, 770048, null, 0, i13.ProjectFormContainerComponent, [i5.ProjectsService, i11.TasksStateService, i6.DynamicPanelService, i8.LinkedInfoService, i9.TopicService, i10.TagService, i4.TasksService], { project: [0, "project"], appearance: [1, "appearance"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; var currVal_1 = "headerFixedBig"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TasksMainViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksMainViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksMainViewComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TasksMainViewComponent_3)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "empty"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "task-form"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.view === "project-form"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_TasksMainViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-main-view", [], null, null, null, View_TasksMainViewComponent_0, RenderType_TasksMainViewComponent)), i1.ɵdid(1, 245760, null, 0, i15.TasksMainViewComponent, [i11.TasksStateService, i16.LayoutStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TasksMainViewComponentNgFactory = i1.ɵccf("app-tasks-main-view", i15.TasksMainViewComponent, View_TasksMainViewComponent_Host_0, {}, {}, []);
export { TasksMainViewComponentNgFactory as TasksMainViewComponentNgFactory };
