<div class="insights-topic-map">
  <div class="topics-map-header">
    <h2 class="title">Topic Map: <span class="folder-name" *ngIf="filterFolderDetails?.name">{{filterFolderDetails.name}}</span></h2>
    <div class="topics-map-header_change-topics-view-icon" (click)="changeTopicsView()"><i class="fa fa-th"></i></div>
    <app-dropdown align="right" [title]="selectedFilter.name">
      <app-dropdown-single-select
        title="Timeframe"
        [options]="filters"
        [selected]="selectedFilter"
        (selectedChange)="selectFilter($event)"
        [divider]="true">
      </app-dropdown-single-select>
      <app-dropdown-single-select
        title="Topics Limit"
        [options]="mapTopicsLimit"
        [selected]="selectedTopicsLimit"
        (selectedChange)="selectTopicsLimit($event)"
        *ngIf="view === 'cloud'">
      </app-dropdown-single-select>
      <app-dropdown-single-select
        title="Order by"
        [options]="topicsOrderOptions"
        [selected]="selectedTopicsOrder"
        (selectedChange)="selectTopicsOrder($event)"
        *ngIf="view === 'list'">
      </app-dropdown-single-select>
    </app-dropdown>
  </div>
  <div [ngClass]="{'insights-topic-map__details' : selectedMapTopics?.length }"
       class="insights-topic-map__wrapper">
    <div
      class="topics-map-wrapper"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="10"
      [immediateCheck]="true"
      [scrollWindow]="false"
      [infiniteScrollDisabled]="view !== 'list' || !(mapTopics?.count > mapTopics?.data?.length && !loading)"
      (scrolled)="loadTopics(true)">
      <ng-container *ngIf="!loading && view === 'cloud' && mapTopics?.data?.length > 0">
        <div class="topics-row" *ngFor="let topicsRow of topicsMapping; let topicsRowIndex = index">
          <app-topic
            class="topics-map-topic"
            [topic]="mapTopics?.data[topicIndex]"
            [selected]="selectedMapTopics.includes(mapTopics?.data[topicIndex])"
            [ngClass]="{'main-topic': topics?.includes(mapTopics?.data[topicIndex]), 'highlighted-topic': highlightedTopics?.includes(mapTopics?.data[topicIndex]), 'scaled' : hoveredTopic.row === topicsRowIndex && i === hoveredTopic.index - 1}"
            (click)="selectTopic(mapTopics?.data[topicIndex], $event)"
            (mouseenter)="onTopicMouseEnter(topicsRowIndex, i)"
            (mouseleave)="onTopicMouseLeave()"
            *ngFor="let topicIndex of topicsRow; let i = index"
            [topicStyle]="'discovered'">
          </app-topic>
        </div>
      </ng-container>
      <ng-container *ngIf="view === 'list'">
        <ul class="topics-map_pagination">
          <li class="topics-map_pagination-item">
            <span (click)="selectFirstSymbol('')"
                  [ngClass]="{'active': topicsFirstSymbol === ''}">
              All
            </span>
          </li>
          <li *ngFor="let paginationItem of paginationModel" class="topics-map_pagination-item">
            <span
              *ngIf="!paginationItem.includes(topicsFirstSymbol)"
              (click)="selectFirstSymbol(paginationItem[0])">
              {{ paginationItem[0] }} - {{ paginationItem[paginationItem.length - 1] }}
            </span>
            <ng-container *ngIf="paginationItem.includes(topicsFirstSymbol)">
              <span
                *ngFor="let symbol of paginationItem"
                (click)="selectFirstSymbol(symbol)"
                [ngClass]="{'active': topicsFirstSymbol === symbol}">
                {{symbol}}
              </span>
            </ng-container>
          </li>
        </ul>
        <div class="topics-list__wrapper" *ngIf="mapTopics?.data?.length > 0">
          <div class="topics-list_first-symbol">
            {{this.topicsFirstSymbol}}
          </div>
          <div class="topics-list">
            <app-topic
              class="topics-map-topic"
              [topic]="topic"
              [selected]="selectedMapTopics.includes(topic)"
              [ngClass]="{'main-topic': topics?.includes(topic), 'highlighted-topic': highlightedTopics?.includes(topic)}"
              (click)="selectTopic(topic, $event)"
              *ngFor="let topic of mapTopics.data">
            </app-topic>
          </div>
        </div>
      </ng-container>
      <div class="no-topics-message" *ngIf="!loading && mapTopics?.data?.length < 1">
        No Topics in the {{selectedFilter.name}}
      </div>
      <div class="loader" *ngIf="loading">
        <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
      </div>
    </div>
    <div *ngIf="selectedMapTopics?.length" class="similar-topics-wrapper">
      <app-insights-topic-details
        [topics]="selectedMapTopics"
        [selectedFolder]="folder"
        [timeFrame]="selectedDate"
        [toTime]="selectedDateTo"
        (changedSelectedFolder)="selectedFolderChanged($event)"
        (topicSelected)="selectSimilarTopic($event)"
      ></app-insights-topic-details>
    </div>
  </div>
</div>
