<div class="priority-input-wrapper" [ngSwitch]="appearanceType">
  <mat-icon
    class="priority-input-flag trigger"
    *ngSwitchCase="'expandable'"
    svgIcon="controls-button-priority"
    [ngClass]="inputFormControl?.value"
    popover
    [popoverContent]="prioritiesListTemplate"
    popoverTrigger="click"
    popoverPlacement="right"
    [popoverArrow]="false"
    matTooltip="Priority"
  ></mat-icon>

  <div *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="prioritiesListTemplate"></ng-container>
  </div>
</div>

<ng-template #prioritiesListTemplate>
  <div class="priority-input" [ngClass]="appearanceType">
    <div
      class="priority-input-container"
      *ngFor="let priority of prioritiesList"
      [ngClass]="{ active: priority.priorityName === inputFormControl?.value }"
    >
      <span class="priority-input-title" *ngIf="appearanceType === 'expandable'">{{ priority.title }}</span>
      <mat-icon
        class="priority-input-flag"
        [ngClass]="priority.priorityName"
        svgIcon="controls-button-priority"
        (click)="handleClick(priority.priorityName)"
      ></mat-icon>
    </div>
  </div>
</ng-template>
