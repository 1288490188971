// Common
import { Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

// Types
import { Task } from './task';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Section {
  formBuilder = injector.get(FormBuilder);

  id?: string;
  title?: string;
  position?: number;
  pinned?: boolean;
  archived?: boolean;
  deleted?: boolean;
  projectId?: string;

  // Tech properties

  /**
   * tasks - build sections and columns tree structures
   */
  tasks?: Task[];

  /**
   * default - invisible section for tasks without section
   */
  default?: boolean;

  constructor(sectionObject: any = {}) {
    this.id = sectionObject.id;
    this.title = sectionObject.title;
    this.position = sectionObject.position;
    this.pinned = sectionObject.pinned;
    this.archived = sectionObject.archived;
    this.deleted = sectionObject.deleted;
    this.projectId = sectionObject.projectId;
    this.tasks = sectionObject.tasks;
    this.default = sectionObject.default;
  }

  static fromFormGroup(form: FormGroup): Section {
    const formValues = form.value;

    return new Section({
      id: formValues.id,
      title: formValues.title,
      position: formValues.position,
      projectId: formValues.projectId,
      tasks: formValues.tasks
    });
  }

  asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      position: [this.position],
      projectId: [this.projectId],
      tasks: [this.tasks]
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      position: this.position,
      projectId: this.projectId
    };
  }
}
