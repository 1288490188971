// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { AuthRoutingModule } from './auth-routing.module';

// Services
import { AuthService } from './services/auth.service';
import { GoogleApiService } from './services/google-api.service';
import { GoogleAuthService } from './services/google-auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { UnauthGuard } from './services/unauth-guard.service';

// Components
import { SignInComponent } from './components/sign-in/sign-in.component';

// Env
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
  ],
  declarations: [SignInComponent],
  providers: [
    GoogleApiService,
    GoogleAuthService,
    AuthService,
    AuthGuard,
    UnauthGuard
  ]
})
export class AuthModule { }
