<ul class="contacts-counter list-inline" *ngIf="isCollapsed">
  <li *ngFor="let contact of visibleContacts; let isLast=last"
      [ngClass]="{'selected-connection':
      contact.name === selectedContact?.name && contact.email === selectedContact?.email,
      'list-inline__item': true}"
      (click)="selectContact(contact)">
    {{contact.name || contact.email}}{{isLast ? '' : ','}}
  </li>

  <li *ngIf="contactList?.length > minContactLen" (click)="toggleContacts()">
    <a role="button" class="inline-center">+ {{hiddenContactsState.length}} more <mat-icon svgIcon="collapse-down"></mat-icon></a>
  </li>
</ul>

<ul class="contacts-counter list-inline" *ngIf="!isCollapsed">
  <li *ngFor="let contact of hiddenContactsState; let isLast=last"
      [ngClass]="{'selected-connection':
      contact.name === selectedContact?.name && contact.email === selectedContact?.email,
      'list-inline__item': true}"
      (click)="selectContact(contact)">
      {{contact.name || contact.email}}{{isLast ? '' : ','}}
    </li>

  <li (click)="toggleContacts()">
    <a role="button" class="inline-center">Show less <mat-icon svgIcon="collapse-up"></mat-icon></a>
  </li>
</ul>
