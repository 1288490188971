// Common
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Types
import { TemporalExpression } from '@modules/topic/types/temporal-expression';

@Component({
  selector: 'app-temporal-expression',
  templateUrl: './temporal-expression.component.html',
  styleUrls: ['./temporal-expression.component.less']
})
export class TemporalExpressionComponent implements OnInit {

  // Inputs
  @Input() expression: TemporalExpression;
  @Input() selected = false;
  @Input() disableContextMenu = false;

  // Output
  @Output() showInCalendar = new EventEmitter<void>();
  @Output() createAppointment = new EventEmitter<void>();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Context Menu actions
   */

   showInCalendarHandler() {
     this.showInCalendar.emit();
   }

   createAppointmentHandler() {
     this.createAppointment.emit();
   }

}
