// Common
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TagService } from '@modules/tag/services/tag.service';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Tag } from '@modules/tag/types/tag';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.less']
})
export class TagComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() tag: Tag;
  @Input() messages: string[];
  @Input() events: string[];
  @Input() projects: string[];
  @Input() dragEnabled = true;

  // Output
  @Output() delete = new EventEmitter();
  @Output() pinned = new EventEmitter<boolean>();

  // Private
  private componentNotDestroyed  = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => ['message', 'event', 'task', 'note', 'file'].includes(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private tagService: TagService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  deleteTag() {
    this.tagService.deleteTags([this.tag], this.messages, this.events, this.projects)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((success: boolean) => {
        if (success) {
          this.delete.emit();
        }
      });
  }

  pinTag() {
    this.tagService.pinTags([this.tag], !this.tag.pinned)
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((success: boolean) => {
        if (success) {
          this.tag.pinned = !this.tag.pinned;
          this.pinned.emit(this.tag.pinned);
        }
      });
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    this.tagService.createTags(
      [this.tag],
      {
        messagesIds: dragData.type === 'message' && dragData.data ? (dragData.data as MailMessage[]).map(mail => mail.id) : [],
        eventsIds: dragData.type === 'event' && dragData.data ? (dragData.data as CalendarEvent[]).map(event => event.id) : [],
        tasksIds: dragData.type === 'task' && dragData.data ? (dragData.data as Task[]).map(task => task.id) : [],
        projectsIds: dragData.type === 'project' && dragData.data ? (dragData.data as Task[]).map(project => project.id) : [],
        notesIds: dragData.type === 'note' && dragData.data ? (dragData.data as Note[]).map(note => note.id) : []
      }
    );
  }

}
