/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-content-modal.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../split-view/components/split-view/split-view.component.ngfactory";
import * as i5 from "../../../split-view/components/split-view/split-view.component";
import * as i6 from "../../../settings/services/state.service";
import * as i7 from "../../../split-view/services/split-view.service";
import * as i8 from "../../../mail/components/message-content/message-content.component.ngfactory";
import * as i9 from "../../../mail/components/message-content/message-content.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../analytics/services/google-analytics.service";
import * as i12 from "../../../mail/services/mail.service";
import * as i13 from "../../services/modal.service";
import * as i14 from "../../../events/services/calendar.service";
import * as i15 from "../../../tasks/services/tasks.service";
import * as i16 from "../../../mail/services/knowledge-graph.service";
import * as i17 from "../../../topic/services/topic.service";
import * as i18 from "../../../linked-info/services/linked-info.service";
import * as i19 from "../../../tag/services/tag.service";
import * as i20 from "../../../pages/services/dynamic-panel.service";
import * as i21 from "../../../popover/services/popover.service";
import * as i22 from "../../../knowledge-panel/components/knowledge-panel/knowledge-panel.component.ngfactory";
import * as i23 from "../../../knowledge-panel/components/knowledge-panel/knowledge-panel.component";
import * as i24 from "../../../knowledge-panel/services/knowledge-panel.service";
import * as i25 from "@angular/material/tooltip";
import * as i26 from "@angular/common";
import * as i27 from "./mail-content-modal.component";
import * as i28 from "../../../popup/services/popup.service";
var styles_MailContentModalComponent = [i0.styles];
var RenderType_MailContentModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailContentModalComponent, data: {} });
export { RenderType_MailContentModalComponent as RenderType_MailContentModalComponent };
function View_MailContentModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.name)) ? ((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.name)) : ((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.email))); _ck(_v, 1, 0, currVal_0); }); }
function View_MailContentModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "modal-title-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "email"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "message-modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "email"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (((((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.name)) ? ((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.name)) : ((_co.message == null) ? null : ((_co.message.from == null) ? null : _co.message.from.email))) + ": ") + ((_co.message == null) ? null : _co.message.subject)); _ck(_v, 4, 0, currVal_3); }); }
function View_MailContentModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Collapse"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "collapse-modal"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "collapse-modal"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MailContentModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "In new window"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "fullscreen"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "fullscreen"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MailContentModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "app-split-view", [["collapseableSide", "right"], ["key", "knowledgePanelModal"]], null, null, null, i4.View_SplitViewComponent_0, i4.RenderType_SplitViewComponent)), i1.ɵdid(2, 4440064, null, 0, i5.SplitViewComponent, [i6.StateService, i7.SplitViewService, i1.NgZone, i1.ChangeDetectorRef], { collapseableSide: [0, "collapseableSide"], key: [1, "key"], minWidth: [2, "minWidth"], magneticWidth: [3, "magneticWidth"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-message-content", [["split-left-pane", ""]], null, [[null, "contactChange"], [null, "topicsSelected"], [null, "temporalExpressionsSelected"], [null, "visibleTopicsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contactChange" === en)) {
        var pd_0 = ((_co.selectedContact = $event) !== false);
        ad = (pd_0 && ad);
    } if (("topicsSelected" === en)) {
        var pd_1 = (_co.selectTopics($event) !== false);
        ad = (pd_1 && ad);
    } if (("temporalExpressionsSelected" === en)) {
        var pd_2 = ((_co.selectedTemporalExpressions = $event) !== false);
        ad = (pd_2 && ad);
    } if (("visibleTopicsChange" === en)) {
        var pd_3 = ((_co.visibleTopics = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_MessageContentComponent_0, i8.RenderType_MessageContentComponent)), i1.ɵdid(4, 770048, null, 0, i9.MessageContentComponent, [i10.Router, i11.GoogleAnalyticsService, i12.MailService, i13.ModalService, i14.CalendarService, i15.TasksService, i16.KnowledgeGraphService, i17.TopicService, i18.LinkedInfoService, i1.ElementRef, i19.TagService, i20.DynamicPanelService, i21.PopoverService], { message: [0, "message"], contact: [1, "contact"], relatedContacts: [2, "relatedContacts"], relatedTopics: [3, "relatedTopics"], composeModalFrameCallback: [4, "composeModalFrameCallback"] }, { topicsSelected: "topicsSelected", visibleTopicsChange: "visibleTopicsChange", contactChange: "contactChange", temporalExpressionsSelected: "temporalExpressionsSelected" }), (_l()(), i1.ɵeld(5, 0, null, 1, 4, "div", [["class", "knowledge-panel__wrapper"], ["split-right-pane", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "app-knowledge-panel", [["class", "modal-mail-knowledge"], ["splitViewKey", "knowledgePanelModal"]], null, [[null, "selectedContactChange"], [null, "selectedRelatedContactsChange"], [null, "selectedRelatedTopicsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedContactChange" === en)) {
        var pd_0 = ((_co.selectedContact = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedRelatedContactsChange" === en)) {
        var pd_1 = ((_co.selectedRelatedContacts = $event) !== false);
        ad = (pd_1 && ad);
    } if (("selectedRelatedTopicsChange" === en)) {
        var pd_2 = ((_co.selectedRelatedTopics = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i22.View_KnowledgePanelComponent_0, i22.RenderType_KnowledgePanelComponent)), i1.ɵdid(7, 245760, null, 0, i23.KnowledgePanelComponent, [i6.StateService, i7.SplitViewService, i1.ChangeDetectorRef, i24.KnowledgePanelService], { selectedMessages: [0, "selectedMessages"], selectedMessagesVisibleTopics: [1, "selectedMessagesVisibleTopics"], selectedContact: [2, "selectedContact"], selectedTopics: [3, "selectedTopics"], selectedRelatedContacts: [4, "selectedRelatedContacts"], selectedRelatedTopics: [5, "selectedRelatedTopics"], selectedTemporalExpressions: [6, "selectedTemporalExpressions"], splitViewKey: [7, "splitViewKey"] }, { selectedContactChange: "selectedContactChange", selectedRelatedContactsChange: "selectedRelatedContactsChange", selectedRelatedTopicsChange: "selectedRelatedTopicsChange" }), i1.ɵpad(8, 1), i1.ɵprd(256, null, i25.MAT_TOOLTIP_DEFAULT_OPTIONS, i23.ɵ0, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right"; var currVal_1 = "knowledgePanelModal"; var currVal_2 = 50; var currVal_3 = 200; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.message; var currVal_5 = _co.selectedContact; var currVal_6 = _co.selectedRelatedContacts; var currVal_7 = _co.selectedRelatedTopics; var currVal_8 = _co.onReplayMessageCallback; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _ck(_v, 8, 0, _co.message); var currVal_10 = _co.visibleTopics; var currVal_11 = _co.selectedContact; var currVal_12 = _co.selectedTopics; var currVal_13 = _co.selectedRelatedContacts; var currVal_14 = _co.selectedRelatedTopics; var currVal_15 = _co.selectedTemporalExpressions; var currVal_16 = "knowledgePanelModal"; _ck(_v, 7, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }, null); }
export function View_MailContentModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailContentModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i26.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailContentModalComponent_2)), i1.ɵdid(4, 16384, null, 0, i26.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailContentModalComponent_3)), i1.ɵdid(6, 16384, null, 0, i26.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailContentModalComponent_4)), i1.ɵdid(8, 16384, null, 0, i26.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "button-modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailContentModalComponent_5)), i1.ɵdid(13, 16384, null, 0, i26.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.minimized; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.minimized; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.availableExpand; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.availableExpand; _ck(_v, 8, 0, currVal_3); var currVal_6 = "close"; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.message; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 11).inline; var currVal_5 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_MailContentModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-content-modal", [], null, null, null, View_MailContentModalComponent_0, RenderType_MailContentModalComponent)), i1.ɵdid(1, 245760, null, 0, i27.MailContentModalComponent, [i1.ElementRef, i28.PopupService, i11.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailContentModalComponentNgFactory = i1.ɵccf("app-mail-content-modal", i27.MailContentModalComponent, View_MailContentModalComponent_Host_0, { message: "message", availableExpand: "availableExpand" }, { closed: "closed", collapsed: "collapsed" }, []);
export { MailContentModalComponentNgFactory as MailContentModalComponentNgFactory };
