<context-menu #contextMenu>

  <ng-template contextMenuItem [subMenu]="newItemMenu">
    Add New Item
  </ng-template>
  <context-menu #newItemMenu>
    <ng-template contextMenuItem>Option 1</ng-template>
    <ng-template contextMenuItem>Option 2</ng-template>
  </context-menu>
    
  <ng-template contextMenuItem (execute)="selectAll()">
    Select All
  </ng-template>

  <ng-template contextMenuItem [subMenu]="snoozeMenu">
    Snooze
  </ng-template>
  <context-menu #snoozeMenu>
    <ng-template contextMenuItem (execute)="snooze('tomorrow')">{{'tomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snooze('afterTomorrow')">{{'afterTomorrow' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snooze('endOfWeek')">{{'endOfWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem (execute)="snooze('nextWeek')">{{'nextWeek' | snoozedDate}}</ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="openCustomDateModal('snooze')">Custom date...</ng-template>
  </context-menu>

  <ng-template contextMenuItem (execute)="moveToArchive()">
    Move To Archive
  </ng-template>

  <ng-template contextMenuItem (execute)="deleteAgenda()">
    Delete Agenda
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="copy()">
    Copy
  </ng-template>

  <ng-template contextMenuItem (execute)="paste()">
    Paste
  </ng-template>

  <ng-template contextMenuItem (execute)="print()">
    Print
  </ng-template>

  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem (execute)="openInWindow()">
    Open In Window
  </ng-template>

  <ng-template contextMenuItem (execute)="copyLinkToAgenda()">
    Copy Link To Agenda
  </ng-template>
</context-menu>
