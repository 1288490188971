import { Pipe, PipeTransform } from '@angular/core';
import { Recipient } from '@modules/mail/types/recipient';


@Pipe({
  name: 'userNames'
})
export class UserNamesPipe implements PipeTransform {

  transform(value: Recipient[], args?: any): string {
    return value.map(({name, email}) => name || email).join(', ');
  }

}
