// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';

// Components
import { ContextMenuComponent } from './components/context-menu/context-menu.component';

// Directives
import { ContextMenuDirective } from './directives/context-menu/context-menu.directive';

@NgModule({
  imports: [
    CommonModule,
    NgxContextMenuModule
  ],
  declarations: [ContextMenuComponent, ContextMenuDirective],
  exports: [ContextMenuComponent, ContextMenuDirective],
  entryComponents: [ContextMenuComponent]
})
export class ContextMenuModule { }
