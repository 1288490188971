// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AngularSplitModule } from 'angular-split';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TopicModule } from '../topic/topic.module';
import { CalendarModule } from '../calendar/calendar.module';
import { TasksModule } from './../tasks/tasks.module';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { EventsModule } from '@modules/events/events.module';
import { NotesModule } from '@modules/notes/notes.module';
import { ElementsModule } from '../elements/elements.module';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { MailModule } from '@modules/mail/mail.module';

// Services
import { KnowledgePanelService } from './services/knowledge-panel.service';

// Components
import { KnowledgePanelComponent } from './components/knowledge-panel/knowledge-panel.component';
import { MailPanelComponent } from './components/mail-panel/mail-panel.component';
import { KnowledgePanelFormComponent } from './components/knowledge-panel-form/knowledge-panel-form.component';
import { EventsPanelComponent } from './components/events-panel/events-panel.component';
import { TasksPanelComponent } from './components/tasks-panel/tasks-panel.component';
import { FullCalendarModule } from '@modules/full-calendar/full-calendar.module';
import { CompassPanelComponent } from './components/compass-panel/compass-panel.component';
import { MessagesListPanelComponent } from './components/messages-list-panel/messages-list-panel.component';
import { NotesPanelComponent } from './components/notes-panel/notes-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
    UserAvatarModule,
    MatTooltipModule,
    MatTabsModule,
    MatIconModule,
    AngularSplitModule,
    InfiniteScrollModule,
    TopicModule,
    CalendarModule,
    TasksModule,
    AppSplitViewModule,
    FormControlsModule,
    EventsModule,
    NotesModule,
    FullCalendarModule,
    ElementsModule,
    QuickFormsModule,
    MailModule,
  ],
  declarations: [
    KnowledgePanelComponent,
    MailPanelComponent,
    EventsPanelComponent,
    KnowledgePanelFormComponent,
    TasksPanelComponent,
    CompassPanelComponent,
    MessagesListPanelComponent,
    NotesPanelComponent
  ],
  exports: [
    KnowledgePanelComponent,
  ],
  providers: [
    KnowledgePanelService,
  ]
})
export class KnowledgePanelModule { }
