<div class="group">
  <div class="group-header">
    <div class="group-header-title-container" *ngIf="!headerTitleTemplate">
      <mat-icon
        class="icon"
        *ngIf="icon"
        [svgIcon]="icon"
      ></mat-icon>
      <div class="title">{{ title }}</div>
    </div>
    <div class="group-header-title-container">
      <ng-container *ngIf="headerTitleTemplate" [ngTemplateOutlet]="headerTitleTemplate"></ng-container>
    </div>
    <div class="group-header-actions">
      <div class="group-header-actions-template-container" *ngIf="headerRightIconsTemplate">
        <ng-container [ngTemplateOutlet]="headerRightIconsTemplate"></ng-container>
      </div>
      <div class="group-header-actions-container">
        <mat-icon
          *ngIf="extendIconEnabled"
          class="group-header-actions-container-extend-icon"
          [svgIcon]="extendIcon || 'collapse-open'"
          [class.selected]="extendIconSelected"
          (click)="handleExtendIcon($event)"
        ></mat-icon>
        <mat-icon
          class="group-header-actions-container-dropdown-icon"
          svgIcon="dropdown"
          [class.group-header-dropdown-icon-collapsed]="collapsed"
          (click)="toggle()"
        ></mat-icon>
      </div>
    </div>
    <div class="group-header-straight"></div>
  </div>

  <div
    class="group-collapseable-body"
    [class.collapsed]="collapsed"
    [@collapseMotion]="{value: collapsed ? 'collapsed' : 'expanded', params: {height: contentHeight}}"
  >
    <div
      class="group-collapseable-body-content"
      #contentContainer
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
