<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="events-list-wrapper"
>
  <!-- Events -->
  <ng-container *cdkVirtualFor="let event of itemsStreamObservable; let i = index">

    <!-- Date header -->
    <div class="days-separator">
      <div
        class="days-separator-date"
        *ngIf="!event?.pinned && selectedOrder.key === 'date' && event?.when?.start | differentDay: items[i - 1]?.when?.start"
      >
        {{ (event?.when?.start | relativeDate : true : false : true) || (event?.when?.start | date: 'MMM d, yyyy') }}
      </div>
    </div>

    <!-- Event -->
    <app-event
      class="events-list-wrapper-event"
      [event]="event"
      [class.event-cut]="event && event | isEventCut | async"
      (click)="selectItem(event, $event)"
      [hoverPlacement]="hoverPlacement"
      [selectedEvents]="selectedItems"
    ></app-event>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading && items?.length === 0"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="event-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true || (selectedItems && selectedItems?.length > 1))">
  <div class="total">Items: {{items.length}}</div>
  <div *ngIf="selectedItems?.length > 1" class="selected">Selected: {{selectedItems?.length}}</div>
</div>

<div class="events-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="events-stub-image" src="assets/placeholders/placeholder-events.svg"/>
  <p class="events-stub-text">{{ placeholderText }}</p>
</div>
