// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

// Types
import { Project } from '@modules/tasks/types/project';
import { SectionsTree } from '@modules/tasks/types/sections-tree';

// Services
import { SectionsTreeService } from '@modules/tasks/services/sections-tree.service';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.less'],
})
export class ProjectFormComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';
  @Input() project: Project;
  @Input() projectForm: FormGroup;
  @Input() bundledGroup: string;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() openLinkedInfoDP: EventEmitter<void> = new EventEmitter();

  // Public
  public sectionsTree: SectionsTree;

  // Private
  private alive: Subject<void> = new Subject();
  private projectChanged = new Subject<string>();

  /**
   * Constructor
   */

  constructor (
    private sectionsTreeService: SectionsTreeService
  ) {

  }

  ngOnInit() {
    this.projectChanged
      .pipe(
        switchMap((projectId => this.sectionsTreeService.getSections(projectId))),
        takeUntil(this.alive)
      )
      .subscribe((tree: SectionsTree) => {
        this.sectionsTree = tree;
      });

    if (this.project) {
      this.projectChanged.next(this.project.id);
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    if ('project' in changes && this.project) {
      this.projectChanged.next(this.project.id);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addSection() {
    this.sectionsTree.insertSection();
  }

  addTask() {
    this.sectionsTree.insertTask();
  }
}
