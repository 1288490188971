// Commons
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Third Party
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AngularSplitModule } from 'angular-split';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { NavBarModule } from '../nav-bar/nav-bar.module';
import { MailModule } from './../mail/mail.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { SearchModule } from '../search/search.module';
import { TopicModule } from '../topic/topic.module';
import { AppMenuModule } from '../app-menu/app-menu.module';
import { AppSplitViewModule } from '@modules/split-view/split-view.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ElementsModule } from '@modules/elements/elements.module';

// Components
import { InsightsTopicMapComponent } from './components/insights-topic-map/insights-topic-map.component';
import { InsightsTopicDetailsComponent } from './components/insights-topic-details/insights-topic-details.component';
import { InsightsRelatedConnectionsComponent } from './components/insights-related-connections/insights-related-connections.component';

@NgModule({
  imports: [
    AngularSplitModule,
    AppCommonModule,
    BrowserAnimationsModule,
    CommonModule,
    ContextMenuModule,
    FormsModule,
    InfiniteScrollModule,
    NavBarModule,
    MailModule,
    SearchModule,
    UserAvatarModule,
    TopicModule,
    AppMenuModule,
    AppSplitViewModule,
    DropdownModule,
    ElementsModule,
  ],
  exports: [
    InsightsTopicMapComponent, InsightsTopicDetailsComponent, InsightsRelatedConnectionsComponent
  ],
  declarations: [
    InsightsTopicMapComponent, InsightsTopicDetailsComponent, InsightsRelatedConnectionsComponent
  ]
})
export class InsightsModule { }
