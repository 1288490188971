// Common
import { Optional, Component, OnInit, ChangeDetectorRef, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest, merge } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

// Services
import { TopicService } from '@modules/topic/services/topic.service';
import { MailService } from '@modules/mail/services/mail.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { StateService } from '@modules/settings/services/state.service';
import { ContentMenuService } from '../../services/content-menu.service';

// Types
import { UserMailTopic } from '@modules/topic/types/user-mail-topic';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { SearchParam } from '@modules/search/types/search-param';
import { ResponseTopics } from '@modules/topic/types/response-topics';

// Componnents
import { ContentMenuItemComponent } from '../content-menu-item/content-menu-item.component';
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';
import { ContentMenuPelletComponent } from '../content-menu-pellet/content-menu-pellet.component';

@Component({
  selector: 'app-content-menu-pellets',
  templateUrl: './content-menu-pellets.component.html',
  styleUrls: ['./content-menu-pellets.component.less'],
  providers: [{ provide: ContentMenuBaseItemComponent, useExisting: ContentMenuPelletsComponent }]
})
export class ContentMenuPelletsComponent extends ContentMenuItemComponent implements OnInit, AfterViewInit {

  // Content Children
  @ViewChildren(
    ContentMenuPelletComponent
  ) items: QueryList<ContentMenuPelletComponent>;

  // Public
  public topics: UserMailTopic[] = [];
  public selectedTopics: string[] = [];
  public orders: DropdownOption[] = [
    {name: 'Alphabet', key: 'name'},
    {name: 'Alphabet Reverse', key: 'name-desc'},
    {name: 'Most Frequent', key: 'frequency'},
    {name: 'Most Recent', key: 'recency'},
  ];
  public selectedOrder: DropdownOption;
  public pagination: {limit: number, offset: number, count: number, step: number} = {
    limit: 20,
    offset: 0,
    count: 0,
    step: 20
  };

  // Private
  private searchParams: SearchParam[] = [];
  private getPinnedTopics = new Subject();

  /**
   * Constructor
   */

  constructor (
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef,
    stateService: StateService,
    private topicService: TopicService,
    private mailService: MailService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() contentMenuService: ContentMenuService,
  ) {
    super(stateService, elementRef, changeDetector, contentMenuService);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    // Set states
    this.selectedOrder = this.stateService.sortPinnedTopics;

    // Get topics
    merge(this.topicService.getTopicsUpdate(), this.mailService.getFolders(), this.getPinnedTopics)
      .pipe(
        takeUntil(this.alive),
        switchMap(() => this.topicService.getTopics({
            pinned: true,
            order: this.selectedOrder.key as 'name' | 'name-desc' | 'recency' | 'frequency',
            limit: this.pagination.limit,
            offset: this.pagination.offset
          })
        )
      )
      .subscribe((response: ResponseTopics) => {
        this.topics = response.topics;
        this.pagination.count = response.count;
      });

    // Get selected topics
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.alive))
      .subscribe(([params, queryParams]) => {
        const folder = params.folder;
        const page = this.getMainRouteSegment();

        if (
          (
            (page === 'events' || page === 'tasks' || page === 'notes') ||
            (folder && folder === 'search')
          ) &&
          queryParams.query
        ) {
          this.searchParams = JSON.parse(queryParams.query);
          this.selectedTopics = this.searchParams.filter(param => param.type === 'topic').map(param => param.value);
        } else {
          this.searchParams = [];
          this.selectedTopics = [];
        }
      });

    this.getPinnedTopics.next();
  }

  ngAfterViewInit() {
    super.ngAfterContentInit();
  }

  /**
   * Methods
   */

  performSearch(params: SearchParam[]) {
    const page = this.getMainRouteSegment();

    if (params && params.length > 0) {
      const searchParams = JSON.stringify(params);

      this.router.navigate(
        [page === 'mail' ? '../search' : `/${ page }`],
        { relativeTo: this.route, queryParams: {query: searchParams}}
      );
    } else {
      this.router.navigate(
        [page === 'mail' ? '../' : `/${ page }`],
        { relativeTo: this.route }
      );
    }
  }

  /**
   * Actions
   */

  selectTopic(topic: UserMailTopic, multi: boolean = false) {
    if (multi && this.selectedTopics.some(selectedTopic => selectedTopic === topic.name)) {
      this.searchParams = this.searchParams.filter(param => !(param.type === 'topic' && param.value === topic.name));
    } else {
      if (!multi) {
        this.searchParams = this.searchParams.filter(param => !(
          param.type === 'topic' &&
          this.topics.some(pinnedTopic => pinnedTopic.name === param.value)
        ));
      }
      this.searchParams.push({type: 'topic', value: topic.name});
    }
    this.performSearch(this.searchParams);
  }

  selectOrder(order: DropdownOption) {
    this.selectedOrder = order;
    this.stateService.sortPinnedTopics = this.selectedOrder;
    this.getPinnedTopics.next();
  }

  openTopicsModal() {
    this.modalService.topicList();
  }

  showMore() {
    this.pagination.limit += this.pagination.step;
    this.getPinnedTopics.next();
  }

  /**
   * Helpers
   */

  getMainRouteSegment(): string {
    return this.router.url.split(/\/|\?/)[1];
  }
}
