import * as tslib_1 from "tslib";
// Rx
import { Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, filter } from 'rxjs/operators';
var SplitViewService = /** @class */ (function () {
    /**
     * Constructor
     */
    function SplitViewService() {
        // Private
        this.toggleClick = new Subject();
        this.minimizedState = new BehaviorSubject({});
    }
    /**
     * Interface
     */
    SplitViewService.prototype.toggleClicked = function (key) {
        this.toggleClick.next(key);
    };
    SplitViewService.prototype.getToggleIconClick = function (key) {
        return this.toggleClick
            .asObservable()
            .pipe(filter(function (toggleKey) { return toggleKey === key; }), map(function () { return null; }));
    };
    SplitViewService.prototype.setMinimized = function (key, value) {
        var _a;
        this.minimizedState.next(tslib_1.__assign({}, this.minimizedState.value, (_a = {}, _a[key] = value, _a)));
    };
    SplitViewService.prototype.getMinimized = function (key) {
        return this.minimizedState
            .asObservable()
            .pipe(map(function (minimized) { return minimized[key]; }), distinctUntilChanged());
    };
    return SplitViewService;
}());
export { SplitViewService };
