// Common
import { Injectable } from '@angular/core';

// RX
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ContentMenuService {

  // Private
  private minimized: BehaviorSubject<boolean> = new BehaviorSubject(null);

  /**
   * Constructor
   */

  constructor () {}

  /**
   * Actions
   */

  public setMinimized(minimized: boolean) {
    this.minimized.next(minimized);
  }

  public getMinimized(): Observable<boolean> {
    return this.minimized.asObservable();
  }
}
