<div class="box">

  <div class="box-header with-border">
    <h3 class="box-title">Desktop notification preferences</h3>
  </div>

  <div class="box-body with-padding">
    <div class="alert alert-info" role="alert">
      Allows to display popup notifications on your desktop.
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="!isSupported">
      Notifications are not supported in your browser.
    </div>
  </div>

  <div *ngIf="isSupported">
    <div class="box-body with-padding" [ngSwitch]="permission">
      <p *ngSwitchCase="'default'">
        <button class="btn btn-primary" (click)="grantPermission()">Enable notifications</button>
      </p>

      <div *ngSwitchCase="'denied'" class="alert alert-warning" role="alert">
        Notifications have been disabled in this browser. You can enable them in browser settings.
      </div>

      <ng-container *ngSwitchCase="'granted'">
        <p><button *ngIf="!enabled" class="btn btn-primary" (click)="enableSubscription()">Enable notifications</button></p>
        <p><button *ngIf="enabled" class="btn btn-primary" (click)="disableSubscription()">Disable notifications</button></p>
      </ng-container>
    </div>
  </div>

</div>
