import * as tslib_1 from "tslib";
// Common
import { ElementRef, OnInit } from '@angular/core';
import { groupBy, sortBy, toInteger } from 'lodash';
import { FormControl } from '@angular/forms';
// RxJS
import { timer } from 'rxjs';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { MatTooltipDefaultOptions } from '@angular/material';
/** Custom options the configure the tooltip's default show/hide delays. */
export var tooltipDefaults = {
    showDelay: 600,
    hideDelay: 200,
    touchendHideDelay: 1000,
};
var TopicsListModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TopicsListModalComponent, _super);
    /**
     * Constructor
     */
    function TopicsListModalComponent(topicService) {
        var _this = _super.call(this) || this;
        _this.topicService = topicService;
        // Override
        _this.defaultSize = {
            x: 'calc(50% - 300px)',
            y: 'calc(50% - 200px)',
            width: '600px',
            height: '400px'
        };
        // Public
        _this.topics = [];
        _this.groupTopics = [];
        _this.search = new FormControl('');
        _this.orders = [
            { name: 'Name', key: 'name' },
            { name: 'Frequency', key: 'relevance' },
            { name: 'Date', key: 'date' },
        ];
        _this.loading = false;
        _this.selectedOrder = _this.orders[0];
        return _this;
    }
    /**
     * Component lifecycle
     */
    TopicsListModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.loadTopics();
        this.search.valueChanges.subscribe(function (value) {
            var filtredTopics = _this.getFilteredTopics(value, _this.topics);
            _this.groupTopics = _this.getGroupedTopics(filtredTopics, _this.selectedOrder);
        });
    };
    /**
     * Actions
     */
    TopicsListModalComponent.prototype.selectOrder = function (order) {
        this.selectedOrder = order;
        this.groupTopics = this.getGroupedTopics(this.topics, order);
    };
    TopicsListModalComponent.prototype.deleteTopic = function (topic) {
        var _this = this;
        this.topicService.deleteTopics([topic]).subscribe(function (success) {
            if (success) {
                var index = _this.topics.indexOf(topic);
                if (index !== -1) {
                    _this.topics.splice(index, 1);
                    var filtredTopics = _this.getFilteredTopics(_this.search.value, _this.topics);
                    _this.groupTopics = _this.getGroupedTopics(filtredTopics, _this.selectedOrder);
                }
            }
        });
    };
    TopicsListModalComponent.prototype.startEditTopic = function (topic) {
        var _this = this;
        this.editTopic = topic;
        this.editTopicInputText = topic.name;
        this.changeEditTopic();
        timer(200).subscribe(function () {
            _this.editTopicInput.nativeElement.focus();
            _this.changeEditTopic();
        });
    };
    TopicsListModalComponent.prototype.changeEditTopic = function () {
        var _this = this;
        setTimeout(function () {
            _this.editTopicInput.nativeElement.style.width = (_this.invisibleText.nativeElement.offsetWidth + 2) + 'px';
        }, 0);
    };
    TopicsListModalComponent.prototype.finishEditTopic = function (topic) {
        var _this = this;
        this.topicService.updateTopics(topic.name, this.editTopicInputText).subscribe(function (success) {
            if (success) {
                topic.name = _this.editTopicInputText;
            }
            _this.editTopic = null;
        });
    };
    TopicsListModalComponent.prototype.cancelEditTopic = function () {
        this.editTopic = null;
    };
    /**
     * Methods
     */
    TopicsListModalComponent.prototype.loadTopics = function () {
        var _this = this;
        this.loading = true;
        this.topicService.getTopics({
            autodiscovered: true,
            limit: 200 // Temporary set up to 200 topics, should be replaced with proper pagination
        })
            .subscribe(function (_a) {
            var topics = _a.topics;
            _this.loading = false;
            _this.topics = topics;
            var filtredTopics = _this.getFilteredTopics(_this.search.value, topics);
            _this.groupTopics = _this.getGroupedTopics(filtredTopics, _this.selectedOrder);
        });
    };
    TopicsListModalComponent.prototype.getFilteredTopics = function (searchText, topics) {
        if (topics === void 0) { topics = []; }
        if (searchText && searchText.length) {
            return topics.filter(function (topic) { return topic.name.toLowerCase().includes(searchText.toLowerCase()); });
        }
        else {
            return topics;
        }
    };
    /**
     * Group topics
     */
    TopicsListModalComponent.prototype.getGroupedTopics = function (topics, order) {
        var groupedTopics = [];
        switch (order.key) {
            case 'name':
                groupedTopics = this.groupByName(topics);
                break;
            case 'relevance':
                groupedTopics = this.groupByFrequency(topics);
                break;
            case 'date':
                groupedTopics = this.groupByDate(topics);
                break;
        }
        return groupedTopics;
    };
    TopicsListModalComponent.prototype.groupByName = function (topics) {
        var groupedObj = groupBy(topics, function (topic) { return topic.name[0].toUpperCase(); });
        var groupedTopics = Object.keys(groupedObj).map(function (key) {
            return {
                title: key,
                topics: sortBy(groupedObj[key], function (topic) { return topic.name.toUpperCase(); })
            };
        });
        return groupedTopics.sort(function (groupA, groupB) {
            return groupA.title.localeCompare(groupB.title);
        });
    };
    TopicsListModalComponent.prototype.groupByFrequency = function (topics) {
        var _this = this;
        var groupedObj = groupBy(topics, function (topic) {
            if (topic.count < 10) {
                return 0;
            }
            else if (topic.count >= 10 && topic.count < 100) {
                return 10;
            }
            else if (topic.count >= 100 && topic.count < 1000) {
                return 100;
            }
            else {
                return 1000;
            }
        });
        return Object.keys(groupedObj)
            .sort(function (a, b) { return +b - +a; })
            .map(function (key) {
            return {
                title: _this.getGroupTitleToFrequencyGrouping(toInteger(key)),
                topics: sortBy(groupedObj[key], function (topic) { return topic.count; })
            };
        });
    };
    TopicsListModalComponent.prototype.getGroupTitleToFrequencyGrouping = function (count) {
        if (![0, 10, 100, 1000].includes(count)) {
            count = 0;
        }
        // TODO replace '< 10' to user-friendly text
        switch (count) {
            case 0:
                return 'up to 10';
            case 10:
                return '10+';
            case 100:
                return '100+';
            case 1000:
                return '1000+';
        }
    };
    TopicsListModalComponent.prototype.groupByDate = function (topics) {
        var periods = {
            day: 1000 * 60 * 60 * 24,
            week: 1000 * 60 * 60 * 24 * 7,
            month: 1000 * 60 * 60 * 24 * 30
        };
        var sortRule = ['at last day', 'at last week', 'at last month', 'never'];
        var groupedObj = groupBy(topics, function (topic) {
            if (topic.date) {
                topic.date = new Date(topic.date);
                var period = Date.now() - topic.date.getTime();
                if (period < periods.day) {
                    return 'at last day';
                }
                else if (period < periods.week) {
                    return 'at last week';
                }
                else if (period < periods.month) {
                    return 'at last month';
                }
                else {
                    return 'never';
                }
            }
            else {
                return 'never';
            }
        });
        return Object.keys(groupedObj)
            .sort(function (a, b) { return sortRule.indexOf(a) - sortRule.indexOf(b); })
            .map(function (key) {
            return {
                title: key,
                topics: sortBy(groupedObj[key], function (topic) { return topic.name.toUpperCase(); })
            };
        });
    };
    return TopicsListModalComponent;
}(BaseModalComponent));
export { TopicsListModalComponent };
