// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { MailService } from '@modules/mail/services/mail.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Types
import { MailMessage } from '@modules/mail/types/mail-message';
import { ResponseMessages } from '@modules/mail/types/mail-response.model';

@Component({
  selector: 'app-linked-info-message-list',
  templateUrl: './linked-info-message-list.component.html',
  styleUrls: ['./linked-info-message-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoMessageListComponent extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private mailService: MailService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'message';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<MailMessage[]> {
    return this.mailService.getMessages({ids}, 'date', ids.length, 0)
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response: ResponseMessages) => response.messages)
      );
  }

  /**
   * Actions
   */

  select(message: MailMessage) {
    if (message.isDraft &&
      !(message.sending && (message.sending.status === 'sending' || message.sending.status === 'pending'))
    ) {
      this.modalService.compose(message);
      return;
    }
    this.modalService.mailContent(message);
  }

}
