/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-template-items.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dropdown-template-items.component";
var styles_DropdownTemplateItemsComponent = [i0.styles];
var RenderType_DropdownTemplateItemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownTemplateItemsComponent, data: {} });
export { RenderType_DropdownTemplateItemsComponent as RenderType_DropdownTemplateItemsComponent };
function View_DropdownTemplateItemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownTemplateItemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DropdownTemplateItemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DropdownTemplateItemsComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { option: 0, select: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit, _co.select); var currVal_1 = _co.optionTemplateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DropdownTemplateItemsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null))], null, null); }
export function View_DropdownTemplateItemsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownTemplateItemsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownTemplateItemsComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownTemplateItemsComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.options; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.divider; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_DropdownTemplateItemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-template-items", [], null, null, null, View_DropdownTemplateItemsComponent_0, RenderType_DropdownTemplateItemsComponent)), i1.ɵdid(1, 49152, null, 0, i3.DropdownTemplateItemsComponent, [], null, null)], null, null); }
var DropdownTemplateItemsComponentNgFactory = i1.ɵccf("app-dropdown-template-items", i3.DropdownTemplateItemsComponent, View_DropdownTemplateItemsComponent_Host_0, { title: "title", options: "options", divider: "divider", optionTemplateRef: "optionTemplateRef" }, { selectedChange: "selectedChange" }, []);
export { DropdownTemplateItemsComponentNgFactory as DropdownTemplateItemsComponentNgFactory };
