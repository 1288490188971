<section class="app-sidebar">
  <div class="logo" [ngClass]="{'extra-top-padding': extraTopPadding}">
    <img
      [ngClass]="{'logo-large': !minimized, 'logo-small': minimized}"
      [src]="minimized ? 'assets/logo/logo.svg' : 'assets/logo/logo-main.svg'"
    />

    <button class="button-icon button-arrow" *ngIf="!minimized">
      <mat-icon svgIcon="button-arrow" [split-view-toggle]="splitViewKey"></mat-icon>
    </button>
  </div>

  <div class="sidebar-content">
    <ng-content></ng-content>
  </div>
</section>
