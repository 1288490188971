// Common
import { Component, OnInit } from '@angular/core';

// Services
import { AccountService } from '../../services/account.service';

// Types
import { AccountQuickReplyTemplate } from '../../types/account-quick-reply-template';

@Component({
  selector: 'app-account-quick-reply-templates',
  templateUrl: './account-quick-reply-templates.component.html',
  styleUrls: ['./account-quick-reply-templates.component.css']
})
export class AccountQuickReplyTemplatesComponent implements OnInit {
  titleInputValue: string;
  loading: boolean;
  saved: boolean;
  errorMessage: string;
  showForm: boolean;
  quickReplyTemplates: AccountQuickReplyTemplate[];
  replyTemplateId: string;
  replyTemplate: AccountQuickReplyTemplate;

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.titleInputValue = '';
    this.loading = false;
    this.saved = false;
    this.errorMessage = '';
    this.showForm = false;
    this.quickReplyTemplates = [];
    this.replyTemplateId = '';
    this.replyTemplate = { content: '', title: ''} as AccountQuickReplyTemplate;

    this.accountService.getQuickReplyTemplates()
      .subscribe(
        response => {
          this.quickReplyTemplates = response;
        },
        error => this.errorMessage = error
      );
  }

  openForm(replyTemplate?) {
    this.closeForm();

    if (replyTemplate) {
      this.replyTemplateId = replyTemplate.id;
      this.replyTemplate = replyTemplate;
      this.titleInputValue = replyTemplate.title;
    }

    this.showForm = true;

    window.scrollTo(0, 0);
  }

  closeForm() {
    this.showForm = false;
    this.replyTemplate = { content: '', title: ''} as AccountQuickReplyTemplate;
    this.titleInputValue = '';
    this.replyTemplateId = '';
    this.errorMessage = '';
    this.saved = false;
  }

  _onSuccess(result: any) {
    this.loading = false;
    this.saved = result['success'];
    if (!result['success']) {
      this.errorMessage = result['error'];
    }

    this.accountService.getQuickReplyTemplates()
      .subscribe(
        response => {
          this.quickReplyTemplates = response;
        },
        error => this.errorMessage = error
      );

    this.closeForm();
  }

  _onError(error: any) {
    this.loading = false;
    this.saved = false;
    this.errorMessage = error.error.error;
  }

  saveTemplate() {
    this.loading = true;
    this.saved = false;
    this.errorMessage = '';

    if (this.replyTemplateId) {
      this.accountService.updateQuickReplyTemplate(this.replyTemplateId, this.titleInputValue, this.replyTemplate.content)
        .subscribe(
          response => {
            this._onSuccess(response);
          },
          error => {
            this._onError(error);
          }
        );
    } else {
      this.accountService.createQuickReplyTemplate(this.titleInputValue, this.replyTemplate.content)
        .subscribe(
          response => {
            this._onSuccess(response);
          },
          error => {
            this._onError(error);
          }
        );
    }
  }

  deleteTemplate(id) {
    this.loading = true;
    this.saved = false;
    this.errorMessage = '';
    this.accountService.removeQuickReplyTemplate(id)
      .subscribe(
        response => {
          this._onSuccess(response);
        },
        error => {
          this._onError(error);
        }
      );
  }

}
