/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-action-items.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dropdown-item/dropdown-item.component.ngfactory";
import * as i3 from "../dropdown-item/dropdown-item.component";
import * as i4 from "../../../pipes/pipes/includes-by.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./dropdown-action-items.component";
var styles_DropdownActionItemsComponent = [i0.styles];
var RenderType_DropdownActionItemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownActionItemsComponent, data: {} });
export { RenderType_DropdownActionItemsComponent as RenderType_DropdownActionItemsComponent };
function View_DropdownActionItemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownActionItemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-dropdown-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DropdownItemComponent_0, i2.RenderType_DropdownItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.DropdownItemComponent, [i1.ElementRef], { title: [0, "title"], disabled: [1, "disabled"] }, null), i1.ɵppd(2, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.disabled, _v.context.$implicit.key, "key")); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DropdownActionItemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null))], null, null); }
export function View_DropdownActionItemsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.IncludesByPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownActionItemsComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownActionItemsComponent_2)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownActionItemsComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.options; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.divider; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_DropdownActionItemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-action-items", [], null, null, null, View_DropdownActionItemsComponent_0, RenderType_DropdownActionItemsComponent)), i1.ɵdid(1, 114688, null, 0, i6.DropdownActionItemsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownActionItemsComponentNgFactory = i1.ɵccf("app-dropdown-action-items", i6.DropdownActionItemsComponent, View_DropdownActionItemsComponent_Host_0, { title: "title", options: "options", disabled: "disabled", divider: "divider" }, { actionSelected: "actionSelected" }, []);
export { DropdownActionItemsComponentNgFactory as DropdownActionItemsComponentNgFactory };
