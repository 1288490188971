// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { TopicModule } from '../topic/topic.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ElementsModule } from '../elements/elements.module';
import { ContentMenuModule } from '../content-menu/content-menu.module';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { QuickFormsModule } from '@modules/quick-forms/quick-forms.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { DropdownModule } from '@modules/dropdown/dropdown.module';
import { ContextMenuModule as NgxContextMenuModule } from 'ngx-contextmenu';
import { ContextMenuModule } from '@modules/context-menu/context-menu.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { TagModule } from '@modules/tag/tag.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatTabsModule } from '@angular/material';

// Services
import { TasksService } from './services/tasks.service';
import { ProjectsService } from './services/projects.service';
import { SectionsService } from './services/sections.service';
import { ColumnsService } from './services/columns.service';
import { TasksStateService } from './services/tasks-state.service';
import { SectionsTreeService } from './services/sections-tree.service';
import { ColumnsTreeService } from './services/columns-tree.service';

// Components
import { ProjectBoardComponent } from './components/project-board/project-board.component';
import { ProjectBoardColumnComponent } from './components/project-board-column/project-board-column.component';
import { ColumnContextMenuComponent } from './components/column-context-menu/column-context-menu.component';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { TaskFormSubtasksComponent } from './components/task-form-subtasks/task-form-subtasks.component';
import { TasksSideBarComponent } from './components/side-bar/side-bar.component';
import { TasksListContainerComponent } from './components/tasks-list-container/tasks-list-container.component';
import { TasksListHeaderComponent } from './components/tasks-list-header/tasks-list-header.component';
import { ProjectsListHeaderComponent } from './components/projects-list-header/projects-list-header.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TasksMainViewComponent } from './components/tasks-main-view/tasks-main-view.component';
import { ProjectFormContainerComponent } from './components/project-form-container/project-form-container.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { ProjectTopicsComponent } from './components/form-controls/project-topics/project-topics.component';
import { TaskTopicsComponent } from './components/form-controls/task-topics/task-topics.component';
import { SidebarTaskContextMenuComponent } from './components/sidebar-task-context-menu/sidebar-task-context-menu.component';
import { SidebarProjectsContextMenuComponent } from './components/sidebar-projects-context-menu/sidebar-projects-context-menu.component';

// Directives
import { BoardColumnContextMenuDirective } from './directives/board-column-context-menu/board-column-context-menu.directive';
import { SidebarTaskContextMenuDirective } from './directives/sidebar-task-context-menu.directive';
import { SidebarProjectsContextMenuDirective } from './directives/sidebar-projects-context-menu.directive';

// Pipes
import { GetTitleBySidebarFilterKeyPipe } from '@modules/tasks/pipes/get-title-by-sidebar-filter-key.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    BundledInputsModule,
    ContentMenuModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    ScrollingModule,
    TopicModule,
    CommonModule,
    QuickFormsModule,
    ScrollModule,
    MatTooltipModule,
    DragNDropModule,
    DropdownModule,
    NgxContextMenuModule.forRoot(),
    ContextMenuModule,
    PipesModule,
    PopoverModule,
    TagModule,
    LinkedInfoModule,
    MatTabsModule,
  ],
  declarations: [
    ProjectBoardComponent,
    ProjectBoardColumnComponent,
    ColumnContextMenuComponent,
    ProjectsListComponent,
    ProjectsListHeaderComponent,
    ProjectFormContainerComponent,
    ProjectFormComponent,
    SidebarProjectsContextMenuComponent,
    SidebarProjectsContextMenuDirective,
    SidebarTaskContextMenuComponent,
    SidebarTaskContextMenuDirective,
    TasksMainViewComponent,
    TaskFormComponent,
    TaskFormSubtasksComponent,
    TasksListComponent,
    TasksListContainerComponent,
    TasksListHeaderComponent,
    TasksSideBarComponent,
    ProjectTopicsComponent,
    TaskTopicsComponent,

    BoardColumnContextMenuDirective,
    GetTitleBySidebarFilterKeyPipe,
  ],
  exports: [
    ProjectsListComponent,
    TasksMainViewComponent,
    ProjectsListComponent,
    TasksListComponent,
    TasksListContainerComponent,
    TasksSideBarComponent,
    TaskFormComponent,
    TaskFormSubtasksComponent,
    ProjectFormContainerComponent,
    ProjectFormComponent,
    GetTitleBySidebarFilterKeyPipe,
  ],
  providers: [
    ProjectsService,
    SectionsService,
    ColumnsService,
    TasksService,
    TasksStateService,
    SectionsTreeService,
    ColumnsTreeService,
  ],
  entryComponents: [
    ColumnContextMenuComponent,
    SidebarTaskContextMenuComponent,
    SidebarProjectsContextMenuComponent,
  ]
})
export class TasksModule { }
