<div class="calendar-month--wrapper" [@dateAnimation]="viewDate" #monthsContainer>
  <ng-template
    #dayTemplate
    let-day="day"
    let-openDay="openDay"
    let-locale="locale"
    let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay"
    let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDelay="tooltipDelay"
    let-trackByEventId="trackByEventId"
  >
    <ng-template #popoverDay>
      <ng-container *ngTemplateOutlet="quickPreviewTemplate; context: { day: day.date, events: day.events }"></ng-container>
    </ng-template>

    <div
      #cell
      class="cal-cell-container"
      [appCalendarCellContextMenu]="day.date"
      appCalendarCellContextMenuCalendarType="year"
      (appCalendarCellContextMenuLoadDayEvents)="handleLoadDayEvents(day.date)"
      (appClick)="clickDay(event, day.date, cell)"
      (appDblClick)="dblClickDay(day.date)"
    >
      <div class="cal-cell-top"
           popover
           popoverPlacement="right"
           popoverTrigger="hover"
           [popoverDisabled]="day.events.length === 0"
           [popoverContent]="popoverDay"
      >
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span>
      </div>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 0">
      <div
        class="cal-event"
        *ngFor="let event of day.events.slice(0,3); trackBy: trackByEventId"
        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({ event: event })"
        (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        mwlDraggable
        [class.cal-draggable]="event.draggable"
        dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }"
        [dragAxis]="{ x: event.draggable, y: event.draggable }"
        (mwlClick)="eventClicked.emit({ event: event })"
        [ngStyle]="{ 'background-color': event.meta.color || (event.meta.calendarId | calendarColor | async) }"
      ></div>
    </div>
  </ng-template>

  <div class="calendar-year">
    <div *ngFor="let month of monthsList" class="months-splitter">
      <div class="calendar-year-item--wrapper" #monthItem>
        <div class="calendar-month__header">
          <div class="calendar-month__title">
            {{ month | date:'MMMM' }}
          </div>
        </div>
        <mwl-calendar-month-view
          [viewDate]="month"
          [events]="events"
          [refresh]="refreshCalendar"
          (beforeViewRender)="beforeMonthViewRender($event)"
          [cellTemplate]="dayTemplate"
          [weekStartsOn]="0">
        </mwl-calendar-month-view>
      </div>
    </div>
  </div>

</div>
