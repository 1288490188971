// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// Services
import { PopupService } from '@modules/popup/services/popup.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Note } from '@modules/notes/types/note';

@Component({
  selector: 'app-note-form-modal',
  templateUrl: './note-form-modal.component.html',
  styleUrls: ['./note-form-modal.component.less']
})
export class NoteFormModalComponent extends BaseModalComponent implements OnInit {

   // Inputs
   @Input() note: Note;

   // Override
   public defaultSize: ModalFrame = {
     x: 'calc(50% - 259px)',
     y: '5vh',
     width: '518px',
     height: '90vh'
   };

   /**
    * Constructor
    */

   constructor(
     protected element: ElementRef,
     protected popupService: PopupService,
     protected ga: GoogleAnalyticsService,
   ) {
     super();
   }

   /**
    * Component lifecycle
    */

   ngOnInit() {
     super.ngOnInit();
   }

   /**
    * Actions
    */

   expand() {
     this.ga.trackEvent('task-form-modal', 'expand');
     this.popupService.noteContent(this.note.id, this.maximizedSize.popupSize());
     this.close();
   }

}
