<div class="modal-header">
  <div class="modal-title">
    <mat-icon *ngIf="minimized" svgIcon="project" class="modal-title-icon"></mat-icon>
    {{ project?.title }}
  </div>
  <button type="button" class="button-modal" aria-label="Collapse" (click)="collapse()">
    <mat-icon svgIcon="collapse-modal"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="In new window" (click)="expand()">
    <mat-icon svgIcon="fullscreen"></mat-icon>
  </button>
  <button type="button" class="button-modal" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="modal-content">
    <app-project-form [project]="project" (close)="close()" class="modal-form" appearance="headerFixedBig"></app-project-form>
    <app-linked-info-toolbar *ngIf="project?.id" [linkedInfo]="[{type: 'project', id: project.id}]"></app-linked-info-toolbar>
  </div>
</div>
