import * as tslib_1 from "tslib";
// Common
import { OnInit, EventEmitter } from '@angular/core';
// Components
import { MessageComponent } from '@modules/elements/components/message/message.component';
var LinkInfoMessageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInfoMessageComponent, _super);
    function LinkInfoMessageComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shortView = false;
        // Outputs
        _this.unlink = new EventEmitter();
        return _this;
    }
    /**
     * Actions
     */
    LinkInfoMessageComponent.prototype.unlinkItem = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.unlink.emit(this.linkedInfo);
    };
    LinkInfoMessageComponent.prototype.openMessage = function () {
        if (this.message) {
            this.modalService.mailContent(this.message);
        }
    };
    return LinkInfoMessageComponent;
}(MessageComponent));
export { LinkInfoMessageComponent };
