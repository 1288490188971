// Common
import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';

// Services
import { StateService } from './../../../settings/services/state.service';

@Component({
  selector: 'app-account-general',
  templateUrl: './account-general.component.html',
  styleUrls: ['./account-general.component.less']
})
export class AccountGeneralComponent implements OnInit {

  // Public
  public messageSnoozed: string;
  public saved = false;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.loadSettings();
  }

  /**
   * Actions
   */

  save() {
    this.stateService.settingMessageSnoozed = this.messageSnoozed;
    this.saved = true;
    timer(3000).subscribe(() => {
      this.saved = false;
    });
  }

  cancel() {
    this.saved = false;
    this.loadSettings();
  }

  /**
   * Methods
   */

  loadSettings() {
    this.messageSnoozed = this.stateService.settingMessageSnoozed;
  }

}
