import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOption } from '../../types/dropdown-option';

@Component({
  selector: 'app-dropdown-pagination',
  templateUrl: './dropdown-pagination.component.html',
  styleUrls: ['./dropdown-pagination.component.less']
})
export class DropdownPaginationComponent implements OnInit {

  public firstSymbol: string = null;


  // Inputs
  @Input() title: string;
  @Input() selected: DropdownOption[];
  @Input() options: DropdownOption[];
  @Input() divider = false;

  // Outputs
  @Output() selectedChange: EventEmitter<string> = new EventEmitter();

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  /**
   * Actions
   */

  onGoFirstSymbol(letter: string) {
    this.selectedChange.emit(letter);
    this.firstSymbol = null;
  }

}
