// Common
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

// Components
import { FallingLettersComponent } from '../components/falling-letters/falling-letters.component';

// Services
import { BundledInputsService } from '../services/bundled-inputs.service';

// Types
import { BundledEvent } from '../types/bundled-event';

@Directive({
  selector: '[bundledConsumer]',
})
export class BundledConsumerDirective implements AfterViewInit, OnDestroy {

  // Private
  private alive: Subject<void> = new Subject();
  private lastBundledValue: any;

  // Inputs
  @Input() bundledConsumer: string[];
  @Input() bundledConsumerGroup = '';
  @Input() bundledTransformFunction: (event: BundledEvent) => void;
  @Input() bundledAnimationStrategy: 'entireText' | 'highlightOnly' = 'entireText';
  @Input() bundledFormControl: FormControl;
  @Input() bundledValueCompareFunction: (value: any, bundledValue: any) => boolean;
  @Input() bundledInputInvisible = false;

  /**
   * Constructor
   */

  constructor(
    private elementRef: ElementRef,
    private bundledInputsService: BundledInputsService,
    private overlay: Overlay,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit(): void {
    if (this.bundledConsumer && this.bundledConsumer.length) {
      this.subscribeToEvents();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private subscribeToEvents() {
    this.bundledInputsService.bundledEvents(
      this.bundledConsumer.map(key => `${this.bundledConsumerGroup}_${key}`)
    )
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((event: BundledEvent) => {
        if (!this.elementRef.nativeElement.isConnected) {
          return;
        }

        const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(this.elementRef.nativeElement);

        if (event.toValue && (!event.fromValueHighlightRange || !event.toValueHighlightRange )) {
          const highlightRange = this.calculateHighlightRange(event.toValue, this.elementRef.nativeElement.value);
          event.fromValueHighlightRange = highlightRange;
          event.toValueHighlightRange = highlightRange;
        }

        event.animationStrategy = this.bundledAnimationStrategy;

        event.consumer = {
          x: boundingRect.x,
          y: boundingRect.y,
          padding: computedStyle.padding,
          fontSize: computedStyle.fontSize,
          fontFamily: computedStyle.fontFamily,
          fontWeight: computedStyle.fontWeight,
          lineHeight: computedStyle.lineHeight,
          letterSpacing: computedStyle.letterSpacing,
          color: computedStyle.color,
        };

        if (this.bundledTransformFunction) {
          this.bundledTransformFunction(event);
        }

        const valueChanged = this.bundledFormControl ?
          event.formControlValueChanged :
          this.elementRef.nativeElement.value !== event.toValue;

        if (event.toValue != null && valueChanged) {
          if (!event.fromValueHighlightRange) {
            event.fromValueHighlightRange = [0, 0];
          }
          if (!event.toValueHighlightRange) {
            event.toValueHighlightRange = [0, 0];
          }
          if (this.bundledInputInvisible) {
            this.setValueToFormControl(event);
          } else {
            this.startAnimation(event);
          }
        }

        if (event.toValue === null && this.bundledFormControl) {
          if (
            this.bundledValueCompareFunction ?
            this.bundledValueCompareFunction(this.bundledFormControl.value, this.lastBundledValue) :
            this.lastBundledValue === this.bundledFormControl.value
          ) {
            this.bundledFormControl.setValue(null);
          }

          this.lastBundledValue = null;
        }
      });
  }

  private startAnimation(event: BundledEvent): void {
    const overlayRef: OverlayRef = this.overlay.create();
    const componentPortal = new ComponentPortal(FallingLettersComponent);
    const componentRef = overlayRef.attach(componentPortal);

    componentRef.instance.event = event;

    componentRef.instance.animationFinished
      .pipe(
        takeUntil(this.alive),
        first()
      )
      .subscribe(() => {
        this.setValueToFormControl(event);

        overlayRef.dispose();
      });
  }

  /**
   * Helpers
   */

  calculateHighlightRange(newValue: string, oldValue: string): number[] {
    const index = newValue.indexOf(oldValue);

    if (index === 0) {
      // something new was added to the end of existing text
      return [oldValue.length, newValue.length];
    } else {
      // animate nothing
      return [0, 0];
    }
    // TODO predict another possible cases
  }

  private setValueToFormControl(event: BundledEvent) {
    if (this.bundledFormControl) {
      this.lastBundledValue = event.formControlValue;
      this.bundledFormControl.setValue(event.formControlValue);
    } else {
      this.elementRef.nativeElement.value = event.toValue;
      this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
