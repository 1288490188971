import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-form-popup',
  templateUrl: './note-form-popup.component.html',
  styleUrls: ['./note-form-popup.component.less']
})
export class NoteFormPopupComponent {

  /**
   * Constructor
   */

  constructor() {

  }

  /**
   * Actions
   */

  close() {
    window.close();
  }

}
