/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./content-menu.component";
var styles_ContentMenuComponent = [i0.styles];
var RenderType_ContentMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentMenuComponent, data: {} });
export { RenderType_ContentMenuComponent as RenderType_ContentMenuComponent };
export function View_ContentMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 1, "div", [["class", "content-menu-wrapper"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ContentMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-content-menu", [], null, null, null, View_ContentMenuComponent_0, RenderType_ContentMenuComponent)), i1.ɵdid(1, 2146304, null, 1, i2.ContentMenuComponent, [], null, null), i1.ɵqud(603979776, 1, { items: 1 })], null, null); }
var ContentMenuComponentNgFactory = i1.ɵccf("app-content-menu", i2.ContentMenuComponent, View_ContentMenuComponent_Host_0, {}, {}, ["*"]);
export { ContentMenuComponentNgFactory as ContentMenuComponentNgFactory };
