import * as tslib_1 from "tslib";
// Common
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
// Components
import { TextEditorComponent } from '@modules/common/components/text-editor/text-editor.component';
var NoteEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NoteEditorComponent, _super);
    /**
     * Constructor
     */
    function NoteEditorComponent(stateService) {
        var _this = _super.call(this, stateService) || this;
        _this.stateService = stateService;
        // Inputs
        _this.body = [];
        // Output
        _this.bodyChanged = new EventEmitter();
        // Update Editor settings
        _this.editorSettings.content_style = "\n      blockquote table.mce-item-table, blockquote table.mce-item-table td {border: none;}\n      .mce-content-body {font-size: 14px; margin: 0; caret-color: #409aff;}\n    ";
        _this.editorSettings['autoresize_bottom_margin'] = 0;
        _this.editorSettings.plugins.push('autoresize');
        return _this;
    }
    /**
     * Component lifecycle
     */
    NoteEditorComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if ('body' in changes) {
            this.content = this.body && this.body.length > 0 ? this.body[0].value : null;
        }
    };
    /**
     * Methods
     */
    NoteEditorComponent.prototype.contentChanged = function (content) {
        var item = { type: 'html', value: content };
        this.bodyChanged.emit([item]);
    };
    return NoteEditorComponent;
}(TextEditorComponent));
export { NoteEditorComponent };
