// Common
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';

// Types
import { Contact } from '@modules/contacts/types/contact';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less'],
})
export class ContactFormComponent implements OnDestroy {

  // Inputs
  @Input() appearance: 'noHeader' | 'headerFixedBig' | 'headerFixedSmall';
  @Input() contact: Contact;
  @Input() contactForm: FormGroup;
  @Input() bundledGroup: string;

  // Outputs
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() openLinkedInfoDP: EventEmitter<void> = new EventEmitter();

  // Public
  public conferencingOptions = [
    {title: 'Hangouts', value: '?', icon: 'social-hangouts'},
    {title: 'Skype', value: '?', icon: 'social-skype'},
    {title: 'Slack', value: '?', icon: 'social-slack'},
    {title: 'Zoom', value: '?', icon: 'social-zoom'},
    {title: 'No Conferencing', value: '?', icon: 'social-no-conf'},
  ];

  // Private
  private alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor () {

  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
