import * as tslib_1 from "tslib";
// Common
import { EventEmitter, QueryList, OnDestroy, AfterContentInit, ChangeDetectorRef, ElementRef, TemplateRef, OnChanges, SimpleChanges, } from '@angular/core';
// RX
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { startWith, takeUntil, switchMap, map, scan, debounceTime, filter } from 'rxjs/operators';
// Components
import { ContentMenuBaseItemComponent } from '../content-menu-base-item/content-menu-base-item.component';
var ContentMenuItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentMenuItemComponent, _super);
    /**
     * Constructor
     */
    function ContentMenuItemComponent(stateService, elementRef, changeDetector, contentMenuService) {
        var _this = _super.call(this, changeDetector, contentMenuService) || this;
        _this.stateService = stateService;
        _this.elementRef = elementRef;
        _this.iconColor = '';
        _this.minimizedAppearanceType = 'popup';
        // Outputs
        _this.createIconSelected = new EventEmitter();
        // Public
        _this.expandable = false;
        _this.collapsed = true;
        _this.bodyHeight = 0;
        _this.treeHeight = 0;
        _this.popoverOpened = false;
        _this.self = null;
        _this.headerHeight = 24;
        // Private
        _this.connectedItemsStream = new Subject();
        _this.connectedItems = new BehaviorSubject([]);
        _this.contentInit = new Subject();
        _this.connectedItemsStream
            .pipe(scan(function (items, action) { return action.type === 'add' ? tslib_1.__spread(items, [action.item]) :
            items.filter(function (item) { return item !== action.item; }); }, []), takeUntil(_this.alive))
            .subscribe(function (items) {
            _this.connectedItems.next(items);
            _this.contentInit.next(); // switchMap -> combineLatest should handle this without contentInit emitting
            // - but for some reason it doesn't
        });
        _this.contentInit
            .pipe(debounceTime(100), // just to reassure and prevent possible performance issues
        switchMap(function () { return (combineLatest([
            _this.items.changes.pipe(map(function () { return _this.getItems(); }), startWith(_this.getItems())),
            _this.connectedItems.pipe(startWith([]))
        ])); }), map(function (_a) {
            var _b = tslib_1.__read(_a, 2), items = _b[0], connectedItems = _b[1];
            return tslib_1.__spread(items, connectedItems);
        }), switchMap(function (items) { return (combineLatest(items.map(function (item) { return item.heightChange
            .pipe(startWith(item.headerHeight), map(function (height) { return ({ height: height, componentType: item.constructor.name }); })); }))); }), takeUntil(_this.alive))
            .subscribe(function (items) {
            if (items.length === 0) {
                return;
            }
            var newExpandable = items.length > 0;
            var newBodyHeight = items.map(function (item) { return item.height; }).reduce(function (a, b) { return a + b; });
            var newTreeHeight = _this.calculateTreeHeight(items);
            if (_this.bodyHeight !== newBodyHeight ||
                _this.treeHeight !== newTreeHeight ||
                _this.expandable !== newExpandable) {
                _this.expandable = newExpandable;
                _this.bodyHeight = newBodyHeight;
                _this.treeHeight = newTreeHeight;
                _this.emitHeight();
                _this.detectChanges();
            }
        });
        return _this;
    }
    /**
     * Component lifecycle
     */
    ContentMenuItemComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.self = this;
        this.detectChanges();
        this.contentInit.next();
        this.emitHeight();
        this.stateService.getCollapsed(this.collapsedStateKey)
            .pipe(filter(function () { return !!_this.collapsedStateKey; }), takeUntil(this.alive))
            .subscribe(function (collapsed) {
            _this.collapsed = collapsed;
            _this.detectChanges();
        });
    };
    ContentMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if ('secondLevel' in changes) {
            this.detectChanges();
        }
        if ('root' in changes && this.root) {
            var items = this.getItems();
            items.forEach(function (i) { return i.secondLevel = true; });
            this.detectChanges();
        }
        if ('parent' in changes && this.parent) {
            this.parent.connectItem(this);
        }
    };
    ContentMenuItemComponent.prototype.ngOnDestroy = function () {
        if (this.parent) {
            this.parent.disconnectItem(this);
        }
        this.connectedItems.complete();
        this.contentInit.complete();
        this.connectedItemsStream.complete();
        _super.prototype.ngOnDestroy.call(this);
    };
    /**
     * Actions
     */
    ContentMenuItemComponent.prototype.emitHeight = function () {
        this.heightChange.emit(this.headerHeight + (this.collapsed ? 0 : this.bodyHeight));
    };
    ContentMenuItemComponent.prototype.trigger = function () {
        this.collapsed = !this.collapsed;
        if (this.collapsedStateKey) {
            this.stateService.setCollapsed(this.collapsedStateKey, this.collapsed);
        }
        this.emitHeight();
        this.detectChanges();
    };
    ContentMenuItemComponent.prototype.createIconSelect = function (event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.createIconSelected.emit(event);
    };
    ContentMenuItemComponent.prototype.connectItem = function (item) {
        this.connectedItemsStream.next({ type: 'add', item: item });
    };
    ContentMenuItemComponent.prototype.disconnectItem = function (item) {
        this.connectedItemsStream.next({ type: 'remove', item: item });
    };
    /**
     * Helpers
     */
    ContentMenuItemComponent.prototype.getItems = function () {
        var _this = this;
        return this.items.filter(function (item) { return item !== _this; });
    };
    ContentMenuItemComponent.prototype.calculateTreeHeight = function (items) {
        return items.slice(0, -1).reverse().reduce(function (memo, item) {
            var itemFound = memo.itemFound;
            if (!itemFound && item.componentType === 'ContentMenuItemComponent') {
                itemFound = true;
            }
            return { itemFound: itemFound, total: (itemFound ? item.height : 0) + memo.total };
        }, { total: 0, itemFound: false }).total;
    };
    return ContentMenuItemComponent;
}(ContentMenuBaseItemComponent));
export { ContentMenuItemComponent };
