<div 
  class="content-menu-checkbox-wrapper"
  [ngClass]="{ minimized: minimized }"
>
  <div class="checkbox-container">
    <mat-checkbox
      class="checkbox-item-checkbox"
      [checked]="checked"
      [disableRipple]="true"
      [disabled]="disabled"
      (change)="toggle()"
    ></mat-checkbox>
  </div>

  <mat-icon *ngIf="icon" [svgIcon]="icon" class="icon"></mat-icon>
  <div class="title" *ngIf="!minimized" (click)="toggle()">{{ title }}</div>
</div>
