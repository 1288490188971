// Common
import { Component, NgZone } from '@angular/core';

// RxJS
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Class
import { LinkInfoPopover } from '../link-info-popover';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { ContactsListResponse } from '@modules/contacts/types/contacts-list-response';

@Component({
  selector: 'app-linked-info-contact-list',
  templateUrl: './linked-info-contact-list.component.html',
  styleUrls: ['./linked-info-contact-list.component.less', '../../../styles/link-info-popover.less']
})
export class LinkedInfoContactListComponent extends LinkInfoPopover {

  /**
   * Constructor
   */

  constructor(
    private modalService: ModalService,
    private contactsService: ContactsService,
    protected ngZone: NgZone
  ) {
    super(ngZone);
    this.type = 'contact';
  }

  /**
   * Methods
   */

  getItems(ids: string[]): Observable<Contact[]> {
    return this.contactsService.getContacts({contactsIds: ids, limit: ids.length})
      .pipe(
        takeUntil(this.componentNotDestroyed),
        map((response: ContactsListResponse) => response.contacts)
      );
  }

  /**
   * Actions
   */

  select(contact: Contact) {
    this.modalService.contactForm(contact);
  }

}
