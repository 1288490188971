import * as tslib_1 from "tslib";
// Rx
import { Subject, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
// Types
import { Note } from '../types/note';
// Env
import { environment } from '@environment';
// Decorators
import { warmUpObservable } from '@decorators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../linked-info/services/linked-info.service";
import * as i3 from "../../async-tasks/services/async-tasks-toasts.service";
var NotesService = /** @class */ (function () {
    /**
     * Constructor
     */
    function NotesService(http, linkedInfoService, asyncTasksToastsService) {
        this.http = http;
        this.linkedInfoService = linkedInfoService;
        this.asyncTasksToastsService = asyncTasksToastsService;
        // Public
        this.createNewNote = new Subject();
        this.updatedNote = new Subject();
        this.deletedNote = new Subject();
        // Private
        this.refreshAll = new Subject();
    }
    /**
     * Settings
     */
    NotesService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    /**
     * Helpers
     */
    NotesService.prototype.formatFilters = function (filters) {
        var formatedFilters = {};
        if (filters.notesIds) {
            formatedFilters['notes_ids[]'] = filters.notesIds;
        }
        if (filters.archived) {
            formatedFilters['archived'] = filters.archived + '';
        }
        if (filters.deleted) {
            formatedFilters['deleted'] = filters.deleted + '';
        }
        if (filters.pinned) {
            formatedFilters['pinned'] = filters.pinned + '';
        }
        if (filters.favorite) {
            formatedFilters['favorite'] = filters.favorite + '';
        }
        if (filters.fromTime) {
            formatedFilters['from_time'] = filters.fromTime + '';
        }
        if (filters.toTime) {
            formatedFilters['to_time'] = filters.toTime + '';
        }
        if (filters.noDueDate) {
            formatedFilters['no_due_date'] = filters.noDueDate + '';
        }
        if (filters.keywords) {
            formatedFilters['keywords[]'] = filters.keywords;
        }
        if (filters.topics) {
            formatedFilters['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            formatedFilters['tags[]'] = filters.tags;
        }
        if (filters.order) {
            formatedFilters['order'] = filters.order;
        }
        if (filters.orderWithPinned) {
            formatedFilters['order_with_pinned'] = filters.orderWithPinned + '';
        }
        if (filters.limit) {
            formatedFilters['limit'] = filters.limit + '';
        }
        if (filters.offset) {
            formatedFilters['offset'] = filters.offset + '';
        }
        return formatedFilters;
    };
    /**
     * Methods
     */
    NotesService.prototype.doRefreshNotes = function () {
        this.refreshAll.next();
    };
    NotesService.prototype.getRefresh = function () {
        return this.refreshAll.asObservable();
    };
    NotesService.prototype.getNotebooks = function () {
        return of([]);
    };
    NotesService.prototype.getNotes = function (filters) {
        if (filters === void 0) { filters = {}; }
        return this.http.get(environment.baseUrl + "/api/notes", { params: this.formatFilters(filters) })
            .pipe(map(function (_a) {
            var count = _a.count, notes = _a.notes;
            return ({ count: count, notes: notes.map(function (note) { return new Note(note); }) });
        }), catchError(NotesService.handleObserverError));
    };
    NotesService.prototype.getNote = function (id) {
        return this.http.get(environment.baseUrl + "/api/notes/" + id)
            .pipe(map(function (_a) {
            var note = _a.note;
            return new Note(note);
        }));
    };
    NotesService.prototype.createNote = function (note) {
        var _this = this;
        return this.http.post(environment.baseUrl + "/api/notes", note.asPayloadJSON())
            .pipe(map(function (_a) {
            var newNote = _a.note;
            return new Note(newNote);
        }), tap(function (newNote) {
            if (note.linkedInfo) {
                _this.linkedInfoService.linkToItem({ type: 'note', id: newNote.id }, note.linkedInfo);
            }
        }), tap(function (newNote) {
            _this.createNewNote.next(newNote);
            _this.asyncTasksToastsService.show({ text: 'Note successfully created.' });
        }));
    };
    NotesService.prototype.updateNote = function (note) {
        var _this = this;
        return this.http.put(environment.baseUrl + "/api/notes/" + note.id, note.asPayloadJSON())
            .pipe(map(function (_a) {
            var newNote = _a.note;
            return new Note(newNote);
        }), tap(function (newNote) { return _this.updatedNote.next(newNote); }));
    };
    NotesService.prototype.updateNotes = function (filters, updates) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        return this.http.put(environment.baseUrl + "/api/notes/", updates, { params: this.formatFilters(filters) })
            .pipe(map(function (_a) {
            var notes = _a.notes;
            return !!notes;
        }), tap(function () { return _this.updatedNote.next(); }));
    };
    NotesService.prototype.deleteNotes = function (filters) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        return this.http.delete(environment.baseUrl + "/api/notes", { params: this.formatFilters(filters) })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), tap(function (success) {
            if (success) {
                _this.deletedNote.next();
                _this.asyncTasksToastsService.show({
                    text: "Note" + (filters.notesIds && filters.notesIds.length > 1 ? 's' : '') + " successfully deleted."
                });
            }
        }));
    };
    NotesService.prototype.restoreNotes = function (filters) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        return this.http.put(environment.baseUrl + "/api/notes", { deleted: false }, { params: this.formatFilters(tslib_1.__assign({}, filters, { deleted: true })) })
            .pipe(map(function (_a) {
            var notes = _a.notes;
            return !!notes;
        }), tap(function (success) {
            if (success) {
                _this.updatedNote.next();
                _this.asyncTasksToastsService.show({
                    text: "Note" + (filters.notesIds && filters.notesIds.length > 1 ? 's' : '') + " successfully restored from trash."
                });
            }
        }));
    };
    NotesService.prototype.archiveNotes = function (filters, archive) {
        var _this = this;
        if (filters === void 0) { filters = {}; }
        if (archive === void 0) { archive = true; }
        return this.http.put(environment.baseUrl + "/api/notes", { archived: archive }, { params: this.formatFilters(tslib_1.__assign({}, filters, { archived: !archive })) })
            .pipe(map(function (_a) {
            var notes = _a.notes;
            return !!notes;
        }), tap(function (success) {
            if (success) {
                _this.updatedNote.next();
                _this.asyncTasksToastsService.show({
                    text: "Note" + (filters.notesIds.length > 1 ? 's' : '') + " successfully " + (archive ? 'archived' : 'restored from archive') + ".",
                    actions: [{
                            text: 'Undo',
                            handler: function () { return _this.archiveNotes(filters, !archive); }
                        }]
                });
            }
        }));
    };
    NotesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotesService_Factory() { return new NotesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LinkedInfoService), i0.ɵɵinject(i3.AsyncTasksToastsService)); }, token: NotesService, providedIn: "root" });
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "createNote", null);
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "updateNote", null);
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "updateNotes", null);
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "deleteNotes", null);
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "restoreNotes", null);
    tslib_1.__decorate([
        warmUpObservable
    ], NotesService.prototype, "archiveNotes", null);
    return NotesService;
}());
export { NotesService };
