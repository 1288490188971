// Common
import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ContextMenuService } from 'ngx-contextmenu';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.less', '../../styles/input.less'],
})
export class DropdownSelectComponent implements OnInit, OnDestroy {

  // View Children
  @ViewChild('matSelect', { static: true }) matSelect: MatSelect;

  // Inputs
  @Input() inputFormControl: FormControl;
  @Input() placeholder: string;
  @Input() options: DropdownSelectItem[];
  @Input() width: string;
  @Input() maxWidth: string;
  @Input() appearance: 'standard' | 'outline' = 'outline';

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */
  constructor(
    private contextMenuService: ContextMenuService,
  ) {}

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.contextMenuService.show
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => this.close());
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  handleOpenedChange(opened: boolean) {
    if (opened) {
      this.matSelect.overlayDir.overlayRef.backdropElement.hidden = true;
    }
  }

  close() {
    this.matSelect.close();
  }
}
