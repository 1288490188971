<app-split-view
  collapseableSide="left"
  key="mailsSidebar"
  [minWidth]="65"
  [shadow]="true"
  [magneticWidth]="120"
>
  <app-sidebar split-left-pane splitViewKey="mailsSidebar">
    <app-mail-side-bar></app-mail-side-bar>
  </app-sidebar>

  <app-split-view
    #splitView
    split-right-pane
    collapseableSide="right"
    key="knowledgePanel"
    [minWidth]="50"
    [magneticWidth]="200"
    [shadow]="dynamicPanelContent?.type !== 'kp'"
  >
    <div split-left-pane class="main">
      <app-nav-bar></app-nav-bar>
      <div class="main-under-nav-bar">

        <div class="main-content">
          <app-split-view
            collapseableSide="left"
            key="mailsList"
            [minWidth]="240"
            [shadow]="true"
          >
            <app-messages-list
              split-left-pane
              [selectedMessages]="pannelsSelectedMessages.main"
              (selectedMessagesChange)="selectMessages($event, 'main')"
            ></app-messages-list>

            <div
              split-right-pane
              class="message-content"
              *ngIf="selectedMessages?.length > 0"
            >
              <app-message-content
                [message]="selectedMessages[0]"
                [contact]="selectedContact"
                [relatedContacts]="selectedRelatedContacts"
                [relatedTopics]="selectedRelatedTopics"
                (temporalExpressionsSelected)="selectTemporalExpressions($event)"
                (contactChange) = "selectContact($event)"
                (topicsSelected)="selectTopics($event)"
                (visibleTopicsChange)="visibleTopics = $event"
              ></app-message-content>
              <app-linked-info-toolbar [linkedInfo]="[{type: 'message', id: selectedMessages[0]?.id}]"></app-linked-info-toolbar>
            </div>
            <div split-right-pane class="no-messages-placeholder" *ngIf="selectedMessages?.length < 1">
              <img class="logo" src="assets/placeholders/placeholder-mail.svg"/>
            </div>
          </app-split-view>
        </div>


      </div>
    </div>

    <div split-right-pane class="knowledge-panel" [ngSwitch]="dynamicPanelContent?.type">
      <app-knowledge-panel
        split-right-pane
        *ngSwitchDefault
        [selectedMessages]="selectedMessages"
        [(selectedContact)]="selectedContact"
        [selectedTopics]="selectedTopics"
        [(selectedRelatedContacts)]="selectedRelatedContacts"
        [(selectedRelatedTopics)]="selectedRelatedTopics"
        [selectedTemporalExpressions]="selectedTemporalExpressions"
        [selectedMessagesVisibleTopics]="visibleTopics"
      ></app-knowledge-panel>
      <app-attachments-dynamic-panel
        split-right-pane
        *ngSwitchCase="'attachments'"
      ></app-attachments-dynamic-panel>
      <app-linked-info-dynamic-panel
        split-right-pane
        *ngSwitchCase="'linkedInfo'"
        [parentLinkedInfo]="dynamicPanelContent?.data?.parentLinkedInfo"
        [linkedInfo]="dynamicPanelContent?.data?.linkedInfo"
      ></app-linked-info-dynamic-panel>
      <app-linked-info-toolbar *ngSwitchCase="'linkedInfo'"></app-linked-info-toolbar>
    </div>
  </app-split-view>
</app-split-view>
<app-menu></app-menu>
