// Common
import { ComponentRef, Directive, Input } from '@angular/core';
// Components
import {
  SidebarProjectsContextMenuComponent
} from '@modules/tasks/components/sidebar-projects-context-menu/sidebar-projects-context-menu.component';
// Directives
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

@Directive({
  selector: '[appSidebarProjectsContextMenu]'
})
export class SidebarProjectsContextMenuDirective extends ContextMenuDirective {
  // Inputs
  @Input() contextMenuComponent = SidebarProjectsContextMenuComponent;

  setContextMenuInstanceData(componentRef: ComponentRef<SidebarProjectsContextMenuComponent>) {
  }

}
