/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collapseable-group.component.less.shim.ngstyle";
import * as i1 from "../../styles/input.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "./collapseable-group.component";
var styles_CollapseableGroupComponent = [i0.styles, i1.styles];
var RenderType_CollapseableGroupComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CollapseableGroupComponent, data: { "animation": [{ type: 7, name: "collapseMotion", definitions: [{ type: 1, expr: "collapsed => expanded", animation: [{ type: 6, styles: { height: "10px", opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "{{height}}", opacity: 1 }, offset: null }, timings: ".5s ease-in-out" }], options: null }, { type: 1, expr: "expanded => collapsed", animation: [{ type: 6, styles: { height: "{{height}}", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "10px", opacity: 0 }, offset: null }, timings: ".5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_CollapseableGroupComponent as RenderType_CollapseableGroupComponent };
function View_CollapseableGroupComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i2.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i2.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).inline; var currVal_1 = (((i2.ɵnov(_v, 1).color !== "primary") && (i2.ɵnov(_v, 1).color !== "accent")) && (i2.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CollapseableGroupComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "group-header-title-container"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CollapseableGroupComponent_2)), i2.ɵdid(2, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
function View_CollapseableGroupComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i2.ɵdid(1, 540672, null, 0, i5.NgTemplateOutlet, [i2.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i2.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTitleTemplate; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CollapseableGroupComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "group-header-actions-template-container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i2.ɵdid(2, 540672, null, 0, i5.NgTemplateOutlet, [i2.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerRightIconsTemplate; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CollapseableGroupComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "group-header-actions-container-extend-icon mat-icon notranslate"], ["role", "img"]], [[2, "selected", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleExtendIcon($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i2.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i2.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.extendIcon || "collapse-open"); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.extendIconSelected; var currVal_1 = i2.ɵnov(_v, 1).inline; var currVal_2 = (((i2.ɵnov(_v, 1).color !== "primary") && (i2.ɵnov(_v, 1).color !== "accent")) && (i2.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CollapseableGroupComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { contentContainer: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 20, "div", [["class", "group"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 14, "div", [["class", "group-header"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CollapseableGroupComponent_1)), i2.ɵdid(4, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(5, 0, null, null, 2, "div", [["class", "group-header-title-container"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CollapseableGroupComponent_3)), i2.ɵdid(7, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(8, 0, null, null, 7, "div", [["class", "group-header-actions"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CollapseableGroupComponent_4)), i2.ɵdid(10, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(11, 0, null, null, 4, "div", [["class", "group-header-actions-container"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CollapseableGroupComponent_5)), i2.ɵdid(13, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(14, 0, null, null, 1, "mat-icon", [["class", "group-header-actions-container-dropdown-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "dropdown"]], [[2, "group-header-dropdown-icon-collapsed", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i2.ɵdid(15, 9158656, null, 0, i4.MatIcon, [i2.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i2.ɵeld(16, 0, null, null, 0, "div", [["class", "group-header-straight"]], null, null, null, null, null)), (_l()(), i2.ɵeld(17, 0, null, null, 4, "div", [["class", "group-collapseable-body"]], [[2, "collapsed", null], [24, "@collapseMotion", 0]], null, null, null, null)), i2.ɵpod(18, { height: 0 }), i2.ɵpod(19, { value: 0, params: 1 }), (_l()(), i2.ɵeld(20, 0, [[1, 0], ["contentContainer", 1]], null, 1, "div", [["class", "group-collapseable-body-content"]], null, null, null, null, null)), i2.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.headerTitleTemplate; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.headerTitleTemplate; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.headerRightIconsTemplate; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.extendIconEnabled; _ck(_v, 13, 0, currVal_3); var currVal_7 = "dropdown"; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.collapsed; var currVal_5 = i2.ɵnov(_v, 15).inline; var currVal_6 = (((i2.ɵnov(_v, 15).color !== "primary") && (i2.ɵnov(_v, 15).color !== "accent")) && (i2.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.collapsed; var currVal_9 = _ck(_v, 19, 0, (_co.collapsed ? "collapsed" : "expanded"), _ck(_v, 18, 0, _co.contentHeight)); _ck(_v, 17, 0, currVal_8, currVal_9); }); }
export function View_CollapseableGroupComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-form-collapseable-group", [], null, null, null, View_CollapseableGroupComponent_0, RenderType_CollapseableGroupComponent)), i2.ɵdid(1, 49152, null, 0, i6.CollapseableGroupComponent, [], null, null)], null, null); }
var CollapseableGroupComponentNgFactory = i2.ɵccf("app-form-collapseable-group", i6.CollapseableGroupComponent, View_CollapseableGroupComponent_Host_0, { title: "title", icon: "icon", headerTitleTemplate: "headerTitleTemplate", headerRightIconsTemplate: "headerRightIconsTemplate", extendIconEnabled: "extendIconEnabled", extendIcon: "extendIcon", extendIconSelected: "extendIconSelected" }, { extendIconAction: "extendIconAction" }, ["*"]);
export { CollapseableGroupComponentNgFactory as CollapseableGroupComponentNgFactory };
