import * as tslib_1 from "tslib";
// Common
import { ComponentRef, EventEmitter } from '@angular/core';
// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';
// Components
import { CalendarCellContextMenuComponent } from '../components/calendar-cell-context-menu/calendar-cell-context-menu.component';
// RX
import { takeUntil } from 'rxjs/operators';
var CalendarCellContextMenuDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarCellContextMenuDirective, _super);
    function CalendarCellContextMenuDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contextMenuComponent = CalendarCellContextMenuComponent;
        // Outputs
        _this.appCalendarCellContextMenuLoadDayEvents = new EventEmitter();
        return _this;
    }
    CalendarCellContextMenuDirective.prototype.setContextMenuInstanceData = function (componentRef) {
        var _this = this;
        componentRef.instance.date = this.appCalendarCellContextMenu;
        componentRef.instance.calendarType = this.appCalendarCellContextMenuCalendarType;
        componentRef.instance.loadDayEvents
            .pipe(takeUntil(this.alive))
            .subscribe(function () {
            _this.appCalendarCellContextMenuLoadDayEvents.emit();
        });
    };
    return CalendarCellContextMenuDirective;
}(ContextMenuDirective));
export { CalendarCellContextMenuDirective };
