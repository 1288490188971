<div
  class="container"
  [class.dragging]="dragging"
  [@hideWhileDragging]="{
    value: dragging ? 'hidden' : 'shown',
    params: {
      width: direction === 'horizontal' ? size + 'px' : 'auto',
      height: direction === 'vertical' ? size + 'px' : 'auto'
    }
  }"
  (@hideWhileDragging.done)="handleFinishAnimation()"
>
  <div
    #container
    [class.horizontalContent]="direction === 'horizontal'"
  >
    <div
      class="placeholder-container"
      [style.opacity]="hovered ? 1 : 0"
      [style.padding]="hovered ? '8px 0': 0"
      [ngStyle]="direction | orderedItemPlaceholderSize : hovered : placeholderSize"
    >
      <div class="placeholder"></div>
    </div>
    <ng-template 
      [ngTemplateOutlet]="itemTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: implicit, index: index }"
    ></ng-template>
  </div>

  <div
    *ngIf="!dragging && !disabled"
    class="droppable"
    [ngStyle]="direction | orderedItemZonePosition : hovered : size : placeholderSize : neighborSize"
    appDroppable
    [appDroppableNoReposition]="true"
    [appDroppablePredicate]="predicate"
    [appDroppableZIndex]="zIndex"
    (appDroppableDrop)="handleDropBefore($event)"
    (appDroppableEnter)="handleEnterLeave(true)"
    (appDroppableLeave)="handleEnterLeave(false)"
  ></div>
</div>
