import * as tslib_1 from "tslib";
// Rx
import { Subject, BehaviorSubject, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
// Types
import { Calendar } from '../types/calendar';
import { CalendarEvent } from '../types/calendar-event';
// Env
import { environment } from '@environment';
// Decorators
import { warmUpObservable } from '@decorators';
var CalendarService = /** @class */ (function () {
    /**
     * Constructor
     */
    function CalendarService(http, asyncTasksToastsService, linkedInfoService, topicService) {
        var _this = this;
        this.http = http;
        this.asyncTasksToastsService = asyncTasksToastsService;
        this.linkedInfoService = linkedInfoService;
        this.topicService = topicService;
        this.createNewEvent = new Subject();
        this.updatedEvent = new Subject();
        this.deletedEvent = new Subject();
        this.reloadCalendarsList = new Subject();
        this.calendarsList = new BehaviorSubject([]);
        /**
         * Settings
         */
        this.monthViewState = new BehaviorSubject(CalendarService.getMonthViewState());
        this.reloadCalendarsList
            .pipe(switchMap(function () { return _this.getCalendars(); }))
            .subscribe(function (calendars) {
            _this.calendarsList.next(calendars);
        });
        this.reloadCalendarsList.next();
    }
    CalendarService.getMonthViewState = function () {
        return (localStorage.getItem('calendar.month.state') || 'month');
    };
    CalendarService.handleObserverError = function (error) {
        console.error(error);
        return throwError(error);
    };
    CalendarService.prototype.setMonthViewState = function (state) {
        localStorage.setItem('calendar.month.state', state);
        this.monthViewState.next(state);
    };
    /**
     * Calendars
     */
    CalendarService.prototype.getCalendarsList = function () {
        return this.calendarsList.asObservable();
    };
    CalendarService.prototype.getCalendars = function () {
        return this.http.get(environment.baseUrl + '/api/calendars')
            .pipe(map(function (_a) {
            var calendars = _a.calendars;
            return calendars.map(function (calendar) { return new Calendar(calendar); });
        }), catchError(CalendarService.handleObserverError));
    };
    CalendarService.prototype.editCalendar = function (calendar) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/calendars/' + calendar.id, { color: calendar.color })
            .pipe(map(function (_a) {
            var success = _a.success;
            return success;
        }), tap(function () { return _this.reloadCalendarsList.next(); }));
    };
    /**
     * Events
     */
    CalendarService.prototype.getEvents = function (filters) {
        if (filters === void 0) { filters = {}; }
        var params = {};
        if (filters.eventsIds) {
            params['events_ids[]'] = filters.eventsIds;
        }
        if (filters.fromDate) {
            params['from_time'] = filters.fromDate.getTime().toString();
        }
        if (filters.toDate) {
            params['to_time'] = filters.toDate.getTime().toString();
        }
        if (filters.offset) {
            params['offset'] = filters.offset.toString();
        }
        if (filters.limit !== null && filters.limit !== undefined) {
            params['limit'] = filters.limit.toString();
        }
        if (filters.order) {
            params['order'] = filters.order === 'date' ? 'date-asc' : filters.order;
        }
        if (filters.orderWithPinned) {
            params['order_with_pinned'] = true;
        }
        if (filters.archived !== null && filters.archived !== undefined) {
            params['archived'] = filters.archived.toString();
        }
        if (filters.deleted !== null && filters.deleted !== undefined) {
            params['deleted'] = filters.deleted.toString();
        }
        if (filters.calendarIds) {
            params['calendars[]'] = filters.calendarIds;
        }
        if (filters.topics) {
            params['topics[]'] = filters.topics;
        }
        if (filters.tags) {
            params['tags[]'] = filters.tags;
        }
        if (filters.contacts) {
            params['contacts[]'] = filters.contacts;
        }
        if (filters.keywords) {
            params['keywords[]'] = filters.keywords;
        }
        return this.http.get(environment.baseUrl + '/api/calendars/events', { params: params })
            .pipe(map(function (_a) {
            var count = _a.count, events = _a.events;
            return ({ count: count, events: events.map(function (event) { return new CalendarEvent(event); }) });
        }), catchError(CalendarService.handleObserverError));
    };
    CalendarService.prototype.getEvent = function (id) {
        return this.http.get(environment.baseUrl + '/api/calendars/events/' + id)
            .pipe(map(function (_a) {
            var event = _a.event;
            return new CalendarEvent(event);
        }));
    };
    CalendarService.prototype.createEvent = function (newEvent) {
        var _this = this;
        return this.http.post(environment.baseUrl + '/api/calendars/events', newEvent.asPayloadJson())
            .pipe(map(function (_a) {
            var event = _a.event;
            return event;
        }), tap(function (event) {
            if (newEvent.linkedInfo && newEvent.linkedInfo.length) {
                _this.linkedInfoService.linkToItem({ type: 'event', id: event.id }, newEvent.linkedInfo);
            }
        }), tap(function (event) { return _this.createNewEvent.next(event); }), switchMap(function (event) {
            if (newEvent.topics.length === 0) {
                return of(event);
            }
            return _this.topicService.createTopics(newEvent.topics, { eventsIds: [event.id] })
                .pipe(map(function () { return event; }));
        }));
    };
    CalendarService.prototype.editEvent = function (calendarEvent) {
        var _this = this;
        return this.http.put(environment.baseUrl + '/api/calendars/events/' + calendarEvent.id, calendarEvent.asPayloadJson())
            .pipe(tap(function (_a) {
            var event = _a.event;
            return _this.updatedEvent.next(event);
        }), map(function (_a) {
            var event = _a.event;
            return event;
        }));
    };
    CalendarService.prototype.deletePermanentlyEvent = function (eventIds) {
        var _this = this;
        return forkJoin(eventIds.map(function (eventId) { return _this.http.delete(environment.baseUrl + '/api/calendars/events/' + eventId, {}); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.deletedEvent.next();
                _this.asyncTasksToastsService.show({ text: "Event(s) successfully deleted." });
            }
        }));
    };
    CalendarService.prototype.deleteEvent = function (eventIds, deleted) {
        var _this = this;
        return forkJoin(eventIds.map(function (eventId) { return _this.http.put(environment.baseUrl + '/api/calendars/events/' + eventId, { deleted: deleted === true, archived: false }); }))
            .pipe(map(function (results) { return results.some(function (result) { return result.success; }); }), tap(function (success) {
            if (success) {
                _this.updatedEvent.next();
                _this.asyncTasksToastsService.show({ text: "Event(s) " + (deleted ? 'moved to' : 'restored from') + " trash." });
            }
        }));
    };
    CalendarService.prototype.archiveEvents = function (eventIds, archived) {
        var _this = this;
        return forkJoin(eventIds.map(function (eventId) { return _this.http.put(environment.baseUrl + '/api/calendars/events/' + eventId, { archived: archived === true, deleted: false }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.event; }); }), tap(function (success) {
            if (success) {
                _this.updatedEvent.next();
                _this.asyncTasksToastsService.show({ text: "Event(s) " + (archived ? 'moved to' : 'restored from') + " archive." });
            }
        }));
    };
    CalendarService.prototype.pinEvent = function (eventIds, status) {
        var _this = this;
        return forkJoin(eventIds.map(function (eventId) { return _this.http.put(environment.baseUrl + '/api/calendars/events/' + eventId, { pinned: status === true }); }))
            .pipe(map(function (results) { return results.map(function (result) { return result.event; }); }), tap(function (success) {
            if (success) {
                _this.updatedEvent.next();
                _this.asyncTasksToastsService.show({ text: "Event" + (eventIds.length > 1 ? 's' : '') + " " + (eventIds.length > 1 ? 'are' : 'is') + " " + (status ? 'pinned' : 'unpinned') });
            }
        }));
    };
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "getCalendars", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "editCalendar", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "createEvent", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "editEvent", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "deletePermanentlyEvent", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "deleteEvent", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "archiveEvents", null);
    tslib_1.__decorate([
        warmUpObservable
    ], CalendarService.prototype, "pinEvent", null);
    return CalendarService;
}());
export { CalendarService };
