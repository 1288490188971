// Common
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown-nested',
  templateUrl: './dropdown-nested.component.html',
  styleUrls: ['./dropdown-nested.component.less']
})
export class DropdownNestedComponent implements OnInit {

  // Inputs
  @Input() title: string;
  @Input() name: string;
  @Input() divider = false;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

}
