// Common
import { Directive, Input, ComponentRef } from '@angular/core';

// Other
import { ContextMenuDirective } from '@modules/context-menu/directives/context-menu/context-menu.directive';

// Components
import { TaskContextMenuComponent } from '../components/task-context-menu/task-context-menu.component';

// Types
import { Task } from '@modules/tasks/types/task';

@Directive({
  selector: '[appTaskContextMenu]'
})
export class TaskContextMenuDirective extends ContextMenuDirective {


  // Inputs
  @Input() contextMenuComponent = TaskContextMenuComponent;
  @Input() appTaskContextMenu: Task;

  setContextMenuInstanceData(componentRef: ComponentRef<TaskContextMenuComponent>) {
    componentRef.instance.task = this.appTaskContextMenu;
  }
}
