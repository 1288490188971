/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-folders.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../dropdown/components/dropdown/dropdown.component.ngfactory";
import * as i5 from "../../../dropdown/components/dropdown/dropdown.component";
import * as i6 from "../../../dropdown/components/dropdown-single-select/dropdown-single-select.component.ngfactory";
import * as i7 from "../../../dropdown/components/dropdown-single-select/dropdown-single-select.component";
import * as i8 from "../../../dropdown/components/dropdown-nested/dropdown-nested.component.ngfactory";
import * as i9 from "../../../dropdown/components/dropdown-nested/dropdown-nested.component";
import * as i10 from "./dropdown-folders.component";
import * as i11 from "../../services/mail.service";
var styles_DropdownFoldersComponent = [i0.styles];
var RenderType_DropdownFoldersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownFoldersComponent, data: {} });
export { RenderType_DropdownFoldersComponent as RenderType_DropdownFoldersComponent };
function View_DropdownFoldersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dropdown-title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "dropdown-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "dropdown-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "dropdown-small"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "dropdown-small"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.folder == null) ? null : _co.folder.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).inline; var currVal_2 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_DropdownFoldersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "app-dropdown", [], null, null, null, i4.View_DropdownComponent_0, i4.RenderType_DropdownComponent)), i1.ɵdid(1, 1753088, null, 6, i5.DropdownComponent, [], { closeOnSelect: [0, "closeOnSelect"] }, null), i1.ɵqud(335544320, 1, { toggleButtonTemplate: 0 }), i1.ɵqud(603979776, 2, { singleSelects: 1 }), i1.ɵqud(603979776, 3, { multiSelects: 1 }), i1.ɵqud(603979776, 4, { actionItems: 1 }), i1.ɵqud(603979776, 5, { templateItems: 1 }), i1.ɵqud(603979776, 6, { colorSelect: 1 }), (_l()(), i1.ɵand(0, [[1, 2], ["toggleButtonTemplate", 2]], 0, 0, null, View_DropdownFoldersComponent_1)), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "app-dropdown-single-select", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownSingleSelectComponent_0, i6.RenderType_DropdownSingleSelectComponent)), i1.ɵdid(10, 114688, [[2, 4]], 0, i7.DropdownSingleSelectComponent, [], { selected: [0, "selected"], options: [1, "options"], divider: [2, "divider"] }, { selectedChange: "selectedChange" }), i1.ɵpod(11, { name: 0, key: 1 }), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "app-dropdown-nested", [["name", "Folders"]], null, null, null, i8.View_DropdownNestedComponent_0, i8.RenderType_DropdownNestedComponent)), i1.ɵdid(13, 114688, null, 0, i9.DropdownNestedComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "app-dropdown-single-select", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownSingleSelectComponent_0, i6.RenderType_DropdownSingleSelectComponent)), i1.ɵdid(15, 114688, null, 0, i7.DropdownSingleSelectComponent, [], { selected: [0, "selected"], options: [1, "options"] }, { selectedChange: "selectedChange" }), i1.ɵpod(16, { name: 0, key: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 11, 0, ((_co.folder == null) ? null : _co.folder.name), ((_co.folder == null) ? null : _co.folder.id)); var currVal_2 = _co.defaultFolders; var currVal_3 = true; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Folders"; _ck(_v, 13, 0, currVal_4); var currVal_5 = _ck(_v, 16, 0, ((_co.folder == null) ? null : _co.folder.name), ((_co.folder == null) ? null : _co.folder.id)); var currVal_6 = _co.userFolders; _ck(_v, 15, 0, currVal_5, currVal_6); }, null); }
export function View_DropdownFoldersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-folders", [], null, null, null, View_DropdownFoldersComponent_0, RenderType_DropdownFoldersComponent)), i1.ɵdid(1, 114688, null, 0, i10.DropdownFoldersComponent, [i11.MailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownFoldersComponentNgFactory = i1.ɵccf("app-dropdown-folders", i10.DropdownFoldersComponent, View_DropdownFoldersComponent_Host_0, { folder: "folder" }, { folderChange: "folderChange" }, []);
export { DropdownFoldersComponentNgFactory as DropdownFoldersComponentNgFactory };
