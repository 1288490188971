// Common
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// RX
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

// Types
import { SearchSuggestions } from '@modules/search/types/search-suggestions';
import { Recipient } from '@modules/mail/types/recipient';

// Services
import { SearchService } from '@modules/search/services/search.service';

@Component({
  selector: 'app-participants-input',
  templateUrl: './participants-input.component.html',
  styleUrls: ['./participants-input.component.less'],
})
export class ParticipantsInputComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() inputFormControl: FormControl;

  // Public
  public searchSuggestions: Recipient[] = [];
  public searchControl = new FormControl();
  public itemsLimit = 3;
  public showAll = false;
  public hidePopover = new Subject();

  // Private
  private selectedRecipient: Recipient;
  private componentNotDestroyed: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor(
    protected searchService: SearchService,
  ) {

  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      takeUntil(this.componentNotDestroyed),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (value && value.length && ((this.selectedRecipient && this.selectedRecipient.email) !== value)) {
          this.selectedRecipient = null;
          return this.searchService.searchSuggestions(value, undefined, 3);
        }

        return of(new SearchSuggestions());
      })
    ).subscribe((result: SearchSuggestions) => {
      this.searchSuggestions = result.contacts || [];
    });
  }

  ngOnDestroy() {
    this.hidePopover.next();
    this.hidePopover.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }
  /**
   * Actions
   */

  handleContactClick(contact: Recipient) {
    this.selectedRecipient = contact;
    this.searchControl.setValue(contact.email);
  }

  handleAdd() {
    if (this.selectedRecipient) {
      const currentValue = this.inputFormControl.value instanceof Array ? this.inputFormControl.value : [];
      this.inputFormControl.setValue(currentValue.concat(this.selectedRecipient));
      this.selectedRecipient = null;

      this.searchControl.setValue('');
      this.searchSuggestions = [];
      this.hidePopover.next();
    }
  }
}
