// Common
import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, OnDestroy, TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import print, { Configuration } from 'print-js';
import { orderBy } from 'lodash';

// Services
import { MailService } from '../../services/mail.service';
import { KnowledgeGraphService, KnowledgeInfoFilters } from '../../services/knowledge-graph.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { CalendarService } from '@modules/events/services/calendar.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { TopicService } from '@modules/topic/services/topic.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { TagService } from '@modules/tag/services/tag.service';
import { DynamicPanelService } from '@modules/pages/services/dynamic-panel.service';
import { PopoverService } from '@modules/popover/services/popover.service';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';
import { MailMessage } from './../../types/mail-message';
import { TemporalExpression } from '@modules/topic/types/temporal-expression';
import { Topic, TopicsList } from '@modules/topic/types/topic';
import { Recipient } from '../../types/recipient';
import { CalendarEvent } from '@modules/events/types/calendar-event';
import { Task } from '@modules/tasks/types/task';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { SearchParam } from '@modules/search/types/search-param';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo, LinkedInfoType } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tag/types/tag';
import { DynamicPanelType } from '@modules/pages/types/dynamic-panel-data';

@Component({
  selector: 'app-message-content',
  templateUrl: './message-content.component.html',
  styleUrls: ['./message-content.component.less']
})
export class MessageContentComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() message: MailMessage;
  @Input() contact: Recipient = null;
  @Input() relatedContacts: Recipient[] = [];
  @Input() relatedTopics: string[];
  @Input() composeModalFrameCallback: () => ModalFrame;

  // Outputs
  @Output() topicsSelected = new EventEmitter<Topic[]>();
  @Output() visibleTopicsChange = new EventEmitter<Topic[]>();
  @Output() contactChange = new EventEmitter<Recipient>();
  @Output() temporalExpressionsSelected = new EventEmitter<TemporalExpression[]>();

  // Public
  public expandedAttachments = true;
  public highlightedBody: string;
  public replyState: 'selecting' | 'reply' | 'replyAll' | 'forward' = 'selecting';
  public selectedTopics: Topic[] = [];
  public selectedTemporalExpressions: TemporalExpression[] = [];
  public selectedText: string;
  public newTopicText = new Subject<string>();
  public highlightTopics: string[] = [];
  public showHeaderShadow = false;
  public thread: {
    loading?: boolean,
    expanded: boolean,
    offset: number,
    count: number,
    unread_count: number,
    messages: MailMessage[]
  };
  public dynamicPanel: DynamicPanelType;
  public editorMessage: MailMessage;

  // Private
  private isMessageReplyContentCollapsed = true;
  private isCompactWindow = false;
  private loadHighlightTopicsSubject: Subject<void> = new Subject();
  private threadReloaded: Subject<void> = new Subject();
  private componentNotDestroyed = new Subject();
  private popoverClose = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    !(dragData.type === 'message' && dragData.data.length === 1 && dragData.data[0]['id'] === this.message.id) &&
    ['message', 'event', 'task', 'project', 'note', 'topic', 'tag'].includes(dragData.type)

  /**
   * Constructor
   */

  constructor(
    private router: Router,
    private ga: GoogleAnalyticsService,
    private mailService: MailService,
    private modalService: ModalService,
    private calendarService: CalendarService,
    private tasksService: TasksService,
    private knowledgeGraphService: KnowledgeGraphService,
    private topicService: TopicService,
    private linkedInfoService: LinkedInfoService,
    private elementRef: ElementRef,
    private tagService: TagService,
    private dynamicPanelService: DynamicPanelService,
    private popoverService: PopoverService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.isCompactWindow = this.router.url.split('/')[1] === 'compact';
    this.subscribeCollapseMessageBody();
    this.subscribeDynamicPanel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      this.setDefaultValues();
    }
    if ('relatedContacts' in changes || 'relatedTopics' in changes) {
      this.getHighlightTopics();
    }
  }

  ngOnDestroy() {
    this.loadHighlightTopicsSubject.next();
    this.loadHighlightTopicsSubject.complete();
    this.threadReloaded.next();
    this.threadReloaded.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
    this.popoverClose.next();
    this.popoverClose.complete();
  }

  /**
   * Methods
   */

  setDefaultValues() {
    this.replyState = 'selecting';
    this.highlightedBody = '';
    this.selectedTopics = [];
    this.selectedTemporalExpressions = [];
    this.dynamicPanelService.setContent();

    if (this.message) {
      this.highlightedBody = this.message.body;

      // Addinng the icon to toggle collapsed previous threads
      this.highlightedBody = this.highlightedBody.replace(
        '<blockquote',
        '<span class="toggle-mail-content" title="Trimmed content">...</span><blockquote class="hidden"'
      );

      this.getHighlightTopics();
    }

    // Set thread data from the current message
    this.threadReloaded.next();
    if (!this.message || !this.message.threadMessages) {
      this.thread = null;
    } else {
      this.thread = {
        ...this.message.threadMessages,
        offset: 0,
        expanded: false
      };
      if (this.thread.messages[0].id === this.message.id) {
        this.thread.offset = 1;
        this.thread.messages = this.thread.messages.slice(1);
      }
    }
  }

  subscribeCollapseMessageBody() {
    this.elementRef.nativeElement.addEventListener('click', e => {
      if (e.target && e.target.matches('.toggle-mail-content')) {
        e.preventDefault();
        e.stopImmediatePropagation();

        e.target.nextSibling.classList.toggle('hidden');
        this.isMessageReplyContentCollapsed = e.target.nextSibling.classList.contains('hidden');
      }
    });
  }

  subscribeDynamicPanel() {
    this.dynamicPanelService.getContent()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({type}) => this.dynamicPanel = type);
  }

  handleBodyScroll(event: MouseEvent) {
    this.showHeaderShadow = event.target['scrollTop'] !== 0;
  }

  getHighlightTopics() {
    if (!this.message || !this.message.topics ||
      !((this.relatedContacts && this.relatedContacts.length) ||
      (this.relatedTopics && this.relatedTopics.length))) {
      this.highlightTopics = [];
      return;
    }
    const count = this.message.topics.length;
    const filters: KnowledgeInfoFilters = {
      messageId: this.message.id,
      relatedContacts: this.relatedContacts,
      relatedTopics: this.relatedTopics,
      onlyRelatedMessageTopics: true,
    };
    this.loadHighlightTopicsSubject.next();
    this.knowledgeGraphService.getRelatedTopics(filters, count, 0)
      .pipe(
        takeUntil(this.loadHighlightTopicsSubject)
      )
      .subscribe((topics: TopicsList) => {
        this.highlightTopics = topics.data;
      });
  }

  highlightBody() {
    this.ga.trackEvent('mail-content', 'highlight-topic');

    let offsets = [];
    let highlightedBody = this.message.body;

    this.selectedTopics.forEach(topicValue => {
      if (topicValue.offsets && topicValue.offsets.length) {
        offsets.push(...topicValue.offsets);
      }
    });

    this.selectedTemporalExpressions.forEach(expression => {
      if (expression.offsets && expression.offsets.length) {
        offsets.push(...expression.offsets);
      }
    });

    offsets = orderBy(offsets, ['start', 'end'], ['asc', 'asc']);
    offsets = offsets.reduce((result, current) => {
      if (result.length === 0) {
        return [current];
      }

      const previous = result.pop();
      if (previous.end > current.start) {
        result.push({
          start: Math.min(previous.start, current.start),
          end: Math.max(previous.end, current.end)
        });
      } else {
        result.push(previous, current);
      }

      return result;
    }, []);

    offsets.forEach((highligh, index) => {
      if (highlightedBody.length > highligh['start'] &&
          highlightedBody.length > highligh['end']) {
        highlightedBody =
          highlightedBody.slice(0, highligh['start'] + index * 13) +
          '<mark>' +
          highlightedBody.slice(highligh['start'] + index * 13, highligh['end'] + index * 13) +
          '</mark>' +
          highlightedBody.slice(highligh['end'] + index * 13);
      }
    });

    this.highlightedBody = highlightedBody;

    const collapseIcon = this.isMessageReplyContentCollapsed
      ? '<span class="toggle-mail-content" title="Trimmed content">...</span><blockquote class="hidden"'
      : '<span class="toggle-mail-content" title="Trimmed content">...</span><blockquote';

    this.highlightedBody = this.highlightedBody.replace('<blockquote', collapseIcon);
  }

  isFilePrintable(file: Attachment): boolean {
    return file ? file.type.match(/^image\//) !== null || file.type.match('application/pdf') !== null : false;
  }

  /**
   * Actions
   */

  // Contact
  selectContact(contact: Recipient) {
    if (this.contact === contact) {
      this.contact = null;
    } else {
      this.contact = contact;
    }
    this.contactChange.emit(this.contact);
  }

  // Topics
  selectTopics(topics: Topic[]) {
    this.selectedTopics = topics;
    this.topicsSelected.emit(topics);
    this.highlightBody();
  }

  changeVisibleTopics(topics: Topic[]) {
    this.visibleTopicsChange.emit(topics);
  }

  selectTemporalExpressions(expressions: TemporalExpression[]) {
    this.selectedTemporalExpressions = expressions;
    this.temporalExpressionsSelected.emit(expressions);
    this.highlightBody();
  }

  // Message
  starMessage(message: MailMessage) {
    this.ga.trackEvent('mail-content', 'star');
    this.mailService.starMessage(message).subscribe();
  }

  pinMessage(message: MailMessage) {
    this.mailService.pinnedMessage(message).subscribe();
  }

  replyMessage(action: 'reply' | 'replyAll' | 'forward', message: MailMessage) {
    if (this.isCompactWindow) {
      this.router.navigate(['/compact/mail/compose'], {queryParams: {action: action, messageId: message.id}});
    } else {
      let modalFrame: ModalFrame;
      if (this.composeModalFrameCallback) {
        modalFrame = this.composeModalFrameCallback();
      }
      this.modalService.compose(null, message, action, modalFrame);
    }
  }

  moveMessageToFolder(message: MailMessage, folder: string) {
    this.ga.trackEvent('mail-content', `move-to-${folder}`);
    if (message.threadMessages) {
      this.modalService.confirmationModal(
        'Selected message(s) are part of the thread. Would you like to move all messages in the thread?',
        'Move Message(s)',
        'Yes',
        'No',
        (withThread: boolean) => {
          this.mailService.moveMessages({
            messages: [message],
            filters: {
              threadIds: withThread ? [message.thread] : null
            }
          }, [folder]);
        });
    } else {
      this.mailService.moveMessages({ messages: [message] }, [folder]);
    }
  }

  openMessageModal(message: MailMessage) {
    if (message.isDraft &&
      !(message.sending && (message.sending.status === 'sending' || message.sending.status === 'pending'))
    ) {
      return;
    }
    this.ga.trackEvent('mail-content', 'open-email');
    this.modalService.mailContent(message);
  }

  loadTreadMessages() {
    if (
      !this.thread ||
      !this.thread.messages ||
      this.thread.count <= this.thread.messages.length + this.thread.offset
    ) {
      return;
    }

    this.thread.loading = true;
    this.threadReloaded.next();
    this.mailService.getMessages({
      threadIds: [this.message.thread]
    }, 'date', this.thread.count, this.thread.offset)
      .pipe(
        tap(() => this.thread.loading = false),
        takeUntil(this.threadReloaded)
      )
      .subscribe(({ messages }) => this.thread.messages = messages);
  }

  //  Reply
  selectReplyState(state: 'selecting' | 'reply' | 'replyAll' | 'forward', files?: Attachment[]) {
    this.popoverClose.next();
    if (files && files.length) {
      this.editorMessage = new MailMessage();
      this.editorMessage.files = files;
    }
    this.replyState = state;
  }

  closeReply() {
    this.replyState = 'selecting';
    this.editorMessage = null;
  }

  showPopover(element: ElementRef, template: TemplateRef<any>) {
    this.popoverService.create(
      element,
      {
        placement: 'bottom',
        arrow: false,
        content: template,
        trigger: 'click',
        allowedOutsideSelectors: '.mat-autocomplete-panel',
        showUntil: this.popoverClose
      }
    );
  }

  // Actions for selected text
  addSelectedTextTopic() {
    const text = this.selectedText.replace(/\n/g, ' ').trim();
    this.newTopicText.next(text);
    document.getSelection().removeAllRanges();
  }

  addSelectedTextAppointment() {
    const calendarEvent = CalendarEvent.fromMailMessage(this.message);
    calendarEvent.title = this.selectedText.replace(/\n/g, ' ').trim();
    this.calendarService.createNewEvent.next(calendarEvent);
    document.getSelection().removeAllRanges();
  }

  addSelectedTextTask() {
    const task = new Task();
    task.title = this.selectedText.replace(/\n/g, ' ').trim();
    this.tasksService.create(task);

    document.getSelection().removeAllRanges();
  }

  searchSelectedText() {
    this.router.navigate(['/mail/search'], {
      queryParams: {
        query: JSON.stringify(
          [new SearchParam(this.selectedText, 'keyword')]
        )
      }
    });
  }

  exploreSelectedText() {
    this.router.navigate(['/insights/search'], {
      queryParams: {
        query: JSON.stringify(
          [new SearchParam(this.selectedText, 'keyword')]
        )
      }
    });
  }

  openAttachmentsDynamicPanel() {
    if (this.dynamicPanel === 'attachments') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('attachments', { attachments: this.message.files, parentTitle: this.message.subject });
    }
  }

  openLinkedInfoDynamicPanel() {
    if (this.dynamicPanel === 'linkedInfo') {
      this.dynamicPanelService.setContent();
    } else {
      this.dynamicPanelService.setContent('linkedInfo', {
        linkedInfo: this.message.linkedInfo,
        parentLinkedInfo: { type: 'message', id: this.message.id },
        parentTitle: this.message.subject
      });
    }
  }

  /**
   * Drag and drop
   */

  dndDrop(dragData: DragData) {
    if (!dragData.data) {
      return;
    }
    // Message | Event | Project | Task | Note
    if (['message', 'event', 'project', 'task', 'note'].includes(dragData.type) && dragData.data) {
      const items = dragData.data as {id: string}[];
      const linkedItems = items.map(item => new LinkedInfo(dragData.type as LinkedInfoType, item.id));
      this.linkedInfoService.linkToItem(new LinkedInfo('message', this.message.id), linkedItems);
    }
    // Topic
    if (dragData.type === 'topic') {
      const topic = dragData.data[0] as string;
      this.topicService.createTopics([{name: topic}], {messagesIds: [this.message.id]});
    }
    // Tag
    if (dragData.type === 'tag') {
      const tags = dragData.data as Tag[];
      this.tagService.createTags(tags, {messagesIds: [this.message.id]});
      this.message.tags = [...this.message.tags, ...tags];
    }
  }

}
