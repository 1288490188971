// Common
import { Component, OnInit } from '@angular/core';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

// Rx
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';

@Component({
  selector: 'app-files-list-container',
  templateUrl: './files-list-container.component.html',
  styleUrls: ['./files-list-container.component.less']
})
export class FilesListContainerComponent implements OnInit {

  // Public
  public selectedOrder: DropdownOption;
  public listScrollPosition: number;
  public minimized: boolean;
  public searchQuery: string;
  public quickFormExpanded = false;
  public listFilters: TasksFilters;
  public loading: Boolean;
  public orders: DropdownOption[] = [
    {name: 'Recency', key: 'recency'},
    {name: 'Date', key: 'date'},
    {name: 'Title', key: 'title'},
  ];
  public scrollOptions = [
    {name: 'Scroll to top', key: 'scroll-top'},
    {name: 'Scroll to bottom', key: 'scroll-bottom'}
  ];

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();

  // Constructor
  constructor(
    private splitViewService: SplitViewService
  ) { }

  // Lifecycle
  ngOnInit() {
    this.splitViewService.getMinimized('tasksList')
      .pipe(
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });
  }

}
