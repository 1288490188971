// Common
import {
  Component, Input, ViewChild, OnInit, ElementRef, OnDestroy, SimpleChanges, OnChanges, TemplateRef, Output, EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
import * as Chrono from 'chrono-node';
import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';

// Services
import { ContextMenuService } from 'ngx-contextmenu';
import { StateService } from '@modules/settings/services/state.service';

// RX
import { Subject, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, startWith, filter } from 'rxjs/operators';

// Pipes
import { DatePipe } from '@angular/common';

// Types
import { BundledEvent } from '@modules/bundled-inputs/types/bundled-event';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { CollapsedStateKey } from '@modules/settings/types/collapsed-state';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: '43px' }),
    animate(`.3s ease-in-out`,
      style({
        height: '{{height}}',
      })
    )
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{height}}', }),
    animate(`.3s ease-in-out`,
      style({
        height: '43px',
      })
    )
  ])
]);

export class CalendarMonthDateFormatter extends CalendarDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'EEEEE', locale);
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less', '../../styles/input.less'],
  animations: [collapseMotion],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarMonthDateFormatter
    }
  ]
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {

  // View Children
  @ViewChild('calendarContainer', { static: false }) calendarContainer: ElementRef;

  // Inputs
  @Input() inputFormControl = new FormControl();
  @Input() placeholder: string;
  @Input() width: string;
  @Input() disabled = false;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() inline: boolean;
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputAppearance: 'start' | 'end' = 'start';
  @Input() bundledInputConsumerGroup: string;
  @Input() collapsedStateKey: CollapsedStateKey;
  @Input() maxDate: Date;
  @Input() collapseable = true;
  @Input() dayTemplate: TemplateRef<any>;
  @Input() activeDate = new Date();
  @Input() events: AngularCalendarEvent[] = [];

  // Output
  @Output() activeDateChange = new EventEmitter();
  @Output() dateSelected = new EventEmitter();

  // Public
  public collapsed = false;
  public inlineHeight: string;
  public dateStringFormControl: FormControl = new FormControl();
  public years: DropdownOption[] = [];
  public months: DropdownOption[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ].map((month, index) => ({name: month, key: index.toString()}));
  public hidePopover = new Subject();

  // Private
  private componentNotDestroyed: Subject<void> = new Subject();
  private inputFormControlSubscription: Subscription;
  private yearsShift = 0;

  /**
   * Constructor
   */

  constructor(
    private contextMenuService: ContextMenuService,
    private datePipe: DatePipe,
    private stateService: StateService,
  ) {
    this.bundledTransformFunction = this.bundledTransformFunction.bind(this);
    this.subscribeToFormControl();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.contextMenuService.show
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => this.close());

    this.getPersistedState();
    this.generateYearsList();
  }

  ngOnDestroy() {
    this.hidePopover.next();
    this.hidePopover.complete();
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes && this.inputFormControl) {
      this.subscribeToFormControl();
    }
  }

  /**
   * Actions
   */

  handlePreviousClick(): void {
    this.setActiveDate(new Date(
      this.activeDate.getFullYear(),
      this.activeDate.getMonth() - 1,
      Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() - 1)))
    ));
  }

  handleNextClick(): void {
    this.setActiveDate(new Date(
      this.activeDate.getFullYear(),
      this.activeDate.getMonth() + 1,
      Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() + 1)))
    ));
  }

  handleChangeYearsViewport(direction: 'up' | 'down') {
    this.yearsShift += direction === 'up' ? -1 : 1;
    this.generateYearsList();
  }

  handleTodayClick() {
    this.setActiveDate(new Date());
  }

  handleMonthClick(monthIndex: string): void {
    this.setActiveDate(new Date(
      this.activeDate.getFullYear(),
      parseInt(monthIndex, 10),
      Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(this.activeDate.getFullYear(), parseInt(monthIndex, 10))))
    ));
  }

  handleYearClick(year: string) {
    this.setActiveDate(new Date(
      parseInt(year, 10),
      this.activeDate.getMonth(),
      Math.min(this.activeDate.getDate(), this.daysInMonth(new Date(parseInt(year, 10), this.activeDate.getMonth())))
    ));
  }

  handleDateSelect(date: Date) {
    if (this.maxDate && date >= this.maxDate) {
      return;
    }

    this.setActiveDate(date);
    this.inputFormControl.markAsDirty();
    this.inputFormControl.setValue(date);
    this.setDateStringFormControl();

    if (!this.inline) {
      this.close();
    }
  }

  close() {
    this.hidePopover.next();
  }

  handleCollapse() {
    this.inlineHeight = this.calendarContainer.nativeElement.offsetHeight + 'px';
    this.collapsed = !this.collapsed;
    if (this.inline && this.collapsedStateKey) {
      this.stateService.setCollapsed(this.collapsedStateKey, this.collapsed);
    }
  }

  private setDateStringFormControl() {
    this.dateStringFormControl.setValue(this.datePipe.transform(this.inputFormControl.value, 'MMM d, yyyy'));
  }

  private setActiveDate(date: Date) {
    this.activeDate = date;
    this.activeDateChange.emit(date);
  }

  /**
   * Helpers
   */

  generateYearsList() {
    const selectedYear = this.activeDate.getFullYear() + this.yearsShift * 20;
    this.years = [...Array(20).keys()]
      .map(index => selectedYear - 10 + index)
      .map(year => ({name: year.toString(), key: year.toString()}));
  }

  getPersistedState() {
    if (this.inline && this.collapsedStateKey) {
      this.stateService.getCollapsed(this.collapsedStateKey)
        .pipe(
          takeUntil(this.componentNotDestroyed),
          distinctUntilChanged(),
        )
        .subscribe((value: boolean) => this.collapsed = value);
    }
  }

  bundledTransformFunction(event: BundledEvent) {
    const chronoResult = Chrono.parse(event.fromValue);

    const date = this.bundledInputAppearance === 'start' ?
      (chronoResult && chronoResult[0] && chronoResult[0].start)
      :
      (chronoResult && ((chronoResult[0] && chronoResult[0].end) || (chronoResult[1] && chronoResult[1].start)));

    if (
      date &&
      date.knownValues &&
      (
        date.knownValues.day ||
        date.knownValues.month ||
        date.knownValues.year ||
        date.knownValues.weekday
      )
    ) {
      const dateParts = {
        ...date.impliedValues,
        ...date.knownValues
      };

      const dateObject = new Date(dateParts.year, dateParts.month - 1, dateParts.day);
      event.toValue = this.datePipe.transform(dateObject, 'MMM d, yyyy');
      event.formControlValue = dateObject;
      event.formControlValueChanged = !this.inputFormControl.value ||
        dateObject.getTime() !== this.inputFormControl.value.getTime();

      let index = 1;
      if (
        this.bundledInputAppearance === 'start' ||
        (this.bundledInputAppearance === 'end' && chronoResult[0].end)
      ) {
        index = 0;
      }

      event.fromValueHighlightRange = [chronoResult[index].index, chronoResult[index].index + chronoResult[index].text.length];
    } else {
      event.toValue = null;
    }
  }

  bundledValueCompareFunction(value: Date, bundledValue: Date): boolean {
    return (
      value &&
      bundledValue &&
      value.getFullYear() === bundledValue.getFullYear() &&
      value.getMonth() === bundledValue.getMonth() &&
      value.getDate() === bundledValue.getDate()
    );
  }

  subscribeToFormControl() {
    if (this.inputFormControlSubscription) {
      this.inputFormControlSubscription.unsubscribe();
    }
    this.inputFormControlSubscription = this.inputFormControl.valueChanges
      .pipe(
        startWith(this.inputFormControl.value as Date),
        distinctUntilChanged((a, b) => (
          a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
        )),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe((date: Date) => {
        if (!date) {
          return;
        }
        this.setActiveDate(date);
        this.dateSelected.emit(date);
        this.setDateStringFormControl();
      });
  }

  /**
   * Helpers
   */

  private daysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
}
