// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { TooltipModule } from 'ngx-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { PopoverModule } from '@modules/popover/popover.module';

// Components
import { TemporalExpressionComponent } from './components/temporal-expression/temporal-expression.component';
import { TopicsFormPopoverComponent } from './components/topics-form-popover/topics-form-popover.component';
import { TopicsComponent } from './components/topics/topics.component';

@NgModule({
  imports: [
    CommonModule,
    ContextMenuModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    MatIconModule,
    AppCommonModule,
    ElementsModule,
    FormControlsModule,
    PipesModule,
    PopoverModule,
  ],
  declarations: [
    TemporalExpressionComponent,
    TopicsFormPopoverComponent,
    TopicsComponent,
  ],
  exports: [
    TemporalExpressionComponent,
    TopicsFormPopoverComponent,
  ]
})
export class TopicModule { }
