// Common
import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

/**
 * Types
 */
class FileType {
  color: string;
  text: string;
  icon?: string;

  constructor(color: string, text: string, icon?: string) {
    this.color = color;
    this.text = text;
    this.icon = icon;
  }
}

@Component({
  selector: 'app-attachment-icon',
  templateUrl: './attachment-icon.component.html',
  styleUrls: ['./attachment-icon.component.less']
})
export class AttachmentIconComponent implements OnInit, OnChanges {

  // Inputs
  @Input() type: string;

  // Public
  public fileType: FileType;

  /**
   * Constructor
   */

  constructor() { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fileType = this.attachmentIcon(this.type);
  }

  /**
   * Methods
   */

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
  attachmentIcon(type: string): FileType {
    const iconsClasses = {
      'audio': new FileType('#c3daf1', 'AUD'),
      'default': new FileType('#a95227', 'ARCH'),
      'image': new FileType('#6a69c3', 'IMG'),
      'text': new FileType('#ffbb6f', 'TXT'),
      'video': new FileType('#438cd4', 'MOV'),
      'application/ics': new FileType('#d54747', null, 'tab-calendar'),
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': new FileType('#217344', 'XLS'),
      'application/javascript': new FileType('#f7df1e', 'JS'),
      'application/msword': new FileType('#409aff', 'DOC'),
      'application/pdf': new FileType('#f16969', 'PDF'),
      'text/html': new FileType('#e34f26', 'HTML'),
      'text/xml': new FileType('#217344', 'XLS'),
      'text/calendar': new FileType('#d54747', null, 'tab-calendar'),
      'image/svg': new FileType('#6a69c3', 'SVG'),
      'image/svg+xml': new FileType('#6a69c3', 'SVG'),
      'image/jpeg': new FileType('#fff', null , 'jpg'),
      'image/png': new FileType('#6a69c3', 'PNG'),
      'image/gif': new FileType('#6a69c3', 'GIF'),
      'image/bmp': new FileType('#6a69c3', 'BMP'),
      'image/vnd.microsoft.icon': new FileType('#6a69c3', 'ICO'),
      'image/tiff': new FileType('#6a69c3', 'TIFF'),
      'image/webp': new FileType('#6a69c3', 'WEBP'),
    };
    return iconsClasses[type] || new FileType('#ffbb6f', 'FILE');
  }

}
