// Common
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { addDays } from 'date-fns';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.less']
})
export class QuickActionsComponent {

  // Inputs
  @Input() startDateFormControl: AbstractControl;
  @Input() endDateFormControl: AbstractControl;
  @Input() startTimeFormControl: AbstractControl;
  @Input() endTimeFormControl: AbstractControl;
  @Input() priorityFormControl: AbstractControl;

  @Input() showQuickActionsForm = true;

  // Private
  private hidePopover = new Subject<void>();
  private todaysDate = new Date();

  // Public
  public hidePopoverObservable = this.hidePopover.asObservable();

  // Constructor
  constructor() { }

  //  Actions
  public handleQuickSetStartDate(day: 'today' | 'tomorrow' | 'nextMonday'): void {
    let delta = 0;

    if (day === 'tomorrow') { delta = 1; }
    if (day === 'nextMonday') {
      if (this.todaysDate.getDay() !== 0) { delta = 7 - this.todaysDate.getDay(); }
      delta += 1;
    }

    this.startDateFormControl.setValue(addDays(this.todaysDate, delta));
  }

  public handleClosePopover() {
    this.hidePopover.next();
  }

}
