/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./note-quick-form.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../form-controls/components/input/input.component.ngfactory";
import * as i4 from "../../../form-controls/components/input/input.component";
import * as i5 from "../../../form-controls/components/button/button.component.ngfactory";
import * as i6 from "../../../form-controls/components/button/button.component";
import * as i7 from "./note-quick-form.component";
import * as i8 from "../../../notes/services/notes.service";
var styles_NoteQuickFormComponent = [i0.styles];
var RenderType_NoteQuickFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoteQuickFormComponent, data: {} });
export { RenderType_NoteQuickFormComponent as RenderType_NoteQuickFormComponent };
export function View_NoteQuickFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "note-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.handleSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "note-form-horizontal-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "note-form-horizontal-group-full-width-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-input", [["appearance", "standard"], ["placeholder", "Title"]], null, null, null, i3.View_InputComponent_0, i3.RenderType_InputComponent)), i1.ɵdid(9, 49152, null, 0, i4.InputComponent, [], { inputFormControl: [0, "inputFormControl"], placeholder: [1, "placeholder"], appearance: [2, "appearance"], bundledInputConsumerKeys: [3, "bundledInputConsumerKeys"], bundledInputConsumerGroup: [4, "bundledInputConsumerGroup"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "note-form-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "app-button", [["class", "note-form-buttons-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleMoreOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(13, 49152, null, 0, i6.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, [" More Options "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "app-button", [["appearance", "blue"], ["class", "note-form-buttons-item"], ["type", "submit"]], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i6.ButtonComponent, [i1.ElementRef], { type: [0, "type"], appearance: [1, "appearance"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, [" Add Note "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.noteForm; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.noteForm.controls["title"]; var currVal_9 = "Title"; var currVal_10 = "standard"; var currVal_11 = _ck(_v, 10, 0, "title"); var currVal_12 = _co.bundledGroup; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "submit"; var currVal_14 = "blue"; var currVal_15 = _co.saveInProgress; _ck(_v, 16, 0, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NoteQuickFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-note-quick-form", [], null, null, null, View_NoteQuickFormComponent_0, RenderType_NoteQuickFormComponent)), i1.ɵdid(1, 573440, null, 0, i7.NoteQuickFormComponent, [i8.NotesService], null, null)], null, null); }
var NoteQuickFormComponentNgFactory = i1.ɵccf("app-note-quick-form", i7.NoteQuickFormComponent, View_NoteQuickFormComponent_Host_0, { bundledGroup: "bundledGroup", note: "note" }, { more: "more", close: "close" }, []);
export { NoteQuickFormComponentNgFactory as NoteQuickFormComponentNgFactory };
