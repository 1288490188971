// Common
import { Component, OnInit, Input, ElementRef, ChangeDetectorRef } from '@angular/core';

// Services
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { PopupService } from '../../../popup/services/popup.service';

// Types
import { MailMessage } from '../../../mail/types/mail-message';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Recipient } from '../../../mail/types/recipient';

@Component({
  selector: 'app-mail-editor-modal',
  templateUrl: './mail-editor-modal.component.html',
  styleUrls: ['./mail-editor-modal.component.less']
})
export class MailEditorModalComponent extends BaseModalComponent implements OnInit {

  // Common properties for all modals
  @Input() availableExpand = true;
  @Input() action: 'reply' | 'replyAll' | 'forward';
  @Input() message: MailMessage;
  @Input() sourceMessage: MailMessage;

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(100% - 705px)',
    y: 'calc(100% - 505px)',
    width: '700px',
    height: '500px'
  };

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected element: ElementRef,
    protected popupService: PopupService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Actions
   */

  messageChange(message: MailMessage) {
    this.message = message;
    this.changeDetector.detectChanges();
  }

  expand() {
    this.ga.trackEvent('mail-editor-modal', 'expand');
    this.popupService.compose(this.message, this.maximizedSize.popupSize());
    this.close();
  }

}
