<div class="calendar-week--wrapper" [@dateAnimation]="displayDate">

  <!--Calendar Header-->
  <ng-template
    #customTemplate
    let-days="days"
    let-locale="locale"
    let-dayHeaderClicked="dayHeaderClicked"
    let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
  >
    <div class="cal-day-headers">
      <div class="gmt"><span class="timezone"> {{ timeZoneName }} </span></div>
      <div
        class="cal-header"
        *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
        [class.cal-past]="day.isPast"
        [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend"
        [class.cal-selected]="day.date | sameDay : selectedDate"
        [ngClass]="day.cssClass"
        (mwlClick)="dayHeaderClicked.emit({ day: day })"
      >
        <div class="side-borders-overflow"></div>

        <div
          class="flexColumn"
          (appDblClick)="dblClickDay(day.date)"
        >
          <div class="headerDate" (click)="handleLoadDayEvents(day.date)">
            <b class="day-number">{{ day.date | calendarDate: 'weekViewColumnHeader':locale }}</b>
            <span class="day-name">{{ day.date | calendarDate: 'weekViewColumnSubHeader':locale }}</span>
          </div>
        </div>

      </div>
    </div>
  </ng-template>

  <ng-template
    #hourSegmentTemplate
    let-segment="segment"
    let-locale="locale"
    let-segmentHeight="segmentHeight"
    let-isTimeLabel="isTimeLabel"
  >
    <div [ngClass]="{'today': segment.date | checkDate:'today'}">
      <div class="timelineContainer" *ngIf="!isTimeLabel && segment.date | checkDate:'beginningOfToday' ">
        <div class="time">
          <div class="calendar-day__current-time" [style.top.px]="currentTimeLineOffset - 8">
            <span class="display-time">{{ currentTime | date:'hh:mm a' }}</span>
          </div>
        </div>
        <div class="calendar-day__current-time-line" [style.top.px]="currentTimeLineOffset"></div>
      </div>

      <div
        #cell
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        appDroppable
        appDroppableHoverClass="drag-over"
        (appDroppableDrop)="handleDrop($event, segment.date, cell)"
        (appClick)="clickHourSegment($event, segment.date, cell)"
        (appDblClick)="dblClickHourSegment(segment.date)"
        [appCalendarCellContextMenu]="segment.date"
        appCalendarCellContextMenuCalendarType="week"
        (appCalendarCellContextMenuLoadDayEvents)="handleLoadDayEvents(segment.date)"
      >
        <div class="cal-time" *ngIf="isTimeLabel"> {{ segment.date | calendarDate: 'weekViewHour':locale }} </div>
      </div>
    </div>
  </ng-template>

  <app-customized-calendar-week
    class="calendar"
    [viewDate]="displayDate"
    [highlightedDay]="highlightedDay"
    [events]="events"
    [headerTemplate]="customTemplate"
    [hourSegmentTemplate]="hourSegmentTemplate"
    [hourSegmentHeight]="hourSegmentHeight"
    [hourSegments]="1"
    [eventTemplate]="eventTemplate"
    [weekStartsOn]="weekStartsOn"
    [excludeDays]="excludeDays"
    [refresh]="refreshCalendar"
    (beforeViewRender)="beforeRender($event)"
  >
  </app-customized-calendar-week>

</div>
