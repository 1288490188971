import * as tslib_1 from "tslib";
// Common
import { OnInit, OnDestroy, NgZone, EventEmitter } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
// RxJS
import { Subject, of, timer, fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, map, filter, tap, switchMap, takeUntil, retryWhen, startWith } from 'rxjs/operators';
var VirtualScrollListComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function VirtualScrollListComponent(ngZone) {
        this.ngZone = ngZone;
        // Public
        this.loading = false;
        this.loadingError = false;
        this.itemsStream = new Subject();
        this.itemsStreamObservable = this.itemsStream.asObservable().pipe(startWith([]));
        this.focused = false;
        // Protected
        this.alive = new Subject();
        this.currentIndex = new BehaviorSubject(0);
        this.itemType = Object;
        // Private
        this.loadItems = new Subject();
        this.initialIndex = 0;
        this.loadInProgress = new EventEmitter();
    }
    /**
     * Component lifecycle
     */
    VirtualScrollListComponent.prototype.ngOnInit = function () {
        var _this = this;
        /* Item Loading logic */
        this.loadItems
            .pipe(debounceTime(200), map(function (range) {
            if (_this.initialIndex) {
                range = tslib_1.__assign({}, range, { start: _this.initialIndex, end: _this.initialIndex + 20 });
            }
            if (!_this.items || range.rewrite) {
                return range;
            }
            var updatedRange = { start: undefined, end: undefined, rewrite: range.rewrite };
            for (var i = range.start; i <= range.end && i < _this.items.length; i++) {
                if (!_this.items[i].data) {
                    updatedRange.start = updatedRange.start !== undefined ? updatedRange.start : i;
                    updatedRange.end = i;
                }
            }
            return updatedRange;
        }), filter(function (range) { return range && range.end > 0 && range.start < range.end; }), tap(function () {
            _this.loadInProgress.next(true);
            _this.loadingError = false;
        }), switchMap(function (range) {
            return _this.getItems(range.start, range.end - range.start + 1)
                .pipe(map(function (response) { return ({ range: range, response: response }); }));
        }), takeUntil(this.alive), map(function (_a) {
            var _b;
            var range = _a.range, response = _a.response;
            _this.loadInProgress.next(false);
            if (!_this.items) {
                _this.items = new Array(response.count || 0).fill(_this.itemFactory(null));
            }
            else if (range.rewrite) {
                // Compare if new messages are not the same messages in selected range
                if (!response.items.length ||
                    _this.items.length !== response.count ||
                    response.items.some(function (item, index) {
                        return !_this.items[range.start + index] ||
                            !_this.items[range.start + index].data ||
                            !_this.compareItems(item, _this.items[range.start + index]);
                    })) {
                    _this.items = new Array(response.count || 0).fill(_this.itemFactory(null));
                }
            }
            else if (_this.items.length !== response.count) {
                _this.refreshCurrentItems();
            }
            (_b = _this.items).splice.apply(_b, tslib_1.__spread([range.start, response.items.length], response.items));
            if (_this.initialIndex) {
                timer(0).subscribe(function () {
                    _this.scrollToIndex(_this.initialIndex);
                    _this.initialIndex = 0;
                });
            }
            return _this.items;
        }), retryWhen(function (errors) {
            return errors.pipe(tap(function () {
                _this.loadInProgress.next(false);
                _this.loadingError = true;
            }));
        }))
            .subscribe(function (items) { return _this.itemsStream.next(items); });
        this.viewport.renderedRangeStream
            .pipe(filter(function (range) { return range && range.end > 0 && range.start <= range.end; }), takeUntil(this.alive))
            .subscribe(function (range) {
            _this.loadItems.next({ start: range.start, end: range.end, rewrite: false });
        });
        // Add subs for event required for keyboard navigation. Defined outside of angular for performance reasons
        this.ngZone.runOutsideAngular(function () {
            _this.viewport.scrolledIndexChange
                .pipe(takeUntil(_this.alive))
                .subscribe(function (index) { return _this.currentIndex.next(index); });
            fromEvent(window.document, 'click')
                .pipe(filter(function () { return !!(_this.viewport && _this.viewport.elementRef); }), takeUntil(_this.alive))
                .subscribe(function (event) {
                return _this.focused =
                    _this.viewport.elementRef.nativeElement.contains(event.target) ||
                        event['path'].includes(_this.viewport.elementRef.nativeElement);
            });
            fromEvent(window.document, 'keydown')
                .pipe(filter(function (event) { return _this.focused && !(event.target instanceof Element &&
                event.target.tagName.toLowerCase() === 'input'); }), tap(function (event) {
                event.preventDefault();
                event.stopPropagation();
            }), takeUntil(_this.alive))
                .subscribe(function (event) { return _this.keydown(event); });
        });
        this.loadInProgress.asObservable()
            .pipe(takeUntil(this.alive))
            .subscribe(function (value) { return _this.loading = value; });
        this.resetItems();
    };
    VirtualScrollListComponent.prototype.ngOnDestroy = function () {
        this.alive.next();
        this.alive.complete();
    };
    /**
     * Actions
     */
    VirtualScrollListComponent.prototype.resetItems = function () {
        this.items = null;
        this.itemsStream.next([]); // Viewport accepts only arrays, so can't send null
        this.selectedItems = [];
        this.loadItems.next({ start: 0, end: 20, rewrite: true });
    };
    VirtualScrollListComponent.prototype.refreshCurrentItems = function () {
        var renderedRange = this.viewport.getRenderedRange();
        if (renderedRange && !renderedRange.start && !renderedRange.end) {
            renderedRange.end = 20;
        }
        this.loadItems.next(tslib_1.__assign({}, renderedRange, { rewrite: true }));
    };
    VirtualScrollListComponent.prototype.scrollToIndex = function (index, behavior) {
        if (!this.items) {
            this.initialIndex = index;
        }
        else {
            this.viewport.scrollToIndex(index, behavior);
        }
    };
    // This method have to be overload, to get appropriate items
    VirtualScrollListComponent.prototype.getItems = function (offset, limit) {
        return of({ items: [], count: 0 });
    };
    // Optionally overload this method for items comparison. For example if id field named differently
    VirtualScrollListComponent.prototype.compareItems = function (item1, item2) {
        return item1 && item2 && item1.data && item2.data && item1.data['id'] === item2.data['id'];
    };
    // This method have to be overloaded in order to get proper sizes calculations
    VirtualScrollListComponent.prototype.itemFactory = function (data) {
        throw new Error('itemFactory not implemented');
    };
    VirtualScrollListComponent.prototype.selectItem = function (item, event, selectAll) {
        var _this = this;
        if (selectAll === void 0) { selectAll = false; }
        if (!item || !item.data) {
            return;
        }
        var multi = event.ctrlKey || event.shiftKey || event.metaKey;
        var range = event.shiftKey || selectAll;
        if (multi && this.selectedItems.length === 1 && this.compareItems(this.selectedItems[0], item)) {
            return;
        }
        if (!multi || this.selectedItems.length === 0) {
            this.selectedItems = [item];
        }
        else if (!range) {
            var index = this.selectedItems.findIndex(function (currentItem) { return _this.compareItems(currentItem, item); });
            if (index === -1) {
                this.selectedItems.push(item);
            }
            else {
                this.selectedItems.splice(index, 1);
            }
        }
        else {
            var lastSelectedIndex = this.items
                .findIndex(function (currentItem) { return _this.compareItems(currentItem, _this.selectedItems[_this.selectedItems.length - 1]); });
            var currentSelectedIndex = this.items.findIndex(function (currentItem) { return _this.compareItems(currentItem, item); });
            var startSelection = Math.min(lastSelectedIndex, currentSelectedIndex);
            var endSelection = Math.max(lastSelectedIndex, currentSelectedIndex);
            var _loop_1 = function (i) {
                var indexInSelection = this_1.selectedItems.findIndex(function (currentItem) { return _this.compareItems(currentItem, _this.items[i]); });
                if (indexInSelection !== -1) {
                    this_1.selectedItems.splice(indexInSelection, 1);
                }
                this_1.selectedItems.push(this_1.items[i]);
            };
            var this_1 = this;
            for (var i = startSelection; i <= endSelection; i++) {
                _loop_1(i);
            }
        }
    };
    VirtualScrollListComponent.prototype.keydown = function (event) {
        var _this = this;
        var selectAllPressed = event.code === 'KeyA' && (event.ctrlKey || event.metaKey);
        if (!this.items ||
            !this.items.length ||
            (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && !selectAllPressed)) {
            return;
        }
        var selectAll = false;
        var nextSelectedPosition = 0;
        if (this.selectedItems.length === 0) {
            nextSelectedPosition = 0;
        }
        else if (event.key === 'ArrowUp') {
            var firstSelectedPosition = this.items.findIndex(function (item) { return _this.compareItems(item, _this.selectedItems[0]); });
            nextSelectedPosition = Math.max(firstSelectedPosition - 1, 0);
        }
        else if (event.key === 'ArrowDown') {
            var lastSelectedPosition = this.items
                .findIndex(function (item) { return _this.compareItems(item, _this.selectedItems[_this.selectedItems.length - 1]); });
            nextSelectedPosition = Math.min(lastSelectedPosition + 1, this.items.length - 1);
        }
        else if (selectAllPressed) {
            selectAll = true;
            this.selectedItems = [this.items[0]];
            nextSelectedPosition = this.items.length - 1;
        }
        this.selectItem(this.items[nextSelectedPosition], event, selectAll);
        this.scrollToSelected(nextSelectedPosition);
    };
    VirtualScrollListComponent.prototype.scrollToSelected = function (selectedIndex) {
        if (selectedIndex < this.currentIndex.value) {
            this.viewport.scrollToIndex(selectedIndex);
            return;
        }
        var viewportBottom = this.viewport.measureScrollOffset() + this.viewport.getViewportSize();
        var offset = 0;
        for (var i = 0; i <= selectedIndex; i++) {
            offset += this.items[i].size;
        }
        if (offset > viewportBottom) {
            this.viewport.scrollToOffset(offset - this.viewport.getViewportSize());
        }
    };
    return VirtualScrollListComponent;
}());
export { VirtualScrollListComponent };
